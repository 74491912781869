import Avatar, { AvatarSize } from 'atoms/Avatar';
import Badge, { BadgeColor } from 'atoms/Badge';
import {
  getInvitationRole,
  getSenderEntity,
  getSenderName,
  getSenderPhoto,
} from 'components/NotificationComponent/utils.notification';
import Notification from 'common/model/Notification';

const EntityRevoke = (notification: Notification) => {
  const senderEntity = getSenderEntity(notification.infoSender);

  const isSenderEntityDeleted: boolean = senderEntity === null;

  return (
    <div className="info-details">
      <Avatar
        size={AvatarSize.Medium}
        src={getSenderPhoto(notification.infoSender)}
      />

      {isSenderEntityDeleted ? (
        <span className="text blackish">
          Your invitation and acesss to an entity was <span className="text bold-orange">revoked</span> by
          {getSenderName(notification.infoSender)} but{' '}
          <Badge badgeColor={BadgeColor.RED}>this entity has been deleted</Badge>
        </span>
      ) : (
        <span className="text blackish">
          Your invitation and acesss to “{getSenderEntity(notification.infoSender)}” as a{' '}
          {getInvitationRole(notification.user, notification.infoSender)} has been{' '}
          <span className="text bold-orange">revoked</span> by
          {getSenderName(notification.infoSender)}
        </span>
      )}
    </div>
  );
};

export default EntityRevoke;
