import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentDateTimeISO } from 'utils/utils-date';

interface CreateTransactionDiscussionApprovalMutationVariables {
  params: {
    userId: string | null;
    dateSubmitted: string;
    draftMessage?: string;
  };
}

const CREATE_TRANSACTION_DISCUSSION_APPROVAL = (userId: string | null, draftMessage?: string) => {
  const variables: CreateTransactionDiscussionApprovalMutationVariables = {
    params: {
      userId,
      dateSubmitted: getCurrentDateTimeISO(),
      draftMessage,
    },
  };

  return {
    mutation: gql`
      mutation ($params: TransactionDiscussionApprovalInput!) {
        createTransactionDiscussionApproval(params: $params) {
          id
          dateSubmitted
          user {
            id
            firstName
            lastName
            photoUrl
          }
          draftMessage
        }
      }
    `,
    variables,
  };
};

export const createTransactionDiscussionApproval = createAsyncThunk(
  'transactions/createTransactionDiscussionApproval',
  async ({ draftMessage }: { draftMessage?: string }, { getState }) => {
    const {
      auth: { id: userId },
    } = getState() as RootState;

    const response = await client.mutate(CREATE_TRANSACTION_DISCUSSION_APPROVAL(userId, draftMessage));
    return response;
  },
);
