import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { MultiLevelParamPaginationProps } from 'common/api/multiLevelMenu';
import { LIST_PARAMETERS } from 'common/api/parameters';

export const listParameters = createAsyncThunk(
  'multiLevelMenu/listParameters',
  async ({ first, last, before, after, parameterFilter, choices }: MultiLevelParamPaginationProps) => {
    const response = await adminClient.query(
      LIST_PARAMETERS(parameterFilter?.search, undefined, first, last, after, before, choices),
    );
    return response.data;
  },
);
