import { RefObject, useEffect, useState } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handleOnClickOutside: (event: MouseEvent | TouchEvent) => void,
  options: {
    ignoreTargets?: RefObject<HTMLElement>[];
  },
) => {
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    const listener = (event: MouseEvent | TouchEvent) => {
      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        (options?.ignoreTargets && options?.ignoreTargets?.some(ref => ref.current?.contains(event.target as Node)))
      ) {
        return;
      }

      handleOnClickOutside(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handleOnClickOutside, isEnabled]);

  return { isEnabled, setIsEnabled };
};
