import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { createFormatter, getFormatter, updateFormatter } from 'common/api/formatters';
import { ViewProps } from 'common/api/miscellaneous';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { formatterDisabled } from 'utils/tsValidator';
// import FormatterEditor from './FormatterEditor';
import FormatterContent from './FormatterContent';

const CreatorViewer = ({ isViewPage }: ViewProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { isLoading } = useAppSelector((state: RootState) => state.formatterDetail);
  const checkCreate: boolean = location.pathname.includes('create');
  const pageTypeCheck: MODE_OPTIONS = checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ;
  const [mode, setMode] = useState<MODE_OPTIONS>(pageTypeCheck);

  useEffect(() => {
    if (isViewPage && id) {
      dispatch(getFormatter({ id: id }));
    }
  }, [id]);

  const getPageHeaderProps = (): PageHeaderProps => {
    const { activeFormatter } = useAppSelector((state: RootState) => state.formatterDetail);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const FormatterHeaderButtons = (): JSX.Element => {
      const checkDisabled = (): boolean => {
        return formatterDisabled(activeFormatter);
      };

      const onCreate = (): void => {
        dispatch(createFormatter()).then(response => {
          if (response.meta.requestStatus === 'fulfilled') {
            navigate('/formatters/dashboard');
          }
        });
      };

      /**
       * On Save button Click
       */
      const onSave = (): void => {
        if (checkCreate) {
          onCreate();
        } else {
          const result = dispatch(updateFormatter()).then(response => response.meta.requestStatus === 'fulfilled');
          if (!result) setMode(MODE_OPTIONS.EDIT);
        }
      };

      /**
       * On Cancel button Click
       */
      const onCancel = (): void => {
        if (checkCreate) {
          navigate('/formatters/dashboard');
        } else {
          const formatter = activeFormatter;
          if (formatter.id) {
            dispatch(getFormatter({ id: formatter.id }));
          }
        }
      };

      return (
        <DetailsControlButtons
          mode={mode}
          setMode={setMode}
          checkDisabled={checkDisabled()}
          onCancel={onCancel}
          onSave={onSave}
        />
      );
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <FormatterHeaderButtons />,
    };

    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Formatters',
        url: '/formatters/dashboard',
      },
      {
        title: activeFormatter.name as string,
      },
    ];

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <FormatterContent
        mode={mode}
        checkCreate={checkCreate}
      />
    </PageLayout>
  );
};

export default CreatorViewer;
