export const getArrayTagsHtmlString = (str: string) => {
  str = str.replace(/[\n,]/g, '');
  let htmlSplit = str.split('>');
  let arrayElements: any[] = [];
  let nodeElement = '';
  htmlSplit.forEach(element => {
    element = element.trim();
    if (element.includes('<')) {
      nodeElement = element + '>';
    } else {
      nodeElement = element;
    }
    arrayElements.push(nodeElement);
  });
  return arrayElements;
};

export const labelResizeObserver = (containerId: string, setShow: (value: boolean) => void) => {
  // Label popup control when label is truncated with an ellipsis
  const element = document.getElementById(containerId);
  if (element) {
    const resizeObserver = new ResizeObserver(() => {
      const labelElement = element?.children[0];
      const labelSpan = labelElement?.children[0];
      const labelSpanWidth = labelSpan?.clientWidth || 0;
      const labelSpanScrollWidth = labelSpan?.scrollWidth || 0;

      if (labelSpanScrollWidth > labelSpanWidth) {
        setShow(true);
      } else {
        setShow(false);
      }
    });

    resizeObserver.observe(element!);
  }
};
