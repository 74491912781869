export enum Colors {
  WHITE = '#FFFFFF',
  GRAY = '#EEEEEE',
  GRAY_VERY_LIGHT = '#F5F5F5',
  GRAY_LIGHT_MAGENTA = '#E1E0E1',
  GREEN = '#0FB239',
  GREEN_LIME = '#C3ECCD',
  GREEN_DARK = '#E2F7E8',
  YELLOW = '#F6DBA6',
  YELLOW_STRONG = '#C6B307',
  YELLOW_BRIGHT = '#EFD90F',
  BLUE = '#DFE8F6',
  BLUE_LIGHT = '#2C66C0',
  BLUE_DARK_GRAYISH = '#9BA2AC',
  BLUE_VERY_DARK_GRAYISH = '#4F5C6E',
  BLUE_VERY_DARK = '#041630',
  VIOLET = '#EAD0EA',
  RED = '#ff0000',
  RED_VERY_SOFT = '#E94D58',
  RED_PALE = '#FFD8DB',
  RED_SOFT = '#ED7179',
  RED_MODERATE_DARK = '#B23F47',
  ORANGE = '#E69701',
  ORANGE_LIGHT = '#FBEBCC',
  ORANGE_STRONG = '#C48205',
  ORANGE_SOFT = '#EFBD5D',
  ORANGE_VERY_SOFT = '#FF6347',
  CYAN_DARK = '#0D812B',
  CYAN_MODERATE = '#65CD81',
  MAGENTA_DESATURATED = '#C885C8',
  PINK = '#F4A6AB',
}

export enum ColorNames {
  DEFAULT = 'default',
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  GRAY = 'gray',
  GREY = 'grey',
  LIGHT_GREY = 'light-gray',
  PURPLE = 'purple',
  DARK_ORANGE = 'dark-orange',
  TOMATO = 'tomato',
  BLACK = 'black',
}
