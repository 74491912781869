import { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetProvisionUse } from 'store/provisions/provisionsListingSlice';
import ProvisionPolicyUse from 'common/model/ProvisionPolicyUse';
import ProvisionTransactionUse from 'common/model/ProvisionTransactionUse';
import { checkProvisionUse } from 'common/api/provisions';
import './ProvisionUse.scss';

interface ProvisionUseTemplateProps {
  displayAsRow?: boolean;
  provision_id: string;
}

const ProvisionUseTemplate = ({ displayAsRow, provision_id }: ProvisionUseTemplateProps) => {
  const { provisionUse, provisionUseLoading } = useAppSelector((state: RootState) => state.provisionsListing);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (provision_id) dispatch(checkProvisionUse({ id: provision_id }));

    return () => {
      dispatch(resetProvisionUse());
    };
  }, [provision_id]);

  return (
    <div className={`provisions-use-display ${displayAsRow && 'display_in_row'}`}>
      {provisionUseLoading ? (
        <div className="loader-container">
          <Loader
            active={true}
            size="big"
          />
        </div>
      ) : (
        <>
          <div className="list-column m-t-none">
            <div className="table-title m-b-xs">
              <h3 className="m-r-xs">Policy</h3> used in &nbsp;
              {provisionUse.policies.length} policies
            </div>
            {provisionUse.policies.length > 0 && (
              <div className="table-wrapper">
                <table className="ui table dashboard-table provision-use">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Policy name</th>
                      <th>Landlord name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {provisionUse.policies.map((policy: ProvisionPolicyUse, index: number) => (
                      <tr key={index}>
                        <td>{1 + index}</td>
                        <td>{policy.name}</td>
                        <td>{policy.landlord.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="list-column m-t-sm">
            <div className="table-title m-b-xs">
              <h3 className="m-r-xs">Transaction</h3> used in &nbsp;
              {provisionUse.transactions.length} transactions
            </div>
            {provisionUse.transactions.length > 0 && (
              <div className="table-wrapper">
                <table className="ui table dashboard-table provision-use">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Transaction identifier</th>
                      <th>Landlord name</th>
                      <th>Policy name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {provisionUse.transactions.map((transaction: ProvisionTransactionUse, index: number) => (
                      <tr key={index}>
                        <td>{1 + index}</td>
                        <td>{transaction.identifier}</td>
                        <td>{transaction.landlord.name}</td>
                        <td>{transaction.policy.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProvisionUseTemplate;
