import { IEntityClone } from 'common/_classes/entities';
import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import FilesUpload from 'atoms/FormField/FilesUpload';
import TabContainer from 'components/TabContainer';
import { addSupportingFiles, deleteSupportingFiles } from 'store/entities/entityDetailSlice';
import { EntityCreatorTypes, prepEntityDocsForUpload } from 'common/api/entities';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import ContactInformationForm from './ContactInformationForm';
import EntityGeneralForm from './EntityGeneralForm';
import EntityInformationTabToolbar from './EntityInformationTabToolbar';
import EntityLegalForm from './EntityLegalForm';

interface ContentProps {
  type: EntityCreatorTypes;
  mode: MODE_OPTIONS;
  entityData: IEntityClone;
  isChild?: boolean;
}

const Content = ({ mode, type, entityData, isChild }: ContentProps) => {
  const documents = entityData?.documents ?? [];
  // const {
  //   activeEntity: { documents },
  // } = useAppSelector((state: RootState) => state.entityDetail);
  // const { selectedSubsidiary} = useAppSelector((state: RootState) => state.entityDetail);
  return entityData ? (
    <Form>
      <Grid>
        <Grid.Row className="pm-none">
          <Grid.Column
            width={8}
            className="pm-none p-r-sm"
          >
            <SectionComponent
              title="General information"
              className="m-b-xl"
            >
              <EntityGeneralForm
                mode={mode}
                type={type}
                entityData={entityData}
                isChild={isChild}
              />
            </SectionComponent>
            <SectionComponent title="Contact information">
              <ContactInformationForm
                mode={mode}
                entityData={entityData}
                isChild={isChild}
              />
            </SectionComponent>
          </Grid.Column>
          <Grid.Column
            width={8}
            className="pm-none p-l-sm"
          >
            <SectionComponent title="Legal information">
              <EntityLegalForm
                mode={mode}
                entityData={entityData}
              />
            </SectionComponent>
            <SectionComponent
              title="Files to upload"
              className="m-t-xl"
            >
              <Grid>
                <Grid.Row className="pm-none">
                  <Grid.Column
                    width={10}
                    className="pm-none"
                  >
                    <FilesUpload
                      disabled={mode === MODE_OPTIONS.READ}
                      prepUpload={prepEntityDocsForUpload}
                      documents={documents as any}
                      onDelete={deleteSupportingFiles}
                      onAdd={addSupportingFiles}
                      onAddFunctionParams={{}}
                      prepUploadParams={{}}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  ) : (
    <div>Could not load entity data</div>
  );
};

interface EntityInformationTabProps {
  title?: React.ReactElement;
  organisation?: boolean;
  type: EntityCreatorTypes;
  isChild?: boolean;
}

const EntityInformationTab = ({ title, organisation, type, isChild = false }: EntityInformationTabProps) => {
  const [mode, setMode] = useState<MODE_OPTIONS>(MODE_OPTIONS.READ);
  const tabTitle: string = organisation ? 'Organisation Profile' : 'Entity Information';
  const { activeEntity, selectedSubsidiary } = useAppSelector((state: RootState) => state.entityDetail);
  const entityData = isChild ? selectedSubsidiary : activeEntity;

  return entityData ? (
    <TabContainer
      tabTitle={title ?? tabTitle}
      tabToolbar={
        <EntityInformationTabToolbar
          mode={mode}
          setMode={setMode}
          entityData={entityData}
        />
      }
      mode={mode}
    >
      {/* Mode prop is present */
      /* @ts-ignore */}
      <Content
        type={type}
        entityData={entityData}
        isChild={isChild}
      />
    </TabContainer>
  ) : (
    <Message
      severity="warn"
      text="Could not load entity data"
      className="m-t-sm"
    />
  );
};

export default EntityInformationTab;
