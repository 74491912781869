import { MultiSelectChangeEvent } from 'primereact/multiselect';
import MultiSelect from 'atoms/FormField/MultiSelect';
import { EventDropdownOption } from 'utils/types/stackingPlan';
import './ContractEventDropdown.scss';

interface ContractEventDropdownProps {
  className?: string;
  options: EventDropdownOption[];
  placeholder: string;
  onChange: Function;
  value: string[];
  dataTest: string;
}
const ContractEventDropdown = ({
  className,
  options,
  onChange,
  value,
  placeholder,
  dataTest,
}: ContractEventDropdownProps) => {
  const ContractEventTemplate = (eventOption: EventDropdownOption) => {
    return (
      <span
        className={'contract-event-dropdown-label ' + eventOption.className}
        data-test={'event-filter-item-' + eventOption.value}
      >
        {eventOption.label}
      </span>
    );
  };

  return (
    <div className={'contract-event-dropdown border-sm-gray-light ' + className}>
      <MultiSelect
        value={value}
        options={options}
        data-test={dataTest}
        dataKey="value"
        onChange={(event: MultiSelectChangeEvent) => onChange(event.value)}
        maxSelectedLabels={1}
        itemTemplate={ContractEventTemplate}
        placeholder={placeholder}
      />
    </div>
  );
};

export default ContractEventDropdown;
