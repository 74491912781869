import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createLink } from 'apollo-absinthe-upload-link';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

// TODO: The functionality below has been disabled. Revist this before going live:
// URL: https://www.apollographql.com/docs/react/api/link/apollo-link-http/#fetchoptions
/*
 * NOTE: fetchOptions: An object that can be used to configure the behavior of the fetch() function.
 * This object is passed directly to fetch() when making a network request.
 * The mode you want to use for the request, e.g., cors, no-cors, or same-origin.
 * See all options available here: https://developer.mozilla.org/en-US/docs/Web/API/fetch#mode
 * Further reading: https://stackoverflow.com/questions/44121783/fetch-api-default-cross-origin-behavior
 */

// check node env to see whether we are in development or production
// const isProd = process.env.NODE_ENV === 'production';

// If in prod, enable CORS
const httpLinkProps = { uri: process.env.REACT_APP_LANDLORD_API_URL };
// const httpLinkProps = isProd
//   ? { uri: process.env.REACT_APP_LANDLORD_API_URL }
//   : { uri: process.env.REACT_APP_LANDLORD_API_URL, fetchOptions: { mode: 'no-cors' } };

const adminHttpLinkProps = { uri: process.env.REACT_APP_LANDLORD_ADMIN_API_URL };
// const adminHttpLinkProps =   isProd
//   ? { uri: process.env.REACT_APP_LANDLORD_ADMIN_API_URL }
//   : { uri: process.env.REACT_APP_LANDLORD_ADMIN_API_URL, fetchOptions: { mode: 'no-cors' } };

const httpLink = createLink(httpLinkProps);

const adminHttpLink = createLink(adminHttpLinkProps);

// For testing file upload locally
const stagingHttpLink = createLink({
  uri: process.env.REACT_APP_LANDLORD_STAGING_API_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('sericin_app_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// For checking File Upload locally
const authLinkStaging = setContext((_, { headers }) => {
  // Add the token you have on staging
  const token = '';
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions,
});

const adminClient = new ApolloClient({
  link: authLink.concat(adminHttpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions,
});

// Connection to staging for testing file upload locally
const stagingClient = new ApolloClient({
  link: authLinkStaging.concat(stagingHttpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions,
});

export { client, adminClient, stagingClient };
