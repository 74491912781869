import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import TabContainer from 'components/TabContainer';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import SetupTabToolbar from './SetupTabToolbar';
import TransactionSetupContent from './TransactionSetupContent';
import './SetupTab.scss';

const SetupTab = (): JSX.Element => {
  const location = useLocation();

  const checkCreate: boolean = location.pathname.includes('create');
  const pageTypeCheck: MODE_OPTIONS = checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ;
  const [mode, setMode] = useState<MODE_OPTIONS>(pageTypeCheck);

  return (
    <TabContainer
      tabToolbar={
        <SetupTabToolbar
          checkCreate={checkCreate}
          mode={mode}
          setMode={setMode}
        />
      }
      mode={mode}
      tabTitle="Setup"
    >
      <TransactionSetupContent mode={mode} />
    </TabContainer>
  );
};

export default SetupTab;
