import { Input } from 'semantic-ui-react';
import { InputFieldType } from 'atoms/FormField/Input';
import { updateFloorPlanInputProps } from 'common/api/properties';
import { numberInputValidator } from 'utils/utils-clean-input';
import { addSpaceOrComma, removeSpaceOrComma } from 'utils/utils-number';

interface AreaProps {
  id: string;
  label: string;
  disabled: boolean;
  required?: boolean;
  updateFloorPlanInput: updateFloorPlanInputProps;
  value: string | number | null;
  fieldKey: string;
  dataTest?: string;
}

const Area = ({ id, label, disabled, required, updateFloorPlanInput, value, fieldKey, dataTest }: AreaProps) => {
  return (
    <div className="d-flex justify-space-between align-center w-100 area-sub-component">
      <span>
        {label} {required && <span className="required">*</span>}
      </span>

      <Input
        size="small"
        value={value === null ? '' : addSpaceOrComma(value, false)}
        disabled={disabled}
        readOnly={disabled}
        data-test={dataTest}
        type={InputFieldType.TEXT}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, true, false, value)}
        onChange={(e, { value }) => updateFloorPlanInput(fieldKey, removeSpaceOrComma(value), id)}
      />
    </div>
  );
};

export default Area;
