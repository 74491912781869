import ParamRefOffset from 'common/model/ParamRefOffset';
import ParamRefPosition from 'common/model/ParamRefPosition';

export interface ParamRefInput {
  index: number | null;
  offset?: ParamRefOffset;
  parameterId: string | null;
  position?: ParamRefPosition;
  tableId: string | null;
}

export enum EventHandlerNode {
  NONE = 'NONE',
  CREATE_PARAMETER = 'CREATE_PARAMETER',
  EDIT_PARAMETER = 'EDIT_PARAMETER',
  UPDATE_CONDITION = 'UPDATE_CONDITION',
  REMOVE_CONDITION = 'REMOVE_CONDITION',
}
