import { Popup } from 'semantic-ui-react';
import { AttachedUsersToolTipType } from 'utils/types/userManagement/adminEntities';
import AttachedUsersListing from './AttachedUsersListing';

const AttachedUsersToolTip = ({ trigger, id, disabled }: AttachedUsersToolTipType) => (
  <Popup
    content={<AttachedUsersListing id={id} />}
    position="bottom left"
    className="attached-users-tooltip border-none bg-white"
    trigger={trigger}
    hoverable={true}
    basic={true}
    disabled={disabled}
  />
);

export default AttachedUsersToolTip;
