import ValuationDataPremises2 from 'common/model/ValuationDataPremises2';
import { CValuationDataFloor } from './ValuationDataFloor';

export class CValuationDataPremises2 {
  id: string;
  buildingName: string;
  floors: CValuationDataFloor[];

  constructor(input: ValuationDataPremises2) {
    this.id = input.id;
    this.buildingName = input.buildingName;
    this.floors = input.floors.map(floor => new CValuationDataFloor(floor));
  }

  static fragment() {
    return `
        id
        buildingName
        floors {
            ${CValuationDataFloor.fragment()}
        }
    `;
  }
}
