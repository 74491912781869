import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ProvisionCategoryDetailClone } from 'common/_classes';
import { RootState } from 'store';
import { convertToProvisionCategoryInput } from 'common/api/provisions';

interface CreateProvisionCategoryQueryVariables {
  params: ProvisionCategoryDetailClone;
}

const CREATE_PROVISION_CATEGORY = (activeCategory: ProvisionCategoryDetailClone) => {
  const variables: CreateProvisionCategoryQueryVariables = {
    params: convertToProvisionCategoryInput(activeCategory),
  };

  return {
    mutation: gql`
      mutation ($params: ProvisionCategoryInput!) {
        createProvisionCategory(params: $params) {
          ${ProvisionCategoryDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createProvisionCategory = createAsyncThunk(
  'provisions/createProvisionCategory',
  async (_args, { getState }) => {
    const {
      provisionsListing: { activeCategory },
    } = getState() as RootState;
    const response = await adminClient.mutate(CREATE_PROVISION_CATEGORY(activeCategory));
    return response;
  },
);
