import { Message } from 'primereact/message';
import { useErrorBoundary } from 'react-error-boundary';
import { Button, ButtonGroup } from 'semantic-ui-react';
import puzzeled from 'assets/images/svg/et-puzzle.svg';
import './ErrorFallback.scss';

interface ErrorFallbackProps {
  error: Error;
}

const ErrorFallback = ({ error }: ErrorFallbackProps) => {
  const { resetBoundary } = useErrorBoundary();
  const messagesExists = error.message !== undefined;

  return (
    <div className="error-fallback flex justify-content-center flex-wrap">
      <div className="flex align-items-center justify-content-center w-50rem h-50rem font-bold border-round m-2">
        <div className="flex justify-content-center flex-wrap">
          <div className="flex align-items-center justify-content-center font-bold border-round m-2 flex-column">
            <img
              src={puzzeled}
              alt="We're puzzled"
              width="20%"
            />
            <br />
            <h3 className="font-orange">Something unexpected occured.</h3>
            <br />
            <p>We have logged the issue and our engineers will be looking into it.</p>
            <br />
            {messagesExists && (
              <Message
                severity="warn"
                text={error.message}
              />
            )}
            <br />
            <br />
            <ButtonGroup
              basic
              size="small"
            >
              <Button
                as="a"
                href="/"
                color="black"
              >
                Home
              </Button>
              <Button onClick={resetBoundary}>Try again</Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
