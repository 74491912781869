import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import OccupancyRateGraph from 'components/Graphs/OccupancyRate';
import OccupancyVarianceGraph from 'components/Graphs/OccupancyVariance';
import RevenuePerformanceVsForecastGraph from 'components/Graphs/RevenuePerfomanceVsForecast';
import RevenueVarianceGraph from 'components/Graphs/RevenueVariance';
import { DASHBOARD_PAGE_TABS } from 'views/dashboard/DashboardTabConfig';
import Scale from 'common/model/Scale';

const ForecastingGraphs = () => {
  const { propertyRevenueAnalysis, period, isLoading, portfolioRevenueAnalysis } = useAppSelector(
    (state: RootState) => state.forecasts,
  );
  const { context } = useAppSelector((state: RootState) => state.dashboard);

  return (
    <>
      <RevenuePerformanceVsForecastGraph
        propertyRevenueAnalysis={propertyRevenueAnalysis}
        portfolioRevenueAnalysis={portfolioRevenueAnalysis}
        period={period as Scale}
        isLoading={isLoading}
        tab={DASHBOARD_PAGE_TABS.FORECASTING}
        context={context}
      />

      <RevenueVarianceGraph
        propertyRevenueAnalysis={propertyRevenueAnalysis}
        portfolioRevenueAnalysis={portfolioRevenueAnalysis}
        period={period as Scale}
        isLoading={isLoading}
        tab={DASHBOARD_PAGE_TABS.FORECASTING}
        context={context}
      />

      <OccupancyRateGraph
        propertyRevenueAnalysis={propertyRevenueAnalysis}
        portfolioRevenueAnalysis={portfolioRevenueAnalysis}
        period={period as Scale}
        isLoading={isLoading}
        tab={DASHBOARD_PAGE_TABS.FORECASTING}
        context={context}
      />

      <OccupancyVarianceGraph
        propertyRevenueAnalysis={propertyRevenueAnalysis}
        portfolioRevenueAnalysis={portfolioRevenueAnalysis}
        period={period as Scale}
        isLoading={isLoading}
        tab={DASHBOARD_PAGE_TABS.FORECASTING}
        context={context}
      />
    </>
  );
};

export default ForecastingGraphs;
