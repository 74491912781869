import MParameterCategory from 'common/model/ParameterCategory';

export type TParameterCategoryOnlyId = Pick<MParameterCategory, 'id'>;
export type TParameterCategoryPickName = Pick<MParameterCategory, 'id' | 'name'>;
export type TParameterCategoryBase = Pick<MParameterCategory, 'id' | 'name' | 'index' | 'color'>;

export class ParameterCategoryBase implements TParameterCategoryBase {
  id: string;
  name: string;
  color: string;
  index: number;

  constructor(input: TParameterCategoryBase) {
    this.id = input.id;
    this.name = input.name;
    this.color = input.color;
    this.index = input.index;
  }

  static fragments(): string {
    return `
            id
            name
            color
            index
        `;
  }
}
