import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import RoleModal from 'views/userAccountManagement/components/TeamModals/RoleModal';
import { onEditDetails } from 'store/roles/rolesSlice';
import { deleteRole } from 'common/api/roles';
import { ColorNames } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { RolesRow } from './RolesTableConfig';

interface RolesRowActionButtonParams {
  row: RolesRow;
}

export const RolesRowActionButtons = ({ row }: RolesRowActionButtonParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = (id: string) => {
    dispatch(deleteRole({ id }));
  };

  const onEdit = (id: string) => {
    dispatch(onEditDetails({ id }));
  };

  const buttons: TableActionButton[] = [
    {
      template: (
        <RoleModal
          trigger={
            <button
              className="mini compact ui button"
              data-tooltip="Edit Details"
              data-position="left center"
              onClick={() => onEdit(row.id)}
              data-test="edit-details-button"
            >
              <Icon
                icon={Icons.Pencil}
                className={ColorNames.GREY}
              />
            </button>
          }
          createMode={false}
        />
      ),
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete',
      onClick: () => onDelete(row.id),
      isPopup: true,
      popupText: 'Do you really want to delete this Role?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
