import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { CContractEvent, Contract2EventsInputClone } from 'common/_classes/contractsV2';
import { RootState } from 'store';
import _ from 'lodash';

export const CREATE_CONTRACT_EVENT = (contractId: string, params: any) => {
  if (_.isEmpty(contractId)) {
    throw new Error('Create Contract Event: Contract ID could not be determined. Query aborted.');
  }

  if (_.isEmpty(params)) {
    throw new Error('Create Contract Event: No contract event provided. Query aborted.');
  }

  const currentLandlordEntityId = params?.ownerId;

  if (_.isEmpty(currentLandlordEntityId)) {
    throw new Error('Create Contract Event: Landlord Entity could not be determined. Query aborted.');
  }

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $contractId: ID!, $params: Contract2EventsInput!) {
        createContractEvent(currentLandlordEntityId: $currentLandlordEntityId, contractId: $contractId, params: $params) {
          ${CContractEvent.fragment()}
        }
      }
    `,
    variables: {
      currentLandlordEntityId,
      contractId,
      params,
    },
  };
};

export const createContractEvent = createAsyncThunk(
  'contractDetailV2/createContractEvent',
  async (contractId: string, { getState }) => {
    const {
      contractDetailV2: { activeContractEvent },
    } = getState() as RootState;

    const params = await CContractEvent.formatForAPI(activeContractEvent);

    const response = await client.mutate(CREATE_CONTRACT_EVENT(contractId, params));

    return response;
  },
);
