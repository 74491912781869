import { Button, Modal } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './ExportModal.scss';

interface SuccessContentProps {
  close: (isClosed: boolean) => void;
}

const SuccessContent = ({ close }: SuccessContentProps) => {
  return (
    <Modal.Content className="border-xl-green">
      <Modal.Description>
        <h4 className="title">Success!</h4>
        <Icon icon={Icons.Success} />
      </Modal.Description>

      <div className="text">Report has been generated and automatically downloaded.</div>
      <Button
        fluid={true}
        className="btn grey-bg"
        onClick={() => close(false)}
      >
        OK
      </Button>
    </Modal.Content>
  );
};

export default SuccessContent;
