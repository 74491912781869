import { useCallback, useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { InputFieldType } from 'atoms/FormField/Input';
import { handlePaste } from 'utils/tsHelper';
import { numberInputValidator } from 'utils/utils-clean-input';
import { addSpaceOrComma } from 'utils/utils-number';
import { generateUniqueId } from 'utils/utils-random';

interface AreaInputProps {
  index: number;
  area: number;
  updateSpaceDetail: Function;
  isEditable: boolean;
  fieldKey: string;
  columnName: string;
  dataTest: string;
}

const AreaInput = ({
  index,
  area,
  updateSpaceDetail,
  isEditable,
  fieldKey,
  columnName,
  dataTest,
}: AreaInputProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const id = generateUniqueId('area');
  const [pasteEventAdded, setPasteEventAdded] = useState<boolean>(false);

  const onChange = useCallback(
    (value: string) => {
      dispatch(
        updateSpaceDetail({
          key: fieldKey,
          value,
          spaceIndex: index,
        }),
      );
    },
    [index, dispatch, updateSpaceDetail, fieldKey],
  );

  useEffect(() => {
    const pasteHandler = (e: ClipboardEvent) => {
      handlePaste(e, onChange);
    };
    const inputField = document.getElementById(id);
    if (inputField) {
      if (isEditable && !pasteEventAdded) {
        setPasteEventAdded(true);
        inputField.addEventListener('paste', pasteHandler);
      }

      return () => {
        inputField.removeEventListener('paste', pasteHandler, true);
      };
    }
  }, [isEditable, id, pasteEventAdded, onChange]);

  const getFormatted = (value: number) => {
    return isEditable ? value : addSpaceOrComma(value, true);
  };

  const getType: InputFieldType = isEditable ? InputFieldType.NUMBER : InputFieldType.TEXT;
  const value = area === null ? '' : getFormatted(area);

  return (
    <Input
      id={id}
      index={index}
      value={value}
      data-test={dataTest}
      placeholder="-"
      type={getType}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, false, false, value)}
      onChange={({ target: { value } }) => onChange(value)}
      columnname={columnName}
    />
  );
};

export default AreaInput;
