import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import TabContainer from 'components/TabContainer';
import { resetFileUploader } from 'store/transactions/transactionDetailSlice';
import { HistoryDocumentsProps } from 'common/api/transactions';
import DocHistory from './Tabs/Doc';
import SignedDocumentModal from './Tabs/Doc/SignedDocumentModal';
import './History.scss';

const History = () => {
  const dispatch = useAppDispatch();

  const {
    activeTransaction: { historyGeneral, historyDocuments },
  } = useAppSelector((state: RootState) => state.transactionDetail);
  const { documentTypesList } = useAppSelector(({ miscellaneous }) => miscellaneous);
  const [activeTabIndex, setActiveTab] = useState(0 as any);

  const onTabUpdate = (activeIndex: number | string | undefined) => {
    dispatch(resetFileUploader());
    setActiveTab(activeIndex);
  };

  const checkSetup: boolean = historyGeneral.length > 1;
  const historyDocumentIds: string[] = historyDocuments.map((obj: HistoryDocumentsProps) => obj.documentTypeId);

  const approvalTabMenu: TabMenuProps[] = historyDocumentIds.map((documentTypeId: string) => {
    const match = documentTypesList.find(documentType => documentType.id === documentTypeId)!;

    return {
      key: `history-tab-${match.id}`,
      label: match.name,
      customTab: checkSetup ? (
        <DocHistory
          documentTypeId={match.id}
          title={match.name}
        />
      ) : (
        <></>
      ),
    };
  });

  return (
    <>
      {approvalTabMenu.length !== 0 && (
        <TabContainer tabTitle="History">
          <TabReact
            className="history-tab"
            activeTabIndex={activeTabIndex}
            onTabChange={(activeIndex: number) => onTabUpdate(activeIndex)}
            tabMenu={approvalTabMenu}
            nested={true}
          />
          <SignedDocumentModal />
        </TabContainer>
      )}
    </>
  );
};

export default History;
