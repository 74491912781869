import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { EDITOR_INFORMATION_TABS_OFFSET } from 'components/Editor/EditorSideMenu';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import { ViewProps, listDocumentTypes } from 'common/api/miscellaneous';
import { listProvisionCategories } from 'common/api/provisions';
import { getProvision } from 'common/api/provisions';
import ProvisionTabs from './Tabs/ProvisionTabs';

const ProvisionCreatorViewer = ({ isViewPage }: ViewProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { activeProvision, isLoading, activeTabIndex } = useAppSelector((state: RootState) => state.provisionDetail);
  const { categoriesLoading } = useAppSelector((state: RootState) => state.provisionsListing);

  useEffect(() => {
    if (isViewPage) {
      dispatch(getProvision({ id: id || '' }));
      dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.NONE));
    }

    dispatch(listProvisionCategories());
    dispatch(listDocumentTypes({ first: 100 }));
  }, [id]);

  const isEditorTab = activeTabIndex === 1;

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Provisions',
        url: '/provisions/dashboard',
      },
      {
        title: activeProvision.name,
      },
    ];

    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading || categoriesLoading}
      flexHeight={isEditorTab}
      className="provisions-create-view"
      pageHeader={getPageHeaderProps()}
    >
      <ProvisionTabs />
    </PageLayout>
  );
};

export default ProvisionCreatorViewer;
