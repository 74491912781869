import Decimal from 'decimal.js';
import LandlordApprovalType from 'common/model/LandlordApprovalType';
import RightRestriction from 'common/model/RightRestriction';
import SubletRights from 'common/model/SubletRights';
import SubletRightsInput from 'common/model/SubletRightsInput';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';

export interface SubletRightsInputClone
  extends Omit<
    SubletRightsInput,
    'wholeOrPart' | 'premisePercentage' | 'restrictedTo' | 'landlordApproval' | 'landlordProfitShare'
  > {
  wholeOrPart: WholeOrPartOptions | null;
  premisePercentage: Decimal | null;
  restrictedTo: RightRestriction | null;
  landlordApproval: LandlordApprovalType | null;
  landlordProfitShare: Decimal | null;
}

export class CSubletRights implements SubletRightsInputClone {
  wholeOrPart: WholeOrPartOptions | null;
  premisePercentage: Decimal | null;
  restrictedTo: RightRestriction | null;
  landlordApproval: LandlordApprovalType | null;
  landlordProfitShare: Decimal | null;

  constructor(input: SubletRights | null) {
    this.wholeOrPart = input ? input.wholeOrPart : null;
    this.premisePercentage = input && input.premisePercentage ? new Decimal(input.premisePercentage) : null;
    this.restrictedTo = input ? input.restrictedTo : null;
    this.landlordApproval = input ? input.landlordApproval : null;
    this.landlordProfitShare = input && input.landlordProfitShare ? new Decimal(input.landlordProfitShare) : null;
  }

  static fragment() {
    return `
        wholeOrPart
        premisePercentage
        restrictedTo
        landlordApproval
        landlordProfitShare
    `;
  }

  static formatForAPI(right: CSubletRights) {
    return {
      wholeOrPart: right.wholeOrPart ? right.wholeOrPart : null,
      premisePercentage: right.premisePercentage ? parseFloat(right.premisePercentage.toString()) : null,
      restrictedTo: right.restrictedTo ? right.restrictedTo : null,
      landlordApproval: right.landlordApproval ? right.landlordApproval : null,
      landlordProfitShare: right.landlordProfitShare ? parseFloat(right.landlordProfitShare.toString()) : null,
    };
  }
}
