import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DropdownSelect from 'atoms/DropdownSelect';
import SearchFilter from 'components/SearchFilter';
import { updateParameterView, updateSearch } from 'store/parameters/parametersListingSlice';
import { PARAMETERS_DASHBOARD_VIEW_OPTIONS } from 'common/api/miscellaneous';
import { listParameterCategoriesAndGroups, listParameterTablesPage, listParameters } from 'common/api/parameters';
import { DROPDOWN_OPTION } from 'utils/UI';

interface PARAMETER_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: PARAMETERS_DASHBOARD_VIEW_OPTIONS;
}

export const PARAMETER_VIEW_OPTIONS: PARAMETER_TYPE_OPTION[] = [
  { key: 1, text: 'List: Group', value: PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP },
  { key: 2, text: 'List: Parameters', value: PARAMETERS_DASHBOARD_VIEW_OPTIONS.PARAMETER },
  { key: 3, text: 'List: Tables of parameters', value: PARAMETERS_DASHBOARD_VIEW_OPTIONS.TABLE },
];

const ParametersHeaderToolbar = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { search, paramView } = useAppSelector((state: RootState) => state.parametersListing);
  const { paramViewSelectedGroup } = useAppSelector((state: RootState) => state.parameterDetail);

  /* prettier-ignore */
  const getDataFunction: Function | null =
    paramView === PARAMETERS_DASHBOARD_VIEW_OPTIONS.PARAMETER ? listParameters :
    paramView === PARAMETERS_DASHBOARD_VIEW_OPTIONS.TABLE     ? listParameterTablesPage :
                                                                listParameterCategoriesAndGroups;

  const onChangeView = (value: PARAMETERS_DASHBOARD_VIEW_OPTIONS): void => {
    dispatch(updateParameterView({ type: value }));
  };

  const onGetData = (value?: string) => {
    return getDataFunction({ searchValue: value, first: 10 });
  };

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      <DropdownSelect
        // TODO: Height need to move inside SelectField if possible
        className={`height-2_429`}
        dataTest="parameter-listing-view-select"
        value={paramView}
        options={
          paramViewSelectedGroup === null
            ? PARAMETER_VIEW_OPTIONS
            : PARAMETER_VIEW_OPTIONS.filter(
                (option: PARAMETER_TYPE_OPTION) => option.value !== PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP,
              )
        }
        onChange={(key, value) => onChangeView(value as PARAMETERS_DASHBOARD_VIEW_OPTIONS)}
      />
    </>
  );
};

export default ParametersHeaderToolbar;
