import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import Notification from 'common/model/Notification';
import { listNotifications } from 'common/api/notifications';
import { WebSocketNotification } from 'utils/types/websocket';
import { useChannel } from 'utils/useChannel';
import { checkIfAdminURL } from 'utils/utils-admin';
import { Icons } from 'utils/utils-icons';
import { shortenNumber } from 'utils/utils-number';
import './NotificationBell.scss';

interface NotificationWebsocket {
  archive: boolean;
  date: Date;
  entity_id: string | null;
  id: string | null;
  info_approval: {
    history_id: string | null;
    id: string | null;
    transaction_id: string | null;
  };
  info_sender: {
    entity_id: string | null;
    id: string | null;
    user_id: string | null;
  };
  inserted_at: Date;
  seen: boolean;
  type: string;
  updated_at: Date;
  user_id: string | null;
}

const NotificationBell = () => {
  const [notificationId, setNotificationId] = useState<string | null>(null);
  const url = '/profile?tab=notifications';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { notifications } = useAppSelector((state: RootState) => state.notifications);
  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const unreadNotifications: Notification[] = notifications.filter((notification: Notification) => !notification.seen);
  const unreadCount: number = unreadNotifications.length;

  const onClick = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(url);
  };

  if (!checkIfAdminURL()) {
    const channelName = userId ? `user:${userId}` : null;

    // Connect to user channel return null if null
    const userChannel = useChannel(channelName, undefined, (_channel: Object, _messages: Object) => {});

    // Websocket: check if any new notification was created
    //  => load notifications from the back-end
    //  => update the notification bell counter
    //  => update the list of notifications to be displayed in the notification page
    useEventHandler(userChannel, WebSocketNotification.USER_NOTIFICATION, (notification: NotificationWebsocket) => {
      if (notification.id !== notificationId) {
        toast('New notification is available.');
        // To handle dual pop-up case
        setNotificationId(notification.id);
        dispatch(listNotifications({ first: 1000, loader: false }));
      }
    });
  }

  const { shortenedNumber: count, suffix } = shortenNumber(unreadCount);

  return (
    <a
      className="nav-notication-container"
      href={url}
      onClick={onClick}
    >
      {unreadCount > 0 && (
        <span className="count">
          {count}
          {suffix}
        </span>
      )}
      <Icon
        className="bell-icon"
        data-test="notification-icon"
        icon={Icons.SolidBell}
      ></Icon>
    </a>
  );
};

export default NotificationBell;
