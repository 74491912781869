import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

export interface UserWithPasswordInput {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
}

interface RegisterInvitedUserQueryVariables {
  token: string | null;
  params: UserWithPasswordInput;
}

export const REGISTER_INVITED_USER = ({ token, params }: RegisterInvitedUserQueryVariables) => {
  if (!token) throw new Error('Registered Invited User: Token ID is missing');

  const variables: RegisterInvitedUserQueryVariables = {
    token,
    params,
  };

  return {
    mutation: gql`
      mutation ($token: String!, $params: UserWithPasswordInput!) {
        registerInvitedUser(token: $token, params: $params) {
          email
          firstName
          id
          lastName
          countryCode
          phoneNumber
          photoUrl
        }
      }
    `,
    variables,
  };
};

export const registerInvitedUser = createAsyncThunk(
  'auth/registerInvitedUser',
  async ({ token }: { token: string | null }, { getState }) => {
    const {
      auth: { firstName, lastName, email, newPassword, newPasswordConfirmation },
    } = getState() as RootState;
    const userData: UserWithPasswordInput = {
      firstName,
      lastName,
      email,
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
    };
    const response = await client.mutate(REGISTER_INVITED_USER({ token, params: userData }));
    return response;
  },
);
