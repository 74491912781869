import ComparablesTable from 'components/ComparablesTable';
import TabContainer from 'components/TabContainer';

const ValuationTab = ({ contractId }: { contractId: string }): JSX.Element => {
  return (
    <TabContainer tabTitle="Comparables">
      <ComparablesTable contractId={contractId} />
    </TabContainer>
  );
};

export default ValuationTab;
