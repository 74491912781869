import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { deleteTranslateToContract, listTranslateToContracts } from 'common/api/translatorsToContract';
import { TranslateToContractClone } from 'common/api/translatorsToContract';

interface TranslatorsListingState {
  translateToContractList: TranslateToContractClone[];
  isLoading: boolean;
  search: string | null;
  isSearching: boolean;
}

const initialState: TranslatorsListingState = {
  translateToContractList: [],
  isLoading: false,
  search: null,
  isSearching: false,
};

const translatorsListingSlice = createSlice({
  name: 'translatorsListing',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    resetTranslatorsToContractSearch: state => {
      state.search = null;
    },
  },
  extraReducers: builder => {
    //deleteTranslateToContract
    builder.addCase(deleteTranslateToContract.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteTranslateToContract.fulfilled, (state, action) => {
      state.translateToContractList = state.translateToContractList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Translate to contract deleted successfully');
    });
    builder.addCase(deleteTranslateToContract.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });

    //listTranslateToContracts
    builder.addCase(listTranslateToContracts.pending, state => {
      if (state.search) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listTranslateToContracts.fulfilled, (state, action) => {
      const translators = action.payload.data.listTranslateToContracts;
      state.translateToContractList = translators.edges.map((element: any) => element.node);
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listTranslateToContracts.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error);
      toast.error('listTranslateToContracts API request rejected');
    });
  },
});

export const { resetTranslatorsToContractSearch, updateSearch } = translatorsListingSlice.actions;

export default translatorsListingSlice.reducer;
