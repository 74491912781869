import { Form } from 'semantic-ui-react';

const BooleanChoices = (): JSX.Element => {
  return (
    <>
      <Form.Radio
        className="radio-options"
        label="Yes"
        type="radio"
        disabled={true}
        value="yes"
      />
      <Form.Radio
        className="radio-options"
        label="No"
        type="radio"
        disabled={true}
        value="no"
      />
    </>
  );
};

export default BooleanChoices;
