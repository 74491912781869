import { ParameterChoiceBase } from 'common/_classes';
import AnswerTypes from 'common/model/AnswerTypes';

//TODO this is related to Policy and Transaction, hence should be in a different store and different subfolder,
// like listContextParameters.ts file

export interface QuestionProps {
  answerType: AnswerTypes;
  parameterId: string;
  index: number;
  tableId: string | null;
  tabIndex: number | null;
  choices?: ParameterChoiceBase[];
  mandatory?: boolean;
  onChange: () => void;
}

export enum RowNumberType {
  PARAMETER = 'parameter',
  TABLE = 'table',
}
