/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Popup } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { Icon } from '@iconify/react';
import CustomHeader from 'atoms/DateCell/CustomHeader';
import PopUpInfo from 'atoms/PopUpInfo';
import { removeActiveClassFromPreviousDate } from 'utils/tsHelper';
import { checkRequired, runValidations } from 'utils/tsValidator';
import { checkNotEmpty } from 'utils/tsValidator';
import { dateInputValidator } from 'utils/utils-clean-input';
import { labelResizeObserver } from 'utils/utils-html';
import { Icons } from 'utils/utils-icons';
import { generateUniqueId } from 'utils/utils-random';
import './DateInput.scss';

interface DateFieldProps {
  minDate?: Date | string | null;
  maxDate?: Date | string | null;
  dateFormat?: string | null;
  info?: boolean;
  label?: string | null;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value: string | Date | null;
  onChange?: (key: string, value: any) => void;
  fieldKey: string;
  validationFuncs?: ValidationFuncs;
  runValidation?: boolean;
  validationDependant?: (string | null)[] | string | null;
  infoValue?: string;
  popperProps?: {
    strategy: string;
  };
  dataTest?: string;
  isClearable?: boolean;
}

const DateField = ({
  minDate,
  maxDate,
  label,
  placeholder,
  required,
  value,
  disabled,
  info,
  onChange,
  fieldKey,
  validationFuncs,
  runValidation,
  validationDependant,
  infoValue,
  dateFormat,
  popperProps,
  dataTest,
  isClearable,
}: DateFieldProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);
  const [formContainerId] = useState<string>(generateUniqueId(fieldKey));
  const [calenderId] = useState<string>(generateUniqueId(fieldKey));
  const dateFormated: string = dateFormat === null || dateFormat === undefined ? 'dd/MM/yyyy' : dateFormat;
  const [showLabelPopup, setShowLabelPopup] = useState(false);

  const debounceFunc = (touched: boolean) =>
    debounce((requiredFunc, otherValidations, currentValue, required, setError) => {
      if (touched) {
        runValidations(requiredFunc, otherValidations, currentValue, required, setError);
      }
    }, 500);

  const debouncedCheck = useRef(debounceFunc(false));

  const checkDate = (date: Date | string | null | undefined): Date | null | undefined => {
    if (date) {
      if (typeof date === 'object') return date;

      const isDate = !isNaN(Date.parse(date));
      if (isDate) {
        return new Date(date);
      }
    }
    return null;
  };

  useEffect(() => {
    if (!disabled) {
      setTouched(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (debouncedCheck.current) {
      debouncedCheck.current.cancel();
    }

    if (touched && !disabled && value) {
      debouncedCheck.current = debounceFunc(touched);
      debouncedCheck.current(checkRequired, validationFuncs, value, required, setError);
    } else {
      setTouched(false);
      setError('');
    }
  }, [value]);

  useEffect(() => {
    // If the value that this field depends on changes, remove the error on this field
    // because the error will now be showing on that field. This is so that there are few
    // errors showing at any given time
    //
    // NOTE: You have to enable validation on that field too
    if (validationDependant) {
      setError('');
    }
  }, [validationDependant]);

  useEffect(() => {
    if (runValidation) {
      // TODO For Adebayo
      // @ts-ignore
      runValidations(checkRequired, validationFuncs, value, required, setError);
    }
  }, [runValidation]);

  useEffect(() => {
    const calenderElement = document.getElementById(`date-wrapper-${calenderId}`);

    const highlightDateText = () => {
      const selection = window.getSelection();

      if (selection && selection.rangeCount > 0) {
        selection.removeAllRanges();
      }

      const dateElement = document.getElementById(calenderId);
      const range = document.createRange();

      if (dateElement && selection) {
        range.selectNode(dateElement);
        selection.addRange(range);
      }
    };

    calenderElement?.addEventListener('dblclick', highlightDateText, true);

    return () => calenderElement?.removeEventListener('dblclick', highlightDateText);
  }, []);

  const convertToDate = (date: Date | undefined): null | undefined | Date => {
    if (!checkNotEmpty(date)) {
      return date;
    } else {
      return new Date(date as Date);
    }
  };

  const clickCalender = (calenderId: string) => {
    document.getElementById(calenderId)?.click();
  };

  const selectDate = (date: Date) => {
    if (onChange) {
      onChange(fieldKey, date);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    removeActiveClassFromPreviousDate(isOpen);
  }, [isOpen]);

  useEffect(() => {
    // Label popup control when label is truncated with an ellipsis
    labelResizeObserver(formContainerId, setShowLabelPopup);
  }, []);

  return (
    <Form.Field
      className="field-style"
      id={formContainerId}
    >
      {label && (
        <label>
          <Popup
            trigger={<span>{label}</span>}
            content={label}
            disabled={!showLabelPopup}
          />
          {required && <span className="required-indicator">*</span>}
          {info && (
            <PopUpInfo
              popUp={true}
              infoText={infoValue}
              info={true}
            />
          )}
        </label>
      )}

      <div
        id={`date-wrapper-${calenderId}`}
        data-test="stacking-plan-monitoring-date" //TODO remove this
      >
        <div
          className={`pos-rel ${disabled && 'custom-disabled'} ${error && 'error-date-field'}`}
          onClick={() => (disabled ? null : setTouched(true))}
          data-test={dataTest} //TODO clean multi usage of DataTest...
        >
          <DatePicker
            minDate={minDate ? checkDate(minDate) : undefined}
            maxDate={maxDate ? checkDate(maxDate) : undefined}
            id={calenderId}
            onInputClick={() => setIsOpen(true)}
            onClickOutside={() => setIsOpen(false)}
            open={isOpen}
            placeholderText={placeholder}
            data-test={dataTest}
            dateFormat={dateFormated}
            tabIndex={-1}
            renderCustomHeader={data => CustomHeader(data, selectDate, { minDate, maxDate })}
            onKeyDown={dateInputValidator}
            selected={convertToDate(value as any)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={selectDate}
            preventOpenOnFocus={true}
            popperProps={popperProps}
            isClearable={isClearable}
          />
          <Icon
            icon={Icons.Calender}
            className="date-icon"
            onClick={() => clickCalender(calenderId)}
          />
        </div>
      </div>
      {error && (
        <div className="input-validation-message error p-t-xxs">
          <Icon
            icon={Icons.Warning}
            className="m-r-xxs"
          />
          {error}
        </div>
      )}
    </Form.Field>
  );
};
export default DateField;
