import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ListPolicyProvisionsQueryVariables {
  policyId: string | undefined;
}

export const LIST_POLICY_PROVISIONS = (policyId: string | undefined) => {
  if (!policyId) throw new Error('LIST_POLICY_PROVISIONS: policyId is required');

  const variables: ListPolicyProvisionsQueryVariables = { policyId };

  return {
    query: gql`
      query ($policyId: ID!) {
        listPolicyProvisions(policyId: $policyId) {
          id
          policy {
            id
          }
          provision {
            id
            name
            index
            provisionCategory {
              id
              name
            }
            contents {
              content
              documentTypeId
            }
            description
            updatedAt
            guideline {
              explanatoryNotes
              generalGuidance
              internalNotes
              valuationGuidance
            }
          }
          mandatory
          selectedByDefault
        }
      }
    `,
    variables,
  };
};

export const listPolicyProvisions = createAsyncThunk(
  'policies/listPolicyProvisions',
  async ({ policyId }: { policyId: string | undefined }) => {
    const response = await client.query(LIST_POLICY_PROVISIONS(policyId));
    return response;
  },
);
