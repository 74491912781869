import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';
import { PaginationAPIProps } from 'common/api/miscellaneous';

interface ListUsersQueryVariables {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  sortOrder: SortOrder;
  userFilter: {
    search?: string;
  };
}

export const LIST_USERS = (
  searchValue: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const variables: ListUsersQueryVariables = {
    sortOrder: SortOrder.Asc,
    first: first,
    last: last,
    after: after,
    before: before,
    userFilter: {
      search: searchValue || undefined,
    },
  };
  return {
    query: gql`
      query (
        $sortOrder: SortOrder!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $userFilter: UserFilter
      ) {
        listUsers(
          sortOrder: $sortOrder
          first: $first
          last: $last
          after: $after
          before: $before
          userFilter: $userFilter
        ) {
          edges {
            cursor
            node {
              id
              firstName
              lastName
              countryCode
              phoneNumber
              email
              photoUrl
              insertedAt
              members {
                entity {
                  id
                  name
                }
                status
                role {
                  id
                  name
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listUsers = createAsyncThunk(
  'adminUsers/listUsers',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      adminUsers: { search },
    } = getState() as RootState;

    const response = await adminClient.query(LIST_USERS(search, first, last, after, before));

    return response;
  },
);
