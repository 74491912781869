import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Image, Input } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import PasswordPopUp from 'atoms/PasswordPopUp';
import { updateInput, updateResetStep } from 'store/authentication/authSlice';
import { getUserByToken, registerInvitedUser, signUp } from 'common/api/authentication';
import { invalidEmailText } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import { moveTo } from 'utils/utils-actions';
import { signUpValid } from 'utils/utils-credential';
import { Icons } from 'utils/utils-icons';
import { PasswordValidator, passwordValidator, passwordValidatorChecks } from 'utils/utils-password';
import { emailIsValid } from 'utils/utils-string';
import triangleCheck from 'assets/images/svg/triangle-check.svg';
import { PASSWORD_RESET_STEPS } from '../PasswordReset';
import './SignUpForm.scss';

const SignUpForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { firstName, lastName, email, confirmEmail, newPassword, newPasswordConfirmation } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const [type, setType] = useState<InputFieldType>(InputFieldType.PASSWORD);
  const [typeConfirm, setTypeConfirm] = useState<InputFieldType>(InputFieldType.PASSWORD);
  const { minLength, specialCharacters, oneUpperCase, oneLowerCase, oneNumber } = passwordValidatorChecks;
  const checkNewPassword: PasswordValidator = passwordValidator({
    validationChecks: [minLength, specialCharacters, oneUpperCase, oneLowerCase, oneNumber],
    value: newPassword,
    isNewPasswordField: true,
  });

  // conditions for validator and checked renders
  const showConfirmEmailValidation: boolean = email !== confirmEmail && confirmEmail.length !== 0;
  const checkEmailValidity: boolean = emailIsValid(email);
  const checkedConfirm: boolean = checkEmailValidity && email === confirmEmail;
  const showNewPasswordValidation: boolean = checkNewPassword.isInValid && newPassword.length !== 0;
  const newPasswordConfirmationValidation: boolean =
    newPassword !== newPasswordConfirmation && newPasswordConfirmation.length !== 0;
  const newPasswordChecked: boolean = newPassword === newPasswordConfirmation && !checkNewPassword.isInValid;

  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');

  useEffect(() => {
    if (token !== null) {
      // @ts-ignore
      dispatch(getUserByToken({ token }));
    }
  }, [dispatch, token]);

  const moveUp = (): boolean => {
    return checkNotEmpty(confirmEmail) && checkNotEmpty(newPassword) && checkNotEmpty(newPasswordConfirmation);
  };

  const onSignUp = (): void => {
    let method = token !== null ? registerInvitedUser : signUp;
    moveTo({
      // @ts-ignore
      moveToEvent: dispatch(method({ token })),
      navigate,
      url: '/sign-up/verify',
    });
  };

  const moveToAlternate = (): void => {
    const memberId = url.searchParams.get('member_id');
    dispatch(updateResetStep({ step: PASSWORD_RESET_STEPS.ENTER_EMAIL }));
    navigate(`/alternate-email?member_id=${memberId}`);
  };

  return (
    <Form
      className="auth-form"
      signup="true"
      onSubmit={() => onSignUp()}
    >
      <div className="content">
        <h1 className="header">
          Registration <Icon icon={Icons.CircleDownFilled} />
        </h1>
        <Form.Field>
          <label>
            First Name <span className="required">*</span>
          </label>
          <Input
            value={firstName}
            placeholder="Enter your first name..."
            type={InputFieldType.TEXT}
            onChange={e => dispatch(updateInput({ type: 'firstName', value: e.target.value }))}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Last Name <span className="required">*</span>
          </label>
          <Input
            value={lastName}
            placeholder="Enter your last name..."
            type={InputFieldType.TEXT}
            onChange={e => dispatch(updateInput({ type: 'lastName', value: e.target.value }))}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Email <span className="required">*</span>
          </label>
          <Input
            className={!checkEmailValidity ? 'input-error-field' : ''}
            value={email}
            placeholder="Enter your email..."
            type={InputFieldType.EMAIL}
            onChange={e => dispatch(updateInput({ type: 'email', value: e.target.value }))}
          />
          {!checkEmailValidity && (
            <p className="validation-message error-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              {invalidEmailText}
            </p>
          )}
        </Form.Field>
        <Form.Field>
          <label>
            Confirm email <span className="required">*</span>
          </label>
          <Input
            value={confirmEmail}
            placeholder="Confirm your email..."
            type={InputFieldType.EMAIL}
            onChange={e => dispatch(updateInput({ type: 'confirmEmail', value: e.target.value }))}
          />
          {showConfirmEmailValidation && (
            <p className="validation-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              Email is not the same.
            </p>
          )}
          {checkedConfirm && (
            <p className="validation-message success">
              <Image
                className="triangle-check width-0_857 height-0_857"
                src={triangleCheck}
              />
              Confirmed
            </p>
          )}
        </Form.Field>
        <Form.Field className="create-password">
          <label>
            Create password <span className="required">*</span>
            <PasswordPopUp />
          </label>
          <Input
            value={newPassword}
            icon={
              <Icon
                icon={InputFieldType.PASSWORD ? Icons.Eye : Icons.EyeSlash}
                onClick={() =>
                  setType(type === InputFieldType.PASSWORD ? InputFieldType.TEXT : InputFieldType.PASSWORD)
                }
              />
            }
            placeholder="Enter your password..."
            type={type}
            onChange={e => dispatch(updateInput({ type: 'newPassword', value: e.target.value }))}
          />
          {showNewPasswordValidation && (
            <p className="validation-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              {checkNewPassword.errorMessage}
            </p>
          )}
          {!checkNewPassword.isInValid && (
            <p className="validation-message success">
              <Image
                className="triangle-check width-0_857 height-0_857"
                src={triangleCheck}
              />
              Password is secure
            </p>
          )}
        </Form.Field>

        <Form.Field>
          <label>
            Confirm password <span className="required">*</span>
          </label>
          <Input
            value={newPasswordConfirmation}
            icon={
              <Icon
                icon={typeConfirm === InputFieldType.PASSWORD ? Icons.Eye : Icons.EyeSlash}
                onClick={() =>
                  setTypeConfirm(
                    typeConfirm === InputFieldType.PASSWORD ? InputFieldType.TEXT : InputFieldType.PASSWORD,
                  )
                }
              />
            }
            placeholder="Confirm your password..."
            type={typeConfirm}
            onChange={e =>
              dispatch(
                updateInput({
                  type: 'newPasswordConfirmation',
                  value: e.target.value,
                }),
              )
            }
          />
          {newPasswordConfirmationValidation && (
            <p className="validation-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              Passwords should be same.
            </p>
          )}
          {newPasswordChecked && (
            <p className="validation-message success">
              <Image
                className="triangle-check width-0_857 height-0_857"
                src={triangleCheck}
              />
              Checked
            </p>
          )}
        </Form.Field>

        <Button
          className="login-btn m-t-s height-2_857 font-600 text-center"
          type="submit"
          toggleup={`${moveUp()}`}
          disabled={
            !signUpValid(firstName, lastName, email, confirmEmail, !checkNewPassword.isInValid, newPasswordChecked)
          }
        >
          CREATE AN ACCOUNT
          <Icon
            icon={Icons.ArrowDownRight}
            rotate={4}
            className="width-1_143 height-1_143"
          />
        </Button>
      </div>
      {
        <p className="another-email">
          Not your email?
          <span
            onClick={() => moveToAlternate()}
            role="button"
          >
            Continue with another email
          </span>
        </p>
      }
    </Form>
  );
};

export default SignUpForm;
