import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ForecastedTenancies } from 'common/_classes/forecastsV2';
import { RootState } from 'store';

// Create async thunk for getForecastedTenancy
// export const getForecastedTenancies = createAsyncThunk(
//   'forecasts2/getForecastedTenancy',
//   async (_, { rejectWithValue }) => {
//     try {
//       // Replace this with the actual API call
//       const response = await fetch('/api/getForecastedTenancy');
//       const data = await response.json();
//       return data.map((item: any) => new ForecastedTenancies(item));
//     } catch (error) {
//       return rejectWithValue('Failed to fetch forecasted tenancy');
//     }
//   },
// );
// Create async thunk for getForecastedTenancies with sample data
export const getForecastedTenancies = createAsyncThunk(
  'forecasts2/getForecastedTenancies',
  async (_, { rejectWithValue }) => {
    try {
      // This is a mock API response with 10 sample records
      const mockData = Array(10)
        .fill(null)
        .map((_, index) => ({
          id: `ft${index + 1}`,
          buildingId: 'building123',
          buildingName: 'Sample Building',
          tenantId: `tenant${index + 1}`,
          tenantName: `Tenant ${index + 1}`,
          unitId: `unit${index + 1}`,
          unitName: `Unit ${index + 1}`,
          leaseId: `lease${index + 1}`,
          leaseStartDate: '2023-01-01',
          leaseEndDate: '2023-12-31',
          rentAmount: 1000,
          rentFreePeriod: 3,
          forecasted: 0,
        }));

      return mockData.map((item: any) => new ForecastedTenancies(item));
    } catch (error) {
      return rejectWithValue('Failed to fetch forecasted tenancies');
    }
  },
);
