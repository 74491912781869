import { ProvisionListingClone, TProvisionOnlyId } from 'common/_classes';
import { useState } from 'react';
import { Button, Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardViewTemplate, { CardTemplateConfiguration } from 'components/CardViewTemplate';
import { setSelectedProvisionFilter } from 'store/miscellaneous/miscellaneousSlice';
import { updateProvisionModalState } from 'store/provisions/provisionsListingSlice';
import { updateTransactionViewDetailsTab } from 'store/transactions/transactionDetailSlice';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { updateTransactionProvision } from 'common/api/transactions';
import { TransactionProvisionInput, TransactionProvisionTemp } from 'common/api/transactions';
import { Colors } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { TRANSACTION_TABS_OFFSET } from '../TransactionsTabs';

interface CardElement extends Omit<TransactionProvisionTemp, 'provisionCategory'>, ProvisionListingClone {}

export const singleCategoryList = (categoryList: ProvisionCategoryClone[], color: Colors) => {
  let provisions = [] as TProvisionOnlyId[];
  for (let i = 0; i < categoryList.length; i++) {
    provisions = [...provisions, ...categoryList[i].provisions];
  }
  return [
    {
      color,
      id: '',
      index: 1,
      name: '',
      provisions,
      insertedAt: '',
      updatedAt: '',
    },
  ];
};

const ProvisionsBox = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTransactionProvisions, activeTransaction } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );
  const { provisionCategoriesList } = useAppSelector((state: RootState) => state.provisionsListing);

  const [showUnselected, setShowUnselected] = useState(false);

  const sortedProvisions = orderBy(
    activeTransactionProvisions,
    element => {
      return element.provision.index;
    },
    'asc',
  );
  // list the provisions of the policy that are USED in this transaction
  const orderedSelectedProvisions: TransactionProvisionTemp[] = sortedProvisions.filter(
    (element: TransactionProvisionTemp) =>
      element.mandatory === false && (element.selectedByDefault || element.selected),
  );

  // list the provisions of the policy that are NOT USED in this transaction
  const orderedUnselectedProvisions: TransactionProvisionTemp[] = sortedProvisions.filter(
    (element: TransactionProvisionTemp) =>
      element.mandatory === false && (element.selectedByDefault === false || element.selected === false),
  );

  // Check if a USED provision is listed in this section
  const isProvisionInTransaction = (id?: string): boolean => {
    if (!id) return false;
    return !!orderedSelectedProvisions.find((element: TransactionProvisionTemp) => element.provision.id === id);
  };

  // Check if an UNUSED provision is listed in this section
  const isUnselectedProvisionInTransaction = (id?: string): boolean => {
    if (!id) return false;
    return !!orderedUnselectedProvisions.find((element: TransactionProvisionTemp) => element.provision.id === id);
  };

  const onViewDetails = (provision: ProvisionListingClone, e?: React.MouseEvent): void => {
    dispatch(
      updateProvisionModalState({
        open: true,
        provision,
      }),
    );
  };

  const moveToParameterTab = (provisionId: string | undefined): void => {
    if (provisionId) {
      dispatch(setSelectedProvisionFilter({ provisionId: provisionId }));
      dispatch(updateTransactionViewDetailsTab({ tab: TRANSACTION_TABS_OFFSET.PARAMETERS }));
    }
  };

  // List only the categories that have a provision
  const categoryList: ProvisionCategoryClone[] = provisionCategoriesList.filter(category => {
    const hasProvisions = orderedSelectedProvisions.find(
      (element: TransactionProvisionTemp) => element.provision?.provisionCategory?.id === category.id,
    );
    return hasProvisions;
  });

  // List only the categories that have a provision
  const unSelectedCategoryList: ProvisionCategoryClone[] = provisionCategoriesList.filter(category => {
    const hasProvisions = orderedUnselectedProvisions.find(
      (element: TransactionProvisionTemp) => element.provision?.provisionCategory?.id === category.id,
    );
    return hasProvisions;
  });

  // For each category :
  //  - list the provisions of that specific category
  //  - map to the CardElement
  const filterElementsPerCategory = (selected: boolean): CardElement[] => {
    const provisions = selected ? orderedSelectedProvisions : orderedUnselectedProvisions;
    const isProvision = selected ? isProvisionInTransaction : isUnselectedProvisionInTransaction;

    return provisions
      .filter((element: TransactionProvisionTemp) => isProvision(element.provision.id))
      .map((transactionProvision: TransactionProvisionTemp) => {
        const result: CardElement = {
          ...transactionProvision,
          ...transactionProvision.provision,
        };
        return result;
      });
  };

  const onSelectProvision = (provision: CardElement, selected: boolean): void => {
    const updatedTransactionProvision: TransactionProvisionInput = {
      provisionId: provision.id,
      transactionId: activeTransaction.id,
      mandatory: provision.mandatory,
      selected,
    };

    const provisions = selected ? orderedUnselectedProvisions : orderedSelectedProvisions;

    const transactionProvisionId: string | undefined = provisions.find(
      (element: TransactionProvisionTemp) => element.provision.id === provision.id,
    )?.id;

    dispatch(
      updateTransactionProvision({
        id: transactionProvisionId,
        transactionProvision: updatedTransactionProvision,
      }),
    );
  };

  const cardHeaderTemplate = (provision: CardElement, selected: boolean): JSX.Element => {
    return (
      <div className="column-custom-template">
        <Popup
          className="popup-info"
          content={provision.name}
          trigger={
            <span
              className="name color-black"
              onClick={(e: React.MouseEvent) => onViewDetails(provision, e)}
            >
              {provision.name}
            </span>
          }
        />
        {provision.selectedByDefault && (
          <Icon
            icon={Icons.TickCircleSolid}
            color={Colors.GREEN}
            width="2rem"
            height="1.5rem"
          />
        )}

        {provision.mandatory && (
          <span className="card-header-icon-mandatory">
            <span className="text-m">M</span>
          </span>
        )}

        {!selected && (
          <button className="icon-wrapper-add">
            <Icon
              icon={Icons.Add}
              onClick={() => onSelectProvision(provision, true)}
            />
          </button>
        )}
      </div>
    );
  };

  const cardMenuItems = (provision: CardElement, selected: boolean): DropdownMenuItem[] => {
    return selected
      ? [
          {
            key: '1',
            label: 'Unselect',
            icon: Icons.RemoveCircle,
            onClick: () => onSelectProvision(provision, false),
            disabled: provision.mandatory,
          },
          {
            key: '2',
            label: 'View Details',
            icon: Icons.EyeOpen,
            onClick: () => onViewDetails(provision),
          },
        ]
      : [];
  };

  const cardConfigs = (selected: boolean): CardTemplateConfiguration<ProvisionCategoryClone, CardElement> => {
    return {
      columnConfiguration: {
        header: {
          titleField: 'name',
          showEdit: false,
        },
        width: '20rem',
      },
      cardConfiguration: {
        header: {
          headerTemplate: (provision: CardElement) => cardHeaderTemplate(provision, selected),
          cardMenuItems: (provision: CardElement) => cardMenuItems(provision, selected),
        },
        bodyTemplate: () => <></>,
        onBodyClick: (provision: CardElement) => moveToParameterTab(provision.id),
        onClick: (provision: CardElement) => {},
      },
    };
  };

  return (
    <div className="transaction-provision-section selected-provisions border-sm-grayish-magenta-light">
      <h4 className="provisions-title m-none color-blue-very-dark">Special Provisions</h4>
      {showUnselected ? (
        <button className="cross-btn-parent">
          <Icon
            className="cross-btn"
            icon={Icons.CancelRounded}
            onClick={() => setShowUnselected(!showUnselected)}
          ></Icon>
        </button>
      ) : (
        <Button
          className="btn grey-bg add-remove"
          data-test="edit-button"
          onClick={() => setShowUnselected(!showUnselected)}
        >
          Add
        </Button>
      )}

      <Grid
        columns={2}
        className="provisions-box"
      >
        <Grid.Column width={8}>
          <CardViewTemplate<ProvisionCategoryClone, CardElement>
            categoryList={singleCategoryList(categoryList, Colors.VIOLET)}
            configuration={cardConfigs(true)}
            getCardElementsList={() => filterElementsPerCategory(true)}
          />
        </Grid.Column>
        {showUnselected && (
          <Grid.Column width={8}>
            <CardViewTemplate<ProvisionCategoryClone, CardElement>
              categoryList={singleCategoryList(unSelectedCategoryList, Colors.VIOLET)}
              configuration={cardConfigs(false)}
              getCardElementsList={() => filterElementsPerCategory(false)}
            />
          </Grid.Column>
        )}
      </Grid>
    </div>
  );
};

export default ProvisionsBox;
