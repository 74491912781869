import { TransactionListingPremise } from 'common/_classes';
import { addSpaceOrComma } from 'utils/utils-number';
import { FloorsListingData, getFloorListingFromPremises } from 'utils/utils-premises';
import './FloorsToolTip.scss';

interface FloorsListingProps {
  premise: TransactionListingPremise;
}

const FloorsListing = ({ premise }: FloorsListingProps): JSX.Element => {
  const sortedFloors: FloorsListingData[] = getFloorListingFromPremises(premise);
  return (
    <ul className="listing pm-none">
      {sortedFloors.map((floor: FloorsListingData, index: number) => (
        <li
          className="item"
          key={`${index}-${floor.name}`}
        >
          <div className="text">
            <span className="font-600 color-blue-very-dark m-r-xs">{floor.name}</span>
            <span
              className={`floor-box ${
                floor.isWholeFloor
                  ? 'color-green bg-green-light-grayish-cyan-lime'
                  : 'color-orange bg-orange-light-grayish m-r-xs'
              }`}
            >
              {floor.isWholeFloor ? 'WHOLE FLOOR' : `PART FLOOR`}
            </span>
            {` (${floor.spaceNames.join(', ')})`}
          </div>
          <div className="text">Total area : {addSpaceOrComma(floor.totalArea, false)} sqft</div>
        </li>
      ))}
    </ul>
  );
};

export default FloorsListing;
