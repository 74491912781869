import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import PageInfo from 'common/model/PageInfo';
import {
  deleteProperty,
  exportForecasting,
  exportProperty,
  importForecasting,
  importProperty,
  listLandlordEntityOptions,
  listProperties,
  listPropertiesForAdmin,
} from 'common/api/properties';
import { DROPDOWN_OPTION } from 'utils/UI';
import { autoDownloadExportedFile, dataURLtoFile } from 'utils/tsHelper';

interface PropertiesListingState {
  propertiesList: any[];
  landlordEntitiesOptions: DROPDOWN_OPTION[];
  search: string | null;
  pageInfo: PageInfo[];
  isLoading: boolean;
  isSearching: boolean;
  importedProperty: any;
  exportSuccess: boolean;
  exportFail: boolean;
  isExporting: boolean;
}

const initialState: PropertiesListingState = {
  propertiesList: [],
  landlordEntitiesOptions: [],
  search: null,
  pageInfo: [],
  isLoading: false,
  isSearching: false,
  importedProperty: null,
  exportSuccess: false,
  exportFail: false,
  isExporting: false,
};

const propertiesListingSlice = createSlice({
  name: 'propertiesListing',
  initialState,
  reducers: {
    clearImportedProperty: state => {
      state.importedProperty = null;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    //listProperties
    builder.addCase(listProperties.pending, (state, action) => {
      // @ts-ignore
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listProperties.fulfilled, (state, action) => {
      if (action.payload.data.listProperties) {
        const { edges, pageInfo } = action.payload.data.listProperties;
        state.propertiesList = edges.map((element: any) => element.node);
        state.pageInfo = pageInfo;
      }
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listProperties.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listProperties API request failed');
    });

    //listPropertiesForAdmin
    builder.addCase(listPropertiesForAdmin.pending, (state, action) => {
      // @ts-ignore
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listPropertiesForAdmin.fulfilled, (state, action) => {
      const { edges, pageInfo } = action.payload.data.listProperties;
      state.propertiesList = edges.map((element: any) => element.node);
      state.pageInfo = pageInfo;
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listPropertiesForAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listPropertiesForAdmin API request failed');
    });

    //listLandlordEntityOptions
    builder.addCase(listLandlordEntityOptions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listLandlordEntityOptions.fulfilled, (state, action) => {
      const landlordEntities = action.payload.data.listLandlordEntities.edges.map((element: any) => element.node);
      state.landlordEntitiesOptions = landlordEntities.map((entity: any, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: entity.name,
          value: entity.id,
        };
        return result;
      });
      state.isLoading = false;
    });
    builder.addCase(listLandlordEntityOptions.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('listLandlordEntityOptions API request failed');
    });

    //deleteProperty
    builder.addCase(deleteProperty.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteProperty.fulfilled, (state, action) => {
      // @ts-ignore
      state.propertiesList = state.propertiesList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('The property was deleted successfully');
    });
    builder.addCase(deleteProperty.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });

    //importProperty
    builder.addCase(importProperty.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(importProperty.fulfilled, (state, action) => {
      const { importProperty } = action.payload.data;
      state.importedProperty = importProperty;
      state.isLoading = false;
      toast.success(
        `Property(s) ${importProperty.simulation ? 'import simulation successful' : 'imported successfully'} `,
      );
    });
    builder.addCase(importProperty.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      if (action.error.message) {
        toast.error(action.error.message);
      } else {
        toast.error('importProperty API request failed');
      }
    });

    //importForecasting
    builder.addCase(importForecasting.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(importForecasting.fulfilled, (state, action) => {
      const { importForecasting } = action.payload.data;
      state.importedProperty = importForecasting;
      state.isLoading = false;
      toast.success(
        `Property(s) ${importForecasting.simulation ? 'import simulation successful' : 'imported successfully'} `,
      );
    });
    builder.addCase(importForecasting.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      if (action.error.message) {
        toast.error(action.error.message);
      } else {
        toast.error('importForecasting API request failed');
      }
    });

    //exportForecasting
    builder.addCase(exportForecasting.pending, state => {
      state.isExporting = true;
      state.exportSuccess = false;
      state.exportFail = false;
    });
    builder.addCase(exportForecasting.fulfilled, (state, action) => {
      const {
        payload: {
          data: { exportForecasting },
        },
      } = action;
      const formattedDate = moment().format('DD-MM-YYYY');
      const fileName = `Revenue-Forecast-${formattedDate}.xlsx`;
      autoDownloadExportedFile(dataURLtoFile(exportForecasting, fileName));
      state.exportSuccess = true;
      state.isExporting = false;
      toast.success('Forecasting exported successfully');
    });
    builder.addCase(exportForecasting.rejected, (state, action) => {
      state.exportFail = true;
      state.isExporting = false;
      console.warn(action.error);
      toast.error('exportForecasting API request failed');
    });

    //exportProperty
    builder.addCase(exportProperty.pending, state => {
      state.isExporting = true;
      state.exportSuccess = false;
      state.exportFail = false;
    });
    builder.addCase(exportProperty.fulfilled, (state, action) => {
      const {
        payload: {
          data: { exportProperty },
        },
      } = action;
      const formattedDate = moment().format('DD-MM-YYYY');
      const fileName = `Property-${formattedDate}.xlsx`;
      autoDownloadExportedFile(dataURLtoFile(exportProperty, fileName));
      state.exportSuccess = true;
      state.isExporting = false;
      toast.success('Propert exported successfully');
    });
    builder.addCase(exportProperty.rejected, (state, action) => {
      state.exportFail = true;
      state.isExporting = false;
      console.warn(action.error);
      toast.error('exportProperty API request failed');
    });
  },
});

export const { clearImportedProperty, updateSearch } = propertiesListingSlice.actions;

export default propertiesListingSlice.reducer;
