import TabContainer from 'components/TabContainer';
import PropertiesTable from 'views/properties/Dashboard/Table/PropertiesTable';
import EntitiesPropertiesTabToolbarProps from './EntitiesPropertiesTabToolbar';

const EntitiesPropertiesTab = () => {
  return (
    <TabContainer
      tabTitle={'Properties'}
      tabToolbar={<EntitiesPropertiesTabToolbarProps />}
    >
      <PropertiesTable />
    </TabContainer>
  );
};

export default EntitiesPropertiesTab;
