import moment from 'moment';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Badge, { BadgeColor } from 'atoms/Badge';
import CheckboxField from 'atoms/FormField/Checkbox';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import {
  getBadgeText,
  getNotificationBadgeColor,
  getSenderEntity,
  getSenderName,
} from 'components/NotificationComponent/utils.notification';
import { selectNotification, unselectNotification } from 'store/notifications/notificationsSlice';
import Notification from 'common/model/Notification';
import {
  deleteNotification,
  toggleArchiveMultipleNotifications,
  toggleNotificationReadStatus,
} from 'common/api/notifications';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import NotificationInfo from './NotificationInfo';
import './NotificationListingRow.scss';

interface NotificationListingRowProps {
  notification: Notification;
  className?: string;
}

const NotificationListingRow = ({ notification, className }: NotificationListingRowProps) => {
  const dispatch = useAppDispatch();
  const { selectedNotificationIds, archive } = useAppSelector((state: RootState) => state.notifications);

  const toggleReadStatus = () => {
    dispatch(
      toggleNotificationReadStatus({
        notificationId: notification.id,
        read: !notification.seen,
      }),
    );
  };

  const toggleSelection = (id: string, value: boolean) => {
    if (value) {
      dispatch(selectNotification({ id }));
    } else {
      dispatch(unselectNotification({ id }));
    }
  };

  const onArchive = () => {
    dispatch(
      toggleArchiveMultipleNotifications({
        archive: !archive,
        id: notification.id,
      }),
    );
  };

  const buttons: TableActionButton[] = [
    {
      icon: notification.seen ? Icons.Unread : Icons.Read,
      tooltip: `Mark as ${notification.seen ? 'unread' : 'read'}`,
      onClick: () => toggleReadStatus(),
    },
    {
      icon: archive ? Icons.UnarchiveFilled : Icons.ArchiveFilled,
      tooltip: archive ? 'Unarchive' : 'Archive',
      onClick: () => onArchive(),
    },
  ];

  if (archive) {
    const onDelete = () => {
      dispatch(deleteNotification({ notificationId: notification.id }));
    };

    buttons.push({
      icon: Icons.TrashFill,
      tooltip: 'Delete',
      onClick: () => onDelete(),
    });
  }

  const senderEntity = getSenderEntity(notification.infoSender);

  const isSenderEntityDeleted: boolean = senderEntity === null;

  return (
    <div className={`notification-listing-section-row ${conditionalClassName(className, className)}`}>
      <CheckboxField
        fieldKey={notification.id}
        onChange={toggleSelection}
        value={selectedNotificationIds.includes(notification.id)}
        className="m-t-s"
      />

      <div className={`content ${conditionalClassName(!notification.seen, 'unread')}`}>
        <div className="info-group">
          <div className="info-header">
            <Badge badgeColor={getNotificationBadgeColor(notification.type)}>{getBadgeText(notification.type)}</Badge>
            <span className="text m-l-xs m-r-xs">{moment(notification.date).format('MM/DD/YYYY [at] HH:mm')}</span>
            <span className="text bold">
              {getSenderName(notification.infoSender)}{' '}
              {isSenderEntityDeleted ? (
                <Badge badgeColor={BadgeColor.RED}>sender entity has been deleted</Badge>
              ) : (
                `(${senderEntity})`
              )}
            </span>
          </div>

          <NotificationInfo {...notification} />
        </div>
        <TableActionButtons buttons={buttons} />
      </div>
    </div>
  );
};

export default NotificationListingRow;
