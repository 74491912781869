/* eslint-disable jsx-a11y/label-has-associated-control */
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import EditorField from 'atoms/FormField/EditorField';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SpecialCheckBox from 'atoms/FormField/SpecialCheckBox';
import TextAreaField from 'atoms/FormField/TextArea';
import ReviewBox, { ReviewBoxValues } from 'components/ReviewBox';
import {
  updateActiveTranslateToContract,
  updateTranslateToContractReviewedStatus,
} from 'store/translatorsToContract/translatorDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { TranslateToContractClone } from 'common/api/translatorsToContract';

const TranslateToContractDefinition = ({ mode }: ModeProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTranslateToContract } = useAppSelector((state: RootState) => state.translatorDetail);

  const { name, description, index, active, reviewFlag, reviewStatus } =
    activeTranslateToContract as TranslateToContractClone;

  const onChange = (key: string, value: any) => dispatch(updateActiveTranslateToContract({ key, value }));

  const reviewBoxValues: ReviewBoxValues = {
    reviewFlag,
    reviewStatus,
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <TextAreaField
            label="Name"
            value={name}
            placeholder="Type here..."
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="name"
            dataTest="translator-name"
          />
        </Grid.Column>
        <Grid.Column>
          <SectionComponent className="review-form">
            <ReviewBox
              mode={mode}
              values={reviewBoxValues}
              onChange={updateTranslateToContractReviewedStatus}
            />
          </SectionComponent>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Index"
            type={InputFieldType.NUMBER}
            fieldKey="index"
            value={index as any}
            required={true}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
            dataTest="translator-index"
          />
        </Grid.Column>
        <Grid.Column
          width={8}
          className="pm-none structure-provision d-flex"
        >
          <SpecialCheckBox
            label="Active"
            mode={mode}
            info={false}
            required={mode === MODE_OPTIONS.EDIT}
            value={active}
            onChange={onChange}
            fieldKey="active"
            dataTest="translator-active"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <EditorField
            label="Description"
            placeholder="Type here..."
            value={description}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="description"
            height="250"
            code={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TranslateToContractDefinition;
