import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { EntityCreatorTypes } from './types';

interface GetEntityQueryVairables {
  currentLandlordEntityId?: string;
  entityId: string;
}

export const GET_ENTITIY = (type: EntityCreatorTypes, entityId: string) => {
  if (!entityId) throw new Error('Get Entity: entityId is required');

  let currentLandlordEntity = '',
    innerTextLandlordEntity = '',
    currentLandlordEntityId;

  if (type === EntityCreatorTypes.Tenant) {
    currentLandlordEntityId = getCurrentLandlordEntityId();

    if (!currentLandlordEntityId) throw new Error('Get Entity: currentLandlordEntityId is required');

    currentLandlordEntity = '$currentLandlordEntityId: ID!,';
    innerTextLandlordEntity = 'currentLandlordEntityId: $currentLandlordEntityId,';
  }

  const variables: GetEntityQueryVairables = {
    currentLandlordEntityId,
    entityId,
  };

  return {
    query: gql`
         query(${currentLandlordEntity} $entityId: ID!) {
          get${type}Entity(${innerTextLandlordEntity} entityId: $entityId){
            id
            activity
            address
            addressLocal
            businessAddress
            cardId
            type
            contacts {
              email
              firstName
              lastName
              phone
              position
            }
            details
            documents{
              id
              name
              public
              url
              path
            }
            insertedAt
            legalDomicile
            logo
            name
            nickname
            identifier
            industry{
              id
              name
              code
            }
            overseasRegistrationId
            passportId
            registrationCountry
            registrationId
            residentialAddress
            updatedAt 
            landlordNetworks{
              childId
              description {
                sharePercentage
              }
              id
              parentId
              type
            }
          }
        },
      `,
    variables,
  };
};

export const getEntity = createAsyncThunk(
  'entityDetail/getEntity',
  async ({
    type,
    id,
    isChild,
  }: {
    type: EntityCreatorTypes;
    id: string;
    step?: number | string;
    isChild?: boolean;
  }) => {
    const response = await client.query(GET_ENTITIY(type, id));

    return response;
  },
);
