import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';

interface AdminSignInQueryVariables {
  email: string;
  password: string;
}

export const ADMIN_SIGN_IN = ({ email, password }: AdminSignInQueryVariables) => {
  const variables: AdminSignInQueryVariables = {
    email,
    password,
  };

  return {
    mutation: gql`
      mutation ($email: String!, $password: String!) {
        adminLogin(email: $email, password: $password) {
          token
          adminUser {
            email
            id
            firstName
            lastName
          }
        }
      }
    `,
    variables,
  };
};

export const adminSignIn = createAsyncThunk('auth/adminSignIn', async (_arg, { getState }) => {
  const {
    auth: { email, password },
  } = getState() as RootState;
  const response = await adminClient.mutate(ADMIN_SIGN_IN({ email, password }));
  return response;
});
