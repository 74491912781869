import Decimal from 'decimal.js';
import Security2 from 'common/model/Security2';

export class CSecurity2 {
  amount: Decimal | null;
  months: number | null;

  constructor(input: Security2) {
    this.amount = input.amount ? new Decimal(input.amount) : null;
    this.months = input.months !== null ? input.months : null;
  }
  static fragment() {
    return `
        amount
        months
        `;
  }
}
