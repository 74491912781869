import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Contract } from 'utils/types/stackingPlan';
import { getCurrentRent, getEffectiveRent, getRentPerAreaCellValue, getTotalArea } from 'utils/utils-contract';
import { Icons } from 'utils/utils-icons';
import { addSpaceOrComma } from 'utils/utils-number';
import { FloorsListingData, getFloorListingFromPremises } from 'utils/utils-premises';
import { SectionRow } from './SectionRow';
import './LeaseTemplate.scss';

interface LeaseTemplateProps {
  contract: Contract;
  monitoringDate: string;
  selectedFloor?: any;
}

const LeaseTemplate = ({ contract, selectedFloor }: LeaseTemplateProps) => {
  const navigate = useNavigate();

  const tenantName = contract.tenant.name;
  const totalArea = getTotalArea(contract.premises as any);
  const baseRent = getCurrentRent(contract.rentDescriptions as any);
  const effectiveRent = getEffectiveRent(contract.rentDescriptions as any);
  const rentPerArea = getRentPerAreaCellValue(baseRent, totalArea);
  const effectiveRentPerArea = getRentPerAreaCellValue(effectiveRent, totalArea);

  const floors: string[] = getFloorListingFromPremises(contract.premises[0]).map(
    (floorData: FloorsListingData) => `${floorData.name}, ${floorData.spaceNames.join(', ')}`,
  );

  const monthlyRent = `${addSpaceOrComma(baseRent, false) || ''} HK$/mo`;
  const monthlyRentPerArea = `${addSpaceOrComma(rentPerArea, false) || '0'} HK$/mo/sqft`;
  const effectiveMonthlyRent = `${addSpaceOrComma(effectiveRent, false) || ''} HK$/mo`;
  const effectiveMonthlyRentPerArea = `${addSpaceOrComma(effectiveRentPerArea, false) || ''} HK$/mo/sqft`;

  const navigateToLink = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(`/contracts/${contract.id}/details`);
  };

  return (
    <Grid className="lease-template m-none">
      <Grid.Row>
        <SectionRow
          name="Tenant"
          value={tenantName}
        />
        <SectionRow
          name="Headline rent"
          value={`${monthlyRent} `}
          secondRowValue={`${monthlyRentPerArea}`}
        />
        <SectionRow
          name="Effective rent"
          value={`${effectiveMonthlyRent} `}
          secondRowValue={`${effectiveMonthlyRentPerArea}`}
        />
        <SectionRow
          name="Lease term"
          value={`${moment(contract.startDate).format('DD/MM/YYYY')} to 
          ${moment(contract.endDate).format('DD/MM/YYYY')}`}
        />
        <hr className="w-100 m-t-l m-b-l separator" />
        <SectionRow
          name="Floors & units"
          list={floors}
        />
        {/* TODO: Will be added after api changes */}
        {/* <SectionRow name="Important events" list={[]} />
        <SectionRow name="Option" list={[]} /> */}
      </Grid.Row>
      <a
        onClick={navigateToLink}
        href={`/contracts/${contract.id}/details`}
      >
        Link to this Contract
        <Icon
          icon={Icons.ArrowDown90}
          rotate={2}
        />
      </a>
      <hr className="w-100 m-t-l m-b-l separator" />
    </Grid>
  );
};

export default LeaseTemplate;
