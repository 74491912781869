import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface DeleteRoleQueryVariables {
  currentLandlordEntityId: string;
  roleId: string;
}
export const DELETE_ROLE = (roleId: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Delete Role: currentLandlordEntityId is required');

  const variables: DeleteRoleQueryVariables = {
    currentLandlordEntityId,
    roleId,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $roleId: ID!) {
        deleteRole(currentLandlordEntityId: $currentLandlordEntityId, roleId: $roleId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteRole = createAsyncThunk('roles/deleteRole', async ({ id }: { id: string }) => {
  const response = await client.mutate(DELETE_ROLE(id));
  return response;
});
