import { useMemo } from 'react';
import { Checkbox, Grid, Input, Popup } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import DateField from 'atoms/FormField/DateInput';
import { InputFieldType } from 'atoms/FormField/Input';
import { updateFloorPlan } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { FloorPlanProps, SpaceProps } from 'common/api/properties';
import { floorPlanRevisionDateValidation } from 'utils/tsValidator';
import { convertDateToISO } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import { addSpaceOrComma, getEfficiencyRatio, removeSpaceOrComma } from 'utils/utils-number';
import { getFilteredSpaces } from 'utils/utils-property';
import Area from './Area';
import PlanUploadAndThreeDotsMenu from './PlanUploadAndThreeDotsMenu';
import '../FloorPlan.scss';

interface FloorPlanProvisionProps {
  index: number;
  mode: MODE_OPTIONS;
  floorPlans: FloorPlanProps[];
  spaces: SpaceProps[];
}

const FloorPlanComponent = ({ index, mode, floorPlans, spaces }: FloorPlanProvisionProps) => {
  const dispatch = useAppDispatch();

  const {
    id,
    reviewFlag,
    revisionDate,
    efficiencyRatio,
    grossArea,
    lettableArea,
    netArea,
    saleableArea,
    unitsNumber,
    whollyOwned,
    floorPlan,
  } = floorPlans[index];

  const updateFloorPlanInput = (key: string, value: any, id: string) => {
    dispatch(updateFloorPlan({ key, value, id }));
  };

  let previousRevisionDate: null | string = null;
  let nextRevisionDate: null | string = null;

  if (index > 0) {
    ({ revisionDate: previousRevisionDate } = floorPlans[index - 1]);
  }

  if (index + 1 <= floorPlans.length - 1) {
    ({ revisionDate: nextRevisionDate } = floorPlans[index + 1]);
  }

  const validationDependants = useMemo(
    () => [previousRevisionDate, nextRevisionDate],
    [previousRevisionDate, nextRevisionDate],
  );

  const dateFilter = convertDateToISO(revisionDate);
  const verifiedUnitsNumber = getFilteredSpaces(dateFilter, spaces).length;

  const disabled = reviewFlag || mode === MODE_OPTIONS.READ;

  return (
    <Grid className="outlined container plan-content pm-none p-sm m-r-sm bg-white border-sm-grayish-magenta-light">
      <PlanUploadAndThreeDotsMenu
        id={id}
        disabled={disabled}
        floorPlan={floorPlan}
        index={index}
      />

      <hr className="w-100 border-sm-grayish-magenta-light m-t-sm" />

      <Grid.Row className="pm-none m-t-sm justify-space-between align-center">
        <span>Review</span>

        <Checkbox
          disabled={mode === MODE_OPTIONS.READ}
          checked={reviewFlag === null ? false : reviewFlag}
          onChange={(e, { checked }) => updateFloorPlanInput('reviewFlag', checked, id)}
        />
      </Grid.Row>

      <Grid.Row className="pm-none m-t-sm justify-space-between align-center">
        <span>
          Revision date <span className="required">*</span>
        </span>

        <DateField
          fieldKey="revisionDate"
          value={revisionDate}
          disabled={disabled}
          onChange={(fieldKey, value) => updateFloorPlanInput(fieldKey, value, id)}
          validationFuncs={floorPlanRevisionDateValidation(previousRevisionDate, nextRevisionDate)}
          validationDependant={validationDependants}
          required={true}
          data-test="revision-date"
        />
      </Grid.Row>

      <Grid.Row className="pm-none m-t-sm justify-space-between align-center">
        <span>
          Number of units <span className="required">*</span>
        </span>

        <div className="d-flex justify-space-between align-center">
          <Input
            size="small"
            value={unitsNumber === null ? '' : addSpaceOrComma(unitsNumber, false)}
            disabled={disabled}
            readOnly={disabled}
            data-test="number-of-units"
            type={InputFieldType.TEXT}
            className="number-of-units"
            onChange={(e, { value }) => updateFloorPlanInput('unitsNumber', removeSpaceOrComma(value), id)}
          />

          <Popup
            className="popup-info"
            content={verifiedUnitsNumber === unitsNumber ? 'Verified' : 'Unverified'}
            trigger={
              verifiedUnitsNumber === unitsNumber ? (
                <Icon
                  className="m-l-xxs color-green height-1_143 width-1_143"
                  icon={Icons.TickCircleSolid}
                />
              ) : (
                <Icon
                  className="m-l-xxs color-red-soft height-1_286 width-1_286"
                  icon={Icons.CancelRounded}
                />
              )
            }
          />
        </div>
      </Grid.Row>

      <Grid.Row className="pm-none m-t-sm justify-space-between align-center">
        <span>
          Wholly owned <span className="required">*</span>
        </span>

        <Checkbox
          disabled={disabled}
          checked={whollyOwned}
          data-test="wholly-owned-toggle"
          toggle
          onChange={(e, { checked }) => updateFloorPlanInput('whollyOwned', checked, id)}
        />
      </Grid.Row>

      <hr className="w-100 border-sm-grayish-magenta-light m-t-sm" />

      <Grid.Row className="pm-none m-t-sm justify-space-between">
        <p className="color-blue-very-dark-grayish w-100">All areas below are in "sq.ft"</p>

        <Grid.Column
          className="pm-none"
          width={7}
        >
          <Area
            id={id}
            label="Gross"
            fieldKey="grossArea"
            dataTest="gross-area"
            disabled={disabled}
            required={true}
            updateFloorPlanInput={updateFloorPlanInput}
            value={grossArea}
          />
        </Grid.Column>
        <Grid.Column
          className="pm-none"
          width={8}
        >
          <Area
            id={id}
            label="Lettable"
            fieldKey="lettableArea"
            dataTest="lettable-area"
            disabled={disabled}
            updateFloorPlanInput={updateFloorPlanInput}
            value={lettableArea}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="pm-none m-t-sm justify-space-between">
        <Grid.Column
          className="pm-none"
          width={7}
        >
          <Area
            id={id}
            label="Net"
            fieldKey="netArea"
            dataTest="net-area"
            disabled={disabled}
            updateFloorPlanInput={updateFloorPlanInput}
            value={netArea}
          />
        </Grid.Column>
        <Grid.Column
          className="pm-none"
          width={8}
        >
          <Area
            id={id}
            label="Saleable"
            fieldKey="saleableArea"
            dataTest="saleable-area"
            disabled={disabled}
            updateFloorPlanInput={updateFloorPlanInput}
            value={saleableArea}
          />
        </Grid.Column>
      </Grid.Row>

      <hr className="w-100 border-sm-grayish-magenta-light m-t-sm" />

      <Grid.Row className="pm-none m-t-sm justify-space-between align-center">
        <span>
          Efficiency ratio (%) <span className="required">*</span>
        </span>
        <Input
          size="small"
          value={efficiencyRatio === null ? '' : getEfficiencyRatio(efficiencyRatio)}
          disabled={disabled}
          data-test="efficiency-ratio"
          readOnly={disabled}
          type={InputFieldType.NUMBER}
          onChange={(e, { value }) => updateFloorPlanInput('efficiencyRatio', value, id)}
        />
      </Grid.Row>
    </Grid>
  );
};

export default FloorPlanComponent;
