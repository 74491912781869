import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';

interface CreateFloorQueryVariables {
  currentLandlordEntityId: string;
  params: CreateFloorMurationQueryParams;
  propertyId: string;
}

interface CreateFloorMurationQueryParams {
  name: any;
  index: any;
  comments: any;
  characteristics: any;
  ceilingHeight: any;
  floorLoading: any;
  activationDate: any;
  deactivationDate: any;
}

const CREATE_FLOOR = (activeFloor: any, propertyId: string | null) => {
  const { name, index, comments, characteristics, ceilingHeight, floorLoading, activationDate, deactivationDate } =
    activeFloor;

  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) throw new Error('Create Floor: No currentLandlordEntityId');

  if (!propertyId) throw new Error('Create Floor: No propertyId');

  const params: CreateFloorMurationQueryParams = {
    name,
    index: parseInt(index),
    comments,
    characteristics,
    ceilingHeight,
    floorLoading,
    activationDate: convertDateToISO(activationDate),
    deactivationDate: convertDateToISO(deactivationDate),
  };

  const variables: CreateFloorQueryVariables = {
    currentLandlordEntityId,
    params,
    propertyId,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: FloorInput!, $propertyId: ID!) {
        createFloor(currentLandlordEntityId: $currentLandlordEntityId, params: $params, propertyId: $propertyId) {
          id
          name
          index
          comments
          characteristics
          ceilingHeight
          floorLoading
          activationDate
          deactivationDate
          spaces {
            id
            name
            registeredName
            type
            usedForOccupancyRate
            grossArea
            netArea
            lettableArea
            saleableArea
            efficiencyRatio
            comments
            characteristics
            activationDate
            deactivationDate
          }
          floorPlans {
            id
            reviewFlag
            floorPlan {
              id
              name
              path
              public
              url
            }
            revisionDate
            unitsNumber
            whollyOwned
            grossArea
            netArea
            lettableArea
            saleableArea
            efficiencyRatio
          }
        }
      }
    `,
    variables,
  };
};

export const createFloor = createAsyncThunk('properties/createFloor', async (_arg, { getState }) => {
  const {
    propertyDetail: {
      activeProperty: { id },
      activeFloor,
    },
  } = getState() as RootState;
  const response = await client.mutate(CREATE_FLOOR(activeFloor, id));
  return response;
});
