import MProvisionPolicyUse from 'common/model/ProvisionPolicyUse';
import MProvisionTransactionUse from 'common/model/ProvisionTransactionUse';
import MProvisionUse from 'common/model/ProvisionUse';

export class ProvisionUse implements MProvisionUse {
  policies: MProvisionPolicyUse[];
  transactions: MProvisionTransactionUse[];

  constructor(input: MProvisionUse) {
    this.policies = input.policies;
    this.transactions = input.transactions;
  }

  static fragments(): string {
    return `
      policies {
        id
        name
        landlord {
          id
          name
        }
      }
      transactions {
        id
        identifier
        landlord {
          id
          name
        }
        policy {
          id
          name
        }
      }
    `;
  }
}
