import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import BreadCrumb, { BREAD_CRUMB_SIZE } from 'layouts/BreadCrumb';
import LandlordEntitiesList from 'components/LandlordEntitiesList';
import LandlordEntitiesHeaderToolbar from 'components/LandlordEntitiesList/LandlordEntitiesHeaderToolbar';
import TabContainer from 'components/TabContainer';
import EntityInformationTab from 'views/entities/components/Tabs/EntityInformationTab';
import { updateSelectedSubsidiary } from 'store/entities/entityDetailSlice';
import { EntityCreatorTypes } from 'common/api/entities';

const SubsidiariesTabs = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean | undefined>();

  const { selectedSubsidiary } = useAppSelector((state: RootState) => state.entityDetail);

  const breadCrumb = [
    {
      url: '/organisation/subsidiaries',
      title: 'Subsidiaries',
      onClick: () => {
        dispatch(updateSelectedSubsidiary(null));
      },
    },
    {
      title: selectedSubsidiary?.name ?? '',
    },
  ];

  const createURL = `/organisation/subsidiary/create`;
  return selectedSubsidiary ? (
    <EntityInformationTab
      title={
        <BreadCrumb
          size={BREAD_CRUMB_SIZE.SMALL}
          sections={breadCrumb}
        />
      }
      type={EntityCreatorTypes.Landlord}
      organisation={false}
      isChild={true}
    />
  ) : (
    <TabContainer tabToolbar={<LandlordEntitiesHeaderToolbar createURL={createURL} />}>
      <LandlordEntitiesList
        setIsLoading={setIsLoading}
        organisation={true}
      />
    </TabContainer>
  );
};

export default SubsidiariesTabs;
