import { useEffect, useRef, useState } from 'react';
import { Form, Popup } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { Icon } from '@iconify/react';
import { DropdownSelectTemplate } from 'atoms/DropdownSelect';
import PopUpInfo from 'atoms/PopUpInfo';
import { conditionalClassName } from 'utils/tsHelper';
import { checkRequired, runValidations } from 'utils/tsValidator';
import { checkNotEmpty } from 'utils/tsValidator';
import { labelResizeObserver } from 'utils/utils-html';
import { Icons } from 'utils/utils-icons';
import { generateUniqueId } from 'utils/utils-random';
import './Select.scss';

export interface SelectFieldProps {
  loading?: boolean;
  onOpen?: (data: any) => void;
  multiple?: boolean;
  clearable?: boolean;
  label?: string | null;
  search?: boolean;
  options?: any;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  dataTest?: string;
  value?: any;
  onChange?: (key: string, value: string) => void;
  fieldKey?: string;
  info?: boolean;
  onClick?: any;
  validationFuncs?: ValidationFuncs;
  runValidation?: boolean;
  validationDependant?: string | boolean;
  popUp?: boolean;
  infoText?: string;
  className?: string;
}

const SelectField = ({
  loading,
  onOpen,
  multiple,
  clearable,
  label,
  search,
  options,
  disabled,
  required,
  placeholder,
  value,
  onChange,
  fieldKey = '',
  info,
  onClick,
  validationFuncs,
  runValidation,
  validationDependant,
  dataTest,
  popUp,
  infoText,
  className,
}: SelectFieldProps): JSX.Element => {
  const selectValue = (value: any) => {
    return onChange ? onChange(fieldKey, value) : '';
  };

  const [error, setError] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);
  const [showLabelPopup, setShowLabelPopup] = useState(false);
  const [formContainerId] = useState<string>(generateUniqueId(''));

  const debouncedCheck = useRef(
    debounce(
      (requiredFunc, otherValidations, currentValue, required, setError) =>
        runValidations(requiredFunc, otherValidations, currentValue, required, setError),
      500,
    ),
  ).current;

  useEffect(() => {
    if (!disabled) {
      setTouched(false);
    } else {
      debouncedCheck(checkRequired, validationFuncs, value, false, setError);
    }
  }, [disabled]);

  useEffect(() => {
    if (!touched) {
      setTouched(true);
    }

    if (touched && !disabled) {
      debouncedCheck(checkRequired, validationFuncs, value, required, setError);
    }
  }, [value]);

  useEffect(() => {
    if (validationDependant && !runValidation) {
      setError('');
    }
  }, [validationDependant]);

  useEffect(() => {
    if (checkNotEmpty(value) && value !== undefined) {
      setTimeout(function () {
        setError('');
      }, 100);
    } else {
      if (disabled) {
        setError('');
      } else {
        if (runValidation) {
          runValidations(checkRequired, validationFuncs, value, required, setError);
        }
      }
    }
  }, [runValidation, disabled]);

  useEffect(() => {
    // Label popup control when label is truncated with an ellipsis
    labelResizeObserver(formContainerId, setShowLabelPopup);
  }, []);

  return (
    <Form.Field
      className="field-style select-atom-field"
      id={formContainerId}
    >
      {label && (
        <label>
          <Popup
            trigger={<span>{label}</span>}
            content={label}
            disabled={!showLabelPopup}
          />
          {required && <span className="required-indicator">*</span>}
          <PopUpInfo
            popUp={popUp}
            infoText={infoText}
            info={info}
          />
        </label>
      )}
      <div className="pos-rel">
        <DropdownSelectTemplate
          className={`${conditionalClassName(disabled, 'custom-disabled')} ${conditionalClassName(
            error,
            'error-field',
          )} ${conditionalClassName(className, className)} form-field-dropdown
          `}
          fieldKey={fieldKey}
          clearable={clearable}
          placeholder={placeholder}
          dataTest={dataTest}
          value={value}
          options={options}
          selection={true}
          search={search}
          multiple={multiple}
          onChange={(key, value) => selectValue(value)}
          onClick={onClick}
          loading={loading}
          onOpen={onOpen}
          upward={false}
        />

        {error && (
          <div className="input-validation-message error">
            <Icon
              icon={Icons.Warning}
              className="m-r-xxs"
            />
            {error}
          </div>
        )}
      </div>
    </Form.Field>
  );
};

export default SelectField;
