import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { PERMITTED_USE_OPTIONS, SPECIFIC_USE_OPTIONS } from 'utils/UI';

const UseForm = ({ mode }: ModeProps) => {
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: string) => {
    dispatch(updateActiveContractEventInput({ key, value: value || null }));
  };

  const disabled = mode === MODE_OPTIONS.READ;

  return (
    <div className="grid">
      <div className="col-2">
        <SelectField
          label="Permitted use"
          fieldKey="permittedUse"
          dataTest=""
          value={activeContractEvent?.permittedUse}
          disabled={disabled}
          options={PERMITTED_USE_OPTIONS}
          onChange={onChange}
          clearable={true}
        />
      </div>
      <div className="col-2">
        <SelectField
          label="Specific use"
          fieldKey="specificUse"
          dataTest=""
          value={activeContractEvent?.specificUse}
          disabled={disabled}
          options={SPECIFIC_USE_OPTIONS}
          onChange={onChange}
          clearable={true}
        />
      </div>
    </div>
  );
};

export default UseForm;
