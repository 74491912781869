import { useAppDispatch } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import { createContact } from 'store/entities/entityDetailSlice';
import ContactList from './ContactList';
import './Contacts.scss';

const Contacts = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const onCreateContact = () => dispatch(createContact());

  return (
    <>
      <div className="d-flex justify-space-between align-center m-t-l">
        <h2 className="color-blue-very-dark p-none">Contact Information</h2>
        <AddPlus
          label="Add Contact Person"
          onClick={onCreateContact}
          dataTest="contact-add-button"
        />
      </div>

      <ContactList />
    </>
  );
};

export default Contacts;
