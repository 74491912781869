import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import { UserClone } from 'utils/types/userManagement/users';
import './AssignedUsersToolTip.scss';

export interface AssignedUsersListingProps {
  id: string;
}

interface ValuesFromRolesType {
  user: UserClone;
}

const AssignedUsersListing = ({ id }: AssignedUsersListingProps) => {
  const {
    // @ts-ignore
    node: { members },
  } = useAppSelector((state: RootState) => state.roles.rolesList.find((element: any) => element.id === id));

  return (
    <ul className="listing pm-none">
      {members.map(({ user: { id, firstName, lastName, photoUrl } }: ValuesFromRolesType) => (
        <li
          className="item"
          key={id}
        >
          <div className="top">
            <Avatar
              src={photoUrl || ''}
              size={AvatarSize.Small}
            />
            <span className="listing-name color-blue-very-dark">{`${firstName} ${lastName}`}</span>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default AssignedUsersListing;
