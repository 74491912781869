import { PropertyClone } from 'common/_classes';
import { useEffect, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import Property from 'common/model/Property';
import { DropdownProps, MODE_OPTIONS } from 'common/api/miscellaneous';
import { deepClone, evalMeasurement } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { addSpaceOrComma } from 'utils/utils-number';
import { PremisesFormOnChangeFunction } from '..';
import { PremiseSelectedProps, onAddPremiseFloor } from '../utils-premiseForm';
import FloorRow from './FloorRow';
import './PremisesSelect.scss';

interface PremisesSelectProps {
  mode: MODE_OPTIONS;
  premiseIndex: number; // TODO : to be removed, should be dealt at the parent level.
  premises: PremiseSelectedProps[];
  onChange: PremisesFormOnChangeFunction;
}

interface FloorAreas {
  [key: string]: number; // TODO : could use 'Map'
  total: number;
}

const extractFloorDropdownOptions = (
  { floors }: PropertyClone,
  floorIds: PremiseSelectedProps['floorIds'],
): DropdownProps[] => {
  const selectedIds = floorIds.map(({ id }) => id);
  return floors.map(({ id, name }) => ({
    key: id ? id : '',
    text: name,
    value: id ? id : '',
    selected: selectedIds.includes(id as string),
  }));
};

const extractUnitsDropdownOptions = (floorId: string, property: PropertyClone | null): DropdownProps[] => {
  if (!property) return [];

  const { floors } = property;
  let options: DropdownProps[] = [];

  for (let i = 0; i < floors.length; i++) {
    const { id, spaces } = floors[i];

    if (id === floorId) {
      options = spaces.map(({ id, name }) => ({
        key: id ? id : '',
        text: name ? name : '',
        value: id ? id : '',
      }));
      break;
    }
  }

  return options;
};

const getFloorAreas = (property: PropertyClone, floorIds: PremiseSelectedProps['floorIds']): FloorAreas => {
  const floorAreas: FloorAreas = { total: 0 };

  for (let i = 0; i < floorIds.length; i++) {
    const { id: floorId, spaceIds } = floorIds[i];

    const floor = property?.floors.filter(({ id }) => floorId === id);

    if (floor.length > 0) {
      const { spaces } = floor[0];

      const spacesData = spaces.filter(({ id }) => spaceIds.includes(id as string));

      const totalFloorArea = spacesData.reduce(
        (previousValue: number, { grossArea }: any) => previousValue + parseFloat(grossArea ? grossArea : '0'),
        0,
      );

      floorAreas[floorId] = totalFloorArea;
      floorAreas.total += totalFloorArea;
    }
  }

  return floorAreas;
};

const PremisesSelect = ({ mode, premises, premiseIndex, onChange }: PremisesSelectProps): JSX.Element => {
  const [floorOptions, setFloorOptions] = useState<DropdownProps[]>([]);
  const [floorAreas, setFloorAreas] = useState<FloorAreas>({ total: 0 });
  const { floorIds, property } = premises[premiseIndex];

  const { id: propertyId, measurementMethod, measurementUnit } = property || { id: '' };

  const showBtn: boolean = floorIds.length === floorOptions.length;
  const emptyFloors: boolean = floorOptions.length === 0;

  const measurement: string =
    measurementUnit && measurementMethod ? evalMeasurement(measurementUnit, measurementMethod) : '';

  useEffect(() => {
    // UPDATE FLOOR OPTIONS AVAILABLE BASED ON THE PROPERTY SELECTED
    if (property && property.floors) {
      setFloorOptions(extractFloorDropdownOptions(property, floorIds));
    }
  }, [property, floorIds]);

  useEffect(() => {
    // UPDATE THE AREA OF EACH FLOOR SELECTED AND TOTAL OF THESE SELECTED FLOORS
    if (property) {
      setFloorAreas(getFloorAreas(property, floorIds));
    }
  }, [floorIds]);

  const onAddFloor = (premiseIndex: number): void => {
    const updatedPremises = deepClone(premises);
    const currentFloors = updatedPremises[premiseIndex].floorIds;

    updatedPremises[premiseIndex].floorIds = onAddPremiseFloor(currentFloors);

    onChange(updatedPremises);
  };

  return (
    <>
      {property && (
        <div className="premises-sub-section">
          {mode === MODE_OPTIONS.EDIT && emptyFloors && (
            <h5 className="sub-header">No floors available for this property</h5>
          )}
          {!emptyFloors && (
            <Grid className="sub-section">
              {floorIds.map(({ id }, index: number) => (
                <FloorRow
                  key={index}
                  firstRow={index === 0}
                  floorRowIndex={index}
                  mode={mode}
                  premises={premises}
                  floorOptions={floorOptions}
                  measurement={measurement}
                  propertyId={propertyId}
                  premiseIndex={premiseIndex}
                  haveDeleteButton={floorIds.length !== 1}
                  spaceArea={floorAreas[id] ? floorAreas[id] : 0}
                  getSpaceOptions={() => extractUnitsDropdownOptions(id, property)}
                  onChange={onChange}
                />
              ))}
              <Grid.Row className="total-row">
                <Grid.Column
                  width={12}
                  className="add-floor-container pm-none"
                >
                  {!showBtn && mode === MODE_OPTIONS.EDIT && (
                    <Button
                      className="add-floor-container border-sm-blue-very-dark bg-blue-very-dark-grayish m-t-xs"
                      data-test="add-new-floor"
                      onClick={() => onAddFloor(premiseIndex)}
                    >
                      <Icon
                        className="color-white"
                        icon={Icons.Add}
                      />
                      <span className="color-white m-l-xxs">ADD ANOTHER FLOOR</span>
                    </Button>
                  )}
                </Grid.Column>
                <Grid.Column
                  className="area"
                  data-test="total-input"
                  width={3}
                >
                  <span className="bold">Total : </span>
                  <span className="area-value">
                    {addSpaceOrComma(floorAreas.total, false)}
                    {measurement}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default PremisesSelect;
