import { IFormatterClone } from 'common/_classes';
import { useEffect, useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import NodeAlert from 'components/Editor/NodeAlert';
import ParameterMultiLevelSelect from 'components/ParameterMultiLevelSelect';
import {
  handleCurrentValue,
  handleMultiLevelSelector,
  handleMultilevelReturn,
} from 'components/ParameterMultiLevelSelect/multiLevelInputHelper';
import { updateForm, updateHandleEvent } from 'store/nodes/nodesSlice';
import NodeType from 'common/model/NodeType';
import { OptionItem } from 'utils/types/categories';
import { EventHandlerNode } from 'utils/types/nodes';
import { ReactComponent as Trash } from 'assets/images/svg/trash.svg';
import DuplicateNode from '../DuplicateNode';

const Parameters = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [renew, setRenew] = useState<boolean>(false);

  const { formattersList } = useAppSelector((state: RootState) => state.formattersListing);
  const { activeNode, node, nodeError } = useAppSelector((state: RootState) => state.nodes);
  const { parameterGroups, parameterTables, parameters } = useAppSelector((state: RootState) => state.multiLevelMenu);
  const {
    activeProvision: { id: provisionId },
  } = useAppSelector((state: RootState) => state.provisionDetail);

  useEffect(() => {
    if (renew) setRenew(false);
  }, [renew]);

  /**
   * Add new parameters even without a selected value
   */
  const handleIncreaseParameters = (list: OptionItem[], index: number): void => {
    const paramRef = handleMultilevelReturn(list);

    if (!node?.paramRefs || !paramRef) return;

    let newParameter = Object.fromEntries(Object.entries(paramRef).filter(([_, v]) => v !== null)) as typeof paramRef;

    let result = node.paramRefs;

    if (index < result.length) {
      result = result.map((oldParameter, indexCheck) => (index === indexCheck ? newParameter : oldParameter));
    } else {
      result = [...result];
      if (newParameter) result.push(newParameter);
    }
    handleChange('paramRefs', result);
  };

  /**
   * Remove parameter at index
   */
  const handleRemoveParameter = (index: number): void => {
    if (!node?.paramRefs) return;
    const { paramRefs } = node;
    setRenew(true);
    const result = paramRefs.filter((_, check) => check !== index);
    handleChange('paramRefs', result);
  };

  /**
   * Handle onChange for a new Formatter
   */
  const handleSelectedOption = () => {
    const optionSelected = formattersList.find(element => element.id === node?.formatterId);

    if (!optionSelected) return null;

    const { id, description } = optionSelected;
    return { id, description };
  };

  const selectedOption = handleSelectedOption();
  const handleHideOrShow = () => {
    return selectedOption ? 'formatter-flex' : 'formatter-none';
  };

  /**
   * Create a list to show the Formatter Functions
   */
  const handleListFormatter = () => {
    return formattersList
      .map((element: IFormatterClone) => {
        const { id: value, name: text } = element;
        if (text && value) return { key: value, text, value };
        return null;
      })
      .filter(formatter => formatter);
  };

  /**
   * Generic onChange for update redux form. This affects the editor too
   */
  const handleChange = (key: string, value?: any): void => {
    dispatch(updateForm({ key, value }));
  };

  /**
   * Add a new empty slot for parameters
   */
  const handleAddSlot = (): void => {
    if (!node?.paramRefs) return;
    const { paramRefs } = node;
    handleChange('paramRefs', [...paramRefs, null]);
  };

  const handleSaveParameter = (): void => {
    dispatch(updateHandleEvent(EventHandlerNode.EDIT_PARAMETER));
  };

  if (!activeNode && !nodeError) return <div>Loading...</div>;

  if (nodeError) {
    return <NodeAlert message="Alert, the node no longer exists in the database" />;
  }

  if (activeNode?.provision?.id !== provisionId) {
    return <NodeAlert message="Alert, fix the integrity of the node" />;
  }

  if (node?.type !== NodeType.Parameter) return <div>Select a Parameter</div>;

  const paramRefsNode = node?.paramRefs?.length ? node?.paramRefs : [null];

  return (
    <div
      className="param-container"
      data-test="param-container"
    >
      <Form
        className="param-container-form"
        autoComplete="off"
      >
        <Grid.Row>
          <h3 className="m-t-m">Parameter Node Name</h3>
        </Grid.Row>
        <Grid.Row>
          <InputField
            value={node.name}
            fieldKey="name"
            dataTest="parameter-name"
            onChange={handleChange}
          />
        </Grid.Row>
        <DuplicateNode
          nodeId={activeNode?.id}
          type={NodeType.Parameter}
          provisionId={String(provisionId)}
        />
        <Grid.Row>
          <h3>Select parameter(s)</h3>
        </Grid.Row>
        {paramRefsNode.map((param, index) => {
          if (renew) return null;

          return (
            <Grid.Row
              key={index}
              className="grid-row"
              data-test="parameter-row"
            >
              <span>{index + 1}.</span>
              <ParameterMultiLevelSelect
                currentValue={handleCurrentValue(param || undefined)}
                dropdown={handleMultiLevelSelector(parameterGroups, parameterTables, parameters)}
                onChange={result => handleIncreaseParameters(result, index)}
              />
              <div
                className="parameter-delete p-sm"
                data-test="delete-parameter"
                onClick={() => handleRemoveParameter(index)}
              >
                <Trash />
              </div>
            </Grid.Row>
          );
        })}
        <Grid.Row>
          <AddPlus
            onClick={handleAddSlot}
            label="Add"
            dataTest="add-parameter-button"
          />
        </Grid.Row>
        <div className="formatter-function-section">
          <h3>Formatter Function</h3>

          <Grid.Row className="grid-row m-none">
            <SelectField
              placeholder="Select a formatter function"
              fieldKey="formatterId"
              dataTest="select-formatter"
              value={selectedOption?.id || ''}
              options={handleListFormatter()}
              onChange={handleChange}
              clearable={false}
              search={true}
            />
            <div
              className="parameter-delete p-sm m-none"
              data-test="delete-formatter"
              onClick={() => handleChange('formatterId', '')}
            >
              <Trash />
            </div>
          </Grid.Row>
        </div>
        <div
          className={handleHideOrShow()}
          data-test="formatter-description"
        >
          <h3>Function Description</h3>
          <div
            className="editor-to-html"
            dangerouslySetInnerHTML={{
              __html: selectedOption?.description || '',
            }}
          />
        </div>
        <Grid>
          <Grid.Row>
            <Button
              className="btn grey-bg parameters-bar"
              data-test="save-button"
              onClick={handleSaveParameter}
            >
              SAVE
            </Button>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
};

export default Parameters;
