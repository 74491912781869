import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateInputKey } from 'store/entities/entityDetailSlice';
import { listIndustries, listSubsidiaries } from 'common/api/entities';
import { EntityCreatorTypes, EntityTypeProps } from 'common/api/entities';

const EntityGeneralForm = ({ entityType }: EntityTypeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeEntity, industryListOptions, subsidiariesListOptions } = useAppSelector(
    (state: RootState) => state.entityDetail,
  );
  const { name, nickname, address, identifier, industry, parentId, sharePercentage } = activeEntity;

  let industryValue; //TODO simplify this. is it an object or a string ?
  if (typeof industry === 'object' && industry !== null) {
    industryValue = industry?.id;
  } else {
    industryValue = industry;
  }

  const isTenant: boolean = entityType === EntityCreatorTypes.Tenant;

  const onChange = (key: string, value: string) => dispatch(updateInputKey({ key, value }));

  const [partiallyOwned, setPartiallyOwned] = useState<boolean>(
    !sharePercentage ? false : Number(sharePercentage) != 100,
  );
  useEffect(() => {
    if (partiallyOwned) {
      onChange('sharePercentage', '0');
    } else {
      onChange('sharePercentage', '100');
    }
  }, [partiallyOwned]);

  useEffect(() => {
    if (isTenant) {
      dispatch(listIndustries());
    }
    if (isSubsidiary) {
      dispatch(listSubsidiaries());
    }
  }, [entityType, dispatch]);

  const isSubsidiary = useLocation().pathname.includes('subsidiary');

  return (
    <>
      <h2 className="color-blue-very-dark">Details</h2>
      <Grid className="m-t-sm w-60">
        <Grid.Row className="p-none">
          <Grid.Column
            width="8"
            className="p-l-none"
          >
            {isSubsidiary && (
              <>
                <SelectField
                  label="Parent entity"
                  required={true}
                  placeholder="Select parent entity..."
                  data-test="parent-entity"
                  options={subsidiariesListOptions}
                  value={parentId}
                  search={true}
                  onChange={onChange}
                  fieldKey="parentId"
                />
                <CheckboxField
                  label={partiallyOwned ? 'Partially owned' : 'Wholly owned?'}
                  className="d-flex align-center p-t-sm"
                  dataTest=""
                  fieldKey=""
                  value={partiallyOwned}
                  toggle={true}
                  onChange={() => setPartiallyOwned(!partiallyOwned)}
                />
                {partiallyOwned && (
                  <InputField
                    label="Ownership %"
                    value={sharePercentage}
                    onChange={onChange}
                    fieldKey="sharePercentage"
                    dataTest="entity-ownership"
                  />
                )}
              </>
            )}
            <InputField
              label={`${entityType} legal name`}
              fieldKey="name"
              value={name || ''}
              placeholder="Enter legal name..."
              dataTest="entity-legal-name"
              onChange={onChange}
              required={true}
            />

            <InputField
              label={`${entityType} trading name`}
              fieldKey="nickname"
              value={nickname || ''}
              placeholder="Enter trading name..."
              dataTest="entity-trading-name"
              onChange={onChange}
              required={true}
            />

            {isTenant && (
              <SelectField
                label="Tenant industry"
                required={true}
                placeholder="Select industry..."
                data-test="entity-industry"
                options={industryListOptions}
                value={industryValue}
                search={true}
                onChange={onChange}
                fieldKey="industry"
              />
            )}
          </Grid.Column>

          <Grid.Column
            width="8"
            className="p-r-none"
          >
            {isTenant && (
              <InputField
                label={`${entityType} identifier`}
                fieldKey="identifier"
                value={identifier || ''}
                placeholder="Enter identifier..."
                dataTest="entity-identifier"
                onChange={onChange}
                required={true}
              />
            )}

            <TextAreaField
              label="Postal address"
              fieldKey="address"
              value={address || ''}
              placeholder="Enter postal address..."
              dataTest="entity-postal-address"
              onChange={onChange}
              required={true}
              style={{ height: '8.76rem' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default EntityGeneralForm;
