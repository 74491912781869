import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';
import './DateRangePicker.scss';

interface DateRangePickerProps {
  onChange: Function;
  format: string;
  value: Date[];
}
const DateRangePicker = ({ onChange, format, value }: DateRangePickerProps) => {
  return (
    <ReactDateRangePicker
      value={value as any}
      className="date-range-picker"
      format={format || 'dd/MM/yyyy'}
      onChange={event => onChange(event)}
    />
  );
};

export default DateRangePicker;
