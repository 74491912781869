import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface UpdateExistenceParameterProvisionMutationVariables {
  transactionId: string | undefined;
  answerIds: string[];
}

const UPDATE_EXISTENCE_PARAMETER_PROVISION = (transactionId: string | undefined, answerIds: string[]) => {
  const variables: UpdateExistenceParameterProvisionMutationVariables = {
    transactionId,
    answerIds,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $answerIds: [ID!]) {
        updateExistenceParameterProvision(transactionId: $transactionId, answerIds: $answerIds) {
          id
          transaction {
            id
          }
          provision {
            id
            name
            index
            provisionCategory {
              id
              name
            }
            contents {
              content
              documentTypeId
            }
            description
            updatedAt
            guideline {
              explanatoryNotes
              generalGuidance
              internalNotes
              valuationGuidance
            }
          }
          mandatory
          selected
        }
      }
    `,
    variables,
  };
};

export const updateExistenceParameterProvision = createAsyncThunk(
  'transactions/updateExistenceParameterProvision',
  async ({ answerIds }: { answerIds: string[] }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
    } = getState() as RootState;

    const response = await client.mutate(UPDATE_EXISTENCE_PARAMETER_PROVISION(activeTransaction.id, answerIds));
    return response;
  },
);
