import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { ParameterGroupClone } from 'common/_classes';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import { listParameterGroups, listParameterTablesPage, listParameters } from 'common/api/multiLevelMenu';

interface MultiLevelMenuState {
  parameterGroups: ParameterGroupClone[];
  parameters: Parameter[];
  parameterTables: ParameterTable[];
  isLoading: boolean;
}

const initialState: MultiLevelMenuState = {
  parameterGroups: [],
  parameters: [],
  parameterTables: [],
  isLoading: false,
};

const multiLevelMenuSlice = createSlice({
  name: 'multilevel',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<MultiLevelMenuState>) => {
    builder.addCase(listParameters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listParameters.fulfilled, (state, action) => {
      state.parameters = action.payload.listParameters.edges.map((parameter: { node: Parameter }) => parameter.node);
      state.isLoading = false;
    });
    builder.addCase(listParameters.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });
    builder.addCase(listParameterGroups.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listParameterGroups.fulfilled, (state, action) => {
      state.parameterGroups = action.payload.listParameterGroups;
      state.isLoading = false;
    });
    builder.addCase(listParameterGroups.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });
    builder.addCase(listParameterTablesPage.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listParameterTablesPage.fulfilled, (state, action) => {
      state.parameterTables = action.payload.listParameterTablesPage.edges.map(
        (parameterTable: { node: ParameterTable }) => parameterTable.node,
      );
      state.isLoading = false;
    });
    builder.addCase(listParameterTablesPage.rejected, (state, action) => {
      console.error(action.error);
      state.isLoading = false;
    });
  },
});

export default multiLevelMenuSlice.reducer;
