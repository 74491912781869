import moment from 'moment';
import { v4 } from 'uuid';

export interface IForecasts {
  id: string;
  referenceId: string;
  startDate: string;
  endDate: string;
  duration: number;
  buildingId: string;
  buildingName: string;
  marketRent: number;
  yoyRentGrowth: number;
  voidMonths: number;
  renewalRate: number;
  effectiveDowntime: number;
  newLeaseTerm: number;
  newLeaseRentFree: number;
  version: number;
  userId: string;
}

export class Forecasts implements IForecasts {
  id: string;
  referenceId: string;
  startDate: string;
  endDate: string;
  duration: number;
  buildingId: string;
  buildingName: string;
  marketRent: number;
  yoyRentGrowth: number;
  voidMonths: number;
  renewalRate: number;
  effectiveDowntime: number;
  newLeaseTerm: number;
  newLeaseRentFree: number;
  version: number;
  userId: string;

  constructor(data: IForecasts) {
    this.id = data.id;
    this.referenceId = data.referenceId;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.duration = data.duration;
    this.buildingId = data.buildingId;
    this.buildingName = data.buildingName;
    this.marketRent = data.marketRent;
    this.yoyRentGrowth = data.yoyRentGrowth;
    this.voidMonths = data.voidMonths;
    this.renewalRate = data.renewalRate;
    this.effectiveDowntime = data.effectiveDowntime;
    this.newLeaseTerm = data.newLeaseTerm;
    this.newLeaseRentFree = data.newLeaseRentFree;
    this.version = data.version;
    this.userId = data.userId;
  }

  static init() {
    return new Forecasts({
      id: v4(),
      referenceId: 'Q1 2024/2025',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().add(10, 'years').format('YYYY-MM-DD'),
      duration: 10,
      buildingId: '',
      buildingName: '',
      marketRent: 600,
      yoyRentGrowth: 1,
      voidMonths: 3,
      renewalRate: 12,
      effectiveDowntime: 2,
      newLeaseTerm: 24,
      newLeaseRentFree: 2,
      version: 1,
      userId: '',
    });
  }

  static fragment() {
    return `
      id
      referenceId
      startDate
      endDate
      duration
      buildingId
      buildingName
      marketRent
      yoyRentGrowth
      voidMonths
      renewalRate
      effectiveDowntime
      newLeaseTerm
      newLeaseRentFree
      version
      userId
      archived
    `;
  }

  static formatForAPI(forecast: Forecasts) {
    return {
      id: forecast.id,
      reference_id: forecast.referenceId,
      start_date: forecast.startDate,
      end_date: forecast.endDate,
      duration: forecast.duration,
      building_id: forecast.buildingId,
      market_rent: forecast.marketRent,
      yoy_rent_growth: forecast.yoyRentGrowth,
      void_months: forecast.voidMonths,
      renewal_rate: forecast.renewalRate,
      effective_downtime: forecast.effectiveDowntime,
      new_lease_term: forecast.newLeaseTerm,
      new_lease_rent_free: forecast.newLeaseRentFree,
      version: forecast.version,
      user_id: forecast.userId,
    };
  }
}
