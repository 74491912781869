import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import AuthLayout from 'layouts/AuthLayout';
import ContinueWithEmail from './ContinueWithEmail';
import '../authentication.scss';

const AlternateEmail = () => {
  const { isLoading } = useAppSelector((state: RootState) => state.auth);
  return (
    <AuthLayout
      loading={isLoading}
      form={<ContinueWithEmail />}
    />
  );
};

export default AlternateEmail;
