import { useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { discardMemberCreation } from 'store/members/membersSlice';
import {
  CreatedMemberInput,
  createMember,
  updateMemberResendInvite,
  updateMemberStatusAndRole,
} from 'common/api/members';
import { ContentProps, ModalProps } from 'utils/types/userManagement/users';
import { inviteUserValidation } from '../../userValidator';
import InviteMemberForm from './InviteMemberForm';

const Content = ({ setOpen, createMode }: ContentProps) => {
  const dispatch = useAppDispatch();
  const { activeInvitedUser, membersList } = useAppSelector((state: RootState) => state.members);
  const [initialEmail] = useState<string>(activeInvitedUser.email);

  const onSave = (): void => {
    if (createMode) {
      // Create a member (Invites a user)
      dispatch(createMember());
    } else if (initialEmail !== activeInvitedUser.email) {
      // Update a member (Member is already created, only update email and resend invitation email)
      dispatch(updateMemberResendInvite());
    } else {
      // Update a member (Update everything except email)
      const invitedUser = activeInvitedUser as CreatedMemberInput;
      const member = membersList.find(member => member.id === invitedUser.memberId);

      if (member) {
        dispatch(
          updateMemberStatusAndRole({
            memberId: member.id,
            params: {
              entityId: member.entity.id,
              roleId: member.role.id,
              status: member.status,
              userId: member.user.id,
            },
          }),
        );
      }
    }

    if (setOpen) setOpen(false);
  };

  let btnText: string;

  if (createMode) {
    btnText = 'SEND INVITATION';
  } else if (initialEmail !== activeInvitedUser.email) {
    btnText = 'RESEND INVITATION';
  } else {
    btnText = 'UPDATE MEMBER';
  }

  return (
    <Form>
      <Grid className="pm-none">
        <Grid.Row className="p-none">
          <Grid.Column
            width={16}
            className="pm-none"
          >
            <h3
              className="section-title"
              data-test="section-title"
            >
              Details
            </h3>
            <InviteMemberForm />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="m-t-sm pm-none w-100 d-flex justify-center">
          <div className="d-flex flex-gap-m">
            <Button
              className="cancel-btn"
              onClick={() => setOpen && setOpen(false)}
            >
              CANCEL
            </Button>
            <Button
              className="btn grey-bg invite-user height-2_857"
              disabled={inviteUserValidation(activeInvitedUser)}
              onClick={onSave}
            >
              {btnText}
            </Button>
          </div>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const InviteUserModal = ({ trigger, createMode }: ModalProps) => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = (value: boolean) => {
    setIsOpen(value);

    if (!value) {
      dispatch(discardMemberCreation());
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onToggleModalStatus={toggleModal}
      trigger={trigger}
      title={`${createMode ? 'Invite New User' : 'Invitation Details'}`}
    >
      <Content
        createMode={createMode}
        setOpen={toggleModal}
      />
    </ModalTemplate>
  );
};

export default InviteUserModal;
