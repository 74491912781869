/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ConfirmPopup from 'atoms/ConfirmPopup';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import PolicyRegulatories from 'components/PolicyRegulatories';
import 'views/provisions/CreatorViewer/Tabs/GeneralTab/ProvisionGeneralForm/ProvisionGeneralForm.scss';
import { updateActivePolicy } from 'store/policies/policyDetailSlice';
import { DocumentTypeClone, listCountries, listLanguages } from 'common/api/miscellaneous';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION, USE_TYPE_OPTIONS } from 'utils/UI';

const PolicyGeneralForm = ({ mode }: ModeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const checkCreate: boolean = location.pathname.includes('create');

  const [escrow, setEscrow] = useState<null | { key: string; value: string }>(null);
  const [regulatoriesOpen, setRegulatoriesOpen] = useState<boolean>(false);
  const [warningDisabled, setWarningDisabled] = useState<boolean>(checkCreate);

  const { activePolicy } = useAppSelector((state: RootState) => state.policyDetail);

  const { documentTypesOptions, documentTypesList, countries, languages } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );

  const { name, documentTypeIds, description, regulatories, useType, jurisdiction, language } = activePolicy;

  const onChange = (key: string, value: any) => dispatch(updateActivePolicy({ key, value }));

  const storeAndAwaitConfirmation = (key: string, value: string) => setEscrow({ key, value });

  const makeChange = (): void => {
    onChange(escrow?.key!, escrow?.value);
    setEscrow(null);
    setWarningDisabled(true);
  };

  const onCancel = (): void => setEscrow(null);

  const sortDocs = (docIds: string[]): string[] => {
    const listOfIds: string[] = documentTypesList.map((documentType: DocumentTypeClone) => documentType.id);
    return [...docIds].sort((a, b) => listOfIds.indexOf(a) - listOfIds.indexOf(b));
  };

  const sortedDocIds: string[] = sortDocs(documentTypeIds);

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(listCountries());
    }
    if (!languages.length) {
      dispatch(listLanguages());
    }
  }, [dispatch]);

  const languagesOptions: DROPDOWN_OPTION[] = languages.map((language: string, index: number) => {
    const result: DROPDOWN_OPTION = {
      key: index,
      text: language,
      value: language,
    };
    return result;
  });

  const onOpen = (): void => setRegulatoriesOpen(true);
  const onClose = (): void => setRegulatoriesOpen(false);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Policy Name"
            value={name}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="name"
            dataTest="policy-name"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <SelectField
            label="Applicable documents"
            value={sortedDocIds}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            placeholder="Select..."
            multiple={true}
            options={documentTypesOptions}
            onChange={onChange}
            fieldKey="documentTypeIds"
            dataTest="policy-applicable-documents"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <Grid.Column width={8}>
            <TextAreaField
              label="Description"
              value={description}
              disabled={mode === MODE_OPTIONS.READ}
              onChange={onChange}
              fieldKey="description"
              dataTest="policy-description"
            />
          </Grid.Column>
          <Grid.Column
            width={8}
            className="m-t-xl"
          >
            <SelectField
              label="Use Type"
              value={useType}
              disabled={mode === MODE_OPTIONS.READ}
              placeholder="Select use type"
              options={USE_TYPE_OPTIONS}
              onChange={warningDisabled ? onChange : storeAndAwaitConfirmation}
              clearable={true}
              fieldKey="useType"
              dataTest="policy-use-type"
            />
          </Grid.Column>

          <Grid.Column
            width={8}
            className="m-t-xl"
          >
            <SelectField
              label="Language"
              value={language}
              disabled={mode === MODE_OPTIONS.READ}
              placeholder="Select language"
              options={languagesOptions}
              onChange={warningDisabled ? onChange : storeAndAwaitConfirmation}
              clearable={true}
              fieldKey="language"
              dataTest="policy-language"
            />
          </Grid.Column>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Column width={8}>
            <PolicyRegulatories
              mode={mode}
              regulatories={regulatories}
              editable={true}
              onChange={onChange}
              onOpen={onOpen}
              onClose={onClose}
            />
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{ marginTop: `${regulatoriesOpen ? '4.9rem' : '9.8rem'}` }}
          >
            <SelectField
              label="Jurisdiction"
              value={jurisdiction}
              disabled={mode === MODE_OPTIONS.READ}
              placeholder="Select jurisdiction"
              options={countries}
              onChange={warningDisabled ? onChange : storeAndAwaitConfirmation}
              clearable={true}
              fieldKey="jurisdiction"
              dataTest="policy-jurisdiction"
            />
          </Grid.Column>
        </Grid.Column>
      </Grid.Row>
      <ConfirmPopup
        trigger={!warningDisabled && escrow !== null}
        confirmButtonText="Yes, make change"
        cancelButtonText="No"
        onConfirm={makeChange}
        onCancel={onCancel}
        content="Warning, changing this value will cause the list of provisions used (if any), and the sequence (if any) to be cleared.
        <br>
        <br>
        Do you want to continue with change?"
      />
    </Grid>
  );
};

export default PolicyGeneralForm;
