import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import Badge, { BadgeColor } from 'atoms/Badge';
import {
  getApprovalTransaction,
  getSenderName,
  getSenderPhoto,
  getTransactionUrl,
  goToTransaction,
} from 'components/NotificationComponent/utils.notification';
import Notification from 'common/model/Notification';

const ApprovalRefused = (notification: Notification) => {
  const transaction = getApprovalTransaction(notification.infoApproval);

  const isTransactionDeleted: boolean = transaction === null;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const url: string = getTransactionUrl(transaction?.id);

  return (
    <div className="info-details">
      <Avatar
        size={AvatarSize.Medium}
        src={getSenderPhoto(notification.infoSender)}
      />

      {isTransactionDeleted ? (
        <span className="text blackish">
          Your <span className="text bold-orange">approval request</span> was declined by{' '}
          {getSenderName(notification.infoSender)} but{' '}
          <Badge badgeColor={BadgeColor.RED}>This transaction has been deleted</Badge>
        </span>
      ) : (
        <span className="text blackish">
          Your <span className="text bold-orange">approval request</span> for{' '}
          <a
            href={url}
            onClick={e => goToTransaction(e, dispatch, navigate, url)}
          >
            <span className="text bold-orange">Transaction {transaction.identifier}</span>
          </a>{' '}
          has been declined by {getSenderName(notification.infoSender)}
        </span>
      )}
    </div>
  );
};

export default ApprovalRefused;
