import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface GetTransactionChannelQueryVariables {
  transactionChannelId: string;
}

const GET_TRANSACTION_CHANNEL = (id: string | undefined) => {
  if (!id) throw new Error('Get Transaction Channel: transactionChannelId is required');

  const variables: GetTransactionChannelQueryVariables = {
    transactionChannelId: id,
  };

  return {
    query: gql`
      query ($transactionChannelId: ID!) {
        getTransactionChannel(transactionChannelId: $transactionChannelId) {
          id
          title
          type
          status
          documentTypeId
          node {
            id
          }
          transaction {
            id
          }
          amendment {
            id
          }
          insertedAt
        }
      }
    `,
    variables,
  };
};

export const getTransactionChannel = createAsyncThunk(
  'transactions/getTransactionChannel',
  async ({ id }: { id: string | undefined }) => {
    const response = await client.query(GET_TRANSACTION_CHANNEL(id));
    return response;
  },
);
