import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';

interface AlertProps {
  isSimulation?: boolean;
}
const Alert = ({ isSimulation }: AlertProps) => {
  const simulationText =
    'Attention, this is a simulation of an import. no data has been saved. the following report is the one that would be have been generated if you deactivated the simulation mode.';
  const importText = 'Attention, this is not a simulation of an import. all data has been saved.';

  return (
    <div className={`alert ${isSimulation ? 'amber' : 'error'}`}>
      <Icon icon={Icons.Warning} />
      <span>{isSimulation ? simulationText : importText}</span>
    </div>
  );
};

export default Alert;
