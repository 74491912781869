import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface ChannelMessage {
  message: string;
  transactionChannelId: string;
}

interface CreateTransactionMessagesMutationVariables {
  params: ChannelMessage[];
}

const CREATE_TRANSACTION_MESSAGES = (params: ChannelMessage[]) => {
  const variables: CreateTransactionMessagesMutationVariables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: [ChannelMessageInput!]) {
        createTransactionMessages(params: $params) {
          id
        }
      }
    `,
    variables,
  };
};

export const createTransactionMessages = createAsyncThunk(
  'transactions/createTransactionMessages',
  async (_args, { getState }) => {
    const {
      transactionDetail: { transactionApprovalAnswers },
    } = getState() as RootState;
    let params = [] as ChannelMessage[];
    let discussionApproval = [] as string[];
    for (let i = 0; i < transactionApprovalAnswers.length; i++) {
      const approval = transactionApprovalAnswers[i].transactionDiscussionApproval;
      const approvalId = approval?.id;

      if (approvalId && !discussionApproval.includes(approvalId)) {
        discussionApproval.push(approvalId);
        const message = transactionApprovalAnswers[i].transactionMessage;
        params.push({
          message: approval.draftMessage,
          transactionChannelId: message.transactionChannel.id,
        });
      }
    }
    if (params.length === 0) {
      return;
    }

    const response = await client.mutate(CREATE_TRANSACTION_MESSAGES(params));
    return response;
  },
);
