import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import TabContainer from 'components/TabContainer';
import { updatePolicyViewDetailsTab } from 'store/policies/policyDetailSlice';
import { listPolicyProvisions, listPolicySequences, updatePolicyContent } from 'common/api/policies';
import { Icons } from 'utils/utils-icons';
import { POLICY_TABS_OFFSET } from '../PolicyTabs';
import UnusedProvisionsCards from './UnusedProvisionsCards';
import UsedProvisionsCards from './UsedProvisionsCards';
import './ProvisionsTab.scss';

const ProvisionsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [showCatalogue, toggleCatalogue] = useState<boolean>(false);
  const { activePolicy } = useAppSelector((state: RootState) => state.policyDetail);

  const updateContent = (): void => {
    dispatch(updatePolicyContent());
  };

  useEffect(() => {
    dispatch(updatePolicyViewDetailsTab({ tab: POLICY_TABS_OFFSET.PROVISIONS }));
    dispatch(listPolicyProvisions({ policyId: activePolicy.id }));
    dispatch(listPolicySequences({ policyId: activePolicy.id }));
  }, []);

  const ProvisionsTabToolbar = (): React.ReactElement => {
    return (
      <>
        <Button
          className="btn grey-outline"
          data-test="update-content-button"
          onClick={() => updateContent()}
        >
          UPDATE CONTENT
        </Button>
        <Button
          className="btn grey-outline"
          data-test="add-provision-button"
          onClick={() => toggleCatalogue(true)}
        >
          <Icon icon={Icons.Add} />
          PROVISIONS
        </Button>
      </>
    );
  };

  return (
    <TabContainer
      tabTitle="Provisions"
      tabToolbar={<ProvisionsTabToolbar />}
    >
      <div className="policy-provisions-tab">
        <UsedProvisionsCards />
        {/* Unused Provisions */}
        {showCatalogue ? <UnusedProvisionsCards onClose={() => toggleCatalogue(false)} /> : ''}
      </div>
    </TabContainer>
  );
};

export default ProvisionsTab;
