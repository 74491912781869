import { Editor } from 'tinymce';
import { Icon } from '@iconify/react';
import { ContentNodeType, NodeInputProps } from 'common/api/nodes';

export const updateParameter = (editor: Editor, form: NodeInputProps) => {
  const { id, name } = form;
  const [node] = editor.dom.select(`[data-node-id="${id}"]`);

  if (!node) return;

  node.innerHTML = name || '';
};

export const deleteActiveNode = (editor: Editor, form: NodeInputProps) => {
  const [node] = editor.dom.select(`[data-node-id="${form.id}"]`);

  if (!node) return;

  const { innerText } = node;
  editor.dom.remove(node);
  editor.insertContent(innerText);
};

export const updatedConditionActiveNode = (editor: Editor, form: NodeInputProps, iteration: boolean) => {
  const { id, conditions } = form;

  if (!editor || !id) return;

  const queryId = `div[data-node-id="${id}"]`;
  let element = editor.dom.getRoot().querySelector(queryId);
  if (iteration) {
    element?.setAttribute('data-node-iterable', 'true');
  } else if (element?.getAttribute('data-node-iterable')) {
    element?.removeAttribute('data-node-iterable');
  }

  if (!element) return;

  let hasConditions = conditions?.length !== 0;

  // SOURCE: https://bobbyhadz.com/blog/javascript-get-element-by-attribute#get-dom-element-by-partial-match-of-an-attribute
  const queryIndex = 'div[data-node-type^="clause-index"]';
  const elementIndex = element.querySelector(queryIndex);
  const queryCondition = 'div[data-condition="true"]';

  const queryConditionIteration = 'span[data-iteration="true"]';
  const existIterationTag = elementIndex?.querySelector(queryConditionIteration);

  const existConditionTag = elementIndex?.querySelector(queryCondition);

  // Add Condition ICON
  if (!existConditionTag && hasConditions) {
    const { length } = Array.from(elementIndex?.childNodes || []);
    // clause-index-X is used in the tinymce-style.css file to add space around the 'C' of clauses with conditions
    elementIndex?.setAttribute('data-node-type', `clause-index-${length + 1}`);

    if (elementIndex) elementIndex.innerHTML += '<div data-condition="true">C</div>';
  } else if (existConditionTag && !hasConditions) {
    // Remove Condition ICON
    const node = elementIndex?.querySelector(queryCondition);
    node?.remove();

    elementIndex?.setAttribute('data-node-type', ContentNodeType.CLAUSE_INDEX);
  }

  const IconCircleArrow =
    '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="0.8em" viewBox="0 0 14 14"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><path d="m9.25.5l2.5 2.5l-2.5 2.5"></path><path d="M12.25 8.25A5.25 5.25 0 1 1 7 3h4.75"></path></g></svg>';

  const iterationIconHTML = `<span data-iteration="true">${IconCircleArrow}</span>`;

  // Add Iteration ICON
  if (iteration && !existIterationTag) {
    if (elementIndex) elementIndex.innerHTML += iterationIconHTML;
  } else if (!iteration && existIterationTag) {
    // Remove Iteration ICON
    const node = elementIndex?.querySelector(queryConditionIteration);
    node?.remove();
  }

  if (elementIndex) {
    const iterationIndex = elementIndex.innerHTML.indexOf('<span data-iteration="true">');

    if (hasConditions && iteration && iterationIndex === 2) {
      // Get the part of the string before the <span>
      const clauseNumber =
        iterationIndex === 2 ? elementIndex.innerHTML.slice(0, iterationIndex) : elementIndex.innerHTML;
      elementIndex.innerHTML = `${clauseNumber}<div data-condition="true">C</div><span data-iteration="true">${IconCircleArrow}</span>`;
    }
  }
};
