import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkIfAdminURL } from 'utils/utils-admin';

export const isAuthenticated = (element: ReactNode) => {
  const token = localStorage.getItem('sericin_app_token');
  const selectedEntity = localStorage.getItem('sericin_selected_entity');
  const location = useLocation();

  if (token === null) {
    return (
      <Navigate
        to="/sign-in"
        state={{ previousUrl: location.pathname }}
      />
    );
  } else {
    if (checkIfAdminURL()) {
      return element;
    }

    if (selectedEntity !== null) {
      return element;
    } else {
      const checkUrl = window.location.pathname;
      if (checkUrl === '/landlord-entities/create' || checkUrl === '/profile') {
        return element;
      } else {
        return <Navigate to="/home" />;
      }
    }
  }
};

export const isRegistration = (element: ReactNode) => {
  const token = localStorage.getItem('sericin_app_token');
  const selectedEntity = localStorage.getItem('sericin_selected_entity');

  if (token === null) {
    return element;
  } else {
    if (selectedEntity !== null) {
      return <Navigate to="/home" />;
    } else {
      return <Navigate to="/welcome" />;
    }
  }
};

export const isWelcome = (element: ReactNode) => {
  const token = localStorage.getItem('sericin_app_token');
  const selectedEntity = localStorage.getItem('sericin_selected_entity');
  if (token === null) {
    return <Navigate to="/sign-in" />;
  } else {
    if (selectedEntity !== null) {
      return <Navigate to="/home" />;
    } else {
      return element;
    }
  }
};
