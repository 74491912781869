import { ReactNode, useState } from 'react';
import DropdownCardMenu, { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import './CardHeader.scss';

interface CardHeaderProps {
  hexColor?: string;
  dropdownItems?: DropdownMenuItem[];
  headerTemplate: ReactNode;
  onClick: () => void;
}

const CardHeader = ({ hexColor, dropdownItems, headerTemplate, onClick }: CardHeaderProps): JSX.Element => {
  const [showActions, updateShowActions] = useState<boolean>(false);

  return (
    <div
      className="card-header"
      data-test="card-header"
      style={{ background: hexColor || '' }}
      onClick={onClick}
    >
      <div
        className="card-header-template"
        onMouseEnter={() => updateShowActions(true)}
        onMouseLeave={() => updateShowActions(false)}
        data-test="card-header-template"
      >
        <div className="title-template">{headerTemplate}</div>
        {showActions && dropdownItems && dropdownItems.length ? <DropdownCardMenu dropdownItems={dropdownItems} /> : ''}
      </div>
    </div>
  );
};

export default CardHeader;
