import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { DefaultFilterRowProps } from '.';
import { FilterOnchangeKeys } from '../RowTemplate/RowProps';

const PermittedUse = ({ onChange, values }: DefaultFilterRowProps) => {
  return (
    <div className="grid">
      <div className="col-2 d-flex align-center">
        <label className="font-600">Permitted use</label>
      </div>

      <div className="col-10">
        <div className="grid">
          <div className="col-5">
            <div className="grid">
              <div className="col-6">
                <MultiSelect
                  appendTo="self"
                  value={values.filterValue}
                  options={values.options}
                  itemTemplate={(option: any) => option.text}
                  onChange={(e: MultiSelectChangeEvent) =>
                    onChange(`${values.id},${FilterOnchangeKeys.filterValue}`, e.value)
                  }
                  placeholder="Value"
                  className="p-column-filter w-100"
                  dataKey="value"
                  optionLabel="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermittedUse;
