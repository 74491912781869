import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ProvisionInfoModal from 'components/ProvisionInfoModal';
import TabContainer from 'components/TabContainer';
import { listAmendments, listTransactionProvisions } from 'common/api/transactions';
import Amendments from './Amendments';
import ProvisionsBox from './ProvisionsBox';
import './ProvisionsTab.scss';

const ProvisionsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeModalProvision } = useAppSelector((state: RootState) => state.provisionsListing);
  const {
    activeTransaction: { id: transactionId },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  useEffect(() => {
    dispatch(listAmendments());
    dispatch(listTransactionProvisions({ transactionId }));
  }, []);

  return (
    <TabContainer tabTitle="Provisions">
      <div className="transaction-provision-tab">
        {/* Selected Provisions */}
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              {/* Provisions */}
              <ProvisionsBox />
            </Grid.Column>
            <Grid.Column width={8}>{<Amendments />}</Grid.Column>
          </Grid.Row>
        </Grid>

        {/* Modal to show details */}
        {activeModalProvision !== null && <ProvisionInfoModal />}
      </div>
    </TabContainer>
  );
};

export default ProvisionsTab;
