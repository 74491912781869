import { useState } from 'react';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import ApprovalChanges from '../ApprovalChanges';

export enum APPROVAL_INNER_TABS {
  GROUPS = 0,
  LIST = 1,
}

const getTabMenuConfig = (): TabMenuProps[] => {
  return [
    {
      key: 'transaction-approval-groups',
      label: 'Groups',
      customTab: <ApprovalChanges />,
    },
    {
      key: 'transaction-approval-list',
      label: 'List',
      customTab: <></>,
    },
  ];
};

const ApprovalInnerTabs = () => {
  const onTabChange = (tab: string | number | undefined) => {
    setActiveTabIndex(tab as APPROVAL_INNER_TABS);
  };

  const [activeTabIndex, setActiveTabIndex] = useState<APPROVAL_INNER_TABS>(APPROVAL_INNER_TABS.GROUPS);

  return (
    <TabReact
      className="approval-inner-tabs"
      activeTabIndex={activeTabIndex}
      customTabChangeAction={onTabChange}
      tabMenu={getTabMenuConfig()}
      nested={true}
    />
  );
};

export default ApprovalInnerTabs;
