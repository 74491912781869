import { ParameterChoiceBase } from 'common/_classes';
import { Fragment } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { orderBy } from 'lodash';

const MultiChoices = ({ choices }: { choices: ParameterChoiceBase[] }): JSX.Element => {
  const orderedChoices: ParameterChoiceBase[] = orderBy(choices, 'index', 'asc');
  return (
    <>
      {orderedChoices?.map((choice: ParameterChoiceBase, index: number) => (
        <Fragment key={index}>
          <Checkbox
            label={choice.text}
            name="surrenderVariant"
            value={choice.id}
          />
          <br />
        </Fragment>
      ))}
      <Checkbox
        className="none-option"
        label="None"
        name="surrenderVariant"
        value=""
      />
    </>
  );
};

export default MultiChoices;
