/*
 * Definition of the column (names, format...)
 */
import moment from 'moment';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import { filterElementDate, filterElementNumberRange, filterElementText } from 'atoms/TableReactPrime/columnTemplates';
import AttachedUsersToolTip from 'views/userAccountManagement/AdminEntities/AttachedUsersToolTip';
import EntityWithJoinForAdmin from 'common/model/EntityWithJoinForAdmin';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';
import { AdminEntitiesRowActionButtons } from './AdminEntitiesRowActionButtons';

export class AdminEntitiesRow {
  id?: string;
  name: string | null;
  identifier: string | null;
  type: string;
  businessAddress: string;
  contactInfo: string;
  membersCount: number;
  contractCount: number;
  propertyCount: number;
  insertedAt: Date;
  status: string;
  activity: string;

  constructor(adminEntity: EntityWithJoinForAdmin) {
    const contact = adminEntity.contacts[0];
    const contactInfo: string = `${contact.firstName} ${contact.lastName} ${contact.email}`;
    this.id = adminEntity.id;
    this.name = adminEntity.name;
    this.type = adminEntity.type;
    this.identifier = adminEntity.identifier;
    this.activity = replaceUnderscoreWithSpace(adminEntity.activity.toString());
    this.businessAddress = adminEntity.businessAddress;
    this.contactInfo = contactInfo;
    this.membersCount = adminEntity.members.length;
    this.contractCount = adminEntity.contractCount;
    this.propertyCount = adminEntity.propertyCount;
    this.insertedAt = new Date(adminEntity.insertedAt);
    this.status = '---';
  }
}

export const adminEntitiesColumnConfig = (
  typesOfEntity: string[],
  typesOfEntityActivity: string[],
): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Name of entity',
      filter: true,
      filterPlaceholder: 'Search by Name of entity',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      mandatory: true,
      width: '20rem',
    },
    {
      field: 'identifier',
      header: 'Identifier',
      filter: true,
      filterPlaceholder: 'Identifier',
      filterField: 'identifier',
      sortable: true,
      dataType: 'text',
      isHide: true,
      mandatory: false,
    },
    {
      field: 'activity',
      header: 'Activity of entity',
      filter: true,
      filterPlaceholder: 'Search by Activity of entity',
      filterField: 'activity',
      sortable: true,
      filterElement: options =>
        filterElementText(
          options,
          typesOfEntityActivity.map(x => replaceUnderscoreWithSpace(x)),
        ),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      field: 'type',
      header: 'Type of entity',
      filter: true,
      filterPlaceholder: 'Search by Type of entity',
      filterField: 'type',
      sortable: true,
      filterElement: options => filterElementText(options, typesOfEntity),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },

    {
      field: 'businessAddress',
      header: 'Postal address',
      filter: true,
      filterPlaceholder: 'Search by Postal address',
      filterField: 'businessAddress',
      sortable: true,
    },
    {
      field: 'contactInfo',
      header: '1st contact person',
      filterPlaceholder: 'Search by 1st contact person',
      filter: true,
      filterField: 'contactInfo',
      sortable: true,
      width: '30rem',
    },
    {
      field: 'membersCount',
      header: 'Numbers of users attached',
      filter: true,
      filterPlaceholder: 'Search by Numbers of users attached',
      filterField: 'membersCount',
      sortable: true,
      dataType: 'numeric',
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      showFilterMatchModes: false,
      body: row => (
        <AttachedUsersToolTip
          trigger={<span className="cursor-default">{row.membersCount}</span>}
          id={row.id}
          disabled={!row.membersCount || row.membersCount === 0}
        />
      ),
    },
    {
      field: 'contractCount',
      header: 'Numbers of Contracts',
      filterPlaceholder: 'Search by Numbers of Contracts',
      filter: true,
      filterField: 'contractCount',
      sortable: true,
      dataType: 'numeric',
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      showFilterMatchModes: false,
    },
    {
      field: 'propertyCount',
      header: 'Numbers of Properties',
      filter: true,
      filterPlaceholder: 'Search by Numbers of properties',
      filterField: 'propertyCount',
      sortable: true,
      dataType: 'numeric',
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      showFilterMatchModes: false,
    },
    {
      field: 'insertedAt',
      header: 'Created at',
      filterPlaceholder: 'Search by Created at',
      filter: true,
      filterField: 'insertedAt',
      sortable: true,
      dataType: 'date',
      body: (row: AdminEntitiesRow) => {
        return moment(row.insertedAt).format('DD/MM/YYYY');
      },
      filterElement: filterElementDate,
    },
    {
      field: 'status',
      header: 'Status',
      filter: false,
      sortable: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      frozen: true,
      alignFrozen: 'right',
      body: (row: AdminEntitiesRow) => <AdminEntitiesRowActionButtons rowId={row.id || ''} />,
    },
  ];
};
