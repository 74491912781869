import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DuplicateProvisionQueryVariables {
  id: string;
}

export const DUPLICATE_PROVISION = (id: string | undefined) => {
  if (!id) throw new Error('Duplicate Provision: id is required');

  const variables: DuplicateProvisionQueryVariables = {
    id,
  };

  return {
    query: gql`
      query ($id: ID!) {
        duplicateProvision(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const duplicateProvision = createAsyncThunk(
  'provisions/duplicateProvision',
  async ({ id }: { id: string | undefined }) => {
    const response = await adminClient.query(DUPLICATE_PROVISION(id));
    return response;
  },
);
