import { store } from 'store';
import { Editor } from 'tinymce';
import { EditorEvents } from 'store/editor/setup';
import { updateShowContext } from 'store/nodes/nodesSlice';
import { ContentNodeType } from 'common/api/nodes';
import { KeyboardKeys } from 'utils/utils-clean-input';

/**
 * This method is used on the onChangeEvent from Provision Editor
 * Prevents clause deletion
 */
export const preventBackspace = (editor: Editor) => {
  // This isPressing var is used to prevent longpress feature with backspace
  let isPressing = 0;

  editor.on(EditorEvents.Keydown, ({ key, preventDefault }) => {
    // Check if the current PRESSED key is Backspace or Delete
    if (key === KeyboardKeys.Backspace || key === KeyboardKeys.Delete) {
      if (isPressing === 0) {
        isPressing += 1;
      } else {
        console.warn('[EDITOR] - Prevent by continuous pressing');
        preventDefault();
      }
    }
  });

  editor.on(EditorEvents.Keyup, ({ key, code, preventDefault }) => {
    store.dispatch(updateShowContext(false));

    let node: Element = editor.selection.getNode();
    let nodeType = node.getAttribute('data-node-type') as ContentNodeType;

    // Enter space at the end of node

    if (code === KeyboardKeys.Space) {
      if ([ContentNodeType.PARAMETER, ContentNodeType.TEXT].includes(nodeType)) {
        const currentSelection = editor.selection.getSel();

        if (currentSelection !== null && currentSelection.anchorNode !== null) {
          const anchorOffset = currentSelection.anchorOffset;
          const nodeLength = (currentSelection.anchorNode as any).length;

          // Check if at the end of node
          if (anchorOffset === nodeLength) {
            const innerHTML = node.innerHTML;
            if (innerHTML.slice(-6) !== '&nbsp;') {
              node.innerHTML = innerHTML + '&nbsp;';
              editor.selection.select(node.lastChild as ChildNode, true);
              editor.selection.collapse(false);
            }
          }
        }
      }
    }

    // Check if the current RELEASED key is Backspace or Delete
    if (key === KeyboardKeys.Backspace || key === KeyboardKeys.Delete) {
      // Get the current mouse selection object
      const selected = editor.selection.getSel();
      if (!selected || !selected.anchorNode) return;

      let parent = selected.anchorNode as Node;

      // START BLOCK INITIALIZATION - TO KNOW WHERE IS THE CURSOR
      // Get to the clause element
      let element = parent.parentElement;

      while (element && element.getAttribute('data-node-type') !== ContentNodeType.CLAUSE) {
        if (!element) break;

        element = element.parentElement;
      }
      // END BLOCK INITIALIZATION - TO KNOW WHERE IS THE CURSOR

      if (element) {
        // Check if clause becomes empty on backspace
        const isInvalid: boolean = checkifEmptyClause(element);
        // Check if there are clauses without number/index
        const hasNoIndex: boolean = checkIndexNumberingClause(editor, DataTypeSelect.Editor);
        if (element && (isInvalid || hasNoIndex)) {
          // Get back old content before deletion
          editor.undoManager.hasUndo() && editor.undoManager.undo();
          preventDefault();
        }
      }

      isPressing = 0;
    }
  });
};

export enum DataTypeSelect {
  Document = 'document',
  Editor = 'editor',
}

// A selector which selects the queried string elements from passed editor or document
const selector = (data: Editor | Document, query: string, event: DataTypeSelect) => {
  return event === DataTypeSelect.Document
    ? Array.from((data as Document).querySelectorAll(query))
    : Array.from((data as Editor).dom.select(query));
};

// Check if Clause has no content
const checkifEmptyClause = (node: HTMLElement): boolean => {
  const childNodes: NodeListOf<ChildNode> = node.childNodes;
  if (childNodes.length === 2) {
    const childContent = childNodes[1].textContent;
    // Check if the content node of the clause has any content
    return childContent === '';
  }
  // If more than 2 nodes content exists
  return false;
};

/**
 * Check if clauses exists without an index
 */
export const checkIndexNumberingClause = (parent: Editor | Document, event: DataTypeSelect): boolean => {
  // Get all clauses from the Editor or the Copied clipboard data
  const allClauses: Element[] = selector(parent, 'div[data-node-type="clause"]', event);

  // Loop for all clauses
  for (let clause of allClauses) {
    // Check if clause index exists for a clause
    const checkIfIndexExists: boolean | undefined = clause.children[0]
      .getAttribute('data-node-type')
      ?.includes('clause-index');
    // Clause without index exists
    if (!checkIfIndexExists) {
      return true;
    }
  }

  // All clauses have an Index tag
  return false;
};
