import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ProvisionContentClone } from 'common/_classes';
import { RootState } from 'store';
import { omit } from 'lodash';
import { ContentClone } from '.';

interface UpdateProvisionQueryVariables {
  id: string;
  content: any;
}

const UPDATE_PROVISION_CONTENT = (id: string, content: ContentClone) => {
  const variables: UpdateProvisionQueryVariables = {
    id,
    content: omit(content, 'id'),
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $content: ProvisionContentInput!) {
        updateProvisionContent(id: $id, content: $content) {
          ${ProvisionContentClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateProvisionContent = createAsyncThunk(
  'provisions/updateProvisionContent',
  async ({ documentTypeId }: { documentTypeId?: string | null }, { getState }) => {
    const state = getState() as RootState;
    const { activeProvision } = state.provisionDetail;

    // Select the content that needs to be updated, depending on 'documentTypeId'
    const activeContent = activeProvision.contents.find(
      (content: ContentClone) => content.documentTypeId === documentTypeId,
    );

    if (!activeContent) {
      throw new Error('No matching content found');
    }

    const response = await adminClient.mutate(
      UPDATE_PROVISION_CONTENT(activeContent.id, activeContent as ContentClone),
    );
    return response;
  },
);
