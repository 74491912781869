import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/translatorsToContract/translatorsListingSlice';
import { listTranslateToContracts } from 'common/api/translatorsToContract';

const TranslateToContractHeaderToolbar = (): JSX.Element => {
  const { search } = useAppSelector((state: RootState) => state.translatorsListing);

  const onGetData = (value?: string) => {
    // @ts-ignore
    return listTranslateToContracts({ searchValue: value, first: 1000 });
  };

  return (
    <SearchFilter
      search={search}
      onUpdateSearch={updateSearch}
      onGetData={onGetData}
    />
  );
};

export default TranslateToContractHeaderToolbar;
