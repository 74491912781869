import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { CRenewalRights } from 'common/_classes/contractsV2';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';

const EVALUATE_RENEWAL_RIGHTS_DATES = (leaseEndDate: Date | null, renewalRights: CRenewalRights[]) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('Evaluate Renewal Rights Dates: Landlord Entity could not be determined. Query aborted.');
  }

  if (renewalRights.length === 0) {
    throw new Error('Evaluate Renewal Rights Dates: Renewal rights could not be determined. Query aborted.');
  }

  const variables = {
    currentLandlordEntityId,
    leaseEndDate: convertDateToISO(leaseEndDate),
    renewalRightsTermLengths: renewalRights.map(right => ({ renewalTermLength: right.renewalTermLength })),
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $leaseEndDate: Date!
        $renewalRightsTermLengths: [RenewalRightsDatesEvaluatorInput]!
      ) {
        evaluateRenewalRightsDates(
          currentLandlordEntityId: $currentLandlordEntityId
          leaseEndDate: $leaseEndDate
          renewalRightsTermLengths: $renewalRightsTermLengths
        ) {
          renewalTermLength
          startDate
          endDate
        }
      }
    `,
    variables: variables,
  };
};

export const evaluateRenewalRightsDates = createAsyncThunk(
  'contractDetailV2/evaluateRenewalRightsDates',
  async ({ leaseEndDate, renewalRights }: { leaseEndDate: Date | null; renewalRights: CRenewalRights[] }) => {
    const response = await client.query(EVALUATE_RENEWAL_RIGHTS_DATES(leaseEndDate, renewalRights));
    return response;
  },
);
