import RentFreePeriod from 'common/model/RentFreePeriod';
import RentFreePeriodInput from 'common/model/RentFreePeriodInput';
import RentFreePeriodPayables from 'common/model/RentFreePeriodPayables';
import { convertDateToISO } from 'utils/utils-date';

export interface RentFreePeriodInputClone extends Omit<RentFreePeriodInput, 'startDate' | 'endDate'> {
  startDate: Date | null;
  endDate: Date | null;
  duration: string | null;
}

export class CRentFreePeriod implements RentFreePeriodInputClone {
  payables: RentFreePeriodPayables[];
  startDate: Date | null;
  endDate: Date | null;
  duration: string | null;

  constructor(input: RentFreePeriod | null) {
    this.payables = input ? input.payables : [];
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
    this.duration = '';
  }

  static fragment() {
    return `
    payables
    startDate
    endDate
    `;
  }

  static formatForAPI(freePeriod: CRentFreePeriod) {
    return {
      payables: freePeriod.payables,
      startDate: convertDateToISO(freePeriod.startDate),
      endDate: convertDateToISO(freePeriod.endDate),
    };
  }
}
