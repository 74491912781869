/* eslint-disable jsx-a11y/label-has-associated-control */
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Form, Popup, TextArea } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { Icon } from '@iconify/react';
import { checkEmpty, checkRequired, runValidations } from 'utils/tsValidator';
import { checkNotEmpty } from 'utils/tsValidator';
import { labelResizeObserver } from 'utils/utils-html';
import { Icons } from 'utils/utils-icons';
import { generateUniqueId } from 'utils/utils-random';

interface TextAreaFieldProps {
  label?: string | null;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value: string | null;
  onChange: (key: string, value: any) => void;
  fieldKey: string;
  validationFuncs?: ValidationFuncs;
  runValidation?: boolean;
  validationDependant?: string | boolean;
  dataTest?: string;
  style?: CSSProperties;
  className?: string;
}
const TextAreaField = ({
  label,
  required,
  placeholder,
  disabled,
  value,
  onChange,
  fieldKey,
  validationFuncs,
  runValidation,
  validationDependant,
  dataTest,
  style,
  className,
}: TextAreaFieldProps): JSX.Element => {
  const [error, setError] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);
  const [showLabelPopup, setShowLabelPopup] = useState(false);
  const [formContainerId] = useState<string>(generateUniqueId(''));

  const debouncedCheck = useRef(
    debounce(
      (requiredFunc, otherValidations, currentValue, required, setError) =>
        runValidations(requiredFunc, otherValidations, currentValue, required, setError),
      500,
    ),
  ).current;

  useEffect(() => {
    if (!disabled) {
      setTouched(false);
    }
  }, [disabled]);

  useEffect(() => {
    if (!touched) {
      setTouched(true);
    }

    if (touched && !disabled) {
      debouncedCheck(checkRequired, validationFuncs, value, required, setError);
    }
  }, [value]);

  useEffect(() => {
    if (validationDependant && !runValidation) {
      setError('');
    }
  }, [validationDependant]);

  useEffect(() => {
    if (checkNotEmpty(value)) {
      setTimeout(function () {
        setError('');
      }, 100);
    } else {
      if (disabled) {
        setError('');
      } else {
        if (runValidation) {
          runValidations(checkRequired, validationFuncs, value, required, setError);
        }
      }
    }
  }, [runValidation, disabled]);

  useEffect(() => {
    // Label popup control when label is truncated with an ellipsis
    labelResizeObserver(formContainerId, setShowLabelPopup);
  }, []);

  return (
    <Form.Field
      className={`field-style ${className}`}
      id={formContainerId}
    >
      {label && (
        <label>
          <Popup
            trigger={<span>{label}</span>}
            content={label}
            disabled={!showLabelPopup}
          />
          {required && <span className="required-indicator">*</span>}
        </label>
      )}
      <div className="pos-rel">
        <TextArea
          placeholder={placeholder}
          data-test={dataTest}
          className={`${disabled && 'custom-disabled'} ${error && 'error-field'}`}
          value={value ? value : ''}
          onChange={e => onChange(fieldKey, e.target.value)}
          readOnly={disabled}
          style={style}
        />
        {error && (
          <div className="input-validation-message error p-t-xxs">
            <Icon
              icon={Icons.Warning}
              className="m-r-xxs"
            />
            {error}
          </div>
        )}
      </div>
    </Form.Field>
  );
};

export default TextAreaField;
