enum BuildingGrade {
  Prime = 'PRIME',
  GradeA = 'GRADE_A',
  GradeB = 'GRADE_B',
  GradeC = 'GRADE_C',
  Other = 'OTHER',
  FirstClassMall = 'FIRST_CLASS_MALL',
  LocalMall = 'LOCAL_MALL'
}
export default BuildingGrade;
