import { useNavigate } from 'react-router-dom';
import { Button, Image } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { PASSWORD_RESET_STEPS } from 'views/authentication/PasswordReset';
import { updateResetStep } from 'store/authentication/authSlice';
import { Icons } from 'utils/utils-icons';
import sericinIconAuth from 'assets/images/svg/sericin-icon-auth.svg';
import './AuthHeader.scss';

export interface AuthHeaderProps {
  signin?: boolean;
  verify?: boolean;
}

const AuthHeader = ({ signin, verify }: AuthHeaderProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { resetStep } = useAppSelector((state: RootState) => state.auth);

  let url: string, accountText: string, buttonText: string;

  if (signin) {
    url = '/sign-up';
    accountText = 'Need a Sericin account?';
    buttonText = 'REGISTRATION';
  } else {
    url = '/sign-in';
    accountText = 'Already have an account?';
    buttonText = 'LOG IN';
  }

  const onRedirect = () => {
    dispatch(updateResetStep({ step: PASSWORD_RESET_STEPS.ENTER_EMAIL }));
    navigate(url);
  };

  return (
    <div className="auth-header">
      <Image
        src={sericinIconAuth}
        className="sericin-icon bg-gray-blackish"
      />
      {resetStep === PASSWORD_RESET_STEPS.ENTER_EMAIL && verify === undefined && (
        <>
          <span
            className="info bg-gray-blackish color-white"
            onClick={onRedirect}
          >
            {accountText}
          </span>
          <Button
            onClick={onRedirect}
            className="btn orange-outline bg-gray-blackish"
          >
            {buttonText}
            <Icon
              icon={Icons.ArrowLeft90}
              rotate={1}
            />
          </Button>
        </>
      )}
    </div>
  );
};

export default AuthHeader;
