import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { uniqBy } from 'lodash';
import { Icon } from '@iconify/react';
import ConfirmPopup from 'atoms/ConfirmPopup';
import { addClosed, addPostCompletion } from 'store/transactions/transactionDetailSlice';
import TransactionHistoryGeneralStatus from 'common/model/TransactionHistoryGeneralStatus';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { getLandlordTransaction, updateTransaction } from 'common/api/transactions';
import { TransactionDocument } from 'common/api/transactions';
import { translateToContract } from 'common/api/translatorsToContract';
import { transactionDisabled } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';

interface PostCompletionTabToolbarProps {
  anySigned: boolean;
  checkCurrentStatus: TransactionHistoryGeneralStatus;
  mode: MODE_OPTIONS;
  setMode: (value: MODE_OPTIONS) => void;
}

const PostCompletionTabToolbar = ({
  anySigned,
  checkCurrentStatus,
  mode,
  setMode,
}: PostCompletionTabToolbarProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTransaction } = useAppSelector((state: RootState) => state.transactionDetail);
  const { documents, regulatories } = activeTransaction;

  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const checkDetails: boolean = location.pathname.includes('details');

  const setup: boolean = checkCurrentStatus === TransactionHistoryGeneralStatus.Setup;

  // Check that mandatory documents have already been downloaded
  // Note that:
  //  - you can have multiple documents of each type
  //  - documents can also be loaded as "Other documents" type, hence
  //    should not be considered in the verification of the length
  //  - only mandatory documents are verified
  const checkFinalize = (): boolean => {
    if (regulatories.length === 0) {
      return true;
    }
    const documentsToCheck: TransactionDocument[] = documents.filter((element: TransactionDocument) =>
      regulatories.includes(element.name),
    );

    const uniqDocs: number = uniqBy(documentsToCheck, 'name').length;
    return uniqDocs === regulatories.length;
  };

  // When all mandatory documents have been downloaded "finalize transaction" button can appear
  const finalize: boolean = checkFinalize();

  const finalizeTransaction = (): void => {
    dispatch(addClosed({ userId }));
    dispatch(translateToContract({ finalised: true }));
    dispatch(updateTransaction({ approval: true }));
  };

  const startPostCompletion = (): void => {
    dispatch(addPostCompletion({ userId }));
    dispatch(updateTransaction({ approval: true }));
  };

  const toShowTabToolbar: boolean = checkDetails && !setup;

  const postCompletion: JSX.Element = setup ? (
    <Button
      className="btn grey-outline start-post-completion-btn"
      data-test="cancel-button"
      disabled={!anySigned}
      onClick={startPostCompletion}
    >
      START POST COMPLETION
    </Button>
  ) : (
    <></>
  );

  const onCancel = (): void => {
    dispatch(getLandlordTransaction({ id: activeTransaction.id }));
    setMode(MODE_OPTIONS.READ);
  };

  const onSave = (): void => {
    dispatch(updateTransaction({ approval: true }));
    setMode(MODE_OPTIONS.READ);
  };

  if (mode === MODE_OPTIONS.READ) {
    return (
      <>
        {postCompletion}
        {toShowTabToolbar ? (
          <div className="d-flex btns">
            {finalize && (
              <ConfirmPopup
                trigger={
                  <Button
                    className="btn grey-outline"
                    data-test="cancel-button"
                  >
                    FINALIZE TRANSACTION
                  </Button>
                }
                content="Do you really want to finalize?"
                confirmButtonText="CONFIRM"
                onConfirm={finalizeTransaction}
                cancelButtonText="CANCEL"
              />
            )}

            <Button
              className="btn grey-bg"
              data-test="edit-button"
              onClick={() => setMode(MODE_OPTIONS.EDIT)}
              disabled={!anySigned}
            >
              <Icon icon={Icons.Pencil}></Icon>
              EDIT
            </Button>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return toShowTabToolbar ? (
      <>
        <Button
          className="btn grey-outline"
          data-test="cancel-button"
          onClick={() => onCancel()}
        >
          CANCEL
        </Button>

        <Button
          className="btn grey-bg"
          data-test="save-button"
          disabled={transactionDisabled(activeTransaction)}
          onClick={onSave}
        >
          SAVE
        </Button>
      </>
    ) : (
      <></>
    );
  }
};

export default PostCompletionTabToolbar;
