import KnowHow from 'views/know-how';
import { isAuthenticated } from './redirects';

const KnowHowRoutes = () => [
  {
    path: '/know-how',
    element: isAuthenticated(<KnowHow />),
  },
];

export default KnowHowRoutes;
