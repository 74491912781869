import { GuidelineClone, GuidelinesBase } from 'common/_classes';
import { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { GuidelineType, OnUpdateGuidelineTypeAction, getTitleFromGuidelineType } from './GuidelineEditor';
import GuidelineEditorModal from './GuidelineEditorModal';
import './Guidelines.scss';

const getGuidelineValue = (value: GuidelineType, guidelines: GuidelinesBase): string | null => {
  if (guidelines !== null) {
    switch (value) {
      case GuidelineType.GENERAL:
        return guidelines.generalGuidance;
      case GuidelineType.VALUATION:
        return guidelines.valuationGuidance;
      case GuidelineType.INTERNAL:
        return guidelines.internalNotes;
      default: //case GuidelineType.EXPLANATORY:
        return guidelines.explanatoryNotes;
    }
  }
  return null;
};

/*
 * GuidelinesComponent implements a row of guidelines, one for
 * each of the 4 following guidelines:
 *  -> general guideline
 *  -> valuation guideline
 *  -> explanatory notes
 *  -> internal notes
 */
interface GuidelinesComponentProps extends ModeProps {
  guidelines: GuidelineClone;
  onUpdateGuidelineType: OnUpdateGuidelineTypeAction;
}

const GuidelinesComponent = ({ mode, guidelines, onUpdateGuidelineType }: GuidelinesComponentProps): JSX.Element => {
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [guildlineType, setGuidelineType] = useState<GuidelineType>(GuidelineType.GENERAL);

  const openModal = (guidelineType: GuidelineType): void => {
    if (mode === MODE_OPTIONS.EDIT) {
      setGuidelineType(guidelineType);
      setModalStatus(true);
    }
  };

  const guideLineTypes: GuidelineType[] = Object.values(GuidelineType);

  return (
    <Grid
      className="guidelines bg-white"
      data-test="guidelines"
    >
      <Grid.Row className="header-row bg-gray-very-light">
        {guideLineTypes.map((type: GuidelineType, index: number) => (
          <Grid.Column
            key={`title-col-${index}`}
            width={4}
            className="pm-none"
          >
            {getTitleFromGuidelineType(type)}
            {guideLineTypes.length - 1 !== index && <div className="right-line"></div>}
          </Grid.Column>
        ))}
      </Grid.Row>

      <Grid.Row
        className="text-boxes pm-none"
        data-test="guidelines-boxes"
      >
        {guideLineTypes.map((type, index) => (
          <Grid.Column
            key={`content-col-${index}`}
            width={4}
            className="pm-none inner-content editor-to-html"
            onClick={() => openModal(type)}
            dangerouslySetInnerHTML={{
              __html: getGuidelineValue(type, guidelines),
            }}
          />
        ))}
      </Grid.Row>

      <Grid.Row className="footer-row border-sm-grayish-magenta-light bg-gray-very-light"></Grid.Row>

      <GuidelineEditorModal
        guidelines={guidelines}
        status={modalStatus}
        setModalStatus={setModalStatus}
        guidelineType={guildlineType}
        onUpdateGuidelineType={onUpdateGuidelineType}
      />
    </Grid>
  );
};

export default GuidelinesComponent;
