import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import Entity from 'common/model/Entity';
import EntityType from 'common/model/EntityType';
import { getCurrentLandlordEntityId, namesListHK } from 'utils/tsHelper';
import { EntityCreatorTypes } from './types';

interface CreateEntityQueryVariables {
  currentLandlordEntityId?: string;
  params: any;
}

export const CREATE_ENTITIY = (entityType: EntityCreatorTypes, activeEntity: Entity) => {
  const {
    name,
    nickname,
    address,
    industry,
    identifier,
    contacts,
    type,
    documents,
    legalDomicile,
    residentialAddress,
    businessAddress,
    passportId,
    cardId,
    registrationCountry,
    registrationId,
    overseasRegistrationId,
    details,
    addressLocal,
  } = activeEntity;

  let industryValue;
  if (typeof industry === 'object' && industry !== null) {
    industryValue = industry.id;
  } else {
    industryValue = industry;
  }

  let params: any = {
    name,
    nickname,
    industryId: industryValue,
    identifier,
    address,
    contacts,
    type,
    documents,
    details,
  };

  if (type === EntityType.Individual) {
    params = { ...params, legalDomicile, residentialAddress };

    if (namesListHK.includes(legalDomicile)) {
      params = { ...params, cardId };
    } else {
      params = { ...params, passportId, addressLocal };
    }
  } else {
    params = { ...params, registrationCountry, businessAddress };

    if (namesListHK.includes(registrationCountry)) {
      params = { ...params, registrationId };
    } else {
      params = { ...params, overseasRegistrationId };
    }

    if (type === EntityType.Company) {
      params = { ...params, addressLocal };
    }
  }

  let currentLandlordEntity = '',
    innerTextLandlordEntity = '',
    currentLandlordEntityId;

  let inputType = 'LandlordEntityInput!';

  if (entityType === EntityCreatorTypes.Tenant) {
    currentLandlordEntityId = getCurrentLandlordEntityId();

    if (!currentLandlordEntityId) throw new Error('Create Entity: currentLandlordEntityId is required');

    currentLandlordEntity = '$currentLandlordEntityId: ID!,';
    innerTextLandlordEntity = 'currentLandlordEntityId: $currentLandlordEntityId,';
    inputType = 'TenantEntityInput!';
  }

  const variables: CreateEntityQueryVariables = {
    currentLandlordEntityId,
    params: JSON.parse(JSON.stringify(params)),
  };

  return {
    mutation: gql`
        mutation(${currentLandlordEntity} $params: ${inputType}){
          create${entityType}Entity(${innerTextLandlordEntity} params: $params){
            id
            activity
            address
            businessAddress
            cardId
            type
            addressLocal
            contacts {
              email
              firstName
              lastName
              phone
              position
            }
            details
            documents{
              id
              name
              public
              url
              path
            }
            insertedAt
            legalDomicile
            logo
            name
            nickname
            identifier
            industry{
              id
              name
              code
            }
            overseasRegistrationId
            passportId
            registrationCountry
            registrationId
            residentialAddress
            updatedAt 
        }
      }
      `,
    variables,
  };
};

export const createEntity = createAsyncThunk(
  'entityDetail/createEntity',
  async ({ type }: { type: EntityCreatorTypes; saveAndExit?: boolean }, { getState }) => {
    const {
      entityDetail: { activeEntity },
    } = getState() as RootState;

    const response = await client.mutate(CREATE_ENTITIY(type, activeEntity));

    return response;
  },
);
