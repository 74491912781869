import React from 'react';
import { Icon } from '@iconify/react';
import ConfirmPopup, { TemplateData } from 'atoms/ConfirmPopup';
import Tooltip from 'atoms/Tooltip';
import { ColorNames } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { capitalizeFirstLetter } from 'utils/utils-string';
import './TableActionButtons.scss';

export interface TableActionButton {
  icon?: Icons;
  tooltip?: string;
  onClick?: Function;
  isPopup?: boolean;
  popupText?: string;
  isLargeTooltip?: boolean;
  popupConfirmationButton?: string;
  popupCancelButton?: string;
  template?: React.ReactNode;
  dataTest?: string;
  templateData?: TemplateData[];
  color?: ColorNames;
  disabled?: boolean;
  largeTooltipContent?: JSX.Element;
}

interface TableActionButtonsParams {
  buttons: TableActionButton[];
}

export const RowActionButton = ({ button }: { button: TableActionButton }) => {
  return (
    <button
      className="mini compact ui button"
      disabled={button.disabled}
      data-tooltip={button.tooltip}
      data-position="left center"
      onClick={() => button.onClick && button.onClick()}
      data-test={button.dataTest || 'View Details'}
    >
      <Icon
        icon={button.icon ? button.icon : Icons.Link}
        // To improve
        color={capitalizeFirstLetter(button.color as string) as string}
      />
    </button>
  );
};

const ConfirmPopupTemplate = ({ button }: { button: TableActionButton }) => {
  return (
    <ConfirmPopup
      trigger={
        <button
          onClick={e => e.stopPropagation()}
          className="mini compact ui button"
          disabled={button.disabled}
          data-tooltip={button.tooltip}
          data-position="left center"
          data-test={button.dataTest || 'delete-button'}
        >
          <Icon
            icon={button.icon ? button.icon : Icons.TrashFill}
            // To improve
            color={capitalizeFirstLetter(button.color as string) as string}
          />
        </button>
      }
      content={button.popupText ? button.popupText : 'Are you sure?'}
      confirmButtonText={button.popupConfirmationButton || 'YES'}
      onConfirm={() => button.onClick && button.onClick()}
      cancelButtonText={button.popupCancelButton || 'CANCEL'}
      templateData={button.templateData}
      color={button.color}
    />
  );
};

const LargeTooltip = ({ button }: { button: TableActionButton }) => {
  return (
    <Tooltip
      className="user-tooltip"
      trigger={
        <button
          className="mini compact ui button"
          onClick={() => button.onClick && button.onClick()}
        >
          <Icon
            icon={button.icon ? button.icon : Icons.TrashFill}
            // To improve
            color={capitalizeFirstLetter(button.color as string) as string}
          />
        </button>
      }
      HTMLContent={button.largeTooltipContent ? button.largeTooltipContent : <></>}
    />
  );
};

const getButtonType = (button: TableActionButton, buttonKey: string) => {
  if (button.template) {
    return button.template;
  }
  if (button.isPopup) {
    return (
      <ConfirmPopupTemplate
        button={button}
        key={buttonKey}
      />
    );
  }
  if (button.isLargeTooltip) {
    return <LargeTooltip button={button} />;
  }
  return (
    <RowActionButton
      button={button}
      key={buttonKey}
    />
  );
};

const TableActionButtons = ({ buttons }: TableActionButtonsParams) => {
  return (
    <div className="mini compact ui icon buttons basic table-action-menu">
      {buttons.map((button: TableActionButton, index: number) => {
        const buttonKey = `table-row-action-button-${index}`;
        return getButtonType(button, buttonKey);
      })}
    </div>
  );
};

export default TableActionButtons;
