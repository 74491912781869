import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox, Form, Input, Popup } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import DateField from 'atoms/FormField/DateInput';
import { InputFieldType } from 'atoms/FormField/Input';
import { CHARACTERISTICS_OPTIONS, SPACE_TYPE_OPTIONS } from 'utils/UI';
import { Icons } from 'utils/utils-icons';
import AreaInput from './AreaInput';
import { SpaceAndUnitRow } from './SpacesAndUnitsTableConfig';

export const nameInputElement = (row: SpaceAndUnitRow, updateActiveCell: Function, spaceUpdate: Function) => {
  return (
    <Form.Field onClick={() => updateActiveCell(row.index, 1)}>
      <Input
        value={row.name === null ? '' : row.name}
        placeholder="Enter space/unit name..."
        data-test="unit-name"
        onChange={(e, { value }) =>
          spaceUpdate({
            key: 'name',
            value,
            index: row.index,
          })
        }
      />
    </Form.Field>
  );
};

export const registeredNameInputElement = (row: SpaceAndUnitRow, updateActiveCell: Function, spaceUpdate: Function) => {
  return (
    <Form.Field onClick={() => updateActiveCell(row.index, 2)}>
      <Input
        value={row.registeredName === null ? '' : row.registeredName}
        placeholder="Type here..."
        data-test="registered-unit-name"
        onChange={(e, { value }) =>
          spaceUpdate({
            key: 'registeredName',
            value,
            index: row.index,
          })
        }
      />
    </Form.Field>
  );
};

export const mainUseInputElement = (row: SpaceAndUnitRow, spaceUpdate: Function, expandTableHeight: Function) => {
  return (
    <div
      className="form-field"
      onClick={() => expandTableHeight(row.index, 3)}
    >
      <Dropdown
        value={row.type}
        onChange={(event: DropdownChangeEvent) =>
          spaceUpdate({
            key: 'type',
            value: event.value,
            index: row.index,
          })
        }
        options={SPACE_TYPE_OPTIONS}
        optionLabel="text"
        placeholder="Choose..."
        data-test="choose-main-use"
        className="width-full"
      />
    </div>
  );
};

export const grossAreaInputElement = (
  row: SpaceAndUnitRow,
  updateSpaceDetail: Function,
  updateActiveCell: Function,
  isEditable: boolean,
) => {
  return (
    <div onClick={() => updateActiveCell(row.index, 4)}>
      <AreaInput
        columnName="grossArea"
        index={row.index}
        area={row.grossArea}
        updateSpaceDetail={updateSpaceDetail}
        isEditable={isEditable}
        fieldKey="grossArea"
        dataTest="gross-area"
      />
    </div>
  );
};

export const netAreaInputElement = (
  row: SpaceAndUnitRow,
  updateSpaceDetail: Function,
  updateActiveCell: Function,
  isEditable: boolean,
) => {
  return (
    <div onClick={() => updateActiveCell(row.index, 5)}>
      <AreaInput
        columnName="netArea"
        index={row.index}
        area={row.netArea}
        updateSpaceDetail={updateSpaceDetail}
        isEditable={isEditable}
        fieldKey="netArea"
        dataTest="net-area"
      />
    </div>
  );
};

export const lettableAreaInputElement = (
  row: SpaceAndUnitRow,
  updateSpaceDetail: Function,
  updateActiveCell: Function,
  isEditable: boolean,
) => {
  return (
    <div onClick={() => updateActiveCell(row.index, 6)}>
      <AreaInput
        columnName="lettableArea"
        index={row.index}
        area={row.lettableArea}
        updateSpaceDetail={updateSpaceDetail}
        isEditable={isEditable}
        fieldKey="lettableArea"
        dataTest="lettable-area"
      />
    </div>
  );
};

export const saleableAreaInputElement = (
  row: SpaceAndUnitRow,
  updateSpaceDetail: Function,
  updateActiveCell: Function,
  isEditable: boolean,
) => {
  return (
    <div onClick={() => updateActiveCell(row.index, 7)}>
      <AreaInput
        columnName="saleableArea"
        index={row.index}
        area={row.saleableArea}
        updateSpaceDetail={updateSpaceDetail}
        isEditable={isEditable}
        fieldKey="saleableArea"
        dataTest="saleable-area"
      />
    </div>
  );
};

const getEfficiencyRatio = (efficiencyRatio: number) => {
  let effRatio: string | number = efficiencyRatio * 100;
  const firstDecimal = String(efficiencyRatio).split('.')[1];
  let lastChar;
  if (firstDecimal) {
    lastChar = firstDecimal.slice(-1);
    if (lastChar === '0') {
      effRatio = effRatio.toFixed(0) + '.0';
    }
  }

  return effRatio;
};

export const efficiencyRatioInputElement = (
  row: SpaceAndUnitRow,
  updateActiveCell: Function,
  spaceUpdate: Function,
  activeRow: number | null,
  activeColumn: number | null,
) => {
  return (
    <Form.Field onClick={() => updateActiveCell(row.index, 8)}>
      <Input
        data-test="efficiency-ratio"
        type={`${activeRow === row.index && activeColumn === 8 ? InputFieldType.NUMBER : InputFieldType.TEXT}`}
        value={`${
          activeRow === row.index && activeColumn === 8
            ? getEfficiencyRatio(row.efficiencyRatio)
            : `${getEfficiencyRatio(row.efficiencyRatio)}%`
        }`}
        placeholder="-"
        onChange={(e, { value }) =>
          spaceUpdate({
            key: 'efficiencyRatio',
            value,
            index: row.index,
          })
        }
      />
    </Form.Field>
  );
};

export const occupancyRateInputElement = (row: SpaceAndUnitRow, updateActiveCell: Function, spaceUpdate: Function) => {
  return (
    <Form.Field onClick={() => updateActiveCell(row.index, 9)}>
      <Checkbox
        checked={row.usedForOccupancyRate}
        type="checkbox"
        onChange={(e, { checked }) =>
          spaceUpdate({
            key: 'usedForOccupancyRate',
            value: checked,
            index: row.index,
          })
        }
      />
    </Form.Field>
  );
};

export const characteristicsInputElement = (
  row: SpaceAndUnitRow,
  expandTableHeight: Function,
  spaceUpdate: Function,
) => {
  return (
    <div
      className="form-field"
      onClick={() => expandTableHeight(row.index, 3)}
    >
      <MultiSelect
        value={row.characteristics === null ? [] : row.characteristics}
        onChange={({ value }) =>
          spaceUpdate({
            key: 'characteristics',
            value,
            index: row.index,
          })
        }
        options={CHARACTERISTICS_OPTIONS}
        optionLabel="text"
        placeholder="Choose..."
        data-test="select-characteristics"
        maxSelectedLabels={3}
        className="width-full"
      />
    </div>
  );
};

export const activationDateInputElement = (
  row: SpaceAndUnitRow,
  onDateChange: Function,
  updateActiveCell: Function,
  disabled: boolean,
) => {
  return (
    <div
      onClick={() => updateActiveCell(row.index, 11)}
      data-test="units-activation-date"
    >
      <DateField
        fieldKey="activationDate"
        value={row.activationDate}
        disabled={disabled}
        onChange={(fieldKey, value) => onDateChange(fieldKey, value, row.index)}
        popperProps={{
          strategy: 'fixed',
        }}
      />
    </div>
  );
};

export const deactivationDateInputElement = (
  row: SpaceAndUnitRow,
  onDateChange: Function,
  updateActiveCell: Function,
  disabled: boolean,
) => {
  return (
    <div
      onClick={() => updateActiveCell(row.index, 12)}
      data-test="units-deactivation-date"
    >
      <DateField
        fieldKey="deactivationDate"
        value={row.deactivationDate}
        disabled={disabled}
        onChange={(fieldKey, value) => onDateChange(fieldKey, value, row.index)}
        popperProps={{
          strategy: 'fixed',
        }}
      />
    </div>
  );
};

export const commentsInputElement = (row: SpaceAndUnitRow, updateActiveCell: Function, spaceUpdate: Function) => {
  return (
    <Form.Field onClick={() => updateActiveCell(row.index, 13)}>
      <Input
        value={row.comments === null ? '' : row.comments}
        placeholder="Type here..."
        data-test="comments"
        onChange={(e, { value }) =>
          spaceUpdate({
            key: 'comments',
            value,
            index: row.index,
          })
        }
      />
    </Form.Field>
  );
};

export const efficiencyRatioHeaderTemplate = () => {
  return (
    <div className="floor-area">
      Efficiency ratio
      <span className="required">*</span>
      <Popup
        className="popup-info"
        content="The efficiency ratio is a measure of the usable floor space relative to the total floor area of the building expressed as a percentage. E.g. on a floor of 1,000 sqft an efficiency ratio of 80% would mean that 800 sqft are available for lease and use by occupants."
        trigger={
          <Icon
            icon={Icons.InfoOutline}
            className="circle-m info-icon-area color-orange"
          />
        }
      />
    </div>
  );
};

export const occupancyRateHeaderTemplate = () => {
  return (
    <div className="floor-area">
      Used for OR
      <Popup
        content="Used to calculate occupancy rate"
        on={['hover']}
        trigger={
          <Icon
            icon={Icons.InfoOutline}
            className="circle-m info-icon-area color-orange"
          />
        }
      />
    </div>
  );
};

export const requiredHeaderElement = (label: string) => {
  return (
    <p>
      {label} <span className="required">*</span>
    </p>
  );
};
