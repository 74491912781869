import { ReactNode } from 'react';

interface SectionComponentProps {
  title?: string | ReactNode;
  className?: string;
  children?: ReactNode;
  [key: string]: any;
}

/**
 * Template for Form Sections
 * @param {SectionComponentProps} param
 * @returns
 */
const SectionComponent = ({ title, children, className, ...rest }: SectionComponentProps) => {
  return (
    <div className={`grid outlined container m-none w-100 bg-white border-sm-grayish-magenta-light p-sm ${className}`}>
      <div className="col-12">
        <h4 className="section-title m-none">{title}</h4>
      </div>
      <div className="col-12">{children}</div>
    </div>
  );
};

export default SectionComponent;
