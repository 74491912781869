import { useState } from 'react';
import { Form } from 'semantic-ui-react';
import EditorBox from 'atoms/EditorBox';
import EditorLoading from 'atoms/EditorBox/EditorLoading';

interface EditorFieldProps {
  label?: string;
  required?: boolean;
  placeholder: string;
  disabled: boolean;
  value: string | null;
  onChange: (key: string, value: any) => void;
  fieldKey: string;
  height: string;
  codeSample?: boolean;
  code?: boolean;
  pasteAsText?: boolean;
  className?: string;
}

const EditorField = ({
  label,
  required,
  placeholder,
  disabled,
  value,
  onChange,
  fieldKey,
  height,
  codeSample,
  code,
  pasteAsText,
  className,
}: EditorFieldProps): JSX.Element => {
  const [editorLoaded, setEditorLoaded] = useState<boolean>(false);

  return (
    <Form.Field className={`field-style ${className}`}>
      <label>
        {label}
        {required && <span className="required-indicator">*</span>}
      </label>
      <EditorBox
        setEditorLoaded={setEditorLoaded}
        editorLoaded={editorLoaded}
        placeholder={placeholder}
        value={value ? value : ''}
        onChange={onChange}
        fieldKey={fieldKey}
        height={height}
        disabled={disabled}
        codeSample={codeSample}
        code={code}
        pasteAsText={pasteAsText}
      />
      {!editorLoaded && <EditorLoading />}
    </Form.Field>
  );
};

export default EditorField;
