import BenchmarksDashboard from 'views/benchmark/Dashboard';
import BenchmarkViewer from 'views/benchmark/Viewer';
import { checkIfAdminURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const BenchmarkRoutes = () => {
  let children = [
    {
      path: '/benchmarks/dashboard',
      element: isAuthenticated(<BenchmarksDashboard />),
    },
  ];

  if (!checkIfAdminURL()) {
    children = [
      ...children,
      {
        path: '/benchmarks/:id/details',
        element: isAuthenticated(<BenchmarkViewer isViewPage={true} />),
      },
      {
        path: '/benchmarks/create',
        element: isAuthenticated(<BenchmarkViewer />),
      },
    ];
  }

  return [
    {
      path: '/benchmarks',
      children,
    },
  ];
};
export default BenchmarkRoutes;
