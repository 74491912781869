import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import { createNewFormatter } from 'store/formatters/formatterDetailSlice';
import { listFormatters } from 'common/api/formatters';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import FormattersHeaderToolbar from './FormattersHeaderToolbar';
import { FormattersRow, formatterColumnConfig } from './FormattersTableConfig';

const FormattersDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, formattersList } = useAppSelector((state: RootState) => state.formattersListing);

  const onCreateFormatter = (): void => {
    dispatch(createNewFormatter());
    navigate('/formatters/create');
  };

  const onViewDetails = (id: string): void => {
    const url = `/formatters/${id}/details`;
    navigate(url);
  };

  const getFormatters = (params: PaginationAPIProps): void => {
    dispatch(listFormatters(params));
  };

  const currentList: FormattersRow[] = formattersList.map(formatter => new FormattersRow(formatter));

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Formatters' }];

    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE FORMATTER',
      dataTest: 'create-formatter-button',
      onCreateNew: onCreateFormatter,
      url: '/formatters/create',
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <FormattersHeaderToolbar />,
      createNewButton,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          getFormatters(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={formatterColumnConfig(onViewDetails)}
        dataTest="formatters-table"
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default FormattersDashboard;
