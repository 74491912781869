import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CreateNewButton, { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/entities/entitiesListingSlice';
import { createNewEntity } from 'store/entities/entityDetailSlice';
import { EntityCreatorTypes, listEntities } from 'common/api/entities';

const LandlordEntitiesHeaderToolbar = ({ createURL }: { createURL?: string }) => {
  const { search } = useAppSelector((state: RootState) => state.entitiesListing);
  const { activeEntity } = useAppSelector((state: RootState) => state.entityDetail);
  const id = activeEntity?.id;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const onGetData = (value?: string) => {
    return listEntities({
      type: EntityCreatorTypes.Landlord,
      searchBarSpinOnly: value !== undefined && value !== null,
      first: 1000,
    });
  };

  const createNewButton: CreateNewButtonProps = {
    btnText: 'CREATE LANDLORD ENTITY',
    url: createURL ? createURL : '/landlord-entities/create?step=1',
    dataTest: 'create-landlord-entity',
    onCreateNew: () => {
      dispatch(createNewEntity({ parent: id, type: EntityCreatorTypes.Landlord }));
      navigate(createURL ? createURL : '/landlord-entities/create?step=1');
    },
  };

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      <CreateNewButton {...createNewButton} />
    </>
  );
};

export default LandlordEntitiesHeaderToolbar;
