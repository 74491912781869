import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { FormatterAnswerInput } from 'common/api/formatters';
import { getClientType } from 'utils/utils-authentication';
import { FormatterAnswerInputResult, convertFormatterAnswerInputs } from '.';

interface ExecuteBaseFormatterQueryVariables {
  id: string;
  answers: FormatterAnswerInputResult[];
}

const EXECUTE_BASE_FORMATTER = (id: string, answers: FormatterAnswerInput[]) => {
  const variables: ExecuteBaseFormatterQueryVariables = {
    id,
    answers: convertFormatterAnswerInputs(answers),
  };

  if (!id) throw new Error('Execute Basee Formatter: id is required');

  return {
    query: gql`
      query ($answers: [FormatterAnswerInput]!, $id: ID!) {
        executeBaseFormatter(answers: $answers, id: $id) {
          value
        }
      }
    `,
    variables,
  };
};

export const executeBaseFormatter = createAsyncThunk('formatters/executeBaseFormatter', async (_args, { getState }) => {
  const {
    formatterDetail: { activeFormatter, answerInputs },
  } = getState() as RootState;

  /*
   * TODO: This is a potential issue.
   * Rather than using the activeFormatter.id directly, we cast it to a string to avoid a type error e.g. String(activeFormatter.id )
   * Using the "as string" is a type assertion, so the even if the value is null or number, TS will ignore the issue (i.e. no conversion occurs.)
   * If activeFormatter.id is not a string at runtime, this will likely lead to an issue.
   * Using String(activeFormatter.id) will convert the value to a string, (e.g. a number 5 will become '5', null will become 'null');
   */
  const response = await getClientType().query(EXECUTE_BASE_FORMATTER(activeFormatter.id as string, answerInputs));
  return response;
});
