import Decimal from 'decimal.js';
import GovernmentRent from 'common/model/GovernmentRent';
import GovernmentRentInput from 'common/model/GovernmentRentInput';
import PaidBy from 'common/model/PaidBy';
import { convertDateToISO } from 'utils/utils-date';

export interface GovernmentRentInputClone
  extends Omit<GovernmentRentInput, 'paidBy' | 'rent' | 'startDate' | 'endDate'> {
  paidBy: PaidBy | null;
  rent: Decimal | null;
  startDate: Date | null;
  endDate: Date | null;
}

export class CGovernmentRent implements GovernmentRentInputClone {
  paidBy: PaidBy | null;
  rent: Decimal | null;
  startDate: Date | null;
  endDate: Date | null;

  constructor(input: GovernmentRent | null) {
    this.paidBy = input ? input.paidBy : null;
    this.rent = input && input.rent ? new Decimal(input.rent) : null;
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
  }

  static fragment() {
    return `
      paidBy
      rent
      startDate
      endDate
    `;
  }

  static formatForAPI(rent: CGovernmentRent) {
    return {
      paidBy: rent.paidBy,
      rent: rent.rent ? parseFloat(rent.rent.toString()) : null,
      startDate: convertDateToISO(rent.startDate),
      endDate: convertDateToISO(rent.endDate),
    };
  }
}
