import { ParameterChoiceBase } from 'common/_classes';
import { Form, Grid } from 'semantic-ui-react';
import AnswerTypes from 'common/model/AnswerTypes';
import BooleanChoices from './BooleanChoices';
import MultiChoices from './MultiChoices';
import SingleChoices from './SingleChoices';

interface ChoiceOptionsProps {
  answerType: AnswerTypes | undefined;
  choices: ParameterChoiceBase[];
}

const ChoiceOptions = ({ answerType, choices }: ChoiceOptionsProps): JSX.Element => {
  const getChoices = (): JSX.Element => {
    switch (answerType) {
      case AnswerTypes.SingleChoice:
        return <SingleChoices choices={choices} />;
      case AnswerTypes.MultiChoice:
        return <MultiChoices choices={choices} />;
      case AnswerTypes.Boolean:
        return <BooleanChoices />;
      default:
        return <></>;
    }
  };

  return (
    <Grid.Row>
      <Grid.Column width={4}></Grid.Column>
      <Grid.Column width={12}>
        <Form.Group
          grouped
          className="options-parameter-mcq"
        >
          {getChoices()}
        </Form.Group>
      </Grid.Column>
    </Grid.Row>
  );
};

export default ChoiceOptions;
