import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { TransactionProvisionTemp, listTransactionProvisions } from 'common/api/transactions';
import { DROPDOWN_OPTION } from 'utils/UI';

const GeneralForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const onChange = (key: string, value: any) => {
    dispatch(updateActiveAmendment({ key, value }));
  };

  const {
    activeAmendment,
    activeTransaction: { documentTypeIds, id: transactionId },
    activeTransactionProvisions,
    activeTransactionAmendments,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { documentTypesOptions } = useAppSelector((state: RootState) => state.miscellaneous);
  const { provisionCategoriesList } = useAppSelector((state: RootState) => state.provisionsListing);

  const documentTypesOptionsFiltered: DROPDOWN_OPTION[] = documentTypesOptions.filter(obj =>
    // @ts-ignore
    documentTypeIds.includes(obj.value),
  );

  // list the provisions of the policy that are USED in this transaction
  const transactionProvisionsUsed: TransactionProvisionTemp[] = activeTransactionProvisions.filter(
    ({ mandatory, selectedByDefault, selected }: TransactionProvisionTemp) =>
      mandatory || selectedByDefault || selected,
  );

  // List only the categories that have a provision
  const categoryList: ProvisionCategoryClone[] = provisionCategoriesList.filter(category => {
    const hasProvisions = transactionProvisionsUsed.find(
      (element: TransactionProvisionTemp) => element.provision?.provisionCategory?.id === category.id,
    );
    return !!hasProvisions;
  });

  const categoryOptions = categoryList.map(({ name, id }) => ({
    key: id,
    value: id,
    text: name,
  }));

  useEffect(() => {
    if (!activeAmendment?.id) {
      if (activeTransactionAmendments && activeTransactionAmendments.length) {
        onChange('index', (activeTransactionAmendments.length + 1).toString());
      } else {
        onChange('index', (1).toString());
      }
    }
  }, [activeTransactionAmendments?.length]);

  useEffect(() => {
    if (transactionId) {
      dispatch(listTransactionProvisions({ transactionId }));
    }
  }, [transactionId]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <InputField
            label="Name"
            value={activeAmendment?.name || ''}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="name"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <SelectField
            label="Applicable documents"
            value={activeAmendment.documentTypeId}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            placeholder="Select..."
            options={documentTypesOptionsFiltered}
            onChange={onChange}
            fieldKey="documentTypeId"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <SelectField
            label="Provision Category"
            value={activeAmendment.categoryId}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            search={true}
            placeholder="Select..."
            options={categoryOptions}
            onChange={onChange}
            fieldKey="categoryId"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <InputField
            label="Index"
            type={InputFieldType.NUMBER}
            value={(activeAmendment.index || '') as string}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="index"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default GeneralForm;
