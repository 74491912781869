import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { filterElementStatusTemplate } from 'atoms/TableReactPrime/columnTemplates';
import { listTransactionStatuses } from 'views/transactions/Dashboard/TransactionsTableConfig';
import { TransactionProps } from 'utils/types/stackingPlan';
import { Icons } from 'utils/utils-icons';
import './OngoingTransaction.scss';

interface OngoingTransactionProps {
  transaction: TransactionProps;
}

const OngoingTransaction = ({ transaction }: OngoingTransactionProps) => {
  const navigate = useNavigate();

  const { tenantName, status, startDate, transactionId } = transaction;

  const navigateToLink = (e: React.SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(`/transactions/${transactionId}/details`);
  };

  return (
    <div className="contract-ongoing-transaction border-sm-grayish-magenta-light color-blue-very-dark">
      <Grid className="m-none">
        <Grid.Row>
          <Grid.Column width={8}>
            <h6>Tenant</h6>
          </Grid.Column>
          <Grid.Column width={8}>{tenantName} </Grid.Column>
          <Grid.Column width={8}>
            <h6>Negotiation stage</h6>
          </Grid.Column>
          <Grid.Column width={8}>{filterElementStatusTemplate(status, listTransactionStatuses)}</Grid.Column>
          <Grid.Column width={8}>
            <h6>Started negotiations:</h6>
          </Grid.Column>
          <Grid.Column width={8}>{moment(startDate).format('DD/MM/YYYY')} </Grid.Column>
          <Grid.Column width={16}>
            <a
              onClick={navigateToLink}
              href={`/transactions/${transactionId}/details`}
            >
              Link to this Transaction
              <Icon
                icon={Icons.ArrowDown90}
                rotate={2}
              />
            </a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default OngoingTransaction;
