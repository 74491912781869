import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { EntityCreatorTypes, getEntity } from 'common/api/entities';
import LandlordEntityTabs from './LandlordEntityTabs';

const LandlordEntitiesDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { activeEntity, isLoading } = useAppSelector((state: RootState) => state.entityDetail);

  useEffect(() => {
    if (id) dispatch(getEntity({ type: EntityCreatorTypes.Landlord, id }));
  }, []);

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Landlord Entities',
        url: '/landlord-entities/dashboard',
      },
      {
        title: activeEntity.name || '',
      },
    ];
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <LandlordEntityTabs />
    </PageLayout>
  );
};

export default LandlordEntitiesDetails;
