import { CRentReview } from 'common/_classes/contractsV2';
import Decimal from 'decimal.js';
import ContractValuationData2 from 'common/model/ContractValuationData2';
import {
  CBuildingFeatures,
  CContractValuationDataOthers,
  CPremisesCharacteristics,
  CRentFreeAndEarlyOccupation,
  CSecurity2,
  CSpecialRights2,
  CTerm,
  CValuationDataPremises2,
} from './';

export class CContractValuationData {
  effectiveRentPcm: Decimal | null;
  effectiveRentPsfPcm: Decimal | null;
  faceRentPcm: Decimal | null;
  faceRentPsfPcm: Decimal | null;
  premiseSize: Decimal;
  tenant: string;
  others: CContractValuationDataOthers;
  buildingFeatures: CBuildingFeatures;
  premises: CValuationDataPremises2;
  premisesCharacteristics: CPremisesCharacteristics;
  rentFreeAndEarlyOccupation: CRentFreeAndEarlyOccupation;
  rentReview: CRentReview[];
  security: CSecurity2;
  specialRights: CSpecialRights2;
  term: CTerm;

  constructor(input: ContractValuationData2) {
    this.effectiveRentPcm = input && input.effectiveRentPcm ? new Decimal(input.effectiveRentPcm) : null;
    this.effectiveRentPsfPcm = input && input.effectiveRentPsfPcm ? new Decimal(input.effectiveRentPsfPcm) : null;
    this.faceRentPcm = input && input.faceRentPcm ? new Decimal(input.faceRentPcm) : null;
    this.faceRentPsfPcm = input && input.faceRentPsfPcm ? new Decimal(input.faceRentPsfPcm) : null;

    this.premiseSize = new Decimal(input.premiseSize);
    this.tenant = input.tenant;

    this.others = new CContractValuationDataOthers(input.others);
    this.buildingFeatures = new CBuildingFeatures(input.buildingFeatures);
    this.premises = new CValuationDataPremises2(input.premises);
    this.premisesCharacteristics = new CPremisesCharacteristics(input.premisesCharacteristics);
    this.rentFreeAndEarlyOccupation = new CRentFreeAndEarlyOccupation(input.rentFreeAndEarlyOccupation);
    this.rentReview = input.rentReview.map(review => new CRentReview(review));
    this.security = new CSecurity2(input.security);
    this.specialRights = new CSpecialRights2(input.specialRights);
    this.term = new CTerm(input.term);
  }
  static fragment() {
    return `
    effectiveRentPcm
    effectiveRentPsfPcm
    faceRentPcm
    faceRentPsfPcm
    premiseSize
    tenant
    buildingFeatures {
      ${CBuildingFeatures.fragment()}
    }
    others {
      ${CContractValuationDataOthers.fragment()}
    }
    premises {
      ${CValuationDataPremises2.fragment()}
    }
    premisesCharacteristics {
      ${CPremisesCharacteristics.fragment()}
    }
    rentReview {
      ${CRentReview.fragment()}
    }
    security {
      ${CSecurity2.fragment()}
    }
    specialRights {
      ${CSpecialRights2.fragment()}
    }
    term {
      ${CTerm.fragment()}
    }
    rentFreeAndEarlyOccupation {
      ${CRentFreeAndEarlyOccupation.fragment()}
    }
    `;
  }
}
