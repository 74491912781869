import MAnswerTypes from 'common/model/AnswerTypes';
import MFormatter from 'common/model/Formatter';
import MFunctionInput from 'common/model/FunctionInput';
import MReviewStatus from 'common/model/ReviewStatus';
import MUser from 'common/model/User';
import { OmitMultiple } from 'utils/UI';

type TUserPickClone = Pick<MUser, 'id' | 'firstName' | 'lastName'>;

// TODO move UserClone to at better file/folder inthe future
export interface IUserClone extends Omit<TUserPickClone, 'id' | 'firstName' | 'lastName'> {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface IFunctionInputClone extends OmitMultiple<MFunctionInput, 'type' | 'label'> {
  type: MAnswerTypes | null;
  label: string | null;
}

export interface IReviewStatusClone extends OmitMultiple<MReviewStatus, 'date' | 'user'> {
  date: Date | null;
  user: IUserClone;
}

export interface IFormatterClone
  extends OmitMultiple<
    MFormatter,
    'insertedAt' | 'updatedAt' | 'reviewStatus' | 'inputs' | 'function' | 'example' | 'description'
  > {
  example: string | null;
  description: string | null;
  function: string | null;
  inputs: IFunctionInputClone[];
}

export interface IFormatterDetail
  extends OmitMultiple<
    MFormatter,
    'id' | 'insertedAt' | 'updatedAt' | 'reviewStatus' | 'inputs' | 'function' | 'example' | 'description' | 'name'
  > {
  id?: string | null;
  name: string | null;
  example: string | null;
  description: string | null;
  function: string | null;
  inputs: IFunctionInputClone[];
  reviewStatus: IReviewStatusClone;
}

export class CFormatterDetail implements IFormatterDetail {
  id?: string | null;
  name: string | null;
  example: string | null;
  description: string | null;
  function: string | null;
  reviewFlag: boolean;
  inputs: IFunctionInputClone[];
  reviewStatus: IReviewStatusClone;

  constructor(input: IFormatterDetail) {
    this.id = input.id ?? null;
    this.name = input.name ?? null;
    this.example = input.example ?? null;
    this.description = input.description ?? null;
    this.function = input.function ?? null;
    this.reviewFlag = input.reviewFlag;
    this.inputs = input.inputs;
    this.reviewStatus = input.reviewStatus;
  }

  static fragment() {
    return `
      id
      name
      example
      description
      function
      inputs {
        label
        type
      }
      insertedAt
      updatedAt
      reviewFlag
      reviewStatus {
        date
        user {
          id
          firstName
          lastName
        }
      }
    `;
  }

  static init(user: IUserClone): CFormatterDetail {
    return {
      name: null,
      example: null,
      description: null,
      inputs: [],
      function: null,
      reviewFlag: false,
      reviewStatus: {
        date: null,
        user: user,
      },
    };
  }
}
