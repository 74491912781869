import { ProvisionListingClone } from 'common/_classes';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardViewTemplate, { CardTemplateConfiguration } from 'components/CardViewTemplate';
import ModalTemplate from 'components/ModalTemplate';
import ProvisionUseTemplate from 'views/provisions/ProvisionUseTemplate';
import { setActiveCategory } from 'store/provisions/provisionsListingSlice';
import { CountryClone, DocumentTypeClone, MODAL_MODE_OPTIONS } from 'common/api/miscellaneous';
import {
  ProvisionContentOnlyDocTypeId,
  checkNodeIntegrity,
  deleteProvision,
  deleteProvisionCategory,
  duplicateProvision,
  listProvisions,
} from 'common/api/provisions';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { USE_TYPE_OPTIONS } from 'utils/UI';
import { Icons } from 'utils/utils-icons';
import GenerateNodeIntegrityModal from '../GenerateNodeIntegrityModal';
import './ProvisionsCardsView.scss';

interface ProvisionsCardsViewProps {
  children?: ReactNode;
  setModalStatus: (type: MODAL_MODE_OPTIONS) => void;
}
/**
 * Component to render the CardColumns
 * @param setModalStatus Function | Open modal to edit or create a category
 */
const ProvisionsCardsView = ({ setModalStatus }: ProvisionsCardsViewProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { countries, documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);
  const { provisionsList, provisionCategoriesList } = useAppSelector((state: RootState) => state.provisionsListing);
  const [integrityModalStatus, setIntegrityModalStatus] = useState<boolean>(false);
  const [isCheckUseModalOpen, updateCheckUseModalStatus] = useState<boolean>(false);
  const [provisionId, setProvisionId] = useState<string>('');

  const onUpdateActiveCategory = (category: ProvisionCategoryClone): void => {
    dispatch(setActiveCategory({ category }));
    setModalStatus(MODAL_MODE_OPTIONS.UPDATE);
  };

  const orderedCategoriesList: ProvisionCategoryClone[] = orderBy(
    provisionCategoriesList,
    element => {
      return element.index;
    },
    'asc',
  );

  const orderedProvisionsList: ProvisionListingClone[] = orderBy(
    provisionsList,
    provision => {
      return provision.index;
    },
    'asc',
  );

  const onUpdateIntegrityModalStatus = (provisionId: string | undefined): void => {
    setIntegrityModalStatus(true);
    provisionId ? setProvisionId(provisionId) : null;
    dispatch(checkNodeIntegrity({ id: provisionId }));
  };

  /*
    Duplicate - Provision
    - Get the provision details
    - Create the new provision with the referenced provision data
    - Get list of all provisions
  */
  const onDuplicateProvision = (provisionId: string | undefined): void => {
    dispatch(duplicateProvision({ id: provisionId })).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(
          // TODO: fix bug: we should use listProvisionsAndCategories using the filter values for languages/jurisdiction/...
          listProvisions({
            first: 500,
          }),
        );
      }
    });
  };

  const cardMenuItems = (provision: ProvisionListingClone): DropdownMenuItem[] => {
    return [
      {
        key: '1',
        label: 'View Details',
        icon: Icons.EyeOpen,
        onClick: () => navigate(`/provisions/${provision.id}/details`),
      },
      {
        key: '2',
        label: 'Check Use',
        icon: Icons.EyeOpen,
        template: (
          <ModalTemplate
            isOpen={isCheckUseModalOpen}
            onToggleModalStatus={updateCheckUseModalStatus}
            trigger={
              <Dropdown.Item key="check-use">
                <Icon icon={Icons.EyeOpen} />
                <span>Check Use</span>
              </Dropdown.Item>
            }
            title={provision.name}
          >
            <ProvisionUseTemplate
              displayAsRow={false}
              provision_id={provision.id || ''}
            />
          </ModalTemplate>
        ),
      },
      {
        key: '3',
        label: 'Duplicate',
        icon: Icons.Duplicate,
        onClick: () => onDuplicateProvision(provision.id),
      },
      {
        key: '4',
        label: 'Delete',
        icon: Icons.Trash,
        onClick: () => dispatch(deleteProvision({ id: provision.id || '' })),
      },
      {
        key: '5',
        label: 'Check Node Integrity',
        icon: Icons.Play,
        onClick: () => onUpdateIntegrityModalStatus(provision.id),
      },
    ];
  };

  const getCountryLabel = (countryCode: string | null): JSX.Element => {
    const countryMatch: CountryClone = countries.find((country: CountryClone) => country.code === countryCode)!;
    if (countryMatch)
      return (
        <>
          <i className={`flag ${countryMatch?.flag}`}></i> {countryMatch.name}
        </>
      );
    else return <></>;
  };

  const getUseTypeLabel = (useType: string | null): string | undefined => {
    const found = USE_TYPE_OPTIONS.find(x => x.value === useType);
    if (found) return found.text;
  };

  const cardBodyTemplate = (provision: ProvisionListingClone): ReactNode => {
    const documentMap = new Map();

    documentTypesList.map((documentType: DocumentTypeClone) => {
      documentMap.set(documentType.id, documentType.name);
    });

    const getDocument = (id: string): string => {
      return documentMap.get(id);
    };

    return (
      <Grid
        className="component-card-body"
        data-test="component-card-body"
      >
        <Grid.Row className="p-none">
          <Grid.Column
            className="p-none"
            width={5}
          >
            Appears in:
          </Grid.Column>
          <Grid.Column
            className="p-none"
            width={11}
          >
            {provision.contents
              ?.map((content: ProvisionContentOnlyDocTypeId) => getDocument(content.documentTypeId))
              .join(', ')}
          </Grid.Column>
        </Grid.Row>
        {provision.language ? (
          <Grid.Row className="p-none">
            <Grid.Column
              className="p-none"
              width={5}
            >
              Language:
            </Grid.Column>
            <Grid.Column
              className="p-none"
              width={11}
            >
              {provision.language}
            </Grid.Column>
          </Grid.Row>
        ) : (
          ''
        )}
        {provision.jurisdiction ? (
          <Grid.Row className="p-none">
            <Grid.Column
              className="p-none"
              width={5}
            >
              Jurisdiction:
            </Grid.Column>
            <Grid.Column
              className="p-none"
              width={11}
            >
              {getCountryLabel(provision.jurisdiction)}
            </Grid.Column>
          </Grid.Row>
        ) : (
          ''
        )}
        {provision.entity?.name ? (
          <Grid.Row className="p-none">
            <Grid.Column
              className="p-none"
              width={5}
            >
              Owner:
            </Grid.Column>
            <Grid.Column
              className="p-none"
              width={11}
            >
              {provision.entity?.name}
            </Grid.Column>
          </Grid.Row>
        ) : (
          ''
        )}
        {provision.useType ? (
          <Grid.Row className="p-none">
            <Grid.Column
              className="p-none"
              width={5}
            >
              Use Type:
            </Grid.Column>
            <Grid.Column
              className="p-none"
              width={11}
            >
              {getUseTypeLabel(provision.useType)}
            </Grid.Column>
          </Grid.Row>
        ) : (
          ''
        )}
      </Grid>
    );
  };

  const cardHeaderTemplate = (provision: ProvisionListingClone): JSX.Element => {
    return (
      <div className="column-custom-template">
        <Popup
          className="popup-info"
          content={provision.name}
          trigger={
            <span
              className="name color-black"
              data-test="card-header-title"
            >
              {provision.name}
            </span>
          }
        />

        <span
          className="version m-r-xs m-t-xs m-t-none m-b-none"
          data-test="card-header-version"
        >
          V.2.0
        </span>
        <Badge badgeColor={BadgeColor.GREEN}>Published</Badge>
      </div>
    );
  };

  const cardConfigs: CardTemplateConfiguration<ProvisionCategoryClone, ProvisionListingClone> = {
    columnConfiguration: {
      header: {
        titleField: 'name',
        onEdit: category => onUpdateActiveCategory(category),
        onDelete: id => dispatch(deleteProvisionCategory({ id })),
        showDelete: category => category.provisions.length === 0,
      },
      width: '25rem',
    },
    cardConfiguration: {
      header: {
        headerTemplate: (provision: ProvisionListingClone) => cardHeaderTemplate(provision),
        cardMenuItems: (provision: ProvisionListingClone) => cardMenuItems(provision),
      },
      bodyTemplate: (provision: ProvisionListingClone) => cardBodyTemplate(provision),
      onClick: provision => navigate(`/provisions/${provision.id}/details`),
    },
  };

  const emptyCategoryTemplate: JSX.Element = (
    <div
      className="empty-category-card border-sm-grayish-magenta-light color-blue-very-dark-grayish"
      data-test="empty-category-card"
      onClick={() => setModalStatus(MODAL_MODE_OPTIONS.CREATE)}
    >
      <p className="m-t-xl">You don't have any provisions yet. First create a category and add provisions there.</p>
      <p className="m-t-ml font-600">
        <span className="circle-m plus-icon plus-position d-inline-flex m-r-xs">
          <Icon icon={Icons.Add} />
        </span>
        Create a category
      </p>
    </div>
  );

  const categoryList: ProvisionCategoryClone[] = orderedCategoriesList;

  const filterElementsPerCategory = (category: ProvisionCategoryClone): ProvisionListingClone[] => {
    return orderedProvisionsList.filter((provision: ProvisionListingClone) => {
      const categoryId = provision.provisionCategory.id;
      const columnId = category?.id;
      return categoryId === columnId;
    });
  };

  return (
    <div className="component-card-view">
      {categoryList.length === 0 ? (
        emptyCategoryTemplate
      ) : (
        <>
          <CardViewTemplate<ProvisionCategoryClone, ProvisionListingClone>
            getCardElementsList={category => filterElementsPerCategory(category)}
            categoryList={categoryList}
            configuration={cardConfigs}
          />
          <GenerateNodeIntegrityModal
            status={integrityModalStatus}
            provisionId={provisionId}
            setStatus={setIntegrityModalStatus}
          />
        </>
      )}
    </div>
  );
};

export default ProvisionsCardsView;
