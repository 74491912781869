import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ExportForecastingQueryVariables {
  propertyId: string;
  startDate: Date | string;
  endDate: Date | string;
}

const EXPORT_FORECASTING = (propertyId: string, startDate: Date | string, endDate: Date | string) => {
  if (!propertyId) {
    throw new Error('Export Forecasting: propertyId is required');
  }

  const variables: ExportForecastingQueryVariables = {
    propertyId,
    startDate,
    endDate,
  };

  return {
    query: gql`
      query ($propertyId: ID!, $startDate: Date!, $endDate: Date!) {
        exportForecasting(propertyId: $propertyId, startDate: $startDate, endDate: $endDate)
      }
    `,
    variables,
  };
};

export const exportForecasting = createAsyncThunk(
  'properties/exportForecasting',
  async (args: { id: any; startDate: any; endDate: any }) => {
    const { id, startDate, endDate } = args;
    const response = await client.query(EXPORT_FORECASTING(id, startDate, endDate));
    return response;
  },
);
