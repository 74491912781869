import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface CreatePortfolioQueryVariables {
  params: {
    entityId: string;
    name: string;
    propertyIds: string[];
  };
}

export const CREATE_PORTFOLIO = (name: string, propertyIds: string[]) => {
  const entityId: string | null = getCurrentLandlordEntityId();

  if (!entityId) throw new Error('Create Portfolio: entityId is required');

  const variables: CreatePortfolioQueryVariables = {
    params: {
      entityId,
      name,
      propertyIds,
    },
  };

  return {
    mutation: gql`
      mutation ($params: PortfolioInput!) {
        createPortfolio(params: $params) {
          entity {
            id
          }
          entityId
          id
          name
          propertyIds
        }
      }
    `,
    variables,
  };
};

export const createPortfolio = createAsyncThunk('portfolioDetail/createPortfolio', async (args, { getState }) => {
  const {
    portfolioDetail: { activePortfolio },
  } = getState() as RootState;

  const response = await getClientType().mutate(CREATE_PORTFOLIO(activePortfolio.name, activePortfolio.propertyIds));

  return response.data;
});
