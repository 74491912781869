import CharacteristicsTypes from 'common/model/CharacteristicsTypes';
import FloorView from 'common/model/FloorView';
import { CSpaceView } from './SpaceView';

export class CFloorView {
  id: string;
  characteristics: CharacteristicsTypes[];
  floorName: string;
  spaces: CSpaceView[];

  constructor(input: FloorView) {
    this.id = input.id;
    this.characteristics = input.characteristics;
    this.floorName = input.floorName;
    this.spaces = input.spaces.map(space => new CSpaceView(space));
  }

  static fragment() {
    return `
        id
        characteristics
        floorName
        spaces {
          ${CSpaceView.fragment()}
        }
        `;
  }
}
