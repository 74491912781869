export interface PasswordValidator {
  isInValid: boolean;
  errorMessage?: string;
  successMessage?: string;
}

interface PasswordValidtorParams {
  validationChecks: ((value: string, password: string | undefined) => PasswordValidator)[];
  value: string;
  isNewPasswordField: boolean;
  password?: string;
}
export const passwordValidator = ({
  validationChecks,
  value,
  isNewPasswordField,
  password,
}: PasswordValidtorParams): PasswordValidator => {
  const response: PasswordValidator = {
    isInValid: false,
    errorMessage: '',
    successMessage: '',
  };
  for (let i = 0; i < validationChecks.length; i++) {
    const validation = validationChecks[i](value, password);
    if (validation.isInValid) {
      response.isInValid = validation.isInValid;
      response.errorMessage = validation.errorMessage;
      return response;
    }
  }

  if (isNewPasswordField) {
    response.successMessage = 'Password is secure';
  } else if (password) {
    response.successMessage = 'Confirmed';
  }

  return response;
};
interface PasswordValidatorChecks {
  minLength: (value: string, password: string | undefined, minLength?: number) => PasswordValidator;
  specialCharacters: (value: string, password: string | undefined) => PasswordValidator;
  oneUpperCase: (value: string, password: string | undefined) => PasswordValidator;
  oneLowerCase: (value: string, password: string | undefined) => PasswordValidator;
  oneNumber: (value: string, password: string | undefined) => PasswordValidator;
  sameAsCurrentPassword: (value: string, password: string | undefined) => PasswordValidator;
  sameAsPassword: (value: string, password: string | undefined) => PasswordValidator;
}

export const passwordValidatorChecks: PasswordValidatorChecks = {
  minLength: (value: string, password: string | undefined, minLength: number = 8): PasswordValidator => {
    const response = {
      isInValid: false,
      errorMessage: '',
    };

    if (value.length < minLength) {
      response.isInValid = true;
      response.errorMessage = `Password should be at least ${minLength} characters long`;
    }

    return response;
  },
  specialCharacters: (value: string, password: string | undefined): PasswordValidator => {
    const response = {
      isInValid: false,
      errorMessage: '',
    };

    if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)) {
      response.isInValid = true;
      response.errorMessage = `The password must contain at least one special character (~! @#$%^&*)`;
    }

    return response;
  },
  oneUpperCase: (value: string, password: string | undefined): PasswordValidator => {
    const response = {
      isInValid: false,
      errorMessage: '',
    };

    if (!/[A-Z]/.test(value)) {
      response.isInValid = true;
      response.errorMessage = `The password must contain at least one upper case character`;
    }

    return response;
  },
  oneLowerCase: (value: string, password: string | undefined): PasswordValidator => {
    const response = {
      isInValid: false,
      errorMessage: '',
    };

    if (!/[a-z]/.test(value)) {
      response.isInValid = true;
      response.errorMessage = `The password must contain at least one lower case character`;
    }

    return response;
  },
  oneNumber: (value: string, password: string | undefined): PasswordValidator => {
    const response = {
      isInValid: false,
      errorMessage: '',
    };

    if (!/\d/.test(value)) {
      response.isInValid = true;
      response.errorMessage = `The password must contain at least one number`;
    }

    return response;
  },
  sameAsCurrentPassword: (value: string, password: string | undefined): PasswordValidator => {
    const response = {
      isInValid: false,
      errorMessage: '',
    };

    if (password === value) {
      response.isInValid = true;
      response.errorMessage = 'New password should not be same as current Password';
    }

    return response;
  },
  sameAsPassword: (value: string, password: string | undefined): PasswordValidator => {
    const response = {
      isInValid: false,
      errorMessage: '',
    };

    if (password !== value) {
      response.isInValid = true;
      response.errorMessage = 'Password confirmation is not same as new Password';
    }

    return response;
  },
};
