import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

// Question: why is this set to 200?
const FIRST = 200;

const List_PROVISION_CATEGORIES = () => {
  return {
    query: gql`
      query ($first: Int) {
        listProvisionCategories(first: $first) {
          edges {
            cursor
            node {
              id
              name
              color
              index
              insertedAt
              updatedAt
              provisions {
                contents {
                  content
                  documentTypeId
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables: {
      first: FIRST,
    },
  };
};

export const listProvisionCategories = createAsyncThunk('provisions/listProvisionCategories', async () => {
  const response = await adminClient.query(List_PROVISION_CATEGORIES());
  return response;
});
