enum AnswerTypes {
  Text = 'TEXT',
  Date = 'DATE',
  Time = 'TIME',
  Duration = 'DURATION',
  Period = 'PERIOD',
  Number = 'NUMBER',
  NumberPercent = 'NUMBER_PERCENT',
  NumberUnit = 'NUMBER_UNIT',
  Boolean = 'BOOLEAN',
  SingleChoice = 'SINGLE_CHOICE',
  MultiChoice = 'MULTI_CHOICE',
  Image = 'IMAGE'
}
export default AnswerTypes;
