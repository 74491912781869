import { Dimmer, Loader } from 'semantic-ui-react';

const DimmerLoader = ({ status }: { status?: boolean }) => {
  return (
    <Dimmer active={status}>
      <Loader
        size="massive"
        active={status}
      >
        Loading...
      </Loader>
    </Dimmer>
  );
};

export default DimmerLoader;
