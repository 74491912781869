import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterCategoryDetailClone } from 'common/_classes';
import { RootState } from 'store';

interface UpdateParameterCategoryQueryVariables {
  id: string;
  params: any;
}

const UPDATE_PARAMETER_CATEGORY = (activeCategory: ParameterCategoryDetailClone) => {
  const { id, name, color, index } = activeCategory;

  if (!id) throw new Error('UPDATE_PARAMETER_CATEGORY: id is required');

  const params = {
    name,
    color,
    index,
  };

  const variables: UpdateParameterCategoryQueryVariables = {
    id,
    params,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: ParameterCategoryInput!) {
        updateParameterCategory(id: $id, params: $params) {
          ${ParameterCategoryDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateParameterCategory = createAsyncThunk(
  'parameters/updateParameterCategory',
  async (_args, { getState }) => {
    const {
      parametersListing: { activeCategory },
    }: any = getState() as RootState;
    const response = await adminClient.mutate(UPDATE_PARAMETER_CATEGORY(activeCategory));
    return response;
  },
);
