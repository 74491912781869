import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import Scale from 'common/model/Scale';
import { PortfolioRevenueAnalysis, PropertyRevenueAnalysis } from 'common/api/dashboard';
import {
  generatePortfolioRevenueAnalysisBase,
  generatePropertyRevenueAnalysisBase, // getRevenueForecasts,
} from 'common/api/dashboard/forecasting';
import { DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS } from 'common/api/miscellaneous';

const newProperty = () => ({
  entity: {
    id: null,
  },
  id: null,
  photo: null,
  buildingName: null,
  buildingIdentifier: null,
  buildingNumberOfFloors: null,
  buildingNumberOfUndergroundFloors: 0,
  area: {
    gross: null,
    grossDescription: null,
    net: null,
    netDescription: null,
    lettable: null,
    lettableDescription: null,
    saleable: null,
    saleableDescription: null,
  },
  type: [],
  lotNumber: null,
  buildingGrade: null,
  address: null,
  description: null,
  identification: null,
  measurementUnit: null,
  measurementMethod: null,
  floors: [],
  documents: [],
  reviewFlag: false,
  reviewStatus: {
    date: null,
    user: {
      id: null,
      firstName: null,
      lastName: null,
    },
  },
});

const propertyRevenueAnalysis: PropertyRevenueAnalysis = {
  startDate: '',
  endDate: '',
  propertyId: '',
  propertyIdentifier: '',
  propertyName: '',
  period: Scale.Month,
  propertyData: [],
  unitsData: [],
};

const portfolioRevenueAnalysis: PortfolioRevenueAnalysis = {
  endDate: '',
  period: Scale.Month,
  portfolioData: [],
  propertiesData: [],
  startDate: '',
  unitsData: [],
};

const initialState = {
  activeProperty: newProperty(),
  forecastsList: [],
  search: null,
  pageInfo: [],
  isLoading: false,
  isSearching: false,
  dateFilter: {
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').format('YYYY-MM-DD'),
  },
  period: Scale.Month,
  propertyRevenueAnalysis,
  portfolioRevenueAnalysis,
  viewType: DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS.GRAPH,
};

const forecastsSlice = createSlice({
  name: 'forecastsData',
  initialState,
  reducers: {
    updateForecastView: (state, action) => {
      state.viewType = action.payload.value;
    },
    updateForecastFilterDates: (state, action) => {
      const { date, type } = action.payload;
      state.dateFilter[type as 'endDate' | 'startDate'] = moment(date).format('YYYY-MM-DD');
    },

    updateActiveProperty: (state, action) => {
      state.activeProperty = action.payload.property;
    },
  },
  extraReducers: builder => {
    // NOTE: getRevenueForecasts is never dispatched, thererfore these listeners are not needed
    // Consider removing them

    //getRevenueForecasts
    // builder.addCase(getRevenueForecasts.pending, (state, action) => {
    //   const { searchValue } = action.meta.arg;
    //   if (searchValue || searchValue === '') {
    //     state.isSearching = true;
    //   } else {
    //     state.isLoading = true;
    //   }
    // });
    // builder.addCase(getRevenueForecasts.fulfilled, (state, action) => {
    //   const { edges, pageInfo } = action.payload.data.getRevenueForecasting;
    //   state.forecastsList = edges;
    //   state.pageInfo = pageInfo;
    //   state.search = null;
    //   state.isLoading = false;
    //   state.isSearching = false;
    // });
    // builder.addCase(getRevenueForecasts.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.isSearching = false;
    //   console.warn(action.error);
    //   toast.error('getRevenueForecasts API request failed');
    // });

    // generatePropertyRevenueAnalysisBase
    builder.addCase(generatePropertyRevenueAnalysisBase.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(generatePropertyRevenueAnalysisBase.fulfilled, (state, action) => {
      state.propertyRevenueAnalysis = action.payload.data.generatePropertyRevenueAnalysisBase2;
      state.isLoading = false;
    });
    builder.addCase(generatePropertyRevenueAnalysisBase.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('generatePropertyRevenueAnalysisBase API request failed');
    });

    // generatePortfolioRevenueAnalysisBase
    builder.addCase(generatePortfolioRevenueAnalysisBase.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(generatePortfolioRevenueAnalysisBase.fulfilled, (state, action) => {
      state.portfolioRevenueAnalysis = action.payload.data.generatePortfolioRevenueAnalysisBase2;
      state.isLoading = false;
    });

    builder.addCase(generatePortfolioRevenueAnalysisBase.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('generatePortfolioRevenueAnalysisBase API request failed');
    });
  },
});

export const { updateActiveProperty, updateForecastFilterDates, updateForecastView } = forecastsSlice.actions;

export default forecastsSlice.reducer;
