import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import { updateBenchmarkInput } from 'store/benchmark/benchmarkDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const SecurityForm = ({ mode }: ModeProps) => {
  const {
    activeBenchmark: { securityAmount },
  } = useAppSelector((state: RootState) => state.benchmarkDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: any) => {
    dispatch(updateBenchmarkInput({ key, value: parseFloat(value) }));
  };

  return (
    <div className="col-6">
      <InputField
        label="Security amount"
        dataTest="security-amount-input-field"
        fieldKey="securityAmount"
        value={String(securityAmount)}
        unit={'(HK$)'}
        disabled={mode === MODE_OPTIONS.READ}
        onChange={onChange}
      />
    </div>
  );
};

export default SecurityForm;
