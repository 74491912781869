/*
 * Definition of the column (names, format...)
 */
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementNumberRange,
  filterElementStatus,
  filterElementStatusTemplate,
  filterElementYesOrNo,
} from 'atoms/TableReactPrime/columnTemplates';
import Tooltip from 'atoms/Tooltip';
import RawHTML from 'components/RawHTML';
import { TranslateToContractClone } from 'common/api/translatorsToContract';
import { limitStringSize } from 'utils/utils-string';
import { capitalizeFirstLetter } from 'utils/utils-string';
import { TranslateToContractRowActionButtons } from './TranslateToContractRowActionButtons';

const statuses: StatusBadgeArgument[] = [
  { value: 'REVIEWED', color: BadgeColor.GREEN },
  { value: 'DRAFTING', color: BadgeColor.ORANGE },
];

export class TranslateToContractRow {
  id: string;
  name: string | null;
  index: number;
  description: string | null;
  active: boolean;
  status: string;

  constructor(translateToContract: TranslateToContractClone) {
    this.id = translateToContract.id as string;
    this.name = translateToContract.name;
    this.index = Number(translateToContract.index);
    this.description = translateToContract.description;
    this.active = translateToContract.active;
    this.status = translateToContract.reviewFlag ? statuses[0].value : statuses[1].value;
  }
}

const filterElementDescription = (row: TranslateToContractRow): JSX.Element => {
  let htmlRawString: string = '';
  htmlRawString += row.description;
  return (
    <Tooltip
      trigger={
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: limitStringSize(htmlRawString, 35),
            }}
          ></span>
        </div>
      }
      HTMLContent={<RawHTML rawHTMLString={htmlRawString} />}
    />
  );
};

export const translateToContractColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  const results: CustomColumnProps[] = [
    {
      field: 'name',
      header: 'Name',
      filter: true,
      filterPlaceholder: 'Search by Name',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      mandatory: true,
      width: '30rem',
    },
    {
      field: 'description',
      header: 'Description',
      width: '30rem',

      filter: true,
      filterPlaceholder: 'Search by Description',
      filterField: 'description',
      sortable: true,
      body: (row: TranslateToContractRow) => filterElementDescription(row),
    },
    {
      field: 'index',
      header: 'Index',
      filter: true,
      filterPlaceholder: 'Search by Index',
      filterField: 'index',
      sortable: true,
      dataType: 'numeric',
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      showFilterMatchModes: false,
    },
    {
      field: 'active',
      header: 'Active',
      filterPlaceholder: 'Search by Active',
      filter: true,
      filterField: 'active',
      sortable: true,
      showFilterMenuOptions: false,
      filterElement: options => filterElementYesOrNo(options, 'Active'),
      filterMatchMode: 'equals',

      body: row => capitalizeFirstLetter(row.active.toString()),
    },
    {
      field: 'status',
      header: 'Status',
      filter: true,
      filterPlaceholder: 'Search by status',
      filterField: 'status',
      body: (row: TranslateToContractRow) => filterElementStatusTemplate(row.status, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      className: 'status-column',
      showFilterMatchModes: false,
      sortable: true,
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: TranslateToContractRow) => (
        <TranslateToContractRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
    },
  ];
  return results;
};
