import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import {
  setDashboardOption,
  updateCurrentDashboardTab,
  updateSelectedContextName,
} from 'store/dashboard/dashboardSlice';
import { updateActivePortfolio } from 'store/dashboard/dashboardSlice';
import { updateActiveProperty } from 'store/dashboard/forecastsSlice';
import { selectedProperty } from 'store/dashboard/stackingPlanSlice';
import AnalysisContext from 'common/model/AnalysisContext';
import { DASHBOARD_TABS_OFFSET, WholePortolioText } from 'common/api/dashboard';
import { DASHBOARD_PAGE_SCOPE } from 'common/api/dashboard';
import { listLandlordProperties } from 'common/api/miscellaneous/listLandlordProperties';
import { listPortfolios } from 'common/api/portfolio';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { PortfolioClone, PropertyProps } from 'utils/types/portfolio';
import { Icons } from 'utils/utils-icons';
import './DashboardScopeSelector.scss';

const DashboardScopeSelector = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeTabIndex, dashboardOption, dashboardValue, contextId } = useAppSelector(
    (state: RootState) => state.dashboard,
  );
  const { landlordProperties } = useAppSelector(({ miscellaneous }: RootState) => miscellaneous);
  const { portfolios } = useAppSelector((state: RootState) => state.portfoliosListing);
  // const { activeContract } = useAppSelector((state: RootState) => state.contractDetail);
  const [open, setOpen] = useState<boolean>(false);
  const propertiesList: PropertyProps[] = landlordProperties;
  const [dropdownText, setDropdownText] = useState<string>(`Portfolio: ${dashboardValue}`);

  const selectScope = (
    e: ReactMouseEvent<Element, MouseEvent>,
    contextName: string,
    contextType: AnalysisContext,
    property: PropertyProps | null = null,
    portfolio: PortfolioClone | null = null,
  ): void => {
    e.stopPropagation();

    let contextId: string | null = null;

    if (AnalysisContext.Portfolio && portfolio) {
      contextId = portfolio.id;
    } else if (AnalysisContext.Property && property) {
      contextId = property.id;
    }

    dispatch(
      updateSelectedContextName({
        value: contextName,
        type: contextType,
        id: contextId,
      }),
    );
    dispatch(updateActiveProperty({ property }));
    dispatch(selectedProperty({ property }));
    dispatch(updateActivePortfolio({ portfolio }));

    if (contextType === AnalysisContext.Portfolio && activeTabIndex === DASHBOARD_TABS_OFFSET.STACKING_PLAN) {
      dispatch(updateCurrentDashboardTab({ tab: DASHBOARD_TABS_OFFSET.DASHBOARD }));
    }
    setOpen(false);
  };

  const onSelectScope =
    (
      contextName: string,
      contextType: AnalysisContext,
      property: PropertyProps | null = null,
      portfolio: PortfolioClone | null = null,
    ) =>
    (e: ReactMouseEvent<Element, MouseEvent>) =>
      selectScope(e, contextName, contextType, property, portfolio);

  const landlordId: string | null = getCurrentLandlordEntityId();

  const onSetDashboardOption = (option: DASHBOARD_PAGE_SCOPE) => () => dispatch(setDashboardOption({ value: option }));

  const handleClickOutside = (e: Event): void => {
    const target = e.target as HTMLElement;
    let type = typeof target.className;
    if (type === 'string') {
      if (!target.className.includes('dashboard-selector')) {
        setOpen(false);
      }
    }
  };

  // Dropdown Text Control
  useEffect(() => {
    let context = dashboardOption === DASHBOARD_PAGE_SCOPE.ALL ? DASHBOARD_PAGE_SCOPE.PORTFOLIO : dashboardOption;

    if (!contextId) {
      context = DASHBOARD_PAGE_SCOPE.PORTFOLIO;
    } else if (portfolios.find(item => item.id === contextId)) {
      context = DASHBOARD_PAGE_SCOPE.PORTFOLIO;
    } else if (propertiesList.find(item => item.id === contextId)) {
      context = DASHBOARD_PAGE_SCOPE.PROPERTY;
    }

    setDropdownText(`${context}: ${dashboardValue}`);
  }, [dashboardOption, dashboardOption, contextId]);

  useEffect(() => {
    dispatch(listPortfolios({ first: 1000 }));
    dispatch(listLandlordProperties(landlordId));
    const dropdown = document.getElementById('analytics-dashboard');

    if (dropdown) {
      dropdown.addEventListener('click', handleClickOutside, true);
    }
    return () => {
      if (dropdown) {
        dropdown.removeEventListener('click', handleClickOutside, true);
      }
    };
  }, [dispatch, landlordId]);

  const filterOpen = (e: any): void => {
    if (open) {
      const id = e.target.id;
      if (['down-arrow', 'analytics-dropdown'].includes(id)) {
        setOpen(false);
      }
    } else {
      setOpen(true);
    }
  };

  const portfoliosExists: boolean = portfolios.length > 0;
  const propertiesExists: boolean = propertiesList.length > 0;

  return (
    <div className="analytics-dashboard-header">
      <div className="header-left">
        <Dropdown
          id="analytics-dropdown"
          className="dashboard-selector p-ml border-sm-grayish-magenta-light"
          data-testid="entity"
          data-test="dashboard-view-level-selector"
          icon={
            <Icon
              id="down-arrow"
              className="down-arrow height-1_6 width-1_6"
              icon={Icons.ArrowDown}
            />
          }
          open={open}
          onClick={e => filterOpen(e)}
          text={dropdownText}
        >
          <Dropdown.Menu>
            {dashboardOption === DASHBOARD_PAGE_SCOPE.ALL && (
              <>
                <Dropdown.Item onClick={onSelectScope(WholePortolioText, AnalysisContext.Portfolio)}>
                  <span data-test="dashboard-portfolios">Whole portfolio</span>
                </Dropdown.Item>

                {portfoliosExists && (
                  <Dropdown.Item onClick={onSetDashboardOption(DASHBOARD_PAGE_SCOPE.PORTFOLIO)}>
                    <span data-test="dashboard-view-level-portfolios">
                      Portfolios
                      <Icon
                        className="initial-menu-icon height-1_143 width-1_143"
                        icon={Icons.ArrowRightDash}
                      />
                    </span>
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={onSetDashboardOption(DASHBOARD_PAGE_SCOPE.PROPERTY)}>
                  <span data-test="dashboard-view-level-properties">
                    Properties
                    <Icon
                      className="initial-menu-icon height-1_143 width-1_143"
                      icon={Icons.ArrowRightDash}
                    />
                  </span>
                </Dropdown.Item>
              </>
            )}
            {dashboardOption === DASHBOARD_PAGE_SCOPE.PORTFOLIO && portfoliosExists && (
              <>
                <Dropdown.Item onClick={onSetDashboardOption(DASHBOARD_PAGE_SCOPE.ALL)}>
                  <span
                    className="portfolio"
                    data-test="dashboard-view-level-back"
                  >
                    <Icon
                      className="portfolio-icon width-1_143 height-1_143"
                      icon={Icons.ArrowBackIOS}
                    />
                    Portfolios
                  </span>
                </Dropdown.Item>
                {portfolios.map(portfolio => (
                  <Dropdown.Item
                    key={portfolio.id}
                    onClick={onSelectScope(portfolio.name, AnalysisContext.Portfolio, null, portfolio)}
                  >
                    <span>{portfolio.name}</span>
                  </Dropdown.Item>
                ))}
              </>
            )}
            {dashboardOption === DASHBOARD_PAGE_SCOPE.PROPERTY && propertiesExists && (
              <>
                <Dropdown.Item onClick={onSetDashboardOption(DASHBOARD_PAGE_SCOPE.ALL)}>
                  <span
                    className="portfolio"
                    data-test="dashboard-view-level-back"
                  >
                    <Icon
                      className="portfolio-icon width-1_143 height-1_143"
                      icon={Icons.ArrowBackIOS}
                    />
                    Properties
                  </span>
                </Dropdown.Item>
                {propertiesList.map((property: PropertyProps) => (
                  <Dropdown.Item
                    data-test={property.buildingName}
                    key={property.id}
                    onClick={onSelectScope(
                      property.buildingName ? property.buildingName : '',
                      AnalysisContext.Property,
                      property,
                      null,
                    )}
                  >
                    <span>{property.buildingName}</span>
                  </Dropdown.Item>
                ))}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default DashboardScopeSelector;
