import { CValuationReport } from 'common/_classes/valuationReport2';
import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { FilterDataType } from 'components/ComparablesTable';
import ModalTemplate, { ModalTemplateSize } from 'components/ModalTemplate';
import { checkIfNotDefined, deepClone } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { updateNestedObject } from 'utils/utils-nested-object-update';
import { disableApplyButton } from '../RowTemplate/RowFilterPopup/validation.utils';
import { RowAction, RowLabel, RowProps } from '../RowTemplate/RowProps';
import Building from './Building';
import PermittedUse from './PermittedUse';
import PremiseSize from './PremiseSize';
import TermLength from './TermLength';
import TermStartDate from './TermStartDate';

interface DefaultFilterProps {
  filterData: FilterDataType;
  onFilter: (filterData: FilterDataType) => void;
  setFilterData: (filterData: FilterDataType) => void;
}

export const initializeDefaultFIlters = (rowConfigs: RowProps[], report: CValuationReport) => {
  const filterData: FilterDataType = { activeConfigId: null };
  for (let i = 0; i < rowConfigs.length; i++) {
    const config = rowConfigs[i];
    if (config.defaultFilter) {
      filterData[config.id] = config.defaultFilter(report, config);
    }
  }
  return filterData;
};

export interface DefaultFilterRowProps {
  onChange: (key: string, value: any, updateRoot?: boolean) => void;
  values: any;
}

const DefaultFilter = ({ filterData, setFilterData, onFilter }: DefaultFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const getLabelValues = (label: RowLabel) => {
    const data = Object.entries(filterData).find(data => {
      if (data[0] === 'activeConfigId') return false;

      const value = data[1] as RowAction & RowProps;

      if (value.label === label) return true;

      return false;
    });

    if (data) {
      return data[1];
    } else {
      return {
        filterMode: undefined,
        filterMode2: undefined,
        filterValue: undefined,
        filterValue2: undefined,
        filterLogicalOperator: undefined,
        id: undefined,
      };
    }
  };

  const values = {
    [RowLabel.PREMISES_SIZE]: getLabelValues(RowLabel.PREMISES_SIZE),
    [RowLabel.TERM_START_DATE]: getLabelValues(RowLabel.TERM_START_DATE),
    [RowLabel.TERM_LENGTH]: getLabelValues(RowLabel.TERM_LENGTH),
    [RowLabel.BUILDING]: getLabelValues(RowLabel.BUILDING),
    [RowLabel.PERMITTED_USE]: getLabelValues(RowLabel.PERMITTED_USE),
  };

  const onChange = (keys: string, value: any, updateRoot?: boolean) => {
    const [id, key] = keys.split(',');

    if (!checkIfNotDefined(id)) {
      const parsedKey = parseInt(id);
      let updatedFilterData = deepClone(filterData);

      if (updateRoot) {
        updatedFilterData[parsedKey] = { ...updatedFilterData[parsedKey], ...value };
      } else {
        updatedFilterData[parsedKey] = updateNestedObject(updatedFilterData[parsedKey], key, value);
      }

      setFilterData(updatedFilterData);
    }
  };

  const labels = [RowLabel.PREMISES_SIZE, RowLabel.TERM_START_DATE, RowLabel.TERM_LENGTH, RowLabel.BUILDING];

  const onClear = () => {
    const updatedFilter = deepClone(filterData);

    for (const key in filterData) {
      if (key === 'activeConfigId') {
        updatedFilter.activeConfigId = null;
      } else {
        const config = filterData[key];

        updatedFilter[config.id] = {
          ...filterData[config.id],
          filterMode: undefined,
          filterMode2: undefined,
          filterValue: undefined,
          filterValue2: undefined,
          filterLogicalOperator: undefined,
          filterActive: false,
        };
      }
    }
    setIsOpen(false);
    setFilterData(updatedFilter);
    onFilter(updatedFilter);
  };

  const onApplyDefaultFilter = () => {
    setIsOpen(false);
    onFilter(filterData);
  };

  const disableButton = () => {
    let active = 0;

    for (const key in filterData) {
      const config = filterData[key];
      if (labels.includes(config?.label)) {
        const disable = disableApplyButton(config, true);

        if (disable) return disable;

        if (config.filterActive) active++;
      }
    }

    return !active;
  };

  return (
    <>
      <span className={`filter-icon active`}>
        <Icon
          icon={Icons.Filter}
          onClick={() => setIsOpen(true)}
        />
      </span>

      <ModalTemplate
        isOpen={isOpen}
        onToggleModalStatus={setIsOpen}
        title="Filter"
        className="valuation-contract-view-row-filters-modal"
        showHeader={true}
        size={ModalTemplateSize.fullscreen}
      >
        <div>
          {/* Premise Size  */}
          <PremiseSize
            onChange={onChange}
            values={values[RowLabel.PREMISES_SIZE]}
          />

          {/* Term start date  */}
          <TermStartDate
            onChange={onChange}
            values={values[RowLabel.TERM_START_DATE]}
          />

          {/* Term length  */}
          <TermLength
            onChange={onChange}
            values={values[RowLabel.TERM_LENGTH]}
          />

          {/* Building */}
          <Building
            onChange={onChange}
            values={values[RowLabel.BUILDING]}
          />

          {/* Permitted use */}
          <PermittedUse
            onChange={onChange}
            values={values[RowLabel.PERMITTED_USE]}
          />
        </div>

        <div className="d-flex justify-center m-t-sm flex-gap-m">
          <Button
            className="btn orange-outline m-none"
            onClick={onClear}
          >
            CLEAR
          </Button>

          <Button
            className="btn orange-bg m-none"
            onClick={onApplyDefaultFilter}
            disabled={disableButton()}
          >
            APPLY
          </Button>
        </div>
      </ModalTemplate>
    </>
  );
};

export default DefaultFilter;
