import { Grid } from 'semantic-ui-react';
import InputField, { InputFieldType } from 'atoms/FormField/Input';

interface InputWithUnitProps {
  columnWidth?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | undefined;
  label: string | null;
  value: string;
  onChange: (key: string, value: any) => void;
  fieldKey: string;
  unit: string;
  dataTest?: string;
}

const InputWithUnit = ({ label, value, columnWidth, onChange, fieldKey, unit, dataTest }: InputWithUnitProps) => {
  return (
    <Grid.Column
      width={columnWidth}
      className="input-column"
    >
      <InputField
        label={label}
        value={value}
        onChange={onChange}
        fieldKey={fieldKey}
        type={InputFieldType.NUMBER}
        unit={`(${unit})`}
        delimiter="COMMA"
        dataTest={dataTest}
      />
    </Grid.Column>
  );
};

export default InputWithUnit;
