import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { EntityPickName } from '../../_classes/Entity';

const LIST_LANDLORD_ENTITIES = () => {
  return {
    query: gql`
      query {
        listLandlordEntities {
          ${EntityPickName.fragments()}
        }
      }
    `,
  };
};

export const listLandlordEntities = createAsyncThunk('provisions/listLandlordEntities', async () => {
  const response = await adminClient.query(LIST_LANDLORD_ENTITIES());
  return response;
});
