import SurrenderRights from 'common/model/SurrenderRights';
import SurrenderRightsInput from 'common/model/SurrenderRightsInput';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';
import { convertDateToISO } from 'utils/utils-date';

export interface SurrenderRightsInputClone
  extends Omit<SurrenderRightsInput, 'earliestDate' | 'latestDate' | 'noticePeriodMonths' | 'wholeOrPart'> {
  earliestDate: Date | null;
  latestDate: Date | null;
  noticePeriodMonths: number | null;
  wholeOrPart: WholeOrPartOptions | null;
}

export class CSurrenderRights implements SurrenderRightsInputClone {
  compensationFormula: string;
  earliestDate: Date | null;
  latestDate: Date | null;
  noticePeriodMonths: number | null;
  wholeOrPart: WholeOrPartOptions | null;

  constructor(input: SurrenderRights | null) {
    this.compensationFormula = input ? input.compensationFormula : '';
    this.earliestDate = input && input.earliestDate ? new Date(input.earliestDate) : null;
    this.latestDate = input && input.latestDate ? new Date(input.latestDate) : null;
    this.noticePeriodMonths = input ? input.noticePeriodMonths : null;
    this.wholeOrPart = input ? input.wholeOrPart : null;
  }

  static fragment() {
    return `
        compensationFormula
        earliestDate
        latestDate
        noticePeriodMonths
        wholeOrPart
    `;
  }

  static formatForAPI(right: CSurrenderRights) {
    return {
      compensationFormula: right.compensationFormula ? right.compensationFormula : '',
      earliestDate: convertDateToISO(right.earliestDate),
      latestDate: convertDateToISO(right.latestDate),
      noticePeriodMonths: right.noticePeriodMonths ? parseInt(right.noticePeriodMonths.toString()) : null,
      wholeOrPart: right ? right.wholeOrPart : null,
    };
  }
}
