import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

const GET_DEVELOPMENT = (id: string) => {
  return {
    query: gql`
      query ($id: ID!) {
        getDevelopment(id: $id) {
          address
          addressDistrict
          area
          developmentIdentifier
          developmentLotNumber
          developmentName
          entityId
          id
          insertedAt
          ownershipType
          updatedAt
        }
      }
    `,
    variables: { id },
  };
};

export const getDevelopment = createAsyncThunk(
  'propertiesV2/getDevelopment',
  async (args: { id: string }, { getState }) => {
    const response = await client.query(GET_DEVELOPMENT(args.id));
    return response;
  },
);
