import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TabPane } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateParameterViewDetailsTab } from 'store/parameters/parameterDetailSlice';
import { PARAMETER_TABLE_IMPLEMENTATION } from 'common/api/miscellaneous';
import { getParameterTable } from 'common/api/parameters';
import { listParametersWithAnswerType } from 'common/api/parameters';
import ColumnDefinition from '../CreatorViewer/Tabs/ColumnDefinition';
import ParameterInformationTab from '../CreatorViewer/Tabs/ParameterInformationTab';
import { tableDisabled } from '../CreatorViewer/Tabs/ParameterValidator';

const getParameterTableTabMenuConfig = (): TabMenuProps[] => {
  return [
    {
      key: '0',
      href: '?tab=general',
      label: 'General',
      dataTest: 'general-tab',
      customTab: <ParameterInformationTab type={PARAMETER_TABLE_IMPLEMENTATION.TABLE} />,
    },
    {
      key: '1',
      href: '?tab=column',
      label: 'Column definition',
      dataTest: 'column-definition-tab',
      customTab: <ColumnDefinition />,
    },
    {
      key: '2',
      href: '?tab=approvals',
      label: 'Approvals',
      dataTest: 'approvals-tab',
      customTab: <TabPane attached={false}>Approvals</TabPane>,
    },
  ];
};

const TableOfParametersTabs = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { activeTabIndex, activeTableParameter } = useAppSelector((state: RootState) => state.parameterDetail);

  // TODO: Figure why this API call is here, and why no AnswersTypes are passed as properties
  useEffect(() => {
    dispatch(listParametersWithAnswerType({}));
  }, []);

  const onTabChange = (activeIndex: number): void => {
    if (activeIndex === 1) {
      const detailsPage = pathname.includes('details');
      if (tableDisabled(activeTableParameter) && detailsPage) {
        // @ts-ignore
        dispatch(getParameterTable({ id: activeTableParameter.id }));
      }
    }
  };
  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChange={(activeIndex: number) => onTabChange(activeIndex)}
      onTabChangeAction={updateParameterViewDetailsTab}
      tabMenu={getParameterTableTabMenuConfig()}
    />
  );
};
export default TableOfParametersTabs;
