import { CAcFee } from 'common/_classes/contractsV2';
import Decimal from 'decimal.js';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import ManagementChargeMonthlyAcFeesPayable from 'common/model/ManagementChargeMonthlyAcFeesPayable';
import OutOfHoursAcChargesPayable from 'common/model/OutOfHoursAcChargesPayable';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import { MANAGEMENT_CHARGE_MONTHLY_AC_FEES_PAYABLE_OPTIONS, OUT_OF_HOURS_AC_CHARGES_PAYABLE_OPTIONS } from 'utils/UI';

const AcFeesPayableForm = ({ mode, isViewPage }: ModeProps & ViewProps) => {
  const { activeContract } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);
  const dispatch = useAppDispatch();

  const disabled = mode === MODE_OPTIONS.READ || isViewPage;

  const outOfHoursAcPaybleApplicable =
    activeContract?.monthlyAcFeesPayable &&
    activeContract?.monthlyAcFeesPayable !== ManagementChargeMonthlyAcFeesPayable.NotApplicableAcNotProvided;

  const acFees = activeContract?.acFees || [];
  const acFeesRootKey = 'acFees';

  const createACFee = () => {
    const newACfee = new CAcFee(null);
    dispatch(
      updateActiveContractInput({
        key: acFeesRootKey,
        value: [newACfee],
      }),
    );
  };

  const deleteACFees = () => {
    if (acFees.length !== 0)
      dispatch(
        updateActiveContractInput({
          key: acFeesRootKey,
          value: [],
        }),
      );
  };

  const onChange = (key: string, value: any) => {
    dispatch(
      updateActiveContractInput({
        key,
        value: value ? value : null,
      }),
    );
  };

  const updateAndDeleteACFees = (key: string, value: any) => {
    onChange(key, value);
    deleteACFees();
  };

  const updateAndCreateACFeesIfNoACFee = (key: string, value: any) => {
    onChange(key, value);

    if (acFees.length === 0) createACFee();
  };

  const onChangeMonthlyAcFeesPayable = (key: string, value: any) => {
    switch (value) {
      case '':
        updateAndDeleteACFees(key, value);
        break;

      case ManagementChargeMonthlyAcFeesPayable.NotApplicableAcNotProvided:
        updateAndDeleteACFees(key, value);
        onChange('outOfHoursAcPayble', null);
        break;

      case ManagementChargeMonthlyAcFeesPayable.NotIncludedAdditionalAcFeesPaidByTenant:
        updateAndCreateACFeesIfNoACFee(key, value);
        break;

      case ManagementChargeMonthlyAcFeesPayable.IncludedInManagementCharges:
        onChange(key, value);

        if (!activeContract?.outOfHoursAcPayble) deleteACFees();
        break;
    }
  };

  const onChangeOutOfHoursAcPayble = (key: string, value: any) => {
    switch (value) {
      case '':
        onChange(key, value);

        if (
          activeContract?.monthlyAcFeesPayable !==
          ManagementChargeMonthlyAcFeesPayable.NotIncludedAdditionalAcFeesPaidByTenant
        )
          deleteACFees();
        break;

      case OutOfHoursAcChargesPayable.Yes:
        updateAndCreateACFeesIfNoACFee(key, value);
        break;

      case OutOfHoursAcChargesPayable.NoAcIs247:
        updateAndCreateACFeesIfNoACFee(key, value);

        acFees.forEach((acFee, index) => {
          dispatch(
            updateActiveContractInput({
              key: [acFeesRootKey, `${index}`],
              value: {
                ...acFee,
                outOfHoursFee: null,
                standardHours: new Decimal('24'),
              },
            }),
          );
        });
        break;

      case OutOfHoursAcChargesPayable.NoAcNotProvidedOutsideStandardHours:
        updateAndCreateACFeesIfNoACFee(key, value);

        acFees.forEach((acFee, index) => {
          dispatch(
            updateActiveContractInput({
              key: [acFeesRootKey, `${index}`],
              value: {
                ...acFee,
                outOfHoursFee: null,
                standardHours: null,
              },
            }),
          );
        });
        break;
    }
  };

  return (
    <div className="grid pm-none">
      <div className="col-2 p-l-none">
        <SelectField
          label="Monthly AC fees payable?"
          fieldKey="monthlyAcFeesPayable"
          dataTest=""
          value={activeContract?.monthlyAcFeesPayable}
          disabled={disabled}
          options={MANAGEMENT_CHARGE_MONTHLY_AC_FEES_PAYABLE_OPTIONS}
          onChange={onChangeMonthlyAcFeesPayable}
          clearable={true}
        />
      </div>

      {outOfHoursAcPaybleApplicable ? (
        <div className="col-2">
          <SelectField
            label="Out-of-hours AC fees payable?"
            fieldKey="outOfHoursAcPayble"
            dataTest=""
            value={activeContract?.outOfHoursAcPayble}
            disabled={disabled}
            options={OUT_OF_HOURS_AC_CHARGES_PAYABLE_OPTIONS}
            onChange={onChangeOutOfHoursAcPayble}
            clearable={true}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AcFeesPayableForm;
