import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Badge, { BadgeColor } from 'atoms/Badge';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import TabContainer from 'components/TabContainer';
import Contract from 'views/contracts-v2/Viewer/Contract';
import AlertsTab from 'views/transactions/CreatorViewer/Tabs/ContractView/AlertsTab';
import { updateTransactionContractViewsTab } from 'store/transactions/transactionDetailSlice';
import OverviewTabToolbar from './OverviewTabToolbar';
import ValuationTab from './ValuationTab';
import './ContractView.scss';

export enum TRANSACTION_CONTRACT_VIEW_TABS_OFFSET {
  ALERTS = 0,
  DETAILS = 1,
  OVERVIEW = 2,
  VALUATION = 3,
}

const ContractView = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const {
    activeTransaction: { translationInfo },
    activeContractViewTabOffset,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  let contractId: string = translationInfo?.contract?.id || '';

  const noDataBadge: JSX.Element = <Badge badgeColor={BadgeColor.DARK_ORANGE}>Contract data not available</Badge>;

  const getSubTabMenuConfig = (): TabMenuProps[] => {
    return [
      {
        key: 'contract-tab-alerts',
        href: '?tab=alerts',
        label: 'Alerts',
        dataTest: 'nav-contract-alerts',
        customTab: <AlertsTab />,
      },
      {
        key: 'contract-tab-details',
        href: '?tab=details',
        label: 'Details',
        dataTest: 'nav-details',
        customTab: contractId ? (
          <Contract
            contractId={contractId}
            isViewPage={true}
          />
        ) : (
          <TabContainer
            tabTitle="Details"
            nested={true}
          >
            {noDataBadge}
          </TabContainer>
        ),
      },
      {
        key: 'contract-tab-valuation',
        href: '?tab=valuation',
        label: 'Comparables',
        dataTest: 'nav-contract-valuation',
        customTab: contractId ? (
          <ValuationTab contractId={contractId} />
        ) : (
          <TabContainer
            tabTitle="Comparable"
            nested={true}
          >
            {noDataBadge}
          </TabContainer>
        ),
      },
    ];
  };

  useEffect(() => {
    return () => {
      // RESET TO ALERTS TAB
      dispatch(updateTransactionContractViewsTab({ tab: TRANSACTION_CONTRACT_VIEW_TABS_OFFSET.ALERTS }));
    };
  }, []);

  return (
    <TabContainer
      tabTitle="Overview"
      tabToolbar={<OverviewTabToolbar activeTabIndex={activeContractViewTabOffset} />}
    >
      <TabReact
        className="contract-view-tab"
        activeTabIndex={activeContractViewTabOffset}
        onTabChange={(activeIndex: number) => dispatch(updateTransactionContractViewsTab({ tab: activeIndex }))}
        tabMenu={getSubTabMenuConfig()}
        nested={true}
      />
    </TabContainer>
  );
};

export default ContractView;
