import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface GetParameterGroupHTMLQueryVariables {
  groupId: string;
}

const GET_PARAMETER_GROUP_HTML = (id: string) => {
  if (!id) throw new Error('GET_PARAMETER_GROUP_HTML: id is required');

  const variables: GetParameterGroupHTMLQueryVariables = { groupId: id };

  return {
    query: gql`
      query ($groupId: ID!) {
        getParameterGroupPdfHtml(groupId: $groupId)
      }
    `,
    variables,
  };
};

export const getParameterGroupHtml = createAsyncThunk(
  'parameters/getParameterGroupPdfHtml',
  async ({ id }: { id: string }) => {
    const response = await adminClient.query(GET_PARAMETER_GROUP_HTML(id));
    return response;
  },
);
