import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterGroupDetailClone, extractGuidelineUpdateParams } from 'common/_classes';
import { RootState } from 'store';

interface UpdateParameterGroupParams {
  name: string;
  index: number;
  categoryId: any;
  guideline: any;
}

interface UpdateParameterGroupQueryVariables {
  id: string;
  params: UpdateParameterGroupParams;
}

const UPDATE_PARAMETER_GROUP = (activeGroup: ParameterGroupDetailClone) => {
  const { id, name, index, category, guideline } = activeGroup;

  if (!id) throw new Error('UPDATE_PARAMETER_GROUP: id is required');
  if (!name) throw new Error('UPDATE_PARAMETER_GROUP: name is required');
  if (!index) throw new Error('UPDATE_PARAMETER_GROUP: index is required');
  if (!category || !category.id) throw new Error('UPDATE_PARAMETER_GROUP: category id is requiresd');

  const params: UpdateParameterGroupParams = {
    name,
    index,
    categoryId: category.id,
    guideline: extractGuidelineUpdateParams(guideline),
  };

  const variables: UpdateParameterGroupQueryVariables = {
    id,
    params,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: ParameterGroupInput!) {
        updateParameterGroup(id: $id, params: $params) {
          ${ParameterGroupDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateParameterGroup = createAsyncThunk('parameters/updateParameterGroup', async (_args, { getState }) => {
  const {
    parametersListing: { activeGroup },
  }: any = getState() as RootState;
  const response = await adminClient.mutate(UPDATE_PARAMETER_GROUP(activeGroup));
  return response;
});
