import { ReactNode } from 'react';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './DropdownCardMenu.scss';

export interface DropdownMenuItem {
  onClick?: () => void;
  disabled?: boolean;
  label: string | ReactNode;
  key: string;
  href?: string;
  icon?: Icons;
  // if we have any specific template available for item like modal
  template?: ReactNode;
}

interface DropdownCardMenuProps {
  className?: string;
  dropdownItems: DropdownMenuItem[];
  dataTest?: string;
}

const DropdownCardMenu = ({ className, dropdownItems, dataTest }: DropdownCardMenuProps): JSX.Element => {
  const onClick = (e: React.MouseEvent<HTMLDivElement>, triggerAction?: () => void): void => {
    e.preventDefault();
    if (triggerAction) {
      triggerAction();
    }
  };

  return (
    <Dropdown
      className={`dropdown-card-menu-atom ${className}`}
      icon={false}
      trigger={
        <span
          className={`dropdown-card-menu-icon `}
          data-test="menu-button"
        >
          <Icon icon={Icons.VerticalDots} />
        </span>
      }
      direction="left"
      data-test={dataTest}
    >
      <Dropdown.Menu
        className="dropdown-card-menu-options"
        data-test="card-options"
      >
        {dropdownItems.map((item: DropdownMenuItem) => {
          return item.template ? (
            <React.Fragment key={item.key}>{item.template}</React.Fragment>
          ) : (
            <Dropdown.Item
              key={item.key}
              onClick={e => onClick(e, item.onClick)}
              disabled={item.disabled}
              href={item.href}
            >
              {item.icon && <Icon icon={item.icon} />}
              <span> {item.label}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownCardMenu;
