import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListPropertiesQueryVariables {
  currentLandlordEntityId: string;
  sortOrder: SortOrder;
  first: number;
  last: number;
  after: string;
  before: string;
  ilike: string | null;
}

const LIST_PROPERTIES = (searchValue: string | null, first: number, last: number, after: string, before: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List Properties: currentLandlordEntityId is required');

  const variables: ListPropertiesQueryVariables = {
    currentLandlordEntityId,
    sortOrder: SortOrder.Asc,
    first: first,
    last: last,
    after: after,
    before: before,
    ilike: searchValue,
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $sortOrder: SortOrder!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $ilike: String
      ) {
        listProperties(
          currentLandlordEntityId: $currentLandlordEntityId
          sortOrder: $sortOrder
          first: $first
          last: $last
          after: $after
          before: $before
          filters: { Ilike: $ilike }
        ) {
          edges {
            cursor
            node {
              id
              entity {
                id
                name
              }
              photo
              buildingName
              type
              address
              identification
              spaceCount
              floorCount
              reviewFlag
              floors {
                id
                name
                spaces {
                  id
                  grossArea
                  netArea
                  lettableArea
                  saleableArea
                  activationDate
                  deactivationDate
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

// interface ListPropertiesArgs {
//   first: number;
//   last?: number;
//   after?: string;
//   before?: string;
//   searchValue?: string;
//   isLandlordEntityProperties: boolean;
// }

export const listProperties = createAsyncThunk(
  'properties/listProperties',
  async (args: { first: number; last: number; after: string; before: string }, { getState }) => {
    const { first, last, after, before } = args;
    const {
      propertiesListing: { search },
    } = getState() as RootState;
    const response = await client.query(LIST_PROPERTIES(search, first, last, after, before));
    return response;
  },
);
