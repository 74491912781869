import { Button } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './ShowMoreButton.scss';

interface ShowMoreButton {
  isShow: boolean;
  setShow: (val: boolean) => void;
}

const ShowMoreButton = ({ isShow, setShow }: ShowMoreButton) => {
  return (
    <div className="more-reports-btn d-flex m-t-xl w-100">
      <Button
        onClick={() => setShow(!isShow)}
        className="more-reports border-ml-blue-very-dark bg-white color-blue-very-dark-grayish d-flex font-600"
      >
        {isShow ? 'Hide Reports' : 'see more reports'}
        <Icon
          className="m-l-sm"
          icon={`${isShow ? Icons.FullArrowUp : Icons.FullArrowDown}`}
        />
      </Button>
    </div>
  );
};

export default ShowMoreButton;
