import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { DocumentsType } from '../contracts';

interface UploadTenantQueryVariables {
  currentLandlordEntityId: string;
  simulation: boolean;
  uploadFile: DocumentsType;
}

export const UPLOAD_TENANT = (simulation: boolean, uploadFile: DocumentsType) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Upload Tenant: currentLandlordEntityId is required');

  const variables: UploadTenantQueryVariables = {
    currentLandlordEntityId,
    simulation,
    uploadFile,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $simulation: Boolean!, $uploadFile: Upload!) {
        uploadTenant(
          currentLandlordEntityId: $currentLandlordEntityId
          simulation: $simulation
          uploadFile: $uploadFile
        ) {
          dataType
          items {
            name
            identifier
            address
          }
          itemsDuplicateBase {
            log
            type
          }
          itemsDuplicateExcel {
            log
            type
          }
          itemsModified {
            name
            identifier
            address
          }
          logs {
            log
            type
          }
          simulation
        }
      }
    `,
    variables,
  };
};

export const uploadTenant = createAsyncThunk(
  'entitesListing/uploadTenant',
  async ({ simulation, file }: { simulation: boolean; file: DocumentsType }) => {
    const response = await client.mutate(UPLOAD_TENANT(simulation, file));
    return response;
  },
);
