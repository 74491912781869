import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';

export const LIST_LANGUAGES = () => {
  return {
    query: gql`
      query {
        listLanguages {
          list
        }
      }
    `,
  };
};

export const listLanguages = createAsyncThunk('miscellaneous/listLanguages', async () => {
  const response = await getClientType().query(LIST_LANGUAGES());
  return response;
});
