import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ORGANISATION_TABS_OFFSET } from 'views/userAccountManagement/organisation/Tabs/OrganisationTabs';
import MemberConnection from 'common/model/MemberConnection';
import PageInfo from 'common/model/PageInfo';
import {
  CreateMemberInput,
  CreatedMemberInput,
  MemberClone,
  TeamMember,
  createMember,
  listMembers,
  updateMember,
  updateMemberResendInvite,
  updateMemberStatusAndRole,
  updateMemberUser,
} from 'common/api/members';

const initNewInvitedMember = (): CreateMemberInput => ({
  email: '',
  roleId: '',
});

const extractTeamMember = (member: MemberClone): TeamMember => ({
  memberId: member.id,
  firstName: member.user.firstName,
  lastName: member.user.lastName,
  email: member.user.email,
  roleId: member.role.id,
  phoneNumber: member.user.phoneNumber,
  countryCode: member.user.countryCode,
});

export interface MemberSliceState {
  activeInvitedUser: CreateMemberInput | CreatedMemberInput;
  activeTeamMember: TeamMember | null;
  membersList: MemberClone[];
  pageInfo: PageInfo | null;
  search: string | null;
  isSearching: boolean;
  memberLoading: boolean;
  activeTabIndex: ORGANISATION_TABS_OFFSET;
  selectedSubsidiary: string | null;
}

const initialState: MemberSliceState = {
  membersList: [],
  activeInvitedUser: initNewInvitedMember(),
  activeTeamMember: null,
  pageInfo: null,
  search: null,
  isSearching: false,
  memberLoading: false,
  activeTabIndex: ORGANISATION_TABS_OFFSET.PROFILE,
  selectedSubsidiary: null,
};

const members = createSlice({
  name: 'members',
  initialState,
  reducers: {
    updateOrganisationViewDetailsTab: (state, action) => {
      if (action.payload.tab !== ORGANISATION_TABS_OFFSET.SUBSIDIARIES) {
        state.selectedSubsidiary = null;
      }
      state.activeTabIndex = action.payload.tab;
    },
    updatedInvitedUser: (state, action) => {
      const { key, value } = action.payload;
      state.activeInvitedUser[key as keyof CreateMemberInput] = value;
    },
    updateTeamMember: (state, action) => {
      const { key, value } = action.payload;

      if (state.activeTeamMember) {
        state.activeTeamMember[key as keyof TeamMember] = value;
      }
    },
    discardMemberCreation: state => {
      state.activeInvitedUser = initNewInvitedMember();
    },
    onEditNewMemberDetails: (state, action) => {
      const id = action.payload;
      const member = state.membersList.find(member => member.id === id);

      if (member) {
        const { user, role, id } = member;

        state.activeInvitedUser = {
          memberId: id,
          email: user.email,
          roleId: role.id,
        };
      }
    },
    onEditTeamMemberDetails: (state, action) => {
      const memberId = action.payload;
      const member = state.membersList.find(member => member.id === memberId);
      if (member) state.activeTeamMember = extractTeamMember(member);
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    //createMember
    builder.addCase(createMember.pending, state => {
      state.memberLoading = true;
    });
    builder.addCase(createMember.fulfilled, (state, action) => {
      const { createMember } = action.payload.data;
      state.activeTeamMember = createMember;
      state.membersList = [...state.membersList, createMember];
      state.memberLoading = false;
      toast.success('New member has been successfully added.');
    });
    builder.addCase(createMember.rejected, (state, action) => {
      state.memberLoading = false;
      console.error(action.error);
      toast.error('Invalid details passed role for two members cannot be same');
    });

    //updateMemberUser
    builder.addCase(updateMemberUser.pending, state => {
      state.memberLoading = true;
    });
    builder.addCase(updateMemberUser.fulfilled, state => {
      state.memberLoading = false;
    });
    builder.addCase(updateMemberUser.rejected, (state, action) => {
      state.memberLoading = false;
      console.warn(action.error);
      toast.error('updateMemberUser API request failed');
    });

    //updateMemberResendInvite
    builder.addCase(updateMemberResendInvite.pending, state => {
      state.memberLoading = true;
    });
    builder.addCase(updateMemberResendInvite.fulfilled, (state, action) => {
      const updatedMember = action.payload.data.updateMemberResendInvite;
      const index = state.membersList.findIndex(obj => obj.id === updatedMember.id);

      if (index !== -1) {
        state.membersList[index] = updatedMember;
        toast.success('Member has been re-invited successfully.');
      } else {
        toast.error('Could not find the member in question');
      }

      state.memberLoading = false;
    });
    builder.addCase(updateMemberResendInvite.rejected, (state, action) => {
      state.memberLoading = false;
      console.error(action.error);
      toast.error('Invalid details passed role for two members cannot be same');
    });

    //listMembers
    builder.addCase(listMembers.pending, (state, action) => {
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.memberLoading = true;
      }
    });
    builder.addCase(listMembers.fulfilled, (state, action) => {
      const { edges = [], pageInfo }: MemberConnection = action.payload?.data?.listMembers ?? {};
      state.pageInfo = pageInfo;
      state.membersList = edges.map(element => element.node);
      state.search = null;
      state.memberLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listMembers.rejected, (state, action) => {
      state.memberLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listMembers API request failed');
    });

    //updateMemberStatusAndRole
    builder.addCase(updateMemberStatusAndRole.pending, state => {
      state.memberLoading = true;
    });
    builder.addCase(updateMemberStatusAndRole.fulfilled, (state, action) => {
      const updatedMember = action.payload.updateMemberStatusAndRole;
      const index = state.membersList.findIndex(obj => obj.id === updatedMember.id);

      if (index !== -1) {
        state.membersList[index].status = updatedMember.status;
        state.membersList[index].role = updatedMember.role;
        toast.success('Member updated successfully.');
      } else {
        toast.error('Could not find the member');
      }

      state.memberLoading = false;
    });
    builder.addCase(updateMemberStatusAndRole.rejected, (state, action) => {
      state.memberLoading = false;
      console.warn(action.error);
      toast.error('updateMemberStatusAndRole API request failed');
    });

    //updateMember
    builder.addCase(updateMember.pending, state => {
      state.memberLoading = true;
    });
    builder.addCase(updateMember.fulfilled, (state, action) => {
      const { updateMember } = action.payload;
      const { user, role } = updateMember;

      const index = state.membersList.findIndex(obj => obj.id === state.activeTeamMember?.memberId);

      if (index !== -1) {
        state.membersList[index].user = user;
        state.membersList[index].role = role;
        toast.success('Member saved successfully.');
      } else {
        toast.error('Some error occurred');
      }

      state.memberLoading = false;
    });
    builder.addCase(updateMember.rejected, (state, action) => {
      state.memberLoading = false;
      console.error(action.error);
      toast.error('updateMember API request failed');
    });
  },
});

export const {
  discardMemberCreation,
  updatedInvitedUser,
  updateTeamMember,
  onEditNewMemberDetails,
  onEditTeamMemberDetails,
  updateOrganisationViewDetailsTab,
  updateSearch,
} = members.actions;

export default members.reducer;
