import { FloorPickMainData, TransactionListingPremise } from 'common/_classes';
import { orderBy } from 'lodash';
import FloorStatus from 'common/model/FloorStatus';
import { Premises } from 'utils/types/stackingPlan';

export const getFormattedFloorsList = (premise: TransactionListingPremise): string => {
  if (!premise || !premise.property || !premise.property.floors) {
    return '';
  }
  const propertyFloors: FloorPickMainData[] = premise.property.floors;
  const floorIds: string[] = premise.floorsStatus
    .filter((floor: FloorStatus) => floor.floorId !== null)
    .map((floor: FloorStatus) => floor.floorId);

  // Transform propertyFloors into a Map
  const propertyFloorsMap: Map<string, FloorPickMainData> = new Map(
    propertyFloors.map((floor: FloorPickMainData) => [floor.id, floor]),
  );

  // Triage the floor names by types:
  // - number
  // - one alphabet character
  // - string (several alphabet characters)
  let nums: number[] = [];
  let alphabets: string[] = [];
  let others: string[] = [];

  for (let i = 0; i < floorIds.length; i++) {
    const floor: FloorPickMainData | undefined = propertyFloorsMap.get(floorIds[i]);
    if (!floor) {
      continue;
    }
    const floorName: string = floor.name;

    if (Number.isInteger(Number(floorName))) {
      nums.push(parseInt(floorName));
    } else if (floorName.length === 1) {
      alphabets.push(floorName);
    } else {
      others.push(floorName);
    }
  }

  // Sort the numeric and alphabets floors
  nums.sort();
  alphabets.sort();

  // Concatenate the floors (number or characters) that are followwing each others
  // For example: [1,2,4,5,6,9] will become: "1-2, 4-6, 9"
  //              [A,B,D,E,F,W] will become: "A-B, D-F, W"
  const formatFloors = (arr: any): string => {
    let result: string = arr[0] ? `${arr[0]} ` : '';
    let rangeMax: any;
    for (let i = 1; i < arr.length; i++) {
      if (rangeMax) {
        if (rangeMax + 1 === arr[i]) {
          rangeMax = arr[i];
        } else {
          result += ` - ${rangeMax}, ${arr[i]}`;
          rangeMax = null;
        }
      } else {
        if (arr[i - 1] + 1 === arr[i]) {
          rangeMax = arr[i];
        } else {
          result += `, ${arr[i]}`;
        }
      }

      if (rangeMax === arr[arr.length - 1]) {
        result += ` - ${rangeMax}`;
      }
    }

    return result;
  };

  const alpha: string = formatFloors(alphabets);
  const num: string = formatFloors(nums);

  let result = '';
  result += num;
  result += result && alpha ? `, ${alpha}` : `${alpha}`;
  result += result && others.length > 0 ? `, ${others.join(', ')}` : `${others.join(', ')}`;

  return result;
};

export interface FloorsListingData {
  name: string;
  isWholeFloor: boolean;
  spaceNames: string[];
  totalArea: number;
}

interface SpacesMapValue {
  name: string;
  grossArea: string | number | null;
}

interface PremisesFloor {
  name: string;
  spaces: Map<string, SpacesMapValue>;
}

export const getFloorListingFromPremises = ({
  property,
  floorsStatus,
  spaceIds,
}: TransactionListingPremise | Premises): FloorsListingData[] => {
  // Step 1: Create a Map of all the floors of a property with all their spaces
  const floorsMap: Map<string, PremisesFloor> = new Map();

  for (let i = 0; i < property.floors.length; i++) {
    const { id: foorId, spaces, name: floorName } = property.floors[i];

    let spacesMap: Map<string, SpacesMapValue> = new Map();

    for (let j = 0; j < spaces.length; j++) {
      const { id, name: spaceName, grossArea } = spaces[j];

      if (id && spaceName) {
        const value: SpacesMapValue = { name: spaceName, grossArea };
        spacesMap.set(id, value);
      }
    }

    if (foorId) {
      const spaces: PremisesFloor = {
        name: floorName,
        spaces: spacesMap,
      };
      floorsMap.set(foorId, spaces);
    }
  }

  // Step 2: for all the floors listed in the premises:
  //  -> list the used space/units of the floor
  //  -> calculate the associated totalArea of the floor
  const results: FloorsListingData[] = floorsStatus.map((floorStatus: FloorStatus) => {
    // Get information of the floor
    const floor: PremisesFloor = floorsMap.get(floorStatus.floorId || '')!;

    let spaceNames: string[] = [];
    let totalArea: number = 0;

    // Accumulate the spaces names and calculate the totalArea
    for (let j = 0; j < spaceIds.length; j++) {
      if (floor.spaces.get(spaceIds[j])) {
        const space: SpacesMapValue = floor.spaces.get(spaceIds[j])!;
        spaceNames.push(space.name);

        if (space.grossArea) {
          totalArea += parseFloat(space.grossArea.toString());
        }
      }
    }

    const result: FloorsListingData = {
      name: floor.name,
      isWholeFloor: floorStatus.whole,
      spaceNames: orderBy(spaceNames),
      totalArea,
    };
    return result;
  });

  return orderBy(results, 'name', 'asc');
};
