import { Grid, List } from 'semantic-ui-react';

interface ListItemProps {
  name: string;
  value?: string;
  secondRowValue?: string;
  list?: string[];
}
export const SectionRow = ({ name, value, list, secondRowValue }: ListItemProps) => {
  return (
    <>
      <Grid.Column
        className="side-section-name-column p-l-none"
        width={6}
      >
        {name}
      </Grid.Column>

      {value && (
        <Grid.Column
          width={10}
          className="side-section-value-column"
        >
          {value}
        </Grid.Column>
      )}
      {secondRowValue ? (
        <>
          <Grid.Column
            className="side-section-name-column p-l-none"
            width={6}
          ></Grid.Column>
          <Grid.Column
            width={10}
            className="side-section-value-column"
          >
            {secondRowValue}
          </Grid.Column>
        </>
      ) : (
        ''
      )}
      {list && list.length ? (
        <Grid.Column
          width={10}
          className="side-section-value-column"
        >
          <List bulleted>
            {list?.map((listItem: string, index: number) => <List.Item key={index}>{listItem}</List.Item>)}
          </List>
        </Grid.Column>
      ) : (
        ''
      )}
    </>
  );
};
