import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { deletePortfolio, listPortfolios, listProperties } from 'common/api/portfolio';
import { getDetailsFromEdges } from 'utils/tsHelper';
import { PortfolioClone, PropertyProps } from 'utils/types/portfolio';

interface PortfoliosListingState {
  isLoading: boolean;
  portfolios: PortfolioClone[];
  propertiesList: PropertyProps[];
}

const initialState: PortfoliosListingState = {
  isLoading: false,
  portfolios: [],
  propertiesList: [],
};

const portfoliosListingSlice = createSlice({
  name: 'portfoliosListing',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // listPortfolios
    builder.addCase(listPortfolios.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listPortfolios.fulfilled, (state, action) => {
      state.portfolios = getDetailsFromEdges(action.payload.listPortfolios.edges);
      state.isLoading = false;
    });
    builder.addCase(listPortfolios.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('Some Error Occurred');
    });

    // deletePortfolio
    builder.addCase(deletePortfolio.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deletePortfolio.fulfilled, (state, action) => {
      state.portfolios = state.portfolios.filter(portfolio => portfolio.id !== action.meta.arg.id);
      state.isLoading = false;
    });
    builder.addCase(deletePortfolio.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('Some Error Occurred');
    });

    // listProperties
    builder.addCase(listProperties.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listProperties.fulfilled, (state, action) => {
      if (action.payload.data.listProperties) {
        state.propertiesList = getDetailsFromEdges(action.payload.data.listProperties.edges);
      }

      state.isLoading = false;
    });
    builder.addCase(listProperties.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('Some Error Occurred');
    });
  },
});

export default portfoliosListingSlice.reducer;
