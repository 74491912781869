import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import HandoverCondition from 'common/model/HandoverCondition';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { EXISTING_FITOUT_TREATMENT_OPTIONS, HANDOVER_CONDITION_OPTIONS } from 'utils/UI';

const HandOverConditionForm = ({ mode }: ModeProps) => {
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: string) => {
    if (key === 'handoverCondition' && value === HandoverCondition.Standard) {
      dispatch(updateActiveContractEventInput({ key: 'existingFitOutTreatment', value: null }));
    }

    dispatch(updateActiveContractEventInput({ key, value: value || null }));
  };

  const disabled = mode === MODE_OPTIONS.READ;

  return (
    <div className="grid">
      <div className="col-2">
        <SelectField
          label="Handover condition"
          fieldKey="handoverCondition"
          dataTest=""
          value={activeContractEvent?.handoverCondition}
          disabled={disabled}
          options={HANDOVER_CONDITION_OPTIONS}
          onChange={onChange}
          clearable={true}
        />
      </div>

      {activeContractEvent?.handoverCondition === HandoverCondition.FullyOrPartiallyFitted && (
        <div className="col-2">
          <SelectField
            label="Tenant's treatment of existing fit-out"
            fieldKey="existingFitOutTreatment"
            dataTest=""
            value={activeContractEvent?.existingFitOutTreatment}
            disabled={disabled}
            options={EXISTING_FITOUT_TREATMENT_OPTIONS}
            onChange={onChange}
            clearable={true}
          />
        </div>
      )}
    </div>
  );
};

export default HandOverConditionForm;
