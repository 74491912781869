import { useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import PopUpInfo from 'atoms/PopUpInfo';
import ModalTemplate from 'components/ModalTemplate';
import { createNewUser, discardUserCreation, editUser } from 'store/adminUsers/adminUsersListingSlice';
import { ContentProps } from 'utils/types/userManagement/users';
import { createNewUserValidation } from 'utils/utils-credential';
import UserModalForm from './UserModalForm';

const Content = ({ setOpen, createMode }: ContentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeUser } = useAppSelector((state: RootState) => state.adminUsers);

  const onSave = (): void => {
    if (createMode) {
      dispatch(createNewUser());
    } else {
      dispatch(editUser());
    }
    if (setOpen) setOpen(false);
  };

  return (
    <Form>
      <Grid className="pm-none">
        <Grid.Row className="p-none">
          <Grid.Column
            width={3}
            className="pm-none"
          >
            <h4 className="section-title">
              Profile Image
              <PopUpInfo
                popUp={false}
                info={true}
              />
            </h4>
            <Avatar
              src={null}
              size={AvatarSize.Medium}
            />
          </Grid.Column>

          <Grid.Column width={2}></Grid.Column>

          <Grid.Column
            width={11}
            className="pm-none"
          >
            <h4 className="section-title">Details</h4>
            <UserModalForm />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="p-none">
          <div className="height-0_0714 w-100 m-b-xl m-t-xl border-bottom-sm-grayish-magenta-light"></div>
        </Grid.Row>

        <Grid.Row className="p-none">
          <Button
            className="btn grey-bg"
            disabled={createNewUserValidation(
              activeUser.firstName,
              activeUser.lastName,
              activeUser.email,
              activeUser.userType,
            )}
            onClick={() => onSave()}
          >
            {createMode ? 'CREATE' : 'SAVE'}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const UserModal = ({ createMode }: { createMode: boolean }): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = (value: boolean): void => {
    setIsOpen(value);

    if (!value) {
      dispatch(discardUserCreation());
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onToggleModalStatus={toggleModal}
      trigger={<Button className="btn grey-bg">ADD NEW USER</Button>}
      title={`${createMode ? 'Add New User' : 'Edit User Details'}`}
    >
      <Content
        createMode={createMode}
        setOpen={toggleModal}
      />
    </ModalTemplate>
  );
};

export default UserModal;
