import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { PolicyProvisionInput } from 'common/api/policies';
import { PolicyProvisionDetailClone } from '../../_classes/PolicyProvisionDetailClone';

interface CreatePolicyProvisionQueryVariables {
  params: PolicyProvisionInput;
}

export const CREATE_POLICY_PROVISION = (policyProvision: PolicyProvisionInput) => {
  const variables: CreatePolicyProvisionQueryVariables = {
    params: policyProvision,
  };

  return {
    mutation: gql`
      mutation ($params: PolicyProvisionInput!) {
        createPolicyProvision(params: $params) {
          ${PolicyProvisionDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createPolicyProvision = createAsyncThunk(
  'policies/createPolicyProvision',
  async (policyProvision: PolicyProvisionInput) => {
    const response = await client.mutate(CREATE_POLICY_PROVISION(policyProvision));
    return response;
  },
);
