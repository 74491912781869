import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetFloorlist, updatePropertyFloorsTabView } from 'store/properties/propertyDetailSlice';
import { listPropertyFloors } from 'common/api/floor';
import { ViewProps } from 'common/api/miscellaneous';
import { PROPERTY_TABS_OFFSET } from '../PropertyTabs';
import FloorCreator from './FloorCreator';
import FloorListing from './FloorListing';

export enum PROPERTY_FLOORS_TAB_VIEW {
  FLOORS_LISTING = 'table',
  FLOOR_DETAIL = 'floor_detail',
  FLOOR_CREATE = 'create_floor',
}

export interface FloorTabViewProps {
  switchView: (view: PROPERTY_FLOORS_TAB_VIEW) => void;
}

const PropertyFloorsTab = ({ isViewPage }: ViewProps) => {
  const { id: propertyId } = useParams();
  const dispatch = useAppDispatch();

  const { floorsTabView, activeTabIndex } = useAppSelector((state: RootState) => state.propertyDetail);

  const switchPropertyFloorView: (view: PROPERTY_FLOORS_TAB_VIEW) => void = (view: PROPERTY_FLOORS_TAB_VIEW) =>
    dispatch(updatePropertyFloorsTabView({ view }));

  useEffect(() => {
    if (activeTabIndex === PROPERTY_TABS_OFFSET.FLOORS_AND_UNITS && isViewPage) {
      // @ts-ignore
      dispatch(listPropertyFloors({ first: 100000, propertyId }));
    }

    return () => {
      dispatch(resetFloorlist());
    };
  }, [activeTabIndex]);

  return floorsTabView === PROPERTY_FLOORS_TAB_VIEW.FLOORS_LISTING ? (
    <FloorListing switchView={switchPropertyFloorView} />
  ) : (
    <FloorCreator switchView={switchPropertyFloorView} />
  );
};

export default PropertyFloorsTab;
