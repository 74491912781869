import CreateViewerPage from 'views/properties-v2/CreatorViewer';
import DashboardPage from 'views/properties-v2/Dashboard';
import PropertyCreatorViewer from 'views/properties/CreatorViewer';
import { checkIfAdminURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const PropertiesRoutes = () => {
  let children = [
    {
      path: '/properties-new',
      element: isAuthenticated(<DashboardPage />),
    },
  ];

  if (!checkIfAdminURL()) {
    children = [
      ...children,
      {
        path: '/properties-new/create',
        element: isAuthenticated(<CreateViewerPage />),
      },
      {
        path: '/properties-new/:id/details',
        element: isAuthenticated(<CreateViewerPage />),
      },
    ];
  }

  return [
    {
      path: '/properties-new',
      children,
    },
  ];
};

export default PropertiesRoutes;
