import { IFormatterClone, IFunctionInputClone } from 'common/_classes';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementNumberRange,
  filterElementStatus,
  filterElementStatusTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import Tooltip from 'atoms/Tooltip';
import RawHTML from 'components/RawHTML';
import AnswerTypes from 'common/model/AnswerTypes';
import { ANSWER_TYPE_OPTIONS } from 'utils/utils-answer';
import { limitStringSize } from 'utils/utils-string';
import { FormatterRowActionButtons } from './FormattersRowActionButtons';

export class FormattersRow {
  id: string;
  name: string | null;
  example: string | null;
  description: string | null;
  inputs: IFunctionInputClone[];
  inputsLength: number;
  status: string;

  constructor(formatter: IFormatterClone) {
    this.id = formatter.id;
    this.name = formatter.name;
    this.example = formatter.example;
    this.description = formatter.description;
    this.inputsLength = formatter.inputs.length;
    this.inputs = formatter.inputs;
    this.status = formatter.reviewFlag ? statuses[0].value : statuses[1].value;
  }
}

const statuses: StatusBadgeArgument[] = [
  { value: 'REVIEWED', color: BadgeColor.GREEN },
  { value: 'DRAFTING', color: BadgeColor.ORANGE },
];

const getTypeText = (type: AnswerTypes | null): string => {
  switch (type) {
    case AnswerTypes.SingleChoice:
      return 'MCQ Single';

    case AnswerTypes.MultiChoice:
      return 'MCQ Multi';

    default:
      const foundOption = ANSWER_TYPE_OPTIONS.find(obj => obj.value === type);
      return foundOption ? foundOption.text : '';
  }
};

const getInputText = (inputs: IFunctionInputClone[], type: AnswerTypes | null, index: number): string => {
  return index === inputs.length - 1 ? getTypeText(type) : `${getTypeText(type)}, `;
};

const filterElementTemplate = (inputs: IFunctionInputClone[]): JSX.Element => {
  const result = inputs.map((input: IFunctionInputClone) => getTypeText(input.type)).join(', ');

  return <span key="input-type">{result}</span>;
};

const filterElementDescription = (row: FormattersRow): JSX.Element => {
  let htmlRawString: string = '';
  htmlRawString += row.description ? '<h4>Description</h4>' + row.description : '';
  htmlRawString += row.example ? '<h4>Example</h4>' + row.example : '';

  return (
    <Tooltip
      trigger={
        <div>
          <span
            className="editor-to-html"
            dangerouslySetInnerHTML={{
              __html: limitStringSize(row.example as string, 35),
            }}
          ></span>
        </div>
      }
      HTMLContent={<RawHTML rawHTMLString={htmlRawString} />}
    />
  );
};

/**
 * Custom Filter Function for the Inputs column
 * @param [IFunctionInputClone[]] inputs
 * @param [string] filter eg. Number OR Number, text
 * @returns : boolean
 */
// TODO: check if we can simplify filterElementInputFunction
const filterElementInputFunction = (inputs: IFunctionInputClone[], filter: string): boolean => {
  if (!filter || !filter.trim()) {
    return true;
  }
  filter = filter.trim().toLowerCase();

  // In case of coma separated multiple keywords are present
  if (filter.includes(',')) {
    const output: boolean[] = [];
    const allFilterKeywords: string[] = filter
      .split(',')
      .map(keyword => (keyword ? keyword.trim().toLowerCase() : ''))
      .filter(keyword => keyword);

    allFilterKeywords.forEach((keyword: string) => {
      const out = inputs.find((input, index) => {
        const text: string = getInputText(inputs, input.type, index);
        if (text) {
          return text.toLowerCase().includes(keyword);
        } else {
          return false;
        }
      });
      if (out) {
        output.push(true);
      }
    });
    return output.length === allFilterKeywords.length;
  } else {
    const output = inputs.filter((input, index) => {
      const text: string = getInputText(inputs, input.type, index);
      if (text) {
        return text.toLowerCase().includes(filter);
      } else {
        return false;
      }
    });
    return output.length > 0;
  }
};

export const formatterColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Name',
      filter: true,
      filterPlaceholder: 'Search by Name',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'description',
      header: 'Description',
      filter: true,
      filterPlaceholder: 'Search by Description',
      filterField: 'description',
      sortable: true,
      body: (row: FormattersRow) => filterElementDescription(row),
    },
    {
      field: 'inputsLength',
      header: 'Number of inputs',
      filter: true,
      filterPlaceholder: 'Search by Number of inputs',
      filterField: 'inputsLength',
      sortable: true,
      dataType: 'numeric',
      filterMatchMode: 'in',
      filterElement: filterElementNumberRange,
      showFilterMatchModes: false,
    },
    {
      field: 'inputs',
      header: 'Inputs',
      width: '20rem',
      filterPlaceholder: 'Search by Inputs',
      filter: true,
      filterField: 'inputs',
      sortable: true,
      filterMatchMode: 'custom',
      filterMatchModeOptions: [{ label: 'Contains', value: 'custom' }],
      filterFunction: (inputs: IFunctionInputClone[], filter: string) => filterElementInputFunction(inputs, filter),
      body: (row: FormattersRow) => filterElementTemplate(row.inputs),
    },
    {
      field: 'status',
      header: 'Status',
      filter: true,
      filterPlaceholder: 'Search by status',
      filterField: 'status',
      body: (row: FormattersRow) => filterElementStatusTemplate(row.status, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      className: 'status-column',
      showFilterMatchModes: false,
      sortable: true,
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: FormattersRow) => (
        <FormatterRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
