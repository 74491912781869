import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DoughnutBox, { DoughnutBoxType } from 'components/GraphsInfo/DoughnutBox';
import RentBox, { RentBoxType } from 'components/GraphsInfo/RentBox';
import TotalBox from 'components/GraphsInfo/TotalBox';
import { generateSummaryStatisticsOfTheMonth } from 'common/api/dashboard/dashboardTab';
import './StatisticsInfoBar.scss';

const StatisticsInfoBar = () => {
  const dispatch = useAppDispatch();
  const { date } = useAppSelector((state: RootState) => state.dashboardTab);
  const { activePortfolio } = useAppSelector((state: RootState) => state.dashboard);
  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);

  useEffect(() => {
    dispatch(generateSummaryStatisticsOfTheMonth());
  }, [dispatch, date, activeProperty, activePortfolio]);

  return (
    <>
      <div className="d-flex flex-gap-m dashboard-analytics-row">
        <DoughnutBox
          type={DoughnutBoxType.Occupancy}
          dataTest="occupancy-section"
        />
        <TotalBox dataTest="total-section" />
        <DoughnutBox
          type={DoughnutBoxType.Availability}
          dataTest="occupancy-section"
        />
      </div>

      <div className="d-flex flex-gap-m m-t-m dashboard-analytics-row">
        <RentBox
          type={RentBoxType.Headline}
          dataTest="headline-rent-section"
        />
        <RentBox
          type={RentBoxType.Effective}
          dataTest="effective-rent-section"
        />
        <RentBox
          type={RentBoxType.Contractual}
          dataTest="contractual-rent-section"
        />
      </div>
    </>
  );
};

export default StatisticsInfoBar;
