import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface GetUserByTokenQueryVariables {
  token: string | null;
}

export const GET_USER_BY_TOKEN = (token: string | null) => {
  if (!token) throw new Error('Get User By Token: Token ID is missing');

  const variables: GetUserByTokenQueryVariables = {
    token,
  };

  return {
    query: gql`
      query ($token: String!) {
        getUserByToken(token: $token) {
          email
          firstName
          id
          lastName
          countryCode
          phoneNumber
          photoUrl
          defaultEntity {
            id
          }
        }
      }
    `,
    variables,
  };
};

export const getUserByToken = createAsyncThunk(
  'auth/getUserByToken',
  async ({ token }: GetUserByTokenQueryVariables) => {
    const response = await client.query(GET_USER_BY_TOKEN(token));
    return response;
  },
);
