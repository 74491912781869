import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveTab } from 'store/entities/entityDetailSlice';
import Contact from 'common/model/Contact';
import ContactForm from './ContactForm';
import './Contacts.scss';

const ContactList = (): JSX.Element => {
  const { activeEntity, activeTab } = useAppSelector((state: RootState) => state.entityDetail);
  const { contacts } = activeEntity;

  const getContactTabsMenu = (): TabMenuProps[] => {
    return contacts.map((contact: Contact, index: number) => {
      return {
        key: `contact-form-${index}`,
        class: 'details-form',
        label: `Contact Person ${index + 1}`,
        customTab: (
          <ContactForm
            contact={contact}
            index={index}
            contacts={contacts}
          />
        ),
      };
    });
  };

  return (
    <TabReact
      className="contact-tabs m-t-xs p-b-s"
      dataTest="contact-tabs"
      activeTabIndex={activeTab}
      onTabChangeAction={updateActiveTab}
      tabMenu={getContactTabsMenu()}
    />
  );
};

export default ContactList;
