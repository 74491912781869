import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { DefaultFilterRowProps } from '.';
import { FILTER_OPTIONS, LOGICAL_OPERATORS } from '../RowTemplate/RowFilterPopup/RowFilterContent/NumberFilter';
import { DataType, FilterOnchangeKeys } from '../RowTemplate/RowProps';

const PremiseSize = ({ onChange, values }: DefaultFilterRowProps) => {
  return (
    <div className="grid">
      <div className="col-2 d-flex align-center">
        <label className="font-600">Premise size</label>
      </div>

      <div className="col-10">
        <div className="grid">
          <div className="col-5">
            <div className="grid">
              <div className="col-6">
                <SelectField
                  label="Condition 1"
                  placeholder="Select..."
                  fieldKey={`${values.id},${FilterOnchangeKeys.filterMode}`}
                  value={values.filterMode}
                  options={FILTER_OPTIONS.filter(({ value }) => value !== values.filterMode2)}
                  onChange={onChange}
                  clearable={true}
                />
              </div>
              <div className="col-6">
                <InputField
                  label="Value 1"
                  fieldKey={`${values.id},${FilterOnchangeKeys.filterValue}`}
                  type={InputFieldType.NUMBER}
                  value={values.filterValue}
                  onChange={onChange}
                  isDecimal={values?.dataType === DataType.DECIMAL}
                />
              </div>
            </div>
          </div>

          <div className="col-2">
            <SelectField
              label="Operator"
              placeholder="Select..."
              fieldKey={`${values.id},${FilterOnchangeKeys.filterLogicalOperator}`}
              value={values.filterLogicalOperator}
              options={LOGICAL_OPERATORS}
              onChange={(key, value) => {
                if (value) {
                  onChange(key, value);
                } else {
                  onChange(
                    key,
                    {
                      filterLogicalOperator: value,
                      filterMode2: undefined,
                      filterValue2: undefined,
                    },
                    true,
                  );
                }
              }}
              clearable={true}
            />
          </div>
          {values.filterLogicalOperator && (
            <div className="col-5">
              <div className="grid">
                <div className="col-6">
                  <SelectField
                    label="Condition 2"
                    placeholder="Select..."
                    fieldKey={`${values.id},${FilterOnchangeKeys.filterMode2}`}
                    value={values.filterMode2}
                    options={FILTER_OPTIONS.filter(({ value }) => value !== values.filterMode)}
                    onChange={onChange}
                    clearable={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    label="Value 2"
                    fieldKey={`${values.id},${FilterOnchangeKeys.filterValue2}`}
                    type={InputFieldType.NUMBER}
                    value={values.filterValue2}
                    onChange={onChange}
                    isDecimal={values?.dataType === DataType.DECIMAL}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PremiseSize;
