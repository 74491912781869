import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { MultiLevelParamPaginationProps } from 'common/api/multiLevelMenu';
import { LIST_PARAMETER_TABLES_PAGE } from 'common/api/parameters';

export const listParameterTablesPage = createAsyncThunk(
  'multiLevelMenu/listParameterTablesPage',
  async ({ first, last, before, after, parameterFilter }: MultiLevelParamPaginationProps) => {
    // TODO: Implement paramViewSelectedGroup as parameter in the future
    const paramViewSelectedGroup = {};
    const response = await adminClient.query(
      LIST_PARAMETER_TABLES_PAGE(parameterFilter?.search, paramViewSelectedGroup, first, last, after, before),
    );
    return response.data;
  },
);
