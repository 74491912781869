import { ChartType, ScriptableTooltipContext, TooltipModel } from 'chart.js';

export type GraphEvent = (
  e: MouseEvent,
) => (tooltipElement: HTMLElement | null, opacity: '0' | '1', display: 'none' | 'inline') => void;

export interface GraphIDs {
  graphID: string;
  tooltipContainerID: string;
  tooltipID: string;
}

const externalTooltip = (
  context: ScriptableTooltipContext<ChartType>,
  { graphID, tooltipContainerID, tooltipID }: GraphIDs,
  tooltipHTML: (tooltipModel: TooltipModel<ChartType>) => string,
  handleToolTipOpacity: GraphEvent,
  eventsAdded: boolean,
  setEventsAdded: (status: boolean) => void,
  tooltipXAxisTolerance: number,
) => {
  // Tooltip Element
  let tooltipEl = document.getElementById(tooltipContainerID);
  if (!tooltipEl) {
    let parentElement = document.getElementById('root');
    tooltipEl = document.createElement('div');
    tooltipEl.id = tooltipContainerID;
    tooltipEl.innerHTML = `<div id=${tooltipID}></div>`;

    if (parentElement) {
      parentElement.appendChild(tooltipEl);
    }
  }

  // Add class
  tooltipEl?.classList.add('graph-tooltip-container');

  if (!eventsAdded) {
    // Add event listeners to handle tooltip showing when mouse cursor is on it
    tooltipEl.addEventListener('mouseleave', (e: MouseEvent) => handleToolTipOpacity(e)(tooltipEl, '0', 'none'));
    tooltipEl.addEventListener('mouseenter', (e: MouseEvent) => handleToolTipOpacity(e)(tooltipEl, '1', 'inline'));

    let graphDiv = document.getElementById(graphID);
    graphDiv?.addEventListener('mouseleave', (e: MouseEvent) => handleToolTipOpacity(e)(tooltipEl, '0', 'none'));

    setEventsAdded(true);
  }

  const tooltipModel = context.tooltip;

  // Hide if no tooltip
  if (tooltipModel.opacity === 0) {
    return;
  }
  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  let innerHtml = '';

  // Set Text
  if (tooltipModel.body) {
    const html = tooltipHTML(tooltipModel);

    innerHtml += html ? html : '<div class="no-data">No data </div>';
  }

  let tooltipWrapper = document.getElementById(tooltipID);

  if (tooltipWrapper) {
    tooltipWrapper.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.display = 'inline';
  tooltipEl.style.position = 'absolute';

  const tooltipTooCloseToRight =
    window.innerWidth - tooltipModel.caretX - tooltipEl.clientWidth - position.left < tooltipXAxisTolerance;

  tooltipEl.style.left =
    tooltipModel.caretX + position.left + (tooltipTooCloseToRight ? -tooltipXAxisTolerance : 0) + 'px';

  let topPosition = position.top + window.pageYOffset + tooltipModel.caretY;

  const isEndOfScreen = topPosition + tooltipEl.clientHeight >= window.innerHeight;

  if (isEndOfScreen) {
    topPosition -= tooltipEl.clientHeight + 15;
  }

  tooltipEl.style.top = topPosition + 'px';
};

export default externalTooltip;
