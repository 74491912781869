import { omit } from 'lodash';
import { ContentClone } from './types';

// Replaces the text and parameter <span> tag with <code> tag,
// TODO this code should at some point be removed.
export const convertSpanToCode = (contents: Array<ContentClone> | []) => {
  return contents.map(content => {
    let newContent = content.content;
    if (newContent !== null) {
      // Update for parameter
      newContent = newContent.replace(
        /<span class="parameter-wrapper"(.*?)<\/span>/g,
        '<code class="parameter-wrapper"$1</code>',
      );
      // Update for text block
      newContent = newContent.replace(
        /<span class="text-block-wrapper"(.*?)<\/span>/g,
        '<code class="text-block-wrapper"$1</code>',
      );
    }
    return {
      documentTypeId: content.documentTypeId,
      content: newContent,
    };
  });
};

export const setContentInput = (contents: ContentClone[]) => {
  return contents.map((content: ContentClone) => omit(content, 'id'));
};
