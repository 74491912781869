import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DuplicatePolicyQueryVariables {
  policyId: string;
}

export const DUPLICATE_POLICY = (policyId: string | undefined) => {
  if (!policyId) throw new Error('DUPLICATE_POLICY: policyId is required');

  const variables: DuplicatePolicyQueryVariables = { policyId };

  return {
    mutation: gql`
      mutation ($policyId: ID!) {
        duplicatePolicy(policyId: $policyId) {
          id
        }
      }
    `,
    variables,
  };
};

export const duplicatePolicy = createAsyncThunk('policies/duplicatePolicy', async (policyId: string | undefined) => {
  const response = await client.mutate(DUPLICATE_POLICY(policyId));
  return response;
});
