import { ProvisionListingClone } from 'common/_classes';
import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setActiveProvisionId, setProvisionsList } from 'store/miscellaneous/miscellaneousSlice';
import { ContentClone } from 'common/api/provisions';
import { getActiveDocument } from 'utils/tsHelper';
import { parseSelectedContent } from '..';

export interface ProvisionListElementT {
  id: string;
  text: string | null | undefined;
  isStructure: boolean;
}

interface TableOfContent {
  contents: ContentClone[];
  nodeType: string | null | undefined;
}

// The provision node in a content has the following structure:
// <div data-node-type="provision" data-node-id="nodeId">
//   <span class="provision-name">
//     name of the provision
//   </span>
// </div>
export const getListOfProvisionsFromContent = (
  contents: ContentClone[],
  activeDocTypeId: string | null,
  provisionsList: ProvisionListingClone[],
) => {
  const parsed = parseSelectedContent(contents, activeDocTypeId);

  const allProvisionElements = parsed.querySelectorAll('[data-node-type="provision"]');

  let provisions = [];
  for (let i = 0; i < allProvisionElements.length; i++) {
    const provisionElement = allProvisionElements[i] as HTMLElement;
    const provisionNameElement: HTMLElement | null = provisionElement.querySelector('.provision-name');

    const isStructure: boolean = !!provisionsList.find(
      (obj: ProvisionListingClone) => obj.id === provisionElement.dataset.nodeId,
    )?.isStructure;

    provisions.push({
      id: provisionElement.dataset.nodeId,
      text: provisionNameElement !== null ? provisionNameElement.innerText : null,
      isStructure,
    });
  }
  return provisions;
};

const TableOfContent = ({ contents, nodeType }: TableOfContent) => {
  const dispatch = useAppDispatch();

  const { docProvisionsList, activeDocTypeId, activeProvisionId } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );
  const { provisionsList } = useAppSelector((state: RootState) => state.provisionsListing);

  useEffect(() => {
    const provisions = getListOfProvisionsFromContent(contents, activeDocTypeId, provisionsList);
    dispatch(setProvisionsList({ provisions }));
  }, [activeDocTypeId, nodeType, provisionsList]);

  const scrollToProvision = (provisionId: string) => {
    console.log('provision id: ', provisionId);
    const activeDoc: Document | null = getActiveDocument();
    const documentElement: HTMLElement | null = activeDoc !== null ? activeDoc.documentElement : null;
    const scrollItem: Element | null =
      documentElement !== null ? documentElement.querySelector(`[data-node-id="${provisionId}"]`) : null;

    if (scrollItem !== null) {
      scrollItem.scrollIntoView();
      window.scroll(0, 0);
      dispatch(setActiveProvisionId({ provisionId }));
    }
  };

  return (
    <div className="table-of-content m-t-s">
      {docProvisionsList &&
        docProvisionsList.map((provision: ProvisionListElementT) => (
          <div
            id={`table-of-content-${provision.id}`}
            data-test={`table-of-content-${provision.text}`}
            className={`provision-box
              ${provision.isStructure ? 'bold' : ''}
              ${activeProvisionId === provision.id ? 'active' : ''}
            `}
            key={`provision-${provision.id}`}
            onClick={() => scrollToProvision(provision.id)}
          >
            {provision.text}
          </div>
        ))}
    </div>
  );
};

export default TableOfContent;
