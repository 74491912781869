import { ParameterChoiceBase } from 'common/_classes';
import { Fragment, ReactNode, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import ModalTemplate from 'components/ModalTemplate';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerProps } from 'common/api/policies';
import { checkIfFromDeviation, checkPolicyInAnswers, getAnswerValue } from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import ChoiceOptions from '../../ParameterAnswerAccordion/AnswerHistoryModal/ChoiceOptions';
import './AnswerHistoryModal.scss';

const valueToDisplayText = (
  history: AnswerProps,
  valueOfAnswer: string | { title: string; url: string },
  unit: string | undefined,
): ReactNode | string => {
  if (valueOfAnswer === '') {
    return 'Cleared';
  } else if (history.answerType === AnswerTypes.Image && typeof valueOfAnswer !== 'string') {
    return (
      <a
        href={valueOfAnswer.url}
        target="_blank"
      >
        {valueOfAnswer.title}
      </a>
    );
  } else {
    const answerUnit: string = unit ? ` ${unit}` : '';
    return `${valueOfAnswer}${answerUnit}`;
  }
};

class AnswerHistory {
  fromInventory?: boolean;
  valueOfAnswer: string | { title: string; url: string };
  valueToDisplay: ReactNode | string;
  answerDate: string;
  fromPolicy?: boolean;
  userName: string;
  index?: number;

  constructor(
    history: AnswerProps,
    unit: string | undefined,
    choices: ParameterChoiceBase[],
    loggedInUserName: string,
  ) {
    this.fromPolicy = history.fromPolicy;
    this.index = history.blockRef;
    this.valueOfAnswer = getAnswerValue(history.answer, history.answerType, choices);

    this.userName = history.user ? `${history.user.firstName} ${history.user.lastName}` : loggedInUserName;
    let answerDate = new Date(history.dateOfAnswer);
    answerDate = new Date(answerDate.getTime() + answerDate.getTimezoneOffset() * 60000);

    this.answerDate = answerDate.toLocaleString('en-UK', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Asia/Hong_Kong',
    }) as string;

    this.valueToDisplay = valueToDisplayText(history, this.valueOfAnswer, unit);
  }
}

interface AnswerHistoryModalProps {
  answerType: AnswerTypes;
  question: string;
  questionNote: string;
  answers: AnswerProps[];
  choices: ParameterChoiceBase[];
  unit: string | undefined;
  hasInventory: boolean;
  inventoryBtnText: string;
}

const AnswerHistoryModal = ({
  answerType,
  question,
  questionNote,
  answers,
  choices,
  unit,
  hasInventory,
  inventoryBtnText,
}: AnswerHistoryModalProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { firstName, lastName } = useAppSelector((state: RootState) => state.auth);

  const loggedInUserName: string = `${firstName} ${lastName}`;

  const checkIfFromPolicy: boolean = checkPolicyInAnswers(answers);

  const answerHistory: AnswerHistory[] = answers.map(
    (answer: AnswerProps) => new AnswerHistory(answer, unit, choices, loggedInUserName),
  );

  const multipleTypes: AnswerTypes[] = [AnswerTypes.SingleChoice, AnswerTypes.MultiChoice, AnswerTypes.Boolean];
  const checkMultiple: boolean = multipleTypes.includes(answerType);

  return (
    <ModalTemplate
      isOpen={open}
      onToggleModalStatus={setOpen}
      className="history-modal"
      title="Parameter History"
      trigger={
        <Icon
          icon={Icons.ClockCounterClockwise}
          className="clock-icon bg-orange-very-light-grayish color-gray-very-darker m-l-sm m-r-xs m-t-clear-sm"
        />
      }
      header={
        <div className="flex-header">
          {hasInventory && (
            <Badge
              className="parameter-history-header-badge"
              badgeColor={BadgeColor.PURPLE}
            >
              {inventoryBtnText}
            </Badge>
          )}
          {checkIfFromPolicy && (
            <Badge
              className="parameter-history-header-badge"
              badgeColor={BadgeColor.BLUE}
            >
              POLICY
            </Badge>
          )}
          {checkIfFromDeviation(checkIfFromPolicy, answers) && (
            <Badge
              className="parameter-history-header-badge"
              badgeColor={BadgeColor.ORANGE}
            >
              DEVIATION
            </Badge>
          )}
        </div>
      }
    >
      <Grid>
        <Grid.Row className="question-row">
          <Grid.Column width={4}>
            <h2 className="left-header">Question</h2>
          </Grid.Column>
          <Grid.Column width={12}>
            <h2 className="question-text">{question}</h2>
            <h2 className="question-note-text">{questionNote}</h2>
          </Grid.Column>
        </Grid.Row>
        {checkMultiple && (
          <ChoiceOptions
            choices={choices}
            answerType={answerType}
          />
        )}
        <Grid.Row>
          <div className="w-100 m-l-sm m-r-sm border-bottom-sm-grayish-magenta-light" />
        </Grid.Row>
        <Grid.Row className={`answer-row ${checkMultiple && 'mcq-type'}`}>
          <Grid.Column width={4}>
            <h2 className="left-header answer-history">Answer history</h2>
          </Grid.Column>
          <Grid.Column
            width={12}
            className="answers-list"
          >
            <Grid
              columns={3}
              className="answer-columns"
            >
              {answerHistory.map((answerHistory: AnswerHistory, index: number) => {
                if (answerHistory.index !== undefined) {
                  // Do not show unsaved new answer in history modal
                  return <Fragment key={`answer-${index}`}></Fragment>;
                }
                const previousAnswerIsUnsaved = index && answers[index - 1].blockRef !== undefined;

                return (
                  <Grid.Row
                    key={`answer-${index}`}
                    className="answer-row-details"
                    single-value={`${answers.length === 1}`}
                    has-inventory={`${answerHistory.fromInventory}`}
                  >
                    <div
                      className="circle-xs history-dots bg-blue"
                      first-dot={`${index === 0 || previousAnswerIsUnsaved}`}
                      last-dot={`${answers.length - 1 === index || answerHistory.valueOfAnswer === ''}`}
                      has-inventory={`${answerHistory.fromInventory}`}
                    ></div>
                    <Grid.Column
                      width={6}
                      className="user-time-details"
                      first={`${index === 0 || previousAnswerIsUnsaved}`}
                    >
                      <div>{answerHistory.answerDate.toString()}</div>
                      {answerHistory.fromInventory && <div>Inventory data</div>}
                      <div>{answerHistory.fromPolicy ? 'Policy initial position' : `by ${answerHistory.userName}`}</div>
                    </Grid.Column>
                    <Grid.Column
                      width={6}
                      className="answer-value"
                      first={`${index === 0}`}
                    >
                      <div className={`${answerHistory.valueOfAnswer === '' && 'cleared-answer'}`}>
                        {answerHistory.valueToDisplay}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                );
              })}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="btn-row">
        <Button
          className="btn grey-outline history-cancel-btn"
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
      </div>
    </ModalTemplate>
  );
};

export default AnswerHistoryModal;
