import { ExecContext } from '@apollo/client/core/LocalState';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { current } from '@reduxjs/toolkit';
import moment from 'moment';
import { isEqual, sortBy } from 'lodash';
import { updatePolicyLatestAnswers } from 'store/policies/policyDetailSlice';
import { updateTransactionLatestAnswers } from 'store/transactions/transactionDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import ExecuteContext from 'common/model/ExecuteContext';
import ParamRef from 'common/model/ParamRef';
import Parameter from 'common/model/Parameter';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { AnswerProps, ParameterChoiceBase, SetAnswerInputResult } from 'common/api/policies';
import { DROPDOWN_OPTION } from 'utils/UI';
import { checkIfNotDefined, checkIfPoliciesPage } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import { uploadToAWS } from 'utils/utils-upload';
import { getCurrentDateTimeISO } from './utils-date';
import { makePlural } from './utils-string';

interface ANSWER_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: AnswerTypes;
}

//TODO: Investigate whether "key" is necessary prop for the React Dropdown component [https://react.semantic-ui.com/modules/dropdown/#types-selection]
export const ANSWER_TYPE_OPTIONS: ANSWER_TYPE_OPTION[] = [
  { key: 1, text: 'MCQ Single', value: AnswerTypes.SingleChoice },
  { key: 2, text: 'MCQ Multiple', value: AnswerTypes.MultiChoice },
  { key: 3, text: 'Text', value: AnswerTypes.Text },
  { key: 4, text: 'Date', value: AnswerTypes.Date },
  { key: 5, text: 'Time', value: AnswerTypes.Time },
  { key: 6, text: 'Duration', value: AnswerTypes.Duration },
  { key: 7, text: 'Number', value: AnswerTypes.Number },
  { key: 8, text: 'Number percent', value: AnswerTypes.NumberPercent },
  { key: 9, text: 'Number with unit', value: AnswerTypes.NumberUnit },
  { key: 10, text: 'Boolean', value: AnswerTypes.Boolean },
  { key: 11, text: 'Image', value: AnswerTypes.Image },
];

export const convertAnswerType = (answerType: AnswerTypes): string => {
  const type = ANSWER_TYPE_OPTIONS.find((obj: ANSWER_TYPE_OPTION) => obj.value === answerType);
  return type ? type.text : '';
};

export enum CounterOptions {
  Increment = 'increment',
  Decrement = 'decrement',
}

export const updateIterationHelper = (
  answers: any,
  answerType: string,
  parentId: string,
  type: CounterOptions,
  activeMessageId: string | null,
  context: ExecuteContext,
): any[] | undefined => {
  let latestAnswers = answers.filter(
    (obj: AnswerProps) =>
      obj.answerType === answerType && obj.paramRef.parameterId === parentId && checkIfNotDefined(obj.paramRef.tableId),
  );
  latestAnswers = latestAnswers[latestAnswers.length - 1];
  let cloneLatestAnswer = JSON.parse(JSON.stringify(latestAnswers));

  if (activeMessageId !== null) {
    cloneLatestAnswer.transactionMessage = { id: activeMessageId };
    cloneLatestAnswer.messageId = activeMessageId;
  } else {
    cloneLatestAnswer.transactionMessage = null;
    cloneLatestAnswer.messageId = null;
  }

  let answerValue;
  if (type === CounterOptions.Decrement) {
    answerValue = String(Number(cloneLatestAnswer.answer.value) - 1);
  } else {
    answerValue = Number(cloneLatestAnswer.answer.value) + 1;
  }

  cloneLatestAnswer.answer.value = answerValue;
  cloneLatestAnswer.dateOfAnswer = getCurrentDateTimeISO();
  delete cloneLatestAnswer['id'];
  delete cloneLatestAnswer['user'];
  if (cloneLatestAnswer.blockRef !== undefined) {
    // update the cloneLatest value instead of adding a new answer
    const index = answers.findIndex(
      (obj: AnswerProps) =>
        obj.paramRef.parameterId === cloneLatestAnswer.paramRef.parameterId &&
        obj.paramRef.tableId === cloneLatestAnswer.paramRef.tableId &&
        obj.paramRef.index === cloneLatestAnswer.paramRef.index &&
        obj.blockRef !== undefined,
    );

    answers[index] = cloneLatestAnswer;
    return answers;
  } else {
    const accordions = document.getElementsByClassName('custom-accordion');
    let blockRef = 0;
    for (let i = 0; i < accordions.length; i++) {
      if (accordions[i].id === `${parentId}-null-null`) {
        break;
      }
      blockRef += 1;
    }
    cloneLatestAnswer.blockRef = blockRef;
    if (context === ExecuteContext.Transaction) {
      cloneLatestAnswer.approvalState = ValidApprovalStates.Pending;
    }
    return [...answers, cloneLatestAnswer];
  }
};

/**
 * Note: JSON.parse() can return any type of data represented
 * in JSON (an array, an object, a string, a number, true, false, or null),
 * and the function may also return the original string
 * TODO: examine the reference calls for the function and see what data type is being expected and update the conditional logic accordingly
 * @param str
 * @returns object | string | number | boolean | null
 */
export const getJSON = (str: string): object | string | number | boolean | null => {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
};

interface GetLatestAnswerParams {
  answers: AnswerProps[];
  answerType: AnswerTypes;
  parameterId: string;
  tableId: string | null;
  tabIndex: number | undefined | null;
  emptyValue: string | object;
}

export const getLatestAnswer = ({
  answers,
  answerType,
  parameterId,
  tableId,
  tabIndex,
  emptyValue,
}: GetLatestAnswerParams): string | object => {
  let getAnswers;
  let output: string | object = '';
  if (tableId) {
    getAnswers = answers.filter(
      (obj: AnswerProps) =>
        obj.answerType === answerType &&
        obj.paramRef.parameterId === parameterId &&
        obj.paramRef.tableId === tableId &&
        obj.paramRef.index === tabIndex,
    );
    if (getAnswers.length === 0) {
      output = emptyValue;
    }
  } else {
    getAnswers = answers.filter(
      (obj: AnswerProps) =>
        obj.answerType === answerType &&
        obj.paramRef.parameterId === parameterId &&
        checkIfNotDefined(obj.paramRef.tableId),
    );
  }

  const answerSize = getAnswers.length;
  if (answerSize !== 0) {
    const { answer } = getAnswers[answerSize - 1];
    let answerValue = JSON.parse(JSON.stringify(answer));
    answerValue = getJSON(answerValue);
    if (
      answerType === AnswerTypes.Image ||
      answerType === AnswerTypes.Period ||
      answerType === AnswerTypes.Duration ||
      answerType === AnswerTypes.SingleChoice ||
      answerType === AnswerTypes.MultiChoice ||
      answerType === AnswerTypes.Time
    ) {
      output = answerValue;
    } else {
      output = answerValue.value;
    }
  } else {
    output = emptyValue;
  }
  return output;
};

interface CheckIfAnswerExistsParams {
  answers: AnswerProps[];
  answerType: AnswerTypes | undefined;
  parameterId: string | undefined;
  tableId: string | undefined;
  tabIndex: number | undefined;
}

export const checkIfAnswerExists = ({
  answers,
  answerType,
  parameterId,
  tableId,
  tabIndex,
}: CheckIfAnswerExistsParams): boolean => {
  let getAnswers;
  if (tableId) {
    getAnswers = answers.filter(
      (obj: AnswerProps) =>
        obj.answerType === answerType &&
        obj.paramRef.parameterId === parameterId &&
        obj.paramRef.tableId === tableId &&
        obj.paramRef.index === tabIndex,
    );
  } else {
    getAnswers = answers.filter(
      (obj: AnswerProps) =>
        obj.answerType === answerType &&
        obj.paramRef.parameterId === parameterId &&
        checkIfNotDefined(obj.paramRef.tableId),
    );
  }

  const answerSize = getAnswers.length;
  if (answerSize !== 0) {
    const { answer } = getAnswers[answerSize - 1];
    let answerValue = JSON.parse(JSON.stringify(answer));
    answerValue = getJSON(answerValue);
    switch (answerType) {
      case AnswerTypes.Boolean:
        let value = answerValue.value;
        value = value === null ? null : value.toString().toUpperCase();
        return checkNotEmpty(value);
      case AnswerTypes.Image:
        const { url, title } = answerValue;
        return checkNotEmpty(url) && checkNotEmpty(title);
      case AnswerTypes.MultiChoice:
      case AnswerTypes.SingleChoice:
        if (answerValue.values?.length === 0) {
          return true;
        }
        return answerValue.values !== null && answerValue.values.length;
      case AnswerTypes.Time:
        const { hours, minutes } = answerValue;
        return hours.length !== 0 || minutes.length !== 0;
      case AnswerTypes.Duration:
        const { days, months, years } = answerValue;
        return days?.length !== 0 || months?.length !== 0 || years?.length !== 0;
      case AnswerTypes.Period:
        const { startDate, endDate } = answerValue;
        return startDate.length !== 0 || endDate.length !== 0;
      default:
        if (answerValue.value !== null) {
          return answerValue.value.length !== 0;
        } else {
          return false;
        }
    }
  } else {
    return false;
  }
};

interface UpdateAnswerParams {
  value: any;
  blockRef: number | string;
  answerType: AnswerTypes;
  parameterId: string;
  tableId: string | null;
  tabIndex: number | undefined | null;
  dispatch: Dispatch<AnyAction>;
  answerUnit?: string;
}

export const updateAnswer = ({
  value,
  blockRef,
  answerType,
  parameterId,
  tableId,
  tabIndex,
  answerUnit,
  dispatch,
}: UpdateAnswerParams): any => {
  let answerValue, paramRef;

  if (
    answerType === AnswerTypes.Period ||
    answerType === AnswerTypes.SingleChoice ||
    answerType === AnswerTypes.MultiChoice ||
    answerType === AnswerTypes.Time
  ) {
    answerValue = value;
  } else if (answerType === AnswerTypes.NumberUnit) {
    answerValue = { value, unit: answerUnit };
  } else if (answerType === AnswerTypes.Duration) {
    answerValue = {
      years: value.years ? value.years : '',
      months: value.months ? value.months : '',
      days: value.days ? value.days : '',
    };
  } else {
    answerValue = { value };
  }

  if (tableId) {
    paramRef = {
      parameterId,
      tableId,
      index: tabIndex,
      // might need offset as well
    };
  } else {
    paramRef = {
      parameterId,
      tableId: null,
      index: null,
    };
  }

  const answer = {
    blockRef,
    answer: answerValue,
    answerType,
    dateOfAnswer: getCurrentDateTimeISO(),
    paramRef,
  };

  const addNewAnswer = checkIfPoliciesPage() ? updatePolicyLatestAnswers : updateTransactionLatestAnswers;
  dispatch(addNewAnswer({ answer }));
};

export interface ParameterCount {
  totalQuestions: number;
  definedAnswers: number;
}

/*
  Get the total questions and defined answers 
  to be shown in the progress bar.
  1. Iterate through paramsGroupList -> Combination of parameters and table by group
  2. If Table of parameters ->
    Go inside columns and the parameters which have an answer
  3. If parameters ->
    Add the parameters to the count which have an answer
  4. Increment total questions as well for every iteration  
*/
export const getParameterCount = (
  paramsGroupList: any, // TODO
  answers: AnswerProps[],
): ParameterCount => {
  let totalQuestions: number = 0;
  let definedAnswers: number = 0;

  for (let i = 0; i < paramsGroupList.length; i++) {
    // Table of Parameter
    if (paramsGroupList[i].rowNumber) {
      if (paramsGroupList[i].rowNumber.parameter && paramsGroupList[i].rowNumber.parameter.id !== null) {
        const answerValue = getLatestAnswerValue({
          answers,
          answerType: paramsGroupList[i].rowNumber.parameter.answerType,
          parameterId: paramsGroupList[i].rowNumber.parameter.id,
        });
        if (answerValue) {
          const columns = paramsGroupList[i].columns;
          const tableId = paramsGroupList[i].id;
          // Iterate through columns of table
          for (let i = 0; i < columns.length; i++) {
            for (let tabIndex = 0; tabIndex < answerValue; tabIndex++) {
              if (
                !!columns[i].parameter.parameterTableColumns &&
                columns[i].parameter.parameterTableColumns.length > 0
              ) {
                const checkIfAnswer: boolean = checkIfAnswerExists({
                  answers,
                  answerType: columns[i].parameter.answerType,
                  parameterId: columns[i].parameter.id,
                  tableId,
                  tabIndex,
                });
                if (checkIfAnswer) {
                  definedAnswers += 1;
                }
                totalQuestions += 1;
              }
            }
          }
        }
      }
    }
    // Parameter
    else {
      if (!paramsGroupList[i].parameterTableColumns || paramsGroupList[i].parameterTableColumns.length === 0) {
        const checkIfAnswer = checkIfAnswerExists({
          answers,
          answerType: paramsGroupList[i].answerType,
          parameterId: paramsGroupList[i].id,
          tableId: undefined,
          tabIndex: undefined,
        });
        if (checkIfAnswer) {
          definedAnswers += 1;
        }
        totalQuestions += 1;
      }
    }
  }

  return { definedAnswers, totalQuestions };
};

/**
 *
 * This function is problematic in typescript given the widely varying return types.
 * The function's utility to save time in retriving an answer, but given the possible
 * variance in response, the utility becomes redundent.
 * EXAMPLE: See the function calls in getDefaultPolicyAnswer
 */
export const getAnswerValue = (
  answer: any,
  answerType: AnswerTypes | undefined,
  choices?: ParameterChoiceBase[],
): any => {
  let answerValue = JSON.parse(JSON.stringify(answer));
  answerValue = getJSON(answerValue);

  switch (answerType) {
    case AnswerTypes.Image:
      const { title, url } = answer;
      return title ? { title, url } : '';

    case AnswerTypes.SingleChoice:
    case AnswerTypes.MultiChoice:
      if (answerValue.values === null) {
        return '';
      }

      if (answerValue.values.length === 0 && answerValue.inverses.length !== 0) {
        return 'None';
      }

      const findAnswerText = (choiceId: string) => {
        const found = choices?.find(({ id }) => id === choiceId);
        return found ? found.text : 'Deleted Choice';
      };
      const result = answerValue.values.map((choiceId: string) => findAnswerText(choiceId));
      return result.join(', ');

    case AnswerTypes.Time:
      const { hours, minutes } = answerValue;
      if (hours === '' && minutes === '') {
        return '';
      }
      return `${hours ? hours : 0} hour(s) : ${minutes ? minutes : 0} minute(s)`;

    case AnswerTypes.Duration:
      const { days, months, years } = answerValue;

      if (days === '' && months === '' && years === '') {
        return '';
      }

      // only list the years/months/days if they are defined and none zero.
      const list = [
        years && years !== '' && years !== '0' ? makePlural(years, ' year') : null,
        months && months !== '' && months !== '0' ? makePlural(months, ' month') : null,
        days && days !== '' && days !== '0' ? makePlural(days, ' day') : null,
      ];
      return list.filter(element => element !== null).join(' ');

    case AnswerTypes.Boolean:
      const value = answerValue.value;

      if (value === null) return '';

      return value ? 'Yes' : 'No';

    case AnswerTypes.Date:
      return answerValue.value ? moment(answerValue.value).format('DD/MM/YYYY') : '';
    default:
      return answerValue.value;
  }
};

// TODO: temporary create this, needs to check why it is needed...
interface ParamWithRef extends Parameter {
  paramRef: ParamRef;
}

// Find the answer value by paramRef
export const getAnswerValueByParameterId = (
  answers: AnswerProps[],
  parameter: ParamWithRef,
): string | { title: string; url: string } | null => {
  // Find the answer with the paramRef
  // @ts-ignore
  const answer = answers.findLast(
    (answer: AnswerProps) =>
      answer.paramRef.parameterId === parameter.id &&
      answer.paramRef.tableId === parameter.paramRef?.tableId &&
      answer.paramRef.index === parameter.paramRef?.index,
  );
  if (answer) {
    // Get the value of the answer
    return getAnswerValue(answer.answer, answer.answerType as AnswerTypes, parameter.choices);
  }

  return '';
};

interface GetLatestAnswerValueParams {
  answers: AnswerProps[];
  answerType: string;
  parameterId: string;
}

export const getLatestAnswerValue = ({
  answers,
  answerType,
  parameterId,
}: GetLatestAnswerValueParams): number | undefined => {
  const getAnswers: AnswerProps[] = answers.filter(
    (obj: AnswerProps) =>
      obj.answerType === answerType &&
      obj.paramRef.parameterId === parameterId &&
      checkIfNotDefined(obj.paramRef.tableId),
  );
  const answersSize: number = getAnswers.length;
  if (answersSize !== 0) {
    const answerItem: AnswerProps = getAnswers[answersSize - 1];
    const { answer } = answerItem;
    // let answerValue = JSON.parse(JSON.stringify(answer));
    // answerValue = getJSON(answerValue);
    return answer.value;
  }
};

/**
 * Set Answer input for graph ql schema
 *
 * In this DB, we should only saved answers that are modified and the 'answer' field is
 * saved as a json document. This function is performing the following:
 *  1) answer field and is stringified.
 *  2) check if answer is really new or not.
 *     Previous answers are saved in the map 'latestAnswer' with the key being a uniq
 *     descriptor of the parameter : "answerType-parameterId-tableIndex-tableId"
 *     For example:
 *      - "DURATION-215613161313-null-null" if the parameter is not in a table
 *      - "NUMBER-9856454561435-0-7091324651346" if the parameter is at index 0 of a table
 *
 * @param answers
 * @returns
 */
export const setAnswerInput = async (answers: AnswerProps[]): Promise<SetAnswerInputResult[]> => {
  const latestAnswer: { [key: string]: string } = {};
  const answersList: SetAnswerInputResult[] = answers.reduce((result: any, answer: any) => {
    const key: string = `${answer.answerType}-${answer.paramRef.parameterId}-
      ${answer.paramRef.index === undefined ? null : answer.paramRef.index}-
      ${answer.paramRef.tableId === undefined ? null : answer.paramRef.tableId}`;

    const answerCopy = { ...answer };

    if ([AnswerTypes.Number, AnswerTypes.NumberUnit, AnswerTypes.NumberPercent].includes(answer.answerType)) {
      answerCopy.answer = {
        value: answer.answer.value ? Number(answer.answer.value) : answer.answer.value,
        ...(AnswerTypes.NumberUnit === answer.answerType ? { unit: answer.answer.unit } : {}),
      };
    }

    // Upload files to aws
    if (answer.answerType === AnswerTypes.Image) {
      const { uploadUrl, file, cdnUrl, title, local } = answer.answer;
      const answerObj = latestAnswer[key];
      const latestImageAnswerTitle = answerObj ? JSON.parse(latestAnswer[key]).title : null;

      if (local) {
        if (latestImageAnswerTitle !== title) {
          uploadToAWS(uploadUrl, file);

          answerCopy.answer = {
            title,
            url: cdnUrl,
          };
        } else {
          return result;
        }
      }
    }

    // @ts-ignore
    const stringifiedAnswer = JSON.stringify(getJSON(answerCopy.answer as string));

    const formatedAnswer: SetAnswerInputResult = {
      answer: stringifiedAnswer,
      answerType: answer.answerType,
      dateOfAnswer: answer.dateOfAnswer,
      paramRef: answer.paramRef,
    };

    if (answer.fromPolicy) {
      formatedAnswer.fromPolicy = answer.fromPolicy;
    }
    if (answer.fromInventory) {
      formatedAnswer.fromInventory = answer.fromInventory;
    }

    // Add transaction Id
    if (answer.transaction || answer.transactionId) {
      formatedAnswer.transactionId = answer.transactionId ? answer.transactionId : answer.transaction?.id;
    }

    // Add policy Id
    if (answer.policy || answer.policyId) {
      formatedAnswer.policyId = answer.policyId ? answer.policyId : answer.policy?.id;
    }

    // Part of discussion
    if (answer.approvalState) {
      formatedAnswer.approvalState = answer.approvalState;
      formatedAnswer.note = answer.note;
      // @ts-ignore
      formatedAnswer.changedValue = answer.changedValue;
    }

    if (answer.transactionMessage || answer.transactionMessageId) {
      formatedAnswer.transactionMessageId = answer.transactionMessageId
        ? answer.transactionMessageId
        : answer.transactionMessage?.id;
    }

    if (answer.transactionParameterApproval || answer.transactionParameterApprovalId) {
      formatedAnswer.transactionParameterApprovalId = answer.transactionParameterApprovalId
        ? answer.transactionParameterApprovalId
        : answer.transactionParameterApproval.id;
    }

    if (answer.transactionDiscussionApproval || answer.transactionDiscussionApprovalId) {
      formatedAnswer.transactionDiscussionApprovalId = answer.transactionDiscussionApprovalId
        ? answer.transactionDiscussionApprovalId
        : answer.transactionDiscussionApproval?.id;
    }

    if (answer.transactionProvisionApproval || answer.transactionProvisionApprovalId) {
      formatedAnswer.transactionProvisionApprovalId = answer.transactionProvisionApprovalId
        ? answer.transactionProvisionApprovalId
        : answer.transactionProvisionApproval?.id;
    }

    result.push(formatedAnswer);
    return result;
  }, []);
  return answersList;
};

export const getAnswers = (policyAnswers: AnswerProps[], transactionAnswers: AnswerProps[]): AnswerProps[] =>
  checkIfPoliciesPage()
    ? policyAnswers
    : sortBy(transactionAnswers, 'dateOfAnswer').filter(
        (transactionAnswer: AnswerProps) => transactionAnswer.approvalState !== ValidApprovalStates.Rejected,
      );

/**
 * Checking if answer is from policy
 * @param {AnswerProps[]} answers
 * @returns if fromPolicy is true for any answer then returns true otherwise false
 */
export const checkPolicyInAnswers = (answers: AnswerProps[]): boolean => {
  return answers.some(answer => answer.fromPolicy);
};

// checkIfFromDeviation only check deviation for parameters defined
// in policy and that have more than 1 answer.
// if the latest answer is different from the first one (which by
// construction is the default position defined in the policy), this
// means there is a deviation
export const checkIfFromDeviation = (checkFromPolicy: boolean, answers: AnswerProps[]): boolean => {
  const answerSize: number = answers.length;
  // Answer is from policy and we have more than 1 answers
  if (checkFromPolicy && answerSize > 1) {
    const firstAnswer = answers[0].answer;
    const latestAnswer = answers[answers.length - 1].answer;
    const { answerType } = answers[0];

    // Compare according to answer types
    switch (answerType) {
      case AnswerTypes.Number:
      case AnswerTypes.NumberUnit:
      case AnswerTypes.NumberPercent:
        return firstAnswer.value !== latestAnswer.value;

      case AnswerTypes.Boolean:
        return String(firstAnswer.value) !== String(latestAnswer.value);

      case AnswerTypes.MultiChoice:
        const valuesFirst = firstAnswer.values;
        const valuesLatest = latestAnswer.values;
        // If cleared answer
        if (valuesFirst === null || valuesLatest === null) {
          return valuesFirst === valuesLatest;
        }
        // If uncleared answer
        else if (valuesLatest && valuesFirst && valuesFirst.length === valuesLatest.length) {
          return !valuesFirst.every((id: string) => valuesLatest.includes(id));
        }
        return true;

      default:
        return !isEqual(firstAnswer, latestAnswer);
    }
  }
  return false;
};
