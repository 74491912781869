import { useEffect, useState } from 'react';
import { Form, Image, Input, Popup } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import PopUpInfo from 'atoms/PopUpInfo';
import { handlePaste } from 'utils/tsHelper';
import { numberInputValidator } from 'utils/utils-clean-input';
import { labelResizeObserver } from 'utils/utils-html';
import { Icons } from 'utils/utils-icons';
import { addSpaceOrComma } from 'utils/utils-number';
import { removeSpaceOrComma } from 'utils/utils-number';
import { generateUniqueId } from 'utils/utils-random';
import triangleCheck from 'assets/images/svg/triangle-check.svg';
import './Input.scss';

type DelimiterTypes = 'COMMA' | 'SPACE';

export enum InputFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  EMAIL = 'email',
}

interface InputFieldProps {
  info?: boolean;
  label?: string | null;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  type?: InputFieldType;
  value: string | null;
  onChange: (key: string, value: string) => void;
  fieldKey: string;
  isInValid?: boolean;
  errorMessage?: string | boolean;
  successMessage?: string;
  unit?: string;
  isDecimal?: boolean;
  canBeNegative?: boolean;
  delimiter?: DelimiterTypes;
  dataTest?: string;
  popUp?: boolean;
  infoText?: string;
  min?: number;
  max?: number;
  className?: string;
}

const InputField = ({
  info,
  label,
  required,
  placeholder,
  disabled,
  type = InputFieldType.TEXT,
  value,
  onChange,
  fieldKey,
  isInValid,
  errorMessage,
  successMessage,
  unit,
  isDecimal,
  canBeNegative,
  delimiter,
  dataTest,
  popUp,
  infoText,
  min,
  max,
  className,
}: InputFieldProps): JSX.Element => {
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  const [formContainerId] = useState<string>(generateUniqueId(fieldKey));
  const [id] = useState<string>(generateUniqueId(fieldKey));
  const [error, setError] = useState<string>('');
  const [internalValue, setInternalValue] = useState<string | null>(value);
  const [pasteHandlerAdded, setPasteHandlerAdded] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [showLabelPopup, setShowLabelPopup] = useState(false);

  useEffect(() => {
    setPasswordShow(false);
  }, [disabled]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    const pasteHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      handlePaste(e, onChange, fieldKey, type);
    };

    const inputField = document.getElementById(id);

    if (!disabled && type === InputFieldType.NUMBER && !pasteHandlerAdded) {
      (inputField as any).addEventListener('paste', pasteHandler);
      setPasteHandlerAdded(true);
    }

    return () => {
      (inputField as any).removeEventListener('paste', pasteHandler, true);
    };
  }, [disabled, type, id, fieldKey, onChange, pasteHandlerAdded]);

  const handlePasswordShow = () => setPasswordShow(!passwordShow);

  const formatToNoSpaceOrComma = (value: string): string =>
    type === InputFieldType.NUMBER ? removeSpaceOrComma(value) : value;

  const getType = (type: string) => {
    if (type === InputFieldType.NUMBER) {
      return 'text';
    } else {
      return type;
    }
  };

  const minAndMaxForTypeNumber =
    type === InputFieldType.NUMBER
      ? {
          min: min?.toLocaleString(),
          max: max?.toLocaleString(),
        }
      : {};

  const renderValue = (val: string | null) => {
    const getValue = (val: string | null) => {
      if (type === InputFieldType.NUMBER && delimiter) {
        return delimiter === 'COMMA' ? addSpaceOrComma(val, false) : addSpaceOrComma(val, true);
      } else {
        return val;
      }
    };
    return val || ['0', 0].includes(val as string) ? getValue(val) : '';
  };

  const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInternalValue(formatToNoSpaceOrComma(value));
  };

  useEffect(() => {
    if (timer) clearTimeout(timer);

    setTimer(
      setTimeout(() => {
        onChange(fieldKey, internalValue || '');
      }, 500),
    );
  }, [internalValue]);

  useEffect(() => {
    // Label popup control when label is truncated with an ellipsis
    labelResizeObserver(formContainerId, setShowLabelPopup);
  }, []);

  return (
    <Form.Field
      className={`field-style ${className}`}
      id={formContainerId}
    >
      {label && (
        <label>
          <Popup
            trigger={<span>{label}</span>}
            content={label}
            disabled={!showLabelPopup}
          />
          {required && <span className="required-indicator">*</span>}
          <PopUpInfo
            popUp={popUp}
            infoText={infoText}
            info={info}
          />
        </label>
      )}
      <div className="pos-rel">
        <Input
          id={id}
          className={`${disabled && 'custom-disabled'} ${(isInValid || error) && !disabled && 'error-field'} input-field`}
          readOnly={disabled}
          type={`${type === InputFieldType.PASSWORD && passwordShow ? InputFieldType.TEXT : getType(type)}`}
          placeholder={placeholder}
          data-test={dataTest}
          onKeyDown={
            type === InputFieldType.NUMBER
              ? (e: React.KeyboardEvent<HTMLInputElement>) =>
                  numberInputValidator(e, isDecimal || false, canBeNegative || false, internalValue)
              : () => {
                  null;
                }
          }
          value={renderValue(internalValue)}
          onChange={onChangeInternal}
          {...minAndMaxForTypeNumber}
        />
        {unit && <span className={`input-unit color-blue-dark-grayish`}>{unit}</span>}
        {required && (errorMessage || error) && (
          <div
            className="input-validation-message error p-t-xxs"
            data-test="feedback-error"
          >
            <Icon
              icon={Icons.Warning}
              className="m-r-xxs"
            />
            {errorMessage || error}
          </div>
        )}

        {type === InputFieldType.PASSWORD && !isInValid && value && successMessage && (
          <div
            className="input-validation-message success color-green d-flex"
            data-test="feedback-success"
          >
            <Image
              className="m-r-xxs"
              src={triangleCheck}
            />
            {successMessage}
          </div>
        )}

        {!disabled && type === InputFieldType.PASSWORD && (
          <div className="eye-icon">
            <Icon
              icon={`${passwordShow ? Icons.EyeOpen : Icons.EyeClosed16}`}
              onClick={() => handlePasswordShow()}
            />
          </div>
        )}
      </div>
    </Form.Field>
  );
};

InputField.displayName = 'InputField';

export default InputField;
