import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';
import { AdminEntitiesListingActionArgs } from './types';

interface ListEntitiesQueryVariables {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  sortOrder: SortOrder;
  entityFilter?: {
    nameIlike?: string;
  };
}

export const LIST_ENTITIES = (
  searchValue?: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const variables: ListEntitiesQueryVariables = {
    sortOrder: SortOrder.Asc,
    first: first,
    last: last,
    after: after,
    before: before,
    entityFilter: {
      nameIlike: searchValue || undefined,
    },
  };

  return {
    query: gql`
      query (
        $sortOrder: SortOrder!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $entityFilter: EntityFilter
      ) {
        listEntities(
          sortOrder: $sortOrder
          first: $first
          last: $last
          after: $after
          before: $before
          entityFilter: $entityFilter
        ) {
          edges {
            cursor
            node {
              id
              name
              activity
              identifier
              type
              contractCount
              propertyCount
              contacts {
                email
                firstName
                lastName
              }
              businessAddress
              insertedAt
              members {
                role {
                  name
                  approvalLevel
                }
                user {
                  id
                  firstName
                  lastName
                  email
                  countryCode
                  phoneNumber
                  photoUrl
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listEntities = createAsyncThunk<any, AdminEntitiesListingActionArgs, { state: RootState }>(
  'adminEntities/listEntities',
  async ({ first, last, after, before }: AdminEntitiesListingActionArgs, { getState }) => {
    const {
      adminEntities: { search },
    } = getState() as RootState;

    const response = await adminClient.query(LIST_ENTITIES(search, first, last, after, before));

    return response;
  },
);
