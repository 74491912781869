import { Form, Icon, Input } from 'semantic-ui-react';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonTime, initAnswerValue } from 'common/api/formatters';
import { numberInputValidator } from 'utils/utils-clean-input';
import { isValidTimeInput } from 'utils/utils-date';
import './TimeContent.scss';

interface TimeContentProps {
  value: AnswerJsonTime;
  onChange: (value: AnswerJsonTime) => void;
}

const TimeContent = ({ value, onChange }: TimeContentProps): JSX.Element => {
  const initValue: AnswerJsonTime = initAnswerValue(AnswerTypes.Time);

  const onChangeHandler = (changedValue: string, key: string): void => {
    if (key !== 'clear' && !isValidTimeInput(key, changedValue)) return;

    let timeCopy: AnswerJsonTime;

    if (key === 'clear') {
      timeCopy = initValue;
    } else {
      timeCopy = { ...value };
      // @ts-ignore
      timeCopy[key] = changedValue;
    }
    onChange(timeCopy);
  };

  const { hours, minutes } = value;

  return (
    <Form>
      <div className="time-content-wrapper">
        <div>
          <span className="time-content-label color-blue-very-dark">Hours</span>
          <Input
            placeholder="Hours"
            onChange={e => onChangeHandler(e.target.value, 'hours')}
            value={hours}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, false, false, hours)}
          />
        </div>
        <div>
          <span className="time-content-label color-blue-very-dark">Minutes</span>
          <div>
            <Input
              placeholder="Minutes"
              onChange={e => onChangeHandler(e.target.value, 'minutes')}
              value={minutes}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, false, false, minutes)}
            />
          </div>
        </div>
      </div>

      <span
        onClick={e => onChangeHandler('', 'clear')}
        className="parameters-questions-clear-data"
        role="button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default TimeContent;
