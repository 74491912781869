import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProvisionListingClone } from 'common/_classes';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getClientType } from 'utils/utils-authentication';
import { ProvisionFilters } from '.';

interface ProvisionFiltersProps extends PaginationAPIProps, ProvisionFilters {}

interface ListProvisionsQueryVariables {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  search: string | null;
  firstCategories?: number;
  language?: string;
  jurisdiction?: string;
  entityId?: string;
  useType?: string;
}

export const LIST_PROVISIONS_AND_CATEGORIES = (
  searchValue: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
  language?: string,
  jurisdiction?: string,
  entityId?: string,
  useType?: string,
  // eslint-disable-next-line max-params
) => {
  const firstCategories = 100;

  const variables: ListProvisionsQueryVariables = {
    first,
    last,
    after,
    before,
    search: searchValue,
    firstCategories,
    language,
    jurisdiction,
    entityId,
    useType,
  };

  return {
    query: gql`
      query (
        $first: Int
        $last: Int
        $after: String
        $before: String
        $search: String
        $firstCategories: Int
        $entityId: String
        $jurisdiction: String
        $language: String
        $useType: UseType
      ) {
        listProvisions: listProvisions(
          first: $first
          last: $last
          after: $after
          before: $before
          filter: {
            search: $search
            entityId: $entityId
            jurisdiction: $jurisdiction
            language: $language
            useType: $useType
          }
        ) {
          edges {
            cursor
            node {
              ${ProvisionListingClone.fragments()}
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
        listProvisionCategories: listProvisionCategories(first: $firstCategories) {
          edges {
            cursor
            node {
              id
              name
              color
              index
              provisions {
                id
              }
              insertedAt
              updatedAt
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listProvisionsAndCategories = createAsyncThunk(
  'provisions/listProvisionsAndCategories',
  async (
    { first, last, after, before, language, jurisdiction, entityId, useType }: ProvisionFiltersProps,
    { getState },
  ) => {
    const {
      provisionsListing: { search },
    } = getState() as RootState;
    const response = await getClientType().query(
      LIST_PROVISIONS_AND_CATEGORIES(search, first, last, after, before, language, jurisdiction, entityId, useType),
    );
    return response;
  },
);
