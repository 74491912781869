import { useAppDispatch } from 'hooks';
import { TEMPLATE_TYPES } from 'atoms/ConfirmPopup';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { abortTransaction, onReinstate, updateNotes } from 'store/transactions/transactionDetailSlice';
import { deleteTransaction, transactionAbortOrReinstate } from 'common/api/transactions';
import { TransactionPhaseStatus } from 'utils/UI';
import { checkIfTenantURL } from 'utils/utils-admin';
import { ColorNames } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { TransactionRow } from './TransactionsTableConfig';

interface TransactionRowActionButtonParams {
  row: TransactionRow;
  isAdmin: boolean;
  notes: string | null;
  userId: string | null;
  onViewDetails: ViewDetailsProps;
}

export const TransactionRowActionButtons = ({
  row,
  isAdmin,
  notes,
  userId,
  onViewDetails,
}: TransactionRowActionButtonParams) => {
  const dispatch = useAppDispatch();

  const isTenant = checkIfTenantURL();

  const onDelete = (id: string) => {
    dispatch(deleteTransaction({ id }));
  };

  const abortOrReinstate = (isAborted: boolean, transactionId: string): void => {
    const action = isAborted ? onReinstate : abortTransaction;
    dispatch(action({ userId }));
    dispatch(transactionAbortOrReinstate({ id: transactionId }));
  };

  const deleteButton = {
    icon: Icons.TrashFill,
    tooltip: 'Delete Transaction',
    onClick: () => onDelete(row.id),
    isPopup: true,
    popupText: 'Do you really want to delete this Transaction?',
    popupConfirmationButton: 'DELETE',
    dataTest: 'delete-button',
  };

  if (isAdmin) {
    return <TableActionButtons buttons={[deleteButton]} />;
  }

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Transaction',
      onClick: () => onViewDetails(row.id),
      dataTest: 'View Details',
    },
  ];

  if (isTenant) {
    return <TableActionButtons buttons={buttons} />;
  }

  const isAborted: boolean = row.status === TransactionPhaseStatus.Aborted;

  if (isAborted) {
    buttons.push({
      icon: Icons.ArrowCounterClockwise,
      tooltip: 'Reinstate',
      onClick: () => abortOrReinstate(isAborted, row.id),
      isPopup: true,
      popupText: 'Do you really want to reinstate this transaction?',
      popupConfirmationButton: 'REINSTATE TRANSACTION',
      dataTest: 'reinstate-button',
      templateData: [
        {
          templateType: TEMPLATE_TYPES.TEXTAREA,
          dataTest: 'abort-note',
          onChange: (key: string, value: string) => dispatch(updateNotes(value)),
          value: notes || '',
          fieldKey: 'note',
          label: 'Note',
          required: true,
        },
      ],
      color: ColorNames.GREEN,
    });
  } else {
    buttons.push({
      icon: Icons.Refuse,
      tooltip: 'Abort',
      onClick: () => abortOrReinstate(isAborted, row.id),
      isPopup: true,
      popupText: 'Do you really want to abort this transaction?',
      popupConfirmationButton: 'ABORT TRANSACTION',
      dataTest: 'abort-button',
      templateData: [
        {
          templateType: TEMPLATE_TYPES.TEXTAREA,
          dataTest: 'abort-note',
          onChange: (key: string, value: string) => dispatch(updateNotes(value)),
          value: notes || '',
          fieldKey: 'note',
          label: 'Note',
          required: true,
        },
      ],
      color: ColorNames.RED,
    });
  }

  buttons.push(deleteButton);

  return <TableActionButtons buttons={buttons} />;
};
