import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import CardParameters from 'components/CardParameters';
import PreviewTab from 'components/PreviewTab';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import { setSelectedProvisionFilter } from 'store/miscellaneous/miscellaneousSlice';
import { updateGroupName } from 'store/parametersTab/parametersTabSlice';
import { updateTransactionParamTab, updateTransactionViewDetailsTab } from 'store/transactions/transactionDetailSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { getLandlordTransaction, listPremises } from 'common/api/transactions';
import { HistoryGeneralProps } from 'common/api/transactions';
import { listTransactionApprovalAnswers } from 'common/api/transactions/approvals/listTransactionApprovalAnswers';
import ApprovalTab from './ApprovalTab';
import ContractView from './ContractView';
import History from './History';
import PostCompletion from './PostCompletion';
import ProvisionsTab from './ProvisionsTab';
import SetupTab from './SetupTab';

export enum TRANSACTION_TABS_OFFSET {
  SETUP = 0,
  PROVISIONS = 1,
  PARAMETERS = 2,
  PREVIEW = 3,
  APPROVAL = 4,
  PRE_COMPLETION = 5,
  POST_COMPLETION = 6,
  /*DOCUMENTS = 7,
  TEAM = 8,
  CHAT = 9,*/
  CONTRACT_VIEW = 7,
  PROGRESS = 8,
}

const getTabMenuConfig = (
  isCreateMode: boolean,
  showParametersForm: boolean,
  isDisabled: boolean,
  historyGeneral: HistoryGeneralProps[],
  countOfChanges: number,
): TabMenuProps[] => {
  const dispatch = useAppDispatch();

  const setParameterTab = () => {
    dispatch(updateTransactionParamTab({ status: false }));
    dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.TABLE_OF_CONTENTS));
  };

  return [
    {
      key: 'transaction-tab-setup',
      href: '?tab=setup',
      dataTest: 'setup-tab',
      label: 'Setup',
      customTab: <SetupTab />,
    },
    {
      key: 'transaction-tab-provisions',
      tabHeaderClass: isDisabled ? 'disabled-tab' : '',
      isDisabled,
      href: '?tab=provisions',
      dataTest: 'provisions-tab',
      label: 'Provisions',
      customTab: <ProvisionsTab />,
    },
    {
      key: 'transaction-tab-parameters',
      href: '?tab=parameters',
      dataTest: 'parameters-tab',
      label: 'Parameters',
      customTab: <CardParameters showParametersForm={showParametersForm} />,
      isDisabled,
      onClick: () => setParameterTab(),
    },
    {
      key: 'transaction-tab-preview',
      tabHeaderClass: isDisabled ? 'disabled-tab' : '',
      isDisabled,
      href: '?tab=preview',
      dataTest: 'preview-tab',
      label: 'Preview',
      customTab: <PreviewTab context={ExecuteContext.Transaction} />,
    },
    {
      key: 'transaction-tab-approval',
      href: '?tab=approval',
      dataTest: 'approval-tab',
      label: 'Approval',
      count: countOfChanges,
      customTab: <ApprovalTab />,
      isDisabled: isCreateMode,
    },
    {
      key: 'transaction-tab-history',
      isDisabled: isCreateMode || historyGeneral.length === 0,
      href: '?tab=history',
      dataTest: 'history-tab',
      label: 'History',
      customTab: <History />,
    },
    {
      key: 'transaction-tab-post-completion',
      isDisabled: isCreateMode /* || historyGeneral.length === 0*/,
      href: '?tab=post-completion',
      dataTest: 'post-completion-tab',
      label: 'Post-Completion',
      customTab: <PostCompletion />,
    },
    {
      key: 'transaction-tab-contract-view',
      href: '?tab=contract-view',
      dataTest: 'contract-view-tab',
      label: 'Overview',
      customTab: <ContractView />,
      isDisabled: isCreateMode,
    },
  ];
};

const TransactionsTabs = () => {
  const {
    showParametersForm,
    activeTransaction: { id: transactionId, historyGeneral },
    activeTabIndex,
    transactionApprovalAnswers,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { pathname } = useLocation();
  const isCreateMode = pathname.includes('create');

  const isDisabled = isCreateMode || historyGeneral.length === 0;

  const dispatch = useAppDispatch();

  const getCountOfChanges = () => {
    const statuses = [ValidApprovalStates.Pending, ValidApprovalStates.Approved, ValidApprovalStates.Rejected];
    return transactionApprovalAnswers.filter((answer: TransactionApprovalAnswer) =>
      statuses.includes(answer.approvalState),
    ).length;
  };

  const countOfChanges: number = getCountOfChanges();

  const onTabChange = (tab: string | number | undefined) => {
    if (!isCreateMode && transactionId && tab === TRANSACTION_TABS_OFFSET.SETUP) {
      dispatch(getLandlordTransaction({ id: transactionId })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(listPremises({ ownerId: null }));
        }
      });
    }
    dispatch(updateTransactionViewDetailsTab({ tab }));

    if (tab === TRANSACTION_TABS_OFFSET.PREVIEW) {
      dispatch(updateGroupName({ groupId: null, groupName: '' }));
    }

    if (tab === TRANSACTION_TABS_OFFSET.PARAMETERS) {
      dispatch(setSelectedProvisionFilter({ provisionId: null }));
    }
  };

  useEffect(() => {
    if (transactionId) {
      dispatch(listTransactionApprovalAnswers());
    }
  }, [transactionId]);

  return (
    <TabReact
      className="contract-page"
      activeTabIndex={activeTabIndex}
      customTabChangeAction={onTabChange}
      tabMenu={getTabMenuConfig(isCreateMode, showParametersForm, isDisabled, historyGeneral, countOfChanges)}
    />
  );
};

export default TransactionsTabs;
