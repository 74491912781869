enum SpaceType {
  Office = 'OFFICE',
  Storage = 'STORAGE',
  Retail = 'RETAIL',
  Industrial = 'INDUSTRIAL',
  FAndB = 'F_AND_B',
  Parking = 'PARKING',
  Common = 'COMMON',
  HallwaysCommon = 'HALLWAYS_COMMON',
  Toilet = 'TOILET',
  Staircase = 'STAIRCASE',
  Technical = 'TECHNICAL',
  Lift = 'LIFT',
  LiftLobby = 'LIFT_LOBBY',
  Restroom = 'RESTROOM',
  Facilities = 'FACILITIES'
}
export default SpaceType;
