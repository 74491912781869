import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ListTransactionProvisionsQueryVariables {
  transactionId: string | undefined;
}

const LIST_TRANSACTION_PROVISIONS = (transactionId: string | undefined) => {
  if (!transactionId) throw new Error('Get Transaction Provisions: transactionId is required');

  const variables: ListTransactionProvisionsQueryVariables = {
    transactionId,
  };

  return {
    query: gql`
      query ($transactionId: ID!) {
        listTransactionProvisions(transactionId: $transactionId) {
          id
          transaction {
            id
          }
          provision {
            id
            name
            index
            provisionCategory {
              id
              name
            }
            contents {
              content
              documentTypeId
            }
            description
            updatedAt
            guideline {
              explanatoryNotes
              generalGuidance
              internalNotes
              valuationGuidance
            }
          }
          mandatory
          selected
        }
      }
    `,
    variables,
  };
};

export const listTransactionProvisions = createAsyncThunk(
  'transactions/listTransactionProvisions',
  async ({ transactionId }: { transactionId: string | undefined }) => {
    const response = await client.query(LIST_TRANSACTION_PROVISIONS(transactionId));
    return response;
  },
);
