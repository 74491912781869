import { Dropdown } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import ExportModal from './ExportModal';
import { ExportTypes } from './ExportModal';
import './ExportDropdown.scss';

export interface ExportDropdownProps {
  className?: string;
  exportButtonText?: string;
  isExporting: boolean;
  exportFail: boolean;
  exportSuccess: boolean;
  rotateExportIcon?: number;
  disabled?: boolean;
  template?: JSX.Element;
  onExport: (args: { startDate?: string; endDate?: string; propertyId?: string }) => void;
}

const ExportDropdown = (props: ExportDropdownProps) => {
  const {
    onExport,
    isExporting,
    exportSuccess,
    exportFail,
    disabled,
    exportButtonText = 'Export',
    rotateExportIcon = 0,
    className = '',
    template,
  } = props;

  let option: JSX.Element;

  if (template) {
    option = template;
  } else {
    option = (
      <ExportModal
        type={ExportTypes.XLSX}
        onTryAgain={() => onExport({})}
        isExporting={isExporting}
        exportSuccess={exportSuccess}
        exportFail={exportFail}
        trigger={
          <Dropdown.Item onClick={() => onExport({})}>
            <div className="xlsx-option">
              <Icon
                icon={Icons.Excel}
                className="xlsx-icon"
              />
              .XLSX
            </div>
          </Dropdown.Item>
        }
      />
    );
  }

  return (
    <Dropdown
      className="export-dropdown"
      trigger={
        <div className={`export-dropdown-button ${className}`}>
          {exportButtonText}
          <Icon
            icon={Icons.Upload}
            className="export-icon"
            style={{
              transform: `rotate(${rotateExportIcon}deg)`,
            }}
          />
        </div>
      }
      icon={false}
      disabled={disabled}
    >
      <Dropdown.Menu direction="left">{option}</Dropdown.Menu>
    </Dropdown>
  );
};

export default ExportDropdown;
