import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import TableReactPrime from 'atoms/TableReactPrime';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { convertDateToISO } from 'utils/utils-date';
import { getFilteredSpaces } from 'utils/utils-property';
import { SpaceAndUnitRow, getSpaceAndUnitsFooter, unitColumnConfig } from './Table/SpacesAndUnitsTableConfig';
import './Table/SpacesAndUnitsTable.scss';

const SpaceCreatorTable = ({ mode }: ModeProps) => {
  const { activeFloor, filterDate } = useAppSelector((state: RootState) => state.propertyDetail);
  const dispatch = useAppDispatch();

  const [activeCell, setActiveCell] = useState({
    activeRow: null,
    activeColumn: null,
  });

  const disabled: boolean = mode === MODE_OPTIONS.READ;
  const dateFilter = convertDateToISO(filterDate);
  let spaces = getFilteredSpaces(dateFilter, activeFloor.spaces);
  spaces = mode === MODE_OPTIONS.READ ? orderBy(spaces, obj => Number(obj.name), 'asc') : spaces;
  const editableWithSpaces: boolean = mode === MODE_OPTIONS.EDIT && spaces.length > 0;
  const currentList: SpaceAndUnitRow[] = spaces.map((space, index) => new SpaceAndUnitRow(space, index));

  return (
    <div className={`space-table-container ${disabled || (editableWithSpaces && 'with-delete-btn')}`}>
      <h2 className="section-title m-t-sm">Units and Licensable Spaces</h2>
      <div className={`m-t-xl space-units-listing ${disabled && 'disabled-inputs'}`}>
        <TableReactPrime
          isFetching={false}
          content={currentList}
          dataTest="units-and-licensable-spaces-table"
          footerColumnGroup={getSpaceAndUnitsFooter(spaces, disabled)}
          columnConfig={unitColumnConfig(activeCell, setActiveCell, dispatch, mode)}
        />
      </div>
    </div>
  );
};

export default SpaceCreatorTable;
