import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransactionListingClone } from 'common/_classes';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface ListLandlordTransactionsQueryVariables {
  currentLandlordEntityId: string;
  first: number | undefined;
  last: number | undefined;
  after: string | undefined;
  before: string | undefined;
  search: string | null;
  status: string | null;
}

const LIST_LANDLORD_TRANSACTIONS = (
  searchValue: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List Landlord Transactions: currentLandlordEntityId is required');

  const variables: ListLandlordTransactionsQueryVariables = {
    currentLandlordEntityId,
    first: first,
    last: last,
    after: after,
    before: before,
    search: searchValue,
    status: null,
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $search: String
        $status: String
      ) {
        listLandlordTransactions(
          currentLandlordEntityId: $currentLandlordEntityId
          first: $first
          last: $last
          after: $after
          before: $before
          filter: { search: $search, status: $status }
        ) {
          edges {
            cursor
            node {
              ${TransactionListingClone.fragments()}
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `,
    variables,
  };
};

interface ListLandlordTransactionsAllQueryVariables {
  currentLandlordEntityId: string;
  first: number;
  search: string;
  status: string;
}

export const LIST_LANDLORD_TRANSACTIONS_ALL = () => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List All Landlord Transactions: currentLandlordEntityId is required');

  const variables: ListLandlordTransactionsAllQueryVariables = {
    currentLandlordEntityId,
    first: 1000,
    search: '',
    status: '',
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $first: Int!
        $search: String!
        $status: String!
      ) {
        listLandlordTransactions(
          currentLandlordEntityId: $currentLandlordEntityId
          first: $first
          filter: { search: $search, status: $status }
        ) {
          edges {
            cursor
            node {
              ${TransactionListingClone.fragments()}
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listLandlordTransactions = createAsyncThunk(
  'transactions/listLandlordTransactions',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      transactionsListing: { search },
    } = getState() as RootState;

    const response = await getClientType().query(LIST_LANDLORD_TRANSACTIONS(search, first, last, after, before));
    return response.data;
  },
);
