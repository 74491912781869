import Decimal from 'decimal.js';
import TranslateToContract, { Parameter, ParameterGroup } from 'common/model/TranslateToContract';
import { OmitMultiple } from 'utils/UI';
import { IReviewStatusClone } from './formatter';

export interface ITranslateToContractDetailClone
  extends OmitMultiple<
    TranslateToContract,
    | 'id'
    | 'insertedAt'
    | 'updatedAt'
    | 'reviewStatus'
    | 'name'
    | 'description'
    | 'function'
    | 'index'
    | 'reviewFlag'
    | 'key'
  > {
  id: string;
  name: string | null;
  description: string | null;
  reviewFlag: boolean | null;
  function: string;
  index: Decimal;
  reviewStatus: IReviewStatusClone;
  // TO FIX
  parameters: any;
  parameterGroup: any;
  key?: string;
}

export class TranslateToContractDetailClone implements ITranslateToContractDetailClone {
  id: string;
  name: string | null;
  description: string | null;
  reviewFlag: boolean | null;
  function: string;
  index: Decimal;
  active: boolean;
  reviewStatus: IReviewStatusClone;
  parameters: Parameter[];
  parameterGroup: ParameterGroup[];

  constructor(input: ITranslateToContractDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.reviewFlag = input.reviewFlag;
    this.description = input.description;
    this.function = input.function;
    this.reviewFlag = input.reviewFlag;
    this.index = input.index;
    this.active = input.active;
    this.reviewStatus = input.reviewStatus;
    this.parameters = input.parameters;
    this.parameterGroup = input.parameterGroup;
  }

  static fragment() {
    return `
        id
          name
          description
          active
          index
          key
          parameters
          parameterGroup
          function
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
          insertedAt
          updatedAt
    `;
  }
}
