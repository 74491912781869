import Parameter from 'common/model/Parameter';

export type OptionTypes = 'GROUPS' | 'TABLES' | 'PARAMETERS' | 'VALUES' | 'CUSTOM';

export enum GROUP_TYPE {
  PARAMETERS = 'PARAMETERS',
  TABLES = 'TABLES',
  VALUES = 'VALUES',
  GROUPS = 'GROUPS',
  CUSTOM = 'CUSTOM',
}

export type OptionItem = {
  id: string;
  group: OptionTypes;
  label: string;
  value: string;
  custom?: { label: string; value: string };
  selection?: Partial<Parameter>;
  children?: OptionItem[];
};

export const OPTION_TYPES_SECTIONS: OptionTypes[] = [
  GROUP_TYPE.GROUPS,
  GROUP_TYPE.TABLES,
  GROUP_TYPE.PARAMETERS,
  GROUP_TYPE.VALUES,
  GROUP_TYPE.CUSTOM,
];

export const TARGET_GROUPS: OptionTypes[] = [GROUP_TYPE.VALUES, GROUP_TYPE.CUSTOM, GROUP_TYPE.PARAMETERS];
