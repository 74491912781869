import TabContainer from 'components/TabContainer';
import DevelopmentTable from '../Table/DevelopementsTable';
import DevelopmentHeaderToolbar from '../Utils/DevelopmentHeaderToolbar';
import './DashboardPage.scss';

const DevelopmentTab = () => {
  return (
    <TabContainer
      tabTitle={'Developments'}
      tabToolbar={<DevelopmentHeaderToolbar />}
    >
      <DevelopmentTable />
    </TabContainer>
  );
};

export default DevelopmentTab;
