import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface DeleteContractOfTransactionQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
}

const DELETE_CONTRACT_OF_TRANSACTION = (id: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) throw new Error('Delete Contract Of Transaction: currentLandlordEntityId is required');
  if (!id) throw new Error('Delete Contract Of Transaction: id is required');

  const variables: DeleteContractOfTransactionQueryVariables = {
    currentLandlordEntityId,
    transactionId: id,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $transactionId: ID!) {
        deleteContractOfTransaction(currentLandlordEntityId: $currentLandlordEntityId, transactionId: $transactionId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteContractOfTransaction = createAsyncThunk(
  'transactions/deleteContractOfTransaction',
  async (id: string) => {
    const response = await client.mutate(DELETE_CONTRACT_OF_TRANSACTION(id));
    return response;
  },
);
