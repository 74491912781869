import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import AnalysisContext from 'common/model/AnalysisContext';
import { ContextAnalysisData } from 'common/api/dashboard';
import { forecastingColumnConfig } from './ForecastingTableConfig';
import { ForecastingRow } from './ForecastingTableConfig';

const ForecastingTable = (): JSX.Element => {
  const { propertyData, portfolioData } = useAppSelector((state: RootState) => {
    const forcasts = state.forecasts;
    return {
      ...forcasts.propertyRevenueAnalysis,
      ...forcasts.portfolioRevenueAnalysis,
    };
  });

  const { context } = useAppSelector((state: RootState) => state.dashboard);

  const data: ContextAnalysisData[] = context === AnalysisContext.Property ? propertyData : portfolioData;

  const currentList: ForecastingRow[] = data.map(item => new ForecastingRow(item));

  return (
    <>
      <div>Notes: all currencies are in “HK$”​</div>
      <div className="m-t-xl">
        <TableReactPrime
          fetchContent={() => {}}
          isFetching={false}
          content={currentList}
          columnConfig={forecastingColumnConfig()}
        />
      </div>
    </>
  );
};

export default ForecastingTable;
