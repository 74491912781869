import { useAppDispatch } from 'hooks';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deleteEntity } from 'common/api/adminEntities';
import { Icons } from 'utils/utils-icons';

interface AdminEntitiesRowActionButtonParams {
  rowId: string;
}

export const AdminEntitiesRowActionButtons = ({ rowId }: AdminEntitiesRowActionButtonParams) => {
  const dispatch = useAppDispatch();

  const onDelete = (id: string) => {
    dispatch(deleteEntity({ id }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Entity?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
