import AnswerTypes from 'common/model/AnswerTypes';
import MParameter from 'common/model/Parameter';
import ParameterTypes from 'common/model/ParameterTypes';
import ReferenceTypes from 'common/model/ReferenceTypes';
import { OmitMultiple } from 'utils/UI';
import { GuidelineClone } from './Guideline';
import { ParameterChoiceBase } from './ParameterChoiceBase';
import { TParameterGroupPickName } from './ParameterGroupDetailClone';
import { ParameterTableColumnClone } from './ParameterTableDetailClone';

interface IParameterDetailClone
  extends OmitMultiple<
    OmitMultiple<MParameter, 'insertedAt' | 'updatedAt'>,
    //have null
    | 'id'
    | 'name'
    | 'question'
    | 'questionNote'
    | 'index'
    | 'type'
    | 'answerType'
    | 'unit'
    | 'choices'
    | 'inventoryFunction'
    | 'internalOriginId'
    //pick/omit
    | 'guideline'
    | 'parameterGroup'
    | 'parameterTableColumns'
  > {
  id: string | null;
  name: string | null;
  question: string | null;
  questionNote: string | null;
  index: number | null;
  type: ParameterTypes | null;
  answerType: AnswerTypes | null;
  unit: string | null;
  choices: ParameterChoiceBase[] | null;
  inventoryFunction: string | null;
  internalOriginId: string | null;
  guideline: GuidelineClone;
  parameterGroup: TParameterGroupPickName | null;
  parameterTableColumns: ParameterTableColumnClone[];
}

export class ParameterDetailClone implements IParameterDetailClone {
  id: string | null;
  name: string | null;
  question: string | null;
  questionNote: string | null;
  index: number | null;
  type: ParameterTypes | null;
  answerType: AnswerTypes | null;
  unit: string | null;
  choices: ParameterChoiceBase[] | null;
  hasInventory: boolean;
  inventoryFunction: string | null;
  provisionExistence: boolean;
  internalOriginFlag: boolean;
  internalOriginId: string | null;
  mandatoryChoice: boolean;
  guideline: GuidelineClone;
  parameterGroup: TParameterGroupPickName | null;
  parameterTableColumns: ParameterTableColumnClone[];

  constructor(input: IParameterDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.question = input.question;
    this.questionNote = input.questionNote;
    this.index = input.index;
    this.type = input.type;
    this.choices = input.choices;
    this.answerType = input.answerType;
    this.unit = input.unit;
    this.hasInventory = input.hasInventory;
    this.inventoryFunction = input.inventoryFunction;
    this.provisionExistence = input.provisionExistence;
    this.internalOriginFlag = input.internalOriginFlag;
    this.internalOriginId = input.internalOriginId;
    this.mandatoryChoice = input.mandatoryChoice;
    this.guideline = input.guideline;
    this.parameterGroup = input.parameterGroup;
    this.parameterTableColumns = input.parameterTableColumns;
  }

  static fragments(): string {
    return `
      id
      name
      question
      questionNote
      index
      type
      answerType
      unit
      hasInventory
      inventoryFunction
      provisionExistence
      internalOriginFlag
      internalOriginId
      mandatoryChoice
      choices {${ParameterChoiceBase.fragments()}}
      guideline {
        parameter {
          id
        }
        referenceType
        explanatoryNotes
        generalGuidance
        internalNotes
        valuationGuidance
      }
      parameterGroup {
        id
        name
      }
      parameterTableColumns {
        table {
          id
          name
        }
      }
    `;
  }

  static init(): IParameterDetailClone {
    return {
      id: null,
      name: null,
      type: ParameterTypes.Normal,
      answerType: null,
      question: null,
      questionNote: null,
      unit: null,
      index: null,
      internalOriginFlag: false,
      internalOriginId: null,
      parameterTableColumns: [],
      choices: [],
      guideline: GuidelineClone.init(ReferenceTypes.Parameter),
      hasInventory: false,
      inventoryFunction: null,
      parameterGroup: null,
      mandatoryChoice: false,
      provisionExistence: false,
    };
  }
}
