import { Button, Grid, Loader } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { deleteUnusedNodes } from 'common/api/nodes';
import './GenerateNodeIntegrityModal.scss';

interface GenerateNodeIntegrityModalProps {
  status: boolean;
  provisionId: string;
  setStatus: (value: boolean) => void;
}

const GenerateNodeIntegrityModal = ({ status, provisionId, setStatus }: GenerateNodeIntegrityModalProps) => {
  const dispatch = useAppDispatch();
  const { nodeIntegrityData, nodeIntegrityLoader } = useAppSelector((state: RootState) => state.provisionsListing);

  return (
    <ModalTemplate
      isOpen={status}
      onToggleModalStatus={setStatus}
      className="node-integrity-modal"
      title="Node Integrity Issues"
    >
      {nodeIntegrityLoader ? (
        <div className="loader-container">
          <Loader
            active={true}
            size="big"
          />
        </div>
      ) : (
        <Grid className="pm-none">
          {nodeIntegrityData && (
            <Grid.Row
              className="editor-to-html m-none"
              dangerouslySetInnerHTML={{
                __html: nodeIntegrityData,
              }}
            ></Grid.Row>
          )}

          <Grid.Row className="p-none btn-row">
            <Button
              className="btn grey-outline"
              onClick={() => setStatus(false)}
              data-test="category-cancel-button"
            >
              CANCEL
            </Button>
            <Button
              className="btn red-bg"
              onClick={() => dispatch(deleteUnusedNodes(provisionId))}
              data-test="category-delete-button"
            >
              DELETE UNUSED NODES
            </Button>
          </Grid.Row>
        </Grid>
      )}
    </ModalTemplate>
  );
};

export default GenerateNodeIntegrityModal;
