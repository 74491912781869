import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewDropdownProps, PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { DropdownItem } from 'atoms/DropdownMenu';
import 'views/provisions/Dashboard/ProvisionsDashboard.scss';
import {
  createNewParameter,
  createNewTableParameter,
  updateParamViewSelectedGroup,
} from 'store/parameters/parameterDetailSlice';
import { resetSelectedGroup } from 'store/parameters/parametersListingSlice';
import {
  MODAL_MODE_OPTIONS,
  PARAMETERS_CLASSIFICATION_OPTIONS,
  PARAMETERS_DASHBOARD_VIEW_OPTIONS,
} from 'common/api/miscellaneous';
import { listParameterCategoriesAndGroups, listParameterTablesPage, listParameters } from 'common/api/parameters';
import CreateModal from './CreateModal';
import ParameterTablesListing from './ParameterTablesListing';
import ParametersCardsView from './ParametersCardsView';
import ParametersHeaderToolbar from './ParametersHeaderToolbar';
import ParametersListing from './ParametersListing';

interface TableProps {
  paramView: PARAMETERS_DASHBOARD_VIEW_OPTIONS;
  onSetModalStates: (props: SetModalStatesProps) => void;
}

const Table = ({ paramView, onSetModalStates }: TableProps): React.ReactElement => {
  switch (paramView) {
    case PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP:
      return <ParametersCardsView onSetModalStates={onSetModalStates} />;
    case PARAMETERS_DASHBOARD_VIEW_OPTIONS.PARAMETER:
      return <ParametersListing />;
    case PARAMETERS_DASHBOARD_VIEW_OPTIONS.TABLE:
      return <ParameterTablesListing />;
    default:
      return <>Unknown ParamType: {paramView}</>;
  }
};

export interface SetModalStatesProps {
  type: PARAMETERS_CLASSIFICATION_OPTIONS;
  mode: MODAL_MODE_OPTIONS;
}

const ParametersDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, paramView, paramViewIsNotGroup, search } = useAppSelector(
    (state: RootState) => state.parametersListing,
  );
  const { paramViewSelectedGroup } = useAppSelector((state: RootState) => state.parameterDetail);

  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [mode, setMode] = useState<MODAL_MODE_OPTIONS>(MODAL_MODE_OPTIONS.CREATE);
  const [modalType, setModalType] = useState<PARAMETERS_CLASSIFICATION_OPTIONS>(
    PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY,
  );

  useEffect(() => {
    if (paramView === PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP) {
      dispatch(listParameterCategoriesAndGroups());
    }
  }, [dispatch, paramView, paramViewIsNotGroup]);

  const onSetModalStates = ({ type, mode }: SetModalStatesProps): void => {
    setModalType(type);
    setMode(mode);
    setModalStatus(true);
  };

  const urlSearch: string = window.location.search;

  useEffect(() => {
    if (urlSearch === '') {
      dispatch(updateParamViewSelectedGroup({}));
    } else {
      if (!paramViewIsNotGroup) {
        const url = new URL(window.location.href);
        url.searchParams.delete('group');
        window.history.pushState({}, '', url);
      }
    }
  }, [urlSearch, paramViewIsNotGroup, dispatch]);

  const getPageHeaderProps = (): PageHeaderProps => {
    const onGoToParametersPage = () => {
      dispatch(resetSelectedGroup());
      dispatch(updateParamViewSelectedGroup({}));
      navigate('/parameters/dashboard');
    };

    const onCreateNewParameter = (): void => {
      navigate('/parameters/create');
      dispatch(createNewParameter());
      if (paramView === PARAMETERS_DASHBOARD_VIEW_OPTIONS.TABLE) {
        dispatch(listParameters({ searchValue: search, first: 10 }));
      }
    };

    const onCreateNewTableParameter = (): void => {
      navigate('/parameters/table/create');
      dispatch(createNewTableParameter());
      if (paramView === PARAMETERS_DASHBOARD_VIEW_OPTIONS.PARAMETER) {
        dispatch(listParameterTablesPage({ searchValue: search, first: 10 }));
      }
    };

    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Parameters',
        url: paramViewIsNotGroup ? '/parameters/dashboard' : undefined,
        onClick: paramViewIsNotGroup ? onGoToParametersPage : undefined,
      },
      {
        // @ts-ignore
        title: paramViewSelectedGroup?.name,
      },
    ];

    const dropdownItems: DropdownItem[] = [
      {
        key: '1',
        label: 'Category',
        onClick: () =>
          onSetModalStates({
            type: PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY,
            mode: MODAL_MODE_OPTIONS.CREATE,
          }),
      },
      {
        key: '2',
        label: 'Group',
        onClick: () =>
          onSetModalStates({
            type: PARAMETERS_CLASSIFICATION_OPTIONS.GROUP,
            mode: MODAL_MODE_OPTIONS.CREATE,
          }),
      },
      {
        key: '3',
        label: 'Parameter',
        onClick: () => onCreateNewParameter(),
        href: 'create',
      },
      {
        key: '4',
        label: ' Table of parameters',
        onClick: () => onCreateNewTableParameter(),
        href: 'table/create',
      },
    ];

    const createNewDropdown: CreateNewDropdownProps = {
      dropdownItems,
      dropdownText: 'CREATE',
      dataTest: 'create-button',
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <ParametersHeaderToolbar />,
      createNewDropdown,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      className="parameters-page"
      pageHeader={getPageHeaderProps()}
    >
      <Table
        paramView={paramView}
        onSetModalStates={onSetModalStates}
      />
      <CreateModal
        type={modalType}
        mode={mode}
        status={modalStatus}
        setModalStatus={setModalStatus}
      />
    </PageLayout>
  );
};

export default ParametersDashboard;
