import { Modal, Progress } from 'semantic-ui-react';
import { ExportTypes } from './index';

const ExportingContent = ({ type }: { type: ExportTypes }) => {
  return (
    <Modal.Content>
      <Modal.Description>
        <h4 className="title">Generating report in .{type}...</h4>
      </Modal.Description>

      <Progress
        percent={100}
        active={true}
        className="small"
      />
    </Modal.Content>
  );
};

export default ExportingContent;
