import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';

interface GetProvisionPDFDocumentQueryVariables {
  provisionId: string | undefined;
  documentTypeId: string | null;
  final: boolean;
}

const GET_PROVISION_PDF_HTML = (provisionId: string | undefined, documentTypeId: string | null) => {
  if (!provisionId) throw new Error('provisionId is required');
  if (!documentTypeId) throw new Error('documentTypeId is required');

  const variables: GetProvisionPDFDocumentQueryVariables = {
    provisionId,
    documentTypeId,
    final: false,
  };

  return {
    query: gql`
      query ($provisionId: ID!, $documentTypeId: ID!, $final: Boolean!) {
        getProvisionPdfHtml(provisionId: $provisionId, documentTypeId: $documentTypeId, final: $final)
      }
    `,
    variables,
  };
};

export const getProvisionPdfHtml = createAsyncThunk(
  'provisions/getProvisionPdfHtml',
  async (
    {
      provisionId,
      documentTypeId,
    }: {
      provisionId: string | undefined;
      documentTypeId?: string;
    },
    { getState },
  ) => {
    const {
      miscellaneous: { activeDocTypeId },
    } = getState() as RootState;
    const docId = documentTypeId ? documentTypeId : activeDocTypeId;
    const response = await adminClient.query(GET_PROVISION_PDF_HTML(provisionId, docId));
    return response;
  },
);
