import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { listLandlordAndTenantEntities } from 'common/api/entities';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { listLandlordProperties } from 'common/api/miscellaneous/listLandlordProperties';

const Parties = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const { landlordEntitiesOptions, tenantEntitiesOptions, activeContractEvent } = useAppSelector(
    ({ contractDetailV2 }: RootState) => contractDetailV2,
  );

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  useEffect(() => {
    dispatch(listLandlordAndTenantEntities());
  }, []);

  useEffect(() => {
    if (activeContractEvent?.ownerId) dispatch(listLandlordProperties(activeContractEvent?.ownerId));
  }, [activeContractEvent?.ownerId]);

  const onChange = (key: string, value: string) => dispatch(updateActiveContractEventInput({ key, value }));

  return (
    <div className="grid">
      <div className="col-3">
        <SelectField
          label="Landlord"
          fieldKey="ownerId"
          dataTest="landlord-input-field"
          value={activeContractEvent?.ownerId}
          disabled={true}
          required={required}
          options={landlordEntitiesOptions}
          onChange={onChange}
          search={true}
        />
      </div>
      <div className="col-3">
        <SelectField
          label="Tenant"
          fieldKey="tenantId"
          dataTest="tenant-input-field"
          value={activeContractEvent?.tenantId}
          disabled={disabled}
          required={required}
          options={tenantEntitiesOptions}
          onChange={onChange}
          search={true}
        />
      </div>
      <div className="col-3">
        <InputField
          label="Tenant Business Name"
          fieldKey="tenantId"
          dataTest="tenant-business-name-field"
          value={tenantEntitiesOptions.find(({ value }) => value === activeContractEvent?.tenantId)?.nickname || null}
          disabled={true}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

export default Parties;
