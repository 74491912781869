import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import FilesUpload from 'atoms/FormField/FilesUpload';
import ReviewBox, { ReviewBoxValues } from 'components/ReviewBox';
import {
  addSupportingFiles,
  deleteSupportingFiles,
  updatePropertyReviewedStatus,
} from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { prepPropertyDocsForUpload } from 'common/api/properties';
import BuildingInfo from './BuildingInfo';
import PropertyOwner from './PropertyOwner';
import PropertyPhoto from './PropertyPhoto';

interface PropertyInformationContentProps {
  mode: MODE_OPTIONS;
  setMode: (mode: MODE_OPTIONS) => void;
}

const PropertyInformationContent = ({ mode }: PropertyInformationContentProps) => {
  const { reviewFlag, reviewStatus, documents } = useAppSelector(
    (state: RootState) => state.propertyDetail.activeProperty,
  );

  const reviewBoxValues: ReviewBoxValues = {
    reviewFlag,
    reviewStatus,
  };

  return (
    <Form className="pm-none">
      <SectionComponent>
        <Grid>
          <Grid.Row className="p-none">
            <Grid.Column
              width={2}
              className="review-form p-none"
            >
              <SectionComponent>
                <ReviewBox
                  mode={mode}
                  values={reviewBoxValues}
                  onChange={updatePropertyReviewedStatus}
                />
              </SectionComponent>
            </Grid.Column>
            <Grid.Column
              width={1}
              className="pm-none"
            ></Grid.Column>
            <Grid.Column
              width={12}
              className="pm-none"
            >
              <PropertyOwner mode={mode} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className="p-none m-t-xl">
            <Grid.Column
              width={2}
              className="pm-none"
            >
              <h4 className="section-title">Property Photo</h4>
              <PropertyPhoto mode={mode} />
            </Grid.Column>
            <Grid.Column
              width={1}
              className="pm-none"
            ></Grid.Column>
            <Grid.Column
              width={12}
              className="pm-none"
            >
              <BuildingInfo mode={mode} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionComponent>
      <SectionComponent
        title="Files to upload"
        className="m-t-xl"
      >
        <Grid>
          <Grid.Row className="pm-none">
            <Grid.Column
              width={12}
              className="pm-none"
            >
              <FilesUpload
                disabled={mode === MODE_OPTIONS.READ}
                prepUpload={prepPropertyDocsForUpload}
                documents={documents}
                onDelete={deleteSupportingFiles}
                onAdd={addSupportingFiles}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionComponent>
    </Form>
  );
};

export default PropertyInformationContent;
