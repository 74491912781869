import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { ID } from 'common/api/miscellaneous';

interface DeleteContractQueryVariables {
  contractId: string;
}

const DELETE_CONTRACT2 = (id: string) => {
  if (!id) {
    throw new Error('Delete Contract: Contract ID could not be determined. Query aborted.');
  }

  const queryVariables: DeleteContractQueryVariables = {
    contractId: id,
  };

  return {
    mutation: gql`
      mutation ($contractId: ID!) {
        deleteContract2(contractId: $contractId) {
          id
        }
      }
    `,
    variables: queryVariables,
  };
};

export const deleteContract2 = createAsyncThunk('contractListingV2/deleteContract2', async ({ id }: ID) => {
  const response = await client.mutate(DELETE_CONTRACT2(id));
  return response;
});
