import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import LandlordEntitiesList from 'components/LandlordEntitiesList';
import { createNewEntity } from 'store/entities/entityDetailSlice';
import LandlordEntitiesHeaderToolbar from '../../../../components/LandlordEntitiesList/LandlordEntitiesHeaderToolbar';

const LandlordEntitiesDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean | undefined>();

  const getPageHeaderProps = (): PageHeaderProps => {
    const createNewLandlord = () => {
      dispatch(createNewEntity(null));
      navigate(`/landlord-entities/create?step=1`);
    };

    const breadCrumb: BreadCrumbItem[] = [{ title: 'Landlord Entities' }];

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <LandlordEntitiesHeaderToolbar />,
      // createNewButton: {
      //   btnText: 'CREATE LANDLORD ENTITY',
      //   url: '/landlord-entities/create?step=1',
      //   dataTest: 'create-landlord-entity',
      //   onCreateNew: createNewLandlord,
      // },
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <LandlordEntitiesList setIsLoading={setIsLoading} />
    </PageLayout>
  );
};

export default LandlordEntitiesDashboard;
