import { checkNotEmpty } from 'utils/tsValidator';
import { emailIsValid } from 'utils/utils-string';

export const signInValid = (email: string, password: string): boolean => {
  return emailIsValid(email) && checkNotEmpty(password);
};

export const signUpValid = (
  firstName: string,
  lastName: string,
  email: string,
  confirmEmail: string,
  newPasswordValid: boolean,
  newPasswordConfirmationValid: boolean,
): boolean => {
  return (
    checkNotEmpty(firstName) &&
    checkNotEmpty(lastName) &&
    emailIsValid(email) &&
    emailIsValid(confirmEmail) &&
    email === confirmEmail &&
    newPasswordValid &&
    newPasswordConfirmationValid &&
    newPasswordValid === newPasswordConfirmationValid
  );
};

export const createNewUserValidation = (
  firstName: string,
  lastName: string,
  email: string,
  userType: string,
): boolean => {
  return !checkNotEmpty(firstName) || !checkNotEmpty(lastName) || !emailIsValid(email) || !checkNotEmpty(userType);
};

export const userInfoValidation = (firstName: string, lastName: string, email: string): boolean => {
  return !checkNotEmpty(firstName) || !checkNotEmpty(lastName) || !emailIsValid(email);
};
