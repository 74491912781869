import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface DeleteFloorQueryVariables {
  currentLandlordEntityId: string;
  floorId: string;
}

const DELETE_FLOOR = (id: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) throw new Error('Delete Floor: No currentLandlordEntityId');
  if (!id) throw new Error('Delete Floor: No floorId');

  const variables: DeleteFloorQueryVariables = {
    currentLandlordEntityId,
    floorId: id,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $floorId: ID!) {
        deleteFloor(currentLandlordEntityId: $currentLandlordEntityId, floorId: $floorId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteFloor = createAsyncThunk('properties/deleteFloor', async (args: { id: string }) => {
  const response = await client.mutate(DELETE_FLOOR(args.id));
  return response;
});
