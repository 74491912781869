import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deletePortfolio } from 'common/api/portfolio';
import { Icons } from 'utils/utils-icons';

interface PortfolioRowActionButtonsParams {
  rowId: string;
  onViewDetails: ViewDetailsProps;
}

export const PortfolioRowActionButtons = ({ rowId, onViewDetails }: PortfolioRowActionButtonsParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = (id: string) => {
    dispatch(deletePortfolio({ id: id || '' }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Portfolio',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Portfolio',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Portfolio?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
