import { Grid } from 'semantic-ui-react';
import { ModeProps } from 'common/api/miscellaneous';
import SpaceCreatorTable from '../SpaceCreatorTable';

const SpacesAndUnits = ({ mode }: ModeProps) => {
  return (
    <Grid className="space-and-units-section spaces-units-filter m-t-s m-b-ml">
      <Grid.Row>
        <Grid.Column width={16}>
          <SpaceCreatorTable mode={mode} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SpacesAndUnits;
