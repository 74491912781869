import { Input } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import { OptionItem, TARGET_GROUPS } from 'utils/types/categories';
import { Icons } from 'utils/utils-icons';
import ShowParameter from './ShowParameter';
import './ParameterInput.scss';

/**
 * This Component shows 'input' OR 'selected option' used by ParameterMultiLevelSelect
 *
 * @param props.value OptionItem | Object with
 * @param props.placeholder String | Common placeholder for searching input
 * @param props.valueSelection DropdownItemProps[]  | Sequence of value already selected
 * @param props.showDropdown Boolean | Indicates if dropdown is shown or not
 * @param props.setShowDropdown Function | Callback function to open or close modal
 * @param props.setValueSelection Function | Callback to update or clean optionsSelect values
 * @param props.onSearching Function | Callback to update searching field
 */
export const ParameterInput = ({
  searching,
  placeholder,
  showDropdown,
  lastOptionMenu,
  setShowDropdown,
  onSearching,
}: ParameterInputProps) => {
  const lastItem = lastOptionMenu.length && lastOptionMenu[lastOptionMenu.length - 1];

  const hasSelection = lastItem && TARGET_GROUPS.includes(lastItem.group);

  return (
    <div className="parameter-input">
      {hasSelection ? (
        <ShowParameter
          handleRemove={() => setShowDropdown(true)}
          lastOptionMenu={lastOptionMenu}
        />
      ) : (
        <Input
          id="id-single-input"
          type={InputFieldType.TEXT}
          className="input-override"
          value={searching}
          placeholder={placeholder}
          onChange={({ target }) => {
            onSearching(target.value);
            setShowDropdown(true);
          }}
          onClick={() => setShowDropdown(true)}
        />
      )}
      <span
        className="wrap-icon"
        data-test="wrap-icon"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <WrapIcon showDropdown={showDropdown} />
      </span>
    </div>
  );
};

const WrapIcon = ({ showDropdown }: { showDropdown: boolean }) => {
  if (showDropdown) return <Icon icon={Icons.ChevUp} />;

  return <Icon icon={Icons.ChevDown} />;
};

interface ParameterInputProps {
  lastOptionMenu: OptionItem[];
  placeholder?: string;
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  searching: string;
  onSearching: (value: string) => void;
}
