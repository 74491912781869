import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import { checkNotEmpty } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';
import './Breadcrumb.scss';

export enum BREAD_CRUMB_SIZE {
  SMALL = 'small-size',
  MEDIUM = 'medium-size',
}

export interface BreadCrumbItem {
  url?: string;
  title: string | null;
  onClick?: () => void;
}

interface BreadCrumbProps {
  sections?: BreadCrumbItem[];
  size?: BREAD_CRUMB_SIZE;
}

const BreadCrumb = ({ sections, size = BREAD_CRUMB_SIZE.MEDIUM }: BreadCrumbProps) => {
  const navigate = useNavigate();

  const moveToPage = (url: string | undefined) => {
    navigate(url ? url : '');
  };

  const action = (e: React.SyntheticEvent<HTMLAnchorElement>, onClick?: () => void, url?: string) => {
    e.preventDefault();

    if (onClick) {
      onClick();
    } else {
      moveToPage(url);
    }
  };

  return (
    <Breadcrumb
      className="custom"
      data-test="back-to-listing-page"
    >
      {sections?.map(({ url, title, onClick }, index) => (
        <Fragment key={`breadcrumb-${index}`}>
          {index !== 0 && title && (
            <Breadcrumb.Divider
              icon={Icons.ChevronRight}
              className={`bread-crumb-divider color-blue-very-dark ${size}`}
            />
          )}

          <Breadcrumb.Section
            className={`breadcrumb-main color-blue-very-dark ${size} ${!checkNotEmpty(url) && !checkNotEmpty(onClick) ? 'disabled' : ''}`}
            onClick={e => action(e, onClick, url)}
            href={url}
            data-test="entity-title"
          >
            {title}
          </Breadcrumb.Section>
        </Fragment>
      ))}
    </Breadcrumb>
  );
};
export default BreadCrumb;
