import moment from 'moment';
import PopUpInfo from 'atoms/PopUpInfo';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import FloorUnitCharacteristics from 'components/FloorUnitCharacteristics';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { FloorPlanProps, FloorProps } from 'common/api/properties';
import { SpaceProps } from 'common/api/properties';
import { isValidNumber } from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import { FloorRowActionButtons } from './FloorRowActionButtons';

export class FloorRow {
  floor: FloorProps;
  id: string;
  name: string;
  grossAreaValue: number;
  lettableAreaValue: number;
  netAreaValue: number;
  saleableAreaValue: number;
  efficiencyRatio: number | null;
  characteristics: string[];
  ceilingHeight: string;
  floorLoading: string;
  floorPlanValue: null | DocumentIntendedToBeUploaded;
  numberOfUnits: number;
  comments: string;

  constructor(floor: FloorProps) {
    const latestFloorPlan = getLatestFloorPlan(floor.floorPlans);
    let floorPlanValue = null;

    if (latestFloorPlan) {
      floorPlanValue = latestFloorPlan.floorPlan;
    }
    const { grossAreaValue, lettableAreaValue, netAreaValue, saleableAreaValue, weightedAverageEfficiencyRatio } =
      getAreasAndWeightedEfficiencyRatio(floor.spaces);

    this.floor = floor;
    this.id = floor.id || '';
    this.name = floor.name;
    this.grossAreaValue = grossAreaValue;
    this.lettableAreaValue = lettableAreaValue;
    this.netAreaValue = netAreaValue;
    this.saleableAreaValue = saleableAreaValue;
    this.efficiencyRatio = getEfficiencyRatio(weightedAverageEfficiencyRatio);
    this.ceilingHeight = floor.ceilingHeight;
    this.characteristics = floor.characteristics;
    this.floorLoading = floor.floorLoading;
    this.floorPlanValue = floorPlanValue;
    this.numberOfUnits = numberOfUnits(floor.spaces);
    this.comments = floor.comments || '';
  }
}

const getEfficiencyRatio = (value: number): number | null => (value ? Number(value.toFixed(2)) : null);

const getLatestFloorPlan = (floorPlans: FloorPlanProps[]) => {
  if (floorPlans.length !== 0) {
    let today = new Date().getTime();
    let result;

    for (let i = 0; i < floorPlans.length; i++) {
      if (floorPlans[i]) {
        let currentRevisionDate: number | string | null = floorPlans[i].revisionDate;
        currentRevisionDate = new Date(currentRevisionDate || '').getTime();

        if (!result) {
          result = {
            index: i,
            timeInSecs: currentRevisionDate,
          };
          continue;
        } else {
          if (currentRevisionDate <= today) {
            if (currentRevisionDate > result.timeInSecs || result.timeInSecs > today) {
              result = {
                index: i,
                timeInSecs: currentRevisionDate,
              };
            }
          } else {
            if (currentRevisionDate < result.timeInSecs) {
              result = {
                index: i,
                timeInSecs: currentRevisionDate,
              };
            }
          }
        }
      }
    }
    return result ? floorPlans[result.index] : null;
  }
};

//TODO good candidate to have a similar function in utils-date.ts
const dateInRange = (activationDate: Date | null, deactivationDate: Date | null) => {
  const todaysDate = new Date();
  if (deactivationDate !== null) {
    if (todaysDate && activationDate && todaysDate >= activationDate && todaysDate <= deactivationDate) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const numberOfUnits = (spaces: SpaceProps[]) => {
  return spaces.filter(({ activationDate, deactivationDate }) =>
    dateInRange(activationDate ? new Date(activationDate) : null, deactivationDate ? new Date(deactivationDate) : null),
  ).length;
};

const isActiveSpace = (value: string | null, activationDate: Date | null, deactivationDate: Date | null): boolean => {
  if (
    (deactivationDate && moment().diff(deactivationDate, 'minutes') > 0) ||
    (activationDate && moment().diff(activationDate, 'minutes') < 0)
  ) {
    return false;
  }
  if (moment().diff(activationDate, 'minutes') < 0) {
    return false;
  }
  return isValidNumber(value);
};

const getAreasAndWeightedEfficiencyRatio = (spaces: SpaceProps[]) => {
  let netAreaValue = 0,
    saleableAreaValue = 0,
    grossAreaValue = 0,
    lettableAreaValue = 0,
    weightedEfficiencyRatio = 0;

  for (let i = 0; i < spaces.length; i++) {
    const { grossArea, lettableArea, netArea, saleableArea, activationDate, deactivationDate, efficiencyRatio } =
      spaces[i];

    const activationDateObject = activationDate ? new Date(activationDate) : null;
    const deactivationDateObject = deactivationDate ? new Date(deactivationDate) : null;

    grossAreaValue += isActiveSpace(grossArea, activationDateObject, deactivationDateObject)
      ? grossArea
        ? parseInt(grossArea)
        : 0
      : 0;

    lettableAreaValue += isActiveSpace(lettableArea, activationDateObject, deactivationDateObject)
      ? lettableArea
        ? parseInt(lettableArea)
        : 0
      : 0;

    netAreaValue += isActiveSpace(netArea, activationDateObject, deactivationDateObject)
      ? netArea
        ? parseInt(netArea)
        : 0
      : 0;

    saleableAreaValue += isActiveSpace(saleableArea, activationDateObject, deactivationDateObject)
      ? saleableArea
        ? parseInt(saleableArea)
        : 0
      : 0;

    weightedEfficiencyRatio +=
      (isActiveSpace(grossArea, activationDateObject, deactivationDateObject)
        ? grossArea
          ? parseInt(grossArea)
          : 0
        : 0) * (isValidNumber(efficiencyRatio) ? parseFloat(efficiencyRatio) * 100 : 0);
  }

  const weightedAverageEfficiencyRatio = grossAreaValue ? weightedEfficiencyRatio / grossAreaValue : 0;

  return {
    grossAreaValue,
    lettableAreaValue,
    netAreaValue,
    saleableAreaValue,
    weightedAverageEfficiencyRatio,
  };
};

/*
 * Definition of the column (names, format...)
 */
export const floorColumnConfig = (activeProperty: any): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Floor name',
      filterPlaceholder: 'Search by Floor name',
      filter: true,
      filterField: 'name',
      sortable: true,
    },
    {
      field: 'grossAreaValue',
      header: 'Gross area',
      filter: true,
      filterPlaceholder: 'Search by Gross area',
      filterField: 'grossAreaValue',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.grossAreaValue ? addSpaceOrComma(row.grossAreaValue, false) : '----'),
    },
    {
      field: 'lettableAreaValue',
      header: 'Lettable area',
      filterPlaceholder: 'Search by Lettable area',
      filter: true,
      filterField: 'lettableAreaValue',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.lettableAreaValue ? addSpaceOrComma(row.lettableAreaValue, false) : '----'),
      isHide: true,
    },
    {
      field: 'netAreaValue',
      header: 'Net area',
      filter: true,
      filterPlaceholder: 'Search by Net area',
      filterField: 'netAreaValue',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.netAreaValue ? addSpaceOrComma(row.netAreaValue, false) : '----'),
      isHide: true,
    },
    {
      field: 'saleableAreaValue',
      header: 'Saleable area',
      filter: true,
      filterPlaceholder: 'Search by Saleable area',
      filterField: 'saleableAreaValue',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.saleableAreaValue ? addSpaceOrComma(row.saleableAreaValue, false) : '----'),
      isHide: true,
    },
    {
      field: 'efficiencyRatio',
      columnKey: 'efficiency-ratio-header',
      filter: true,
      filterPlaceholder: 'Search by Efficiency ratio',
      filterField: 'efficiencyRatio',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.efficiencyRatio ? row.efficiencyRatio + '%' : ''),
      header: (
        <div key="Efficiency-ratio-header-popup">
          Efficiency ratio
          <PopUpInfo
            popUp={true}
            infoText="The efficiency ratio is a measure of the usable floor space relative to the total floor area of the building expressed as a percentage. E.g. on a floor of 1,000 sqft an efficiency ratio of 80% would mean that 800 sqft are available for lease and use by occupants."
          />
        </div>
      ),
    },
    {
      field: 'efficiencyRatio',
      header: 'Characteristics',
      filter: false,
      filterPlaceholder: 'Search by Characteristics',
      filterField: 'efficiencyRatio',
      sortable: false,

      body: (row: FloorRow) => (
        <FloorUnitCharacteristics
          id={row.id}
          characteristics={row.characteristics}
        />
      ),
    },
    {
      field: 'ceilingHeight',
      header: 'Ceiling Height (m)',
      filter: true,
      filterPlaceholder: 'Search by Ceiling Height',
      filterField: 'ceilingHeight',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.ceilingHeight ? addSpaceOrComma(row.ceilingHeight, false) : '----'),
    },
    {
      field: 'floorLoading',
      columnKey: 'floor-loading-header',
      header: (
        <div key="floor-loading-header-popup">
          Floor loading
          <PopUpInfo
            popUp={true}
            infoText="The floor loading capacity sets out the weight that a floor can support without causing structural damage or failure to the building."
          />
        </div>
      ),
      filter: true,
      filterPlaceholder: 'Search by Floor Loading',
      filterField: 'floorLoading',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.floorLoading ? addSpaceOrComma(row.floorLoading, false) : '----'),
    },
    {
      field: 'floorPlan',
      header: 'Floorplan',
      filter: false,
      filterPlaceholder: 'Search by Floorplan',
      sortable: false,
      body: (row: FloorRow) => {
        return row.floorPlanValue === null ? (
          '-'
        ) : (
          <a
            href={row.floorPlanValue?.url}
            target="_blank"
            rel="noreferrer"
            className="text name"
          >
            <span>{row.floorPlanValue?.name}</span>
          </a>
        );
      },
    },
    {
      field: 'numberOfUnits',
      header: 'Current number of units',
      filterPlaceholder: 'Search by Current number of units',
      filter: true,
      filterField: 'numberOfUnits',
      sortable: true,
      dataType: 'numeric',
      body: (row: FloorRow) => (row.numberOfUnits ? addSpaceOrComma(row.numberOfUnits, false) : '----'),
    },
    {
      field: 'comments',
      header: 'Comment',
      filter: true,
      filterPlaceholder: 'Search by Comment',
      filterField: 'comments',
      sortable: true,
      isHide: true,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: FloorRow) => (
        <FloorRowActionButtons
          row={row}
          activeProperty={activeProperty}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
