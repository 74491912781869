import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import MemberStatus from 'common/model/MemberStatus';
import CreateButton from './CreateButton';
import Steps from './WelcomeSteps';

const WelcomeContent = () => {
  const { members } = useAppSelector((state: RootState) => state.auth);
  const checkValidOrInvited = members.filter(
    (obj: { status: string }) => obj.status === MemberStatus.Valid || obj.status === MemberStatus.Invited,
  );
  const membersSize = checkValidOrInvited.length;
  return (
    <div className="left-content">
      <h2>Welcome to Sericin!</h2>
      {membersSize === 0 ? (
        <p>
          You have already created an account! You&apos;re almost there! All you have to do is create a landlord entity,
          because without it, it is impossible to manage your business, transactions, tenants and team.
        </p>
      ) : (
        <p>
          Your account has been created. You&apos;re almost there ! All you have to do is now accept the invitation from
          any of the invited or valid entities. You will then be able to take part of the management of tenants and
          contracts.
        </p>
      )}
      {membersSize === 0 && <Steps />}
      <CreateButton membersSize={membersSize} />
    </div>
  );
};

export default WelcomeContent;
