import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface DeletePropertyQueryVariables {
  currentLandlordEntityId: string;
  propertyId: string;
}

const DELETE_PROPERTY = (id: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) throw new Error('Delete Property: No currentLandlordEntityId');

  const variables: DeletePropertyQueryVariables = {
    currentLandlordEntityId,
    propertyId: id,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $propertyId: ID!) {
        deleteProperty(currentLandlordEntityId: $currentLandlordEntityId, propertyId: $propertyId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteProperty = createAsyncThunk('properties/deleteProperty', async (args: { id: string }) => {
  const response = await client.mutate(DELETE_PROPERTY(args.id));
  return response;
});
