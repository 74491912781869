import ParamRefOffset from 'common/model/ParamRefOffset';
import { GROUP_TYPE, OptionItem } from 'utils/types/categories';

/**
 * This file switch between Div and Input
 *
 * If multilevelMenu has a value, this value should be shown within a div
 *
 * If the selection is in progress, show a input and dropdown
 *
 * @param props.lastOptionMenu ObjectItem | Last item selected
 * @param props.handleRemove Function | Callback to remove the last OptionItem
 *
 */
const ShowParameter = ({ lastOptionMenu, handleRemove }: ShowParameterProps) => {
  const tables: OptionItem | undefined = lastOptionMenu.find(({ group }) => group === GROUP_TYPE.TABLES);
  const parameters: OptionItem | undefined = lastOptionMenu.find(({ group }) => group === GROUP_TYPE.PARAMETERS);
  const values: OptionItem | undefined = lastOptionMenu.find(({ group }) => group === GROUP_TYPE.VALUES);

  const sequence = [];
  tables && sequence.push(tables);
  parameters && sequence.push(parameters);

  if (tables && values) {
    return (
      <div onClick={handleRemove}>
        {sequence.map(({ id, group, label, value }) => {
          return (
            <span key={id}>
              <b>{group === GROUP_TYPE.CUSTOM ? label : group.toLowerCase()}:</b>
              <span>{label.toLowerCase()}</span>
            </span>
          );
        })}

        {values?.custom?.value && (
          <span>
            <b>{values?.custom?.label}:</b>
            <span>({handleCustomValue(values?.custom?.value)})</span>
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div onClick={handleRemove}>
        <span>
          <span>{parameters?.label}</span>
        </span>
      </div>
    );
  }
};

const handleCustomValue = (customValue: string) => {
  let result = customValue;
  switch (customValue) {
    case ParamRefOffset.N:
      result = 'N';
      break;
    case ParamRefOffset.NMinus1:
      result = 'N-1';
      break;
    case ParamRefOffset.NPlus1:
      result = 'N+1';
      break;
  }
  return result;
};

interface ShowParameterProps {
  lastOptionMenu: OptionItem[];
  handleRemove: () => void;
}

export default ShowParameter;
