import MParameterNodeUse from 'common/model/ParameterNodeUse';
import MParameterTableUse from 'common/model/ParameterTableUse';
import MParameterUse from 'common/model/ParameterUse';

interface IParameterUse extends Omit<MParameterUse, 'translateToContracts'> {
  nodes: MParameterNodeUse[];
  tables: MParameterTableUse[];
  translateToContracts: (string | null)[];
}

export class ParameterUse implements IParameterUse {
  nodes: MParameterNodeUse[];
  tables: MParameterTableUse[];
  translateToContracts: (string | null)[];

  constructor(input: IParameterUse) {
    this.nodes = input.nodes;
    this.tables = input.tables;
    this.translateToContracts = input.translateToContracts;
  }

  static fragments(): string {
    return `
      nodes {
        id
        name
        type
        provision {
          id
          name
        }
      }
      tables {
        id
        name
        type
      }
      translateToContracts
    `;
  }
}
