enum PropertyType {
  Office = 'OFFICE',
  Industrial = 'INDUSTRIAL',
  Warehouse = 'WAREHOUSE',
  Retail = 'RETAIL',
  RAndD = 'R_AND_D',
  Residential = 'RESIDENTIAL',
  FAndB = 'F_AND_B',
  Parking = 'PARKING',
  MixedUse = 'MIXED_USE'
}
export default PropertyType;
