import WelcomeToSericin from 'views/authentication/WelcomeToSericin';
import LandingPage from 'views/landingPage';
import { isAuthenticated, isWelcome } from './redirects';

const WelcomeToSericinRoute = () => [
  {
    path: '/welcome',
    element: isWelcome(<WelcomeToSericin />),
  },
  {
    path: '/home',
    element: isAuthenticated(<LandingPage />),
  },
];

export default WelcomeToSericinRoute;
