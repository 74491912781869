import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentDateTimeISO } from 'utils/utils-date';

interface CreateTransactionParameterApprovalMutationVariables {
  params: {
    userId: string | null;
    dateSubmitted: string;
  };
}

const CREATE_TRANSACTION_PARAMETER_APPROVAL = (userId: string | null) => {
  const variables: CreateTransactionParameterApprovalMutationVariables = {
    params: {
      userId,
      dateSubmitted: getCurrentDateTimeISO(),
    },
  };

  return {
    mutation: gql`
      mutation ($params: TransactionParameterApprovalInput!) {
        createTransactionParameterApproval(params: $params) {
          id
          dateSubmitted
          user {
            id
            firstName
            lastName
            photoUrl
          }
        }
      }
    `,
    variables,
  };
};

export const createTransactionParameterApproval = createAsyncThunk(
  'transactions/createTransactionParameterApproval',
  async (_args, { getState }) => {
    const {
      auth: { id: userId },
    } = getState() as RootState;

    const response = await client.mutate(CREATE_TRANSACTION_PARAMETER_APPROVAL(userId));
    return response;
  },
);
