import { last } from 'lodash';
import { Icon } from '@iconify/react';
import { OptionItem } from 'utils/types/categories';
import { Icons } from 'utils/utils-icons';

/**
 * Component to show the go back button on top Dropdown content
 *
 * If there is a previews last option, then is showing
 *
 * @param props.lastOptionMenu Array | List of options selected
 * @param props.onGoBack Function | Callback function to go back
 */
export const GoBack = ({ lastOptionMenu, allOptionMenu, onGoBack }: GoBackProps) => {
  let lastOption = last(lastOptionMenu);

  if (!lastOption) return null;

  const { label } = lastOption;

  if (allOptionMenu.length <= 1) return null;

  return (
    <li
      className="go-back"
      data-test="go-back"
      onClick={onGoBack}
    >
      <span>
        <Icon
          icon={Icons.ChevLeft}
          className="go-back-icon"
        />
      </span>
      {label}
    </li>
  );
};

interface GoBackProps {
  allOptionMenu: OptionItem[][];
  optionMenu: OptionItem[];
  lastOptionMenu: OptionItem[];
  onGoBack: () => void;
}
