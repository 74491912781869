import GuideLineBoxes from 'components/GuideLineBoxes';
import { ProvisionWithType } from 'store/nodes/nodesSlice';

interface ProvisionTabprops {
  provisionData: ProvisionWithType | null;
}

const ProvisionTab = ({ provisionData }: ProvisionTabprops): JSX.Element => {
  return (
    <div className="m-t-ml">
      <p>{`${provisionData?.type}: ${provisionData?.name}`}</p>
      <div className="provision-guideline">
        <GuideLineBoxes
          guideline={provisionData?.guideline}
          posRight={false}
        />
      </div>
    </div>
  );
};

export default ProvisionTab;
