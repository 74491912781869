import { Image } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import './Avatar.scss';

export enum AvatarSize {
  Mini = 'mini-size',
  Small = 'small-size',
  Medium = 'medium-size',
}

interface AvatarProps {
  src: string | null;
  size?: AvatarSize;
  avatar?: boolean;
  circular?: boolean;
  icon?: string;
  className?: string;
}

const Avatar = ({
  src,
  avatar = true,
  circular = true,
  size,
  icon = Icons.UserOutlined,
  className = '',
}: AvatarProps) => {
  if (src) {
    return (
      <Image
        src={src}
        avatar={avatar}
        circular={circular}
        className={`avatar-image outlined ${size} ${conditionalClassName(className, className)}`}
      />
    );
  } else {
    return (
      <Icon
        className={`avatar-image-icon outlined ${size} ${
          circular && 'circular'
        } ${conditionalClassName(className, className)}`}
        icon={icon}
      />
    );
  }
};

export default Avatar;
