import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import AuthLayout from 'layouts/AuthLayout';
import NewPassword from './NewPassword';
import PasswordCreated from './PasswordCreated';
import SendResetEmail from './SendResetEmail';
import SentLink from './SentLink';
import '../authentication.scss';
import './PasswordReset.scss';

interface RenderStepProps {
  resetStep: PASSWORD_RESET_STEPS;
}

export enum PASSWORD_RESET_STEPS {
  ENTER_EMAIL = 1,
  RESET_EMAIL_SENT_RESPONSE = 2,
  PASSWORD_RESET_FORM = 3,
  PASSWORD_RESET_RESPONSE = 4,
}

const RenderStep = ({ resetStep }: RenderStepProps) => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');

  if (token !== null) {
    return <NewPassword />;
  } else if (resetStep === PASSWORD_RESET_STEPS.ENTER_EMAIL) {
    return <SendResetEmail />;
  } else if (resetStep === PASSWORD_RESET_STEPS.RESET_EMAIL_SENT_RESPONSE) {
    return <SentLink />;
  } else {
    return <PasswordCreated />;
  }
};

const PasswordReset = () => {
  const { isLoading, resetStep } = useAppSelector((state: RootState) => state.auth);
  return (
    <AuthLayout
      loading={isLoading}
      form={<RenderStep resetStep={resetStep} />}
    />
  );
};

export default PasswordReset;
