import { CreateMemberInput, TeamMember } from 'common/api/members';
import { checkNotEmpty } from 'utils/tsValidator';
import { emailIsValid } from 'utils/utils-string';

export const createTeamMemberValidation = (activeTeamMember: TeamMember | null) => {
  if (!activeTeamMember) return false;

  return (
    !checkNotEmpty(activeTeamMember.firstName) ||
    !checkNotEmpty(activeTeamMember.lastName) ||
    !checkNotEmpty(activeTeamMember.roleId) ||
    !emailIsValid(activeTeamMember.email)
  );
};

export const inviteUserValidation = ({ email, roleId }: CreateMemberInput) => {
  return !emailIsValid(email) || !checkNotEmpty(roleId);
};

export const createRoleValidation = ({ name, approvalLevel }: { name: string; approvalLevel: string }) => {
  return !checkNotEmpty(name) || !checkNotEmpty(approvalLevel);
};
