import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import AnswerTypes from 'common/model/AnswerTypes';

interface PrepTransactionImageAnswerQueryVariables {
  transactionId: string | undefined;
  fileName: string;
  mimeType: string;
}

const PREP_TRANSACTIONS_ANSWER_OF_TYPE_IMAGE_FOR_UPLOAD = (transactionId: string | undefined, file: any) => {
  if (!transactionId) throw new Error('Prep Transaction Answer Of Image Type For Upload: transactionId is required');

  const { name: fileName, type: mimeType } = file;

  const variables: PrepTransactionImageAnswerQueryVariables = {
    transactionId,
    fileName,
    mimeType,
  };

  return {
    query: gql`
      query ($fileName: String!, $mimeType: String!, $transactionId: ID!) {
        prepTransactionImageAnswer(transactionId: $transactionId, fileName: $fileName, mimeType: $mimeType) {
          cdnUrl
          uploadUrl
        }
      }
    `,
    variables,
  };
};

export const prepTransactionAnswerOfImageTypeForUpload = createAsyncThunk(
  'transactions/prepTransactionAnswerOfImageTypeForUpload',
  async ({ index }: { index: number }, { getState }) => {
    const {
      transactionDetail: {
        activeTransaction: { id },
        activeTransactionAnswers,
      },
    } = getState() as RootState;
    const answerIndex: number = activeTransactionAnswers.findIndex(
      ({ blockRef: answerIndex, answerType }) => answerType === AnswerTypes.Image && answerIndex === index,
    );

    const { file } = activeTransactionAnswers[answerIndex].answer;

    const response = await client.query(PREP_TRANSACTIONS_ANSWER_OF_TYPE_IMAGE_FOR_UPLOAD(id, file));

    return response;
  },
);
