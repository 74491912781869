import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import SortOrder from 'common/model/SortOrder';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListSubsidiaryEntitiesQueryVariables {
  sortOrder: SortOrder;
  first: number;
}

export const LIST_SUBSIDIARIES = () => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List Subsidiary Entities: currentLandlordEntityId is required');

  // @ts-ignore
  const variables: ListSubsidiaryEntitiesQueryVariables = {
    sortOrder: SortOrder.Asc,
    first: 100000,
  };

  return {
    query: gql`
      query ($sortOrder: SortOrder!, $first: Int) {
        landlord: listLandlordEntities(sortOrder: $sortOrder, first: $first) {
          edges {
            cursor
            node {
              id
              name
              nickname
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listSubsidiaries = createAsyncThunk('entitesListing/listSubsidiaries', async () => {
  const response = await client.query(LIST_SUBSIDIARIES());
  return response;
});
