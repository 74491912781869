import { checkNotEmpty } from 'utils/tsValidator';

interface checkPropertyStepsProps {
  entity?: { id: string | null };
  buildingName: string | null;
  buildingNumberOfFloors: number | null;
  buildingNumberOfUndergroundFloors?: number;
  area: { gross: number | null };
  type: string[];
  lotNumber: number | null;
  buildingGrade: string | null;
  address: string | null;
  measurementUnit: string | null;
  measurementMethod: string | null;
}

export const checkPropertySteps = ({
  entity,
  buildingName,
  buildingNumberOfFloors,
  buildingNumberOfUndergroundFloors,
  area,
  type,
  lotNumber,
  buildingGrade,
  address,
  measurementUnit,
  measurementMethod,
}: checkPropertyStepsProps) => {
  if (
    !checkNotEmpty(entity) ||
    !checkNotEmpty(buildingName) ||
    type.length === 0 ||
    !checkNotEmpty(lotNumber) ||
    !checkNotEmpty(buildingGrade) ||
    !checkNotEmpty(address) ||
    !checkNotEmpty(measurementUnit) ||
    !checkNotEmpty(measurementMethod) ||
    !checkNotEmpty(buildingNumberOfFloors) ||
    !checkNotEmpty(buildingNumberOfUndergroundFloors) ||
    !checkNotEmpty(area.gross)
  ) {
    return true;
  } else {
    return false;
  }
};
