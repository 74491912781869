import CharacteristicsTypes from 'common/model/CharacteristicsTypes';
import SpaceView from 'common/model/SpaceView';

export class CSpaceView {
  id: string;
  characteristics: CharacteristicsTypes[];
  spaceName: string;

  constructor(input: SpaceView) {
    this.id = input.id;
    this.characteristics = input.characteristics;
    this.spaceName = input.spaceName;
  }

  static fragment() {
    return `
    id
    characteristics
    spaceName
    `;
  }
}
