import { ChangeEvent, useRef } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './FileSelect.scss';

interface FileSelectProps {
  setSimulation: (isSimulation: boolean) => void;
  file: File | null;
  setFile: (file: File | null) => void;
}

const FileSelect = ({ setSimulation, file, setFile }: FileSelectProps) => {
  const uploadFileRef = useRef<HTMLInputElement>(null);

  const openUploaded = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    uploadFileRef.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      e.target.value = '';
    }
  };

  const handleFileDelete = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setFile(null);
    setSimulation(false);
  };

  return (
    <Dropdown.Item
      className="file-select"
      onClick={openUploaded}
    >
      <input
        ref={uploadFileRef}
        type="file"
        className="file-uploaded"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={handleFileChange}
      />

      <div
        aria-hidden="true"
        className="choose-text"
      >
        <Icon icon={Icons.Excel} />
        <span className="bold-text">Choose file..</span>
      </div>

      {file && (
        <div className="file-text-container color-blue-very-dark m-t-xs">
          <div className="file-text">{file.name}</div>

          <div
            className="file-cancel"
            onClick={handleFileDelete}
          >
            <Icon icon={Icons.Cross} />
          </div>
        </div>
      )}
    </Dropdown.Item>
  );
};

export default FileSelect;
