import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { CreateMemberInput } from './type';

interface CreateMemberQueryVariables {
  currentLandlordEntityId: string;
  params: CreateMemberInput;
}

export const CREATE_MEMBER = (newMember: CreateMemberInput) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Create Member: currentLandlordEntityId is required');

  const variables: CreateMemberQueryVariables = {
    currentLandlordEntityId,
    params: {
      email: newMember.email,
      roleId: newMember.roleId,
    },
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: CreateMemberInput!) {
        createMember(currentLandlordEntityId: $currentLandlordEntityId, params: $params) {
          id
          user {
            id
            photoUrl
            email
            firstName
            lastName
            phoneNumber
          }
          role {
            id
            name
            approvalLevel
          }
          entity {
            id
            name
          }
          status
        }
      }
    `,
    variables,
  };
};

export const createMember = createAsyncThunk('members/createMember', async (_arg, { getState }) => {
  const state = getState() as RootState;
  const members: any = state.members;
  const activeInvitedUser: any = members.activeInvitedUser;
  const response = await client.mutate(CREATE_MEMBER(activeInvitedUser));

  return response;
});
