import { ParameterChoiceBase } from 'common/_classes';
import { Fragment } from 'react';
import { Form } from 'semantic-ui-react';
import { orderBy } from 'lodash';

const SingleChoices = ({ choices }: { choices: ParameterChoiceBase[] }): JSX.Element => {
  const orderedChoices: ParameterChoiceBase[] = orderBy(choices, 'index', 'asc');
  return (
    <>
      {orderedChoices?.map((choice: ParameterChoiceBase, index) => (
        <Fragment key={index}>
          <Form.Radio
            className="radio-options"
            label={choice.text}
            name="surrenderRights"
            type="radio"
            disabled={true}
            value={choice.id}
          />
        </Fragment>
      ))}
    </>
  );
};

export default SingleChoices;
