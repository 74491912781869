import { ReactNode, useState } from 'react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './CardColumnHeader.scss';

export interface CardColumnHeaderProps {
  title?: string;
  showEdit?: boolean;
  onEdit?: () => void;
  showDelete?: boolean;
  onDelete?: () => void;
}

const CardColumnHeader = ({
  title,
  showEdit = true,
  onEdit,
  showDelete = false,
  onDelete,
}: CardColumnHeaderProps): JSX.Element => {
  const [showActions, updateShowActions] = useState<boolean>(false);

  return (
    <span
      className={`card-column-header ${(showEdit || showDelete) && 'hover-available'}`}
      onMouseEnter={() => updateShowActions(true)}
      onMouseLeave={() => updateShowActions(false)}
      data-test="card-column-header"
    >
      <div
        className="card-column-title color-black"
        data-test="card-column-title"
      >
        {title}
      </div>
      <div className={showActions ? 'show' : 'hide'}>
        <span
          className={showEdit ? 'show' : 'hide'}
          onClick={onEdit}
        >
          <Icon
            className="edit-button"
            icon={Icons.Edit}
            data-test="card-column-edit-button"
          />
        </span>
        <span
          className={showDelete ? 'show' : 'hide'}
          onClick={onDelete}
        >
          <Icon
            icon={Icons.Delete}
            data-test="card-column-delete-button"
          />
        </span>
      </div>
    </span>
  );
};

export default CardColumnHeader;
