import { IEntityClone } from 'common/_classes/entities';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import ConfirmPopup from 'atoms/ConfirmPopup';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import PhoneNumberField from 'atoms/FormField/PhoneInput';
import { deleteContact, updateContactInputKey } from 'store/entities/entityDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { invalidEmailText } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { emailIsValid } from 'utils/utils-string';

interface ContactPersonFormProps {
  contactIndex: number;
  mode: MODE_OPTIONS;
  entityData: IEntityClone;
  isChild?: boolean;
}

const ContactPersonForm = ({
  contactIndex,
  mode,
  entityData,
  isChild = false,
}: ContactPersonFormProps): JSX.Element => {
  const dispatch = useAppDispatch();

  // const { activeEntity } = useAppSelector((state: RootState) => state.entityDetail);
  const { contacts } = entityData;
  const { firstName, lastName, position, email, phone } = contacts[contactIndex];

  const onChange = (key: string, value: string | number) =>
    dispatch(updateContactInputKey({ key, value, index: contactIndex, isChild }));

  const checkIfValidEmail: boolean = emailIsValid(email);

  const onlyOneContact: boolean = contacts.length === 1;

  const onDeleteHandler = (): void => {
    if (onlyOneContact) return;

    dispatch(deleteContact({ index: contactIndex }));
  };

  return (
    <Grid className="m-t-sm">
      <Grid.Row>
        <Grid.Column
          width={10}
          className="pm-none"
        >
          <InputField
            label="First name"
            value={firstName}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="firstName"
            dataTest="contact-first-name"
          />

          <InputField
            label="Last name"
            value={lastName}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="lastName"
            dataTest="contact-last-name"
          />

          <InputField
            label="Email"
            type={InputFieldType.EMAIL}
            value={email}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="email"
            dataTest="contact-email"
            isInValid={!checkIfValidEmail}
            errorMessage={!checkIfValidEmail && invalidEmailText}
          />

          <InputField
            label="Job title/position"
            value={position}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="position"
            dataTest="contact-job-title"
          />

          <PhoneNumberField
            label="Phone number"
            placeholder="+852 8888 8888"
            value={phone === null ? '+852' : phone}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="phone"
            className="m-t-sm"
          />

          {mode === MODE_OPTIONS.EDIT && !onlyOneContact && (
            <ConfirmPopup
              trigger={
                <div className="delete-contact-btn color-red-soft m-t-sm p-xs">
                  <div>
                    <Icon icon={Icons.Delete} />
                    <span className="text">Delete this Contact Person</span>
                  </div>
                </div>
              }
              content="Do you want to delete this contact person?"
              confirmButtonText="Delete"
              onConfirm={onDeleteHandler}
              cancelButtonText="CANCEL"
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ContactPersonForm;
