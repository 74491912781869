import { MultiSelect as PrimeMultiSelect, MultiSelectProps as PrimeMultiSelectProps } from 'primereact/multiselect';
import './MultiSelect.scss';

const MultiSelect = ({ options = [], value, ...rest }: PrimeMultiSelectProps) => {
  return (
    <PrimeMultiSelect
      value={value}
      options={options}
      className="p-column-filter"
      {...rest}
    />
  );
};

export default MultiSelect;
