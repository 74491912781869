import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { Forecasts } from 'common/_classes/forecastsV2';
import { RootState } from 'store';

// Create async thunk for getForecasts
export const getForecastsList = createAsyncThunk('forecasts2/getForecasts', async (_, { rejectWithValue }) => {
  try {
    // This is a mock API response for testing purposes
    const mockData = [
      {
        id: '1',
        startDate: '2023-01-01T00:00:00Z',
        endDate: '2023-12-31T23:59:59Z',
        referenceId: 'Q1 2024/2025',
        duration: 10,
        buildingId: 'building1',
        buildingName: 'Building 1',
        marketRent: 1000,
        yoyRentGrowth: 0.02,
        voidMonths: 1,
        renewalRate: 0.8,
        effectiveDowntime: 0.5,
        newLeaseTerm: 12,
        newLeaseRentFree: 1,
        version: 1,
        userId: 'user1',
        createdDate: '2023-01-01T00:00:00Z',
        archived: false,
        insertedAt: '2023-01-01T00:00:00Z',
        updatedAt: '2023-01-01T00:00:00Z',
      },
      {
        id: '2',
        startDate: '2023-01-01T00:00:00Z',
        endDate: '2023-12-31T23:59:59Z',
        duration: 10,
        referenceId: 'Q1 2024/2025',
        buildingId: 'building2',
        buildingName: 'Building 2',
        marketRent: 1200,
        yoyRentGrowth: 0.025,
        voidMonths: 2,
        renewalRate: 0.75,
        effectiveDowntime: 0.75,
        newLeaseTerm: 24,
        newLeaseRentFree: 2,
        version: 1,
        userId: 'user2',
        createdDate: '2023-01-01T00:00:00Z',
        archived: false,
        insertedAt: '2023-01-01T00:00:00Z',
        updatedAt: '2023-01-01T00:00:00Z',
      },
    ];

    return mockData.map((item: any) => new Forecasts(item));
  } catch (error) {
    return rejectWithValue('Failed to fetch forecasts');
  }
});
