import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { ReviewStatusMutationType } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId, getReviewFlagAndReviewStatus } from 'utils/tsHelper';
import { uploadToAWS } from 'utils/utils-upload';

interface ActivePropertyType {
  photo: string | null;
  buildingName: string;
  type: string;
  lotNumber: string;
  description: string;
  buildingGrade: string;
  address: string;
  identification: string;
  measurementUnit: string;
  measurementMethod: string;
  documents: string[];
  buildingIdentifier: string;
  buildingNumberOfFloors: number;
  buildingNumberOfUndergroundFloors: number;
  area: {
    gross: number;
    grossDescription: string;
    net: number;
    netDescription: string;
    lettable: number;
    lettableDescription: string;
    saleable: number;
    saleableDescription: string;
  };
  reviewFlag: boolean;
  reviewStatus: ReviewStatusMutationType;
}

interface CreatePropertyQueryVariables {
  currentLandlordEntityId: string;
  params: ActivePropertyType;
}

const CREATE_PROPERTY = (activeProperty: any, reviewFlag: boolean, reviewStatus: ReviewStatusMutationType) => {
  const {
    photo,
    buildingName,
    type,
    lotNumber,
    description,
    buildingGrade,
    address,
    identification,
    measurementUnit,
    measurementMethod,
    documents,
    buildingIdentifier,
    buildingNumberOfFloors,
    buildingNumberOfUndergroundFloors,
    area,
  } = activeProperty;

  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) throw new Error('CreateProperty: No currentLandlordEntityId found');

  const variables: CreatePropertyQueryVariables = {
    currentLandlordEntityId,
    params: {
      photo,
      buildingName,
      type,
      lotNumber,
      description,
      buildingGrade,
      address,
      identification,
      measurementUnit,
      measurementMethod,
      documents,
      buildingIdentifier,
      buildingNumberOfFloors,
      buildingNumberOfUndergroundFloors,
      area,
      reviewFlag,
      reviewStatus,
    },
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: PropertyInput!) {
        createProperty(currentLandlordEntityId: $currentLandlordEntityId, params: $params) {
          id
          entity {
            id
            name
          }
          photo
          buildingName
          buildingIdentifier
          buildingNumberOfFloors
          buildingNumberOfUndergroundFloors
          area {
            gross
            grossDescription
            net
            netDescription
            lettable
            lettableDescription
            saleable
            saleableDescription
          }
          type
          lotNumber
          buildingGrade
          address
          description
          identification
          measurementUnit
          measurementMethod
          floors {
            id
            name
            index
            comments
            characteristics
            ceilingHeight
            floorLoading
            activationDate
            deactivationDate
          }
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables,
  };
};

export const createProperty = createAsyncThunk('properties/createProperty', async (_arg, { getState }) => {
  const {
    propertyDetail: { file, uploadUrl, cdnUrl, deletePreviousPhoto, activeProperty, activePropertyFrozenCopy },
  } = getState() as RootState;

  const property = { ...activeProperty };

  if (file) {
    await uploadToAWS(uploadUrl, file);

    property.photo = cdnUrl;
  } else if (deletePreviousPhoto) {
    property.photo = null;
  }

  const [reviewFlag, reviewStatus] = getReviewFlagAndReviewStatus(activeProperty, activePropertyFrozenCopy);

  const response = await client.mutate(CREATE_PROPERTY(property, reviewFlag, reviewStatus));

  return response;
});
