import { useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import PopUpInfo from 'atoms/PopUpInfo';
import ModalTemplate from 'components/ModalTemplate';
import { updateGroupStatus } from 'views/transactions/CreatorViewer/Tabs/ApprovalTab/ApprovalChanges/ChangesBox/ChangesTable';
import { addToDocHistory } from 'store/transactions/transactionDetailSlice';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { createTransactionAnswers, updateTransaction, uploadSendToTenantPDF } from 'common/api/transactions';
import { updateExistenceParameterProvision } from 'common/api/transactions/approvals/updateExistenceParameterProvision';
import { createTransactionMessages } from 'common/api/transactions/createTransactionMessages';
import { DROPDOWN_OPTION } from 'utils/UI';
import { approvalRequestDisabled } from 'utils/tsValidator';
import './ConfigureSendOutToTenantModal.scss';

export interface ApprovalRequest {
  userId: string | null;
  tenantId: string | null | undefined;
  pendingChanges: boolean | null;
  note: string | null;
  documents: string[];
}

const ConfigureSendOutToTenantModal = () => {
  const dispatch = useAppDispatch();

  const { id: userId } = useAppSelector((state: RootState) => state.auth);
  const {
    modificationNotes,
    activeTransaction: { tenantId, documentTypeIds },
    transactionApprovalAnswers,
  } = useAppSelector((state: RootState) => state.transactionDetail);
  const { documentTypesOptions } = useAppSelector((state: RootState) => state.miscellaneous);

  const newApprovalRequest: ApprovalRequest = {
    userId: userId,
    tenantId,
    pendingChanges: null,
    note: null,
    documents: [],
  };

  const [open, setOpen] = useState<boolean>(false);
  const [approvalRequest, setApprovalRequest] = useState(newApprovalRequest);
  const { pendingChanges, note, documents } = approvalRequest;

  const updateApprovalRequest = (key: string, value: any) => {
    let copyApprovalRequest = { ...approvalRequest };
    // @ts-ignore
    copyApprovalRequest[key] = value;
    setApprovalRequest(copyApprovalRequest);
  };

  const approvalAnswerIds = transactionApprovalAnswers
    .filter((answer: TransactionApprovalAnswer) =>
      [ValidApprovalStates.Approved, ValidApprovalStates.Rejected].includes(
        answer.approvalState as ValidApprovalStates,
      ),
    )
    .map((answer: TransactionApprovalAnswer) => answer.id);

  const sendToTenant = async () => {
    setOpen(false);
    dispatch(addToDocHistory(approvalRequest));
    dispatch(updateTransaction({ approval: true })).then((response: any) => {
      // Send messages to external messages
      dispatch(createTransactionMessages());
      const approved: TransactionApprovalAnswer[] = transactionApprovalAnswers.filter(
        (answer: TransactionApprovalAnswer) => answer.approvalState === ValidApprovalStates.Approved,
      );

      const rejected: TransactionApprovalAnswer[] = transactionApprovalAnswers.filter(
        (answer: TransactionApprovalAnswer) => answer.approvalState === ValidApprovalStates.Rejected,
      );

      const approvedAnswerIds: string[] = approved.map((answer: TransactionApprovalAnswer) => answer.id);

      if (approvedAnswerIds.length !== 0) {
        dispatch(createTransactionAnswers({ approvedAnswerIds }));
      }

      const rejectedAnswerIds: string[] = rejected.map((answer: TransactionApprovalAnswer) => answer.id);

      if (rejectedAnswerIds.length !== 0) {
        dispatch(updateExistenceParameterProvision({ answerIds: rejectedAnswerIds }));
      }

      updateGroupStatus(approvalAnswerIds as string[], ValidApprovalStates.Completed, dispatch);
      dispatch(uploadSendToTenantPDF({ documentTypeIds: approvalRequest.documents }));
    });
    setApprovalRequest(newApprovalRequest);
  };

  const onModalStateUpdate = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const documentTypesOptionsFiltered: DROPDOWN_OPTION[] = documentTypesOptions.filter(doc =>
    documentTypeIds.some((docId: string) => docId === doc.value),
  );

  return (
    <ModalTemplate
      isOpen={open}
      onToggleModalStatus={onModalStateUpdate}
      className="approval configure-tenant-modal large"
      title="Configure send-out Tenant"
      showHeader={true}
      trigger={<Button className="btn grey-bg configure-tenant">Configure Sendout to Tenant</Button>}
    >
      <Form>
        <Grid className="pm-none">
          <Grid.Row className="p-none">
            <Grid.Column
              width={16}
              className="p-l-none"
            >
              <SelectField
                label="Which document(s) would you like to send?"
                placeholder="Select documents..."
                value={documents}
                multiple={true}
                options={documentTypesOptionsFiltered}
                onChange={updateApprovalRequest}
                info={true}
                required={true}
                fieldKey="documents"
              />
              <Form.Field className="field-style approval-type m-t-xs">
                <label>
                  Do you want to include changes that are still pending approval?
                  <span className="required-indicator">*</span>
                  <PopUpInfo
                    popUp={false}
                    info={true}
                  />
                </label>
                <Form.Radio
                  className="option-1"
                  label="No, send approved changes only"
                  name="approval-type"
                  type="radio"
                  onChange={() => updateApprovalRequest('pendingChanges', false)}
                  checked={pendingChanges === false}
                />
                <Form.Radio
                  className="option-2"
                  label="Yes, send pending changes and appoved changes"
                  name="approval-type"
                  type="radio"
                  onChange={() => updateApprovalRequest('pendingChanges', true)}
                  checked={pendingChanges === true}
                />
              </Form.Field>
              <TextAreaField
                className="cover-message"
                label="Cover message to tenant"
                required={true}
                value={note}
                onChange={updateApprovalRequest}
                fieldKey="note"
              />
            </Grid.Column>
          </Grid.Row>
          {modificationNotes && (
            <Grid.Row
              id="deviation-notes"
              className="editor-to-html m-l-none"
              dangerouslySetInnerHTML={{
                __html: modificationNotes,
              }}
            ></Grid.Row>
          )}

          <Grid.Row className="p-none btn-row m-t-m">
            <Button
              className="btn grey-outline"
              onClick={() => onModalStateUpdate(false)}
              data-test="category-cancel-button"
            >
              Save And Exit
            </Button>
            <Button
              className="btn red-bg"
              disabled={approvalRequestDisabled(approvalRequest)}
              onClick={sendToTenant}
              data-test="category-create-button"
            >
              Send To Tenant
            </Button>
          </Grid.Row>
        </Grid>
      </Form>
    </ModalTemplate>
  );
};

export default ConfigureSendOutToTenantModal;
