import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ToggleCheckbox from 'atoms/ToggleCheckbox';
import { updateBenchmarkInput } from 'store/benchmark/benchmarkDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const SpecialClauses = ({ mode }: ModeProps) => {
  const {
    activeBenchmark: {
      redevelopmentFlag,
      refusalFlag,
      renewFlag,
      subletFlag,
      surrenderFlag,
      expansionFlag,
      assignmentFlag,
    },
  } = useAppSelector((state: RootState) => state.benchmarkDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: any) => {
    dispatch(updateBenchmarkInput({ key, value }));
  };

  return (
    <div className="grid gap-4">
      <div className="flex align-items-left flex-grow-1 flex-column gap-3">
        <ToggleCheckbox
          classNames="special-rights-box"
          isDisabled={mode === MODE_OPTIONS.READ}
          label="Sale & redevelopment"
          dataTest="sale-redevelopment"
          fieldKey="redevelopmentFlag"
          value={redevelopmentFlag}
          onChange={onChange}
          popUp={true}
          infoText="A sale and redevelopment clause is a provision that allows the landlord to terminate the lease early if they wish to sell the property to a developer for redevelopment. This clause is often included in commercial lease agreements for properties that are located in areas that are undergoing significant redevelopment or where the landlord anticipates that the property may be attractive to developers in the future. The clause allows the landlord to take advantage of potential development opportunities while also giving the tenant some degree of protection and potentially compensation in the event of early termination."
        />
        <ToggleCheckbox
          classNames="special-rights-box"
          isDisabled={mode === MODE_OPTIONS.READ}
          label="Renewable rights"
          fieldKey="renewFlag"
          dataTest="renewable-rights"
          value={renewFlag}
          onChange={onChange}
          popUp={true}
          infoText={
            <>
              <p>
                {`A renewable rights clause is a provision that allows the
                  tenant to renew the lease for an additional term at the end of
                  the initial lease term. It typically outlines the conditions
                  and requirements for renewing the lease, such as giving the
                  landlord a certain amount of notice and agreeing to any rent
                  increases or changes in lease terms. The clause may also
                  specify the length of the renewal term and any restrictions or
                  limitations on the tenant's use of the property during the
                  renewal term.`}
              </p>
              <p>
                {`The inclusion of a renewable rights clause can be important
                  for tenants who wish to secure a long-term lease and avoid the
                  uncertainty and expense of relocating to a new property. The
                  clause also provides some degree of protection for tenants
                  against the landlord terminating the lease or significantly
                  changing the terms of the lease at the end of the initial
                  term. Landlords typically seek to limit the tenant's ability
                  to renew the lease or to negotiate higher rent or other
                  changes in lease terms.`}
              </p>
            </>
          }
        />
        <ToggleCheckbox
          classNames="special-rights-box"
          isDisabled={mode === MODE_OPTIONS.READ}
          label="Surrender rights"
          dataTest="surrender-rights"
          fieldKey="surrenderFlag"
          value={surrenderFlag}
          onChange={onChange}
          popUp={true}
          infoText={
            <>
              <p>{`A surrender right is a provision that allows the tenant to terminate the lease or part of the lease early by surrendering the premises or part of the premises to the landlord before the end of the lease term. The surrender right typically outlines the conditions and requirements for surrendering the property, such as giving the landlord a certain amount of notice and agreeing to any compensation or penalties for early termination.`}</p>
              <p>{`The inclusion of a surrender rights clause can be important for tenants who wish to terminate the lease early for various reasons, such as a change in business needs or financial difficulties. The clause allows the tenant to avoid potential penalties or legal disputes associated with breaking the lease without the landlord's consent. Landlords may seek to limit the tenant's ability to surrender the property early or to negotiate higher compensation or penalties for early termination.`}</p>
            </>
          }
        />
        <ToggleCheckbox
          classNames="special-rights-box"
          isDisabled={mode === MODE_OPTIONS.READ}
          label="Rights of refusal"
          fieldKey="refusalFlag"
          dataTest="rights-of-refusal"
          value={refusalFlag}
          onChange={onChange}
          popUp={true}
          infoText={
            <>
              <p>{`A right of refusal (also known as a right of first refusal) is a provision that gives the tenant the option to match or better any offer made by a third party to lease the premises. The right of refusal typically gives the tenant a certain amount of time to respond to a third-party offer, during which time the landlord is prohibited from leasing the property to the third party. If the tenant decides to exercise their right of refusal, they must typically meet the same terms and conditions as the third-party offer, including the rental rate.`}</p>
              <p>{`The inclusion of a right of refusal clause can be important for tenants who wish to maintain their occupancy of the premises and avoid potential disruptions or relocation costs associated with a new owner or tenant. The clause provides some degree of control over the disposition of the property and can help to ensure that the tenant's business interests are protected. Landlords may seek to limit the tenant's ability to exercise the right or to negotiate higher compensation or other conditions for relinquishing the right.`}</p>
            </>
          }
        />
      </div>
      <div className="flex align-items-right justify-content-top flex-grow-1 flex-column gap-3">
        <ToggleCheckbox
          classNames="special-rights-box"
          isDisabled={mode === MODE_OPTIONS.READ}
          label="Expansion rights"
          dataTest="expansion-rights"
          fieldKey="expansionFlag"
          value={expansionFlag}
          onChange={onChange}
          popUp={true}
          infoText={
            <>
              <p>{`An expansion right is a provision that grants the tenant the option to expand the leased premises to include additional space in the same building, property or development. The expansion right typically outlines the conditions and requirements for exercising the option, such as giving the landlord a certain amount of notice and agreeing to any rent increases or changes in lease terms. The clause may also specify the maximum amount of additional space that the tenant can lease and any restrictions or limitations on the tenant's use of the additional space.`}</p>
              <p>{`The inclusion of an expansion right can be important for tenants who anticipate future growth or expansion of their business and wish to secure additional space in the same location. The clause provides some degree of flexibility and control over the tenant's occupancy needs, and can help to avoid potential disruptions or relocation costs associated with moving to a new property. Landlords may seek to limit the tenant's ability to expand or to negotiate higher rent or other changes in lease terms for the additional space.`}</p>
            </>
          }
        />
        <ToggleCheckbox
          classNames="special-rights-box"
          isDisabled={mode === MODE_OPTIONS.READ}
          label="Sub let rights"
          dataTest="sub-let-rights"
          fieldKey="subletFlag"
          value={subletFlag}
          onChange={onChange}
          popUp={true}
          infoText={
            <>
              <p>{`A sublet right is a provision that allows the tenant to sublease all or part of the leased premises to a third party. The sublet right typically outlines the conditions and requirements for subletting, such as obtaining the landlord's consent and agreeing to any restrictions or limitations on the subletting arrangement. The clause may also specify the amount of rent that the tenant can charge the subtenant and the conditions under which the sublease can be terminated.`}</p>
              <p>{`The inclusion of a sublet right can be important for tenants who wish to offset their rental costs or avoid the burden of paying rent on unused space. The clause provides some degree of flexibility and control over the tenant's occupancy needs, and can help to avoid potential financial strains or operational inefficiencies associated with maintaining an underutilized space. Landlords may seek to limit the tenant's ability to sublet or to negotiate higher rent or other conditions for the sublease.`}</p>
            </>
          }
        />
        <ToggleCheckbox
          classNames="special-rights-box"
          isDisabled={mode === MODE_OPTIONS.READ}
          label="Assignment"
          fieldKey="assignmentFlag"
          dataTest="assignment"
          value={assignmentFlag}
          onChange={onChange}
          popUp={true}
          infoText={
            <>
              <p>{`An assignment clause is a provision that allows the tenant to transfer their rights and obligations under the lease to a third party. The assignment right typically outlines the conditions and requirements for assigning the lease, such as obtaining the landlord's consent and complying with any restrictions or limitations on the assignment. The clause may also specify the conditions under which the assignment can be terminated and any compensation or penalties associated with the assignment.`}</p>
              <p>{`The inclusion of an assignment right can be important for tenants who wish to transfer their lease obligations to another party, such as in the case of a business sale or restructuring. The clause provides some degree of flexibility and control over the tenant's occupancy needs, and can help to avoid potential legal disputes or liabilities associated with an unauthorized transfer of the lease. Landlords may seek to limit the tenant's ability to assign or to negotiate higher compensation or other conditions for the assignment.`}</p>
            </>
          }
        />
      </div>
    </div>
  );
};

export default SpecialClauses;
