import Decimal from 'decimal.js';
import RentReviewInput from 'common/model/RentReviewInput';
import RentReviewType from 'common/model/RentReviewType';
import { convertDateToISO } from 'utils/utils-date';

export interface RentReviewInputClone
  extends Omit<
    RentReviewInput,
    | 'cap'
    | 'collar'
    | 'effectiveRentPcm'
    | 'effectiveRentPsfPcm'
    | 'faceRentPcm'
    | 'faceRentPsfPcm'
    | 'increment'
    | 'newRent'
    | 'reviewDate'
    | 'type'
    | 'rentAgreementDate'
    | 'rentDeterminationDate'
    | 'vacancyRate'
  > {
  cap: Decimal | null;
  collar: Decimal | null;
  effectiveRentPcm: Decimal | null;
  effectiveRentPsfPcm: Decimal | null;
  faceRentPcm: Decimal | null;
  faceRentPsfPcm: Decimal | null;
  increment: Decimal | null;
  newRent: Decimal | null;
  rentAgreementDate: Date | null;
  rentDeterminationDate: Date | null;
  reviewDate: Date | null;
  type: RentReviewType | null;
  vacancyRate: Decimal | null;
}

export class CRentReview implements RentReviewInputClone {
  cap: Decimal | null;
  collar: Decimal | null;
  effectiveRentPcm: Decimal | null;
  effectiveRentPsfPcm: Decimal | null;
  faceRentPcm: Decimal | null;
  faceRentPsfPcm: Decimal | null;
  increment: Decimal | null;
  newRent: Decimal | null;
  rentAgreementDate: Date | null;
  rentDeterminationDate: Date | null;
  reviewDate: Date | null;
  type: RentReviewType | null;
  vacancyRate: Decimal | null;

  constructor(input: RentReviewInput | null) {
    this.cap = input && input.cap ? new Decimal(input.cap) : null;
    this.collar = input && input.collar ? new Decimal(input.collar) : null;
    this.effectiveRentPcm = input && input.effectiveRentPcm ? new Decimal(input.effectiveRentPcm) : null;
    this.effectiveRentPsfPcm = input && input.effectiveRentPsfPcm ? new Decimal(input.effectiveRentPsfPcm) : null;
    this.faceRentPcm = input && input.faceRentPcm ? new Decimal(input.faceRentPcm) : null;
    this.faceRentPsfPcm = input && input.faceRentPsfPcm ? new Decimal(input.faceRentPsfPcm) : null;
    this.increment = input && input.increment ? new Decimal(input.increment) : null;
    this.newRent = input && input.newRent ? new Decimal(input.newRent) : null;
    this.reviewDate = input && input.reviewDate ? new Date(input.reviewDate) : null;
    this.rentAgreementDate = input && input.rentAgreementDate ? new Date(input.rentAgreementDate) : null;
    this.rentDeterminationDate = input && input.rentDeterminationDate ? new Date(input.rentDeterminationDate) : null;
    this.type = input ? input.type : null;
    this.vacancyRate = input && input.vacancyRate ? new Decimal(input.vacancyRate) : null;
  }

  static fragment() {
    return `
        cap
        collar
        effectiveRentPcm
        effectiveRentPsfPcm
        faceRentPcm
        faceRentPsfPcm
        increment
        newRent
        reviewDate
        rentAgreementDate
        rentDeterminationDate
        type
        vacancyRate
    `;
  }

  static formatForAPI(review: CRentReview) {
    return {
      cap: review.cap ? parseFloat(review.cap.toString()) : null,
      collar: review.collar ? parseFloat(review.collar.toString()) : null,
      effectiveRentPcm: review.effectiveRentPcm ? parseFloat(review.effectiveRentPcm.toString()) : null,
      effectiveRentPsfPcm: review.effectiveRentPsfPcm ? parseFloat(review.effectiveRentPsfPcm.toString()) : null,
      faceRentPcm: review.faceRentPcm ? parseFloat(review.faceRentPcm.toString()) : null,
      faceRentPsfPcm: review.faceRentPsfPcm ? parseFloat(review.faceRentPsfPcm.toString()) : null,
      increment: review.increment ? parseFloat(review.increment.toString()) : null,
      newRent: review.newRent ? parseFloat(review.newRent.toString()) : null,
      reviewDate: convertDateToISO(review.reviewDate),
      rentAgreementDate: convertDateToISO(review.rentAgreementDate),
      rentDeterminationDate: convertDateToISO(review.rentDeterminationDate),
      type: review.type,
      vacancyRate: review.vacancyRate ? parseFloat(review.vacancyRate.toString()) : null,
    };
  }
}
