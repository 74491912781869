import { createSlice } from '@reduxjs/toolkit';
import { IFormatterClone } from 'common/_classes';
import { toast } from 'react-toastify';
import {
  deleteFormatter,
  executeListOfConditions,
  executeListOfFormatters,
  listFormatters,
} from 'common/api/formatters';

interface FormattersListingState {
  formattersList: IFormatterClone[];
  isLoading: boolean;
  search: string | null;
  isSearching: boolean;
  formattedAnswersList: FormatterAnswerList[];
  conditionAnswersList: ConditionAnswersList[];
}

export interface FormatterAnswerList {
  nodeId: string | null;
  result: string | null;
  error: string | null;
}

interface ConditionAnswersList {
  nodeId: string;
  result: string | null;
  error: string | null;
}

const initialState: FormattersListingState = {
  formattersList: [],
  isLoading: false,
  search: null,
  isSearching: false,
  formattedAnswersList: [],
  conditionAnswersList: [],
};

const formattersListingSlice = createSlice({
  name: 'formattersListing',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    resetFormattersSearch: state => {
      state.search = null;
    },
  },
  extraReducers: builder => {
    //deleteFormatter
    builder.addCase(deleteFormatter.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteFormatter.fulfilled, (state, action) => {
      state.formattersList = state.formattersList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Formatter deleted successfully');
    });
    builder.addCase(deleteFormatter.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });

    //listFormatters
    builder.addCase(listFormatters.pending, state => {
      if (state.search) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listFormatters.fulfilled, (state, action) => {
      state.formattersList = action.payload.data.listFormatters.edges.map(
        (element: { node: IFormatterClone }) => element.node,
      );
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listFormatters.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listFormatters API request rejected');
    });

    // TODO: why executeListOfFormatters and executeListOfConditions are here ?

    //executeListOfFormatters
    builder.addCase(executeListOfFormatters.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(executeListOfFormatters.fulfilled, (state, action) => {
      state.formattedAnswersList = action.payload.executeListOfFormatters;
      state.isLoading = false;
    });
    builder.addCase(executeListOfFormatters.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('executeListOfFormatters API request rejected');
    });

    //executeListOfConditions
    builder.addCase(executeListOfConditions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(executeListOfConditions.fulfilled, (state, action) => {
      state.conditionAnswersList = action.payload.executeListOfConditions;
      state.isLoading = false;
    });
    builder.addCase(executeListOfConditions.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('executeListOfConditions API request rejected');
    });
  },
});

export const { resetFormattersSearch, updateSearch } = formattersListingSlice.actions;

export default formattersListingSlice.reducer;
