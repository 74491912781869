import Node from 'common/model/Node';
import TransactionChannel from 'common/model/TransactionChannel';
import TransactionChannelStatus from 'common/model/TransactionChannelStatus';

type TTransactionChannelClone = Pick<TransactionChannel, 'id' | 'title' | 'status' | 'node'>;

export class TransactionChannelClone implements TTransactionChannelClone {
  id: string;
  title: string;
  status: TransactionChannelStatus;
  node: Node;

  constructor(input: TransactionChannelClone) {
    this.id = input.id;
    this.title = input.title;
    this.status = input.status;
    this.node = input.node;
  }

  static fragments(): string {
    return `
       id
       title
       status
       node {
         id
       }
    `;
  }
}
