import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterListClone } from 'common/_classes';
import AnswerTypes from 'common/model/AnswerTypes';

interface ListParametersWithAnswersQueryVariables {
  answerType: AnswerTypes;
}

const LIST_PARAMETERS_WITH_ANSWER_TYPE = (answerType: AnswerTypes) => {
  if (!answerType) {
    return {
      query: gql`
        query {
          listParametersWithAnswerType {
            ${ParameterListClone.fragments()}
          }
        }
      `,
    };
  }
  const variables: ListParametersWithAnswersQueryVariables = { answerType };
  return {
    query: gql`
      query ($answerType: AnswerTypes) {
        listParametersWithAnswerType(answerType: $answerType) {
          ${ParameterListClone.fragments()}
      }
  }
    `,
    variables,
  };
};

export const listParametersWithAnswerType = createAsyncThunk(
  'parameters/listParametersWithAnswerType',
  async (arg?: any) => {
    const answerType: AnswerTypes = arg && arg.answerType;
    const response = await adminClient.query(LIST_PARAMETERS_WITH_ANSWER_TYPE(answerType));
    return response;
  },
);
