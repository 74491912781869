import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { PASSWORD_RESET_STEPS } from 'views/authentication/PasswordReset';
import { PROFILE_TABS_OFFSET } from 'views/profile/Tabs/ProfileTabs';
import MemberStatus from 'common/model/MemberStatus';
import {
  adminSignIn,
  changeAdminPassword,
  changePassword,
  forgotPassword,
  getAdminProfile,
  getProfile,
  getSystemInfo,
  getUserByToken,
  prepUserPhotoForUpload,
  registerInvitedUser,
  resetPassword,
  signIn,
  signUp,
  updateAdminProfile,
  updateDefaultEntity,
  updateMemberInvitationStatus,
  updateProfile,
} from 'common/api/authentication';
import { MemberClone } from 'common/api/members';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

export interface AuthenticationInput {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  photoUrl: string;
  countryCode: string;
  phoneNumber: string;
  newPassword: string;
  newPasswordConfirmation: string;
  activeTabIndex: PROFILE_TABS_OFFSET;
  resetStep: PASSWORD_RESET_STEPS;
  members: MemberClone[];
  isLoading: boolean;
  disableFormSubmit: boolean;
  cdnUrl: string | null;
  uploadUrl: string | null;
  file: File | null;
  deletePreviousPhoto: boolean;
  version: string | null;
  buildDate: string | null;
  environment: string | null;
  gitVersion: string | null;
  id: string | null;
  defaultEntity: string | null;
  token: string | null;
}

const initialState: AuthenticationInput = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  password: '',
  photoUrl: '',
  countryCode: '',
  phoneNumber: '',
  newPassword: '',
  newPasswordConfirmation: '',
  activeTabIndex: PROFILE_TABS_OFFSET.NOTIFICATIONS,
  resetStep: PASSWORD_RESET_STEPS.ENTER_EMAIL,
  members: [],
  isLoading: false,
  disableFormSubmit: true,
  cdnUrl: null,
  uploadUrl: null,
  file: null,
  deletePreviousPhoto: false,
  version: null,
  buildDate: null,
  environment: null,
  gitVersion: null,
  id: null,
  defaultEntity: null,
  token: null, // Token saved here to identify the websocket communication
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateInput: (state, action) => {
      const type = action.payload.type;
      // @ts-ignore
      state[type] = action.payload.value;
    },
    deleteSelectedPhoto: state => {
      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
    },
    deletePhoto: state => {
      state.deletePreviousPhoto = true;
    },
    clearPasswords: state => {
      state.password = '';
      state.newPassword = '';
      state.newPasswordConfirmation = '';
    },
    toggleDisableFormSubmit: (state, action) => {
      state.disableFormSubmit = action.payload.status;
    },
    signOut: state => {
      state.members = [];
      localStorage.removeItem('sericin_app_token');
      localStorage.removeItem('sericin_selected_entity');
      state.token = null;
    },
    updateResetStep: (state, action) => {
      const { step } = action.payload;
      state.resetStep = step;
      state.firstName = '';
      state.lastName = '';
      state.email = '';
      state.confirmEmail = '';
      state.password = '';
      state.newPassword = '';
      state.newPasswordConfirmation = '';
    },
    updateResetPassword: state => {
      state.resetStep = PASSWORD_RESET_STEPS.ENTER_EMAIL;
      state.email = '';
      state.password = '';
    },
    updateActiveProfileTab: (state, action) => {
      state.activeTabIndex = action.payload.tab;
    },
    setToken: state => {
      state.token = localStorage.getItem('sericin_app_token');
    },
  },
  extraReducers: builder => {
    //signIn
    builder.addCase(signIn.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      const { token, user } = action.payload.data.login;
      localStorage.setItem('sericin_app_token', token);
      state.token = token;
      let selectedEntity = user.defaultEntity;
      selectedEntity = selectedEntity !== null ? selectedEntity.id : selectedEntity;
      if (selectedEntity !== null) {
        localStorage.setItem('sericin_selected_entity', selectedEntity);
      }
      state.id = user.id;
      state.members = user.members;
      state.password = '';
      state.isLoading = false;
      toast.success('User Logged in Successfully');
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Invalid Credentials');
    });

    //adminSignIn
    builder.addCase(adminSignIn.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(adminSignIn.fulfilled, (state, action) => {
      const { token, adminUser } = action.payload.data.adminLogin;
      localStorage.setItem('sericin_app_token', token);
      state.token = token;
      state.id = adminUser.id;
      state.password = '';
      state.isLoading = false;
      toast.success('User Logged in Successfully');
    });
    builder.addCase(adminSignIn.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Invalid Credentials');
    });

    //signUp
    builder.addCase(signUp.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      const { email } = action.payload.data.register;
      state.firstName = '';
      state.lastName = '';
      state.email = email;
      state.confirmEmail = '';
      state.newPassword = '';
      state.newPasswordConfirmation = '';
      state.isLoading = false;
      toast.success('User Registration Successful');
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('User already exists');
    });

    //registerInvitedUser
    builder.addCase(registerInvitedUser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(registerInvitedUser.fulfilled, (state, action) => {
      const { email } = action.payload.data.registerInvitedUser;
      state.firstName = '';
      state.lastName = '';
      state.email = email;
      state.confirmEmail = '';
      state.newPassword = '';
      state.newPasswordConfirmation = '';
      state.isLoading = false;
      toast.success('User Registration Successful');
    });
    builder.addCase(registerInvitedUser.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('User already exists');
    });

    //Change Admin Password
    builder.addCase(changeAdminPassword.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(changeAdminPassword.fulfilled, (state, action) => {
      const { token } = action.payload.data.changePassword;
      localStorage.setItem('sericin_app_token', token);
      state.token = token;
      state.password = '';
      state.newPassword = '';
      state.newPasswordConfirmation = '';
      state.disableFormSubmit = true;
      state.isLoading = false;
      toast.success('Password changed Successfully');
    });
    builder.addCase(changeAdminPassword.rejected, (state, action) => {
      let msg = 'changeAdminPassword API call rejected';
      if (action.error.message === 'Cannot update password of admin user') {
        msg = 'Current password is incorrect!';
      } else {
        console.error(action.error);
      }
      state.isLoading = false;
      toast.error(msg);
    });

    //Change Password
    builder.addCase(changePassword.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      const { token } = action.payload.data.changePassword;
      localStorage.setItem('sericin_app_token', token);
      state.token = token;
      state.password = '';
      state.newPassword = '';
      state.newPasswordConfirmation = '';
      state.disableFormSubmit = true;
      state.isLoading = false;
      toast.success('Password changed Successfully');
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      let msg = 'changePassword API call rejected';
      if (action.error.message === 'Cannot update password of user') {
        msg = 'Current password is incorrect!';
      } else {
        console.error(action.error);
      }
      state.isLoading = false;
      toast.error(msg);
    });

    //forgotPassword
    builder.addCase(forgotPassword.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      const { email } = action.payload.data.forgotPassword;
      state.email = email;
      state.resetStep = PASSWORD_RESET_STEPS.RESET_EMAIL_SENT_RESPONSE;
      state.isLoading = false;
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
      toast.error('Something went wrong when trying to reset your password.');
    });

    //resetPassword
    builder.addCase(resetPassword.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const { email } = action.payload.data.resetPassword;
      window.history.pushState({ path: '/reset-password' }, '', '/reset-password');
      state.email = email;
      state.resetStep = PASSWORD_RESET_STEPS.PASSWORD_RESET_RESPONSE;
      state.isLoading = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
      toast.error('Something went wrong when tring to reset your password');
    });

    //getProfile
    builder.addCase(getProfile.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      const { data, errors } = action.payload;

      if (errors && errors[0].message === 'Unauthorized') {
        state.members = [];
        localStorage.removeItem('sericin_app_token');
        localStorage.removeItem('sericin_selected_entity');
        window.location.href = '/sign-in';
        state.token = null;
      }
      const userData = data.getProfile;
      for (const key in userData) {
        // @ts-ignore
        state[key] = userData[key];
      }
      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
      state.deletePreviousPhoto = false;
      state.isLoading = false;

      if (userData.defaultEntity === null) {
        localStorage.removeItem('sericin_selected_entity');

        const { pathname } = window.location;

        if (!['/welcome', '/profile', '/landlord-entities/create'].includes(pathname)) {
          window.location.href = '/welcome';
        }
      } else {
        const selectedEntity = getCurrentLandlordEntityId();
        if (selectedEntity) {
          const member = userData.members.find((member: MemberClone) => selectedEntity === member.entity.id);
          const status = member?.status;
          if (status === MemberStatus.Revoked) {
            localStorage.removeItem('sericin_selected_entity');
            window.location.href = '/profile?tab=entities';
          }
        } else if (userData.defaultEntity) {
          localStorage.setItem('sericin_selected_entity', userData.defaultEntity.id);
        }
      }
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getProfile API request rejected');
    });

    //getAdminProfile
    builder.addCase(getAdminProfile.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      const { data, errors } = action.payload;

      if (errors && errors[0].message === 'Unauthorized') {
        state.members = [];
        localStorage.removeItem('sericin_app_token');
        window.location.href = '/sign-in';
        state.token = null;
      }
      const userData = data.getProfile;

      for (const key in userData) {
        // @ts-ignore
        state[key] = userData[key];
      }
      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
      state.deletePreviousPhoto = false;
      state.isLoading = false;
    });
    builder.addCase(getAdminProfile.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getAdminProfile API request rejected');
    });

    //getUserByToken
    builder.addCase(getUserByToken.pending, state => {
      state.isLoading = true;
    });

    builder.addCase(getUserByToken.fulfilled, (state, action) => {
      const user = action.payload.data.getUserByToken;
      if (user) {
        const email = action.payload.data.getUserByToken.email;
        state.email = email;
        state.confirmEmail = email;
      } else {
        toast.error('Registration link has expired');
      }
      state.isLoading = false;
    });

    builder.addCase(getUserByToken.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getUserByToken API request rejected');
    });

    //updateAdminProfile
    builder.addCase(updateAdminProfile.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateAdminProfile.fulfilled, (state, action) => {
      for (const key in action.payload.data.updateAdminProfile) {
        // @ts-ignore
        state[key] = action.payload.data.updateAdminProfile[key];
      }

      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
      state.deletePreviousPhoto = false;

      state.isLoading = false;

      toast.success('Profile updated Successfully');
    });
    builder.addCase(updateAdminProfile.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateAdminProfile API request rejected');
    });

    //updateProfile
    builder.addCase(updateProfile.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      for (const key in action.payload.data.updateProfile) {
        // @ts-ignore
        state[key] = action.payload.data.updateProfile[key];
      }

      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
      state.deletePreviousPhoto = false;

      state.isLoading = false;

      toast.success('Profile updated Successfully');
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateProfile API request rejected');
    });

    //updateDefaultEntity
    builder.addCase(updateDefaultEntity.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateDefaultEntity.fulfilled, (state, action) => {
      const { defaultEntity } = action.payload.data.updateDefaultEntity;
      let selectedEntity = defaultEntity !== null ? defaultEntity.id : defaultEntity;
      if (selectedEntity !== null) {
        localStorage.setItem('sericin_selected_entity', selectedEntity);
        state.defaultEntity = defaultEntity;
      }
      state.isLoading = false;
    });
    builder.addCase(updateDefaultEntity.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateDefaultEntity API request rejected');
    });

    //prepUserPhotoForUpload
    builder.addCase(prepUserPhotoForUpload.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(prepUserPhotoForUpload.fulfilled, (state, action) => {
      // @ts-ignore
      const file = action.meta.arg?.file;
      if (file) {
        state.file = file;
      }
      if (action.payload?.data?.prepUserPhoto) {
        const cdnUrl = action.payload.data.prepUserPhoto?.cdnUrl;
        const uploadUrl = action.payload.data.prepUserPhoto?.uploadUrl;
        state.cdnUrl = cdnUrl;
        state.uploadUrl = uploadUrl;
      }
      state.isLoading = false;
    });
    builder.addCase(prepUserPhotoForUpload.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('prepUserPhotoForUpload API request rejected');
    });

    //updateMemberInvitationStatus
    builder.addCase(updateMemberInvitationStatus.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateMemberInvitationStatus.fulfilled, (state, action) => {
      const { id, status } = action.payload.data.updateMemberInvitationStatus;
      const index = state.members.findIndex(member => member.id === id);
      const member = { ...state.members[index], status: status };
      state.members[index] = member;
      state.isLoading = false;
      toast.success('Member updated successfully.');
    });
    builder.addCase(updateMemberInvitationStatus.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateMemberInvitationStatus API request rejected');
    });

    //getSystemInfo
    builder.addCase(getSystemInfo.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getSystemInfo.fulfilled, (state, action) => {
      const { version, buildDate, environment, gitVersion } = action.payload.data.getSystemInfo;
      state.version = version;
      state.buildDate = buildDate;
      state.environment = environment;
      state.gitVersion = gitVersion;
      state.isLoading = false;
    });
    builder.addCase(getSystemInfo.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getSystemInfo API request rejected');
    });
  },
});

export const {
  updateInput,
  signOut,
  clearPasswords,
  toggleDisableFormSubmit,
  updateResetStep,
  updateResetPassword,
  updateActiveProfileTab,
  deleteSelectedPhoto,
  deletePhoto,
  setToken,
} = authSlice.actions;

export default authSlice.reducer;
