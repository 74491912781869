import './PrivacyAndTerms.scss';

const PrivacyAndTerms = () => {
  return (
    <div className="privacy-and-terms color-blue-very-dark">
      <div className="header-privacy ">
        <h1 className="m-none">Sericin App terms of use</h1>
        <h2 className="m-none">Last updated on 5 October 2022</h2>
      </div>
      <p>
        These terms of use (<b>&quot;Terms&quot;</b>) constitute a legally binding agreement made between you, whether
        personally or on behalf of an entity (<b>&quot;you&quot;</b>) and Sericin Limited (<b>&quot;Sericin&quot;</b>)
        concerning your access to and use of the{' '}
        <a
          href="https://app.sericin.net"
          className="privacy-link color-blue-dark"
        >
          https://app.sericin.net
        </a>{' '}
        website as well as any other media form, medial channel or mobile website related, linked or otherwise connected
        thereto (<b>&quot;Service&quot;</b>). You agree that by accessing the Service you have read, understood and
        agree to be bound by these Terms. If you do not agree with all of these Terms then you are expressly prohibited
        from using the Service and you must discontinue use immediately.
      </p>
      <h2>Intellectual property rights</h2>
      <p>
        Unless otherwise indicated, this Service, its proprietary content, features and functionality are and shall
        remain the exclusive property of Sericin and any licensors, and are protected by copyright and trademark laws
        and other intellectual property rights and unfair competition laws and international conventions.
      </p>
      <h2>User representations</h2>
      <div>
        <p>By using the Service, you represent and warrant that:</p>
        <p>(1) you have the legal capacity and you agree to comply with these Terms;</p>
        <p>(2) you will not access the Service through automated means such as scripts or bots;</p>
        <p>(3) you will not use the Service for any illegal or unauthorized purpose; and</p>
        <p>(4) your use of the Service will not violate any law or regulation applicable to you.</p>
      </div>
      <h2>Links to other websites</h2>
      <p>
        This Service may contain links to third-party websites or services that are not owned or controlled by Sericin
        and we do not investigate, monitor or check such sites or services. You agree and acknowledge that Sericin does
        not endorse the products or services offered on such third-party websites and Sericin assumes no responsibility
        for the content, privacy policies, practices of or services offered by such third-party websites. You
        acknowledge and agree that Sericin shall under no circumstances be responsible or liable, whether directly or
        indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on
        any such content, goods or services available on or through any such websites or services.
      </p>
      <p>
        Sericin recommends that you read the terms and conditions and privacy policies of any such third-party websites
        or services before using them, so you understand your rights and obligations relating to such third-party
        websites or services.
      </p>
      <h2>Modifications and interruptions</h2>
      <p>
        Sericin reserves the right to change, modify, or remove the contents of this Service or discontinue this Service
        at any time or for any reason at our sole discretion and without notice, but have no obligation to update any
        information provided via this Service. We shall not be liable to you or any third party for any modification,
        suspension, or discontinuance of this Service.
      </p>
      <p>
        We cannot guarantee this Service will be available at all times. We may experience hardware, software, or other
        problems or need to perform maintenance related to this Service, resulting in interruptions, delays, or errors.
        You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability
        to access or use this Service during any downtime or discontinuance of the Site.
      </p>
      <h2>Term, suspension and termination</h2>
      <p>
        These Terms shall remain in full force and effect while you use the Service. Sericin reserves the right, in our
        sole discretion and without notice or liability, to suspend access temporarily, deny access (including blocking
        of certain IP addresses) or terminate this Service, including, without limitation, in any cases of breach or
        potential breach of these Terms. We reserve the right, in our sole discretion and without notice, to monitor the
        Service for violations of these Terms, to take appropriate legal action and, without limitation, refuse,
        restrict access to and limit the availability of the Service to you.
      </p>
      <h2>Forward-Looking Statements</h2>
      <p>
        Certain statements included in the Service may be forward-looking. Such forward-looking statements are based on
        certain assumptions and reflect our current expectations. As a result, forward-looking statements are subject to
        a number of risks and uncertainties that could cause actual results or events to differ materially from current
        expectations. There is no assurance that any forward-looking statements will materialize. You are cautioned not
        to place undue reliance on forward-looking statements, which reflect expectations only as of the date of the
        Service. Except as may be required by applicable law, Sericin disclaims any intention or obligation to update or
        revise any forward-looking statements.
      </p>
      <h2>Disclaimer</h2>
      <p>
        You agree to use of this Service at your sole risk and Sericin provides this Service on an &quot;as is&quot; and
        &quot;as available&quot; basis. Sericin provides this Service without warranties of any kind, whether express or
        implied, including, but not limited to, warranties relating to merchantability, fitness for a particular
        purpose, non-infringement or course of performance.
      </p>
      <h2>Miscellaneous</h2>
      <p>
        Sericin reserves the right, at our sole discretion without notice and liability, to change, modify or replace
        these Terms at any time.
      </p>
      <p>
        By continuing to access or use this Service after such revisions come into effect, you agree to be bound by such
        revised terms. If you do not agree to the new terms, you are required to stop using this Service.
      </p>
      <p>We may assign any or all of our rights and obligations to others at any time.</p>
      <p>
        If any provision or part of a provision of these Terms is determined to be unlawful, void, or unenforceable,
        that provision or part of the provision is deemed severable from these Terms and does not affect the validity
        and enforceability of any remaining provisions.
      </p>
      <p>
        You agree that these Terms will not be construed against us by virtue of having drafted them and you hereby
        waive any and all defences you may have based on the electronic form of these Terms and the lack of signing by
        the parties hereto to execute these Terms
      </p>
      <h2>Governing law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the Hong Kong Special
        Administrative Region of the People&apos;s Republic of China, excluding its conflict of laws provisions.
      </p>
      <p>
        A failure to enforce any rights or provision of these Terms shall not be considered a waiver of those rights. If
        any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of
        these Terms shall remain in effect. These Terms constitute the entire agreement between Sericin and you
        regarding this Service, and supersede and replace any prior agreements Sericin and you might have regarding this
        Service.
      </p>
      <h2>Contact</h2>
      <p className="contact-box">
        If you have any questions or concerns about these Terms, please{' '}
        <a
          href="mailto:info@sericin.net"
          className="privacy-link color-blue-dark"
        >
          <u>contact us (info@sericin.net)</u>
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyAndTerms;
