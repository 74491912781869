import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import { updateActiveTableParameter } from 'store/parameters/parameterDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { listParameterTables, listParametersWithAnswerType } from 'common/api/parameters';
import { RowNumberType } from 'common/api/parameters';
import { DROPDOWN_OPTION } from 'utils/UI';
import { RowNumberProps } from '.';

interface RowSelectorProps {
  type: string;
  mode: MODE_OPTIONS;
}

const RowSelector = ({ type, mode }: RowSelectorProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeTableParameter } = useAppSelector((state: RootState) => state.parameterDetail);
  const { parameterNumberOptions, parameterTablesOptions } = useAppSelector(
    (state: RootState) => state.parametersListing,
  );

  const options: DROPDOWN_OPTION[] = type === RowNumberType.PARAMETER ? parameterNumberOptions : parameterTablesOptions;

  useEffect(() => {
    if (type === RowNumberType.TABLE) {
      dispatch(listParameterTables());
      dispatch(listParametersWithAnswerType({}));
    }
  }, [type, dispatch]);

  const onChange = (key: string, value: string) => dispatch(updateActiveTableParameter({ key, value, type }));

  const getValue = (): string | null => {
    if (!activeTableParameter.rowNumber) return null;
    const rowNumber: RowNumberProps = activeTableParameter.rowNumber;

    if (type === RowNumberType.PARAMETER) {
      return rowNumber.parameter ? rowNumber.parameter.id : null;
    } else {
      return rowNumber.table ? rowNumber.table.id : null;
    }
  };

  return (
    <Grid.Column width={14}>
      <SelectField
        label={`Select ${type}`}
        value={getValue()}
        disabled={mode === MODE_OPTIONS.READ}
        placeholder="Select ..."
        dataTest="select-constrain"
        options={options}
        required={mode === MODE_OPTIONS.EDIT}
        onChange={onChange}
        fieldKey="rowNumber"
        search={true}
      />
    </Grid.Column>
  );
};

export default RowSelector;
