import { checkNotEmpty } from 'utils/tsValidator';
import { DataType, RowAction, RowProps } from '../RowProps';

const valueCheck = (rowFilter: RowAction & RowProps, isDefaultFilter?: boolean) => {
  if (rowFilter.dataType === DataType.LIST) {
    return !rowFilter.filterValue || rowFilter.filterValue.length === 0;
  }

  if (rowFilter.dataType === DataType.PREMISE) {
    const { floorFilterMode, floorFilterValue, unitFilterMode, unitFilterValue } = rowFilter.filterValue || {};

    let disable = false;
    if (checkNotEmpty(floorFilterValue)) {
      disable = !checkNotEmpty(floorFilterMode);
    }

    if (checkNotEmpty(floorFilterMode) && !checkNotEmpty(floorFilterValue)) return true;

    if (checkNotEmpty(unitFilterValue)) {
      disable = !checkNotEmpty(unitFilterMode);
    }

    if (checkNotEmpty(unitFilterMode) && !checkNotEmpty(unitFilterValue)) return true;

    return disable;
  }

  return !checkNotEmpty(rowFilter.filterValue);
};

const modeAndValueCheck = (rowFilter: RowAction & RowProps, isDefaultFilter?: boolean) => {
  const modeIsEmpty = !checkNotEmpty(rowFilter.filterMode);
  let valueIsEmpty = false;

  if (rowFilter.dataType === DataType.DURATION) {
    const { years, months, days } = rowFilter.filterValue || {};
    valueIsEmpty = !checkNotEmpty(years) && !checkNotEmpty(months) && !checkNotEmpty(days);
  } else {
    valueIsEmpty = !checkNotEmpty(rowFilter.filterValue);
  }

  if (isDefaultFilter && modeIsEmpty && valueIsEmpty) return false;

  return modeIsEmpty || valueIsEmpty;
};

const mode2AndValue2Check = (rowFilter: RowAction & RowProps, isDefaultFilter?: boolean) => {
  const { filterLogicalOperator, filterValue2, filterMode2 } = rowFilter;

  const operatorIsEmpty = !checkNotEmpty(filterLogicalOperator);
  const modeIsEmpty = !checkNotEmpty(filterMode2);
  let valueIsEmpty = false;

  if (rowFilter.dataType === DataType.DURATION) {
    const { years, months, days } = rowFilter.filterValue2 || {};
    valueIsEmpty = !checkNotEmpty(years) && !checkNotEmpty(months) && !checkNotEmpty(days);
  } else {
    valueIsEmpty = !checkNotEmpty(filterValue2);
  }

  if (isDefaultFilter && operatorIsEmpty && modeIsEmpty && valueIsEmpty) return false;

  return (operatorIsEmpty && (!valueIsEmpty || !modeIsEmpty)) || (!operatorIsEmpty && (valueIsEmpty || modeIsEmpty));
};

export const disableApplyButton = (rowFilter: RowAction & RowProps, isDefaultFilter?: boolean) => {
  switch (rowFilter.dataType) {
    case DataType.BOOLEAN:
    case DataType.LIST:
    case DataType.PREMISE:
      return valueCheck(rowFilter, isDefaultFilter);

    case DataType.STRING:
      return modeAndValueCheck(rowFilter, isDefaultFilter);

    case DataType.NUMBER:
    case DataType.DECIMAL:
    case DataType.DATE:
    case DataType.DURATION:
      return modeAndValueCheck(rowFilter, isDefaultFilter) || mode2AndValue2Check(rowFilter, isDefaultFilter);
  }

  return false;
};
