import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import TransactionDiscussionApproval from 'common/model/TransactionDiscussionApproval';
import TransactionDiscussionApprovalInput from 'common/model/TransactionDiscussionApprovalInput';
import { checkNotEmpty } from 'utils/tsValidator';

interface updateTransactionDiscussionApprovalMutationVariables {
  transactionDiscussionApprovalId: string;
  params: TransactionDiscussionApprovalInput;
}

export const UPDATE_TRANSACTION_DISCUSSION_APPROVAL = (
  transactionDiscussionApproval: TransactionDiscussionApproval | undefined,
  draftMessage?: string,
) => {
  if (!transactionDiscussionApproval?.id)
    throw new Error('Update Transaction Discussion Approval: Transaction Discussion Approval ID is missing');

  const variables: updateTransactionDiscussionApprovalMutationVariables = {
    transactionDiscussionApprovalId: transactionDiscussionApproval.id,
    params: {
      userId: transactionDiscussionApproval.user.id,
      dateSubmitted: transactionDiscussionApproval.dateSubmitted,
      draftMessage: draftMessage
        ? draftMessage
        : checkNotEmpty(transactionDiscussionApproval.draftMessage)
          ? transactionDiscussionApproval.draftMessage
          : 'Reviewed',
    },
  };

  return {
    mutation: gql`
      mutation ($transactionDiscussionApprovalId: ID!, $params: TransactionDiscussionApprovalInput!) {
        updateTransactionDiscussionApproval(
          transactionDiscussionApprovalId: $transactionDiscussionApprovalId
          params: $params
        ) {
          id
          dateSubmitted
          user {
            id
            firstName
            lastName
            photoUrl
          }
          draftMessage
        }
      }
    `,
    variables: variables,
  };
};

export const updateTransactionDiscussionApproval = createAsyncThunk(
  'transactions/updateTransactionDiscussionApproval',
  async ({ approvalId, draftMessage }: { approvalId: string; draftMessage?: string }, { getState }) => {
    const {
      transactionDetail: { transactionDiscussionApprovals },
    } = getState() as RootState;
    const activeTransactionDiscussionApproval = transactionDiscussionApprovals.find(
      (obj: TransactionDiscussionApproval) => obj.id === approvalId,
    );

    const response = await client.mutate(
      UPDATE_TRANSACTION_DISCUSSION_APPROVAL(activeTransactionDiscussionApproval, draftMessage),
    );
    return response;
  },
);
