import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ListBenchmarksQueryVariables {
  benchmarkId: string;
}

export const GET_BENCHMARK = (id: string) => {
  if (!id) throw new Error('Get Benchmark: Benchmark ID is missing');

  const queryVariables: ListBenchmarksQueryVariables = {
    benchmarkId: id,
  };

  return {
    query: gql`
      query ($benchmarkId: ID!) {
        getBenchmark(benchmarkId: $benchmarkId) {
          id
          identifier
          address
          description
          size
          endDate
          startDate
          name
          industry {
            id
            name
          }
          rentPerArea
          rentFreePeriod
          securityAmount
          redevelopmentFlag
          assignmentFlag
          refusalFlag
          renewFlag
          subletFlag
          surrenderFlag
          expansionFlag
          hasCentralAc
          hasCurtainWall
          hasMetroCoveredAccess
          landlord {
            id
            name
          }
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: queryVariables,
  };
};

export const getBenchmark = createAsyncThunk('benchmarks/getBenchmark', async ({ id }: { id: string }) => {
  const response = await client.query(GET_BENCHMARK(id));
  return response;
});
