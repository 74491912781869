import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';

const WelcomeSteps = () => {
  return (
    <div className="steps">
      <div className="step">
        <div className="progress">
          <div className="progress-line"></div>
          <span className="step-selected">1</span>
        </div>
        <div className="inner">
          <span className="step-header">STEP</span>
          <div className="container">
            <span className="step-title">Create an account</span>

            <span className="step-selected">
              <Icon icon={Icons.Check}></Icon>
            </span>
          </div>
        </div>
      </div>

      <div className="step">
        <div className="progress">
          <div className="progress-line"></div>
          <span className="step-selected">2</span>
        </div>
        <div className="inner">
          <span className="step-header">STEP</span>
          <div className="container">
            <span className="step-title">Create landlord entity</span>

            <span className="empty-circle-icon"></span>
          </div>
        </div>
      </div>

      <div className="step">
        <div className="progress">
          <div className="progress-line"></div>
          <span className="step-selected">3</span>
        </div>
        <div className="inner">
          <span className="step-header">STEP</span>
          <div className="container">
            <span className="step-title">Start using Sericin</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSteps;
