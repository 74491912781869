import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { useState } from 'react';
import { SearchProps } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import CheckboxField from 'atoms/FormField/Checkbox';
import MultiSelect from 'atoms/FormField/MultiSelect';
import SearchBar from 'components/SearchBar';
import { NOTIFICATIONS_TABS_OFFSET } from 'store/notifications/notificationsSlice';
import {
  selectAllNotifications,
  unSelectAllNotifications,
  updateNotificationFilters,
  updateNotificationSearch,
} from 'store/notifications/notificationsSlice';
import NotificationType from 'common/model/NotificationType';
import { deleteMultipleNotifications, toggleArchiveMultipleNotifications } from 'common/api/notifications';
import { listNotifications } from 'common/api/notifications';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import {
  NotificationReadStatus,
  notificationsFilterReadOptions,
  notificationsFilterTypeOptions,
} from '../utils.notification';
import './NotificationFilter.scss';

const NotificationFilter = () => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const {
    selectedNotificationIds,
    notifications,
    typeFilter,
    readFilter,
    activeTabIndex,
    search,
    isSearching,
    archive,
  } = useAppSelector((state: RootState) => state.notifications);

  const isArchiveTab: boolean = activeTabIndex === NOTIFICATIONS_TABS_OFFSET.ARCHIVED;

  const toggleSelectAll = (_key: string, value: boolean) => {
    if (value) {
      dispatch(selectAllNotifications());
    } else {
      dispatch(unSelectAllNotifications());
    }
  };

  const onMultipleArchive = (archive: boolean) => {
    dispatch(toggleArchiveMultipleNotifications({ archive }));
  };

  const onMultipleDelete = () => {
    dispatch(deleteMultipleNotifications());
  };

  const updateSearchValue = (e: React.MouseEvent<HTMLElement>, { value }: SearchProps) => {
    dispatch(updateNotificationSearch(value));
    getNotifications();
  };

  const getNotifications = () => {
    dispatch(listNotifications({ first: 1000, loader: true }));
  };

  const onFilter = (value: NotificationType[] | NotificationReadStatus[], isTypeFilter: boolean) => {
    dispatch(updateNotificationFilters({ value, isTypeFilter }));

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(setTimeout(getNotifications, 700));
  };

  const atLeastOneSelected: boolean = selectedNotificationIds.length > 0;

  const allSelected: boolean = atLeastOneSelected && selectedNotificationIds.length === notifications.length;

  return (
    <div className="notification-filter-section">
      <CheckboxField
        fieldKey="select-all"
        onChange={toggleSelectAll}
        value={allSelected}
        className="m-b-none m-t-ml"
      />

      <SearchBar
        value={search}
        searching={isSearching}
        search={updateSearchValue}
        className="white-bg"
      />

      <MultiSelect
        value={typeFilter}
        options={notificationsFilterTypeOptions}
        onChange={(e: MultiSelectChangeEvent) => onFilter(e.value, true)}
        placeholder="Type filter"
        className="notification-filter"
      />

      <MultiSelect
        value={readFilter}
        options={notificationsFilterReadOptions}
        onChange={(e: MultiSelectChangeEvent) => onFilter(e.value, false)}
        placeholder="Read filter"
        className="notification-filter"
      />

      {atLeastOneSelected && (
        <div
          className="archive orange"
          onClick={() => onMultipleArchive(!isArchiveTab)}
        >
          <Icon
            className={`m-r-xs ${conditionalClassName(isArchiveTab, 'thicker')}`}
            icon={isArchiveTab ? Icons.ArrowCounterClockwise : Icons.Archive}
          />
          {isArchiveTab ? 'Unarchive ' : 'Archive '}
          selected ({selectedNotificationIds.length})
        </div>
      )}

      {atLeastOneSelected && archive && (
        <div
          className="delete red"
          onClick={onMultipleDelete}
        >
          <Icon
            className="m-r-xs"
            icon={Icons.Delete}
          />
          Delete ({selectedNotificationIds.length})
        </div>
      )}
    </div>
  );
};

export default NotificationFilter;
