import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const DatesForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContract } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const onChange = (key: string, value: string) => dispatch(updateActiveContractInput({ key, value: value || null }));

  const disabled = mode === MODE_OPTIONS.READ;

  return (
    <div className="grid">
      <div className="col-2">
        <DateField
          label="Date of binding offer letter"
          fieldKey="bindingOfferLetterDate"
          dataTest=""
          value={activeContract?.bindingOfferLetterDate || null}
          disabled={disabled}
          onChange={onChange}
          isClearable={true}
        />
      </div>
      <div className="col-2">
        <DateField
          label="Date lease signed by tenant"
          fieldKey="tenantSignatureDate"
          dataTest=""
          value={activeContract?.tenantSignatureDate || null}
          disabled={disabled}
          onChange={onChange}
          isClearable={true}
        />
      </div>
      <div className="col-2">
        <DateField
          label="Date tenant given possession"
          fieldKey="tenantPossessionDate"
          dataTest=""
          value={activeContract?.tenantPossessionDate || null}
          disabled={disabled}
          onChange={onChange}
          isClearable={true}
        />
      </div>
      <div className="col-2">
        <DateField
          label="Date of lease"
          fieldKey="leaseDate"
          dataTest=""
          value={activeContract?.leaseDate || null}
          disabled={disabled}
          onChange={onChange}
          isClearable={true}
        />
      </div>
    </div>
  );
};

export default DatesForm;
