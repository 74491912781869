import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import Parameter from 'common/model/Parameter';
import ParameterGroup from 'common/model/ParameterGroup';
import { ReviewStatusClone, ReviewStatusMutationType } from 'common/api/miscellaneous';
import { TranslateToContractClone } from 'common/api/translatorsToContract';
import { OmitMultiple } from 'utils/UI';
import { getReviewFlagAndReviewStatus } from 'utils/tsHelper';
import { replaceDoubleSlash } from 'utils/utils-string';

interface TranslateToContractCloneStringParams extends Omit<TranslateToContractClone, 'parameters' | 'parameterGroup'> {
  parameters: string;
  parameterGroup: string;
}

interface UpdateTranslateToContractQueryVariables {
  id: string;
  params: TranslateToContractCloneStringParams;
}

export const UPDATE_TRANSLATE_TO_CONTRACT = (
  activeTranslateToContract: TranslateToContractClone,
  reviewFlag: boolean,
  reviewStatus: ReviewStatusMutationType,
) => {
  const { id, name, description, active, index, parameterGroup, parameters } = activeTranslateToContract;

  if (!id) throw new Error('Update TranslateToContract: id is required');
  const params = {
    name,
    description,
    active,
    index,
    function: replaceDoubleSlash(activeTranslateToContract.function),
    reviewFlag,
    reviewStatus: reviewStatus as ReviewStatusClone,
    parameters: JSON.stringify(parameters || []),
    parameterGroup: JSON.stringify(parameterGroup || []),
  };
  const variables: UpdateTranslateToContractQueryVariables = {
    id: id,
    params: params,
  };
  return {
    mutation: gql`
      mutation ($id: ID!, $params: TranslateToContractInput!) {
        updateTranslateToContract(id: $id, params: $params) {
          id
          name
          description
          active
          index
          function
          reviewFlag
          parameters
          parameterGroup
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
          insertedAt
          updatedAt
        }
      }
    `,
    variables,
  };
};

export const updateTranslateToContract = createAsyncThunk(
  'translatorsToContract/updateTranslateToContract',
  async (args, { getState }) => {
    const {
      translatorDetail: { activeTranslateToContract, activeTranslateToContractFrozenCopy },
    } = getState() as RootState;

    const [reviewFlag, reviewStatus] = getReviewFlagAndReviewStatus(
      activeTranslateToContract,
      activeTranslateToContractFrozenCopy,
    );
    console.log('activeTranslateToContract: ', activeTranslateToContract);
    const response = await adminClient.mutate(
      UPDATE_TRANSLATE_TO_CONTRACT(activeTranslateToContract, reviewFlag, reviewStatus),
    );

    return response;
  },
);
