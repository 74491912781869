import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client, stagingClient } from 'apollo';
import { RootState } from 'store';
import { checkIfLocalEnv } from 'utils/tsHelper';
import { TransactionDocument } from './types';

const PREP_TRANSACTIONS_DOCS_FOR_UPLOAD = (documents: TransactionDocument[], id: string | undefined) => {
  if (!id) throw new Error('Prep Transaction Docs For Upload: transactionId is required');

  let prepFilesQuery = '';

  for (let i = 0; i < documents.length; i++) {
    const { name, type } = documents[i].document.file;

    prepFilesQuery += `
      prepTransactionDoc${documents[i].document.id}: prepTransactionDocument(transactionId: "${id}", fileName: "${name}", mimeType: "${type}"){
        cdnUrl
        uploadUrl
        path
      }
    `;
  }

  return {
    query: gql`
      query {
        ${prepFilesQuery}
      }
    `,
  };
};

export const prepTransactionDocsForUpload = createAsyncThunk(
  'transactions/prepTransactionDocsForUpload',
  async (args, { getState }) => {
    const {
      transactionDetail: {
        activeTransaction: { id, documents },
      },
    } = getState() as RootState;

    const filteredDocuments = documents.filter((doc: TransactionDocument) => doc.document.local);

    // Add the token you have on staging inside staging client to check file upload locally
    const clientType = checkIfLocalEnv() ? stagingClient : client;
    const response = await clientType.query(PREP_TRANSACTIONS_DOCS_FOR_UPLOAD(filteredDocuments, id));
    return response;
  },
);
