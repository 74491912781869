import { IEntityClone } from 'common/_classes/entities';
import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateInputKey } from 'store/entities/entityDetailSlice';
import { EntityCreatorTypes, listIndustries } from 'common/api/entities';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION } from 'utils/UI';

interface EntityGeneralFormProps {
  mode: MODE_OPTIONS;
  type: EntityCreatorTypes;
  entityData: IEntityClone;
  isChild?: boolean;
}

const EntityGeneralForm = ({ mode, type, entityData, isChild }: EntityGeneralFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isLoading, entitiesList } = useAppSelector((state: RootState) => state.entitiesListing);
  const { industryListOptions } = useAppSelector((state: RootState) => state.entityDetail);
  // const { selectedSubsidiary} = useAppSelector((state: RootState) => state.entityDetail);
  const { id, name, nickname, address, identifier, industry, parentId, sharePercentage } = entityData;
  const onChange = (key: string, value: string) => {
    dispatch(updateInputKey({ key, value, isChild }));
  };
  let industryValue; //TODO simplify this. is it an object or a string ?
  if (typeof industry === 'object' && industry !== null) {
    // @ts-ignore
    industryValue = industry.id;
  } else {
    industryValue = industry;
  }

  const isTenant: boolean = type === EntityCreatorTypes.Tenant;

  useEffect(() => {
    if (isTenant) {
      dispatch(listIndustries());
    }
  }, [dispatch, isTenant]);

  const isPartiallyOwned = !sharePercentage ? false : Number(sharePercentage) != 100;

  const onPartiallyOwnedChange = (value: boolean) => {
    if (value) {
      onChange('sharePercentage', '0');
    } else {
      onChange('sharePercentage', '100');
    }
  };

  const entityListDropdownOptions: DROPDOWN_OPTION[] = entitiesList
    .filter(entity => entity.id != id)
    .map(entity => {
      return {
        key: entity.id,
        value: entity.id,
        text: entity.name,
      };
    });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          width={10}
          className="pm-none"
        >
          <Grid>
            <Grid.Row className="pm-none">
              <Grid.Column
                width={isTenant ? 8 : 16}
                className="pm-none p-r-s"
              >
                <InputField
                  label={`${type} legal name`}
                  value={name}
                  disabled={mode === MODE_OPTIONS.READ}
                  required={mode === MODE_OPTIONS.EDIT}
                  onChange={onChange}
                  fieldKey="name"
                  dataTest="entity-legal-name"
                />
                {isChild && (
                  <>
                    <SelectField
                      label="Parent entity"
                      required={mode === MODE_OPTIONS.EDIT}
                      placeholder="Select parent entity..."
                      data-test="parent-entity"
                      options={entityListDropdownOptions}
                      disabled={mode === MODE_OPTIONS.READ}
                      value={parentId}
                      search={true}
                      onChange={onChange}
                      fieldKey="parentId"
                    />
                    <CheckboxField
                      label={isPartiallyOwned ? 'Partially owned' : 'Wholly owned?'}
                      className="d-flex align-center p-t-sm"
                      dataTest=""
                      fieldKey=""
                      value={isPartiallyOwned}
                      toggle={true}
                      disabled={mode === MODE_OPTIONS.READ}
                      onChange={(key, value) => onPartiallyOwnedChange(value)}
                    />
                    {isPartiallyOwned && (
                      <InputField
                        label="Ownership %"
                        value={sharePercentage}
                        disabled={mode === MODE_OPTIONS.READ}
                        required={mode === MODE_OPTIONS.EDIT}
                        onChange={onChange}
                        fieldKey="sharePercentage"
                        dataTest="entity-ownership"
                      />
                    )}
                  </>
                )}
              </Grid.Column>

              {isTenant && (
                <Grid.Column
                  width={8}
                  className="pm-none p-l-s"
                >
                  <InputField
                    label={`${type} identifier`}
                    value={identifier}
                    disabled={mode === MODE_OPTIONS.READ}
                    required={mode === MODE_OPTIONS.EDIT}
                    onChange={onChange}
                    fieldKey="identifier"
                    dataTest="entity-identifier"
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>

          <Grid className="m-b-sm">
            <Grid.Row className="pm-none">
              <Grid.Column
                width={isTenant ? 8 : 16}
                className="pm-none p-r-s"
              >
                <InputField
                  label={`${type} trading name`}
                  value={nickname}
                  disabled={mode === MODE_OPTIONS.READ}
                  required={mode === MODE_OPTIONS.EDIT}
                  onChange={onChange}
                  fieldKey="nickname"
                  dataTest="entity-trading-name"
                />
              </Grid.Column>

              {isTenant && (
                <Grid.Column
                  width={8}
                  className="pm-none p-l-s"
                >
                  <SelectField
                    label="Tenant industry"
                    value={industryValue}
                    disabled={mode === MODE_OPTIONS.READ}
                    placeholder="Select industry..."
                    options={industryListOptions}
                    search={true}
                    required={mode === MODE_OPTIONS.EDIT}
                    onChange={onChange}
                    fieldKey="industry"
                    dataTest="entity-industry"
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>

          <TextAreaField
            label="Postal address"
            value={address}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="address"
            dataTest="entity-postal-address"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EntityGeneralForm;
