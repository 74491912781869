import CodeEditor from '@uiw/react-textarea-code-editor';
import { html_beautify } from 'js-beautify';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Colors } from 'utils/utils-colors';
import './EditorSideMenu.scss';

export const ShowCode = () => {
  const { contents } = useAppSelector((state: RootState) => state.provisionDetail.activeProvision);
  const { editorTab } = useAppSelector((state: RootState) => state.hiddenMenu);

  const documentType = contents.find(({ documentTypeId }: { documentTypeId: string }) => documentTypeId === editorTab);

  if (!documentType) return null;

  return (
    <div className="w-tc-editor-var m-t-ml">
      <CodeEditor
        value={html_beautify(documentType.content, { indent_size: 2 })}
        language="html"
        placeholder="Waiting for HTML code."
        padding={5}
        disabled
        style={{
          fontSize: 12,
          backgroundColor: Colors.GRAY_VERY_LIGHT,
          fontFamily: 'ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace',
        }}
      />
    </div>
  );
};
