import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deleteDevelopment } from 'common/api/propertiesV2';
import { Icons } from 'utils/utils-icons';

interface DevelopmentRowActionButtonParams {
  rowId: string;
  onViewDetails: ViewDetailsProps;
}

export const DevelopmentRowActionButtons = ({ rowId, onViewDetails }: DevelopmentRowActionButtonParams) => {
  const dispatch = useAppDispatch();

  const onDelete = (id: string) => {
    dispatch(deleteDevelopment({ id }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Development',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Development',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Development?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
