import { Loader } from 'semantic-ui-react';

const EditorLoading = () => {
  return (
    <div className="editor-loading">
      <Loader
        active={true}
        size="big"
      />
    </div>
  );
};

export default EditorLoading;
