import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { NotificationReadStatus } from 'components/NotificationComponent/utils.notification';
import Notification from 'common/model/Notification';
import NotificationType from 'common/model/NotificationType';
import PageInfo from 'common/model/PageInfo';
import {
  deleteMultipleNotifications,
  deleteNotification,
  listNotifications,
  toggleArchiveMultipleNotifications,
  toggleNotificationReadStatus,
} from 'common/api/notifications';

export enum NOTIFICATIONS_TABS_OFFSET {
  NEW = 0,
  ARCHIVED = 1,
}

interface NotificationState {
  notifications: Notification[];
  selectedNotificationIds: string[];
  pageInfo: PageInfo[];
  typeFilter: NotificationType[];
  readFilter: NotificationReadStatus[];
  search: string;
  isSearching: boolean;
  archive: boolean;
  activeTabIndex: NOTIFICATIONS_TABS_OFFSET;
  isLoading: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  selectedNotificationIds: [],
  pageInfo: [],
  typeFilter: [],
  readFilter: [],
  search: '',
  isSearching: false,
  archive: false,
  activeTabIndex: NOTIFICATIONS_TABS_OFFSET.NEW,
  isLoading: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotificationSearch: (state, action) => {
      state.search = action.payload;
    },
    updateNotificationFilters: (state, action) => {
      const { value, isTypeFilter } = action.payload;

      if (isTypeFilter) {
        state.typeFilter = value;
      } else {
        state.readFilter = value;
      }
    },
    updateActiveNotificationTabIndex: (state, action) => {
      const tab = action.payload.tab;
      state.notifications = [];
      state.selectedNotificationIds = [];
      state.typeFilter = [];
      state.readFilter = [];
      state.archive = tab === NOTIFICATIONS_TABS_OFFSET.ARCHIVED;
      state.activeTabIndex = tab;
    },
    selectNotification: (state, action) => {
      const { id } = action.payload;
      state.selectedNotificationIds = [...state.selectedNotificationIds, id];
    },
    unselectNotification: (state, action) => {
      const { id } = action.payload;
      state.selectedNotificationIds = state.selectedNotificationIds.filter(item => item !== id);
    },
    selectAllNotifications: state => {
      state.selectedNotificationIds = state.notifications.map(item => item.id);
    },
    unSelectAllNotifications: state => {
      state.selectedNotificationIds = [];
    },
  },
  extraReducers: builder => {
    // toggleArchiveMultipleNotifications
    builder.addCase(toggleArchiveMultipleNotifications.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(toggleArchiveMultipleNotifications.fulfilled, (state, action) => {
      const data = action.payload.data;

      const archivedIds: string[] = [];
      Object.keys(data).map(key => {
        archivedIds.push(data[key].id);
      });
      const notifications = state.notifications.filter(element => !archivedIds.includes(element.id));

      state.notifications = notifications;
      state.selectedNotificationIds = [];
      state.isLoading = false;
    });
    builder.addCase(toggleArchiveMultipleNotifications.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('toggleArchiveMultipleNotifications API request failed');
    });

    // toggleNotificationReadStatus
    builder.addCase(toggleNotificationReadStatus.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(toggleNotificationReadStatus.fulfilled, (state, action) => {
      const { readNotification } = action.payload.data;

      state.notifications = state.notifications.map(edge => {
        if (edge.id === readNotification.id) {
          return readNotification;
        } else {
          return [];
        }
      });

      state.isLoading = false;
    });
    builder.addCase(toggleNotificationReadStatus.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('toggleNotificationReadStatus API request failed');
    });

    // listNotifications
    builder.addCase(listNotifications.pending, (state, action) => {
      const { loader } = action.meta.arg;
      const { search } = state;

      if (search) {
        state.isSearching = true;
      } else {
        state.isLoading = loader;
      }
    });
    builder.addCase(listNotifications.fulfilled, (state, action) => {
      const { listNotifications } = action.payload.data;
      state.pageInfo = listNotifications.pageInfo;
      state.notifications = listNotifications.edges.map((element: any) => element.node);
      state.isSearching = false;
      state.isLoading = false;
    });
    builder.addCase(listNotifications.rejected, (state, action) => {
      state.isSearching = false;
      state.isLoading = false;
      console.warn(action.error);
      toast.error('listNotifications API request failed');
    });

    // deleteNotification
    builder.addCase(deleteNotification.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteNotification.fulfilled, (state, action) => {
      state.notifications = state.notifications.filter(element => element.id !== action.meta.arg.notificationId);
      state.isLoading = false;
      toast.success('Notification deleted successfully');
    });
    builder.addCase(deleteNotification.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('deleteNotification API request failed');
    });

    // deleteMultipleNotifications
    builder.addCase(deleteMultipleNotifications.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteMultipleNotifications.fulfilled, state => {
      const notifications = state.notifications.filter(element => !state.selectedNotificationIds.includes(element.id));

      state.notifications = notifications;
      state.selectedNotificationIds = [];
      state.isLoading = false;
    });
    builder.addCase(deleteMultipleNotifications.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('deleteMultipleNotifications API request failed');
    });
  },
});

export const {
  updateActiveNotificationTabIndex,
  selectNotification,
  unselectNotification,
  selectAllNotifications,
  unSelectAllNotifications,
  updateNotificationFilters,
  updateNotificationSearch,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
