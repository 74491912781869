import './PageNotFound.scss';

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <h1>Error 404</h1>
      <div>Woops... Looks like this page doesn't exist.</div>
    </div>
  );
};

export default PageNotFound;
