export const STORAGE_KEYS = {
  HIDDEN_COLUMNS_PREFIX: '-hidden-columns',
  POLICY_SEARCH: 'policySearch',
  POLICY_TABLE: 'policy-table',
};

export const getStoredValue = (key: string): string | null => {
  return sessionStorage.getItem(key);
};

export const saveIntoStorage = (key: string, value: string): void => {
  sessionStorage.setItem(key, value);
};
