export const capitalizeFirstLetter = (input: string | null): string | null => {
  if (!input) {
    return null;
  }
  const string = input.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const limitStringSize = (text: string, number: number): string => {
  return text !== null && text.length > number ? `${text.substring(0, number - 3)}...` : text;
};

export const addQuotes = (value: string | null): string | null => {
  if (value === null) {
    return value;
  } else {
    return `"${value}"`;
  }
};

export const getPhoneNumber = (countryCode: string | null, phone: string | null): string => {
  return countryCode && phone ? `${countryCode} ${phone}` : '------';
};

export const emailIsValid = (email: string): boolean => {
  return /\S+@\S+\.\S+/.test(email);
};

export const replaceDoubleSlash = (text: string | null): string | null => {
  if (text === null) {
    return null;
  } else {
    return text.replace(/\/\//, '');
  }
};

// Count the number of substrings inside the string
export const countSubstr = (substr: string | undefined, str: string): number => {
  if (substr) {
    const regExp = new RegExp(substr, 'gi');
    const paraCount = (str.match(regExp) || []).length;
    return paraCount;
  } else {
    return 0;
  }
};

export const replaceUnderscoreWithSpace = (str: string): string => {
  return str.replace(/_/g, ' ');
};

export const toCapitalSnakeCase = (text: string): string => text.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase();

export const makePlural = (num: number, label: string): string => {
  return num.toString() + label + (num === 1 ? '' : 's');
};

export const parseContent = (content: string): Document => {
  const parser = new DOMParser();
  return parser.parseFromString(content, 'text/html');
};

export const isUUID = (value: string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(value);
};

export const isValidJSON = (value: any) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};
