import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DropdownSelect from 'atoms/DropdownSelect';
import { updateForecastView } from 'store/dashboard/forecastsSlice';
import { DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION } from 'utils/UI';

interface FORECAST_VIEW_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS;
}

const FORECAST_VIEW_OPTIONS: FORECAST_VIEW_OPTION[] = [
  { key: 1, value: DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS.TABLE, text: 'View Table' },
  { key: 2, value: DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS.GRAPH, text: 'View Graph' },
];

const ViewSelect = () => {
  const dispatch = useAppDispatch();
  const { viewType } = useAppSelector((state: RootState) => state.forecasts);

  return (
    <DropdownSelect
      className="m-l-l"
      placeholder="Select month"
      options={FORECAST_VIEW_OPTIONS}
      value={viewType}
      onChange={(key, value) =>
        dispatch(updateForecastView({ value: value as DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS }))
      }
    />
  );
};

export default ViewSelect;
