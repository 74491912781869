import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import ValidApprovalStates from 'common/model/ValidApprovalStates';

interface UpdateTransactionApprovalAnswersStateMutationVariables {
  transactionId: string | undefined;
  transactionApprovalAnswerIds: string[];
  approvalState: ValidApprovalStates;
}

export const UPDATE_TRANSACTION_APPROVAL_ANSWERS_STATE = (
  transactionId: string | undefined,
  approvalAnswerIds: string[],
  approvalState: ValidApprovalStates,
) => {
  const variables: UpdateTransactionApprovalAnswersStateMutationVariables = {
    transactionId,
    transactionApprovalAnswerIds: approvalAnswerIds,
    approvalState,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $transactionApprovalAnswerIds: [ID!], $approvalState: ValidApprovalStates!) {
        updateTransactionApprovalAnswersState(
          transactionId: $transactionId
          transactionApprovalAnswerIds: $transactionApprovalAnswerIds
          approvalState: $approvalState
        )
      }
    `,
    variables: variables,
  };
};

export const updateTransactionApprovalAnswersState = createAsyncThunk(
  'transactions/updateTransactionApprovalAnswersState',
  async (
    { approvalAnswerIds, approvalState }: { approvalAnswerIds: string[]; approvalState: ValidApprovalStates },
    { getState },
  ) => {
    const {
      transactionDetail: {
        activeTransaction: { id: transactionId },
      },
    } = getState() as RootState;
    const response = await client.mutate(
      UPDATE_TRANSACTION_APPROVAL_ANSWERS_STATE(transactionId, approvalAnswerIds, approvalState),
    );
    return response;
  },
);
