import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { UsagePoint } from 'common/api/contracts2';
import { evaluateLengthFromDates } from 'common/api/contracts2/evaluateLengthOfDates';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { RENT_FREE_PERIOD_PAYABLES_OPTIONS } from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';

interface RentFreePeriodFormProps extends ModeProps {
  index: number;
}

const RentFreePeriodForm = ({ mode, index }: RentFreePeriodFormProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const freePeriods = activeContractEvent?.rentFreePeriods || [];
  const { endDate, startDate, payables, duration } = freePeriods[index];

  const onChange = (key: string, value: string) =>
    dispatch(updateActiveContractEventInput({ key: ['rentFreePeriods', `${index}`, key], value }));

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(evaluateLengthFromDates({ date1: startDate, date2: endDate, index, usage: UsagePoint.RentFreePeriod }));
    } else {
      onChange('duration', '');
    }
  }, [startDate, endDate]);

  return (
    <div className="grid m-b-s m-t-s pm-none">
      <div className="col-2">
        <DateField
          label="Start Date"
          fieldKey="startDate"
          dataTest=""
          value={startDate || null}
          disabled={disabled}
          required={required}
          onChange={onChange}
          minDate={getMinDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            freePeriods,
            index,
            'startDate',
          )}
          maxDate={getMaxDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            freePeriods,
            index,
            'startDate',
          )}
        />
      </div>
      <div className="col-2">
        <DateField
          label="End Date"
          fieldKey="endDate"
          dataTest=""
          value={endDate || null}
          disabled={disabled}
          required={required}
          onChange={onChange}
          minDate={getMinDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            freePeriods,
            index,
            'endDate',
          )}
          maxDate={getMaxDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            freePeriods,
            index,
            'endDate',
          )}
        />
      </div>
      <div className="col-2">
        <InputField
          className="m-t-xxxs"
          label="Duration"
          dataTest=""
          type={InputFieldType.NUMBER}
          fieldKey=""
          value={duration}
          disabled={true}
          onChange={() => {}}
        />
      </div>
      <div className="col-2">
        <SelectField
          label="Rent Free Period Payables"
          fieldKey="payables"
          dataTest=""
          value={payables}
          disabled={disabled}
          required={required}
          multiple={true}
          options={RENT_FREE_PERIOD_PAYABLES_OPTIONS}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default RentFreePeriodForm;
