import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface ListAmendmentsQueryVariables {
  transactionId: string;
}

const LIST_AMENDMENTS = (transactionId: string) => {
  if (!transactionId) throw new Error('List Amendments: transactionId is required');

  const variables: ListAmendmentsQueryVariables = {
    transactionId,
  };

  return {
    query: gql`
      query ($transactionId: ID!) {
        listAmendments(transactionId: $transactionId) {
          id
          name
          index
          category {
            id
            name
          }
          documentType {
            id
            name
          }
        }
      }
    `,
    variables,
  };
};

export const listAmendments = createAsyncThunk('transactions/listAmendments', async (args, { getState }) => {
  const {
    transactionDetail: { activeTransaction },
  } = getState() as RootState;

  const response = await client.query(LIST_AMENDMENTS(activeTransaction.id!));
  return response;
});
