import { createSlice } from '@reduxjs/toolkit';
import { TransactionListingClone } from 'common/_classes';
import { toast } from 'react-toastify';
import TenantEntityWithJoin from 'common/model/TenantEntityWithJoin';
import TransactionEdge from 'common/model/TransactionEdge';
import {
  deleteTransaction,
  listLandlordTransactions,
  listTenantTransactions,
  listTenantsAndAutoSelectFirstTenant,
  listTransactions,
  transactionAbortOrReinstate,
} from 'common/api/transactions';
import { DROPDOWN_OPTION } from 'utils/UI';

interface TransactionsListingState {
  transactionsList: TransactionListingClone[];
  isLoading: boolean;
  search: string | null;
  selectedTenant: string;
  tenantEntitiesOptions: DROPDOWN_OPTION[];
}

const initialState: TransactionsListingState = {
  transactionsList: [],
  isLoading: false,
  search: null,
  selectedTenant: '',
  tenantEntitiesOptions: [],
};

const transactionsListingSlice = createSlice({
  name: 'transactionsListing',
  initialState,
  reducers: {
    updateSelectedTenant: (state, action) => {
      state.selectedTenant = action.payload;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    resetTransactionsSearch: state => {
      state.search = null;
    },
  },
  extraReducers: builder => {
    const handleListTransactionsPending = (state: TransactionsListingState, action: any) => {
      if (!action.meta.arg.searchValue) {
        state.isLoading = true;
      }
    };

    const handleListTransactionsFulfilled = (apiName: string) => (state: TransactionsListingState, action: any) => {
      const transactions = action.payload[apiName];

      if (transactions) {
        state.transactionsList = transactions.edges.map((element: TransactionEdge) => element.node);
      } else {
        toast.info('No transactions found');
      }
      state.isLoading = false;
    };

    const handleListTransactionsRejected = (apiName: string) => (state: TransactionsListingState) => {
      state.isLoading = false;

      if (apiName === 'listTenantTransactions' && !state.selectedTenant) return;

      toast.error('Some error occurred when trying to loading the Transactions');
    };

    // Listing transactions
    builder.addCase(listTransactions.pending, handleListTransactionsPending);
    builder.addCase(listTransactions.fulfilled, handleListTransactionsFulfilled('listTransactions'));
    builder.addCase(listTransactions.rejected, handleListTransactionsRejected('listTransactions'));

    // Listing landlord transactions
    builder.addCase(listLandlordTransactions.pending, handleListTransactionsPending);
    builder.addCase(listLandlordTransactions.fulfilled, handleListTransactionsFulfilled('listLandlordTransactions'));
    builder.addCase(listLandlordTransactions.rejected, handleListTransactionsRejected('listLandlordTransactions'));

    // Listing tenant transactions
    builder.addCase(listTenantTransactions.pending, handleListTransactionsPending);
    builder.addCase(listTenantTransactions.fulfilled, handleListTransactionsFulfilled('listTenantTransactions'));
    builder.addCase(listTenantTransactions.rejected, handleListTransactionsRejected('listTenantTransactions'));

    // listTenantsAndAutoSelectFirstTenant
    builder.addCase(listTenantsAndAutoSelectFirstTenant.fulfilled, (state, action) => {
      const tenantsList: TenantEntityWithJoin[] = action.payload.data.tenant.edges.map(
        (element: { node: TenantEntityWithJoin }) => element.node,
      ) as TenantEntityWithJoin[];

      state.tenantEntitiesOptions = tenantsList.map((item: TenantEntityWithJoin, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: item.name,
          value: item.id,
        };
        return result;
      });

      if (tenantsList.length > 0 && !state.selectedTenant) {
        state.selectedTenant = tenantsList[0].id;
      }
    });

    //deleteTransaction
    builder.addCase(deleteTransaction.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteTransaction.fulfilled, (state, action) => {
      state.transactionsList = state.transactionsList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Transaction deleted successfully');
    });
    builder.addCase(deleteTransaction.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.warning('Cannot delete transaction');
    });

    //abortOrReinstate Transaction
    builder.addCase(transactionAbortOrReinstate.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(transactionAbortOrReinstate.fulfilled, (state, action) => {
      const { id: transactionId, historyGeneral } = action.payload.data.transactionAbortOrReinstate;
      state.isLoading = false;
      const transaction = state.transactionsList.find(({ id }) => id === transactionId);

      if (transaction) {
        transaction.historyGeneral = [...historyGeneral];
      }
      toast.success('Transaction status updated Successfully');
    });
    builder.addCase(transactionAbortOrReinstate.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to abortOrReinstate Transaction');
    });
  },
});

export const { updateSearch, resetTransactionsSearch, updateSelectedTenant } = transactionsListingSlice.actions;

export default transactionsListingSlice.reducer;
