import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeleteAmendmentQueryVariables {
  amendmentId: string;
}

const DELETE_AMENDMENT = (amendmentId: string) => {
  if (!amendmentId) throw new Error('Delete Amendment: amendmentId is required');

  const variables: DeleteAmendmentQueryVariables = {
    amendmentId,
  };

  return {
    mutation: gql`
      mutation ($amendmentId: ID!) {
        deleteAmendment(amendmentId: $amendmentId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteAmendment = createAsyncThunk('transactions/deleteAmendment', async (amendmentId: string) => {
  const response = await client.mutate(DELETE_AMENDMENT(amendmentId));
  return response;
});
