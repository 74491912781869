import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ImportForecastingQueryVariables {
  simulation: boolean;
  uploadFile: any;
}

const IMPORT_FORECASTING = (simulation: boolean, file: any) => {
  const variables: ImportForecastingQueryVariables = {
    simulation,
    uploadFile: file,
  };

  return {
    mutation: gql`
      mutation ($simulation: Boolean!, $uploadFile: Upload!) {
        importForecasting(simulation: $simulation, uploadFile: $uploadFile) {
          dataType
          items {
            forecast
            month
            rent
            floorName
            spaceName
          }
          itemsDuplicateBase {
            log
            type
          }
          itemsDuplicateExcel {
            log
            type
          }
          itemsModified {
            forecast
            month
            rent
            floorName
            spaceName
          }
          logs {
            log
            type
          }
          simulation
        }
      }
    `,
    variables,
  };
};

interface ImportForecastingParams {
  simulation: boolean;
  file: any;
}

export const importForecasting = createAsyncThunk(
  'properties/importForecasting',
  async ({ simulation, file }: ImportForecastingParams) => {
    const response = await client.mutate(IMPORT_FORECASTING(simulation, file));
    return response;
  },
);
