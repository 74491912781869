import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';
import { StackingPlanProps } from '.';

export interface StackingPlanTransactionQueryVariables {
  propertyId: string;
  date: string;
}

const GET_STACKING_PLAN_TRANSACTIONS = (propertyId: string, date: string) => {
  if (!propertyId) {
    throw new Error('Get Stacking Plans Transactions: Property ID could not be determined. Query aborted.');
  }

  const variables: StackingPlanTransactionQueryVariables = {
    propertyId,
    date,
  };

  return {
    query: gql`
      query ($propertyId: ID!, $date: Date!) {
        getStackingPlanTransactions(propertyId: $propertyId, date: $date) {
          propertyId
          date
          transactions {
            startDate
            status
            transactionId
            tenantName
            unitIds
          }
        }
      }
    `,
    variables,
  };
};

/**
 * Get Property's ongoing transactions
 */
export const getStackingPlanTransactions = createAsyncThunk(
  'stackingPlan/getStackingPlanTransactions',
  async ({ propertyId, date }: StackingPlanProps) => {
    const response = await getClientType().query(GET_STACKING_PLAN_TRANSACTIONS(propertyId, date));
    return response.data;
  },
);
