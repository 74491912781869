import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';
import { StackingPlanProps } from '.';

export interface GetPropertyEventsQueryVariables {
  propertyId: string;
  date: string;
}

const GET_PROPERTY_EVENTS = (propertyId: string, date: string) => {
  if (!propertyId) {
    throw new Error('Get Property Events: Property ID could not be determined. Query aborted.');
  }

  const variables: GetPropertyEventsQueryVariables = {
    propertyId,
    date,
  };

  return {
    query: gql`
      query ($propertyId: ID!, $date: Date!) {
        getPropertyEvents(propertyId: $propertyId, date: $date) {
          propertyId
          date
          events {
            date
            event
            description
            tenantName
            floors {
              floorName
              floorId
              floorIndex
            }
          }
        }
      }
    `,
    variables,
  };
};

/**
 * Get Property's Events Timeline
 */
export const getPropertyEvents = createAsyncThunk(
  'stackingPlan/getPropertyEvents',
  async ({ propertyId, date }: StackingPlanProps) => {
    const response = await getClientType().query(GET_PROPERTY_EVENTS(propertyId, date));
    return response.data;
  },
);
