import { MenuItem } from 'primereact/menuitem';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import ConfirmPopup from 'atoms/ConfirmPopup';
import TableReactPrime from 'atoms/TableReactPrime';
import NavigateButton from 'atoms/TableReactPrime/NavigateButton';
import { initNewPortfolio } from 'store/portfolio/portfolioDetailSlice';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { deletePortfolio, listPortfolios, listProperties } from 'common/api/portfolio';
import { PortfolioClone } from 'utils/types/portfolio';
import { Icons } from 'utils/utils-icons';
import { PortfoliosRow, portfolioColumnConfig } from './PortfoliosTableConfig';

const PortfolioDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, portfolios, propertiesList } = useAppSelector((state: RootState) => state.portfoliosListing);

  useEffect(() => {
    dispatch(listProperties(1000));
  }, []);

  const onViewDetails = (id: string): void => {
    const url = `/portfolios/${id}/details`;
    navigate(url);
  };

  const createPortfolio = () => {
    dispatch(initNewPortfolio());
    navigate('/portfolios/create');
  };

  /*
   * Definition of the action menu of a row with:
   *  - view details
   *  - duplicate
   *  - delete
   */
  const actionMenuConfig = (row: PortfoliosRow): MenuItem[] => {
    const result: MenuItem[] = [
      {
        template: <NavigateButton url={`/portfolios/${row.id}/details`} />,
      },
      {
        template: (
          <ConfirmPopup
            trigger={
              <div
                className="dashboard-action-menu-item"
                data-test="delete-button"
              >
                <Icon icon={Icons.Delete} />
                <span>Delete</span>
              </div>
            }
            content="Do you really want to delete this Porfolio?"
            confirmButtonText="DELETE"
            onConfirm={() => dispatch(deletePortfolio({ id: row.id }))}
            cancelButtonText="CANCEL"
          />
        ),
      },
    ];
    return result;
  };

  const breadCrumb: BreadCrumbItem[] = [{ title: 'Portfolios' }];

  const currentList: PortfoliosRow[] = portfolios.map(
    (portfolio: PortfolioClone) => new PortfoliosRow(portfolio, propertiesList),
  );

  const getPageHeaderProps = (): PageHeaderProps => {
    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE PORTFOLIO',
      url: '/portfolios/create',
      dataTest: 'create-portfolio-button',
      onCreateNew: createPortfolio,
    };

    // TODO: remove the comment below and integrate query search
    const headerButtons: PageHeaderToolbarProps = {
      // headerToolbar: <PortfolioHeaderToolbar />,
      createNewButton,
    };

    const pageHeader: PageHeaderProps = {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };

    return pageHeader;
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          dispatch(listPortfolios(rest));
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={portfolioColumnConfig(actionMenuConfig, onViewDetails)}
        dataTest="portfolio-table"
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default PortfolioDashboard;
