import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import AnswerTypes from 'common/model/AnswerTypes';
import ParameterTable from 'common/model/ParameterTable';
import { AnswerProps } from 'common/api/policies';
import { updateAnswer } from 'utils/utils-answer';

const createNullifiedAnswersForUnusedColumns = (
  answersOfUnusedColumns: AnswerProps[],
  dispatch: Dispatch<AnyAction>,
) => {
  answersOfUnusedColumns.forEach(answer => {
    const { index, parameterId, tableId } = answer.paramRef;

    updateAnswer({
      value: getNullifiedAnswerValueForAnswerTypes(answer),
      blockRef: tableId ? `${parameterId}-${index}` : index,
      answerType: answer.answerType,
      answerUnit: '',
      parameterId,
      tableId,
      tabIndex: index,
      dispatch,
    });
  });
};

const getNullifiedAnswerValueForAnswerTypes = (answer: AnswerProps) => {
  switch (answer.answerType) {
    case AnswerTypes.Image:
      return { title: '', url: '' };

    case AnswerTypes.SingleChoice:
    case AnswerTypes.MultiChoice:
      (() => {
        const answerValue = answer.answer.values as string[];
        const answerInverses = answer.answer.inverses as string[];
        return {
          values: null,
          inverses: [...answerInverses, ...answerValue],
        };
      })();

    case AnswerTypes.Time:
      return { hours: '', minutes: '' };

    case AnswerTypes.Duration:
      return { years: '', months: '', days: '' };
    case AnswerTypes.Boolean:
      return null;
    default:
      return '';
  }
};

const getLatestAnswersRelatedToGroup = (answers: AnswerProps[], tableData: ParameterTable) => {
  const foundAnswers: AnswerProps[] = [];

  const foundChache: { [key: string]: boolean } = {};

  for (let i = answers.length - 1; i >= 0; i--) {
    const answer = answers[i];
    const cacheKey = `${answer.paramRef.tableId}-${answer.paramRef.parameterId}-${answer.paramRef.index}`;

    if (!foundChache[cacheKey] && answer.paramRef.tableId === tableData.id) {
      foundAnswers.push(answer);

      foundChache[cacheKey] = true;
    }
  }

  return foundAnswers;
};

const extractColumnData = (tableData: ParameterTable) => {
  const columnParamerterIds: string[] = [];
  const columnData: {
    [key: string]: {
      hasConditions: boolean;
      applicableRowIndexes: number[];
    };
  } = {};

  tableData.columns.forEach(column => {
    columnParamerterIds.push(column.parameter.id);

    columnData[column.parameter.id] = {
      hasConditions: Array.isArray(column.conditions) ? column.conditions.length > 0 : false,
      applicableRowIndexes: Array.isArray(column.applicableRowIndexes) ? column.applicableRowIndexes : [],
    };
  });

  return { columnParamerterIds, columnData };
};

const filterAnswersToBeNullified = (latestTableAnswers: AnswerProps[], tableData: ParameterTable) => {
  const { columnParamerterIds, columnData } = extractColumnData(tableData);

  return latestTableAnswers.filter(answer => {
    const {
      paramRef: { parameterId: answerParameterId, index: answerIndex },
    } = answer;

    if (!columnParamerterIds.includes(answerParameterId)) return true;

    const column = columnData[answerParameterId];

    if (column.hasConditions && !column.applicableRowIndexes.includes(answerIndex)) return true;

    return false;
  });
};

export const nullifyAnswersOfUnusedColumns = (
  activeGroupTables: ParameterTable[],
  answers: AnswerProps[],
  dispatch: Dispatch<AnyAction>,
) => {
  const allAnswersToBeNullified = activeGroupTables.reduce((acc, tableData) => {
    const latestTableAnswers = getLatestAnswersRelatedToGroup(answers, tableData);

    const answersToBeNullifiedInCurrentTable = filterAnswersToBeNullified(latestTableAnswers, tableData);

    return [...acc, ...answersToBeNullifiedInCurrentTable];
  }, [] as AnswerProps[]);

  createNullifiedAnswersForUnusedColumns(allAnswersToBeNullified, dispatch);
};
