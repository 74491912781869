import { ProvisionListingClone } from 'common/_classes';
import { ReactNode } from 'react';
import { Button, Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardViewTemplate, { CardTemplateConfiguration } from 'components/CardViewTemplate';
import { updateProvisionModalState } from 'store/provisions/provisionsListingSlice';
import { createPolicyProvision, listPolicySequences } from 'common/api/policies';
import { PolicyProvisionInput } from 'common/api/policies';
import { ProvisionContentOnlyDocTypeId } from 'common/api/provisions';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { isProvisionInPolicy } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';

interface UnusedProvisionsCardsProps {
  onClose: Function;
}

interface CardElement extends ProvisionListingClone {}

const UnusedProvisionsCards = ({ onClose }: UnusedProvisionsCardsProps) => {
  const { provisionCategoriesList, provisionsList } = useAppSelector((state: RootState) => state.provisionsListing);
  const { activePolicy, activePolicyProvisions } = useAppSelector((state: RootState) => state.policyDetail);
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);

  const dispatch = useAppDispatch();

  const orderedProvisionsList: ProvisionListingClone[] = orderBy(
    provisionsList,
    provision => {
      return provision.index;
    },
    'asc',
  );

  const onSelectProvision = (provision: ProvisionListingClone) => {
    const found: ProvisionListingClone | undefined = orderedProvisionsList.find(item => provision?.id === item.id);

    if (!found) return;

    const newPolicyProvision: PolicyProvisionInput = {
      policyId: activePolicy.id,
      provisionId: provision.id,
      mandatory: false,
      selectedByDefault: false,
    };

    dispatch(createPolicyProvision(newPolicyProvision)).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(listPolicySequences({ policyId: activePolicy.id }));
      }
    });
  };

  const cardBodyTemplate = (provision: CardElement): ReactNode => {
    const documentMap = new Map();

    documentTypesList.map(element => {
      documentMap.set(element.id, element.name);
    });

    const getDocument = (key: string): string => {
      return documentMap.get(key);
    };

    return (
      <Grid className="component-card-body color-blue-desaturated-dark">
        <Grid.Row className="p-none">
          <Grid.Column
            className="p-none"
            width={5}
          >
            Appears in:
          </Grid.Column>
          <Grid.Column
            className="p-none color-blue-very-dark-grayish"
            width={11}
          >
            {provision.contents
              ?.map((content: ProvisionContentOnlyDocTypeId) => getDocument(content.documentTypeId))
              .join(', ')}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const cardHeaderTemplate = (provision: CardElement): JSX.Element => {
    return (
      <div className="column-custom-template">
        <Popup
          className="popup-info"
          content={provision.name}
          trigger={<span className="name color-black">{provision.name}</span>}
        />
        <div className="card-header-menu">
          <span
            className="card-header-menu-add-button"
            data-test="add-button"
            role="button"
            onClick={() => onSelectProvision(provision)}
          >
            Add
          </span>
        </div>
      </div>
    );
  };

  const cardMenuItems = (provision: CardElement): DropdownMenuItem[] => {
    return [
      {
        key: '1',
        label: 'View Details',
        icon: Icons.EyeOpen,
        onClick: () => dispatch(updateProvisionModalState({ open: true, provision })),
      },
    ];
  };

  const cardConfigs: CardTemplateConfiguration<ProvisionCategoryClone, CardElement> = {
    columnConfiguration: {
      header: {
        titleField: 'name',
        showEdit: false,
      },
      width: '25rem',
    },
    cardConfiguration: {
      header: {
        headerTemplate: (provision: CardElement) => cardHeaderTemplate(provision),
        cardMenuItems: (provision: CardElement) => cardMenuItems(provision),
      },
      bodyTemplate: (provision: CardElement) => cardBodyTemplate(provision),
      onClick: (provision: CardElement) => {},
    },
  };

  // Show all the categories
  const categoryList: ProvisionCategoryClone[] = provisionCategoriesList;

  // List, per category, the provisions that:
  //  - are part of this category
  //  - AND is not in the policy
  const filterElementsPerCategory = (category: ProvisionCategoryClone): ProvisionListingClone[] => {
    return orderedProvisionsList.filter(
      (provision: ProvisionListingClone) =>
        provision.provisionCategory.id === category.id && !isProvisionInPolicy(activePolicyProvisions, provision.id),
    );
  };

  return (
    <div className="section-provisions m-t-l p-t-xl p-l-xl border-sm-grayish-magenta-light bg-white">
      <h4 className="section-provisions-label color-blue-very-dark m-none">
        2. Catalogue of Provisions
        <Button
          className="catalogue-close-button bg-gray-very-light-v3"
          onClick={() => onClose()}
        >
          X
        </Button>
      </h4>
      <div className="policy-provisions-section">
        <CardViewTemplate<ProvisionCategoryClone, CardElement>
          categoryList={categoryList}
          configuration={cardConfigs}
          getCardElementsList={category => filterElementsPerCategory(category)}
        />
      </div>
    </div>
  );
};

export default UnusedProvisionsCards;
