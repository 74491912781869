/*
 * Definition of the column (names, format...)
 */
import Avatar, { AvatarSize } from 'atoms/Avatar';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementNumberRange,
  filterElementStatus,
  filterElementStatusTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import { PROPERTY_TYPE_OPTIONS } from 'utils/UI';
import { Floor } from 'utils/types/stackingPlan';
import { checkIfAdminURL } from 'utils/utils-admin';
import { Icons } from 'utils/utils-icons';
import { isValidNumber } from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import { PropertyRowActionButtons } from './PropertyRowActionButtons';

const statuses: StatusBadgeArgument[] = [
  { value: 'REVIEWED', color: BadgeColor.GREEN },
  { value: 'TO BE REVIEWED', color: BadgeColor.RED },
];

export class PropertiesRow {
  id: string;
  photo: string;
  identification: string;
  buildingName: string;
  address: string;
  floorCount: number;
  totalArea: number;
  numberOfUnits: number;
  type: string[];
  status: string;
  landlordName: string;

  constructor(property: any) {
    this.id = property.id;
    this.photo = property.photo;
    this.identification = property.identification;
    this.buildingName = property.buildingName;
    this.address = property.address;
    this.floorCount = property.floorCount;
    this.totalArea = getGrossArea(property.floors);
    this.numberOfUnits = property.spaceCount;
    this.type = property.type;
    this.status = property.reviewFlag ? statuses[0].value : statuses[1].value;
    this.landlordName = property.entity.name;
  }
}

const propertyTypeText = (value: string) => {
  const getPropertyType = PROPERTY_TYPE_OPTIONS.find(obj => obj.value === value);
  return getPropertyType?.text;
};

const getGrossArea = (floors: Floor[]) => {
  const isActiveSpace = (area: string, activationDate: string | null, deactivationDate: string | null) => {
    const today = new Date().getTime();
    const deactivationTime = deactivationDate ? new Date(deactivationDate).getTime() : null;

    if (deactivationTime && deactivationTime < today) {
      return false;
    }
    const activationTime = activationDate ? new Date(activationDate).getTime() : null;
    if (!activationTime || activationTime > today) {
      return false;
    }
    return isValidNumber(area);
  };

  return floors.reduce(
    (accumulator, { spaces }) =>
      accumulator +
      spaces.reduce(
        (accumulator, { grossArea, activationDate, deactivationDate }) =>
          accumulator + (isActiveSpace(grossArea, activationDate, deactivationDate) ? parseInt(grossArea) : 0),
        0,
      ),
    0,
  );
};

export const propertyColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  const isAdmin = checkIfAdminURL();

  const columns: CustomColumnProps[] = [
    {
      field: 'photo',
      header: 'Photo',
      filter: false,
      sortable: false,
      minWidth: '4rem',
      body: (row: PropertiesRow) => {
        return (
          <Avatar
            src={row.photo || ''}
            size={AvatarSize.Small}
            icon={Icons.Building}
          />
        );
      },
    },
    {
      field: 'identification',
      header: 'Property ID',
      filter: true,
      filterPlaceholder: 'Search by Property ID',
      filterField: 'identification',
      sortable: true,
      dataType: 'text',
      mandatory: !isAdmin,
      isHide: isAdmin,
    },
    {
      field: 'buildingName',
      header: 'Building name',
      filter: true,
      filterPlaceholder: 'Search by Building name',
      filterField: 'buildingName',
      sortable: true,
    },
    // NOTE a column (Landlord name) is added here if its admin, scroll down to see
    {
      field: 'address',
      header: 'Building address',
      filter: true,
      filterPlaceholder: 'Search by Building address',
      filterField: 'address',
      sortable: true,
    },
    {
      field: 'floorCount',
      header: 'Floors',
      filterPlaceholder: 'Search by Floors',
      filter: true,
      filterField: 'floorCount',
      sortable: true,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      body: (row: PropertiesRow) => addSpaceOrComma(row.floorCount, false),
    },
    {
      field: 'totalArea',
      header: 'Total area',
      filter: true,
      filterPlaceholder: 'Search by Total area',
      filterField: 'totalArea',
      sortable: true,
      mandatory: true,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: options => filterElementNumberRange(options, [0, 50000]),
      body: (row: PropertiesRow) => addSpaceOrComma(row.totalArea, false),
    },
    {
      field: 'numberOfUnits',
      header: 'Number of units',
      filter: true,
      filterPlaceholder: 'Search by Number of units',
      filterField: 'numberOfUnits',
      sortable: true,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      body: (row: PropertiesRow) => addSpaceOrComma(row.numberOfUnits, false),
    },
    {
      field: 'released',
      header: 'Property type',
      filter: false,
      filterPlaceholder: 'Search by Property type',
      filterField: 'released',
      sortable: false,
      body: (row: PropertiesRow) => {
        return (
          <div className="characteristics-box property-type-column">
            {row.type.map((obj, index) => (
              <div
                className="characteristic-inner"
                key={`${row.id}-characteristic-${index}`}
              >
                {propertyTypeText(obj)}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      field: 'status',
      header: 'Status',
      filter: true,
      filterPlaceholder: 'Search by status',
      filterField: 'status',
      body: (row: PropertiesRow) => {
        return filterElementStatusTemplate(row.status, statuses);
      },
      filterElement: options => filterElementStatus(options, statuses),
      className: 'p-sm',
      showFilterMatchModes: false,
      sortable: true,
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
  ];

  if (isAdmin) {
    columns.splice(3, 0, {
      field: 'landlordName',
      header: 'Landlord name',
      filter: true,
      filterPlaceholder: 'Search by Landlord name',
      filterField: 'landlordName',
      sortable: true,
    });
  } else {
    columns.push({
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: PropertiesRow) => (
        <PropertyRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    });
  }

  return columns;
};
