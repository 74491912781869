import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import InputField from 'atoms/FormField/Input';
import TextAreaField from 'atoms/FormField/TextArea';
import ReviewBox, { ReviewBoxValues } from 'components/ReviewBox';
import { updateBenchmarkInput, updateBenchmarkReviewedStatus } from 'store/benchmark/benchmarkDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import './BenchmarkGeneralForm.scss';

const BenchmarkGeneralForm = ({ mode }: ModeProps) => {
  const {
    activeBenchmark: { identifier, description, reviewFlag, reviewStatus },
  } = useAppSelector((state: RootState) => state.benchmarkDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string) => dispatch(updateBenchmarkInput({ key, value }));

  const reviewBoxValues: ReviewBoxValues = {
    reviewFlag,
    reviewStatus,
  };

  return (
    <div className="grid">
      <div className="col-8">
        <InputField
          label="Identifier"
          dataTest="benchmark-identifier-input"
          value={identifier}
          disabled={mode === MODE_OPTIONS.READ}
          onChange={onChange}
          fieldKey="identifier"
          required={mode === MODE_OPTIONS.EDIT}
        />
        <TextAreaField
          label="Contract summary description"
          dataTest="contract-summary-description"
          value={description}
          disabled={mode === MODE_OPTIONS.READ}
          onChange={onChange}
          fieldKey="description"
        />
      </div>
      <div className="col-3 col-offset-1">
        <SectionComponent className="review-form">
          <ReviewBox
            mode={mode}
            values={reviewBoxValues}
            onChange={updateBenchmarkReviewedStatus}
          />
        </SectionComponent>
      </div>
    </div>
  );
};

export default BenchmarkGeneralForm;
