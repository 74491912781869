import { Fragment, useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import CheckboxField from 'atoms/FormField/Checkbox';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import RadioGroup, { RadioGroupOptions } from 'atoms/FormField/RadioGroup';
import TextAreaField from 'atoms/FormField/TextArea';
import { ColorNames } from 'utils/utils-colors';
import './ConfirmPopup.scss';

/*
 * ConfirmPopup is a popup that interract the user to cancel or confirm an action.
 * it appears with:
 *  - a text 'content', used to explain the confirmation that is requested
 *  - a confirm button, which we can modify the name
 *  - a cancel button, which we can modify the name too
 *
 * Extra configurable fields option:
 *  - It is possible to add extra configurable fields (textarea, input, checkbox) to add
 *    extra information at the confirmation. For example, this could be used to add
 *    'specific notes' to the action that will be saved alongside.
 *  - by default there is no configurable fields
 */

// Extra configurable fields supported types
export enum TEMPLATE_TYPES {
  TEXTAREA = 'TEXTAREA',
  INPUT = 'INPUT',
  CHECKBOX = 'CHECKBOX',
  RADIO_GROUP = 'RADIOGROUP',
}

// object to define various types of extra configurable fields
export interface TemplateData {
  templateType: TEMPLATE_TYPES;
  value: string | boolean;
  onChange: (key: string, value: any) => void;
  required?: boolean;
  placeholder?: string;
  label?: string | null;
  disabled?: boolean;
  fieldKey: string;
  type?: InputFieldType;
  min?: number;
  max?: number;
  toggle?: boolean;
  radio?: boolean;
  options?: RadioGroupOptions[];
  dataTest?: string;
}

const getTemplateType = (data: TemplateData) => {
  switch (data.templateType) {
    case TEMPLATE_TYPES.TEXTAREA:
      return (
        <TextAreaField
          label={data.label}
          required={data.required}
          placeholder={data.placeholder}
          dataTest={data.dataTest}
          value={data.value as string}
          onChange={data.onChange}
          fieldKey={data.fieldKey}
          disabled={data.disabled}
        />
      );
    case TEMPLATE_TYPES.INPUT:
      return (
        <InputField
          label={data.label}
          fieldKey={data.fieldKey}
          dataTest={data.dataTest}
          type={data.type}
          value={data.value as string}
          onChange={data.onChange}
          min={data.min}
          max={data.max}
        />
      );
    case TEMPLATE_TYPES.CHECKBOX:
      return (
        <CheckboxField
          value={data.value as boolean}
          toggle={data.toggle}
          disabled={data.disabled}
          dataTest={data.dataTest}
          radio={data.radio}
          label={data.label}
          fieldKey={data.fieldKey}
          onChange={data.onChange}
        />
      );

    case TEMPLATE_TYPES.RADIO_GROUP:
      return (
        <RadioGroup
          options={data.options}
          label={data.label}
          fieldKey={data.fieldKey}
          dataTest={data.dataTest}
          required={data.required}
          onChange={data.onChange}
          value={data.value as string}
        />
      );
    default:
      return <></>;
  }
};

const triggerID: string = 'popup-trigger';

export interface ConfirmPopupProps {
  onCancel?: (args: { clickOutside: boolean }) => void;
  onConfirm: () => void;
  trigger: boolean | JSX.Element;
  content: string;
  confirmButtonText: string;
  cancelButtonText: string;
  templateData?: TemplateData[];
  color?: ColorNames;
}

const ConfirmPopup = ({
  onCancel,
  onConfirm,
  content,
  confirmButtonText,
  cancelButtonText,
  trigger,
  templateData,
  color = ColorNames.DEFAULT,
}: ConfirmPopupProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const isBooleanTrigger: boolean = typeof trigger === 'boolean';

  const onCloseModal = (e: React.SyntheticEvent<HTMLElement>, clickOutside: boolean): void => {
    if (onCancel) onCancel({ clickOutside });

    setOpen(false);
  };

  useEffect(() => {
    if (isBooleanTrigger && trigger) {
      const defaultTrigger = document.getElementById(triggerID);

      if (defaultTrigger) defaultTrigger.click();
    }
  });

  const disableConfirmButton = (): boolean => {
    if (templateData && templateData.length > 0) {
      const invalid = templateData.find(
        data => data.required && !data.value && data.templateType !== TEMPLATE_TYPES.CHECKBOX,
      );
      return invalid ? true : false;
    }
    return false;
  };

  const btnColor: ColorNames = !color || color === ColorNames.DEFAULT ? ColorNames.GREY : color;

  const stopPropagation = (e: React.MouseEvent<HTMLElement>): void => e.stopPropagation();

  return (
    <Modal
      onClose={e => onCloseModal(e, true)}
      onOpen={() => setOpen(true)}
      open={open}
      className="confirmation-modal"
      trigger={isBooleanTrigger ? <div id={triggerID}></div> : trigger}
      onClick={stopPropagation}
    >
      <Modal.Content className={`${btnColor}-border`}>
        <Modal.Description className="title">
          <div
            className="editor-to-html"
            dangerouslySetInnerHTML={{
              __html: content as any,
            }}
          ></div>
        </Modal.Description>

        {/* Template Fields  */}
        <div className="template-row">
          {templateData?.map((data, index) => <Fragment key={index}>{getTemplateType(data)}</Fragment>)}
        </div>

        {/* Buttons  */}
        <div className="btn-container">
          <Button
            className={`btn ${btnColor}-outline`}
            onClick={e => onCloseModal(e, false)}
            data-test="popup-cancel-button"
          >
            {cancelButtonText}
          </Button>
          <Button
            className={`btn ${btnColor}-bg`}
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            data-test="popup-confirm-button"
            disabled={disableConfirmButton()}
          >
            {confirmButtonText}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default ConfirmPopup;
