import React, { useRef, useState } from 'react';
import { Button, Image, Input, Menu, MenuItem, Segment, Sticky } from 'semantic-ui-react';
import _ from 'lodash';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { TabReact } from 'atoms/TabReact';
import TabContainer from 'components/TabContainer';
import './TestPage.scss';

const Placeholder = () => <Image src="https://react.semantic-ui.com/images/wireframe/paragraph.png" />;

const TabToolbar = () => {
  const [activeItem, setActiveItem] = useState<string>('bio');
  const handleItemClick = (name: string) => setActiveItem(name);
  return (
    <div className="stickMenu">
      <Menu
        attached="top"
        tabular
        style={{ backgroundColor: '#fff', paddingTop: '1em' }}
      >
        <MenuItem
          as="a"
          name="bio"
          active={activeItem === 'bio'}
          onClick={() => handleItemClick('bio')}
        />
        <MenuItem
          as="a"
          name="photos"
          active={activeItem === 'photos'}
          onClick={() => handleItemClick('photos')}
        />
        <Menu.Menu position="right">
          <MenuItem>
            <Input
              transparent
              icon={{ name: 'search', link: true }}
              placeholder="Search users..."
            />
          </MenuItem>
        </Menu.Menu>
      </Menu>
    </div>
  );
};

const TabOne = (): React.ReactElement => {
  return (
    <TabContainer
      tabTitle={'Tab One'}
      // tabToolbar={<TabToolbar />}
    >
      <>
        {_.times(15, i => (
          <Placeholder key={i} />
        ))}
      </>
    </TabContainer>
  );
};
const TabTwo = (): React.ReactElement => {
  return (
    <TabContainer
      tabTitle={'Tab Two'}
      // tabToolbar={<TabToolbar />}
    >
      <>
        {_.times(5, i => (
          <Placeholder key={i} />
        ))}
      </>
      <div id="stickMenu">
        <Menu
          attached="top"
          tabular
          style={{ backgroundColor: '#fff', paddingTop: '1em' }}
        >
          <Menu.Menu position="right">
            <MenuItem>
              <Input
                transparent
                icon={{ name: 'search', link: true }}
                placeholder="Search users..."
              />
            </MenuItem>
          </Menu.Menu>
        </Menu>
      </div>
      <>
        {_.times(15, i => (
          <Placeholder key={i} />
        ))}
      </>
    </TabContainer>
  );
};
const TestPage = () => {
  throw new Error('Test Error');

  const tabMenu = [
    {
      label: 'Test 1',
      key: 'test1',
      customTab: <TabOne />,
    },
    {
      label: 'Test 2',
      key: 'test2',
      customTab: <TabTwo />,
    },
  ];

  const getPageHeaderProps = (): PageHeaderProps => {
    const throwError = () => {
      console.log('Throwing Error');
      throw new Error('Test Error');
    };

    const headerButtons = {
      headerToolbar: (
        <Button
          primary
          onClick={throwError}
        >
          Create
        </Button>
      ),
    };
    return {
      showBreadCrumb: true,
      customTitle: <h1>Test Page</h1>,
      headerButtons,
    };
  };

  return (
    <PageLayout pageHeader={getPageHeaderProps()}>
      <>
        {_.times(3, i => (
          <Placeholder key={i} />
        ))}
      </>
      {/* <TabToolbar /> */}
      <TabReact tabMenu={tabMenu} />
      {/* <div>
          <>
            {_.times(15, (i) => (
              <Placeholder key={i} />
            ))}
          </>
          <div id="stickMenu">
            <Menu
              attached='top'
              tabular
              style={{ backgroundColor: '#fff', paddingTop: '1em' }}
            >
              <MenuItem
                as='a'
                name='bio'
                active={activeItem === 'bio'}
                onClick={() => handleItemClick('bio')}
              />
              <MenuItem
                as='a'
                name='photos'
                active={activeItem === 'photos'}
                onClick={() => handleItemClick('photos')}
              />
              <Menu.Menu position='right'>
                <MenuItem>
                  <Input
                    transparent
                    icon={{ name: 'search', link: true }}
                    placeholder='Search users...'
                  />
                </MenuItem>
              </Menu.Menu>
            </Menu>
          </div>
          <div>
            {Array.from({ length: 5 }).map((_, i) => (
              <Image key={i} src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
            ))}
          </div>
          <>
            {_.times(15, (i) => (
              <Placeholder key={i} />
            ))}
          </>
        </div> */}
    </PageLayout>
  );
};

export default TestPage;
