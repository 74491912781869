import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { TransactionProps } from './types';

interface TransactionAbortOrReinstateQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string | undefined;
  params: TransactionProps['historyGeneral'];
}

const TRANSACTION_ABORT_OR_REINSTATE = (id: string | undefined, activeTransaction: TransactionProps) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Transaction Abort Or Reinstate: currentLandlordEntityId is required');

  const params = [...activeTransaction.historyGeneral];

  const variables: TransactionAbortOrReinstateQueryVariables = {
    currentLandlordEntityId,
    transactionId: id,
    params,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $transactionId: ID!, $params: [HistoryGeneralInput]) {
        transactionAbortOrReinstate(
          currentLandlordEntityId: $currentLandlordEntityId
          transactionId: $transactionId
          params: $params
        ) {
          id
          historyGeneral {
            date
            documents {
              document {
                id
                name
                path
                public
                url
              }
              documentTypeId
            }
            status
            notes
            user {
              id
              firstName
              lastName
              photoUrl
            }
          }
        }
      }
    `,
    variables,
  };
};

export const transactionAbortOrReinstate = createAsyncThunk(
  'transactions/transactionAbortOrReinstate',
  async ({ id }: { id: string | undefined }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
    } = getState() as RootState;

    const response = await client.mutate(TRANSACTION_ABORT_OR_REINSTATE(id, activeTransaction));
    return response;
  },
);
