import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client, stagingClient } from 'apollo';
import { RootState } from 'store';
import { checkIfLocalEnv } from 'utils/tsHelper';

interface PrepTransactionImageAnswerQueryVariables {
  transactionId: string | undefined;
  fileName: string;
  mimeType: string;
}

const PREP_TRANSACTION_DOC_FOR_UPLOAD = (fileName: string, mimeType: string, transactionId: string | undefined) => {
  if (!transactionId) throw new Error('Prep Transaction Document For Upload: transactionId is required');

  const variables: PrepTransactionImageAnswerQueryVariables = {
    fileName,
    mimeType,
    transactionId,
  };

  return {
    query: gql`
      query ($fileName: String!, $mimeType: String!, $transactionId: ID!) {
        prepTransactionDocument(fileName: $fileName, mimeType: $mimeType, transactionId: $transactionId) {
          cdnUrl
          uploadUrl
          path
        }
      }
    `,
    variables,
  };
};

export const prepTransactionDocForUpload = createAsyncThunk(
  'transactions/prepTransactionDocForUpload',
  async ({ files }: { files: any; docId?: string }, { getState }) => {
    const file = files[0];
    const {
      transactionDetail: {
        activeTransaction: { id },
      },
    } = getState() as RootState;
    const response = await client.query(PREP_TRANSACTION_DOC_FOR_UPLOAD(file.file.name, file.file.type, id));

    return response;
  },
);
