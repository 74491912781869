import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListPropertyFloorsQueryVariables {
  currentLandlordEntityId: string;
  propertyId: string;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  sortOrder: SortOrder;
}

interface ListPropertyFloorsArgs {
  propertyId?: string;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
}

const LIST_PROPERTY_FLOORS = (propertyId: any, first?: number, last?: number, after?: string, before?: string) => {
  if (!propertyId) throw new Error('List Property Floors: No propertyId');

  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();
  if (!currentLandlordEntityId) throw new Error('List Property Floors: No currentLandlordEntityId');

  const variables: ListPropertyFloorsQueryVariables = {
    currentLandlordEntityId,
    propertyId: propertyId,
    first,
    last,
    after,
    before,
    sortOrder: SortOrder.Asc,
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $propertyId: ID!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $sortOrder: SortOrder!
      ) {
        listPropertyFloors(
          currentLandlordEntityId: $currentLandlordEntityId
          propertyId: $propertyId
          first: $first
          last: $last
          after: $after
          before: $before
          sortOrder: $sortOrder
        ) {
          edges {
            cursor
            node {
              id
              name
              index
              comments
              characteristics
              ceilingHeight
              floorLoading
              activationDate
              deactivationDate
              spaces {
                id
                name
                registeredName
                type
                usedForOccupancyRate
                grossArea
                netArea
                lettableArea
                saleableArea
                efficiencyRatio
                comments
                characteristics
                activationDate
                deactivationDate
              }
              floorPlans {
                id
                reviewFlag
                floorPlan {
                  id
                  name
                  path
                  public
                  url
                }
                revisionDate
                unitsNumber
                whollyOwned
                grossArea
                netArea
                lettableArea
                saleableArea
                efficiencyRatio
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

// TODO: Why do we have a selector between propertyId and id ?  there must be a simplification to do here.
export const listPropertyFloors = createAsyncThunk(
  'properties/listPropertyFloors',
  async ({ propertyId, first, last, after, before }: ListPropertyFloorsArgs, { getState }) => {
    const {
      propertyDetail: {
        activeProperty: { id },
      },
    } = getState() as RootState;
    const propId = propertyId ? propertyId : id;
    if (!propId) throw new Error('List Property Floors: No propertyId');
    const response = await client.query(LIST_PROPERTY_FLOORS(propId, first, last, after, before));
    return response;
  },
);
