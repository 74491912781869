import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import { setAnswerInput } from 'utils/utils-answer';
import { getCurrentDateTimeISO } from 'utils/utils-date';
import { AnswerProps, SetAnswerInputResult } from '../policies';

interface CreateTransactionAnswersQueryVariables {
  answers: SetAnswerInputResult[];
}

const CREATE_TRANSACTION_ANSWERS = async (answers: AnswerProps[]) => {
  if (answers.length === 0) throw new Error('Create Transaction Answers: no answers to create');

  const answersParam = await setAnswerInput(answers);

  const variables: CreateTransactionAnswersQueryVariables = {
    answers: answersParam,
  };

  return {
    mutation: gql`
      mutation ($answers: [TransactionAnswerInput]) {
        createTransactionAnswers(answers: $answers) {
          answer
          answerType
          dateOfAnswer
          fromPolicy
          fromInventory
          paramRef {
            parameterId
            tableId
            index
          }
          user {
            id
            firstName
            lastName
          }
          transaction {
            id
          }
        }
      }
    `,
    variables,
  };
};

// createTransactionAnswers API may also save some answers that have files embedded.
// Those files are uploaded to AWS S3 buckets and we need to wait for this action.
//  => this is why there is a 'await' inside the 'client.mutate'
export const createTransactionAnswers = createAsyncThunk(
  'transactions/createTransactionAnswers',
  async ({ approvedAnswerIds }: { approvedAnswerIds?: string[] }, { getState }) => {
    const {
      transactionDetail: { activeTransactionAnswers, transactionApprovalAnswers },
    } = getState() as RootState;
    const newAnswers: AnswerProps[] = activeTransactionAnswers.filter(
      (answer: AnswerProps) => answer.blockRef !== undefined,
    );

    const approvalAnswers: TransactionApprovalAnswer[] = transactionApprovalAnswers.filter(
      (answer: TransactionApprovalAnswer) => approvedAnswerIds?.includes(answer.id),
    );

    const answers =
      approvedAnswerIds && approvedAnswerIds?.length !== 0
        ? approvalAnswers.map((approvalAnswer: TransactionApprovalAnswer) => {
            return {
              answer: approvalAnswer.answer,
              answerType: approvalAnswer.answerType,
              dateOfAnswer: getCurrentDateTimeISO(),
              fromInventory: approvalAnswer.fromInventory,
              fromPolicy: approvalAnswer.fromPolicy,
              paramRef: approvalAnswer.paramRef,
              transactionId: approvalAnswer.transaction.id,
            };
          })
        : newAnswers;

    const response = await client.mutate(await CREATE_TRANSACTION_ANSWERS(answers as AnswerProps[]));
    return response;
  },
);
