import { ParameterDetailClone, ParameterTableDetailClone } from 'common/_classes';
import _ from 'lodash';
import AnswerTypes from 'common/model/AnswerTypes';
import { checkNotEmpty } from 'utils/tsValidator';

export const parameterDisabled = ({
  name,
  question,
  type,
  answerType,
  choices,
  unit,
  parameterGroup,
  index,
}: ParameterDetailClone): boolean => {
  const groupId: string | null = parameterGroup && parameterGroup.id ? parameterGroup.id : null;
  let isInValid = false;

  switch (answerType) {
    case AnswerTypes.NumberUnit:
      isInValid = !checkNotEmpty(unit);
      if (isInValid) {
        return isInValid;
      }
      break;

    case AnswerTypes.SingleChoice:
    case AnswerTypes.MultiChoice:
      for (let i = 0; i < choices!.length; i++) {
        isInValid = !checkNotEmpty(choices![i].text);
        if (isInValid) {
          return isInValid;
        }
      }
      break;

    default:
      return (
        !checkNotEmpty(name) ||
        !checkNotEmpty(question) ||
        !checkNotEmpty(answerType) ||
        !checkNotEmpty(type) ||
        !checkNotEmpty(groupId) ||
        !checkNotEmpty(index)
      );
  }
  return isInValid;
};

export const tableDisabled = ({
  name,
  type,
  parameterGroup,
  index,
  rowNumber,
  columns,
}: ParameterTableDetailClone): boolean => {
  const groupId: string | null = parameterGroup && parameterGroup.id ? parameterGroup.id : null;
  let isInValid: boolean = false;

  if (!checkNotEmpty(name) || !checkNotEmpty(type) || !checkNotEmpty(groupId) || !checkNotEmpty(index)) {
    return true;
  } else {
    if (rowNumber && checkNotEmpty(rowNumber)) {
      const parameterId = rowNumber.parameter && rowNumber.parameter.id ? rowNumber.parameter.id : null;
      const tableId = rowNumber.table && rowNumber.table.id ? rowNumber.table.id : null;
      isInValid = !checkNotEmpty(parameterId) && !checkNotEmpty(tableId);
      if (isInValid) {
        return isInValid;
      }
    }
    for (let i = 0; i < columns.length; i++) {
      const parameterId: string | null = columns[i].parameter ? columns[i].parameter!.id : null;
      isInValid = !checkNotEmpty(columns[i].title) || !checkNotEmpty(parameterId);

      if (isInValid) {
        return isInValid;
      }
    }
  }
  return isInValid;
};
