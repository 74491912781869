import { LogsType } from '../utils-interfaces';

interface ErrorsAndWarningsProps {
  logs: LogsType[];
}

const ErrorsAndWarnings = ({ logs }: ErrorsAndWarningsProps) => {
  return (
    <div className="item">
      <h6 className="title">Errors and warning in Excel file</h6>
      <ul>
        {logs.length === 0 ? (
          <li>No warnings or errors</li>
        ) : (
          logs.map(({ type, log }, index) => (
            <li key={`log-${index}`}>
              <span className={`${type?.toUpperCase() === 'ERROR' ? 'error' : 'amber'}`}>{type.toLowerCase()}</span>:{' '}
              {log}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default ErrorsAndWarnings;
