import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DASHBOARD_PAGE_TABS } from 'views/dashboard/DashboardTabConfig';
import { setDashboardOption, updateSelectedContextName } from 'store/dashboard/dashboardSlice';
import { updateActivePortfolio } from 'store/dashboard/dashboardSlice';
import { updateActiveProperty, updateForecastView } from 'store/dashboard/forecastsSlice';
import { selectedProperty } from 'store/dashboard/stackingPlanSlice';
import { DASHBOARD_PAGE_SCOPE } from 'common/api/dashboard';
import { DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS } from 'common/api/miscellaneous';

const ChangeContext = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [{ context, propertyId, propertyName, tab }] = useState(Object.fromEntries([...searchParams]));

  useEffect(() => {
    const changeContext = (context: DASHBOARD_PAGE_SCOPE) => {
      if (!context || !propertyId || !propertyName) {
        return;
      }

      const property = {
        id: propertyId,
        buildingName: propertyName,
      };

      const contextName = context.toLocaleUpperCase();
      dispatch(
        setDashboardOption({
          value: context,
        }),
      );
      dispatch(updateActivePortfolio({ portfolio: null }));
      dispatch(
        updateSelectedContextName({
          value: property.buildingName,
          type: contextName,
          id: propertyId,
        }),
      );
      dispatch(updateActiveProperty({ property }));
      dispatch(selectedProperty({ property }));

      if (tab === DASHBOARD_PAGE_TABS.FORECASTING) {
        dispatch(updateForecastView({ value: DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS.GRAPH }));
      }
      setSearchParams({});
    };

    changeContext(context as DASHBOARD_PAGE_SCOPE);
  }, [dispatch, setSearchParams, context, propertyId, propertyName]);
};

export default ChangeContext;
