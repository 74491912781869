import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterGroupDetailClone } from 'common/_classes';
import { RootState } from 'store';

interface CreateParamsGroupParams {
  name: string;
  index: number;
  categoryId: string;
  guideline: any;
}

interface CreateParamsGroupQueryVariables {
  params: CreateParamsGroupParams;
}

const CREATE_PARAMETER_GROUP = (activeGroup: ParameterGroupDetailClone) => {
  const { name, index, category, guideline } = activeGroup;

  if (!name) throw new Error('CREATE_PARAMETER_GROUP: name is required');
  if (!index) throw new Error('CREATE_PARAMETER_GROUP: index is required');
  if (!category || !category.id) throw new Error('CREATE_PARAMETER_GROUP: category id is required');

  const params: CreateParamsGroupParams = {
    name,
    index,
    categoryId: category.id,
    guideline,
  };

  const variables: CreateParamsGroupQueryVariables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: ParameterGroupInput!) {
        createParameterGroup(params: $params) {
          ${ParameterGroupDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createParameterGroup = createAsyncThunk('parameters/createParameterGroup', async (_args, { getState }) => {
  const {
    parametersListing: { activeGroup },
  }: any = getState() as RootState;
  const response = await adminClient.mutate(CREATE_PARAMETER_GROUP(activeGroup));
  return response;
});
