import { Input } from 'semantic-ui-react';
import { InputFieldType } from 'atoms/FormField/Input';
import { addSpaceOrComma, removeSpaceOrComma } from 'utils/utils-number';

const DurationInput = ({
  groupIndex,
  index,
  type,
  value,
  dataTest,
  onChange,
}: {
  groupIndex: number;
  index: number;
  type: string;
  value: string;
  dataTest: string;
  onChange: (groupIndex: number, index: number, type: string, answer: string) => void;
}) => {
  return (
    <Input
      id={`duration-years-id-${groupIndex}-${index}`}
      key={`duration-years-${groupIndex}-${index}`}
      className="duration-inputs border-sm-grayish-magenta-light"
      type={InputFieldType.TEXT}
      placeholder={type}
      data-test={dataTest}
      value={value ? addSpaceOrComma(value, false) : ''}
      onChange={({ target }: { target: EventTarget & HTMLInputElement }) => {
        const answer = removeSpaceOrComma(target.value);
        onChange(groupIndex, index, type, answer);
      }}
      onKeyDown={(e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        if (e.key === '.') {
          return e.preventDefault();
        }
      }}
    />
  );
};

export default DurationInput;
