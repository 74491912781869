import { gql } from '@apollo/client';
import { PropertyClone } from 'common/_classes';
import SortOrder from 'common/model/SortOrder';

// TODO, shall we move LIST_PROPERTIES_FOR_CONTRACT_CREATION to miscellaneous store ?
// also name is not good as it is also used for TRANSACTION

interface ListPropertiesForContractCreationQueryVariables {
  currentLandlordEntityId: string;
  sortOrder: SortOrder;
  first: number;
  last: number | undefined;
  after: string | undefined;
  before: string | undefined;
  ilike: string | undefined;
}

export const LIST_PROPERTIES_FOR_CONTRACT_CREATION = (landlordId: any) => {
  if (!landlordId) throw new Error('List Properties For Contract Creation: landlordId is required');

  const variables: ListPropertiesForContractCreationQueryVariables = {
    currentLandlordEntityId: landlordId,
    sortOrder: SortOrder.Asc,
    first: 100,
    last: undefined,
    after: undefined,
    before: undefined,
    ilike: undefined,
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $sortOrder: SortOrder!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $ilike: String
      ) {
        listProperties(
          currentLandlordEntityId: $currentLandlordEntityId
          sortOrder: $sortOrder
          first: $first
          last: $last
          after: $after
          before: $before
          filters: { Ilike: $ilike }
        ) {
          edges {
            cursor
            node {
              ${PropertyClone.fragments()}
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};
