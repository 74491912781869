import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Image } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './PhotoInput.scss';

export interface InputRefProp {
  current: HTMLInputElement | null;
}

interface PhotoInputProps {
  src?: string | null;
  icon?: string;
  iconClassName?: string;
  disabled?: boolean;
  prepUpload?: Function;
  deletePreviousPhoto?: boolean;
  deleteSelectedPhoto?: Function;
  deletePhoto?: Function;
  clearUploadedPhoto?: string | boolean;
  size?: string;
}

const PhotoInput = ({
  src,
  icon = Icons.UserOutlined,
  iconClassName = '',
  disabled,
  prepUpload,
  deletePreviousPhoto,
  deleteSelectedPhoto,
  deletePhoto,
  clearUploadedPhoto,
}: PhotoInputProps) => {
  const dispatch = useDispatch();

  const uploadPhotoRef: InputRefProp = useRef(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const openUploaded = () => {
    if (uploadPhotoRef && uploadPhotoRef.current && uploadPhotoRef.current) uploadPhotoRef.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files !== null) {
      const file = files[0];
      setImageUrl(URL.createObjectURL(file));
      if (prepUpload) dispatch(prepUpload({ file: files[0] }));
    }
  };

  const onDelete = () => {
    if (imageUrl) {
      if (deleteSelectedPhoto) dispatch(deleteSelectedPhoto());
    } else if (src) {
      if (deletePhoto) dispatch(deletePhoto());
    }
  };

  let photo;
  if ((src || imageUrl) && !deletePreviousPhoto) {
    photo = (
      <Image
        src={imageUrl ? imageUrl : src}
        className="circular uploaded-photo"
      />
    );
  } else {
    photo = (
      <div className="ui image circular uploaded-photo border-xl-orange">
        <Icon
          icon={icon}
          className={`color-blue-desaturated-dark ${iconClassName}`}
        />
      </div>
    );
  }

  useEffect(() => {
    if (clearUploadedPhoto && imageUrl) {
      setImageUrl(null);
    }
    // eslint-disable-next-line
  }, [clearUploadedPhoto]);

  return (
    <div className="custom-image-input">
      {photo}
      {!disabled && (
        <>
          <div
            onClick={openUploaded}
            className="btn-photo color-blue-very-dark-grayish"
            role="button"
            tabIndex={0}
          >
            <input
              ref={uploadPhotoRef}
              type="file"
              className="d-none"
              alt="upload"
              accept="image/png, image/jpeg, image/jpg"
              onChange={e => handleChange(e)}
            />
            <Icon icon={Icons.Document} />
            <span className="text">Browse photo</span>
          </div>
          <div
            className={`btn-photo color-red-soft ${!src && !imageUrl && 'no-cursor'}`}
            onClick={() => onDelete()}
            role="button"
            tabIndex={0}
          >
            <Icon icon={Icons.Delete} />
            <span className="text">Remove photo</span>
          </div>
        </>
      )}
    </div>
  );
};

export default PhotoInput;
