import { ParameterTableListingClone, RowNumberClone } from 'common/_classes';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementNumberRange,
  filterElementStatus,
  filterElementStatusTemplate,
  filterElementText,
} from 'atoms/TableReactPrime/columnTemplates';
import TableValidTypes from 'common/model/TableValidTypes';
import { PARAMETER_TABLE_IMPLEMENTATION } from 'common/api/miscellaneous';
import { ParameterTablesRowActionButtons } from './ParameterTablesRowActionButtons';

const TableValidTypesTranslated = {
  [TableValidTypes.Tab]: 'Tabulation',
  [TableValidTypes.Table]: 'Table',
};

// If rowNumber is null: means there is no contraint on the table
// else
//  - parameter is defined, this means the contraint is linked to a parameter
//  - table is defined, this means the contraint is linked to a table
export interface TableConstraintInfo {
  name: string;
  type: PARAMETER_TABLE_IMPLEMENTATION | string;
  typeText: string;
  link: string;
}

export const getTableRowNumberConstraint = (rowNumber: RowNumberClone): TableConstraintInfo => {
  const result: TableConstraintInfo =
    rowNumber && rowNumber.parameter
      ? {
          name: rowNumber.parameter.name,
          type: PARAMETER_TABLE_IMPLEMENTATION.PARAMETER,
          typeText: 'Parameters: ',
          link: `/parameters/${rowNumber.parameter.id}/details`,
        }
      : rowNumber && rowNumber.table
        ? {
            name: rowNumber.table.name,
            type: PARAMETER_TABLE_IMPLEMENTATION.TABLE,
            typeText: 'Tables: ',
            link: `/parameters/table/${rowNumber.table.id}/details`,
          }
        : {
            name: '',
            type: '',
            typeText: '',
            link: '',
          };
  return result;
};

const statuses: StatusBadgeArgument[] = [{ value: 'DRAFT', color: BadgeColor.ORANGE }];

export class ParametersTableRow {
  id: string;
  name: string | null;
  numberOfParameters: number;
  rowNumber?: RowNumberClone;
  type: string;
  parameterGroupCategoryName: string;
  parameterGroupName: string;
  index: number;
  version: number;
  status: string;

  constructor(parameter: ParameterTableListingClone) {
    this.id = parameter.id;
    this.name = parameter.name;
    this.numberOfParameters = parameter.columns ? parameter.columns.length : 0;
    this.type = TableValidTypesTranslated[parameter.type];
    this.rowNumber = parameter.rowNumber;
    this.parameterGroupCategoryName = parameter.parameterGroup.category.name;
    this.parameterGroupName = parameter.parameterGroup.name;
    this.index = Number(parameter.index);
    // TODO: following are not yet implemented
    this.version = 1;
    this.status = statuses[0].value;
  }
}

export const parametersColumnConfig = (
  getRowConstraint: Function,
  onViewDetails: ViewDetailsProps,
): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Name',
      filter: true,
      sortable: true,
      filterPlaceholder: 'Search by Name',
      filterField: 'name',
      dataType: 'text',
    },
    {
      field: 'numberOfParameters',
      header: 'Number of parameters',
      filter: true,
      sortable: true,
      filterPlaceholder: 'Search by Number of parameters',
      filterField: 'numberOfParameters',
      dataType: 'numeric',
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      showFilterMatchModes: false,
    },
    {
      field: 'type',
      header: 'Table presentation',
      filterPlaceholder: 'Search by Table presentation',
      filter: true,
      sortable: true,
      filterField: 'type',
      dataType: 'text',
      showFilterMatchModes: false,
      showFilterMenuOptions: false,
      filterElement: options =>
        filterElementText(options, [
          TableValidTypesTranslated[TableValidTypes.Tab],
          TableValidTypesTranslated[TableValidTypes.Table],
        ]),
      filterMatchMode: 'in',
    },
    {
      field: 'rowNumber',
      header: 'Row size constraint',
      filterPlaceholder: 'Search by Row size constraint',
      filter: true,
      filterField: 'rowNumber',
      sortable: false,
      body: row => getRowConstraint(row.rowNumber),
      filterMatchMode: 'custom',
      filterMatchModeOptions: [{ label: 'Contains', value: 'custom' }],
      filterFunction: (value: RowNumberClone, filter: string) => {
        if (!filter) {
          return true;
        }
        const name = getTableRowNumberConstraint(value)?.name;
        if (!name) {
          return false;
        }
        return name.toLowerCase().includes(filter.toLowerCase());
      },
    },
    {
      field: 'parameterGroupCategoryName',
      header: 'Category',
      filter: true,
      filterPlaceholder: 'Search by Category',
      filterField: 'parameterGroupCategoryName',
      sortable: true,
    },
    {
      field: 'parameterGroupName',
      header: 'Group',
      filter: true,
      filterPlaceholder: 'Search by Group',
      filterField: 'parameterGroupName',
      sortable: true,
    },
    {
      field: 'index',
      header: 'Group index',
      filterPlaceholder: 'Search by Group index',
      filter: true,
      sortable: true,
      filterField: 'index',
      dataType: 'numeric',
    },
    {
      field: 'version',
      header: 'Version',
      filterPlaceholder: 'Search by Version',
      filter: true,
      sortable: true,
      showFilterMatchModes: false,
      filterField: 'version',
      dataType: 'numeric',
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      body: (row: ParametersTableRow) => row.version.toFixed(1),
    },
    {
      field: 'status',
      header: 'Status',
      filter: true,
      sortable: true,
      body: (row: ParametersTableRow) => filterElementStatusTemplate(row.status, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: ParametersTableRow) => (
        <ParameterTablesRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
