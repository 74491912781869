import Decimal from 'decimal.js';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const CapitalAllowance = ({ mode }: ModeProps) => {
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const dispatch = useAppDispatch();

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(updateActiveContractEventInput({ key, value: value ? new Decimal(value) : null }));
  };

  return (
    <div className="grid">
      <div className="col-2">
        <InputField
          label="Capital allowance"
          type={InputFieldType.NUMBER}
          unit="(HK$)"
          fieldKey="capitalAllowance"
          dataTest="capital-allowance-text-field"
          disabled={mode === MODE_OPTIONS.READ}
          value={activeContractEvent?.capitalAllowance?.toString() || null}
          onChange={onChangeDecimal}
          isDecimal={true}
          delimiter="COMMA"
        />
      </div>
    </div>
  );
};

export default CapitalAllowance;
