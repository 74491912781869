import { Fragment, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Floor, Space } from 'utils/types/stackingPlan';
import { addSpaceOrComma } from 'utils/utils-number';
import './StackingPlanSideSections.scss';

const EmptyAreaDetails = () => {
  const [floors, setFloor] = useState<Space[]>([]);

  const { selectedFloor, floorsWithSpaces } = useAppSelector((state: RootState) => state.stackingPlan);

  useEffect(() => {
    const floorDetail: Floor | undefined = floorsWithSpaces.find((floor: Floor) => floor.id === selectedFloor.floorId);
    if (floorDetail) {
      setFloor(floorDetail.spaces);
    } else {
      setFloor([]);
    }
  }, [selectedFloor]);

  const { floorName, floorNumber, floorArea } = selectedFloor;
  return (
    <>
      <p className="area-head color-blue-very-dark">Empty area description ({floorName || `Floor ${floorNumber}`})</p>
      <Grid className="m-none">
        <Grid.Row className="empty-area-title p-t-sm border-sm-grayish-magenta-light bg-gray-very-light">
          <Grid.Column
            width={8}
            className="color-blue-very-dark"
          >
            Unit name
          </Grid.Column>
          <Grid.Column
            width={8}
            className="color-blue-very-dark"
          >
            Area (sqft, gross)
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="empty-area-description m-t-sm">
          {floors.map(({ name, grossArea }: Space, index: number) => (
            <Fragment key={index}>
              <Grid.Column
                width={8}
                className="color-blue-very-dark"
              >
                {name}
              </Grid.Column>
              <Grid.Column
                width={8}
                className="color-blue-very-dark"
              >
                {addSpaceOrComma(grossArea, false)}
              </Grid.Column>
            </Fragment>
          ))}
        </Grid.Row>
        <hr className="w-100" />
        <Grid.Row>
          <Grid.Column width={8}>Total Area</Grid.Column>
          <Grid.Column width={8}>{addSpaceOrComma(floorArea, false)}</Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default EmptyAreaDetails;
