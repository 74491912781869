import { KeyboardEvent } from 'react';
import _ from 'lodash';

export enum KeyboardKeys {
  Backspace = 'Backspace',
  Delete = 'Delete',
  Space = 'Space',
  Tab = 'Tab',
  ArrowUp = 'ArrowUp',
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  ArrowDown = 'ArrowDown',
  Control = 'Control',
  Enter = 'Enter',
  Zero = '0',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Slash = '/',
  Dot = '.',
  Dash = '-',
  a = 'a',
  c = 'c',
  v = 'v',
  x = 'x',
}

/**
 * Validate the number input on key down event
 * @returns
 */
export const numberInputValidator = (
  e: KeyboardEvent<HTMLInputElement>,
  isDecimalAllowed: boolean,
  isNegativeAllowed: boolean,
  currentValue: string | number | null,
) => {
  const isFirstKey = (value: string | number | null): boolean => {
    return value && value.toString().length ? false : true;
  };
  let previousKey: string = '';
  if (e.key === KeyboardKeys.Dot && String(currentValue).includes(KeyboardKeys.Dot)) {
    return e.preventDefault();
  }

  let allowedKeys: KeyboardKeys[] = [
    KeyboardKeys.Zero,
    KeyboardKeys.One,
    KeyboardKeys.Two,
    KeyboardKeys.Three,
    KeyboardKeys.Four,
    KeyboardKeys.Five,
    KeyboardKeys.Six,
    KeyboardKeys.Seven,
    KeyboardKeys.Eight,
    KeyboardKeys.Nine,
    KeyboardKeys.Backspace,
    KeyboardKeys.Tab,
    KeyboardKeys.ArrowUp,
    KeyboardKeys.ArrowRight,
    KeyboardKeys.ArrowLeft,
    KeyboardKeys.ArrowDown,
    KeyboardKeys.Control,
  ];

  if (isNegativeAllowed && isFirstKey(currentValue)) {
    allowedKeys = [...allowedKeys, KeyboardKeys.Dash];
  }
  if (previousKey === KeyboardKeys.Control) {
    allowedKeys = [...allowedKeys, KeyboardKeys.a, KeyboardKeys.c, KeyboardKeys.v, KeyboardKeys.x];
  }

  if (isDecimalAllowed && currentValue) {
    if (!String(currentValue).includes(KeyboardKeys.Dot)) {
      allowedKeys = [...allowedKeys, KeyboardKeys.Dot];
    }
  }
  previousKey = e.key;
  return !allowedKeys.includes(e.key as KeyboardKeys) && e.preventDefault();
};

/**
 * Calculate the frequency if the character in string
 * @param string
 * @param character
 * @returns
 */
const count = (string: string, character: string): number => _.countBy(string)[character] || 0;

const isHighlighting = (): boolean => {
  // detects mouse is highlighting a text
  return window.getSelection && window.getSelection()?.type === 'Range';
};

/**
 * Validate the input key on key down event
 * @param e
 * @returns
 */
export const dateInputValidator: (e: React.KeyboardEvent<HTMLInputElement>) => false | void = (
  e: React.KeyboardEvent<HTMLInputElement>,
) => {
  const key = e.key as KeyboardKeys;

  const otherKeys: KeyboardKeys[] = [
    KeyboardKeys.Backspace,
    KeyboardKeys.Tab,
    KeyboardKeys.ArrowUp,
    KeyboardKeys.ArrowRight,
    KeyboardKeys.ArrowLeft,
    KeyboardKeys.ArrowDown,
  ];

  const allKeys: KeyboardKeys[] = [
    KeyboardKeys.Zero,
    KeyboardKeys.One,
    KeyboardKeys.Two,
    KeyboardKeys.Three,
    KeyboardKeys.Four,
    KeyboardKeys.Five,
    KeyboardKeys.Six,
    KeyboardKeys.Seven,
    KeyboardKeys.Eight,
    KeyboardKeys.Nine,
    KeyboardKeys.Slash,
    ...otherKeys,
  ];

  if ((e.ctrlKey || e.metaKey) && key === KeyboardKeys.c) {
    return;
  }

  const target = e.target as HTMLInputElement; // Cast the e.target to HTMLInputElement
  const value = target.value; // Now you can access the value property without TypeScript errors

  if (value) {
    if (value.length >= 10 && isHighlighting()) {
      if (!allKeys.includes(key)) {
        e.preventDefault();
      } else if (key === KeyboardKeys.Slash && count(value, KeyboardKeys.Slash) >= 2) {
        e.preventDefault();
      }
    } else if (value.length >= 10 && !otherKeys.includes(key)) {
      e.preventDefault();
    } else if (key === KeyboardKeys.Slash && count(value, KeyboardKeys.Slash) >= 2) {
      e.preventDefault();
    } else {
      return !allKeys.includes(key) && e.preventDefault();
    }
  }
};
