import BuildingFeatures from 'common/model/BuildingFeatures';
import BuildingGrade from 'common/model/BuildingGrade';
import PropertyType from 'common/model/PropertyType';

export class CBuildingFeatures {
  centralAc: boolean;
  coveredAccessToMtr: boolean;
  curtainWall: boolean;
  grade: BuildingGrade;
  name: string;
  useType: PropertyType[];

  constructor(input: BuildingFeatures) {
    this.centralAc = input.centralAc;
    this.coveredAccessToMtr = input.coveredAccessToMtr;
    this.curtainWall = input.curtainWall;
    this.grade = input.grade;
    this.name = input.name;
    this.useType = input.useType;
  }

  static fragment() {
    return `
      centralAc
      coveredAccessToMtr
      curtainWall
      grade
      name
      useType
    `;
  }
}
