import { Editor } from 'tinymce';

/**
 * Method to get only the number of the Clause Index
 */
export const getClauseIndexFrom = (editor: Editor, id: String) => {
  // Create the query and get the Node by ID
  const queryId: string = `div[data-node-id="${id}"]`;
  const parentElement = editor.dom.getRoot().querySelector(queryId);

  // Get the Index by the attribute contenteditable = "false"
  const indexQuery: string = 'div[contenteditable="false"]';
  const indexElement = parentElement?.querySelector(indexQuery);

  // Check if exist
  if (!indexElement) return null;

  // Copy the inner content
  const innerHTML: string = indexElement.innerHTML;

  // Check if there is another element tag inside of the Index. Then return the current number
  return innerHTML.indexOf('<') < 0 ? innerHTML : innerHTML.substring(0, innerHTML.indexOf('<'));
};
