import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import { Editor } from 'tinymce';
import { v4 } from 'uuid';
import NodeType from 'common/model/NodeType';
import { CREATE_NODE } from './createNode';
import { ContentNodeType } from './types';

// Note about text-nodes.
//  The field 'level' is used to differentiate the text-nodes levels
//  e.g. to define different colors depending on the level. for text-nodes
//  level is in {1, 2, 3}
const createTextBlockNode = createAsyncThunk(
  'nodes/createTextBlockNode',
  async (args: { editor: Editor; level: number }, { getState }) => {
    const { editor, level } = args;
    const state = getState() as RootState;
    const { activeProvision } = state.provisionDetail;
    const content = editor.selection.getContent();

    const bodyRequest = {
      level,
      id: v4(),
      type: NodeType.Text,
      name: content,
      provisionId: activeProvision.id,
      formatterId: '',
    };

    const responseCreate = await adminClient.mutate(CREATE_NODE(bodyRequest));
    const { id } = responseCreate.data.createNode;

    const node = editor.dom.create('code', {}, content);

    node.classList.add('text-block-wrapper');
    node.setAttribute('data-node-id', id || '');
    node.setAttribute('data-node-type', ContentNodeType.TEXT);
    node.setAttribute('data-node-level', `${level}`);

    editor.selection.setNode(node);

    return responseCreate;
  },
);

export { createTextBlockNode };
