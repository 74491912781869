import { useCallback, useState } from 'react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import 'components/Editor/PageEditor/PageEditor.scss';
import { DocumentTypeClone } from 'common/api/miscellaneous';
import { SequenceProps } from 'common/api/policies';
import SequenceList from '../SequenceList';

const SequenceBox = (): JSX.Element => {
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);
  const {
    activePolicy: { documentTypeIds },
    activePolicySequences,
  } = useAppSelector((state: RootState) => state.policyDetail);

  //TODO: maybe we can get the documentTypeIds, directly from activePolicySequences?

  //list the documentTypesList selected in this policy
  const documentTypesListSelected: DocumentTypeClone[] = documentTypesList.filter((element: DocumentTypeClone) =>
    documentTypeIds.includes(element.id),
  );

  const [activeDocTypeId, setActiveDocId] = useState<string>(documentTypeIds[0]);

  const handleChangeEditorTabs = (value: string): void => {
    setActiveDocId(value);
  };

  const SequenceListRender = useCallback((): JSX.Element => {
    const sequenceIndex: number = activePolicySequences.findIndex(
      sequence => sequence.documentType?.id === activeDocTypeId,
    );
    const currentSequence: SequenceProps = activePolicySequences[sequenceIndex];

    return (
      <SequenceList
        sequence={currentSequence}
        sequenceIndex={sequenceIndex}
      />
    );
  }, [activeDocTypeId, activePolicySequences]);

  return (
    <div className="page-body-container">
      <div
        className="editor-tabs"
        data-test="documents-tabs"
      >
        {documentTypesListSelected?.map((element: DocumentTypeClone) => (
          <div
            className={activeDocTypeId === element.id ? 'is-active' : ''}
            onClick={() => handleChangeEditorTabs(element.id)}
            key={element.id}
          >
            {element.name}
          </div>
        ))}
      </div>
      <SequenceListRender />
    </div>
  );
};

export default SequenceBox;
