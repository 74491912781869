import { createSlice } from '@reduxjs/toolkit';
import { CFormatterDetail, IFormatterDetail, IFunctionInputClone } from 'common/_classes';
import { toast } from 'react-toastify';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonMultiChoice, Choice, FormatterAnswerInput, initFormatterAnswer } from 'common/api/formatters';
import { createFormatter, executeBaseFormatter, getFormatter, updateFormatter } from 'common/api/formatters';
import { getFrozenObjectCopy, getNewReviewer } from 'utils/tsHelper';

interface FormatterDetailState {
  activeFormatter: IFormatterDetail;
  isLoading: boolean;
  isSearching: boolean;
  answerInputs: FormatterAnswerInput[];
  executionResult: string;
  activeFormatterFrozenCopy: Readonly<IFormatterDetail>;
  resultLoader: boolean;
}

const initFormatter: IFormatterDetail = CFormatterDetail.init(getNewReviewer());

const newInput: IFunctionInputClone = {
  type: null,
  label: null,
};

const initialState: FormatterDetailState = {
  activeFormatter: initFormatter,
  isLoading: false,
  isSearching: false,
  answerInputs: [],
  executionResult: '',
  activeFormatterFrozenCopy: initFormatter,
  resultLoader: false,
};

const formatterDetailSlice = createSlice({
  name: 'formatterDetail',
  initialState,
  reducers: {
    clearFormatterExecutionResult: state => {
      state.executionResult = '';
    },
    updateFormatterReviewedStatus: (state, action) => {
      const { reviewStatus, reviewFlag } = action.payload;
      state.activeFormatter.reviewStatus = reviewStatus;
      state.activeFormatter.reviewFlag = reviewFlag;
    },
    createNewFormatter: state => {
      state.activeFormatter = initFormatter;
      state.answerInputs = [];
      state.executionResult = '';
      state.resultLoader = false;
    },
    /*
     * NOTE:
     * key type can be improved by: type FormatterDetailKey = keyof FormatterDetail;
     * value can be asserted by isOfType(key: FormatterDetailKeys, value: any): value is FormatterDetail[FormatterDetailKeys] {}
     * This is likely overkill, as we do not want hardcoded logic to asses types, this should happen at runtime.
     * Hopefully this will be resolved with better type implementation in the components or in state management.
     */
    updateActiveFormatter: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeFormatter[key] = value;
    },
    addFormatterInput: state => {
      let inputs = state.activeFormatter.inputs;
      inputs = [...inputs, newInput];
      state.activeFormatter.inputs = inputs;
    },
    /*
     * See note above for updateActiveFormatter
     */
    updateFormatterInput: (state, action) => {
      const { key, value, index } = action.payload;

      // @ts-ignore
      state.activeFormatter.inputs[index][key] = value;
      if (key === 'type' && state.answerInputs[index]) {
        state.answerInputs[index].answer = initFormatterAnswer(value);
        state.answerInputs[index].answerType = value;
      }
    },
    deleteFormatterInput: (state, action) => {
      const { index } = action.payload;
      const activeFormatter = state.activeFormatter;
      activeFormatter.inputs = activeFormatter.inputs.filter((value, idx: number) => idx !== index);

      state.answerInputs = state.answerInputs.filter((value, idx: number) => idx !== index);
    },
    updateMultiChoiceAnswer: (state, action) => {
      const { index, checked, value } = action.payload;
      const answer = state.answerInputs[index].answer as AnswerJsonMultiChoice;

      if (checked) {
        answer.values.push(value);
        answer.inverses = answer.inverses.filter(val => val !== value);
      } else {
        answer.inverses.push(value);
        answer.values = answer.values.filter(val => val !== value);
      }
    },
    resetMultiChoiceAnswer: (state, action) => {
      const answer = state.answerInputs[action.payload.index].answer as AnswerJsonMultiChoice;
      answer.inverses = [...answer.values, ...answer.inverses];
      answer.values = [];
    },

    updateSingleChoiceAnswer: (state, action) => {
      const { index, value } = action.payload;
      const answer = state.answerInputs[index].answer as AnswerJsonMultiChoice;

      answer.values = [value];

      let found = false;
      answer.inverses = [];

      answer.choices.forEach(({ value: val }) => {
        if (val === value && !found) {
          found = true;
        } else {
          answer.inverses.push(val);
        }
      });
    },
    testAddChoice: (state, action) => {
      const { index, text, value } = action.payload;
      const answer = state.answerInputs[index].answer as AnswerJsonMultiChoice;

      const optionExists = answer.choices.findIndex(({ value: optionValue }) => optionValue === value);

      if (optionExists === -1) {
        answer.choices.push({ text, value });
        answer.inverses.push(text);
      } else {
        toast.warn('Option already exists');
      }
    },
    testDeleteChoice: (state, action) => {
      const { index, value } = action.payload;
      const answer = state.answerInputs[index].answer as AnswerJsonMultiChoice;

      answer.values = answer.values.filter((val: string) => val !== value);

      let found = false;
      const newChoices: Choice[] = [];
      answer.inverses = [];

      answer.choices.forEach(obj => {
        const { value: val } = obj;

        if (val === value && !found) {
          found = true;
        } else {
          newChoices.push(obj);
          answer.inverses.push(val);
        }
      });

      answer.choices = newChoices;
    },
    testUpdateAnswerInput: (state, action) => {
      const { index, answerType, label, value, key } = action.payload;
      if (!state.answerInputs[index]) {
        const answer = initFormatterAnswer(answerType);

        state.answerInputs.push({
          answer,
          answerType,
          label,
        });
      } else {
        let answer;

        switch (answerType) {
          case AnswerTypes.SingleChoice:
          case AnswerTypes.MultiChoice:
            return;

          case AnswerTypes.Time:
          case AnswerTypes.Duration:
          case AnswerTypes.NumberUnit:
            answer = { ...state.answerInputs[index].answer };

            if (key) {
              answer = { ...answer, [key]: value };
            }
            break;

          default:
            answer = {
              value: value,
            };
        }

        state.answerInputs[index].answer = answer;
      }
    },
  },
  extraReducers: builder => {
    //createFormatter
    builder.addCase(createFormatter.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createFormatter.fulfilled, (state, action) => {
      const { createFormatter } = action.payload.data;
      state.activeFormatter = createFormatter;
    });
    builder.addCase(createFormatter.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('createFromatter API request rejected');
    });

    //updateFormatter
    builder.addCase(updateFormatter.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateFormatter.fulfilled, (state, action) => {
      const { updateFormatter } = action?.payload?.data;
      state.activeFormatter = updateFormatter;
      state.activeFormatterFrozenCopy = getFrozenObjectCopy(updateFormatter) as IFormatterDetail;
      state.isLoading = false;
      toast.success('Formatter updated successfully');
    });
    builder.addCase(updateFormatter.rejected, (state, action) => {
      state.isLoading = false;
      const isApolloError: boolean = action.error.name === 'ApolloError';
      console.warn(action.error);
      toast.error(isApolloError ? 'updateFormatter API request rejected' : action.error.message);
    });

    //getFormatter
    builder.addCase(getFormatter.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getFormatter.fulfilled, (state, action) => {
      const formatter = action.payload.data.getFormatter;
      state.activeFormatter = formatter;
      state.activeFormatterFrozenCopy = getFrozenObjectCopy(formatter) as IFormatterDetail;
      state.answerInputs = [];
      state.executionResult = '';
      state.isLoading = false;
      state.resultLoader = false;
    });
    builder.addCase(getFormatter.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('getFormatter API request rejected');
    });

    //executeBaseFormatter
    builder.addCase(executeBaseFormatter.pending, state => {
      state.resultLoader = true;
    });
    builder.addCase(executeBaseFormatter.fulfilled, (state, action) => {
      const { value } = action.payload.data.executeBaseFormatter;
      state.executionResult = value;
      state.resultLoader = false;
      toast.success('Function execution successful');
    });
    builder.addCase(executeBaseFormatter.rejected, (state, action) => {
      state.resultLoader = false;
      console.warn(action.error);
      toast.error('executeBaseFormatter API request rejected');
    });
  },
});

export const {
  addFormatterInput,
  clearFormatterExecutionResult,
  createNewFormatter,
  deleteFormatterInput,
  resetMultiChoiceAnswer,
  testAddChoice,
  testDeleteChoice,
  testUpdateAnswerInput,
  updateActiveFormatter,
  updateFormatterInput,
  updateFormatterReviewedStatus,
  updateMultiChoiceAnswer,
  updateSingleChoiceAnswer,
} = formatterDetailSlice.actions;

export default formatterDetailSlice.reducer;
