import { ChartType, TooltipModel } from 'chart.js';
import {
  DASHBOARD_PAGE_SCOPE,
  PortfolioRevenueAnalysis,
  PropertyAnalysisData,
  RevenueAnalysisStats,
  StatsRevenue,
} from 'common/api/dashboard';
import { ColorNames } from 'utils/utils-colors';
import { addSpaceOrComma, removeDecimals } from 'utils/utils-number';

export const RevenuePerformanceVsForecastPropertyTooltip = ({ title: [title], body }: TooltipModel<ChartType>) => {
  let [contractualRevenue, forecastRevenue] = body.map(({ lines }: { lines: string[] }) => lines[0].split(': ')[1]);

  contractualRevenue = removeDecimals(contractualRevenue);
  forecastRevenue = removeDecimals(forecastRevenue);
  return `
    <div class="inner-html revenue-performance-vs-forecast">
      <h4 class="title">Revenue Forecast - ${title}</h4>
      <p><span class="circle-xs m-r-xxs ${ColorNames.GREEN}"></span>${
        contractualRevenue !== 'unavailable' ? `HK$ ${contractualRevenue}` : contractualRevenue
      }</p>
      <p><span class="circle-xs m-r-xxs ${ColorNames.ORANGE}"></span>${
        forecastRevenue !== 'unavailable' ? `HK$ ${forecastRevenue}` : forecastRevenue
      }</p>
    </div>
    `;
};

export const RevenuePerformanceVsForecastPortfolioTooltip =
  (data: PortfolioRevenueAnalysis, tab: string) =>
  ({ title: [title], dataPoints }: TooltipModel<ChartType>) => {
    const index = dataPoints[0].dataIndex;
    const month = data.portfolioData[index].month;

    const properties: PropertyAnalysisData[] = data.propertiesData.filter(({ month: _month }) => month === _month);

    const total = {
      contractualRevenue: 0,
      headlineRent: 0,
      forecastRevenue: 0,
    };

    for (let i = 0; i < properties.length; i++) {
      const statsRevenue: StatsRevenue = properties[i].statsRevenue;

      for (const key in statsRevenue) {
        const parsedValue = parseFloat(statsRevenue[key as keyof RevenueAnalysisStats]);
        if (statsRevenue[key as keyof RevenueAnalysisStats] !== null && !isNaN(parsedValue)) {
          total[key as keyof RevenueAnalysisStats] += parsedValue;
        }
      }
    }

    return `
    <div class="inner-html revenue-performance-vs-forecast">
      <h4 class="title">Revenue Forecast - ${title}</h4>

      <div class="table-container">
        <table class="ui table dashboard-table revenue-performance-vs-forecast">
          <thead>
            <tr>
              <th>Property</th>
              <th>Contractual Revenue</th>
              <th>Annual Budget Forecast</th>
            </tr>
          </thead>

          <tbody>
            ${properties
              .map(
                property =>
                  `
              <tr>
                <td>
                  <a href="?context=${DASHBOARD_PAGE_SCOPE.PROPERTY}&propertyId=${property.propertyId}&propertyName=${
                    property.propertyName
                  }&tab=${tab}" target="_blank">
                    ${property.propertyName}
                  </a>
                </td>
                <td>${addSpaceOrComma(property.statsRevenue.contractualRevenue, false)}
                </td>
                <td>${addSpaceOrComma(property.statsRevenue.forecastRevenue, false)}
                </td>
              </tr>
              `,
              )
              .join('')}
                        
            <tr class="total-row">
              <td>Total :
              </td>
              <td>${addSpaceOrComma(total.contractualRevenue, false)}
              </td>
              <td>${addSpaceOrComma(total.forecastRevenue, false)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    `;
  };
