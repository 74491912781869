import { Accordion } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import FilesUpload from 'atoms/FormField/FilesUpload';
import {
  addTransactionDocs,
  deleteTransactionDocs,
  updateActiveTransaction,
} from 'store/transactions/transactionDetailSlice';
import { DocumentIntendedToBeUploaded, MODE_OPTIONS } from 'common/api/miscellaneous';
import { prepTransactionDocsForUpload } from 'common/api/transactions';
import { TransactionDocument } from 'common/api/transactions';
import { capitalizeFirstLetter } from 'utils/utils-string';

const DocUploadBox = ({
  mode,
  name,
  required,
}: {
  mode: MODE_OPTIONS;
  name: string;
  required: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  let viewerStatus = false;

  const {
    activeTransaction: { documents },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const onChange = (files: DocumentIntendedToBeUploaded[]) => {
    const updateFiles = documents.filter(doc => doc.name !== name);

    files.forEach(file => {
      updateFiles.push({
        name,
        document: file,
      });
    });

    dispatch(updateActiveTransaction({ key: 'documents', value: updateFiles }));
  };

  const currentDocuments = documents
    .filter((doc: TransactionDocument) => doc.name === name)
    .map((doc: TransactionDocument) => doc.document);

  return (
    <div className="box-flex d-flex-column">
      <Accordion className={`approval-accordian ${viewerStatus && 'border-sm-green'} upload-acc-docs`}>
        <Accordion.Title
          active={true}
          index={1}
          className="p-sm"
        >
          <p className="content-title m-t-xs m-b-none">
            {capitalizeFirstLetter(name)}
            {required ? <span className="required-indicator">*</span> : <></>}
          </p>
        </Accordion.Title>
        <Accordion.Content
          active={true}
          className="p-sm p-t-xxs"
        >
          <FilesUpload
            hideUploadArea={currentDocuments.length >= 1}
            disabled={mode === MODE_OPTIONS.READ}
            prepUpload={prepTransactionDocsForUpload}
            documents={currentDocuments}
            onDelete={deleteTransactionDocs}
            onAddFunctionParams={{ name }}
            onAddInTransaction={addTransactionDocs}
            onChange2={onChange}
          />
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default DocUploadBox;
