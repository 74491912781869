import { ReactNode } from 'react';
import 'layouts/PageLayout/PageLayout.scss';
import DropdownMenu, { DropdownItem, DropdownMenuProps } from 'atoms/DropdownMenu';
import CreateNewButton, { CreateNewButtonProps } from './CreateNewButton';

export interface CreateNewDropdownProps {
  dropdownText?: string;
  dropdownItems?: DropdownItem[];
  disabled?: boolean;
  dataTest?: string;
}

export interface PageHeaderToolbarProps {
  headerToolbar?: ReactNode;
  createNewButton?: CreateNewButtonProps;
  createNewDropdown?: DropdownMenuProps;
}

const PageHeaderToolbar = (props: PageHeaderToolbarProps): JSX.Element => {
  return (
    <>
      {props.headerToolbar}
      {props.createNewButton && <CreateNewButton {...props.createNewButton} />}
      {props.createNewDropdown && (
        <DropdownMenu
          className={props.createNewDropdown.className}
          dropdownText={props.createNewDropdown.dropdownText}
          dropdownItems={props.createNewDropdown.dropdownItems}
          disabled={props.createNewDropdown.dropdownItems?.length === 0 || props.createNewDropdown.disabled}
          dataTest={props.createNewDropdown.dataTest}
        />
      )}
    </>
  );
};

export default PageHeaderToolbar;
