import { FormatterAnswerInput } from './types';

export * from './types';
export * from './createFormatter';
export * from './deleteFormatter';
export * from './executeBaseFormatter';
export * from './executeListOfConditions';
export * from './executeListOfFormatters';
export * from './getFormatter';
export * from './listFormatters';
export * from './updateFormatter';

export interface FormatterAnswerInputResult {
  answer: string;
  answerType: string;
  label: string;
}

// convertFormatterAnswerInputs is used to convert 'answer' field into JSON
export const convertFormatterAnswerInputs = (answers: FormatterAnswerInput[]): FormatterAnswerInputResult[] => {
  const results: FormatterAnswerInputResult[] = answers.map((answerInput: FormatterAnswerInput) => {
    const result: FormatterAnswerInputResult = {
      answer: JSON.stringify(answerInput.answer),
      answerType: answerInput.answerType.toString(),
      label: answerInput.label,
    };
    return result;
  });
  return results;
};
