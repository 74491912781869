import React from 'react';
import { Form } from 'semantic-ui-react';
import SectionComponent from 'layouts/SectionComponent';
import { ModeProps } from 'common/api/miscellaneous';
import BenchmarkGeneralForm from './BenchmarkGeneralForm';
import PartiesOrTermForm from './PartiesOrTerm';
import Premises from './Premises';
import RentForm from './Rent';
import SecurityForm from './Security';
import SpecialClauses from './SpecialClauses';
import UnsavedChangesModal from './UnsavedChangesModal';
import './BenchmarkForm.scss';

const BenchmarkForm = React.forwardRef<HTMLFormElement, ModeProps>(({ mode }: ModeProps, ref) => {
  return (
    <Form ref={ref}>
      <div className="grid">
        <div className="col-6">
          <SectionComponent
            title="1. Parties / Term"
            className="m-b-sm"
          >
            <PartiesOrTermForm mode={mode} />
          </SectionComponent>
          <SectionComponent
            title="3. Premises"
            className="m-b-sm"
          >
            <Premises mode={mode} />
          </SectionComponent>
          <SectionComponent
            title="4. Rent"
            className="m-b-sm"
          >
            <RentForm mode={mode} />
          </SectionComponent>
          <SectionComponent
            title="5. Security"
            className="m-b-sm"
          >
            <SecurityForm mode={mode} />
          </SectionComponent>
        </div>
        <div className="col-6">
          <SectionComponent
            title="2. General"
            className="m-b-sm"
          >
            <BenchmarkGeneralForm mode={mode} />
          </SectionComponent>
          <SectionComponent
            title="6. Special Rights"
            className="d-block"
          >
            <SpecialClauses mode={mode} />
          </SectionComponent>
        </div>
      </div>
      <UnsavedChangesModal />
    </Form>
  );
});

BenchmarkForm.displayName = 'BenchmarkForm';

export default BenchmarkForm;
