import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { BenchmarkInput } from 'common/api/benchmark';

interface UpdatedBenchmarkInput extends Omit<BenchmarkInput, 'id' | 'industryId'> {
  industryId: string | null;
}

interface UpdateBenchmarkQueryVariables {
  benchmarkId: string;
  params: UpdatedBenchmarkInput;
}

export const UPDATE_BENCHMARK = (benchmark: BenchmarkInput) => {
  // Destructuring to separate 'id' from the rest
  const { id, reviewStatus, ...rest } = benchmark;

  if (!id) throw new Error('Update Benchmark: Benchmark ID is missing');

  const params: UpdatedBenchmarkInput = {
    ...rest,
    // TODO: Assuming the existence of 'industry' implies we should use its 'id'. Not sure why this is. Should be investigated.
    industryId: typeof benchmark.industry === 'object' && benchmark.industry !== null ? benchmark.industry.id : null,
    reviewStatus: {
      date: reviewStatus.date,
      userId: reviewStatus.user?.id,
    },
  };

  delete params.landlord;
  delete params.industry;

  const variables: UpdateBenchmarkQueryVariables = {
    benchmarkId: String(id),
    params,
  };

  return {
    mutation: gql`
      mutation ($benchmarkId: ID!, $params: BenchmarkInput!) {
        updateBenchmark(benchmarkId: $benchmarkId, params: $params) {
          id
          identifier
          address
          description
          size
          endDate
          startDate
          name
          industry {
            id
            name
          }
          rentPerArea
          rentFreePeriod
          securityAmount
          redevelopmentFlag
          assignmentFlag
          refusalFlag
          renewFlag
          subletFlag
          surrenderFlag
          expansionFlag
          hasCentralAc
          hasCurtainWall
          hasMetroCoveredAccess
          landlord {
            id
            name
          }
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    `,
    variables: variables,
  };
};

export const updateBenchmark = createAsyncThunk('benchmarks/updateBenchmark', async (args, { getState }) => {
  const {
    benchmarkDetail: { activeBenchmark },
  } = getState() as RootState;

  const response = await client.mutate(UPDATE_BENCHMARK(activeBenchmark));
  return response;
});
