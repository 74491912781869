import { useAppDispatch } from 'hooks';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import MemberStatus from 'common/model/MemberStatus';
import { updateMemberInvitationStatus } from 'common/api/authentication';
import { Icons } from 'utils/utils-icons';
import { EntityRow } from './EntitiesTableConfig';

interface EntitiesRowActionButtonParams {
  row: EntityRow;
  defaultEntity: string | null;
}

export const EntitiesRowActionButtons = ({ row, defaultEntity }: EntitiesRowActionButtonParams) => {
  const dispatch = useAppDispatch();

  const acceptAndUpdateDefault = (entity: EntityRow) => {
    dispatch(
      // @ts-ignore
      updateMemberInvitationStatus({
        id: entity.propertyId,
        entityId: entity.id,
        roleId: entity.roleId,
        status: MemberStatus.Valid,
      }),
      // @ts-ignore
    ).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        if (defaultEntity === null) {
          // @ts-ignore
          dispatch(updateDefaultEntity({ entityId: entity.id }));
        }
      }
    });
  };

  const onRefuse = (entity: EntityRow) => {
    dispatch(
      // @ts-ignore
      updateMemberInvitationStatus({
        id: entity.propertyId,
        entityId: entity.id,
        roleId: entity.roleId,
        status: MemberStatus.Refused,
      }),
    );
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.Check,
      tooltip: 'Accept',
      onClick: () => acceptAndUpdateDefault(row),
      dataTest: 'accept-invite',
      disabled: [MemberStatus.Valid].includes(row.status as MemberStatus),
    },
    {
      icon: Icons.Refuse,
      tooltip: 'Refuse',
      onClick: () => onRefuse(row),
      isPopup: true,
      popupText: 'Do you really want to refuse this invite?',
      popupConfirmationButton: 'REFUSE INVITE',
      dataTest: 'refuse-invite',
      disabled: [MemberStatus.Refused].includes(row.status as MemberStatus),
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
