import { TreeNode } from 'primereact/treenode';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TreeTablePrime from 'atoms/TreeTable';
import { EntityCreatorTypes, getEntity, listEntities } from 'common/api/entities';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { LandlordEntitiesRow, landlordEntitiesColumnConfig } from './LandlordEntitiesTableConfig';

const LandlordEntitiesList = ({
  organisation,
  setIsLoading,
}: {
  organisation?: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean | undefined>>;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, entitiesList } = useAppSelector((state: RootState) => state.entitiesListing);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  // Helper function to find children based on parentId
  const findChildren = (id: string, nodes: any[]): TreeNode[] => {
    return nodes
      .filter(n => n.landlordNetworks.some((ln: any) => ln.parentId === id))
      .map(entity => ({
        data: new LandlordEntitiesRow(entity),
        id: entity.id,
        key: entity.id,
        label: entity.name,
        expanded: false,
        children: findChildren(entity.id, nodes),
      }));
  };

  // Transform data into TreeNode structure
  const transformToTreeNode = (entitiesList: any[]): TreeNode[] => {
    return (entitiesList || [])
      .filter(
        n =>
          n &&
          n.landlordNetworks &&
          Array.isArray(n.landlordNetworks) &&
          !n.landlordNetworks.some((ln: any) => ln && ln.parentId),
      )
      .map(entity => ({
        data: new LandlordEntitiesRow(entity),
        id: entity?.id ?? '',
        key: entity?.id ?? '',
        label: entity?.name ?? '',
        expanded: false,
        children: findChildren(entity?.id ?? '', entitiesList),
      }));
  };

  const currentList: TreeNode[] = transformToTreeNode(entitiesList);

  const getLandlordsEntities = (params: PaginationAPIProps) => {
    dispatch(listEntities({ type: EntityCreatorTypes.Landlord, ...params }));
  };

  const onViewDetails = (id: string): void => {
    if (organisation) {
      dispatch(getEntity({ type: EntityCreatorTypes.Landlord, id, step: 0, isChild: true }));
    } else {
      const url = `/landlord-entities/${id}/details`;
      navigate(url);
    }
  };

  return (
    <TreeTablePrime
      content={currentList}
      fetchContent={({ ...rest }) => {
        getLandlordsEntities(rest);
      }}
      columnConfig={landlordEntitiesColumnConfig(onViewDetails)}
      dataTest="landlords-table"
    />
  );
};

export default LandlordEntitiesList;
