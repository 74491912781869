import { Icon } from '@iconify/react';
import { FilterDataType } from 'components/ComparablesTable';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { RowProps } from '../RowTemplate/RowProps';

interface RowFilterProps {
  onClick: Function;
  filterData: FilterDataType;
  rowConfig: RowProps;
}

/**
 * Row Filter element
 * @returns
 */
const RowFilterIcon = ({ onClick, filterData, rowConfig }: RowFilterProps): JSX.Element => {
  const rowFilter = filterData[rowConfig.id];

  return (
    <span className={`filter-icon ${conditionalClassName(rowFilter?.filterActive, 'active')}`}>
      <Icon
        icon={Icons.Filter}
        onClick={() => onClick()}
      />
    </span>
  );
};

export default RowFilterIcon;
