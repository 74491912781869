import { useEffect, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import TextAreaField from 'atoms/FormField/TextArea';
import ModalTemplate from 'components/ModalTemplate';
import { updateApprovalAnswer } from 'store/transactions/transactionDetailSlice';
import { updateTransactionApprovalAnswer } from 'common/api/transactions/approvals/updateTransactionApprovalAnswer';
import { checkEmpty } from 'utils/tsValidator';
import { ApprovalRow } from '..';
import ApprovalItemResponseTable from './ApprovalItemResponseTable';
import './ApprovalItemResponseModal.scss';

const ApprovalItemResponseModal = ({
  openCommentModal,
  setOpenCommentModal,
  row,
}: {
  openCommentModal: boolean;
  setOpenCommentModal: (open: boolean) => void;
  row: ApprovalRow;
}) => {
  const dispatch = useAppDispatch();
  const [note, setNote] = useState(row.note);

  useEffect(() => {
    setNote(row.note);
  }, [row.id]);

  const onClose = () => {
    setNote('');
    setOpenCommentModal(false);
  };

  const onSave = () => {
    dispatch(updateApprovalAnswer({ answerId: row.id, key: 'note', value: note }));
    dispatch(updateTransactionApprovalAnswer({ answerId: row.id }));
    setOpenCommentModal(false);
  };

  return (
    <ModalTemplate
      className="changes-table-modal"
      title="Approval Item Response"
      showHeader={true}
      isOpen={openCommentModal}
      onModalClose={onClose}
    >
      <Grid>
        <Grid.Row className="p-none btn-row m-b-s approval-item-response-table">
          <Grid.Column
            width={16}
            className="p-none"
          >
            <ApprovalItemResponseTable row={row} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none btn-row">
          <Grid.Column
            width={16}
            className="p-none"
          >
            <TextAreaField
              label="Comments of this particular change"
              value={note}
              onChange={(_key: string, value: string) => setNote(value)}
              fieldKey="note"
              required={true}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none btn-row m-t-m">
          <Button
            className="cancel-btn"
            data-test="cancel-button"
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            className="btn grey-bg"
            data-test="save-button"
            disabled={checkEmpty(note)}
            onClick={onSave}
          >
            SEND
          </Button>
        </Grid.Row>
      </Grid>
    </ModalTemplate>
  );
};

export default ApprovalItemResponseModal;
