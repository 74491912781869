import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ProvisionCategoryDetailClone } from 'common/_classes';
import { RootState } from 'store';
import { convertToProvisionCategoryInput } from 'common/api/provisions';

interface UpdateProvisionCategoryQueryVariables {
  id: string | undefined;
  params: any;
}

export const UPDATE_PROVISION_CATEGORY = (activeCategory: ProvisionCategoryDetailClone) => {
  const variables: UpdateProvisionCategoryQueryVariables = {
    id: activeCategory.id,
    params: convertToProvisionCategoryInput(activeCategory),
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: ProvisionCategoryInput!) {
        updateProvisionCategory(id: $id, params: $params) {
          ${ProvisionCategoryDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateProvisionCategory = createAsyncThunk(
  'provisions/updateProvisionCategory',
  async (_args, { getState }) => {
    const {
      provisionsListing: { activeCategory },
    } = getState() as RootState;
    const response = await adminClient.mutate(UPDATE_PROVISION_CATEGORY(activeCategory));
    return response;
  },
);
