import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TextAreaField from 'atoms/FormField/TextArea';
import 'views/provisions/CreatorViewer/Tabs/GeneralTab/ProvisionGeneralForm/ProvisionGeneralForm.scss';
import { updateActiveTransaction } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const SpecificCircumstances = ({ mode }: ModeProps): JSX.Element => {
  const {
    activeTransaction: { description },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: any): void => {
    dispatch(updateActiveTransaction({ key, value, userId: userId }));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <p className="m-b-none">
            Include any special factors relating to the transaction. E.g. "anchor tenant" or "special tenant"...
          </p>
          <TextAreaField
            value={description}
            onChange={onChange}
            fieldKey={'description'}
            disabled={mode === MODE_OPTIONS.READ}
            dataTest="special-remarks"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SpecificCircumstances;
