import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ForecastedTenancies } from 'common/_classes/forecastsV2/ForecastedTenancies';
import { Forecasts } from 'common/_classes/forecastsV2/Forecasts';
import { toast } from 'react-toastify';
import { createForecast } from 'common/api/dashboard/forecasting/createForecast';
import { generateAssumedTenanciesThunk } from 'common/api/dashboard/forecasting/generateAssumedTenancies';
import { getForecastsList } from 'common/api/dashboard/forecasting/getForecastList';
import { getForecastedTenancies } from 'common/api/dashboard/forecasting/getForecastedTenancies';

export interface IActiveForecast extends Forecasts {
  comments: string;
  referenceId: string;
}

export enum FORECAST_MODE {
  CREATE = 'create',
  EDIT = 'edit',
  READ = 'read',
  LIST = 'list',
}

// Define the initial state
interface Forecasts2State {
  activeForecast: IActiveForecast | null;
  forecasts: IActiveForecast[];
  forecastedTenancies: ForecastedTenancies[];
  isLoading: boolean;
  isListLoading: boolean;
  forecastMode: FORECAST_MODE;
  error: string | null;
}

const initialState: Forecasts2State = {
  // activeForecast: {
  //   ...Forecasts.init(),
  //   forecastLength: 10,
  //   comments: '',
  //   referenceId: '',
  // },
  activeForecast: null,
  forecasts: [],
  forecastedTenancies: [],
  forecastMode: FORECAST_MODE.LIST,
  isLoading: false,
  isListLoading: false,
  error: null,
};

// Create the slice
const forecasts2Slice = createSlice({
  name: 'forecasts2',
  initialState,
  reducers: {
    // Add any synchronous actions here if needed
    onForecastValueChange: (state, action) => {
      const { key, value, id } = action.payload;
      if (state.activeForecast) {
        if (key in state.activeForecast) {
          const activeForecast: IActiveForecast = { ...state.activeForecast, [key]: value };
          state.activeForecast = activeForecast;
        }
      }
    },
    setCurrentForecast: (state, action) => {
      const forecast = state.forecasts.find(forecast => forecast.id === action.payload);
      if (forecast) {
        state.activeForecast = forecast as IActiveForecast;
        state.forecastMode = FORECAST_MODE.READ;
      } else {
        state.activeForecast = null;
        state.forecastMode = FORECAST_MODE.LIST;
      }
    },
    initialiseNewForecast: state => {
      state.forecastMode = FORECAST_MODE.CREATE;
      state.activeForecast = Forecasts.init() as IActiveForecast;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getForecastedTenancies.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getForecastedTenancies.fulfilled, (state, action) => {
        console.log('action.payload', action.payload);
        state.isLoading = false;
        console.log('setting forecastedTenancies: ', action.payload);
        state.forecastedTenancies = action.payload as ForecastedTenancies[];
      })
      .addCase(getForecastedTenancies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        toast.error('Failed to fetch forecasted tenancy');
      })
      .addCase(getForecastsList.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getForecastsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.forecasts = action.payload as IActiveForecast[];
      })
      .addCase(getForecastsList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
        toast.error('Failed to fetch forecasts');
      })
      .addCase(createForecast.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createForecast.fulfilled, (state, action) => {
        state.isLoading = false;
        console.log('action.payload', action.payload);
        const existingForecasts = state.forecasts;
        const newForecast = [...existingForecasts, action.payload as IActiveForecast];
        console.log('newForecast', newForecast);
        state.forecasts = newForecast;
        // state.forecastMode = FORECAST_MODE.LIST;
        state.activeForecast = null;
        // Call generateAssumedTenanciesThunk after creating the forecast
      })
      .addCase(generateAssumedTenanciesThunk.pending, (state: { isListLoading: boolean }) => {
        state.isListLoading = true;
      })
      .addCase(
        generateAssumedTenanciesThunk.fulfilled,
        (
          state: { isListLoading: boolean; forecastedTenancies: ForecastedTenancies[]; forecastMode: FORECAST_MODE },
          action: any,
        ) => {
          const payload = action.payload;
          state.forecastedTenancies = action.payload;
          state.forecastMode = FORECAST_MODE.READ;
          state.isListLoading = false;
          // Handle the response if needed
        },
      )
      .addCase(
        generateAssumedTenanciesThunk.rejected,
        (state: { isListLoading: boolean; error: string | null }, action: any) => {
          state.isListLoading = false;
          state.error = action.payload as string;
          toast.error('Failed to generate assumed tenancies');
        },
      );
  },
});

export const { onForecastValueChange, setCurrentForecast, initialiseNewForecast } = forecasts2Slice.actions;

export default forecasts2Slice.reducer;
