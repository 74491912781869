import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { GENERATE_PROPERTY_REVENUE_ANALYSIS_BASE } from 'common/api/dashboard/forecasting';

export const generatePropertyRevenueAnalysisBase = createAsyncThunk(
  'dashboardTab/generatePropertyRevenueAnalysisBase',
  async (_args, { getState }) => {
    const {
      dashboardTab: {
        dateFilter: { startDate, endDate },
      },
      stackingPlan: { activeProperty },
    } = getState() as RootState;

    const { id: propertyId } = activeProperty || { id: '' };

    const response = await client.query(GENERATE_PROPERTY_REVENUE_ANALYSIS_BASE(propertyId, startDate, endDate));
    return response;
  },
);
