import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

export interface Passwords {
  password: string;
  passwordConfirmation: string;
}

interface ResetPasswordQueryVariables {
  params: Passwords;
  token: string | null;
}

export const RESET_PASSWORD = ({ password, passwordConfirmation }: Passwords) => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');

  const variables: ResetPasswordQueryVariables = {
    params: {
      password,
      passwordConfirmation,
    },
    token,
  };

  return {
    mutation: gql`
      mutation ($params: PasswordParams!, $token: String!) {
        resetPassword(params: $params, token: $token) {
          firstName
          lastName
          email
          countryCode
          phoneNumber
          photoUrl
        }
      }
    `,
    variables,
  };
};

export const resetPassword = createAsyncThunk('auth/resetPassword', async (_arg, { getState }) => {
  const {
    auth: { newPassword, newPasswordConfirmation },
  } = getState() as RootState;
  const response = await client.mutate(
    RESET_PASSWORD({ password: newPassword, passwordConfirmation: newPasswordConfirmation }),
  );
  return response;
});
