import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { setTransactionChannelInput } from '.';
import { ActiveTransactionChannel } from './types';

interface CreateTransactionChannelQueryVariables {
  transactionId: string | undefined;
  params: any;
}

const CREATE_TRANSACTION_CHANNEL = (
  transactionChannel: ActiveTransactionChannel,
  transactionId: string | undefined,
) => {
  const params = setTransactionChannelInput(transactionChannel);

  const variables: CreateTransactionChannelQueryVariables = {
    transactionId: transactionId,
    params,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $params: TransactionChannelInput!) {
        createTransactionChannel(transactionId: $transactionId, params: $params) {
          id
          documentTypeId
          insertedAt
        }
      }
    `,
    variables,
  };
};

export const createTransactionChannel = createAsyncThunk(
  'transactions/createTransactionChannel',
  async (_args, { getState }) => {
    const {
      transactionDetail: { activeTransactionChannel, activeTransaction },
      miscellaneous: { activeDocTypeId },
    } = getState() as RootState;
    const response = await client.mutate(
      CREATE_TRANSACTION_CHANNEL(
        { ...activeTransactionChannel, documentTypeId: activeDocTypeId as string },
        activeTransaction.id,
      ),
    );
    return response;
  },
);
