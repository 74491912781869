import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { SequenceProps } from 'common/api/policies';

interface UpdatePolicySequencesQueryVariables {
  policyId: string | undefined;
  sequences: SequenceProps[];
}

export const UPDATE_POLICY_SEQUENCES = (policyId: string | undefined, sequences: SequenceProps[]) => {
  if (!policyId) throw new Error('UPDATE_POLICY_SEQUENCES: policyId is required');

  const formattedSequences = JSON.parse(JSON.stringify(sequences));

  const variables: UpdatePolicySequencesQueryVariables = {
    policyId,
    sequences: formattedSequences,
  };

  return {
    mutation: gql`
      mutation ($policyId: ID!, $sequences: [DocumentSequenceInput]) {
        updatePolicySequences(policyId: $policyId, sequences: $sequences) {
          id
          policy {
            id
          }
          documentType {
            id
            name
          }
          elements {
            provision {
              id
              name
              provisionCategory {
                color
              }
            }
            order
          }
        }
      }
    `,
    variables,
  };
};

export const updatePolicySequences = createAsyncThunk('policies/updatePolicySequences', async (_args, { getState }) => {
  const state = getState() as RootState;
  const {
    policyDetail: {
      activePolicy: { id },
      activePolicySequences,
    },
  } = state;

  const response = await client.mutate(UPDATE_POLICY_SEQUENCES(id, activePolicySequences));
  return response;
});
