import ValuationDataFloor from 'common/model/ValuationDataFloor';
import { CValuationDataSpace } from './ValuationDataSpace';

export class CValuationDataFloor {
  id: string;
  name: string;
  whole: boolean;
  spaces: CValuationDataSpace[];

  constructor(input: ValuationDataFloor) {
    this.id = input.id;
    this.name = input.name;
    this.whole = input.whole;
    this.spaces = input.spaces.map(space => new CValuationDataSpace(space));
  }

  static fragment() {
    return `
        id
        name
        whole
        spaces {
            ${CValuationDataSpace.fragment()}
        }
    `;
  }
}
