import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteParameterQueryVariables {
  parameterId: string;
}

const DELETE_PARAMETER = (id: string) => {
  if (!id) throw new Error('Delete Parameter: id is required');

  const variables: DeleteParameterQueryVariables = { parameterId: id };

  return {
    mutation: gql`
      mutation ($parameterId: ID!) {
        deleteParameter(parameterId: $parameterId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteParameter = createAsyncThunk('parameters/deleteParameter', async ({ id }: { id: string }) => {
  const response = await adminClient.mutate(DELETE_PARAMETER(id));
  return response;
});
