import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import DateCell from 'atoms/DateCell';
import { updateDate } from 'store/dashboard/dashboardTabSlice';
import './DateSelect.scss';

const DateSelect = () => {
  const { date } = useAppSelector((state: RootState) => state.dashboardTab);

  return (
    <div
      className="date-select"
      data-test="date-select"
    >
      <span>As at:</span>
      <DateCell
        type="date"
        date={date}
        onChange={updateDate}
      />
    </div>
  );
};

export default DateSelect;
