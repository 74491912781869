import RentFreeAndEarlyOccupation from 'common/model/RentFreeAndEarlyOccupation';
import { CDuration } from './Duration';

export class CRentFreeAndEarlyOccupation {
  earlyOccupationLicense: CDuration | null;
  incentiveRentFree: CDuration | null;
  rentFreePeriod: number | null;

  constructor(input: RentFreeAndEarlyOccupation) {
    this.earlyOccupationLicense = input.earlyOccupationLicense ? new CDuration(input.earlyOccupationLicense) : null;
    this.incentiveRentFree = input.incentiveRentFree ? new CDuration(input.incentiveRentFree) : null;
    this.rentFreePeriod = input.rentFreePeriod ? input.rentFreePeriod : null;
  }

  static fragment() {
    return `
        earlyOccupationLicense {
          ${CDuration.fragment()}
        }
        incentiveRentFree {
          ${CDuration.fragment()}
        }
        rentFreePeriod
    `;
  }
}
