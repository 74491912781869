import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { LIST_POLICIES } from 'common/api/policies';

export const listPolicies = createAsyncThunk(
  'transactions/listPolicies',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      policiesListing: { search },
    } = getState() as RootState;
    const response = await client.query(LIST_POLICIES(search, first, last, after, before));
    return response.data;
  },
);
