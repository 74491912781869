import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import OrganisationTabs from './Tabs/OrganisationTabs';

const OrganisationPage = () => {
  const { isLoading } = useAppSelector((state: RootState) => state.entityDetail);
  const { memberLoading } = useAppSelector((state: RootState) => state.members);
  const { isLoading: roleLoading } = useAppSelector((state: RootState) => state.roles);
  const { activeEntity } = useAppSelector((state: RootState) => state.entityDetail);
  const name = activeEntity?.name;
  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Organisation',
        url: '/organisation',
      },
      {
        title: name || '',
      },
    ];
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading || memberLoading || roleLoading}
      pageHeader={getPageHeaderProps()}
    >
      <OrganisationTabs />
    </PageLayout>
  );
};

export default OrganisationPage;
