import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { uploadToAWS } from 'utils/utils-upload';
import { UserProfileInput } from './updateAdminProfile';

interface UpdateProfileQueryVariables {
  params: UserProfileInput;
}

export const UPDATE_PROFILE = (params: UserProfileInput) => {
  const variables: UpdateProfileQueryVariables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: UserProfileInput!) {
        updateProfile(params: $params) {
          email
          firstName
          id
          lastName
          countryCode
          phoneNumber
          photoUrl
        }
      }
    `,
    variables,
  };
};

export const updateProfile = createAsyncThunk('auth/updateProfile', async (_arg, { getState }) => {
  const {
    auth: { firstName, lastName, countryCode, phoneNumber, file, uploadUrl, cdnUrl, deletePreviousPhoto },
  } = getState() as RootState;

  const userData: UserProfileInput = {
    firstName,
    lastName,
    phoneNumber,
    countryCode,
  };

  if (file) {
    await uploadToAWS(uploadUrl, file);
    userData.photoUrl = cdnUrl;
  } else if (deletePreviousPhoto) {
    userData.photoUrl = '';
  }

  const response = await client.mutate(UPDATE_PROFILE(userData));
  return response;
});
