import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParameterTablePickName } from 'common/_classes';
import { getClientType } from 'utils/utils-authentication';

export const LIST_PARAMETER_TABLES = () => {
  return {
    query: gql`
      query {
        listParameterTables {
          ${ParameterTablePickName.fragments()}
        }
      }
    `,
  };
};

export const listParameterTables = createAsyncThunk('parameters/listParameterTables', async () => {
  const response = await getClientType().query(LIST_PARAMETER_TABLES());
  return response;
});
