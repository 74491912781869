import React, { ReactNode } from 'react';
import { Button, Image, Modal } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import headerFloorImage from 'assets/images/svg/header-floor-image.svg';
import './ModalTemplate.scss';

export enum ModalTemplateSize {
  mini = 'mini',
  tiny = 'tiny',
  small = 'small',
  large = 'large',
  fullscreen = 'fullscreen',
}

interface ModalTemplateProps {
  onToggleModalStatus?: (isOpen: boolean) => void;
  isOpen?: boolean;
  className?: string;
  dataTest?: string;
  header?: ReactNode;
  children: any;
  trigger?: ReactNode;
  title?: string;
  showHeader?: boolean;
  closeOnClickOutside?: boolean;
  onModalClose?: () => void;
  exitBtnTitle?: string;
  size?: ModalTemplateSize;
}

const ModalTemplate = ({
  onToggleModalStatus,
  isOpen,
  className,
  dataTest,
  header,
  children,
  trigger,
  title,
  showHeader = true,
  closeOnClickOutside = true,
  onModalClose,
  exitBtnTitle,
  size,
}: ModalTemplateProps) => {
  const btnClassName = 'exit-btn';

  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;

    if (!target || target.className !== `ui button ${btnClassName}`) {
      e.stopPropagation();
    }
  };

  const onClose = () => {
    if (onToggleModalStatus) {
      onToggleModalStatus(false);
    }
    if (onModalClose) {
      onModalClose();
    }
  };

  return (
    <Modal
      onClose={onClose}
      onOpen={() => onToggleModalStatus && onToggleModalStatus(true)}
      open={isOpen}
      className={`modal-template ${className}`}
      data-test={dataTest}
      trigger={trigger}
      onClick={stopPropagation}
      closeOnDimmerClick={closeOnClickOutside}
      size={size}
    >
      {showHeader && (
        <Modal.Header data-test="popup-header">
          <h2>{title}</h2>
          <Image
            src={headerFloorImage}
            className="header-image"
          />
          {header}
          <Button
            className={btnClassName}
            onClick={onClose}
          >
            {exitBtnTitle ? exitBtnTitle : 'Exit'}
            <Icon
              icon={Icons.ArrowLeft90}
              rotate={1}
            />
          </Button>
        </Modal.Header>
      )}

      <Modal.Content>
        <Modal.Description className="m-none">
          {React.Children.map(children, child => React.cloneElement(child))}
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ModalTemplate;
