import { PropertyClone } from 'common/_classes';
import Property from 'common/model/Property';

export enum FLOOR_PORTION_OPTIONS {
  PART = 'PART',
  WHOLE = 'WHOLE',
}
export interface PremiseSelectedFloorProps {
  floorPortion: FLOOR_PORTION_OPTIONS | null;
  id: string;
  spaceIds: string[];
}

export interface PremiseSelectedProps {
  property: null | PropertyClone;
  floorIds: PremiseSelectedFloorProps[];
}

const initNewPremiseFloor = (): PremiseSelectedFloorProps => {
  return {
    id: '',
    floorPortion: null,
    spaceIds: [],
  };
};

const initNewPremise = (): PremiseSelectedProps => {
  return {
    floorIds: [initNewPremiseFloor()],
    property: null,
  };
};

export const refreshPremises = (): [PremiseSelectedProps] => {
  return [initNewPremise()];
};

export const onAddPremiseFloor = (previousFloors: PremiseSelectedFloorProps[]): PremiseSelectedFloorProps[] => {
  return [...previousFloors, initNewPremiseFloor()];
};

export const onDeletePremiseFloor = (
  previousFloors: PremiseSelectedFloorProps[],
  arrayIndex: number,
): PremiseSelectedFloorProps[] => {
  return previousFloors.filter((_floor, index: number) => index !== arrayIndex);
};

export const onUpdatePremiseFloorId = (
  previousFloors: PremiseSelectedFloorProps[],
  arrayIndex: number,
  id: string,
): PremiseSelectedFloorProps[] => {
  const floor = previousFloors[arrayIndex];

  if (floor) {
    const updatedFloors = [...previousFloors];
    updatedFloors[arrayIndex] = { ...initNewPremiseFloor(), id };

    return updatedFloors;
  }

  return previousFloors;
};

export const onUpdatePremiseFloorPortion = (
  previousFloors: PremiseSelectedFloorProps[],
  arrayIndex: number,
  floorPortion: FLOOR_PORTION_OPTIONS,
): PremiseSelectedFloorProps[] => {
  const floor = previousFloors[arrayIndex];

  if (floor) {
    const updatedFloors = [...previousFloors];
    updatedFloors[arrayIndex] = { ...floor, floorPortion };

    return updatedFloors;
  }

  return previousFloors;
};

export const onUpdatePremiseSelectedUnits = (
  previousFloors: PremiseSelectedFloorProps[],
  arrayIndex: number,
  spaceIds: string[],
): PremiseSelectedFloorProps[] => {
  const floor = previousFloors[arrayIndex];

  if (floor) {
    const updatedFloors = [...previousFloors];
    updatedFloors[arrayIndex] = { ...floor, spaceIds };

    return updatedFloors;
  }
  return previousFloors;
};

/**
 * onUpdatePremiseProperty
 *
 * Updates the premise with details of the new building selected.
 * This is basically the data of the building and empty floor data
 * that takes the shape of the interface PremiseSelectedProps
 */
export const onUpdatePremiseProperty = (
  premises: PremiseSelectedProps[],
  arrayIndex: number,
  properties: PropertyClone[],
  propertyId: string,
): PremiseSelectedProps[] => {
  const premise = premises[arrayIndex];

  if (premise) {
    const updatedPremises = [...premises];

    const selectedProperty = properties.find(property => property.id === propertyId);

    const newPremise = initNewPremise();

    if (selectedProperty) {
      newPremise.property = selectedProperty;
    }

    updatedPremises[arrayIndex] = newPremise;

    return updatedPremises;
  }

  return premises;
};
