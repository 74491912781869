import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

const LIST_TRANSACTION_PARAMETER_APPROVALS = () => {
  return {
    query: gql`
      query {
        listTransactionParameterApprovals {
          id
          dateSubmitted
          user {
            id
            firstName
            lastName
            photoUrl
          }
        }
      }
    `,
  };
};

export const listTransactionParameterApprovals = createAsyncThunk(
  'transactions/listTransactionParameterApprovals',
  async (_args, { getState }) => {
    const response = await client.query(LIST_TRANSACTION_PARAMETER_APPROVALS());
    return response;
  },
);
