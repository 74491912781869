import { PolicyListingClone, PolicyListingProvisionClone } from 'common/_classes';
import moment from 'moment';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementCountry,
  filterElementCountryTemplate,
  filterElementDate,
  filterElementNumberRange,
  filterElementStatus,
  filterElementStatusTemplate,
  filterElementText,
} from 'atoms/TableReactPrime/columnTemplates';
import Tooltip from 'atoms/Tooltip';
import ListOfProvisions from 'components/ListOfProvisions';
import { CountryClone } from 'common/api/miscellaneous';
import { USE_TYPE_OPTIONS } from 'utils/UI';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';
import { PolicyRowActionButtons } from './PolicyRowActionButtons';

const statuses: StatusBadgeArgument[] = [
  { value: 'PUBLISHED', color: BadgeColor.GREEN },
  { value: 'DRAFT', color: BadgeColor.ORANGE },
];

export class PolicyListRow {
  id: string;
  name: string;
  version: string;
  provisionsNb: number;
  provisions: PolicyListingProvisionClone[];
  insertedAt: Date | null;
  updatedAt: Date | null;
  status: string;
  released: string;
  owner: string;
  language: string;
  useType: string;
  jurisdictionName?: string | null;
  jurisdictionFlag?: string | null;

  constructor(policy: PolicyListingClone, countries: CountryClone[]) {
    const countryMatch: CountryClone = countries.find((country: CountryClone) => country.code === policy.jurisdiction)!;
    const useType = policy.useType ? replaceUnderscoreWithSpace(policy.useType.toString().toLowerCase()) : '';

    this.id = policy.id as string;
    this.name = policy.name;
    this.owner = policy.entity?.name || '';
    this.provisionsNb = policy.provisions.length;
    this.provisions = policy.provisions;
    this.jurisdictionFlag = countryMatch ? countryMatch?.flag : undefined;
    this.jurisdictionName = countryMatch ? countryMatch.text : undefined;
    this.useType = useType;
    this.language = policy.language;
    this.insertedAt = policy.insertedAt ? new Date(policy.insertedAt) : null;
    this.updatedAt = policy.updatedAt ? new Date(policy.updatedAt) : null;
    //dummy data for the moment
    this.version = '3.0';
    this.status = statuses[0].value;
    this.released = 'Surrender v3.0; Rent v2.0';
  }
}

/*
 * Definition of the column (names, format...)
 */
export const policyColumnConfig = (
  languages: string[],
  countries: CountryClone[],
  isAdmin: boolean,
  startCursor: any,
  onViewDetails: ViewDetailsProps,
): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Name',
      filterPlaceholder: 'Search by name',
      filter: true,
      filterField: 'name',
      sortable: true,
      mandatory: true,
      minWidth: '10rem',
    },
    {
      field: 'owner',
      header: 'Landlord',
      filter: true,
      filterPlaceholder: 'Search by Landlord',
      filterField: 'owner',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'jurisdictionName',
      header: 'Jurisdiction',
      filter: true,
      filterPlaceholder: 'Search by jurisdiction',
      filterField: 'jurisdictionName',
      body: (row: PolicyListRow) => {
        return filterElementCountryTemplate(row.jurisdictionName || '', row.jurisdictionFlag || '');
      },
      filterElement: options =>
        filterElementCountry(
          options,
          countries.map((country: CountryClone) => country.name),
        ),
      showFilterMatchModes: false,
      sortable: true,
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      field: 'language',
      header: 'Language',
      filter: true,
      filterPlaceholder: 'Search by language',
      filterField: 'language',
      sortable: true,
      filterElement: options =>
        filterElementText(
          options,
          languages.map((langauge: string) => langauge),
        ),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      field: 'useType',
      header: 'Use Type',
      filter: true,
      filterPlaceholder: 'Search by use type',
      filterField: 'useType',
      sortable: true,
      filterElement: options =>
        filterElementText(
          options,
          USE_TYPE_OPTIONS.map(useType => replaceUnderscoreWithSpace(useType.value.toString().toLowerCase())),
        ),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    // {
    //   field: 'version',
    //   header: 'Version',
    //   filter: true,
    //   filterPlaceholder: 'Search by version',
    //   filterField: 'version',
    //   sortable: true,
    //   dataType: 'text',
    //   isHide: true,
    //   mandatory: true,
    // },
    {
      field: 'provisionsNb',
      header: 'Numbers of provisions',
      filter: true,
      filterPlaceholder: 'Search by quantity',
      filterField: 'provisionsNb',
      sortable: true,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      body: (row: PolicyListRow) => {
        return (
          <Tooltip
            trigger={<span>{row.provisions.length}</span>}
            HTMLContent={<ListOfProvisions provisions={row.provisions} />}
            disabled={row.provisions.length === 0}
          />
        );
      },
    },
    {
      field: 'insertedAt',
      header: 'Creation date',
      filter: true,
      filterPlaceholder: 'Search by date',
      filterField: 'insertedAt',
      sortable: true,
      dataType: 'date',
      body: (row: PolicyListRow) => {
        return moment(row.insertedAt).format('DD/MM/YYYY');
      },
      filterElement: filterElementDate,
    },
    {
      field: 'updatedAt',
      header: 'Updated on',
      filter: true,
      filterPlaceholder: 'Search by date',
      filterField: 'updatedAt',
      sortable: true,
      body: (row: PolicyListRow) => {
        return moment(row.updatedAt).format('DD/MM/YYYY');
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },
    // {
    //   field: 'released',
    //   header: 'New clauses released',
    //   filter: true,
    //   filterPlaceholder: 'Search by new release',
    //   filterField: 'released',
    //   sortable: true,
    // },
    {
      field: 'status',
      header: 'Status',
      filter: true,
      filterPlaceholder: 'Search by status',
      filterField: 'status',
      body: (row: PolicyListRow) => {
        return filterElementStatusTemplate(row.status, statuses);
      },
      filterElement: options => filterElementStatus(options, statuses),
      showFilterMatchModes: false,
      sortable: true,
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: PolicyListRow) => (
        <PolicyRowActionButtons
          rowId={row.id}
          isAdmin={isAdmin}
          startCursor={startCursor}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
