import { useDispatch } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import ModalTemplate from 'components/ModalTemplate';
import { resetActiveCategory } from 'store/provisions/provisionsListingSlice';
import { MODAL_MODE_OPTIONS } from 'common/api/miscellaneous';
import CategoryForm from './CategoryForm';
import CreateButton from './CreateButton';
import './CreateModal.scss';

interface ContentProps {
  type: MODAL_MODE_OPTIONS;
  setModalStatus: (value: boolean) => void;
  onExit: () => void;
}

const Content = ({ type, setModalStatus, onExit }: ContentProps) => {
  return (
    <Form>
      <Grid className="pm-none">
        <Grid.Row className="p-none">
          <Grid.Column
            width={16}
            className="pm-none"
          >
            <h4
              className="section-title"
              data-test="section-title"
            >
              Details
            </h4>
            <CategoryForm />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="p-none btn-row">
          <Button
            className="cancel-btn"
            onClick={() => onExit()}
            data-test="cancel-button"
          >
            CANCEL
          </Button>
          <CreateButton
            type={type}
            setModalStatus={setModalStatus}
          />
        </Grid.Row>
      </Grid>
    </Form>
  );
};

interface CreateModalProps {
  type: MODAL_MODE_OPTIONS;
  status: boolean;
  setModalStatus: (value: boolean) => void;
}

const CreateModal = ({ type, status, setModalStatus }: CreateModalProps) => {
  const dispatch = useDispatch();

  const onClose = (isOpen: boolean) => {
    setModalStatus(isOpen);
    if (!isOpen) dispatch(resetActiveCategory());
  };

  return (
    <ModalTemplate
      isOpen={status}
      onToggleModalStatus={onClose}
      title={type === MODAL_MODE_OPTIONS.CREATE ? 'Create New Category' : 'Update Category'}
      className="modal-provisions"
    >
      <Content
        type={type}
        setModalStatus={setModalStatus}
        onExit={() => onClose(false)}
      />
    </ModalTemplate>
  );
};

export default CreateModal;
