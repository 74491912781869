import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { queryNotificationResult } from './listNotifications';

interface ToggleNotificationsArchiveStatus {
  notificationIds: string[];
  archive: boolean;
}

export const TOGGLE_NOTIFICATIONS_ARCHIVE_STATUS = ({ notificationIds, archive }: ToggleNotificationsArchiveStatus) => {
  if (notificationIds.length === 0) throw new Error('Toggle Notifications Archive Status: notificationIds is required');

  let archiveMutations: string = '';

  for (let index = 0; index < notificationIds.length; index++) {
    const notificationId = notificationIds[index];

    archiveMutations += `
              archiveNotification${index}: archiveNotification(notificationId: "${notificationId}", archive: ${archive}) {
                  ${queryNotificationResult}
              }
          `;
  }

  return {
    mutation: gql`
        mutation {
          ${archiveMutations}
        }
      `,
  };
};

export const toggleArchiveMultipleNotifications = createAsyncThunk(
  'notifications/toggleArchiveMultipleNotifications',
  async ({ archive, id }: { archive: boolean; id?: string }, { getState }) => {
    const {
      notifications: { selectedNotificationIds },
    } = getState() as RootState;

    const ids: string[] = id ? [id] : selectedNotificationIds;

    const response = await client.mutate(TOGGLE_NOTIFICATIONS_ARCHIVE_STATUS({ notificationIds: ids, archive }));
    return response;
  },
);
