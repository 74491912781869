import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { ContractTimelineEvent } from 'common/api/contracts';
import { TimeLineEventsUI } from 'utils/types/stackingPlan';
import './EventsTimeline.scss';

const PER_EVENT_HEIGHT = 6;

interface EventsTimelineProps {
  timeline: TimeLineEventsUI[] | null;
}

const EventsTimeline = ({ timeline }: EventsTimelineProps) => {
  const [events, setEvents] = useState<TimeLineEventsUI[]>([]);

  useEffect(() => {
    if (timeline) {
      timeline.sort((eventA: ContractTimelineEvent, eventB: ContractTimelineEvent) => {
        const eventADate = new Date(eventA.date),
          eventBDate = new Date(eventB.date);
        if (eventADate < eventBDate) return -1;
        if (eventADate > eventBDate) return 1;
        return 0;
      });

      setEvents(timeline);
    }
  }, [timeline]);
  if (!events.length) {
    return <div className="timeline"></div>;
  }
  return (
    <div className="events-timeline ">
      <div
        className="timeline-wrapper"
        style={{
          height: `${(events.length - 1) * PER_EVENT_HEIGHT}rem`,
        }}
      >
        <div className="timeline-container">
          <div className="timeline-main-line border-sm-gray-very-light-v6"></div>
          <div className="timeline">
            <div className="timeline-main-line-axis">
              {events.map(({ date, color, background, event, description }, index: number) => (
                <Fragment key={index}>
                  <div
                    className="timeline-circle-point"
                    style={{
                      top: `${index * PER_EVENT_HEIGHT + 0.4}rem`,
                      background: color,
                    }}
                  ></div>
                  <div
                    className="timeline-text-box date"
                    data-test="timeline-date"
                    style={{
                      top: `${index * PER_EVENT_HEIGHT}rem`,
                    }}
                  >
                    {moment(date).format('DD/MM/YYYY')}
                  </div>
                  <div
                    className="timeline-text-box event"
                    data-test="timeline-event"
                    style={{
                      top: `${index * PER_EVENT_HEIGHT}rem`,
                      color: color,
                      background: background,
                    }}
                  >
                    {event && event.split('_').join(' ')}
                  </div>
                  <div
                    className="timeline-text-box  description"
                    data-test="timeline-property"
                    style={{
                      top: `${index * PER_EVENT_HEIGHT - 0.5}rem`,
                    }}
                  >
                    <a href="#">{description && description.length > 30 ? description.slice(0, 30) : description}</a>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsTimeline;
