import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import PolicyProvision from 'common/model/PolicyProvision';
import UseType from 'common/model/UseType';
import { AnswerProps, PolicyProps, SequenceProps } from 'common/api/policies';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface CreatePolicyQueryVariables extends Omit<PolicyProps, 'useType'> {
  // Override or add new properties as needed, for example:
  provisions?: PolicyProvision[]; // Make optional if necessary
  answers?: AnswerProps[]; // Make optional if necessary
  sequences?: SequenceProps[]; // Make optional if different from PolicyProps
  useType: UseType | string | null; // Expand type if needed or make optional
}

export const CREATE_POLICY = (policy: PolicyProps) => {
  const entityId = getCurrentLandlordEntityId();
  if (!entityId) {
    throw new Error('Landlord entity ID is required for creating a policy');
  }
  const useType = policy.useType || null;

  // Casting directly to CreatePolicyProps type while ensuring necessary properties are included or omitted properly
  const params: CreatePolicyQueryVariables = {
    ...policy,
    entityId,
    useType,
    provisions: undefined, // Explicitly setting omitted properties to undefined
    answers: undefined,
    sequences: undefined,
  };

  const variables = {
    params,
  };

  return {
    mutation: gql`
      mutation CreatePolicy($params: PolicyInput!) {
        createPolicy(params: $params) {
          id
        }
      }
    `,
    variables,
  };
};

export const createPolicy = createAsyncThunk('policies/createPolicy', async (_args, { getState }) => {
  const state = getState() as RootState;
  const activePolicy = state?.policyDetail?.activePolicy || undefined;
  if (!activePolicy) {
    throw new Error('No active policy available');
  }
  const response = await client.mutate(CREATE_POLICY(activePolicy));
  return response;
});
