import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { DropdownProps } from 'semantic-ui-react';
import _cloneDeep from 'lodash/cloneDeep';
import Development from 'common/model/Development';
import OwnershipType from 'common/model/OwnershipType';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { listLandlordEntityOptions } from 'common/api/properties';
import { listDevelopments, updateDevelopment } from 'common/api/propertiesV2';
import { createDevelopment } from 'common/api/propertiesV2/createDevelopment';
import { deleteDevelopment } from 'common/api/propertiesV2/deleteDevelopment';
import { getDevelopment } from 'common/api/propertiesV2/getDevelopments';

export enum VIEW_TYPE {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create',
}

// type Building = {
//   id: string;
//   name: string;
// };

const DEVELOPMENT_TEMPLATE: Development = {
  id: '',
  developmentIdentifier: '',
  developmentName: '',
  developmentLotNumber: '',
  ownershipType: OwnershipType.WhollyOwnedSingleEntity,
  entityId: [],
  address: '',
  addressDistrict: '',
  area: '',
  insertedAt: new Date(),
  updatedAt: new Date(),
};

type State = {
  mode: MODE_OPTIONS;
  isDevelopmentsLoading: boolean;
  isOwnersLoading: boolean;
  isUpdatingDevelopment: boolean;
  isDeletingDevelopment: boolean;
  isCreatingDevelopment: boolean;
  isGettingDevelopment: boolean;
  developmentList: Development[];
  ownersOptions: DropdownProps[];
  currentDevelopment: Development | null; // Development being edited or created
};

const initialState: State = {
  mode: MODE_OPTIONS.READ,
  isDevelopmentsLoading: false,
  isOwnersLoading: false,
  isUpdatingDevelopment: false,
  isDeletingDevelopment: false,
  isCreatingDevelopment: false,
  isGettingDevelopment: false,
  developmentList: [],
  ownersOptions: [],
  currentDevelopment: null,
};

const propertiesV2Slice = createSlice({
  name: 'propertiesV2',
  initialState,
  reducers: {
    setMode: (state, action: { payload: MODE_OPTIONS }) => {
      state.mode = action.payload;
    },
    newDevelopment: (state, action) => {
      state.currentDevelopment = _cloneDeep(DEVELOPMENT_TEMPLATE);
      state.mode = MODE_OPTIONS.EDIT;
    },
    updateDevelopmentProperty: (
      state,
      action: {
        payload: {
          key: keyof Development;
          value: any;
        };
      },
    ) => {
      if (!state.currentDevelopment) return;

      state.currentDevelopment = {
        ...state.currentDevelopment,
        [action.payload.key]: action.payload.value,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(listDevelopments.pending, state => {
      state.isDevelopmentsLoading = true;
    });
    builder.addCase(listDevelopments.fulfilled, (state, action) => {
      state.developmentList = action.payload.data?.listDevelopments || [];
      state.isDevelopmentsLoading = false;
    });
    builder.addCase(listLandlordEntityOptions.pending, state => {
      state.isOwnersLoading = true;
    });
    builder.addCase(listLandlordEntityOptions.fulfilled, (state, action) => {
      const landlordEntities = action.payload.data.listLandlordEntities.edges.map((element: any) => element.node);
      state.ownersOptions = landlordEntities.map((entity: any, index: number) => {
        const result: DropdownProps = {
          key: index,
          text: entity.name,
          value: entity.id,
        };
        return result;
      });
      state.isOwnersLoading = false;
    });

    builder.addCase(updateDevelopment.pending, state => {
      state.isUpdatingDevelopment = true;
    });
    builder.addCase(updateDevelopment.fulfilled, (state, action) => {
      state.currentDevelopment = action.payload.data.updateDevelopment;
      state.isUpdatingDevelopment = false;
      state.developmentList = state.developmentList.map(development =>
        development.id === action.payload.data.updateDevelopment.id
          ? action.payload.data.updateDevelopment
          : development,
      );
      state.mode = MODE_OPTIONS.READ;
      toast.success('Development Updated Successfully');
    });

    builder.addCase(updateDevelopment.rejected, (state, action) => {
      state.isUpdatingDevelopment = false;
      console.error(action.error);
      toast.error('updateDevelopment API request rejected');
    });

    builder.addCase(deleteDevelopment.pending, state => {
      state.isDeletingDevelopment = true;
    });
    builder.addCase(deleteDevelopment.fulfilled, (state, action) => {
      state.isDeletingDevelopment = false;
      state.developmentList = state.developmentList.filter(development => development.id !== action.meta.arg.id);
      toast.success('Development Deleted Successfully');
    });
    builder.addCase(deleteDevelopment.rejected, (state, action) => {
      state.isDeletingDevelopment = false;
      console.error(action.error);
      toast.error('deleteDevelopment API request rejected');
    });
    builder.addCase(createDevelopment.pending, state => {
      state.isCreatingDevelopment = true;
    });
    builder.addCase(createDevelopment.fulfilled, (state, action) => {
      state.isCreatingDevelopment = false;
      state.currentDevelopment = action.payload.data.createDevelopment;
      state.developmentList.unshift(action.payload.data.createDevelopment);
      state.mode = MODE_OPTIONS.READ;
      toast.success('Development Created Successfully');
    });
    builder.addCase(createDevelopment.rejected, (state, action) => {
      state.isCreatingDevelopment = false;
      console.error(action.error);
      toast.error('createDevelopment API request rejected');
    });

    builder.addCase(getDevelopment.pending, state => {
      state.isGettingDevelopment = true;
    });
    builder.addCase(getDevelopment.fulfilled, (state, action) => {
      state.isGettingDevelopment = false;
      state.currentDevelopment = action.payload.data.getDevelopment;
    });
    builder.addCase(getDevelopment.rejected, (state, action) => {
      state.isGettingDevelopment = false;
      console.error(action.error);
      toast.error('getDevelopment API request rejected');
    });
  },
});

export const { setMode, updateDevelopmentProperty, newDevelopment } = propertiesV2Slice.actions;
export default propertiesV2Slice.reducer;
