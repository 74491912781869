import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { GET_PROVISION } from '.';

export const getClientProvision = createAsyncThunk(
  'provisions/getClientProvision',
  async ({ id }: { id: string | undefined | null }) => {
    const response = await client.query(GET_PROVISION(id as string));
    return response;
  },
);
