import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import Industry from 'common/model/Industry';
import { BenchmarkInput } from 'common/api/benchmark';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface UpdatedBenchmarkInput extends Omit<BenchmarkInput, 'id' | 'industryId'> {
  id?: string | null;
  industryId: string | null;
  industry?: Industry;
}

interface QueryVariables {
  currentLandlordEntityId: string | null;
  params: UpdatedBenchmarkInput;
}

const CREATE_BENCHMARK = (benchmark: BenchmarkInput) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Create Benchmark: Current Landlord Entity ID is missing');

  // Assuming that industry directly maps to industryId in terms of value/type
  const { industry, reviewStatus, ...rest } = benchmark;

  const params: UpdatedBenchmarkInput = {
    ...rest,
    industryId: String(industry),
    reviewStatus: {
      date: reviewStatus.date,
      userId: reviewStatus.user?.id,
    },
  };

  delete params.id;
  delete params.industry;

  const variables: QueryVariables = {
    currentLandlordEntityId,
    params,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: BenchmarkInput!) {
        createBenchmark(currentLandlordEntityId: $currentLandlordEntityId, params: $params) {
          id
        }
      }
    `,
    variables,
  };
};

export const createBenchmark = createAsyncThunk('benchmarks/create', async (_, { getState }) => {
  const state: RootState = getState() as RootState;
  const { activeBenchmark } = state.benchmarkDetail;
  const response = await client.mutate(CREATE_BENCHMARK(activeBenchmark));
  return response;
});
