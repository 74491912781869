import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParameterGroupPickName } from 'common/_classes';
import { getClientType } from 'utils/utils-authentication';

export const LIST_PARAMETER_GROUPS = () => {
  return {
    query: gql`
      query {
        listParameterGroups {
          ${ParameterGroupPickName.fragments()}
        }
      }
    `,
  };
};

export const listParameterGroups = createAsyncThunk('parameters/listParameterGroups', async () => {
  const response = await getClientType().query(LIST_PARAMETER_GROUPS());
  return response;
});
