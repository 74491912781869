import { Form } from 'semantic-ui-react';
import { ModeProps, ViewProps } from 'common/api/miscellaneous';
import CapitalAllowance from './CapitalAllowance';
import DatesForm from './Dates';
import Documents from './Documents';
import FitoutDepositAndFee from './FitoutDepositAndFee';
import GeneralForm from './General';
import HandOverConditionForm from './HandOverCondition';
import OtherCharges from './OtherCharges';
import PartiesForm from './Parties';
import Premises from './Premises';
import RentAndRentReview from './RentAndRentReview';
import RentFreePeriod from './RentFreePeriod';
import SecurityForm from './Security';
import SpecialClauses from './SpecialClauses';
import TermForm from './Term';
import UnsavedChangesModal from './UnsavedChangesModal';
import UseForm from './Use';

const ContractSection = (props: any) => {
  return (
    <div className="grid border-top m-1 pt-3">
      <div className="col-2">
        <h4 className="section-title m-none">{props.title}</h4>
      </div>
      <div className="col-10">{props.children}</div>
    </div>
  );
};

const ContractForm = ({ mode, isViewPage }: ModeProps & ViewProps) => {
  // const {
  //   incompleteFields,
  //   translationTransaction,
  // } = useAppSelector((state: RootState) => state.contractDetail);
  //const transactionLink = translationTransaction !== null ? true : false;
  //const transactionLinkText = transactionLink ? 'TRANSACTION' : 'UNLINKED';
  {
    /* <Button className={`transaction-link-btn ${!transactionLink ? ColorNames.GRAY : ''}`}>
        {transactionLinkText}
      </Button> */
  }

  const formElements: { title: string; element: JSX.Element }[] = [
    {
      title: 'Parties',
      element: <PartiesForm mode={mode} />,
    },
    {
      title: 'Premises',
      element: <Premises mode={mode} />,
    },
    {
      title: 'Handover Condition',
      element: <HandOverConditionForm mode={mode} />,
    },
    {
      title: 'Use',
      element: <UseForm mode={mode} />,
    },
    {
      title: 'Term',
      element: <TermForm mode={mode} />,
    },
    {
      title: 'Dates',
      element: <DatesForm mode={mode} />,
    },
    {
      title: 'Rent & rent review',
      element: <RentAndRentReview mode={mode} />,
    },
    {
      title: 'Other charges',
      element: (
        <OtherCharges
          mode={mode}
          isViewPage={isViewPage}
        />
      ),
    },
    {
      title: 'Rent free period',
      element: <RentFreePeriod mode={mode} />,
    },
    {
      title: 'Security',
      element: <SecurityForm mode={mode} />,
    },
    {
      title: 'Fit-out',
      element: <FitoutDepositAndFee mode={mode} />,
    },
    {
      title: 'Capital Allowance',
      element: <CapitalAllowance mode={mode} />,
    },
    {
      title: 'Special rights',
      element: <SpecialClauses mode={mode} />,
    },
    {
      title: 'General',
      element: (
        <GeneralForm
          mode={mode}
          isViewPage={isViewPage}
        />
      ),
    },
    {
      title: 'Documents',
      element: <Documents mode={mode} />,
    },
  ];

  return (
    <Form>
      {formElements.map(({ title, element }, index) => (
        <ContractSection
          key={index}
          title={`${index + 1}. ${title}`}
        >
          {element}
        </ContractSection>
      ))}

      {/* <IncompleteFieldsBox incompleteFields={incompleteFields} /> */}

      <UnsavedChangesModal />
    </Form>
  );
};

export default ContractForm;
