import { Icon } from '@iconify/react';
import PDFBox from 'layouts/PDFBox';
import { Icons } from 'utils/utils-icons';

const UploadedDocs = ({ fileName, fileURL }: { fileName: string; fileURL: string | null | undefined }) => {
  const downloadSampleFile = () => {
    // Create a new link
    const anchor = document.createElement('a');
    anchor.href = fileURL && fileURL !== null ? fileURL : '';
    anchor.download = fileName;
    anchor.target = '_blank';

    // Append to the DOM
    document.body.appendChild(anchor);

    // Trigger `click` event
    anchor.click();

    // Remove element from DOM
    document.body.removeChild(anchor);
  };

  return (
    <div
      className="pdf-main uploaded-docs"
      onClick={downloadSampleFile}
    >
      <PDFBox />
      <p className="inner-text">{fileName}</p>
      <Icon
        className="download-icon"
        icon={Icons.Download}
      />
    </div>
  );
};

export default UploadedDocs;
