import moment from 'moment';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { updateBenchmarkInput } from 'store/benchmark/benchmarkDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const RentForm = ({ mode }: ModeProps) => {
  const {
    activeBenchmark: { size, rentPerArea, rentFreePeriod, startDate, endDate },
  } = useAppSelector((state: RootState) => state.benchmarkDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string) => {
    dispatch(updateBenchmarkInput({ key, value }));
  };

  // Calculate the duration of the terms in months (this number can be decimal,
  // to take into account non complete months too)
  const termsDuration: number = moment(endDate).add(1, 'days').diff(moment(startDate), 'months', true);

  return (
    <div className="grid">
      <div className="col-6">
        <InputField
          label="Rent per sqft"
          value={String(rentPerArea)}
          type={InputFieldType.NUMBER}
          dataTest="rent-per-sqft"
          unit={'(HK$/sqft/month)'}
          disabled={mode === MODE_OPTIONS.READ}
          fieldKey="rentPerArea"
          onChange={onChange}
          isDecimal={true}
          delimiter="COMMA"
        />
        <InputField
          label="Monthly base rent"
          value={
            rentPerArea !== null && size !== null
              ? (parseFloat(String(rentPerArea)) * parseFloat(String(size))).toString()
              : null
          }
          unit={'(HK$/month)'}
          disabled={true}
          fieldKey="monthlyBaseRent"
          onChange={() => {}}
        />
      </div>
      <div className="col-6">
        <InputField
          label="Rent Free Period (months)"
          value={String(rentFreePeriod) || '0'}
          type={InputFieldType.NUMBER}
          dataTest="rent-free-period"
          unit="(month)"
          disabled={mode === MODE_OPTIONS.READ}
          fieldKey="rentFreePeriod"
          onChange={onChange}
          isDecimal={true}
          delimiter="COMMA"
        />
        <InputField
          label="Effective rent"
          value={
            rentPerArea !== null && rentFreePeriod !== null && size !== null && termsDuration !== null
              ? (
                  ((parseFloat(String(rentPerArea)) * parseFloat(String(size))) / termsDuration) *
                  (termsDuration - parseFloat(String(rentFreePeriod)))
                ).toFixed(2)
              : null
          }
          unit={'(HK$/month)'}
          disabled={true}
          fieldKey="effectiveRent"
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

export default RentForm;
