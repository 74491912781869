import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import Entity from 'common/model/Entity';
import { EntityCreatorTypes, deleteEntity, exportTenant, listEntities, uploadTenant } from 'common/api/entities';
import { deleteNetwork } from 'common/api/entities/deleteNetwork';
import { autoDownloadExportedFile, dataURLtoFile, getCurrentLandlordEntityId } from 'utils/tsHelper';

const initialState = {
  entitiesList: [] as any[],
  search: null,
  isLoading: false,
  isSearching: false,
  importedTenant: null,
  exportSuccess: false,
  exportFail: false,
  isExporting: false,
};

const entitiesListingSlice = createSlice({
  name: 'entitiesListing',
  initialState,
  reducers: {
    clearImportedTenant: state => {
      state.importedTenant = null;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    //listEntities
    builder.addCase(listEntities.pending, (state, action) => {
      const { searchBarSpinOnly } = action.meta.arg;
      if (searchBarSpinOnly) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listEntities.fulfilled, (state, action) => {
      const { type } = action.meta.arg;
      const data = action.payload.data;
      const entities = data[`list${type}Entities`];
      state.entitiesList = entities?.edges ? entities.edges.map((element: { node: Entity }) => element.node) : [];
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listEntities.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listEntities');
    });

    //deleteEntity
    builder.addCase(deleteEntity.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteEntity.fulfilled, (state, action) => {
      const { id, type } = action.meta.arg;
      state.entitiesList = state.entitiesList.filter(obj => obj.id !== id);

      if (type === EntityCreatorTypes.Landlord) {
        const selectedEntity = getCurrentLandlordEntityId();

        if (selectedEntity === id) {
          localStorage.removeItem('sericin_selected_entity');
          window.location.href = '/welcome';
        }
      }

      state.isLoading = false;
    });
    builder.addCase(deleteEntity.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('deleteEntity API request rejected');
    });

    //deleteChildEntity
    builder.addCase(deleteNetwork.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteNetwork.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteNetwork.rejected, state => {
      state.isLoading = false;
      toast.error('Some Error Occurred');
    });

    //uploadTenant
    builder.addCase(uploadTenant.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(uploadTenant.fulfilled, (state, action) => {
      state.importedTenant = action.payload.data.uploadTenant;
      state.isLoading = false;

      toast.success(
        `Tenant(s) ${action.payload.data.uploadTenant.simulation ? 'import simulation successful' : 'imported successfully'} `,
      );
    });
    builder.addCase(uploadTenant.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      const { message } = action.error;
      if (message) {
        toast.error(message);
      } else {
        toast.error('uploadTenant API request rejected');
      }
    });

    //exportTenant
    builder.addCase(exportTenant.pending, state => {
      state.isExporting = true;
      state.exportSuccess = false;
      state.exportFail = false;
    });
    builder.addCase(exportTenant.fulfilled, (state, action) => {
      const {
        payload: {
          data: { exportTenant },
        },
        meta: {
          arg: { landlordName },
        },
      } = action;
      const formattedDate = moment().format('DD-MM-YYYY');

      const fileName = landlordName
        ? `${landlordName.toLowerCase()}-tenants-${formattedDate}.xlsx`
        : `Tenants-${formattedDate}.xlsx`;

      autoDownloadExportedFile(dataURLtoFile(exportTenant, fileName));
      state.exportSuccess = true;
      state.isExporting = false;
      toast.success('Tenant(s) exported successfully');
    });
    builder.addCase(exportTenant.rejected, (state, action) => {
      state.exportFail = true;
      state.isExporting = false;
      console.warn(action.error);
      toast.error('exportTenant API request rejected');
    });
  },
});

export const { updateSearch, clearImportedTenant } = entitiesListingSlice.actions;

export default entitiesListingSlice.reducer;
