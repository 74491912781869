import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import { FilterOnchangeKeys, RowAction, RowProps } from '../../RowProps';

export function applyListFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyListFilter: field key is missing');

  const value: string | string[] = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  if (!(rowAction.filterValue && rowAction.filterValue.length)) return true;
  if (typeof value === 'string') {
    return rowAction.filterValue.filter((x: string) =>
      // @ts-ignore
      value.toLowerCase().includes(x.toLowerCase()),
    ).length;
  }

  return rowAction.filterValue.filter((x: string) => {
    return value.indexOf(x) > -1;
  }).length;
}

const ListFilter = ({ filterValue, options, onChange }: RowFilterChildProps): JSX.Element => {
  return (
    <MultiSelect
      appendTo="self"
      value={filterValue}
      options={options}
      itemTemplate={(option: any) => option.text}
      onChange={(e: MultiSelectChangeEvent) => onChange(FilterOnchangeKeys.filterValue, e.value)}
      placeholder="Value"
      className="p-column-filter w-100"
      dataKey="value"
      optionLabel="text"
    />
  );
};

export default ListFilter;
