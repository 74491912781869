import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateInputAreaKey, updateInputKey } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { BUILDING_GRADE_OPTIONS, METHOD_OPTIONS, PROPERTY_TYPE_OPTIONS, UNIT_OPTIONS } from 'utils/UI';
import PropertyCheckbox from './PropertyCheckbox';
import './BuildingInfo.scss';

const BuildingInfo = ({ mode }: ModeProps) => {
  const { activeProperty } = useAppSelector((state: RootState) => state.propertyDetail);
  const disabled: boolean = mode === MODE_OPTIONS.READ;
  const required: boolean = mode === MODE_OPTIONS.EDIT;

  const {
    buildingName,
    type,
    lotNumber,
    buildingGrade,
    address,
    measurementUnit,
    measurementMethod,
    description,
    buildingIdentifier,
    buildingNumberOfFloors,
    buildingNumberOfUndergroundFloors,
    area,
    developmentName,
    developmentLotNumber,
    addressDistrict,
    hasCentralAc,
    hasCurtainWall,
    hasMetroCoveredAccess,
  } = activeProperty;

  const { gross, grossDescription, net, netDescription, lettable, lettableDescription, saleable, saleableDescription } =
    area;

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string | boolean | undefined) =>
    dispatch(updateInputKey({ key, value, tab: 'property information' }));

  const areaKeys = ['gross', 'lettable', 'net', 'saleable'];
  const onChangeArea = (key: string, value: string) => {
    let val: string | null = value;
    if (value === '' && areaKeys.includes(key)) {
      val = null;
    }
    dispatch(updateInputAreaKey({ key, value: val }));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <h4 className="section-title">Building information</h4>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <InputField
            label="Building name"
            placeholder="Enter building name..."
            dataTest="building-name"
            fieldKey="buildingName"
            value={buildingName}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <InputField
            label="Development name"
            placeholder="Enter development name..."
            dataTest="development-name"
            fieldKey="developmentName"
            value={developmentName}
            disabled={disabled}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <InputField
            label="Building lot no."
            placeholder="Enter building lot no..."
            dataTest="building-lot-number"
            fieldKey="lotNumber"
            value={lotNumber}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <InputField
            label="Development lot no."
            placeholder="Enter development lot no..."
            dataTest="development-lot-number"
            fieldKey="developmentLotNumber"
            value={developmentLotNumber}
            disabled={disabled}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <InputField
            label="Building Address"
            placeholder="Enter building address..."
            dataTest="building-address"
            fieldKey="address"
            value={address}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <InputField
            label="District"
            placeholder="Enter district..."
            dataTest="district"
            fieldKey="addressDistrict"
            value={addressDistrict}
            disabled={disabled}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="m-t-ml">
        <Grid.Column width={12}>
          <h4 className="section-title">Building Characteristics</h4>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <SelectField
            label="Building grade"
            placeholder="Select building grade"
            dataTest="building-grade"
            fieldKey="buildingGrade"
            value={buildingGrade}
            options={BUILDING_GRADE_OPTIONS}
            search={false}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <InputField
            label="Building identifier"
            placeholder="Type here..."
            fieldKey="buildingIdentifier"
            dataTest="building-identifier"
            value={buildingIdentifier}
            disabled={disabled}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <SelectField
            label="Building Main Use"
            placeholder="Select building main use"
            dataTest="property-type"
            fieldKey="type"
            value={type}
            options={PROPERTY_TYPE_OPTIONS}
            multiple={true}
            search={false}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </Grid.Column>
        <PropertyCheckbox
          title="Central AC"
          disabled={disabled}
          required={required}
          checked={hasCentralAc}
          fieldKey="hasCentralAc"
          onChange={onChange}
        />
        <PropertyCheckbox
          title="Curtain Wall"
          disabled={disabled}
          required={required}
          checked={hasCurtainWall}
          fieldKey="hasCurtainWall"
          onChange={onChange}
        />
        <PropertyCheckbox
          title="Covered access to MTR"
          disabled={disabled}
          required={required}
          checked={hasMetroCoveredAccess}
          fieldKey="hasMetroCoveredAccess"
          onChange={onChange}
        />
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={6}>
          <TextAreaField
            label="Other property information"
            placeholder="Type here.."
            dataTest="other-property-information"
            fieldKey="description"
            value={description}
            disabled={disabled}
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <InputField
            label="Building total number of floors"
            placeholder="Enter number of floors..."
            dataTest="number-of-bulding-floors"
            fieldKey="buildingNumberOfFloors"
            value={buildingNumberOfFloors}
            type={InputFieldType.NUMBER}
            disabled={disabled}
            required={required}
            onChange={onChange}
            delimiter="COMMA"
          />
          <InputField
            className="m-t-ml"
            label="Building total number of underground floors"
            placeholder="Enter number of underground floors number..."
            dataTest="number-of-underground-floor"
            fieldKey="buildingNumberOfUndergroundFloors"
            value={buildingNumberOfUndergroundFloors?.toString()}
            type={InputFieldType.NUMBER}
            disabled={disabled}
            required={required}
            onChange={onChange}
            delimiter="COMMA"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="m-t-ml">
        <Grid.Column width={12}>
          <h4 className="section-title">Building Measurement</h4>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <SelectField
            label="Measurement unit"
            placeholder="Select measurement unit"
            dataTest="measurement-unit"
            fieldKey="measurementUnit"
            value={measurementUnit}
            options={UNIT_OPTIONS}
            search={false}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <SelectField
            label="Measurement method"
            placeholder="Select measurement method"
            dataTest="measurement-method"
            fieldKey="measurementMethod"
            value={measurementMethod}
            options={METHOD_OPTIONS}
            search={false}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <InputField
            label="Gross area"
            placeholder="Enter gross area..."
            dataTest="gross-area"
            fieldKey="gross"
            value={gross}
            disabled={disabled}
            required={required}
            onChange={onChangeArea}
            type={InputFieldType.NUMBER}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <InputField
            label="Lettable area"
            placeholder="Enter lettable area..."
            dataTest="lettable-area"
            fieldKey="lettable"
            value={lettable}
            disabled={disabled}
            onChange={onChangeArea}
            type={InputFieldType.NUMBER}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <InputField
            label="Net area"
            placeholder="Enter net area..."
            dataTest="net-area"
            fieldKey="net"
            value={net}
            disabled={disabled}
            onChange={onChangeArea}
            type={InputFieldType.NUMBER}
            delimiter="COMMA"
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <InputField
            label="Saleable area"
            placeholder="Enter saleable area..."
            dataTest="saleable-area"
            fieldKey="saleable"
            value={saleable}
            disabled={disabled}
            onChange={onChangeArea}
            type={InputFieldType.NUMBER}
            delimiter="COMMA"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <TextAreaField
            label="Gross area definition"
            placeholder="Type here.."
            dataTest="gross-area-definition"
            fieldKey="grossDescription"
            value={grossDescription}
            disabled={disabled}
            onChange={onChangeArea}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <TextAreaField
            label="Lettable area definition"
            placeholder="Type here.."
            dataTest="lettable-area-definition"
            fieldKey="lettableDescription"
            value={lettableDescription}
            disabled={disabled}
            onChange={onChangeArea}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <TextAreaField
            label="Net area definition"
            placeholder="Type here.."
            dataTest="net-area-definition"
            fieldKey="netDescription"
            value={netDescription}
            disabled={disabled}
            onChange={onChangeArea}
          />
        </Grid.Column>
        <Grid.Column width={3}>
          <TextAreaField
            label="Saleable area definition"
            placeholder="Type here.."
            dataTest="saleable-area-definition"
            fieldKey="saleableDescription"
            value={saleableDescription}
            disabled={disabled}
            onChange={onChangeArea}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BuildingInfo;
