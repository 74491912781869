import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import FullScreen from 'layouts/FullScreen';
import DropdownMenu, { DropdownItem } from 'atoms/DropdownMenu';
import PageEditor from 'components/Editor/PageEditor';
import SortOrder from 'common/model/SortOrder';
import { listFormatters } from 'common/api/formatters';
import {
  MultiLevelParamPaginationProps,
  listParameterGroups,
  listParameterTablesPage,
  listParameters,
} from 'common/api/multiLevelMenu';
import { getProvisionPDFDocument, getProvisionPdfHtml } from 'common/api/provisions';
import { Icons } from 'utils/utils-icons';
import EditorSideMenu from './EditorSideMenu';
import './Editor.scss';

interface CustomPaginationParameters extends Omit<MultiLevelParamPaginationProps, 'sortOrder'> {
  totalPages: number;
}

const Editor = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [activeDocTypeId, setActiveDocTypeId] = useState<string | undefined>(undefined);

  const {
    activeProvision: { id: provisionId },
  } = useAppSelector((state: RootState) => state.provisionDetail);

  useEffect(() => {
    const FIRST_PAGINATION: number = 1000,
      params: MultiLevelParamPaginationProps = {
        first: FIRST_PAGINATION,
        sortOrder: SortOrder.Asc,
      };
    const paramsWithTotalPages: CustomPaginationParameters = {
      ...params,
      totalPages: FIRST_PAGINATION,
    };

    // TODO: why do we need pagination to get the data. Shall we not get them all ?
    dispatch(listFormatters(paramsWithTotalPages));
    dispatch(listParameters({ ...params, choices: true }));
    dispatch(listParameterGroups());
    dispatch(listParameterTablesPage(params));
  }, [dispatch]);

  const previewDropdownItems: DropdownItem[] = [
    {
      key: '1',
      label: 'Preview HTML',
      icon: Icons.Html,
      onClick: () => dispatch(getProvisionPdfHtml({ provisionId: provisionId, documentTypeId: activeDocTypeId })),
    },
    {
      key: '2',
      label: 'Generate Pdf Template',
      icon: Icons.Pdf,
      onClick: () =>
        dispatch(
          getProvisionPDFDocument({
            provisionId: String(provisionId),
            documentTypeId: activeDocTypeId,
          }),
        ),
    },
  ];

  const editorAndSidePanel = (
    <div className="editor-container p-sm">
      <div className="d-flex editor-top-left">
        <Button
          className="btn grey-outline"
          onClick={() => setIsFullScreen(!isFullScreen)}
        >
          {isFullScreen ? 'GO BACK' : 'FULLSCREEN'}
        </Button>

        <DropdownMenu
          className="preview-menu-dropdown ui button btn grey-outline m-l-s"
          dropdownText="Preview"
          dropdownItems={previewDropdownItems}
        />
      </div>

      <PageEditor setActiveTabDocTypeId={setActiveDocTypeId} />
      <EditorSideMenu />
    </div>
  );

  return isFullScreen ? <FullScreen>{editorAndSidePanel}</FullScreen> : editorAndSidePanel;
};

export default Editor;
