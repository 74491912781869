import { Button, Form, Input } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import { updateInput } from 'store/authentication/authSlice';
import { forgotPassword } from 'common/api/authentication';
import { invalidEmailText } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { emailIsValid } from 'utils/utils-string';

const SendResetEmail = () => {
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state: RootState) => state.auth);

  const checkIfValidEmail = emailIsValid(email);

  return (
    <Form
      className="auth-form"
      step="1"
      onSubmit={() => dispatch(forgotPassword())}
    >
      <div className="content">
        <h1 className="header">
          Reset Password <Icon icon={Icons.CircleDownFilled} />
        </h1>
        <p className="reset-text">
          No worries, we all make mistakes. Fill out your email and we will send you a link to reset your password.
        </p>
        <Form.Field className="reset-email">
          <label>
            Email <span className="required">*</span>
          </label>
          <Input
            className={!checkIfValidEmail ? 'input-error-field' : ''}
            value={email}
            placeholder="Enter your email..."
            type={InputFieldType.EMAIL}
            onChange={e => dispatch(updateInput({ type: 'email', value: e.target.value }))}
            required={true}
          />
          {!checkIfValidEmail && (
            <p className="validation-message error-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              {invalidEmailText}
            </p>
          )}
        </Form.Field>
        <Button
          className="login-btn height-2_857 font-600 text-center"
          type="submit"
          disabled={!checkIfValidEmail}
        >
          RESET PASSWORD
          <Icon
            icon={Icons.ArrowDownRight}
            rotate={4}
            className="width-1_143 height-1_143"
          />
        </Button>
      </div>
    </Form>
  );
};

export default SendResetEmail;
