import Decimal from 'decimal.js';
import Conditions from 'common/model/Conditions';
import MParameterTable from 'common/model/ParameterTable';
import MParameterTableColumn from 'common/model/ParameterTableColumn';
import ParameterTableColumn from 'common/model/ParameterTableColumn';
import RowNumber from 'common/model/RowNumber';
import MTableValidTypes from 'common/model/TableValidTypes';
import {
  IParameterOnlyId,
  IParameterPickName,
  IParameterTableOnlyId,
  IParameterTablePickName,
} from 'common/api/provisions';
import { OmitMultiple } from 'utils/UI';
import { TParameterGroupPickName } from './ParameterGroupDetailClone';

type TParameterTableOnlyId = Pick<MParameterTable, 'id'>;

export interface ParameterTableColumnForm extends Omit<ParameterTableColumn, 'parameter' | 'table'> {
  parameter: IParameterOnlyId;
  table: IParameterTableOnlyId;
}

export interface ParameterTableColumnClone extends OmitMultiple<Pick<ParameterTableColumn, 'table'>, 'table'> {
  table: IParameterTablePickName;
}

export interface RowNumberClone extends OmitMultiple<RowNumber, 'parameter' | 'table'> {
  parameter: IParameterPickName | null;
  table: IParameterTablePickName | null;
}

interface IParameterTableDetailColumnClone
  extends OmitMultiple<
    Pick<MParameterTableColumn, 'id' | 'parameter' | 'table' | 'index' | 'title' | 'conditions'>,
    'id' | 'parameter' | 'table' | 'title'
  > {
  id: string | null;
  title: string | null;
  parameter: IParameterOnlyId | null;
  table: TParameterTableOnlyId;
}

export class ParameterTableDetailColumnClone implements IParameterTableDetailColumnClone {
  id: string | null;
  index: number;
  title: string | null;
  parameter: IParameterOnlyId | null;
  table: TParameterTableOnlyId;
  conditions: Conditions[];

  constructor(input: IParameterTableDetailColumnClone) {
    this.id = input.id;
    this.index = input.index;
    this.title = input.title;
    this.parameter = input.parameter;
    this.table = input.table;
    this.conditions = input.conditions;
  }

  static init(index: number, tableId: string): ParameterTableDetailColumnClone {
    return {
      id: null,
      index: index,
      title: null,
      parameter: null,
      table: { id: tableId },
      conditions: [],
    };
  }
}

interface IParameterTableDetailClone
  extends OmitMultiple<
    OmitMultiple<MParameterTable, 'insertedAt' | 'updatedAt'>,
    'id' | 'name' | 'index' | 'type' | 'description' | 'parameterGroup' | 'rowNumber' | 'columns'
  > {
  id: string | null;
  name: string | null;
  index: Decimal | null;
  type: MTableValidTypes | null;
  description: string | null;
  parameterGroup: TParameterGroupPickName | null;
  rowNumber: RowNumberClone | null;
  columns: IParameterTableDetailColumnClone[];
}

export class ParameterTableDetailClone implements IParameterTableDetailClone {
  id: string | null;
  name: string | null;
  index: Decimal | null;
  type: MTableValidTypes | null;
  description: string | null;
  parameterGroup: TParameterGroupPickName | null;
  rowNumber: RowNumberClone | null;
  columns: IParameterTableDetailColumnClone[];

  constructor(input: IParameterTableDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.type = input.type;
    this.description = input.description;
    this.parameterGroup = input.parameterGroup;
    this.rowNumber = input.rowNumber;
    this.columns = input.columns;
  }

  static fragments(): string {
    return `
      id
      name
      index
      type
      description
      parameterGroup {
        id
        name
      }
      rowNumber {
        parameter {
          id
          name
        }
        table {
          id
          name
        }
      }
      columns {
        id
        index
        title
        table {
          id
        }
        parameter {
          id
        }
        table {
          id
        }
        conditions {
          list {
            answer
            paramRef2 {
              tableId
              position
              offset
              parameterId
              index
            }
            comparator
            paramRef {
              tableId
              position
              offset
              parameterId
              index
            }
            position
            type
          }
          operator
        }
      }
    `;
  }

  static init(): ParameterTableDetailClone {
    return {
      id: null,
      name: null,
      index: null,
      description: null,
      type: null,
      rowNumber: null,
      columns: [],
      parameterGroup: null,
    };
  }
}
