import LandlordEntitiesDashboard from 'views/entities/landlordEntities/Dashboard';
import LandlordEntitiesCreator from 'views/entities/landlordEntities/EntityCreator/EntityCreator';
import LandlordEntitiesDetails from 'views/entities/landlordEntities/EntityDetails/EntityDetails';
import { isAuthenticated } from './redirects';

const LandlordEntitiesRoutes = () => [
  {
    path: '/landlord-entities',
    children: [
      {
        path: '/landlord-entities/dashboard',
        element: isAuthenticated(<LandlordEntitiesDashboard />),
      },
      {
        path: '/landlord-entities/create',
        element: isAuthenticated(<LandlordEntitiesCreator />),
      },
      {
        path: '/landlord-entities/:id/details',
        element: isAuthenticated(<LandlordEntitiesDetails />),
      },
    ],
  },
];

export default LandlordEntitiesRoutes;
