import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ProvisionDetailClone, convertToGuidelineInput } from 'common/_classes';
import { RootState } from 'store';
import { convertSpanToCode, setContentInput } from 'common/api/provisions';
import { convertToProvisionInput } from '.';

interface UpdateProvisionQueryVariables {
  id: string | undefined;
  params: unknown; //ProvisionInput
}

export const UPDATE_PROVISION = (activeProvision: ProvisionDetailClone) => {
  const convertedContents = convertSpanToCode(activeProvision.contents); //TODO: to be removed one day.
  const params = convertToProvisionInput(activeProvision);
  params.contents = setContentInput(convertedContents);
  params.guideline = convertToGuidelineInput(activeProvision.guideline);

  const variables: UpdateProvisionQueryVariables = {
    id: activeProvision.id,
    params: params,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: ProvisionInput!) {
        updateProvision(id: $id, params: $params) {
          ${ProvisionDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateProvision = createAsyncThunk('provisions/updateProvision', async (_args, { getState }) => {
  const {
    provisionDetail: { activeProvision },
  } = getState() as RootState;
  const response = await adminClient.mutate(UPDATE_PROVISION(activeProvision));
  return response;
});
