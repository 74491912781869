import { CHARACTERISTICS_OPTIONS } from 'utils/UI';
import './FloorUnitCharacteristics.scss';

interface FloorUnitCharacteristicsProps {
  id: string;
  characteristics: string[] | null;
}

const FloorUnitCharacteristics = ({ id, characteristics }: FloorUnitCharacteristicsProps): JSX.Element => {
  const characteristicsText = (value: string) => {
    const getCharacteristic = CHARACTERISTICS_OPTIONS.find(obj => obj.value === value);
    return getCharacteristic?.text;
  };

  return (
    <div className="characteristics-box">
      {characteristics?.map((characteristic: string, index: number) => (
        <div
          className="characteristic-inner"
          key={`${id}-characteristic-${index}`}
        >
          {characteristicsText(characteristic)}
        </div>
      ))}
    </div>
  );
};

export default FloorUnitCharacteristics;
