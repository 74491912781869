import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { EntityCreatorTypes } from './types';

interface DeleteEntityQueryVariables {
  currentLandlordEntityId?: string;
  entityId: string;
}

export const DELETE_ENTITY = (type: EntityCreatorTypes, entityId: string) => {
  if (!entityId) throw new Error('Delete Entity: entityId is required');

  let currentLandlordEntity = '',
    innerTextLandlordEntity = '',
    currentLandlordEntityId;

  if (type === EntityCreatorTypes.Tenant) {
    currentLandlordEntityId = getCurrentLandlordEntityId();

    if (!currentLandlordEntityId) throw new Error('Delete Entity: currentLandlordEntityId is required');

    currentLandlordEntity = '$currentLandlordEntityId: ID!,';
    innerTextLandlordEntity = 'currentLandlordEntityId: $currentLandlordEntityId,';
  }

  const variables: DeleteEntityQueryVariables = {
    currentLandlordEntityId,
    entityId,
  };

  return {
    mutation: gql`
        mutation(${currentLandlordEntity} $entityId: ID!){
        delete${type}Entity(${innerTextLandlordEntity} entityId: $entityId){
            id
        }
      }
      `,
    variables,
  };
};

export const deleteEntity = createAsyncThunk(
  'entitesListing/deleteEntity',
  async ({ type, id }: { type: EntityCreatorTypes; id: string }) => {
    const response = await client.mutate(DELETE_ENTITY(type, id));
    return response;
  },
);
