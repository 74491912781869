import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import _cloneDeep from 'lodash/cloneDeep';
import { updateRowGraphData, updateSelectedFloor, updateSideBar } from 'store/dashboard/stackingPlanSlice';
import { deepClone } from 'utils/tsHelper';
import { Floor, GraphInput, GraphInputDataMeta } from 'utils/types/stackingPlan';
import { addSpaceOrComma, convertToString } from 'utils/utils-number';
import { formatTenantName, getGraphConfiguration, mapToGraphData } from './GraphConfig';
import { sideSections } from './StackingPlanSideSections';
import { AVAILABILITY_STATUS, BAR_COLOR, HIGHLIGHTED_BAR_COLOR } from './stackingPlanDefinitions';
import './StackingPlan.scss';

const GRAPH_MIN_HEIGHT = 300;
const GRAPH_MIN_HEIGHT_PER_FLOOR = 45;

const StackingPlanGraph = () => {
  const { isLoading, floors, totalFloor, buildingNumberOfUndergroundFloors, blocks, graphData, monitoringDate } =
    useAppSelector((state: RootState) => state.stackingPlan);
  const dispatch = useAppDispatch();

  const [height, setHeight] = useState<number>(GRAPH_MIN_HEIGHT);
  const [maxBarArea, setMaxBarArea] = useState<number>(0);

  useEffect(() => {
    const calculatedHeight: number = (buildingNumberOfUndergroundFloors + totalFloor) * GRAPH_MIN_HEIGHT_PER_FLOOR;
    console.log('calculatedHeight', calculatedHeight,GRAPH_MIN_HEIGHT);
    setHeight(calculatedHeight < GRAPH_MIN_HEIGHT ? GRAPH_MIN_HEIGHT : calculatedHeight);
  }, [totalFloor, buildingNumberOfUndergroundFloors]);

  useEffect(() => {
    dispatch(updateSideBar({ sideBar: 0 }));
    const { data, maxArea } = mapToGraphData(
      floors,
      totalFloor,
      buildingNumberOfUndergroundFloors,
      blocks,
      monitoringDate,
    );
    setMaxBarArea(maxArea);
    dispatch(updateRowGraphData({ graphData: data }));
  }, [floors]);

  const getStackingPlanBarColor = (status: string): string => {
    if (status === AVAILABILITY_STATUS.AVAILABLE) return BAR_COLOR.AVAILABLE;
    if (status === AVAILABILITY_STATUS.LESS_THAN_SIX) return BAR_COLOR.LESS_THAN_SIX;
    if (status === AVAILABILITY_STATUS.MORE_THAN_TWENTY_FOUR) return BAR_COLOR.MORE_THAN_TWENTY_FOUR;
    if (status === AVAILABILITY_STATUS.SIX_TO_TWELVE) return BAR_COLOR.SIX_TO_TWELVE;
    if (status === AVAILABILITY_STATUS.TWELVE_TO_TWENTY_FOUR) return BAR_COLOR.TWELVE_TO_TWENTY_FOUR;
    return '';
  };

  /**
   * Bar Click handler for Graph
   */
  const barClickHandler = (event: any, chartContext: any, { seriesIndex, dataPointIndex, config: { series } }: any) => {
    const { contractId, futureContractIds, floorName, floorId }: GraphInput =
      (series[seriesIndex] &&
        series[seriesIndex].data[dataPointIndex] &&
        series[seriesIndex].data[dataPointIndex].meta) ||
      {};

    if (seriesIndex === -1) {
      dispatch(updateSideBar({ sideBar: 0 }));
      resetToDefaultColor(series);
    } else if (contractId) {
      dispatch(updateSideBar({ sideBar: sideSections.CURRENT_LEASE }));
      highlightContractBar(series, contractId);
    } else {
      dispatch(updateSideBar({ sideBar: sideSections.EMPTY_AREA_DETAILS }));
      resetToDefaultColor(series);
    }

    dispatch(
      updateSelectedFloor({
        floor: {
          floorId,
          floorName,
          floorNumber: totalFloor + buildingNumberOfUndergroundFloors - dataPointIndex - 1,
          floorArea:
            series[seriesIndex] &&
            series[seriesIndex].data[dataPointIndex] &&
            series[seriesIndex].data[dataPointIndex].y,
          contractId,
          futureContractIds,
        },
      }),
    );
  };

  const getStackingPlanBarHighlightedColor = (status: string): string => {
    switch (status) {
      case AVAILABILITY_STATUS.AVAILABLE:
        return HIGHLIGHTED_BAR_COLOR.AVAILABLE;
      case AVAILABILITY_STATUS.LESS_THAN_SIX:
        return HIGHLIGHTED_BAR_COLOR.LESS_THAN_SIX;
      case AVAILABILITY_STATUS.MORE_THAN_TWENTY_FOUR:
        return HIGHLIGHTED_BAR_COLOR.MORE_THAN_TWENTY_FOUR;
      case AVAILABILITY_STATUS.SIX_TO_TWELVE:
        return HIGHLIGHTED_BAR_COLOR.SIX_TO_TWELVE;
      case AVAILABILITY_STATUS.TWELVE_TO_TWENTY_FOUR:
        return HIGHLIGHTED_BAR_COLOR.TWELVE_TO_TWENTY_FOUR;
      default:
        return '';
    }
  };

  /**
   * Highlight all the bars related to selected contract
   */
  const highlightContractBar = (series: GraphInput[], selectedContractId: string) => {
    const seriesData = deepClone(series);

    for (let index = 0; index < seriesData.length; index++) {
      const meta: GraphInputDataMeta | undefined = seriesData[index].data[0].meta;
      if (meta) {
        const { contractId, availabilityStatus } = meta;
        if (!contractId) {
          continue;
        }
        if (contractId === selectedContractId) {
          seriesData[index].color = getStackingPlanBarHighlightedColor(availabilityStatus);
        } else {
          seriesData[index].color = getStackingPlanBarColor(availabilityStatus);
        }
      }
    }
    dispatch(updateRowGraphData({ graphData: seriesData }));
  };

  /**
   * Un-highlight all the bars
   */
  const resetToDefaultColor = (series: GraphInput[]) => {
    const seriesData = deepClone(series);
    for (let index = 0; index < seriesData.length; index++) {
      const meta: GraphInputDataMeta | undefined = seriesData[index].data[0].meta;
      if (meta) {
        const { contractId, availabilityStatus } = meta;
        if (contractId) {
          seriesData[index].color = getStackingPlanBarColor(availabilityStatus);
        }
      }
    }
    dispatch(updateRowGraphData({ graphData: seriesData }));
  };

  /**
   * Y Axis Label Formatter
   */
  const yAxisLabelsFormatter = (val: number, options: any) => {
    if (options && options.dataPointIndex > -1) {
      const dataIndex = totalFloor - options.dataPointIndex - 1;
      const floor: Floor | undefined = floors.find((floorDetail: Floor) => {
        const dataIndex = totalFloor - options.dataPointIndex - 1;
        return parseInt(floorDetail.index) === dataIndex;
      });
      if (floor) {
        return floor.name || convertToString(dataIndex);
      } else {
        return convertToString(dataIndex);
      }
    }
    return convertToString(val);
  };

  /**
   * Bar Label Formatter
   */
  const barLabelsFormatter = (value: number, { seriesIndex, config, dataPointIndex }: any) => {
    const { series } = config;
    const { tenantName }: GraphInputDataMeta = (series && series[seriesIndex].data[dataPointIndex].meta) || {};
    if (!tenantName) {
      return '';
    }
    const area = series[seriesIndex].data[dataPointIndex].y;
    if (!maxBarArea || !area) {
      return tenantName;
    }
    const barSize = (area * 100) / Number(maxBarArea);
    if (barSize > 50) {
      return formatTenantName(value, tenantName, 30);
    }
    if (barSize > 30) {
      return formatTenantName(value, tenantName, 15);
    }
    if (barSize > 20) {
      return formatTenantName(value, tenantName, 10);
    }
    if (barSize > 10) {
      return formatTenantName(value, tenantName, 6);
    }
    if (barSize > 7) {
      return formatTenantName(value, tenantName, 2);
    }

    return value;
  };

  const toolTipTemplate = (label: string, value: string) => {
    return `
          <div class="tooltip-row">
            <span class="left">${label} </span>
            <span class="right">${value}</span>
          </div>
    `;
  };

  /**
   * Bar Tooltip Formatter
   */
  const barTooltipFormatter = ({ seriesIndex, dataPointIndex, w }: any) => {
    const { series } = w.config || {};
    if (!series) {
      return '';
    }
    const { tenantName, headlineRent, availableDate, effectiveRent }: GraphInputDataMeta =
      (series && series[seriesIndex].data[dataPointIndex].meta) || {};
    const area = addSpaceOrComma(series && series[seriesIndex].data[dataPointIndex].y, false);
    if (tenantName) {
      return `
            <div class="tooltip-data">
              ${toolTipTemplate('Tenant', tenantName)}
              ${toolTipTemplate('Total Space', area + ' sqft. gross')}
              ${toolTipTemplate('Headline rent', addSpaceOrComma(headlineRent, false) + ' HK$')}
              ${toolTipTemplate('Effective rent', addSpaceOrComma(effectiveRent, false) + ' HK$')}
              ${toolTipTemplate('Availability Date', moment(availableDate).format('DD/MM/YYYY'))}

            </div>`;
    } else {
      return `
          <div class="tooltip-data">
          ${toolTipTemplate('Total Space', area + ' sqft. gross')}
          </div>
      `;
    }
  };

  const StackingChartConfigurations: ApexOptions = getGraphConfiguration({
    barClickHandler,
    yAxisLabelsFormatter,
    barLabelsFormatter,
    barTooltipFormatter,
    totalFloor,
    buildingNumberOfUndergroundFloors,
    maxScale: maxBarArea,
  });

  const isDataValid = graphData && graphData.length > 0 && !!StackingChartConfigurations;

  return isDataValid ? (
    <ReactApexChart
      series={_cloneDeep(graphData)}
      options={StackingChartConfigurations}
      type="bar"
    />
  ) : (
    <div>No data available to display the graph.</div>
  );
};

export default StackingPlanGraph;
