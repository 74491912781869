import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import PolicyRegulatories from 'components/PolicyRegulatories';
import 'views/provisions/CreatorViewer/Tabs/GeneralTab/ProvisionGeneralForm/ProvisionGeneralForm.scss';
import { updateActiveTransaction } from 'store/transactions/transactionDetailSlice';
import { DocumentTypeClone, MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION } from 'utils/UI';
import { checkNotEmpty } from 'utils/tsValidator';

const PolicyDocuments = ({ mode }: ModeProps): JSX.Element => {
  const {
    activeTransaction: { policyId, documentTypeIds, regulatories },
    policiesDropdownOptions,
    policyDocs,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { documentTypesOptions, documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);
  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: any): void => {
    dispatch(updateActiveTransaction({ key, value, userId: userId }));
  };

  const documentTypesOptionsFiltered: DROPDOWN_OPTION[] = documentTypesOptions.filter(obj =>
    // @ts-ignore
    policyDocs.includes(obj.value),
  );

  const sortDocs = (docIds: string[]): string[] => {
    const listOfIds: string[] = documentTypesList.map((documentType: DocumentTypeClone) => documentType.id);
    return [...docIds].sort((a, b) => listOfIds.indexOf(a) - listOfIds.indexOf(b));
  };

  const sortedDocIds: string[] = sortDocs(documentTypeIds);

  const docFieldKey: string = 'documentTypeIds';

  const automateDocsForSelectedPolicy = (): void => {
    const newDocumentTypeIds = documentTypesOptionsFiltered.map(option => option.value);

    onChange(docFieldKey, newDocumentTypeIds);
  };

  useEffect(() => {
    // Select all docs of policy when policyId changes
    if (mode === MODE_OPTIONS.EDIT) automateDocsForSelectedPolicy();
  }, [policyId]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <SelectField
            label="Policy applied"
            fieldKey="policyId"
            dataTest="transaction-select-policy"
            value={policyId}
            options={policiesDropdownOptions}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            runValidation={!checkNotEmpty(policyId) || policyId === undefined}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <SelectField
            label="Documents produced"
            value={sortedDocIds}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select..."
            dataTest="transaction-select-documents"
            multiple={true}
            options={documentTypesOptionsFiltered}
            onChange={onChange}
            fieldKey={docFieldKey}
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <PolicyRegulatories
            mode={mode}
            regulatories={regulatories}
            editable={false}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PolicyDocuments;
