import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import PhotoInput from 'atoms/FormField/PhotoInput';
import { deletePhoto, deleteSelectedPhoto } from 'store/authentication/authSlice';
import { prepUserPhotoForUpload } from 'common/api/authentication';

interface PhotoInputWrapperProps {
  isDisabled: boolean;
}

const PhotoInputWrapper = ({ isDisabled }: PhotoInputWrapperProps) => {
  const { photoUrl, deletePreviousPhoto, uploadUrl } = useAppSelector((state: RootState) => state.auth);

  return (
    <PhotoInput
      disabled={isDisabled}
      src={photoUrl}
      clearUploadedPhoto={uploadUrl ? false : true}
      prepUpload={prepUserPhotoForUpload}
      deleteSelectedPhoto={deleteSelectedPhoto}
      deletePhoto={deletePhoto}
      deletePreviousPhoto={deletePreviousPhoto}
    />
  );
};

export default PhotoInputWrapper;
