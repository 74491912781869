import { Image, Popup } from 'semantic-ui-react';
import GuidanceIcon from 'assets/images/svg/guidance.svg';
import ValuationIcon from 'assets/images/svg/valuation.svg';
import './GuidelinesDisplayOnly.scss';

interface GuidelinesDisplayOnlyProps {
  generalGuidance: string | null | undefined;
  valuationGuidance: string | null | undefined;
  className?: string;
}

const displayGuidance = (guidance: string) => (
  <div
    className="content editor-to-html"
    dangerouslySetInnerHTML={{
      __html: guidance,
    }}
  ></div>
);

const GuidelinesDisplayOnly = ({ generalGuidance, valuationGuidance, className = '' }: GuidelinesDisplayOnlyProps) => {
  return (
    <div className={`guidelines-grid ${(generalGuidance || valuationGuidance) && className}`}>
      {generalGuidance && (
        <Popup
          className="guidelines-popup"
          content={displayGuidance(generalGuidance)}
          trigger={
            <div className="columns p-sm">
              <div className="title">
                <Image
                  src={ValuationIcon}
                  className="guidelines-icon"
                />
                <h3 className="p-none m-none">General Guidance</h3>
              </div>
            </div>
          }
          on="click"
          basic={true}
          position="bottom left"
        />
      )}

      {valuationGuidance && (
        <Popup
          className="guidelines-popup"
          content={displayGuidance(valuationGuidance)}
          trigger={
            <div className="columns p-sm">
              <div className="title">
                <Image
                  src={GuidanceIcon}
                  className="guidelines-icon"
                />
                <h3 className="p-none m-none">Valuation Guidance</h3>
              </div>
            </div>
          }
          on="click"
          basic={true}
          position="bottom right"
        />
      )}
    </div>
  );
};

export default GuidelinesDisplayOnly;
