import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/adminUsers/adminUsersListingSlice';
import { listUsers } from 'common/api/adminUsers';
import UserModal from '../components/TeamModals/UserModal';

const AdminUsersHeaderToolbar = () => {
  const { search } = useAppSelector((state: RootState) => state.adminUsers);

  const onGetData = (value?: string) => {
    // @ts-ignore
    return listUsers({ searchValue: value, first: 1000 });
  };

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      <UserModal createMode={true} />
    </>
  );
};

export default AdminUsersHeaderToolbar;
