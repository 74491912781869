import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface HiddenMenuState {
  activeTab: number;
  selectedParameterItems: string[];
  selectedFormatterFunction: string | null;
  parameterErrorMessage: string;
  /* id of the node that should be edited. Will default to node creation if no id
   * is given. */
  nodeToEdit: string;
  editorTab: string;
  nodeSelectParameter: string | null;
}

const initialState: HiddenMenuState = {
  activeTab: 0,
  selectedParameterItems: [''],
  selectedFormatterFunction: null,
  parameterErrorMessage: '',
  nodeToEdit: '',
  editorTab: '',
  nodeSelectParameter: null,
};

interface ReceiveParameter {
  index: number;
  value: string;
}

export const hiddenMenuSlice = createSlice({
  name: 'hiddenMenu',
  initialState,
  reducers: {
    updateSidebarTab: (state, action) => {
      state.activeTab = action.payload;
    },
    resetParameterItems: state => {
      state.selectedParameterItems = [''];
    },
    addParameterItem: state => {
      state.selectedParameterItems.push('');
    },
    removeParameterItem: (state, action: PayloadAction<number>) => {
      if (state.selectedParameterItems.length === 1) throw new Error('Not allowed to delete last parameter item!');

      const index = action.payload;
      if (state.selectedParameterItems[index] === undefined)
        throw new Error(`Tried to access non-existent parameter item at index ${index}!`);

      /* Copy it and assign */
      const tmp: string[] = [...state.selectedParameterItems];
      tmp.splice(index, 1);
      return { ...state, selectedParamItems: tmp };
    },
    receiveParameter: (state, action: PayloadAction<ReceiveParameter>) => {
      const { index, value } = action.payload;

      if (state.selectedParameterItems[index] === undefined)
        throw new Error(`Tried to access non-existent parameter item at index ${index} ` + `to write ${value}.`);

      state.selectedParameterItems[index] = value;
    },
    receiveParameterErrorMessage: (state, action: PayloadAction<string>) => {
      state.parameterErrorMessage = action.payload;
    },
    receiveNodeToEdit: (state, action: PayloadAction<string>) => {
      state.nodeToEdit = action.payload;
    },
    receiveFormatterFunction: (state, action: PayloadAction<string | null>) => {
      state.selectedFormatterFunction = action.payload;
    },
    receiveEditorTab: (state, action: PayloadAction<string>) => {
      state.editorTab = action.payload;
    },
    receiveNodeSelectParameter: (state, action: PayloadAction<any>) => {
      state.nodeSelectParameter = action.payload;
    },
  },
});

export const {
  updateSidebarTab,
  resetParameterItems,
  addParameterItem,
  removeParameterItem,
  receiveParameter,
  receiveParameterErrorMessage,
  receiveNodeToEdit,
  receiveFormatterFunction,
  receiveEditorTab,
  receiveNodeSelectParameter,
} = hiddenMenuSlice.actions;

export default hiddenMenuSlice.reducer;
