import { deepClone } from './tsHelper';

export const updateNestedObject = <T>(obj: T, keys: string | string[], value: any): T => {
  const newObject = deepClone(obj);
  // Ensure keys is an array
  const keyArray = typeof keys === 'string' ? keys.split(',') : keys;

  let current: any = newObject;

  // Traverse the object
  for (let i = 0; i < keyArray.length - 1; i++) {
    const key = keyArray[i].trim();
    if (Array.isArray(current)) {
      // Parse key as a number for array indices
      const index = parseInt(key, 10);
      if (isNaN(index) || index < 0 || index >= current.length) {
        throw new Error(`Index "${key}" is out of bounds or not a number`);
      }
      current = current[index];
    } else if (current && typeof current === 'object' && key in current) {
      current = current[key];
    } else {
      throw new Error(`Key "${key}" does not exist`);
    }
  }

  // Last key for setting the value
  const lastKey = keyArray[keyArray.length - 1].trim();

  if (Array.isArray(current)) {
    // Parse last key as a number for array indices
    const index = parseInt(lastKey, 10);
    if (isNaN(index) || index < 0 || index >= current.length) {
      throw new Error(`Index "${lastKey}" is out of bounds or not a number`);
    }
    current[index] = value;
  } else if (current && typeof current === 'object' && lastKey in current) {
    current[lastKey] = value;
  } else {
    throw new Error(`Key "${lastKey}" does not exist`);
  }

  return newObject;
};

export const getNestedValue = <T>(obj: T, keys: string | string[]): any => {
  // Ensure keys is an array
  const keyArray = typeof keys === 'string' ? keys.split(',') : keys;

  let current: any = obj;

  // Traverse the object to get the value
  for (let i = 0; i < keyArray.length; i++) {
    const key = keyArray[i].trim();

    if (Array.isArray(current)) {
      // Parse key as a number for array indices
      const index = parseInt(key, 10);
      if (isNaN(index) || index < 0 || index >= current.length) {
        throw new Error(`Index "${key}" is out of bounds or not a number`);
      }
      current = current[index];
    } else if (current && typeof current === 'object' && key in current) {
      current = current[key];
    } else {
      throw new Error(`Key "${key}" does not exist`);
    }
  }

  return current;
};
