export interface ImportReportProps {
  dataType?: string;
  items: ContractType[] | PropertyType[];
  itemsDuplicateBase: Array<LogsType>;
  itemsDuplicateExcel: Array<LogsType>;
  itemsModified: ContractType[] | PropertyType[];
  logs: LogsType[];
  simulation?: boolean;
}

export interface LogsType {
  log: string;
  type: string;
}

export enum DataObjectName {
  CONTRACT = 'Contract',
  PROPERTY = 'Property',
  TENANT = 'Tenant',
}

export interface UploadReportProps {
  className?: string;
  importReport: ImportReportProps;
  clearReport?: any;
  dataObjectName: DataObjectName;
}

export interface TenantType {
  name: string;
  identifier: string;
  address: string;
}

export interface ContractType {
  identifier: string;
}

export interface PropertyType {
  buildingName: string;
  buildingIdentifier: string;
}

export interface ImportDetailsProps {
  dataObjectName: DataObjectName;
  details: ContractType[] | PropertyType[] | TenantType[];
}
