import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { TranslateToContractDetailClone } from 'common/_classes/TranslateToContractDetailClone';

interface GetTranslateToContractQueryVariables {
  id: string;
}

export const GET_TRANSLATE_TO_CONTRACT = (id: string) => {
  if (!id) throw new Error('Get TranslateToContract: id is required');

  const variables: GetTranslateToContractQueryVariables = { id };

  return {
    query: gql`
      query ($id: ID!) {
        getTranslateToContract(id: $id) {
          ${TranslateToContractDetailClone.fragment()}
        }
      }
    `,
    variables,
  };
};

export const getTranslateToContract = createAsyncThunk(
  'translatorsToContract/getTranslateToContract',
  async ({ id }: { id: string }) => {
    const response = await adminClient.query(GET_TRANSLATE_TO_CONTRACT(id));

    return response;
  },
);
