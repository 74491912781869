import moment from 'moment';
import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import InputWithUnit from 'atoms/FormField/InputWithUnit';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { testUpdateAnswerInput } from 'store/formatters/formatterDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonTypes } from 'common/api/formatters';
import { isValidTimeInput } from 'utils/utils-date';
import FormatterMultiChoice from './FormatterMultiChoice';

interface FormatterInputTestProps {
  type: AnswerTypes | null;
  label: string | null;
  index: number;
}

const FormatterInputTest = ({ type, label, index }: FormatterInputTestProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { answerInputs } = useAppSelector((state: RootState) => state.formatterDetail);

  // @ts-ignore
  useEffect(() => {
    dispatch(testUpdateAnswerInput({ index, answerType: type, label }));
  }, [answerInputs.length]);

  const onChange = (key: string, value: string): void => {
    dispatch(testUpdateAnswerInput({ index, answerType: type, label, value, key }));
  };

  const onChangeDate = (key: string, value: Date): void => {
    onChange(key, moment(value).format('YYYY-MM-DD'));
  };

  const onChangeTime = (key: string, value: string): void => {
    isValidTimeInput(key, value) ? onChange(key, value) : null;
  };

  type AnswerKey = 'hours' | 'minutes' | 'years' | 'months' | 'days' | 'value' | 'unit';

  const getValue = (key?: AnswerKey) => {
    if (answerInputs[index]) {
      const answer: AnswerJsonTypes = answerInputs[index].answer;

      if ([AnswerTypes.SingleChoice, AnswerTypes.MultiChoice].includes(type as AnswerTypes)) {
        return answer;
      }

      if (key) {
        if (key in answer) {
          // @ts-ignore
          return answer[key];
        }
      }

      // Add a type guard here
      if ('value' in answer) {
        return answer.value;
      }
    }
  };

  const getUnitForNumbers = (): string => {
    return type === AnswerTypes.NumberPercent ? '(%)' : type === AnswerTypes.NumberUnit ? '(unit)' : '';
  };

  switch (type) {
    case AnswerTypes.Text:
      return (
        <TextAreaField
          label={label}
          value={getValue()}
          onChange={onChange}
          fieldKey=""
          dataTest="run-formatter-text"
        />
      );

    case AnswerTypes.Number:
    case AnswerTypes.NumberPercent:
      return (
        <InputField
          label={label}
          value={getValue()}
          onChange={onChange}
          fieldKey=""
          type={InputFieldType.NUMBER}
          delimiter="COMMA"
          unit={getUnitForNumbers()}
          isDecimal={true}
          canBeNegative={true}
          dataTest="run-formatter-number"
        />
      );

    case AnswerTypes.NumberUnit:
      return (
        <Grid className="m-none">
          <Grid.Row className="p-none">
            <Grid.Column width={8}>
              <InputField
                label={label}
                value={getValue('value')}
                onChange={onChange}
                fieldKey="value"
                type={InputFieldType.NUMBER}
                delimiter="COMMA"
                isDecimal={true}
                canBeNegative={true}
                dataTest="run-formatter-number"
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <InputField
                label="."
                value={getValue('unit')}
                onChange={onChange}
                fieldKey="unit"
                type={InputFieldType.TEXT}
                unit="unit"
                dataTest="run-formatter-unit"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    case AnswerTypes.Date:
      const date = getValue();
      return (
        <DateField
          label={label}
          value={date ? date : ''}
          onChange={onChangeDate}
          fieldKey=""
          dataTest="run-formatter-date"
        />
      );
    case AnswerTypes.Time:
      return (
        <Grid className="m-none">
          <Grid.Row className="p-none">
            <InputWithUnit
              columnWidth={8}
              label={label}
              value={getValue('hours')}
              onChange={onChangeTime}
              unit="Hours"
              fieldKey="hours"
              dataTest="run-formatter-time-hours"
            />

            <InputWithUnit
              columnWidth={8}
              label={label ? '.' : ''}
              value={getValue('minutes')}
              onChange={onChangeTime}
              unit="Minutes"
              fieldKey="minutes"
              dataTest="run-formatter-time-minutes"
            />
          </Grid.Row>
        </Grid>
      );
    case AnswerTypes.Duration:
      return (
        <Grid className="m-none">
          <Grid.Row className="p-none">
            <InputWithUnit
              columnWidth={5}
              label={label}
              value={getValue('years')}
              onChange={onChange}
              unit="Years"
              fieldKey="years"
              dataTest="run-formatter-duration-years"
            />

            <InputWithUnit
              columnWidth={6}
              label={label ? '.' : ''}
              value={getValue('months')}
              onChange={onChange}
              unit="Months"
              fieldKey="months"
              dataTest="run-formatter-duration-months"
            />

            <InputWithUnit
              columnWidth={5}
              label={label ? '.' : ''}
              value={getValue('days')}
              onChange={onChange}
              unit="Days"
              fieldKey="days"
              dataTest="run-formatter-duration-days"
            />
          </Grid.Row>
        </Grid>
      );
    case AnswerTypes.Boolean:
      return (
        <SelectField
          label={label}
          dataTest="run-formatter-select-boolean"
          value={getValue()}
          placeholder="Select Yes or No"
          options={[
            {
              key: '0',
              value: true,
              text: 'Yes',
              dataTest: 'run-formatter-boolean-yes',
            },
            {
              key: '1',
              value: false,
              text: 'No',
              dataTest: 'run-formatter-boolean-no',
            },
          ]}
          onChange={onChange}
          fieldKey=""
        />
      );
    default:
      return (
        <FormatterMultiChoice
          input={getValue()}
          label={label}
          index={index}
          type={type}
          dataTest="run-formatter-multi-choice"
        />
      );
  }
};

export default FormatterInputTest;
