import Decimal from 'decimal.js';
import ExistingFitOutTreatment from 'common/model/ExistingFitOutTreatment';
import HandoverCondition from 'common/model/HandoverCondition';
import PermittedUse from 'common/model/PermittedUse';
import PremisesCharacteristics from 'common/model/PremisesCharacteristics';
import SpecificUse from 'common/model/SpecificUse';
import { CFloorView } from './FloorView';

export class CPremisesCharacteristics {
  efficiency: Decimal;
  existingFitOutTreatment: ExistingFitOutTreatment;
  handoverCondition: HandoverCondition;
  partFloorsCount: number;
  permittedUse: PermittedUse;
  specificUse: SpecificUse;
  views: CFloorView[];
  wholeFloorsCount: number;

  constructor(input: PremisesCharacteristics) {
    this.efficiency = new Decimal(input.efficiency);
    this.existingFitOutTreatment = input.existingFitOutTreatment;
    this.handoverCondition = input.handoverCondition;
    this.partFloorsCount = input.partFloorsCount;
    this.permittedUse = input.permittedUse;
    this.specificUse = input.specificUse;
    this.views = input.views.map(view => new CFloorView(view));
    this.wholeFloorsCount = input.wholeFloorsCount;
  }

  static fragment() {
    return `
        efficiency
        existingFitOutTreatment
        handoverCondition
        partFloorsCount
        permittedUse
        specificUse
        wholeFloorsCount
        views {
            ${CFloorView.fragment()}
        }
    `;
  }
}
