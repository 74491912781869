import { Dropdown } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './DropdownMenu.scss';

export interface DropdownItem {
  onClick: () => void;
  disabled?: boolean;
  label: string;
  key: string;
  href?: string;
  icon?: Icons;
  dataTest?: string;
}

export interface DropdownMenuProps {
  className?: string;
  dropdownItems?: DropdownItem[];
  disabled?: boolean;
  dataTest?: string;
  dropdownText?: string;
}

const DropdownMenu = ({
  className,
  dropdownItems = [],
  disabled,
  dataTest,
  dropdownText,
}: DropdownMenuProps): JSX.Element => {
  const onClick = (e: React.MouseEvent<HTMLDivElement>, createAction: () => void) => {
    e.preventDefault();
    createAction();
  };

  return (
    <Dropdown
      className={`dropdown-menu-atom ${className}`}
      placeholder={dropdownText}
      icon={
        <Icon
          icon={Icons.ArrowDown}
          width="16px"
          height="16px"
        />
      }
      disabled={disabled}
      direction="left"
      data-test={dataTest}
    >
      <Dropdown.Menu>
        {dropdownItems.map((item: DropdownItem) => (
          <Dropdown.Item
            key={item.key}
            onClick={e => onClick(e, item.onClick)}
            disabled={item.disabled}
            href={item.href}
            data-test={item.dataTest}
          >
            {item.icon && <Icon icon={item.icon} />}
            <span>{item.label}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
