import CheckboxField from 'atoms/FormField/Checkbox';
import PopUpInfo from 'atoms/PopUpInfo';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import './SpecialCheckBox.scss';

interface SpecialCheckBoxProps {
  label?: string | null;
  mode: MODE_OPTIONS;
  value?: boolean;
  onChange?: (key: string, value: any) => void;
  fieldKey: string;
  required?: boolean;
  info?: boolean;
  popUp?: boolean;
  infoText?: string;
  dataTest?: string;
}

const SpecialCheckBox = ({
  label,
  mode,
  value,
  onChange,
  fieldKey,
  required,
  info,
  popUp,
  infoText,
  dataTest,
}: SpecialCheckBoxProps) => {
  const disabled: boolean = mode === MODE_OPTIONS.READ;

  return (
    <div
      className={`special-checkbox border-sm-grayish-magenta-light ${disabled && 'disabled'}`}
      data-test={dataTest}
    >
      <span>
        {label}
        {required && <span className="required-indicator">*</span>}
        <PopUpInfo
          popUp={popUp}
          infoText={infoText}
          info={info}
        />
      </span>
      {/* @ts-ignore */}
      <CheckboxField
        value={value}
        onChange={onChange}
        fieldKey={fieldKey}
        toggle={true}
        disabled={disabled}
      />
    </div>
  );
};

export default SpecialCheckBox;
