import { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import ExportingContent from './ExportingContent';
import FailureContent from './FailureContent';
import SuccessContent from './SuccessContent';
import './ExportModal.scss';

export enum ExportTypes {
  XLSX = 'xlsx',
  PDF = 'pdf',
}
interface ExportModalProps {
  type: ExportTypes;
  onTryAgain: () => void;
  isExporting: boolean;
  exportSuccess: boolean;
  exportFail: boolean;
  trigger: JSX.Element;
}

const ExportModal = ({
  type,
  onTryAgain,
  isExporting,
  exportSuccess,
  exportFail,
  trigger,
}: ExportModalProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const showSuccessContent: boolean = !isExporting && exportSuccess;
  const showFailContent: boolean = !isExporting && exportFail;

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      className="export-modal"
      trigger={trigger}
      size="tiny"
    >
      {isExporting && <ExportingContent type={type} />}
      {showSuccessContent && <SuccessContent close={setOpen} />}
      {showFailContent && (
        <FailureContent
          close={setOpen}
          onTryAgain={onTryAgain}
        />
      )}
    </Modal>
  );
};

export default ExportModal;
