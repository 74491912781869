import Decimal from 'decimal.js';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const FirstRentPeriodForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const rentPeriods = activeContractEvent?.rentReview || [];
  const firstRentPeriod = rentPeriods.length > 0 ? rentPeriods[0] : null;

  const onChange = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['rentReview', '0', key],
        value: value || null,
      }),
    );
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['rentReview', '0', key],
        value: value ? new Decimal(value) : null,
      }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div>
      <div className="grid  m-t-s">
        <div className="col-2">
          <DateField
            label="Date initial rent first agreed"
            fieldKey="rentAgreementDate"
            dataTest=""
            value={firstRentPeriod?.rentAgreementDate || null}
            disabled={disabled}
            onChange={onChange}
          />
        </div>

        <div className="col-2">
          <InputField
            label="Vacancy rate when initial rent first agreed"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(%)"
            fieldKey="vacancyRate"
            value={firstRentPeriod?.vacancyRate?.toString() || null}
            disabled={disabled}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
      </div>

      <div className="grid m-t-s">
        <div className="col-2">
          <InputField
            label="Face rent (pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/mo)"
            fieldKey="faceRentPcm"
            value={firstRentPeriod?.faceRentPcm?.toString() || null}
            disabled={disabled}
            required={required}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <InputField
            label="Face rent (psf pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/sf/mo)"
            fieldKey="faceRentPsfPcm"
            value={firstRentPeriod?.faceRentPsfPcm?.toString() || null}
            disabled={true}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <InputField
            label="Effective rent (pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/mo)"
            fieldKey="effectiveRentPcm"
            value={firstRentPeriod?.effectiveRentPcm?.toString() || null}
            disabled={true}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <InputField
            label="Effective rent (psf pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/sf/mo)"
            fieldKey="effectiveRentPsfPcm"
            value={firstRentPeriod?.effectiveRentPsfPcm?.toString() || null}
            disabled={true}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
      </div>
    </div>
  );
};

export default FirstRentPeriodForm;
