import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import BreadCrumb, { BREAD_CRUMB_SIZE, BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { MODE_OPTIONS } from 'common/api/miscellaneous';

const DevelopmentOwnedBuildingToolbar = (): React.ReactElement => {
  return (
    <div className="d-flex justify-content-between w-100 py-4 border-top">
      <h2 className="color-blue-very-dark">Owned Buildings</h2>
      <Button
        className="btn grey-bg"
        data-test="save-button"
        onClick={() => {}}
      >
        ATTACH BUILDING TO THIS DEVELOPMENT
      </Button>
    </div>
  );
};

export default DevelopmentOwnedBuildingToolbar;
