import { OptionItem } from 'utils/types/categories';
import { BodyOptions } from '../../ParameterMultiLevelSelect/ParameterDropdown/BodyOptions';
import { GoBack } from '../../ParameterMultiLevelSelect/ParameterDropdown/GoBack';
import './ParameterDropdown.scss';

type ParameterDropdownProps = {
  allOptionMenu: OptionItem[][];
  showDropdown: boolean;
  dataTest: string;
  optionMenu: OptionItem[];
  lastOptionMenu: OptionItem[];
  onGoBack: () => void;
  onSelect: (option: OptionItem) => void;
};

/**
 * This Component is a float modal used by ParameterMultiLevelSelect
 *
 * Its show a list of Options and handle navigation
 *
 * @param props.onGoBack Function | Callback goback function
 * @param props.optionsSelect DropdownItemProps[] | Values already selected
 * @param props.dropdown DropdownItemProps[]  | Modal Options to be selected
 * @param props.showDropdown Function | Callback function to open or close modal
 * @param props.onSelect Function | Callback to update optionsSelect and dropdown values
 */
export const ParameterDropdown = ({
  allOptionMenu,
  optionMenu,
  lastOptionMenu,
  showDropdown,
  dataTest,
  onSelect,
  onGoBack,
}: ParameterDropdownProps) => {
  const styleList = [];

  styleList.push('parameter-multi-level-select-dropdown border-sm-grayish-magenta-light bg-white');

  if (showDropdown) {
    styleList.push('d-flex');
  }
  return (
    <div
      className={styleList.join(' ')}
      data-test={dataTest}
    >
      <ul>
        <GoBack
          optionMenu={optionMenu}
          lastOptionMenu={lastOptionMenu}
          onGoBack={onGoBack}
          allOptionMenu={allOptionMenu}
        />
        <BodyOptions
          optionMenu={optionMenu}
          lastOptionMenu={lastOptionMenu}
          onSelect={onSelect}
        />
      </ul>
    </div>
  );
};
