import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { SHARE_RIGHT_RESTRICTION_OPTIONS } from 'utils/UI';

const ShareRightsForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const shareRights = activeContractEvent?.shareRights;

  const onChange = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['shareRights', key],
        value: value || null,
      }),
    );
  };

  return (
    <div className="grid m-t-s">
      <div className="col-2">
        <SelectField
          label="Sharing restricted to"
          fieldKey="restrictedTo"
          dataTest=""
          value={shareRights?.restrictedTo || false}
          disabled={mode === MODE_OPTIONS.READ}
          options={SHARE_RIGHT_RESTRICTION_OPTIONS}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
export default ShareRightsForm;
