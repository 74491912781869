import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface GetPropertyQueryVariables {
  currentLandlordEntityId: string;
  propertyId: string;
}

const GET_PROPERTY = (propertyId: string) => {
  if (!propertyId) {
    throw new Error('Get Property: Property ID could not be determined. Query aborted.');
  }

  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('Get Property: currentLandlordEntityId is required');
  }

  const variables: GetPropertyQueryVariables = {
    currentLandlordEntityId,
    propertyId,
  };

  return {
    query: gql`
      query ($propertyId: ID!, $currentLandlordEntityId: ID!) {
        getProperty(propertyId: $propertyId, currentLandlordEntityId: $currentLandlordEntityId) {
          address
          floors {
            id
            name
            spaces {
              grossArea
              id
              name
              type
              activationDate
              deactivationDate
            }
          }
        }
      }
    `,
    variables,
  };
};

interface GetPropertyProps {
  propertyId: string;
}

/**
 * Get Units detail for Floors
 */
export const getProperty = createAsyncThunk('stackingPlan/getProperty', async ({ id }: { id: string }) => {
  const response = await getClientType().query(GET_PROPERTY(id));
  return response.data;
});
