import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface GetParameterGroupPDFQueryVariables {
  groupId: string;
}

const GET_PARAMETER_GROUP_PDF = (id: string) => {
  if (!id) throw new Error('GET_PARAMETER_GROUP_PDF: id is required');

  const variables: GetParameterGroupPDFQueryVariables = { groupId: id };

  return {
    query: gql`
      query ($groupId: ID!) {
        getParameterGroupPdfDocument(groupId: $groupId)
      }
    `,
    variables,
  };
};

export const getParameterGroupPdf = createAsyncThunk(
  'parameters/getParameterGroupPdfDocument',
  async ({ id }: { id: string }) => {
    const response = await adminClient.query(GET_PARAMETER_GROUP_PDF(id));
    return response;
  },
);
