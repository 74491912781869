import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface PrepContractDocsForUploadProps {
  files: any;
  id?: string | null;
}

export const PREP_CONTRACTS_DOCS_FOR_UPLOAD = ({ files, id }: PrepContractDocsForUploadProps) => {
  if (files.length === 0) {
    throw new Error('Prep Contract Document: There are no files to upload. Query aborted.');
  }

  let prepFilesQuery = '';

  for (let i = 0; i < files.length; i++) {
    const { name, type } = files[i].file;

    prepFilesQuery += `
      prepContractDoc${files[i].id}: prepContractDocument(contractId: "${id}", fileName: "${name}", mimeType: "${type}"){
        cdnUrl
        uploadUrl
        path
      }
    `;
  }

  return {
    query: gql`
      query {
        ${prepFilesQuery}
      }
    `,
  };
};

export const prepContractDocsForUpload = createAsyncThunk(
  'contracts/prepContractDocsForUpload',
  async ({ files }: { files: any[] }, { getState }) => {
    const {
      contractDetailV2: { activeContract },
    } = getState() as RootState;

    const response = await client.query(PREP_CONTRACTS_DOCS_FOR_UPLOAD({ files, id: activeContract?.id }));
    return response;
  },
);
