import { ParameterCategoryBase, ParameterGroupClone } from 'common/_classes';
import { ReactNode } from 'react';
import { Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardViewTemplate, { CardTemplateConfiguration } from 'components/CardViewTemplate';
import { updateParamViewSelectedGroup } from 'store/parameters/parameterDetailSlice';
import { setActiveCategory, setActiveGroup } from 'store/parameters/parametersListingSlice';
import { updateSelectedGroup } from 'store/parameters/parametersListingSlice';
import { MODAL_MODE_OPTIONS, PARAMETERS_CLASSIFICATION_OPTIONS } from 'common/api/miscellaneous';
import {
  deleteParameterCategory,
  deleteParameterGroup,
  getParameterGroupHtml,
  getParameterGroupPdf,
} from 'common/api/parameters';
import { checkNotEmpty } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';
import { SetModalStatesProps } from '../index';
import './ParametersCardsView.scss';

interface ParametersCardsViewProps {
  children?: ReactNode;
  onSetModalStates: ({ type, mode }: SetModalStatesProps) => void;
}
/**
 * Parameter to render the CardColumns
 * @param setUpdateModal Function | Open modal to edit or create a category
 */
const ParametersCardsView = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { filteredGroupsList, filteredCategoriesList, search } = useAppSelector(
    (state: RootState) => state.parametersListing,
  );

  const onUpdateActiveGroup = (group: ParameterGroupClone): void => {
    dispatch(setActiveGroup({ group }));
    // onSetModalStates({
    //   type: PARAMETERS_CLASSIFICATION_OPTIONS.GROUP,
    //   mode: MODAL_MODE_OPTIONS.UPDATE,
    // });
  };

  const onUpdateActiveCategory = (category: ParameterCategoryBase): void => {
    dispatch(setActiveCategory({ category }));
    // onSetModalStates({
    //   type: PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY,
    //   mode: MODAL_MODE_OPTIONS.UPDATE,
    // });
  };

  const cardMenuItems = (group: ParameterGroupClone): DropdownMenuItem[] => {
    const checkDelete: boolean = group.parameterCount + group.tableCount !== 0;

    return [
      {
        key: '1',
        label: 'View Details',
        icon: Icons.EyeOpen,
        onClick: () => onUpdateActiveGroup(group),
      },
      {
        key: '2',
        label: 'View HTML',
        icon: Icons.Html,
        // @ts-ignore
        onClick: () => dispatch(getParameterGroupHtml({ id: group?.id })),
      },
      {
        key: '3',
        label: 'View PDF',
        icon: Icons.Pdf,
        // @ts-ignore
        onClick: () => dispatch(getParameterGroupPdf({ id: group?.id })),
      },
      {
        key: '4',
        label: 'Delete',
        icon: Icons.Trash,
        // @ts-ignore
        onClick: () => dispatch(deleteParameterGroup({ id: group?.id })),
        disabled: checkDelete,
      },
    ];
  };

  const categoryList: ParameterCategoryBase[] = filteredCategoriesList;

  const cardBodyTemplate = (group: ParameterGroupClone): JSX.Element => {
    return (
      <>
        <div>
          Tables: <b>{group?.tableCount}</b>
        </div>
        <div>
          Parameters: <b>{group?.parameterCount}</b>
        </div>
      </>
    );
  };

  const hideCategoryDelete = (category: ParameterCategoryBase): boolean => {
    for (let i = 0; i < filteredGroupsList.length; i++) {
      const cardElement = filteredGroupsList[i] as ParameterGroupClone;
      if (cardElement.category.id === category.id) {
        return true;
      }
    }
    return false;
  };

  const cardHeaderTemplate = (group: ParameterGroupClone): JSX.Element => (
    <Popup
      className="popup-info"
      content={group?.name}
      trigger={<span className="name">{group?.name}</span>}
    />
  );

  const cardConfigs: CardTemplateConfiguration<ParameterCategoryBase, ParameterGroupClone> = {
    columnConfiguration: {
      header: {
        titleField: 'name',
        onEdit: category => onUpdateActiveCategory(category),
        // @ts-ignore
        onDelete: id => dispatch(deleteParameterCategory({ id })),
        showDelete: category => !hideCategoryDelete(category),
      },
      width: '15rem',
    },
    cardConfiguration: {
      header: {
        headerTemplate: (group: ParameterGroupClone) => cardHeaderTemplate(group),
        cardMenuItems: (group: ParameterGroupClone) => cardMenuItems(group),
      },
      bodyTemplate: (group: ParameterGroupClone) => cardBodyTemplate(group),
      onClick: group => {
        dispatch(updateSelectedGroup({ group })), dispatch(updateParamViewSelectedGroup({ group }));
      },
    },
  };

  const emptyCategoryTemplate: JSX.Element = (
    <div
      className="empty-category-card border-sm-grayish-magenta-light color-blue-very-dark-grayish"
      // onClick={
      //   ()=> onSetModalStates({
      //     type: PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY,
      //     mode: MODAL_MODE_OPTIONS.CREATE,
      //   })
      // }
    >
      <p className="m-t-xl">
        {!checkNotEmpty(search)
          ? `You don't have any categories yet. First create a category and add groups there.`
          : `No categories or groups found. You can create a category with the following name.`}
      </p>
      <p className="m-t-ml font-600">
        <span className="circle-m plus-icon plus-position d-inline-flex m-r-xs">
          <Icon icon={Icons.Add} />
        </span>
        Create a category
      </p>
    </div>
  );

  const filterElementsPerCategory = (category: ParameterCategoryBase): ParameterGroupClone[] => {
    return filteredGroupsList.filter((parameterGroup: ParameterGroupClone) => {
      const categoryId = parameterGroup.category?.id;
      const columnId = category?.id;
      return categoryId === columnId;
    });
  };

  return (
    <div className="card-view-container">
      {categoryList.length === 0 ? (
        emptyCategoryTemplate
      ) : (
        <CardViewTemplate<ParameterCategoryBase, ParameterGroupClone>
          getCardElementsList={category => filterElementsPerCategory(category)}
          categoryList={categoryList}
          configuration={cardConfigs}
        />
      )}
    </div>
  );
};

export default ParametersCardsView;
