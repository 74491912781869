import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import 'views/provisions/CreatorViewer/Tabs/GeneralTab/ProvisionGeneralForm/ProvisionGeneralForm.scss';
import { createNewEntity } from 'store/entities/entityDetailSlice';
import { updateActiveTransaction } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { getAutomatedTransactionIdentifier, listPremises } from 'common/api/transactions';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import './PartiesForm.scss';

const PartiesForm = ({ mode }: ModeProps) => {
  const {
    activeTransaction: { ownerId, tenantId, identifier, premises },
    landlordEntitiesOptions,
    tenantEntitiesOptions,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const landlordFieldKey = 'ownerId';
  const propertyId = premises.length !== 0 ? premises[0].property?.id : null;

  const onChange = (key: string, value: any) => {
    if (key === landlordFieldKey) {
      dispatch(listPremises({ ownerId: value }));
    }
    if (key === 'tenantId' && propertyId !== undefined) {
      dispatch(
        getAutomatedTransactionIdentifier({
          tenantId: value,
          propertyId: propertyId,
        }),
      ).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(updateActiveTransaction({ key, value }));
        }
      });
    } else {
      dispatch(updateActiveTransaction({ key, value }));
    }
  };

  const createNewTenant = (): void => {
    dispatch(createNewEntity(null));
    navigate(`/tenant-entities/create?from=transactions&step=1`);
  };

  useEffect(() => {
    // Auto select current landlord
    onChange(landlordFieldKey, getCurrentLandlordEntityId());
  }, []);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={7}>
          <SelectField
            label="Landlord"
            fieldKey="ownerId"
            dataTest="transaction-landlord"
            value={ownerId}
            disabled={true}
            required={mode === MODE_OPTIONS.EDIT}
            options={landlordEntitiesOptions}
            onChange={() => {}}
          />
        </Grid.Column>
        <Grid.Column width={7}>
          <InputField
            label="Negotiation Id"
            dataTest="transaction-negotiation-id"
            value={identifier}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="identifier"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={7}>
          <SelectField
            label="Tenant"
            fieldKey="tenantId"
            dataTest="transaction-tenant"
            value={tenantId}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            options={tenantEntitiesOptions}
            onChange={onChange}
            search={true}
            runValidation={!checkNotEmpty(tenantId)}
          />
          <p className="m-b-s m-t-l">Click on “Add New Tenant” to create a new one</p>
          <Button
            className="create-tenant color-white bg-blue-very-dark-grayish"
            data-test="add-new-tenant-button"
            onClick={createNewTenant}
          >
            ADD NEW TENANT
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PartiesForm;
