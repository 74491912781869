import CheckboxField from 'atoms/FormField/Checkbox';

interface ContractAvailableStatusItemProps {
  label: string;
  className: string;
  onChange: Function;
  value: string;
  allFilters: string[];
  showCheckbox?: boolean;
}

const ContractAvailableStatusItem = ({
  label,
  className,
  onChange,
  value,
  allFilters,
  showCheckbox,
}: ContractAvailableStatusItemProps) => {
  const isChecked = allFilters.includes(value);

  return (
    <div
      className="status-filter-item"
      data-test={'stacking-plan-availability-filter ' + label}
      onClick={() => onChange({ isChecked: !isChecked, value })}
    >
      {showCheckbox && (
        <CheckboxField
          fieldKey=""
          value={isChecked}
          className="d-flex"
        />
      )}
      <div className={'circle-sm m-r-s m-l-s ' + className}></div>
      <div className="content">{label}</div>
    </div>
  );
};

export default ContractAvailableStatusItem;
