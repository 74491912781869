/*
 * Definition of the column (names, format...)
 */
import moment from 'moment';
import { entries, groupBy } from 'lodash';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementDate,
  filterElementStatusTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import Tooltip from 'atoms/Tooltip';
import MemberStatus from 'common/model/MemberStatus';
import User from 'common/model/User';
import { conditionalClassName } from 'utils/tsHelper';
import { Member } from 'utils/types/userManagement/users';
import { getPhoneNumber } from 'utils/utils-string';

export class UserRow {
  id?: string;
  photoUrl: string | null;
  name: string | null;
  email: string | null;
  phone: string | null;
  members: Member[];
  userType: string;
  insertedAt: Date;
  status: string;

  constructor(user: User) {
    this.id = user.id;
    this.photoUrl = user.photoUrl;
    this.name = user.firstName + ' ' + user.lastName;
    this.email = user.email;
    this.phone = getPhoneNumber(user.countryCode, user.phoneNumber);
    this.members = user.members;
    this.userType = '---';
    this.insertedAt = new Date(user.insertedAt);
    this.status = '---';
  }
}

const filterElementPhotoTemplate = (photoUrl: string | null) => {
  return (
    <Avatar
      src={photoUrl}
      size={AvatarSize.Medium}
    />
  );
};

const statuses: StatusBadgeArgument[] = [
  { value: MemberStatus.Valid, color: BadgeColor.GREEN },
  { value: MemberStatus.Refused, color: BadgeColor.RED },
  { value: MemberStatus.Revoked, color: BadgeColor.RED },
  { value: MemberStatus.Invited, color: BadgeColor.ORANGE },
];

const filterElementOrganisationTemplate = (members: Member[]) => {
  if (members.length) {
    const groupedMembers = entries(groupBy(members, 'status'));

    return groupedMembers.map(([status, members], index) => (
      <Tooltip
        key={`${status}-${index}`}
        trigger={
          <div className={`d-flex ${conditionalClassName(index !== 0, 'm-t-sm')}`}>
            {filterElementStatusTemplate(status, statuses)}
            <div className="d-flex m-l-xs align-center">{members.map(item => item.entity.name).join(', ')}</div>
          </div>
        }
        HTMLContent={
          <ul>
            {members.map((member, index) => (
              <li key={index}>
                {member.entity.name} {` (${member.role.name})`}
              </li>
            ))}
          </ul>
        }
      />
    ));
  } else {
    return '------';
  }
};

const filterElementOrganisationFunction = (members: Member[], filter: string): boolean => {
  if (!filter || !filter.trim()) {
    return true;
  }
  if (!members.length) {
    return false;
  }
  const filteredMembers = members.filter(member => {
    const name = member.entity.name;
    return name ? name.trim().toLowerCase().includes(filter.toLowerCase()) : false;
  });
  return filteredMembers.length > 0;
};

export const adminUsersColumnConfig = (): CustomColumnProps[] => {
  return [
    {
      field: 'photoUrl',
      header: 'Photo',
      sortable: false,
      filter: false,
      body: (row: UserRow) => filterElementPhotoTemplate(row.photoUrl),
    },
    {
      field: 'name',
      header: 'Name of employee',
      filter: true,
      filterPlaceholder: 'Search by Name of employee',
      filterField: 'name',
      sortable: true,
    },
    {
      field: 'email',
      header: 'Email Address',
      filter: true,
      filterPlaceholder: 'Search by Email Address',
      filterField: 'email',
      sortable: true,
    },
    {
      field: 'phone',
      header: 'Phone number',
      filterPlaceholder: 'Search by Phone number',
      filter: true,
      filterField: 'phone',
      sortable: true,
    },
    {
      field: 'members',
      header: 'Organisations',
      filter: true,
      filterPlaceholder: 'Search by Organisations',
      filterField: 'members',
      sortable: false,
      body: (row: UserRow) => filterElementOrganisationTemplate(row.members),
      filterMatchMode: 'custom',
      filterMatchModeOptions: [{ label: 'Contains', value: 'custom' }],
      filterFunction: (value: Member[], filter: string) => filterElementOrganisationFunction(value, filter),
    },
    {
      field: 'userType',
      header: 'User type',
      filterPlaceholder: 'Search by User type',
      filter: false,
      sortable: false,
    },
    {
      field: 'insertedAt',
      header: 'Created',
      filter: true,
      filterPlaceholder: 'Search by Created',
      filterField: 'insertedAt',
      sortable: true,
      body: (row: UserRow) => moment(row.insertedAt).format('DD/MM/YYYY'),
      dataType: 'date',
      filterElement: filterElementDate,
    },
    {
      field: 'status',
      header: 'Status',
      filter: false,
      sortable: false,
    },
    {
      mandatory: false,
      isHide: true,
      field: 'options',
      header: 'Actions',
    },
  ];
};
