import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { BenchmarkInput } from 'common/api/benchmark';
import { createBenchmark, getBenchmark, updateBenchmark } from 'common/api/benchmark';
import { getNewReviewer } from 'utils/tsHelper';

const newBenchmark: BenchmarkInput = {
  id: null,
  identifier: null,
  address: null,
  description: null,
  size: null,
  endDate: null,
  startDate: null,
  name: null,
  industry: undefined,
  industryId: null,
  rentPerArea: null,
  rentFreePeriod: null,
  securityAmount: null,
  redevelopmentFlag: false,
  assignmentFlag: false,
  refusalFlag: false,
  renewFlag: false,
  subletFlag: false,
  surrenderFlag: false,
  expansionFlag: false,
  hasCentralAc: false,
  hasCurtainWall: false,
  hasMetroCoveredAccess: false,
  reviewFlag: false,
  reviewStatus: {
    date: null,
    user: getNewReviewer(),
  },
};

const initialState = {
  activeBenchmark: newBenchmark,
  isLoading: false,
  runBenchmarkValidation: false,
};

const benchmarkDetailSlice = createSlice({
  name: 'benchmarkDetail',
  initialState,
  reducers: {
    createNewBenchmark: state => {
      state.activeBenchmark = newBenchmark;
    },
    updateBenchmarkInput: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeBenchmark[key] = value;
    },
    updateBenchmarkValidation: (state, action) => {
      state.runBenchmarkValidation = action.payload;
    },
    updateBenchmarkReviewedStatus: (state, action) => {
      const { reviewStatus, reviewFlag } = action.payload;
      state.activeBenchmark.reviewStatus = reviewStatus;
      state.activeBenchmark.reviewFlag = reviewFlag;
    },
  },
  extraReducers: builder => {
    //getBenchmark
    builder.addCase(getBenchmark.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getBenchmark.fulfilled, (state, action) => {
      const data = action.payload.data.getBenchmark;
      state.activeBenchmark = data;
      state.isLoading = false;
    });
    builder.addCase(getBenchmark.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getBenchmark API request rejected');
    });

    //Create Benchmark
    builder.addCase(createBenchmark.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createBenchmark.fulfilled, state => {
      toast.success(`Benchmark created Successfully`);
      state.isLoading = false;
    });
    builder.addCase(createBenchmark.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error(`Some error occurred when trying to add Benchmark`);
    });

    //Update Benchmark
    builder.addCase(updateBenchmark.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateBenchmark.fulfilled, (state, action) => {
      state.activeBenchmark = action.payload.data.updateBenchmark;
      state.isLoading = false;
      toast.success('Benchmark updated Successfully');
    });
    builder.addCase(updateBenchmark.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('Some error occurred when trying to update Benchmark');
    });
  },
});

export const { createNewBenchmark, updateBenchmarkInput, updateBenchmarkValidation, updateBenchmarkReviewedStatus } =
  benchmarkDetailSlice.actions;

export default benchmarkDetailSlice.reducer;
