import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/policies/policiesListingSlice';
import { listPolicies } from 'common/api/policies';

const PoliciesHeaderToolbar = () => {
  const { search } = useAppSelector((state: RootState) => state.policiesListing);

  const onGetData = (value?: string) => {
    // @ts-ignore
    return listPolicies({ searchValue: value, first: 1000 });
  };

  return (
    <SearchFilter
      search={search}
      onUpdateSearch={updateSearch}
      onGetData={onGetData}
    />
  );
};

export default PoliciesHeaderToolbar;
