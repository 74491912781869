import moment from 'moment';
import { useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setActiveContractEvent } from 'store/contractsV2/contractDetailSliceV2';
import UploadImgLeft from 'assets/images/svg/upload-image-left-small.svg';

type ContractEventHistoryItem = {
  id?: string;
  insertedAt: Date;
  index: number;
  setActiveItem: (id: string) => void;
};
const HistoryItem = ({ id, insertedAt, index }: ContractEventHistoryItem) => {
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }) => contractDetailV2);

  const dispatch = useAppDispatch();
  return (
    <div
      className={`contract-item ${activeContractEvent?.id === id && 'active'}`}
      onClick={() => dispatch(setActiveContractEvent(id))}
    >
      <Image
        className="img"
        src={UploadImgLeft}
      />
      <div className="content">
        <div className="font-600 font-size-1_1429 color-blue-very-dark-grayish">v{index + 1}</div>
        <div>{insertedAt && moment(insertedAt).format('YYYY-MM-DD')}</div>
        <div className="font-600 font-size-0_8571 color-blue-very-dark-grayish">
          {insertedAt && moment(insertedAt).format('HH:mm A')}
        </div>
      </div>
    </div>
  );
};

export default HistoryItem;
