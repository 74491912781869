import { ParameterListingClone, ParameterPickIndex } from 'common/_classes';
import { DataTableRowReorderEvent } from 'primereact/datatable';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import { updateParametersList } from 'store/parameters/parametersListingSlice';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { deleteParameter, updateParametersIndex } from 'common/api/parameters';
import { listParameters } from 'common/api/parameters';
import { deepClone } from 'utils/tsHelper';
import { ParametersListRow, parametersColumnConfig } from './ParameterListTableConfig';
import './ParametersListing.scss';

const ParametersListing = ({ setParamId }: { setParamId: (id: string) => void }): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, parametersList } = useAppSelector((state: RootState) => state.parametersListing);
  const [allowDragDrop, updateAllowDragDrop] = useState<boolean>(false);

  const getParamId = (id: string): void => {
    setParamId(id);
  };
  const defaultColumnConfigs: CustomColumnProps[] = parametersColumnConfig(getParamId);

  const [columnConfigs, updateColumnConfigs] = useState<CustomColumnProps[]>(defaultColumnConfigs);

  const sortParametersByIndex = (): ParameterListingClone[] => {
    return deepClone(parametersList).sort((a: ParameterListingClone, b: ParameterListingClone) => {
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    });
  };

  const currentList: ParametersListRow[] = sortParametersByIndex().map(
    (parameter: ParameterListingClone) => new ParametersListRow(parameter),
  );

  const onFetchParameters = (params: PaginationAPIProps): void => {
    // @ts-ignore
    dispatch(listParameters({ ...params }));
  };

  const onDelete = (id: string): void => {
    // @ts-ignore
    dispatch(deleteParameter({ id: id || '' }));
  };

  const onToggleDragAndDrop = (): void => {
    if (!allowDragDrop) {
      const configs: CustomColumnProps[] = defaultColumnConfigs.map(column => {
        return { ...column, sortable: false };
      });

      // Add reorder icon column for drag and drop
      configs.unshift({
        field: 'reorder-button',
        rowReorder: true,
        width: '3rem',
      });

      // Update column configs
      updateColumnConfigs(configs);
    } else {
      // Update column configs to default
      updateColumnConfigs(defaultColumnConfigs);
    }
    updateAllowDragDrop(!allowDragDrop);
  };

  /**
   * Handle drag and drop functionality
   * @param {DataTableRowReorderEvent<ParameterListingClone[]>} event from table
   */
  const onReorder = (event: DataTableRowReorderEvent<ParameterListingClone[]>): void => {
    const list: ParameterListingClone[] = sortParametersByIndex();
    const draggedItem = list[event.dragIndex];
    // removed the dragged item
    list.splice(event.dragIndex, 1);

    // Added the item at drop location
    list.splice(event.dropIndex, 0, draggedItem);

    // Update index for list
    list.forEach((parameter: ParameterListingClone, index: number) => {
      // @ts-ignore
      parameter.index = index + 1;
    });
    // Updates the index using api
    dispatch(
      // @ts-ignore
      updateParametersIndex({
        parametersList: list.map((parameter: ParameterListingClone) => {
          const result: ParameterPickIndex = {
            id: parameter.id,
            index: parameter.index,
          };
          return result;
        }),
      }),
    );

    // Updates the index in store
    dispatch(updateParametersList({ parametersList: list }));
  };

  return (
    <div className="m-t-xl parameters-listing">
      <TableReactPrime
        fetchContent={({ ...rest }) => {
          onFetchParameters(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={columnConfigs}
        showReorderingToggler={true}
        allowDragDrop={allowDragDrop}
        onToggleDragAndDrop={onToggleDragAndDrop}
        onRowReorder={event => onReorder(event)}
        defaultSortField="index"
        defaultSortOrder={1}
        dataTest="dashboard-table"
        onRowClick={getParamId}
      />
    </div>
  );
};

export default ParametersListing;
