import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import { Editor } from 'tinymce';
import { v4 } from 'uuid';
import NodeType from 'common/model/NodeType';
import { CREATE_NODE } from './createNode';
import { ContentNodeType } from './types';

export const createParameterNode = createAsyncThunk(
  'nodes/createParameterNode',
  async (args: { editor: Editor }, { getState }) => {
    const { editor } = args;
    const state = getState() as RootState;
    const { activeProvision } = state.provisionDetail;
    const content = editor.selection.getContent();

    const bodyRequest = {
      level: 1,
      id: v4(),
      type: NodeType.Parameter,
      name: content,
      provisionId: activeProvision.id,
      formatterId: '',
    };

    const responseCreate = await adminClient.mutate(CREATE_NODE(bodyRequest));
    const { id } = responseCreate.data.createNode;
    const node = editor.dom.create('code', {}, content);

    node.classList.add('parameter-wrapper');
    node.setAttribute('data-node-id', id || '');
    node.setAttribute('data-node-type', ContentNodeType.PARAMETER);
    // Determines content inside the circle
    node.setAttribute('data-content', '#');

    editor.selection.setNode(node);
    return responseCreate;
  },
);
