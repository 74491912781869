import { useEffect } from 'react';
import { Button, Progress } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { updatePercentageModalStatus } from 'store/transactions/transactionDetailSlice';
import './PercentageModal.scss';

// NOT BEING USED AT THE MOMENT
const PercentageModal = ({ modalText, modalFunc }: { modalText: string; modalFunc?: () => void }) => {
  const { percentageModalStatus } = useAppSelector((state: RootState) => state.transactionDetail);
  const dispatch = useAppDispatch();

  /* MIGHT BE USED IN THE FUTURE
  useEffect(() => {
    if (percentageModalStatus && uploadPrep.uploadUrl !== null && modalFunc) {
      modalFunc();
    }
  }, [percentageModalStatus, uploadPrep]);
  */

  return (
    <ModalTemplate
      showHeader={false}
      isOpen={percentageModalStatus}
      onToggleModalStatus={isOpen => dispatch(updatePercentageModalStatus(isOpen))}
      className="progress-modal"
    >
      <h4 className="title">{modalText}</h4>

      <Progress
        percent={100}
        active={true}
        className="small"
      />
      <Button
        className="doc-cancel-btn border-ml-blue-very-dark"
        onClick={() => dispatch(updatePercentageModalStatus(false))}
      >
        CANCEL
      </Button>
    </ModalTemplate>
  );
};

export default PercentageModal;
