import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

export const GET_PROFILE = () => {
  return {
    query: gql`
      query {
        getProfile {
          email
          firstName
          id
          lastName
          countryCode
          phoneNumber
          photoUrl
          defaultEntity {
            id
          }
          members {
            id
            role {
              id
              name
            }
            entity {
              id
              name
            }
            user {
              email
              firstName
              id
              lastName
              countryCode
              phoneNumber
              photoUrl
            }
            status
          }
        }
      }
    `,
  };
};

export const getProfile = createAsyncThunk('auth/getProfile', async () => {
  const response = await client.query(GET_PROFILE());
  return response;
});
