enum SpecificUse {
  None = 'NONE',
  MedicalServices = 'MEDICAL_SERVICES',
  LawFirm = 'LAW_FIRM',
  BarristerChambers = 'BARRISTER_CHAMBERS',
  Consulate = 'CONSULATE',
  EstateAgency = 'ESTATE_AGENCY',
  WatchesAndJewelry = 'WATCHES_AND_JEWELRY',
  Fashion = 'FASHION',
  Cosmetics = 'COSMETICS',
  Supermarket = 'SUPERMARKET',
  BureauDeChange = 'BUREAU_DE_CHANGE',
  TravelAgency = 'TRAVEL_AGENCY',
  Bank = 'BANK'
}
export default SpecificUse;
