import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import { Answer, AnswerProps } from 'common/api/policies';
import { getJSON } from 'utils/utils-answer';

interface UpdateTransactionApprovalAnswerMutationVariables {
  transactionApprovalAnswerId: string;
  params: AnswerProps;
}

export const UPDATE_TRANSACTION_APPROVAL_ANSWER = (transactionApprovalAnswer: TransactionApprovalAnswer) => {
  if (!transactionApprovalAnswer.id)
    throw new Error('Update Transaction Approval Answer: Transaction Approval Answer ID is missing');

  const stringifiedAnswer = JSON.stringify(getJSON(transactionApprovalAnswer.answer as string));

  const variables: UpdateTransactionApprovalAnswerMutationVariables = {
    transactionApprovalAnswerId: transactionApprovalAnswer.id,
    params: {
      answer: stringifiedAnswer as Answer,
      answerType: transactionApprovalAnswer.answerType,
      approvalState: transactionApprovalAnswer.approvalState,
      changedValue: transactionApprovalAnswer.changedValue,
      dateOfAnswer: transactionApprovalAnswer.dateOfAnswer,
      fromInventory: transactionApprovalAnswer.fromInventory,
      fromPolicy: transactionApprovalAnswer.fromPolicy,
      note: transactionApprovalAnswer.note,
      paramRef: transactionApprovalAnswer.paramRef,
      transactionId: transactionApprovalAnswer?.transaction?.id,
      transactionDiscussionApprovalId: transactionApprovalAnswer?.transactionDiscussionApproval?.id,
      transactionMessageId: transactionApprovalAnswer?.transactionMessage?.id,
      transactionParameterApprovalId: transactionApprovalAnswer?.transactionParameterApproval?.id,
      transactionProvisionApprovalId: transactionApprovalAnswer?.transactionProvisionApproval?.id,
    },
  };

  return {
    mutation: gql`
      mutation ($transactionApprovalAnswerId: ID!, $params: TransactionApprovalAnswerInput!) {
        updateTransactionApprovalAnswer(transactionApprovalAnswerId: $transactionApprovalAnswerId, params: $params) {
          id
          answer
          answerType
          dateOfAnswer
          fromPolicy
          fromInventory
          paramRef {
            parameterId
            tableId
            index
          }
          user {
            id
            firstName
            lastName
          }
          transaction {
            id
          }
          approvalState
          note
          changedValue
          transactionMessage {
            id
            transactionChannel {
              id
              title
            }
          }
          transactionParameterApproval {
            id
            dateSubmitted
          }
          transactionDiscussionApproval {
            id
            dateSubmitted
            draftMessage
          }
          transactionProvisionApproval {
            id
            dateSubmitted
          }
        }
      }
    `,
    variables: variables,
  };
};

export const updateTransactionApprovalAnswer = createAsyncThunk(
  'transactions/updateTransactionApprovalAnswer',
  async ({ answerId }: { answerId: string }, { getState }) => {
    const {
      transactionDetail: { transactionApprovalAnswers },
    } = getState() as RootState;
    const transactionApprovalAnswer = transactionApprovalAnswers.find(
      (transactionApprovalAnswer: TransactionApprovalAnswer) => transactionApprovalAnswer.id === answerId,
    );

    if (transactionApprovalAnswer) {
      const response = await client.mutate(UPDATE_TRANSACTION_APPROVAL_ANSWER(transactionApprovalAnswer));
      return response;
    }
  },
);
