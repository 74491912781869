import moment from 'moment';

export const downloadContextInPDF = (
  contextInbase64: string,
  context: string | undefined,
  contextName: string | undefined | null,
): void => {
  const formattedDate = moment().format('DD-MM-YYYY');

  const updatedContext = context?.toLowerCase();
  const updatedContextName = contextName?.toLowerCase();

  const contextNameTextIncludesContext =
    updatedContextName && updatedContext && updatedContextName.includes(updatedContext);

  const fileName = `${updatedContextName ? updatedContextName + '-' : ''}${
    contextNameTextIncludesContext ? '' : updatedContext + '-'
  }(${formattedDate})`;
  downloadPdf(contextInbase64, fileName);
};

export const downloadContextInDocx = (
  contextInbase64: string,
  context: string | undefined,
  contextName: string | undefined | null,
): void => {
  const formattedDate = moment().format('DD-MM-YYYY');

  const updatedContext = context?.toLowerCase();
  const updatedContextName = contextName?.toLowerCase();

  const contextNameTextIncludesContext =
    updatedContextName && updatedContext && updatedContextName.includes(updatedContext);

  const fileName = `${updatedContextName ? updatedContextName + '-' : ''}${
    contextNameTextIncludesContext ? '' : updatedContext + '-'
  }(${formattedDate})`;
  downloadDocx(contextInbase64, fileName);
};

export const downloadPdf = (base64String: string, filename: string): void => {
  const linkSource = `data:application/pdf;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = `${filename}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadDocx = (base64String: string, filename: string): void => {
  const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = `${filename}.docx`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
