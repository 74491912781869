import { useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import PopUpInfo from 'atoms/PopUpInfo';
import ModalTemplate from 'components/ModalTemplate';
import { discardMemberCreation } from 'store/members/membersSlice';
import { createMember, updateMember } from 'common/api/members';
import { ContentProps, ModalProps } from 'utils/types/userManagement/users';
import { createTeamMemberValidation } from '../../userValidator';
import TeamMemberModalForm from './TeamMemberModalForm';

const Content = ({ setOpen, createMode, closeDropDown }: ContentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTeamMember } = useAppSelector((state: RootState) => state.members);

  const onSave = (): void => {
    if (createMode) {
      dispatch(createMember());
    } else {
      dispatch(updateMember());
    }
    if (setOpen) setOpen(false);
    setTimeout(() => {
      if (closeDropDown) closeDropDown(false);
    }, 0);
  };

  return (
    <Form>
      <Grid className="pm-none">
        <Grid.Row className="p-none">
          <Grid.Column
            width={3}
            className="pm-none"
          >
            <h3 className="section-title m-b-sm">
              Profile Image
              <PopUpInfo
                popUp={false}
                info={true}
              />
            </h3>
            <div className="d-flex justify-center">
              <Avatar
                src={null}
                size={AvatarSize.Medium}
              />
            </div>
          </Grid.Column>

          <Grid.Column width={2}></Grid.Column>

          <Grid.Column
            width={11}
            className="pm-none"
          >
            <h3 className="section-title m-b-sm">Details</h3>
            <TeamMemberModalForm />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="p-none">
          <div className="height-0_0714 w-100 m-b-xl m-t-xl border-bottom-sm-grayish-magenta-light"></div>
        </Grid.Row>

        <Grid.Row className="p-none">
          <Button
            className="btn grey-bg"
            disabled={createTeamMemberValidation(activeTeamMember)}
            onClick={onSave}
          >
            {createMode ? 'CREATE' : 'SAVE'}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const TeamMemberModal = ({ trigger, createMode }: ModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = (value: boolean): void => {
    setIsOpen(value);

    if (!value) {
      dispatch(discardMemberCreation());
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onToggleModalStatus={toggleModal}
      trigger={trigger}
      title={`${createMode ? 'Add New Team Member' : 'Edit Team Member Details'}`}
    >
      <Content
        createMode={createMode}
        setOpen={toggleModal}
      />
    </ModalTemplate>
  );
};

export default TeamMemberModal;
