/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { toggleDisableFormSubmit, updateInput } from 'store/authentication/authSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { PasswordValidator, passwordValidator, passwordValidatorChecks } from 'utils/utils-password';

const PasswordForm = ({ mode }: ModeProps) => {
  const { password, newPassword, newPasswordConfirmation } = useAppSelector((state: RootState) => state.auth);

  const { minLength, specialCharacters, oneUpperCase, oneLowerCase, oneNumber, sameAsPassword, sameAsCurrentPassword } =
    passwordValidatorChecks;

  const [passwordValidation, setPasswordValidation] = useState<PasswordValidator>({
    isInValid: true,
    errorMessage: '',
  });

  const [newPasswordValidation, setNewPasswordValidation] = useState<PasswordValidator>({
    isInValid: true,
    errorMessage: '',
  });

  const [newPasswordConfirmationValidation, setNewPasswordConfirmationValidation] = useState<PasswordValidator>({
    isInValid: true,
    errorMessage: '',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (mode === MODE_OPTIONS.EDIT) {
      dispatch(
        toggleDisableFormSubmit({
          status:
            passwordValidation.isInValid ||
            newPasswordValidation.isInValid ||
            newPasswordConfirmationValidation.isInValid,
        }),
      );
    }
  }, [dispatch, mode, passwordValidation, newPasswordValidation, newPasswordConfirmationValidation]);

  useEffect(() => {
    if (mode === MODE_OPTIONS.EDIT) {
      let check = passwordValidator({
        validationChecks: [minLength],
        value: password,
        isNewPasswordField: false,
      });
      setPasswordValidation({
        isInValid: check.isInValid,
        errorMessage: check.errorMessage,
      });
    }
  }, [password, minLength, mode]);

  useEffect(() => {
    if (mode === MODE_OPTIONS.EDIT) {
      let check = passwordValidator({
        validationChecks: [minLength, specialCharacters, oneUpperCase, oneLowerCase, oneNumber, sameAsCurrentPassword],
        password: password,
        isNewPasswordField: true,
        value: newPassword,
      });

      setNewPasswordValidation({
        isInValid: check.isInValid,
        errorMessage: check.errorMessage,
        successMessage: check.successMessage,
      });

      if (newPasswordConfirmation !== '') {
        check = passwordValidator({
          isNewPasswordField: false,
          validationChecks: [sameAsPassword],
          value: newPasswordConfirmation,
          password: newPassword,
        });
        setNewPasswordConfirmationValidation({
          isInValid: check.isInValid,
          errorMessage: check.errorMessage,
          successMessage: check.successMessage,
        });
      }
    }
  }, [newPassword, mode, newPasswordConfirmation]);

  useEffect(() => {
    if (mode === MODE_OPTIONS.EDIT) {
      let check = passwordValidator({
        isNewPasswordField: false,
        validationChecks: [sameAsPassword],
        value: newPasswordConfirmation,
        password: newPassword,
      });
      setNewPasswordConfirmationValidation({
        isInValid: check.isInValid,
        errorMessage: check.errorMessage,
        successMessage: check.successMessage,
      });
    }
  }, [newPasswordConfirmation, mode, newPassword, sameAsPassword]);

  const onChange = (type: string, value: string) => dispatch(updateInput({ type, value }));

  return (
    <Grid className="w-50">
      <Grid.Row>
        <Grid.Column width={11}>
          <InputField
            label="Current Password"
            placeholder="Enter your Current Password"
            type={InputFieldType.PASSWORD}
            fieldKey="password"
            value={password}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            isInValid={mode === MODE_OPTIONS.EDIT && password ? passwordValidation.isInValid : false}
            errorMessage={mode === MODE_OPTIONS.EDIT ? passwordValidation.errorMessage : ''}
            onChange={onChange}
            dataTest="current-password"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={11}>
          <InputField
            label="New Password"
            placeholder="Enter your new password"
            type={InputFieldType.PASSWORD}
            fieldKey="newPassword"
            value={newPassword}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            isInValid={mode === MODE_OPTIONS.EDIT && newPassword ? newPasswordValidation.isInValid : false}
            errorMessage={mode === MODE_OPTIONS.EDIT ? newPasswordValidation.errorMessage : ''}
            successMessage={mode === MODE_OPTIONS.EDIT ? newPasswordValidation.successMessage : ''}
            onChange={onChange}
            dataTest="new-password"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={11}>
          <InputField
            label="Confirm New Password"
            placeholder="Confirm your new password"
            type={InputFieldType.PASSWORD}
            fieldKey="newPasswordConfirmation"
            value={newPasswordConfirmation}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            isInValid={
              mode === MODE_OPTIONS.EDIT && newPasswordConfirmation
                ? newPasswordConfirmationValidation.isInValid
                : false
            }
            errorMessage={mode === MODE_OPTIONS.EDIT ? newPasswordConfirmationValidation.errorMessage : ''}
            successMessage={mode === MODE_OPTIONS.EDIT ? newPasswordConfirmationValidation.successMessage : ''}
            onChange={onChange}
            dataTest="confirm-password"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PasswordForm;
