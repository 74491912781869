import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import TransactionHistoryGeneralStatus from 'common/model/TransactionHistoryGeneralStatus';
import { getCurrentDateTimeISO } from 'utils/utils-date';
import { TransactionProps, TransactionProvisionTemp, setPremiseInput } from '.';
import { AnswerProps } from '../policies';

interface CreateTransactionClone extends Omit<TransactionProps, 'answers' | 'provisions'> {
  provisions?: TransactionProvisionTemp[];
  answers?: AnswerProps[];
}

interface CreateTransactionQueryVariables {
  currentLandlordEntityId: string | null;
  params: CreateTransactionClone;
}

export const CREATE_TRANSACTION = (activeTransaction: TransactionProps, userId: string | null) => {
  if (!userId) throw new Error('Create Transaction: userId is required');

  const cloneActiveTransaction: CreateTransactionClone = JSON.parse(JSON.stringify(activeTransaction));

  cloneActiveTransaction.premises = setPremiseInput(cloneActiveTransaction.premises);

  cloneActiveTransaction.historyGeneral = [
    {
      status: TransactionHistoryGeneralStatus.Opened,
      date: getCurrentDateTimeISO(),
      notes: null,
      userId,
      documents: [],
    },
  ];

  delete cloneActiveTransaction.answers;
  delete cloneActiveTransaction.provisions;

  const currentLandlordEntityId = activeTransaction.ownerId;

  if (!currentLandlordEntityId) throw new Error('Update Transaction: currentLandlordEntityId is required');

  const variables: CreateTransactionQueryVariables = {
    currentLandlordEntityId,
    params: cloneActiveTransaction,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: TransactionInput!) {
        createTransaction(currentLandlordEntityId: $currentLandlordEntityId, params: $params) {
          id
        }
      }
    `,
    variables,
  };
};

export const createTransaction = createAsyncThunk('transactions/createTransaction', async (_args, { getState }) => {
  const {
    transactionDetail: { activeTransaction },
    auth: { id },
  } = getState() as RootState;

  const response = await client.mutate(CREATE_TRANSACTION(activeTransaction, id));
  return response;
});
