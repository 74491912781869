import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateCell from 'atoms/DateCell';
import { updateForecastFilterDates } from 'store/dashboard/forecastsSlice';
import AnalysisContext from 'common/model/AnalysisContext';
import {
  generatePortfolioRevenueAnalysisBase,
  generatePropertyRevenueAnalysisBase,
} from 'common/api/dashboard/forecasting';
import './PeriodSelect.scss';

const PeriodSelect = () => {
  const { startDate, endDate } = useAppSelector((state: RootState) => state.forecasts.dateFilter);
  const { context } = useAppSelector((state: RootState) => state.dashboard);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (context === AnalysisContext.Portfolio) {
      dispatch(generatePortfolioRevenueAnalysisBase());
    } else if (context === AnalysisContext.Property) {
      dispatch(generatePropertyRevenueAnalysisBase());
    }
  }, [startDate, endDate]);

  return (
    <div className="forecasting-tab-period-select">
      <div className="date-select m-r-l">
        <span className="m-r-xs">From:</span>
        <DateCell
          type="startDate"
          date={startDate}
          onChange={updateForecastFilterDates}
        />
      </div>

      <div className="date-select">
        <span className="m-r-xs">To:</span>
        <DateCell
          type="endDate"
          date={endDate}
          onChange={updateForecastFilterDates}
        />
      </div>
    </div>
  );
};

export default PeriodSelect;
