import ValuationDataSpace from 'common/model/ValuationDataSpace';

export class CValuationDataSpace {
  id: string;
  name: string;

  constructor(input: ValuationDataSpace) {
    this.id = input.id;
    this.name = input.name;
  }

  static fragment() {
    return `
        id
        name
    `;
  }
}
