import { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import DateRangePicker from 'atoms/FormField/DateRangePicker';
import Event from 'common/model/Event';
import { EventDropdownOption } from 'utils/types/stackingPlan';
import ContractEventDropdown from '../ContractEventDropdown';
import './EventsFilter.scss';

export interface EVENT_FILTER_OPTION {
  label: string;
  value: Event;
  className: string;
}

export const EVENT_FILTER_OPTIONS: EVENT_FILTER_OPTION[] = [
  { label: 'Expiry', value: Event.Expiry, className: 'color-red-soft bg-red-very-pale' },
  { label: 'Occupation', value: Event.Occupation, className: 'color-green bg-green-light-grayish-cyan-lime' },
  { label: 'Rent review', value: Event.RentReview, className: 'color-orange bg-orange-light-grayish' },
  { label: 'Surrender', value: Event.Surrender, className: 'color-blue-very-dark-grayish bg-gray-very-light' },
  { label: 'Expansion', value: Event.Expansion, className: 'color-blue bg-blue-light-grayish' },
];

interface EventsFilterProps {
  onChange: Function;
}

const EventsFilter = ({ onChange }: EventsFilterProps): JSX.Element => {
  const twoYearsAhead = new Date();
  twoYearsAhead.setFullYear(twoYearsAhead.getFullYear() + 2);

  const defaultDateRange = [new Date(), twoYearsAhead];
  const { totalFloor, buildingNumberOfUndergroundFloors, activeProperty } = useAppSelector(
    (state: RootState) => state.stackingPlan,
  );

  const [range, setRange] = useState<Date[]>(defaultDateRange);
  const [events, setEvents] = useState<string[]>([]);
  const [floors, setFloors] = useState<string[]>([]);
  const [floorOptions, setFloorOptions] = useState<EventDropdownOption[]>([]);

  useEffect(() => {
    if (activeProperty && activeProperty.id) {
      setRange(defaultDateRange);
      setEvents([]);
      setFloors([]);
    }
  }, [activeProperty]);

  useEffect(() => {
    let index = -buildingNumberOfUndergroundFloors;
    const options: EventDropdownOption[] = [];
    while (index < totalFloor) {
      options.push({
        label: index,
        value: index.toString(),
      });
      index++;
    }
    setFloorOptions([...options]);
  }, [totalFloor, buildingNumberOfUndergroundFloors]);

  // Apply Filter
  useEffect(() => {
    onChange({ range, floors, events });
  }, [range, floors, events]);

  return (
    <Form.Field className="events-filter-form m-b-m">
      <div
        className="date-field"
        data-test="monitoring-period-field"
      >
        <span className="label">Monitoring period:</span>
        <DateRangePicker
          value={range}
          format="dd/MM/yyyy"
          onChange={(value: Date[]) => setRange(value)}
        />
      </div>
      <div className="dropdown-field">
        <ContractEventDropdown
          options={EVENT_FILTER_OPTIONS}
          placeholder="Filter: All Events"
          dataTest="property-event-timeline-filter"
          value={events}
          onChange={(value: string[]) => setEvents(value)}
        />
        <ContractEventDropdown
          options={floorOptions}
          placeholder="Filter: All Floors"
          dataTest="property-floor-timeline-filter"
          value={floors}
          onChange={(value: string[]) => setFloors(value)}
        />
      </div>
    </Form.Field>
  );
};

export default EventsFilter;
