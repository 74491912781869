import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

export const LIST_ENTITY_ACTIVITIES = () => {
  return {
    query: gql`
      query {
        __type(name: "EntityActivity") {
          enumValues {
            name
          }
        }
      }
    `,
    variables: {},
  };
};

export const listEntityActivities = createAsyncThunk('adminEntities/listEntityActivities', async () => {
  const response = await adminClient.query(LIST_ENTITY_ACTIVITIES());
  return response;
});
