import { createSlice } from '@reduxjs/toolkit';
import { PolicyListingClone } from 'common/_classes';
import { toast } from 'react-toastify';
import PageInfo from 'common/model/PageInfo';
import { deletePolicy, listPolicies } from 'common/api/policies';
import { STORAGE_KEYS, getStoredValue, saveIntoStorage } from 'utils/utils-storage';

interface PoliciesListingState {
  policiesList: PolicyListingClone[];
  isLoading: boolean;
  pageInfo: PageInfo[];
  isSearching: boolean;
  search: string | null;
}

const initialState: PoliciesListingState = {
  policiesList: [],
  isLoading: false,
  pageInfo: [],
  isSearching: false,
  search: getStoredValue(STORAGE_KEYS.POLICY_SEARCH),
};

const policiesListingSlice = createSlice({
  name: 'policiesListing',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload;
      saveIntoStorage(STORAGE_KEYS.POLICY_SEARCH, action.payload);
    },
  },
  extraReducers: builder => {
    // Listing policies
    builder.addCase(listPolicies.pending, (state, action) => {
      if (action.meta.arg.searchValue) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listPolicies.fulfilled, (state, action) => {
      const listPolicies = action?.payload?.listPolicies;
      if (listPolicies) {
        const { edges, pageInfo } = action?.payload?.listPolicies;
        const policiesList: PolicyListingClone[] = edges ? edges.map((element: any) => element.node) : [];
        state.policiesList = policiesList;
        state.pageInfo = pageInfo;
      }

      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listPolicies.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error);
      toast.error('Some error occurred when trying to loading the Policies');
    });

    //deletePolicy
    builder.addCase(deletePolicy.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deletePolicy.fulfilled, (state, action) => {
      state.policiesList = state.policiesList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Policy deleted successfully');
    });
    builder.addCase(deletePolicy.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error(action.error.message);
    });
  },
});

export const { updateSearch } = policiesListingSlice.actions;

export default policiesListingSlice.reducer;
