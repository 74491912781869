import Decimal from 'decimal.js';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { LANDLORD_APPROVAL_TYPE_OPTIONS, RIGHT_RESTRICTION_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';

const SubletRightsForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const subletRights = activeContractEvent?.subletRights;

  const onChange = (key: string, value: string) => {
    if (key === 'wholeOrPart' && value !== WholeOrPartOptions.PartOnly) onChangeDecimal('premisePercentage', '');

    dispatch(
      updateActiveContractEventInput({
        key: ['subletRights', key],
        value: value || null,
      }),
    );
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['subletRights', key],
        value: value ? new Decimal(value) : null,
      }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ;

  return (
    <div className="grid m-t-s">
      <div className="col-2">
        <SelectField
          label="Right to sublet whole or part"
          fieldKey="wholeOrPart"
          dataTest=""
          value={subletRights?.wholeOrPart || false}
          disabled={disabled}
          required={mode === MODE_OPTIONS.EDIT}
          options={WHOLE_OR_PART_OPTIONS}
          onChange={onChange}
        />
      </div>

      {subletRights?.wholeOrPart === WholeOrPartOptions.PartOnly && (
        <div className="col-2">
          <InputField
            label="Right to sublet what % of premises"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(%)"
            fieldKey="premisePercentage"
            value={subletRights?.premisePercentage?.toString() || null}
            disabled={disabled}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
      )}

      <div className="col-2">
        <SelectField
          label="Subletting restricted to"
          fieldKey="restrictedTo"
          dataTest=""
          value={subletRights?.restrictedTo || false}
          disabled={disabled}
          options={RIGHT_RESTRICTION_OPTIONS}
          onChange={onChange}
        />
      </div>

      <div className="col-2">
        <SelectField
          label="Landlord's approval"
          fieldKey="landlordApproval"
          dataTest=""
          value={subletRights?.landlordApproval || false}
          disabled={disabled}
          options={LANDLORD_APPROVAL_TYPE_OPTIONS}
          onChange={onChange}
        />
      </div>

      <div className="col-2">
        <InputField
          label="% profit to landlord"
          dataTest=""
          type={InputFieldType.NUMBER}
          unit="(%)"
          fieldKey="landlordProfitShare"
          value={subletRights?.landlordProfitShare?.toString() || null}
          disabled={disabled}
          onChange={onChangeDecimal}
          isDecimal={true}
          delimiter="COMMA"
        />
      </div>
    </div>
  );
};
export default SubletRightsForm;
