import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { PolicyProvisionInput } from 'common/api/policies';
import { PolicyProvisionDetailClone } from '../../_classes/PolicyProvisionDetailClone';

interface UpdatePolicyProvisionQueryVariables {
  id: string | undefined;
  params: PolicyProvisionInput;
}

export const UPDATE_POLICY_PROVISION = (id: string | undefined, policyProvision: PolicyProvisionInput) => {
  if (!id) throw new Error('UPDATE_POLICY_PROVISION: id is required');

  const variables: UpdatePolicyProvisionQueryVariables = {
    id,
    params: policyProvision,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: PolicyProvisionInput!) {
        updatePolicyProvision(id: $id, params: $params) {
          ${PolicyProvisionDetailClone.fragments()}
      }
    }
    `,
    variables,
  };
};

export const updatePolicyProvision = createAsyncThunk(
  'policies/updatePolicyProvision',
  async ({ id, policyProvision }: { id: string | undefined; policyProvision: PolicyProvisionInput }) => {
    const response = await client.mutate(UPDATE_POLICY_PROVISION(id, policyProvision));
    return response;
  },
);
