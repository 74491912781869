import { DataType, FilterLogicalOperators, FilterMode, RowAction, RowProps } from '../../RowProps';
import BooleanFilter from './BooleanFilter';
import DateFilter from './DateFilter';
import DurationFilter from './DurationFilter';
import ListFilter from './ListFilter';
import NumberFilter from './NumberFilter';
import PremiseFilter from './PremiseFilter';
import StringFilter from './StringFilter';

interface RowFilterContentProps {
  filter: RowAction & RowProps;
  onChange: (key: string, value: string | number | Duration | boolean | any, updateRoot?: boolean) => void;
}

export interface RowFilterChildProps {
  filterMode?: FilterMode;
  filterMode2?: FilterMode;
  filterLogicalOperator?: FilterLogicalOperators;
  filterValue: string | boolean | number | Duration | string[] | any;
  filterValue2: string | boolean | number | Duration | string[] | any;
  dataType?: DataType;
  label: string;
  onChange: (key: string, value: string | number | Duration | boolean | any, updateRoot?: boolean) => void;
  options?: Array<any>;
}

/**
 * Contents for row filter in filter popup
 * @returns
 */
function RowFilterContent({ filter, onChange }: RowFilterContentProps): JSX.Element {
  if (!filter) {
    return <></>;
  }

  const {
    dataType,
    filterValue,
    filterValue2,
    filterMode,
    filterMode2,
    filterLogicalOperator,
    filterElement,
    options,
    label,
    id,
  } = filter;

  const filterProps = {
    filterValue,
    filterValue2,
    filterMode,
    filterMode2,
    filterLogicalOperator,
    onChange,
    label,
    options,
    dataType,
    id,
  };

  if (filterElement) {
    return filterElement(filterProps);
  }

  switch (dataType) {
    case DataType.STRING:
      return <StringFilter {...filterProps} />;
    case DataType.NUMBER:
      return <NumberFilter {...filterProps} />;
    case DataType.DECIMAL:
      return <NumberFilter {...filterProps} />;
    case DataType.DATE:
      return <DateFilter {...filterProps} />;
    case DataType.DURATION:
      return <DurationFilter {...filterProps} />;
    case DataType.BOOLEAN:
      return <BooleanFilter {...filterProps} />;
    case DataType.LIST:
      return <ListFilter {...filterProps} />;
    case DataType.PREMISE:
      return <PremiseFilter {...filterProps} />;
    default:
      return <></>;
  }
}

export default RowFilterContent;
