import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import AuthLayout from 'layouts/AuthLayout';
import SignUpForm from './SignUpForm';
import SignUpVerify from './SignUpVerify';
import '../authentication.scss';

interface SignUpProps {
  verify?: boolean;
}
const SignUp = ({ verify }: SignUpProps) => {
  const { isLoading } = useAppSelector((state: RootState) => state.auth);

  return (
    <AuthLayout
      loading={isLoading}
      form={verify ? <SignUpVerify /> : <SignUpForm />}
      verify={verify}
    />
  );
};

export default SignUp;
