import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeleteNetworkQueryVariables {
  networkId: string;
}

export const DELETE_Network = (networkId: string) => {
  if (!networkId) throw new Error('Network Entity: networkId is required');

  const variables: DeleteNetworkQueryVariables = {
    networkId,
  };

  return {
    mutation: gql`
      mutation ($networkId: ID!) {
        deleteNetwork(networkId: $networkId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteNetwork = createAsyncThunk('entitiesListing/deleteNetwork', async ({ id }: { id: string }) => {
  const response = await client.mutate(DELETE_Network(id));
  return response;
});
