import React from 'react';
import { Icon } from '@iconify/react';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import './AddPlus.scss';

interface AddPlusProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  dataTest?: string;
  disabled?: boolean;
}

const AddPlus = ({ label, dataTest, onClick, style, className, disabled }: AddPlusProps) => {
  return (
    <span
      className={`add-plus-container p-xs color-blue-very-dark-grayish font-600 ${conditionalClassName(className, className)} ${conditionalClassName(disabled, 'disabled')}`}
      data-test={dataTest}
      onClick={disabled ? () => {} : onClick}
      style={style}
    >
      <Icon
        className="color-orange"
        icon={Icons.AddFilled}
      />
      {label}
    </span>
  );
};

export default AddPlus;
