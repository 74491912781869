import { useEffect } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import { resetActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import ContentEditor from './ContentEditor';
import GeneralForm from './GeneralForm';

interface AmendmentFormProps {
  mode: MODE_OPTIONS;
}

const AmendmentForm = ({ mode }: AmendmentFormProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetActiveAmendment());
    };
  }, []);

  return (
    <Grid className="page-details-container m-t-none">
      <Grid.Column
        mobile={16}
        tablet={16}
        computer={16}
        className="p-none"
      >
        <Form className="m-t-s">
          <Grid>
            <Grid.Row className="pm-none">
              <Grid.Column width={5}>
                <SectionComponent title="General">
                  <GeneralForm mode={mode} />
                </SectionComponent>
              </Grid.Column>

              <Grid.Column width={11}>
                <SectionComponent
                  title={
                    <>
                      Amendment language
                      {mode === MODE_OPTIONS.EDIT && <span className="required-indicator-title m-l-xxs">*</span>}
                    </>
                  }
                >
                  <ContentEditor mode={mode} />
                </SectionComponent>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default AmendmentForm;
