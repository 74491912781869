import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import { ChangePasswordQueryVariables } from './changePassword';

export const CHANGE_ADMIN_PASSWORD = (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
  const variables: ChangePasswordQueryVariables = {
    currentPassword: oldPassword,
    params: {
      password: newPassword,
      passwordConfirmation: confirmNewPassword,
    },
  };

  return {
    mutation: gql`
      mutation ($currentPassword: String!, $params: PasswordParams!) {
        changePassword(currentPassword: $currentPassword, params: $params) {
          token
        }
      }
    `,
    variables,
  };
};

export const changeAdminPassword = createAsyncThunk('auth/changeAdminPassword', async (_arg, { getState }) => {
  const {
    auth: { newPassword, newPasswordConfirmation, password },
  } = getState() as RootState;
  const response = await adminClient.mutate(CHANGE_ADMIN_PASSWORD(password, newPassword, newPasswordConfirmation));
  return response;
});
