import { ToastContainerProps } from 'react-toastify';

export const defaultToastContainerProps: ToastContainerProps = {
  draggable: true,
  pauseOnHover: true,
  theme: 'colored',
  position: 'bottom-center',
  // @ts-ignore
  stacked: true,
  hideProgressBar: true,
  autoClose: 4000,
};
