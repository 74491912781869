import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface CheckNodeIntegrityQueryVariables {
  id: string;
}

const CHECK_NODE_INTEGRITY = (id: string | undefined) => {
  if (!id) throw new Error('Check Node Integrity: id is required');

  const variables: CheckNodeIntegrityQueryVariables = {
    id,
  };

  return {
    query: gql`
      query ($id: ID!) {
        checkNodeIntegrity(id: $id) {
          result
        }
      }
    `,
    variables,
  };
};

export const checkNodeIntegrity = createAsyncThunk(
  'provisions/checkNodeIntegrity',
  async ({ id }: { id: string | undefined }) => {
    const response = await adminClient.query(CHECK_NODE_INTEGRITY(id));
    return response;
  },
);
