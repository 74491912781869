import moment from 'moment';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import { filterElementDate } from 'atoms/TableReactPrime/columnTemplates';
import Tooltip from 'atoms/Tooltip';
import { BenchmarkInput } from 'common/api/benchmark';
import { addSpaceOrComma } from 'utils/utils-number';
import { BenchmarkRowActionButtons } from './BenchmarkRowActionButtons';

export class BenchmarkRow {
  id: string | undefined | null;
  identifier: string | null;
  address: string | null;
  description: string | null;
  endDate: string | null;
  startDate: string | null;
  tenant: string | null;
  grossArea: number | null;
  rentPerArea: number | null;

  constructor(benchmark: BenchmarkInput) {
    this.id = benchmark.id;
    this.identifier = benchmark.identifier;
    this.address = benchmark.address;
    this.description = benchmark.description;
    this.startDate = benchmark.startDate;
    this.endDate = benchmark.endDate;
    this.tenant = benchmark.name;
    this.grossArea = Number(benchmark.size);
    this.rentPerArea = Number(benchmark.rentPerArea);
  }
}

/*
 * Definition of the column (names, format...)
 */
export const benchmarkColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  return [
    {
      field: 'identifier',
      header: 'Identifier',
      filterPlaceholder: 'Search by identifier',
      filter: true,
      filterField: 'identifier',
      sortable: true,
      mandatory: false,
      minWidth: '10rem',
    },
    {
      field: 'address',
      header: 'Address',
      filter: true,
      filterPlaceholder: 'Search by Address',
      filterField: 'address',
      sortable: true,
      dataType: 'text',
    },
    {
      field: 'description',
      header: 'Description',
      filter: true,
      filterPlaceholder: 'Search by description',
      filterField: 'description',
      sortable: true,
      dataType: 'text',
      width: '25rem',
      body: (row: BenchmarkRow) => {
        return (
          <Tooltip
            trigger={
              <span>
                {row.description && row.description.length >= 100
                  ? row.description.slice(0, 100) + '...'
                  : row.description}
              </span>
            }
            HTMLContent={<span>{row.description}</span>}
            disabled={row.description ? row.description.length <= 200 : false}
          />
        );
      },
    },
    {
      field: 'startDate',
      header: 'Start date',
      filter: true,
      filterPlaceholder: 'Search by date',
      filterField: 'startDate',
      sortable: true,
      dataType: 'date',
      body: (row: BenchmarkRow) => {
        return moment(row.startDate).format('DD/MM/YYYY');
      },
      filterElement: filterElementDate,
    },
    {
      field: 'endDate',
      header: 'End date',
      filter: true,
      filterPlaceholder: 'Search by date',
      filterField: 'endDate',
      sortable: true,
      body: (row: BenchmarkRow) => {
        return moment(row.endDate).format('DD/MM/YYYY');
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },
    {
      field: 'tenant',
      header: 'Tenant',
      filter: true,
      filterPlaceholder: 'Search by Tenant',
      filterField: 'tenant',
      sortable: true,
      dataType: 'text',
    },
    {
      field: 'grossArea',
      header: 'Gross Area',
      filter: true,
      filterPlaceholder: 'Search by Gross Area',
      filterField: 'grossArea',
      sortable: true,
      dataType: 'numeric',
      body: (row: BenchmarkRow) => addSpaceOrComma(row.grossArea, false),
    },
    {
      field: 'rentPerArea',
      header: 'Rent per Area (HK$/Sq.ft/month)',
      filter: true,
      filterPlaceholder: 'Search by Rent per Area',
      filterField: 'rentPerArea',
      sortable: true,
      dataType: 'numeric',
      body: (row: BenchmarkRow) => addSpaceOrComma(row.rentPerArea, false),
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      frozen: true,
      alignFrozen: 'right',
      body: (row: BenchmarkRow) => (
        <BenchmarkRowActionButtons
          rowId={row.id || ''}
          onViewDetails={onViewDetails}
        />
      ),
    },
  ];
};
