import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import { listPropertyFloors } from 'common/api/floor';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { FloorRow, floorColumnConfig } from './FloorTableConfig';

const PropertyFloorTable = () => {
  const dispatch = useAppDispatch();

  const { activeProperty, floorsList, isLoading } = useAppSelector((state: RootState) => state.propertyDetail);

  const currentList: FloorRow[] = floorsList.map((floor: any) => new FloorRow(floor));

  const getFloors = (params: PaginationAPIProps) => {
    dispatch(listPropertyFloors({ ...params }));
  };

  return (
    <TableReactPrime
      fetchContent={({ ...rest }) => {
        getFloors(rest);
      }}
      isFetching={isLoading}
      content={currentList}
      columnConfig={floorColumnConfig(activeProperty)}
      dataTest="floors-and-units-data-table"
    />
  );
};

export default PropertyFloorTable;
