import { RiskAnalysisData } from 'common/api/dashboard';
import Graph from './graph';

interface ParetoGraphProps {
  riskAnalysisData: RiskAnalysisData[];
  title: string;
}

const ParetoGraph = ({ riskAnalysisData, title }: ParetoGraphProps) => {
  return (
    <div className="pareto-graph-container-box">
      <h2 className="graph-title color-blue-very-dark-grayish">{title}</h2>
      {riskAnalysisData.length === 0 ? (
        <div className="no-data">No data...</div>
      ) : (
        <Graph riskAnalysisData={riskAnalysisData} />
      )}
    </div>
  );
};

export default ParetoGraph;
