import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import User from 'common/model/User';
import { listUsers } from 'common/api/adminUsers';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import AdminUsersHeaderToolbar from './AdminUsersHeaderToolbar';
import { UserRow, adminUsersColumnConfig } from './AdminUsersTableConfig';

const AdminUsersListing = () => {
  const dispatch = useAppDispatch();

  const { isLoading, usersList } = useAppSelector((state: RootState) => state.adminUsers);

  const fetchUsers = (params: PaginationAPIProps) => {
    dispatch(listUsers({ ...params }));
  };

  const currentList: UserRow[] = usersList.map((user: User) => new UserRow(user));
  const breadCrumb: BreadCrumbItem[] = [{ title: 'Users' }];

  const getPageHeaderProps = (): PageHeaderProps => {
    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <AdminUsersHeaderToolbar />,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          fetchUsers(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={adminUsersColumnConfig()}
      />
    </PageLayout>
  );
};

export default AdminUsersListing;
