import { useEffect } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateInputKey } from 'store/entities/entityDetailSlice';
import EntityType from 'common/model/EntityType';
import { listCountries } from 'common/api/miscellaneous';
import { CountryClone } from 'common/api/miscellaneous';
import { ENTITY_OPTIONS } from 'utils/UI';
import { namesListHK } from 'utils/tsHelper';

const LegalEntityInfo = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeEntity } = useAppSelector((state: RootState) => state.entityDetail);
  const { countries } = useAppSelector((state: RootState) => state.miscellaneous);
  const {
    type,
    legalDomicile,
    residentialAddress,
    businessAddress,
    cardId,
    passportId,
    registrationCountry,
    registrationId,
    overseasRegistrationId,
    details,
    addressLocal,
  } = activeEntity;

  const onChange = (key: string, value: string) => dispatch(updateInputKey({ key, value }));

  let countryText: string;
  let addressText: string;
  let idText: string;
  let addressType: string;
  let addressValue: string | null;
  let countryType: string;
  let countryValue: string | null = null;

  const checkForIndividual: boolean = type === EntityType.Individual;

  if (checkForIndividual) {
    countryText = 'legal domicile';
    idText = 'Hong Kong ID number';
    addressType = 'residentialAddress';
    addressValue = residentialAddress;
    countryType = 'legalDomicile';
    countryValue = legalDomicile;
    let countryMatch: CountryClone = countries.find(
      (country: CountryClone) => country.name === legalDomicile || country.code === legalDomicile,
    )!;
    const countryName: string = legalDomicile ? (countryMatch ? countryMatch.name : '') : 'legal domicile';

    addressText = `Residential Address in ${countryName}`;
  } else {
    countryText = 'incorporation';
    let registrationNumber: string = 'registration number';

    if (registrationCountry === null || namesListHK.includes(registrationCountry)) {
      idText = `Company ${registrationNumber}`;
    } else {
      idText = `Overseas ${registrationNumber}`;
    }
    let countryMatch: CountryClone = countries.find(
      (country: CountryClone) => country.name === registrationCountry || country.code === registrationCountry,
    )!;
    const countryName: string = countryMatch ? countryMatch.name : 'country of incorporation';
    addressText = `Business Address in ${countryName}`;
    addressType = 'businessAddress';
    addressValue = businessAddress;
    countryType = 'registrationCountry';
    countryValue = registrationCountry;
  }

  const checkCountry: boolean = countryValue === null || namesListHK.includes(countryValue);

  let conditionalField: JSX.Element;

  if (checkForIndividual) {
    if (checkCountry) {
      conditionalField = (
        <InputField
          label={idText}
          fieldKey="cardId"
          value={cardId || ''}
          placeholder="Enter the number..."
          dataTest="entity-hk-id-number"
          onChange={onChange}
          required={true}
        />
      );
    } else {
      conditionalField = (
        <InputField
          label="Passport number"
          fieldKey="passportId"
          value={passportId || ''}
          placeholder="Enter the passport number..."
          dataTest="entity-passport"
          onChange={onChange}
          required={true}
        />
      );
    }
  } else {
    if (checkCountry) {
      conditionalField = (
        <InputField
          label={idText}
          fieldKey="registrationId"
          value={registrationId || ''}
          placeholder="Enter the registration id..."
          dataTest="entity-registration-id"
          onChange={onChange}
          required={true}
        />
      );
    } else {
      conditionalField = (
        <InputField
          label={idText}
          fieldKey="overseasRegistrationId"
          value={overseasRegistrationId || ''}
          placeholder="Enter the overseas registration id..."
          dataTest="entity-overseas-registration-id"
          onChange={onChange}
          required={true}
        />
      );
    }
  }

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(listCountries());
    }
  }, [dispatch, countries.length]);

  const addressLocalField = (): JSX.Element => {
    if (!type) return <></>;

    const field: JSX.Element = (
      <TextAreaField
        label="Business Address in Hong Kong"
        fieldKey="addressLocal"
        value={addressLocal || ''}
        placeholder="Enter the address..."
        dataTest="entity-address-local"
        onChange={onChange}
        required={true}
      />
    );

    // If Entity type is Individual and legalDomicile is not Hong Kong, Business Address in Hong Kong IS required
    if (type === EntityType.Individual && legalDomicile && !namesListHK.includes(legalDomicile || '')) return field;

    // If Entity type is Company and registrationCountry is not Hong Kong, Business Address in Hong Kong IS required
    if (type === EntityType.Company && registrationCountry && !namesListHK.includes(registrationCountry || ''))
      return field;

    // Every other combination, Business Address in Hong Kong IS NOT required
    return <></>;
  };

  return (
    <Form>
      <h2 className="color-blue-very-dark">Details</h2>
      <Grid className="m-t-sm w-60">
        <Grid.Row className="p-none">
          <Grid.Column
            width="8"
            className="p-l-none"
          >
            <SelectField
              label="Type of entity"
              required={true}
              placeholder="Select entity type"
              dataTest="select-entity-type"
              options={ENTITY_OPTIONS}
              value={type || ''}
              fieldKey="type"
              onChange={onChange}
              className="m-b-sm"
            />

            <SelectField
              label={`Country of ${countryText}`}
              required={true}
              placeholder="Select country"
              dataTest="entity-country-of-legal-domicile"
              value={countryValue || ''}
              options={countries}
              search={true}
              fieldKey={countryType}
              onChange={onChange}
              className="m-b-sm"
            />

            {conditionalField}

            <TextAreaField
              label={addressText}
              fieldKey={addressType}
              value={addressValue || ''}
              placeholder="Enter the address..."
              dataTest="entity-address"
              onChange={onChange}
              required={true}
            />

            {addressLocalField()}

            <TextAreaField
              label="Additional information"
              fieldKey="details"
              value={details || ''}
              placeholder="Enter additional information..."
              dataTest="entity-additional-information"
              onChange={onChange}
              required={false}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default LegalEntityInfo;
