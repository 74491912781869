import { ReactNode } from 'react';
import './CardBody.scss';

interface CardBodyProps {
  template: ReactNode;
  onClick: () => void;
}

const CardBody = ({ template, onClick }: CardBodyProps) => {
  const displayNoContent: boolean = template === null || template === undefined;

  return (
    <div
      className="card-body"
      data-test="card-body"
      onClick={onClick}
    >
      {displayNoContent ? '' : <div className="card-body-template">{template}</div>}
    </div>
  );
};

export default CardBody;
