import Avatar, { AvatarSize } from 'atoms/Avatar';
import Badge, { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementStatus,
  filterElementStatusTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import MemberStatus from 'common/model/MemberStatus';
import { MemberClone } from 'common/api/members';
import { getPhoneNumber } from 'utils/utils-string';
import { MemberRowActionButtons } from './MemberRowActionButtons';

export class MemberRow {
  member: MemberClone;
  id: string;
  photoUrl: string | null;
  name: string;
  email: string;
  phone: string;
  role: string;
  approvalLevel: string;
  status: string;

  constructor(member: MemberClone) {
    this.member = member;
    this.id = member.id;
    this.photoUrl = member.user?.photoUrl;
    this.name = member.user?.firstName + ' ' + member.user?.lastName;
    this.email = member.user?.email;
    this.phone = getPhoneNumber(member.user?.countryCode, member.user?.phoneNumber);
    this.role = member.role.name;
    this.approvalLevel = member.role.approvalLevel;
    this.status = member.status;
  }
}

const statuses: StatusBadgeArgument[] = [
  { value: MemberStatus.Valid, color: BadgeColor.GREEN },
  { value: MemberStatus.Refused, color: BadgeColor.RED },
  { value: MemberStatus.Revoked, color: BadgeColor.RED },
  { value: MemberStatus.Invited, color: BadgeColor.ORANGE },
];

const filterElementPhotoTemplate = (photoUrl: string) => {
  return (
    <Avatar
      src={photoUrl || ''}
      size={AvatarSize.Small}
    />
  );
};

const filterElementApprovalLevelTemplate = (approvalLevel: string) => {
  return <Badge>{approvalLevel}</Badge>;
};
/*
 * Definition of the column (names, format...)
 */
export const memberColumnConfig = (): CustomColumnProps[] => {
  return [
    {
      field: 'photoUrl',
      header: 'Photo',
      filter: false,
      sortable: false,
      body: row => {
        return filterElementPhotoTemplate(row.photoUrl);
      },
    },
    {
      field: 'name',
      header: 'Name of employee',
      filter: true,
      filterPlaceholder: 'Search by Name',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'email',
      header: 'Email Address',
      filterPlaceholder: 'Search by Email Address',
      filter: true,
      filterField: 'email',
      sortable: true,
    },
    {
      field: 'phone',
      header: 'Phone number',
      filter: true,
      filterPlaceholder: 'Search by Phone number',
      filterField: 'phone',
      sortable: true,
      dataType: 'text',
    },
    {
      field: 'role',
      header: 'Role',
      filter: true,
      filterPlaceholder: 'Search by Role',
      filterField: 'role',
      sortable: true,
      dataType: 'text',
    },
    {
      field: 'approvalLevel',
      header: 'Approval level',
      filter: true,
      filterPlaceholder: 'Search by Approval level',
      filterField: 'approvalLevel',
      sortable: true,
      body: (row: MemberRow) => filterElementApprovalLevelTemplate(row.approvalLevel),
    },
    {
      field: 'status',
      header: 'Status',
      filterPlaceholder: 'Search by Status',
      filter: true,
      filterField: 'status',
      sortable: true,
      body: (row: MemberRow) => filterElementStatusTemplate(row.status, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: MemberRow) => <MemberRowActionButtons row={row} />,
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
