import { useState } from 'react';
import { Card, Container } from 'semantic-ui-react';
import { capitalize } from 'lodash';
import PageLayout from 'layouts/PageLayout';
import CheckboxField from 'atoms/FormField/Checkbox';
import SelectField from 'atoms/FormField/Select';
import Graph from './Graph';
import { data } from './data';
import './GraphTestPage.scss';

export enum OccupancyType {
  Tenancy = 'tenancy',
  Transaction = 'transaction',
  Assumed = 'assumed',
}

const GraphTestPage = () => {
  const [selectedOption, setSelectedOption] = useState<'monthly' | 'quarterly' | 'yearly'>('monthly');
  const [typesToShow, setTypesToShow] = useState<OccupancyType[]>([
    OccupancyType.Tenancy,
    OccupancyType.Transaction,
    OccupancyType.Assumed,
  ]);

  return (
    <PageLayout>
      <div className="graph-test-page">
        <h1>{data.buildingName}</h1>
        <div
          className="m-b-l m-t-l"
          style={{
            width: '200px',
          }}
        >
          <SelectField
            options={['monthly', 'quarterly', 'yearly'].map(option => ({
              key: option,
              text: capitalize(option),
              value: option,
            }))}
            value={selectedOption}
            onChange={(_, value) => setSelectedOption(value as 'monthly' | 'quarterly' | 'yearly')}
          />
        </div>
        <Graph
          data={data}
          selectedOption={selectedOption}
          typesToShow={typesToShow}
        />
        <Card>
          <Card.Content>
            {Object.values(OccupancyType).map(type => (
              <CheckboxField
                fieldKey={type}
                label={type}
                value={typesToShow.includes(type)}
                onChange={(_, { checked }) => {
                  if (typesToShow.includes(type)) {
                    setTypesToShow(typesToShow.filter(t => t !== type));
                  } else {
                    setTypesToShow([...typesToShow, type]);
                  }
                }}
                key={type}
              />
            ))}
          </Card.Content>
        </Card>
      </div>
    </PageLayout>
  );
};

export default GraphTestPage;
