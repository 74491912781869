import { useState } from 'react';
import { Form } from 'semantic-ui-react';
import SectionComponent from 'layouts/SectionComponent';
import TabContainer from 'components/TabContainer';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import PasswordForm from './PasswordForm';
import PasswordTabToolbar from './PasswordTabToolbar';

const Content = ({ mode }: ModeProps): JSX.Element => {
  return (
    <Form>
      <SectionComponent title="">
        <PasswordForm mode={mode} />
      </SectionComponent>
    </Form>
  );
};

const PasswordTab = (): JSX.Element => {
  const [mode, setMode] = useState<MODE_OPTIONS>(MODE_OPTIONS.READ);

  return (
    <TabContainer
      tabTitle="Password"
      tabToolbar={
        <PasswordTabToolbar
          mode={mode}
          setMode={setMode}
        />
      }
      mode={mode}
      data-test="profile-password-tab"
    >
      {/* Mode prop is present */
      /* @ts-ignore */}
      <Content />
    </TabContainer>
  );
};

export default PasswordTab;
