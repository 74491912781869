import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface PrepPropertyForPhotoUploadQueryVariables {
  fileName: string;
  mimeType: any;
}

const PREP_PROPERTY_PHOTO_FOR_UPLOAD = (fileName: string, mimeType: any) => {
  const variables: PrepPropertyForPhotoUploadQueryVariables = {
    fileName,
    mimeType,
  };

  return {
    query: gql`
      query ($fileName: String!, $mimeType: String!) {
        prepPropertyPhoto(fileName: $fileName, mimeType: $mimeType) {
          cdnUrl
          uploadUrl
          path
        }
      }
    `,
    variables,
  };
};

export const prepPropertyPhotoForUpload = createAsyncThunk(
  'auth/prepPropertyPhotoForUpload',
  async ({ file }: { file: any }) => {
    const response = await client.query(PREP_PROPERTY_PHOTO_FOR_UPLOAD(file.name, file.type));
    return response;
  },
);
