import { IEntityClone } from 'common/_classes/entities';
import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateInputKey } from 'store/entities/entityDetailSlice';
import EntityType from 'common/model/EntityType';
import { listCountries } from 'common/api/miscellaneous';
import { CountryClone, MODE_OPTIONS } from 'common/api/miscellaneous';
import { ENTITY_OPTIONS } from 'utils/UI';
import { namesListHK } from 'utils/tsHelper';

interface EntityLegalFormProps {
  mode: MODE_OPTIONS;
  entityData: IEntityClone;
}

enum EntityTypeSwitch {
  Individual,
  NonIndividual,
}

const checkIfCountryIsHongKong = (country: string | null) => country === null || namesListHK.includes(country);

const EntityLegalForm = ({ mode, entityData }: EntityLegalFormProps) => {
  const { countries } = useAppSelector((state: RootState) => state.miscellaneous);
  // const { activeEntity } = useAppSelector((state: RootState) => state.entityDetail);
  const {
    type = '',
    legalDomicile = '',
    residentialAddress = '',
    businessAddress = '',
    cardId = '',
    passportId = '',
    registrationCountry = '',
    registrationId = '',
    overseasRegistrationId = '',
    details = '',
    addressLocal = '',
  } = entityData || {};

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(listCountries());
    }
  }, [dispatch, countries.length]);

  const onChange = (key: string, value: string) => {
    dispatch(updateInputKey({ key, value }));
  };

  const conditionalFieldData = {
    [EntityTypeSwitch.Individual]: (() => {
      let countryMatch: CountryClone = countries.find(
        (country: CountryClone) => country.name === legalDomicile || country.code === legalDomicile,
      )!;
      const countryName = countryMatch ? countryMatch.name : 'Hong Kong (China)';
      const isHongKongData = checkIfCountryIsHongKong(legalDomicile);

      return {
        // Country
        countryLabel: 'Country of legal domicile',
        countryFieldKey: 'legalDomicile',
        countryValue: legalDomicile,
        // Address
        addressLabel: `Residential address in ${countryName}`,
        addressFieldKey: 'residentialAddress',
        addressValue: residentialAddress,
        // Hong Kong ID Number OR
        // Passport Number
        registrationIDLabel: isHongKongData ? 'Hong Kong ID number' : 'Passport Number',
        registrationIDValueFieldKey: isHongKongData ? 'cardId' : 'passportId',
        registrationIDValue: isHongKongData ? cardId : passportId,
        // Address local,
        hasAddressLocal: !isHongKongData,
      };
    })(),
    [EntityTypeSwitch.NonIndividual]: (() => {
      let countryMatch: CountryClone = countries.find(
        (country: CountryClone) => country.name === registrationCountry || country.code === registrationCountry,
      )!;
      const countryName = countryMatch ? countryMatch.name : 'Hong Kong (China)';
      const isHongKongData = checkIfCountryIsHongKong(registrationCountry);

      return {
        // Country
        countryLabel: 'Country of incorporation',
        countryFieldKey: 'registrationCountry',
        countryValue: registrationCountry,
        // Address
        addressLabel: `Business address in ${countryName}`,
        addressFieldKey: 'businessAddress',
        addressValue: businessAddress,
        // Company Registration Number OR
        // Overseas Registration Number
        registrationIDLabel: isHongKongData ? 'Company registration number' : 'Overseas registration number',
        registrationIDValueFieldKey: isHongKongData ? 'registrationId' : 'overseasRegistrationId',
        registrationIDValue: isHongKongData ? registrationId : overseasRegistrationId,
        // Address local,
        hasAddressLocal: !isHongKongData && type === EntityType.Company,
      };
    })(),
  };

  const conditionalData =
    type === EntityType.Individual
      ? conditionalFieldData[EntityTypeSwitch.Individual]
      : conditionalFieldData[EntityTypeSwitch.NonIndividual];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          width={10}
          className="p-none"
        >
          <SelectField
            className="m-b-sm"
            label="Type of entity"
            value={type}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select entity type"
            dataTest="entity-type"
            options={ENTITY_OPTIONS}
            search={false}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="type"
          />

          {/* Country  */}
          <SelectField
            label={conditionalData.countryLabel}
            value={conditionalData.countryValue}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select Country"
            dataTest="entity-country-of-legal-domicile"
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey={conditionalData.countryFieldKey}
            search={true}
            options={countries}
          />

          {/* Address  */}
          <InputField
            label={conditionalData.addressLabel}
            value={conditionalData.addressValue}
            dataTest="entity-address"
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey={conditionalData.addressFieldKey}
            className="m-t-sm"
          />

          {/* Hong Kong ID Number OR
              Passport Number OR
              Company Registration Number OR
              Overseas Registration Number 
          */}
          <InputField
            label={conditionalData.registrationIDLabel}
            value={conditionalData.registrationIDValue}
            dataTest="entity-id-or-passport-or-registration-or-overseas-number"
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey={conditionalData.registrationIDValueFieldKey}
          />

          {
            // Bussiness Address In Hong Kong
            // (Only for Individual or Companies) not in Hong Kong
            conditionalData.hasAddressLocal && (
              <InputField
                label="Business Address in Hong Kong"
                value={addressLocal}
                dataTest="address-local"
                disabled={mode === MODE_OPTIONS.READ}
                required={mode === MODE_OPTIONS.EDIT}
                onChange={onChange}
                fieldKey="addressLocal"
              />
            )
          }

          <TextAreaField
            label="Additional information"
            value={details}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="details"
            dataTest="entity-additional-information"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EntityLegalForm;
