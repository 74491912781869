import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { clearPasswords } from 'store/authentication/authSlice';
import { changeAdminPassword, changePassword } from 'common/api/authentication';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { checkIfAdminURL } from 'utils/utils-admin';
import { Icons } from 'utils/utils-icons';

interface PasswordTabToolbarProps {
  mode: MODE_OPTIONS;
  setMode: Function;
}

const PasswordTabToolbar = ({ mode, setMode }: PasswordTabToolbarProps) => {
  const dispatch = useAppDispatch();
  const { disableFormSubmit } = useAppSelector((state: RootState) => state.auth);

  const onCancel = () => {
    dispatch(clearPasswords());
    setMode(MODE_OPTIONS.READ);
  };

  const onSave = async () => {
    const isAdmin: boolean = checkIfAdminURL();
    const { meta } = await dispatch(isAdmin ? changeAdminPassword() : changePassword());
    if (meta.requestStatus !== 'fulfilled') {
      return;
    }
    setMode(MODE_OPTIONS.READ);
  };

  return (
    <div className="d-flex btns">
      {mode === MODE_OPTIONS.READ ? (
        <Button
          className="btn grey-bg"
          onClick={() => setMode(MODE_OPTIONS.EDIT)}
          data-test="edit-button"
        >
          <Icon icon={Icons.Pencil}></Icon>
          EDIT
        </Button>
      ) : (
        <>
          <Button
            className="btn grey-outline"
            onClick={() => onCancel()}
            data-test="cancel-button"
          >
            CANCEL
          </Button>
          <Button
            className="btn grey-bg"
            disabled={disableFormSubmit}
            onClick={() => onSave()}
            data-test="save-button"
          >
            CHANGE PASSWORD
          </Button>
        </>
      )}
    </div>
  );
};

export default PasswordTabToolbar;
