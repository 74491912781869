import AlternateEmail from 'views/authentication/AlternateEmail';
import PasswordReset from 'views/authentication/PasswordReset';
import SignIn from 'views/authentication/SignIn';
import SignUp from 'views/authentication/SignUp';
import { isRegistration } from './redirects';

const AuthenticationRoutes = () => [
  {
    path: '/',
    element: isRegistration(<SignIn />),
  },
  {
    path: '/sign-in',
    element: isRegistration(<SignIn />),
  },
  {
    path: '/sign-up',
    children: [
      {
        path: '/sign-up',
        element: isRegistration(<SignUp />),
      },
      {
        path: '/sign-up/verify',
        element: isRegistration(<SignUp verify={true} />),
      },
    ],
  },
  {
    path: '/reset-password',
    element: isRegistration(<PasswordReset />),
  },
  {
    path: '/alternate-email',
    element: isRegistration(<AlternateEmail />),
  },
];

export default AuthenticationRoutes;
