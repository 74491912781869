import moment from 'moment';
import Scale from 'common/model/Scale';

/*
 * returns a number between 1 and 4
 *  - Jan-Mar: 1
 *  - Apr-Jun: 2
 *  - Jul-Sep: 3
 *  - Oct-Dec: 4
 */
const getQuarter = (date: Date): number => {
  //const quarter : number = Math.ceil(moment().month() / 3) + 1;
  const quarter: number = moment(date).quarter();
  return quarter;
};

/*
 * returns a number between 1 and 2
 *  - Jan-Jun: 1
 *  - Jul-Dec: 2
 */
const getSemester = (date: Date): number => {
  const semester: number = Math.ceil(moment().month() / 6) + 1;
  return semester;
};

/*
 * returns a string to format the graph
 *  - if scale is MONTH    : "Feb 2023"
 *  - if scale is QUARTER  : "Q1 2023"
 *  - if scale is SEMESTER : "S1 2023"
 *  - if scale is YEAR     : "2023"
 */
export const getDateFormatForGraph = (date: Date, scale: Scale): string => {
  return scale === Scale.Month
    ? moment(date).format('MMM YYYY')
    : scale === Scale.Quarter
      ? `Q${getQuarter(date)} ` + moment(date).format('YYYY')
      : scale === Scale.Semester
        ? `S${getSemester(date)} ` + moment(date).format('YYYY')
        : moment(date).format('YYYY');
};

/*
 * returns a Date, invert of the getDateFormatForGraph()
 */
export const invertDateFormatForGraph = (string: string, scale: Scale): Date => {
  let stringScaleIndex: string = string.substring(1, 2);
  let stringYear: string = string.substring(3, 7);

  let offset =
    scale === Scale.Quarter
      ? (parseInt(stringScaleIndex) - 1) * 3
      : scale === Scale.Semester
        ? (parseInt(stringScaleIndex) - 1) * 6
        : 0;

  return scale === Scale.Year
    ? moment(string, 'YYYY').toDate()
    : scale === Scale.Month
      ? moment(string, 'MMM YYYY').toDate()
      : moment(stringYear, 'YYYY').add(offset, 'M').toDate();
};

/**
 * Get Current date time in ISO format
 * @returns [string] eg. 2023-06-21T10:36:45.468Z
 */
export const getCurrentDateTimeISO = (): string => {
  return new Date().toISOString();
};

/**
 * Get the difference in days between the dates
 * where endDate > startDate
 * eg: startDate ='2023-01-21T07:08:55.277Z', endDate = '2023-01-26T07:08:55.277Z', difference = 5
 * @param startDate [Date]
 * @param endDate [Date]
 * @returns number
 */
export const getNumberOfDays = (startDate: Date | string | null, endDate: Date | string | null): number => {
  const totalDifference = moment(endDate).diff(startDate, 'days');
  if (Number.isNaN(totalDifference)) {
    return 0;
  }
  return totalDifference;
};

/**
 * Get the difference in month between the dates
 * where endDate > startDate
 * eg: startDate ='2023-01-21T07:08:55.277Z', endDate = '2023-06-21T07:08:55.277Z', difference = 5
 * @param startDate [Date]
 * @param endDate [Date]
 * @returns number
 */
export const getMonthsDifference = (startDate: Date, endDate: Date): number => {
  const totalDifference = moment(endDate).diff(startDate, 'months');
  if (Number.isNaN(totalDifference)) {
    return 0;
  }
  return totalDifference;
};

export const isValidTimeInput = (key: string, value: string | number): boolean => {
  if (key === 'hours') return Number(value) < 24;

  if (key === 'minutes') return Number(value) < 60;

  return false;
};

/**
 * Get the difference in days between the dates
 * where endDate > startDate
 * eg: startDate ='2023-01-21T07:08:55.277Z', endDate = '2023-01-26T07:08:55.277Z', totalDays: 10 difference = 5
 * Output = 50
 * @param startDate [Date]
 * @param endDate [Date]
 * @param totalDays [number]
 * @returns number
 */
export const getPercentageFromStartDate = (startDate: Date, endDate: Date, totalDays: number): number => {
  const days = getNumberOfDays(startDate, endDate);
  return (days / totalDays) * 100;
};

/**
 * Get the difference in years, months, days between the dates
 * where endDate > startDate
 * eg: startDate ='2023-06-28T05:47:06.030Z', endDate = '2029-06-28T05:47:06.030Z'
 * Output = 6 years, 0 months, 0 days
 * @param startDate [Date]
 * @param endDate [Date]
 * @returns number
 */
export const getDifferenceInDates = (startDate: Date | string | null, endDate: Date | string | null): string => {
  if (!startDate || !endDate) {
    return '0 months, 0 days';
  }
  const difference = { years: 0, months: 0, days: 0 };
  if (endDate > startDate) {
    const end = moment(endDate);
    const start = moment(startDate);

    difference.years = end.diff(start, 'year');
    start.add(difference.years, 'years');

    difference.months = end.diff(start, 'months');
    start.add(difference.months, 'months');

    difference.days = end.diff(start, 'days');
  }

  const { years, months, days } = difference;
  let result = '';
  if (years > 0) {
    result = `${years} year${years === 1 ? '' : 's'}, `;
  }
  result += `${months} month${months === 1 ? '' : 's'}, ${days} days`;
  return result;
};

// TODO : this function should be deprecated
/**
 * It returns time in Seconds after deducting hr, min, sec and milliseconds seconds from it
 * @param date
 * @returns
 */
// TODO : this function should be deprecated
export const getTimeInSecs = (date?: string | Date | number): number => {
  let result;

  if (date === 'today') {
    result = new Date();
  } else {
    result = new Date(date as string | Date | number);
  }

  return result.getTime() ? result.setHours(0, 0, 0, 0) : 0;
};

/**
 * It takes the date and convert into date string at GMT 0
 *  eg. data = 2023-06-28T20:12:34.000Z; output = 2023-06-28
 * @param [string | null | Date] date
 * @returns Date String
 */
export const convertDateToISO = (date: string | null | Date | undefined): string | null => {
  if (!date) {
    return null;
  }
  return moment(date).toISOString(true).substring(0, 10);
};

export const checkValidDateFormat = (dateValue: string | null): boolean => {
  if (!dateValue) {
    return false;
  }
  const dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  return dateValue.match(dateFormatRegex) !== null;
};

export const getCurrentDateAndTime = (dateCurrent: string | Date | undefined): string => {
  // Add time zone offset
  if (dateCurrent) {
    let formattedDate = new Date(dateCurrent);
    formattedDate = new Date(formattedDate.getTime());
    // With respect to Hong Kong time
    const date = formattedDate.toLocaleDateString('en-GB', {
      timeZone: 'Asia/Hong_Kong',
    });
    const time = formattedDate.toLocaleTimeString('en-GB', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Asia/Hong_Kong',
    });
    return `${date} at ${time}`;
  }
  return '';
};

export const get12HrsTime = (formattedDate: Date): string => {
  return new Date(formattedDate).toLocaleTimeString('en-GB', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Asia/Hong_Kong',
  });
};

export const formattedMonthDate = (date: string | undefined): string => {
  if (date) {
    const formattedDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return formattedDate.toLocaleDateString('en-GB', options);
  }
  return '';
};

export const getDateText = (date: Date | string | null): string => {
  if (date) {
    return moment(date).format('DD/MM/YYYY , HH:mm');
  }

  return ' --- ';
};

export const getDateOfAddedDays = (date: Date | string | null | undefined, numberOfDaysAfter: number): Date | null => {
  if (moment(date).isValid()) {
    const isPositiveDays = numberOfDaysAfter >= 0;

    const method = isPositiveDays ? 'add' : 'subtract';
    const days = isPositiveDays ? numberOfDaysAfter : numberOfDaysAfter * -1;

    return moment(date)[method](days, 'days').toDate();
  }

  return null;
};

export const getMinDateBoundaryForTenancies = (
  minimumDate: Date | string | null | undefined,
  maximumDate: Date | string | null | undefined,
  periodArray: { startDate: Date | string | null | undefined; endDate: Date | string | null | undefined }[],
  currentIndex: number,
  key: 'startDate' | 'endDate',
) => {
  if (key === 'endDate') {
    const { startDate } = periodArray[currentIndex];

    const currentStartDate: Date | null = startDate && moment(startDate).isValid() ? new Date(startDate) : null;

    if (currentStartDate) return currentStartDate;
  }

  for (let i = currentIndex; i >= 1 && i < periodArray.length; i--) {
    const { startDate, endDate } = periodArray[i - 1];

    const prevStartDate: Date | null = startDate && moment(startDate).isValid() ? new Date(startDate) : null;
    const prevEndDate: Date | null = endDate && moment(endDate).isValid() ? new Date(endDate) : null;

    const dayBeforeMinBoundary = prevEndDate || prevStartDate;

    const minBoundary = getDateOfAddedDays(dayBeforeMinBoundary, 1);

    if (minBoundary) return minBoundary;
  }

  return minimumDate;
};

export const getMaxDateBoundaryForTenancies = (
  minimumDate: Date | string | null | undefined,
  maximumDate: Date | string | null | undefined,
  periodArray: { startDate: Date | string | null | undefined; endDate: Date | string | null | undefined }[],
  currentIndex: number,
  key: 'startDate' | 'endDate',
) => {
  if (key === 'startDate') {
    const { endDate } = periodArray[currentIndex];

    const currentEndDate: Date | null = endDate && moment(endDate).isValid() ? new Date(endDate) : null;

    if (currentEndDate) return currentEndDate;
  }

  for (let i = currentIndex; i + 1 < periodArray.length; i++) {
    const { startDate, endDate } = periodArray[i + 1];

    const nextStartDate: Date | null = startDate && moment(startDate).isValid() ? new Date(startDate) : null;
    const nextEndDate: Date | null = endDate && moment(endDate).isValid() ? new Date(endDate) : null;

    const dayAfterMaxBoundary = nextStartDate || nextEndDate;

    const maxBoundary = getDateOfAddedDays(dayAfterMaxBoundary, -1);

    if (maxBoundary) return maxBoundary;
  }

  return maximumDate;
};

export const getCurrentDateISO = (): string => {
  return new Date().toISOString().split('T')[0];
};
