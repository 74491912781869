import { useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { addToDocHistory } from 'store/transactions/transactionDetailSlice';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { DocHistoryProps, HistoryDocumentsProps, UserProps, updateTransaction } from 'common/api/transactions';
import { DROPDOWN_OPTION } from 'utils/UI';
import { getCurrentDateAndTime } from 'utils/utils-date';
import { uploadFile } from 'utils/utils-upload';
import Signature from '../Boxes/Signature';
import './SignedDocumentModal.scss';

const SignedDocumentModal = () => {
  const dispatch = useAppDispatch();
  const {
    activeTransaction: { historyDocuments, tenantId },
    uploadPrepMultiple,
    policyDocs,
    uploadInProgress,
  } = useAppSelector((state: RootState) => state.transactionDetail);
  const { documentTypesOptions } = useAppSelector((state: RootState) => state.miscellaneous);
  const { id: userId, firstName, lastName, photoUrl } = useAppSelector(({ auth }) => auth);

  const [open, setOpen] = useState<boolean>(false);

  const onModalStateUpdate = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const documentTypesOptionsFiltered: DROPDOWN_OPTION[] = documentTypesOptions.filter(obj =>
    policyDocs.includes(obj.value as string),
  );

  const checkIfSignatureState = (documentTypeId: string) => {
    return (
      historyDocuments.filter(
        (historyDocument: HistoryDocumentsProps) => historyDocument.documentTypeId === documentTypeId,
      )[0]?.history.length !== 0
    );
  };

  const date = getCurrentDateAndTime(new Date());

  const SignatureInsideModal = ({ documentTypeId }: { documentTypeId: string }) => {
    const user = {
      id: userId,
      firstName,
      lastName,
      photoUrl,
    };

    const history = historyDocuments.filter((obj: HistoryDocumentsProps) => obj.documentTypeId === documentTypeId)[0]
      ?.history;
    const signed = history?.filter((obj: DocHistoryProps) => obj.status === ValidDocumentStatus.Signed).length !== 0;

    const getDocument = () => {
      const signedHistory = history?.filter((obj: DocHistoryProps) => obj.status === ValidDocumentStatus.Signed);
      if (signedHistory && signedHistory.length !== 0) {
        return signedHistory[0].document;
      }
      return null;
    };

    const document = getDocument();

    return (
      <Signature
        user={user as UserProps}
        date={date}
        document={document}
        documentTypeId={documentTypeId}
        signed={signed}
      />
    );
  };

  const onSave = async () => {
    if (uploadPrepMultiple !== null && uploadInProgress.length === 0) {
      for (const [key, value] of Object.entries(uploadPrepMultiple)) {
        const doc = await uploadFile(value.uploadPrep, value.fileDetails, dispatch);
        dispatch(
          addToDocHistory({
            userId,
            tenantId,
            pendingChanges: false,
            note: null,
            signed: true,
            documents: [key.split('sign-')[1]],
            doc,
          }),
        );
      }

      dispatch(updateTransaction({ approval: true }));
    }
  };

  return (
    <ModalTemplate
      isOpen={open}
      onToggleModalStatus={onModalStateUpdate}
      className="approval signed-document-modal"
      title="Upload signed document(s)"
      showHeader={true}
      trigger={<Button className="btn grey-bg signed-document-btn">Upload signed document(s)</Button>}
      exitBtnTitle="Save & Exit"
      onModalClose={onSave}
    >
      <Form>
        <Grid className="pm-none">
          <Grid.Row className="p-none">
            <Grid.Column
              width={16}
              className="p-l-none"
            >
              <div className="doc-boxes move-box-down history-tab m-t-xs">
                <div className="docs-box">
                  {documentTypesOptionsFiltered.map(
                    (obj: DROPDOWN_OPTION) =>
                      checkIfSignatureState(obj.value as string) && (
                        <SignatureInsideModal documentTypeId={obj.value as string} />
                      ),
                  )}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </ModalTemplate>
  );
};

export default SignedDocumentModal;
