/* eslint-disable jsx-a11y/label-has-associated-control */
import { IFunctionInputClone } from 'common/_classes';
import { Button, Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import DropdownSelect from 'atoms/DropdownSelect';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { addFormatterInput, deleteFormatterInput, updateFormatterInput } from 'store/formatters/formatterDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { ANSWER_TYPE_OPTIONS } from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import { removeSpaceOrComma } from 'utils/utils-number';
import './FormatterInputsList.scss';

const FormatterInputsList = ({ mode }: ModeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeFormatter } = useAppSelector((state: RootState) => state.formatterDetail);
  const { inputs } = activeFormatter;
  const disabled: boolean = mode === MODE_OPTIONS.READ;

  return (
    <Form.Field className="field-style formatters-inputs-list">
      {inputs.map((input: IFunctionInputClone, index: number) => (
        <div
          key={index}
          className="multi-choice"
        >
          <p>
            {index + 1}. <span className="required-indicator-choices">*</span>
          </p>
          <DropdownSelect
            fieldKey="type"
            disabled={disabled}
            dataTest="input-row-answer-type"
            placeholder="Select type..."
            value={input.type as string}
            options={ANSWER_TYPE_OPTIONS}
            // @ts-ignore
            onChange={(_key: string, value: string) =>
              dispatch(
                updateFormatterInput({
                  key: 'type',
                  value: value,
                  index,
                }),
              )
            }
          />
          <InputField
            fieldKey="label"
            className="m-l-ml"
            type={InputFieldType.TEXT}
            placeholder="Enter label..."
            dataTest="input-row-label"
            value={input.label}
            disabled={disabled}
            onChange={(_key, value) =>
              dispatch(
                updateFormatterInput({
                  key: 'label',
                  value: removeSpaceOrComma(value), // TODO, do not authorize special characters, should define a format
                  index,
                }),
              )
            }
          />
          {!disabled && inputs.length !== 1 && (
            <Button
              className="delete-btn bg-gray-very-light-v4 p-xs height-2_857 width-2_857 m-t-xxs m-l-m"
              data-test="delete-input-row-button"
              onClick={e => e.detail === 1 && dispatch(deleteFormatterInput({ index }))}
            >
              <Icon
                className="color-red-soft height-1_143 width-1_143"
                icon={Icons.Delete}
              />
            </Button>
          )}
        </div>
      ))}
      {!disabled && (
        <span
          className="add-choice color-blue-very-dark-grayish"
          data-test="add-input-button"
          onClick={() => dispatch(addFormatterInput())}
          role="button"
          tabIndex={0}
        >
          <Icon
            className="height-1_286 width-1_286 color-orange m-r-xxs"
            icon={Icons.CirclePlusFill}
          />
          Add input
        </span>
      )}
    </Form.Field>
  );
};

export default FormatterInputsList;
