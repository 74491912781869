import { EntityPickName, ProvisionContentClone, ProvisionContentCloneExtend } from 'common/_classes';
import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import _ from 'lodash';
import ConfirmPopup from 'atoms/ConfirmPopup';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import SpecialCheckBox from 'atoms/FormField/SpecialCheckBox';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateActiveProvision } from 'store/provisions/provisionDetailSlice';
import { listCountries, listLanguages } from 'common/api/miscellaneous';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { listLandlordEntities } from 'common/api/provisions';
import { DROPDOWN_OPTION, USE_TYPE_OPTIONS } from 'utils/UI';
import { sortContents } from 'utils/tsHelper';
import './ProvisionGeneralForm.scss';

const ProvisionGeneralForm = ({ mode }: ModeProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { countries, languages } = useAppSelector((state: RootState) => state.miscellaneous);
  const { activeProvision } = useAppSelector((state: RootState) => state.provisionDetail);
  const { categoriesOptions, owners } = useAppSelector((state: RootState) => state.provisionsListing);
  const { documentTypesList, documentTypesOptions } = useAppSelector((state: RootState) => state.miscellaneous);

  const {
    index,
    name,
    contents,
    isStructure,
    restartNumbering,
    description,
    summary,
    provisionCategory,
    entity,
    language,
    jurisdiction,
    useType,
  } = activeProvision;

  const checkDetailsPage: boolean = window.location.pathname.includes('details');

  // applicableDocIds keep the list of applicable documents that are selected.
  // It is used to transfer the list to the popup for removal popup validation.
  const [applicableDocIds, setApplicableDocIds] = useState<string[]>([]);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(listCountries());
    }
    if (!owners.length) {
      dispatch(listLandlordEntities());
    }
    if (!languages.length) {
      dispatch(listLanguages());
    }
  }, [dispatch, countries.length]);

  const onChange = (key: string, value: any) => {
    if (checkDetailsPage && key === 'contents') {
      // If the list of contents length is different than value length, this means
      // the user is modifying the list of applicable documents by adding or removing one.
      // note:
      //  - flag is false when user is adding an applicable documents from the list
      //  - flag is true when user is removing an applicable documents from the list
      //      -> it triggers opening of a popup that requires a double validation of the deletion.
      if (contents.length !== value.length) {
        //list the applicable documents that are removed
        const removedIds: string[] = contents
          .map((content: ProvisionContentClone) => content.documentTypeId)
          .filter(element => !value.includes(element));
        const removingFlag: boolean = !_.isEmpty(removedIds);

        if (removingFlag) {
          setApplicableDocIds(value);
          setPopupOpen(true);
        } else {
          dispatch(updateActiveProvision({ key: 'contents', value }));
        }
      }
    } else if (key === 'entityId') {
      dispatch(updateActiveProvision({ key: 'entity', value: { id: value } }));
    } else {
      dispatch(updateActiveProvision({ key, value }));
    }
  };

  const sortedContent: ProvisionContentCloneExtend[] = sortContents(contents, documentTypesList);

  const onDeleteDoc = () => {
    dispatch(updateActiveProvision({ key: 'contents', value: applicableDocIds }));
    setPopupOpen(false);
  };

  const ownersOptions: DROPDOWN_OPTION[] = owners.map((item: EntityPickName, index: number) => {
    const result: DROPDOWN_OPTION = {
      key: index,
      text: item.name,
      value: item.id,
    };
    return result;
  });

  // Sericin is the owner of all the base provisions (by default)
  const sericinEntity: EntityPickName = owners.find(
    (owner: EntityPickName) => owner.name.trim().toLowerCase() === 'sericin',
  )!;
  const defaultOwnerId: string | null =
    !(!!activeProvision?.entity && !!activeProvision?.entity.id) && !!sericinEntity ? sericinEntity.id : null;

  const languagesOptions: DROPDOWN_OPTION[] = languages.map((language: string, index: number) => {
    const result: DROPDOWN_OPTION = {
      key: index,
      text: language,
      value: language,
    };
    return result;
  });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Name"
            value={name}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="name"
            dataTest="provision-name"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <SelectField
            label="Applicable documents"
            value={sortedContent.map(sorted => sorted && sorted.documentTypeId)}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            placeholder="Select..."
            multiple={true}
            options={documentTypesOptions}
            onChange={onChange}
            fieldKey="contents"
            dataTest="provision-applicable-docs"
          />
          <ConfirmPopup
            trigger={popupOpen}
            content="Do you really want to delete the document? All your content will be lost"
            confirmButtonText="DELETE"
            onConfirm={onDeleteDoc}
            cancelButtonText="CANCEL"
            onCancel={() => setPopupOpen(false)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={8}
          className="pm-none structure-provision"
        >
          <SpecialCheckBox
            label="Structure provision"
            mode={mode}
            required={mode === MODE_OPTIONS.EDIT}
            value={isStructure}
            onChange={onChange}
            info={true}
            fieldKey="isStructure"
            popUp={true}
            infoText={`A structure provision is an empty provision, which houses headings and subheadings, such as Index, Summary, Annex, Schedule etc. In this we the system can structure in documents, while ensuring that the numbering of sections, schedules etc. is done properly.`}
            dataTest="structure-checkbox"
          />
        </Grid.Column>
        <Grid.Column
          width={8}
          className="pm-none structure-provision"
        >
          {/* TODO: Need to add info text */}
          <SpecialCheckBox
            label="Restart numbering"
            mode={mode}
            required={mode === MODE_OPTIONS.EDIT}
            value={restartNumbering}
            onChange={onChange}
            info={true}
            fieldKey="restartNumbering"
            dataTest="renumbering-checkbox"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <TextAreaField
            label="Description"
            value={description}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="description"
            dataTest="provision-description"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <TextAreaField
            label="Description summary"
            value={summary}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="summary"
            dataTest="provision-summary"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <SelectField
            label="Category"
            value={provisionCategory?.id || null}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select..."
            search={true}
            options={categoriesOptions}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="provisionCategory"
            dataTest="provision-category"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <SelectField
            label="Jurisdiction"
            value={jurisdiction}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select..."
            search={true}
            onChange={onChange}
            fieldKey="jurisdiction"
            dataTest="provision-jurisdiction"
            options={countries}
            clearable={true}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Index"
            value={!!index ? String(index) : null}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Create index"
            onChange={onChange}
            fieldKey="index"
            dataTest="provision-index"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <SelectField
            label="Language"
            value={language}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select..."
            search={true}
            onChange={onChange}
            fieldKey="language"
            dataTest="provision-language"
            options={languagesOptions}
            clearable={true}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <SelectField
            label="Owner"
            value={entity?.id || defaultOwnerId}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select..."
            search={true}
            onChange={onChange}
            fieldKey="entityId"
            dataTest="provision-owner"
            options={ownersOptions}
            clearable={true}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <SelectField
            label="Use Type"
            value={useType}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select..."
            search={true}
            onChange={onChange}
            fieldKey="useType"
            dataTest="use-type"
            options={USE_TYPE_OPTIONS}
            clearable={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ProvisionGeneralForm;
