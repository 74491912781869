import { useState } from 'react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import ConfirmPopup, { TEMPLATE_TYPES, TemplateData } from 'atoms/ConfirmPopup';
import DraggableList from 'atoms/DraggableList';
import { InputFieldType } from 'atoms/FormField/Input';
import { updateSequenceOrder } from 'store/policies/policyDetailSlice';
import { updatePolicySequences } from 'common/api/policies';
import { SequenceClone, SequenceProps } from 'common/api/policies';
import { ColorNames, Colors } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import './SequenceList.scss';

interface SequenceListProps {
  sequence: SequenceProps;
  sequenceIndex: number;
}

const SequenceList = ({ sequence, sequenceIndex }: SequenceListProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [currentPos, setCurrentPos] = useState<number>(0);
  const [newPos, setNewPos] = useState<number>(0);

  const onPositionChange = (currentPos: number, newPos: number): void => {
    dispatch(updateSequenceOrder({ currentPos, newPos, sequenceIndex }));
    dispatch(updatePolicySequences());
  };

  // TODO: currentPosition is still defined as a string (due to the API), even if it is declared as a number here!
  //       this needs to be fixed once the order coming from back-end is really a number and not a string anymore.
  const openModel = (currentPosition: number): void => {
    setCurrentPos(typeof currentPosition === 'string' ? parseInt(currentPosition) : currentPosition);
    setModalOpen(true);
  };

  const onSequenceApply = (): void => {
    setModalOpen(false);
    onPositionChange(currentPos, newPos);
    setCurrentPos(0);
    setNewPos(0);
  };

  const modalTemplate: TemplateData[] = [
    {
      templateType: TEMPLATE_TYPES.INPUT,
      onChange: (key: string, value: string) => {
        const num = value ? parseInt(value) : 0; // TODO: value should be a number too.
        setNewPos(num);
      },
      value: newPos.toString(),
      fieldKey: '',
      label: '',
      required: true,
      type: InputFieldType.NUMBER,
      dataTest: 'new-sequence-position',
    },
  ];

  const rowSequenceTemplate = (sequence: SequenceClone): JSX.Element => {
    return (
      <div
        key={sequence.provision?.id}
        className="flex-item sequence-item"
        data-test="provisions"
        style={{
          backgroundColor: sequence.provision?.provisionCategory.color,
        }}
      >
        <span
          className="item-naming"
          data-test="provision-name"
        >
          {`${sequence.order} . `}
          {sequence.provision?.name}
        </span>
        <div
          className="provision-icons"
          data-test="provision-icons"
        >
          {sequence.selectedByDefault && (
            <Icon
              className="check-icon"
              icon={Icons.TickCircleSolid}
              color={Colors.GREEN}
              width="2rem"
              height="1.5rem"
              data-test="default-icon"
            />
          )}
          {sequence.mandatory && (
            <div
              className="circle-l mandatory-icon"
              data-test="mandatory-icon"
            >
              <span>M</span>
            </div>
          )}
          <Icon
            onClick={() => openModel(sequence.order)}
            className="drag-icon"
            icon={Icons.Drag}
            data-test="drag-icon"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="document-body">
      {sequence && (
        <DraggableList<SequenceClone>
          onPositionChange={onPositionChange}
          data={sequence.elements}
          rowTemplate={rowSequenceTemplate}
        />
      )}

      <ConfirmPopup
        color={ColorNames.GREEN}
        trigger={isModalOpen}
        content="Enter Position"
        confirmButtonText="APPLY"
        onConfirm={onSequenceApply}
        cancelButtonText="CLOSE"
        onCancel={() => setModalOpen(false)}
        templateData={modalTemplate}
      />
    </div>
  );
};

export default SequenceList;
