enum HK_District {
	ABERDEEN = 'Aberdeen',
	AP_LEI_CHAU = 'Ap Lei Chau',
	CENTRAL = 'Central',
	CAUSEWAY_BAY = 'Causeway Bay',
	CHAI_WAN = 'Chai Wan',
	CHEUNG_CHAU = 'Cheung Chau',
	CHEUNG_SHA_WAN = 'Cheung Sha Wan',
	DIAMOND_HILL = 'Diamond Hill',
	DISCOVERY_BAY = 'Discovery Bay',
	FANLING = 'Fanling',
	HAPPY_VALLEY = 'Happy Valley',
	HO_MAN_TIN = 'Ho Man Tin',
	HUNG_HOM = 'Hung Hom',
	KENNEDY_TOWN = 'Kennedy Town',
	KOWLOON_TONG = 'Kowloon Tong',
	KWAI_CHUNG = 'Kwai Chung',
	KWUN_TONG = 'Kwun Tong',
	LAI_CHI_KOK = 'Lai Chi Kok',
	LAM_TIN = 'Lam Tin',
	MA_ON_SHAN = 'Ma On Shan',
	MA_WAN = 'Ma Wan',
	MONG_KOK = 'Mong Kok',
	NGAU_TAU_KOK = 'Ngau Tau Kok',
	NORTH_POINT = 'North Point',
	SAI_KUNG = 'Sai Kung',
	SAI_YING_PUN = 'Sai Ying Pun',
	SHA_TIN = 'Sha Tin',
	SHAU_KEI_WAN = 'Shau Kei Wan',
	SHEUNG_SHUI = 'Sheung Shui',
	SHEUNG_WAN = 'Sheung Wan',
	SIU_LAM = 'Siu Lam',
	STANLEY = 'Stanley',
	TAI_PO = 'Tai Po',
	TAI_WO = 'Tai Wo',
	TSEUNG_KWAN_O = 'Tseung Kwan O',
	TSIM_SHA_TSUI = 'Tsim Sha Tsui',
	TSUEN_WAN = 'Tsuen Wan',
	TSZ_WAN_SHAN = 'Tsz Wan Shan',
	TUNG_CHUNG = 'Tung Chung',
	TUEN_MUN = 'Tuen Mun',
	TSING_YI = 'Tsing Yi',
	WAN_CHAI = 'Wan Chai',
	WONG_CHUK_HANG = 'Wong Chuk Hang',
	WONG_TAI_SIN = 'Wong Tai Sin',
	YAU_MA_TEI = 'Yau Ma Tei',
	YUEN_LONG = 'Yuen Long'
}

export default HK_District;