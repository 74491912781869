import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import PremisesForm from 'components/PremisesForm';
import { PremiseSelectedProps } from 'components/PremisesForm/utils-premiseForm';
import { updateTransactionPremises } from 'store/transactions/transactionDetailSlice';
import { ModeProps } from 'common/api/miscellaneous';
import { getAutomatedTransactionIdentifier } from 'common/api/transactions';

const Premises = ({ mode }: ModeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const {
    propertiesList, //TODO should not come from the transactionDetail page, but from a 'generalStore' as it is used in contract and benchmark too
    activeTransaction: { premises, ownerId, tenantId },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const propertyId = !!premises[0]?.property?.id ? premises[0].property.id : null;

  const onChange = (premises: PremiseSelectedProps[]): void => {
    const update = () => dispatch(updateTransactionPremises({ premises }));

    // Check if property id has been modified -> automatically recalculate the transaction identifier
    const oldPropertyId = propertyId;
    const newPropertyId = premises[0]?.property?.id;

    if ((!oldPropertyId || oldPropertyId !== newPropertyId) && newPropertyId && tenantId) {
      dispatch(getAutomatedTransactionIdentifier({ tenantId, propertyId: newPropertyId })).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          update();
        }
      });
    } else {
      update();
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          width={16}
          className="p-l-s"
        >
          <PremisesForm
            mode={mode}
            landlordId={ownerId}
            properties={propertiesList}
            value={premises}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default Premises;
