import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { Passwords } from './resetPassword';

export interface ChangePasswordQueryVariables {
  currentPassword: string;
  params: Passwords;
}

export const CHANGE_PASSWORD = (oldPassword: string, newPassword: string, confirmNewPassword: string) => {
  const variables: ChangePasswordQueryVariables = {
    currentPassword: oldPassword,
    params: {
      password: newPassword,
      passwordConfirmation: confirmNewPassword,
    },
  };

  return {
    mutation: gql`
      mutation ($currentPassword: String!, $params: PasswordParams!) {
        changePassword(currentPassword: $currentPassword, params: $params) {
          token
        }
      }
    `,
    variables,
  };
};

export const changePassword = createAsyncThunk('auth/changePassword', async (_arg, { getState }) => {
  const {
    auth: { newPassword, newPasswordConfirmation, password },
  } = getState() as RootState;
  const response = await client.mutate(CHANGE_PASSWORD(password, newPassword, newPasswordConfirmation));
  return response;
});
