import Card, { CardConfiguration } from './Card';
import CardBody from './CardBody';
import CardColumn, { ColumnConfiguration } from './CardColumn';
import CardColumnHeader from './CardColumnHeader';
import CardComponent from './CardComponent';
import CardHeader from './CardHeader';
import { DefaultCardT, DefaultColumnT } from './CardViewDefaultTypes';

export { Card, CardBody, CardColumn, CardColumnHeader, CardComponent, CardHeader };

export interface CardTemplateConfiguration<ColumnT, CardT> {
  columnConfiguration: ColumnConfiguration<ColumnT>;
  cardConfiguration: CardConfiguration<CardT>;
}

export interface CardViewTemplateProps<ColumnT, CardT> {
  categoryList: Array<DefaultColumnT & ColumnT>;
  configuration: CardTemplateConfiguration<ColumnT, CardT>;
  getCardElementsList: (columnCategory: DefaultColumnT & ColumnT) => Array<DefaultCardT & CardT>;
}

function CardViewTemplate<ColumnT, CardT>({
  categoryList,
  configuration,
  getCardElementsList,
}: CardViewTemplateProps<ColumnT, CardT>) {
  /**
   * Get Card Template
   *  - Filter the cardElements that belongs to that column
   *  - Map to the associated HTML Card modules
   * @param columnCategory
   * @returns
   */
  const getCards = (columnCategory: DefaultColumnT & ColumnT) => {
    //1. list the cards to display : only those of same category-id
    const elementsList = getCardElementsList(columnCategory);
    //2. return the cards that are listed
    return elementsList.map((cardElement: CardT & DefaultCardT) => {
      return (
        <Card<ColumnT, CardT>
          key={cardElement.id}
          cardElement={cardElement}
          columnCategory={columnCategory}
          cardConfiguration={configuration.cardConfiguration}
        />
      );
    });
  };

  const cardColumns = categoryList.map(category => {
    return (
      <CardColumn<ColumnT>
        key={category.id}
        category={category}
        columnConfiguration={configuration.columnConfiguration}
      >
        {getCards(category)}
      </CardColumn>
    );
  });

  return <CardComponent>{cardColumns}</CardComponent>;
}

export default CardViewTemplate;
