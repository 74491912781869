import React, { ReactNode } from 'react';
import './Badge.scss';

export enum BadgeColor {
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  RED = 'red',
  GRAY = 'gray',
  LIGHT_GREY = 'light-gray',
  PURPLE = 'purple',
  DARK_ORANGE = 'dark-orange',
  YELLOW = 'yellow',
}

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  badgeColor?: BadgeColor;
  dataTest?: string;
}

/**
 * This is a little card. Commonly used in Card Header.
 *
 * This receives a badgeType for color setting and children content;
 *
 * @param props.badgeColor badgeColor A key to set a background-color and font-color
 * @param props.children JSX | string Content to be showing in the center of Badge
 */
const Badge = (props: BadgeProps) => {
  const { badgeColor, children, className = '', ...rest } = props;

  return (
    <div
      {...rest}
      className={`badge-container ${badgeColor} ${className}`}
      data-test={props.dataTest}
    >
      {children}
    </div>
  );
};

export default Badge;
