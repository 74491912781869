import Decimal from 'decimal.js';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import { PAID_BY_OPTIONS } from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';

interface GovernmentRatesFormProps extends ModeProps, ViewProps {
  index: number;
}
const GovernmentRatesForm = ({ mode, index, isViewPage }: GovernmentRatesFormProps) => {
  const dispatch = useAppDispatch();

  const { activeContract, activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const governmentRates = activeContract?.governmentRates || [];

  const { endDate, startDate, rate, paidBy } = governmentRates[index];

  const onChange = (key: string, value: string) => {
    dispatch(
      updateActiveContractInput({
        key: ['governmentRates', `${index}`, key],
        value: value || null,
      }),
    );
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractInput({
        key: ['governmentRates', `${index}`, key],
        value: value ? new Decimal(value) : null,
      }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ || isViewPage;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div className="grid m-t-s pm-none">
      <div className="col-2">
        <DateField
          minDate={getMinDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            governmentRates,
            index,
            'startDate',
          )}
          maxDate={getMaxDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            governmentRates,
            index,
            'startDate',
          )}
          label="Start date"
          fieldKey="startDate"
          dataTest=""
          value={startDate}
          disabled={disabled}
          required={required}
          onChange={onChange}
        />
      </div>
      <div className="col-2">
        <DateField
          minDate={getMinDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            governmentRates,
            index,
            'endDate',
          )}
          maxDate={getMaxDateBoundaryForTenancies(
            activeContractEvent?.startDate,
            activeContractEvent?.endDate,
            governmentRates,
            index,
            'endDate',
          )}
          label="End date"
          fieldKey="endDate"
          dataTest=""
          value={endDate}
          disabled={disabled}
          required={required}
          onChange={onChange}
        />
      </div>
      <div className="col-2">
        <SelectField
          label="Payable by landlord or tenant"
          fieldKey="paidBy"
          dataTest=""
          value={paidBy}
          disabled={disabled}
          required={required}
          options={PAID_BY_OPTIONS}
          onChange={onChange}
        />
      </div>
      <div className="col-2">
        <InputField
          label="Govt. rates (psf pcm)"
          dataTest=""
          type={InputFieldType.NUMBER}
          unit="(HK$/sf/mo)"
          fieldKey="rate"
          value={rate?.toString() || null}
          disabled={disabled}
          required={required}
          onChange={onChangeDecimal}
          isDecimal={true}
          delimiter="COMMA"
        />
      </div>
    </div>
  );
};

export default GovernmentRatesForm;
