import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import UnderConstructionContent from 'atoms/UnderConstruction';
import EntityInformationTab from 'views/entities/components/Tabs/EntityInformationTab';
import EntitiesPropertiesTab from 'views/entities/components/Tabs/Properties';
import { updateViewDetailsTab } from 'store/entities/entityDetailSlice';
import { EntityCreatorTypes } from 'common/api/entities';

export enum LANDLORD_ENTITY_DETAILS_TABS_OFFSET {
  ENTITY_INFORMATION = 0,
  PROPERTIES = 1,
  TRANSACTIONS = 2,
  CONTRACTS = 3,
}

const getTabMenuConfig = (): TabMenuProps[] => {
  const tabs = [
    {
      key: '1',
      href: '?tab=information',
      label: 'Entity Information',
      customTab: (
        <EntityInformationTab
          organisation={false}
          type={EntityCreatorTypes.Landlord}
        />
      ),
    },
    {
      key: '2',
      href: '?tab=properties',
      label: 'Properties',
      customTab: <EntitiesPropertiesTab />,
    },
    {
      key: '3',
      href: '?tab=transactions',
      label: 'Transactions',
      customTab: <UnderConstructionContent showBackButton={false} />,
    },
    {
      key: '4',
      href: '?tab=contracts',
      label: 'Contracts',
      customTab: <UnderConstructionContent showBackButton={false} />,
    },
  ];

  return tabs;
};

const LandlordEntityTabs = () => {
  const { activeTabIndex } = useAppSelector((state: RootState) => state.entityDetail);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateViewDetailsTab}
      tabMenu={getTabMenuConfig()}
    />
  );
};

export default LandlordEntityTabs;
