import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import GuidelinesDisplayOnly from 'components/GuidelinesDisplayOnly';
import ModalTemplate from 'components/ModalTemplate';
import { updateProvisionModalState } from 'store/provisions/provisionsListingSlice';
import { getDocumentName } from 'utils/tsHelper';
import { getCurrentDateAndTime } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import './ProvisionInfoModal.scss';

const ProvisionInfoModal = () => {
  const dispatch = useAppDispatch();
  const { provisionModalState, activeModalProvision } = useAppSelector((state: RootState) => state.provisionsListing);
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);

  // @ts-ignore
  const { name, provisionCategory, description, contents, guideline } = activeModalProvision;

  const getDocumentArticulations = (): string => {
    const result: string = (contents as any[]) //TODO type to be defined
      .map(content => getDocumentName(content.documentTypeId, documentTypesList))
      .join(', ');
    return result;
  };

  return (
    <ModalTemplate
      isOpen={provisionModalState}
      onToggleModalStatus={(isOpen: boolean) => dispatch(updateProvisionModalState({ open: isOpen }))}
      title={name}
      className="provision-info-modal"
    >
      <Grid>
        <h2>Information</h2>
        <Grid.Row>
          <Grid.Column width={4}>
            <h2 className="left-header">Category</h2>
          </Grid.Column>
          <Grid.Column width={12}>
            <h2 className="right-text">{provisionCategory.name}</h2>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <h2 className="left-header">Provision Articulations</h2>
          </Grid.Column>
          <Grid.Column width={12}>
            <h2 className="right-text">{getDocumentArticulations()}</h2>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <h2 className="left-header">Description</h2>
          </Grid.Column>
          <Grid.Column width={12}>
            <h2 className="right-text">{description}</h2>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={4}>
            <h2 className="left-header">Version Number</h2>
          </Grid.Column>
          <Grid.Column width={12}>
            <h2 className="right-text">2.0</h2>
            <div className="vertical-line"></div>
            <div className="history-btn bg-gray-very-light-v4 color-blue-desaturated-dark p-l-sm p-r-ml">
              <Icon icon={Icons.Eye} />
              <span className="text">SEE HISTORY</span>
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="date-of-version">
          <Grid.Column width={4}>
            <h2 className="left-header">Date of Version</h2>
          </Grid.Column>
          <Grid.Column width={12}>
            <h2 className="right-text">
              {/* @ts-ignore */}
              {getCurrentDateAndTime(activeModalProvision?.updatedAt)}
            </h2>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <GuidelinesDisplayOnly
        className="m-t-m m-b-m"
        generalGuidance={guideline?.generalGuidance}
        valuationGuidance={guideline?.valuationGuidance}
      />
    </ModalTemplate>
  );
};

export default ProvisionInfoModal;
