import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParameterCategoryBase } from 'common/_classes';
import { getClientType } from 'utils/utils-authentication';

export const LIST_PARAMETER_CATEGORIES = () => {
  return {
    query: gql`
      query {
        listParameterCategories {
          ${ParameterCategoryBase.fragments()}
        }
      }
    `,
  };
};

export const listParameterCategories = createAsyncThunk('parameters/listParameterCategories', async () => {
  const response = await getClientType().query(LIST_PARAMETER_CATEGORIES());
  return response;
});
