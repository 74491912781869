import { ReactComponent as Logo } from 'assets/images/svg/welcome-block.svg';

const RightImage = () => {
  return (
    <div>
      <svg>
        <Logo
          width="100%"
          height="100%"
        />
      </svg>
    </div>
  );
};

export default RightImage;
