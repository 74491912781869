/*
 * Definition of the column (names, format...)
 */
// import { BadgeColor } from 'atoms/Badge';
// import { StatusBadgeArgument } from 'atoms/TableReactPrime/columnTemplates';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import Development from 'common/model/Development';
import OwnershipType from 'common/model/OwnershipType';
import { checkIfAdminURL } from 'utils/utils-admin';
import { DevelopmentRowActionButtons } from './DevelopmentsRowActionButtons';

// const statuses: StatusBadgeArgument[] = [
//   { value: 'REVIEWED', color: BadgeColor.GREEN },
//   { value: 'TO BE REVIEWED', color: BadgeColor.RED },
// ];

type Building = {
  id: string;
  name: string;
};

type Owner = {
  id: string;
  name: string;
  percentage?: string;
};

export class PropertiesRow {
  id: string;
  developmentName: string;
  address: string;
  addressDistrict: string;
  area: string;
  developmentIdentifier: string;
  developmentLotNumber: string;
  ownershipType: OwnershipType;
  updatedAt: Date;
  insertedAt: Date;
  buildings?: Building[];
  owners?: Owner[];

  constructor(development: Development & { owners: Owner[]; buildings?: Building[] }) {
    this.id = development.id;
    this.developmentName = development.developmentName;
    this.buildings = development.buildings;
    this.area = development.area;
    this.owners = development.owners;
    this.address = development.address;
    this.addressDistrict = development.addressDistrict;
    this.developmentIdentifier = development.developmentIdentifier;
    this.developmentLotNumber = development.developmentLotNumber;
    this.ownershipType = development.ownershipType;
    this.updatedAt = new Date(development.updatedAt);
    this.insertedAt = new Date(development.insertedAt);
  }
}

export const developmentColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  const isAdmin = checkIfAdminURL();

  const columns: CustomColumnProps[] = [
    {
      field: 'developmentName',
      header: 'Development Name',
      sortable: true,
      sortField: 'developmentName',
      body: (row: PropertiesRow) => row.developmentName,
    },
    {
      field: 'buildings',
      header: 'Owned Buildings',
      sortable: true,
      sortField: 'buildings',

      body: (row: PropertiesRow) => (
        <div>
          {row.buildings?.map((building: Building) => (
            <div
              key={building.id}
              className="m-b-xs"
            >
              {building.name}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'area',
      header: 'Floor Area',
      body: (row: PropertiesRow) => row.area,
    },
    {
      field: 'owners',
      header: 'Owner(s)',
      sortable: true,
      sortField: 'owners',
      body: (row: PropertiesRow) => (
        <div>
          {row.owners?.map((owner: Owner) => (
            <div
              key={owner.id}
              className="m-b-xs"
            >
              {owner.name}
            </div>
          ))}
        </div>
      ),
    },
  ];

  if (isAdmin) {
    columns.splice(3, 0, {
      field: 'landlordName',
      header: 'Landlord name',
      filter: true,
      filterPlaceholder: 'Search by Landlord name',
      filterField: 'landlordName',
      sortable: true,
    });
  } else {
    columns.push({
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: PropertiesRow) => (
        <DevelopmentRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    });
  }

  return columns;
};
