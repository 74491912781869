import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import TabContainer from 'components/TabContainer';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { listRoles } from 'common/api/roles';
import RolesHeaderToolbar from './RolesHeaderToolbar';
import { RolesRow, rolesColumnConfig } from './RolesTableConfig';
import './RolesTable.scss';

const TeamRolesTab = () => {
  const dispatch = useAppDispatch();
  const { rolesList, isLoading } = useAppSelector((state: RootState) => state.roles);

  const currentList: RolesRow[] = rolesList.map(role => new RolesRow(role));

  const getRoles = (params: PaginationAPIProps) => dispatch(listRoles(params));

  return (
    <TabContainer
      tabTitle={'Roles & Access Rights'}
      tabToolbar={<RolesHeaderToolbar />}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          getRoles(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={rolesColumnConfig()}
      />
    </TabContainer>
  );
};

export default TeamRolesTab;
