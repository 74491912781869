import { Editor } from 'tinymce';

/**
 * This function is part of Formatting HTML doing:
 * * Remove the HTML commentaries from Editor
 * * Remove the span with lang and other attributes
 * * Replace the p tag with attributes for a new p tag without attributes
 */
export const cleanerFormattingHTML = (editor: Editor) => {
  console.warn('[EDITOR] Removing undesired element tags');

  // MICROSOFT COPY and PASTE - Removing comment inside of HTML
  // Select all elements from Editor
  const allHtmlTags = editor.dom.select('*');
  // With all elements, do a Loop to check if the content has HTML commentary
  Array.from(allHtmlTags).map(element => {
    // Check if it was the start of HTML commentary
    if (element.innerHTML.indexOf('<!--') >= 0) {
      // Do a regex to replace the comment to an empty string
      const regex = /<!--(.|\n)*?-->/g;
      element.innerHTML = element.innerHTML.replace(regex, '');
    }
  });

  // get all span with lang attr - Select for MICROSOFT COPY and PASTE
  const spanContent = editor.dom.select('span[lang]');
  // start the loop
  Array.from(spanContent).map(element => {
    // then copy the inner content of span and replace the entire span.
    // example: this <span lang><p>parag..</p></span> became this <p>parag..</p>
    element.outerHTML = element.innerHTML;
  });

  // paragraph tag - Select for MICROSOFT COPY and PASTE
  const pContent = editor.dom.select('p[class]');
  // with all p tag with some class, start a loop
  Array.from(pContent).map(element => {
    // then it will remove the old p tag and place a new clean p tag
    element.outerHTML = '<p>' + element.innerHTML + '</p>';
  });
};
