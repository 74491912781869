import DropdownSelect from 'atoms/DropdownSelect';
import InputField from 'atoms/FormField/Input';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import {
  ComparablesTableFilterDropdownOptions,
  FilterMode,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
} from '../../RowProps';

export const STRING_FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Is same as', value: FilterMode.EQUALS },
  { key: 2, text: 'Contains', value: FilterMode.CONTAINS },
  { key: 3, text: 'Start With', value: FilterMode.STARTS_WITH },
];

export function applyStringFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyStringFilter: field key is missing');

  let value: string = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  if (!rowAction.filterValue) return true;

  value = value.toLowerCase().trim();

  switch (rowAction.filterMode) {
    case FilterMode.EQUALS:
      return value == (rowAction.filterValue.toLowerCase().trim() as string);
    case FilterMode.STARTS_WITH:
      return value.startsWith(rowAction.filterValue.toLowerCase().trim() as string);
    case FilterMode.CONTAINS:
      return value.includes(rowAction.filterValue.toLowerCase().trim() as string);
  }
  return true;
}

const StringFilter = ({ filterMode, label, filterValue, onChange }: RowFilterChildProps): JSX.Element => {
  return (
    <div className="grid">
      <div className="col-6">
        <DropdownSelect
          placeholder="Select..."
          fieldKey={FilterOnchangeKeys.filterMode}
          value={filterMode}
          options={STRING_FILTER_OPTIONS}
          onChange={onChange}
        />
      </div>
      <div className="col-6">
        <InputField
          value={filterValue}
          placeholder={'Search By ' + label}
          onChange={onChange}
          fieldKey={FilterOnchangeKeys.filterValue}
        />
      </div>
    </div>
  );
};

export default StringFilter;
