import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import UseType from 'common/model/UseType';
import { AnswerProps, PolicyProps, SequenceProps } from 'common/api/policies';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface UpdatePolicyProps extends Omit<PolicyProps, 'useType'> {
  answers?: AnswerProps[];
  sequences?: SequenceProps[];
  useType?: UseType | string | null; // Explicitly allowing `null` here also TODO SHOULD not be string anymore
}

interface UpdatePolicyQueryVariables {
  id: string;
  params: UpdatePolicyProps;
}

export const UPDATE_POLICY = (activePolicy: PolicyProps) => {
  const { id, name, description, documentTypeIds, regulatories, jurisdiction, language } = activePolicy;
  const useType = activePolicy?.useType || null;

  const params: UpdatePolicyProps = {
    name,
    description,
    documentTypeIds,
    entityId: getCurrentLandlordEntityId(),
    regulatories,
    jurisdiction,
    useType,
    language,
  };

  if (!id) throw new Error('UPDATE_POLICY: id is required');

  const variables: UpdatePolicyQueryVariables = {
    id,
    params,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: PolicyInput!) {
        updatePolicy(id: $id, params: $params) {
          id
          name
          description
          documentTypeIds
          regulatories
          useType
          jurisdiction
          language
          answers {
            answer
            answerType
            dateOfAnswer
            paramRef {
              parameterId
              tableId
              index
            }
            user {
              id
              firstName
              lastName
            }
            policy {
              id
            }
          }
        }
      }
    `,
    variables,
  };
};

export const updatePolicy = createAsyncThunk('policies/updatePolicy', async (_args, { getState }) => {
  const state = getState() as RootState;
  const {
    policyDetail: { activePolicy },
  } = state;
  const response = await client.mutate(UPDATE_POLICY(activePolicy));
  return response;
});
