import BreadCrumb from 'layouts/BreadCrumb';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import 'layouts/PageLayout/PageLayout.scss';
import Badge, { BadgeProps } from 'atoms/Badge';
import PageHeaderButtons, { PageHeaderToolbarProps } from './PageHeaderToolbar';

export interface PageHeaderProps {
  showBreadCrumb?: boolean;
  breadCrumb?: BreadCrumbItem[];
  showBadge?: boolean;
  badgeProps?: BadgeProps;
  headerButtons?: PageHeaderToolbarProps;
  headerFilter?: React.ReactNode;
  customTitle?: React.ReactNode;
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <section className="page-layout-header">
      <div className="page-layout-header-block">
        <div className="page-layout-header-title">
          {props.showBreadCrumb && <BreadCrumb sections={props.breadCrumb} />}
          {props.customTitle && props.customTitle}
          {props.showBadge && props.badgeProps && <Badge {...props.badgeProps} />}
        </div>
        <div className="page-layout-header-toolbar">
          {props?.headerButtons && <PageHeaderButtons {...props.headerButtons} />}
        </div>
      </div>
      {props?.headerFilter && <div className="page-layout-header-filter">{props.headerFilter}</div>}
    </section>
  );
};

export default PageHeader;
