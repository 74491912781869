import { Column, ColumnProps } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable, DataTableRowReorderEvent, DataTableRowToggleEvent } from 'primereact/datatable';
import { MenuItem } from 'primereact/menuitem';
import { Paginator } from 'primereact/paginator';
import { useEffect, useRef, useState } from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { STORAGE_KEYS, getStoredValue, saveIntoStorage } from 'utils/utils-storage';
import ColumnToggler from './ColumnToggler';
import './PrimeDataTableBody.scss';
import './PrimeDataTableFilterPopup.scss';
import './PrimeDataTableFooter.scss';
import './PrimeDataTableHeader.scss';
import './PrimeDataTablePagination.scss';
// icons
import 'primeflex/primeflex.css';
// core css
import 'primeicons/primeicons.css';
// theme
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

// TEXT_FILTER_MODE_OPTIONS is defined in order to order the different options
// with 'Contains' option first
const TEXT_FILTER_MODE_OPTIONS = [
  { label: 'Contains', value: 'contains' },
  { label: 'Starts With', value: 'startsWith' },
  { label: 'Not Contains', value: 'notContains' },
  { label: 'Ends With', value: 'endsWith' },
  { label: 'Equals', value: 'equals' },
  { label: 'Not Equals', value: 'notEquals' },
];

type DataTableProps = typeof DataTable;

export type ViewDetailsProps = (id: string) => void;

const TableReactPrime = ({
  content,
  columnConfig,
  dataTableProps,
  fetchContent,
  customHeader,
  noRecordMessage,
  minWidth,
  width,
  footerColumnGroup,
  dataTest,
  stateKey,
  showReorderingToggler = false,
  allowDragDrop = false,
  onRowReorder,
  onToggleDragAndDrop,
  defaultSortField,
  defaultSortOrder,
  onRowClick,
  showPagination,
  selectionMode,
  selectedRows,
  onSelectionChange,
  className,
  rowExpansion,
  expandedRows,
  onRowToggle,
  onRowExpand,
  onRowCollapse,
  rowExpansionTemplate,
  menuModel,
}: TableReactPrimeProps) => {
  const [products, setProducts] = useState<any[]>([]);
  const [hideColumns, setHideColumns] = useState<string[]>([]);

  const [paginator] = useState<{
    first: number;
    rows: number;
  }>({
    first: 0,
    rows: 10,
  });

  useEffect(() => {
    if (content) {
      setProducts(content);
    }
  }, [content]);

  useEffect(() => {
    const hiddenColumns = JSON.parse(getStoredValue(stateKey + STORAGE_KEYS.HIDDEN_COLUMNS_PREFIX) || '[]');
    if (hiddenColumns && hiddenColumns.length) {
      updateHiddenColumns(hiddenColumns);
    } else {
      const columns: any[] = [];
      columnConfig.forEach(column => {
        if (!column.mandatory && column.isHide && column.header) {
          columns.push(column.header);
        }
      });
      updateHiddenColumns(columns);
    }
  }, [columnConfig]);

  useEffect(() => {
    if (fetchContent) {
      // TODO: need to add pagination
      const { after, first } = resolvePageRange(1, 1000);
      fetchContent({ after, first });
    }
  }, []);

  /**
   * Update the state and storage with hidden columns
   * @param columns column list
   */
  const updateHiddenColumns = (columns: string[]) => {
    if (columnConfig.length && stateKey) {
      saveIntoStorage(stateKey + STORAGE_KEYS.HIDDEN_COLUMNS_PREFIX, JSON.stringify(columns));
    }
    setHideColumns(columns);
  };

  const handleOnChangeDropdown = (headerName: string) => (checked: boolean) => {
    if (checked) {
      updateHiddenColumns([...hideColumns, headerName]);
    } else {
      updateHiddenColumns(hideColumns.filter(col => col !== headerName));
    }
  };

  const header = (
    <div className="inner-header">
      <div className="result-group">
        <span>Result: {content?.length || 0} Entries</span>
      </div>
      <div className="d-inline-flex">
        {showReorderingToggler && (
          <Popup
            className="popup-info"
            content="Reorder / Normal Mode"
            trigger={
              <Button
                className="drag-drop-toggle-btn border-sm-gray-light m-r-m p-xs"
                onClick={() => onToggleDragAndDrop && onToggleDragAndDrop()}
              >
                <Icon icon={Icons.DragAndDrop} />
              </Button>
            }
          />
        )}
        <ColumnToggler
          columnConfig={columnConfig}
          hideColumns={hideColumns}
          onChange={handleOnChangeDropdown}
        />
      </div>
    </div>
  );

  const showColumn = columnConfig.filter(({ header }) => {
    const isHide = hideColumns.find(col => col === header);
    if (isHide) return false;
    return true;
  });

  const contextmenu = useRef(null) as any;

  const onClickContextMenu = (e: any) => {
    return contextmenu.current.show(e.originalEvent);
  };

  return (
    <div
      className={`card data-table ${conditionalClassName(className, className)}`}
      data-test="porfolio-data-table"
    >
      {menuModel && (
        <ContextMenu
          model={menuModel}
          ref={contextmenu}
          // Might be used
          //onHide={() => setSelectedProduct(null)}
        />
      )}

      <DataTable
        scrollable={true}
        size="small"
        stripedRows
        data-test={dataTest}
        emptyMessage={noRecordMessage || 'No Records Found'}
        value={products}
        header={!customHeader ? false : customHeader || header}
        className={conditionalClassName(!customHeader, 'no-header')}
        tableStyle={{
          minWidth: minWidth || '10rem',
          width: width || '100%',
        }}
        paginator={showPagination !== undefined ? showPagination : true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        paginatorTemplate="RowsPerPageDropdown  CurrentPageReport PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Entries"
        footerColumnGroup={footerColumnGroup}
        stateStorage="session"
        stateKey={stateKey}
        reorderableRows={allowDragDrop}
        onRowReorder={e => onRowReorder && onRowReorder(e)}
        selection={selectedRows}
        onSelectionChange={e => onSelectionChange && onSelectionChange(e)}
        sortField={defaultSortField}
        sortOrder={defaultSortOrder}
        onRowClick={({ data }) => onRowClick && onRowClick(data.id)}
        onRowToggle={onRowToggle}
        expandedRows={expandedRows}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        onContextMenu={contextmenu.current && onClickContextMenu}
        onContextMenuSelectionChange={(e: any) => ''}
        {...dataTableProps}
      >
        {allowDragDrop && (
          <Column
            rowReorder
            style={{ width: '3em' }}
          />
        )}

        {selectionMode && (
          <Column
            selectionMode="multiple"
            headerStyle={{ width: '3em' }}
          />
        )}

        {rowExpansion && (
          <Column
            expander={true}
            style={{ width: '5rem' }}
          />
        )}

        {showColumn.map((props, index) => {
          if (!props.filterMatchMode && (!props.dataType || props.dataType == 'text')) {
            props.filterMatchModeOptions = TEXT_FILTER_MODE_OPTIONS;
            props.filterMatchMode = 'contains';
          }

          return (
            <Column
              key={`${index}${header}`}
              {...props}
              style={{
                minWidth: props.minWidth,
                width: props.width,
                marginLeft: 0,
              }}
            />
          );
        })}
        <Paginator
          first={paginator.first}
          rows={paginator.rows}
          totalRecords={products.length}
        />
      </DataTable>
    </div>
  );
};

export default TableReactPrime;

interface RowEvent<T = any> {
  data: T; // Replace `any` with your row's specific type if known
}

interface TableReactPrimeProps {
  columnConfig: CustomColumnProps[];
  content?: any[];
  dataTableProps?: DataTableProps;
  customHeader?: React.ReactNode;
  customFooter?: React.ReactNode;
  customCurrentPage?: Number;
  onChangeCurrentPage?: (currentPage: number) => void;
  fetchContent?: (request: any) => void;
  isFetching?: boolean;
  noRecordMessage?: string;
  minWidth?: string;
  footerColumnGroup?: React.ReactNode;
  width?: string;
  dataTest?: string;
  stateKey?: string;
  showReorderingToggler?: boolean;
  onRowReorder?: (e: DataTableRowReorderEvent<any[]>) => void;
  onToggleDragAndDrop?: () => void;
  allowDragDrop?: boolean;
  defaultSortField?: string;
  defaultSortOrder?: 1 | 0 | -1 | null | undefined;
  onRowClick?: ViewDetailsProps;
  showPagination?: boolean;
  selectionMode?: string;
  selectedRows?: any[];
  onSelectionChange?: (e: any) => void;
  className?: string;
  rowExpansion?: boolean;
  expandedRows?: any; // Can be of many types
  onRowToggle?: (e: DataTableRowToggleEvent) => void;
  onRowExpand?: (e: RowEvent<any>) => void;
  onRowCollapse?: (e: RowEvent<any>) => void;
  rowExpansionTemplate?: (row: any) => JSX.Element;
  menuModel?: MenuItem[];
}

const resolvePageRange = (currentPage: number, quantity: number) => {
  const last = currentPage * Number(quantity) - Number(quantity);
  const first = currentPage * Number(quantity);

  return {
    after: btoa('arrayconnection:' + (last - 1)),
    first,
  };
};

export interface CustomColumnProps extends ColumnProps {
  isHide?: boolean;
  mandatory?: boolean;
  width?: string;
  minWidth?: string;
}
