import { Grid } from 'semantic-ui-react';
import Section from 'components/Section';
import { checkIfAdminURL, checkIfTenantURL } from 'utils/utils-admin';
import Puzzle from 'assets/images/svg/et-puzzle.svg';
import ParameterSectionIcon from 'assets/images/svg/parameter-section.svg';
import './LandingPageContent.scss';

const LandingPageContent = () => {
  const isTenant = checkIfTenantURL();
  const isAdmin = checkIfAdminURL();
  const isLandlord = !isTenant && !isAdmin;
  return (
    <div className="left-content section-left">
      <Grid>
        <h2
          className="section-selector color-blue-very-dark"
          data-test="home-title"
        >
          Select a Section
        </h2>
        {isTenant && (
          <>
            <Grid.Row
              columns={2}
              className="section-row"
            >
              <Section
                header="Dashboard"
                content="View financial metrics at a glance, track statistics and display leasing data in charts and graphs."
                url="/dashboard"
                btnText="DASHBOARD"
                iconName="bi:bar-chart-line"
              />
              <Section
                header="Transactions"
                content="Access details of all ongoing negotiations with Tenants."
                url="/transactions/dashboard"
                btnText="TRANSACTIONS"
                iconName="fluent:folder-people-20-regular"
              />
            </Grid.Row>
          </>
        )}
        {isAdmin && (
          <>
            <Grid.Row
              columns={2}
              className="section-row"
            >
              <Section
                header="Users"
                content="Create, view, update and manage users and their access rights."
                url="/users"
                btnText="USERS"
                iconName="gridicons:multiple-users"
              />
              <Section
                header="Entities"
                content="Create, view, update and manage Landlord and Tenant Entities."
                url="/entities"
                btnText="ENTITIES"
                iconName="heroicons:building-office-2"
              />
            </Grid.Row>
            <Grid.Row
              columns={2}
              className="section-row"
            >
              <Section
                header="Parameters"
                content="Access the global Paramter catalogue to create, view, update and manage Parameters."
                url="/parameters/dashboard"
                btnText="PARAMETERS"
                iconSrc={Puzzle}
              />
              <Section
                header="Provisions"
                content="Access the global Provision catalogue to create, view, update and manage Provisions."
                url="/provisions/dashboard"
                btnText="PROVISIONS"
                iconSrc={ParameterSectionIcon}
              />
            </Grid.Row>
          </>
        )}
        {isLandlord && (
          <>
            <Grid.Row
              columns={2}
              className="section-row"
            >
              <Section
                header="Dashboard"
                content="View financial metrics at a glance, track statistics and display leasing data in charts and graphs."
                url="/dashboard"
                btnText="DASHBOARD"
                iconName="bi:bar-chart-line"
              />
              <Section
                header="Transactions"
                content="Access details of all ongoing negotiations with Tenants."
                url="/transactions/dashboard"
                btnText="TRANSACTIONS"
                iconName="fluent:folder-people-20-regular"
              />
            </Grid.Row>
            <Grid.Row
              columns={2}
              className="section-row"
            >
              <Section
                header="Tenants"
                content="Access current and previous Tenants, update their information and set up new Tenants."
                url="/tenant-entities/dashboard"
                btnText="TENANTS"
                iconName="fluent:home-person-20-regular"
              />
              <Section
                header="Contracts"
                content="Access current and previous lease agreements and view their terms."
                url="/contracts/dashboard"
                btnText="CONTRACTS"
                iconName="clarity:contract-line"
              />
            </Grid.Row>
          </>
        )}
      </Grid>
    </div>
  );
};

export default LandingPageContent;
