/*
 * Definition of the column (names, format...)
 */
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import { PortfolioClone } from 'utils/types/portfolio';
import { PropertyProps } from 'utils/types/portfolio';
import { PortfolioRowActionButtons } from './PortfolioRowActionButtons';

const findPropertyName = (id: string, propertiesList: PropertyProps[]) => {
  const foundProperty = propertiesList.find(({ id: propertyId }) => propertyId === id);
  return foundProperty ? (foundProperty.buildingName as string) : '';
};

export class PortfoliosRow {
  id: string;
  name: string | null;
  properties: string[];

  constructor(portfolio: PortfolioClone, propertiesList: PropertyProps[]) {
    this.id = portfolio.id as string;
    this.name = portfolio.name;
    this.properties = portfolio.propertyIds.map(id => findPropertyName(id, propertiesList));
  }
}

/**
 * Custom Filter Function for the Inputs column
 * @param [string[]] properties
 * @param [string] filter eg. Number OR Number, text
 * @returns : boolean
 */
const filterElementInputFunction = (properties: string[], filter: string): boolean => {
  if (!filter || !filter.trim()) {
    return true;
  }
  filter = filter.trim().toLowerCase();

  // In case of coma separated multiple keywords are present
  if (filter.includes(',')) {
    const output: boolean[] = [];
    const allFilterKeywords: string[] = filter
      .split(',')
      .map(keyword => (keyword ? keyword.trim().toLowerCase() : ''))
      .filter(keyword => keyword);

    allFilterKeywords.forEach((keyword: string) => {
      const out = properties.filter(property => property.toLowerCase().includes(filter));

      if (out) {
        output.push(true);
      }
    });
    return output.length === allFilterKeywords.length;
  } else {
    const output = properties.filter(property => property.toLowerCase().includes(filter));
    return output.length > 0;
  }
};

export const portfolioColumnConfig = (
  actionMenuConfig: Function,
  onViewDetails: ViewDetailsProps,
): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Name',
      filter: true,
      filterPlaceholder: 'Search by Name',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'properties',
      header: 'Properties',
      filter: true,
      filterPlaceholder: 'Search by Properties',
      filterField: 'properties',
      sortable: true,
      filterMatchMode: 'custom',
      filterMatchModeOptions: [{ label: 'Contains', value: 'custom' }],
      filterFunction: (properties: string[], filter: string) => filterElementInputFunction(properties, filter),
      body: (row: PortfoliosRow) => row.properties.join(', '),
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: PortfoliosRow) => (
        <PortfolioRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
