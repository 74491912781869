import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';

const SaleAndRedevelopmentRightForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const saleAndRedevelopmentRights = activeContractEvent?.saleAndRedevelopmentRights;
  const dateValidationArray = [
    { ...saleAndRedevelopmentRights, startDate: saleAndRedevelopmentRights?.earliestDate, endDate: null },
  ];

  const onChange = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['saleAndRedevelopmentRights', key],
        value: value || null,
      }),
    );
  };

  const onChangeNumber = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['saleAndRedevelopmentRights', key],
        value: value ? parseInt(value) : null,
      }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ;

  return (
    <div>
      <div className="grid m-t-s">
        <div className="col-2">
          <DateField
            label="Earliest date"
            fieldKey="earliestDate"
            dataTest=""
            value={saleAndRedevelopmentRights?.earliestDate || null}
            disabled={disabled}
            onChange={onChange}
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'startDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'startDate',
            )}
          />
        </div>

        <div className="col-2">
          <InputField
            label="Notice period"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(months)"
            fieldKey="noticePeriodMonths"
            value={saleAndRedevelopmentRights?.noticePeriodMonths?.toString() || null}
            disabled={disabled}
            onChange={onChangeNumber}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <TextAreaField
            label="Compensation formula"
            dataTest=""
            fieldKey="compensationFormula"
            value={saleAndRedevelopmentRights?.compensationFormula || null}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
export default SaleAndRedevelopmentRightForm;
