import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { queryNotificationResult } from './listNotifications';

interface ToggleNotificationReadStatus {
  notificationId: string;
  read: boolean;
}

interface ToggleNotificationReadStatusQueryVariables {
  notificationId: string;
  read: boolean;
}

const TOGGLE_NOTIFICATION_READ_STATUS = ({ notificationId, read }: ToggleNotificationReadStatus) => {
  if (!notificationId) throw new Error('Toggle Notification Read Status: notificationId is required');

  const variables: ToggleNotificationReadStatusQueryVariables = {
    notificationId,
    read,
  };

  return {
    mutation: gql`
            mutation ($notificationId: ID!, $read: Boolean!) {
              readNotification(notificationId: $notificationId, read: $read) {
                ${queryNotificationResult}
              }
            }
          `,
    variables,
  };
};

export const toggleNotificationReadStatus = createAsyncThunk(
  'notifications/toggleNotificationReadStatus',
  async ({ notificationId, read }: ToggleNotificationReadStatus) => {
    const response = await client.mutate(TOGGLE_NOTIFICATION_READ_STATUS({ notificationId, read }));

    return response;
  },
);
