/**
 * Used on the Policy and Transaction Preview Editor
 *
 * This method is used to:
 * * Add a border to the Node according to the Condition result
 */

// Colors to define the border of the CLAUSE/TEXT nodes
export enum NODE_BORDER_COLORS {
  GREEN = 'rgba(30, 244, 72)', // is displayed when evaluated as true
  RED = 'rgba(246, 50, 8)', // is not displayed when evaluated as false
  BLUE = 'rgba(29, 72, 245)', // is displayed when not yet evaluated e.g. null
  YELLOW = 'rgba(245, 242, 8)', // always displayed, there is no condition
  GRAY = 'rgba(130, 130, 130, 1)', // when condition has not been answered
}

// Set the border color of the node
export const toggleColorToNode = (node: HTMLElement, color: string) => {
  node.setAttribute('style', 'border: 0.15rem solid ' + color);
};

// Set the border of clause number according to the result
export const toggleClauseNumberBorderColor = (node: HTMLElement, color: string) => {
  const children: NodeListOf<ChildNode> = node.childNodes;
  for (let i = 0; i < children.length; i++) {
    const child = children[i] as HTMLElement;
    if (child.nodeName === 'DIV') {
      const nodeType: string | null = child.getAttribute('data-node-type');
      // Set the clause number node
      if (nodeType && nodeType.includes('clause-index')) {
        child.setAttribute('style', 'border: 0.15rem solid ' + color);
        break;
      }
    }
  }
};
