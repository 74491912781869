import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import PhoneNumberField from 'atoms/FormField/PhoneInput';
import SelectField from 'atoms/FormField/Select';
import { updateTeamMember } from 'store/members/membersSlice';
import { clearRoleOptions } from 'store/roles/rolesSlice';
import { listRolesOptions } from 'common/api/roles';
import { invalidEmailText } from 'utils/tsHelper';
import { emailIsValid } from 'utils/utils-string';

const TeamMemberModalForm = () => {
  const dispatch = useAppDispatch();
  const { roleOptions, optionsLoading } = useAppSelector((state: RootState) => state.roles);
  const { activeTeamMember } = useAppSelector((state: RootState) => state.members);

  useEffect(() => {
    dispatch(listRolesOptions());
    return () => {
      dispatch(clearRoleOptions());
    };
  }, [dispatch]);

  const onChange = (key: string, value: string | number, countryCode?: string) => {
    dispatch(updateTeamMember({ key, value }));

    if (countryCode) {
      dispatch(updateTeamMember({ key: 'countryCode', value: countryCode }));
    }
  };

  const checkIfValidEmail = emailIsValid(activeTeamMember?.email || '');

  return (
    <Grid>
      <Grid.Row className="pm-none">
        <Grid.Column
          width={8}
          className="pm-none p-r-sm"
        >
          <InputField
            label="First name"
            fieldKey="firstName"
            placeholder="Enter first name.."
            value={activeTeamMember?.firstName || ''}
            onChange={onChange}
            required={true}
          />

          <InputField
            label="Email"
            type={InputFieldType.EMAIL}
            fieldKey="email"
            placeholder="Enter email address.."
            value={activeTeamMember?.email || ''}
            onChange={onChange}
            required={true}
            isInValid={!checkIfValidEmail}
            errorMessage={(!checkIfValidEmail && invalidEmailText) || ''}
          />

          <SelectField
            label="Role"
            fieldKey="roleId"
            placeholder="Select role.."
            options={roleOptions ? roleOptions : []}
            value={activeTeamMember?.roleId || ''}
            onChange={onChange}
            info={true}
            loading={optionsLoading}
          />
        </Grid.Column>

        <Grid.Column
          width={8}
          className="pm-none p-l-sm"
        >
          <InputField
            className="m-b-m p-b-xxs"
            label="Last name"
            fieldKey="lastName"
            placeholder="Enter last name.."
            value={activeTeamMember?.lastName || ''}
            onChange={onChange}
            required={true}
          />

          <PhoneNumberField
            label="Phone Number"
            fieldKey="phoneNumber"
            value={activeTeamMember?.phoneNumber || ''}
            countryCode={activeTeamMember?.countryCode || ''}
            onChange={onChange}
            useCountryCode={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TeamMemberModalForm;
