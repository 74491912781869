interface RawHTMLProps {
  rawHTMLString: string;
}

const RawHTML = ({ rawHTMLString }: RawHTMLProps) => (
  <div
    className="editor-to-html"
    dangerouslySetInnerHTML={{
      __html: rawHTMLString,
    }}
  ></div>
);

export default RawHTML;
