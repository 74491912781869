import { MouseEvent, useState } from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import CheckboxField from 'atoms/FormField/Checkbox';
import { Icons } from 'utils/utils-icons';
import FileSelect from './FileSelect';
import './ImportDropdown.scss';

export interface ImportDropdownProps {
  onImport: (simulation: boolean, file: any) => void;
  extraMenuItems?: JSX.Element[];
  importButtonText?: string;
  rotateImportIcon?: number;
  className?: string;
}

const ImportDropdown = ({
  onImport,
  importButtonText = 'Import',
  extraMenuItems = [],
  rotateImportIcon = 0,
  className = '',
}: ImportDropdownProps): JSX.Element => {
  const [simulation, setSimulation] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const onClose = (): void => {
    setSimulation(false);
    setFile(null);
  };

  const onStopPropagation = (e: React.MouseEvent<HTMLElement>): void => {
    const sourceElement = e.target as HTMLElement;
    const tagName: string = sourceElement.tagName;

    if (tagName !== 'BUTTON') {
      e.stopPropagation();
    }
  };

  const onImportClick = (e: React.MouseEvent<HTMLElement>): void => {
    onImport(simulation, file);
  };

  const onSimulationChange = (event: MouseEvent) => {
    event.stopPropagation();
    setSimulation(!simulation);
  };

  return (
    <Dropdown
      className="import-dropdown m-l-auto"
      closeOnChange={false}
      trigger={
        <div className={`import-dropdown-button color-blue-very-dark-grayish p-xs ${className}`}>
          {importButtonText}
          <Icon
            icon={Icons.ImportRight}
            className="import-icon"
            style={{
              transform: `rotate(calc(90deg + ${rotateImportIcon}deg))`,
            }}
          />
        </div>
      }
      icon={false}
      onClose={onClose}
    >
      <Dropdown.Menu direction="left">
        <FileSelect
          setSimulation={setSimulation}
          file={file}
          setFile={setFile}
        />
        {extraMenuItems.map((MenuItem, index) => (
          <Dropdown.Item key={`item-${index}`}>{MenuItem}</Dropdown.Item>
        ))}
        <Dropdown.Item
          className="simulation-select m-b-none"
          onClick={event => onSimulationChange(event)}
        >
          <CheckboxField
            fieldKey=""
            value={simulation}
            disabled={true}
            label="Simulation"
          />
        </Dropdown.Item>
        <Dropdown.Item
          className="action-btn"
          onClick={onStopPropagation}
        >
          <Button
            className="btn grey-bg"
            onClick={onImportClick}
            disabled={file === null}
          >
            IMPORT
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ImportDropdown;
