import Decimal from 'decimal.js';
import MParameterTable from 'common/model/ParameterTable';
import MParameterTableColumn from 'common/model/ParameterTableColumn';
import MTableValidTypes from 'common/model/TableValidTypes';
import { IParameterOnlyId, IParameterTableOnlyId, IParameterTablePickName } from 'common/api/provisions';
import { OmitMultiple } from 'utils/UI';
import { ParameterGroupMainDataClone } from './ParameterGroupDetailClone';
import { RowNumberClone } from './ParameterTableDetailClone';

export class ParameterTablePickName implements IParameterTablePickName {
  id: string;
  name: string;

  constructor(input: IParameterTablePickName) {
    this.id = input.id;
    this.name = input.name;
  }

  static fragments(): string {
    return `
      id
      name
    `;
  }
}

interface ParameterTableListingColumnClone
  extends OmitMultiple<Pick<MParameterTableColumn, 'parameter' | 'table'>, 'parameter' | 'table'> {
  parameter: IParameterOnlyId;
  table: IParameterTableOnlyId;
}

interface IParameterTableListingClone
  extends OmitMultiple<
    OmitMultiple<MParameterTable, 'insertedAt' | 'updatedAt' | 'description'>,
    'parameterGroup' | 'rowNumber' | 'columns'
  > {
  parameterGroup: ParameterGroupMainDataClone;
  rowNumber: RowNumberClone;
  columns: ParameterTableListingColumnClone[];
}

export class ParameterTableListingClone implements IParameterTableListingClone {
  id: string;
  name: string;
  index: Decimal;
  type: MTableValidTypes;
  parameterGroup: ParameterGroupMainDataClone;
  rowNumber: RowNumberClone;
  columns: ParameterTableListingColumnClone[];

  constructor(input: IParameterTableListingClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.type = input.type;
    this.parameterGroup = input.parameterGroup;
    this.rowNumber = input.rowNumber;
    this.columns = input.columns;
  }

  static fragments(): string {
    return `
      id
      name
      index
      type
      parameterGroup {
        id
        name
        category {
          id
          name
        }
      }
      rowNumber {
        parameter {
          id
          name
        }
        table {
          id
          name
        }
      }
      columns {
        parameter {
          id
        }
        table {
          id
        }
      }
    `;
  }
}
