import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ProvisionUse } from 'common/_classes';

interface CheckProvisionUseQueryVariables {
  id: string;
}

const CHECK_PROVISION_USE = (id: string) => {
  if (!id) throw new Error('Check Provision Use: id is required');

  const variables: CheckProvisionUseQueryVariables = { id };

  return {
    query: gql`
      query ($id: ID!) {
        checkProvisionUse(id: $id) {
          ${ProvisionUse.fragments()}
        }
      }
    `,
    variables,
  };
};

export const checkProvisionUse = createAsyncThunk('provisions/checkProvisionUse', async ({ id }: { id: string }) => {
  const response = await adminClient.query(CHECK_PROVISION_USE(id));
  return response;
});
