import Decimal from 'decimal.js';
import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import ManagementChargeMonthlyAcFeesPayable from 'common/model/ManagementChargeMonthlyAcFeesPayable';
import OutOfHoursAcChargesPayable from 'common/model/OutOfHoursAcChargesPayable';
import RateType from 'common/model/RateType';
import { UsagePoint, evaluateManagementChargeOrAcFeeFinalCharge } from 'common/api/contracts2';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import { RATE_TYPE_OPTIONS } from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';

interface StandaloneACFeesFormProps extends ModeProps, ViewProps {
  index: number;
}

const AcFeesForm = ({ mode, index, isViewPage }: StandaloneACFeesFormProps) => {
  const dispatch = useAppDispatch();

  const { activeContract, activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const acFees = activeContract?.acFees || [];

  const acFee = acFees[index];

  const { endDate, startDate, type, standardHours, standardRate, standardRateDiscount, finalCharge, outOfHoursFee } =
    acFee;

  const isStandardType = type === RateType.Standard;
  const isDiscountType = type === RateType.Discount;
  const isFixedType = type === RateType.Fixed;

  const disabled = mode === MODE_OPTIONS.READ || isViewPage;
  const required = mode === MODE_OPTIONS.EDIT;

  const AcRatesApply =
    activeContract?.monthlyAcFeesPayable ===
    ManagementChargeMonthlyAcFeesPayable.NotIncludedAdditionalAcFeesPaidByTenant;

  const outOFHoursFeeApplicable = activeContract?.outOfHoursAcPayble === OutOfHoursAcChargesPayable.Yes;
  const acIs24_7 = activeContract?.outOfHoursAcPayble === OutOfHoursAcChargesPayable.NoAcIs247;

  const rootKey = 'acFees';

  const onChangeDate = (key: string, value: any) => {
    dispatch(
      updateActiveContractInput({
        key: [rootKey, `${index}`, key],
        value: value ? value : null,
      }),
    );
  };

  const onChangeType = (_key: string, value: string) => {
    switch (value) {
      case RateType.Standard:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`],
            value: {
              ...acFee,
              standardRateDiscount: new Decimal('0'),
              finalCharge: acFee.standardRate,
              type: value,
            },
          }),
        );
        break;

      case RateType.Fixed:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`],
            value: {
              ...acFee,
              standardRateDiscount: new Decimal('0'),
              type: value,
            },
          }),
        );
        break;

      case RateType.Discount:
        dispatch(
          updateActiveContractInput({
            key: [rootKey, `${index}`],
            value: {
              ...acFee,
              standardRateDiscount: null,
              type: value,
              finalCharge: null,
            },
          }),
        );
        break;
    }
  };

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractInput({
        key: [rootKey, `${index}`, key],
        value: value ? new Decimal(value) : null,
      }),
    );

    if (key === 'standardRateDiscount' && !value) {
      dispatch(
        updateActiveContractInput({
          key: [rootKey, `${index}`, 'finalCharge'],
          value: null,
        }),
      );
    }
  };

  useEffect(() => {
    if (type === RateType.Discount && standardRate && standardRateDiscount) {
      dispatch(
        evaluateManagementChargeOrAcFeeFinalCharge({
          standardRate: parseFloat(standardRate.toString()),
          standardRateDiscount: parseFloat(standardRateDiscount.toString()),
          usage: UsagePoint.ACFee,
          index,
        }),
      );
    }
  }, [type, standardRate?.toString(), standardRateDiscount?.toString()]);

  const onChangeStandardRate = (_key: string, value: string) => {
    const decimalValue = value ? new Decimal(value) : null;

    const updatedAcFee = {
      ...acFee,
      standardRate: decimalValue,
    };

    if (type === RateType.Standard) {
      updatedAcFee.finalCharge = decimalValue;
    }

    dispatch(
      updateActiveContractInput({
        key: [rootKey, `${index}`],
        value: updatedAcFee,
      }),
    );
  };

  return (
    <div>
      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'startDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'startDate',
            )}
            label="Start date"
            fieldKey="startDate"
            dataTest=""
            value={startDate}
            disabled={disabled}
            required={required}
            onChange={onChangeDate}
          />
        </div>

        <div className="col-2">
          <DateField
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'endDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              acFees,
              index,
              'endDate',
            )}
            label="End date"
            fieldKey="endDate"
            dataTest=""
            value={endDate}
            disabled={disabled}
            required={required}
            onChange={onChangeDate}
          />
        </div>
      </div>

      {AcRatesApply && (
        <div className="grid m-t-s pm-none">
          <div className="col-2">
            <SelectField
              label="Standard/discount/fixed"
              fieldKey="type"
              dataTest=""
              value={type}
              disabled={disabled}
              required={required}
              options={RATE_TYPE_OPTIONS}
              onChange={onChangeType}
            />
          </div>
          <div className="col-2">
            <InputField
              label="Standard rate (psf pcm)"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(HK$/sf/mo)"
              fieldKey="standardRate"
              value={standardRate?.toString() || null}
              disabled={disabled}
              required={required}
              onChange={onChangeStandardRate}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
          <div className="col-2">
            <InputField
              label="Discount from standard rate"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="standardRateDiscount"
              value={standardRateDiscount?.toString() || null}
              disabled={disabled || !type || isStandardType || isFixedType}
              required={required && type !== null && !isStandardType && !isFixedType}
              onChange={onChangeDecimal}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
          <div className="col-2">
            <InputField
              label="Final AC fees (psf pcm)"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(HK$/sf/mo)"
              fieldKey="finalCharge"
              value={finalCharge?.toString() || null}
              disabled={disabled || !type || isStandardType || isDiscountType}
              required={required && type !== null && !isStandardType && !isDiscountType}
              onChange={onChangeDecimal}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
        </div>
      )}

      <div className="grid m-t-s pm-none">
        <div className="col-2">
          <InputField
            label="Standard hours"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(hours))"
            fieldKey="standardHours"
            value={standardHours?.toString() || null}
            disabled={disabled || acIs24_7}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        {outOFHoursFeeApplicable ? (
          <div className="col-2">
            <InputField
              label="Out of hours AC fee"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(HK$/hr))"
              fieldKey="outOfHoursFee"
              value={outOfHoursFee?.toString() || null}
              disabled={disabled}
              onChange={onChangeDecimal}
              isDecimal={true}
              delimiter="COMMA"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AcFeesForm;
