/*
 * Definition of the column (names, format...)
 */
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import { filterElementDate } from 'atoms/TableReactPrime/columnTemplates';
import { updateHeightExpand, updateSpaceDetail } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { SpaceProps } from 'common/api/properties';
import { isValidNumber } from 'utils/utils-number';
import { addSpaceOrComma } from 'utils/utils-number';
import AddUnitModal from './AddUnitModal';
import { SpaceAndUnitRowActionButtons } from './SpaceAndUnitRowActionButtons';
import {
  activationDateInputElement,
  characteristicsInputElement,
  commentsInputElement,
  deactivationDateInputElement,
  efficiencyRatioHeaderTemplate,
  efficiencyRatioInputElement,
  grossAreaInputElement,
  lettableAreaInputElement,
  mainUseInputElement,
  nameInputElement,
  netAreaInputElement,
  occupancyRateHeaderTemplate,
  occupancyRateInputElement,
  registeredNameInputElement,
  requiredHeaderElement,
  saleableAreaInputElement,
} from './UnitsInputElements';

export class SpaceAndUnitRow {
  id: string;
  name: string | null;
  registeredName: string;
  type: string;
  grossArea: number;
  netArea: number;
  lettableArea: number;
  saleableArea: number;
  efficiencyRatio: number;
  usedForOccupancyRate: boolean;
  comments: string;
  characteristics: string;
  activationDate: string | null;
  deactivationDate: string | null;
  index: number;

  constructor(space: any, index: number) {
    this.id = space.id;
    this.registeredName = space.registeredName;
    this.name = space.name;
    this.type = space.type;
    this.grossArea = space.grossArea;
    this.netArea = space.netArea;
    this.lettableArea = space.lettableArea;
    this.saleableArea = space.saleableArea;
    this.efficiencyRatio = space.efficiencyRatio;
    this.usedForOccupancyRate = space.usedForOccupancyRate;
    this.comments = space.comments;
    this.characteristics = space.characteristics;
    this.activationDate = space.activationDate;
    this.deactivationDate = space.deactivationDate;
    this.index = index;
  }
}

export const unitColumnConfig = (
  activeCell: { activeRow: number | null; activeColumn: number | null },
  setActiveCell: Function,
  dispatch: Dispatch<AnyAction>,
  mode?: string,
): CustomColumnProps[] => {
  const spaceUpdate = ({ key, value, index }: { key: string; value: string; index: number }) => {
    dispatch(
      updateSpaceDetail({
        key,
        value,
        spaceIndex: index,
      }),
    );
  };

  const onDateChange = (fieldKey: string, value: Date, index: number) => {
    dispatch(
      updateSpaceDetail({
        key: fieldKey,
        value: value,
        spaceIndex: index,
      }),
    );
  };

  const { activeRow, activeColumn } = activeCell;

  const updateActiveCell = (index: number, columnIndex: number) => {
    setActiveCell({ activeRow: index, activeColumn: columnIndex });
  };
  const expandTableHeight = (index: number, columnIndex: number) => {
    dispatch(updateHeightExpand({ status: true }));
    setActiveCell({ activeRow: index, activeColumn: columnIndex });
  };

  const disabled: boolean = mode === MODE_OPTIONS.READ;

  const config: CustomColumnProps[] = [
    {
      field: 'name',
      header: requiredHeaderElement('Space/Unit name'),
      filter: true,
      filterPlaceholder: 'Search by space unit name',
      filterField: 'name',
      sortable: true,
      body: (row: SpaceAndUnitRow) => nameInputElement(row, updateActiveCell, spaceUpdate),
    },
    {
      field: 'registeredName',
      header: 'Registered unit',
      filter: true,
      filterPlaceholder: 'Search by Registered unit',
      filterField: 'registeredName',
      sortable: true,
      body: (row: SpaceAndUnitRow) => registeredNameInputElement(row, updateActiveCell, spaceUpdate),
    },
    {
      field: 'type',
      header: requiredHeaderElement('Main use'),
      filter: true,
      filterPlaceholder: 'Search by Main use',
      filterField: 'type',
      sortable: true,
      bodyClassName: 'main-use',
      body: (row: SpaceAndUnitRow) => mainUseInputElement(row, spaceUpdate, expandTableHeight),
      width: '15rem',
    },
    {
      field: 'grossArea',
      header: requiredHeaderElement('Gross area'),
      filter: true,
      filterPlaceholder: 'Search by Gross area',
      filterField: 'grossArea',
      sortable: true,
      body: (row: SpaceAndUnitRow) => grossAreaInputElement(row, updateSpaceDetail, updateActiveCell, !disabled),
    },
    {
      field: 'netArea',
      header: 'Net area',
      filter: true,
      filterPlaceholder: 'Search by Net area',
      filterField: 'netArea',
      sortable: true,
      body: (row: SpaceAndUnitRow) => netAreaInputElement(row, updateSpaceDetail, updateActiveCell, !disabled),
    },
    {
      field: 'lettableArea',
      header: 'Lettable area',
      filterPlaceholder: 'Search by Lettable area',
      filter: true,
      filterField: 'lettableArea',
      sortable: true,
      body: (row: SpaceAndUnitRow) => lettableAreaInputElement(row, updateSpaceDetail, updateActiveCell, !disabled),
    },
    {
      field: 'saleableArea',
      header: 'Saleable area',
      filterPlaceholder: 'Search by Saleable area',
      filter: true,
      sortable: true,
      filterField: 'saleableArea',
      body: (row: SpaceAndUnitRow) => saleableAreaInputElement(row, updateSpaceDetail, updateActiveCell, !disabled),
    },
    {
      field: 'efficiencyRatio',
      header: efficiencyRatioHeaderTemplate(),
      filter: true,
      filterPlaceholder: 'Search by Efficiency ratio',
      filterField: 'efficiencyRatio',
      sortable: true,
      body: (row: SpaceAndUnitRow) =>
        efficiencyRatioInputElement(row, updateActiveCell, spaceUpdate, activeRow, activeColumn),
    },
    {
      field: 'usedForOccupancyRate',
      filterField: 'usedForOccupancyRate',
      header: occupancyRateHeaderTemplate(),
      filterPlaceholder: 'Search by used For Occupancy Rate',
      filter: true,
      sortable: true,
      body: (row: SpaceAndUnitRow) => occupancyRateInputElement(row, updateActiveCell, spaceUpdate),
    },
    {
      field: 'characteristics',
      header: 'Characteristics',
      filterField: 'characteristics',
      filterPlaceholder: 'Search by Characteristics',
      filter: true,
      sortable: true,
      body: (row: SpaceAndUnitRow) => characteristicsInputElement(row, expandTableHeight, spaceUpdate),
      width: '15rem',
    },
    {
      field: 'activationDate',
      header: requiredHeaderElement('Activation date'),
      filter: true,
      filterPlaceholder: 'Search by Activation date',
      filterField: 'activationDate',
      sortable: true,
      body: (row: SpaceAndUnitRow) => activationDateInputElement(row, onDateChange, updateActiveCell, disabled),
      dataType: 'date',
      filterElement: filterElementDate,
    },
    {
      field: 'deactivationDate',
      header: 'Deactivation date',
      filter: true,
      filterPlaceholder: 'Search by Deactivation date',
      filterField: 'deactivationDate',
      sortable: true,
      dataType: 'date',
      filterElement: filterElementDate,
      body: (row: SpaceAndUnitRow) => deactivationDateInputElement(row, onDateChange, updateActiveCell, disabled),
    },
    {
      field: 'comments',
      header: 'Comments',
      filterField: 'comments',
      filterPlaceholder: 'Search by Comments',
      filter: true,
      sortable: true,
      body: (row: SpaceAndUnitRow) => commentsInputElement(row, updateActiveCell, spaceUpdate),
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: SpaceAndUnitRow) => {
        return !disabled ? <SpaceAndUnitRowActionButtons rowId={row.id} /> : '';
      },
      frozen: true,
      alignFrozen: 'right',
    },
  ];

  return config;
};

const getTotalAreas = (spaces: SpaceProps[]) => {
  let totalGrossArea = 0,
    totalSaleableArea = 0,
    totalLettableArea = 0,
    totalNetArea = 0;

  for (let i = 0; i < spaces.length; i++) {
    const { lettableArea, netArea, grossArea, saleableArea } = spaces[i];

    totalGrossArea += isValidNumber(grossArea) ? parseInt(grossArea ? grossArea : '0') : 0;
    totalLettableArea += isValidNumber(lettableArea) ? parseInt(lettableArea ? lettableArea : '0') : 0;
    totalNetArea += isValidNumber(netArea) ? parseInt(netArea ? netArea : '0') : 0;
    totalSaleableArea += isValidNumber(saleableArea) ? parseInt(saleableArea ? saleableArea : '0') : 0;
  }
  return {
    totalGrossArea,
    totalSaleableArea,
    totalLettableArea,
    totalNetArea,
  };
};

export const getSpaceAndUnitsFooter = (spaces: SpaceProps[], disabled: boolean) => {
  const totalAreas = getTotalAreas(spaces);
  return (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total"
          className="total-cell"
        />
        <Column
          footer=""
          colSpan={2}
        />
        <Column
          footer={addSpaceOrComma(totalAreas.totalGrossArea, false)}
          className="total-cell data-test-total-gross-area"
        />
        <Column
          footer={addSpaceOrComma(totalAreas.totalNetArea, false)}
          className="total-cell data-test-total-net-area"
        />
        <Column
          footer={addSpaceOrComma(totalAreas.totalLettableArea, false)}
          className="total-cell data-test-total-lettable-area"
        />
        <Column
          footer={addSpaceOrComma(totalAreas.totalSaleableArea, false)}
          className="total-cell data-test-total-saleable-area"
        />
        <Column
          footer=""
          colSpan={7}
        />
      </Row>
      {!disabled ? (
        <Row>
          <Column
            footer={<AddUnitModal />}
            colSpan={14}
          />
        </Row>
      ) : (
        ''
      )}
    </ColumnGroup>
  );
};
