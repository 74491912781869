import TranslatorCreatorViewer from 'views/translateToContract/CreatorViewer';
import TranslateToContractDashboard from 'views/translateToContract/Dashboard';
import { isAuthenticated } from './redirects';

const TranslateToContractRoutes = () => [
  {
    path: '/translate-to-contract',
    children: [
      {
        path: '/translate-to-contract/dashboard',
        element: isAuthenticated(<TranslateToContractDashboard />),
      },
      {
        path: '/translate-to-contract/create',
        element: isAuthenticated(<TranslatorCreatorViewer />),
      },
      {
        path: '/translate-to-contract/:id/details',
        element: isAuthenticated(<TranslatorCreatorViewer isViewPage={true} />),
      },
    ],
  },
];

export default TranslateToContractRoutes;
