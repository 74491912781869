import AreaMeasurementMethod from 'common/model/AreaMeasurementMethod';
import AreaMeasurementUnit from 'common/model/AreaMeasurementUnit';
import Floor from 'common/model/Floor';
import Property from 'common/model/Property';

type TPropertyClone = Pick<Property, 'id' | 'buildingName' | 'measurementMethod' | 'measurementUnit' | 'floors'>;

export class PropertyClone implements TPropertyClone {
  id: string;
  buildingName: string;
  measurementMethod: AreaMeasurementMethod;
  measurementUnit: AreaMeasurementUnit;
  floors: Floor[];

  constructor(input: PropertyClone) {
    this.id = input.id;
    this.buildingName = input.buildingName;
    this.measurementMethod = input.measurementMethod;
    this.measurementUnit = input.measurementUnit;
    this.floors = input.floors;
  }

  static fragments(): string {
    return `
        id
        buildingName
        measurementMethod
        measurementUnit
        floors {
          id
          name
          spaces {
            id
            name
            grossArea
          }
        }
    `;
  }
}
