import { EntityPickName } from 'common/_classes';
import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DropdownSelectFilter from 'atoms/DropdownSelectFilter';
import SearchFilter from 'components/SearchFilter';
import { updateProvisionFilters, updateSearch } from 'store/provisions/provisionsListingSlice';
import { listCountries, listLanguages } from 'common/api/miscellaneous';
import { CountryClone } from 'common/api/miscellaneous';
import { listLandlordEntities, listProvisionsAndCategories } from 'common/api/provisions';
import { DROPDOWN_OPTION, USE_TYPE_OPTIONS } from 'utils/UI';
import './ProvisionsHeaderToolbar.scss';

const ProvisionsHeaderToolbar = () => {
  const dispatch = useAppDispatch();
  const { countries, languages } = useAppSelector((state: RootState) => state.miscellaneous);
  const { search, owners, filters } = useAppSelector((state: RootState) => state.provisionsListing);

  const { jurisdiction, language, entityId, useType } = filters;

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(listCountries());
    }
    if (!languages.length) {
      dispatch(listLanguages());
    }
    if (!owners.length) {
      dispatch(listLandlordEntities());
    }
  }, []);

  const onGetData = (value?: string) => {
    return listProvisionsAndCategories({ searchValue: value, first: 300 });
  };

  const ownersOptions: DROPDOWN_OPTION[] = owners.map((entity: EntityPickName, index: number) => {
    const result: DROPDOWN_OPTION = {
      key: index,
      text: entity.name,
      value: entity.id,
    };
    return result;
  });

  const languagesOptions: DROPDOWN_OPTION[] = languages.map((language: string, index: number) => {
    const result: DROPDOWN_OPTION = {
      key: index,
      text: language,
      value: language,
    };
    return result;
  });

  const onChange = (key: string, value: any): void => {
    dispatch(updateProvisionFilters({ key, value }));
  };

  useEffect(() => {
    dispatch(
      listProvisionsAndCategories({
        searchValue: search || '',
        first: 300,
        language,
        jurisdiction,
        entityId,
        useType,
      }),
    );
  }, [language, jurisdiction, entityId, search, useType]);

  const resetSelection: CountryClone = {
    index: -1,
    name: '',
    code: '',
    key: null,
    text: '--Reset--',
    value: null,
  };

  //TODO The SelectFields below are a different size to the buttons and search bars. We need to standardise size on all imput fields
  return (
    <div className="provisions-filter d-flex">
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
        className="m-r-sm"
      />
      <DropdownSelectFilter
        className="m-l-xs m-r-xs"
        value={jurisdiction}
        placeholder="Jurisdiction"
        search={true}
        onChange={onChange}
        fieldKey="jurisdiction"
        dataTest="provision-jurisdiction"
        options={[resetSelection, ...countries]}
      />
      <DropdownSelectFilter
        className="m-l-xs m-r-xs"
        value={language}
        placeholder="Language"
        search={true}
        onChange={onChange}
        fieldKey="language"
        dataTest="provision-language"
        options={[resetSelection, ...languagesOptions]}
      />
      <DropdownSelectFilter
        className="m-l-xs m-r-xs"
        value={entityId}
        placeholder="Owner"
        search={true}
        onChange={onChange}
        fieldKey="entityId"
        dataTest="provision-owner"
        options={[resetSelection, ...ownersOptions]}
      />
      <DropdownSelectFilter
        className="m-l-xs m-r-xs"
        value={useType}
        placeholder="Use Type"
        search={true}
        onChange={onChange}
        fieldKey="useType"
        dataTest="use-type"
        options={[resetSelection, ...USE_TYPE_OPTIONS]}
      />
    </div>
  );
};

export default ProvisionsHeaderToolbar;
