import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import PremisesForm from 'components/PremisesForm';
import { FLOOR_PORTION_OPTIONS, PremiseSelectedProps } from 'components/PremisesForm/utils-premiseForm';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import Premise2Input from 'common/model/Premise2Input';
import { ModeProps } from 'common/api/miscellaneous';
import { listLandlordProperties } from 'common/api/miscellaneous/listLandlordProperties';

const Premises = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);
  const { landlordProperties } = useAppSelector(({ miscellaneous }: RootState) => miscellaneous);

  useEffect(() => {
    if (activeContractEvent?.ownerId) dispatch(listLandlordProperties(activeContractEvent.ownerId));
  }, [activeContractEvent?.ownerId]);

  const onChange = (premises: PremiseSelectedProps[]) => {
    if (premises.length > 0) {
      const premise = premises[0];

      const contractEventpremise: Premise2Input = {
        propertyId: premise.property?.id || '',
        floors: premise.floorIds.map(floorData => ({
          floorId: floorData.id,
          spaceIds: floorData.spaceIds,
          whole: floorData.floorPortion === FLOOR_PORTION_OPTIONS.WHOLE,
        })),
      };

      dispatch(updateActiveContractEventInput({ key: 'premises', value: contractEventpremise }));
    }
  };

  const getValue = () => {
    const { floors, propertyId } = activeContractEvent?.premises || {};

    if (!propertyId) {
      return [
        {
          property: null,
          floorIds: [],
        },
      ];
    }

    const property = landlordProperties.find(({ id }) => id === propertyId);

    return [
      {
        property,
        floorIds:
          floors?.map(floorData => ({
            id: floorData.floorId,
            spaceIds: floorData.spaceIds,
            floorPortion: floorData.whole ? FLOOR_PORTION_OPTIONS.WHOLE : FLOOR_PORTION_OPTIONS.PART,
          })) || [],
      },
    ];
  };

  return (
    <div className="grid">
      <div className="col-12">
        <PremisesForm
          mode={mode}
          properties={landlordProperties}
          landlordId={activeContractEvent?.ownerId}
          value={getValue()}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
export default Premises;
