import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { IFormatterDetail, IReviewStatusClone } from 'common/_classes';
import { CFormatterDetail } from 'common/_classes/formatter/FormatterDetail';
import { RootState } from 'store';
import { getReviewFlagAndReviewStatus } from 'utils/tsHelper';
import { replaceDoubleSlash } from 'utils/utils-string';

interface CreateFormatterQueryVariables {
  params: IFormatterDetail;
}

const CREATE_FORMATTER = (activeFormatter: IFormatterDetail, reviewFlag: boolean, reviewStatus: IReviewStatusClone) => {
  const formatterUpdatedFunction = { ...activeFormatter };

  formatterUpdatedFunction.function = replaceDoubleSlash(activeFormatter.function);

  const cloneActiveFormatter = JSON.parse(JSON.stringify(formatterUpdatedFunction)) as typeof activeFormatter;

  const variables: CreateFormatterQueryVariables = {
    params: { ...cloneActiveFormatter, reviewFlag, reviewStatus },
  };

  return {
    mutation: gql`
      mutation ($params: FormatterInput!) {
        createFormatter(params: $params) {
          ${CFormatterDetail.fragment()}
        }
      }
    `,
    variables,
  };
};

export const createFormatter = createAsyncThunk('formatters/createFormatter', async (_args, { getState }) => {
  const {
    formatterDetail: { activeFormatter, activeFormatterFrozenCopy },
  } = getState() as RootState;

  const [reviewFlag, reviewStatus] = getReviewFlagAndReviewStatus(activeFormatter, activeFormatterFrozenCopy);

  const response = await adminClient.mutate(
    CREATE_FORMATTER(activeFormatter, reviewFlag, reviewStatus as IReviewStatusClone), // TODO clean type ReviewStatusMutationType
  );
  return response;
});
