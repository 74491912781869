import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import SectionComponent from 'layouts/SectionComponent';
import RevisionForm from 'components/RevisionForm';
import TabContainer from 'components/TabContainer';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { createPolicy, getPolicy, updatePolicy } from 'common/api/policies';
import { policyDisabled } from 'utils/tsValidator';
import PolicyGeneralForm from './PolicyGeneralForm';

const Content = ({ mode }: ModeProps): JSX.Element => {
  return (
    <Form>
      <Grid>
        <Grid.Row className="pm-none">
          <Grid.Column width={10}>
            <SectionComponent title="General">
              <PolicyGeneralForm mode={mode} />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column width={6}>
            <SectionComponent title="Versioning Information">
              <RevisionForm note="The policy has been updated in order to take into account the evolution of the legislation concerning the TODO option." />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const GeneralTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const checkCreate: boolean = location.pathname.includes('create');
  const pageTypeCheck: MODE_OPTIONS = checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ;
  const [mode, setMode] = useState<MODE_OPTIONS>(pageTypeCheck);
  const { activePolicy } = useAppSelector((state: RootState) => state.policyDetail);

  /**
   * On Save button Click
   */
  const onSave = (): void => {
    if (checkCreate) {
      dispatch(createPolicy()).then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          // @ts-ignore
          const { id } = response.payload.data.createPolicy;
          navigate(`/policies/${id}/details`);
        }
      });
    } else {
      dispatch(updatePolicy());
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = (): void => {
    if (checkCreate) {
      navigate('/policies/dashboard');
    } else {
      dispatch(getPolicy({ id: activePolicy.id }));
    }
  };

  return (
    <TabContainer
      tabTitle="General"
      tabToolbar={
        <DetailsControlButtons
          mode={mode}
          setMode={setMode}
          checkDisabled={policyDisabled(activePolicy)}
          onCancel={onCancel}
          onSave={onSave}
        />
      }
      mode={mode}
    >
      <Content mode={mode} />
    </TabContainer>
  );
};

export default GeneralTab;
