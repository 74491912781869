import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface UpdatePortfolioQueryVariables {
  portfolioId: string;
  params: {
    entityId: string;
    name: string;
    propertyIds: string[];
  };
}

export const UPDATE_PORTFOLIO = (portfolioId: string, name: string, propertyIds: string[]) => {
  const entityId: string | null = getCurrentLandlordEntityId();

  if (!entityId) throw new Error('Update Portfolio: entityId is required');

  const variables: UpdatePortfolioQueryVariables = {
    portfolioId,
    params: {
      entityId,
      name,
      propertyIds,
    },
  };

  return {
    mutation: gql`
      mutation ($portfolioId: ID!, $params: PortfolioInput!) {
        updatePortfolio(portfolioId: $portfolioId, params: $params) {
          entity {
            id
          }
          entityId
          id
          name
          propertyIds
        }
      }
    `,
    variables,
  };
};

export const updatePortfolio = createAsyncThunk('portfolioDetail/updatePortfolio', async (args, { getState }) => {
  const {
    portfolioDetail: { activePortfolio },
  } = getState() as RootState;

  const response = await getClientType().mutate(
    UPDATE_PORTFOLIO(activePortfolio.id, activePortfolio.name, activePortfolio.propertyIds),
  );

  return response.data;
});
