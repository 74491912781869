import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { DefaultFilterRowProps } from '.';
import { FILTER_OPTIONS, LOGICAL_OPERATORS } from '../RowTemplate/RowFilterPopup/RowFilterContent/NumberFilter';
import { DataType, FilterOnchangeKeys } from '../RowTemplate/RowProps';

const TermLength = ({ onChange, values }: DefaultFilterRowProps) => {
  return (
    <div className="grid">
      <div className="col-2 d-flex align-center">
        <label className="font-600">Term length</label>
      </div>

      <div className="col-10">
        <div className="grid">
          <div className="col-5">
            <div className="grid">
              <div className="col-4">
                <SelectField
                  label="Condition 1"
                  placeholder="Select..."
                  fieldKey={`${values.id},${FilterOnchangeKeys.filterMode}`}
                  value={values.filterMode}
                  options={FILTER_OPTIONS.filter(({ value }) => value !== values.filterMode2)}
                  onChange={onChange}
                  clearable={true}
                />
              </div>

              <div className="col-8">
                <div className="grid">
                  <div className="col-4">
                    <InputField
                      label="Years"
                      fieldKey={`${values.id},${FilterOnchangeKeys.filterValue},years`}
                      type={InputFieldType.NUMBER}
                      value={values.filterValue?.years}
                      onChange={(key, years) => onChange(key, { ...values.filterValue, years })}
                      isDecimal={values?.dataType === DataType.DECIMAL}
                    />
                  </div>
                  <div className="col-4">
                    <InputField
                      label="Months"
                      fieldKey={`${values.id},${FilterOnchangeKeys.filterValue},months`}
                      type={InputFieldType.NUMBER}
                      value={values.filterValue?.months}
                      onChange={(key, months) => onChange(key, { ...values.filterValue, months })}
                      isDecimal={values?.dataType === DataType.DECIMAL}
                    />
                  </div>
                  <div className="col-4">
                    <InputField
                      label="Days"
                      fieldKey={`${values.id},${FilterOnchangeKeys.filterValue},days`}
                      type={InputFieldType.NUMBER}
                      value={values.filterValue?.days}
                      onChange={(key, days) => onChange(key, { ...values.filterValue, days })}
                      isDecimal={values?.dataType === DataType.DECIMAL}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-2">
            <SelectField
              label="Operator"
              placeholder="Select..."
              fieldKey={`${values.id},${FilterOnchangeKeys.filterLogicalOperator}`}
              value={values.filterLogicalOperator}
              options={LOGICAL_OPERATORS}
              onChange={(key, value) => {
                if (value) {
                  onChange(key, value);
                } else {
                  onChange(
                    key,
                    {
                      filterLogicalOperator: value,
                      filterMode2: undefined,
                      filterValue2: undefined,
                    },
                    true,
                  );
                }
              }}
              clearable={true}
            />
          </div>

          {values.filterLogicalOperator && (
            <div className="col-5">
              <div className="grid">
                <div className="col-4">
                  <SelectField
                    label="Condition 2"
                    placeholder="Select..."
                    fieldKey={`${values.id},${FilterOnchangeKeys.filterMode2}`}
                    value={values.filterMode2}
                    options={FILTER_OPTIONS.filter(({ value }) => value !== values.filterMode)}
                    onChange={onChange}
                    clearable={true}
                  />
                </div>
                <div className="col-8">
                  <div className="grid">
                    <div className="col-4">
                      <InputField
                        label="Years"
                        fieldKey={`${values.id},${FilterOnchangeKeys.filterValue2},years`}
                        type={InputFieldType.NUMBER}
                        value={values.filterValue2?.years}
                        onChange={(key, years) => onChange(key, { ...values.filterValue2, years })}
                        isDecimal={values?.dataType === DataType.DECIMAL}
                      />
                    </div>
                    <div className="col-4">
                      <InputField
                        label="Months"
                        fieldKey={`${values.id},${FilterOnchangeKeys.filterValue2},months`}
                        type={InputFieldType.NUMBER}
                        value={values.filterValue2?.months}
                        onChange={(key, months) => onChange(key, { ...values.filterValue2, months })}
                        isDecimal={values?.dataType === DataType.DECIMAL}
                      />
                    </div>
                    <div className="col-4">
                      <InputField
                        label="Days"
                        fieldKey={`${values.id},${FilterOnchangeKeys.filterValue2},days`}
                        type={InputFieldType.NUMBER}
                        value={values.filterValue2?.days}
                        onChange={(key, days) => onChange(key, { ...values.filterValue2, days })}
                        isDecimal={values?.dataType === DataType.DECIMAL}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermLength;
