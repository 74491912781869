import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteEntityQueryVariables {
  entityId: string;
}

export const DELETE_ENTITY = (entityId: string) => {
  if (!entityId) throw new Error('Delete Entity: entity id is required');

  const variables: DeleteEntityQueryVariables = {
    entityId,
  };

  return {
    mutation: gql`
      mutation ($entityId: ID!) {
        deleteEntity(entityId: $entityId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteEntity = createAsyncThunk('adminEntities/deleteEntity', async ({ id }: { id: string }) => {
  const response = await adminClient.mutate(DELETE_ENTITY(id));
  return response;
});
