import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface SetCrossRefInPolicyQueryVariables {
  id: string | undefined;
}

export const SET_CROSS_REF_IN_POLICY = (id: string | undefined | null) => {
  if (!id) throw new Error('SET_CROSS_REF_IN_POLICY: id is required');

  const variables: SetCrossRefInPolicyQueryVariables = { id };

  return {
    query: gql`
      query ($id: ID!) {
        setCrossRefInPolicy(id: $id) {
          content
          documentType {
            name
            id
          }
        }
      }
    `,
    variables,
  };
};

export const setCrossRefInPolicy = createAsyncThunk(
  'policies/setCrossRefInPolicy',
  async ({ id }: { id: string | undefined | null }) => {
    const response = await client.query(SET_CROSS_REF_IN_POLICY(id));
    return response;
  },
);
