import RightImagePageTemplate from 'layouts/RightImagePageTemplate';
import LandingPageContent from './LandingPageContent';

const LandingPage = () => {
  return (
    <RightImagePageTemplate
      leftContent={<LandingPageContent />}
      landingPage={true}
    />
  );
};

export default LandingPage;
