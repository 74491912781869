import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectColor from 'atoms/SelectColor/SelectColor';
import { updateActiveCategory } from 'store/provisions/provisionsListingSlice';

const CategoryForm = () => {
  const dispatch = useAppDispatch();
  const { activeCategory } = useAppSelector((state: RootState) => state.provisionsListing);

  const onChange = (key: string, value: any) => {
    dispatch(updateActiveCategory({ key, value }));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width="16">
          <InputField
            label="Category name"
            type={InputFieldType.TEXT}
            fieldKey="name"
            placeholder="Type category name..."
            value={activeCategory.name}
            onChange={onChange}
            required={true}
            dataTest="category-name"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width="16">
          <SelectColor
            label="Select color"
            fieldKey="color"
            placeholder="Select color..."
            value={activeCategory.color}
            onChange={onChange}
            info={true}
            required={true}
            dataTest="category-color"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width="16">
          <InputField
            label="Index"
            type={InputFieldType.NUMBER}
            fieldKey="index"
            value={!!activeCategory.index ? String(activeCategory.index) : null}
            required={true}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
            placeholder="Type index..."
            dataTest="category-index"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CategoryForm;
