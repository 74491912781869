import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

export const LIST_INDUSTRIES = () => {
  return {
    query: gql`
      query {
        listIndustries {
          id
          code
          description
          groupCode
          groupName
          insertedAt
          name
          sectorCode
          sectorName
          subIndustryCode
          subIndustryName
          updatedAt
        }
      }
    `,
  };
};

export const listIndustries = createAsyncThunk('entityDetail/listIndustries', async () => {
  const response = await client.query(LIST_INDUSTRIES());
  return response;
});
