// appStateSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IContextState, evaluateAIQuestion, localAPIFallback } from 'common/api/aiQuestions';

export interface IChatBotMessage {
  isFromBot: boolean;
  sender: string;
  message: string;
  timestamp: any;
  additionalData?: any;
}

type TChatBotState = {
  messages: IChatBotMessage[];
  isLoading: boolean;
};
const MESSAGES: IChatBotMessage[] = [
  {
    isFromBot: true,
    sender: 'Chatbot',
    timestamp: 1725847310000,
    message: 'Hello! How can I assist you today?',
  },
  /*
  {
    isFromBot: true,
    sender: 'Chatbot',
    timestamp: 1725847310000,
    message: `
        Here's is a demo of showing a table in the chatbot: \n
        {Table} \n
        That's it!
      `,
    additionalData: {
      Table: {
        columnConfig: [
          {
            field: 'name',
            header: 'Document name',
            filterPlaceholder: 'Search by ID',
            filter: true,
            filterField: 'name',
            sortable: true,
            // body: "test",
          },
          {
            field: 'dateSigned',
            header: 'Date signed',
            filter: true,
            filterPlaceholder: 'Search by Property',
            filterField: 'dateSigned',
            sortable: true,
            dataType: 'text',
            mandatory: true,
          },
          {
            field: 'comment',
            header: 'Comment',
            filter: true,
            filterPlaceholder: 'Search by Property',
            filterField: 'comment',
            sortable: true,
            dataType: 'text',
            mandatory: true,
          },
        ],
      },
    },
  },
  {
    isFromBot: true,
    sender: 'Chatbot',
    timestamp: 1726048884345,
    message: `
        Here's is a demo of showing a line chart in the chatbot: \n
        {Chart} \n
        That's it!
      `,
    additionalData: {
      Chart: {
        data: {
          labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          datasets: [
            {
              label: 'Dataset 1',
              data: [65, 59, 80, 81, 56, 55, 40],
            },
            {
              label: 'Dataset 2',
              data: [28, 48, 40, 19, 86, 27, 90],
            },
          ],
        },
      },
    },
  },*/
];
const initialState: TChatBotState = {
  messages: MESSAGES,
  isLoading: false,
};

const chatBotSlice = createSlice({
  name: 'chatBotState',
  initialState,
  reducers: {
    getChatbotMessages: (state, action) => {},
  },
  extraReducers: builder => {
    // evaluateAIQuestion
    builder.addCase(evaluateAIQuestion.pending, (state, action) => {
      const message = action?.meta?.arg?.message;
      state.messages = [...state.messages, message];
    });
    builder.addCase(evaluateAIQuestion.fulfilled, (state, action) => {
      let result = action?.payload as any;
      if (result?.errors) {
        result = 'You are asking the question inside the wrong page.';
      } else {
        const answer = result.data.evaluateAiQuestion.result.data;
        const format = answer.format;
        if (format === 'string') {
          result = answer.value;
        } else if (format === 'object') {
          result = JSON.stringify(answer, null, 2);
        } else if (format === 'table') {
          result = JSON.stringify(answer, null, 2);
        }
      }

      state.messages = [
        ...state.messages,
        {
          isFromBot: true,
          sender: 'Chatbot',
          message: result,
          timestamp: Date.now(),
        },
      ];
    });
    builder.addCase(evaluateAIQuestion.rejected, (state, action) => {
      console.error(action.error);
      toast.error('evaluateAIQuestion API request rejected');
    });

    // localAPIFallback
    builder.addCase(localAPIFallback.pending, (state, action) => {
      const message = action?.meta?.arg?.message;
      state.messages = [...state.messages, message];
      state.isLoading = true;
    });

    builder.addCase(localAPIFallback.fulfilled, (state, action) => {
      const message = action?.payload;
      state.messages = [
        ...state.messages,
        {
          isFromBot: true,
          sender: 'Chatbot',
          message: JSON.stringify(message, null, 2),
          timestamp: Date.now(),
        },
      ];
      state.isLoading = false;
    });
    builder.addCase(localAPIFallback.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('localAPIFallback API request rejected');
    });
  },
});

export const { getChatbotMessages } = chatBotSlice.actions;
export default chatBotSlice.reducer;
