import { GROUP_TYPE, OptionItem } from 'utils/types/categories';

/**
 * This function creates a huge list to make easy to search
 */
export const flatOptions = (optionMenu: OptionItem[]) => {
  let options = optionMenu;

  optionMenu.forEach(option => {
    if (option.group !== GROUP_TYPE.CUSTOM && option.children) {
      options = options.concat(flatOptions(option.children));
    }
  });

  return options;
};

/**
 * This function do a search
 *
 * If there is a search valid prop, return the object
 */
export const searchDropdown = (search: string, dropdown: OptionItem[]) => {
  if (search) {
    const flatOptionsMenu = flatOptions(dropdown);

    return flatOptionsMenu.filter(
      ({ group, label }) => group !== GROUP_TYPE.CUSTOM && label.toLowerCase().includes(search.toLowerCase()),
    );
  }

  return null;
};

/**
 * This function normalize output, removing the children props
 */
export const handleResult = (result: OptionItem[]) => {
  const options = result.map(option => {
    return {
      id: option.id,
      group: option.group,
      label: option.label,
      value: option.value,
      selection: { ...option.selection },
    };
  });

  return options;
};

/**
 * After search, you got a Option Object but without path
 *
 * This function makes a reverse path and gives the full path for your search result
 */
export const withSelectedOptionCreatePath = (optionMenu: OptionItem[], lastSelected: OptionItem) => {
  const flatOptionMenu = flatOptions(optionMenu);
  let found = lastSelected;
  const result = [found];
  do {
    const optionTmp = flatOptionMenu.find(option => {
      if (option.group === GROUP_TYPE.GROUPS) {
        return option?.children?.find(child => child.id === found.id && child.group === GROUP_TYPE.TABLES);
      }
      return option?.children?.find(child => child.id === found.id);
    });
    if (optionTmp) {
      found = optionTmp;
      result.push(found);
    } else {
      break;
    }
  } while (true);
  return result.reverse();
};

/**
 * This create a sequence of option
 *
 * @param optionMenuPath OptionItem[] | This value should be the return of reverseEngineering
 */
export const getNewOptionsMenuSet = (optionMenuPath: OptionItem[], dropdown: OptionItem[]) => {
  let currentOptionMenu = dropdown;

  const result: OptionItem[][] = [];

  optionMenuPath.forEach(optionMenu => {
    const found = currentOptionMenu?.find(({ id }) => optionMenu.id === id);

    if (found?.children) {
      currentOptionMenu = found.children;
      result.push(found.children);
    }
  });

  return [dropdown].concat(result);
};

/**
 *
 */
export const substitute = (newOption: OptionItem, options: OptionItem[]) => {
  const { group: newGroup } = newOption;
  const alreadyHad = options.find(({ group }) => group === newGroup);
  if (alreadyHad) {
    return options.map(({ group, ...rest }) => {
      if (group === newGroup) return newOption;
      return { ...rest, group };
    });
  } else {
    return [...options, newOption];
  }
};
