/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment';
import { toast } from 'react-toastify';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateActiveFloor } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { CHARACTERISTICS_OPTIONS } from 'utils/UI';

const MainDescription = ({ mode }: ModeProps) => {
  const { activeFloor } = useAppSelector((state: RootState) => state.propertyDetail);

  const { name, index, ceilingHeight, floorLoading, characteristics, activationDate, deactivationDate, comments } =
    activeFloor as any;

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: any) => dispatch(updateActiveFloor({ key, value }));

  const activationDateValidation = (currentEndDate: string) => [
    (newStartDate: any) => {
      if (newStartDate && currentEndDate && moment(newStartDate).isAfter(currentEndDate)) {
        toast.error('Activation date should be before deacactivation date');
        return [true, 'Date cannot be after deactivation date'];
      }

      return [false, ''];
    },
  ];

  const deactivationDateValidation = (currentStartDate: string) => [
    (newEndDate: any) => {
      if (newEndDate && currentStartDate && moment(currentStartDate).isAfter(newEndDate)) {
        toast.error('Deactivation date should be after activation date');
        return [true, 'Date cannot be before activation date'];
      }

      return [false, ''];
    },
  ];
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={3}>
          <InputField
            label="Floor name"
            value={name}
            placeholder="Type here..."
            dataTest="floor-name"
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="name"
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <InputField
            label="Floor index"
            value={index}
            placeholder="Type here..."
            dataTest="floor-index"
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="index"
            type={InputFieldType.NUMBER}
            canBeNegative={true}
            delimiter="COMMA"
          />
        </Grid.Column>

        <Grid.Column
          width={3}
          className="m-l-xxl"
          data-test="activation-date"
        >
          <DateField
            label="Floor activation date"
            fieldKey="activationDate"
            value={activationDate}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            runValidation={true}
            // @ts-ignore
            validationFuncs={activationDateValidation(deactivationDate)}
            validationDependant={deactivationDate}
          />
        </Grid.Column>

        <Grid.Column
          width={3}
          data-test="deactivation-date"
        >
          <DateField
            label="Floor deactivation date"
            fieldKey="deactivationDate"
            value={deactivationDate}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            info={true}
            runValidation={true}
            // @ts-ignore
            validationFuncs={deactivationDateValidation(activationDate)}
            validationDependant={activationDate}
            infoValue="Here the user can define a date upon which the current floor layout will expire. This can be the case, for example, when the user knows that as of a certain date in the future a new tenant moves in, which will require the combination of 2 units into a single unit, or similar adjustments to the current floor layout."
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <InputField
            label="Ceiling height"
            value={ceilingHeight}
            placeholder="Type here..."
            dataTest="ceiling-height"
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="ceilingHeight"
            unit="m"
            type={InputFieldType.NUMBER}
            isDecimal={true}
            delimiter="COMMA"
          />
        </Grid.Column>

        <Grid.Column width={3}>
          <InputField
            label="Floor loading"
            value={floorLoading}
            placeholder="Type here..."
            dataTest="floor-loading"
            disabled={mode === MODE_OPTIONS.READ}
            info={true}
            onChange={onChange}
            unit="kg/sqft"
            fieldKey="floorLoading"
            type={InputFieldType.NUMBER}
            isDecimal={true}
            delimiter="COMMA"
            popUp={true}
            infoText="The floor loading capacity sets out the weight that a floor can support without causing structural damage or failure to the building."
          />
        </Grid.Column>

        <Grid.Column
          width={6}
          className="m-l-xxl"
        >
          <TextAreaField
            label="Comments"
            value={comments}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="comments"
            placeholder="Type here..."
            dataTest="comments"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="floor-characteristics">
        <Grid.Column width={6}>
          <SelectField
            label="Characteristics"
            fieldKey="characteristics"
            dataTest="characteristics"
            value={characteristics === null ? [] : characteristics}
            disabled={mode === MODE_OPTIONS.READ}
            options={CHARACTERISTICS_OPTIONS}
            onChange={onChange}
            multiple={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MainDescription;
