/* eslint-disable jsx-a11y/label-has-associated-control */
import { ParameterDropdownOption, ParameterTableColumnForm } from 'common/_classes';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveTableParameterColumn } from 'store/parameters/parameterDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import ParameterTypes from 'common/model/ParameterTypes';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { convertAnswerType } from 'utils/utils-answer';
import { addSpaceOrComma } from 'utils/utils-number';
import ChoiceList from './ChoiceList';

interface ColumnFormProps {
  mode: MODE_OPTIONS;
  column: ParameterTableColumnForm;
  index: number;
}

const ColumnForm = ({ mode, column, index }: ColumnFormProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { parameterDropdown } = useAppSelector((state: RootState) => state.parametersListing);
  const { title } = column;

  const selectedParameterId: string = column?.parameter?.id ?? undefined;

  const onChange = (key: string, value: string): void => {
    dispatch(updateActiveTableParameterColumn({ key, value, index }));
  };

  const parameter: ParameterDropdownOption | undefined = parameterDropdown.find(
    (obj: ParameterDropdownOption) => obj.id === selectedParameterId,
  );

  const getLink = (): string => {
    return `/parameters/${parameter?.value}/details`;
  };

  const guidance: boolean = !!parameter?.guidance;
  const inventory: boolean = !!parameter?.inventory;
  const alternative: boolean = parameter?.type === ParameterTypes.AlternativeLanguage;
  const choicesExist: boolean =
    !!parameter?.answerType && [AnswerTypes.SingleChoice, AnswerTypes.MultiChoice].includes(parameter?.answerType);

  return (
    <div className="m-t-l">
      <InputField
        label="Title"
        dataTest="title-name"
        value={title}
        disabled={mode === MODE_OPTIONS.READ}
        required={mode === MODE_OPTIONS.EDIT}
        onChange={onChange}
        fieldKey="title"
      />
      <SelectField
        label="Parameter"
        value={selectedParameterId}
        disabled={mode === MODE_OPTIONS.READ}
        placeholder="Select ..."
        dataTest="select-parameter"
        options={parameterDropdown}
        required={mode === MODE_OPTIONS.EDIT}
        onChange={onChange}
        fieldKey="parameterId"
        search={true}
      />
      {selectedParameterId && parameter && (
        <>
          <div className="d-flex align-center m-t-m">
            <a
              className="param-link"
              data-test="view-parameter-link"
              href={getLink()}
              target="_blank"
              rel="noopener noreferrer"
            >
              View parameter
            </a>

            {guidance && <span className="param-type m-l-xxs color-white bg-pink-bright">G</span>}
            {inventory && <span className="param-type m-l-xxs color-white bg-purple">I</span>}
            {alternative && <span className="param-type m-l-xxs color-white bg-orange">AL</span>}
          </div>

          <div
            className="m-t-ml"
            data-test="answer-type"
          >
            <p className="answer-type">Type</p>
            <p>
              {convertAnswerType(parameter?.answerType)}
              {parameter?.answerType === AnswerTypes.NumberUnit && `: ${addSpaceOrComma(parameter?.unit)}`}
            </p>
          </div>

          {choicesExist && (
            <ChoiceList
              choices={parameter?.choices}
              className="m-t-ml"
            />
          )}
        </>
      )}
    </div>
  );
};

export default ColumnForm;
