import PropertyCreatorViewer from 'views/properties/CreatorViewer';
import PropertiesDashboard from 'views/properties/Dashboard';
import { checkIfAdminURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const PropertiesRoutes = () => {
  let children = [
    {
      path: '/properties/dashboard',
      element: isAuthenticated(<PropertiesDashboard />),
    },
  ];

  if (!checkIfAdminURL()) {
    children = [
      ...children,
      {
        path: '/properties/create',
        element: isAuthenticated(<PropertyCreatorViewer />),
      },
      {
        path: '/properties/:id/details',
        element: isAuthenticated(<PropertyCreatorViewer isViewPage={true} />),
      },
    ];
  }

  return [
    {
      path: '/properties',
      children,
    },
  ];
};

export default PropertiesRoutes;
