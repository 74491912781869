import { FormEvent } from 'react';
import { Checkbox, CheckboxProps, Form, HtmlLabelProps, SemanticShorthandItem } from 'semantic-ui-react';
import { conditionalClassName } from 'utils/tsHelper';
import './Checkbox.scss';

interface CheckboxFieldProps {
  value?: boolean;
  toggle?: boolean;
  disabled?: boolean;
  radio?: boolean;
  dataTest?: string;
  label?: SemanticShorthandItem<HtmlLabelProps>;
  fieldKey: string;
  className?: string;
  onChange?: ((key: string, value: any) => void) | undefined;
}

const CheckboxField = ({
  toggle,
  radio,
  label,
  disabled,
  onChange,
  fieldKey,
  value,
  dataTest,
  className = '',
}: CheckboxFieldProps) => {
  const onSelectionCheckbox = (checked: boolean) => {
    if (onChange) {
      onChange(fieldKey, checked);
    }
  };

  return (
    <div className="field-style">
      <Checkbox
        className={`check ${conditionalClassName(!toggle && !radio, 'box')} ${className}`}
        disabled={disabled}
        label={label}
        data-test={dataTest}
        toggle={toggle}
        radio={radio}
        onChange={(event: FormEvent<HTMLInputElement>, data: CheckboxProps) =>
          onSelectionCheckbox(data.checked || false)
        }
        checked={value}
      />
    </div>
  );
};

export default CheckboxField;
