import { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SearchBar from 'components/SearchBar';
import { updateGraphData } from 'store/dashboard/stackingPlanSlice';
import { deepClone } from 'utils/tsHelper';
import { GraphInput } from 'utils/types/stackingPlan';
import ContractAvailableStatusList from './ContractAvailableStatusList';
import { AVAILABILITY_STATUS } from './stackingPlanDefinitions';
import './StackingPlan.scss';

interface ListChangeEvent {
  isChecked: boolean;
  value: string;
}
const StackingPlanLegendFilter = () => {
  const dispatch = useAppDispatch();
  const { rawGraphData, activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);
  const [activeStatusFilters, updateStatusFilter] = useState<string[]>([]);
  const [activeSearchFilters, updateActiveSearchFilters] = useState<string>('');

  useEffect(() => {
    if (activeProperty && activeProperty.id) {
      // When user selects different property
      updateStatusFilter([]);
      updateActiveSearchFilters('');
    }
  }, [activeProperty]);

  /**
   * On Tenant Search input change
   * @param {string} value
   */
  useEffect(() => {
    const searchDelayDebounceFn = setTimeout(() => {
      applyAvailabilityStatusFilters();
    }, 500);
    return () => clearTimeout(searchDelayDebounceFn);
  }, [activeSearchFilters]);

  const onFilterChange = ({ isChecked, value }: ListChangeEvent) => {
    const index = activeStatusFilters.indexOf(value);
    if (isChecked) {
      if (index === -1) {
        activeStatusFilters.push(value);
      }
    } else {
      if (index !== -1) {
        activeStatusFilters.splice(index, 1);
      }
    }
    updateStatusFilter(activeStatusFilters);
    applyAvailabilityStatusFilters();
  };

  /**
   * Apply all legend filters to current graph data
   */
  const applyAvailabilityStatusFilters = () => {
    if (!(rawGraphData && rawGraphData.length)) {
      return;
    }
    let filteredData: GraphInput[] = [];
    let graphData = deepClone(rawGraphData) as GraphInput[];

    // If we have some text in tenant search field
    if (activeSearchFilters) {
      graphData = graphData.filter(({ tenantName }) => {
        if (tenantName) {
          return tenantName.toLowerCase().indexOf(activeSearchFilters.toLowerCase()) > -1;
        }
      });
    }

    // If legend availability status not selected
    if (!activeStatusFilters.length || !graphData.length) {
      dispatch(updateGraphData({ graphData }));
      return;
    }

    // To check if filter is active
    const checkIfFilterActive = (filterName: string) => {
      return activeStatusFilters.includes(filterName);
    };

    const isAvailableActive = checkIfFilterActive(AVAILABILITY_STATUS.AVAILABLE);
    const isLessThenSixActive = checkIfFilterActive(AVAILABILITY_STATUS.LESS_THAN_SIX);
    const isMoreThanTwentyFourActive = checkIfFilterActive(AVAILABILITY_STATUS.MORE_THAN_TWENTY_FOUR);
    const isSixToTwelveActive = checkIfFilterActive(AVAILABILITY_STATUS.SIX_TO_TWELVE);
    const isTwelveToTwentyFourActive = checkIfFilterActive(AVAILABILITY_STATUS.TWELVE_TO_TWENTY_FOUR);

    graphData.forEach(data => {
      switch (data.availabilityStatus) {
        case AVAILABILITY_STATUS.AVAILABLE:
          if (isAvailableActive) filteredData.push(data);
          break;
        case AVAILABILITY_STATUS.LESS_THAN_SIX:
          if (isLessThenSixActive) filteredData.push(data);
          break;
        case AVAILABILITY_STATUS.SIX_TO_TWELVE:
          if (isSixToTwelveActive) filteredData.push(data);
          break;
        case AVAILABILITY_STATUS.TWELVE_TO_TWENTY_FOUR:
          if (isTwelveToTwentyFourActive) filteredData.push(data);
          break;
        case AVAILABILITY_STATUS.MORE_THAN_TWENTY_FOUR:
          if (isMoreThanTwentyFourActive) filteredData.push(data);
          break;
      }
    });
    dispatch(updateGraphData({ graphData: filteredData }));
  };

  return (
    <Form.Field className="stacking-plan-range-filter">
      <ContractAvailableStatusList
        allFilters={activeStatusFilters}
        onChange={onFilterChange}
        showCheckbox={true}
      />
      <SearchBar
        value={activeSearchFilters || ''}
        search={(_event, { value }) => updateActiveSearchFilters(value || '')}
        dataTest="nav-tenant-search"
      />
    </Form.Field>
  );
};

export default StackingPlanLegendFilter;
