import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import './KnowHow.scss';

const KnowHow = () => {
  const breadCrumb: BreadCrumbItem[] = [{ title: 'Know-How' }];

  const DocLink = ({ name, link }: { name: string; link: string }) => {
    return (
      <li>
        <a
          href={link}
          target="_blank"
        >
          {name}
        </a>
      </li>
    );
  };

  const getPageHeaderProps = (): PageHeaderProps => {
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={false}
      pageHeader={getPageHeaderProps()}
    >
      <div className="list-of-docs">
        <ul>
          <DocLink
            name="Fundamental Legal Principles of Leasing"
            link="https://sericin.net/wp-content/uploads/know-how_fundamental-legal-principles-of-leasing.pdf"
          />
          <DocLink
            name="Rent Negotiation and Open Market Rent Review"
            link="https://sericin.net/wp-content/uploads/rent-negotiation-and-open-market-rent-review.pdf"
          />
          <DocLink
            name="Security"
            link="https://sericin.net/wp-content/uploads/know-how_security.pdf"
          />
          <DocLink
            name="Default and Remedies"
            link="https://sericin.net/wp-content/uploads/know-how_default-and-remedies.pdf"
          />
        </ul>
      </div>
    </PageLayout>
  );
};

export default KnowHow;
