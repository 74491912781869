import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { AuthenticationInput } from 'store/authentication/authSlice';
import { UserWithPasswordInput } from './registerInvitedUser';

interface UserWithPassworConfirmInput extends UserWithPasswordInput {
  emailConfirmation: string;
}

interface SignUpQueryVariables {
  params: UserWithPassworConfirmInput;
}

export const SIGN_UP = (auth: AuthenticationInput) => {
  const { firstName, lastName, email, confirmEmail, newPassword, newPasswordConfirmation } = auth;

  const variables: SignUpQueryVariables = {
    params: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      emailConfirmation: confirmEmail,
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
    },
  };

  return {
    mutation: gql`
      mutation ($params: UserRegistrationInput!) {
        register(params: $params) {
          id
          firstName
          lastName
          email
        }
      }
    `,
    variables,
  };
};

export const signUp = createAsyncThunk('auth/signUp', async (_arg, { getState }) => {
  const { auth } = getState() as RootState;
  const response = await client.mutate(SIGN_UP(auth));
  return response;
});
