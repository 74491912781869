import { TransactionListingPremise } from 'common/_classes';
import { Popup } from 'semantic-ui-react';
import FloorsListing from './FloorsListing';

/**
 * Check if filter matches the floor range
 * @param rangeString floor range eg. 10-20
 * @param filter filter value eg. 10
 * @returns boolean
 */
const matchFilterWithRange = (rangeString: string, filter: number): boolean => {
  const isContainsRange = rangeString.toLowerCase().includes('-');
  if (!isContainsRange) return false;
  else {
    const [start, end] = rangeString.split('-').map(x => parseInt(x.trim()));
    if (Number.isNaN(start) || Number.isNaN(end)) {
      return false;
    }
    return start <= filter && end >= filter;
  }
};

/**
 * Function being called in table to filter premises
 * @param value Column Value
 * @param filter Filter Value
 * @returns
 */
export const filterPremises = (value: string, filter: string): boolean => {
  if (!filter) {
    return true;
  }
  const isMatched = value.toLowerCase().includes(filter.toLowerCase());
  if (isMatched) {
    return true;
  } else {
    const isContainsRange = value.toLowerCase().includes('-');
    // check if contains range and filter is floor number
    if (!isContainsRange || Number.isNaN(Number(filter))) {
      return false;
    } else {
      // check if contains multiple floor range
      const ranges = value.split(',');
      for (let index = 0; index < ranges.length; index++) {
        if (matchFilterWithRange(ranges[index], Number(filter))) {
          return true;
        }
      }
      return false;
    }
  }
};

interface FloorsToolTipType {
  premise: TransactionListingPremise;
  floorNames: string;
}

const FloorsToolTip = ({ premise, floorNames }: FloorsToolTipType) => {
  return (
    <Popup
      content={<FloorsListing premise={premise} />}
      position="bottom left"
      className="attached-users-tooltip border-none bg-white"
      trigger={<div className="cursor-default">{floorNames}</div>}
      hoverable={true}
      basic={true}
    />
  );
};

export default FloorsToolTip;
