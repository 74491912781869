import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deleteParameterTable } from 'common/api/parameters';
import { Icons } from 'utils/utils-icons';

interface ParameterTablesRowActionButtonsParams {
  rowId: string;
  onViewDetails: ViewDetailsProps;
}

export const ParameterTablesRowActionButtons = ({ rowId, onViewDetails }: ParameterTablesRowActionButtonsParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = (id: string): void => {
    // @ts-ignore
    dispatch(deleteParameterTable({ id: id || '' }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Details',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Parameter Table?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
