import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ExportDropdown from 'atoms/ExportDropdown';
import ImportDropdown from 'atoms/ImportDropdown';
import ImportReport from 'atoms/ImportReport';
import { DataObjectName } from 'atoms/ImportReport/utils-interfaces';
import { clearImportedProperty } from 'store/properties/propertiesListingSlice';
import { exportForecasting, importForecasting } from 'common/api/properties';
import ForecastingDownloadTemplate from './ForecastingDownloadTemplate';
import PropertyDetails from './PropertyDetails';

const UploadAndDownload = () => {
  const dispatch = useAppDispatch();

  const { importedProperty, isExporting, exportSuccess, exportFail } = useAppSelector(
    (state: RootState) => state.propertiesListing,
  );

  const onImportForecasting = (simulation: boolean, file: File) => {
    // @ts-ignore
    dispatch(importForecasting({ simulation, file }));
  };
  const onExportForecasting = ({
    startDate,
    endDate,
    propertyId,
  }: {
    startDate?: string;
    endDate?: string;
    propertyId?: string;
  }) => {
    // @ts-ignore
    dispatch(exportForecasting({ id: propertyId, startDate, endDate }));
  };

  const clearReport = () => {
    dispatch(clearImportedProperty());
  };

  const exportTemplate = (
    <ForecastingDownloadTemplate
      onExport={onExportForecasting}
      isExporting={isExporting}
      exportSuccess={exportSuccess}
      exportFail={exportFail}
    />
  );

  return (
    <>
      <ImportDropdown
        onImport={onImportForecasting}
        extraMenuItems={[<PropertyDetails />]}
        importButtonText="Upload Forecast"
        className="m-r-xs"
        rotateImportIcon={180}
      />

      <ExportDropdown
        onExport={onExportForecasting}
        isExporting={isExporting}
        exportSuccess={exportSuccess}
        exportFail={exportFail}
        rotateExportIcon={180}
        template={exportTemplate}
      />

      {importedProperty && (
        <ImportReport
          className="m-t-l"
          importReport={importedProperty}
          clearReport={clearReport}
          dataObjectName={DataObjectName.PROPERTY}
        />
      )}
    </>
  );
};

export default UploadAndDownload;
