import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';

interface ListPropertiesForAdminQueryVariables {
  sortOrder: SortOrder;
  first: number;
  last: number;
  after: string;
  before: string;
  ilike: string | null;
}

const LIST_PROPERTIES_FOR_ADMIN = (
  searchValue: string | null,
  first: number,
  last: number,
  after: string,
  before: string,
) => {
  const variables: ListPropertiesForAdminQueryVariables = {
    sortOrder: SortOrder.Asc,
    first: first,
    last: last,
    after: after,
    before: before,
    ilike: searchValue,
  };

  return {
    query: gql`
      query ($sortOrder: SortOrder!, $first: Int, $last: Int, $after: String, $before: String, $ilike: String) {
        listProperties(
          sortOrder: $sortOrder
          first: $first
          last: $last
          after: $after
          before: $before
          filters: { Ilike: $ilike }
        ) {
          edges {
            cursor
            node {
              id
              entity {
                id
                name
              }
              photo
              buildingName
              type
              address
              identification
              spaceCount
              floorCount
              reviewFlag
              floors {
                id
                name
                spaces {
                  id
                  grossArea
                  netArea
                  lettableArea
                  saleableArea
                  activationDate
                  deactivationDate
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listPropertiesForAdmin = createAsyncThunk(
  'properties/listPropertiesForAdmin',
  async (args: { first: number; last: number; after: string; before: string }, { getState }) => {
    const { first, last, after, before } = args;
    const {
      propertiesListing: { search },
    } = getState() as RootState;
    const response = await adminClient.query(LIST_PROPERTIES_FOR_ADMIN(search, first, last, after, before));
    return response;
  },
);
