import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

export interface EditorWrapperContent {
  content: string;
}

const initialState: EditorWrapperContent = {
  content: '',
};

export const selectContent = (state: RootState) => state.content;

export const editorWrapperSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    set: (state, content) => {
      state.content = content.payload;
    },
  },
});

export const { set } = editorWrapperSlice.actions;

export default editorWrapperSlice.reducer;
