import { cloneElement, useEffect, useRef, useState } from 'react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { useClickOutside } from 'hooks/useClickOutside';
import ChatBotModalContent from './ChatBotModalContent';
import { MessageType } from './Message';
import './ChatBotModal.scss';

const ChatBotModal = ({
  isOpen,
  isLoading,
  setIsLoading,
  trigger,
  data,
  onClose,
  onSubmit,
  className,
  children,
}: {
  isOpen: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  trigger: JSX.Element;
  data: MessageType[];
  onClose: () => void;
  onSubmit: (userPrompt: string) => Promise<void>;
  className?: string;
  children: React.ReactNode;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const { messages } = useAppSelector((state: RootState) => state.chatBot);

  // close chatbot modal when clicking outside of the modal
  const { setIsEnabled: setIsEnabledClickOutside } = useClickOutside(contentRef, () => onClose(), {
    ignoreTargets: [triggerRef],
  });

  const scrollToBottom = () => {
    const scrollContentRef = contentRef.current?.querySelector('[data-scroll-id="message-list"]');
    if (scrollContentRef && scrollContentRef instanceof HTMLElement) {
      // not at bottom
      if (scrollContentRef.scrollTop < scrollContentRef.scrollHeight - scrollContentRef.clientHeight) {
        // delay scroll to the bottom of the chatbot content after the content is rendered
        setTimeout(() => {
          scrollContentRef.scrollTo({
            top: scrollContentRef.scrollHeight,
            behavior: 'smooth',
          });
        }, 100);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setIsEnabledClickOutside(isOpen);

    // scroll to the bottom of the chatbot content
    if (isOpen) {
      scrollToBottom();
    }
  }, [isOpen]);

  // handle form onSubmit
  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const message = formData.get('message') as string;
    if (!message) return;
    // clear input field
    e.currentTarget.reset();
    // set loading state to true
    setIsLoading(true);
    try {
      await onSubmit(message);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // https://react.semantic-ui.com/modules/modal/
  return (
    <div className={`chatbot-container${className ? ` ${className}` : ``}`}>
      {trigger &&
        cloneElement(trigger, {
          ref: triggerRef,
          className: `chatbot-container__trigger${trigger.props.className ? ` ${trigger.props.className}` : ``}`,
        })}
      <ChatBotModalContent
        ref={contentRef}
        className={`chatbot-container__modal${isOpen ? ` chatbot-container__modal--isOpen` : ``}${isExpanded ? ` chatbot-container__modal--isExpanded` : ``}`}
        onSubmit={handleOnSubmit}
        onClose={onClose}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        disabled={isLoading}
      >
        {children}
      </ChatBotModalContent>
    </div>
  );
};

export default ChatBotModal;
