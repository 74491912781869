import { useAppDispatch } from 'hooks';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deleteSpace } from 'store/properties/propertyDetailSlice';
import { Icons } from 'utils/utils-icons';

interface SpaceAndUnitRowRowActionButtonParams {
  rowId: string;
}

export const SpaceAndUnitRowActionButtons = ({ rowId }: SpaceAndUnitRowRowActionButtonParams) => {
  const dispatch = useAppDispatch();

  const onDelete = (id: string) => {
    dispatch(deleteSpace({ id }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Space?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
