import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Label, Menu, Tab, TabPane, TabProps } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { conditionalClassName, preventReload } from 'utils/tsHelper';
import { generateUniqueId } from 'utils/utils-random';
import './Tabs.scss';

/*
 * TabReact atoms implements a generic tabulation
 */

export interface TabMenuProps {
  label: string | ReactNode;
  key: string;
  tabHeaderClass?: string;
  tabBodyClass?: string;
  isDisabled?: boolean;
  href?: string;
  dataTest?: string;
  customTab: ReactNode;
  onClick?: Function;
  count?: number;
}

export interface TabDefinitionProps {
  tabMenu: TabMenuProps[];
  activeTabIndex?: number | string;
  onTabChange?: Function;
  onTabChangeAction?: ActionCreatorWithPayload<any, string>;
  customTabChangeAction?: (tab: string | number | undefined) => void;
  className?: string;
  dataTest?: string;
  nested?: boolean;
}

export const TabReact = ({
  tabMenu,
  activeTabIndex,
  onTabChange,
  onTabChangeAction,
  customTabChangeAction,
  className,
  dataTest,
  nested,
}: TabDefinitionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [tabId] = useState<string>(generateUniqueId('tab-react-id-'));

  useEffect(() => {
    const tabParent = document.getElementById(tabId);
    const scrollableMenuElement = tabParent?.firstChild as HTMLElement;

    // Create two new divs for shadow left and right
    const shadowLeft = document.createElement('div');
    shadowLeft.classList.add('shadow-before');

    const shadowRight = document.createElement('div');
    shadowRight.classList.add('shadow-after');

    const getScrollRatio = (element: HTMLElement) => {
      const scrollLeft = element.scrollLeft;
      const maxScrollLeft = element.scrollWidth - element.clientWidth;
      return scrollLeft / maxScrollLeft;
    };

    const alterShadowOpacities = () => {
      const scrollRatio = getScrollRatio(scrollableMenuElement);

      shadowLeft.style.opacity = `${scrollRatio}`;
      shadowRight.style.opacity = `${1 - scrollRatio}`;
    };

    if (tabParent && scrollableMenuElement) {
      // Append them to tab container
      tabParent.appendChild(shadowLeft);
      tabParent.appendChild(shadowRight);

      scrollableMenuElement?.addEventListener('scroll', alterShadowOpacities);

      const resizeObserver = new ResizeObserver(() => {
        if (isNaN(getScrollRatio(scrollableMenuElement))) {
          shadowRight.style.opacity = `0`;
          shadowLeft.style.opacity = `0`;
        } else {
          shadowRight.style.opacity = `1`;
        }
      });

      resizeObserver.observe(scrollableMenuElement);
    }

    return () => {
      scrollableMenuElement?.removeEventListener('scroll', alterShadowOpacities);
    };
  }, []);

  /**
   * It gets trigger when we change the tab
   * @param data TabProps
   */
  const onChange = (data: TabProps): void => {
    if (onTabChange) {
      onTabChange(data.activeIndex);
    }
    if (onTabChangeAction) {
      dispatch(onTabChangeAction({ tab: data.activeIndex }));
    }
    if (customTabChangeAction) {
      customTabChangeAction(data.activeIndex);
    }
  };

  /**
   * On Click event handler
   */
  const onClickEvent = (event: MouseEvent<HTMLAnchorElement>, onClick?: Function): void => {
    preventReload(event);
    if (onClick) {
      onClick();
    }
  };

  /**
   * It maps the given menu to properties panes of Tab library
   */
  const tabPanes = tabMenu.map((menu: TabMenuProps) => {
    return {
      menuItem: (
        <Menu.Item
          key={menu.key}
          href={menu.href}
          data-test={menu.dataTest}
          onClick={event => onClickEvent(event, menu.onClick)}
          disabled={menu.isDisabled}
          className={`${menu.tabHeaderClass} ${menu.isDisabled ? 'disabled-tab' : ''}`}
        >
          {menu.label} {menu?.count && menu?.count > 0 ? <Label color="orange">{menu.count}</Label> : null}
        </Menu.Item>
      ),
      render: () => (
        <TabPane
          attached={false}
          className={conditionalClassName(menu.tabBodyClass, menu.tabBodyClass)}
        >
          {menu.customTab}
        </TabPane>
      ),
    };
  });

  return (
    <Tab
      id={tabId}
      className={`tabs ${className}`}
      menu={!nested ? { secondary: true, pointing: true } : undefined}
      panes={tabPanes}
      data-test={dataTest}
      activeIndex={activeTabIndex}
      onTabChange={(e, data) => onChange(data)}
    />
  );
};
