import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import Development from 'common/model/Development';

type CreateDevelopmentParams = Omit<Development, 'id' | 'insertedAt' | 'updatedAt'>;

export const CREATE_DEVELOPMENT = (params: CreateDevelopmentParams) => {
  const variables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: DevelopmentInput!) {
        createDevelopment(params: $params) {
          address
          addressDistrict
          area
          developmentIdentifier
          developmentLotNumber
          developmentName
          entityId
          id
          insertedAt
          ownershipType
          updatedAt
        }
      }
    `,
    variables,
  };
};

export const createDevelopment = createAsyncThunk(
  'propertiesV2/createDevelopment',
  async (args: { params: CreateDevelopmentParams }) => {
    const response = await client.mutate(CREATE_DEVELOPMENT(args.params));
    return response;
  },
);
