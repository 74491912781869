import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';

interface DeletePortfolioQueryVariables {
  portfolioId: string;
}

export const DELETE_PORTFOLIO = (portfolioId: string) => {
  if (!portfolioId) throw new Error('Delete Portfolio: portfolio id is required');

  const variables: DeletePortfolioQueryVariables = {
    portfolioId,
  };

  return {
    mutation: gql`
      mutation ($portfolioId: ID!) {
        deletePortfolio(portfolioId: $portfolioId) {
          entity {
            id
          }
          entityId
          id
          name
          propertyIds
        }
      }
    `,
    variables,
  };
};

export const deletePortfolio = createAsyncThunk('portfoliosListing/deletePortfolio', async ({ id }: { id: string }) => {
  const response = await getClientType().mutate(DELETE_PORTFOLIO(id));

  return response.data;
});
