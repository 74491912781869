/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ActionCreatorWithPayload, AsyncThunkPayloadCreatorReturnValue } from '@reduxjs/toolkit';
import { useRef } from 'react';
import { Image } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import { generateUniqueNum } from 'utils/utils-random';
import UploadImgLeft from 'assets/images/svg/upload-image-left-small.svg';
import UploadImgRight from 'assets/images/svg/upload-image-right-small.svg';
import { FILES_UPLOAD_ACTIONS } from '.';
import { allowedFileTypes } from './allowedFileTypes';
import './FileUpload.scss';

export interface NewFileForUploadProps {
  id: string;
  name: string;
  public: boolean;
  local: boolean;
  url: string;
  file: File;
  comment: string;
  dateSigned: string;
  insertedAt?: Date | string;
}

export enum UploadAreaSize {
  SMALL = 'small',
  BIG = 'big',
}

export interface UploadAreaProps {
  disabled?: boolean;
  onAdd?: ActionCreatorWithPayload<{
    files: NewFileForUploadProps[];
    [key: string]: any;
  }>;
  onAddFunctionParams?: { [key: string]: any };
  isMultipleUploads?: boolean;
  prepUpload: AsyncThunkPayloadCreatorReturnValue<
    any,
    {
      [key: string]: any;
    }
  >;
  prepUploadParams?: { [key: string]: any };
  hidden?: boolean;
  id?: string;
  fileTypesAllowed?: string[];
  uploadText?: string;
  size?: UploadAreaSize;
  onAddInTransaction?: ActionCreatorWithPayload<any, string>;
  onChange?: () => void;
  onChange2?: (updatedFiles: any[], action?: FILES_UPLOAD_ACTIONS) => void;
}

const UploadArea = ({
  disabled = false,
  onAdd,
  onAddFunctionParams,
  isMultipleUploads = false,
  prepUpload,
  prepUploadParams,
  hidden = false,
  id = '',
  fileTypesAllowed = allowedFileTypes,
  uploadText,
  size = UploadAreaSize.BIG,
  onAddInTransaction,
  onChange,
  onChange2,
}: UploadAreaProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const uploadFileRef = useRef<HTMLInputElement | null>(null);

  const openUploaded = () => {
    if (!disabled && uploadFileRef) {
      uploadFileRef.current?.click();
    }
  };

  const onAddAndPrepFiles = (files: NewFileForUploadProps[]): void => {
    if (onAddInTransaction) dispatch(onAddInTransaction({ files, ...onAddFunctionParams }));

    if (onAdd) dispatch(onAdd({ files, ...onAddFunctionParams }));

    onChange2 && onChange2(files, FILES_UPLOAD_ACTIONS.ADD);

    dispatch(prepUpload({ files, ...prepUploadParams }));

    if (onChange) {
      onChange();
    }
  };

  const onHandleChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;

    const files = Array.from(target.files || []).map((file: File) => {
      return {
        id: generateUniqueNum(),
        name: file.name,
        public: true,
        local: true,
        url: URL.createObjectURL(file),
        file: file,
        dateSigned: '',
        comment: '',
      };
    });

    if (uploadFileRef.current) {
      uploadFileRef.current.value = '';
    }

    onAddAndPrepFiles(files);
  };

  const onPreventDefault = (ev: React.DragEvent<HTMLDivElement>): void => {
    ev.preventDefault();
  };

  /**
   * Drop
   * @param ev
   */
  const onDrop = (ev: React.DragEvent<HTMLDivElement>): void => {
    onPreventDefault(ev);
    if (ev.dataTransfer.items && ev.dataTransfer.items.length) {
      const files: NewFileForUploadProps[] = ev.dataTransfer.items
        // @ts-ignore
        .map(item => {
          if (item.kind === 'file' && fileTypesAllowed.includes(item.type)) {
            const file = item.getAsFile();

            if (file) {
              return {
                id: generateUniqueNum(),
                name: file.name,
                public: true,
                local: true,
                url: URL.createObjectURL(file),
                file,
              };
            }
            return;
          }
        })
        .filter((file: NewFileForUploadProps) => file);

      if (files.length > 0) {
        onAddAndPrepFiles(files);
      }
    }
  };

  return (
    <div
      id={id}
      onClick={openUploaded}
      onDrop={onDrop}
      onDragOver={onPreventDefault}
      className={`dash-outlined upload-area ${disabled ? 'inactive' : 'active'} ${
        hidden ? 'hidden' : 'visible'
      } ${size}`}
    >
      <input
        ref={uploadFileRef}
        type="file"
        className="d-none"
        alt="upload"
        multiple={isMultipleUploads}
        disabled={disabled}
        accept={fileTypesAllowed.join()}
        onChange={e => onHandleChange(e)}
      />
      <Image
        className="img-left"
        src={UploadImgLeft}
      />
      <Image
        className="img-right"
        src={UploadImgRight}
      />

      <div
        className="d-flex p-sm"
        data-test="upload-file"
      >
        <span className="circle-m plus-icon">
          <Icon icon={Icons.Add} />
        </span>
        <span className="upload-file-area-title">{uploadText ? uploadText : 'Upload file'}</span>
      </div>
      <div className="text">Or drag and drop</div>
    </div>
  );
};

export default UploadArea;
