import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import Footer from 'layouts/Footer';
import DimmerLoader from 'atoms/DimmerLoader';
import AuthHeader from 'components/AuthHeader';
import { defaultToastContainerProps } from 'utils/utils-toast';
import './AuthLayout.scss';

export interface AuthLayoutProps {
  loading?: boolean;
  form?: ReactNode;
  signIn?: boolean;
  verify?: boolean;
}

const AuthLayout = ({ loading, form, signIn, verify }: AuthLayoutProps) => {
  return (
    <div className="auth-layout">
      <DimmerLoader status={loading} />
      <ToastContainer {...defaultToastContainerProps} />

      <div className="auth-left-panel p-xl">
        <AuthHeader
          signin={signIn}
          verify={verify}
        />
      </div>
      <div className="auth-right-panel p-xl">
        <div className="form-container">{form}</div>
        <Footer />
      </div>
    </div>
  );
};

export default AuthLayout;
