import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import FilesUpload, { FILES_UPLOAD_ACTIONS } from 'atoms/FormField/FilesUpload';
import {
  addContractDocs,
  deleteContractDocs,
  updateActiveContractEventInput,
} from 'store/contractsV2/contractDetailSliceV2';
import { prepContractDocsForUpload } from 'common/api/contracts2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const Documents = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const documents = activeContractEvent?.documents || [];

  const onChange = (value: any[], action?: FILES_UPLOAD_ACTIONS) => {
    if (action === FILES_UPLOAD_ACTIONS.ADD) {
      dispatch(updateActiveContractEventInput({ key: 'documents', value: [...value, ...documents] }));
    } else {
      dispatch(updateActiveContractEventInput({ key: 'documents', value }));
    }
  };

  return (
    <FilesUpload
      disabled={mode === MODE_OPTIONS.READ}
      prepUpload={prepContractDocsForUpload}
      // @ts-ignore
      documents={documents}
      onDelete={deleteContractDocs}
      onAdd={addContractDocs}
      onChange2={onChange}
    />
  );
};

export default Documents;
