import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetParameterUse } from 'store/parameters/parametersListingSlice';
import { checkParameterUse } from 'common/api/parameters';

interface ParameterUseTemplateProps {
  displayAsRow?: boolean;
  parameter_id: string;
}

const ParameterUseTemplate = ({ displayAsRow, parameter_id }: ParameterUseTemplateProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { parameterUse, parameterUseLoading } = useAppSelector((state: RootState) => state.parametersListing);

  useEffect(() => {
    // @ts-ignore
    if (parameter_id) dispatch(checkParameterUse({ id: parameter_id }));

    return () => {
      dispatch(resetParameterUse());
    };
  }, [parameter_id]);

  const provisionDetailsUrl = (id: string): string => `/provisions/${id}/details`;

  const onStopPropagation = (e: React.MouseEvent<HTMLElement>, id: string): void => {
    e.preventDefault();
    navigate(provisionDetailsUrl(id));
  };

  return (
    <div className={`provisions-use-display ${displayAsRow ? 'display_in_row' : ''}`}>
      {parameterUseLoading ? (
        <div className="loader-container">
          <Loader
            active={true}
            size="big"
          />
        </div>
      ) : (
        <>
          <div className={`list-column  ${displayAsRow ? 'm-t-sm' : 'm-t-none'}`}>
            <div className="table-title m-b-xs">
              <h3 className="m-r-xs">Translator</h3> used in &nbsp;
              {parameterUse.translateToContracts.length} translators
            </div>
            {parameterUse.translateToContracts.length > 0 && (
              <div className="table-wrapper">
                <table className="ui table dashboard-table provision-use">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Translator name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {parameterUse.translateToContracts.map((translateToContract, index) => (
                      <tr key={index}>
                        <td>{1 + index}</td>
                        <td>{translateToContract}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="list-column m-t-sm">
            <div className="table-title m-b-xs">
              <h3 className="m-r-xs">Node</h3> used in &nbsp;
              {parameterUse.nodes.length} nodes
            </div>
            {parameterUse.nodes.length > 0 && (
              <div className="table-wrapper">
                <table className="ui table dashboard-table provision-use">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Node name</th>
                      <th>Node type</th>
                      <th>Provision that uses node</th>
                    </tr>
                  </thead>

                  <tbody>
                    {parameterUse.nodes.map((node, index) => (
                      <tr key={index}>
                        <td>{1 + index}</td>
                        <td>{node.name}</td>
                        <td>{node.type}</td>
                        <td>
                          <a
                            href={provisionDetailsUrl(node.provision.id)}
                            onClick={e => onStopPropagation(e, node.provision.id)}
                          >
                            {node.provision.name}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="list-column m-t-sm">
            <div className="table-title m-b-xs">
              <h3 className="m-r-xs">Table</h3> used in &nbsp;
              {parameterUse.tables.length} tables
            </div>
            {parameterUse.tables.length > 0 && (
              <div className="table-wrapper">
                <table className="ui table dashboard-table provision-use">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Table name</th>
                      <th>Table type</th>
                    </tr>
                  </thead>

                  <tbody>
                    {parameterUse.tables.map((table, index) => (
                      <tr key={index}>
                        <td>{1 + index}</td>
                        <td>{table.name}</td>
                        <td>{table.type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ParameterUseTemplate;
