import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import AnswerTypes from 'common/model/AnswerTypes';

interface PrepPoliciesAnswerImageUploadQueryVariables {
  policyId: string | undefined;
  fileName: any;
  mimeType: any;
}

export const PREP_POLICIES_ANSWER_OF_TYPE_IMAGE_FOR_UPLOAD = (
  policyId: string | undefined,
  file: { name: any; type: any },
) => {
  const { name: fileName, type: mimeType } = file;

  const variables: PrepPoliciesAnswerImageUploadQueryVariables = {
    policyId,
    fileName,
    mimeType,
  };

  return {
    query: gql`
      query ($fileName: String!, $mimeType: String!, $policyId: ID!) {
        prepPolicyImageAnswer(policyId: $policyId, fileName: $fileName, mimeType: $mimeType) {
          cdnUrl
          uploadUrl
        }
      }
    `,
    variables,
  };
};

export const prepPolicyAnswerOfImageTypeForUpload = createAsyncThunk(
  'policies/prepPolicyAnswerOfImageTypeForUpload',
  async ({ index, paramRef: argParam }: { index: number; paramRef: any }, { getState }) => {
    const state = getState() as RootState;
    const {
      policyDetail: {
        activePolicy: { id: policyId },
        activePolicyAnswers,
      },
    } = state;

    const answerIndex = activePolicyAnswers.findIndex(
      ({ blockRef: answerIndex, paramRef, answerType }) =>
        answerType === AnswerTypes.Image &&
        answerIndex === index &&
        argParam.index === paramRef.index &&
        argParam.tableId === paramRef.tableId,
    );

    const { file } = activePolicyAnswers[answerIndex].answer;

    const response = await client.query(PREP_POLICIES_ANSWER_OF_TYPE_IMAGE_FOR_UPLOAD(policyId, file));

    return response;
  },
);
