import { useNavigate } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { preventReload } from 'utils/tsHelper';

interface MenuItemProps {
  linkTo: string;
  name: string;
  pathsToMatch: string[];
  dataTest: string;
  exactMatch?: boolean;
}

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    active?: string;
  }
}

const MenuItem = ({ linkTo, name, pathsToMatch, dataTest }: MenuItemProps) => {
  const navigate = useNavigate();
  const isActive = pathsToMatch.some(x => location.pathname.includes(x));
  return (
    <>
      <div
        className="circle-xs active-menu active-menu-position bg-green"
        active={isActive.toString()}
      />
      <Menu.Item
        content={name}
        href={linkTo}
        data-test={dataTest}
        active={isActive}
        onClick={e => {
          preventReload(e);
          navigate(linkTo);
        }}
      />
    </>
  );
};

export default MenuItem;
