import { store } from 'store';
import { Editor } from 'tinymce';
import { ToolbarButton } from 'components/ContextToolbar';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import {
  DiscussionMode,
  setDiscussionMode,
  setTransactionChannel,
  updateDiscussionModalStatus,
} from 'store/transactions/transactionDetailSlice';
import { ContentNodeType } from 'common/api/nodes';
import { listTransactionChannels } from 'common/api/transactions';
import { contextToolbarForceClose } from '../ContextClauseToolbar';

const addDiscussion: ToolbarButton = {
  id: 'addDiscussion',
  text: 'Add discussion',
  icon: 'comment',
  action: (editor: Editor) => {
    // Clicked node
    const node = editor.selection.getNode();
    const parent = node.parentNode as Element;
    if (parent) {
      const nodeId = parent.getAttribute('data-node-id') as string;
      store.dispatch(setTransactionChannel({ nodeId }));
      store.dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION));
      contextToolbarForceClose();
      store.dispatch(updateDiscussionModalStatus(true));
    }
  },
};
/*
  NOTE: There were was request to remove this menu item: 
  Leaving the code here in case we ever need to add it back
  https://www.notion.so/Various-Renaming-30a429a065404667b8f8b25a306e4b33?pvs=4

  const listDiscussions: ToolbarButton = {
    id: 'listDiscussions',
    text: 'List discussions',
    icon: 'eye',
    action: (_editor: Editor) => {
      store.dispatch(listTransactionChannels({}));
      store.dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION));
      store.dispatch(setDiscussionMode(DiscussionMode.List));
      contextToolbarForceClose();
    },
  };
*/

/** Function to build context-menu based on the defined array of ToolbarButtons
 * @param {Editor} editor
 */
const DiscussionToolbar = (editor: Editor) => {
  const contextButtons: ToolbarButton[] = [addDiscussion]; //, listDiscussions

  // Register every option. The option SHOULD be registered before used.
  for (let button of contextButtons) {
    // Add a button passing each object's attributes as parameters
    editor.ui.registry.addButton(button.id, {
      text: button.text,
      icon: button.icon,
      onAction: () => {
        button.action(editor);
      },
    });
  }

  // Map over each button and take it's id.
  const buttonsList: string = [''].concat(contextButtons.map(btn => btn.id)).join(' | ');

  // Context with Add Context
  editor.ui.registry.addContextToolbar('discussionToolbar', {
    /* Predicate returns a boolean
    Toolbar is shown accordingly */
    predicate: function (node) {
      const nodeType = node.getAttribute('data-node-type');

      if (!nodeType) return false;

      // Open only in the clause numbering
      return nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX);
    },
    items: buttonsList,
    position: 'selection',
    scope: 'node',
  });
};

export default DiscussionToolbar;
