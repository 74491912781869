import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { getAdminProfile, getProfile } from 'common/api/authentication';
import { checkIfAdminURL } from 'utils/utils-admin';
import ProfileTabs, { PROFILE_TABS_OFFSET } from './Tabs/ProfileTabs';

const UserProfilePage = () => {
  const { isLoading, activeTabIndex } = useAppSelector((state: RootState) => state.auth);
  const { isLoading: notificationsLoading } = useAppSelector((state: RootState) => state.notifications);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkIfAdminURL() ? getAdminProfile() : getProfile());
  }, [dispatch]);

  const isNotificationsTab: boolean = activeTabIndex === PROFILE_TABS_OFFSET.NOTIFICATIONS;

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Profile' }];
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageHeader={getPageHeaderProps()}
      pageSpinner={isLoading || notificationsLoading}
      flexHeight={isNotificationsTab}
    >
      <ProfileTabs />
    </PageLayout>
  );
};

export default UserProfilePage;
