import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ParameterCategoryBase, ParameterGroupClone } from 'common/_classes';
import { RootState } from 'store';
import { getClientType } from 'utils/utils-authentication';

interface ListParameterCategoriesAndGroupsQueryVariables {
  first: number;
  search: string | null;
}

const FIRST: number = 1000;

export const LIST_PARAMETER_CATEGORIES_AND_GROUPS = (search: string | null) => {
  const variables: ListParameterCategoriesAndGroupsQueryVariables = {
    first: FIRST,
    search,
  };

  return {
    query: gql`
      query ($first: Int, $search: String) {
        listFilteredParameterCategories: listFilteredParameterCategories(first: $first, filter: { search: $search }) {
          edges {
            cursor
            node {
              ${ParameterCategoryBase.fragments()}
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
        listFilteredParameterGroups: listFilteredParameterGroups(first: $first, filter: { search: $search }) {
          edges {
            cursor
            node {
              ${ParameterGroupClone.fragments()}
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listParameterCategoriesAndGroups = createAsyncThunk(
  'parameters/listParameterCategoriesAndGroups',
  async (_args, { getState }) => {
    const {
      parametersListing: { search },
    }: any = getState() as RootState;

    const response = await getClientType().query(LIST_PARAMETER_CATEGORIES_AND_GROUPS(search));
    return response;
  },
);
