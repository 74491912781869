import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateInputKey } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const PropertyOwner = ({ mode }: ModeProps) => {
  const { landlordEntitiesOptions, activeProperty } = useAppSelector((state: RootState) => state.propertyDetail);

  const { identification, entity } = activeProperty;
  const dispatch = useAppDispatch();

  const onChange = (key: string, value: string) =>
    dispatch(updateInputKey({ key, value, tab: 'property information' }));

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <SelectField
            label="Landlord"
            dataTest="landlord-legal-entity"
            value={entity.id}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select legal entity"
            options={landlordEntitiesOptions}
            search={false}
            required={mode === MODE_OPTIONS.EDIT}
            fieldKey="entity"
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <InputField
            label="Property identifier"
            placeholder="Type here..."
            dataTest="property-identifier"
            fieldKey="identification"
            value={identification}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PropertyOwner;
