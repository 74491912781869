import { Contract2InputClone } from 'common/_classes/contractsV2';
import moment from 'moment';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementDate,
  filterElementStatus,
  filterElementStatusTemplate,
  filterElementYesOrNo,
  filterElementYesOrNoTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import FloorsToolTip, { filterPremises } from 'components/FloorsToolTip';
import Contract2 from 'common/model/Contract2';
import RentDescription from 'common/model/RentDescription';
import { PremiseProps } from 'common/api/contracts';
import {
  getContractEndDate,
  getCurrentRent,
  getEffectiveRent,
  getRentPerAreaCellValue,
  getTotalArea,
} from 'utils/utils-contract';
import { addSpaceOrComma } from 'utils/utils-number';
import { getFormattedFloorsList } from 'utils/utils-premises';
import { ContractRowActionButtons } from './ContractRowActionButtons';

type TranslationInfo = {
  transaction: {
    id: string;
    identifier: string;
  };
};

export class ContractRow {
  id: string;
  identifier: string;
  property: string;

  tenant: string;
  termStartDate: Date | null;
  termEndDate: Date | null;

  constructor(contract: Contract2) {
    const event = contract.events[contract.events.length - 1];
    const premises = event.premises;

    this.id = contract.id;
    this.identifier = contract.identifier;
    const firstPremise = premises;
    this.property = firstPremise?.property?.buildingName ?? '';
    this.tenant = event?.tenant?.name ?? '';
    this.termStartDate = event?.startDate ? new Date(event.startDate) : null;
    this.termEndDate = getContractEndDate(event?.endDate, event?.terminationDate);
  }
}

const statuses: StatusBadgeArgument[] = [
  { value: 'REVIEWED', color: BadgeColor.GREEN },
  { value: 'TO BE REVIEWED', color: BadgeColor.RED },
];

const addTransactionLink = (translationInfo: TranslationInfo | null) => {
  // if (translationInfo !== null && translationInfo.transaction !== null) {
  //   return (
  //     <a href={`/transactions/${translationInfo.transaction.id}/details`}>{translationInfo.transaction.identifier}</a>
  //   );
  // } else {
  //   return '-N/A-';
  // }
  return '-N/A-';
};

/*
 * Definition of the column (names, format...)
 */
export const contractColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  return [
    {
      field: 'identifier',
      header: 'ID',
      filterPlaceholder: 'Search by ID',
      filter: true,
      filterField: 'identifier',
      sortable: true,
    },
    {
      field: 'property',
      header: 'Property',
      filter: true,
      filterPlaceholder: 'Search by Property',
      filterField: 'property',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'tenant',
      header: 'Tenant',
      filter: true,
      filterPlaceholder: 'Search by Tenant',
      filterField: 'tenant',
      sortable: true,
      dataType: 'text',
    },

    {
      field: 'termStartDate',
      header: 'Term Start Date',
      filter: true,
      filterPlaceholder: 'Search by start date',
      filterField: 'termStartDate',
      sortable: true,
      dataType: 'date',
      body: (row: ContractRow) => {
        return moment(row.termStartDate).format('DD/MM/YYYY');
      },
      filterElement: filterElementDate,
    },
    {
      field: 'termEndDate',
      header: 'Term End Date',
      filter: true,
      filterPlaceholder: 'Search by end date',
      filterField: 'termEndDate',
      sortable: true,
      body: (row: ContractRow) => {
        return moment(row.termEndDate).format('DD/MM/YYYY');
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },

    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: ContractRow) => (
        <ContractRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};

const getReviewDates = (rentDescriptions: RentDescription[]): Date[] => {
  return [new Date()];
  // return rentDescriptions
  //   .filter(rentDescription => rentDescription.rentReview)
  //   .map((rentDescription: RentDescription) => new Date(rentDescription.rentReview.date as Date));
};

const displayListOfDates = (dates: Date[]): string => {
  return dates.length > 0 ? dates.map(date => moment(date).format('DD/MM/YYYY')).join(', ') : '------';
};

const getOriginValue = (translationInfo: TranslationInfo | null): string => {
  // return translationInfo !== null && translationInfo.transaction !== null
  //   ? translationInfo.transaction.identifier
  //   : '-N/A-';
  return 'N/A';
};
