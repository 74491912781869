import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import SortOrder from 'common/model/SortOrder';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface ListPortfoliosQueryVariables {
  after?: string;
  before?: string;
  entityId: string;
  first?: number;
  last?: number;
  sortOrder: SortOrder;
}

export const LIST_PORTFOLIOS = (first?: number, last?: number, after?: string, before?: string) => {
  const entityId: string | null = getCurrentLandlordEntityId();

  if (!entityId) throw new Error('List Portfolios: entityId is required');

  const variables: ListPortfoliosQueryVariables = {
    entityId,
    first,
    last,
    after,
    before,
    sortOrder: SortOrder.Asc,
  };

  return {
    query: gql`
      query ($entityId: ID!, $first: Int, $last: Int, $after: String, $before: String, $sortOrder: SortOrder!) {
        listPortfolios(
          entityId: $entityId
          first: $first
          last: $last
          after: $after
          before: $before
          sortOrder: $sortOrder
        ) {
          edges {
            node {
              id
              name
              propertyIds
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listPortfolios = createAsyncThunk(
  'portfoliosListing/listPortfolios',
  async ({ first, last, after, before }: PaginationAPIProps) => {
    const response = await getClientType().query(LIST_PORTFOLIOS(first, last, after, before));

    return response.data;
  },
);
