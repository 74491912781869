import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './PDFBox.scss';

const PDFBox = () => {
  return (
    <div className="pdf-box border-sm-blue-very-dark bg-gray-very-light">
      <Icon icon={Icons.Pdf} />
    </div>
  );
};

export default PDFBox;
