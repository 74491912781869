import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import NodeType from 'common/model/NodeType';
import ParametersList from './ParametersList';
import ProvisionTab from './ProvisionTab';

const NodeControlTab = (): JSX.Element => {
  const { activeNode, provisionData } = useAppSelector((state: RootState) => state.nodes);
  const nodeType: NodeType | undefined = provisionData !== null ? provisionData.type : activeNode?.type;

  switch (nodeType) {
    case NodeType.Provision:
      return <ProvisionTab provisionData={provisionData} />;
    case NodeType.Clause:
    case NodeType.Parameter:
    case NodeType.Text:
      return <ParametersList />;
  }

  return <></>;
};

export default NodeControlTab;
