import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import Message, { MessageType } from './Message';

type GroupedMessages = {
  [date: string]: MessageType[];
};

const MessageEndIndicator = () => {
  return (
    <div className="message-end-indicator">
      <p>{`<----- Here's the bottom ----->`}</p>
    </div>
  );
};

const MessageList = ({ messages }: { messages: MessageType[] }) => {
  const groupMessagesByDate = (messages: MessageType[]): GroupedMessages => {
    return messages.reduce((acc: GroupedMessages, message: MessageType) => {
      const messageDate = moment(new Date(message.timestamp)).format('yyyy-MM-DD');

      if (!acc[messageDate]) {
        acc[messageDate] = [];
      }
      acc[messageDate].push(message);
      return acc;
    }, {});
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
    <div
      data-scroll-id="message-list"
      className="message-list"
    >
      {Object.keys(groupedMessages).map(date => {
        return (
          <div
            key={date}
            className="message-list__item"
          >
            <div className="message-list__date">
              <span>{date}</span>
            </div>
            {groupedMessages[date].map((msg, index) => {
              return (
                <Message
                  key={index}
                  {...msg}
                />
              );
            })}
          </div>
        );
      })}
      <MessageEndIndicator />
      <Loader size="mini">Loading</Loader>
    </div>
  );
};

export default MessageList;
