import PlaygroundPage from 'playground/views';
import LandlordEntitiesCreator from 'views/entities/landlordEntities/EntityCreator/EntityCreator';
import GraphTestPage from 'views/graphTestPage';
import KnowHow from 'views/know-how';
import TestPage from 'views/testPage';
import { checkIfAdminURL, checkIfTenantURL } from 'utils/utils-admin';
import AuthenticationRoutes from './AuthenticationRoutes';
import BenchmarkRoutes from './BenchmarkRoutes';
import ContractsRoutes from './ContractsRoutes';
import DashboardRoutes from './DashboardRoutes';
import FormattersRoutes from './FormattersRoutes';
import KnowHowRoutes from './KnowHow';
import LandlordEntitiesRoutes from './LandlordEntitiesRoutes';
import MiscellaneousRoutes from './MiscellaneousRoutes';
import ParametersRoutes from './ParametersRoutes';
import PolicyRoutes from './PolicyRoutes';
import PortfolioRoutes from './PorfolioRoutes';
import ProfileRoutes from './ProfileRoutes';
import PropertiesNewRoutes from './PropertiesNewRoutes';
import PropertiesRoutes from './PropertiesRoutes';
import ProvisionsRoutes from './ProvisionsRoutes';
import TenantRoutes from './TenantsRoutes';
import TransactionsRoutes from './TransactionsRoutes';
import TranslateToContractRoutes from './TranslateToContractRoutes';
import UserAccountManagementRoutes from './UserAccountManagementRoutes';
import WelcomeToSericinRoute from './WelcomeToSericin';
import { isAuthenticated } from './redirects';

const AllRoutes = () => {
  const commonUrls = [...AuthenticationRoutes(), ...WelcomeToSericinRoute(), ...ProfileRoutes(), ...KnowHowRoutes()];
  const isTenant = checkIfTenantURL();
  const isAdmin = checkIfAdminURL();
  let routes = [
    ...commonUrls,
    {
      path: '/graph-test',
      element: <GraphTestPage />,
    },
  ];
  if (isTenant) {
    routes = [...routes, ...TransactionsRoutes()];
  }

  if (isAdmin) {
    // Case when an admin wants to create an entity
    const createEntity = [
      {
        path: '/landlord-entities/create',
        element: isAuthenticated(<LandlordEntitiesCreator />),
      },
      {
        path: '/test',
        element: <TestPage />,
      },
      {
        path: '/playground',
        element: isAuthenticated(<PlaygroundPage />),
      },
    ];

    routes = [
      ...routes,
      ...createEntity,
      ...ParametersRoutes(),
      ...ProvisionsRoutes(),
      ...FormattersRoutes(),
      ...UserAccountManagementRoutes(),
      ...MiscellaneousRoutes(),
      ...PolicyRoutes(),
      ...TransactionsRoutes(),
      ...TranslateToContractRoutes(),
      ...PropertiesRoutes(),
      ...BenchmarkRoutes(),
    ];
  }
  if (!isTenant && !isAdmin) {
    routes = [
      ...routes,
      ...ContractsRoutes(),
      ...TransactionsRoutes(),
      ...UserAccountManagementRoutes(),
      ...PortfolioRoutes(),
      ...PolicyRoutes(),
      ...MiscellaneousRoutes(),
      ...DashboardRoutes(),
      ...LandlordEntitiesRoutes(),
      ...TenantRoutes(),
      ...PropertiesRoutes(),
      ...PropertiesNewRoutes(),
      ...BenchmarkRoutes(),
    ];
  }
  return routes;
};

export default AllRoutes;
