import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { BenchmarkInput } from 'common/api/benchmark';
import { deleteBenchmark, listBenchmarks } from 'common/api/benchmark';

const initialState = {
  benchmarksList: [] as BenchmarkInput[],
  isLoading: false,
};

const benchmarksListingSlice = createSlice({
  name: 'benchmarksListing',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Listing benchmarks
    builder.addCase(listBenchmarks.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listBenchmarks.fulfilled, (state, action) => {
      state.benchmarksList = action.payload.data.listBenchmarks;
      state.isLoading = false;
    });
    builder.addCase(listBenchmarks.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('Some error occurred when trying to loading the Benchmarks');
    });

    //deleteBenchmark
    builder.addCase(deleteBenchmark.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteBenchmark.fulfilled, (state, action) => {
      state.benchmarksList = state.benchmarksList.filter(
        // @ts-ignore
        obj => obj.id !== action.meta.arg.id,
      );
      state.isLoading = false;
      toast.success('Benchmark deleted successfully');
    });
    builder.addCase(deleteBenchmark.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });
  },
});

export default benchmarksListingSlice.reducer;
