import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { GENERATE_PORTFOLIO_REVENUE_ANALYSIS_BASE } from 'common/api/dashboard/forecasting';

export const generatePortfolioRevenueAnalysisBase = createAsyncThunk(
  'dashboardTab/generatePortfolioRevenueAnalysisBase',
  async (_args, { getState }) => {
    const {
      dashboardTab: {
        dateFilter: { startDate, endDate },
      },
      dashboard: { activePortfolio },
    } = getState() as RootState;

    const portfolioId: string | null = activePortfolio ? activePortfolio.id : null;

    const response = await client.query(GENERATE_PORTFOLIO_REVENUE_ANALYSIS_BASE(portfolioId, startDate, endDate));
    return response;
  },
);
