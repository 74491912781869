import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import {
  CContract2,
  CContractEvent,
  Contract2EventsInputClone,
  Contract2InputClone,
} from 'common/_classes/contractsV2';
import { RootState } from 'store';

export const CREATE_CONTRACT_AND_EVENT = (
  activeContract: Contract2InputClone | null,
  activeContractEvent: Contract2EventsInputClone | null,
) => {
  if (!activeContract) {
    throw new Error('Create Contract And Event: Contract could not be determined. Query aborted.');
  }

  if (!activeContractEvent) {
    throw new Error('Create Contract And Event: Event could not be determined. Query aborted.');
  }

  const currentLandlordEntityId = activeContractEvent.ownerId;

  if (!currentLandlordEntityId) {
    throw new Error('Create Contract And Event: Landlord Entity ID could not be determined. Query aborted.');
  }

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $contractParams: Contract2Input!, $eventParams: Contract2EventsInput!) {
        createContractAndEvent(currentLandlordEntityId: $currentLandlordEntityId, contractParams: $contractParams, eventParams: $eventParams) {
          ${CContract2.fragment()}
        }
      }
    `,
    variables: {
      currentLandlordEntityId,
      contractParams: CContract2.formatForAPI(activeContract),
      eventParams: CContractEvent.formatForAPI(activeContractEvent),
    },
  };
};

export const createContractAndEvent = createAsyncThunk(
  'contractDetailV2/createContractAndEvent',
  async (arg, { getState }) => {
    const {
      contractDetailV2: { activeContract, activeContractEvent },
    } = getState() as RootState;

    const response = await client.mutate(CREATE_CONTRACT_AND_EVENT(activeContract, activeContractEvent));
    return response;
  },
);
