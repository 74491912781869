import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { CFormatterList, CPageInfo } from 'common/_classes';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';

interface ListFormattersQueryVariables {
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  search?: string | null;
}

export const LIST_FORMATTERS = (
  searchValue: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const variables: ListFormattersQueryVariables = { first, last, after, before, search: searchValue };

  return {
    query: gql`
      query ($first: Int, $last: Int, $after: String, $before: String, $search: String) {
        listFormatters(first: $first, last: $last, after: $after, before: $before, filter: { search: $search }) {
          edges {
            cursor
            node {
              ${CFormatterList.fragment()}
            }
          }
          pageInfo {
            ${CPageInfo.fragments()}
          }
        }
      }
    `,
    variables,
  };
};

export const listFormatters = createAsyncThunk(
  'formatters/listFormatters',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      formattersListing: { search },
    } = getState() as RootState;
    const response = await adminClient.query(LIST_FORMATTERS(search, first, last, after, before));
    return response;
  },
);
