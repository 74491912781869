import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import Development from 'common/model/Development';

type UpdateDevelopmentParams = Omit<Development, 'id' | 'insertedAt' | 'updatedAt'>;

export const UPDATE_DEVELOPMENT = (id: string, params: UpdateDevelopmentParams) => {
  const variables = {
    id,
    params,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: DevelopmentInput!) {
        updateDevelopment(id: $id, params: $params) {
          address
          addressDistrict
          area
          developmentIdentifier
          developmentLotNumber
          developmentName
          entityId
          id
          insertedAt
          ownershipType
          updatedAt
        }
      }
    `,
    variables,
  };
};

export const updateDevelopment = createAsyncThunk(
  'propertiesV2/updateDevelopment',
  async (args: { id: string; params: UpdateDevelopmentParams }) => {
    const response = await client.mutate(UPDATE_DEVELOPMENT(args.id, args.params));
    return response;
  },
);
