import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface UpdateMemberUserQueryVariables {
  memberId: string;
}

export const UPDATE_MEMBER_USER = (memberId: string) => {
  if (!memberId) throw new Error('Update Member User: memberId is required');

  const variables: UpdateMemberUserQueryVariables = {
    memberId,
  };

  return {
    mutation: gql`
      mutation ($memberId: ID!) {
        updateMemberUser(memberId: $memberId) {
          id
          user {
            id
            photoUrl
            email
            firstName
            lastName
            phoneNumber
          }
          role {
            id
            name
            approvalLevel
          }
          entity {
            id
            name
          }
          status
        }
      }
    `,
    variables,
  };
};

export const updateMemberUser = createAsyncThunk('members/updateMemberUser', async () => {
  const url = new URL(window.location.href);
  const memberId = url.searchParams.get('member_id');
  const response = await client.mutate(UPDATE_MEMBER_USER(memberId as string));
  return response;
});
