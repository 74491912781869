import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { DefaultFilterRowProps } from '.';
import { STRING_FILTER_OPTIONS } from '../RowTemplate/RowFilterPopup/RowFilterContent/StringFilter';
import { FilterOnchangeKeys } from '../RowTemplate/RowProps';

const Building = ({ onChange, values }: DefaultFilterRowProps) => {
  return (
    <div className="grid">
      <div className="col-2 d-flex align-center">
        <label className="font-600">Building</label>
      </div>

      <div className="col-10">
        <div className="grid">
          <div className="col-5">
            <div className="grid">
              <div className="col-6">
                <SelectField
                  label="Value"
                  fieldKey={`${values.id},${FilterOnchangeKeys.filterMode}`}
                  options={STRING_FILTER_OPTIONS}
                  value={values.filterMode}
                  onChange={onChange}
                />
              </div>

              <div className="col-6">
                <InputField
                  label="Value"
                  fieldKey={`${values.id},${FilterOnchangeKeys.filterValue}`}
                  type={InputFieldType.TEXT}
                  value={values.filterValue}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Building;
