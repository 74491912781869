import { Dropdown, DropdownProps } from 'semantic-ui-react';
import './DropdownSelect.scss';

interface DropdownSelectProps extends Omit<DropdownProps, 'onChange'> {
  fieldKey?: string;
  disabled?: boolean;
  className?: string;
  dataTest?: string;
  onChange?: (key: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => void;
}

export const DropdownSelectTemplate = ({
  fieldKey = '',
  disabled,
  error,
  className,
  placeholder,
  dataTest,
  value,
  options,
  search,
  multiple,
  onChange,
  loading,
  onOpen,
  clearable,
  ...rest
}: DropdownSelectProps) => {
  // This is a temporary fix for the dropdown not closing issue
  // TO CHECK
  // ISSUE happened when packages were updated some time back
  //const conditionalSearch = search === true && options.length > 6 ? false : search;

  // Ensure value is an array when multiple is true, or undefined when it's not
  const dropdownValue = multiple ? value || [] : value;

  return (
    <Dropdown
      key={`${fieldKey}-key`}
      className={`dropdown-atom-select ${className}`}
      placeholder={placeholder}
      data-test={dataTest}
      value={dropdownValue}
      disabled={disabled}
      options={options}
      selection={true}
      search={search}
      multiple={multiple}
      onChange={(e, { value }) => onChange && onChange(fieldKey, value)}
      loading={loading}
      onOpen={onOpen}
      upward={false}
      clearable={clearable}
      {...rest}
    />
  );
};

const DropdownSelect = (props: DropdownSelectProps) => {
  return (
    <div className="dropdown-select-wrapper">
      <DropdownSelectTemplate {...props} />
    </div>
  );
};

export default DropdownSelect;
