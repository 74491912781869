import { AsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/transactions/transactionsListingSlice';
import { PaginationAPIProps } from 'common/api/miscellaneous';

const TransactionsHeaderToolbar = ({
  action,
}: {
  action: AsyncThunk<any, PaginationAPIProps, AsyncThunkConfig>;
}): JSX.Element => {
  const { search } = useAppSelector((state: RootState) => state.transactionsListing);

  const onGetData = (value?: string) => {
    return action({ searchValue: value, first: 1000 });
  };

  return (
    <SearchFilter
      search={search}
      onUpdateSearch={updateSearch}
      onGetData={onGetData}
    />
  );
};

export default TransactionsHeaderToolbar;
