import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import ModalTemplate from 'components/ModalTemplate';
import ParameterUseTemplate from 'views/parameters/ParameterUseTemplate';
import { deleteParameter } from 'common/api/parameters';
import { Icons } from 'utils/utils-icons';
import { ParametersListRow } from './ParameterListTableConfig';

interface ParametersRowActionButtonParams {
  row: ParametersListRow;
  onViewDetails: ViewDetailsProps;
}

export const ParametersRowActionButtons = ({ row, onViewDetails }: ParametersRowActionButtonParams) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);

  const onDelete = (id: string): void => {
    // @ts-ignore
    dispatch(deleteParameter({ id: id || '' }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Parameter',
      onClick: () => onViewDetails(row.id),
      dataTest: 'View Details',
    },
    {
      template: (
        <ModalTemplate
          isOpen={open}
          onToggleModalStatus={setOpen}
          key={row.id}
          title={row.name || ''}
          trigger={
            <button
              onClick={e => e.stopPropagation()}
              className="ui button"
              data-tooltip="Check Use"
              data-position="left center"
            >
              <Icon icon={Icons.EyeOpen} />
            </button>
          }
          children={
            <ParameterUseTemplate
              displayAsRow={false}
              parameter_id={row.id}
            />
          }
        />
      ),
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Parameter',
      onClick: () => onDelete(row.id),
      isPopup: true,
      popupText: 'Do you really want to delete this Parameter?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
