import { useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import DashboardTabs from './DashboardTabConfig';

const DashboardPage = () => {
  const { isDevelopmentsLoading } = useAppSelector(state => state.propertiesV2);
  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Owned Properties' }];

    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageHeader={getPageHeaderProps()}
      pageSpinner={isDevelopmentsLoading}
    >
      <DashboardTabs />
    </PageLayout>
  );
};

export default DashboardPage;
