import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterCategoryDetailClone } from 'common/_classes';
import { RootState } from 'store';

interface CreateParameterCategoryQueryVariables {
  params: any;
}

const CREATE_PARAMETER_CATEGORY = (activeCategory: ParameterCategoryDetailClone) => {
  const variables: CreateParameterCategoryQueryVariables = {
    params: activeCategory,
  };

  return {
    mutation: gql`
      mutation ($params: ParameterCategoryInput!) {
        createParameterCategory(params: $params) {
          ${ParameterCategoryDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createParameterCategory = createAsyncThunk(
  'parameters/createParameterCategory',
  async (_args, { getState }) => {
    const {
      parametersListing: { activeCategory },
    }: any = getState() as RootState;
    const response = await adminClient.mutate(CREATE_PARAMETER_CATEGORY(activeCategory));
    return response;
  },
);
