import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface SignInQueryVariables {
  email: string;
  password: string;
}

export const SIGN_IN = ({ email, password }: SignInQueryVariables) => {
  const variables: SignInQueryVariables = {
    email,
    password,
  };

  return {
    mutation: gql`
      mutation ($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          token
          user {
            email
            id
            firstName
            lastName
            countryCode
            phoneNumber
            photoUrl
            defaultEntity {
              id
            }
            members {
              entity {
                id
                name
              }
              status
              role {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables,
  };
};

export const signIn = createAsyncThunk('auth/signIn', async (_arg, { getState }) => {
  const {
    auth: { email, password },
  } = getState() as RootState;
  const response = await client.mutate(SIGN_IN({ email, password }));
  return response;
});
