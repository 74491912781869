import { forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';

const AIChatBotTrigger = forwardRef<HTMLDivElement, { className?: string; onClick: () => void }>(
  ({ className, onClick }, ref) => {
    return (
      <div
        ref={ref}
        className={`${className ? `${className} ` : ``}ai-chatbot-trigger`}
      >
        <button
          className="ai-chatbot-trigger__btn"
          onClick={onClick}
        >
          <Icon
            icon={Icons.Bot}
            className="icon"
          />
          <span className="sr-only">Open AI Chatbot</span>
        </button>
      </div>
    );
  },
);

export default AIChatBotTrigger;
