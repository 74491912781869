import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { DocumentIntendedToBeUploaded, ReviewStatusMutationType } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId, getReviewFlagAndReviewStatus } from 'utils/tsHelper';
import { uploadToAWS } from 'utils/utils-upload';

interface UpdatePropertyQueryVariables {
  currentLandlordEntityId: string;
  propertyId: string;
  params: {
    photo: string | null;
    buildingName: string;
    type: string;
    lotNumber: string;
    description: string;
    buildingGrade: string;
    address: string;
    identification: string;
    measurementUnit: string;
    measurementMethod: string;
    documents: DocumentIntendedToBeUploaded[];
    buildingIdentifier: string;
    buildingNumberOfFloors: number;
    buildingNumberOfUndergroundFloors: number;
    area: {
      gross: number;
      grossDescription: string;
      net: number;
      netDescription: string;
      lettable: number;
      lettableDescription: string;
      saleable: number;
      saleableDescription: string;
    };
    reviewFlag: boolean;
    reviewStatus: ReviewStatusMutationType;
    developmentName: string;
    developmentLotNumber: string;
    addressDistrict: string;
    buildingAddressDistrict: string;
    hasCentralAc: boolean;
    hasCurtainWall: boolean;
    hasMetroCoveredAccess: boolean;
  };
}

const UPDATE_PROPERTY = (
  activeProperty: any,
  propertyDocs: DocumentIntendedToBeUploaded[],
  reviewFlag: boolean,
  reviewStatus: ReviewStatusMutationType,
) => {
  const {
    id,
    photo,
    buildingName,
    type,
    lotNumber,
    description,
    buildingGrade,
    address,
    identification,
    measurementUnit,
    measurementMethod,
    buildingIdentifier,
    buildingNumberOfFloors,
    buildingNumberOfUndergroundFloors,
    area,
    developmentName,
    developmentLotNumber,
    addressDistrict,
    buildingAddressDistrict,
    hasCentralAc,
    hasCurtainWall,
    hasMetroCoveredAccess,
  } = activeProperty;

  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Update Property: currentLandlordEntityId is required');

  const variables: UpdatePropertyQueryVariables = {
    currentLandlordEntityId,
    propertyId: id,
    params: {
      photo,
      buildingName,
      type,
      lotNumber,
      description,
      buildingGrade,
      address,
      identification,
      measurementUnit,
      measurementMethod,
      documents: propertyDocs,
      buildingIdentifier,
      buildingNumberOfFloors,
      buildingNumberOfUndergroundFloors,
      area,
      reviewFlag,
      reviewStatus,
      developmentName,
      developmentLotNumber,
      addressDistrict,
      buildingAddressDistrict,
      hasCentralAc,
      hasCurtainWall,
      hasMetroCoveredAccess,
    },
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: PropertyInput!, $propertyId: ID!) {
        updateProperty(currentLandlordEntityId: $currentLandlordEntityId, params: $params, propertyId: $propertyId) {
          id
          entity {
            id
            name
          }
          photo
          buildingName
          buildingIdentifier
          buildingNumberOfFloors
          buildingNumberOfUndergroundFloors
          area {
            gross
            grossDescription
            net
            netDescription
            lettable
            lettableDescription
            saleable
            saleableDescription
          }
          type
          lotNumber
          buildingGrade
          address
          description
          identification
          measurementUnit
          measurementMethod
          documents {
            id
            name
            public
            url
            path
          }
          floors {
            id
            name
            index
            comments
            characteristics
            ceilingHeight
            floorLoading
            activationDate
            deactivationDate
            spaces {
              id
              name
              registeredName
              type
              usedForOccupancyRate
              grossArea
              netArea
              lettableArea
              saleableArea
              efficiencyRatio
              comments
              characteristics
              activationDate
              deactivationDate
            }
            floorPlans {
              id
              reviewFlag
              floorPlan {
                id
                name
                path
                public
                url
              }
              revisionDate
              unitsNumber
              whollyOwned
              grossArea
              netArea
              lettableArea
              saleableArea
              efficiencyRatio
            }
          }
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
          developmentName
          developmentLotNumber
          addressDistrict
          buildingAddressDistrict
          hasCentralAc
          hasCurtainWall
          hasMetroCoveredAccess
        }
      }
    `,
    variables,
  };
};

export const updateProperty = createAsyncThunk('properties/updateProperty', async (_arg, { getState }) => {
  const {
    propertyDetail: { file, uploadUrl, cdnUrl, deletePreviousPhoto, activeProperty, activePropertyFrozenCopy },
  } = getState() as RootState;

  const property = { ...activeProperty };

  if (file) {
    await uploadToAWS(uploadUrl, file);

    property.photo = cdnUrl;
  } else if (deletePreviousPhoto) {
    property.photo = null;
  }

  const propertyDocs = [...activeProperty.documents];

  if (propertyDocs.length > 0) {
    for (let i = 0; i < propertyDocs.length; i++) {
      const { local, uploadUrl, file, name, path, public: docPublic } = propertyDocs[i];

      if (local) {
        await uploadToAWS(uploadUrl, file);
      }

      propertyDocs.splice(i, 1, {
        name,
        path,
        public: local ? true : docPublic,
      });
    }
  }

  const [reviewFlag, reviewStatus] = getReviewFlagAndReviewStatus(activeProperty, activePropertyFrozenCopy);

  const response = await client.mutate(UPDATE_PROPERTY(property, propertyDocs, reviewFlag, reviewStatus));

  return response;
});
