import MProvisionContent from 'common/model/ProvisionContent';

export type TProvisionContentClone = Pick<MProvisionContent, 'id' | 'content' | 'documentTypeId'>;

export class ProvisionContentClone implements TProvisionContentClone {
  id: string;
  documentTypeId: string;
  content: string;

  constructor(input: TProvisionContentClone) {
    this.id = input.id;
    this.documentTypeId = input.documentTypeId;
    this.content = input.content;
  }

  static fragments(): string {
    return `
      id
      content
      documentTypeId
    `;
  }
}

export interface ProvisionContentCloneExtend extends ProvisionContentClone {
  documentName: string | undefined;
}
