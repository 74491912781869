import { Checkbox, Form } from 'semantic-ui-react';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementStatus,
  filterElementStatusTemplate,
} from 'atoms/TableReactPrime/columnTemplates';
import MemberStatus from 'common/model/MemberStatus';
import { EntitiesRowActionButtons } from './EntitiesRowActionButtons';

const statuses: StatusBadgeArgument[] = [
  { value: MemberStatus.Valid, color: BadgeColor.GREEN },
  { value: MemberStatus.Refused, color: BadgeColor.RED },
  { value: MemberStatus.Revoked, color: BadgeColor.RED },
  { value: MemberStatus.Invited, color: BadgeColor.ORANGE },
];
export class EntityRow {
  id: string;
  entityName: string;
  role: string;
  status: string;
  roleId: string;
  propertyId: string;

  constructor(entity: any) {
    this.id = entity.entity.id;
    this.entityName = entity.entity.name;
    this.roleId = entity.role.id;
    this.role = entity.role.name;
    this.status = entity.status;
    this.propertyId = entity.id;
  }
}

export const entityColumnConfig = (
  entityUserId: string,
  onSelectionChange: Function,
  defaultEntity: string | null,
): CustomColumnProps[] => {
  return [
    {
      field: 'id',
      header: 'Default at login',
      mandatory: true,
      filter: false,
      sortable: false,
      body: (row: EntityRow) => (
        <Form.Field className="field-style">
          <Checkbox
            className="check"
            radio={true}
            disabled={row.status !== MemberStatus.Valid}
            onChange={(e, data) => onSelectionChange(data.checked, row.id)}
            checked={row.id === entityUserId}
            value={row.id}
          />
        </Form.Field>
      ),
    },
    {
      field: 'entityName',
      header: 'Entity Name',
      mandatory: true,
      filter: true,
      filterPlaceholder: 'Search by Entity Name',
      filterField: 'entityName',
      sortable: true,
      dataType: 'text',
    },
    {
      field: 'role',
      header: 'User Role',
      mandatory: true,
      filter: true,
      filterPlaceholder: 'Search by User Role',
      filterField: 'role',
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      mandatory: true,
      filterPlaceholder: 'Search by Status',
      filter: true,
      filterField: 'status',
      sortable: true,
      width: '10rem',
      body: (row: EntityRow) => filterElementStatusTemplate(row.status, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: EntityRow) => (
        <EntitiesRowActionButtons
          row={row}
          defaultEntity={defaultEntity}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
