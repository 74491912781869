enum EntityType {
  Individual = 'INDIVIDUAL',
  Trustee = 'TRUSTEE',
  Partnership = 'PARTNERSHIP',
  Company = 'COMPANY',
  Coop = 'COOP',
  Unincorporated = 'UNINCORPORATED',
  Other = 'OTHER'
}
export default EntityType;
