import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface DeletePolicyAllAnswersQueryVariables {
  policyId: string;
}

export const DELETE_POLICY_ALL_ANSWERS = (policyId: string | undefined) => {
  if (!policyId) throw new Error('Delete Policy All Answers: Policy ID is required for deleting all answers');

  const variables: DeletePolicyAllAnswersQueryVariables = { policyId };

  return {
    mutation: gql`
      mutation ($policyId: ID!) {
        deletePolicyAllAnswers(policyId: $policyId)
      }
    `,
    variables,
  };
};

export const deletePolicyAllAnswers = createAsyncThunk(
  'policies/deletePolicyAllAnswers',
  async (_args, { getState }) => {
    const {
      policyDetail: {
        activePolicy: { id: policyId },
      },
    } = getState() as RootState;

    const response = await client.mutate(DELETE_POLICY_ALL_ANSWERS(policyId));
    return response;
  },
);
