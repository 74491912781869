import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterTableDetailClone } from 'common/_classes';
import { RootState } from 'store';
import Condition from 'common/model/Condition';
import { deepClone } from 'utils/tsHelper';

interface UpdateParameterTableQueryVariables {
  parameterTableId: string;
  params: any;
}

const UPDATE_PARAMETER_TABLE = ({
  id,
  name,
  description,
  index,
  rowNumber,
  type,
  columns,
  parameterGroup,
}: ParameterTableDetailClone) => {
  const columnsCopy = columns.map((column: any) => {
    const out: any = {
      id: column.id,
      index: column.index,
      title: column.title,
      parameterId: column.parameter.id,
      tableId: column.table.id,
    };
    if (column.conditions && column.conditions.length) {
      out.conditions = deepClone(column.conditions) || [];
      out.conditions = out.conditions.map((condition: any) => {
        let list = [];
        if (condition.list.length) {
          list = condition.list.map((x: Condition) => {
            return { ...x, answer: JSON.stringify(x.answer) };
          });
        }
        return { ...condition, list };
      });
    }
    return out;
  });

  let rowNumberObj;

  if (rowNumber !== null) {
    rowNumberObj = {
      parameterId: rowNumber.parameter ? rowNumber.parameter.id : null,
      tableId: rowNumber.table ? rowNumber.table.id : null,
    };
  }

  const params = {
    name,
    description,
    index,
    rowNumber: rowNumber !== null ? rowNumberObj : rowNumber,
    type,
    columns: columnsCopy,
    parameterGroupId: parameterGroup ? parameterGroup.id : null,
  };

  if (!id) {
    throw new Error('UPDATE_PARAMETER_TABLE, id is required');
  }

  const variables: UpdateParameterTableQueryVariables = {
    parameterTableId: id,
    params: params,
  };

  return {
    mutation: gql`
      mutation ($parameterTableId: ID!, $params: ParameterTableInput!) {
        updateParameterTable(parameterTableId: $parameterTableId, params: $params) {
          ${ParameterTableDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateParameterTable = createAsyncThunk('parameters/updateParameterTable', async (_args, { getState }) => {
  const {
    parameterDetail: { activeTableParameter },
  }: any = getState() as RootState;
  const response = await adminClient.mutate(UPDATE_PARAMETER_TABLE(activeTableParameter));
  return response;
});
