import { ChartType, TooltipModel } from 'chart.js';
import moment from 'moment';
import Scale from 'common/model/Scale';
import {
  ContextAnalysisData,
  PortfolioRevenueAnalysis,
  PropertyRevenueAnalysis,
  UnitAnalysisData,
} from 'common/api/dashboard';
import { DASHBOARD_PAGE_SCOPE } from 'common/api/dashboard';
import { sortByFloorIndexAndUnitName } from 'utils/tsHelper';
import { ColorNames, Colors } from 'utils/utils-colors';
import { invertDateFormatForGraph } from 'utils/utils-date';
import { addSpaceOrComma } from 'utils/utils-number';

const parseRate = (value: string): string => (parseFloat(value) * 100).toFixed(2);

const isVacant = (value: string) => !value || value === '0';
const getVacancyText = (isVacant: boolean) => (isVacant ? 'vacant' : 'occupied');

export const OccupancyRatePortfolioTooltip =
  (data: PortfolioRevenueAnalysis, period: Scale, tab: string) =>
  ({ title: [title] }: TooltipModel<ChartType>) => {
    const date: Date = invertDateFormatForGraph(title, period); // TODO why data.period is not defined ?

    const portfolioDataFiltered = data.portfolioData.find(
      item => item.month === moment(date).format('YYYY-MM-DD'),
    ) as ContextAnalysisData;

    const propertiesDataFiltered = data.propertiesData.filter(item => item.month === moment(date).format('YYYY-MM-DD'));

    return `
      <div class="inner-html occupancy-rate">
        <h4 class="title">Occupancy rate - ${title}</h4>

        <h3>Contractual occupancy rate :
          <span class="m-l-xs" style="color: ${Colors.GREEN}">
            ${parseRate(portfolioDataFiltered.statsArea.contractualOccupancyRate)}%
          </span>
        </h3>

        <h3>Forecasted occupancy rate :
          <span class="m-l-xs" style="color: ${Colors.BLUE_LIGHT}">
            ${parseRate(portfolioDataFiltered.statsArea.forecastOccupancyRate)}%
          </span>
        </h3>

        <div class="table-container">
          <table class="ui table dashboard-table occupancy-rate">
            <thead>
                <tr>
                  <th>Property</th>
                  <th>Total area (sqft, gross)</th>
                  <th>Contractual Occupancy (%)</th>
                  <th>Forecasted Occupancy (%)</th>
                </tr>
            </thead>

            <tbody>
              ${propertiesDataFiltered
                .map(
                  property =>
                    `<tr>
                  <td>
                    <a href="?context=${DASHBOARD_PAGE_SCOPE.PROPERTY}&propertyId=${property.propertyId}&propertyName=${
                      property.propertyName
                    }&tab=${tab}" target="_blank">
                      ${property.propertyName}
                    </a>
                  </td>
                  <td>
                    ${addSpaceOrComma(property.statsArea.area, false)}
                  </td>
                  <td>
                    ${addSpaceOrComma(property.statsArea.contractualArea, false)} (
                    ${parseRate(property.statsArea.contractualOccupancyRate)}%)
                  </td>
                  <td>
                    ${addSpaceOrComma(property.statsArea.forecastArea, false)} (
                    ${parseRate(property.statsArea.forecastOccupancyRate)}%)
                  </td>
                </tr>`,
                )
                .join('')}
            </tbody>
          </table>
        </div>
      </div>
    `;
  };

export const OccupancyRatePropertyTooltip =
  (data: PropertyRevenueAnalysis, period: Scale) =>
  ({ title: [title] }: TooltipModel<ChartType>) => {
    const date: Date = invertDateFormatForGraph(title, period); // TODO why data.period is not defined ?

    const propertyDataFiltered: ContextAnalysisData = data.propertyData.find(
      item => item.month === moment(date).format('YYYY-MM-DD'),
    ) as ContextAnalysisData;

    const units: UnitAnalysisData[] = data.unitsData.filter(item => item.month === moment(date).format('YYYY-MM-DD'));

    return `
    <div class="inner-html occupancy-rate">
      <h4 class="title">Occupancy rate - ${title}</h4>

      <h3>Contractual occupancy rate :
        <span class="m-l-xs" style="color: ${Colors.GREEN}">
          ${parseRate(propertyDataFiltered.statsArea.contractualOccupancyRate)}%
        </span>
      </h3>

      <h3>Forecasted occupancy rate :
        <span class="m-l-xs" style="color: ${Colors.BLUE_LIGHT}">
          ${parseRate(propertyDataFiltered.statsArea.forecastOccupancyRate)}%
        </span>
      </h3>

      <div class="table-container">
        <table class="ui table dashboard-table occupancy-rate">
          <thead>
              <tr>
                <th>Floor / Unit</th>
                <th>Total area (sqft, gross)</th>
                <th>Contractual status</th>
                <th>Forecasted status</th>
              </tr>
          </thead>

          <tbody>
            ${sortByFloorIndexAndUnitName(units)
              .map(unit => {
                const contractualAreaVacant = isVacant(unit.statsArea.contractualArea);
                const forecastAreaVacant = isVacant(unit.statsArea.forecastArea);
                return `
                <tr>
                  <td>
                    ${unit.floorName} <span class="m-l-xs">(${unit.unitName})</span>
                  </td>
                  <td class="align-right">
                    ${addSpaceOrComma(unit.statsArea.area, false)}
                  </td>
                  <td class="${contractualAreaVacant ? `${ColorNames.GREEN}` : ''}">
                    ${getVacancyText(contractualAreaVacant)}
                  </td>
                  <td class="${forecastAreaVacant ? `${ColorNames.GREEN}` : ''}">
                    ${getVacancyText(forecastAreaVacant)}
                  </td>
                </tr>
              `;
              })
              .join('')}
          </tbody>
        </table>
      </div>
    </div>
  `;
  };
