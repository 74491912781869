import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';

interface TabLabelProps {
  disabled: boolean;
  deletable: boolean;
  index: number;
  title: string;
  dateText?: string;
  onDelete: (index: number) => void;
  activeIndex: number;
  tabsLength: number;
  setTabIndex: (newIndex: number) => void;
}

const TabLabel = ({
  disabled,
  title,
  index,
  deletable,
  dateText,
  onDelete,
  activeIndex,
  tabsLength,
  setTabIndex,
}: TabLabelProps) => {
  const onDeleteClick = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (onDelete) {
      if (activeIndex === tabsLength - 1) {
        const newIndex = activeIndex - 1;
        setTabIndex(newIndex >= 0 ? newIndex : 0);
      }
      onDelete(index);
    }
  };

  return (
    <div className="d-flex align-center">
      {title} {index + 1}&nbsp;
      <span className="font-size-0_8571 color-blue-dark-grayish m-l-xs">{dateText && dateText}</span>
      {!disabled && deletable && (
        <Icon
          className="color-red-soft m-l-xl"
          icon={Icons.Delete}
          onClick={onDeleteClick}
        />
      )}
    </div>
  );
};

export default TabLabel;
