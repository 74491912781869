import React, { forwardRef } from 'react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';

const ChatBotModalContent = forwardRef<
  HTMLDivElement,
  {
    disabled: boolean;
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
    onClose: () => void;
    onSubmit: (e: any) => void;
    className?: string;
    children: React.ReactNode;
  }
>(({ disabled, isExpanded, setIsExpanded, onClose, onSubmit, className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={`chatbot-modal-inner${className ? ` ${className}` : ``}`}
    >
      <div className={`chatbot-modal-inner__header`}>
        <h4>Chatbot</h4>
        <div className={`chatbot-modal-inner__header-action`}>
          <div onClick={() => setIsExpanded(!isExpanded)}>
            <Icon
              icon={isExpanded ? Icons.MenuCollapse : Icons.MenuExpand}
              className="icon"
            />
          </div>
          <div onClick={onClose}>
            <Icon
              icon={Icons.MenuClose}
              className="icon"
            />
          </div>
        </div>
      </div>
      <div className={`chatbot-modal-inner__content`}>{children}</div>
      <div className={`chatbot-modal-inner__action`}>
        <form onSubmit={onSubmit}>
          <input
            name="message"
            type="text"
            placeholder="Ask any question about your portfolio:"
          />
          <button
            disabled={disabled}
            type="submit"
          >
            <Icon
              icon={Icons.Send}
              className="icon"
            />
            <span className="sr-only">Send</span>
          </button>
        </form>
      </div>
    </div>
  );
});

export default ChatBotModalContent;
