import { ContentNodeType } from 'common/api/nodes';

/**
 * This toggles the Condition on Clause
 *
 * It adds or removes the 'C' tag on the right side of the Index Numbering
 *
 * The Number at the final of the clause-index determines a new Styling
 */
export const insertRemoveConditionOnClause = (node: HTMLElement, hasConditions: boolean) => {
  // get the Current node ID
  const nodeId = node.getAttribute('data-node-id');

  // Query to find the Clause Index
  const queryId = 'div[data-node-id="' + nodeId + '"]';
  const queryIndex = queryId + ' > ' + 'div[data-node-type^="clause-index"]';

  // Get the Clause Index
  const elementIndex = node.querySelector(queryIndex);

  // Check if the Clause exist
  if (!elementIndex) return;

  // Query to check if condition tag already exists
  const queryCondition = 'div[data-condition="true"]';
  const existTag = elementIndex?.querySelector(queryCondition);

  // Check if NOT EXIST and will have a condition
  if (!existTag && hasConditions) {
    const { length } = Array.from(elementIndex?.childNodes || []);
    // clause-index-X is used in the tinymce-style.css file to add space around the 'C' of clauses with conditions
    elementIndex?.setAttribute('data-node-type', `clause-index-${length + 1}`);

    // Insert the C tag
    if (elementIndex) elementIndex.innerHTML += '<div data-condition="true">C</div>';
    // Check if EXIST and it wont have a condition
  } else if (existTag && !hasConditions) {
    // Remove ICON
    const node = elementIndex?.querySelector(queryCondition);
    node?.remove();

    // Update the element attribute
    elementIndex?.setAttribute('data-node-type', ContentNodeType.CLAUSE_INDEX);
  }
};
