/* eslint-disable jsx-a11y/label-has-associated-control */
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import EditorField from 'atoms/FormField/EditorField';
import TextAreaField from 'atoms/FormField/TextArea';
import ReviewBox, { ReviewBoxValues } from 'components/ReviewBox';
import { updateActiveFormatter, updateFormatterReviewedStatus } from 'store/formatters/formatterDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const FormatterDefinition = ({ mode }: ModeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeFormatter } = useAppSelector((state: RootState) => state.formatterDetail);

  const { name, example, description, reviewFlag, reviewStatus } = activeFormatter;

  const onChange = (key: string, value: string) => dispatch(updateActiveFormatter({ key, value }));

  const reviewBoxValues: ReviewBoxValues = {
    reviewFlag,
    reviewStatus,
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <TextAreaField
            label="Name"
            value={name}
            placeholder="Type here..."
            dataTest="formatter-name"
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="name"
          />
        </Grid.Column>
        <Grid.Column>
          <SectionComponent className="review-form">
            <ReviewBox
              mode={mode}
              values={reviewBoxValues}
              onChange={updateFormatterReviewedStatus}
            />
          </SectionComponent>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <EditorField
            label="Short description"
            placeholder="Type here..."
            value={example}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="example"
            height="120"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <EditorField
            label="Description"
            placeholder="Type here..."
            value={description}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="description"
            height="250"
            code={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FormatterDefinition;
