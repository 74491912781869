import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deleteBenchmark } from 'common/api/benchmark/deleteBenchmark';
import { Icons } from 'utils/utils-icons';

interface BenchmarkRowActionButtonsParams {
  rowId: string;
  onViewDetails: ViewDetailsProps;
}

export const BenchmarkRowActionButtons = ({ rowId, onViewDetails }: BenchmarkRowActionButtonsParams) => {
  const dispatch = useAppDispatch();

  const onDelete = (id: string) => {
    dispatch(deleteBenchmark({ id: id || '' }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Benchmark',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Benchmark',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Benchmark?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
