import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import PageLayout from 'layouts/PageLayout';
import { setActiveFloor, updateCurrentTab, updatePropertyFloorsTabView } from 'store/properties/propertyDetailSlice';
import { ViewProps } from 'common/api/miscellaneous';
import { getProperty, listLandlordEntityOptions } from 'common/api/properties';
import { PROPERTY_FLOORS_TAB_VIEW } from './Tabs/Floors';
import PropertyTabs, { PROPERTY_TABS_OFFSET } from './Tabs/PropertyTabs';

const extractPageQuery = (searchParams: URLSearchParams) => {
  const { tabIndex, floorId, view } = Object.fromEntries([...searchParams]);

  return {
    tabIndex: tabIndex ? parseInt(tabIndex) : undefined,
    floorId,
    view,
  };
};

const PropertyCreatorViewer = ({ isViewPage }: ViewProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ tabIndex, floorId, view }, setPageQuery] = useState<any>(extractPageQuery(searchParams));

  const { activeProperty, isLoading } = useAppSelector((state: RootState) => state.propertyDetail);

  useEffect(() => {
    dispatch(listLandlordEntityOptions());
    if (isViewPage) {
      // @ts-ignore
      dispatch(getProperty({ id }));
    }
  }, [id]);

  useEffect(() => {
    setPageQuery(extractPageQuery(searchParams));
  }, [searchParams]);

  useEffect(() => {
    const gotoFloorsTab = (tabIndex: PROPERTY_TABS_OFFSET | undefined, view: PROPERTY_FLOORS_TAB_VIEW | undefined) => {
      if (tabIndex === undefined || tabIndex !== PROPERTY_TABS_OFFSET.FLOORS_AND_UNITS || view === undefined) return;

      if (activeProperty?.id) {
        const activeFloor = activeProperty.floors.find((floor: any) => floor.id === floorId);

        if (activeFloor) {
          dispatch(setActiveFloor({ activeFloor }));
        }

        setSearchParams({});
      }

      dispatch(updatePropertyFloorsTabView({ view }));
      dispatch(updateCurrentTab({ tab: tabIndex }));
    };

    gotoFloorsTab(tabIndex, view);
  }, [dispatch, tabIndex, floorId, activeProperty]);

  const getPageHeaderProps = () => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Properties',
        url: '/properties/dashboard',
      },
      {
        title: activeProperty.buildingName || '',
      },
    ];
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <PropertyTabs isViewPage={isViewPage} />
    </PageLayout>
  );
};

export default PropertyCreatorViewer;
