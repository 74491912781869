enum ContractTerminationReason {
  Redevelopment = 'REDEVELOPMENT',
  Renovation = 'RENOVATION',
  Surrender = 'SURRENDER',
  Insolvency = 'INSOLVENCY',
  PaymentBreach = 'PAYMENT_BREACH',
  OtherBreach = 'OTHER_BREACH',
  Other = 'OTHER'
}
export default ContractTerminationReason;
