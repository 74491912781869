import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import { createNewBenchmark } from 'store/benchmark/benchmarkDetailSlice';
import { listBenchmarks } from 'common/api/benchmark';
import { listDocumentTypes } from 'common/api/miscellaneous';
import { listProvisionsAndCategories } from 'common/api/provisions';
import { BenchmarkRow, benchmarkColumnConfig } from './BenchmarkTableConfig';
import './BenchmarksDashboard.scss';

// import BenchmarkHeaderToolbar from './BenchmarkHeaderToolbar';

const BenchmarksDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, benchmarksList } = useAppSelector((state: RootState) => state.benchmarksListing);

  const onViewDetails = (id: string): void => {
    const url = `/benchmarks/${id}/details`;
    navigate(url);
  };

  useEffect(() => {
    dispatch(listProvisionsAndCategories({ first: 300 }));
    dispatch(listDocumentTypes({ first: 100 }));
  }, [dispatch]);

  /*
   * When click on "create" button: open the policy create page
   */
  const onCreateNewBenchmark = () => {
    dispatch(createNewBenchmark());
    navigate('/benchmarks/create');
  };

  /*
   * Page definition with
   *  - title,
   *  - filter,
   *  - create button for new benchmark,
   *  - listing of benchmarks including action button column.
   */

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Benchmarks' }];

    const createNewButton: CreateNewButtonProps = {
      onCreateNew: onCreateNewBenchmark,
      btnText: 'CREATE BENCHMARK',
      url: '/benchmarks/create',
      dataTest: 'create-benchmark-button',
    };
    // TODO: remove the comment below and integrate query search
    const headerButtons: PageHeaderToolbarProps = {
      // headerToolbar: <BenchmarkHeaderToolbar />,
      createNewButton,
    };
    return {
      showBreadCrumb: true,
      breadCrumb: breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      className="benchmarks-page"
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={() => {
          dispatch(listBenchmarks());
        }}
        isFetching={isLoading}
        content={benchmarksList.map(benchmark => new BenchmarkRow(benchmark))}
        columnConfig={benchmarkColumnConfig(onViewDetails)}
        dataTest="benchmark-table"
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default BenchmarksDashboard;
