import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteProvisionCategoryQueryVariables {
  id: string;
}

const DELETE_PROVISION_CATEGORY = (id: string) => {
  if (!id) throw new Error('Delete Provision Category: id is required');

  const variables: DeleteProvisionCategoryQueryVariables = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteProvisionCategory(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteProvisionCategory = createAsyncThunk(
  'provisions/deleteProvisionCategory',
  async ({ id }: { id: string }) => {
    const response = await adminClient.mutate(DELETE_PROVISION_CATEGORY(id));
    return response;
  },
);
