import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { initWorkflow } from 'store/transactions/transactionDetailSlice';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import {
  createTransaction,
  getLandlordTransaction,
  updateTransaction,
  updateTransactionWithInitalization,
} from 'common/api/transactions';
import { HistoryDocumentsProps, InitializationOptions } from 'common/api/transactions';
import { transactionDisabled } from 'utils/tsValidator';

interface TabToolbarProps {
  checkCreate: boolean;
  mode: MODE_OPTIONS;
  setMode: (mode: MODE_OPTIONS) => void;
}

/**
 * Tool bar for the setup tab handles Create and Update both mode
 * @param param0
 * @returns
 */
const SetupTabToolbar = ({ checkCreate, mode, setMode }: TabToolbarProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeTransaction, activeTransactionOldVersion } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );
  const { historyDocuments, documentTypeIds } = activeTransaction;
  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const checkIfAllSigned = (): boolean => {
    let signedDocs: number = 0;

    documentTypeIds.map(id => {
      const doc: HistoryDocumentsProps | undefined = historyDocuments.find(obj => obj.documentTypeId === id);
      if (doc) {
        const { history } = doc;
        /* MIGHT NOT BE NEEDED
        const { status } = history[history.length - 1];
        if ([ValidDocumentStatus.SignaturePending, ValidDocumentStatus.Signed].includes(status)) {
          signedDocs += 1;
        }*/
      }
    });

    if (documentTypeIds.length === 0) {
      toast.error('No documents have been selected');
      return true;
    }

    if (signedDocs === documentTypeIds.length) {
      toast.error('Initialization cannot be processed as all documents reached the SIGNATURE stage');
      return true;
    } else {
      return false;
    }
  };

  const initialise = ({ contents, parameters }: InitializationOptions): void => {
    if (!checkIfAllSigned()) {
      dispatch(initWorkflow({ userId }));
      dispatch(
        updateTransactionWithInitalization({
          contents,
          parameters,
        }),
      );
    }
  };

  const onCreateTransaction = (): void => {
    dispatch(createTransaction()).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        // @ts-ignore
        const { id } = response.payload.data.createTransaction;
        setMode(MODE_OPTIONS.READ);
        navigate(`/transactions/${id}/details`);
        // Init Transaction
        dispatch(initWorkflow({ userId }));
        dispatch(
          updateTransactionWithInitalization({
            transactionId: id,
            contents: true,
            parameters: {
              policy: true,
              inventory: true,
            },
          }),
        );
      }
    });
  };

  const onUpdateTransaction = () => {
    // Type 1: if Policy has changed => save + initialize content + initialize parameters
    if (activeTransaction.policyId !== activeTransactionOldVersion.policyId) {
      initialise({
        contents: true,
        parameters: {
          policy: true,
          inventory: true,
        },
      });
    }
    // Type 2 if (tenant or landlord) and document has changed => save + initialize content + initialize parameters
    // Type 3 if (tenant or landlord) and !document has changed => save + initialize parameters
    else if (
      activeTransaction.tenantId !== activeTransactionOldVersion.tenantId ||
      activeTransaction.ownerId !== activeTransactionOldVersion.ownerId
    ) {
      if (activeTransaction.documentTypeIds !== activeTransactionOldVersion.documentTypeIds) {
        initialise({
          contents: true,
          parameters: {
            policy: true,
            inventory: true,
          },
        });
      } else {
        initialise({
          contents: false,
          parameters: {
            policy: true,
            inventory: true,
          },
        });
      }
    }
    // Type 4: if premises have changed => save + initialize inventory answers
    else if (activeTransaction.premises !== activeTransactionOldVersion.premises) {
      initialise({
        contents: false,
        parameters: {
          policy: false,
          inventory: true,
        },
      });
    } else {
      // Type1 -> simple save
      dispatch(updateTransaction({}));
    }
  };

  /**
   * On Save button Click
   */
  const onSave = () => {
    if (checkCreate) {
      onCreateTransaction();
    } else {
      onUpdateTransaction();
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = () => {
    if (checkCreate) {
      navigate('/transactions/dashboard');
    } else {
      dispatch(getLandlordTransaction({ id: activeTransaction.id }));
    }
  };

  return (
    <DetailsControlButtons
      mode={mode}
      setMode={setMode}
      checkDisabled={transactionDisabled(activeTransaction)}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};

export default SetupTabToolbar;
