import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ExportTenantQuaeryVariables {
  currentLandlordEntityId: string;
}
export const EXPORT_TENANT = () => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Export Tenant: currentLandlordEntityId is required');

  const variables: ExportTenantQuaeryVariables = {
    currentLandlordEntityId,
  };
  return {
    query: gql`
      query ($currentLandlordEntityId: ID!) {
        exportTenant(currentLandlordEntityId: $currentLandlordEntityId)
      }
    `,
    variables,
  };
};

export const exportTenant = createAsyncThunk('entitesListing/exportTenant', async (_args: { landlordName: string }) => {
  const response = await client.query(EXPORT_TENANT());
  return response;
});
