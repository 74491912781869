import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { CFormatterDetail } from 'common/_classes/formatter/FormatterDetail';

interface GetFormatterQueryVariables {
  id: string;
}

export const GET_FORMATTER = (id: string) => {
  if (!id) throw new Error('GET_FORMATTER: id is required');

  const variables: GetFormatterQueryVariables = { id };

  return {
    query: gql`
      query ($id: ID!) {
        getFormatter(id: $id) {
          ${CFormatterDetail.fragment()}
        }
      }
    `,
    variables,
  };
};

export const getFormatter = createAsyncThunk('formatters/getFormatter', async ({ id }: { id: string }) => {
  const response = await adminClient.query(GET_FORMATTER(id));
  return response;
});
