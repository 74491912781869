import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeleteBenchmarkQueryVariables {
  benchmarkId: string;
}

export const DELETE_BENCHMARK = (id: string) => {
  if (!id) throw new Error('Delete Benchmark: Benchmark ID is missing');

  const variables: DeleteBenchmarkQueryVariables = {
    benchmarkId: id,
  };

  return {
    mutation: gql`
      mutation ($benchmarkId: ID!) {
        deleteBenchmark(benchmarkId: $benchmarkId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteBenchmark = createAsyncThunk('benchmarks/deleteBenchmark', async ({ id }: { id: string }) => {
  const response = await client.mutate(DELETE_BENCHMARK(id));
  return response;
});
