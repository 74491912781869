import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deleteFormatter } from 'common/api/formatters';
import { Icons } from 'utils/utils-icons';

interface FormatterRowActionButtonParams {
  rowId: string;
  onViewDetails: ViewDetailsProps;
}

export const FormatterRowActionButtons = ({ rowId, onViewDetails }: FormatterRowActionButtonParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = (id: string): void => {
    dispatch(deleteFormatter({ id }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Formatter',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Formatter',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Formatter?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
