import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { createNewProperty } from 'store/properties/propertyDetailSlice';
import { checkIfAdminURL } from 'utils/utils-admin';
import PropertiesHeaderToolbar from './PropertiesHeaderToolbar';
import PropertiesTable from './Table/PropertiesTable';

const PropertiesDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading } = useAppSelector((state: RootState) => state.propertyDetail);

  const newProperty = () => {
    dispatch(createNewProperty());
    navigate(`/properties/create`);
  };

  const isAdmin = checkIfAdminURL();

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Properties' }];

    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE PROPERTY',
      onCreateNew: newProperty,
      url: '/properties/create',
      dataTest: 'create-property',
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <PropertiesHeaderToolbar />,
      createNewButton: !isAdmin ? createNewButton : undefined,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <PropertiesTable />
    </PageLayout>
  );
};

export default PropertiesDashboard;
