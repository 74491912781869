import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { MODAL_MODE_OPTIONS } from 'common/api/miscellaneous';
import { createProvisionCategory, listProvisionsAndCategories, updateProvisionCategory } from 'common/api/provisions';
import { categoryValidator } from './categoryValidator';

interface CreateButtonProps {
  type: MODAL_MODE_OPTIONS;
  setModalStatus: (value: boolean) => void;
  dataTest?: string;
}

const CreateButton = ({ type, setModalStatus, dataTest }: CreateButtonProps) => {
  const dispatch = useAppDispatch();
  const { activeCategory } = useAppSelector((state: RootState) => state.provisionsListing);

  const isCreate: boolean = type === MODAL_MODE_OPTIONS.CREATE;

  const onSave = () => {
    const btnMethod = isCreate ? createProvisionCategory : updateProvisionCategory;

    dispatch(btnMethod()).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(listProvisionsAndCategories({ first: 300 }));
      }
    });
    setModalStatus(false);
  };

  return (
    <Button
      className="btn grey-bg invite-user"
      disabled={categoryValidator(activeCategory)}
      onClick={() => onSave()}
      data-test="category-create-button"
    >
      SAVE
    </Button>
  );
};

export default CreateButton;
