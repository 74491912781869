import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveProfileTab } from 'store/authentication/authSlice';
import Notification from 'common/model/Notification';
import { checkIfAdminURL } from 'utils/utils-admin';
import EntitiesTab from './Entities';
import InformationTab from './Information';
import NotificationsTab from './Notifications';
import PasswordTab from './Password';

export enum PROFILE_TABS_OFFSET {
  INFO = 0,
  PASSWORD = 1,
  ENTITIES = 2,
  NOTIFICATIONS = 3,
}

const getTabMenuConfig = (membersLength: number, unreadNotifications: number): TabMenuProps[] => {
  const tabs = [
    {
      key: '0',
      href: '?tab=information',
      label: 'Information',
      dataTest: 'nav-information-tab',
      customTab: <InformationTab />,
    },
    {
      key: '1',
      href: '?tab=password',
      label: 'Password',
      dataTest: 'nav-password-tab',
      customTab: <PasswordTab />,
    },
    {
      key: '2',
      href: '?tab=entities',
      label: `Entities (${membersLength})`,
      dataTest: 'nav-entities-tab',
      customTab: <EntitiesTab />,
    },
    {
      key: '3',
      href: '?tab=notifications',
      label: `Notifications ${unreadNotifications ? `(${unreadNotifications})` : ''}`,
      dataTest: 'nav-notifications-tab',
      customTab: <NotificationsTab />,
    },
  ];
  return checkIfAdminURL() ? tabs.slice(0, 2) : tabs;
};

const ProfileTabs = () => {
  const { activeTabIndex, members } = useAppSelector((state: RootState) => state.auth);
  const { notifications } = useAppSelector((state: RootState) => state.notifications);

  const unreadNotifications: Notification[] = notifications.filter(element => !element.seen);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateActiveProfileTab}
      tabMenu={getTabMenuConfig(members.length, unreadNotifications.length)}
    />
  );
};

export default ProfileTabs;
