import ExpansionRightRentAssessmentType from 'common/model/ExpansionRightRentAssessmentType';
import ExpansionRights from 'common/model/ExpansionRights';
import ExpansionRightsInput from 'common/model/ExpansionRightsInput';
import FloorStatus2Input from 'common/model/FloorStatus2Input';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';
import { convertDateToISO } from 'utils/utils-date';

export interface ExpansionRightsInputClone
  extends Omit<ExpansionRightsInput, 'wholeOrPart' | 'latestDate' | 'rentAssessment'> {
  wholeOrPart: WholeOrPartOptions | null;
  latestDate: Date | null;
  rentAssessment: ExpansionRightRentAssessmentType | null;
}
export class CExpansionRights implements ExpansionRightsInputClone {
  latestDate: Date | null;
  wholeOrPart: WholeOrPartOptions | null;
  rentAssessment: ExpansionRightRentAssessmentType | null;
  propertyId: string;
  floors: FloorStatus2Input[];

  constructor(input: ExpansionRights | null) {
    this.latestDate = input && input.latestDate ? new Date(input.latestDate) : null;
    this.wholeOrPart = input ? input.wholeOrPart : null;
    this.rentAssessment = input ? input.rentAssessment : null;
    this.propertyId = input ? input.property.id : '';
    this.floors = input ? input.floors : [];
  }

  static fragment() {
    return `
        latestDate
        wholeOrPart
        rentAssessment
        floors {
            floorId
            spaceIds
            whole
        }
        property {
          id
          buildingName
          floors {
            id
            name
            ceilingHeight
            spaces {
              id
              name
              grossArea
              netArea
              lettableArea
              saleableArea
            }
          }
        }
    `;
  }

  static formatForAPI(right: CExpansionRights) {
    return {
      latestDate: convertDateToISO(right.latestDate),
      wholeOrPart: right ? right.wholeOrPart : null,
      rentAssessment: right ? right.rentAssessment : null,
      propertyId: right ? right.propertyId : '',
      floors: right ? [...right.floors] : [],
    };
  }
}
