import { Button } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';

interface DetailsControlButtonsProps {
  /**
   * Get and set the mode of form
   */
  mode: MODE_OPTIONS;
  setMode: (mode: MODE_OPTIONS) => void;

  /**
   * To check save button disable status
   */
  checkDisabled: boolean;

  /**
   * On Save button Click
   */
  onSave?: () => void | (() => boolean) | Promise<void | boolean>;

  /**
   * On Cancel button Click
   */
  onCancel?: () => void;
}

const DetailsControlButtons = ({ mode, setMode, checkDisabled, onSave, onCancel }: DetailsControlButtonsProps) => {
  const onCancelHandler = () => {
    setMode(MODE_OPTIONS.READ);
    onCancel && onCancel();
  };

  const onSaveHandler = async () => {
    let changeToReadMode = true;

    if (onSave) {
      const response = await onSave();

      if (response === false) changeToReadMode = false;
    }

    if (changeToReadMode) setMode(MODE_OPTIONS.READ);
  };

  return (
    <div className="d-flex btns details-buttons">
      {mode === MODE_OPTIONS.READ ? (
        <Button
          className="btn grey-bg"
          data-test="edit-button"
          onClick={() => setMode(MODE_OPTIONS.EDIT)}
        >
          <Icon icon={Icons.Pencil}></Icon>
          EDIT
        </Button>
      ) : (
        <>
          <Button
            className="btn grey-outline"
            data-test="cancel-button"
            onClick={onCancelHandler}
          >
            CANCEL
          </Button>
          <Button
            className="btn grey-bg"
            data-test="save-button"
            onClick={onSaveHandler}
            disabled={checkDisabled}
          >
            SAVE
          </Button>
        </>
      )}
    </div>
  );
};

export default DetailsControlButtons;
