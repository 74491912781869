import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteUnusedNodesQueryVariables {
  provisionId: string;
}

export const DELETE_UNUSED_NODES = (provisionId: string) => {
  if (!provisionId) throw new Error('Delete Unused Nodes: provisionId is required');

  const variables: DeleteUnusedNodesQueryVariables = {
    provisionId,
  };

  return {
    mutation: gql`
      mutation ($provisionId: ID) {
        deleteUnusedNodes(provisionId: $provisionId) {
          result
        }
      }
    `,
    variables,
  };
};

export const deleteUnusedNodes = createAsyncThunk('nodes/deleteUnusedNodes', async (provisionId: string) => {
  const response = await adminClient.mutate(DELETE_UNUSED_NODES(provisionId));
  return response;
});
