import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { createPortfolio, getPortfolio, listProperties, updatePortfolio } from 'common/api/portfolio';
import { checkNotEmpty } from 'utils/tsValidator';
import PortfolioForm from './PortfolioForm';

const PortfolioCreatorViewer = () => {
  const { id: portfolioId } = useParams();
  const dispatch = useAppDispatch();
  const { activePortfolio, isLoading } = useAppSelector((state: RootState) => state.portfolioDetail);
  const location = useLocation();
  const checkCreate: boolean = location.pathname.includes('create');
  const [mode, setMode] = useState<MODE_OPTIONS>(checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listProperties(1000));

    if (!checkCreate && portfolioId) {
      dispatch(getPortfolio({ portfolioId }));
    }
  }, [portfolioId]);

  /**
   * On Save button Click
   */
  const onSave = () => {
    if (checkCreate) {
      dispatch(createPortfolio());
      navigate('/portfolios/dashboard');
    } else {
      dispatch(updatePortfolio());
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = () => {
    if (checkCreate) {
      navigate('/portfolios/dashboard');
    } else {
      if (portfolioId) dispatch(getPortfolio({ portfolioId }));
    }
  };

  const checkDisabled = (): boolean => {
    if (checkNotEmpty(activePortfolio?.name)) {
      return false;
    }
    return true;
  };

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Portfolios',
        url: '/portfolios/dashboard',
      },
      {
        title: activePortfolio?.name || '',
      },
    ];

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: (
        <DetailsControlButtons
          mode={mode}
          setMode={setMode}
          checkDisabled={checkDisabled()}
          onCancel={onCancel}
          onSave={onSave}
        />
      ),
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <PortfolioForm mode={mode} />
    </PageLayout>
  );
};

export default PortfolioCreatorViewer;
