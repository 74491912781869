import { Icons } from 'utils/utils-icons';
import { getCurrencyShow } from './tsHelper';
import { checkNotEmpty } from './tsValidator';

/*
 * getNumberWithSign() returns a string such as
 *   - for any positive or null number : "+52.3" or "+0.0"
 *   - for any negative number : "-52.3"
 * precision is to define how many decimal digits we want
 */
export const getNumberWithSign = (percent: number, precision: number): string => {
  return percent >= 0 ? `+${percent.toFixed(precision)}` : `${percent.toFixed(precision)}`; // already have the '-' sign
};

export const isPositive = (percent: number): boolean => {
  return percent >= 0;
};

export const getArrowDirection = (percent: number): string => {
  return percent === 0 ? Icons.ArrowThickRight : percent > 0 ? Icons.ArrowThickRightUp : Icons.ArrowThickRightDown;
};

export const getPercentageIncrease = (x: number, y: number): number => {
  if (x === 0 || y === 0) {
    return 0;
  }
  return ((y - x) / x) * 100;
};

export const getNumberWithOrdinal = (input: number): string => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const index = input % 100;
  return input + (suffix[(index - 20) % 10] || suffix[index] || suffix[0]);
};

/**
 * TODO: investigate what the desired output format for the efficiency ratio is?
 * TICKET: https://sericin-tech.atlassian.net/browse/DEV-1536
 */
export const getEfficiencyRatio = (value: number) => {
  if (value !== 0) {
    return (value * 100).toString().slice(0, 5);
  }
  return value;
};

export const convertToString = (value?: number) => {
  if (value === undefined || Number.isNaN(value)) {
    return '';
  } else {
    return value?.toString();
  }
};

export const countDecimals = (value: number | string): number => {
  const converted = value.toString();
  if (converted.includes('.')) {
    return converted.split('.')[1].length;
  }
  return 0;
};

export const getAmountWithCurrencySymbol = (value: string, currency: string = 'HK$'): string => {
  const newVal: string = removeDecimals(value);
  return (getCurrencyShow(newVal) ? `${currency} ` : '') + newVal;
};

export const getPercentage = (dividend: number, divisor: number, precision = 2): number => {
  if (dividend === 0 || divisor === 0) {
    return 0;
  }
  if (dividend > divisor) {
    return 100;
  }
  return Number(((dividend / divisor) * 100).toFixed(precision));
};

/**
 * Calculate Percentage
 * @param number
 * @param total
 * @returns
 */
export const calculatePercent = (number: number, total: number, precision: number = 2): number => {
  const output = number !== 0 && total !== 0 ? ((number / total) * 100).toFixed(precision) : 0;
  return Number(output);
};

export const isValidNumber = (value: any): boolean => {
  const validTypes = ['string', 'number'];

  if (!validTypes.includes(typeof value)) return false;

  if (!value || isNaN(parseInt(value))) return false;

  return true;
};

/* 
  Formats the value into space seperated or comma seperated value
  ex. comma -> 121232312 -> 121,232,312
  ex. space -> 121232312 -> 121 232 312
*/
export const addSpaceOrComma = (value: any, insertSpace: boolean = false): string => {
  if (value === null) {
    return '0';
  }

  if (value !== '-----' && checkNotEmpty(value)) {
    let formattedString: string;

    // Check if value is a string that can be converted to a number
    if (typeof value === 'string' && !isNaN(Number(value))) {
      formattedString = parseFloat(value).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        //@ts-ignore
        roundingMode: 'trunc',
      });

      if (value[value.length - 1] === '.') {
        formattedString += '.';
      }
    }
    // If value is already a number
    else if (typeof value === 'number') {
      formattedString = value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    }
    // Handle edge cases with ".", "-" etc.
    else if (value && ['.', '-'].includes(value[value.length - 1])) {
      formattedString = value.toLocaleString();
    }
    // Default case
    else {
      formattedString = Number(value).toLocaleString();
    }

    return insertSpace ? formattedString.replace(/,/g, ' ') : formattedString;
  }

  return value;
};

/*
  Removes the spaces and commas added
  ex. comma -> 121,232,312 -> 121232312
  ex. space -> 121 232 312 -> 121232312
*/
export const removeSpaceOrComma = (value: string): string => {
  if (checkNotEmpty(value)) {
    let newValue = value.replace(/\s/g, '');
    return newValue.replace(/,/g, '');
  } else {
    return value;
  }
};

/*
  Returns the integer part of a number by removing any fractional digits
  ex. 13.37 -> 13
*/
export const removeDecimals = (value: string): string => {
  const val = Math.trunc(parseFloat(removeSpaceOrComma(value)));
  return val ? addSpaceOrComma(`${val}`, false) : 'unavailable';
};

export enum NumberSuffix {
  LessThanThousand = '',
  Thousand = 'K',
  Million = 'M',
  Billion = 'B',
  Trillion = 'T',
}

export const shortenNumber = (number: number | string): { shortenedNumber: number; suffix: NumberSuffix } => {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  if (isNaN(number)) {
    return {
      shortenedNumber: number,
      suffix: NumberSuffix.LessThanThousand,
    };
  }

  const suffixes: NumberSuffix[] = [
    NumberSuffix.LessThanThousand,
    NumberSuffix.Thousand,
    NumberSuffix.Million,
    NumberSuffix.Billion,
    NumberSuffix.Trillion,
  ];

  let count = 0;

  while (number >= 1000) {
    number /= 1000;
    count++;
  }

  const shortenedNumber: number = parseFloat(number.toFixed(1));

  return {
    shortenedNumber,
    suffix: suffixes[count],
  };
};

function isNumber(value?: string | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
}
