import { SocketContext } from '@ericlathrop/phoenix-js-react-hooks';
import { useContext, useEffect, useRef, useState } from 'react';

// Our version of useChannel is a patch for the phoenix-js-react-hooks
// Keeping it in .js as its written in JS
export function useChannel(topic, params, onJoin) {
  const { socket } = useContext(SocketContext);
  const [channel, setChannel] = useState(null);

  const topicAndParams = `${topic}${JSON.stringify(params)}`;

  const onJoinFun = useRef(onJoin);
  onJoinFun.current = onJoin;

  useEffect(() => {
    // Add missing condition
    if (topic === null || socket === null) {
      return;
    }
    const ch = socket.channel(topic, params);
    ch.join().receive('ok', message => onJoinFun.current(ch, message));
    setChannel(ch);

    return () => {
      ch.leave();
      setChannel(null);
    };
  }, [socket, topicAndParams, topic, params]);

  return channel;
}

function pushPromise(push) {
  return new Promise((resolve, reject) => {
    if (!push) {
      return reject('no push');
    }
    push.receive('ok', resolve).receive('error', reject);
  });
}

export function sendMessage(channel, event, payload) {
  return pushPromise(channel.push(event, payload));
}
