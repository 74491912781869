import Notification from 'common/model/Notification';
import NotificationType from 'common/model/NotificationType';
import ApprovalAccepted from './ApprovalAccepted';
import ApprovalRefused from './ApprovalRefused';
import ApprovalRequest from './ApprovalRequest';
import EntityInvited from './EntityInvited';
import EntityRevoke from './EntityRevoke';

const NotitificationRowType = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.EntityInvited:
      return <EntityInvited {...notification} />;
    case NotificationType.EntityRevoke:
      return <EntityRevoke {...notification} />;
    case NotificationType.ApprovalRequest:
      return <ApprovalRequest {...notification} />;
    case NotificationType.ApprovalAccepted:
      return <ApprovalAccepted {...notification} />;
    case NotificationType.ApprovalRefused:
      return <ApprovalRefused {...notification} />;
    default:
      return <></>;
  }
};

export default NotitificationRowType;
