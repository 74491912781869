import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import FilesUpload from 'atoms/FormField/FilesUpload';
import { addSupportingFiles, deleteSupportingFiles } from 'store/entities/entityDetailSlice';
import { prepEntityDocsForUpload } from 'common/api/entities';

const SupportingDocs = (): JSX.Element => {
  const { activeEntity } = useAppSelector((state: RootState) => state.entityDetail);

  return (
    <>
      <h2 className="color-blue-very-dark">Files to upload</h2>
      <Grid className="m-t-sm w-60">
        <Grid.Row className="p-none">
          <Grid.Column
            width="16"
            className="p-none"
          >
            <FilesUpload
              prepUpload={prepEntityDocsForUpload}
              documents={activeEntity.documents}
              onDelete={deleteSupportingFiles}
              onAdd={addSupportingFiles}
              isCreatorPage={true}
              onAddFunctionParams={{}}
              prepUploadParams={{}}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default SupportingDocs;
