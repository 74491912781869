import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CreateNewButton, { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import ExportDropdown from 'atoms/ExportDropdown';
import ImportDropdown from 'atoms/ImportDropdown';
import ImportReport from 'atoms/ImportReport';
import { DataObjectName } from 'atoms/ImportReport/utils-interfaces';
import SearchFilter from 'components/SearchFilter';
import { clearImportedProperty, updateSearch } from 'store/properties/propertiesListingSlice';
// import { newDevelopment } from 'store/propertiesV2/propertiesV2Slice';
import { DocumentsType } from 'common/api/contracts';
import { exportProperty, importProperty } from 'common/api/properties';
import { listProperties, listPropertiesForAdmin } from 'common/api/properties';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { checkIfAdminURL } from 'utils/utils-admin';
import PropertyDownloadTemplate from './PropertyDownloadTemplate';

const DevelopmentHeaderToolbar = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAdmin = checkIfAdminURL();

  const { importedProperty, isExporting, exportFail, exportSuccess, search } = useAppSelector(
    (state: RootState) => state.propertiesListing,
  );

  const currentLandlordEntityId = getCurrentLandlordEntityId();

  const onImportProperty = (simulation: boolean, file: DocumentsType) => {
    // @ts-ignore
    dispatch(importProperty({ currentLandlordEntityId, simulation, file }));
  };

  const onExportProperty = ({ propertyId }: { startDate?: string; endDate?: string; propertyId?: string }) => {
    // @ts-ignore
    dispatch(exportProperty({ id: propertyId }));
  };

  const clearReport = () => {
    dispatch(clearImportedProperty());
  };

  const onGetData = (value?: string) => {
    const getProperties = isAdmin ? listPropertiesForAdmin : listProperties;
    // @ts-ignore
    return getProperties({ searchValue: value, first: 1000 });
  };

  const onNewDevelopment = () => {
    navigate(`/properties-new/create`);
  };

  const createNewButton: CreateNewButtonProps = {
    btnText: 'CREATE DEVELOPMENT',
    onCreateNew: onNewDevelopment,
    url: '#',
  };

  const exportTemplate = (
    <PropertyDownloadTemplate
      onExport={onExportProperty}
      isExporting={isExporting}
      exportSuccess={exportSuccess}
      exportFail={exportFail}
    />
  );

  return (
    <div className="d-flex w-100 py-2">
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      {!isAdmin && (
        <>
          <ImportDropdown
            onImport={onImportProperty}
            className="m-r-xs"
          />
          <ExportDropdown
            onExport={onExportProperty}
            isExporting={isExporting}
            exportSuccess={exportSuccess}
            exportFail={exportFail}
            template={exportTemplate}
            className="m-r-xs"
          />
          <CreateNewButton {...createNewButton} />
        </>
      )}
      {importedProperty && (
        <ImportReport
          className="m-t-l"
          importReport={importedProperty}
          clearReport={clearReport}
          dataObjectName={DataObjectName.PROPERTY}
        />
      )}
    </div>
  );
};

export default DevelopmentHeaderToolbar;
