import UnderConstructionContent from 'atoms/UnderConstruction';
import TabContainer from 'components/TabContainer';

const ApprovalsTab = () => {
  return (
    <TabContainer tabTitle={'Approvals'}>
      <UnderConstructionContent showBackButton={false} />
    </TabContainer>
  );
};

export default ApprovalsTab;
