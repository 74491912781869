import { ReactNode } from 'react';
import CardColumnHeader from '../CardColumnHeader';
import { DefaultColumnT } from '../CardViewDefaultTypes';
import './CardColumn.scss';

export interface ColumnConfiguration<ColumnT> {
  header: {
    titleField: string;
    showEdit?: boolean;
    onEdit?: (category: ColumnT) => void;
    onDelete?: (id: string) => void;
    showDelete?: (category: ColumnT) => boolean | undefined;
  };
  width?: string;
}
interface CardColumnProps<ColumnT> {
  children: ReactNode;
  category: ColumnT & DefaultColumnT;
  columnConfiguration: ColumnConfiguration<ColumnT>;
}

function CardColumn<ColumnT>({ children, category, columnConfiguration }: CardColumnProps<ColumnT>) {
  const header = (
    <CardColumnHeader
      // @ts-ignore
      title={category[columnConfiguration.header.titleField] || 'No Name'}
      showEdit={columnConfiguration.header.showEdit}
      onEdit={() => columnConfiguration.header.onEdit && columnConfiguration.header.onEdit(category)}
      showDelete={columnConfiguration.header.showDelete && columnConfiguration.header.showDelete(category)}
      onDelete={() => columnConfiguration.header.onDelete && columnConfiguration.header.onDelete(category.id)}
    />
  );

  return (
    <div
      className="card-column-container"
      data-test="card-column-container"
      style={{ width: columnConfiguration.width || '20rem' }}
    >
      {header}
      <div className="card-column-body">{children}</div>
    </div>
  );
}

export default CardColumn;
