import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { CPremise, CRentFreePeriod, CRentReview } from 'common/_classes/contractsV2';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';

export const CALCULATE_AND_UPDATE_RENT_REVIEW_CALCULATED_FIELDS = (
  leaseStartDate: Date | null | undefined,
  leaseEndDate: Date | null | undefined,
  rentReviews?: CRentReview[],
  rentFreePeriods?: CRentFreePeriod[],
  premises?: CPremise,
) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error(
      'Calculate And Update Rent Review Calculated Fields: Landlord Entity could not be determined. Query aborted.',
    );
  }

  if (!rentReviews) {
    throw new Error(
      'Calculate And Update Rent Review Calculated Fields: rentReviews could not be determined. Query aborted.',
    );
  }

  if (!rentFreePeriods) {
    throw new Error(
      'Calculate And Update Rent Review Calculated Fields: rentFreePeriods could not be determined. Query aborted.',
    );
  }

  if (!premises) {
    throw new Error(
      'Calculate And Update Rent Review Calculated Fields: premises could not be determined. Query aborted.',
    );
  }

  const queryVariables = {
    currentLandlordEntityId,
    leaseStartDate: convertDateToISO(leaseStartDate),
    leaseEndDate: convertDateToISO(leaseEndDate),
    rentReviews: rentReviews.map(review => CRentReview.formatForAPI(review)),
    rentFreePeriods: rentFreePeriods.map(freePeriod => CRentFreePeriod.formatForAPI(freePeriod)),
    premises: CPremise.formatForAPI(premises),
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $leaseEndDate: Date
        $leaseStartDate: Date
        $rentFreePeriods: [RentFreePeriodWithNullableDatesInput]!
        $rentReviews: [RentReviewInput]!
        $premises: Premise2Input!
      ) {
        calculateAndUpdateRentReviewCalculatedFields(
          currentLandlordEntityId: $currentLandlordEntityId
          leaseEndDate: $leaseEndDate
          leaseStartDate: $leaseStartDate
          rentFreePeriods: $rentFreePeriods
          rentReviews: $rentReviews
          premises: $premises
        ) {
            ${CRentReview.fragment()}
        }
      }
    `,
    variables: queryVariables,
  };
};

export const calculateAndUpdateRentReviewCalculatedFields = createAsyncThunk(
  'contractDetailV2/calculateAndUpdateRentReviewCalculatedFields',
  async (_arg, { getState }) => {
    const {
      contractDetailV2: { activeContractEvent },
    } = getState() as RootState;

    const response = await client.query(
      CALCULATE_AND_UPDATE_RENT_REVIEW_CALCULATED_FIELDS(
        activeContractEvent?.startDate,
        activeContractEvent?.endDate,
        activeContractEvent?.rentReview,
        activeContractEvent?.rentFreePeriods,
        activeContractEvent?.premises,
      ),
    );
    return response;
  },
);
