import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import SortOrder from 'common/model/SortOrder';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface ListPropertiesQueryVariables {
  currentLandlordEntityId: string;
  sortOrder: SortOrder;
  first: number;
}

// TODO maybe this should be in miscalaneous ?
export const LIST_PROPERTIES = (first: number) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List Properties: currentLandlordEntityId is required');

  const variables: ListPropertiesQueryVariables = {
    currentLandlordEntityId,
    sortOrder: SortOrder.Asc,
    first: first,
  };
  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $sortOrder: SortOrder!, $first: Int) {
        listProperties(currentLandlordEntityId: $currentLandlordEntityId, sortOrder: $sortOrder, first: $first) {
          edges {
            cursor
            node {
              id
              buildingName
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listProperties = createAsyncThunk('portfoliosListing/listProperties', async (first: number) => {
  const response = await getClientType().query(LIST_PROPERTIES(first));

  return response;
});
