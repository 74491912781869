import {
  HistoryGeneralStatus,
  TransactionListingClone,
  TransactionListingPremise,
  TransactionListingProvision,
} from 'common/_classes';
import moment from 'moment';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterElementDate,
  filterElementStatus,
  filterElementStatusTemplate,
  filterElementStatusTemplateV2,
} from 'atoms/TableReactPrime/columnTemplates';
import Tooltip from 'atoms/Tooltip';
import FloorsToolTip, { filterPremises } from 'components/FloorsToolTip';
import ListOfProvisions from 'components/ListOfProvisions';
import HistoryDocuments from 'common/model/HistoryDocuments';
import TransactionHistoryGeneralStatus from 'common/model/TransactionHistoryGeneralStatus';
import ValidDocumentStatus from 'common/model/ValidDocumentStatus';
import { DocumentTypeClone } from 'common/api/miscellaneous';
import { TranslationInfoProps } from 'common/api/transactions';
import { TransactionPhaseStatus } from 'utils/UI';
import { getDocumentName } from 'utils/tsHelper';
import { getFormattedFloorsList } from 'utils/utils-premises';
import { TransactionRowActionButtons } from './TransactionRowActionButtons';

// Definition of the badge's color for the transaction status
export const listTransactionStatuses: StatusBadgeArgument[] = [
  { value: TransactionPhaseStatus.Setup, color: BadgeColor.BLUE },
  { value: TransactionPhaseStatus.Negotiation, color: BadgeColor.ORANGE },
  { value: TransactionPhaseStatus.PostCompletion, color: BadgeColor.ORANGE },
  { value: TransactionPhaseStatus.Closed, color: BadgeColor.GREEN },
  { value: TransactionPhaseStatus.Aborted, color: BadgeColor.RED },
  { value: TransactionPhaseStatus.Reinstated, color: BadgeColor.ORANGE },
  { value: TransactionPhaseStatus.Null, color: BadgeColor.RED },
];
export class TransactionRow {
  id: string;
  identifier: string;
  owner: string;
  policy: string;
  provisions: TransactionListingProvision[];
  leasingManager: string;
  tenant: string;
  building: string;
  premises: TransactionListingPremise;
  dateOpened: Date | null;
  dateClosed: Date | null;
  historyDocuments: HistoryDocuments[];
  status: TransactionPhaseStatus;
  floorNames: string;
  translationInfo: TranslationInfoProps;
  contract: string;
  documentTypeIds: string[];

  constructor(transaction: TransactionListingClone) {
    const status: TransactionPhaseStatus = TransactionRow.getPhaseStatus(
      transaction.historyGeneral,
      transaction.historyDocuments,
    );
    const premises: TransactionListingPremise = transaction?.premises[0] ?? null;
    this.id = transaction.id as string;
    this.identifier = transaction?.identifier as string;
    this.owner = transaction?.owner?.name;
    this.policy = transaction.policy?.name;
    this.provisions = transaction?.provisions ?? [];
    this.floorNames = getFormattedFloorsList(transaction?.premises[0] ?? null);
    this.leasingManager = '';
    this.tenant = transaction?.tenant?.name || '';
    this.dateOpened = transaction.insertedAt ? new Date(transaction?.insertedAt) : null;
    this.dateClosed = TransactionRow.getCompletionDate(transaction?.historyGeneral);
    this.building = premises?.property?.buildingName ? premises.property.buildingName : '';
    this.premises = premises;
    this.historyDocuments = transaction?.historyDocuments ?? [];
    this.translationInfo = transaction?.translationInfo!;
    this.contract = TransactionRow.getContractValue(transaction?.translationInfo!, status);
    this.status = status;
    this.documentTypeIds = transaction.documentTypeIds;
  }

  // Returns the completion Date only if the transaction is closed, e.g. last 'history general' has status 'closed'
  static getCompletionDate = (historyGeneral: HistoryGeneralStatus[]): Date | null => {
    if (historyGeneral.length !== 0) {
      //get status of last general history
      const historyGeneralLast: HistoryGeneralStatus = historyGeneral.at(-1)!;
      if (historyGeneralLast.status === TransactionHistoryGeneralStatus.Closed) {
        return historyGeneralLast.date ? new Date(historyGeneralLast.date) : null;
      }
    }
    return null;
  };

  // Returns the identifier only if the transaction is closed, e.g. last 'history general' has status 'closed'
  static getContractValue = (translationInfo: TranslationInfoProps, status: string): string => {
    return status === TransactionPhaseStatus.Closed && translationInfo !== null && translationInfo.contract
      ? translationInfo.contract.identifier
      : '-N/A-';
  };

  // The phases are defined as following:
  //  - 'Setup' when transaction is opened
  //  - 'Setup' when transaction is in Setup and all documents are still in Setup mode too
  //  - 'Negotiation' when transaction is in Setup and some documents are no longer in Setup mode too
  //  - 'PostCompletion' when transaction is in PostCompletionPending
  //  - 'Closed' when transaction is in 'Closed'
  //  - 'Aborted' when transaction is in 'Aborted'
  //  - 'Reinstated' when transaction is in 'Reinstated'
  static getPhaseStatus = (
    historyGeneral: HistoryGeneralStatus[],
    historyDocuments: HistoryDocuments[],
  ): TransactionPhaseStatus => {
    const size: number = historyGeneral.length;

    if (size > 0) {
      const { status } = historyGeneral[size - 1];

      if (status === TransactionHistoryGeneralStatus.Opened) return TransactionPhaseStatus.Setup;

      //if the last status of any of the document is no longer in status mode, then we are in Negotiation mode
      if (status === TransactionHistoryGeneralStatus.Setup) {
        for (let i = 0; i < historyDocuments.length; i++) {
          // if (historyDocuments[i].history.at(-1)!.status !== ValidDocumentStatus.Setup) {
          if (historyDocuments[i].history.length !== 0) {
            return TransactionPhaseStatus.Negotiation;
          }
        }
        return TransactionPhaseStatus.Setup;
      }

      if (status === TransactionHistoryGeneralStatus.PostCompletionPending)
        return TransactionPhaseStatus.PostCompletion;
      if (status === TransactionHistoryGeneralStatus.Closed) return TransactionPhaseStatus.Closed;
      if (status === TransactionHistoryGeneralStatus.Aborted) return TransactionPhaseStatus.Aborted;
      if (status === TransactionHistoryGeneralStatus.Reinstated) return TransactionPhaseStatus.Reinstated;

      return TransactionPhaseStatus.Negotiation;
    }
    return TransactionPhaseStatus.Null;
  };
}

//Definition of the badge's color for the documents (Note that some names have been modified in 'renameStatuses()')
const listDocumentStatuses: StatusBadgeArgument[] = [
  { value: 'DRAFTING', color: BadgeColor.GRAY },
  { value: 'SENT_OUT', color: BadgeColor.BLUE },
  { value: ValidDocumentStatus.Signed, color: BadgeColor.GREEN },
];

const getDocStatus = (status: ValidDocumentStatus | undefined) => {
  switch (status) {
    case ValidDocumentStatus.SentToTenant:
      return 'SENT_OUT';
    case ValidDocumentStatus.Signed:
      return ValidDocumentStatus.Signed;
    default:
      return 'DRAFTING';
  }
};

const documentsStatusPopup = (
  historyDocuments: HistoryDocuments[],
  documentTypesList: DocumentTypeClone[],
  parentStatus: JSX.Element,
): JSX.Element[] => {
  const docs: JSX.Element[] = documentTypesList.map((item: DocumentTypeClone) => {
    const status = historyDocuments
      .find((obj: HistoryDocuments) => obj.documentTypeId === item.id)
      ?.history.at(-1)?.status;

    const updatedStatus = getDocStatus(status);

    return (
      <div
        className="p-b-xs"
        key={`doc-status-${item.id}`}
      >
        <div className="doc-content color-blue-very-dark">
          <span className="doc-name m-r-s">{getDocumentName(item.id, documentTypesList)}</span>

          {filterElementStatusTemplate(updatedStatus, listDocumentStatuses)}
        </div>
      </div>
    );
  });

  return docs;
};

const statusColumnTemplate = (row: TransactionRow, documentTypesList: DocumentTypeClone[]): JSX.Element => {
  const status = filterElementStatusTemplateV2(row.status, listTransactionStatuses);

  return (
    <Tooltip
      className="doc-pop-up"
      trigger={status}
      // @ts-ignore
      HTMLContent={documentsStatusPopup(row.historyDocuments, documentTypesList, status)}
    />
  );
};

const associatedContract = (translationInfo: TranslationInfoProps, status: TransactionPhaseStatus): JSX.Element => {
  if (status === TransactionPhaseStatus.Closed && translationInfo !== null && translationInfo.contract) {
    return (
      <a
        className="link"
        href={`/contracts/${translationInfo.contract.id}/details`}
      >
        {translationInfo.contract.identifier}
      </a>
    );
  }
  return <span>-N/A-</span>;
};

/*
 * Definition of the column (names, format...)
 */

export const transactionColumnConfig = (
  documentTypesList: DocumentTypeClone[],
  isAdmin: boolean,
  notes: string | null,
  userId: string | null,
  onViewDetails: ViewDetailsProps,
): CustomColumnProps[] => {
  return [
    {
      field: 'identifier',
      header: 'ID',
      filterPlaceholder: 'Search by ID',
      filter: true,
      filterField: 'identifier',
      sortable: true,
    },
    {
      field: 'owner',
      header: 'Landlord',
      filter: true,
      filterPlaceholder: 'Search by Landlord',
      filterField: 'owner',
      sortable: true,
      dataType: 'text',
      mandatory: true,
    },
    {
      field: 'policy',
      header: 'Policy',
      filterPlaceholder: 'Search by Policy',
      filter: true,
      filterField: 'policy',
      sortable: true,
      body: (row: TransactionRow) => (
        <Tooltip
          trigger={<span>{row.policy}</span>}
          HTMLContent={<ListOfProvisions provisions={row.provisions} />}
          disabled={row.provisions.length === 0}
        />
      ),
    },
    {
      field: 'leasingManager',
      header: 'Leasing manager',
      filter: true,
      filterPlaceholder: 'Search by Leasing manager',
      filterField: 'leasingManager',
      sortable: true,
      dataType: 'text',
      isHide: true,
    },
    {
      field: 'tenant',
      header: 'Tenant',
      filter: true,
      filterPlaceholder: 'Search by Tenant',
      filterField: 'tenant',
      sortable: true,
      dataType: 'text',
    },
    {
      field: 'building',
      header: 'Building',
      filter: true,
      filterPlaceholder: 'Search by Building',
      filterField: 'building',
      sortable: true,
      dataType: 'text',
    },
    {
      field: 'floorNames',
      header: 'Premises',
      filter: true,
      filterPlaceholder: 'Search by Premises',
      filterField: 'floorNames',
      sortable: true,
      body: (row: TransactionRow) => (
        <FloorsToolTip
          premise={row.premises}
          floorNames={row.floorNames}
        />
      ),
      filterMatchMode: 'custom',
      filterMatchModeOptions: [{ label: 'Contains', value: 'custom' }],
      filterFunction: (value: string, filter: string) => filterPremises(value, filter),
    },
    {
      field: 'dateOpened',
      header: 'Date opened',
      filterPlaceholder: 'Search by Date opened',
      filter: true,
      filterField: 'dateOpened',
      sortable: true,
      body: (row: TransactionRow) => {
        return moment(row.dateOpened).format('DD/MM/YYYY');
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },
    {
      field: 'dateClosed',
      header: 'Date closed',
      filter: true,
      filterPlaceholder: 'Search by Date closed',
      filterField: 'dateClosed',
      sortable: true,
      body: (row: TransactionRow) => {
        return row.dateClosed ? moment(row.dateClosed).format('DD/MM/YYYY') : '';
      },
      dataType: 'date',
      filterElement: filterElementDate,
    },
    {
      field: 'contract',
      header: 'Associated contract',
      filter: true,
      filterPlaceholder: 'Search by Contract',
      filterField: 'contract',
      sortable: true,
      dataType: 'text',
      body: (row: TransactionRow) => associatedContract(row.translationInfo, row.status),
    },
    {
      field: 'status',
      header: 'Status',
      filterPlaceholder: 'Search by Status',
      filter: true,
      filterField: 'status',
      sortable: true,
      body: (row: TransactionRow) => {
        const documentsList = documentTypesList.filter((doc: DocumentTypeClone) =>
          row.documentTypeIds.some((docId: string) => docId == doc.id),
        );

        return statusColumnTemplate(row, documentsList);
      },
      filterElement: options => filterElementStatus(options, listTransactionStatuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      frozen: true,
      alignFrozen: 'right',
      header: 'Actions',
      body: (row: TransactionRow) => (
        <TransactionRowActionButtons
          row={row}
          isAdmin={isAdmin}
          notes={notes}
          userId={userId}
          onViewDetails={onViewDetails}
        />
      ),
    },
  ];
};
