import { MouseEvent as ReactMouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { signOut, updateActiveProfileTab } from 'store/authentication/authSlice';
import { checkIfLocalEnv, preventReload } from 'utils/tsHelper';
import { checkIfAdminURL } from 'utils/utils-admin';
import { Icons } from 'utils/utils-icons';
import EntitiesList from './EntitiesList';
import UserProfile from './UserProfile';
import './SettingMenu.scss';

interface SettingsMenuProps {
  selectedEntity: string | null;
  isAdmin: boolean;
}

const SettingsMenu = ({ selectedEntity, isAdmin }: SettingsMenuProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { members } = useAppSelector((state: RootState) => state.auth);
  const ifAdmin = checkIfAdminURL();

  const logOut = () => {
    dispatch(signOut());
    navigate('/sign-in');
  };

  const moveToProfile = (tab: number) => {
    dispatch(updateActiveProfileTab({ tab }));
    navigate('/profile');
  };

  const gotoProfile = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    preventReload(e);
    moveToProfile(index);
  };

  return (
    <Dropdown
      item={true}
      className="dropdown-btn"
      icon={false}
      trigger={<UserProfile />}
    >
      <Dropdown.Menu className="settings-menu">
        {members.length !== 0 && <EntitiesList isAdmin={isAdmin} />}
        <Dropdown.Item
          href="/profile"
          data-test="nav-user-profile"
          onClick={e => gotoProfile(e, 0)}
          className="others link"
        >
          <Icon
            icon={Icons.Person}
            className="user"
          />
          User Profile
        </Dropdown.Item>
        {selectedEntity !== null && !ifAdmin && (
          <>
            <Dropdown.Item
              className="others link"
              href="/profile?tab=notifications"
              data-test="nav-user-notifications"
              onClick={e => gotoProfile(e, 3)}
            >
              <Icon
                icon={Icons.Bell}
                className="notification"
              />
              Notifications
            </Dropdown.Item>
          </>
        )}
        {!checkIfLocalEnv() && (
          <>
            <hr className="border-sm-grayish-magenta-light" />
            <span className="p-t-none m-t-sm settings-menu-title">support </span>
            <a
              href="https://sericinlimited.ladesk.com/"
              target="_blank"
              rel="noreferrer"
              className="support-link"
            >
              <Dropdown.Item className="others">
                <Icon icon={Icons.InfoCircle} />
                FAQs
              </Dropdown.Item>
            </a>
            <a
              href="https://sericinlimited.ladesk.com/submit_ticket"
              target="_blank"
              rel="noreferrer"
              className="support-link"
            >
              <Dropdown.Item className="others">
                <Icon icon={Icons.Document} />
                Create support ticket
              </Dropdown.Item>
            </a>
          </>
        )}
        <hr className="border-sm-grayish-magenta-light" />
        <Dropdown.Item
          data-test="nav-logout"
          onClick={() => logOut()}
          className="others"
        >
          <Icon
            icon={Icons.ArrowRightLight}
            className="logout"
          />
          Log Out
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SettingsMenu;
