import UserProfilePage from 'views/profile';
import { isAuthenticated } from './redirects';

const ProfileRoutes = () => [
  {
    path: '/profile',
    element: isAuthenticated(<UserProfilePage />),
  },
];

export default ProfileRoutes;
