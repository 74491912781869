import React, { useState } from 'react';
import * as Drag from 'react-beautiful-dnd';
import { generateUniqueId } from 'utils/utils-random';

interface DraggableListProps<T> {
  data: T[];
  disabled?: boolean;
  rowTemplate: (data: T, index: number) => React.ReactNode;
  onPositionChange: (sourceIndex: number, destinationIndex: number) => void;
}

function DraggableList<T>({
  data,
  disabled = false,
  rowTemplate,
  onPositionChange,
}: DraggableListProps<T>): JSX.Element {
  const [id] = useState<string>(generateUniqueId('droppable'));

  const handleDragEnd = (result: Drag.DropResult): void => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    onPositionChange(result.source.index, result.destination.index);
  };

  return (
    <Drag.DragDropContext onDragEnd={handleDragEnd}>
      <Drag.Droppable
        droppableId={id}
        isDropDisabled={disabled}
      >
        {(provided: any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {data.map((element, index) => (
              <Drag.Draggable
                isDragDisabled={disabled}
                key={index}
                draggableId={`item-${index}`}
                index={index}
              >
                {(provided: any) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {rowTemplate(element, index)}
                  </div>
                )}
              </Drag.Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Drag.Droppable>
    </Drag.DragDropContext>
  );
}

export default DraggableList;
