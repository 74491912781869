import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './NodeAlert.scss';

const NodeAlert = ({ message }: { message: string }) => {
  return (
    <div className="node-alert color-red m-r-xs">
      <Icon
        className="alert-image"
        icon={Icons.Triangle}
      />
      {message}
    </div>
  );
};

export default NodeAlert;
