import { IFunctionInputClone } from './FormatterDetail';

interface IFormatterList {
  id: string;
  name: string | null;
  example: string | null;
  description: string | null;
  function: string | null;
  inputs: IFunctionInputClone[];
  reviewFlag: boolean;
}

export class CFormatterList implements IFormatterList {
  id: string;
  name: string | null;
  example: string | null;
  description: string | null;
  function: string | null;
  inputs: IFunctionInputClone[];
  reviewFlag: boolean;

  constructor(input: IFormatterList) {
    this.id = input.id;
    this.name = input.name ?? null;
    this.example = input.example ?? null;
    this.description = input.description ?? null;
    this.function = input.function ?? null;
    this.inputs = input.inputs;
    this.reviewFlag = input.reviewFlag;
  }

  static fragment() {
    return `
      id
      name
      example
      description
      inputs {
        label
        type
      }
      reviewFlag
    `;
  }
}
