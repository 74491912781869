import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GenerateModifcationNotesQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string | null;
  documentTypeId: string | null;
}

const GENERATE_MODIFICATION_NOTES = (transactionId: string | null | undefined, documentTypeId: string | null) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Generate Modification Notes: currentLandlordEntityId is required');
  if (!documentTypeId) throw new Error('Generate Modification Notes: documentTypeId is required');
  if (!transactionId) throw new Error('Generate Modification Notes: transactionId is required');

  const variables: GenerateModifcationNotesQueryVariables = {
    currentLandlordEntityId,
    transactionId,
    documentTypeId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $transactionId: ID!, $documentTypeId: ID!) {
        generateModificationNotes(
          currentLandlordEntityId: $currentLandlordEntityId
          transactionId: $transactionId
          documentTypeId: $documentTypeId
        ) {
          modificationNotes
          parameterIds
        }
      }
    `,
    variables,
  };
};

export const generateModificationNotes = createAsyncThunk(
  'transactions/generateModificationNotes',
  async ({ documentTypeId }: { documentTypeId: string | null }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
    } = getState() as RootState;
    const response = await client.query(GENERATE_MODIFICATION_NOTES(activeTransaction.id, documentTypeId));
    return response;
  },
);
