import { SelectItem } from 'primereact/selectitem';
import { BadgeColor } from 'atoms/Badge';
import { TRANSACTION_TABS_OFFSET } from 'views/transactions/CreatorViewer/Tabs/TransactionsTabs';
import { updateTransactionViewDetailsTab } from 'store/transactions/transactionDetailSlice';
import InfoApproval from 'common/model/InfoApproval';
import InfoSender from 'common/model/InfoSender';
import NotificationType from 'common/model/NotificationType';
import Transaction from 'common/model/Transaction';
import User from 'common/model/User';
import { replaceUnderscoreWithSpace } from 'utils/utils-string';

export const getBadgeText = (type: NotificationType) => {
  switch (type) {
    case NotificationType.EntityInvited:
      return 'Invitation';
    case NotificationType.EntityRevoke:
      return 'Revoke';
    default:
      return replaceUnderscoreWithSpace(type.toString());
  }
};

export const getSenderName = (infoSender: InfoSender) => {
  const sender = infoSender.user;
  return `${sender.firstName} ${sender.lastName}`;
};

export const getSenderPhoto = (infoSender: InfoSender) => {
  const sender = infoSender.user;
  return sender.photoUrl;
};

export const getSenderEntity = (infoSender: InfoSender) => {
  const entity = infoSender.entity;
  return entity ? entity.name : null;
};

export const getApprovalTransaction = (infoApproval: InfoApproval): Transaction => {
  const transaction = infoApproval.transaction;
  return transaction;
};

export const getInvitationRole = (user: User, infoSender: InfoSender) => {
  const members = user.members || [];

  const memberWithMatchingEntity = members.find(item => item?.entity?.id === infoSender.entity.id);

  const role = memberWithMatchingEntity ? memberWithMatchingEntity?.role?.name : null;

  return role;
};

export const getNotificationBadgeColor = (type: NotificationType): BadgeColor => {
  switch (type) {
    case NotificationType.ApprovalAccepted:
      return BadgeColor.GREEN;
    case NotificationType.ApprovalRefused:
      return BadgeColor.RED;
    default:
      return BadgeColor.GRAY;
  }
};

export const notificationsFilterTypeOptions: SelectItem[] = [
  { label: 'Accepted approvals', value: NotificationType.ApprovalAccepted },
  { label: 'Declined Approvals', value: NotificationType.ApprovalRefused },
  { label: 'Approval requests', value: NotificationType.ApprovalRequest },
  { label: 'Invitation to join', value: NotificationType.EntityInvited },
  { label: 'Revoked invitation/access', value: NotificationType.EntityRevoke },
];

export enum NotificationReadStatus {
  Read = 'READ',
  Unread = 'UNREAD',
}

export const notificationsFilterReadOptions: SelectItem[] = [
  { label: 'Unread', value: 'UNREAD' },
  { label: 'Read', value: 'READ' },
];

export const noNotificationsText = (
  search: string | null,
  typeFIlter: NotificationType[],
  readFilter: NotificationReadStatus[],
) => {
  const filterApplied: boolean = !!search || typeFIlter.length > 0 || readFilter.length > 0;

  return filterApplied ? "You don't have any notifications of this type." : "You don't have any notifications.";
};

export const goToTransaction = (
  e: React.MouseEvent<HTMLAnchorElement>,
  dispatch: Function,
  navigate: Function,
  url: string,
) => {
  e.preventDefault();
  dispatch(updateTransactionViewDetailsTab({ tab: TRANSACTION_TABS_OFFSET.PRE_COMPLETION }));
  navigate(url);
};

export const getTransactionUrl = (id?: string) => `/transactions/${id ? id : ''}/details`;
