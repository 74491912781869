import moment from 'moment';
import { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import DropdownSelect from 'atoms/DropdownSelect';
import ExportModal, { ExportTypes } from 'atoms/ExportDropdown/ExportModal';
import DateField from 'atoms/FormField/DateInput';

interface ForecastingDownloadTemplateProps {
  onExport: (args: { startDate?: string; endDate?: string; propertyId?: string }) => void;
  isExporting: boolean;
  exportSuccess: boolean;
  exportFail: boolean;
}

const ForecastingDownloadTemplate = ({
  onExport,
  isExporting,
  exportSuccess,
  exportFail,
}: ForecastingDownloadTemplateProps) => {
  const formatDate = (value?: string): string => moment(value).format('YYYY-MM-DD');
  const today = formatDate();

  const { activeProperty } = useAppSelector((state: RootState) => state.forecasts);
  const [propertyId, setPropertyId] = useState<string | undefined>(
    activeProperty && activeProperty.id ? activeProperty.id : undefined,
  );
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);

  const stopPropagation = (e: React.MouseEvent<HTMLElement>): void => e.stopPropagation();
  const newOnExport = (): void => onExport({ propertyId, startDate, endDate });

  const templateDropdown = [];

  if (activeProperty) {
    templateDropdown.push({
      key: activeProperty.id,
      text: activeProperty.buildingName,
      value: activeProperty.id,
    });
  } else {
    templateDropdown.push({
      key: '',
      text: '',
      value: '',
    });
  }

  const onChange = (key: string, value: string): void => {
    if (key === 'propertyId') {
      setPropertyId(value);
    } else if (key === 'startDate') {
      setStartDate(formatDate(value));
    } else {
      setEndDate(formatDate(value));
    }
  };

  return (
    <Form className="template-download bg-white">
      <div className="datepicker-wrapper">
        <div>
          <p className="color-blue-very-dark font-600 pm-none">Generate the forecasting revenues Excel spreadsheet</p>
          <hr className="border-sm-grayish-magenta-light" />
        </div>
        <div
          className="d-flex m-t-sm"
          onClick={stopPropagation}
        >
          <label className="m-r-sm">Property</label>
          <DropdownSelect
            placeholder="Select Property"
            fieldKey="propertyId"
            disabled={templateDropdown.length === 1}
            options={templateDropdown}
            value={propertyId}
            onChange={(key, value) => setPropertyId(value as string)}
          />
        </div>
        <div
          className="d-flex m-t-sm"
          onClick={stopPropagation}
        >
          <label className="m-r-sm">From:</label>
          <div className="m-r-sm">
            <DateField
              fieldKey="startDate"
              value={startDate}
              onChange={onChange}
            />
          </div>
          <label className="m-r-sm">To:</label>
          <div>
            <DateField
              fieldKey="endDate"
              value={endDate}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex m-t-m">
        <Button className="btn grey-outline">CANCEL</Button>
        <ExportModal
          type={ExportTypes.XLSX}
          onTryAgain={newOnExport}
          isExporting={isExporting}
          exportSuccess={exportSuccess}
          exportFail={exportFail}
          trigger={
            <Button
              className="btn grey-bg m-l-xl"
              onClick={newOnExport}
              disabled={!propertyId}
            >
              Download Template
            </Button>
          }
        />
      </div>
    </Form>
  );
};

export default ForecastingDownloadTemplate;
