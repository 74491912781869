import { checkNotEmpty } from 'utils/tsValidator';

export const categoryValidator = ({
  name,
  color,
  index,
}: {
  name: string | null;
  color: string | null;
  index: number | null;
}) => {
  return !checkNotEmpty(name) || !checkNotEmpty(color) || !checkNotEmpty(index);
};
