import {
  CAssignmentRights,
  CRenewalRights,
  CSaleAndRedevelopmentRights,
  CShareRights,
  CSubletRights,
  CSurrenderRights,
} from 'common/_classes/contractsV2';
import SpecialRights2 from 'common/model/SpecialRights2';
import { CExpansionRightsValuationData } from './ExpansionRightsValuationData';
import { CRefusalRightsValuationData } from './RefusalRightsValuationData';
import { CValuationDataPremises2 } from './ValuationDataPremises';

export class CSpecialRights2 {
  assignmentFlag: boolean;
  expansionFlag: boolean;
  refusalFlag: boolean;
  renewFlag: boolean;
  saleAndRedevelopmentFlag: boolean;
  shareFlag: boolean;
  subletFlag: boolean;
  surrenderFlag: boolean;
  assignmentRights: CAssignmentRights | null;
  shareRights: CShareRights | null;
  subletRights: CSubletRights | null;
  renewalRights: CRenewalRights[];
  saleAndRedevelopmentRights: CSaleAndRedevelopmentRights | null;
  surrenderRights: CSurrenderRights | null;
  expansionRights: CExpansionRightsValuationData | null;
  refusalRights: CRefusalRightsValuationData | null;

  constructor(input: SpecialRights2) {
    this.assignmentFlag = input.assignmentFlag;
    this.expansionFlag = input.expansionFlag;
    this.refusalFlag = input.refusalFlag;
    this.renewFlag = input.renewFlag;
    this.saleAndRedevelopmentFlag = input.saleAndRedevelopmentFlag;
    this.shareFlag = input.shareFlag;
    this.subletFlag = input.subletFlag;
    this.surrenderFlag = input.surrenderFlag;
    this.assignmentRights = input.assignmentRights ? new CAssignmentRights(input.assignmentRights) : null;
    this.shareRights = input.shareRights ? new CShareRights(input.shareRights) : null;
    this.subletRights = input.subletRights ? new CSubletRights(input.subletRights) : null;
    this.saleAndRedevelopmentRights = input.saleAndRedevelopmentRights
      ? new CSaleAndRedevelopmentRights(input.saleAndRedevelopmentRights)
      : null;
    this.surrenderRights = input.surrenderRights ? new CSurrenderRights(input.surrenderRights) : null;
    this.renewalRights = input.renewalRights.map(right => new CRenewalRights(right));
    this.expansionRights = input.expansionRights ? new CExpansionRightsValuationData(input.expansionRights) : null;
    this.refusalRights = input.refusalRights ? new CRefusalRightsValuationData(input.refusalRights) : null;
  }

  static fragment() {
    return `
        assignmentFlag
        assignmentRights {
          ${CAssignmentRights.fragment()}
        }
        expansionFlag
        expansionRights {
          ${CExpansionRightsValuationData.fragment()}
        }
        refusalFlag
        refusalRights {
          ${CRefusalRightsValuationData.fragment()}
        }
        renewFlag
        renewalRights {
          ${CRenewalRights.fragment()}
        }
        saleAndRedevelopmentFlag
        saleAndRedevelopmentRights {
          ${CSaleAndRedevelopmentRights.fragment()}
        }
        shareFlag
        shareRights {
          ${CShareRights.fragment()}
        }
        subletFlag
        subletRights {
          ${CSubletRights.fragment()}
        }
        surrenderFlag
        surrenderRights {
          ${CSurrenderRights.fragment()}
        }
        refusalRights {
          priority
          premises {
            ${CValuationDataPremises2.fragment()}
          }
        }
    `;
  }
}
