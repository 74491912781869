import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import { createNewTranslateToContract } from 'store/translatorsToContract/translatorDetailSlice';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { listTranslateToContracts } from 'common/api/translatorsToContract';
import TranslateToContractHeaderToolbar from './TranslateToContractHeaderToolbar';
import { TranslateToContractRow, translateToContractColumnConfig } from './TranslateToContractTableConfig';

const TranslateToContractDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, translateToContractList } = useAppSelector((state: RootState) => state.translatorsListing);

  const onCreate = (): void => {
    dispatch(createNewTranslateToContract());
    navigate('/translate-to-contract/create');
  };

  const onViewDetails = (id: string): void => {
    const url = `/translate-to-contract/${id}/details`;
    navigate(url);
  };

  const getTranslateToContracts = (params: PaginationAPIProps): void => {
    dispatch(listTranslateToContracts({ ...params }));
  };

  const currentList: TranslateToContractRow[] = translateToContractList.map(
    translateToContract => new TranslateToContractRow(translateToContract),
  );

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Translators To Contract' }];

    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE TRANSLATOR',
      url: '/translate-to-contract/create',
      dataTest: 'create-translator-button',
      onCreateNew: onCreate,
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <TranslateToContractHeaderToolbar />,
      createNewButton,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          getTranslateToContracts(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={translateToContractColumnConfig(onViewDetails)}
        dataTest="translators-table"
        defaultSortField="index"
        defaultSortOrder={1}
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default TranslateToContractDashboard;
