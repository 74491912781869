import { useAppDispatch } from 'hooks';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { updateApprovalAnswer } from 'store/transactions/transactionDetailSlice';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { updateTransactionApprovalAnswer } from 'common/api/transactions/approvals/updateTransactionApprovalAnswer';
import { ColorNames } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { ApprovalRow } from '..';

const ActionButtons = ({
  row,
  reviewer,
  setOpenCommentModal,
  setActiveRowId,
}: {
  row: ApprovalRow;
  reviewer: boolean;
  setOpenCommentModal?: (open: boolean) => void;
  setActiveRowId?: (value: string) => void;
}) => {
  const dispatch = useAppDispatch();

  const updateApproval = (answerId: string, key: string, value: ValidApprovalStates) => {
    if (reviewer) {
      dispatch(updateApprovalAnswer({ answerId, key, value }));
      dispatch(updateTransactionApprovalAnswer({ answerId }));
    }
  };

  const largeTooltipContent = () => {
    if (row.note === null) {
      return <span>Comment to be added</span>;
    } else {
      return (
        <>
          <div className="person color-blue-very-dark-grayish">
            <Avatar
              src={row.user.photoUrl || ''}
              size={AvatarSize.Small}
              className="person-icon m-l-xs m-r-xs"
            />
            <span className="bold-person color-blue-very-dark">{`${row.user.firstName} ${row.user.lastName}`}</span>
            <span className="date">{row.dateOfAnswer}</span>
          </div>
          <p className="note-text color-blue-very-dark-grayish m-t-sm">{row.note}</p>
        </>
      );
    }
  };

  const onClickComment = (): void => {
    if (setActiveRowId) {
      if (reviewer && setOpenCommentModal) {
        setOpenCommentModal(true);
      }
      setActiveRowId(row.id);
    }
  };

  const buttonsApproved = (): TableActionButton[] => {
    return [
      {
        icon: Icons.Check,
        color: ColorNames.GREEN,
        onClick: () => updateApproval(row.id, 'approvalState', ValidApprovalStates.Rejected),
        tooltip: 'Approved',
        popupConfirmationButton: 'Approve',
      },
      {
        icon: Icons.Comment,
        isLargeTooltip: true,
        largeTooltipContent: largeTooltipContent(),
        onClick: () => onClickComment(),
      },
    ];
  };

  const buttonsRejected = (): TableActionButton[] => {
    return [
      {
        icon: Icons.Cross,
        color: ColorNames.RED,
        onClick: () => updateApproval(row.id, 'approvalState', ValidApprovalStates.Approved),
        tooltip: 'Rejected',
        popupConfirmationButton: 'Reject',
      },
      {
        icon: Icons.Comment,
        isLargeTooltip: true,
        largeTooltipContent: largeTooltipContent(),
        onClick: () => onClickComment(),
      },
    ];
  };

  const buttonsReviewer = (): TableActionButton[] => {
    return [
      {
        icon: Icons.Check,
        color: ColorNames.GREEN,
        tooltip: 'Approve Change',
        onClick: () => updateApproval(row.id, 'approvalState', ValidApprovalStates.Approved),
      },
      {
        icon: Icons.Cross,
        color: ColorNames.RED,
        tooltip: 'Reject Change',
        onClick: () => updateApproval(row.id, 'approvalState', ValidApprovalStates.Rejected),
      },
      {
        icon: Icons.Comment,
        isLargeTooltip: true,
        largeTooltipContent: largeTooltipContent(),
        onClick: () => onClickComment(),
      },
    ];
  };

  const buttonsPending = (): TableActionButton[] => {
    return [
      {
        icon: Icons.InfoCircle,
        color: ColorNames.BLACK,
        tooltip: 'Pending',
      },
    ];
  };

  const getActionButtons = (): TableActionButton[] => {
    // Reviewer mode
    if (reviewer) {
      switch (row.approvalState) {
        case ValidApprovalStates.Approved:
          return buttonsApproved();
        case ValidApprovalStates.Rejected:
          return buttonsRejected();
        default:
          return buttonsReviewer();
      }
    }
    // Non reviewer mode
    switch (row.approvalState) {
      case ValidApprovalStates.Approved:
        return buttonsApproved();
      case ValidApprovalStates.Rejected:
        return buttonsRejected();
      default:
        return buttonsPending();
    }
  };

  return <TableActionButtons buttons={getActionButtons()} />;
};

export default ActionButtons;
