import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { noNotificationsText } from '../utils.notification';
import NotificationListingRow from './NotificationListingRow';
import './NotificationListing.scss';

const NotificationListing = () => {
  const { notifications, typeFilter, readFilter, search } = useAppSelector((state: RootState) => state.notifications);

  const noNotifications: boolean = !notifications || notifications.length === 0;

  return (
    <div className="notification-listing-section m-t-l">
      {noNotifications ? (
        <div className="text">{noNotificationsText(search, typeFilter, readFilter)}</div>
      ) : (
        notifications.map((notification, index) => (
          <NotificationListingRow
            notification={notification}
            className={index !== notifications.length - 1 ? 'm-b-xs' : ''}
            key={index}
          />
        ))
      )}
    </div>
  );
};

export default NotificationListing;
