import './ClauseIndex.scss';

interface ClauseIndexProps {
  numbering?: string | number;
  title?: string;
  hasCondition?: boolean;
  iterable?: boolean;
}

const ClauseIndex = ({ numbering, hasCondition, title, iterable }: ClauseIndexProps) => {
  const classNames = hasCondition ? 'title-row-extended' : '';

  return (
    <div className="clause-index-container">
      <div className="clause-title">
        <div className={'title-row ' + classNames}>
          {numbering || '1'}
          {hasCondition && <span>C</span>}
          {iterable && (
            <span data-iteration="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="0.8em"
                viewBox="0 0 14 14"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                >
                  <path d="m9.25.5l2.5 2.5l-2.5 2.5"></path>
                  <path d="M12.25 8.25A5.25 5.25 0 1 1 7 3h4.75"></path>
                </g>
              </svg>
            </span>
          )}
        </div>
        {title && <span>{title}</span>}
        <h3 className="clause-node-name">Clause Node Name</h3>
      </div>
    </div>
  );
};

export default ClauseIndex;
