import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/properties/propertiesListingSlice';
import { createNewProperty } from 'store/properties/propertyDetailSlice';
import { listProperties } from 'common/api/properties';

const EntitiesPropertiesTabToolbar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useAppSelector((state: RootState) => state.propertiesListing);

  const onGetData = (value?: string) => {
    return listProperties({
      // @ts-ignore
      searchValue: value,
      first: 10,
      isLandlordEntityProperties: true,
    });
  };

  const onCreateNewProperty = () => {
    dispatch(createNewProperty());
    navigate(`/properties/create`);
  };

  return (
    <div>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      <Button
        className="btn grey-bg m-l-l"
        data-test="create-new-property-button"
        onClick={() => onCreateNewProperty()}
      >
        CREATE NEW PROPERTY
      </Button>
    </div>
  );
};

export default EntitiesPropertiesTabToolbar;
