import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import EditorField from 'atoms/FormField/EditorField';
import { updateActiveFormatter } from 'store/formatters/formatterDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import FormatterDefinition from './FormatterForm/FormatterDefinition';
import FormatterInputsList from './FormatterForm/FormatterInputsList';
import FunctionRun from './FormatterForm/FunctionRun';

/*
 * This is the original file that has been replaced by FormatterContent with the new Monaco Editor.
 */

const FormatterContent = ({ mode, checkCreate }: { mode: MODE_OPTIONS; checkCreate: boolean }): JSX.Element => {
  const { activeFormatter } = useAppSelector((state: RootState) => state.formatterDetail);

  const onChange = (key: string, value: string) => updateActiveFormatter({ key, value });

  return (
    <Form>
      <Grid>
        <Grid.Row className="pm-none">
          <Grid.Column width={8}>
            <SectionComponent title="1. Formatter definition">
              <FormatterDefinition mode={mode} />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column width={8}>
            <SectionComponent
              title={
                <>
                  2. Function <span className="required-indicator-title">*</span>
                </>
              }
            >
              <EditorField
                onChange={(key: string, value: string) => onChange(key, value)}
                value={activeFormatter.function}
                placeholder="Type here..."
                disabled={mode === MODE_OPTIONS.READ}
                fieldKey="function"
                height="500"
                codeSample={true}
                code={true}
              />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column width={16}>
            <SectionComponent
              title={
                <>
                  3. List of type inputs <span className="required-indicator-title">*</span>
                </>
              }
              className="m-t-xl"
            >
              <p>List of inputs with their answer type and their label.</p>
              <FormatterInputsList mode={mode} />
            </SectionComponent>
          </Grid.Column>
          {!checkCreate && (
            <Grid.Column
              width={16}
              className="m-t-xl m-b-ml"
            >
              <SectionComponent title="4. Test the function">
                <FunctionRun />
              </SectionComponent>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default FormatterContent;
