import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListTransactionAlertsQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
}

const LIST_TRANSACTION_ALERTS = (id: string | undefined) => {
  if (!id) throw new Error('List Transaction Alerts: id is required');

  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List Transaction Alerts: currentLandlordEntityId is required');

  const variables: ListTransactionAlertsQueryVariables = {
    currentLandlordEntityId,
    transactionId: id,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $transactionId: ID!) {
        getTransaction(currentLandlordEntityId: $currentLandlordEntityId, transactionId: $transactionId) {
          alerts {
            type
            date
            message
            detail
          }
        }
      }
    `,
    variables,
  };
};

export const listTransactionAlerts = createAsyncThunk(
  'transactions/listTransactionAlerts',
  async ({ id }: { id: string | undefined }) => {
    const response = await client.query(LIST_TRANSACTION_ALERTS(id));
    return response;
  },
);
