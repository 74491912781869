import { store } from 'store';
import { Editor } from 'tinymce';
import { EDITOR_INFORMATION_TABS_OFFSET } from 'components/Editor/EditorSideMenu';
import { POLICY_TABS_OFFSET } from 'views/policy/CreatorViewer/Tabs/PolicyTabs';
import { TRANSACTION_TABS_OFFSET } from 'views/transactions/CreatorViewer/Tabs/TransactionsTabs';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import { setSelectedProvisionFilter } from 'store/miscellaneous/miscellaneousSlice';
import { updatePolicyViewDetailsTab } from 'store/policies/policyDetailSlice';
import { updateTransactionViewDetailsTab } from 'store/transactions/transactionDetailSlice';
import { ContentNodeType } from 'common/api/nodes';
import { getClientProvision } from 'common/api/provisions';
import { checkIfPoliciesPage } from 'utils/tsHelper';

// Close down the pop-up dialog after clicking inner content
export const editorContextProvisionForceClose = () => {
  const menuQuery = '.tox-pop--top';
  const button = document.querySelector(menuQuery) as HTMLElement;
  if (button !== null) {
    button.style.display = 'none';
  }
};

const showGuidelines = {
  id: 'showGuidelines',
  text: 'See guidelines',
  icon: 'guidance',
  action: (editor: Editor) => {
    // get node from current click
    let node = editor.selection.getNode() as Element;
    // try to get the provision element as 'parent'
    let parent = node.parentNode as Element;

    // avoid working with undefined object
    if (!parent) return;

    // If clicked on name of provision
    if (parent.className === 'provision-text') {
      parent = parent.parentNode as Element;
    }

    // @ts-ignore
    const nodeType = parent.getAttribute('data-node-type');
    if (nodeType === ContentNodeType.PROVISION) {
      // @ts-ignore
      const nodeId = parent.getAttribute('data-node-id');
      // get provision details
      store.dispatch(getClientProvision({ id: nodeId }));
      store.dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.CLAUSE));
    }

    editorContextProvisionForceClose();
  },
};

const listParameters = {
  id: 'listParameters',
  text: 'List parameters of that provision',
  icon: 'parameters',
  action: (editor: Editor) => {
    // get node from current click
    let node = editor.selection.getNode() as Element;
    // try to get the provision element as 'parent'
    let parent = node.parentNode as Element;
    // avoid working with undefined object
    if (!parent) return;

    // If clicked on name of provision
    if (parent.className === 'provision-text') {
      parent = parent.parentNode as Element;
    }

    // @ts-ignore
    const nodeType = parent.getAttribute('data-node-type');
    if (nodeType === ContentNodeType.PROVISION) {
      // @ts-ignore
      const provisionId = parent.getAttribute('data-node-id');
      // set provision details
      store.dispatch(setSelectedProvisionFilter({ provisionId }));
      // Switch to parameters tab
      if (checkIfPoliciesPage()) {
        store.dispatch(updatePolicyViewDetailsTab({ tab: POLICY_TABS_OFFSET.PARAMETERS }));
      } else {
        store.dispatch(
          updateTransactionViewDetailsTab({
            tab: TRANSACTION_TABS_OFFSET.PARAMETERS,
          }),
        );
      }
    }
  },
};

const ContextProvisionToolbar = (editor: Editor) => {
  // List of options for the Context Provision
  const contextButtons = [showGuidelines, listParameters];

  // Register every option. The option SHOULD be registered before used.
  for (let button of contextButtons) {
    // Add a button passing each object's attributes as parameters
    editor.ui.registry.addButton(button.id, {
      text: button.text,
      icon: button.icon,
      onAction: () => {
        button.action(editor);
      },
    });
  }

  // Map over each button and take it's id.
  // id | id | id
  const buttons: string = [''].concat(contextButtons.map(btn => btn.id)).join(' | ');

  // Context with Add Context
  editor.ui.registry.addContextToolbar('provisionToolbar', {
    /* Predicate returns a boolean
      Toolbar is shown accordingly */
    predicate: node => {
      const nodeType = node.getAttribute('data-node-type');

      if (!nodeType) return false;
      return nodeType === ContentNodeType.PROVISION;
    },
    items: buttons,
    position: 'selection',
    scope: 'node',
  });
};

export default ContextProvisionToolbar;
