import { Popup } from 'semantic-ui-react';
import './Tooltip.scss';

interface TooltipProps {
  trigger: JSX.Element;
  disabled?: boolean;
  HTMLContent: JSX.Element | string;
  className?: string;
}

const Tooltip = ({ trigger, disabled, HTMLContent, className }: TooltipProps) => (
  <Popup
    content={<div className="content">{HTMLContent}</div>}
    position="bottom left"
    id="tooltip"
    className={`tooltip ${className}`}
    trigger={trigger}
    hoverable={true}
    basic={true}
    disabled={disabled}
  />
);

export default Tooltip;
