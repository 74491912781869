import { CSurrenderRights } from 'common/_classes/contractsV2';
import { CSaleAndRedevelopmentRights } from 'common/_classes/contractsV2/SaleAndRedevelopmentRights';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { conditionalClassName } from 'utils/tsHelper';
import SaleAndRedevelopmentRightForm from './SaleAndRedevelopmentRightForm';

const SaleAndRedevelopmentRight = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const saleAndRedevelopmentFlag = activeContractEvent?.saleAndRedevelopmentFlag || false;

  const disabled = mode === MODE_OPTIONS.READ;

  const toggleSwitch = (_key: string, value: boolean) => {
    dispatch(
      updateActiveContractEventInput({
        key: 'saleAndRedevelopmentRights',
        value: value ? new CSaleAndRedevelopmentRights(null) : null,
      }),
    );
    dispatch(updateActiveContractEventInput({ key: 'saleAndRedevelopmentFlag', value }));
  };

  return (
    <div className={conditionalClassName(saleAndRedevelopmentFlag, 'border-bottom-sm-grayish-magenta-light p-b-s')}>
      <div className="grid ">
        <div className="col-4  d-flex justify-space-between">
          <label className="font-600 font-size-1_1429">Landlord's right of termination on sale & redevelopment</label>

          <CheckboxField
            className="d-flex align-center"
            dataTest=""
            fieldKey=""
            value={saleAndRedevelopmentFlag}
            toggle={true}
            disabled={disabled}
            onChange={toggleSwitch}
          />
        </div>
      </div>

      {saleAndRedevelopmentFlag && <SaleAndRedevelopmentRightForm mode={mode} />}
    </div>
  );
};

export default SaleAndRedevelopmentRight;
