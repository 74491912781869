import ContractDocument from 'common/model/ContractDocument';
import ContractDocumentInput from 'common/model/ContractDocumentInput';
import FileUploadData from 'common/model/FileUploadData';
import { convertDateToISO } from 'utils/utils-date';
import { uploadToAWS } from 'utils/utils-upload';

export interface ContractDocumentInputClone
  extends Omit<FileUploadData, 'uploadUrl' | 'cdnUrl'>,
    Omit<ContractDocumentInput, 'dateSigned'> {
  dateSigned: Date | null;
  insertedAt: Date | null;
  uploadUrl: string | null;
  cdnUrl: string | null;
}

export class CContractDocument implements ContractDocumentInputClone {
  comment: string;
  dateSigned: Date | null;
  insertedAt: Date | null;
  name: string;
  path: string;
  public: boolean;
  uploadUrl: string | null;
  url: string | null;
  cdnUrl: string | null;
  file: File | null;
  local: boolean;

  constructor(input: ContractDocument | null) {
    this.comment = input ? input.comment : '';
    this.url = input ? input.url : null;
    this.dateSigned = input && input.dateSigned ? new Date(input.dateSigned) : null;
    this.insertedAt = input && input.insertedAt ? new Date(input.insertedAt) : null;
    this.name = input ? input.name : '';
    this.path = input ? input.path : '';
    this.public = input ? input.public : true;
    this.uploadUrl = null;
    this.cdnUrl = null;
    this.file = null;
    this.local = false;
  }

  static fragment() {
    return `
        name
        public
        dateSigned
        comment
        url
        path
        insertedAt
    `;
  }

  static async formatForAPI(document: CContractDocument) {
    const { uploadUrl, file, name, path, local, comment, dateSigned } = document;

    if (local) {
      const res = await uploadToAWS(uploadUrl, file);
    }

    return {
      name,
      path,
      public: true,
      comment,
      dateSigned: convertDateToISO(dateSigned),
    };
  }
}
