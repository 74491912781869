import Decimal from 'decimal.js';
import moment from 'moment';
import Event from 'common/model/Event';
import RentDescription from 'common/model/RentDescription';
import {
  FloorProps,
  FloorStatusProps,
  LocalRentFreePeriod,
  LocalRentPeriod,
  PremiseProps,
  RentDescriptionStringify,
  SpaceProps,
} from 'common/api/contracts';
import { FloorPlanProps } from 'common/api/properties';
import { ColorNames, Colors } from './utils-colors';
import { convertDateToISO } from './utils-date';
import { toCapitalSnakeCase } from './utils-string';

const getLatestFloorPlanArea = (floorPlans: FloorPlanProps[]): number => {
  const latestFloorPlan = floorPlans[floorPlans.length - 1];
  return parseInt(latestFloorPlan.grossArea);
};

/**
 * Get total area for the premises
 * @param {PremiseProps[]} premises
 * @returns
 */
export const getTotalArea = (premises: PremiseProps[]): number => {
  if (premises.length === 0) return 0;
  const firsPremises: PremiseProps = premises[0];
  const selectedSpaceIds: string[] = firsPremises.spaceIds ?? [];
  const floorsStatus = firsPremises.floorsStatus ?? [];
  const selectedFloorIds: string[] = floorsStatus.map((element: FloorStatusProps) => element.floorId);
  const propertyFloors = firsPremises?.property?.floors ?? [];
  const totalArea: number = propertyFloors
    // Filter selected Floors
    .filter((floor: FloorProps) => selectedFloorIds.includes(floor.id))
    .reduce((accTotalArea: number, floor: FloorProps) => {
      // Filter selected Spaces
      const selectedSpaces = floor.spaces.filter((space: SpaceProps) => selectedSpaceIds.includes(space.id));

      // Accumulate the area of the individual spaces slected on each floor
      // If there is no individaul space on that floor, use the floor area
      if (selectedSpaces.length) {
        accTotalArea += selectedSpaces.reduce((accSpaceArea: number, space: SpaceProps) => {
          accSpaceArea += parseInt(space.grossArea);
          return accSpaceArea;
        }, 0);
      } else {
        // If Floor could not be found
        const floorPlans: FloorPlanProps[] = floor.floorPlans;
        if (floorPlans && floorPlans.length !== 0) {
          accTotalArea += getLatestFloorPlanArea(floorPlans);
        } else {
          accTotalArea += 0;
        }
      }
      return accTotalArea;
    }, 0);

  return totalArea;
};

//TODO Bug to improve: for month that are partially on different rent period, we should calculate
//                    the prorata contribution of each rent period
export const getCurrentRent = (rentDescriptions: RentDescription[]): number | null => {
  if (rentDescriptions.length === 0) return 0;
  const today = new Date();

  //find the current rentDescription
  const match: RentDescription[] = rentDescriptions.filter((rentDescription: RentDescription) =>
    moment(today).isBetween(moment(rentDescription.startDate), moment(rentDescription.endDate), 'day', '[]'),
  );

  const rent: Decimal =
    match.length === 1
      ? match[0].monthlyBaseRent
      : moment(today).isBefore(rentDescriptions[0].startDate)
        ? rentDescriptions[0].monthlyBaseRent
        : rentDescriptions[rentDescriptions.length - 1].monthlyBaseRent;

  return rent ? parseInt(String(rent)) : null;
};

//TODO Bug to improve: for month that are partially on different rent period, we should calculate
//                    the prorata contribution of each rent period
export const getEffectiveRent = (rentDescriptions: RentDescription[] = []): number | null => {
  if (rentDescriptions.length === 0) return 0;
  let today = new Date();

  //find the current rentDescription
  const match: RentDescription[] = rentDescriptions.filter((rentDescription: RentDescription) =>
    moment(today).isBetween(moment(rentDescription.startDate), moment(rentDescription.endDate), 'day', '[]'),
  );

  const rent =
    match.length === 1
      ? match[0].monthlyEffectiveRent
      : moment(today).isBefore(rentDescriptions[0].startDate)
        ? rentDescriptions[0].monthlyEffectiveRent
        : rentDescriptions[rentDescriptions.length - 1].monthlyEffectiveRent;

  return rent ? parseInt(String(rent)) : null;
};

export const getRentPerAreaCellValue = (currentMonthlyBaseRent: number | null, totalArea: number): number | null => {
  if (!currentMonthlyBaseRent) {
    return null;
  }

  const rent: number = Number(currentMonthlyBaseRent);

  return totalArea ? Number((rent / totalArea).toFixed(2)) : null;
};

export const getContractEndDate = (endDate: string | Date, terminationDate: string | Date): Date => {
  return terminationDate ? new Date(terminationDate) : new Date(endDate);
};

type EventTypeData = {
  color: string;
  backgroundColor: string;
  text: string;
};

const contractEventTypeData: {
  [key: string]: EventTypeData;
} = {
  [Event.Occupation]: {
    color: Colors.GREEN,
    backgroundColor: Colors.GREEN_DARK,
    text: 'Occupation',
  },
  [Event.Expiry]: {
    color: Colors.RED_VERY_SOFT,
    backgroundColor: Colors.RED_PALE,
    text: 'Expiry',
  },
  [Event.RentReview]: {
    color: Colors.ORANGE,
    backgroundColor: Colors.ORANGE_LIGHT,
    text: 'Rent Review',
  },
  [Event.Expansion]: {
    color: Colors.BLUE_LIGHT,
    backgroundColor: Colors.BLUE,
    text: 'Expansion',
  },
  [Event.Surrender]: {
    color: Colors.BLUE_VERY_DARK_GRAYISH,
    backgroundColor: Colors.GRAY,
    text: 'Surrender',
  },
  [Event.Termination]: {
    color: Colors.ORANGE_VERY_SOFT,
    backgroundColor: '',
    text: 'Termination',
  },
};

export const getContractEventTypeProperty = (event: Event, property: 'color' | 'backgroundColor' | 'text'): string =>
  contractEventTypeData[toCapitalSnakeCase(event)][property];

export const getContractTimelineEventColorName = (event: string): string => {
  if (event === Event.Occupation) return ColorNames.GREEN;
  if (event === Event.Expiry) return ColorNames.RED;
  if (event === Event.RentReview) return ColorNames.ORANGE;
  if (event === Event.Expansion) return ColorNames.BLUE;
  if (event === Event.Surrender) return ColorNames.PURPLE;
  if (event === Event.Termination) return ColorNames.TOMATO;

  return '';
};

export const getNewRentPeriod = (): LocalRentPeriod => ({
  review: {
    cap: null,
    collar: null,
    date: null,
    increment: null,
    rent: null,
    type: null,
  },
  description: {
    endDate: null,
    monthlyBaseRent: null,
    monthlyEffectiveRent: null,
    startDate: null,
    validated: false,
  },
  additionalFeesAndCharges: {
    governementRates: null,
    governementRent: null,
    managementFees: null,
    airConCharges: null,
  },
  freePeriod: {
    hasRentFreePeriods: false,
    freePeriods: [],
  },
});

export const getNewRentFreePeriod = (): LocalRentFreePeriod => ({
  additionalFreeItems: [],
  startDate: null,
  endDate: null,
});

export const formatRentDescripitionForServer = (rentPeriod: LocalRentPeriod): RentDescriptionStringify => {
  const {
    additionalFeesAndCharges: charges,
    description,
    freePeriod: { freePeriods },
    review,
  } = rentPeriod;

  return {
    charges,
    ...description,
    monthlyBaseRent: description.monthlyBaseRent!,
    monthlyEffectiveRent: description.monthlyEffectiveRent!,
    endDate: convertDateToISO(description.endDate)!,
    startDate: convertDateToISO(description.startDate)!,
    rentReview: review
      ? {
          ...review,
          type: review.type!,
          date: convertDateToISO(review.date),
        }
      : null,
    rentFreePeriods: freePeriods.map(item => ({
      additionalFreeItems: item.additionalFreeItems,
      endDate: convertDateToISO(item.endDate)!,
      startDate: convertDateToISO(item.startDate)!,
    })),
  };
};
