import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { EntityCreatorTypes, deleteEntity } from 'common/api/entities';
import { Icons } from 'utils/utils-icons';

interface TenantEntitiesRowActionButtonsParams {
  rowId: string;
  onViewDetails: ViewDetailsProps;
}

export const TenantEntitiesRowActionButtons = ({ rowId, onViewDetails }: TenantEntitiesRowActionButtonsParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = (id: string) => {
    dispatch(deleteEntity({ type: EntityCreatorTypes.Tenant, id: id || '' }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Tenant',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Tenant',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Tenant entity?',
      popupConfirmationButton: 'DELETE TENANT',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
