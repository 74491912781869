import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { updateActiveStep } from 'store/entities/entityDetailSlice';
import EntityType from 'common/model/EntityType';
import { EntityCreatorTypes, createEntity, updateEntity } from 'common/api/entities';
import { namesListHK } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import { moveTo } from 'utils/utils-actions';
import { checkIfAdminURL } from 'utils/utils-admin';
import { emailIsValid } from 'utils/utils-string';

export const checkEntitiesSteps = (activeEntity: any, entityType: EntityCreatorTypes | undefined, step: number) => {
  if (step === 1) {
    const contacts = activeEntity?.contacts || [];
    if (
      checkNotEmpty(activeEntity?.name) &&
      checkNotEmpty(activeEntity?.nickname) &&
      checkNotEmpty(activeEntity?.address)
    ) {
      if (entityType === EntityCreatorTypes.Tenant) {
        if (!checkNotEmpty(activeEntity?.identifier) || !checkNotEmpty(activeEntity?.industry)) {
          return true;
        }
      }
      for (let i = 0; i < contacts.length; i++) {
        const { firstName, lastName, email } = contacts[i];
        if (!checkNotEmpty(firstName) || !checkNotEmpty(lastName) || !emailIsValid(email)) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  } else if (step === 2) {
    const {
      type,
      legalDomicile,
      residentialAddress,
      businessAddress,
      cardId,
      passportId,
      registrationCountry,
      registrationId,
      overseasRegistrationId,
      addressLocal,
    } = activeEntity;

    if (checkNotEmpty(type) && type === EntityType.Individual && checkNotEmpty(residentialAddress)) {
      if (namesListHK.includes(legalDomicile)) {
        return !checkNotEmpty(cardId);
      } else {
        return !checkNotEmpty(passportId) || !checkNotEmpty(addressLocal);
      }
    } else if (checkNotEmpty(type) && type !== EntityType.Individual && checkNotEmpty(businessAddress)) {
      if (namesListHK.includes(registrationCountry)) {
        return !checkNotEmpty(registrationId);
      } else {
        return !checkNotEmpty(overseasRegistrationId) || (type === EntityType.Company && !checkNotEmpty(addressLocal));
      }
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const entityInfoDetailsPageValidator = (activeEntity: any, entityType: EntityCreatorTypes) => {
  return checkEntitiesSteps(activeEntity, entityType, 1) || checkEntitiesSteps(activeEntity, entityType, 2);
};

interface GotoNextStepFunctionArgs {
  dispatch: Dispatch;
  navigate: NavigateFunction;
  entityId?: string;
  entityType: EntityCreatorTypes;
  activeStep: number;
  nextStep?: number;
}

export const gotoEntityCreatorNextStep = (args: GotoNextStepFunctionArgs) => {
  const { dispatch, navigate, entityId, entityType, activeStep, nextStep } = args;

  if (nextStep && nextStep === activeStep) return;

  if (nextStep && nextStep < activeStep) {
    dispatch(updateActiveStep({ activeStep: nextStep }));
    return;
  }

  if (entityId) {
    dispatch(updateEntity({ type: entityType }));
  } else {
    if (activeStep === 1) {
      dispatch(updateActiveStep({ activeStep: 2, addStep: [1] }));
    } else if (activeStep === 2) {
      dispatch(createEntity({ type: entityType }));
    } else if (activeStep === 3) {
      const url = checkIfAdminURL() ? '/entities' : `/${entityType.toLowerCase()}-entities/${entityId}/details`;
      moveTo({
        moveToEvent: dispatch(updateEntity({ type: entityType })),
        navigate,
        url,
      });
    }
  }
};
