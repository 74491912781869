import Duration from 'common/model/Duration';

export class CDuration {
  days: number;
  months: number;
  years: number;

  constructor(input: Duration) {
    this.days = input.days;
    this.months = input.months;
    this.years = input.years;
  }

  static fragment() {
    return `
        days
        months
        years
    `;
  }

  static toString(value: Duration | CDuration): string {
    let result = '';

    if (value.years) result += `${value.years} year${value.years > 1 ? 's' : ''}`;
    if (value.months)
      result += `${!value.years ? '' : value.days ? ', ' : ' and '} ${value.months} month${value.months > 1 ? 's' : ''}`;
    if (value.days)
      result += `${value.months || value.years ? ' and ' : ''} ${value.days} day${value.days > 1 ? 's' : ''}`;

    return result;
  }

  /**
   * Scales a given duration by a specified percentage, where positive percentages increase
   * and negative percentages decrease the duration.
   * @param duration The original duration to be scaled.
   * @param percentage The percentage by which to scale the duration (e.g., -50 for a 50% reduction, 50 for a 50% increase).
   * @returns A new Duration object with scaled values.
   */
  static scaleDuration(duration: Duration, percentage: number): Duration {
    const scaleFactor = 1 + percentage / 100;

    // Scale each part of the duration separately to avoid rounding issues.
    const totalMonths = duration.years * 12 + duration.months;
    const scaledMonths = totalMonths * scaleFactor;

    // Convert scaled months back to years and months
    const years = Math.floor(scaledMonths / 12);
    const months = Math.round(scaledMonths % 12);

    // Scale days proportionally, rounding for simplicity.
    const days = Math.round(duration.days * scaleFactor);

    return {
      years,
      months,
      days,
    };
  }
}
