import ShareRightRestriction from 'common/model/ShareRightRestriction';
import ShareRights from 'common/model/ShareRights';
import ShareRightsInput from 'common/model/ShareRightsInput';

export interface ShareRightsInputClone extends Omit<ShareRightsInput, 'restrictedTo'> {
  restrictedTo: ShareRightRestriction | null;
}

export class CShareRights implements ShareRightsInputClone {
  restrictedTo: ShareRightRestriction | null;

  constructor(input: ShareRights | null) {
    this.restrictedTo = input ? input.restrictedTo : null;
  }

  static fragment() {
    return `
      restrictedTo
    `;
  }

  static formatForAPI(right: CShareRights) {
    return {
      restrictedTo: right.restrictedTo ? right.restrictedTo : null,
    };
  }
}
