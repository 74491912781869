import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterUse } from 'common/_classes';

interface CheckParameterUseQueryVariables {
  id: string;
}

const CHECK_PARAMETER_USE = (id: string) => {
  if (!id) throw new Error('Check Parameter Use: id is required');

  const variables: CheckParameterUseQueryVariables = { id };

  return {
    query: gql`
      query ($id: ID!) {
        checkParameterUse(id: $id) {
          ${ParameterUse.fragments()}
        }
      }
    `,
    variables,
  };
};

export const checkParameterUse = createAsyncThunk('parameters/checkParameterUse', async ({ id }: { id: string }) => {
  const response = await adminClient.query(CHECK_PARAMETER_USE(id));
  return response;
});
