import { Accordion } from 'semantic-ui-react';
import Avatar, { AvatarSize } from 'atoms/Avatar';
import Badge, { BadgeColor } from 'atoms/Badge';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { UserProps } from 'common/api/transactions';
import UploadedDocs from '../../../../UploadedDocs';
import './SendToTenant.scss';

export enum ApprovalRequestResponse {
  APPROVE = 'approve',
  REJECT = 'reject',
}

const SendToTenant = ({
  user,
  date,
  document,
  note,
  version,
}: {
  user: UserProps | undefined;
  date: string;
  document: DocumentIntendedToBeUploaded | null;
  note: string | null;
  pendingChanges: boolean;
  version: number | undefined;
  index: number;
}) => {
  let docName = null;
  let docLink = null;

  if (document !== null) {
    const { name, url } = document;
    docName = name;
    docLink = url;
  }

  return (
    <div className="final-send-to-tenant box-flex">
      <div className="line-dot"></div>
      <div className="flex-box">
        {/* Request for Approval Box */}
        <Accordion className="approval-accordian m-t-sm">
          <Accordion.Title
            active={true}
            index={1}
            className="p-sm"
          >
            <p className="date-title color-blue-very-dark-grayish">{date} </p>
            <span className="content-title m-t-xs m-b-none">Sent to tenant</span>
          </Accordion.Title>
          <Accordion.Content
            active={true}
            className="p-sm p-t-xxs"
          >
            <div className="person color-blue-very-dark-grayish">
              Sent by &nbsp;
              <Avatar
                src={user?.photoUrl || ''}
                size={AvatarSize.Small}
                className="m-l-xs m-r-xs"
              />
              <span className="bold-person color-blue-very-dark">
                {user?.firstName} {user?.lastName}
              </span>
            </div>

            {note && (
              <p className="person m-t-sm">
                Note
                <span
                  className="editor-to-html note-text color-blue-very-dark"
                  dangerouslySetInnerHTML={{
                    __html: note,
                  }}
                ></span>
              </p>
            )}
            <span className="person color-blue-very-dark-grayish version-number">
              Version &nbsp;
              <Badge
                color={BadgeColor.LIGHT_GREY}
                className="p-t-xxs p-b-xxs p-l-xs p-r-xs"
              >
                V.{version}
              </Badge>
            </span>
          </Accordion.Content>
        </Accordion>
        {docLink !== null && (
          <UploadedDocs
            fileName={docName!}
            fileURL={docLink}
          />
        )}
      </div>
    </div>
  );
};

export default SendToTenant;
