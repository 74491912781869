import { useState } from 'react';
import DropdownSelect from 'atoms/DropdownSelect';
import InputField from 'atoms/FormField/Input';
import { RowFilterChildProps } from '.';
import { ComparablesTableFilterDropdownOptions, FilterMode, FilterOnchangeKeys } from '../../RowProps';

const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Is same as', value: FilterMode.EQUALS },
  { key: 2, text: 'Contains', value: FilterMode.CONTAINS },
  { key: 3, text: 'Start With', value: FilterMode.STARTS_WITH },
];

export interface PremisesFilterValue {
  floorFilterMode?: FilterMode.CONTAINS | FilterMode.STARTS_WITH;
  floorFilterValue?: string;
  unitFilterMode?: FilterMode.CONTAINS | FilterMode.STARTS_WITH;
  unitFilterValue?: string;
}

const PremiseFilter = ({ onChange, filterValue }: RowFilterChildProps): JSX.Element => {
  const [premisesFilterValue, setPremisesFilterValue] = useState<PremisesFilterValue | undefined>(filterValue);

  const onSetValue = (key: any, value: any) => {
    const newValue = { ...premisesFilterValue };
    // @ts-ignore
    newValue[key] = value;

    setPremisesFilterValue(newValue);
    // @ts-ignore
    onChange(FilterOnchangeKeys.filterValue, newValue);
  };
  return (
    <>
      <div className="grid">
        <div className="col-6">
          <DropdownSelect
            placeholder="Select..."
            fieldKey={'floorFilterMode' as keyof PremisesFilterValue}
            value={premisesFilterValue?.floorFilterMode}
            options={FILTER_OPTIONS}
            onChange={onSetValue}
            clearable={true}
          />
        </div>
        <div className="col-6">
          <InputField
            value={premisesFilterValue?.floorFilterValue || ''}
            placeholder={'Search by floor name'}
            onChange={onSetValue}
            fieldKey={'floorFilterValue' as keyof PremisesFilterValue}
            className="w-100"
          />
        </div>
      </div>

      <div className="grid">
        <div className="col-6">
          <DropdownSelect
            placeholder="Select..."
            fieldKey={'unitFilterMode' as keyof PremisesFilterValue}
            value={premisesFilterValue?.unitFilterMode}
            options={FILTER_OPTIONS}
            onChange={onSetValue}
            clearable={true}
          />
        </div>
        <div className="col-6">
          <InputField
            value={premisesFilterValue?.unitFilterValue || ''}
            placeholder={'Search by unit name'}
            onChange={onSetValue}
            fieldKey={'unitFilterValue' as keyof PremisesFilterValue}
            className="w-100"
          />
        </div>
      </div>
    </>
  );
};

export default PremiseFilter;
