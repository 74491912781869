import PortfolioCreatorViewer from 'views/portfolio/CreatorViewer';
import PortfolioDashboard from 'views/portfolio/Dashboard';
import { checkIfAdminURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const PortfolioRoutes = () => {
  let children = [
    {
      path: '/portfolios/dashboard',
      element: isAuthenticated(<PortfolioDashboard />),
    },
  ];

  if (!checkIfAdminURL()) {
    children = [
      ...children,
      {
        path: '/portfolios/create',
        element: isAuthenticated(<PortfolioCreatorViewer />),
      },
      {
        path: '/portfolios/:id/details',
        element: isAuthenticated(<PortfolioCreatorViewer />),
      },
    ];
  }

  return [
    {
      path: '/portfolios',
      children,
    },
  ];
};
export default PortfolioRoutes;
