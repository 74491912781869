import { ParameterGroupClone } from 'common/_classes';
import Decimal from 'decimal.js';
import { last } from 'lodash';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import { ParamRefInputProps } from 'common/api/nodes';
import { GROUP_TYPE, OptionItem } from '../../utils/types/categories';

/**
 * This handle tranforme the activeNode into a valid
 * object for ParameterMultiLevelSelect
 *
 * @param activeNode from Redux Node Slice
 */
export const handleCurrentValue = (param?: ParamRefInputProps) => {
  if (!param) return;

  const { index, offset, position, parameterId } = param;

  if (!parameterId) return;

  let label = undefined;
  let value = undefined;

  if (index || index === 0) (label = 'Index'), (value = index);
  if (offset) (label = 'Offset'), (value = offset);
  if (position) (label = 'Position'), (value = position);

  return { id: parameterId, label, value: String(value) };
};

/**
 * Convert the result of onChange method from multilevel into a ParameterObject
 *
 * @param multiLevelResult result of onChange
 */
export const handleMultilevelReturn = (multiLevelResult: OptionItem[]) => {
  const parameters: OptionItem | undefined = last(multiLevelResult);
  const hasTable: OptionItem | undefined = multiLevelResult.find(({ group }) => group === GROUP_TYPE.TABLES);

  if (!parameters) return;

  let paramRef: ParamRefInputProps = {
    parameterId: parameters.id,
    tableId: hasTable?.id || undefined,
  };

  const { label, value } = parameters?.custom || {};

  if (value) {
    if (label && label === 'Index') paramRef.index = Number(value);
    if (label && label === 'Offset') paramRef.offset = value;
    if (label && label === 'Position') paramRef.position = value;
  }

  return paramRef;
};

/**
 * Moved from TSX
 *
 * @param groups Parameter Group from slice multiLevelMenu.parameterGroups
 * @param tables Parameter Tables from slice multiLevelMenu.parameterTables
 * @param param Parameters from slice multiLevelMenu.parameterGroups
 */
export const handleMultiLevelSelector = (
  groups: ParameterGroupClone[],
  tables: ParameterTable[],
  parameters: Parameter[],
) => {
  if (!groups.length || !tables.length || !parameters.length) return [];

  let result = [] as any[];

  const createNode = (
    { id, index, name }: { id: string; index: number | Decimal; name: string },
    groupName: GROUP_TYPE,
  ) => ({
    id,
    group: groupName,
    value: index,
    label: name,
    children: [],
  });

  // Define Groups
  const resultGroups = groups.map(node => createNode(node, GROUP_TYPE.GROUPS));

  // Define Parameters
  const resultParams = parameters.map(parameter => {
    const found = resultGroups.find(({ id }) => id === parameter.parameterGroup.id);

    const newNode = createNode(parameter, GROUP_TYPE.PARAMETERS);

    if (found) {
      (found.children as any[]).push(newNode);
    }
    // TODO: This valueType should be defined by BACKEND
    return { ...newNode, selection: parameter };
  });

  // Define Tables
  const resultTables = tables.map(table => {
    const found = resultGroups.find(({ id }) => id === table.parameterGroup.id);

    const newTable = createNode(table, GROUP_TYPE.TABLES);

    if (found) {
      (found.children as any[]).push(newTable);
    }

    table.columns.map(({ parameter }) => {
      const found = resultParams.find(({ id }) => id === parameter?.id);
      if (found) {
        (newTable.children as any[]).push(found);
      }
    });

    return newTable;
  });
  return result.concat(resultGroups, resultTables, resultParams);
};
