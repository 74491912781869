import MPageInfo from 'common/model/PageInfo';

export class CPageInfo implements MPageInfo {
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  startCursor: string;
  endCursor: string;

  constructor(input: MPageInfo) {
    this.hasPreviousPage = input.hasPreviousPage;
    this.hasNextPage = input.hasNextPage;
    this.startCursor = input.startCursor;
    this.endCursor = input.endCursor;
  }

  static fragments(): string {
    return `
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
      `;
  }
}
