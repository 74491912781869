import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { updateResetStep } from 'store/authentication/authSlice';
import { Icons } from 'utils/utils-icons';
import { PASSWORD_RESET_STEPS } from '.';

const PasswordCreated = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let text = `"Keep me signed in"`;

  const onRedirect = () => {
    dispatch(updateResetStep({ step: PASSWORD_RESET_STEPS.ENTER_EMAIL }));
    navigate('/sign-in');
  };

  return (
    <Form
      className="auth-form"
      step="2"
    >
      <div className="content">
        <h1 className="header">
          New password
          <br />
          created
          <Icon
            icon={Icons.CircleCheck}
            className="circle-xl success-check-icon"
          />
        </h1>
        <p className="reset-text email-text">
          Now you have created a new password, do not forget it! Also you can click {text} on Log In page.
        </p>
        <Button
          className="login-btn height-2_857 font-600 text-center"
          type="submit"
          onClick={onRedirect}
        >
          BACK TO LOG IN PAGE
          <Icon
            icon={Icons.ArrowDownRight}
            rotate={4}
            className="width-1_143 height-1_143"
          />
        </Button>
      </div>
    </Form>
  );
};

export default PasswordCreated;
