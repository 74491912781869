import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

const LIST_TRANSACTION_APPROVAL_ANSWERS = (transactionId: string | undefined) => {
  return {
    query: gql`
      query ($transactionId: ID!) {
        listTransactionApprovalAnswers(transactionId: $transactionId) {
          id
          answer
          answerType
          dateOfAnswer
          fromPolicy
          fromInventory
          paramRef {
            parameterId
            tableId
            index
          }
          user {
            id
            firstName
            lastName
          }
          transaction {
            id
          }
          approvalState
          note
          changedValue
          transactionMessage {
            id
            message
            transactionChannel {
              id
              title
            }
          }
          transactionParameterApproval {
            id
            dateSubmitted
            user {
              id
              firstName
              lastName
              photoUrl
            }
          }
          transactionDiscussionApproval {
            id
            dateSubmitted
            user {
              id
              firstName
              lastName
              photoUrl
            }
            draftMessage
          }
          transactionProvisionApproval {
            id
          }
        }
      }
    `,
    variables: {
      transactionId,
    },
  };
};

export const listTransactionApprovalAnswers = createAsyncThunk(
  'transactions/listTransactionApprovalAnswers',
  async (_args, { getState }) => {
    const {
      transactionDetail: {
        activeTransaction: { id: transactionId },
      },
    } = getState() as RootState;
    const response = await client.query(LIST_TRANSACTION_APPROVAL_ANSWERS(transactionId));
    return response;
  },
);
