import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import TabContainer from 'components/TabContainer';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { createProperty, getProperty, updateProperty } from 'common/api/properties';
import { checkPropertySteps } from '../../propertyValidator';
import PropertyInformationContent from './PropertyInformationContent';

const PropertyInformationTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkCreate: boolean = location.pathname.includes('create');
  const [mode, setMode] = useState<MODE_OPTIONS>(checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ);
  const { activeProperty } = useAppSelector((state: RootState) => state.propertyDetail);

  const { id } = activeProperty;

  const checkDisabled = (): boolean => {
    return checkPropertySteps(activeProperty);
  };

  /**
   * On Save button Click
   */
  const onSave = (): void => {
    if (checkCreate) {
      dispatch(createProperty()).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          const propertyId = response.payload.data.createProperty.id;
          let url = `/properties/${propertyId}/details`;
          navigate(url);
        }
      });
    } else {
      dispatch(updateProperty());
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = (): void => {
    if (checkCreate) {
      navigate('/properties/dashboard');
    } else {
      // @ts-ignore
      dispatch(getProperty({ id }));
    }
  };

  return (
    <TabContainer
      tabTitle="Property Information"
      mode={mode}
      tabToolbar={
        <DetailsControlButtons
          mode={mode}
          setMode={setMode}
          checkDisabled={checkDisabled()}
          onCancel={onCancel}
          onSave={onSave}
        />
      }
    >
      {/* Mode prop is present */
      /* @ts-ignore */}
      <PropertyInformationContent />
    </TabContainer>
  );
};

export default PropertyInformationTab;
