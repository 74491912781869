import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import SortOrder from 'common/model/SortOrder';

interface ListLandlordEntitiesQueryVariables {
  sortOrder: SortOrder;
  first: number;
}

const LIST_LANDLORD_ENTITY_OPTIONS = () => {
  const variables: ListLandlordEntitiesQueryVariables = {
    sortOrder: SortOrder.Asc,
    first: 50,
  };

  return {
    query: gql`
      query ($sortOrder: SortOrder!, $first: Int!, $name_ilike: String) {
        listLandlordEntities(sortOrder: $sortOrder, first: $first, filters: { nameIlike: $name_ilike }) {
          edges {
            cursor
            node {
              id
              name
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listLandlordEntityOptions = createAsyncThunk('properties/listLandlordEntityOptions', async () => {
  const response = await client.query(LIST_LANDLORD_ENTITY_OPTIONS());
  return response;
});
