import { useState } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import PopUpInfo from 'atoms/PopUpInfo';
import { Colors } from 'utils/utils-colors';
import './SelectColor.scss';

// Values to be updated later
// Cards might be removed in the new specs
const categoryColors = [
  {
    name: 'Gray',
    value: Colors.GRAY,
    text: 'Gray (Default)',
  },
  {
    name: 'Green',
    value: Colors.GREEN_LIME,
    text: 'Green',
  },
  {
    name: 'Yellow',
    value: Colors.YELLOW,
    text: 'Yellow',
  },
  {
    name: 'Blue',
    value: Colors.BLUE,
    text: 'Blue',
  },
  {
    name: 'Violet',
    value: Colors.VIOLET,
    text: 'Violet',
  },
];

const colorInText = (color: string) => {
  const categoryColor = categoryColors.find(categoryColor => categoryColor.value === color);
  if (categoryColor) {
    return categoryColor.text;
  } else {
    return null;
  }
};

interface SelectColorProps {
  label: string;
  required: boolean;
  placeholder: string;
  value: string | null;
  onChange: (key: string, value: string) => void;
  fieldKey: string | null;
  info: boolean;
  dataTest: string;
}

const SelectColor = ({
  label,
  required,
  placeholder,
  value,
  onChange,
  fieldKey,
  info,
  dataTest,
}: SelectColorProps): JSX.Element => {
  const [text, setText] = useState<string | null>(colorInText(value as any));

  const onUpdateSelected = (categoryColor: { text: string; value: string }): void => {
    setText(categoryColor.text);
    onChange('color', categoryColor.value);
  };

  return (
    <Form.Field className="field-style">
      {label && (
        <label>
          {label}
          {required && <span className="required-indicator">*</span>}
          {info && (
            <PopUpInfo
              popUp={false}
              info={true}
            />
          )}
        </label>
      )}
      <div className="pos-rel">
        <Dropdown
          key={`${fieldKey}-key`}
          id="select-category-color"
          className="selection"
          placeholder={placeholder}
          value={value as any}
          text={text as any}
          data-test={dataTest}
        >
          <Dropdown.Menu
            className="select-color-options"
            id="dropdownStatus"
            data-test="dropdown-select"
          >
            {categoryColors.map((categoryColor, index) => (
              <Dropdown.Item
                key={'color-#' + index}
                onClick={() => onUpdateSelected(categoryColor)}
              >
                <div
                  className="circle-l select-color-icon"
                  color={categoryColor.name.toLowerCase()}
                ></div>
                <span className="select-color-text">{categoryColor.text}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Form.Field>
  );
};

export default SelectColor;
