import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { WHOLE_OR_PART_OPTIONS } from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';

const SurrenderRightsForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const surrenderRights = activeContractEvent?.surrenderRights;
  const dateValidationArray = [
    { ...surrenderRights, startDate: surrenderRights?.earliestDate, endDate: surrenderRights?.latestDate },
  ];

  const onChange = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['surrenderRights', key],
        value: value || null,
      }),
    );
  };

  const onChangeNumber = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['surrenderRights', key],
        value: value ? parseInt(value) : null,
      }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div>
      <div className="grid m-t-s">
        <div className="col-2">
          <SelectField
            label="Exercisable in whole or part"
            fieldKey="wholeOrPart"
            dataTest=""
            value={surrenderRights?.wholeOrPart || false}
            disabled={disabled}
            required={required}
            options={WHOLE_OR_PART_OPTIONS}
            onChange={onChange}
          />
        </div>
        <div className="col-2">
          <DateField
            label="Earliest surrender date"
            fieldKey="earliestDate"
            dataTest=""
            value={surrenderRights?.earliestDate || null}
            disabled={disabled}
            onChange={onChange}
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'startDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'startDate',
            )}
          />
        </div>

        <div className="col-2">
          <DateField
            label="Latest surrender date"
            fieldKey="latestDate"
            dataTest=""
            value={surrenderRights?.latestDate || null}
            disabled={disabled}
            onChange={onChange}
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'endDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'endDate',
            )}
          />
        </div>

        <div className="col-2">
          <InputField
            label="Notice period"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(months)"
            fieldKey="noticePeriodMonths"
            value={surrenderRights?.noticePeriodMonths?.toString() || null}
            disabled={disabled}
            required={required}
            onChange={onChangeNumber}
            delimiter="COMMA"
          />
        </div>
      </div>

      <div className="grid m-t-s">
        <div className="col-2">
          <TextAreaField
            label="Compensation formula"
            dataTest=""
            fieldKey="compensationFormula"
            value={surrenderRights?.compensationFormula || null}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
export default SurrenderRightsForm;
