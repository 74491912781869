import { IFunctionInputClone } from 'common/_classes';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { clearFormatterExecutionResult } from 'store/formatters/formatterDetailSlice';
import { executeBaseFormatter } from 'common/api/formatters';
import { formatterAnswerDisabled } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';
import FormatterInputTest from './InputTest';
import './FunctionRun.scss';

const FunctionRun = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeFormatter, executionResult, answerInputs, resultLoader } = useAppSelector(
    (state: RootState) => state.formatterDetail,
  );

  const checkDisabledAnswers: boolean = formatterAnswerDisabled(answerInputs, activeFormatter.function);

  const onRunFormatterFunction = (): void => {
    dispatch(clearFormatterExecutionResult());
    dispatch(executeBaseFormatter());
  };

  return (
    <Grid>
      <Grid.Row className="pm-none m-b-ml">
        <Grid.Column
          width={8}
          className="function-run p-r-xl"
        >
          {activeFormatter.inputs.map((input: IFunctionInputClone, index: number) => (
            <FormatterInputTest
              key={index}
              type={input.type}
              label={input.label}
              index={index}
            />
          ))}
        </Grid.Column>
        <Grid.Column
          width={8}
          className="p-l-xl"
        >
          <div
            className={`run-box ${checkDisabledAnswers && 'disabled-run'}`}
            onClick={() => onRunFormatterFunction()}
            data-test="run-button"
          >
            <Icon
              icon={Icons.Play}
              className="play-box bg-orange color-white"
            />
            <span className="text-to-run">Click to run</span>
          </div>
          <div className="result-box border-sm-grayish-magenta-light">
            <div className="result-box-title bg-gray-very-light-v4">
              <h2>Result</h2>
            </div>
            <p
              className="result-text"
              data-test="run-result"
            >
              {resultLoader ? 'Evaluating Result' : `${executionResult}`}
            </p>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default FunctionRun;
