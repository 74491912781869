import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

export const GET_ADMIN_PROFILE = () => {
  return {
    query: gql`
      query {
        getProfile {
          id
          email
          firstName
          lastName
          countryCode
          phoneNumber
          photoUrl
        }
      }
    `,
  };
};

export const getAdminProfile = createAsyncThunk('auth/getAdminProfile', async () => {
  const response = await adminClient.query(GET_ADMIN_PROFILE());
  return response;
});
