import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { createPortfolio, getPortfolio, updatePortfolio } from 'common/api/portfolio';
import { PortfolioClone } from 'utils/types/portfolio';

interface PortfolioDetailState {
  isLoading: boolean;
  activePortfolio: PortfolioClone;
}

const initPortfolio: PortfolioClone = {
  id: '',
  name: '',
  propertyIds: [],
  properties: [],
};

const initialState: PortfolioDetailState = {
  isLoading: false,
  activePortfolio: initPortfolio,
};

const portfolioDetailSlice = createSlice({
  name: 'portfolioDetail',
  initialState,
  reducers: {
    initNewPortfolio: state => {
      state.activePortfolio = initPortfolio;
    },
    onPortfolioValueChange: (state, action) => {
      const { key, value } = action.payload;
      const { activePortfolio } = state;
      activePortfolio[key as keyof PortfolioClone] = value;
    },
  },
  extraReducers: builder => {
    // createPortfolio
    builder.addCase(createPortfolio.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createPortfolio.fulfilled, (state, action) => {
      const createdPortfolio: PortfolioClone = action.payload.createPortfolio;
      state.activePortfolio = createdPortfolio;
      state.isLoading = false;
      toast.success(`Portfolio created Successfully`);
    });
    builder.addCase(createPortfolio.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createPortfolio API request failed');
    });

    // updatePortfolio
    builder.addCase(updatePortfolio.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updatePortfolio.fulfilled, (state, action) => {
      const updatedPortfolio: PortfolioClone = action.payload.updatePortfolio;
      state.activePortfolio = updatedPortfolio;
      state.isLoading = false;
      toast.success(`Portfolio updated Successfully`);
    });
    builder.addCase(updatePortfolio.rejected, (state, action) => {
      console.error(action.error);
      toast.error('updatePortfolio API request failed');
      state.isLoading = false;
    });

    // getPortfolio
    builder.addCase(getPortfolio.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getPortfolio.fulfilled, (state, action) => {
      state.activePortfolio = action.payload.getPortfolio;
      state.isLoading = false;
    });
    builder.addCase(getPortfolio.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getPortfolio API request failed');
    });
  },
});

export const { onPortfolioValueChange, initNewPortfolio } = portfolioDetailSlice.actions;

export default portfolioDetailSlice.reducer;
