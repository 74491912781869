import TabContainer from 'components/TabContainer';
import DevelopmentForm from '../Form';
import DevelopmentCreateEditHeaderToolbar from '../Utils/DevelopmentCreateEditHeaderToolbar';
import DevelopmentOwnedBuildingToolbar from '../Utils/DevelopmentOwnedBuildingToolbar';

const DevelopmentCreatorViewer = () => {
  return (
    <TabContainer tabToolbar={<DevelopmentCreateEditHeaderToolbar />}>
      <DevelopmentForm />
      <DevelopmentOwnedBuildingToolbar />
    </TabContainer>
  );
};

export default DevelopmentCreatorViewer;
