import { ReactElement } from 'react';
import { Popup } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './PopUpInfo.scss';

interface PopUpInfoProps {
  info?: boolean;
  popUp?: boolean;
  // TODO: Needs to be implemented
  infoText?: string | ReactElement;
  classNames?: string;
}

const PopUpInfo = ({ info, popUp, infoText, classNames }: PopUpInfoProps) => {
  const icon = (
    <Icon
      className="info-bubble"
      icon={Icons.InfoCircle}
    />
  );
  return (
    <>
      {popUp !== undefined ? (
        <Popup
          className={classNames ? `popup-info ${classNames}` : 'popup-info'}
          content={infoText}
          trigger={icon}
        />
      ) : (
        <>{info && icon}</>
      )}
    </>
  );
};

export default PopUpInfo;
