import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';

interface ListDocumentTypesQueryVariables {
  first?: number;
}

export const LIST_DOCUMENT_TYPES = ({ first }: ListDocumentTypesQueryVariables) => {
  const variables: ListDocumentTypesQueryVariables = {
    first,
  };

  return {
    query: gql`
      query ($first: Int) {
        listDocumentTypes(first: $first) {
          edges {
            node {
              id
              name
              order
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listDocumentTypes = createAsyncThunk(
  'miscellaneous/listDocumentTypes',
  async ({ first }: ListDocumentTypesQueryVariables) => {
    const response = await getClientType().query(LIST_DOCUMENT_TYPES({ first }));
    return response;
  },
);
