import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface CreateIterationMutationVariables {
  currentLandlordEntityId: string;
  transactionId: string;
}

const CREATE_ITERATION = (id: string | undefined) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();
  if (!id) throw new Error('Create Iteration: id is required');
  if (!currentLandlordEntityId) throw new Error('Create Iteration: currentLandlordEntityId is required');

  const variables: CreateIterationMutationVariables = {
    currentLandlordEntityId,
    transactionId: id,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $transactionId: ID!) {
        createIteration(currentLandlordEntityId: $currentLandlordEntityId, transactionId: $transactionId) {
          contents {
            content
            documentType {
              name
              id
            }
          }
        }
      }
    `,
    variables,
  };
};

export const createIteration = createAsyncThunk(
  'transactions/createIteration',
  async ({ id }: { id: string | undefined }) => {
    const response = await client.mutate(CREATE_ITERATION(id));
    return response;
  },
);
