import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import TabContainer from 'components/TabContainer';
import { listMembers } from 'common/api/members';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import MembersHeaderToolbar from './MembersHeaderToolbar';
import { MemberRow, memberColumnConfig } from './MembersTableConfig';

const TeamMembersTab = () => {
  const dispatch = useAppDispatch();
  const { membersList } = useAppSelector((state: RootState) => state.members);

  const currentList: MemberRow[] = membersList.map(member => new MemberRow(member));

  const getMembers = (params: PaginationAPIProps) => dispatch(listMembers(params));

  return (
    <TabContainer
      tabTitle={'Team'}
      tabToolbar={<MembersHeaderToolbar />}
    >
      <TableReactPrime
        fetchContent={({ ...rest }) => {
          getMembers(rest);
        }}
        isFetching={false}
        content={currentList}
        columnConfig={memberColumnConfig()}
        dataTest="member-table"
      />
    </TabContainer>
  );
};

export default TeamMembersTab;
