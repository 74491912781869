import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { GuidelineClone, ProvisionContentClone, ProvisionDetailClone } from 'common/_classes';
import { toast } from 'react-toastify';
import { Editor } from 'tinymce';
import { Editor as CoreEditor } from 'tinymce';
import computeAllIndexes from 'components/Editor/Clause/ComputeIndex';
import { PROVISION_TABS_OFFSET } from 'views/provisions/CreatorViewer/Tabs/ProvisionTabs';
import NodeType from 'common/model/NodeType';
import ReferenceTypes from 'common/model/ReferenceTypes';
import { RefData } from 'common/api/nodes/createClauseReferenceNode';
import { ContentClone } from 'common/api/provisions';
import {
  createProvision,
  getProvision,
  getProvisionPDFDocument,
  getProvisionPdfHtml,
  updateProvision,
  updateProvisionContent,
} from 'common/api/provisions';
import { downloadContextInPDF } from 'utils/utils-download';

interface ProvisionDetailState {
  activeProvision: ProvisionDetailClone;
  isLoading: boolean;
  activeTabIndex: PROVISION_TABS_OFFSET;
  newClauseId: string | null;
  editor: CoreEditor | null;
  activeDocTypeId: string | null;
  crossRefModal: boolean;
  crossRefDetails: RefData | null;
}

const initProvision: ProvisionDetailClone = {
  name: null,
  description: null,
  isStructure: false,
  restartNumbering: false,
  summary: null,
  index: null,
  contents: [],
  existenceParameter: null,
  guideline: GuidelineClone.init(ReferenceTypes.Provision),
  showLinkedParameter: false,
  provisionCategory: null,
};

const initialState: ProvisionDetailState = {
  activeProvision: initProvision,
  isLoading: false,
  activeTabIndex: PROVISION_TABS_OFFSET.GENERAL,
  newClauseId: null,
  editor: null,
  activeDocTypeId: null,
  crossRefModal: false,
  crossRefDetails: null,
};

const provisionDetailSlice = createSlice({
  name: 'provisionDetail',
  initialState,
  reducers: {
    updateEditorInstance: (state, action) => {
      state.editor = action.payload;
    },
    updateActiveDocTypeId: (state, action) => {
      state.activeDocTypeId = action.payload;
    },
    updateActiveProvisionGuideLine: (state, action) => {
      const { type, value } = action.payload;
      // @ts-ignore
      state.activeProvision.guideline[type] = value;
    },
    updateProvisionViewDetailsTab: (state, action) => {
      state.activeTabIndex = action.payload.tab;
    },
    createNewProvision: state => {
      state.activeProvision = initProvision;
      state.activeTabIndex = PROVISION_TABS_OFFSET.GENERAL;
    },
    updateNewClauseId: (state, action) => {
      state.newClauseId = action.payload.nodeId;
    },
    updateActiveDocumentContent: (
      state,
      action: PayloadAction<{
        editor: Editor;
        documentTypeId: string;
      }>,
    ) => {
      const { editor, documentTypeId } = action.payload;
      // Update every Clause Index Number
      computeAllIndexes(editor);

      const content = editor.getContent();

      const oldContents = state.activeProvision.contents;

      const bodyRequest = { content, documentTypeId };

      const clauseId = state.newClauseId;
      const query = `[data-node-id="${clauseId}"]`;
      const [newClauseNode] = editor.dom.select(query);

      if (newClauseNode) {
        const prevSibling = newClauseNode.previousSibling;
        const nextSibling = newClauseNode.nextSibling;
        /* Remove unnecessary line breaks 
        before and after the new clause */
        if (prevSibling !== null && nextSibling !== null) {
          editor.dom.remove(prevSibling);
          editor.dom.remove(nextSibling);
          state.newClauseId = null;
        }
      }

      // Update the old content if the ID was found.
      const contents = oldContents.map((doc: ProvisionContentClone) => {
        if (doc.documentTypeId === documentTypeId) return { ...bodyRequest, id: doc.id };
        return doc;
      });

      state.activeProvision = { ...state.activeProvision, contents };
    },
    updateActiveProvision: (state, action) => {
      const { key, value } = action.payload;
      if (key === 'contents') {
        // value here is the list of Ids of applicable documents
        const currentContents = current(state.activeProvision.contents);

        const contents = value.map((id: string) => {
          const found = currentContents.find((content: ProvisionContentClone) => content.documentTypeId === id);
          return found ? found : { documentTypeId: id, content: '' }; // create a new element in 'contents'
        });

        state.activeProvision = { ...state.activeProvision, [key]: contents };
      } else if (key === 'index') {
        state.activeProvision.index = parseInt(value);
      } else if (key === 'existenceParameter') {
        state.activeProvision.existenceParameter = { id: value };
      } else if (key === 'provisionCategory') {
        state.activeProvision.provisionCategory = { id: value };
      } else {
        // @ts-ignore
        state.activeProvision[key] = value;
      }
    },
    togglerShowExistenceParameter: state => {
      const toggle = state.activeProvision.showLinkedParameter;
      if (toggle) {
        state.activeProvision.existenceParameter = null;
      }
      state.activeProvision.showLinkedParameter = !toggle;
    },
    updateEditorDocValue: (state, action) => {
      const { documentTypeId, content } = action.payload;
      const index = state.activeProvision.contents.findIndex(
        (obj: ProvisionContentClone) => obj.documentTypeId === documentTypeId,
      );
      state.activeProvision.contents[index].content = content;
    },
    updateLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    updateProvisionContents: (state, action) => {
      const { contentsToUpdate } = action.payload;
      // Update the Provision content with new content
      for (let i = 0; i < contentsToUpdate.length; i++) {
        // Find the doc that needs an update inside contents
        const index = state.activeProvision.contents.findIndex(
          (obj: ProvisionContentClone) => obj.documentTypeId === contentsToUpdate[i].documentTypeId,
        );
        // if content found, Update the content with the latest content
        if (index !== -1) {
          state.activeProvision.contents[index].content = contentsToUpdate[i].content;
        }
      }
    },
    setCrossRefModal: (state, action) => {
      state.crossRefModal = action.payload;
    },
    setCrossRefDetails: (state, action) => {
      state.crossRefDetails = action.payload;
    },
  },
  extraReducers: builder => {
    //createProvision
    builder.addCase(createProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createProvision.fulfilled, (state, action) => {
      const { createProvision } = action.payload.data;
      state.activeProvision = createProvision;
      toast.success('Provision Created Successfully');
    });
    builder.addCase(createProvision.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('createProvision API request was rejected');
    });

    //updateProvision
    builder.addCase(updateProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateProvision.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.activeProvision = data.updateProvision;
      state.activeProvision.showLinkedParameter = !!state.activeProvision.existenceParameter;
      state.isLoading = false;
      toast.success(`Provision updated successfully`);
    });
    builder.addCase(updateProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateProvision API request was rejected');
    });

    //updateProvisionContent
    builder.addCase(updateProvisionContent.fulfilled, (state, action) => {
      const { documentTypeId } = action.meta.arg;
      const { updateProvisionContent } = action.payload.data;

      const index = state.activeProvision.contents.findIndex(
        (obj: ContentClone) => obj.documentTypeId === documentTypeId,
      );
      state.activeProvision.contents[index] = updateProvisionContent;
      state.isLoading = false;
    });
    builder.addCase(updateProvisionContent.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateProvisionContent API request was rejected');
    });

    //getProvision
    builder.addCase(getProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getProvision.fulfilled, (state, action) => {
      const provision = action.payload.data.getProvision;
      state.activeProvision = provision;
      state.activeProvision.showLinkedParameter = !!provision.existenceParameter;
      /* Reset to general tab on moving to a new provision so 
      that it does not have the undo instance of old provision */
      state.activeTabIndex = PROVISION_TABS_OFFSET.GENERAL;
      state.isLoading = false;
    });
    builder.addCase(getProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getProvision API request was rejected');
    });

    // getProvisionPdfHtml
    builder.addCase(getProvisionPdfHtml.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getProvisionPdfHtml.fulfilled, (state, action) => {
      const html = action.payload.data.getProvisionPdfHtml;
      const endOfInternalStyle = html.indexOf('</style>');
      const newHtml = html.slice(0, endOfInternalStyle) + 'body{padding: 5rem}' + html.slice(endOfInternalStyle);

      const tab = window.open();
      tab?.document.write(newHtml);
      tab?.document.close();
      state.isLoading = false;
    });
    builder.addCase(getProvisionPdfHtml.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getProvisionPdfHtml API request was rejected');
    });

    //getProvisionPDFDocument
    builder.addCase(getProvisionPDFDocument.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getProvisionPDFDocument.fulfilled, (state, action) => {
      downloadContextInPDF(action.payload.data.getProvisionPDFDocument, NodeType.Provision, state.activeProvision.name);
      state.isLoading = false;
    });
    builder.addCase(getProvisionPDFDocument.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getProvisionPDFDocument API request was rejected');
    });
  },
});

export const {
  createNewProvision,
  togglerShowExistenceParameter,
  updateActiveProvision,
  updateActiveDocumentContent,
  updateProvisionViewDetailsTab,
  updateActiveProvisionGuideLine,
  updateNewClauseId,
  updateEditorDocValue,
  updateLoader,
  updateProvisionContents,
  updateEditorInstance,
  updateActiveDocTypeId,
  setCrossRefModal,
  setCrossRefDetails,
} = provisionDetailSlice.actions;

export default provisionDetailSlice.reducer;
