enum CharacteristicsTypes {
  CityView = 'CITY_VIEW',
  SeaView = 'SEA_VIEW',
  MountainView = 'MOUNTAIN_VIEW',
  PartialCityView = 'PARTIAL_CITY_VIEW',
  PartialSeaView = 'PARTIAL_SEA_VIEW',
  PartialMountainView = 'PARTIAL_MOUNTAIN_VIEW',
  NoView = 'NO_VIEW',
  SuspendingCeiling = 'SUSPENDING_CEILING',
  RaisedFloor = 'RAISED_FLOOR'
}
export default CharacteristicsTypes;
