import { useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { Form, Popup } from 'semantic-ui-react';
import { conditionalClassName } from 'utils/tsHelper';
import { labelResizeObserver } from 'utils/utils-html';
import { generateUniqueId } from 'utils/utils-random';
import './PhoneInput.scss';

interface PhoneNumberFieldProps {
  label: string | null;
  required?: boolean;
  placeholder?: string | null;
  disabled?: boolean;
  value: string | null;
  countryCode?: string | null;
  fieldKey: string;
  useCountryCode?: boolean;
  onChange: Function;
  dataTest?: string;
  className?: string;
}

const PhoneNumberField = ({
  label,
  required,
  placeholder,
  disabled,
  value,
  countryCode,
  onChange,
  fieldKey,
  useCountryCode,
  className = '',
}: PhoneNumberFieldProps): JSX.Element => {
  const [showLabelPopup, setShowLabelPopup] = useState(false);
  const [formContainerId] = useState<string>(generateUniqueId(''));

  const onChangeHandler = (value: string, dialCode: string) => {
    let number;
    if (value.substring(0, dialCode.length) === dialCode && value.length > dialCode.length) {
      number = value.substring(dialCode.length);
    } else if (value.length <= dialCode.length) {
      number = '';
    } else {
      number = value;
    }

    onChange(fieldKey, number, number ? '+' + dialCode : null);
  };

  const getValue = (value: string | null, countryCode?: string | null): string | null => {
    if (!countryCode && !value) {
      return '';
    } else if (!value && countryCode) {
      return countryCode.substring(1);
    } else if (!countryCode) {
      return value;
    } else {
      return countryCode.substring(1) + value;
    }
  };

  useEffect(() => {
    // Label popup control when label is truncated with an ellipsis
    labelResizeObserver(formContainerId, setShowLabelPopup);
  }, []);

  return (
    <Form.Field
      className={`field-style phone-input ${conditionalClassName(disabled, 'custom-disabled')} ${conditionalClassName(className, className)}`}
      id={formContainerId}
    >
      {label && (
        <label>
          <Popup
            trigger={<span>{label}</span>}
            content={label}
            disabled={!showLabelPopup}
          />
          {required && <span className="required-indicator">*</span>}
        </label>
      )}

      <PhoneInput
        placeholder={placeholder ? placeholder : ''}
        country={countryCode !== null ? '' : 'hk'}
        value={getValue(value, countryCode)}
        onChange={(value: string, data: CountryData) =>
          useCountryCode ? onChangeHandler(value, data.dialCode) : onChange(fieldKey, value)
        }
      />
    </Form.Field>
  );
};

export default PhoneNumberField;
