import { useState } from 'react';
import { Button, DropdownProps, Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import DropdownSelect from 'atoms/DropdownSelect';
import ExportModal, { ExportTypes } from 'atoms/ExportDropdown/ExportModal';

interface PropertyDownloadTemplateProps {
  onExport: (args: { startDate?: string; endDate?: string; propertyId?: string }) => void;
  isExporting: boolean;
  exportSuccess: boolean;
  exportFail: boolean;
}

const PropertyDownloadTemplate = ({
  onExport,
  isExporting,
  exportSuccess,
  exportFail,
}: PropertyDownloadTemplateProps) => {
  const { propertiesList } = useAppSelector((state: RootState) => state.propertiesListing);

  const dropdown: DropdownProps[] = propertiesList.map((property: any) => ({
    key: property.id,
    text: property.buildingName,
    value: property.id,
  }));

  const [propertyId, setPropertyId] = useState<string>('');
  const stopPropagation = (e: React.MouseEvent<HTMLElement>): void => e.stopPropagation();

  return (
    <Form className="template-download bg-white">
      <div
        className="d-flex m-t-sm"
        onClick={stopPropagation}
      >
        <label className="m-r-sm">Property</label>
        <DropdownSelect
          fieldKey="propertyId"
          disabled={dropdown.length === 1}
          options={dropdown}
          value={propertyId}
          onChange={(key, value) => setPropertyId(value as string)}
        />
      </div>
      <div className="d-flex m-t-m">
        <Button className="btn grey-outline">CANCEL</Button>
        <ExportModal
          type={ExportTypes.XLSX}
          onTryAgain={() => onExport({ propertyId })}
          isExporting={isExporting}
          exportSuccess={exportSuccess}
          exportFail={exportFail}
          trigger={
            <Button
              className="btn grey-bg m-l-xl"
              onClick={() => onExport({ propertyId })}
              disabled={!propertyId}
            >
              Export
            </Button>
          }
        />
      </div>
    </Form>
  );
};

export default PropertyDownloadTemplate;
