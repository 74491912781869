import { ChartDataset, ChartType, TooltipModel } from 'chart.js';
import Graph from './Graph';
import './Graph.scss';

interface GraphContainerProps {
  title?: string;
  datasets: ChartDataset<ChartType, (string | null)[]>[];
  labels: string[];
  tooltipHTML: (tooltipModel: TooltipModel<ChartType>) => string;
  stackedBar: boolean;
  tooltipXAxisTolerance: number;
  chartType: ChartType;
  yAxisTitle: string;
  displayHorizontalGridLines: boolean;
  displayVerticalGridLines: boolean;
}

const GraphContainer = ({
  title,
  datasets,
  labels,
  tooltipHTML,
  stackedBar,
  tooltipXAxisTolerance,
  chartType,
  yAxisTitle,
  displayHorizontalGridLines,
  displayVerticalGridLines,
}: GraphContainerProps) => {
  return (
    <div className="graph-container-box">
      <h2 className="graph-title color-blue-very-dark-grayish">{title}</h2>
      {datasets.length === 0 ? (
        <div className="no-data">No data...</div>
      ) : (
        <Graph
          chartType={chartType}
          labels={labels}
          datasets={datasets}
          tooltipHTML={tooltipHTML}
          stackedBar={stackedBar}
          tooltipXAxisTolerance={tooltipXAxisTolerance}
          yAxisTitle={yAxisTitle}
          displayHorizontalGridLines={displayHorizontalGridLines}
          displayVerticalGridLines={displayVerticalGridLines}
        />
      )}
    </div>
  );
};

export default GraphContainer;
