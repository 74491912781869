import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import SelectColor from 'atoms/SelectColor/SelectColor';
import { updateActiveCategory, updateActiveGroup } from 'store/parameters/parametersListingSlice';
import { PARAMETERS_CLASSIFICATION_OPTIONS } from 'common/api/miscellaneous';
import { listParameterCategories } from 'common/api/parameters';
import { capitalizeFirstLetter } from 'utils/utils-string';

interface CreateFormProps {
  type: PARAMETERS_CLASSIFICATION_OPTIONS;
}

const CreateForm = ({ type }: CreateFormProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeCategory, activeGroup, parameterCategoriesOptions } = useAppSelector(
    (state: RootState) => state.parametersListing,
  );

  const color: string = activeCategory.color!;
  const categoryId: string | null = activeGroup.category && activeGroup.category.id ? activeGroup.category.id : null;
  const isGroup: boolean = type === PARAMETERS_CLASSIFICATION_OPTIONS.GROUP;
  const isCategory: boolean = type === PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY;

  const onChange = (key: string, value: string): void => {
    if (isGroup) {
      dispatch(updateActiveGroup({ key, value }));
    } else if (isCategory) {
      dispatch(updateActiveCategory({ key, value }));
    }
  };

  useEffect(() => {
    if (isGroup) {
      dispatch(listParameterCategories());
    }
  }, [dispatch, type]);

  const index: number | null = isCategory ? activeCategory.index : activeGroup.index;

  return (
    <Grid className="parameters-modal-form">
      <Grid.Row>
        <Grid.Column width="16">
          <InputField
            label={`${capitalizeFirstLetter(type)} name`}
            type={InputFieldType.TEXT}
            fieldKey="name"
            placeholder={`Type ${type.toLowerCase()} name...`}
            value={isCategory ? activeCategory.name : activeGroup.name}
            onChange={onChange}
            required={true}
            dataTest="name-for-category-or-group"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width="16">
          {isCategory ? (
            <SelectColor
              label="Select color"
              fieldKey={color}
              placeholder="Select color..."
              value={color}
              onChange={onChange}
              info={true}
              required={true}
              dataTest="select-color"
            />
          ) : (
            <SelectField
              label="Select category"
              fieldKey="parameterCategory"
              placeholder="Select category..."
              options={parameterCategoriesOptions}
              value={categoryId}
              onChange={onChange}
              info={true}
              required={true}
              dataTest="category-select"
            />
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width="16">
          <InputField
            label="Index"
            type={InputFieldType.NUMBER}
            fieldKey="index"
            value={String(index || '')}
            required={true}
            onChange={onChange}
            isDecimal={true}
            delimiter="COMMA"
            placeholder="Type index..."
            dataTest="enter-index"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CreateForm;
