import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import DateField from 'atoms/FormField/DateInput';
import ModalTemplate from 'components/ModalTemplate';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const TermForm = ({ mode }: ModeProps) => {
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);
  const [holdoverFlag, setHoldoverFlag] = useState(false);
  const [holdoverLikelyToRenew, setHoldoverLikelyToRenew] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: Date) => {
    dispatch(updateActiveContractEventInput({ key, value: value || null }));
  };

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  const toggleHoldoverFlag = (key: string, value: boolean) => {
    setHoldoverFlag(value);
  };

  const toggleHoldoverLikelyToRenew = (key: string, value: boolean) => {
    setHoldoverLikelyToRenew(value);
  };

  const generateNewTenancy = () => {
    console.log('generateNewTenancy');
    setModalOpen(true);
  };

  return (
    <>
      <div className="grid">
        <div className="col-2">
          <DateField
            label="Term start date"
            fieldKey="startDate"
            dataTest="term-start-date-input"
            value={activeContractEvent?.startDate || ''}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </div>
        <div className="col-2">
          <DateField
            minDate={activeContractEvent?.startDate}
            label="Term end date"
            fieldKey="endDate"
            dataTest="term-end-date-input"
            value={activeContractEvent?.endDate || ''}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </div>
        <div className="col-2">
          <DateField
            maxDate={activeContractEvent?.startDate}
            label="Early occupation start date"
            fieldKey="earlyOccupationStartDate"
            dataTest=""
            value={activeContractEvent?.earlyOccupationStartDate || ''}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
        <div className="col-3">
          <label className="font-600 font-size-0_8571">Holdover tenancy?</label>
          <CheckboxField
            className="d-flex align-center"
            dataTest=""
            fieldKey="holdoverFlag"
            value={activeContractEvent?.holdoverFlag}
            toggle={true}
            disabled={disabled}
            onChange={onChange}
          />
          {activeContractEvent?.holdoverFlag && (
            <div className="m-t-xs">
              <label className="font-600 font-size-0_8571">Likely to renew?</label>
              <CheckboxField
                className="d-flex align-center"
                dataTest=""
                fieldKey="holdoverLikelyToRenew"
                value={activeContractEvent?.holdoverLikelyToRenew}
                toggle={true}
                disabled={disabled}
                onChange={onChange}
              />
            </div>
          )}
        </div>
        {activeContractEvent?.holdoverFlag && (
          <div className="col-3">
            {activeContractEvent?.holdoverLikelyToRenew && (
              <Button
                className="border-sm-blue-very-dark  m-t-xs"
                onClick={generateNewTenancy}
              >
                Generate New Tenancy
              </Button>
            )}
          </div>
        )}
      </div>
      <ModalTemplate
        isOpen={modalOpen}
        onToggleModalStatus={setModalOpen}
        className={'renew-tenancy'}
        title={'Renew Tenancy'}
      >
        <>
          <div className="grid m-b-sm">
            <div className="col-12">
              <p>
                You are about to renew & create a new tenancy. Please enter the new term start and end date of the
                renewed tenancy. All the other properties will be automatically transferred from the previous tenancy.
              </p>
            </div>
            <div className="col-2">
              <DateField
                label="Term start date"
                fieldKey="startDate"
                dataTest="term-start-date-input"
                value={activeContractEvent?.startDate || ''}
                disabled={disabled}
                required={required}
                onChange={onChange}
              />
            </div>
            <div className="col-2">
              <DateField
                minDate={activeContractEvent?.startDate}
                label="Term end date"
                fieldKey="endDate"
                dataTest="term-end-date-input"
                value={activeContractEvent?.endDate || ''}
                disabled={disabled}
                required={required}
                onChange={onChange}
              />
            </div>
          </div>
        </>
      </ModalTemplate>
    </>
  );
};

export default TermForm;
