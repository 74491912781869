import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  createTranslateToContract,
  getTranslateToContract,
  updateTranslateToContract,
} from 'common/api/translatorsToContract';
import { TranslateToContractClone } from 'common/api/translatorsToContract';
import { getFrozenObjectCopy, getNewReviewer } from 'utils/tsHelper';

interface TranslatorDetailState {
  activeTranslateToContract: TranslateToContractClone;
  isLoading: boolean;
  activeTranslateToContractFrozenCopy: Readonly<TranslateToContractClone>;
}

const initTranslateToContract: TranslateToContractClone = {
  name: null,
  description: null,
  active: false,
  index: null,
  function: null,
  reviewFlag: false,
  reviewStatus: {
    date: null,
    user: getNewReviewer(),
  },
  // TO FIX
  parameters: [] as any,
  parameterGroup: [] as any,
};

const initialState: TranslatorDetailState = {
  activeTranslateToContract: initTranslateToContract,
  isLoading: false,
  activeTranslateToContractFrozenCopy: initTranslateToContract,
};

const translatorDetailSlice = createSlice({
  name: 'translatorDetail',
  initialState,
  reducers: {
    updateTranslateToContractReviewedStatus: (state, action) => {
      const { reviewStatus, reviewFlag } = action.payload;
      state.activeTranslateToContract.reviewStatus = reviewStatus;
      state.activeTranslateToContract.reviewFlag = reviewFlag;
    },
    createNewTranslateToContract: state => {
      state.activeTranslateToContract = initTranslateToContract;
    },
    updateActiveTranslateToContract: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeTranslateToContract[key] = value;
    },
  },
  extraReducers: builder => {
    //createTranslateToContract
    builder.addCase(createTranslateToContract.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createTranslateToContract.fulfilled, (state, action) => {
      const { createTranslateToContract } = action.payload.data;
      state.activeTranslateToContract = createTranslateToContract;
    });
    builder.addCase(createTranslateToContract.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('createTranslateToContract API request rejected');
    });

    //updateTranslateToContract
    builder.addCase(updateTranslateToContract.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateTranslateToContract.fulfilled, (state, action) => {
      const translateToContract: TranslateToContractClone = action.payload.data.updateTranslateToContract;
      state.activeTranslateToContract = translateToContract;
      state.activeTranslateToContractFrozenCopy = getFrozenObjectCopy(translateToContract) as TranslateToContractClone;
      state.isLoading = false;
      toast.success('Translate to contract updated successfully');
    });
    builder.addCase(updateTranslateToContract.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateTranslateToContract API request rejected');
    });

    //getTranslateToContract
    builder.addCase(getTranslateToContract.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getTranslateToContract.fulfilled, (state, action) => {
      const translateToContract: TranslateToContractClone = action.payload.data?.getTranslateToContract;
      state.activeTranslateToContract = translateToContract;
      state.activeTranslateToContractFrozenCopy = getFrozenObjectCopy(translateToContract) as TranslateToContractClone;
      state.isLoading = false;
    });
    builder.addCase(getTranslateToContract.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getTranslaoteToContract API request rejected');
    });
  },
});

export const {
  createNewTranslateToContract,
  updateActiveTranslateToContract,
  updateTranslateToContractReviewedStatus,
} = translatorDetailSlice.actions;

export default translatorDetailSlice.reducer;
