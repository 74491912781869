import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { DocumentsType } from '../contracts';

export const PREP_ENTITY_DOCS_FOR_UPLOAD = (files: DocumentsType[], id: string) => {
  let prepFilesQuery = '';

  for (let i = 0; i < files.length; i++) {
    const { name, type } = files[i].file;

    prepFilesQuery += `
        prepEntityDoc${files[i].id}: prepEntityDocument(entityId: "${id}", fileName: "${name}", mimeType: "${type}"){
          cdnUrl
          uploadUrl
          path
        }
      `;
  }

  return {
    query: gql`
        query {
          ${prepFilesQuery}
        }
      `,
  };
};

export const prepEntityDocsForUpload = createAsyncThunk(
  'entityDetail/prepEntityDocsForUpload',
  async (args, { getState }) => {
    const {
      entityDetail: {
        activeEntity: { id, documents },
      },
    } = getState() as RootState;

    const filteredFiles = documents.filter((file: DocumentsType) => file.local);

    const response = await client.query(PREP_ENTITY_DOCS_FOR_UPLOAD(filteredFiles, id));
    return response;
  },
);
