import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { CValuationReport } from 'common/_classes/valuationReport2';
import { ID } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetContractValuationReportQueryVariables {
  currentLandlordEntityId: string;
  contractId: string;
}

const GET_CONTRACT_VALUATION_REPORT = (contractId: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('Get Contract Revenue: Landlord Entity could not be determined. Query aborted.');
  }

  const queryVariables: GetContractValuationReportQueryVariables = {
    currentLandlordEntityId,
    contractId: contractId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $contractId: ID!) {
        valuationReport2(
          contractId: $contractId
          currentLandlordEntityId: $currentLandlordEntityId
        ) {
          ${CValuationReport.fragment()}
        }
      }
    `,
    variables: queryVariables,
  };
};

export const getContractValuationReport = createAsyncThunk(
  'contractDetailV2/getContractValuationReport',
  async ({ id }: ID) => {
    const response = await client.query(GET_CONTRACT_VALUATION_REPORT(id));
    return response;
  },
);
