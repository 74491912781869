import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import SelectField from 'atoms/FormField/Select';
import PremisesForm from 'components/PremisesForm';
import { FLOOR_PORTION_OPTIONS, PremiseSelectedProps } from 'components/PremisesForm/utils-premiseForm';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { EXPANSION_RIGHT_RENT_ASSESSMENT_OPTIONS, WHOLE_OR_PART_OPTIONS } from 'utils/UI';
import { getMaxDateBoundaryForTenancies, getMinDateBoundaryForTenancies } from 'utils/utils-date';

const ExpansionRightsForm = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);
  const { landlordProperties } = useAppSelector(({ miscellaneous }: RootState) => miscellaneous);

  const expansionRights = activeContractEvent?.expansionRights;

  const onChange = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['expansionRights', key],
        value: value || null,
      }),
    );
  };

  const dateValidationArray = [{ ...expansionRights, startDate: null, endDate: expansionRights?.latestDate }];

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  const selectedProperty = landlordProperties.find(({ id }) => id === activeContractEvent?.premises.propertyId);

  const getValue = () => {
    const { floors, propertyId } = expansionRights || {};

    if (!propertyId) {
      return [
        {
          property: selectedProperty || null,
          floorIds: [
            {
              id: '',
              spaceIds: [],
              floorPortion: null,
            },
          ],
        },
      ];
    }

    return [
      {
        property: selectedProperty,
        floorIds:
          floors?.map(floorData => ({
            id: floorData.floorId,
            spaceIds: floorData.spaceIds,
            floorPortion: floorData.whole ? FLOOR_PORTION_OPTIONS.WHOLE : FLOOR_PORTION_OPTIONS.PART,
          })) || [],
      },
    ];
  };

  const onPremiseChange = (premises: PremiseSelectedProps[]) => {
    if (premises.length > 0) {
      const premise = premises[0];

      const premiseData = {
        propertyId: premise.property?.id || '',
        floors: premise.floorIds.map(floorData => ({
          floorId: floorData.id,
          spaceIds: floorData.spaceIds,
          whole: floorData.floorPortion === FLOOR_PORTION_OPTIONS.WHOLE,
        })),
      };

      const updatedExpansionRights = { ...expansionRights, ...premiseData };

      dispatch(updateActiveContractEventInput({ key: 'expansionRights', value: updatedExpansionRights }));
    }
  };

  return (
    <div>
      <div className="grid m-t-s p-xs">
        <PremisesForm
          mode={mode}
          properties={selectedProperty ? [selectedProperty] : []}
          landlordId={activeContractEvent?.ownerId}
          value={getValue()}
          onChange={onPremiseChange}
        />
      </div>
      <div className="grid m-t-s">
        <div className="col-2">
          <SelectField
            label="Exercisable in whole or part"
            fieldKey="wholeOrPart"
            dataTest=""
            value={expansionRights?.wholeOrPart || false}
            disabled={disabled}
            required={required}
            options={WHOLE_OR_PART_OPTIONS}
            onChange={onChange}
          />
        </div>

        <div className="col-2">
          <SelectField
            label="Rent assessment"
            fieldKey="rentAssessment"
            dataTest=""
            value={expansionRights?.rentAssessment || false}
            disabled={disabled}
            required={required}
            options={EXPANSION_RIGHT_RENT_ASSESSMENT_OPTIONS}
            onChange={onChange}
          />
        </div>

        <div className="col-2">
          <DateField
            label="Latest expansion date"
            fieldKey="latestDate"
            dataTest=""
            value={expansionRights?.latestDate || null}
            disabled={disabled}
            onChange={onChange}
            minDate={getMinDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'endDate',
            )}
            maxDate={getMaxDateBoundaryForTenancies(
              activeContractEvent?.startDate,
              activeContractEvent?.endDate,
              dateValidationArray,
              0,
              'endDate',
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default ExpansionRightsForm;
