import { ReactElement } from 'react';
import CheckboxField from 'atoms/FormField/Checkbox';
import PopUpInfo from 'atoms/PopUpInfo';

interface ToggleCheckboxProps {
  label?: string | null;
  value: string | null | boolean;
  onChange: (key: string, value: boolean) => void;
  fieldKey: string;
  popUp?: boolean;
  infoText?: ReactElement | string;
  isDisabled: boolean;
  dataTest?: string;
  classNames?: string;
}

const ToggleCheckbox = ({
  label,
  isDisabled,
  value,
  onChange,
  fieldKey,
  popUp,
  infoText,
  dataTest,
  classNames,
}: ToggleCheckboxProps) => {
  return (
    <div className={`special-clause-field bg-gray-very-light-v4 ${isDisabled && 'disabled'}`}>
      <span>
        {`${label} `}
        <PopUpInfo
          classNames={classNames}
          popUp={popUp}
          infoText={infoText}
          info={true}
        />
      </span>
      {/* @ts-ignore:next-line */}
      <CheckboxField
        value={Boolean(value)}
        onChange={onChange}
        fieldKey={fieldKey}
        toggle={true}
        disabled={isDisabled}
        dataTest={dataTest}
      />
    </div>
  );
};

export default ToggleCheckbox;
