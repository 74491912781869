import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import TransactionParameterApproval from 'common/model/TransactionParameterApproval';
import TransactionParameterApprovalInput from 'common/model/TransactionParameterApprovalInput';

interface updateTransactionParameterApprovalMutationVariables {
  transactionParameterApprovalId: string;
  params: TransactionParameterApprovalInput;
}

export const UPDATE_TRANSACTION_PARAMETER_APPROVAL = (
  transactionParameterApproval: TransactionParameterApproval | undefined,
) => {
  if (!transactionParameterApproval?.id)
    throw new Error('Update Transaction Parameter Approval: Transaction Parameter Approval ID is missing');

  const variables: updateTransactionParameterApprovalMutationVariables = {
    transactionParameterApprovalId: transactionParameterApproval.id,
    params: {
      userId: transactionParameterApproval.user.id,
      dateSubmitted: transactionParameterApproval.dateSubmitted,
    },
  };

  return {
    mutation: gql`
      mutation ($transactionParameterApprovalId: ID!, $params: TransactionParameterApprovalInput!) {
        updateTransactionParameterApproval(
          transactionParameterApprovalId: $transactionParameterApprovalId
          params: $params
        ) {
          id
          dateSubmitted
          user {
            id
            firstName
            lastName
            photoUrl
          }
        }
      }
    `,
    variables: variables,
  };
};

export const updateTransactionParameterApproval = createAsyncThunk(
  'transactions/updateTransactionParameterApproval',
  async ({ approvalId }: { approvalId: string }, { getState }) => {
    const {
      transactionDetail: { transactionParameterApprovals },
    } = getState() as RootState;
    const activeTransactionParameterApproval = transactionParameterApprovals.find(
      (obj: TransactionParameterApproval) => obj.id === approvalId,
    );

    const response = await client.mutate(UPDATE_TRANSACTION_PARAMETER_APPROVAL(activeTransactionParameterApproval));
    return response;
  },
);
