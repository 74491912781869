import { useNavigate } from 'react-router-dom';
import { Button, Grid, Image } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import './Section.scss';

interface SectionProps {
  header: string;
  content: string;
  url: string;
  btnText: string;
  iconName?: string;
  iconSrc?: string;
}

const Section = ({ header, content, url, btnText, iconName, iconSrc }: SectionProps) => {
  const navigate = useNavigate();
  return (
    <Grid.Column
      className="section-column border-sm-grayish-magenta-light bg-white"
      width={4}
      data-test="section"
    >
      {iconSrc ? (
        <Image
          className="inner-icon inner-image"
          src={iconSrc}
        />
      ) : iconName ? (
        <Icon
          className="inner-icon"
          icon={iconName}
        />
      ) : (
        ''
      )}
      <h2
        className="section-header color-blue-very-dark font-600 color-blue-very-dark d-flex"
        data-test="section-title"
      >
        {header}
      </h2>
      <p
        className="section-content color-blue-very-dark-grayish"
        data-test="section-content"
      >
        {content}
      </p>
      <Button
        className="section-button border-ml-blue-very-dark color-blue-very-dark-grayish"
        onClick={() => navigate(url)}
        data-test="section-button"
      >
        SEE {btnText}
      </Button>
    </Grid.Column>
  );
};

export default Section;
