import { store } from 'store';
import NodeType from 'common/model/NodeType';
import { ContentNodeType, createNode } from 'common/api/nodes';

interface ClauseProps {
  index: number;
  level: number;
  nodeId: string;
}

export const Clause = ({ index, level, nodeId }: ClauseProps) => {
  const params = window.location.pathname.split('/') ?? '';

  const bodyRequest = {
    type: NodeType.Clause,
    level: level,
    provisionId: params[2],
    name: nodeId,
    id: nodeId,
  };

  // Call create clause api
  store.dispatch(createNode(bodyRequest));

  return (
    <div
      data-node-id={nodeId}
      data-node-type={ContentNodeType.CLAUSE}
    >
      <div
        data-index={index}
        data-node-type={ContentNodeType.CLAUSE_INDEX}
        contentEditable="false"
        suppressContentEditableWarning={true}
      >
        1
      </div>
    </div>
  );
};

export default Clause;
