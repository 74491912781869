import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

const PREP_PROPERY_DOCS_FOR_UPLOAD = (files: any[], id: string | null) => {
  let prepFilesQuery = '';

  for (let i = 0; i < files.length; i++) {
    const { name, type } = files[i].file;

    prepFilesQuery += `
      prepPropertyDoc${files[i].id}: prepPropertyDocument(propertyId: "${id}", fileName: "${name}", mimeType: "${type}"){
        cdnUrl
        uploadUrl
        path
      }
    `;
  }

  return {
    query: gql`
      query {
        ${prepFilesQuery}
      }
    `,
  };
};

export const prepPropertyDocsForUpload = createAsyncThunk(
  'properties/prepEntityDocsForUpload',
  async (_args, { getState }) => {
    const {
      propertyDetail: {
        activeProperty: { id, documents },
      },
    } = getState() as RootState;

    const filteredFiles = documents.filter((document: any) => document.file.local);

    const response = await client.query(PREP_PROPERY_DOCS_FOR_UPLOAD(filteredFiles, id));
    return response;
  },
);
