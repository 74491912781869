import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '../store';
import ChangeContext from './useChangeContext';
import previousValue from './usePreviousValue';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useChangeContext = () => {
  ChangeContext();
};

export const usePreviousValue = <T>(value: T) => previousValue<T>(value);
