import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteTranslateToContractQueryVariables {
  id: string;
}

export const DELETE_TRANSLATE_TO_CONTRACT = (id: string) => {
  if (!id) throw new Error('Delete TranslateToContract: id is required');

  const variables: DeleteTranslateToContractQueryVariables = { id };
  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteTranslateToContract(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteTranslateToContract = createAsyncThunk(
  'translatorsToContract/deleteTranslateToContract',
  async ({ id }: { id: string }) => {
    const response = await adminClient.mutate(DELETE_TRANSLATE_TO_CONTRACT(id));

    return response;
  },
);
