import { AVAILABILITY_STATUS } from '../stackingPlanDefinitions';
import ContractAvailableStatusItem from './ContractAvailableStatusItem';
import './ContractAvailableStatusList.scss';

interface ContractAvailableStatusListProps {
  showCheckbox?: boolean;
  onChange?: Function;
  allFilters?: string[];
}

const stackingPlanLegendFilters = [
  { label: 'Available', value: AVAILABILITY_STATUS.AVAILABLE, className: 'bg-blue-dark-grayish' },
  { label: '< 6 months', value: AVAILABILITY_STATUS.LESS_THAN_SIX, className: 'bg-red-soft' },
  { label: '6 - 12 months', value: AVAILABILITY_STATUS.SIX_TO_TWELVE, className: 'bg-orange' },
  { label: '12 - 24 months', value: AVAILABILITY_STATUS.TWELVE_TO_TWENTY_FOUR, className: 'bg-yellow' },
  { label: '> 24 months', value: AVAILABILITY_STATUS.MORE_THAN_TWENTY_FOUR, className: 'bg-green' },
];

const ContractAvailableStatusList = ({ onChange, allFilters, showCheckbox }: ContractAvailableStatusListProps) => {
  const onFilterChange = (event: { isChecked: boolean; value: string }) => {
    if (showCheckbox && onChange) {
      onChange(event);
    }
  };

  return (
    <div className="stacking-plan-available-status-list">
      <div className="ui horizontal list">
        {stackingPlanLegendFilters.map(({ label, className, value }, index) => (
          <ContractAvailableStatusItem
            key={index}
            label={label}
            className={className}
            onChange={onFilterChange}
            value={value}
            allFilters={allFilters || []}
            showCheckbox={showCheckbox}
          />
        ))}
      </div>
    </div>
  );
};

export default ContractAvailableStatusList;
