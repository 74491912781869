import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { setTransactionChannelInput } from '.';
import { ActiveTransactionChannel } from './types';

interface UpdateTransactionChannelQueryVariables {
  transactionChannelId: string;
  params: any;
}

const UPDATE_TRANSACTION_CHANNEL = (activeTransactionChannel: ActiveTransactionChannel) => {
  const params = setTransactionChannelInput(activeTransactionChannel);

  const transactionChannelId = activeTransactionChannel.id;

  if (!transactionChannelId) throw new Error('Update Transaction Channel: activeTransactionChannelId is required');

  const variables: UpdateTransactionChannelQueryVariables = {
    transactionChannelId,
    params,
  };

  return {
    mutation: gql`
      mutation ($transactionChannelId: ID!, $params: TransactionChannelInput!) {
        updateTransactionChannel(transactionChannelId: $transactionChannelId, params: $params) {
          id
          status
          type
          title
          documentTypeId
          transaction {
            id
          }
          node {
            id
          }
          amendment {
            id
          }
          insertedAt
        }
      }
    `,
    variables,
  };
};

export const updateTransactionChannel = createAsyncThunk(
  'transactions/updateTransactionChannel',
  async (_args, { getState }) => {
    const {
      transactionDetail: { activeTransactionChannel },
    } = getState() as RootState;

    const response = await client.mutate(UPDATE_TRANSACTION_CHANNEL(activeTransactionChannel));
    return response;
  },
);
