import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateCurrentDashboardTab } from 'store/dashboard/dashboardSlice';
import DevelopmentCreatorViewer from './DevelopmentCreatorViewer';

export enum DASHBOARD_PAGE_TABS {
  DEVELOPMENTS = 'developments',
  BUILDINGS = 'buildings',
}

const getTabMenuConfig = ({ isCreate }: { isCreate: boolean }): TabMenuProps[] => {
  return [
    {
      key: 'developments-tab',
      href: `?tab=${DASHBOARD_PAGE_TABS.DEVELOPMENTS}`,
      label: 'Developments',
      dataTest: 'nav-dashboard',
      customTab: <DevelopmentCreatorViewer />,
    },
    {
      key: 'buildings-tab',
      href: `?tab=${DASHBOARD_PAGE_TABS.BUILDINGS}`,
      label: 'Buildings',
      dataTest: 'nav-dashboard',
      isDisabled: isCreate,
      customTab: <DevelopmentCreatorViewer />,
    },
  ];
};

const CreatorViewerTabs = ({ isViewPage }: { isViewPage: boolean }) => {
  const isCreate = location.pathname.includes('create');
  const { activeTabIndex } = useAppSelector((state: RootState) => state.dashboard);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateCurrentDashboardTab}
      tabMenu={getTabMenuConfig({ isCreate })}
    />
  );
};
export default CreatorViewerTabs;
