import { Fragment, SetStateAction, useState } from 'react';
import { Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import {
  resetMultiChoiceAnswer,
  testAddChoice,
  testDeleteChoice,
  updateMultiChoiceAnswer,
  updateSingleChoiceAnswer,
} from 'store/formatters/formatterDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import { Choice } from 'common/api/formatters';
import { Icons } from 'utils/utils-icons';
import './FormatterMultiChoice.scss';

interface MultiChoiceProps {
  input: {
    values: string[];
    choices: Choice[];
  };
  label: string | null;
  index: number;
  type: string | null;
  dataTest?: string;
}

const FormatterMultiChoice = ({ input, label, index, type, dataTest }: MultiChoiceProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [none, setNone] = useState<boolean>(false);
  const [option, setOption] = useState<string>('');

  const onChange = (_key: string, value: SetStateAction<string>): void => {
    setOption(value);
  };

  const onAddOption = (): void => {
    if (option) {
      dispatch(
        testAddChoice({
          text: option,
          value: option,
          index,
        }),
      );
      setOption('');
    }
  };

  const onDeleteOption = (value: string): void => {
    dispatch(
      testDeleteChoice({
        value,
        index,
      }),
    );
  };

  const onSelectSingle = (value: string): void => {
    dispatch(updateSingleChoiceAnswer({ value, index }));
  };

  const onSelectMulti = (checked: boolean | undefined, value: string): void => {
    if (checked) {
      setNone(false);
    }
    dispatch(updateMultiChoiceAnswer({ checked, index, value }));
  };

  const onUpdateNone = (): void => {
    setNone(!none);
    dispatch(resetMultiChoiceAnswer({ index }));
  };

  const multiChoice: boolean = type === AnswerTypes.MultiChoice;

  return (
    <Grid className="pm-none multi-choice">
      <Grid.Row className="pm-none">
        <Grid.Column
          width={9}
          className="pm-none"
        >
          <InputField
            label={label}
            value={option}
            onChange={onChange}
            fieldKey="value"
            type={InputFieldType.TEXT}
            dataTest={dataTest}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="input-options">
        <Grid.Column className="pm-none left-options-column">
          <Form.Group className={`pm-none ${multiChoice ? 'multi-group' : 'single-group'}`}>
            {input?.choices?.map(({ text, value }: Choice, index: number) => (
              <Fragment key={index}>
                <div className="choice-row">
                  <Icon
                    className="color-red-soft"
                    icon={Icons.Delete}
                    onClick={() => onDeleteOption(value)}
                  />

                  {multiChoice ? (
                    <Checkbox
                      label={text}
                      data-test="run-formatter-add-checkbox"
                      onChange={(e, { checked }) => onSelectMulti(checked, value)}
                      checked={input.values.includes(value)}
                      value={value}
                    />
                  ) : (
                    <Form.Radio
                      label={text}
                      data-test="run-formatter-add-radio-button"
                      onChange={() => onSelectSingle(value)}
                      checked={input.values.length > 0 && value === input.values[0]}
                      value={value}
                    />
                  )}
                </div>
              </Fragment>
            ))}
          </Form.Group>
        </Grid.Column>
        {multiChoice && input?.choices.length !== 0 ? (
          <Grid.Column className="right-options-column">
            <Checkbox
              id={`none-option-${index}`}
              label="None"
              onChange={onUpdateNone}
              checked={input?.values.length === 0 && none}
            />
          </Grid.Column>
        ) : (
          <></>
        )}
      </Grid.Row>
      <Button
        className={`btn grey-bg ${label ? 'm-t-xl' : 'm-t-xxs'}`}
        onClick={onAddOption}
        data-test="run-formatter-add-option-button"
      >
        ADD OPTION
      </Button>
    </Grid>
  );
};

export default FormatterMultiChoice;
