import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/roles/rolesSlice';
import { listRoles } from 'common/api/roles';
import RoleModal from '../../../components/TeamModals/RoleModal';

const RolesHeaderToolbar = () => {
  const { search } = useAppSelector((state: RootState) => state.roles);

  const onGetData = (value?: string) => listRoles({ searchValue: value, first: 10 });

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
        className="m-r-sm"
      />
      <RoleModal
        trigger={<Button className="btn grey-bg">ADD NEW ROLE</Button>}
        createMode={true}
      />
    </>
  );
};

export default RolesHeaderToolbar;
