/*
 * Definition of the column (names, format...)
 */
import moment from 'moment';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import { filterElementDate } from 'atoms/TableReactPrime/columnTemplates';
import { ContextAnalysisData } from 'common/api/dashboard';
import { addSpaceOrComma, removeSpaceOrComma } from 'utils/utils-number';

export class ForecastingRow {
  month: Date;
  contractualRevenue: number;
  forecastRevenue: number;
  lockStatus: string;
  variance: number;
  adjustedBudgetForecast: string;
  adjustedVariance: string;
  occupancyRate: string;

  constructor(data: ContextAnalysisData) {
    const oVariance: string = data.statsRevenue.revenueOverPerformance.variance;
    const uVariance: string = data.statsRevenue.revenueUnderPerformance.variance;
    const overPerformanceVariance: number = oVariance ? parseFloat(oVariance) : 0;
    const underPerformanceVariance: number = uVariance ? parseFloat(uVariance) : 0;
    const variance: number = overPerformanceVariance + underPerformanceVariance;
    this.month = new Date(data.month);
    this.contractualRevenue = getUnformattedRevenue(data.statsRevenue.contractualRevenue);
    this.forecastRevenue = getUnformattedRevenue(data.statsRevenue.forecastRevenue);
    this.variance = variance;
    // TODO: These fields are not implemented yet
    this.lockStatus = '---';
    this.adjustedBudgetForecast = '---';
    this.adjustedVariance = '---';
    this.occupancyRate = '---';
  }
}

const getUnformattedRevenue = (data: string) => {
  return Math.trunc(parseFloat(removeSpaceOrComma(data)));
};

export const forecastingColumnConfig = (): CustomColumnProps[] => {
  return [
    {
      field: 'month',
      header: 'Month',
      filter: true,
      filterPlaceholder: 'Search by Month',
      filterField: 'month',
      sortable: true,
      mandatory: true,
      body: (row: ForecastingRow) => {
        return moment(row.month).format('MMM YYYY');
      },
      dataType: 'date',
      filterElement: options => filterElementDate(options, 'month'),
    },
    {
      field: 'contractualRevenue',
      header: 'Contractual Revenue',
      filter: true,
      filterPlaceholder: 'Search by Contractual Revenue',
      filterField: 'contractualRevenue',
      sortable: true,
      dataType: 'numeric',
      body: row => (!row.contractualRevenue ? 'unavailable' : addSpaceOrComma(row.contractualRevenue, false)),
    },
    {
      field: 'forecastRevenue',
      header: 'Annual budget forecast',
      filter: true,
      filterPlaceholder: 'Search by Annual budget forecast',
      filterField: 'forecastRevenue',
      sortable: true,
      dataType: 'numeric',
      body: row => (!row.forecastRevenue ? 'unavailable' : addSpaceOrComma(row.forecastRevenue, false)),
    },
    {
      field: 'lockStatus',
      header: 'Annual budget lock status',
      filter: false,
      sortable: false,
    },
    {
      field: 'variance',
      header: 'Variance',
      filterPlaceholder: 'Search by Variance',
      filter: true,
      filterField: 'variance',
      sortable: true,
      dataType: 'numeric',
      body: row => addSpaceOrComma(row.variance, false),
    },
    {
      field: 'adjustedBudgetForecast',
      header: 'Adjusted budget forecast',
      filter: false,
      sortable: false,
    },
    {
      field: 'adjustedVariance',
      header: 'Adjusted Variance',
      filter: false,
      sortable: false,
    },
    {
      field: 'occupancyRate',
      header: 'Occupancy rate (%)',
      filter: false,
      sortable: false,
    },
  ];
};
