import Decimal from 'decimal.js';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import RentReviewType from 'common/model/RentReviewType';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { RENT_REVIEW_METHOD_OPTIONS } from 'utils/UI';
import { getDateOfAddedDays } from 'utils/utils-date';

interface SubsequentRentProps extends ModeProps {
  index: number;
}

const SubsequentRentPeriodForm = ({ mode, index }: SubsequentRentProps) => {
  const dispatch = useAppDispatch();
  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const rentPeriods = activeContractEvent?.rentReview || [];
  const rentPeriod = rentPeriods.length > 0 ? rentPeriods[index] : null;

  const onChangeDecimal = (key: string, value: string) => {
    dispatch(
      updateActiveContractEventInput({
        key: ['rentReview', `${index}`, key],
        value: value ? new Decimal(value) : null,
      }),
    );
  };

  const onChange = (key: string, value: string) => {
    if (key === 'type') {
      const key = ['rentReview', `${index}`];

      switch (value) {
        case RentReviewType.OpenMarketPrOrOmr:
          updateActiveContractEventInput({
            key,
            value: {
              ...rentPeriod,
              collar: null,
              increment: null,
              newRent: null,
            },
          });
          break;

        case RentReviewType.OpenMarketUpDown:
          dispatch(
            updateActiveContractEventInput({
              key,
              value: {
                ...rentPeriod,
                increment: null,
                newRent: null,
              },
            }),
          );
          break;

        case RentReviewType.New:
          dispatch(
            updateActiveContractEventInput({
              key,
              value: {
                ...rentPeriod,
                cap: null,
                collar: null,
                increment: null,
              },
            }),
          );
          break;

        case RentReviewType.Increment:
          dispatch(
            updateActiveContractEventInput({
              key,
              value: {
                ...rentPeriod,
                cap: null,
                collar: null,
                newRent: null,
              },
            }),
          );
          break;
      }
    }

    dispatch(
      updateActiveContractEventInput({
        key: ['rentReview', `${index}`, key],
        value: value || null,
      }),
    );
  };

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div>
      <div className="grid m-t-s">
        <div className="col-2">
          <DateField
            minDate={
              index - 1 === 0
                ? getDateOfAddedDays(activeContractEvent?.startDate, 1)
                : getDateOfAddedDays(rentPeriods[index - 1].reviewDate, 2)
            }
            maxDate={
              rentPeriods.length > index + 1
                ? getDateOfAddedDays(rentPeriods[index + 1].reviewDate, 1)
                : getDateOfAddedDays(activeContractEvent?.endDate, -1)
            }
            label="Rent review date"
            dataTest=""
            fieldKey="reviewDate"
            value={rentPeriod?.reviewDate || ''}
            disabled={disabled}
            required={required}
            onChange={onChange}
          />
        </div>

        <div className="col-2">
          <SelectField
            label="Rent review method"
            fieldKey="type"
            dataTest=""
            value={rentPeriod?.type}
            disabled={disabled}
            required={required}
            options={RENT_REVIEW_METHOD_OPTIONS}
            onChange={onChange}
          />
        </div>

        {(rentPeriod?.type === RentReviewType.OpenMarketPrOrOmr ||
          rentPeriod?.type === RentReviewType.OpenMarketUpDown) && (
          <div className="col-2">
            <InputField
              label="Cap"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="cap"
              value={rentPeriod?.cap?.toString() || null}
              disabled={disabled}
              onChange={onChangeDecimal}
              isDecimal={true}
            />
          </div>
        )}

        {rentPeriod?.type === RentReviewType.OpenMarketUpDown && (
          <div className="col-2">
            <InputField
              label="Collar"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="collar"
              value={rentPeriod?.collar?.toString() || null}
              disabled={disabled}
              onChange={onChangeDecimal}
              isDecimal={true}
            />
          </div>
        )}

        {rentPeriod?.type === RentReviewType.Increment && (
          <div className="col-2">
            <InputField
              label="Percentage increase"
              dataTest=""
              type={InputFieldType.NUMBER}
              unit="(%)"
              fieldKey="increment"
              value={rentPeriod?.increment?.toString() || null}
              disabled={disabled}
              required={required}
              onChange={onChangeDecimal}
              isDecimal={true}
            />
          </div>
        )}
      </div>

      <div className="grid m-t-s">
        <div className="col-2">
          <DateField
            label="Date rent is agreed"
            dataTest=""
            fieldKey="rentAgreementDate"
            value={rentPeriod?.rentAgreementDate || null}
            disabled={disabled}
            onChange={onChange}
          />
        </div>

        <div className="col-2">
          <DateField
            label="Date rent is determined"
            dataTest=""
            fieldKey="rentDeterminationDate"
            value={rentPeriod?.rentDeterminationDate || null}
            disabled={disabled}
            onChange={onChange}
          />
        </div>

        <div className="col-2">
          <InputField
            label="Vacancy rate when rent is agreed/determined"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(%)"
            fieldKey="vacancyRate"
            value={rentPeriod?.vacancyRate?.toString() || null}
            disabled={disabled}
            onChange={onChangeDecimal}
            isDecimal={true}
          />
        </div>
      </div>

      <div className="grid m-t-s">
        <div className="col-2">
          <InputField
            label="Face rent (pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/mo)"
            fieldKey="faceRentPcm"
            value={rentPeriod?.faceRentPcm?.toString() || null}
            disabled={disabled}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <InputField
            label="Face rent (psf pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/sf/mo)"
            fieldKey="faceRentPsfPcm"
            value={rentPeriod?.faceRentPsfPcm?.toString() || null}
            disabled={true}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <InputField
            label="Effective rent (pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/mo)"
            fieldKey="effectiveRentPcm"
            value={rentPeriod?.effectiveRentPcm?.toString() || null}
            disabled={true}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>

        <div className="col-2">
          <InputField
            label="Effective rent (psf pcm)"
            dataTest=""
            type={InputFieldType.NUMBER}
            unit="(HK$/sf/mo)"
            fieldKey="effectiveRentPsfPcm"
            value={rentPeriod?.effectiveRentPsfPcm?.toString() || null}
            disabled={true}
            onChange={onChangeDecimal}
            isDecimal={true}
            delimiter="COMMA"
          />
        </div>
      </div>
    </div>
  );
};

export default SubsequentRentPeriodForm;
