import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteParameterGroupQueryVariables {
  id: string;
}

export const DELETE_PARAMETER_GROUP = (id: string) => {
  if (!id) throw new Error('DELETE_PARAMETER_GROUP: id is required');

  const variables: DeleteParameterGroupQueryVariables = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteParameterGroup(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteParameterGroup = createAsyncThunk(
  'provisions/deleteParameterGroup',
  async ({ id }: { id: string }) => {
    const response = await adminClient.mutate(DELETE_PARAMETER_GROUP(id));
    return response;
  },
);
