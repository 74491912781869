import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { TranslateToContractDetailClone } from 'common/_classes/TranslateToContractDetailClone';
import { RootState } from 'store';
import { ReviewStatusClone, ReviewStatusMutationType } from 'common/api/miscellaneous';
import { TranslateToContractClone } from 'common/api/translatorsToContract';
import { getReviewFlagAndReviewStatus } from 'utils/tsHelper';
import { replaceDoubleSlash } from 'utils/utils-string';

interface CreateTranslateToContractQueryVariables {
  params: TranslateToContractClone;
}

export const CREATE_TRANSLATE_TO_CONTRACT = (
  activeTranslateToContract: TranslateToContractClone,
  reviewFlag: boolean,
  reviewStatus: ReviewStatusMutationType,
) => {
  const translateToContractUpdatedFunction = { ...activeTranslateToContract };

  translateToContractUpdatedFunction.function = replaceDoubleSlash(activeTranslateToContract.function);

  const cloneActiveTranslateToContract = JSON.parse(
    JSON.stringify(translateToContractUpdatedFunction),
  ) as typeof activeTranslateToContract;

  const variables: CreateTranslateToContractQueryVariables = {
    params: { ...cloneActiveTranslateToContract, reviewFlag, reviewStatus: reviewStatus as ReviewStatusClone },
  };

  return {
    mutation: gql`
      mutation ($params: TranslateToContractInput!) {
        createTranslateToContract(params: $params) {
          ${TranslateToContractDetailClone.fragment()}
        }
      }
    `,
    variables,
  };
};

export const createTranslateToContract = createAsyncThunk(
  'translatorsToContract/createTranslateToContract',
  async (_args, { getState }) => {
    const {
      translatorDetail: { activeTranslateToContract, activeTranslateToContractFrozenCopy },
    } = getState() as RootState;

    const [reviewFlag, reviewStatus] = getReviewFlagAndReviewStatus(
      activeTranslateToContract,
      activeTranslateToContractFrozenCopy,
    );

    const response = await adminClient.mutate(
      CREATE_TRANSLATE_TO_CONTRACT(activeTranslateToContract, reviewFlag, reviewStatus),
    );

    return response;
  },
);
