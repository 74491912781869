import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import ChatBotModal from 'atoms/ChatBotModal';
import { IContextState, evaluateAIQuestion, localAPIFallback } from 'common/api/aiQuestions';
import MessageList from '../../atoms/ChatBotModal/MessageList';
import { RootState } from '../../store/index';
import AIChatBotTrigger from './AIChatBotTrigger';
import './AIChatBot.scss';

// import { getChatbotMessages } from 'store/chatBot/chatBotSlice'

const defaultState: IContextState = {
  transactionId: null,
  transactionIdentifier: null,
  tenancyId: null,
  tenancyIdentifier: null,
  tenantId: null,
  landlordId: null,
  propertyId: null,
  startDate: null,
  buildingName: 'Asia Commerce Centre',
};

const AIChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [messages, setMessages] = useState<MessageType[]>([]);
  const dispatch = useDispatch();
  const { firstName, lastName } = useAppSelector((state: RootState) => state.auth);
  const [context, setContext] = useState<IContextState>(defaultState);
  const location = useLocation();

  const propertyDetail = useAppSelector((state: RootState) => state.propertyDetail);
  const dashboard = useAppSelector((state: RootState) => state.dashboard);
  const contractDetailV2 = useAppSelector((state: RootState) => state.contractDetailV2);
  const transactionDetail = useAppSelector((state: RootState) => state.transactionDetail);

  const { messages } = useAppSelector((state: RootState) => state.chatBot);

  const getCurrentPage = (currentUrl: string) => {
    if (currentUrl === '/dashboard') return 'dashboard';
    if (currentUrl.includes('/tenancy/')) return 'tenancy';
    if (currentUrl.includes('/transactions/')) return 'transaction';
    return 'unknown';
  };

  const todayDateString = useMemo(() => moment().format('YYYY-MM-DD'), []);

  const fetchAppropriateState = (currentPage: string): IContextState => {
    switch (currentPage) {
      case 'dashboard':
        return {
          ...defaultState,
          startDate: todayDateString,
        };
      case 'tenancy':
        return {
          ...defaultState,
          tenancyId: contractDetailV2.activeContract?.id ?? null,
          tenancyIdentifier: contractDetailV2?.activeContract?.identifier ?? null,
          tenantId: contractDetailV2?.activeContractEvent?.tenantId ?? null,
          propertyId: contractDetailV2?.activeContractEvent?.premises?.propertyId ?? null,
          startDate: todayDateString,
          buildingName: 'Asia Commerce Centre',
        };
      case 'transaction':
        return {
          ...defaultState,
          transactionId: transactionDetail?.activeTransaction.id ?? null,
          landlordId: transactionDetail?.activeTransaction?.ownerId ?? null,
          buildingName: 'Asia Commerce Centre',

          transactionIdentifier: transactionDetail?.activeTransaction?.identifier ?? null,
          startDate: todayDateString,
        };
      default:
        return {
          ...defaultState,
          transactionId: transactionDetail?.activeTransaction.id ?? null,
          transactionIdentifier: transactionDetail?.activeTransaction?.identifier ?? null,
          tenancyId: contractDetailV2.activeContract?.id ?? null,
          tenancyIdentifier: contractDetailV2?.activeContract?.identifier ?? null,
          tenantId: contractDetailV2?.activeContractEvent?.tenantId ?? null,
          propertyId: contractDetailV2?.activeContractEvent?.premises?.propertyId ?? null,
          startDate: todayDateString,
          buildingName: 'Asia Commerce Centre',
        };
    }
  };

  useEffect(() => {
    const currentUrl = location.pathname;
    const currentPage = getCurrentPage(currentUrl);
    const updatedContext = fetchAppropriateState(currentPage);
    setContext(updatedContext);
  }, [
    location.pathname,
    dashboard.activeTabIndex,
    propertyDetail?.isLoading,
    transactionDetail.activeTabIndex,
    transactionDetail.isLoading,
    contractDetailV2?.isLoading,
  ]);

  const newMessage = (prompt: string) => {
    return {
      isFromBot: false,
      sender: `${firstName} ${lastName}`,
      message: prompt,
      timestamp: Date.now(),
    };
  };

  const handleOnSubmit = async (userPrompt: string) => {
    // @ts-ignore
    dispatch(evaluateAIQuestion({ message: newMessage(userPrompt), state: context }));
    //dispatch(localAPIFallback({ message: newMessage(userPrompt), state: context }));
  };

  return (
    <ChatBotModal
      className="ai-chatbot-container"
      isOpen={isOpen}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      data={messages}
      onClose={() => setIsOpen(false)}
      onSubmit={handleOnSubmit}
      trigger={<AIChatBotTrigger onClick={() => setIsOpen(!isOpen)} />}
    >
      <MessageList messages={messages} />
    </ChatBotModal>
  );
};

export default AIChatBot;
