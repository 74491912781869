export const data = {
  buildingName: 'Sample Building',
  timelineStart: '2023-01-01',
  timelineEnd: '2037-12-31',
  floors: [
    {
      id: 'd7e5e5a5-4e6b-4f6a-9b0b-8c5eab1c3c3e',
      floorNumber: 3,
      label: '3',
      units: [
        {
          id: '68f7a7c6-2d35-4c9f-b25c-9c3d0d4c4e4b',
          unitNumber: 301,
          label: 'A',
          occupancies: [
            {
              id: '3f9c9e7c-5b3d-4f2f-9e8f-5f4c7a2e8f67',
              type: 'tenancy',
              label: 'Company A',
              startDate: '2023-01-01',
              endDate: '2024-06-30',
              editable: false,
            },
            {
              id: '7b8c9a1d-8e2c-4b56-9f1c-5b8f1d4b8e7e',
              type: 'transaction',
              label: 'Company B',
              startDate: '2024-07-01',
              endDate: '2025-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2026-10-01',
              endDate: '2027-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2028-04-01',
              endDate: '2029-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2030-07-01',
              endDate: '2031-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2032-10-01',
              endDate: '2033-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2034-07-01',
              endDate: '2035-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2036-10-01',
              endDate: '2037-12-31',
              editable: true,
            },
          ],
        },
        {
          id: '4c1587b1-6508-4d91-8e2e-5f2b9b4b7a6d',
          unitNumber: 302,
          label: 'B',
          occupancies: [
            {
              id: 'f3a4b5c7-2f5b-4b1e-bc4b-4e5a6e7b8c7d',
              type: 'tenancy',
              label: 'Company C',
              startDate: '2023-01-01',
              endDate: '2025-12-31',
              editable: false,
            },
            {
              id: 'c8d9e3f4-7a8b-4c9f-8d2c-8e1b2c9d4f5b',
              type: 'transaction',
              label: 'Company D',
              startDate: '2026-01-01',
              endDate: '2027-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2028-04-01',
              endDate: '2029-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2030-07-01',
              endDate: '2031-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2032-10-01',
              endDate: '2033-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2034-07-01',
              endDate: '2035-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2036-10-01',
              endDate: '2037-12-31',
              editable: true,
            },
          ],
        },
      ],
    },
    {
      id: 'c3d5e7e3-2b49-4d2a-a3e1-d0a1d8c9f5c8',
      floorNumber: 2,
      label: '2',
      units: [
        {
          id: 'a2e1f8b6-7d4e-40c4-bc88-9e4e0f4e1c9b',
          unitNumber: 201,
          label: 'A',
          occupancies: [
            {
              id: '6f5c8d9e-b8a2-4e4a-a8e5-1b2e3c8c7d5e',
              type: 'tenancy',
              label: 'Company E',
              startDate: '2023-01-01',
              endDate: '2023-12-31',
              editable: false,
            },
            {
              id: 'd1e2f3a4-b9d7-4c2f-8d8c-2c9a7e1f5d7f',
              type: 'transaction',
              label: 'Company F',
              startDate: '2024-01-01',
              endDate: '2025-06-30',
              editable: true,
            },
            {
              id: 'b7c8d9e1-e6a2-4c8c-b1c4-9e1b5c2d6b9c',
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2025-10-01',
              endDate: '2027-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2027-10-01',
              endDate: '2029-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2029-10-01',
              endDate: '2031-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2031-10-01',
              endDate: '2033-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2033-10-01',
              endDate: '2035-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2035-10-01',
              endDate: '2037-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2037-10-01',
              endDate: '2037-12-31',
              editable: true,
            },
          ],
        },
        {
          id: 'f3b9f0a2-ff6d-41e4-9f03-87d8c2b8a3a1',
          unitNumber: 202,
          label: 'B',
          occupancies: [
            {
              id: '2b8c7e1f-2d5a-4b9b-9a8e-0d3e5f2b8c8a',
              type: 'tenancy',
              label: 'Company G',
              startDate: '2023-01-01',
              endDate: '2027-12-31',
              editable: false,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2028-04-01',
              endDate: '2029-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2030-07-01',
              endDate: '2031-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2032-10-01',
              endDate: '2033-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2034-07-01',
              endDate: '2035-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2036-10-01',
              endDate: '2037-12-31',
              editable: true,
            },
          ],
        },
      ],
    },
    {
      id: 'bfa9f4a4-1b6b-4c3c-bc8d-b0e4f4f3e6b3',
      floorNumber: 1,
      label: '1',
      units: [
        {
          id: 'b1a3f9a3-5f3c-4e0e-9b7a-7b6cd9c4a3c9',
          unitNumber: 101,
          label: 'A',
          occupancies: [
            {
              id: '8f1b5c2d-9a3d-4e5b-b0c7-1d7a2e8f8c6a',
              type: 'tenancy',
              label: 'Company H',
              startDate: '2023-01-01',
              endDate: '2024-12-31',
              editable: false,
            },
            {
              id: 'd3e4f5g6-2b7c-4a9a-9b3b-9e2c4f5a6d8f',
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2025-01-01',
              endDate: '2026-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2027-04-01',
              endDate: '2028-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2029-04-01',
              endDate: '2030-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2031-04-01',
              endDate: '2032-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2033-04-01',
              endDate: '2034-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2035-04-01',
              endDate: '2036-12-31',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2037-04-01',
              endDate: '2037-12-31',
              editable: true,
            },
          ],
        },
        {
          id: 'a2d1f5c8-75f2-4b51-8d2b-5f6c8c3b8e4c',
          unitNumber: 102,
          label: 'B',
          occupancies: [
            {
              id: 'e1f2g3h4-5a6b-4c8d-9e2f-0d3e4f5a6b7c',
              type: 'transaction',
              label: 'Company I',
              startDate: '2023-01-01',
              endDate: '2025-06-30',
              editable: true,
            },
            {
              id: 'b9c8d7e6-5f1a-4c2c-a8b6-3d2b4e5c6f7a',
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2025-10-01',
              endDate: '2027-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2027-10-01',
              endDate: '2029-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2029-10-01',
              endDate: '2031-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2031-10-01',
              endDate: '2033-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2033-10-01',
              endDate: '2035-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2035-10-01',
              endDate: '2037-06-30',
              editable: true,
            },
            {
              type: 'assumed',
              label: 'Assumed Tenant',
              startDate: '2037-10-01',
              endDate: '2037-12-31',
              editable: true,
            },
          ],
        },
      ],
    },
  ],
};

export type DataType = typeof data;
