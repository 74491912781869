import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { EntitiesListingActionArgs, EntityCreatorTypes } from './types';

interface ListEntitiesQueryVariables {
  currentLandlordEntityId?: string;
  sortOrder: SortOrder;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  name_ilike: string | null;
}

export const LIST_ENTITIES = (
  type: EntityCreatorTypes,
  legalName: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  let landlordAdditional = '',
    currentLandlordEntity = '',
    innerTextLandlordEntity = '',
    currentLandlordEntityId;
  let tenantAdditional = '';

  if (type === EntityCreatorTypes.Landlord) {
    landlordAdditional = `
         propertyCount
         floorArea
        landlordNetworks{
          childId
          description {
            sharePercentage
          }
          id
          parentId
          type
      }`;
  } else {
    currentLandlordEntityId = getCurrentLandlordEntityId();

    if (!currentLandlordEntityId) throw new Error('List Entities: currentLandlordEntityId is required');

    currentLandlordEntity = '$currentLandlordEntityId: ID!,';
    innerTextLandlordEntity = 'currentLandlordEntityId: $currentLandlordEntityId,';
    tenantAdditional = `
        identifier
        nickname
        legalDomicile
        registrationCountry
        contractCount
        type
        industry{
          name
        }`;
  }

  const variables: ListEntitiesQueryVariables = {
    currentLandlordEntityId,
    sortOrder: SortOrder.Asc,
    first: first,
    last: last,
    after: after,
    before: before,
    name_ilike: legalName,
  };

  return {
    query: gql`
         query(${currentLandlordEntity} $sortOrder: SortOrder!, $first: Int, $last: Int, $after: String, $before: String, $name_ilike: String) {
          list${type}Entities(${innerTextLandlordEntity} sortOrder: $sortOrder, first: $first, last: $last, after: $after, before: $before, filters: {nameIlike: $name_ilike}){
            edges{
              cursor
              node { 
                id
                name
                transactionCount
                ${tenantAdditional}
                ${landlordAdditional}
              }
            }
            pageInfo{
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
          }
        },
      `,
    variables,
  };
};

export const listEntities = createAsyncThunk(
  'entitiesListing/listEntities',
  async ({ type, first, last, after, before }: EntitiesListingActionArgs, { getState }) => {
    const {
      entitiesListing: { search },
    } = getState() as RootState;

    const response = await client.query(LIST_ENTITIES(type, search, first, last, after, before));
    return response;
  },
);
