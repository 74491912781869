import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import EditorField from 'atoms/FormField/EditorField';
import { updateActiveAmendment } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

interface ContentEditorProps extends ModeProps {
  pasteAsText?: boolean;
}

const ContentEditor = ({ mode, pasteAsText }: ContentEditorProps) => {
  const { activeAmendment } = useAppSelector((state: RootState) => state.transactionDetail);

  const onChange = (key: string, value: string) => updateActiveAmendment({ key, value });

  return (
    <EditorField
      onChange={(key: any, value: any) => onChange(key, value)}
      value={activeAmendment.content}
      placeholder="Type here..."
      disabled={mode === MODE_OPTIONS.READ}
      fieldKey="content"
      height="385"
      code={true}
      pasteAsText={pasteAsText}
    />
  );
};

export default ContentEditor;
