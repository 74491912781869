/**
 *  Context Icons to add new icons package in Editor TinyMCE
 */
import { Editor } from 'tinymce';
import CustomIconsPackage, { CustomIcons } from './CustomIconsPackage';

const ContextIcons = (editor: Editor) => {
  CustomIconsPackage.map((icon: CustomIcons) => (editor as Editor).ui.registry.addIcon(icon.name, icon.svg));
};

export default ContextIcons;
