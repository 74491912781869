import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import SortOrder from 'common/model/SortOrder';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListLandlordAndTenantEntitiesQueryVariables {
  currentLandlordEntityId: string;
  sortOrder: SortOrder;
  first: number;
}

export const LIST_LANDLORD_AND_TENANT_ENTITIES = () => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId)
    throw new Error('List Landlord And Tenant Entities: currentLandlordEntityId is required');

  const variables: ListLandlordAndTenantEntitiesQueryVariables = {
    currentLandlordEntityId,
    sortOrder: SortOrder.Asc,
    first: 100000,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $sortOrder: SortOrder!, $first: Int) {
        landlord: listLandlordEntities(sortOrder: $sortOrder, first: $first) {
          edges {
            cursor
            node {
              id
              name
              nickname
            }
          }
        }
        tenant: listTenantEntities(
          currentLandlordEntityId: $currentLandlordEntityId
          sortOrder: $sortOrder
          first: $first
        ) {
          edges {
            cursor
            node {
              id
              name
              nickname
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listLandlordAndTenantEntities = createAsyncThunk(
  'entitesListing/listLandlordAndTenantEntities',
  async () => {
    const response = await client.query(LIST_LANDLORD_AND_TENANT_ENTITIES());
    return response;
  },
);
