import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateCell from 'atoms/DateCell';
import DropdownSelect from 'atoms/DropdownSelect';
import { updateForecastFilterDates, updateForecastScale } from 'store/dashboard/dashboardTabSlice';
import Scale from 'common/model/Scale';
import { DROPDOWN_OPTION } from 'utils/UI';
import './PeriodSelect.scss';

interface TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: Scale;
}

// TODO: After api modification need to add all scale options
const SCALE_OPTIONS: TYPE_OPTION[] = [
  {
    key: 1,
    value: Scale.Month,
    text: 'Month',
  },
];

const PeriodSelect = () => {
  const {
    dateFilter: { startDate, endDate },
    scale,
  } = useAppSelector((state: RootState) => state.dashboardTab);
  const dispatch = useAppDispatch();

  return (
    <div className="dashboard-tab-period-select d-flex">
      <div className="date-select">
        <span className="m-r-xs">From</span>
        <DateCell
          type="startDate"
          date={startDate}
          onChange={updateForecastFilterDates}
        />
      </div>

      <div className="date-select">
        <span className="m-r-xs">To</span>
        <DateCell
          type="endDate"
          date={endDate}
          onChange={updateForecastFilterDates}
        />
      </div>

      <DropdownSelect
        className="m-l-l"
        placeholder="select scale"
        options={SCALE_OPTIONS}
        value={scale}
        onChange={(key, value) => dispatch(updateForecastScale({ value }))}
      />
    </div>
  );
};

export default PeriodSelect;
