import { Checkbox, Grid } from 'semantic-ui-react';

interface PropertyCheckboxProps {
  title?: string;
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  fieldKey: string;
  dataTest?: string;
  onChange: (key: string, checked?: boolean) => void;
}

const PropertyCheckbox = ({
  title,
  disabled,
  required,
  checked,
  fieldKey,
  dataTest,
  onChange,
}: PropertyCheckboxProps) => {
  return (
    <>
      <Grid.Column
        className="p-r-none"
        width={2}
      >
        <Grid.Column className="property-checkbox-column p-b-sm">
          <b>{title}</b>
        </Grid.Column>
        <Grid.Column className="property-checkbox-column p-b-sm">
          <Checkbox
            disabled={disabled}
            required={required}
            checked={checked}
            data-test={dataTest}
            toggle
            onChange={(e, { checked }) => onChange(fieldKey, checked)}
          />
        </Grid.Column>
      </Grid.Column>
    </>
  );
};

export default PropertyCheckbox;
