import NotificationFilter from './NotificationFilter';
import NotificationListing from './NotificationListing';

const NotificationComponent = () => {
  return (
    <div>
      <NotificationFilter />
      <NotificationListing />
    </div>
  );
};

export default NotificationComponent;
