import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface GetPortfolioQueryVariables {
  currentLandlordEntityId: string;
  portfolioId: string;
}

export const GET_PORTFOLIO = (portfolioId: string) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Get Portfolio: currentLandlordEntityId is required');

  const variables: GetPortfolioQueryVariables = {
    currentLandlordEntityId,
    portfolioId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $portfolioId: ID!) {
        getPortfolio(currentLandlordEntityId: $currentLandlordEntityId, portfolioId: $portfolioId) {
          entity {
            id
          }
          entityId
          id
          name
          propertyIds
        }
      }
    `,
    variables,
  };
};

export const getPortfolio = createAsyncThunk(
  'portfolioDetail/getPortfolio',
  async ({ portfolioId }: { portfolioId: string }) => {
    const response = await getClientType().query(GET_PORTFOLIO(portfolioId));

    return response.data;
  },
);
