import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface DeleteTransactionQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
}

const DELETE_TRANSACTION = (id: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Delete Transaction: currentLandlordEntityId is required');

  if (!id) throw new Error('Delete Transaction: id is required');

  const variables: DeleteTransactionQueryVariables = {
    currentLandlordEntityId,
    transactionId: id,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $currentLandlordEntityId: ID!) {
        deleteTransaction(currentLandlordEntityId: $currentLandlordEntityId, transactionId: $transactionId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteTransaction = createAsyncThunk('transactions/deleteTransaction', async ({ id }: { id: string }) => {
  const response = await client.mutate(DELETE_TRANSACTION(id));
  return response;
});
