import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeletePolicyQueryVariables {
  id: string;
}

export const DELETE_POLICY = (id: string) => {
  if (!id) throw new Error('Policy ID is required for deleting a policy');

  const variables: DeletePolicyQueryVariables = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deletePolicy(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deletePolicy = createAsyncThunk('policies/deletePolicy', async ({ id }: { id: string }) => {
  const response = await client.mutate(DELETE_POLICY(id));
  return response;
});
