import FloorStatus2Input from 'common/model/FloorStatus2Input';
import RefusalRightPriority from 'common/model/RefusalRightPriority';
import RefusalRightRentAssessmentType from 'common/model/RefusalRightRentAssessmentType';
import RefusalRights from 'common/model/RefusalRights';
import RefusalRightsInput from 'common/model/RefusalRightsInput';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';
import { convertDateToISO } from 'utils/utils-date';

export interface RefusalRightsInputClone
  extends Omit<RefusalRightsInput, 'wholeOrPart' | 'exercisableFrom' | 'rentAssessment'> {
  exercisableFrom: Date | null;
  wholeOrPart: WholeOrPartOptions | null;
  rentAssessment: RefusalRightRentAssessmentType | null;
}
export class CRefusalRights implements RefusalRightsInputClone {
  exercisableFrom: Date | null;
  reofferBeforeLeasingAtLowerRent: boolean;
  wholeOrPart: WholeOrPartOptions | null;
  rentAssessment: RefusalRightRentAssessmentType | null;
  propertyId: string;
  floors: FloorStatus2Input[];
  priority: RefusalRightPriority[];

  constructor(input: RefusalRights | null) {
    this.exercisableFrom = input && input.exercisableFrom ? new Date(input.exercisableFrom) : null;
    this.reofferBeforeLeasingAtLowerRent = input ? input.reofferBeforeLeasingAtLowerRent : false;
    this.wholeOrPart = input ? input.wholeOrPart : null;
    this.rentAssessment = input ? input.rentAssessment : null;
    this.propertyId = input ? input.property.id : '';
    this.floors = input ? input.floors : [];
    this.priority = input ? input.priority : [];
  }

  static fragment() {
    return `
        exercisableFrom
        reofferBeforeLeasingAtLowerRent
        wholeOrPart
        rentAssessment
        priority
        floors {
            floorId
            spaceIds
            whole
        }
        property {
          id
          buildingName
          floors {
            id
            name
            ceilingHeight
            spaces {
              id
              name
              grossArea
              netArea
              lettableArea
              saleableArea
            }
          }
        }
    `;
  }

  static formatForAPI(right: CRefusalRights) {
    return {
      exercisableFrom: convertDateToISO(right.exercisableFrom),
      reofferBeforeLeasingAtLowerRent: right.reofferBeforeLeasingAtLowerRent,
      wholeOrPart: right ? right.wholeOrPart : null,
      rentAssessment: right ? right.rentAssessment : null,
      propertyId: right ? right.propertyId : '',
      floors: right ? [...right.floors] : [],
      priority: right ? [...right.priority] : [],
    };
  }
}
