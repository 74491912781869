import { Popup } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './PasswordPopUp.scss';

const PasswordPopUp = () => {
  return (
    <Popup
      className="password-pop-up bg-white"
      hoverable={true}
      trigger={
        <Icon
          className="info-icon"
          icon={Icons.InfoCircle}
        />
      }
    >
      <Popup.Content>
        <ul>
          <li>8 letters minimum</li>
          <li>Capital letters</li>
          <li>Small letters</li>
          <li>Numbers</li>
          <li>Special characters</li>
        </ul>
      </Popup.Content>
    </Popup>
  );
};

export default PasswordPopUp;
