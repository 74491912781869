import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetTransactionContentsOnlyQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
}

const GET_TRANSACTION_CONTENTS_ONLY = (transactionId: string) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Get Transaction Contents Only: currentLandlordEntityId is required');
  if (!transactionId) throw new Error('Get Transaction Contents Only: transactionId is required');

  const variables: GetTransactionContentsOnlyQueryVariables = {
    currentLandlordEntityId,
    transactionId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $transactionId: ID!) {
        getTransaction(currentLandlordEntityId: $currentLandlordEntityId, transactionId: $transactionId) {
          id
          contents {
            content
            documentType {
              name
              id
            }
          }
        }
      }
    `,
    variables,
  };
};

export const getTransactionContentsOnly = createAsyncThunk(
  'transactions/getTransactionContentsOnly',
  async ({ id }: { id: string }) => {
    const response = await client.query(GET_TRANSACTION_CONTENTS_ONLY(id));
    return response;
  },
);
