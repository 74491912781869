import { useAppDispatch } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import { updateActiveTranslateToContract } from 'store/translatorsToContract/translatorDetailSlice';
import { ParameterGroup as IParameterGroup, Parameter } from 'common/model/TranslateToContract';
import ParameterGroup from './ParameterGroup';

const ParameterGroupSection = ({ parameterGroup, mode }: { parameterGroup: IParameterGroup[]; mode: string }) => {
  const dispatch = useAppDispatch();
  const onChangeParamGroup = (updatedParams: Parameter[], parameterGroupKey: string, index: number) => {
    // update the parameter_group with the updated params
    const updatedParameterGroup = parameterGroup.map((parameterGroup, i) => {
      if (i === index) {
        return { ...parameterGroup, value: updatedParams };
      }
      return parameterGroup;
    });
    dispatch(updateActiveTranslateToContract({ key: 'parameterGroup', value: updatedParameterGroup }));
  };
  return (
    <SectionComponent
      title={
        <>
          3. Parameter Tables<span className="required-indicator-title">*</span>
        </>
      }
    >
      {parameterGroup.map((parameterGroup: IParameterGroup, index: any) => {
        return (
          <div key={`${parameterGroup.key}-${index}`}>
            <ParameterGroup
              parameterGroup={parameterGroup}
              key={`${parameterGroup.key}-${index}`}
              onChangeParamGroup={(updatedParams: Parameter[]) => {
                onChangeParamGroup(updatedParams, parameterGroup.key, index);
              }}
              mode={mode}
            />
          </div>
        );
      })}
    </SectionComponent>
  );
};

export default ParameterGroupSection;
