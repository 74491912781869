import { Form } from 'semantic-ui-react';
import CheckboxField from 'atoms/FormField/Checkbox';
import './Legend.scss';

export interface GraphLegendOptions {
  label: string;
  key: number;
  color: string;
}

interface LegendProps {
  legend: GraphLegendOptions[];
}

const Legend = ({ legend }: LegendProps) => {
  return (
    <Form className="graph-legend p-l-xl">
      <h4 className="font-500 color-blue-very-dark">Legend</h4>

      {legend.map(({ label, color, key }) => (
        <div
          className="graph-legend-container"
          key={key}
        >
          {/* @ts-ignore */}
          <CheckboxField
            value={true}
            disabled={true}
          />
          <label className="d-contents">
            <span
              className="circle-sm m-l-xs m-r-xs"
              style={{ backgroundColor: color }}
            ></span>
            <span className="font-size-0_8571 font-600 color-blue-very-dark-grayish">{label}</span>
          </label>
        </div>
      ))}
    </Form>
  );
};

export default Legend;
