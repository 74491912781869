import { SocketProvider } from '@ericlathrop/phoenix-js-react-hooks';
import logdna from '@logdna/browser';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, useLocation, useRoutes } from 'react-router-dom';
import AllRoutes from 'routes/index';
import { RootState } from 'store';
import ErrorFallback from 'components/ErrorFallback';
import { setToken } from 'store/authentication/authSlice';
import { getAdminProfile, getProfile, getSystemInfo } from 'common/api/authentication';
import { checkIfAdminURL } from 'utils/utils-admin';

// To track application name in logs
const LOGDNA_INGESTION_KEY = String(process.env.REACT_APP_LOGDNA_KEY || 'NO_KEY_GIVEN');
const APP_NAME: string = window.location.host;

const AppRouter = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const authURLs = ['/sign-in', '/sign-up', '/sign-up/verify', '/reset-password', '/alternate-email'];
    if (!authURLs.includes(location.pathname)) {
      const profile = checkIfAdminURL() ? getAdminProfile : getProfile;
      dispatch(profile());
    }
    dispatch(getSystemInfo());
  }, [dispatch]);

  return useRoutes(AllRoutes());
};

const App = () => {
  const dispatch = useDispatch();
  const { id, firstName, lastName, token } = useSelector((state: RootState) => state.auth);

  const tokenInLocal = localStorage.getItem('sericin_app_token');
  // Handles the page refresh case
  const tokenValue = token !== null ? token : tokenInLocal;

  useEffect(() => {
    if (tokenInLocal !== null) {
      // Store latest value of token
      dispatch(setToken());
    }
  }, [tokenInLocal, dispatch]);

  const isAdmin = checkIfAdminURL();

  /***
   * Configuration of logdna
   *  - Disable logs on development => enabled only for production, staging and demo.
   *  - setup console.integrations to make sure that console errors, warnings and info logs
   *    render to mezmo
   *  - sampleRate: Percentage of sessions to track from 0 to 100. 0 for none 100 for all
   *  - flushInterval: Number of milliseconds to buffer log lines before sending to LogDNA
   */
  logdna.init(LOGDNA_INGESTION_KEY, {
    app: APP_NAME,
    console: {
      enable: process.env.NODE_ENV !== 'development',
      integrations: ['error', 'warn', 'info'],
    },
    sampleRate: 5,
    flushInterval: 500,
  });

  logdna.addContext({
    user: {
      email: `${firstName} ${lastName}`,
      userId: id,
    },
  });

  const socketUrl = isAdmin ? process.env.REACT_APP_WEBSOCKET_ADMIN_URL : process.env.REACT_APP_WEBSOCKET_URL;
  const socketOptions = {
    params: { authToken: tokenValue },
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router>
        {tokenValue !== null && !isAdmin ? (
          <SocketProvider
            url={socketUrl}
            options={socketOptions}
          >
            <AppRouter />
          </SocketProvider>
        ) : (
          <AppRouter />
        )}
      </Router>
    </ErrorBoundary>
  );
};
export default App;
