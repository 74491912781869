import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { addFloorPlan } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { FloorPlanProps } from 'common/api/properties';
import { conditionalClassName } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import FloorPlanComponent from './FloorPlanComponent';
import './FloorPlan.scss';

const FloorPlan = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const disabled: boolean = mode === MODE_OPTIONS.READ;
  const { activeFloor, filterDate } = useAppSelector((state: RootState) => state.propertyDetail);
  let { floorPlans, spaces } = activeFloor;

  if (filterDate !== null && floorPlans.length !== 0) {
    floorPlans = floorPlans.filter(
      (obj: FloorPlanProps) =>
        // @ts-ignore
        convertDateToISO(obj.revisionDate) <= convertDateToISO(filterDate),
    );
    if (floorPlans.length !== 0) {
      floorPlans = [floorPlans[floorPlans.length - 1]];
    }
  }

  const onAddNew = () => {
    if (!disabled) dispatch(addFloorPlan());
  };

  return (
    <div className="floor-plans-section p-b-xs">
      {floorPlans.map((floorplan: any, index: number) => (
        <FloorPlanComponent
          key={floorplan.id}
          index={index}
          mode={mode}
          floorPlans={floorPlans}
          spaces={spaces}
        />
      ))}

      <Grid
        className={`outlined container plan-content pm-none p-sm bg-white border-sm-grayish-magenta-light align-center ${conditionalClassName(!disabled, 'cursor-pointer')}`}
        onClick={onAddNew}
        data-test="add-new-floorplan-revision-button"
        disabled={disabled}
      >
        <div className="d-flex align-center m-l-auto m-r-auto">
          <span className="circle-m plus-icon m-b-xxs m-r-xxs">
            <Icon icon={Icons.Add} />
          </span>
          <span className="text color-blue-very-dark-grayish">Add new floorplan revision</span>
        </div>
      </Grid>
    </div>
  );
};

export default FloorPlan;
