import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import EntityActivity from 'common/model/EntityActivity';
import EntityType from 'common/model/EntityType';
import EntityWithJoinForAdmin from 'common/model/EntityWithJoinForAdmin';
import PageInfo from 'common/model/PageInfo';
import { deleteEntity, listEntities, listEntityActivities, listEntityTypes } from 'common/api/adminEntities';

export interface AdminEntitiesState {
  entitiesList: EntityWithJoinForAdmin[];
  isLoading: boolean;
  isSearching: boolean;
  search: null | string;
  pageInfo: PageInfo[];
  typesOfEntity: EntityType[];
  typesOfEntityActivity: EntityActivity[];
}

const initialState: AdminEntitiesState = {
  entitiesList: [],
  isLoading: false,
  isSearching: false,
  search: null,
  pageInfo: [],
  typesOfEntity: [],
  typesOfEntityActivity: [],
};

const adminEntities = createSlice({
  name: 'adminEntities',
  initialState,
  reducers: {
    updateSearch: (state, { payload }) => {
      state.search = payload;
    },
  },
  extraReducers: builder => {
    //listEntities
    builder.addCase(listEntities.pending, (state, action) => {
      const { searchBarSpinOnly } = action.meta.arg;
      if (searchBarSpinOnly) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listEntities.fulfilled, (state, action) => {
      const { edges, pageInfo } = action.payload.data.listEntities;
      state.entitiesList = edges.map((element: any) => element.node);
      state.pageInfo = pageInfo;
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listEntities.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error.message);
      toast.error('Something went wrong trying to fetch entities');
    });

    //deleteEntity
    builder.addCase(deleteEntity.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteEntity.fulfilled, (state, action) => {
      state.entitiesList = state.entitiesList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Delete admin entity successfully');
    });
    builder.addCase(deleteEntity.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error.message);
      toast.error('Something went wrong trying to delete the entity');
    });

    // listEntityTypes
    builder.addCase(listEntityTypes.fulfilled, (state, action) => {
      const enumValues: { name: EntityType }[] = action.payload.data.__type.enumValues;
      state.typesOfEntity = enumValues && enumValues.length ? enumValues.map(value => value.name) : [];
    });
    builder.addCase(listEntityTypes.rejected, (state, action) => {
      console.error(action.error);
      toast.error('listEntityTypes API request rejected');
    });

    // listEntityActivities
    builder.addCase(listEntityActivities.fulfilled, (state, action) => {
      const enumValues: { name: EntityActivity }[] = action.payload.data.__type.enumValues;
      state.typesOfEntityActivity = enumValues && enumValues.length ? enumValues.map(value => value.name) : [];
    });
    builder.addCase(listEntityActivities.rejected, (state, action) => {
      console.error(action.error);
      toast.error('listEntityActivities API request rejected');
    });
  },
});

export const { updateSearch } = adminEntities.actions;

export default adminEntities.reducer;
