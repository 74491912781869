import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import AuthLayout from 'layouts/AuthLayout';
import SignInForm from './SignInForm';

const SignIn = () => {
  const { isLoading } = useAppSelector((state: RootState) => state.auth);

  return (
    <AuthLayout
      loading={isLoading}
      form={<SignInForm />}
      signIn={true}
    />
  );
};

export default SignIn;
