import { CAcFee } from 'common/_classes/contractsV2';
import Decimal from 'decimal.js';
import moment from 'moment';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import OutOfHoursAcChargesPayable from 'common/model/OutOfHoursAcChargesPayable';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import TabLabel from '../../TabLabel';
import AcFeesForm from './AcFeesForm';

const AcFees = ({ mode, isViewPage }: ModeProps & ViewProps) => {
  const dispatch = useAppDispatch();

  const { activeContract } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const acFees = activeContract?.acFees || [];

  const [tabIndex, setTabIndex] = useState(0);

  const disabled = mode === MODE_OPTIONS.READ || isViewPage;

  const key = 'acFees';

  const onAddFees = () => {
    const newAcFee = new CAcFee(null);

    if (activeContract?.outOfHoursAcPayble === OutOfHoursAcChargesPayable.NoAcIs247) {
      newAcFee.standardHours = new Decimal('24');
    }

    const value = [...acFees, newAcFee];
    dispatch(updateActiveContractInput({ key, value }));
    setTabIndex(value.length - 1);
  };

  const onDelete = (index: number) => {
    const value = acFees.filter((_item, idx) => index !== idx);
    dispatch(updateActiveContractInput({ key, value }));
  };

  const getTabsMenu = (): TabMenuProps[] => {
    return acFees.map(({ startDate, endDate }, index: number) => {
      const start = moment(startDate).isValid() ? moment(startDate).format('DD/MM/YYYY') : '';
      const end = moment(endDate).isValid() ? moment(endDate).format('DD/MM/YYYY') : '';

      const dateText = `${start}${start && end ? ' - ' : ''}${end}`;

      return {
        key: `standalone-ac-fees-tab-${index}`,
        label: (
          <TabLabel
            title="Air Conditioning"
            disabled={mode === MODE_OPTIONS.READ}
            deletable={acFees.length !== 1}
            index={index}
            onDelete={onDelete}
            activeIndex={tabIndex}
            tabsLength={acFees.length}
            dateText={dateText}
            setTabIndex={setTabIndex}
          />
        ),
        customTab: (
          <AcFeesForm
            key={index}
            mode={mode}
            index={index}
            isViewPage={isViewPage}
          />
        ),
      };
    });
  };

  const onTabChange = (index: number) => setTabIndex(index);

  return acFees.length > 0 ? (
    <div className="border-bottom-sm-grayish-magenta-light p-b-s m-t-sm">
      {!disabled && (
        <AddPlus
          label="Add charge period"
          onClick={onAddFees}
          className="m-l-auto"
        />
      )}

      <TabReact
        dataTest=""
        activeTabIndex={tabIndex}
        tabMenu={getTabsMenu()}
        onTabChange={onTabChange}
      />
    </div>
  ) : (
    <></>
  );
};

export default AcFees;
