import { Button, Grid } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import { Icons } from 'utils/utils-icons';
import './RevisionForm.scss';

interface RevisionFormProps {
  note: string;
}

const RevisionForm = ({ note }: RevisionFormProps) => {
  return (
    <Grid className="revision-information">
      <Grid.Row className="p-b-none">
        <Grid.Column
          width={2}
          className="revision-label color-blue-very-dark"
        >
          Version
        </Grid.Column>
        <Grid.Column width={4}>
          <Badge badgeColor={BadgeColor.GRAY}>V.0.0</Badge>
        </Grid.Column>
        <Grid.Column
          width={10}
          className="revision-last-modified-information color-blue-desaturated-dark"
        >
          Last modified: 22/04/2022 by Mila S.
        </Grid.Column>
      </Grid.Row>

      <Grid.Row className="p-b-none">
        <Grid.Column
          width={2}
          className="revision-label color-blue-very-dark"
        >
          Status
        </Grid.Column>
        <Grid.Column width={4}>
          <Badge badgeColor={BadgeColor.ORANGE}>DRAFT</Badge>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column
          width={2}
          className="revision-label color-blue-very-dark"
        >
          Note
        </Grid.Column>
        <Grid.Column width={13}>{note}</Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <div className="line" />
      </Grid.Row>

      <Grid.Row>
        <Button className="revision-history-btn bg-gray-very-light-v4">
          <Icon icon={Icons.Eye} />
          SEE THE VERSION HISTORY
        </Button>
      </Grid.Row>
    </Grid>
  );
};

export default RevisionForm;
