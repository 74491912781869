import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TabContainer from 'components/TabContainer';
import { ApprovalView, toggleApprovalView } from 'store/transactions/transactionDetailSlice';
import ConfigureSendOutToTenantModal from '../History/Tabs/Doc/ConfigureSendOutToTenantModal';
import ApprovalInnerTabs from './ApprovalInnerTabs';
import './ApprovalTab.scss';

const ApprovalTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { approvalView } = useAppSelector((state: RootState) => state.transactionDetail);
  const viewTitle: ApprovalView =
    approvalView === ApprovalView.Reviewer ? ApprovalView.Submitter : ApprovalView.Reviewer;

  return (
    <TabContainer tabTitle="Approvals">
      <ApprovalInnerTabs />
      <ConfigureSendOutToTenantModal />
      <Button
        className="btn grey-bg selection-btn"
        onClick={() => dispatch(toggleApprovalView())}
      >
        {viewTitle} View
      </Button>
    </TabContainer>
  );
};

export default ApprovalTab;
