import {
  ChartData,
  ChartDataset,
  Chart as ChartJS,
  ChartType,
  ScriptableTooltipContext,
  TooltipModel,
  registerables,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Colors } from 'utils/utils-colors';
import { generateUniqueId } from 'utils/utils-random';
import externalTooltip, { GraphEvent, GraphIDs } from './tooltip';
import './Graph.scss';

ChartJS.register(...registerables);

interface GraphProps {
  chartType: ChartType;
  datasets: ChartDataset<ChartType, (string | null)[]>[];
  labels: string[];
  tooltipHTML: (tooltipModel: TooltipModel<ChartType>) => string;
  stackedBar: boolean;
  tooltipXAxisTolerance: number;
  yAxisTitle: string;
  displayHorizontalGridLines: boolean;
  displayVerticalGridLines: boolean;
}

const alterOpacity = (tooltipElement: HTMLElement | null, opacity: '0' | '1', display: 'none' | 'inline') => {
  if (tooltipElement) {
    tooltipElement.style.opacity = opacity;
    tooltipElement.style.display = display;
  }
};

const handleToolTipOpacity = (e: MouseEvent) => {
  e.stopPropagation();
  return alterOpacity;
};

const options = (
  stackedBar: boolean,
  graphIDs: GraphIDs,
  tooltipHTML: (tooltipModel: TooltipModel<ChartType>) => string,
  handleToolTipOpacity: GraphEvent,
  eventsAdded: boolean,
  setEventsAdded: (status: boolean) => void,
  tooltipXAxisTolerance: number,
  displayHorizontalGridLines: boolean,
  displayVerticalGridLines: boolean,
) => ({
  interaction: {
    mode: 'index',
    intersect: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  categoryPercentage: 0.8,
  barPercentage: 0.6,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      // Disable the on-canvas tooltip
      enabled: false,
      external: (context: ScriptableTooltipContext<ChartType>) => {
        externalTooltip(
          context,
          graphIDs,
          tooltipHTML,
          handleToolTipOpacity,
          eventsAdded,
          setEventsAdded,
          tooltipXAxisTolerance,
        );
      },
    },
  },
  scales: {
    y: {
      grid: {
        display: displayHorizontalGridLines,
      },
      beginAtZero: true,
      display: true,
      ticks: {
        maxTicksLimit: 10,
        color: Colors.BLUE_DARK_GRAYISH,
        font: {
          family: 'Urbanist',
          size: '12',
          weight: '700',
          lineHeight: '17px',
        },
        callback: (value: number) => value.toLocaleString(),
      },
    },
    x: {
      grid: {
        display: displayVerticalGridLines,
      },
      stacked: stackedBar,
      display: true,
      ticks: {
        color: Colors.BLUE_DARK_GRAYISH,
        font: {
          family: 'Urbanist',
          size: '12',
          weight: '700',
          lineHeight: '17px',
        },
      },
    },
  },
});

const Graph = ({
  datasets,
  labels,
  tooltipHTML,
  stackedBar,
  tooltipXAxisTolerance,
  chartType,
  yAxisTitle,
  displayHorizontalGridLines,
  displayVerticalGridLines,
}: GraphProps): JSX.Element => {
  const [eventsAdded, setEventsAdded] = useState<boolean>(false);
  const [graphID] = useState<string>(`graph-${generateUniqueId(null)}`);
  const [tooltipContainerID] = useState<string>(`tooltip-container-${generateUniqueId(null)}`);
  const [tooltipID] = useState<string>(`tooltip-${generateUniqueId(null)}`);

  const ids: GraphIDs = {
    graphID,
    tooltipContainerID,
    tooltipID,
  };

  const data: ChartData<ChartType, (string | null)[], string> = {
    labels,
    datasets,
  };

  useEffect(() => {
    return () => {
      const tooltipEl = document.getElementById(tooltipContainerID);
      const graphDiv = document.getElementById(graphID);

      alterOpacity(tooltipEl, '0', 'none');
      alterOpacity(graphDiv, '0', 'none');

      tooltipEl?.removeEventListener('mouseleave', handleToolTipOpacity);
      tooltipEl?.removeEventListener('mouseenter', handleToolTipOpacity);
      graphDiv?.removeEventListener('mouseleave', handleToolTipOpacity);
    };
  }, [graphID, tooltipContainerID]);

  return (
    <div className="graph">
      <div>
        <p className="y-title">{yAxisTitle}</p>
        <div
          id={graphID}
          className="graph-wrapper"
        >
          <Chart
            type={chartType}
            // @ts-ignore
            options={options(
              stackedBar,
              ids,
              tooltipHTML,
              handleToolTipOpacity,
              eventsAdded,
              setEventsAdded,
              tooltipXAxisTolerance,
              displayHorizontalGridLines,
              displayVerticalGridLines,
            )}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};

export default Graph;
