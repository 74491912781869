import { ParameterChoiceBase } from 'common/_classes';
import { orderBy } from 'lodash';
import './ChoiceList.scss';

interface ChoiceListProps {
  choices: ParameterChoiceBase[];
  className?: string;
}

const ChoiceList = (props: ChoiceListProps): JSX.Element => {
  const orderedChoices = orderBy(props.choices, 'index', 'asc');

  return (
    <div
      className={`choices-list ${props.className}`}
      data-test="choices"
    >
      <p className="answer-type">Choices</p>
      {orderedChoices.map((choice: ParameterChoiceBase, index: number) => (
        <p
          className={`choices-option ${index === 0 && 'first'} m-b-xs`}
          key={`choice-list-${index}`}
        >
          {index + 1}. <span className="choices-highlighted">{choice.text}</span>
        </p>
      ))}
    </div>
  );
};

export default ChoiceList;
