import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { EntityTypeProps } from 'common/api/entities';
import Step, { StepOptionProps } from './Step';

export const ENTITY_STEPS_OPTIONS: StepOptionProps[] = [
  { step: 1, key: 1, label: 'General Information' },
  { step: 2, key: 2, label: 'Legal Information' },
  { step: 3, key: 3, label: 'Supporting Documents' },
];

const Steps = ({ entityType }: EntityTypeProps): JSX.Element => {
  const { activeStep } = useAppSelector((state: RootState) => state.entityDetail);

  return (
    <div>
      {ENTITY_STEPS_OPTIONS.map((item: StepOptionProps, index: number) => (
        <Step
          entityType={entityType}
          key={item.key}
          isOnlyOne={ENTITY_STEPS_OPTIONS.length === 1}
          isFirst={index === 0}
          isLast={index === ENTITY_STEPS_OPTIONS.length - 1}
          active={activeStep === item.step}
          step={item}
        />
      ))}
    </div>
  );
};

export default Steps;
