import PageNotFound from 'layouts/PageNotFound';
import { AppRoute } from './types';

const MiscellaneousRoutes = (): AppRoute[] => [
  {
    path: '/*',
    element: <PageNotFound />,
  },
];

export default MiscellaneousRoutes;
