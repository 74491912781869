import { Decimal } from 'decimal.js';

export interface IForecastedTenancies {
  id: string;
  startDate: string;
  endDate: string;
  tenancyId: string;
  tenantName: string;
  transactionId: string;
  tenantId: string;
  unitId: string;
  unitName: string;
  floorId: string;
  forecastId: string;
  type: string;
  wholeFloor: boolean;
}

export class ForecastedTenancies implements IForecastedTenancies {
  id: string;
  startDate: string;
  endDate: string;
  tenancyId: string;
  tenantName: string;
  transactionId: string;
  tenantId: string;
  unitId: string;
  unitName: string;
  floorId: string;
  forecastId: string;
  type: string;
  wholeFloor: boolean;

  constructor(data: IForecastedTenancies) {
    this.id = data.id;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.tenancyId = data.tenancyId;
    this.tenantName = data.tenantName;
    this.transactionId = data.transactionId;
    this.tenantId = data.tenantId;
    this.unitId = data.unitId;
    this.unitName = data.unitName;
    this.floorId = data.floorId;
    this.forecastId = data.forecastId;
    this.type = data.type;
    this.wholeFloor = data.wholeFloor;
  }

  static fragment() {
    return `
      id
      startDate
      endDate
      tenancyId
      transactionId
      tenantId
      unitId
      floorId
      forecastId
      type
      tenantName
      unitName
      wholeFloor
    `;
  }

  static formatForAPI(forecastedTenancy: ForecastedTenancies) {
    return {
      id: forecastedTenancy.id,
      start_date: forecastedTenancy.startDate,
      end_date: forecastedTenancy.endDate,
      tenancy_id: forecastedTenancy.tenancyId,
      transaction_id: forecastedTenancy.transactionId,
      tenant_id: forecastedTenancy.tenantId,
      unit_id: forecastedTenancy.unitId,
      floor_id: forecastedTenancy.floorId,
      forecast_id: forecastedTenancy.forecastId,
      type: forecastedTenancy.type,
      tenant_name: forecastedTenancy.tenantName,
      unit_name: forecastedTenancy.unitName,
      whole_floor: forecastedTenancy.wholeFloor,
    };
  }
}
