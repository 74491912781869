import { Dropdown } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import UploadArea from 'atoms/FormField/FilesUpload/UploadArea';
import { addFloorPlanFile, deleteFloorPlan, deleteFloorPlanDocument } from 'store/properties/propertyDetailSlice';
import { prepFloorPlanForUpload } from 'common/api/floor';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';

interface PlanUploadAndThreeDotsMenuProps {
  id: string;
  disabled: boolean;
  floorPlan: DocumentIntendedToBeUploaded;
  index: number;
}

const PlanUploadAndThreeDotsMenu = ({ disabled, id, floorPlan, index }: PlanUploadAndThreeDotsMenuProps) => {
  const dispatch = useAppDispatch();
  const allowedFileTypes = ['application/pdf'];
  const floorPlanUploaderId = 'floor-plan-uploader-id-' + index;

  const openUploader = () => {
    if (!disabled) {
      const uploadFileRef = document.getElementById(floorPlanUploaderId);

      if (uploadFileRef) {
        uploadFileRef.click();
      }
    }
  };

  return (
    <div className="d-flex w-100 align-center p-none">
      {floorPlan && (
        <>
          <Icon
            icon={Icons.Pdf}
            className="m-r-xxs"
            width="2rem"
            height="2rem"
          />

          <a
            href={floorPlan.url}
            target="_blank"
            rel="noreferrer"
            className="plan-name color-blue-very-dark-grayish m-r-xs"
          >
            {floorPlan.name}
          </a>
        </>
      )}

      <Dropdown
        className="bg-gray-very-light-v4 d-flex align-center p-xxs m-l-auto"
        trigger={<Icon icon={Icons.EllipsisVertical} />}
        icon={false}
        disabled={disabled}
        direction="left"
      >
        <Dropdown.Menu>
          <Dropdown.Item
            className="d-flex align-center"
            onClick={openUploader}
          >
            <UploadArea
              disabled={disabled}
              prepUpload={prepFloorPlanForUpload}
              prepUploadParams={{ floorPlanId: id }}
              onAdd={addFloorPlanFile}
              onAddFunctionParams={{ floorPlanId: id }}
              isMultipleUploads={false}
              hidden={true}
              id={floorPlanUploaderId}
              fileTypesAllowed={allowedFileTypes}
            />
            <Icon
              icon={Icons.Uploadv3}
              className="m-b-xxs m-r-xs"
            />
            Upload a new file
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-center"
            onClick={() => dispatch(deleteFloorPlanDocument({ id }))}
            disabled={!floorPlan}
          >
            <Icon
              icon={Icons.Delete}
              className="m-b-xxs m-r-xs"
            />
            Delete floor plan
          </Dropdown.Item>

          <Dropdown.Item
            className="d-flex align-center"
            onClick={() => dispatch(deleteFloorPlan({ id }))}
          >
            <Icon
              icon={Icons.Delete}
              className="m-b-xxs m-r-xs"
            />
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default PlanUploadAndThreeDotsMenu;
