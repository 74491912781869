import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';

interface UpdateFloorQueryVariables {
  currentLandlordEntityId: string;
  floorId: any;
  params: {
    name: any;
    index: any;
    comments: any;
    characteristics: any;
    ceilingHeight: any;
    floorLoading: any;
    activationDate: any;
    deactivationDate: any;
  };
}

const UPDATE_FLOOR = (activeFloor: any) => {
  const { id, name, index, comments, characteristics, ceilingHeight, floorLoading, activationDate, deactivationDate } =
    activeFloor;

  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Update Floor: No currentLandlordEntityId');

  if (!id) throw new Error('Update Floor: No floorId');

  const parsedIndex = parseInt(index);

  if (isNaN(parsedIndex)) throw new Error('Update Floor: index is not an integer');

  const variables: UpdateFloorQueryVariables = {
    currentLandlordEntityId,
    floorId: id,
    params: {
      name,
      index: parsedIndex,
      comments,
      characteristics,
      ceilingHeight,
      floorLoading,
      activationDate: convertDateToISO(activationDate),
      deactivationDate: convertDateToISO(deactivationDate),
    },
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $params: FloorInput!, $floorId: ID!) {
        updateFloor(currentLandlordEntityId: $currentLandlordEntityId, params: $params, floorId: $floorId) {
          id
          name
          index
          comments
          characteristics
          ceilingHeight
          floorLoading
          activationDate
          deactivationDate
          spaces {
            id
            name
            registeredName
            type
            usedForOccupancyRate
            grossArea
            netArea
            lettableArea
            saleableArea
            efficiencyRatio
            comments
            characteristics
            activationDate
            deactivationDate
          }
          floorPlans {
            id
            reviewFlag
            floorPlan {
              id
              name
              path
              public
              url
            }
            revisionDate
            unitsNumber
            whollyOwned
            grossArea
            netArea
            lettableArea
            saleableArea
            efficiencyRatio
          }
        }
      }
    `,
    variables,
  };
};

export const updateFloor = createAsyncThunk('properties/updateFloor', async (_arg, { getState }) => {
  const {
    propertyDetail: { activeFloor },
  } = getState() as RootState;
  const response = await client.mutate(UPDATE_FLOOR(activeFloor));
  return response;
});
