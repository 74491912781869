import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { ContextAndDocType } from './getPdfHtml';

interface GenerateDocxDocumentQueryVariables extends ContextAndDocType {
  landlordId: string | null;
  final: boolean;
}

export const GET_DOCX_DOCUMENT = ({ context, contextId, documentTypeId }: ContextAndDocType) => {
  if (!contextId) throw new Error('Get Docx Document: Context ID is missing');

  const variables: GenerateDocxDocumentQueryVariables = {
    context,
    contextId,
    landlordId: getCurrentLandlordEntityId(),
    documentTypeId,
    // TODO whats the purpose of this final parameter?
    final: false,
  };

  return {
    query: gql`
      query ($context: ExecuteContext!, $contextId: ID!, $landlordId: ID, $documentTypeId: ID!, $final: Boolean!) {
        generateDocxDocument(
          context: $context
          contextId: $contextId
          landlordId: $landlordId
          documentTypeId: $documentTypeId
          final: $final
        )
      }
    `,
    variables,
  };
};

export const generateDocxDocument = createAsyncThunk(
  'miscellaneous/generateDocxDocument',
  async (
    {
      context,
      contextId,
      documentTypeId,
    }: {
      context: string;
      contextId: string | undefined;
      documentTypeId?: string;
      contextName: string | undefined | null;
    },
    { getState },
  ) => {
    const {
      miscellaneous: { activeDocTypeId },
    } = getState() as RootState;
    const docId = documentTypeId ? documentTypeId : activeDocTypeId;
    const response = await client.query(GET_DOCX_DOCUMENT({ context, contextId, documentTypeId: docId }));
    return response;
  },
);
