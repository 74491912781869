import { Loader, Search, SearchProps } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { debounce } from 'lodash';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import './SearchBar.scss';

interface SearchBarProps {
  value?: string;
  search: (e: React.MouseEvent<HTMLElement>, args: SearchProps) => void;
  className?: string;
  searching?: boolean;
  dataTest?: string;
}

const SearchBar = ({ value, search, className = '', searching = false, dataTest }: SearchBarProps) => {
  const isSearching = useAppSelector(
    (state: RootState) =>
      state.propertiesListing.isSearching ||
      state.entitiesListing.isSearching ||
      state.contractsListingV2.isSearching ||
      state.members.isSearching ||
      state.roles.isSearching ||
      state.parametersListing.isSearching ||
      state.adminUsers.isSearching ||
      state.adminEntities.isSearching ||
      state.provisionsListing.isSearching ||
      state.policiesListing.isSearching ||
      state.formattersListing.isSearching ||
      state.translatorsListing.isSearching,
  );

  const searchIcon = (
    <Icon
      className="search-icon"
      icon={Icons.Search}
      width="1rem"
      height="1rem"
    />
  );
  const spinner = (
    <Loader
      active={true}
      size="mini"
    />
  );

  /* Might be removed in the future 
  when we have provisions categories search */
  let disabled = false;
  const provisionsSearchDisabled: boolean = useAppSelector(
    (state: RootState) => state.provisionsListing.provisionCategoriesList.length === 0,
  );

  if (window.location.pathname === '/provisions/dashboard') {
    disabled = provisionsSearchDisabled;
  }

  return (
    <Search
      defaultValue={value}
      className={`search-bar ${className}`}
      input={{
        icon: isSearching || searching ? spinner : searchIcon,
        iconPosition: 'left',
      }}
      placeholder="Search..."
      onSearchChange={debounce(search, 700)}
      loading={isSearching}
      disabled={disabled}
      data-test={dataTest || 'search-atom'}
    />
  );
};

export default SearchBar;
