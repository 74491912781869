import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';

export const LIST_PARAMETER_GROUPS = () => {
  return {
    query: gql`
      query {
        listParameterGroups {
          id
          name
          index
          category {
            id
            name
            color
            index
          }
          guideline {
            explanatoryNotes
            generalGuidance
            internalNotes
            parameterGroup {
              id
            }
            referenceType
            valuationGuidance
          }
        }
      }
    `,
  };
};

export const listParameterGroups = createAsyncThunk('multiLevelMenu/listParameterGroups', async () => {
  const response = await getClientType().query(LIST_PARAMETER_GROUPS());
  return response.data;
});
