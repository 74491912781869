import { Button } from 'semantic-ui-react';
import { preventReload } from 'utils/tsHelper';

export interface CreateNewButtonProps {
  onCreateNew?: () => void;
  btnText?: string;
  url: string;
  disabled?: boolean;
  dataTest?: string;
}

const CreateNewButton = ({ onCreateNew, btnText = 'CREATE', url, disabled, dataTest }: CreateNewButtonProps) => (
  <Button
    disabled={disabled}
    className="btn grey-bg layout-create-btn"
    data-test={dataTest}
    onClick={e => {
      preventReload(e);
      onCreateNew && onCreateNew();
    }}
    href={url}
  >
    {btnText}
  </Button>
);

export default CreateNewButton;
