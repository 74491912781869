import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterPickIndex } from 'common/_classes';

interface UpdateParametersIndexQueryVariables {
  parameters: ParameterPickIndex[];
}

const UPDATE_PARAMETER_INDEX = (parameters: ParameterPickIndex[]) => {
  const variables: UpdateParametersIndexQueryVariables = { parameters };

  return {
    mutation: gql`
      mutation ($parameters: [ParameterReorderInput]!) {
        updateParameterIndex(parameters: $parameters) {
          success
        }
      }
    `,
    variables,
  };
};

export const updateParametersIndex = createAsyncThunk(
  'parameters/updateParametersIndex',
  async ({ parametersList }: { parametersList: ParameterPickIndex[] }) => {
    const response = await adminClient.mutate(UPDATE_PARAMETER_INDEX(parametersList));
    return response;
  },
);
