import MEntity from 'common/model/Entity';

type TEntityPickName = Pick<MEntity, 'id' | 'name'>;
export type TEntityPickNameOnly = Pick<MEntity, 'name'>;

export class EntityPickName implements TEntityPickName {
  id: string;
  name: string;

  constructor(input: TEntityPickName) {
    this.id = input.id;
    this.name = input.name;
  }

  static fragments(): string {
    return `
      id
      name
    `;
  }
}
