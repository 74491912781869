import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface GetPolicyQueryVariables {
  id: string | undefined;
}

export const GET_POLICY = (id: string | undefined | null) => {
  if (!id) throw new Error('GET_POLICY: id is required');

  const variables: GetPolicyQueryVariables = { id };

  return {
    query: gql`
      query ($id: ID!) {
        getPolicy(id: $id) {
          id
          name
          description
          documentTypeIds
          regulatories
          useType
          jurisdiction
          language
          entity {
            id
          }
          contents {
            content
            documentType {
              name
              id
            }
          }
          answers {
            answer
            answerType
            dateOfAnswer
            paramRef {
              parameterId
              tableId
              index
            }
            user {
              id
              firstName
              lastName
            }
            policy {
              id
            }
          }
        }
      }
    `,
    variables,
  };
};

export const getPolicy = createAsyncThunk('policies/getPolicy', async ({ id }: { id: string | undefined | null }) => {
  const response = await client.query(GET_POLICY(id));
  return response;
});
