import moment from 'moment';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Button } from 'semantic-ui-react';
import { range } from 'lodash';
import { Icon } from '@iconify/react';
import { getDateOfAddedDays } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import './CustomHeader.scss';

const years = range(new Date().getFullYear() + 178, 1969);

const months = moment.months();

const CustomHeader = (
  { date, changeYear, changeMonth, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps,
  selectDate: (date: Date) => void,
  minAndMax?: {
    minDate?: Date | string | null;
    maxDate?: Date | string | null;
  },
) => {
  const today = new Date();

  const minDateValid = moment(minAndMax?.minDate).isValid();
  const maxDateValid = moment(minAndMax?.maxDate).isValid();

  const canSelectMinDate = minDateValid && moment(getDateOfAddedDays(minAndMax?.minDate, -1)).isBefore(today);
  const canSelectMaxDate = maxDateValid && moment(getDateOfAddedDays(minAndMax?.maxDate, 1)).isAfter(today);

  const setDateToToday = () => {
    selectDate(today);
  };

  return (
    <div className="date-cell-custom-header">
      <Button
        size="mini"
        onClick={setDateToToday}
        disabled={!(canSelectMinDate && canSelectMaxDate)}
      >
        Select Today
      </Button>
      <div className="row">
        <Icon
          className="left-arrow"
          icon={Icons.ArrowBackIOS}
          onClick={decreaseMonth}
        />

        <select
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
        >
          {months.map(option => (
            <option
              key={option}
              value={option}
            >
              {option}
            </option>
          ))}
        </select>
        <select
          className="year-dropdown"
          value={date.getFullYear()}
          onChange={({ target: { value } }) => changeYear(parseInt(value))}
        >
          {years.map(option => (
            <option
              key={option}
              value={option}
            >
              {option}
            </option>
          ))}
        </select>
        <Icon
          className="right-arrow"
          icon={Icons.ArrowForwardIOS}
          onClick={increaseMonth}
        />
      </div>
    </div>
  );
};

export default CustomHeader;
