import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { duplicatePolicy, listPolicies } from 'common/api/policies';
import { deletePolicy } from 'common/api/policies/deletePolicy';
import { Icons } from 'utils/utils-icons';

interface PolicyRowActionButtonsParams {
  rowId: string;
  isAdmin: boolean;
  startCursor: any;
  onViewDetails: ViewDetailsProps;
}

export const PolicyRowActionButtons = ({
  rowId,
  isAdmin,
  startCursor,
  onViewDetails,
}: PolicyRowActionButtonsParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onDelete = (id: string) => {
    dispatch(deletePolicy({ id: id || '' }));
  };

  /*
   * When click on "duplicate" button in a row:
   *  - duplication is done in the back-end
   *  - fetch the policies again to update the list
   */
  const onDuplicate = (policyId: string | undefined) => {
    dispatch(duplicatePolicy(policyId)).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(
          listPolicies({
            first: 10,
            before: startCursor,
          }),
        );
      }
    });
  };

  let buttons: TableActionButton[] = [];

  const deleteMenu = {
    icon: Icons.TrashFill,
    tooltip: 'Delete Policy',
    onClick: () => onDelete(rowId),
    isPopup: true,
    popupText: 'Do you really want to delete this Policy?',
    popupConfirmationButton: 'DELETE',
    dataTest: 'delete-button',
  };

  if (isAdmin) {
    buttons = [deleteMenu];
  } else {
    buttons = [
      {
        icon: Icons.EyeSolid,
        tooltip: 'View Policy',
        onClick: () => onViewDetails(rowId),
        dataTest: 'View Details',
      },
      {
        icon: Icons.Duplicate,
        tooltip: 'Duplicate',
        onClick: () => onDuplicate(rowId),
        dataTest: 'duplicate',
      },
      deleteMenu,
    ];
  }

  return <TableActionButtons buttons={buttons} />;
};
