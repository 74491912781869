import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { checkEntitiesSteps, gotoEntityCreatorNextStep } from 'views/entities/components/entityValidator';
import { EntityTypeProps } from 'common/api/entities';
import { conditionalClassName } from 'utils/tsHelper';
import './Step.scss';

export interface StepOptionProps {
  key: number;
  step: number;
  label: string;
}

interface StepProps extends EntityTypeProps {
  active: boolean;
  step: StepOptionProps;
  isOnlyOne: boolean;
  isFirst: boolean;
  isLast: boolean;
}

const Step = ({ active, step, isOnlyOne, isFirst, isLast, entityType }: StepProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeEntity, activeStep } = useAppSelector(state => state.entityDetail);

  const onClick = (): void => {
    gotoEntityCreatorNextStep({
      dispatch,
      navigate,
      entityId: activeEntity.id,
      entityType,
      activeStep,
      nextStep: step.step,
    });
  };

  return (
    <div
      className={`step-wrapper d-flex p-l-l ${conditionalClassName(active, 'active')} ${conditionalClassName(checkEntitiesSteps(activeEntity, entityType, step.step - 1), 'disabled')}`}
      onClick={onClick}
    >
      <div className={`d-flex ${conditionalClassName(!isOnlyOne && !isFirst && !isLast, 'border-left')}`}>
        <div
          className={`${conditionalClassName(!isOnlyOne, 'line')} ${isFirst ? 'start' : isLast ? 'end' : 'middle'}`}
        ></div>

        <div className="d-flex details">
          <div className="circle">{step.step}</div>

          <div className="m-l-xs">
            <h5 className="title pm-none p-t-xxs color-blue-very-dark-grayish">STEP</h5>
            <h3 className="label pm-none p-t-xs color-blue-very-dark">{step.label}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step;
