import { ChartType, TooltipModel } from 'chart.js';
import { Loader } from 'semantic-ui-react';
import { Grid } from 'semantic-ui-react';
import GraphContainer from './Graph';
import Legend, { GraphLegendOptions } from './Legend';
import './GraphWithOutLinedContainer.scss';

export interface GraphInputData extends GraphLegendOptions {
  data: (string | null)[];
}

interface GraphWithOutLinedContainerProps {
  data: GraphInputData[];
  loading: boolean;
  title?: string;
  labels: string[];
  tooltipHTML: (tooltipModel: TooltipModel<ChartType>) => string;
  className: string;
  stackedBar?: boolean;
  tooltipXAxisTolerance?: number;
  chartType?: ChartType;
  yAxisTitle?: string;
  displayHorizontalGridLines?: boolean;
  displayVerticalGridLines?: boolean;
}

const GraphWithOutLinedContainer = ({
  data,
  loading,
  title,
  labels,
  tooltipHTML,
  className,
  stackedBar = false,
  tooltipXAxisTolerance = 200,
  chartType = 'bar',
  yAxisTitle = 'HK$',
  displayHorizontalGridLines = true,
  displayVerticalGridLines = true,
}: GraphWithOutLinedContainerProps) => {
  //generate legend from the data
  const legend: GraphLegendOptions[] = data.map(({ key, label, color }) => ({
    key,
    label,
    color,
  }));

  //generate legend from the data
  const datasets = data.map((element: GraphInputData) => ({
    label: element.label,
    data: element.data,
    backgroundColor: element.color,
    borderColor: element.color,
    spanGaps: true,
    tension: 0.1,
    pointRadius: 4,
  }));

  return (
    <Grid className={`graph-without-line bg-white border-sm-grayish-magenta-light ${className}`}>
      <Grid.Row className="pm-none">
        {loading ? (
          <Loader
            active={true}
            size="big"
            className="graph-loader"
          />
        ) : (
          <>
            <Grid.Column width={13}>
              <GraphContainer
                chartType={chartType}
                title={title}
                datasets={datasets}
                labels={labels}
                tooltipHTML={tooltipHTML}
                stackedBar={stackedBar}
                tooltipXAxisTolerance={tooltipXAxisTolerance}
                yAxisTitle={yAxisTitle}
                displayHorizontalGridLines={displayHorizontalGridLines}
                displayVerticalGridLines={displayVerticalGridLines}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <Legend legend={legend} />
            </Grid.Column>
          </>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default GraphWithOutLinedContainer;
