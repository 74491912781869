import { v4 as uuidv4 } from 'uuid';

export const generateUniqueId = (key: string | null): string => {
  return `${key ? `${key}-` : ''}${uuidv4()}`;
};

export const generateUniqueNum = (): string => {
  const idNumber: number = Math.floor(Math.random() * (9999999999 - 2 + 1)) + 2;
  return `${idNumber}`;
};
