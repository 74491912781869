import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import PhoneNumberField from 'atoms/FormField/PhoneInput';
import { updateInput } from 'store/authentication/authSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { invalidEmailText } from 'utils/tsHelper';
import { emailIsValid } from 'utils/utils-string';

const DetailsForm = ({ mode }: ModeProps) => {
  const { firstName, lastName, email, phoneNumber, countryCode } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const onChange = (type: string, value: string | number, countryCode?: string) => {
    dispatch(updateInput({ type, value }));

    if (countryCode !== undefined) {
      dispatch(updateInput({ type: 'countryCode', value: countryCode }));
    }
  };

  const checkIfValidEmail = emailIsValid(email);

  return (
    <Grid className="user-information">
      <Grid.Row>
        <Grid.Column width={5}>
          <InputField
            label="First Name"
            placeholder="First Name"
            fieldKey="firstName"
            value={firstName}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            dataTest="first-name"
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <InputField
            label="Last Name"
            placeholder="Last Name"
            fieldKey="lastName"
            value={lastName}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            dataTest="last-name"
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <InputField
            label="Email"
            placeholder="Email"
            type={InputFieldType.EMAIL}
            fieldKey="email"
            value={email}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            dataTest="email"
            isInValid={!checkIfValidEmail}
            errorMessage={!checkIfValidEmail && invalidEmailText}
          />
        </Grid.Column>
        <Grid.Column width={5}>
          <PhoneNumberField
            label="Phone number"
            fieldKey="phoneNumber"
            value={phoneNumber}
            countryCode={countryCode}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            useCountryCode={true}
            dataTest="phone-number"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default DetailsForm;
