import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface UpdateDefaultEntityQueryVariables {
  entityId: string | null;
}

export const UPDATE_DEFAULT_ENTITY = (entityId: string | null) => {
  if (!entityId) throw new Error('Update Defauly Entity: Entity ID is missing');

  const variables: UpdateDefaultEntityQueryVariables = {
    entityId,
  };

  return {
    mutation: gql`
      mutation ($entityId: ID!) {
        updateDefaultEntity(entityId: $entityId) {
          defaultEntity {
            id
          }
        }
      }
    `,
    variables,
  };
};

export const updateDefaultEntity = createAsyncThunk(
  'auth/updateDefaultEntity',
  async ({ entityId }: UpdateDefaultEntityQueryVariables) => {
    const response = await client.mutate(UPDATE_DEFAULT_ENTITY(entityId));
    return response;
  },
);
