import Decimal from 'decimal.js';
import Contract2Events from 'common/model/Contract2Events';
import Contract2EventsInput from 'common/model/Contract2EventsInput';
import ContractRentType from 'common/model/ContractRentType';
import ContractTerminationReason from 'common/model/ContractTerminationReason';
import ExistingFitOutTreatment from 'common/model/ExistingFitOutTreatment';
import HandoverCondition from 'common/model/HandoverCondition';
import PermittedUse from 'common/model/PermittedUse';
import Premise2Input from 'common/model/Premise2Input';
import SpecificUse from 'common/model/SpecificUse';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';
import { AssignmentRightsInputClone, CAssignmentRights } from './AssignmentRights';
import { CContractDocument, ContractDocumentInputClone } from './ContractDocument';
import { CExpansionRights, ExpansionRightsInputClone } from './ExpansionRights';
import { CFitOut, FitOutInputClone } from './FitOut';
import { CPremise } from './Premise';
import { CRefusalRights, RefusalRightsInputClone } from './RefusalRights';
import { CRenewalRights } from './RenewalRights';
import { CRentFreePeriod, RentFreePeriodInputClone } from './RentFreePeriod';
import { CRentReview, RentReviewInputClone } from './RentReview';
import { CSaleAndRedevelopmentRights, SaleAndRedevelopmentRightsInputClone } from './SaleAndRedevelopmentRights';
import { CSecurityAmount, SecurityAmountInputClone } from './SecurityAmount';
import { CShareRights, ShareRightsInputClone } from './ShareRights';
import { CSubletRights, SubletRightsInputClone } from './SubletRights';
import { CSurrenderRights, SurrenderRightsInputClone } from './SurrenderRights';

export interface Contract2EventsInputClone
  extends Omit<
    Contract2EventsInput,
    | 'assignmentRights'
    | 'capitalAllowance'
    | 'documents'
    | 'earlyOccupationStartDate'
    | 'existingFitOutTreatment'
    | 'expansionRights'
    | 'endDate'
    | 'fitOut'
    | 'handoverCondition'
    | 'permittedUse'
    | 'refusalRights'
    | 'renewalRights'
    | 'rentFreePeriods'
    | 'rentReview'
    | 'saleAndRedevelopmentRights'
    | 'securityAmount'
    | 'shareRights'
    | 'specificUse'
    | 'startDate'
    | 'subletRights'
    | 'surrenderRights'
    | 'terminationDate'
    | 'terminationReason'
  > {
  id: string;
  assignmentRights: AssignmentRightsInputClone | null;
  capitalAllowance: Decimal | null;
  documents: CContractDocument[];
  earlyOccupationStartDate: Date | null;
  existingFitOutTreatment: ExistingFitOutTreatment | null;
  expansionRights: ExpansionRightsInputClone | null;
  fitOut: FitOutInputClone;
  handoverCondition: HandoverCondition | null;
  permittedUse: PermittedUse | null;
  refusalRights: RefusalRightsInputClone | null;
  renewalRights: CRenewalRights[];
  rentFreePeriods: RentFreePeriodInputClone[];
  rentReview: RentReviewInputClone[];
  saleAndRedevelopmentRights: SaleAndRedevelopmentRightsInputClone | null;
  securityAmount: SecurityAmountInputClone;
  shareRights: ShareRightsInputClone | null;
  specificUse: SpecificUse | null;
  subletRights: SubletRightsInputClone | null;
  surrenderRights: SurrenderRightsInputClone | null;
  terminationDate: Date | null;
  terminationReason: ContractTerminationReason | null;
  startDate: Date | null;
  endDate: Date | null;
  holdoverFlag: boolean;
  holdoverLikelyToRenew: boolean;
}

export class CContractEvent implements Contract2EventsInputClone {
  id: string;
  ownerId: string;
  tenantId: string;
  assignmentFlag: boolean;
  assignmentRights: AssignmentRightsInputClone | null;
  capitalAllowance: Decimal | null;
  documents: CContractDocument[];
  earlyOccupationStartDate: Date | null;
  existingFitOutTreatment: ExistingFitOutTreatment | null;
  expansionFlag: boolean;
  expansionRights: ExpansionRightsInputClone | null;
  fitOut: FitOutInputClone;
  handoverCondition: HandoverCondition | null;
  permittedUse: PermittedUse | null;
  premises: Premise2Input;
  refusalFlag: boolean;
  refusalRights: RefusalRightsInputClone | null;
  renewFlag: boolean;
  renewalRights: CRenewalRights[];
  rentFreePeriods: RentFreePeriodInputClone[];
  rentReview: RentReviewInputClone[];
  rentType: ContractRentType;
  rentValidated: boolean;
  reviewFlag: boolean;
  saleAndRedevelopmentFlag: boolean;
  saleAndRedevelopmentRights: SaleAndRedevelopmentRightsInputClone | null;
  securityAmount: SecurityAmountInputClone;
  shareFlag: boolean;
  shareRights: ShareRightsInputClone | null;
  specificUse: SpecificUse | null;
  subletFlag: boolean;
  subletRights: SubletRightsInputClone | null;
  surrenderFlag: boolean;
  surrenderRights: SurrenderRightsInputClone | null;
  terminationDate: Date | null;
  terminationDescription: string;
  terminationReason: ContractTerminationReason | null;
  type: string;
  startDate: Date | null;
  endDate: Date | null;
  holdoverFlag: boolean;
  holdoverLikelyToRenew: boolean;

  constructor(input: Contract2Events | null) {
    this.id = input ? input.id : '';
    const landlordId = getCurrentLandlordEntityId();
    this.ownerId = input ? input.owner.id : landlordId ? landlordId : '';
    this.tenantId = input ? input.tenant.id : '';
    this.assignmentFlag = input ? input.assignmentFlag : false;
    this.assignmentRights = input && input.assignmentRights ? new CAssignmentRights(input.assignmentRights) : null;
    this.capitalAllowance = input && input.capitalAllowance ? new Decimal(input.capitalAllowance) : null;
    this.documents = input ? input.documents.map(document => new CContractDocument(document)) : [];
    this.earlyOccupationStartDate =
      input && input.earlyOccupationStartDate ? new Date(input.earlyOccupationStartDate) : null;
    this.existingFitOutTreatment = input ? input.existingFitOutTreatment : null;
    this.expansionFlag = input ? input.expansionFlag : false;
    this.expansionRights = input && input.expansionRights ? new CExpansionRights(input.expansionRights) : null;
    this.fitOut = new CFitOut(input ? input.fitOut : null);
    this.handoverCondition = input ? input.handoverCondition : null;
    this.permittedUse = input ? input.permittedUse : null;
    this.premises = new CPremise(input ? input.premises : null);
    this.refusalFlag = input ? input.refusalFlag : false;
    this.refusalRights = input && input.refusalRights ? new CRefusalRights(input.refusalRights) : null;
    this.renewFlag = input ? input.renewFlag : false;
    this.renewalRights = input ? input.renewalRights.map(right => new CRenewalRights(right)) : [];
    this.rentFreePeriods = input ? input.rentFreePeriods.map(freePeriod => new CRentFreePeriod(freePeriod)) : [];
    this.rentReview = input ? input.rentReview.map(review => new CRentReview(review)) : [new CRentReview(null)];
    this.rentType = input ? input.rentType : ContractRentType.Base;
    this.rentValidated = input ? input.rentValidated : false;
    this.reviewFlag = input ? input.reviewFlag : false;
    this.saleAndRedevelopmentFlag = input ? input.saleAndRedevelopmentFlag : false;
    this.saleAndRedevelopmentRights =
      input && input.saleAndRedevelopmentRights
        ? new CSaleAndRedevelopmentRights(input.saleAndRedevelopmentRights)
        : null;
    this.securityAmount = new CSecurityAmount(input ? input.securityAmount : null);
    this.shareFlag = input ? input.shareFlag : false;
    this.shareRights = input && input.shareRights ? new CShareRights(input.shareRights) : null;
    this.specificUse = input ? input.specificUse : null;
    this.subletFlag = input ? input.subletFlag : false;
    this.subletRights = input && input.subletRights ? new CSubletRights(input.subletRights) : null;
    this.surrenderFlag = input ? input.surrenderFlag : false;
    this.surrenderRights = input && input.surrenderRights ? new CSurrenderRights(input.surrenderRights) : null;
    this.terminationDate = input && input.terminationDate ? new Date(input.terminationDate) : null;
    this.terminationDescription = input ? input.terminationDescription : '';
    this.terminationReason = input ? input.terminationReason : null;
    this.type = input ? input.type : '';
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
    this.holdoverFlag = input && input.holdoverFlag ? input.holdoverFlag : false;
    this.holdoverLikelyToRenew = input && input.holdoverLikelyToRenew ? input.holdoverLikelyToRenew : false;
  }
  // holdoverFlag
  // holdoverLikelyToRenew
  static fragment() {
    return `
        id
        contract2 {
          id
        }
        owner {
          id
          name
        }
        tenant {
          id
          name
        }
        assignmentFlag
        assignmentRights {
          ${CAssignmentRights.fragment()}
        }
        capitalAllowance 
        documents {
          ${CContractDocument.fragment()}
        }
        earlyOccupationStartDate
        existingFitOutTreatment
        expansionFlag
        expansionRights {
          ${CExpansionRights.fragment()}
        }
        fitOut {
          ${CFitOut.fragment()}
        }
        handoverCondition
        insertedAt
        permittedUse
        premises {
          ${CPremise.fragment()}
        }
        refusalFlag
        refusalRights {
          ${CRefusalRights.fragment()}
        }
        renewFlag
        renewalRights {
          ${CRenewalRights.fragment()}
        }
        rentFreePeriods {
          ${CRentFreePeriod.fragment()}
        }
        rentReview {
          ${CRentReview.fragment()}
        }
        rentType
        rentValidated
        reviewFlag
        saleAndRedevelopmentFlag
        saleAndRedevelopmentRights {
          ${CSaleAndRedevelopmentRights.fragment()}
        }
        securityAmount {
          ${CSecurityAmount.fragment()}
        }
        shareFlag
        shareRights {
          ${CShareRights.fragment()}
        }
        specificUse
        subletFlag
        subletRights {
          ${CSubletRights.fragment()}
        }
        surrenderFlag
        surrenderRights {
          ${CSurrenderRights.fragment()}
        }
        terminationDate
        terminationDescription
        terminationReason
        type
        startDate
        endDate
        holdoverFlag
        holdoverLikelyToRenew
      `;
  }

  static async formatForAPI(event: CContractEvent | null) {
    if (!event) {
      throw new Error('Contract Event formatForAPI: event could not be determined. Format aborted.');
    }

    const {
      ownerId,
      tenantId,
      assignmentFlag,
      assignmentRights,
      capitalAllowance,
      documents,
      earlyOccupationStartDate,
      existingFitOutTreatment,
      expansionFlag,
      expansionRights,
      fitOut,
      handoverCondition,
      permittedUse,
      premises,
      saleAndRedevelopmentFlag,
      refusalFlag,
      refusalRights,
      renewFlag,
      renewalRights,
      rentFreePeriods,
      rentReview,
      rentType,
      rentValidated,
      reviewFlag,
      saleAndRedevelopmentRights,
      securityAmount,
      shareFlag,
      shareRights,
      specificUse,
      subletFlag,
      subletRights,
      surrenderFlag,
      surrenderRights,
      terminationDate,
      terminationDescription,
      terminationReason,
      type,
      startDate,
      endDate,
      holdoverFlag,
      holdoverLikelyToRenew,
    } = event;

    const params = {
      ownerId,
      tenantId,
      assignmentFlag,
      assignmentRights: assignmentRights ? CAssignmentRights.formatForAPI(assignmentRights) : null,
      capitalAllowance: capitalAllowance ? parseFloat(capitalAllowance.toString()) : null,
      documents: await Promise.all(documents.map(doc => CContractDocument.formatForAPI(doc))),
      earlyOccupationStartDate: convertDateToISO(earlyOccupationStartDate),
      existingFitOutTreatment,
      expansionFlag,
      expansionRights: expansionRights ? CExpansionRights.formatForAPI(expansionRights) : null,
      fitOut: CFitOut.formatForAPI(fitOut),
      handoverCondition,
      permittedUse,
      premises: CPremise.formatForAPI(premises),
      saleAndRedevelopmentFlag,
      refusalFlag,
      refusalRights: refusalRights ? CRefusalRights.formatForAPI(refusalRights) : null,
      renewFlag,
      renewalRights: renewalRights.map(right => CRenewalRights.formatForAPI(right)),
      rentFreePeriods: rentFreePeriods.map(freePeriod => CRentFreePeriod.formatForAPI(freePeriod)),
      rentReview: rentReview.map(review => CRentReview.formatForAPI(review)),
      rentType,
      rentValidated,
      reviewFlag,
      saleAndRedevelopmentRights: saleAndRedevelopmentRights
        ? CSaleAndRedevelopmentRights.formatForAPI(saleAndRedevelopmentRights)
        : null,
      securityAmount: CSecurityAmount.formatForAPI(securityAmount),
      shareFlag,
      shareRights: shareRights ? CShareRights.formatForAPI(shareRights) : null,
      specificUse,
      subletFlag,
      subletRights: subletRights ? CSubletRights.formatForAPI(subletRights) : null,
      surrenderFlag,
      surrenderRights: surrenderRights ? CSurrenderRights.formatForAPI(surrenderRights) : null,
      terminationDate: convertDateToISO(terminationDate),
      terminationDescription,
      terminationReason,
      type,
      startDate: convertDateToISO(startDate),
      endDate: convertDateToISO(endDate),
      holdoverFlag: holdoverFlag ?? false,
      holdoverLikelyToRenew: holdoverLikelyToRenew ?? false,
    };

    return params;
  }
}
