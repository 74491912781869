import 'components/PreviewTab/Discussion/Discussion.scss';
import { MessageBoxSize } from '..';
import Messages from './Messages';
import './MessagesBox.scss';

const MessagesBox = ({
  channelId,
  approvalId,
  messageBoxSize,
}: {
  channelId?: string;
  approvalId?: string;
  messageBoxSize: MessageBoxSize;
}) => {
  return (
    <div className="message-box-content">
      <Messages
        channelId={channelId}
        approvalId={approvalId}
        messageBoxSize={messageBoxSize}
      />
    </div>
  );
};

export default MessagesBox;
