import { ChartType, TooltipModel } from 'chart.js';
import {
  ContextAnalysisData,
  DASHBOARD_PAGE_SCOPE,
  PortfolioRevenueAnalysis,
  PropertyAnalysisData,
  PropertyRevenueAnalysis,
  RevenueAnalysisData,
  UnitAnalysisData,
} from 'common/api/dashboard';
import { getAmount, getBarWidth, getColour, getMaxAbsoluteVariance } from 'utils/tsHelper';
import { ColorNames } from 'utils/utils-colors';
import { addSpaceOrComma, getAmountWithCurrencySymbol, removeDecimals } from 'utils/utils-number';

const getPerformanceClass = (performance: number, isVariance: boolean) => {
  if (performance < 0) return `minus ${isVariance ? ColorNames.RED : ''}`;
  if (performance >= 0) return `plus ${isVariance ? ColorNames.GREEN : ''}`;
  return '';
};

const mapRows = (unitsData: UnitAnalysisData[]): string => {
  const htmlUnits = unitsData
    .filter(unit => {
      return parseFloat(unit.statsRevenue.variance) !== Number(0);
    })
    .sort((a, b) => parseFloat(b.statsRevenue.performance) - parseFloat(a.statsRevenue.performance))
    .map(unit => {
      const formattedVariance = removeDecimals(unit.statsRevenue.variance);
      const performanceNumber = parseFloat(unit.statsRevenue.performance);
      const performancePercentage = (performanceNumber * 100).toFixed(1);
      return `
          <li class="revenues">
            <div>
              <span class="bullet">.</span>
              ${unit.floorName} - ${unit.unitName} :
              <span class="item">HK$  ${formattedVariance}
                ${`<span class=" item ${getPerformanceClass(
                  performanceNumber,
                  true,
                )}">${performancePercentage}%</span>`}
              </span>
            </div>
          </li>
        `;
    });

  if (!htmlUnits.length) {
    return `<li class="revenues"> Empty list  </li>`;
  } else {
    return htmlUnits.join('');
  }
};

export const RevenueVariancePropertyTooltip =
  (data: PropertyRevenueAnalysis) =>
  ({ title: [title], dataPoints }: TooltipModel<ChartType>) => {
    const { propertyData, unitsData } = data;

    const index: number = dataPoints[0].dataIndex;

    const propertyStat: ContextAnalysisData = propertyData[index];

    const unitData: UnitAnalysisData[] = unitsData.filter(({ month }) => month === propertyStat.month);

    const {
      statsRevenue: {
        forecastRevenue,
        contractualRevenue,
        revenueOverPerformance: { variance: overPerformanceVariance },
        revenueUnderPerformance: { variance: underPerformanceVariance },
      },
    } = propertyStat;

    return `
    <div class="inner-html revenue-variance color-blue-dark">
      <h4 class="title">Revenue variance - ${title}</h4>

      <div class="revenues">

        <div>Contractual revenue : 
          ${getAmountWithCurrencySymbol(contractualRevenue)}
        </div>

        <div>Forecast revenue : 
          ${getAmountWithCurrencySymbol(forecastRevenue)}
        </div>

        <div class="m-t-xs" >
          Overperformance variance :
          <span class="${ColorNames.GREEN}">
            ${getAmountWithCurrencySymbol(overPerformanceVariance)}
          </span>
        </div>

        <div>
          Underperformance variance :
          <span class="${ColorNames.RED}">
            ${getAmountWithCurrencySymbol(underPerformanceVariance)}
          </span>
        </div>

      </div>

      <ul class="units-list">
        ${mapRows(unitData)}
      </ul>
    </div>
    `;
  };

interface PortfolioHTMLData {
  overPerformanceVariance: string;
  underPerformanceVariance: string;
  variance: string | null | undefined;
  underPerformanceBarWidth: number;
  overPerformanceBarWidth: number;
  propertyId: string;
  propertyName: string;
  area: string;
}

const getPortfolioDisplayData = (propertyData: PropertyAnalysisData, maxVariance: number): PortfolioHTMLData => {
  const {
    revenueOverPerformance: { variance: overPerformanceVariance },
    revenueUnderPerformance: { variance: underPerformanceVariance },
  } = propertyData.statsRevenue || {
    revenueOverPerformance: {},
    revenueUnderPerformance: {},
  };

  let variance: string | number =
    (overPerformanceVariance ? parseFloat(overPerformanceVariance) : 0) +
    (underPerformanceVariance ? parseFloat(underPerformanceVariance) : 0);

  variance = variance.toFixed(2);

  const underPerformanceBarWidth = getBarWidth(maxVariance, underPerformanceVariance);

  const overPerformanceBarWidth = getBarWidth(maxVariance, overPerformanceVariance);
  return {
    propertyId: propertyData.propertyId,
    propertyName: propertyData.propertyName,
    area: propertyData.statsArea.area,
    overPerformanceVariance,
    underPerformanceVariance,
    variance,
    underPerformanceBarWidth,
    overPerformanceBarWidth,
  };
};

export const RevenueVariancePortfolioTooltip =
  (data: PortfolioRevenueAnalysis, tab: string) =>
  ({ title: [title], dataPoints }: TooltipModel<ChartType>) => {
    const index: number = dataPoints[0].dataIndex;
    const month: string = data.portfolioData[index].month;

    const properties: PropertyAnalysisData[] = data.propertiesData.filter(item => month === item.month);

    const maxVariance: number = getMaxAbsoluteVariance(properties);

    const propertiesFormated: PortfolioHTMLData[] = properties.map(property =>
      getPortfolioDisplayData(property, maxVariance),
    );

    return `
    <div class="inner-html revenue-variance color-blue-dark">
        <h4 class="title">Revenue Forecast - ${title}</h4>

        <div class="table-container">
            <table class="ui table dashboard-table revenue-variance">
                <thead>
                    <tr>
                      <th>Property</th>
                      <th>Total area (sqft, gross)</th>
                      <th>Variance (HK$)</th>
                      <th>Variance Details (HK$)</th>
                      <th>Under performance (HK$)</th>
                      <th>Over performance (HK$)</th>
                    </tr>
                </thead>

                <tbody>
                  ${propertiesFormated
                    .map(property => {
                      return `
                    <tr>
                      <td>
                        <a href="?context=${DASHBOARD_PAGE_SCOPE.PROPERTY}&propertyId=${property.propertyId}&propertyName=${
                          property.propertyName
                        }&tab=${tab}" target="_blank">
                        ${property.propertyName}
                        </a>
                      </td>
                      <td class="align-right">${addSpaceOrComma(property.area, false)}
                      </td>
                      <td class="${getColour(property.variance)}">
                        ${getAmount(property.variance)}
                      </td>
                      <td class="bar-cell">
                        <div class="bar-container">
                          <div class="axis-line"></div>
                          <div class="bar">
                            <div class="red-bar" style="width: ${property.underPerformanceBarWidth}%"></div>
                          </div>
                          <div class="bar">
                            <div class="green-bar" style="width: ${property.overPerformanceBarWidth}%"></div>
                          </div>
                        </div>
                      </td>
                      <td class="${getColour(property.underPerformanceVariance)}">
                        ${getAmount(property.underPerformanceVariance)}
                      </td>
                      <td class="${getColour(property.overPerformanceVariance)}">
                        ${getAmount(property.overPerformanceVariance)}
                      </td>
                    </tr>
                    `;
                    })
                    .join('')}
                </tbody>
            </table>
        </div>
    </div>
    `;
  };
