import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TabContainer from 'components/TabContainer';
import { getProperty, getStackingPlanTransactions, getStackingPlans } from 'common/api/dashboard/stackingPlan';
import { STACKING_PLAN_VIEW_OPTIONS } from 'utils/types/stackingPlan';
import StackingPlanGraph from './Graph';
import StackingPlanLegendFilter from './LegendFilter';
import StackingPlanFilter from './MainFilter';
import SideMenu from './StackingPlanSideSections';
import './StackingPlan.scss';

const StackingPlan = () => {
  const dispatch = useAppDispatch();
  const { activeProperty, floors, activeView, monitoringDate } = useAppSelector(
    (state: RootState) => state.stackingPlan,
  );

  useEffect(() => {
    if (activeProperty && activeProperty.id) {
      // Get Stacking Plan for property
      dispatch(
        getStackingPlans({
          propertyId: activeProperty.id,
          date: monitoringDate,
        }),
      );
      // Get Units Detail for floors
      dispatch(getProperty({ id: activeProperty.id }));

      // Get on going transactions
      dispatch(
        getStackingPlanTransactions({
          propertyId: activeProperty.id,
          date: monitoringDate,
        }),
      );
    }
  }, [activeProperty]);

  useEffect(() => {
    if (activeProperty && activeProperty.id) {
      dispatch(
        getStackingPlans({
          propertyId: activeProperty.id,
          date: monitoringDate,
        }),
      );
    }
  }, [monitoringDate]);

  useEffect(() => {
    scrollToBottom();
  }, [floors]);

  /**
   * Make Graph Scroll to bottom
   */
  const scrollToBottom = () => {
    setTimeout(() => {
      const div = document.getElementById('graph-scroll-end-id');
      if (div) {
        div.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }, 1000);
  };

  const stackingPlanView = (
    <Grid className="stacking-plan-dashboard m-none">
      <Grid.Row>
        <Grid.Column
          width={12}
          className="p-l-none"
        >
          <div className="stacking-plan-sections p-xl border-sm-grayish-magenta-light">
            <StackingPlanLegendFilter />

            <div className="graph-scroll">
              <StackingPlanGraph />
              <div id="graph-scroll-end-id"></div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column
          width={4}
          className="p-r-none"
        >
          <div className="stacking-plan-sections p-xl side-menu-scroll border-sm-grayish-magenta-light">
            <SideMenu />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );

  return (
    <TabContainer
      tabTitle="Stacking Plan"
      tabToolbar={<StackingPlanFilter />}
    >
      {activeView === STACKING_PLAN_VIEW_OPTIONS.Table ? <></> : stackingPlanView}
    </TabContainer>
  );
};

export default StackingPlan;
