import { ReactNode } from 'react';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardBody from '../CardBody';
import CardHeader from '../CardHeader';
import { DefaultCardT, DefaultColumnT } from '../CardViewDefaultTypes';
import './Card.scss';

export interface CardConfiguration<CardT> {
  header: {
    cardMenuItems: (cardElement: CardT) => DropdownMenuItem[];
    headerTemplate: (cardElement: CardT) => ReactNode;
    onHeaderClick?: (cardElement: CardT) => void;
  };
  bodyTemplate: (cardElement: CardT) => ReactNode;
  onBodyClick?: (cardElement: CardT) => void;
  onClick: (cardElement: CardT) => void;
}

interface CardProps<ColumnT, CardT> {
  cardElement: CardT & DefaultCardT;
  columnCategory: ColumnT & DefaultColumnT;
  cardConfiguration: CardConfiguration<CardT>;
}

function Card<ColumnT, CardT>({ cardElement, columnCategory, cardConfiguration }: CardProps<ColumnT, CardT>) {
  const hexColor = columnCategory?.color;
  return (
    <div
      className={`component-card-container clickable border-sm-grayish-magenta-light`}
      onClick={() => cardConfiguration.onClick && cardConfiguration.onClick(cardElement)}
      data-test="card-container"
    >
      <CardHeader
        hexColor={hexColor}
        dropdownItems={cardConfiguration.header.cardMenuItems(cardElement)}
        headerTemplate={cardConfiguration.header.headerTemplate(cardElement)}
        onClick={() => cardConfiguration.header.onHeaderClick && cardConfiguration.header.onHeaderClick(cardElement)}
      />
      <CardBody
        template={cardConfiguration.bodyTemplate(cardElement)}
        onClick={() => cardConfiguration.onBodyClick && cardConfiguration.onBodyClick(cardElement)}
      />
    </div>
  );
}

export default Card;
