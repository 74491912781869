import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Editor } from '@tinymce/tinymce-react';
import { GuidelinesBase } from 'common/_classes';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Editor as CoreEditor } from 'tinymce';
import { EditorEvents } from 'store/editor/setup';

export enum GuidelineType {
  GENERAL = 'generalGuidance',
  VALUATION = 'valuationGuidance',
  EXPLANATORY = 'explanatoryNotes',
  INTERNAL = 'internalNotes',
}

export const getTitleFromGuidelineType = (value: GuidelineType) => {
  switch (value) {
    case GuidelineType.GENERAL:
      return 'General guidance';
    case GuidelineType.VALUATION:
      return 'Valuation guidance';
    case GuidelineType.INTERNAL:
      return 'Internal notes';
    case GuidelineType.EXPLANATORY:
      return 'Explanatory notes';
    default:
      return 'Note';
  }
};

export interface UpdateGuidelineTypePayload {
  type: GuidelineType;
  value: string;
}

export type OnUpdateGuidelineTypeAction = ActionCreatorWithPayload<UpdateGuidelineTypePayload>;

export interface GuidelinesEditorProps {
  guidelines: GuidelinesBase;
  guidelineType: GuidelineType;
  setEditorLoaded: (value: boolean) => void;
  onUpdateGuidelineType: OnUpdateGuidelineTypeAction;
}

/*
 * TODO: architecture decision to review:
 *   GuidelineEditor get all the 'guidelines' content and then we select only one
 *   with 'guidelineType'. and in the output 'onUpdateGuidelineType' we basically
 *   indicate that the 'content' that has been updated correspond to the 'guidelineType'
 *   to the parent module.
 *   What could be done is to move this 'guidelineType' out of this module and instead
 *   of all 'guidelines' only get the one used here.
 */
const GuidelineEditor = ({
  guidelines,
  guidelineType,
  setEditorLoaded,
  onUpdateGuidelineType,
}: GuidelinesEditorProps): JSX.Element => {
  const dispatch = useDispatch();

  const onEditorChange = useCallback(
    (content: string) => dispatch(onUpdateGuidelineType({ type: guidelineType, value: content })),
    [dispatch, guidelineType],
  );

  const getTextValue = (): string => {
    if (guidelines !== null) {
      return guidelines[guidelineType] || '';
    }
    return '';
  };

  const onAlterFocusClass = (addClass: boolean) => (): void => {
    const editor = document.getElementsByClassName('tox-tinymce');
    if (editor) {
      if (addClass) {
        editor[0].classList.add('focused');
      } else {
        editor[0].classList.remove('focused');
      }
    }
  };

  const focusOnInit = (editor: CoreEditor) => {
    editor.on(EditorEvents.Init, () => {
      editor.focus();
    });
  };

  return (
    <Editor
      id="editor-guideline"
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
      value={getTextValue()}
      init={{
        setup: focusOnInit,
        menubar: false,
        statusbar: false,
        plugins: 'lists code',
        toolbar:
          '| formatselect | ' +
          'bold underline italic forecolor backcolor bullist numlist alignleft aligncenter ' +
          'alignright alignjustify' +
          'codesample code removeformat',
        content_style:
          'body { font-family:Urbanist; font-size:14px; color: #041630; font-weight: 400; caret-color: #E69701}',
      }}
      onEditorChange={onEditorChange}
      onLoadContent={() => setEditorLoaded(true)}
      onFocus={onAlterFocusClass(true)}
      onBlur={onAlterFocusClass(false)}
    />
  );
};

export default GuidelineEditor;
