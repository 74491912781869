import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* upsert = update or insert */
export enum EditorEvent {
  NONE = 'none',
  UPSERT_PARAM = 'upsertParam',
  DELETE_PARAM = 'delParam',
  UPDATE_PARAM = 'updateParam',
}

export interface EditorState {
  /* This will serve as an watchdog variable. You can call useEffect and have this as
   * a dependency, then you manipulate the editor on behalf of other components */
  /* TODO: reimplement this as a queue */
  event: EditorEvent;

  /* Redux store keeps its state updated to the editor's state by listening to
   * events configured in setup.ts */

  /* This is the full content of the editor as HTML. Useful for the `Show Code`
  sidebar tab, but could also be relevant for other features */
  content: string;

  /* This is the currently selected node in the editor, serialized as a string,
  in other words nodeToString(editor.selection.getNode()) */
  selectedNode: string;

  /* This is the currently selected content (editor.selection.getContent()) */
  selectedContent: string;
}

const initialState: EditorState = {
  event: EditorEvent.NONE,
  /* When the editor loads, this should be overridden with its initial contents.
  Also, listener `onEditorChange` used during the editor setup will cause new content
  to be dispatched
  */
  content: '',
  selectedNode: '',
  selectedContent: '',
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    receiveEvent: (state, action: PayloadAction<EditorEvent>) => {
      state.event = action.payload;
    },
    receiveContent: (state, action: PayloadAction<string>) => {
      state.content = action.payload;
    },
    receiveSelectedNode: (state, action: PayloadAction<string>) => {
      state.selectedNode = action.payload;
    },
    receiveSelectedContent: (state, action: PayloadAction<string>) => {
      state.selectedContent = action.payload;
    },
  },
});

/* Action creators */

export const { receiveEvent, receiveContent, receiveSelectedNode } = editorSlice.actions;

/* Helpers */

const xmlSerializer = new XMLSerializer();
export const nodeToString = (node: Element): string => xmlSerializer.serializeToString(node);

export default editorSlice.reducer;
