import { toast } from 'react-toastify';
import { Button, Grid, Radio } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ClauseIndex from 'atoms/ClauseIndex';
import InputField from 'atoms/FormField/Input';
import ConditionsTemplate from 'components/ConditionsTemplate';
import NodeAlert from 'components/Editor/NodeAlert';
import {
  resetNodeConditions,
  setConditionsInput,
  setIteration,
  setShowCondition,
  updateForm,
  updateHandleEvent,
  updateNodeConditions,
} from 'store/nodes/nodesSlice';
import Conditions from 'common/model/Conditions';
import NodeType from 'common/model/NodeType';
import { updateNode } from 'common/api/nodes';
import { checkIfValidNodeConditions } from 'utils/tsValidator';
import { EventHandlerNode } from 'utils/types/nodes';
import DuplicateNode from '../DuplicateNode';
import './Clauses.scss';

const Clauses = () => {
  const dispatch = useAppDispatch();

  const { activeNode, node, showCondition, nodeConditions, activeClauseIndex, nodeError } = useAppSelector(
    (state: RootState) => state.nodes,
  );
  const {
    activeProvision: { id: provisionId },
  } = useAppSelector((state: RootState) => state.provisionDetail);
  const { parameterGroups, parameterTables, parameters } = useAppSelector((state: RootState) => state.multiLevelMenu);

  const handleFormUpdate = (key: string, value: any) => {
    dispatch(updateForm({ key, value }));
  };

  const iterable = node?.iterable;

  const handleSubmit = () => {
    if (!node) return;

    if (checkIfValidNodeConditions(nodeConditions)) {
      dispatch(setConditionsInput());
      dispatch(updateHandleEvent(EventHandlerNode.UPDATE_CONDITION));
      dispatch(updateNode());
    } else {
      toast.error('There are incomplete conditions. Please, check it');
    }

    if (iterable) {
      dispatch(setIteration(iterable));
    }
  };

  if (!activeNode && !nodeError) return <div>Loading...</div>;

  if (nodeError) {
    return <NodeAlert message="Alert, the node no longer exists in the database" />;
  }

  if (activeNode?.provision?.id !== provisionId) {
    return <NodeAlert message="Alert, fix the integrity of the node" />;
  }

  if (node?.type !== NodeType.Clause) return <div>Select a Clause</div>;

  return (
    <div
      className="clause-container"
      data-test="clause-container"
    >
      <Grid.Row>
        <ClauseIndex
          numbering={activeClauseIndex}
          hasCondition={node?.conditions?.length !== 0}
          iterable={iterable}
        />
      </Grid.Row>

      <Grid.Row>
        <InputField
          value={node.name}
          fieldKey="name"
          dataTest="clause-name"
          onChange={handleFormUpdate}
        />
      </Grid.Row>
      <DuplicateNode
        nodeId={activeNode?.id}
        type={NodeType.Clause}
        provisionId={String(provisionId)}
      />
      <Grid.Row>
        <h3>Iteration </h3>
        <Radio
          className="m-l-sm"
          data-test="clause-iteration-button"
          toggle
          checked={iterable}
          onChange={(_event, { checked }) => handleFormUpdate('iterable', checked)}
        />
      </Grid.Row>

      <Grid.Row>
        <h3>Condition</h3>
        <Radio
          className="m-l-sm"
          data-test="clause-condition-button"
          toggle
          checked={showCondition}
          onChange={(_event, data) => {
            if (data.checked === undefined) return;
            dispatch(setShowCondition(data.checked));
            dispatch(resetNodeConditions());
          }}
        />
      </Grid.Row>

      <div className="flex">
        {showCondition && (
          <ConditionsTemplate
            parameters={parameters}
            parameterGroups={parameterGroups}
            parameterTables={parameterTables}
            value={nodeConditions}
            onChange={(value: Conditions[]) => dispatch(updateNodeConditions(value))}
          />
        )}
      </div>

      <Grid.Row>
        <Button
          className="btn grey-bg"
          data-test="save-button"
          onClick={handleSubmit}
        >
          SAVE
        </Button>
      </Grid.Row>
    </div>
  );
};

export default Clauses;
