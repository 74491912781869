import React from 'react';
import './ProgressBar.scss';

/**
 * Progress Bar. Commonly used on body of Card Content
 *
 *
 * @param props.label string Label to describes what progress show
 * @param props.currentProgress Number REQUIRED Set the progress
 * @param props.totalProgress Number REQUIRED Set the total of progress
 */
const ProgressBar = (props: ProgressBarProps) => {
  const { showPercentage, label, currentProgress, totalProgress, ...rest } = props;

  if (!totalProgress || totalProgress === 0) {
    console.warn('Error with numbers used as props on ProgressBar Component');
    return null;
  }

  const percentage = Math.round((currentProgress / totalProgress) * 100) + '%';

  const topProgress = showPercentage
    ? `${percentage} (${currentProgress} / ${totalProgress})`
    : `${currentProgress}/${totalProgress}`;

  const unitLabel = label || '';

  return (
    <div
      className="progress-bar-container"
      {...rest}
    >
      <span className="statistics">{`${topProgress} ${unitLabel}`}</span>
      <span className="progress-template">
        <span
          className="progress-template-bar bg-green"
          style={{ width: percentage }}
        ></span>
      </span>
    </div>
  );
};

interface ProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
  showPercentage?: boolean;
  label?: string;
  currentProgress: number;
  totalProgress: number;
}

export default ProgressBar;
