import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ExportPropertyQueryVariables {
  propertyId: string;
}

const EXPORT_PROPERTY = (propertyId: any) => {
  if (!propertyId) throw new Error('Export Property: propertyId is required');

  const variables: ExportPropertyQueryVariables = {
    propertyId,
  };

  return {
    query: gql`
      query ($propertyId: ID!) {
        exportProperty(propertyId: $propertyId)
      }
    `,
    variables,
  };
};

interface ExportPropertyArgs {
  id: string;
}

export const exportProperty = createAsyncThunk('properties/exportProperty', async ({ id }: ExportPropertyArgs) => {
  const response = await client.query(EXPORT_PROPERTY(id));
  return response;
});
