import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { onPortfolioValueChange } from 'store/portfolio/portfolioDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION } from 'utils/UI';
import './PortfolioForm.scss';

const PortfolioForm = ({ mode }: { mode: MODE_OPTIONS }) => {
  const dispatch = useAppDispatch();
  const { propertiesList } = useAppSelector((state: RootState) => state.portfoliosListing);
  const { activePortfolio } = useAppSelector((state: RootState) => state.portfolioDetail);

  const { id, name, propertyIds } = activePortfolio || {};

  interface PropertyListOption extends Omit<DROPDOWN_OPTION, 'text'> {
    text?: string;
  }

  const propertiesListOption: PropertyListOption[] = propertiesList.map(({ id, buildingName }, index) => {
    return { key: index, text: buildingName, value: id };
  });

  const onValueChange = (key: string, value: string | string[]) => {
    dispatch(onPortfolioValueChange({ key, value, id }));
  };

  return (
    <Form>
      <Grid className="portfolio-form">
        <Grid.Row className="portfolio-detail border-sm-grayish-magenta-light color-blue-very-dark-grayish p-t-sm p-b-sm">
          <Grid.Column width={5}>
            <InputField
              required={true}
              label="Name"
              placeholder="Enter Name"
              dataTest="portfolio-name"
              value={name}
              onChange={(key, value) => onValueChange(key, value)}
              fieldKey="name"
              disabled={mode !== MODE_OPTIONS.EDIT}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <SelectField
              label="List of properties"
              dataTest="select-properties"
              placeholder="Select Properties"
              fieldKey="propertyIds"
              value={propertyIds}
              options={propertiesListOption}
              disabled={mode !== MODE_OPTIONS.EDIT}
              onChange={(key, value) => onValueChange(key, value)}
              multiple={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default PortfolioForm;
