/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import { updateInput, updateResetPassword } from 'store/authentication/authSlice';
import { adminSignIn, getAdminProfile, getProfile, signIn } from 'common/api/authentication';
import { invalidEmailText } from 'utils/tsHelper';
import { checkIfAdminURL } from 'utils/utils-admin';
import { signInValid } from 'utils/utils-credential';
import { Icons } from 'utils/utils-icons';
import { emailIsValid } from 'utils/utils-string';

const page = document.getElementById('root');

interface LocationState {
  previousUrl?: string;
}

const SignInForm = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { email, password } = useAppSelector((state: RootState) => state.auth);
  const [type, setType] = useState<InputFieldType>(InputFieldType.PASSWORD);
  const [interactionDetected, setInteractionDetected] = useState<boolean>(false);
  const checkIfValidEmail = emailIsValid(email) || email.length === 0;

  const onSignIn = (): void => {
    if (checkIfAdminURL()) {
      dispatch(adminSignIn()).then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(getAdminProfile());
          navigate('/home');
        }
      });
    } else {
      dispatch(signIn()).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(getProfile());
          const defaultEntity = response.payload.data.login.user.defaultEntity;
          if (defaultEntity === null) {
            navigate('/welcome');
          } else {
            // NOTE: previousUrl was added in isAuthenticated function found in "src\routes\redirects.tsx"
            const { previousUrl } = (location.state as LocationState) || {};
            navigate(previousUrl || '/home');
          }
        }
      });
    }
  };

  const notifyInteraction = useCallback(() => {
    setInteractionDetected(true);
  }, []);

  useEffect(() => {
    if (page) {
      if (!interactionDetected) {
        page.addEventListener('click', notifyInteraction);
      } else {
        page.removeEventListener('click', notifyInteraction);
      }
    }
  }, [interactionDetected, notifyInteraction]);

  const onForgotPassword = (): void => {
    dispatch(updateResetPassword());
    navigate('/reset-password');
  };

  return (
    <Form
      className={`auth-form border-sm-grayish-magenta-light ${checkIfAdminURL() && 'admin-height'}`}
      onSubmit={onSignIn}
    >
      <div className="content">
        <h1
          className="header"
          data-test="page-title-descriptor"
        >
          Log in to Sericin {checkIfAdminURL() && 'Admin'}
          <Icon icon={Icons.CircleDownFilled} />
        </h1>
        <Form.Field>
          <label>
            Email <span className="required">*</span>
          </label>
          <Input
            className={!checkIfValidEmail ? 'input-error-field' : ''}
            value={email}
            placeholder="Enter your email..."
            type={InputFieldType.EMAIL}
            onChange={e => dispatch(updateInput({ type: 'email', value: e.target.value }))}
            required={true}
            data-test="user-email-login"
          />
          {!checkIfValidEmail && (
            <p className="validation-message error-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              {invalidEmailText}
            </p>
          )}
        </Form.Field>
        <Form.Field>
          <label>
            Password <span className="required">*</span>
          </label>
          <Input
            value={password}
            icon={
              <Icon
                icon={type === InputFieldType.PASSWORD ? Icons.Eye : Icons.EyeSlash}
                onClick={() =>
                  setType(type === InputFieldType.PASSWORD ? InputFieldType.TEXT : InputFieldType.PASSWORD)
                }
              />
            }
            placeholder="Enter your password..."
            type={type}
            onChange={e => dispatch(updateInput({ type: 'password', value: e.target.value }))}
            required={true}
            data-test="user-password-login"
          />
          <span
            className="input-bottom"
            data-test="forgot-password-button"
            onClick={onForgotPassword}
          >
            Forgot Password?
          </span>
        </Form.Field>
        <Button
          className="login-btn height-2_857 font-600 text-center"
          type="submit"
          disabled={interactionDetected && !signInValid(email, password)}
          data-test="login-button"
        >
          LOG IN
          <Icon
            icon={Icons.ArrowDownRight}
            rotate={4}
            className="width-1_143 height-1_143"
          />
        </Button>
      </div>
    </Form>
  );
};

export default SignInForm;
