import { EntityPickName } from 'common/_classes';
import MProvision from 'common/model/Provision';
import { ProvisionCategoryPickName, ProvisionContentOnlyDocTypeId } from 'common/api/provisions';
import { OmitMultiple } from 'utils/UI';

//TODO ProvisionListingClone should be split into 3 or 4 different types and must be derived from Provision.
// We need to clean and split this into different interfaces, all used in different context as :
//  - in API listProvisions() is using LIST_PROVISIONS, that have a restricted description of attributes of Provision
//  - in API listProvisionsAndCategories() is using LIST_PROVISIONS_AND_CATEGORIES, that have a large description of attributes of Provision
//  - in PolicyProvision and TransactionProvision interface ProvisionListingClone is used, and yet with a third description of attributes of Provision
//    see GET_TRANSACTION_PROVISIONS and LIST_POLICY_PROVISIONS
export interface IProvisionListingClone
  extends OmitMultiple<
    Pick<
      MProvision,
      | 'id'
      | 'name'
      | 'index'
      | 'isStructure'
      | 'language'
      | 'jurisdiction'
      | 'useType'
      | 'entity'
      | 'contents'
      | 'provisionCategory'
    >,
    'id' | 'index' | 'language' | 'jurisdiction' | 'useType' | 'entity' | 'contents' | 'provisionCategory'
  > {
  id?: string;
  index: number | null;
  language: string | null;
  jurisdiction: string | null;
  useType: string | null;
  entity?: EntityPickName;
  contents: ProvisionContentOnlyDocTypeId[];
  provisionCategory: ProvisionCategoryPickName;
}

export class ProvisionListingClone implements IProvisionListingClone {
  id?: string;
  name: string;
  index: number | null;
  isStructure: boolean;
  language: string | null;
  jurisdiction: string | null;
  useType: string | null;
  entity?: EntityPickName;
  contents: ProvisionContentOnlyDocTypeId[];
  provisionCategory: ProvisionCategoryPickName;

  constructor(input: IProvisionListingClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.isStructure = input.isStructure;
    this.language = input.language;
    this.jurisdiction = input.jurisdiction;
    this.useType = input.useType;
    this.entity = input.entity;
    this.contents = input.contents;
    this.provisionCategory = input.provisionCategory;
  }

  static fragments(withContent?: boolean): string {
    return `
      id
      name
      index
      isStructure
      language
      jurisdiction
      useType
      entity {
        name
      }
      contents {
        documentTypeId
        ${withContent ? 'content' : ''}
      }
      provisionCategory {
        id
        name
      }
    `;
  }
}
