import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { MemberSliceState } from 'store/members/membersSlice';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListMembersQueryVariables {
  currentLandlordEntityId: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  memberFilter: {
    search?: string;
  };
}

export const LIST_MEMBERS = (search: string | null, first?: number, last?: number, after?: string, before?: string) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List Members: currentLandlordEntityId is required');

  const variables: ListMembersQueryVariables = {
    currentLandlordEntityId,
    first,
    last,
    after,
    before,
    memberFilter: {
      search: search || undefined,
    },
  };

  return {
    query: gql`
      query (
        $currentLandlordEntityId: ID!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $memberFilter: MemberFilter
      ) {
        listMembers(
          currentLandlordEntityId: $currentLandlordEntityId
          first: $first
          last: $last
          after: $after
          before: $before
          memberFilter: $memberFilter
        ) {
          edges {
            cursor
            node {
              id
              user {
                id
                photoUrl
                email
                firstName
                lastName
                phoneNumber
                countryCode
              }
              role {
                id
                name
                approvalLevel
              }
              entity {
                id
                name
              }
              status
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listMembers = createAsyncThunk(
  'members/listMembers',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const state = getState() as RootState;
    const members: MemberSliceState = state.members;
    const search: string | null = members.search;

    const response = await client.query(LIST_MEMBERS(search, first, last, after, before));

    return response;
  },
);
