import Decimal from 'decimal.js';
import AssignmentRights from 'common/model/AssignmentRights';
import AssignmentRightsInput from 'common/model/AssignmentRightsInput';
import LandlordApprovalType from 'common/model/LandlordApprovalType';
import RightRestriction from 'common/model/RightRestriction';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';

export interface AssignmentRightsInputClone
  extends Omit<AssignmentRightsInput, 'wholeOrPart' | 'premisePercentage' | 'restrictedTo' | 'landlordApproval'> {
  wholeOrPart: WholeOrPartOptions | null;
  premisePercentage: Decimal | null;
  restrictedTo: RightRestriction | null;
  landlordApproval: LandlordApprovalType | null;
}

export class CAssignmentRights implements AssignmentRightsInputClone {
  wholeOrPart: WholeOrPartOptions | null;
  premisePercentage: Decimal | null;
  restrictedTo: RightRestriction | null;
  landlordApproval: LandlordApprovalType | null;

  constructor(input: AssignmentRights | null) {
    this.wholeOrPart = input ? input.wholeOrPart : null;
    this.premisePercentage = input && input.premisePercentage ? new Decimal(input.premisePercentage) : null;
    this.restrictedTo = input ? input.restrictedTo : null;
    this.landlordApproval = input ? input.landlordApproval : null;
  }

  static fragment() {
    return `
        wholeOrPart
        premisePercentage
        restrictedTo
        landlordApproval
    `;
  }

  static formatForAPI(right: CAssignmentRights) {
    return {
      wholeOrPart: right.wholeOrPart ? right.wholeOrPart : null,
      premisePercentage: right.premisePercentage ? parseFloat(right.premisePercentage.toString()) : null,
      restrictedTo: right.restrictedTo ? right.restrictedTo : null,
      landlordApproval: right.landlordApproval ? right.landlordApproval : null,
    };
  }
}
