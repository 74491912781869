import RefusalRightPriority from 'common/model/RefusalRightPriority';
import RefusalRightRentAssessmentType from 'common/model/RefusalRightRentAssessmentType';
import RefusalRightsValuationData from 'common/model/RefusalRightsValuationData';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';
import { CValuationDataPremises2 } from './ValuationDataPremises';

export class CRefusalRightsValuationData {
  exercisableFrom: Date | null;
  reofferBeforeLeasingAtLowerRent: boolean;
  wholeOrPart: WholeOrPartOptions;
  rentAssessment: RefusalRightRentAssessmentType;
  priority: RefusalRightPriority[];
  premises: CValuationDataPremises2;

  constructor(input: RefusalRightsValuationData) {
    this.exercisableFrom = input && input.exercisableFrom ? new Date(input.exercisableFrom) : null;
    this.reofferBeforeLeasingAtLowerRent = input.reofferBeforeLeasingAtLowerRent;
    this.wholeOrPart = input.wholeOrPart;
    this.rentAssessment = input.rentAssessment;
    this.priority = input.priority;
    this.premises = new CValuationDataPremises2(input.premises);
  }

  static fragment() {
    return `
        exercisableFrom
        reofferBeforeLeasingAtLowerRent
        wholeOrPart
        rentAssessment
        priority
        premises {
          ${CValuationDataPremises2.fragment()}
        }
    `;
  }
}
