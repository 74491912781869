import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Badge, { BadgeColor } from 'atoms/Badge';
import TableReactPrime from 'atoms/TableReactPrime';
import TabContainer from 'components/TabContainer';
import { createNewEntity } from 'store/entities/entityDetailSlice';
import MemberStatus from 'common/model/MemberStatus';
import { updateDefaultEntity } from 'common/api/authentication';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { EntityRow, entityColumnConfig } from './EntitiesTableConfig';
import './Entities.scss';

const EntitiesTab = () => {
  const dispatch = useAppDispatch();
  const { members, defaultEntity } = useAppSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  // @ts-ignore
  const entityUser = defaultEntity ? defaultEntity.id : defaultEntity;

  const membershipList: EntityRow[] = members.map(entity => new EntityRow(entity));

  const pendingInvite = membershipList.find(item => item.status === MemberStatus.Invited);

  const onSelectionChange = (isChecked: boolean, entityId: string) => {
    // @ts-ignore
    if (isChecked) dispatch(updateDefaultEntity({ entityId }));
  };

  const onCreateNew = () => {
    dispatch(createNewEntity(null));
    navigate(`/landlord-entities/create?step=1`);
  };

  return (
    <TabContainer
      tabTitle={'Entities'}
      tabToolbar={
        <Button
          className="btn grey-bg"
          onClick={() => onCreateNew()}
        >
          CREATE ENTITY
        </Button>
      }
      mode={MODE_OPTIONS.READ}
      data-test="profile-entities-tab"
    >
      <div>
        {pendingInvite && (
          <Badge
            badgeColor={BadgeColor.DARK_ORANGE}
            className="m-b-l"
          >
            You have pending invite(s), click on the action button to accept or decline
          </Badge>
        )}
        <TableReactPrime
          isFetching={false}
          content={membershipList}
          columnConfig={entityColumnConfig(entityUser, onSelectionChange, defaultEntity)}
          dataTest="entity-table"
          noRecordMessage="No entity accessible at the moment"
        />
      </div>
    </TabContainer>
  );
};

export default EntitiesTab;
