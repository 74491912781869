import Amendment from 'common/model/Amendment';
import ThreadType from 'common/model/ThreadType';
import TransactionMessage from 'common/model/TransactionMessage';
import User from 'common/model/User';
import { OmitMultiple } from 'utils/UI';

type TTransactionMessageClone = OmitMultiple<TransactionMessage, 'transactionChannel' | 'updatedAt'>;

export class TransactionMessageClone implements TTransactionMessageClone {
  id: string;
  message: string;
  flag: boolean;
  amendmentFlag: boolean;
  amendmentDeletedFlag: boolean;
  thread: ThreadType;
  amendment: Amendment;
  user: User;
  edited: boolean;
  insertedAt: Date;

  constructor(input: TransactionMessageClone) {
    this.id = input.id;
    this.message = input.message;
    this.flag = input.flag;
    this.amendmentFlag = input.amendmentFlag;
    this.amendmentDeletedFlag = input.amendmentDeletedFlag;
    this.thread = input.thread;
    this.amendment = input.amendment;
    this.user = input.user;
    this.edited = input.edited;
    this.insertedAt = input.insertedAt;
  }

  static fragments(): string {
    return `
        id
        message
        flag
        amendmentFlag
        amendmentDeletedFlag
        thread
        amendment {
           id
        }
        user {
          id
          firstName
          lastName
        }
        edited
        insertedAt
    `;
  }
}
