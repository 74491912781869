import { Form, Grid } from 'semantic-ui-react';
import SectionComponent from 'layouts/SectionComponent';
import { ModeProps } from 'common/api/miscellaneous';
import FilterFloorPlan from './FilterFloorPlan';
import FloorPlan from './FloorPlan';
import MainDescription from './MainDescription';
import SpacesAndUnits from './SpacesAndUnits';
import './FloorForm.scss';

const FloorForm = ({ mode }: ModeProps) => {
  return (
    <Form className="m-t-sm">
      <Grid>
        <Grid.Row className="pm-none">
          <Grid.Column
            width={16}
            className="p-none"
          >
            <SectionComponent
              title="1. Main description"
              className="section-main-description"
            >
              <MainDescription mode={mode} />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column
            width={16}
            className="m-t-xl m-b-xl p-none"
          >
            <SectionComponent title="2. Floorplan evolution">
              <FilterFloorPlan mode={mode} />
              <FloorPlan mode={mode} />
              <SpacesAndUnits mode={mode} />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default FloorForm;
