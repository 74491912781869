import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateCurrentDashboardTab } from 'store/dashboard/dashboardSlice';
import DevelopmentDashboard from './DevelopmentDashboard';

export enum DASHBOARD_PAGE_TABS {
  DEVELOPMENTS = 'developments',
  BUILDINGS = 'buildings',
}

const getTabMenuConfig = (): TabMenuProps[] => {
  return [
    {
      key: 'developments-tab',
      href: `?tab=${DASHBOARD_PAGE_TABS.DEVELOPMENTS}`,
      label: 'Developments',
      dataTest: 'nav-dashboard',
      customTab: <DevelopmentDashboard />,
    },
    {
      key: 'buildings-tab',
      href: `?tab=${DASHBOARD_PAGE_TABS.BUILDINGS}`,
      label: 'Buildings',
      dataTest: 'nav-dashboard',
      customTab: <DevelopmentDashboard />,
    },
  ];
};

const DashboardTabs = () => {
  const { activeTabIndex } = useAppSelector((state: RootState) => state.dashboard);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateCurrentDashboardTab}
      tabMenu={getTabMenuConfig()}
    />
  );
};
export default DashboardTabs;
