import { SpaceProps } from 'common/api/properties';
import { convertDateToISO } from 'utils/utils-date';
import { Floor, Space } from './types/stackingPlan';

//TODO to improve: use moment to compare dates and simplify code

export const getFilteredSpaces = (dateFilter: string | null, spaces: SpaceProps[]): SpaceProps[] => {
  if (dateFilter !== null && spaces.length !== 0) {
    return spaces.filter(
      ({ activationDate, deactivationDate }: { activationDate: string | null; deactivationDate: string | null }) => {
        const activationDateCopy = convertDateToISO(activationDate) as any;
        const deactivationDateCopy = convertDateToISO(deactivationDate) as any;

        if (activationDate !== null && deactivationDate !== null) {
          return dateFilter >= activationDateCopy && dateFilter <= deactivationDateCopy;
        } else if (activationDate === null && deactivationDate !== null) {
          return dateFilter <= deactivationDateCopy;
        } else if (activationDate !== null && deactivationDate === null) {
          return dateFilter >= activationDate;
        } else {
          return false;
        }
      },
    );
  } else {
    return spaces;
  }
};

export const filterUnitsWithMonitoringDate = (floors: Floor[], monitoringDate: string): Floor[] => {
  return floors.map((floor: Floor) => {
    const spaces = floor.spaces.filter(
      ({ activationDate, deactivationDate }) =>
        new Date(activationDate) < new Date(monitoringDate) &&
        (!deactivationDate || new Date(deactivationDate) > new Date(monitoringDate)),
    );
    return { ...floor, spaces };
  });
};
