import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import EntityWithJoinForAdmin from 'common/model/EntityWithJoinForAdmin';
import { listEntities, listEntityActivities, listEntityTypes } from 'common/api/adminEntities';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import AdminEntitiesHeaderToolbar from './AdminEntitiesHeaderToolbar';
import { AdminEntitiesRow, adminEntitiesColumnConfig } from './AdminEntitiesTableConfig';

const AdminEntitiesListing = () => {
  const dispatch = useAppDispatch();

  const { isLoading, entitiesList, typesOfEntity, typesOfEntityActivity } = useAppSelector(
    (state: RootState) => state.adminEntities,
  );

  useEffect(() => {
    dispatch(listEntityTypes());
    dispatch(listEntityActivities());
  }, []);

  const fetchAdminEntities = (params: PaginationAPIProps) => {
    dispatch(listEntities({ ...params }));
  };

  const currentList: AdminEntitiesRow[] = entitiesList.map(
    (adminEntity: EntityWithJoinForAdmin) => new AdminEntitiesRow(adminEntity),
  );

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Entities' }];

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <AdminEntitiesHeaderToolbar />,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };
  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          fetchAdminEntities(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={adminEntitiesColumnConfig(typesOfEntity, typesOfEntityActivity)}
      />
    </PageLayout>
  );
};

export default AdminEntitiesListing;
