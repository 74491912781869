import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { convertDateToISO } from 'utils/utils-date';
import { uploadToAWS } from 'utils/utils-upload';
import { setHistoryDocumentsInput, setHistoryGeneralInput, setPremiseInput } from '.';
import { HistoryDocumentsProps, TransactionDocument, TransactionProps } from './types';

interface UpdateTransactionQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
  params: any;
}

const UPDATE_TRANSACTION = async (
  activeTransaction: TransactionProps,
  transactionDocs: TransactionDocument[],
  paramsAlreadySet?: boolean,
) => {
  if (!activeTransaction) throw new Error('Update Transaction: activeTransaction is required');

  const {
    id,
    identifier,
    documentTypeIds,
    regulatories,
    description,
    premises,
    ownerId,
    policyId,
    tenantId,
    historyGeneral,
    historyDocuments,
  } = activeTransaction;

  const params = {
    ownerId,
    policyId,
    tenantId,
    identifier,
    documentTypeIds,
    regulatories,
    description,
    premises: paramsAlreadySet
      ? premises.map((premise: any) => ({
          floorsStatus: premise.floorsStatus.map((floor: any) => ({
            floorId: floor.floorId,
            whole: floor.whole,
          })),
          spaceIds: premise.spaceIds,
          propertyId: premise.property.id,
        }))
      : setPremiseInput(premises),
    historyGeneral: setHistoryGeneralInput(historyGeneral),
    historyDocuments: setHistoryDocumentsInput(
      historyDocuments.filter((doc: HistoryDocumentsProps) =>
        documentTypeIds.some((docId: string) => docId === doc.documentTypeId),
      ),
    ),
    documents: transactionDocs,
  };

  if (!id) throw new Error('Update Transaction: id is required');

  const currentLandlordEntityId = activeTransaction.ownerId;

  if (!currentLandlordEntityId) throw new Error('Update Transaction: currentLandlordEntityId is required');

  const variables: UpdateTransactionQueryVariables = {
    currentLandlordEntityId,
    transactionId: id,
    params,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $transactionId: ID!, $params: TransactionInput!) {
        updateTransaction(
          currentLandlordEntityId: $currentLandlordEntityId
          transactionId: $transactionId
          params: $params
        ) {
          id
          identifier
          documentTypeIds
          regulatories
          description
          owner {
            id
            name
          }
          tenant {
            id
            name
          }
          policy {
            id
            regulatories
          }
          contents {
            content
            documentType {
              name
              id
            }
          }
          answers {
            answer
            answerType
            dateOfAnswer
            fromPolicy
            fromInventory
            paramRef {
              parameterId
              tableId
              index
            }
            user {
              id
              firstName
              lastName
            }
            transaction {
              id
            }
          }
          premises {
            property {
              buildingName
              measurementUnit
              floors {
                id
                name
                index
                comments
                characteristics
                ceilingHeight
                floorLoading
                activationDate
                deactivationDate
                spaces {
                  id
                  name
                  registeredName
                  type
                  usedForOccupancyRate
                  grossArea
                  netArea
                  lettableArea
                  saleableArea
                  efficiencyRatio
                  comments
                  characteristics
                  activationDate
                  deactivationDate
                }
                floorPlans {
                  id
                  reviewFlag
                  floorPlan {
                    id
                    name
                    path
                    public
                    url
                  }
                  revisionDate
                  unitsNumber
                  whollyOwned
                  grossArea
                  netArea
                  lettableArea
                  saleableArea
                  efficiencyRatio
                  insertedAt
                }
              }
            }
            spaceIds
            floorsStatus {
              floorId
              whole
            }
          }
          historyGeneral {
            date
            documents {
              document {
                id
                name
                path
                public
                url
              }
              documentTypeId
            }
            status
            notes
            user {
              id
              firstName
              lastName
              photoUrl
            }
          }
          historyDocuments {
            documentTypeId
            history {
              user {
                id
                firstName
                lastName
                photoUrl
              }
              tenant {
                id
                name
              }
              pendingChanges
              note
              date
              document {
                id
                name
                path
                public
                url
              }
              version
              status
            }
          }
          documents {
            name
            document {
              id
              name
              path
              public
              dateSigned
              comment
              url
              insertedAt
            }
          }
          insertedAt
          updatedAt
        }
      }
    `,
    variables,
  };
};

export const updateTransaction = createAsyncThunk(
  'transactions/updateTransaction',
  async ({ paramsAlreadySet }: { approval?: boolean; paramsAlreadySet?: boolean }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
    } = getState() as RootState;

    const transactionDocs = [...activeTransaction.documents];

    if (transactionDocs.length > 0) {
      for (let i = 0; i < transactionDocs.length; i++) {
        const {
          local,
          uploadUrl,
          file,
          name,
          path,
          public: docPublic,
          comment,
          dateSigned,
        } = transactionDocs[i].document;

        if (local) {
          await uploadToAWS(uploadUrl, file);
        }

        transactionDocs.splice(i, 1, {
          name: transactionDocs[i].name,
          document: {
            name,
            path,
            public: local ? true : docPublic,
            comment,
            dateSigned: convertDateToISO(dateSigned),
          },
        });
      }
    }

    const response = await client.mutate(
      await UPDATE_TRANSACTION(activeTransaction, transactionDocs, paramsAlreadySet),
    );
    return response;
  },
);
