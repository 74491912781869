import { createSlice } from '@reduxjs/toolkit';
import {
  GuidelineClone,
  ParameterCategoryBase,
  ParameterCategoryDetailClone,
  ParameterDropdownOption,
  ParameterGroupClone,
  ParameterGroupDetailClone,
  ParameterListClone,
  ParameterListingClone,
  ParameterTableListingClone,
} from 'common/_classes';
import { toast } from 'react-toastify';
import AnswerTypes from 'common/model/AnswerTypes';
import ParameterTable from 'common/model/ParameterTable';
import ParameterUse from 'common/model/ParameterUse';
import ReferenceTypes from 'common/model/ReferenceTypes';
import { PARAMETERS_DASHBOARD_VIEW_OPTIONS } from 'common/api/miscellaneous';
import {
  checkParameterUse,
  createParameterCategory,
  createParameterGroup,
  deleteParameter,
  deleteParameterCategory,
  deleteParameterGroup,
  deleteParameterTable,
  listParameterCategories,
  listParameterCategoriesAndGroups,
  listParameterGroups,
  listParameterTables,
  listParameterTablesPage,
  listParameters,
  listParametersWithAnswerType,
  updateParameterCategory,
  updateParameterGroup,
  updateParametersIndex,
} from 'common/api/parameters';
import { DROPDOWN_OPTION } from 'utils/UI';

const initParameterCategory: ParameterCategoryDetailClone = ParameterCategoryDetailClone.init();

const initParameterGroup: ParameterGroupDetailClone = {
  name: null,
  index: null,
  category: null,
  guideline: GuidelineClone.init(ReferenceTypes.ParameterGroup),
};

const initParameterUse: ParameterUse = {
  nodes: [],
  tables: [],
  translateToContracts: [],
};

// Information on usage of the different store data:
//
//  - paramView/paramViewIsNotGroup: stores the current parameter view informations
//      note: paramViewIsNotGroup is true when paramView!==PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP
//  - paramViewSelectedGroup: stores the specific group view informations, when a specific group is selected
//
interface ParametersListingState {
  activeCategory: ParameterCategoryDetailClone;
  activeGroup: ParameterGroupDetailClone;
  parametersList: ParameterListingClone[];
  parameterTablesList: ParameterTableListingClone[];
  parameterCategoriesOptions: DROPDOWN_OPTION[];
  parameterGroupsOptions: DROPDOWN_OPTION[];
  filteredCategoriesList: ParameterCategoryBase[];
  filteredGroupsList: ParameterGroupClone[];
  parameterDropdown: ParameterDropdownOption[];
  parameterNumberOptions: DROPDOWN_OPTION[];
  parameterTablesOptions: DROPDOWN_OPTION[];
  paramView: PARAMETERS_DASHBOARD_VIEW_OPTIONS;
  paramViewIsNotGroup: boolean;
  isLoading: boolean;
  search: string | null;
  isSearching: boolean;
  parameterUse: ParameterUse;
  parameterUseLoading: boolean;
}

const initialState: ParametersListingState = {
  activeCategory: initParameterCategory,
  activeGroup: initParameterGroup,
  parametersList: [],
  parameterTablesList: [],
  parameterCategoriesOptions: [],
  parameterGroupsOptions: [],
  filteredCategoriesList: [],
  filteredGroupsList: [],
  parameterDropdown: [],
  parameterNumberOptions: [],
  parameterTablesOptions: [],
  paramView: PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP,
  paramViewIsNotGroup: false,
  isLoading: false,
  search: null,
  isSearching: false,
  parameterUse: initParameterUse,
  parameterUseLoading: false,
};

// Set group param in URL
const setGroupInURL = (groupId: string): void => {
  const url = new URL(window.location.href);
  url.searchParams.set('group', groupId);
  window.history.pushState({}, '', url);
};

const parametersListingSlice = createSlice({
  name: 'parametersListing',
  initialState,
  reducers: {
    updateActiveCategory: (state, action) => {
      let { key, value } = action.payload;
      if (key === 'index') {
        value = parseInt(value);
      }
      // @ts-ignore
      state.activeCategory[key] = value;
    },
    setActiveCategory: (state, action) => {
      const { category } = action.payload;
      state.activeCategory = category;
    },
    resetActiveCategory: state => {
      state.activeCategory = initParameterCategory;
    },
    updateActiveGroup: (state, action) => {
      let { key, value } = action.payload;
      if (key === 'parameterCategory') {
        state.activeGroup.category = { id: value };
      } else {
        if (key === 'index') {
          value = parseInt(value);
        }
        // @ts-ignore
        state.activeGroup[key] = value;
      }
    },
    setActiveGroup: (state, action) => {
      const { group } = action.payload;
      state.activeGroup = group;
    },
    resetActiveGroup: state => {
      state.activeGroup = initParameterGroup;
    },
    updateActiveGroupGuideLine: (state, action) => {
      const { type, value } = action.payload;
      // @ts-ignore
      state.activeGroup.guideline[type] = value;
    },

    resetParameterUse: state => {
      state.parameterUse = initParameterUse;
    },
    updateSelectedGroup: (state, action) => {
      const { group } = action.payload;
      state.paramView = PARAMETERS_DASHBOARD_VIEW_OPTIONS.PARAMETER;
      state.paramViewIsNotGroup = true;
      setGroupInURL(group.id);
    },
    resetSelectedGroup: state => {
      state.paramView = PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP;
      state.paramViewIsNotGroup = false;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    updateParameterView: (state, action) => {
      // Ensure paramView is of type PARAMETERS_DASHBOARD_VIEW_OPTIONS
      state.paramView = action.payload.type;
      state.parametersList = [];
    },
    resetParametersSearch: state => {
      state.search = null;
      state.paramView = PARAMETERS_DASHBOARD_VIEW_OPTIONS.GROUP;
      state.paramViewIsNotGroup = false;
    },
    updateParametersList: (state, action) => {
      state.parametersList = action.payload.parametersList;
    },
  },
  extraReducers: builder => {
    //deleteParameter
    builder.addCase(deleteParameter.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteParameter.fulfilled, (state, action) => {
      // @ts-ignore
      state.parametersList = state.parametersList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Parameter deleted successfully');
    });
    builder.addCase(deleteParameter.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('deleteParameter API call rejected');
    });

    //listParameters
    builder.addCase(listParameters.pending, (state, action) => {
      // @ts-ignore
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listParameters.fulfilled, (state, action) => {
      const parametersList = action.payload.data.listParameters.edges.map((element: any) => element.node);
      state.parametersList = parametersList;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listParameters.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listParameters API call rejected');
    });

    //listParametersWithAnswerType
    builder.addCase(listParametersWithAnswerType.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listParametersWithAnswerType.fulfilled, (state, action) => {
      const data = action.payload.data;
      const list: ParameterListClone[] = data.listParametersWithAnswerType;
      let dropdownOptions: ParameterDropdownOption[] = list.map(
        (element: ParameterListClone) => new ParameterDropdownOption(element),
      );

      state.parameterDropdown = dropdownOptions;
      state.parameterNumberOptions = list
        .filter((element: ParameterListClone) => element.answerType === AnswerTypes.Number)
        .map((element: ParameterListClone, index: number) => {
          const result: DROPDOWN_OPTION = {
            key: index,
            text: element.name,
            value: element.id,
          };
          return result;
        });
      state.isLoading = false;
    });
    builder.addCase(listParametersWithAnswerType.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('listParametersWithAnswerType API call rejected');
    });

    //listParameterTables
    builder.addCase(listParameterTables.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(listParameterTables.fulfilled, (state, action) => {
      const list = action.payload.data.listParameterTables;
      const dropdownOptions: DROPDOWN_OPTION[] = list.map((item: ParameterTable) => {
        return {
          key: item.id,
          text: item.name,
          value: item.id,
        };
      });

      state.parameterTablesOptions = dropdownOptions;
      state.isLoading = false;
    });
    builder.addCase(listParameterTables.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('listParameterTables API call rejected');
    });

    //listParameterCategories
    builder.addCase(listParameterCategories.pending, state => {
      state.isLoading = false;
    });
    builder.addCase(listParameterCategories.fulfilled, (state, action) => {
      const list = action.payload.data.listParameterCategories;
      const dropdownOptions: DROPDOWN_OPTION[] = list.map((item: any) => {
        return {
          key: item.id,
          text: item.name,
          value: item.id,
        };
      });
      state.parameterCategoriesOptions = dropdownOptions;
      state.isLoading = false;
    });
    builder.addCase(listParameterCategories.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('listParameterCategories API call rejected');
    });

    //createParameterCategory
    builder.addCase(createParameterCategory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createParameterCategory.fulfilled, state => {
      state.activeCategory = initParameterCategory;
      // @ts-ignore
      state.isLoading = false;
      toast.success('Category created successfully');
    });
    builder.addCase(createParameterCategory.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('createParameterCategory API call rejected');
    });

    //updateParameterCategory
    builder.addCase(updateParameterCategory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateParameterCategory.fulfilled, state => {
      state.activeCategory = initParameterCategory;
      state.isLoading = false;
      toast.success(`Category updated successfully`);
    });
    builder.addCase(updateParameterCategory.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('updateParameterCategory API call rejected');
    });

    //createParameterGroup
    builder.addCase(createParameterGroup.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createParameterGroup.fulfilled, state => {
      state.activeGroup = initParameterGroup;
      state.isLoading = false;
      toast.success('Group created successfully');
    });
    builder.addCase(createParameterGroup.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('createParameterGroup API call rejected');
    });

    //updateParameterGroup
    builder.addCase(updateParameterGroup.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateParameterGroup.fulfilled, state => {
      state.activeGroup = initParameterGroup;
      state.isLoading = false;
      toast.success(`Group updated successfully`);
    });
    builder.addCase(updateParameterGroup.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('updateParameterGroup API call rejected');
    });

    //listParameterCategoriesAndGroups
    builder.addCase(listParameterCategoriesAndGroups.pending, (state, action) => {
      // @ts-ignore
      if (action.meta.arg?.searchValue) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listParameterCategoriesAndGroups.fulfilled, (state, action) => {
      const { listFilteredParameterCategories, listFilteredParameterGroups } = action.payload.data;
      state.filteredCategoriesList = listFilteredParameterCategories.edges.map((element: any) => element.node);
      state.filteredGroupsList = listFilteredParameterGroups.edges.map((element: any) => element.node);
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listParameterCategoriesAndGroups.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listParameterCategoriesAndGroups API call rejected');
    });

    //listParameterGroups
    builder.addCase(listParameterGroups.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listParameterGroups.fulfilled, (state, action) => {
      const list = action.payload.data.listParameterGroups;
      const dropdownOptions: DROPDOWN_OPTION[] = list.map((item: any) => {
        return {
          key: item.id,
          text: item.name,
          value: item.id,
        };
      });
      state.parameterGroupsOptions = dropdownOptions;
      state.isLoading = false;
    });
    builder.addCase(listParameterGroups.rejected, (state, action) => {
      console.warn(action.error);
      state.isLoading = false;
      console.warn(action.error);
      toast.error('listParameterGroups API call rejected');
    });

    //listParameterTablesPage
    builder.addCase(listParameterTablesPage.pending, (state, action) => {
      // @ts-ignore
      const { searchValue } = action.meta.arg;
      if (searchValue) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listParameterTablesPage.fulfilled, (state, action) => {
      const parameterTablesList = action.payload.data.listParameterTablesPage.edges.map((element: any) => element.node);
      state.parameterTablesList = parameterTablesList;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listParameterTablesPage.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listParameterTablesPage API call rejected');
    });

    //deleteParameterTable
    builder.addCase(deleteParameterTable.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteParameterTable.fulfilled, (state, action) => {
      // @ts-ignore
      state.parameterTablesList = state.parameterTablesList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Parameter table deleted successfully');
    });
    builder.addCase(deleteParameterTable.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error.message);
      toast.error('deleteParameterTable API call rejected');
    });

    //deleteParameterCategory
    builder.addCase(deleteParameterCategory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteParameterCategory.fulfilled, (state, action) => {
      // @ts-ignore
      state.filteredCategoriesList = state.filteredCategoriesList.filter(element => element.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Parameter category deleted successfully');
    });
    builder.addCase(deleteParameterCategory.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error.message);
      toast.error('deleteParameterCategory API call rejected');
    });

    //deleteParameterGroup
    builder.addCase(deleteParameterGroup.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteParameterGroup.fulfilled, (state, action) => {
      // @ts-ignore
      state.filteredGroupsList = state.filteredGroupsList.filter(element => element.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Parameter group deleted successfully');
    });
    builder.addCase(deleteParameterGroup.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error.message);
      toast.error('deleteParameterGroup API call rejected');
    });

    //checkParameterUse
    builder.addCase(checkParameterUse.pending, state => {
      state.parameterUseLoading = true;
    });
    builder.addCase(checkParameterUse.fulfilled, (state, action) => {
      const data = action.payload.data.checkParameterUse;
      state.parameterUse = data;
      state.parameterUseLoading = false;
    });
    builder.addCase(checkParameterUse.rejected, (state, action) => {
      state.parameterUseLoading = false;
      console.warn(action.error);
      toast.error('checkParameterUse API call rejected');
    });

    builder.addCase(updateParametersIndex.pending, state => {
      state.parameterUseLoading = true; // TODO check why parameterUseLoading is modified here ?
    });
    builder.addCase(updateParametersIndex.fulfilled, () => {
      toast.success('Success');
    });
    builder.addCase(updateParametersIndex.rejected, (state, action) => {
      console.warn(action.error);
      toast.error('updateParametersIndex API call rejected');
    });
  },
});

export const {
  resetActiveCategory,
  resetActiveGroup,
  resetParametersSearch,
  resetParameterUse,
  resetSelectedGroup,
  setActiveCategory,
  setActiveGroup,
  updateActiveCategory,
  updateActiveGroup,
  updateActiveGroupGuideLine,
  updateParametersList,
  updateParameterView,
  updateSearch,
  updateSelectedGroup,
} = parametersListingSlice.actions;

export default parametersListingSlice.reducer;
