import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { LIST_PROPERTIES_FOR_CONTRACT_CREATION } from 'common/api/properties';

// TODO, shall we move that list to miscellaneous store ?
export const listPremises = createAsyncThunk(
  'transactions/listPremises',
  async ({ ownerId }: { ownerId: string | null }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
    } = getState() as RootState;
    const owner = ownerId ? ownerId : activeTransaction.ownerId;
    const response = await client.query(LIST_PROPERTIES_FOR_CONTRACT_CREATION(owner));
    return response;
  },
);
