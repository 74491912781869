import { Contract2EventsInputClone } from 'common/_classes/contractsV2';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import Contract2Events from 'common/model/Contract2Events';
import { generateUniqueId } from 'utils/utils-random';
import HistoryItem from './HistoryItem';
import UpdateContract from './UpdateContract';
import './ContractHistoryNavigator.scss';

interface ContractHistoryNavigatorProps {
  setActiveItem: (id: string) => void;
  onUpdate: () => void;
  events: Contract2Events[];
}

const ContractHistoryNavigator = ({ onUpdate, events, setActiveItem }: ContractHistoryNavigatorProps) => {
  const [id] = useState<string>(generateUniqueId('navigator-'));
  const [shadowLeftId] = useState<string>(generateUniqueId('shadow-left-'));
  const [shadowRightId] = useState<string>(generateUniqueId('shadow-right-'));

  const { contractEvents } = useAppSelector(({ contractDetailV2 }) => contractDetailV2);
  useEffect(() => {
    const scrollableContainer = document.getElementById(id) as HTMLElement;
    const shadowLeft = document.getElementById(shadowLeftId) as HTMLElement;
    const shadowRight = document.getElementById(shadowRightId) as HTMLElement;

    const getScrollRatio = (element: HTMLElement) => {
      const scrollLeft = element.scrollLeft;
      const maxScrollLeft = element.scrollWidth - element.clientWidth;
      return scrollLeft / maxScrollLeft;
    };

    const alterShadowOpacities = () => {
      const scrollRatio = getScrollRatio(scrollableContainer);
      shadowLeft.style.opacity = `${scrollRatio}`;
      shadowRight.style.opacity = `${1 - scrollRatio}`;
    };

    if (scrollableContainer && shadowLeft && shadowRight) {
      scrollableContainer?.addEventListener('scroll', alterShadowOpacities);

      const resizeObserver = new ResizeObserver(() => {
        if (isNaN(getScrollRatio(scrollableContainer))) {
          shadowRight.style.opacity = `0`;
          shadowLeft.style.opacity = `0`;
        } else {
          shadowRight.style.opacity = `1`;
        }
      });

      resizeObserver.observe(scrollableContainer);
    }

    return () => {
      scrollableContainer?.removeEventListener('scroll', alterShadowOpacities);
    };
  }, []);

  return (
    <div className="contract-navigator-wrapper">
      <div>
        <div
          id={shadowLeftId}
          className="shadow left"
        ></div>

        <div
          id={shadowRightId}
          className="shadow right"
        ></div>

        <div
          id={id}
          className="navigator-container"
        >
          {events.map(({ id, insertedAt }, index) => {
            return (
              <HistoryItem
                id={id}
                key={`${id}-${index}`}
                insertedAt={insertedAt}
                index={index}
                setActiveItem={setActiveItem}
              />
            );
          })}
          ;
        </div>
      </div>

      <UpdateContract onUpdate={onUpdate} />
    </div>
  );
};

export default ContractHistoryNavigator;
