import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { MemberSliceState } from 'store/members/membersSlice';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { TeamMember } from './type';

interface UpdateMemberQueryvariables {
  currentLandlordEntityId: string;
  memberId: string;
  roleId: string;
  params: {
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    countryCode?: string;
  };
}

export const UPDATE_MEMBER = (teamMember: TeamMember) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Update Member: currentLandlordEntityId is required');

  if (!teamMember?.memberId) throw new Error('Update Member: memberId is required');

  if (!teamMember?.roleId) throw new Error('Update Member: roleId is required');

  const variables: UpdateMemberQueryvariables = {
    currentLandlordEntityId,
    memberId: teamMember.memberId,
    roleId: teamMember.roleId,
    params: {
      firstName: teamMember.firstName,
      lastName: teamMember.lastName,
      phoneNumber: teamMember.phoneNumber,
      countryCode: teamMember.countryCode,
    },
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $memberId: ID!, $roleId: ID!, $params: UserProfileInput!) {
        updateMember(
          currentLandlordEntityId: $currentLandlordEntityId
          memberId: $memberId
          roleId: $roleId
          params: $params
        ) {
          id
          user {
            id
            photoUrl
            email
            firstName
            lastName
            phoneNumber
            countryCode
          }
          role {
            id
            name
            approvalLevel
          }
          entity {
            id
            name
          }
          status
        }
      }
    `,
    variables,
  };
};

export const updateMember = createAsyncThunk('members/updateMember', async (_arg, { getState }) => {
  const state = getState() as RootState;
  const members: MemberSliceState = state.members;
  const member: TeamMember | null = members.activeTeamMember;

  const response = await client.mutate(UPDATE_MEMBER(member as TeamMember));

  return response.data;
});
