import { CValuationReport } from 'common/_classes/valuationReport2';
import { useState } from 'react';
import SortOrder from 'common/model/SortOrder';
import { deepClone } from 'utils/tsHelper';
import { FilterDataType } from '..';
import { applySorting } from '../ValuationRow';
import RowTemplate from './RowTemplate';
import RowFilterPopup from './RowTemplate/RowFilterPopup';
import { RowAction, RowProps } from './RowTemplate/RowProps';

interface TableBodyProps {
  contents: CValuationReport[];
  setContents: (value: CValuationReport[]) => void;
  filterData: FilterDataType;
  setFilterData: (filterData: FilterDataType) => void;
  rowConfigs: RowProps[];
  onFilter: (filterData: FilterDataType) => void;
}

const TableBody = ({ contents, setFilterData, setContents, filterData, rowConfigs, onFilter }: TableBodyProps) => {
  const [isRowFilterModalOpen, setIsRowFilterModalOpen] = useState<boolean>(false);
  /**
   * On Row filter Modal open
   * @param row
   */
  const onOpenRowFilterModal = (rowConfig: RowProps): void => {
    const updatedFilterData: FilterDataType = deepClone(filterData);
    updatedFilterData.activeConfigId = rowConfig.id;

    if (!filterData[rowConfig.id]) {
      updatedFilterData[rowConfig.id] = { ...rowConfig };
    } else {
      updatedFilterData[rowConfig.id] = { ...rowConfig, ...updatedFilterData[rowConfig.id] };
    }

    setFilterData(updatedFilterData);
    setIsRowFilterModalOpen(true);
  };

  /**
   * On Sorting Change
   * @param row
   * @param sortOrder
   *
   * Note: onSortingChange gets event from the RowTemplate indicating a row with a sorting.
   */
  const onSortingChange = (rowConfig: RowProps, sortOrder: SortOrder): void => {
    const updatedFilterData: FilterDataType = deepClone(filterData);
    const rowConfigId = rowConfig.id;

    if (!filterData[rowConfigId]) {
      updatedFilterData[rowConfigId] = { ...rowConfig };
    }

    const filterDataArray = Object.entries(updatedFilterData);

    const updatedWithSortOrder = filterDataArray.map(data => {
      const objKey = data[0];

      if (objKey === 'activeConfigId') return data;

      const parsedKey = parseInt(objKey);

      const value = data[1] as RowAction & RowProps;

      if (!isNaN(parsedKey) && parsedKey !== rowConfigId) {
        return [objKey, { ...value, sortOrder: undefined }];
      } else {
        return [objKey, { ...value, sortOrder }];
      }
    });

    setFilterData(Object.fromEntries(updatedWithSortOrder));

    setContents(applySorting(contents, rowConfig, sortOrder));
  };

  return (
    <>
      <tbody>
        <RowTemplate
          rowConfigs={rowConfigs}
          contents={contents}
          filterData={filterData}
          onOpenRowFilterModal={onOpenRowFilterModal}
          onSortingChange={onSortingChange}
        />
      </tbody>

      {filterData.activeConfigId !== null && (
        // Row filter popup
        <RowFilterPopup
          isOpen={isRowFilterModalOpen}
          setIsOpen={setIsRowFilterModalOpen}
          filterData={filterData}
          setFilterData={setFilterData}
          onFilter={onFilter}
        />
      )}
    </>
  );
};

export default TableBody;
