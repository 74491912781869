import { useState } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { discardRoleCreation } from 'store/roles/rolesSlice';
import { createRole, updateRole } from 'common/api/roles';
import { ContentProps, ModalProps } from 'utils/types/userManagement/users';
import { createRoleValidation } from '../../userValidator';
import RoleModalForm from './RoleModalForm';

const Content = ({ setOpen, createMode }: ContentProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeRole } = useAppSelector((state: RootState) => state.roles);

  const onSave = (): void => {
    if (createMode) {
      dispatch(createRole()).then(({ meta: { requestStatus } }) => {
        if (requestStatus === 'fulfilled') {
          if (setOpen) setOpen(false);
        }
      });
    } else {
      dispatch(updateRole());
      if (setOpen) setOpen(false);
    }
  };

  return (
    <Form>
      <Grid className="pm-none">
        <Grid.Row className="p-none">
          <Grid.Column
            width={16}
            className="pm-none"
          >
            <h4 className="section-title">Details</h4>
            <RoleModalForm />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className="p-none">
          <div className="height-0_0714 w-100 m-b-xl m-t-xl border-bottom-sm-grayish-magenta-light"></div>
        </Grid.Row>

        <Grid.Row className="p-none">
          <Button
            className="btn grey-bg"
            disabled={createRoleValidation(activeRole)}
            onClick={() => onSave()}
          >
            {createMode ? 'CREATE' : 'SAVE'}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const RoleModal = ({ createMode, trigger }: ModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = (value: boolean): void => {
    setIsOpen(value);
    if (!value) {
      dispatch(discardRoleCreation());
    }
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onToggleModalStatus={toggleModal}
      trigger={trigger}
      title={`${createMode ? 'Add New Role' : 'Edit Role Details'}`}
    >
      <Content
        createMode={createMode}
        setOpen={toggleModal}
      />
    </ModalTemplate>
  );
};

export default RoleModal;
