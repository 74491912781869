import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

const LIST_TRANSACTION_PROVISION_APPROVALS = () => {
  return {
    query: gql`
      query {
        listTransactionProvisionApprovals {
          id
          status
          dateSubmitted
          user {
            id
            firstName
            lastName
            photoUrl
          }
          provision {
            selected
            provision {
              id
              name
              provisionCategory {
                id
                name
              }
            }
          }
        }
      }
    `,
  };
};

export const listTransactionProvisionApprovals = createAsyncThunk(
  'transactions/listTransactionProvisionApprovals',
  async (args, { getState }) => {
    const response = await client.query(LIST_TRANSACTION_PROVISION_APPROVALS());
    return response;
  },
);
