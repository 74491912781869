import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { IEntityClone } from 'common/_classes/entities';
import { RootState } from 'store';
import Entity from 'common/model/Entity';
import EntityType from 'common/model/EntityType';
import { getCurrentLandlordEntityId, namesListHK } from 'utils/tsHelper';
import { uploadToAWS } from 'utils/utils-upload';
import { EntityCreatorTypes } from './types';

interface UpdateEntityQueryVariables {
  currentLandlordEntityId?: string;
  entityId: string;
  params: any;
}

export const UPDATE_ENTITY = (entityType: EntityCreatorTypes, activeEntity: Entity, entityDocs: any) => {
  if (!activeEntity.id) throw new Error('Update Entity: entityId is required');

  const {
    id,
    name,
    nickname,
    industry,
    identifier,
    address,
    contacts,
    type,
    legalDomicile,
    residentialAddress,
    businessAddress,
    passportId,
    cardId,
    registrationCountry,
    registrationId,
    overseasRegistrationId,
    details,
    addressLocal,
  } = activeEntity;

  let industryValue;
  if (typeof industry === 'object' && industry !== null) {
    industryValue = industry.id;
  } else {
    industryValue = industry;
  }

  let params: any = {
    name,
    nickname,
    industryId: industryValue,
    identifier,
    address,
    contacts,
    type,
    documents: entityDocs,
    details,
  };

  if (type === EntityType.Individual) {
    params = { ...params, legalDomicile, residentialAddress };

    if (namesListHK.includes(legalDomicile)) {
      params = { ...params, cardId };
    } else {
      params = { ...params, passportId, addressLocal };
    }
  } else {
    params = { ...params, registrationCountry, businessAddress };

    if (namesListHK.includes(registrationCountry)) {
      params = { ...params, registrationId };
    } else {
      params = { ...params, overseasRegistrationId };

      if (type === EntityType.Company) {
        params = { ...params, addressLocal };
      }
    }
  }

  let currentLandlordEntity = '',
    innerTextLandlordEntity = '',
    currentLandlordEntityId;

  let inputType = 'LandlordEntityInput!';

  if (entityType === EntityCreatorTypes.Tenant) {
    currentLandlordEntityId = getCurrentLandlordEntityId();

    if (!currentLandlordEntityId) throw new Error('Update Entity: currentLandlordEntityId is required');

    currentLandlordEntity = '$currentLandlordEntityId: ID!,';
    innerTextLandlordEntity = 'currentLandlordEntityId: $currentLandlordEntityId,';
    inputType = 'TenantEntityInput!';
  }

  const variables: UpdateEntityQueryVariables = {
    currentLandlordEntityId,
    entityId: id,
    params: JSON.parse(JSON.stringify(params)),
  };

  return {
    mutation: gql`
        mutation(${currentLandlordEntity} $entityId: ID!, $params: ${inputType}){
        update${entityType}Entity(${innerTextLandlordEntity} entityId: $entityId, params: $params){
            id
            activity
            address
            addressLocal
            businessAddress
            cardId
            type
            contacts {
              email
              firstName
              lastName
              phone
              position
            }
            details
            documents{
              id
              name
              public
              url
              path
            }
            insertedAt
            legalDomicile
            logo
            name
            nickname
            identifier
            industry{
              id
              name
              code
            }
            overseasRegistrationId
            passportId
            registrationCountry
            registrationId
            residentialAddress
            updatedAt 
        }
      }`,
    variables,
  };
};

export const updateEntity = createAsyncThunk(
  'entityDetail/updateEntity',
  async (
    { type, entityData }: { type: EntityCreatorTypes; entityData?: IEntityClone; saveAndExit?: boolean },
    { getState },
  ) => {
    const {
      entityDetail: { activeEntity },
    } = getState() as RootState;
    let entity = null;
    if (entityData) {
      entity = entityData;
    } else {
      entity = activeEntity;
    }

    const entityDocs = [...entity.documents];

    if (entityDocs.length > 0) {
      for (let i = 0; i < entityDocs.length; i++) {
        const { local, uploadUrl, file, name, path, public: docPublic } = entityDocs[i];

        if (local) {
          await uploadToAWS(uploadUrl, file);
        }

        entityDocs.splice(i, 1, {
          name,
          path,
          public: local ? true : docPublic,
        });
      }
    }

    const response = await client.mutate(UPDATE_ENTITY(type, entity, entityDocs));
    return response;
  },
);
