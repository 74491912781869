import Term from 'common/model/Term';
import { CDuration } from './Duration';

export class CTerm {
  duration: CDuration;
  endDate: Date;
  startDate: Date;

  constructor(input: Term) {
    this.duration = new CDuration(input.duration);
    this.endDate = new Date(input.endDate);
    this.startDate = new Date(input.startDate);
  }

  static fragment() {
    return `
        duration {
          ${CDuration.fragment()}
        }
        endDate
        startDate
    `;
  }
}
