import { GuidelinesOnly, ParameterChoiceBase } from 'common/_classes';
import AnswerTypes from 'common/model/AnswerTypes';
import Parameter from 'common/model/Parameter';
import ParameterTypes from 'common/model/ParameterTypes';
import { OmitMultiple } from 'utils/UI';
import { checkNotEmpty } from 'utils/tsValidator';

interface IParameterListClone
  extends OmitMultiple<
    Pick<
      Parameter,
      'id' | 'name' | 'answerType' | 'type' | 'unit' | 'hasInventory' | 'inventoryFunction' | 'choices' | 'guideline'
    >,
    'choices' | 'guideline'
  > {
  choices: ParameterChoiceBase[];
  guideline: GuidelinesOnly;
}

export class ParameterListClone implements IParameterListClone {
  id: string;
  name: string;
  type: ParameterTypes;
  answerType: AnswerTypes;
  unit: string;
  hasInventory: boolean;
  inventoryFunction: string;
  choices: ParameterChoiceBase[];
  guideline: GuidelinesOnly;

  constructor(input: IParameterListClone) {
    this.id = input.id;
    this.name = input.name;
    this.type = input.type;
    this.answerType = input.answerType;
    this.unit = input.unit;
    this.hasInventory = input.hasInventory;
    this.inventoryFunction = input.inventoryFunction;
    this.choices = input.choices;
    this.guideline = input.guideline;
  }

  static fragments(): string {
    return `
      id
      name
      answerType
      type
      unit
      hasInventory
      inventoryFunction
      choices {
        id
        default
        text
        archive
        index
      }
      guideline {
        explanatoryNotes
        generalGuidance
        internalNotes
        valuationGuidance
      }
    `;
  }
}

export class ParameterDropdownOption {
  // dropdown part
  key: string;
  text: string;
  value: string;
  // data part
  id: string;
  inventory: boolean;
  answerType: AnswerTypes;
  type: ParameterTypes;
  unit: string | null;
  choices: ParameterChoiceBase[];
  guidance: boolean;

  constructor(input: IParameterListClone) {
    // dropdown part
    this.key = input.id;
    this.text = input.name;
    this.value = input.id;
    // data part
    this.id = input.id;
    this.inventory = input.hasInventory && checkNotEmpty(input.inventoryFunction);
    this.answerType = input.answerType;
    this.type = input.type;
    this.unit = input.unit;
    this.choices = input.choices;
    this.guidance = GuidelinesOnly.checkFilled(input.guideline);
  }
}
