import { FormEvent } from 'react';
import { CheckboxProps, Form, Radio } from 'semantic-ui-react';

export interface RadioGroupOptions {
  label: string;
  value: string;
  disabled?: boolean;
}

interface RadioGroupProps {
  options?: RadioGroupOptions[];
  label?: string | null;
  dataTest?: string;
  fieldKey: string;
  onChange?: ((key: string, value: any) => void) | undefined;
  required?: boolean;
  value: string | number;
}

const RadioGroup = ({ options = [], label, onChange, fieldKey, required, value, dataTest }: RadioGroupProps) => {
  const onSelect = (data: CheckboxProps) => {
    if (onChange) {
      onChange(fieldKey, data.value);
    }
  };

  return (
    <Form>
      <Form.Field className="field-style">
        <label>
          {label}
          {required && <span className="required-indicator">*</span>}
        </label>
      </Form.Field>
      {options.map((data, index) => (
        <Form.Field key={index}>
          <Radio
            label={data.label}
            name="radioGroup"
            value={data.value}
            checked={data.value === value}
            disabled={data.disabled}
            onChange={(event: FormEvent<HTMLInputElement>, data: CheckboxProps) => onSelect(data)}
            data-test={dataTest}
          />
        </Form.Field>
      ))}
    </Form>
  );
};

export default RadioGroup;
