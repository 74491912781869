import { useAppDispatch } from 'hooks';
import ModalTemplate from 'components/ModalTemplate';
import { resetSelectedGroup } from 'store/parameters/parametersListingSlice';
import ParamSearch from './ParamSearch';

interface IParameterSelectorModal {
  modalStatus: boolean;
  setModalStatus: (status: boolean) => void;
  setValue: any;
  parameterKey: string;
}

const ParameterSelectorModal = ({ modalStatus, setModalStatus, setValue }: IParameterSelectorModal) => {
  const dispatch = useAppDispatch();
  const onExit = (): void => {
    setModalStatus(false);
    dispatch(resetSelectedGroup());
  };
  return (
    <ModalTemplate
      isOpen={modalStatus}
      onToggleModalStatus={setModalStatus}
      onModalClose={onExit}
      title={'Select Parameter'}
      className="parameters-search-modal"
    >
      <ParamSearch
        searchParams={true}
        searchGroups={false}
        setValue={setValue}
      />
    </ModalTemplate>
  );
};

export default ParameterSelectorModal;
