import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { updateTransactionViewDetailsTab } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ViewProps } from 'common/api/miscellaneous';
import { getAmendment } from 'common/api/transactions';
import { createAmendment, updateAmendment } from 'common/api/transactions';
import { amendmentDisabled } from 'utils/tsValidator';
import { runTransactionDetailsPageActions } from '../CreatorViewer';
import { TRANSACTION_TABS_OFFSET } from '../CreatorViewer/Tabs/TransactionsTabs';
import AmendmentForm from './AmendmentForm';
import './AmendmentCreator.scss';

const AmendmentCreator = ({ isViewPage }: ViewProps) => {
  const { transactionId, amendmentId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mode, setMode] = useState<MODE_OPTIONS>(isViewPage ? MODE_OPTIONS.READ : MODE_OPTIONS.EDIT);

  const { activeTransaction, activeAmendment, isLoading } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );

  useEffect(() => {
    if (!activeTransaction.id) {
      runTransactionDetailsPageActions(dispatch, transactionId, true);
    }

    if (isViewPage) {
      dispatch(getAmendment(amendmentId!));
    }
  }, []);

  /**
   * On Save button Click
   */
  const onSave = () => {
    if (!isViewPage) {
      dispatch(createAmendment()).then(response => {
        if (response.meta.requestStatus === 'fulfilled') {
          // @ts-ignore
          const id = response.payload.data.createAmendment.id;
          navigate(`/transactions/${activeTransaction.id}/amendment/${id}/details`);
        }
      });
    } else {
      dispatch(updateAmendment());
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = () => {
    if (!isViewPage) {
      dispatch(
        updateTransactionViewDetailsTab({
          tab: TRANSACTION_TABS_OFFSET.PROVISIONS,
        }),
      );
      navigate(`/transactions/${activeTransaction.id}/details`);
    } else {
      dispatch(getAmendment(activeAmendment.id!));
    }
  };

  const headerButtons: PageHeaderToolbarProps = {
    headerToolbar: (
      <DetailsControlButtons
        mode={mode}
        setMode={setMode}
        checkDisabled={amendmentDisabled(activeAmendment)}
        onCancel={onCancel}
        onSave={onSave}
      />
    ),
  };

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Transactions',
        url: '/transactions/dashboard',
      },
      {
        title: activeTransaction.identifier!,
        url: `/transactions/${activeTransaction.id}/details`,
        onClick: () => {
          dispatch(
            updateTransactionViewDetailsTab({
              tab: TRANSACTION_TABS_OFFSET.PROVISIONS,
            }),
          );
          navigate(`/transactions/${activeTransaction.id}/details`);
        },
      },
      {
        title: 'Amendments',
      },
      {
        title: activeAmendment?.name || '',
      },
    ];

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
      className="amendment-creator"
    >
      <AmendmentForm mode={mode} />
    </PageLayout>
  );
};

export default AmendmentCreator;
