import React, { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ParetoGraphWithOutlinedContainer from 'atoms/ParetoGraphWithOutlinedContainer';
import { getRevenueRiskAnalysis } from 'common/api/dashboard/dashboardTab';

const RiskAnalysisGraphs = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  const { riskAnalysis, date, riskAnalysisLoading } = useAppSelector((state: RootState) => state.dashboardTab);

  const industries = riskAnalysis?.industries ?? [];
  const tenants = riskAnalysis?.tenants ?? [];
  const origins = riskAnalysis?.origins ?? [];

  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);

  useEffect(() => {
    dispatch(getRevenueRiskAnalysis());
    scrollToBottom();
  }, [date, activeProperty]);

  const scrollToBottom = () => {
    setTimeout(() => {
      const elements = document.getElementsByClassName('pareto-graph-without-line bg-white');
      const lastDiv = elements[elements.length - 1];
      if (lastDiv) {
        lastDiv.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }, 500);
  };

  return (
    <>
      <h2 className="infobar-header m-b-xs">Portfolio Exposure</h2>
      <ParetoGraphWithOutlinedContainer
        riskAnalysisData={industries}
        graphTitle="Industry mix"
        loading={riskAnalysisLoading}
        className="m-t-l"
      />
      <ParetoGraphWithOutlinedContainer
        riskAnalysisData={tenants}
        graphTitle="Top Revenue Generating Tenants"
        loading={riskAnalysisLoading}
        className="m-t-l"
      />
      <ParetoGraphWithOutlinedContainer
        riskAnalysisData={origins}
        graphTitle="Country of Origin"
        loading={riskAnalysisLoading}
        className="m-t-l"
      />
    </>
  );
};

export default RiskAnalysisGraphs;
