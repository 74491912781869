import { TabPane } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import TabContainer from 'components/TabContainer';
import { updateParameterViewDetailsTab } from 'store/parameters/parameterDetailSlice';
import { PARAMETER_TABLE_IMPLEMENTATION } from 'common/api/miscellaneous';
import ParameterInformationTab from '../CreatorViewer/Tabs/ParameterInformationTab';

export enum PARAMETER_TABS_OFFSET {
  GENERAL = 0,
  APPROVAL = 1,
}

const getParameterTabMenuConfig = (): TabMenuProps[] => {
  return [
    {
      key: '0',
      href: '?tab=general',
      label: 'General',
      dataTest: 'general-tab',
      customTab: <ParameterInformationTab type={PARAMETER_TABLE_IMPLEMENTATION.PARAMETER} />,
    },
    {
      key: '1',
      href: '?tab=approvals',
      label: 'Approvals',
      dataTest: 'approvals-tab',
      customTab: (
        <TabPane attached={false}>
          <TabContainer tabTitle="Approvals">
            <>Approvals</>
          </TabContainer>
        </TabPane>
      ),
    },
  ];
};

const ParametersTabs = (): JSX.Element => {
  const { activeTabIndex } = useAppSelector((state: RootState) => state.parameterDetail);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateParameterViewDetailsTab}
      tabMenu={getParameterTabMenuConfig()}
    />
  );
};
export default ParametersTabs;
