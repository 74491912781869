import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ProvisionDetailClone } from 'common/_classes';

interface GetProvisionQueryVariables {
  id: string;
}

export const GET_PROVISION = (id: string | undefined) => {
  if (!id) {
    throw new Error('Get Provision: id is required');
  }

  const variables: GetProvisionQueryVariables = { id };

  return {
    query: gql`
      query ($id: ID!) {
        getProvision(id: $id) {
          ${ProvisionDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const getProvision = createAsyncThunk('provisions/getProvision', async ({ id }: { id: string | undefined }) => {
  const response = await adminClient.query(GET_PROVISION(id));
  return response;
});
