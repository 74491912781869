import { CDuration } from 'common/_classes/valuationReport2';
import DropdownSelect from 'atoms/DropdownSelect';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { checkNotEmpty } from 'utils/tsValidator';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import { FilterLogicalOperators, FilterMode, FilterOnchangeKeys, RowAction, RowProps } from '../../RowProps';
import { FILTER_OPTIONS, LOGICAL_OPERATORS } from './NumberFilter';

const compare = (filterValue: CDuration, value: CDuration, filterMode?: FilterMode) => {
  if (!filterValue) return true;

  let { years, months, days } = filterValue;

  years = checkNotEmpty(years) && typeof years === 'string' ? parseInt(years) : years;
  months = checkNotEmpty(months) && typeof months === 'string' ? parseInt(months) : months;
  days = checkNotEmpty(days) && typeof days === 'string' ? parseInt(days) : days;

  switch (filterMode) {
    case FilterMode.EQUALS:
      return value.days === days && value.months === months && value.years === years;

    case FilterMode.GREATER_THAN:
      return (
        value.years > years ||
        (value.years === years && value.months > months) ||
        (value.years === years && value.months === months && value.days > days)
      );

    case FilterMode.LESS_THAN:
      return (
        value.years < years ||
        (value.years === years && value.months < months) ||
        (value.years === years && value.months === months && value.days < days)
      );

    case FilterMode.GREATER_THAN_OR_EQUALS:
      return (
        value.years > years ||
        (value.years === years && value.months > months) ||
        (value.years === years && value.months === months && value.days >= days)
      );

    case FilterMode.LESS_THAN_OR_EQUALS:
      return (
        value.years < years ||
        (value.years === years && value.months < months) ||
        (value.years === years && value.months === months && value.days <= days)
      );

    default:
      return true;
  }
};

export function applyDurationFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyDurationFilter: field key is missing');

  let value: CDuration | null = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }

  if (!rowAction.filterValue || typeof rowAction.filterValue === 'string') return true;

  const case1 = compare(rowAction.filterValue, value, rowAction.filterMode);
  const case2 = compare(rowAction.filterValue2, value, rowAction.filterMode2);

  switch (rowAction.filterLogicalOperator) {
    case FilterLogicalOperators.AND:
      return case1 && case2;
    case FilterLogicalOperators.OR:
      return case1 || case2;
    default:
      return case1;
  }
}

const DurationFilter = ({
  filterMode,
  filterMode2,
  onChange,
  filterLogicalOperator,
  filterValue = {
    years: '',
    days: '',
    months: '',
  },
  filterValue2 = {
    years: '',
    days: '',
    months: '',
  },
}: RowFilterChildProps): JSX.Element => {
  return (
    <div>
      <div className="grid">
        <div className="col-4">
          <SelectField
            label="Mode"
            placeholder="Select..."
            fieldKey={FilterOnchangeKeys.filterMode}
            value={filterMode}
            options={FILTER_OPTIONS.filter(({ value }) => value !== filterMode2)}
            onChange={onChange}
            clearable={true}
          />
        </div>

        <div className="col-8">
          <div className="grid">
            <div className="col-4">
              <InputField
                label="Years"
                type={InputFieldType.NUMBER}
                value={filterValue.years}
                placeholder="Years..."
                onChange={(key, years) => onChange(key, { ...filterValue, years })}
                fieldKey={FilterOnchangeKeys.filterValue}
              />
            </div>
            <div className="col-4">
              <InputField
                label="Months"
                type={InputFieldType.NUMBER}
                value={filterValue.months}
                placeholder="Months..."
                onChange={(key, months) => onChange(key, { ...filterValue, months })}
                fieldKey={FilterOnchangeKeys.filterValue}
              />
            </div>
            <div className="col-4">
              <InputField
                label="Days"
                type={InputFieldType.NUMBER}
                value={filterValue.days}
                placeholder="Days..."
                onChange={(key, days) => onChange(key, { ...filterValue, days })}
                fieldKey={FilterOnchangeKeys.filterValue}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid m-b-sm">
        <div className="col-3">
          <DropdownSelect
            placeholder="Select..."
            fieldKey={FilterOnchangeKeys.filterLogicalOperator}
            value={filterLogicalOperator}
            options={LOGICAL_OPERATORS}
            onChange={(key, value) => {
              if (value) {
                onChange(key, value);
              } else {
                onChange(
                  key,
                  {
                    filterLogicalOperator: value,
                    filterMode2: undefined,
                    filterValue2: undefined,
                  },
                  true,
                );
              }
            }}
            clearable={true}
          />
        </div>
      </div>

      {filterLogicalOperator && (
        <div className="grid">
          <div className="col-4">
            <SelectField
              label="Mode"
              placeholder="Select..."
              fieldKey={FilterOnchangeKeys.filterMode2}
              value={filterMode2}
              options={FILTER_OPTIONS.filter(({ value }) => value !== filterMode)}
              onChange={onChange}
              clearable={true}
            />
          </div>

          <div className="col-8">
            <div className="grid">
              <div className="col-4">
                <InputField
                  label="Years"
                  type={InputFieldType.NUMBER}
                  value={filterValue2.years}
                  placeholder="Years..."
                  onChange={(key, years) => onChange(key, { ...filterValue2, years })}
                  fieldKey={FilterOnchangeKeys.filterValue2}
                />
              </div>
              <div className="col-4">
                <InputField
                  label="Months"
                  type={InputFieldType.NUMBER}
                  value={filterValue2.months}
                  placeholder="Months..."
                  onChange={(key, months) => onChange(key, { ...filterValue2, months })}
                  fieldKey={FilterOnchangeKeys.filterValue2}
                />
              </div>
              <div className="col-4">
                <InputField
                  label="Days"
                  type={InputFieldType.NUMBER}
                  value={filterValue2.days}
                  placeholder="Days..."
                  onChange={(key, days) => onChange(key, { ...filterValue2, days })}
                  fieldKey={FilterOnchangeKeys.filterValue2}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DurationFilter;
