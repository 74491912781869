import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { deleteFloor } from 'common/api/floor';
import { Icons } from 'utils/utils-icons';
import { PROPERTY_FLOORS_TAB_VIEW } from '..';
import { PROPERTY_TABS_OFFSET } from '../../PropertyTabs';
import { FloorRow } from './FloorTableConfig';

interface FloorRowActionButtonParams {
  row: FloorRow;
  activeProperty: any;
}

export const FloorRowActionButtons = ({ row, activeProperty }: FloorRowActionButtonParams) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onViewDetails = (): void => {
    const url = `/properties/${activeProperty.id}/details?tabIndex=${PROPERTY_TABS_OFFSET.FLOORS_AND_UNITS}&floorId=${row.floor.id}&view=${PROPERTY_FLOORS_TAB_VIEW.FLOOR_DETAIL}`;
    navigate(url);
  };

  const onDelete = (id: string) => {
    dispatch(deleteFloor({ id }));
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Floor',
      onClick: () => onViewDetails(),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Floor',
      onClick: () => onDelete(row.id),
      isPopup: true,
      popupText: 'Do you really want to delete this Floor?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
