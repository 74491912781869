import moment from 'moment';
import DropdownSelect from 'atoms/DropdownSelect';
import DateField from 'atoms/FormField/DateInput';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import {
  ComparablesTableFilterDropdownOptions,
  FilterLogicalOperators,
  FilterMode,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
} from '../../RowProps';
import { LOGICAL_OPERATORS } from './NumberFilter';

const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Date is', value: FilterMode.EQUALS },
  { key: 2, text: 'Date is not', value: FilterMode.NOT_EQUALS },
  { key: 3, text: 'Date is before', value: FilterMode.LESS_THAN },
  { key: 4, text: 'Date is after', value: FilterMode.GREATER_THAN },
  { key: 5, text: 'Date is same or after', value: FilterMode.GREATER_THAN_OR_EQUALS },
  { key: 6, text: 'Date is same or before', value: FilterMode.LESS_THAN_OR_EQUALS },
];

const compare = (filterValue: Date, value: Date, filterMode?: FilterMode) => {
  switch (filterMode) {
    case FilterMode.EQUALS:
      return moment(value).isSame(filterValue, 'day');
    case FilterMode.NOT_EQUALS:
      return !moment(value).isSame(filterValue, 'day');
    case FilterMode.LESS_THAN:
      return moment(value).isBefore(filterValue, 'day');
    case FilterMode.GREATER_THAN:
      return moment(value).isAfter(filterValue, 'day');
    case FilterMode.GREATER_THAN_OR_EQUALS:
      return moment(value).isSameOrAfter(filterValue, 'day');
    case FilterMode.LESS_THAN_OR_EQUALS:
      return moment(value).isSameOrBefore(filterValue, 'day');
    default:
      return true;
  }
};

export function applyDateFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyDateFilter: field key is missing');

  let value: Date = getNestedValue(content, rowConfig.field);

  if (!value) {
    return false;
  }
  if (!rowAction.filterValue && !rowAction.filterValue2) return true;

  const case1 = compare(rowAction.filterValue, value, rowAction.filterMode);
  const case2 = compare(rowAction.filterValue2, value, rowAction.filterMode2);

  switch (rowAction.filterLogicalOperator) {
    case FilterLogicalOperators.AND:
      return case1 && case2;
    case FilterLogicalOperators.OR:
      return case1 || case2;
    default:
      return case1;
  }
}

const DateFilter = ({
  filterMode,
  filterMode2,
  label,
  filterValue,
  filterValue2,
  filterLogicalOperator,
  onChange,
}: RowFilterChildProps): JSX.Element => {
  return (
    <div>
      <div className="grid">
        <div className="col-6">
          <DropdownSelect
            placeholder="Select..."
            fieldKey={FilterOnchangeKeys.filterMode}
            value={filterMode}
            options={FILTER_OPTIONS.filter(({ value }) => value !== filterMode2)}
            onChange={onChange}
          />
        </div>
        <div className="col-6">
          <DateField
            fieldKey={FilterOnchangeKeys.filterValue}
            value={filterValue}
            onChange={onChange}
            placeholder={'Search By ' + label}
          />
        </div>
      </div>

      <div className="grid m-b-sm">
        <div className="col-3">
          <DropdownSelect
            placeholder="Select..."
            fieldKey={FilterOnchangeKeys.filterLogicalOperator}
            value={filterLogicalOperator}
            options={LOGICAL_OPERATORS}
            onChange={(key, value) => {
              if (value) {
                onChange(key, value);
              } else {
                onChange(
                  key,
                  {
                    filterLogicalOperator: value,
                    filterMode2: undefined,
                    filterValue2: undefined,
                  },
                  true,
                );
              }
            }}
            clearable={true}
          />
        </div>
      </div>

      {filterLogicalOperator && (
        <div className="grid">
          <div className="col-6">
            <DropdownSelect
              placeholder="Select..."
              fieldKey={FilterOnchangeKeys.filterMode2}
              value={filterMode2}
              options={FILTER_OPTIONS.filter(({ value }) => value !== filterMode)}
              onChange={onChange}
            />
          </div>
          <div className="col-6">
            <DateField
              fieldKey={FilterOnchangeKeys.filterValue2}
              value={filterValue2}
              onChange={onChange}
              placeholder={'Search By ' + label}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateFilter;
