import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface UpdatePolicyContent {
  id: string;
}

export const UPDATE_POLICY_CONTENT = (id: string | undefined) => {
  if (!id) throw new Error('UPDATE_POLICY_CONTENT: id is required');

  const variables: UpdatePolicyContent = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        updatePolicyContent(id: $id) {
          contents {
            content
            documentType {
              name
              id
            }
          }
        }
      }
    `,
    variables,
  };
};

export const updatePolicyContent = createAsyncThunk('policies/updatePolicyContent', async (args, { getState }) => {
  const state = getState() as RootState;
  const {
    policyDetail: {
      activePolicy: { id },
    },
  } = state;
  const response = await client.mutate(UPDATE_POLICY_CONTENT(id));
  return response;
});
