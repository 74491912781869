import { IEntityClone } from 'common/_classes/entities';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import AddPlus from 'atoms/AddPlus';
import { TabReact } from 'atoms/TabReact';
import { createContact, updateActiveTab } from 'store/entities/entityDetailSlice';
import Contact from 'common/model/Contact';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';
import ContactPersonForm from './Person';
import './ContactInformationForm.scss';

const ContactInformationForm = ({
  mode,
  entityData,
  isChild,
}: {
  mode: MODE_OPTIONS;
  entityData: IEntityClone;
  isChild?: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activeTab } = useAppSelector((state: RootState) => state.entityDetail);
  const { contacts = [] } = entityData || {};

  const contactsTabs = () => {
    return contacts.map((_contact: Contact, index: number) => {
      return {
        key: `contact-form-${index}`,
        class: 'details-form',
        label: `Contact Person ${index + 1}`,
        customTab: (
          <ContactPersonForm
            contactIndex={index}
            mode={mode}
            entityData={entityData}
            isChild={isChild}
          />
        ),
      };
    });
  };

  return (
    <div className="tabs-with-add-btn">
      <TabReact
        activeTabIndex={activeTab}
        onTabChangeAction={updateActiveTab}
        tabMenu={contactsTabs()}
      />

      {mode === MODE_OPTIONS.EDIT && (
        <AddPlus
          label="Add Contact Person"
          onClick={() => dispatch(createContact())}
          style={{
            position: 'absolute',
            top: '-2.8rem',
            right: '0',
          }}
        />
      )}
    </div>
  );
};

export default ContactInformationForm;
