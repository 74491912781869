import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { convertDateToISO } from 'utils/utils-date';
import { UsagePoint } from './evaluateManagementChargeOrAcFeeFinalCharge';

export const EVALUATE_LENGTH_FROM_DATES = (date1: Date, date2: Date) => {
  const queryVariables = {
    date1: convertDateToISO(date1),
    date2: convertDateToISO(date2),
  };

  return {
    query: gql`
      query ($date1: Date!, $date2: Date!) {
        evaluateLengthFromDates(date1: $date1, date2: $date2)
      }
    `,
    variables: queryVariables,
  };
};

export const evaluateLengthFromDates = createAsyncThunk(
  'contractDetailV2/evaluateLengthFromDates',
  async ({ date1, date2 }: { date1: Date; date2: Date; index?: number; usage: UsagePoint }) => {
    const response = await client.query(EVALUATE_LENGTH_FROM_DATES(date1, date2));
    return response;
  },
);
