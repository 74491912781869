import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import CheckboxField from 'atoms/FormField/Checkbox';
import { Icons } from 'utils/utils-icons';
import { CustomColumnProps } from '..';
import './ColumnToggler.scss';

interface ColumnTogglerProps {
  columnConfig: CustomColumnProps[];
  hideColumns: string[];
  onChange: (headerName: string) => Function;
}
const ColumnToggler = ({ columnConfig, hideColumns, onChange }: ColumnTogglerProps): JSX.Element => {
  const wrapperRef: MutableRefObject<any> = useRef(null);
  const [show, toggle] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent): void => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      toggle(false);
    }
  };

  return (
    <div
      className="table-column-toggler-menu border-sm-gray-light"
      ref={wrapperRef}
    >
      <Button
        className="column-toggler-button m-none color-blue-very-dark p-xs"
        data-test="column-toggle-button"
        onClick={() => toggle(!show)}
      >
        <Icon icon={Icons.FilterRounded} />
      </Button>

      <div className={'dropdown-list ' + (show ? '' : 'd-none')}>
        <Grid>
          {columnConfig.map(({ header, mandatory }, index) => {
            const headerName = header as any;
            const checked = !hideColumns.find(col => col === header) || mandatory || false;
            return (
              <Grid.Row
                key={`${headerName}-${index}`}
                onClick={() => {
                  const setCheck = onChange(headerName);
                  !mandatory && setCheck(!!checked);
                }}
              >
                <Grid.Column width={12}>
                  <span className="dropdown-label">{headerName}</span>
                </Grid.Column>
                <Grid.Column width={3}>
                  <CheckboxField
                    fieldKey=""
                    value={checked}
                    disabled={mandatory}
                  />
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default ColumnToggler;
