import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import DateField from 'atoms/FormField/DateInput';
import { updateFilterDate } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';
import './FilterFloorPlan.scss';

const FilterFloorPlan = ({ mode }: ModeProps) => {
  const { filterDate } = useAppSelector((state: RootState) => state.propertyDetail);
  const dispatch = useAppDispatch();

  const disabled = mode === MODE_OPTIONS.READ;

  return (
    <Grid className="spaces-units-filter m-t-ml m-b-ml">
      <Grid.Row className="pm-none">
        <Grid.Column
          width={5}
          data-test="search-date"
        >
          <DateField
            label="Floorplan and unit date filter"
            fieldKey="searchDate"
            value={filterDate}
            disabled={disabled}
            onChange={(fieldKey, value) => dispatch(updateFilterDate({ value }))}
          />
        </Grid.Column>

        {!disabled && (
          <div
            className="clear-fields color-red-soft m-t-l m-l-xs"
            data-test="clear-date-button"
          >
            <Icon icon={Icons.Delete} />
            <span
              className="text"
              onClick={() => dispatch(updateFilterDate({ value: null }))}
            >
              Clear date
            </span>
          </div>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default FilterFloorPlan;
