import { Colors } from 'utils/utils-colors';

export const AVAILABILITY_STATUS = {
  AVAILABLE: 'AVAILABLE',
  LESS_THAN_SIX: 'LESS_THAN_SIX',
  SIX_TO_TWELVE: 'SIX_TO_TWELVE',
  TWELVE_TO_TWENTY_FOUR: 'TWELVE_TO_TWENTY_FOUR',
  MORE_THAN_TWENTY_FOUR: 'MORE_THAN_TWENTY_FOUR',
};

export const BAR_COLOR = {
  AVAILABLE: Colors.BLUE_DARK_GRAYISH,
  LESS_THAN_SIX: Colors.PINK,
  SIX_TO_TWELVE: Colors.ORANGE,
  TWELVE_TO_TWENTY_FOUR: Colors.YELLOW_BRIGHT,
  MORE_THAN_TWENTY_FOUR: Colors.CYAN_MODERATE,
};

export const HIGHLIGHTED_BAR_COLOR = {
  AVAILABLE: Colors.BLUE_DARK_GRAYISH,
  LESS_THAN_SIX: Colors.RED_MODERATE_DARK,
  SIX_TO_TWELVE: Colors.ORANGE_STRONG,
  TWELVE_TO_TWENTY_FOUR: Colors.YELLOW_STRONG,
  MORE_THAN_TWENTY_FOUR: Colors.CYAN_DARK,
};
