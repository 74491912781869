import { Dropdown, DropdownProps } from 'semantic-ui-react';
import './DropdownSelectFilter.scss';

interface DropdownSelectFilterProps extends Omit<DropdownProps, 'onChange'> {
  fieldKey?: string;
  disabled?: boolean;
  className?: string;
  dataTest?: string;
  onChange: (key: string, value: boolean | number | string | (boolean | number | string)[] | undefined) => void;
}

const DropdownSelectFilter = ({
  fieldKey = '',
  disabled,
  error,
  className,
  placeholder,
  dataTest,
  value,
  options,
  search,
  multiple,
  onChange,
  loading,
  onOpen,
  ...rest
}: DropdownSelectFilterProps) => {
  return (
    <Dropdown
      key={`${fieldKey}-key`}
      className={`dropdown-atom-select-filter ${className}`}
      placeholder={placeholder}
      data-test={dataTest}
      value={value}
      options={options}
      selection={true}
      search={search}
      multiple={multiple}
      onChange={(e, { value }) => onChange(fieldKey, value)}
      loading={loading}
      onOpen={onOpen}
      upward={false}
      {...rest}
    />
  );
};

export default DropdownSelectFilter;
