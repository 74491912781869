import Decimal from 'decimal.js';
import ContractSecurityComponents from 'common/model/ContractSecurityComponents';
import SecurityAmount from 'common/model/SecurityAmount';
import SecurityAmountInput from 'common/model/SecurityAmountInput';

export interface SecurityAmountInputClone
  extends Omit<SecurityAmountInput, 'amount' | 'maxBankGuaranteeAmount' | 'months'> {
  amount: Decimal | null;
  maxBankGuaranteeAmount: Decimal | null;
  months: number | null;
}

export class CSecurityAmount implements SecurityAmountInputClone {
  amount: Decimal | null;
  maxBankGuaranteeAmount: Decimal | null;
  months: number | null;
  components: ContractSecurityComponents[];

  constructor(input: SecurityAmount | null) {
    this.amount = input && input.amount ? new Decimal(input.amount) : null;
    this.maxBankGuaranteeAmount =
      input && input.maxBankGuaranteeAmount ? new Decimal(input.maxBankGuaranteeAmount) : null;
    this.components = input && input.components ? input.components : [];
    this.months = input ? input.months : null;
  }

  static fragment() {
    return `
        amount
        maxBankGuaranteeAmount
        components
        months
    `;
  }

  static formatForAPI(security: CSecurityAmount) {
    return {
      amount: security.amount ? parseFloat(security.amount.toString()) : null,
      maxBankGuaranteeAmount: security.maxBankGuaranteeAmount
        ? parseFloat(security.maxBankGuaranteeAmount.toString())
        : null,
      components: security.components ? security.components : [],
      months: security.months ? parseInt(security.months.toString()) : null,
    };
  }
}
