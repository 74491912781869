import { Editor } from '@tinymce/tinymce-react';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import './EditorBox.scss';

export interface EditorBoxProps {
  editorLoaded: boolean;
  setEditorLoaded: (value: boolean) => void;
  fieldKey?: string;
  placeholder?: string;
  onChange?: any;
  value: string;
  height: string;
  disabled: boolean;
  codeSample?: boolean;
  code?: boolean;
  pasteAsText?: boolean;
}

const EditorBox = ({
  setEditorLoaded,
  editorLoaded,
  fieldKey,
  placeholder,
  onChange,
  value,
  height,
  disabled,
  codeSample,
  code,
  pasteAsText = false,
}: EditorBoxProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [focused, setFocused] = useState<boolean>(false);

  const handleEditorChange = useCallback(
    (content: string) => {
      const chars = {
        '&gt;': '>',
        '&lt;': '<',
      };

      // @ts-ignore
      const replacedContent = content.replace(/&gt;|&lt;/g, m => chars[m]);
      dispatch(onChange(fieldKey, replacedContent));
    },
    [dispatch],
  );

  useEffect(() => {
    const editorBox = document.getElementById(`editor-box-${fieldKey}`);
    if (editorBox !== null) {
      const editor = editorBox.querySelector('.tox-tinymce');
      if (editorLoaded && editor !== null) {
        if (focused) {
          editor.classList.add('focused');
        } else {
          editor.classList.remove('focused');
        }
      }
    }
  }, [editorLoaded, focused]);

  const codeSamplePlugin: string = codeSample ? 'codesample' : '';
  const codePlugin: string = code ? 'code' : '';

  return (
    <div
      id={`editor-box-${fieldKey}`}
      data-test={`editor-box-${fieldKey}`}
      className="editor-box"
    >
      <Editor
        id={`editor-box-${fieldKey}`}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        value={value}
        disabled={disabled}
        init={{
          paste_as_text: pasteAsText,
          menubar: false,
          statusbar: false,
          plugins: `lists ${codeSamplePlugin} ${codePlugin}`,
          placeholder: placeholder,
          height: height,
          toolbar: `${codeSamplePlugin} ${codePlugin} formatselect bold underline italic forecolor backcolor bullist numlist alignleft aligncenter alignright alignjustify`,
          content_style:
            'body { font-family:Urbanist; font-size:14px; color: #041630; font-weight: 400; caret-color: #E69701}',
        }}
        onEditorChange={handleEditorChange}
        onLoadContent={() => setEditorLoaded(true)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
};

export default EditorBox;
