import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

interface ListBenchmarksQueryVariables {
  currentLandlordEntityId: string;
}

export const LIST_BENCHMARKS = () => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Create Benchmark: Current Landlord Entity ID is missing');

  const queryVariables: ListBenchmarksQueryVariables = {
    currentLandlordEntityId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!) {
        listBenchmarks(currentLandlordEntityId: $currentLandlordEntityId) {
          id
          identifier
          address
          description
          size
          endDate
          startDate
          name
          industry {
            id
            name
          }
          rentPerArea
          rentFreePeriod
          securityAmount
          redevelopmentFlag
          assignmentFlag
          refusalFlag
          renewFlag
          subletFlag
          surrenderFlag
          expansionFlag
          hasCentralAc
          hasCurtainWall
          hasMetroCoveredAccess
          landlord {
            id
            name
          }
        }
      }
    `,
    variables: queryVariables,
  };
};

export const listBenchmarks = createAsyncThunk('benchmarks/getAllBenchmarks', async () => {
  const response = await getClientType().query(LIST_BENCHMARKS());
  return response;
});
