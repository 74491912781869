import { useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import Badge, { BadgeColor } from 'atoms/Badge';
import DropdownSelect from 'atoms/DropdownSelect';
import CheckboxField from 'atoms/FormField/Checkbox';
import TabContainer from 'components/TabContainer';
import TransactionAlert from 'common/model/TransactionAlert';
import TransactionAlertType from 'common/model/TransactionAlertType';
import { listTransactionAlerts } from 'common/api/transactions';
import { DROPDOWN_OPTION } from 'utils/UI';
import './AlertsTab.scss';

export const ALERT_CATEGORIES_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, text: 'Alerts', value: 'Alert' },
  { key: 2, text: 'Warning', value: 'Warning' },
  { key: 3, text: 'Info', value: 'Info' },
  { key: 4, text: 'Debug', value: 'Debug' },
];

const AlertsTab = () => {
  const {
    activeTransaction: { alerts, id },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { questionResult } = useAppSelector((state: RootState) => state.miscellaneous);

  const { firstName } = useAppSelector(({ auth }) => auth);

  const [filteredAlerts, updateFilteredAlerts] = useState<TransactionAlert[] | undefined>();
  const [filterValue, updateFilterValue] = useState<string>('Warning');
  const [userAccess, setUserAccess] = useState<boolean>(false);

  useEffect(() => {
    listTransactionAlerts({ id });
    updateFilteredAlerts(alerts);
  }, [alerts]);

  useEffect(() => {
    onApplyFilter(filterValue);
  }, [filterValue]);

  // TODO : temporary code, to select Angy, Allen or Brett, so check if user also
  //        exist as PlatformAdmin instead, if it does then show the button
  useEffect(() => {
    firstName === 'Angy' || firstName === 'Allen' || firstName === 'Brett' ? setUserAccess(true) : setUserAccess(false);
  }, []);

  const onApplyFilter = (value: string) => {
    const showCategories = new Set();
    if (value == 'Debug' || !value) {
      updateFilteredAlerts(alerts);
      return;
    }

    switch (value) {
      case 'Info':
        showCategories.add(TransactionAlertType.Error);
        showCategories.add(TransactionAlertType.Warning);
        showCategories.add(TransactionAlertType.Info);
        break;
      case 'Warning':
        showCategories.add(TransactionAlertType.Error);
        showCategories.add(TransactionAlertType.Warning);
        break;
      case 'Alert':
        showCategories.add(TransactionAlertType.Error);
        break;
    }

    const output = alerts?.filter(alert => showCategories.has(alert.type));
    updateFilteredAlerts(output);
  };

  const getAlertText = (type: TransactionAlertType | undefined) => {
    switch (type) {
      case TransactionAlertType.Error:
        return 'Alerts';
      case TransactionAlertType.Warning:
        return 'Warning';
      case TransactionAlertType.Info:
        return 'Information';
      case TransactionAlertType.Debug:
        return 'Debug';
    }
  };

  const getAlertColor = (type: TransactionAlertType | undefined) => {
    switch (type) {
      case TransactionAlertType.Error:
        return BadgeColor.RED;
      case TransactionAlertType.Warning:
        return BadgeColor.ORANGE;
      case TransactionAlertType.Info:
      case TransactionAlertType.Debug:
        return BadgeColor.GRAY;
    }
  };

  const Alert = ({ alert }: { alert: TransactionAlert }) => {
    return (
      <div className="alert m-b-sm">
        <Badge badgeColor={getAlertColor(alert.type)}>{getAlertText(alert.type)}</Badge>
        {userAccess ? (
          <Popup
            trigger={
              <div
                className="message p-xs m-l-sm"
                dangerouslySetInnerHTML={{ __html: alert.message }}
              ></div>
            }
            hoverable
            position="bottom left"
            className="transaction-alert-popup"
          >
            <pre>{JSON.stringify(alert.detail, null, 2)}</pre>
          </Popup>
        ) : (
          <div
            className="message p-xs m-l-sm"
            dangerouslySetInnerHTML={{ __html: alert.message }}
          />
        )}
      </div>
    );
  };

  const AlertsList = () => {
    if (filteredAlerts && filteredAlerts.length !== 0) {
      return (
        <>
          {filteredAlerts?.map((alert, index) => (
            <Alert
              key={`alert-${index}`}
              alert={alert}
            />
          ))}
        </>
      );
    } else {
      return <span className="no-alert">No Alerts</span>;
    }
  };

  return (
    <TabContainer
      tabTitle={'Alerts'}
      tabToolbar={
        <>
          <CheckboxField
            label="See log data"
            fieldKey=""
            onChange={(e, value) => setUserAccess(value)}
            value={userAccess}
            className="m-t-sm"
          />
          <DropdownSelect
            fieldKey="category-filter"
            value={filterValue}
            options={ALERT_CATEGORIES_OPTIONS}
            onChange={(key, value) => updateFilterValue(value as string)}
            placeholder="Select Level"
            className="alert-level-filter"
          />
        </>
      }
      className="transaction-alerts"
      nested={true}
    >
      <AlertsList />
      {/* TO BE REMOVED */}
      {questionResult !== '' ? (
        <pre className="ai-question-answer-box">
          AI Question Result: {JSON.stringify(questionResult, null, 2) as any}
        </pre>
      ) : (
        <></>
      )}
    </TabContainer>
  );
};

export default AlertsTab;
