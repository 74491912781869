import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { newDevelopment } from 'store/propertiesV2/propertiesV2Slice';
import { getDevelopment } from 'common/api/propertiesV2/getDevelopments';
import CreatorViewerTabs from './CreateViewerTabConfig';

const getPageHeaderProps = (): PageHeaderProps => {
  const breadCrumb: BreadCrumbItem[] = [{ title: 'Owned Properties' }];
  return {
    showBreadCrumb: true,
    breadCrumb,
  };
};

const CreateViewerPage = ({ isViewPage = false }: { isViewPage?: boolean }) => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getDevelopment({ id }));
    } else if (location.pathname.includes('create')) {
      dispatch(newDevelopment({}));
    }
  }, [dispatch, id]);

  return (
    <PageLayout pageHeader={getPageHeaderProps()}>
      <CreatorViewerTabs isViewPage={isViewPage} />
    </PageLayout>
  );
};

export default CreateViewerPage;
