import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import 'components/Editor/Editor.scss';
import TabContainer from 'components/TabContainer';
import { listPolicySequences } from 'common/api/policies';
import SequenceBox from './SequenceBox';

const Sequence = () => {
  const {
    activePolicy: { id },
    activePolicySequences,
  } = useAppSelector((state: RootState) => state.policyDetail);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listPolicySequences({ policyId: id }));
  }, [id]);

  if (activePolicySequences.length === 0) {
    return <h2>No sequence available</h2>;
  } else {
    return (
      <TabContainer tabTitle={'Sequence'}>
        <div className="editor-container p-sm">
          <SequenceBox />
        </div>
      </TabContainer>
    );
  }
};

export default Sequence;
