import { ContractType, DataObjectName, ImportDetailsProps, PropertyType, TenantType } from '../utils-interfaces';

const contractReport = (details: ContractType[]) =>
  details.map(({ identifier }, index) => (
    <li key={`success-${index}`}>
      <span className="success">Imported</span> :{`Contract ${identifier}`}
    </li>
  ));

const tenantReport = (details: TenantType[]) =>
  details.map(({ name, identifier, address }, index) => (
    <li key={`success-${index}`}>
      <span className="success">Imported</span> :{`Tenant ${identifier}, ${name} <-> ${address}`}
    </li>
  ));

const propertyReport = (details: PropertyType[]) =>
  details.map(({ buildingName, buildingIdentifier }, index: number) => (
    <li key={`success-${index}`}>
      <span className="success">Imported</span> :
      {` Property of ${buildingName} of identifier ${buildingIdentifier} is sucessfully imported `}
    </li>
  ));

const ImportDetails = ({ details, dataObjectName }: ImportDetailsProps) => {
  let reportDetails: JSX.Element[] = [];

  if (dataObjectName === DataObjectName.TENANT) {
    reportDetails = tenantReport(details as TenantType[]);
  } else if (dataObjectName === DataObjectName.CONTRACT) {
    reportDetails = contractReport(details as ContractType[]);
  } else {
    reportDetails = propertyReport(details as PropertyType[]);
  }

  return (
    <div className="item">
      <h6 className="title">Succesful import</h6>
      <ul>{details.length === 0 ? <li>No successful import</li> : reportDetails}</ul>
    </div>
  );
};

export default ImportDetails;
