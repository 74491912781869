import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { listProperties, listPropertiesForAdmin } from 'common/api/properties';
import { checkIfAdminURL } from 'utils/utils-admin';
import { PropertiesRow, propertyColumnConfig } from './PropertiesTableConfig';

const PropertiesTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, propertiesList } = useAppSelector((state: RootState) => state.propertiesListing);

  const isAdmin = checkIfAdminURL();
  const currentList: PropertiesRow[] = propertiesList.map(property => new PropertiesRow(property));

  const onViewDetails = (id: string): void => {
    if (!isAdmin) {
      const url = `/properties/${id}/details`;
      navigate(url);
    }
  };

  const onListProperties = (params: PaginationAPIProps) => {
    const listPropertiesCall = isAdmin ? listPropertiesForAdmin : listProperties;

    // @ts-ignore
    dispatch(listPropertiesCall({ ...params }));
  };

  return (
    <div
      className="property-listing"
      data-test="properties-data-table"
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          onListProperties(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={propertyColumnConfig(onViewDetails)}
        onRowClick={onViewDetails}
      />
    </div>
  );
};

export default PropertiesTable;
