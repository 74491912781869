import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';

//TODO this is related to Policy and Transaction, hence should be in a different store and different subfolder

interface ListContextParametersQueryVariables {
  context: ExecuteContext;
  contextId: string | undefined;
  landlordId: string | null;
  provisionId: string | null;
  conditional: boolean;
  mode: ParameterModes;
  groupIds: string[];
}

export const LIST_CONTEXT_PARAMETERS = (
  context: ExecuteContext,
  contextId: string | undefined,
  provisionId: string | null,
  conditional: boolean,
  mode: ParameterModes,
  activeGroupIds: string[],
) => {
  const variables: ListContextParametersQueryVariables = {
    context,
    contextId,
    landlordId: getCurrentLandlordEntityId(),
    provisionId,
    conditional,
    mode,
    groupIds: activeGroupIds,
  };

  return {
    query: gql`
      query (
        $context: ExecuteContext!
        $contextId: ID!
        $landlordId: ID!
        $provisionId: ID
        $conditional: Boolean!
        $mode: ParameterModes!
        $groupIds: [ID!]
      ) {
        listContextParameters(
          context: $context
          contextId: $contextId
          landlordId: $landlordId
          provisionId: $provisionId
          conditional: $conditional
          mode: $mode
          groupIds: $groupIds
        ) {
          parameters {
            id
            name
            answerType
            choices {
              id
              default
              text
              archive
              index
            }
            index
            mandatoryChoice
            internalOriginFlag
            internalOriginId
            parameterGroup {
              id
              name
              index
              category {
                id
                name
                color
                index
              }
            }
            parameterTableColumns {
              title
              parameter {
                id
              }
              table {
                id
              }
            }
            guideline {
              explanatoryNotes
              generalGuidance
              internalNotes
              referenceType
              valuationGuidance
            }
            type
            question
            questionNote
            unit
            hasInventory
            inventoryFunction
            provisionExistence
            insertedAt
            updatedAt
          }
          parameterTables {
            id
            name
            type
            description
            parameterGroup {
              id
              name
              index
              category {
                id
                name
                color
                index
              }
            }
            index
            rowNumber {
              parameter {
                id
                name
                answerType
                question
              }
              table {
                id
                name
              }
            }
            columns {
              title
              applicableRowIndexes
              parameter {
                id
                question
                questionNote
                answerType
                index
                choices {
                  id
                  default
                  text
                  archive
                  index
                }
                parameterTableColumns {
                  id
                }
                guideline {
                  explanatoryNotes
                  generalGuidance
                  internalNotes
                  referenceType
                  valuationGuidance
                }
              }
              table {
                id
              }
            }
            insertedAt
            updatedAt
          }
        }
      }
    `,
    variables,
  };
};

export const listContextParameters = createAsyncThunk(
  'parametersTab/listContextParameters',
  async (
    {
      context,
      contextId,
      provisionId,
      conditional,
      mode,
    }: {
      context: ExecuteContext;
      contextId: string | undefined;
      provisionId: string | null;
      conditional: boolean;
      mode: ParameterModes;
    },
    { getState },
  ) => {
    const {
      transactionDetail: { activeGroupIds },
    } = getState() as RootState;

    const response = await getClientType().query(
      LIST_CONTEXT_PARAMETERS(context, contextId, provisionId, conditional, mode, activeGroupIds),
    );
    return { ...response.data, activeGroupIds };
  },
);
