import { TProvisionOnlyId } from 'common/_classes';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardViewTemplate, { CardTemplateConfiguration } from 'components/CardViewTemplate';
import Amendment from 'common/model/Amendment';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { deleteAmendment } from 'common/api/transactions';
import { Colors } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { singleCategoryList } from './ProvisionsBox';

const Amendments = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { activeTransaction, activeTransactionAmendments } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );
  const { provisionCategoriesList } = useAppSelector((state: RootState) => state.provisionsListing);

  const amendments: Amendment[] = activeTransactionAmendments || [];

  const orderedAmendments: Amendment[] = orderBy(
    amendments,
    amendment => {
      return amendment.index;
    },
    'asc',
  );

  const onDeleteAmendment = (amendement: Amendment): void => {
    dispatch(deleteAmendment(amendement.id!));
  };

  const onViewAmendment = (amendment: Amendment): void => {
    navigate(`/transactions/${activeTransaction.id}/amendment/${amendment.id}/details`);
  };

  const cardHeaderTemplate = (amendment: Amendment): JSX.Element => {
    return (
      <div className="column-custom-template">
        <Popup
          className="popup-info"
          content={amendment.name}
          trigger={<span className="name color-black">{amendment.name}</span>}
        />
      </div>
    );
  };

  const cardMenuItems = (amendment: Amendment): DropdownMenuItem[] => {
    return [
      {
        key: '1',
        label: 'View Details',
        icon: Icons.EyeOpen,
        onClick: () => onViewAmendment(amendment),
      },
      {
        key: '2',
        label: 'Delete',
        icon: Icons.Delete,
        onClick: () => onDeleteAmendment(amendment),
      },
    ];
  };

  const cardBodyTemplate = (amendment: Amendment): JSX.Element => {
    return (
      <Grid className="component-card-body color-blue-desaturated-dark">
        <Grid.Row className="p-none">
          <Grid.Column
            className="p-none"
            width={5}
          >
            Appears in:
          </Grid.Column>
          <Grid.Column
            className="p-none color-blue-very-dark-grayish"
            width={11}
          >
            {amendment.documentType.name}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const cardConfigs: CardTemplateConfiguration<ProvisionCategoryClone, Amendment> = {
    columnConfiguration: {
      header: {
        titleField: 'name',
        showEdit: false,
      },
      width: '20rem',
    },
    cardConfiguration: {
      header: {
        headerTemplate: (amendment: Amendment) => cardHeaderTemplate(amendment),
        cardMenuItems: (amendment: Amendment) => cardMenuItems(amendment),
      },
      bodyTemplate: (amendment: Amendment) => cardBodyTemplate(amendment),
      onClick: (amendment: Amendment) => {},
    },
  };

  const createAmendmentUrl: string = `/transactions/${activeTransaction.id}/amendment/create`;

  const createAmendment = (e: React.SyntheticEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    navigate(createAmendmentUrl);
  };

  return (
    <div className="transaction-provision-section border-sm-grayish-magenta-light">
      <h4 className="provisions-title color-blue-very-dark m-none">Custom Amendments</h4>
      <Button
        className="btn grey-bg add-remove"
        data-test="edit-button"
        onClick={createAmendment}
      >
        Add
      </Button>
      <CardViewTemplate<ProvisionCategoryClone, Amendment>
        categoryList={singleCategoryList(provisionCategoriesList, Colors.YELLOW)}
        configuration={cardConfigs}
        getCardElementsList={() => orderedAmendments}
      />
    </div>
  );
};

export default Amendments;
