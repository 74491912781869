import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import { updateInput } from 'store/authentication/authSlice';
import { getProfile, signIn } from 'common/api/authentication';
import { updateMemberUser } from 'common/api/members';
import { signInValid } from 'utils/utils-credential';
import { Icons } from 'utils/utils-icons';

const ContinueWithEmail = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, password } = useAppSelector((state: RootState) => state.auth);
  const [type, setType] = useState<InputFieldType>(InputFieldType.PASSWORD);

  const signInAndUpdateMember = (): void => {
    dispatch(signIn()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(updateMemberUser());
        dispatch(getProfile());
        const defaultEntity = response.payload.data.login.user.defaultEntity;
        if (defaultEntity === null) {
          navigate('/welcome');
        } else {
          navigate('/landlord-entities/dashboard');
        }
      }
    });
  };

  return (
    <Form
      className="auth-form"
      step="continue"
      onSubmit={() => signInAndUpdateMember()}
    >
      <div className="content">
        <h1
          className="header"
          {...{ step: 'continue' }}
        >
          Continue with
          <br /> another email <Icon icon={Icons.CircleDownFilled} />
        </h1>
        <p className="reset-text">
          You continue your invitation flow with another email. Please enter current email and password:
        </p>
        <Form.Field className="reset-email">
          <label>
            Email <span className="required">*</span>
          </label>
          <Input
            value={email}
            placeholder="Enter your email..."
            type={InputFieldType.EMAIL}
            onChange={e => dispatch(updateInput({ type: 'email', value: e.target.value }))}
            required={true}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Password <span className="required">*</span>
          </label>
          <Input
            value={password}
            icon={
              <Icon
                icon={type === InputFieldType.PASSWORD ? Icons.Eye : Icons.EyeSlash}
                onClick={() =>
                  setType(type === InputFieldType.PASSWORD ? InputFieldType.TEXT : InputFieldType.PASSWORD)
                }
              />
            }
            placeholder="Enter your password..."
            type={type}
            onChange={e => dispatch(updateInput({ type: 'password', value: e.target.value }))}
            required={true}
          />
        </Form.Field>
        <Button
          className="login-btn height-2_857 font-600 text-center"
          type="submit"
          disabled={!signInValid(email, password)}
        >
          CONTINUE
          <Icon
            icon={Icons.ArrowDownRight}
            rotate={4}
            className="width-1_143 height-1_143"
          />
        </Button>
      </div>
    </Form>
  );
};

export default ContinueWithEmail;
