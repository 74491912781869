import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface UploadTenantDocxMutationVariables {
  uploadFile: File;
  documentTypeId: string;
  transactionId: string | undefined;
}

export const UPLOAD_TENANT_DOCX = (uploadFile: File, documentTypeId: string, transactionId: string | undefined) => {
  const variables: UploadTenantDocxMutationVariables = {
    uploadFile,
    documentTypeId,
    transactionId,
  };

  return {
    mutation: gql`
      mutation ($uploadFile: Upload!, $documentTypeId: ID!, $transactionId: ID!) {
        uploadTenantDocx(uploadFile: $uploadFile, documentTypeId: $documentTypeId, transactionId: $transactionId) {
          id
          index
          provisionName
          type
          documentType {
            id
          }
          transaction {
            id
          }
          transactionChannels {
            id
            title
            clauseNumber
            index
            status
            type
            documentTypeId
            amendment {
              id
            }
            node {
              id
            }
            messages {
              id
              message
              updatedAt
            }
          }
        }
      }
    `,
    variables,
  };
};

export const uploadTenantDocx = createAsyncThunk(
  'transactions/uploadTenantDocx',
  async ({
    file,
    documentTypeId,
    transactionId,
  }: {
    file: File;
    documentTypeId: string;
    transactionId: string | undefined;
  }) => {
    const response = await client.mutate(UPLOAD_TENANT_DOCX(file, documentTypeId, transactionId));
    return response;
  },
);
