import { ParameterTableListingClone, RowNumberClone } from 'common/_classes';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { listParameterTablesPage } from 'common/api/parameters';
import {
  ParametersTableRow,
  TableConstraintInfo,
  getTableRowNumberConstraint,
  parametersColumnConfig,
} from './ParametersTableConfig';
import './ParameterTablesListing.scss';

const ParametersTableListing = ({ setParamId }: { setParamId: () => void }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, parameterTablesList } = useAppSelector((state: RootState) => state.parametersListing);
  const currentList: ParametersTableRow[] = parameterTablesList.map(
    (parameterTable: ParameterTableListingClone) => new ParametersTableRow(parameterTable),
  );

  const onFetchParameters = (params: PaginationAPIProps): void => {
    // @ts-ignore
    dispatch(listParameterTablesPage({ ...params }));
  };

  const getRowConstraint = (rowNumber: RowNumberClone): JSX.Element => {
    const constraintType: TableConstraintInfo = getTableRowNumberConstraint(rowNumber);
    const spanProps = { type: constraintType?.type };
    if (!constraintType) return <></>;
    return (
      <div className="constraint">
        <span
          className="name"
          {...spanProps}
        >
          {constraintType?.typeText}
        </span>
        <span
          className="link"
          onClick={() => constraintType.link && navigate(constraintType.link)}
          role="button"
        >
          {constraintType?.name}
        </span>
      </div>
    );
  };

  // const onViewDetails = (id: string): void => {
  //   const url = `/parameters/table/${id}/details`;
  //   navigate(url);
  // };

  return (
    <div className="m-t-xl parameters-tables-listing">
      <TableReactPrime
        fetchContent={({ ...rest }) => {
          onFetchParameters(rest);
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={parametersColumnConfig(getRowConstraint, setParamId)}
        dataTest="dashboard-table"
        onRowClick={setParamId}
      />
    </div>
  );
};

export default ParametersTableListing;
