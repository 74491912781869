import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteParameterTableQueryVariables {
  parameterTableId: string;
}

export const DELETE_PARAMETER_TABLE = (id: string) => {
  if (!id) throw new Error('DELETE_PARAMETER_TABLE: id is required');

  const variables: DeleteParameterTableQueryVariables = { parameterTableId: id };

  return {
    mutation: gql`
      mutation ($parameterTableId: ID!) {
        deleteParameterTable(parameterTableId: $parameterTableId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteParameterTable = createAsyncThunk(
  'parameters/deleteParameterTable',
  async ({ id }: { id: string }) => {
    const response = await adminClient.mutate(DELETE_PARAMETER_TABLE(id));
    return response;
  },
);
