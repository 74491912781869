import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface ListRolesQueryVariables {
  currentLandlordEntityId: string;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  sortOrder: SortOrder;
  roleFilter: { search?: string };
}

export const LIST_ROLES = (
  includeApprovalLevel: boolean,
  search: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('List Roles: currentLandlordEntityId is required');

  const variables: ListRolesQueryVariables = {
    currentLandlordEntityId,
    sortOrder: SortOrder.Asc,
    first,
    last,
    after,
    before,
    roleFilter: { search: search || undefined },
  };

  const extras = includeApprovalLevel
    ? `
  approvalLevel
  members {
    id
    user {
      id
      firstName
      lastName
      photoUrl
    }
  }
`
    : '';

  return {
    query: gql`
          query($currentLandlordEntityId: ID!, $first: Int, $last: Int, $after: String, $before: String, $roleFilter: RoleFilter) {
          listRoles(currentLandlordEntityId: $currentLandlordEntityId, first: $first, last: $last, after: $after, before: $before, roleFilter: $roleFilter){
            edges{
              cursor
              node { 
                id   
                name
                ${extras}
              }
            }
            pageInfo{
              startCursor
              endCursor
              hasNextPage
              hasPreviousPage
            }
          }
        },
      `,
    variables,
  };
};

export const listRoles = createAsyncThunk(
  'roles/listRoles',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      roles: { search },
    } = getState() as RootState;

    const response = await client.query(LIST_ROLES(true, search, first, last, after, before));

    return response;
  },
);

export const listRolesOptions = createAsyncThunk('roles/listRolesOptions', async (arg, { getState }) => {
  const {
    roles: { search },
  } = getState() as RootState;

  const response = await client.query(LIST_ROLES(false, search, 10000));

  return response;
});
