import FormattersCreator from 'views/formatters/CreatorViewer';
import FormattersDashboard from 'views/formatters/Dashboard';
import { isAuthenticated } from './redirects';

const FormattersRoutes = () => [
  {
    path: '/formatters',
    children: [
      {
        path: '/formatters/dashboard',
        element: isAuthenticated(<FormattersDashboard />),
      },
      {
        path: '/formatters/create',
        element: isAuthenticated(<FormattersCreator />),
      },
      {
        path: '/formatters/:id/details',
        element: isAuthenticated(<FormattersCreator isViewPage={true} />),
      },
    ],
  },
];

export default FormattersRoutes;
