import { ChartType, ScriptableTooltipContext } from 'chart.js';
import { RiskAnalysisData } from 'common/api/dashboard';
import { getNumberWithOrdinal, removeDecimals } from 'utils/utils-number';

const getPercentage = (val: string) => (parseFloat(val) * 100).toFixed(1);

export const externalTooltip = (context: ScriptableTooltipContext<ChartType>, id: string, data: RiskAnalysisData[]) => {
  // Tooltip Element
  let tooltipEl = document.getElementById(id);

  if (!tooltipEl) {
    let parentElement = document.getElementById('analytics-dashboard');
    tooltipEl = document.createElement('div');
    tooltipEl.id = id;
    tooltipEl.innerHTML = '<table></table>';
    if (parentElement) {
      parentElement.appendChild(tooltipEl);
    }
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0';
    tooltipEl.style.display = 'none';
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  let left;
  let innerHtml = '<div class="inner-html">';

  // Set Text
  if (tooltipModel.body) {
    const index = tooltipModel.dataPoints[0].dataIndex;
    const item = data[index];

    innerHtml += `<h2>${item.reference}</h2>`;

    innerHtml += `<p class="second">Amount: <span>${removeDecimals(item.revenue)} HK$</span></p>`;

    innerHtml += `<p class="second">Rank: <span>${getNumberWithOrdinal(index + 1)}</span></p>`;

    innerHtml += `<p class="second">% contribution: <span>${getPercentage(item.contribution)} %</span></p>`;

    innerHtml += `<p class="second">% cumalative contribution: <span>${getPercentage(
      item.accumulativeContribution,
    )} %</span></p>`;

    if (tooltipModel.caretX + 500 > window.innerWidth) {
      left = -380;
    }
  }

  innerHtml += '</div>';

  let tableRoot = tooltipEl.querySelector('table');

  if (tableRoot) {
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.display = 'inline';
  tooltipEl.style.left = position.left + tooltipModel.caretX + (left ? left : 0) + 'px';

  let topPosition = position.top + window.scrollY + tooltipModel.caretY - tooltipEl.clientHeight;

  tooltipEl.style.top = topPosition + 'px';

  tooltipEl.style.pointerEvents = 'none';
};
