import Decimal from 'decimal.js';
import AcFee from 'common/model/AcFee';
import AcFeeInput from 'common/model/AcFeeInput';
import RateType from 'common/model/RateType';
import { convertDateToISO } from 'utils/utils-date';

export interface AcFeeInputClone
  extends Omit<
    AcFeeInput,
    | 'finalCharge'
    | 'outOfHoursFee'
    | 'standardHours'
    | 'standardRate'
    | 'standardRateDiscount'
    | 'type'
    | 'startDate'
    | 'endDate'
  > {
  finalCharge: Decimal | null;
  outOfHoursFee: Decimal | null;
  standardHours: Decimal | null;
  standardRate: Decimal | null;
  standardRateDiscount: Decimal | null;
  type: RateType | null;
  startDate: Date | null;
  endDate: Date | null;
}

export class CAcFee implements AcFeeInputClone {
  finalCharge: Decimal | null;
  outOfHoursFee: Decimal | null;
  standardHours: Decimal | null;
  standardRate: Decimal | null;
  standardRateDiscount: Decimal | null;
  type: RateType | null;
  startDate: Date | null;
  endDate: Date | null;

  constructor(input: AcFee | null) {
    this.finalCharge = input && input.finalCharge ? new Decimal(input.finalCharge) : null;
    this.outOfHoursFee = input && input.outOfHoursFee ? new Decimal(input.outOfHoursFee) : null;
    this.standardHours = input && input.standardHours ? new Decimal(input.standardHours) : null;
    this.standardRate = input && input.standardRate ? new Decimal(input.standardRate) : null;
    this.standardRateDiscount = input && input.standardRateDiscount ? new Decimal(input.standardRateDiscount) : null;
    this.type = input ? input.type : null;
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
  }

  static fragment() {
    return `
      finalCharge
      outOfHoursFee
      standardHours
      standardRate
      standardRateDiscount
      type
      startDate
      endDate
    `;
  }

  static formatForAPI(acfee: CAcFee) {
    return {
      finalCharge: acfee.finalCharge ? parseFloat(acfee.finalCharge.toString()) : null,
      outOfHoursFee: acfee.outOfHoursFee ? parseFloat(acfee.outOfHoursFee.toString()) : null,
      standardHours: acfee.standardHours ? parseFloat(acfee.standardHours.toString()) : null,
      standardRate: acfee.standardRate ? parseFloat(acfee.standardRate.toString()) : null,
      standardRateDiscount: acfee.standardRateDiscount ? parseFloat(acfee.standardRateDiscount.toString()) : null,
      type: acfee.type,
      endDate: convertDateToISO(acfee.endDate),
      startDate: convertDateToISO(acfee.startDate),
    };
  }
}
