import { Image } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';
import UploadImgLeft from 'assets/images/svg/upload-image-left-small.svg';

const UpdateContract = ({ onUpdate }: { onUpdate: () => void }) => {
  return (
    <div
      className="contract-item add-item"
      onClick={onUpdate}
    >
      <Image
        className="img"
        src={UploadImgLeft}
      />

      <div className="content">
        <div className="font-600 font-size-1_1429 color-blue-very-dark-grayish">Update</div>
        <button className="icon-wrapper">
          <Icon icon={Icons.Add} />
        </button>
      </div>
    </div>
  );
};

export default UpdateContract;
