import { ModeProps } from 'common/api/miscellaneous';
import AssignmentRights from './AssignmentRights';
import ExpansionRights from './ExpansionRights';
import OptionToRenew from './OptionToRenew';
import RefusalRights from './RefusalRight';
import SaleAndRedevelopmentRight from './SaleAndRedevelopmentRight';
import ShareRights from './ShareRights';
import SubletRights from './SubletRights';
import SurrenderRights from './SurrenderRights';

const SpecialClauses = ({ mode }: ModeProps) => {
  return (
    <div className="d-flex-column flex-gap-sm">
      <OptionToRenew mode={mode} />
      <SurrenderRights mode={mode} />
      <RefusalRights mode={mode} />
      <ExpansionRights mode={mode} />
      <ShareRights mode={mode} />
      <SubletRights mode={mode} />
      <AssignmentRights mode={mode} />
      <SaleAndRedevelopmentRight mode={mode} />
    </div>
  );
};

export default SpecialClauses;
