import { CShareRights } from 'common/_classes/contractsV2';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { conditionalClassName } from 'utils/tsHelper';
import ShareRightsForm from './ShareRightsForm';

const ShareRights = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const shareFlag = activeContractEvent?.shareFlag || false;

  const disabled = mode === MODE_OPTIONS.READ;

  const toggleSwitch = (_key: string, value: boolean) => {
    dispatch(updateActiveContractEventInput({ key: 'shareRights', value: value ? new CShareRights(null) : null }));
    dispatch(updateActiveContractEventInput({ key: 'shareFlag', value }));
  };

  return (
    <div className={conditionalClassName(shareFlag, 'border-bottom-sm-grayish-magenta-light p-b-s')}>
      <div className="grid ">
        <div className="col-2  d-flex justify-space-between">
          <label className="font-600 font-size-1_1429">Right to share</label>

          <CheckboxField
            className="d-flex align-center"
            dataTest=""
            fieldKey=""
            value={shareFlag}
            toggle={true}
            disabled={disabled}
            onChange={toggleSwitch}
          />
        </div>
      </div>

      {shareFlag && <ShareRightsForm mode={mode} />}
    </div>
  );
};

export default ShareRights;
