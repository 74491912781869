import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import Decimal from 'decimal.js';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sortBy } from 'lodash';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { checkNotMessageAnswer } from 'components/ParametersGroupQuestionsForm';
import { ApprovalView } from 'store/transactions/transactionDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import ParamRef from 'common/model/ParamRef';
import Parameter from 'common/model/Parameter';
import ParameterChoice from 'common/model/ParameterChoice';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import User from 'common/model/User';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { AnswerProps } from 'common/api/policies';
import { updateTransactionApprovalAnswersState } from 'common/api/transactions/approvals/updateTransactionApprovalAnswersStatus';
import { getAnswerValue } from 'utils/utils-answer';
import { ColorNames } from 'utils/utils-colors';
import { getCurrentDateAndTime } from 'utils/utils-date';
import { Icons } from 'utils/utils-icons';
import ActionButtons from './ActionButtons';
import ApprovalItemResponseModal from './ApprovalItemResponseModal';
import './ChangesTable.scss';

export interface ApprovalRow {
  id: string;
  parameterGroupName: string | undefined;
  parameterName: string | undefined;
  previousValue: any;
  newValue: any;
  approvalState: ValidApprovalStates;
  note: string;
  user: User;
  dateOfAnswer: string;
}

export const updateGroupStatus = (
  approvalAnswerIds: string[],
  approvalState: ValidApprovalStates,
  dispatch: Dispatch<AnyAction>,
): void => {
  if (approvalAnswerIds.length !== 0) {
    dispatch(updateTransactionApprovalAnswersState({ approvalAnswerIds, approvalState }));
  }
};

export const getAnswerWithChangedValue = (previousValue: any, newValue: any, answerType: string) => {
  switch (answerType) {
    case AnswerTypes.Number:
    case AnswerTypes.NumberPercent:
    case AnswerTypes.NumberUnit:
      if (previousValue) {
        const changedValue = newValue - previousValue;
        const floatValue = parseFloat(String(changedValue));
        const sign = floatValue > 0 ? '+' : '-';
        return `${newValue} (${previousValue} ${sign} ${Math.abs(floatValue)})`;
      } else {
        return newValue;
      }
    default:
      return newValue;
  }
};

const ChangesTable = ({ rows }: { rows: TransactionApprovalAnswer[] }) => {
  const dispatch = useAppDispatch();
  const { approvalView, isLoading, activeTransactionAnswers } = useAppSelector(
    (state: RootState) => state.transactionDetail,
  );
  const { allParameters } = useAppSelector((state: RootState) => state.parametersTab);

  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [activeRowId, setActiveRowId] = useState('');

  const reviewer: boolean = approvalView === ApprovalView.Reviewer;
  const approvalAnswerIds = rows.map((row: TransactionApprovalAnswer) => row.id);
  const pendingApprovalAnswerIds = rows
    .filter((row: TransactionApprovalAnswer) => row.approvalState === ValidApprovalStates.Pending)
    .map((row: TransactionApprovalAnswer) => row.id);

  const buttonsHeader: TableActionButton[] = [
    {
      icon: Icons.Check,
      color: ColorNames.GREEN,
      tooltip: 'Approve Change',
      onClick: () => updateGroupStatus(pendingApprovalAnswerIds, ValidApprovalStates.Approved, dispatch),
    },
    {
      icon: Icons.Cross,
      color: ColorNames.RED,
      tooltip: 'Reject Change',
      onClick: () => updateGroupStatus(pendingApprovalAnswerIds, ValidApprovalStates.Rejected, dispatch),
    },
    {
      icon: Icons.Reset,
      color: ColorNames.BLACK,
      tooltip: 'Reset Change',
      onClick: () => updateGroupStatus(approvalAnswerIds, ValidApprovalStates.Pending, dispatch),
    },
  ];

  const buttonsHeaderApproved: TableActionButton[] = [
    {
      icon: Icons.Check,
      color: ColorNames.GREEN,
      tooltip: 'Approved',
      onClick: () => updateGroupStatus(approvalAnswerIds, ValidApprovalStates.Rejected, dispatch),
    },
    {
      icon: Icons.Reset,
      color: ColorNames.BLACK,
      tooltip: 'Reset Change',
      onClick: () => updateGroupStatus(approvalAnswerIds, ValidApprovalStates.Pending, dispatch),
    },
  ];

  const buttonsHeaderRejected: TableActionButton[] = [
    {
      icon: Icons.Cross,
      color: ColorNames.RED,
      tooltip: 'Rejected',
      onClick: () => updateGroupStatus(approvalAnswerIds, ValidApprovalStates.Approved, dispatch),
    },
    {
      icon: Icons.Reset,
      color: ColorNames.BLACK,
      tooltip: 'Reset Change',
      onClick: () => updateGroupStatus(approvalAnswerIds, ValidApprovalStates.Pending, dispatch),
    },
  ];

  const buttonReset: TableActionButton[] = [
    {
      icon: Icons.Reset,
      color: ColorNames.BLACK,
      tooltip: 'Reset Change',
      onClick: () => updateGroupStatus(approvalAnswerIds, ValidApprovalStates.Pending, dispatch),
    },
  ];

  const getHeaderButton = (): TableActionButton[] => {
    const size = rows.length;
    let approved = 0,
      rejected = 0,
      pending = 0;

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].approvalState === ValidApprovalStates.Pending) {
        pending += 1;
      } else if (rows[i].approvalState === ValidApprovalStates.Approved) {
        approved += 1;
      } else if (rows[i].approvalState === ValidApprovalStates.Rejected) {
        rejected += 1;
      }
    }

    if (pending > 0) {
      return buttonsHeader;
    }

    if (approved === size) {
      return buttonsHeaderApproved;
    } else if (rejected === size) {
      return buttonsHeaderRejected;
    }
    // All approved and rejected
    else {
      return buttonReset;
    }
  };

  const actionText = () => {
    if (reviewer) {
      const headerButton = getHeaderButton();

      return <TableActionButtons buttons={headerButton} />;
    }

    return 'Response';
  };

  const changesColumnConfig = (): CustomColumnProps[] => {
    return [
      {
        field: 'parameterGroupName',
        header: 'Parameter Group',
        filterPlaceholder: 'Search by Group Name',
        filter: true,
        filterField: 'parameterGroupName',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        field: 'parameterName',
        header: 'Parameter',
        filter: true,
        filterPlaceholder: 'Search by Parameter',
        filterField: 'parameterName',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        field: 'previousValue',
        header: 'Previous Value',
        filter: true,
        filterPlaceholder: 'Search by Previous Value',
        filterField: 'previousValue',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        field: 'newValue',
        header: 'New Value',
        filter: true,
        filterPlaceholder: 'Search by New Value',
        filterField: 'newValue',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        mandatory: true,
        field: 'options',
        header: actionText(),
        frozen: true,
        alignFrozen: 'right',
        body: (row: ApprovalRow) => {
          if (row.approvalState === ValidApprovalStates.Pending && approvalView === ApprovalView.Submitter) {
            return 'Pending';
          } else {
            return (
              <ActionButtons
                row={row}
                reviewer={reviewer}
                setOpenCommentModal={setOpenCommentModal}
                setActiveRowId={setActiveRowId}
              />
            );
          }
        },
      },
    ];
  };

  const getPreviousValue = (
    paramRef: ParamRef,
    messageId: string,
    answerType: AnswerTypes,
    choices: ParameterChoice[] | undefined,
    dateOfAnswer: Date,
  ) => {
    // sorting is needed check where all is needed
    let history: AnswerProps[] = activeTransactionAnswers.filter(
      (answer: AnswerProps) =>
        answer.answerType === answerType &&
        answer.paramRef.parameterId === paramRef.parameterId &&
        answer.paramRef.tableId === paramRef.tableId &&
        answer.paramRef.index === paramRef.index &&
        dateOfAnswer >= answer.dateOfAnswer,
    );

    if (messageId) {
      history = history.filter(
        (answer: AnswerProps) => checkNotMessageAnswer(answer) || answer?.transactionMessage?.id === messageId,
      );
    }

    history = sortBy(history, 'dateOfAnswer');

    let oldAnswer = null as AnswerProps | null;

    if (history.length > 1) {
      oldAnswer = history[history.length - 2];
    }

    const oldAnswerValue: any =
      oldAnswer === null ? '' : getAnswerValue(oldAnswer.answer, oldAnswer.answerType, choices);

    return oldAnswerValue;
  };

  /*
   * Convert/prepare list of data
   */
  const currentList = rows.map((row: TransactionApprovalAnswer): ApprovalRow => {
    const parameter: Parameter | undefined = allParameters.find(
      (parameter: Parameter) => row.paramRef.parameterId === parameter.id,
    );

    const previousValue = getPreviousValue(
      row.paramRef,
      row?.transactionMessage?.id,
      row.answerType,
      parameter?.choices,
      row.dateOfAnswer,
    );

    const newValue = getAnswerValue(row.answer, row.answerType, parameter?.choices);

    const result: ApprovalRow = {
      id: row.id,
      parameterGroupName: parameter?.parameterGroup.name,
      parameterName: parameter?.name,
      previousValue: previousValue,
      newValue: getAnswerWithChangedValue(previousValue, newValue, row.answerType),
      approvalState: row.approvalState,
      note: row.note,
      user: row.user,
      dateOfAnswer: getCurrentDateAndTime(row.dateOfAnswer),
    };

    return result;
  });

  const activeRow = currentList.find((row: ApprovalRow) => row.id === activeRowId);

  return (
    <>
      <TableReactPrime
        isFetching={isLoading}
        content={currentList}
        columnConfig={changesColumnConfig()}
        showPagination={false}
      />

      {activeRow && (
        <ApprovalItemResponseModal
          openCommentModal={openCommentModal}
          setOpenCommentModal={setOpenCommentModal}
          row={activeRow}
        />
      )}
    </>
  );
};

export default ChangesTable;
