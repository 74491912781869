import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { LIST_PROPERTIES_FOR_CONTRACT_CREATION } from 'common/api/properties';

// TODO, shall we move that list to miscellaneous store ?
// NOTE: The aove has been done. Its called 'listLandlordProperties' in miscellaneous directory
export const listProperties = createAsyncThunk(
  'contracts/listProperties',
  async ({ landlordId }: { landlordId: string }) => {
    const response = await client.query(LIST_PROPERTIES_FOR_CONTRACT_CREATION(landlordId));
    return response;
  },
);
