import { CRentFreePeriod } from 'common/_classes/contractsV2';
import moment from 'moment';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import CheckboxField from 'atoms/FormField/Checkbox';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';
import TabLabel from '../TabLabel';
import RentFreePeriodForm from './RentFreePeriodForm';

const RentFreePeriod = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const rentFreePeriods = activeContractEvent?.rentFreePeriods || [];

  const [showTabs, setShowTabs] = useState(rentFreePeriods.length !== 0);
  const [tabIndex, setTabIndex] = useState(0);

  const disabled = mode === MODE_OPTIONS.READ;

  const key = 'rentFreePeriods';

  const onAddPeriod = () => {
    const value = [...rentFreePeriods, new CRentFreePeriod(null)];
    dispatch(updateActiveContractEventInput({ key, value }));
    setTabIndex(value.length - 1);
  };

  const onDelete = (index: number) => {
    const value = rentFreePeriods.filter((_item, idx) => index !== idx);
    dispatch(updateActiveContractEventInput({ key, value }));

    if (value.length === 0) setShowTabs(false);
  };

  const getTabsMenu = (): TabMenuProps[] => {
    return rentFreePeriods.map(({ startDate, endDate }, index: number) => {
      const start = moment(startDate).isValid() ? moment(startDate).format('DD/MM/YYYY') : '';
      const end = moment(endDate).isValid() ? moment(endDate).format('DD/MM/YYYY') : '';

      const dateText = `${start}${start && end ? ' - ' : ''}${end}`;
      return {
        key: `rent-free-period-tab-${index}`,
        label: (
          <TabLabel
            title="Rent free"
            disabled={mode === MODE_OPTIONS.READ}
            deletable={true}
            index={index}
            onDelete={onDelete}
            activeIndex={tabIndex}
            tabsLength={rentFreePeriods.length}
            dateText={dateText}
            setTabIndex={setTabIndex}
          />
        ),
        customTab: (
          <RentFreePeriodForm
            key={index}
            mode={mode}
            index={index}
          />
        ),
      };
    });
  };

  const onTabChange = (index: number) => setTabIndex(index);

  const toggleSwitch = (_key: string, isOpen: boolean) => {
    const value = isOpen ? [new CRentFreePeriod(null)] : [];

    dispatch(updateActiveContractEventInput({ key, value }));

    setShowTabs(isOpen);

    if (!isOpen) setTabIndex(0);
  };

  return (
    <div>
      <div className="grid">
        <div className="col-2">
          <CheckboxField
            className="d-flex align-center"
            dataTest=""
            fieldKey=""
            value={showTabs}
            toggle={true}
            disabled={disabled}
            onChange={toggleSwitch}
          />
        </div>

        {showTabs && !disabled && (
          <div
            className="font-600 d-flex p-xs m-l-auto color-blue-very-dark-grayish cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={onAddPeriod}
            data-test=""
          >
            <span className="icon bg-orange height-1_286 width-1_286 circle-xs m-r-xs d-flex align-center justify-center">
              <Icon
                icon={Icons.Add}
                className="color-white"
              />
            </span>
            <span>Add rent free period</span>
          </div>
        )}
      </div>

      {showTabs && (
        <TabReact
          dataTest=""
          activeTabIndex={tabIndex}
          tabMenu={getTabsMenu()}
          onTabChange={onTabChange}
        />
      )}
    </div>
  );
};

export default RentFreePeriod;
