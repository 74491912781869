import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface PrepFloorPlanForUploadQueryVariables {
  fileName: any;
  mimeType: any;
}

const PREP_FLOOR_PLAN_FOR_UPLOAD = (fileName: any, mimeType: any) => {
  const variables: PrepFloorPlanForUploadQueryVariables = {
    fileName,
    mimeType,
  };

  return {
    query: gql`
      query ($fileName: String!, $mimeType: String!) {
        prepFloorPlan(fileName: $fileName, mimeType: $mimeType) {
          cdnUrl
          uploadUrl
          path
        }
      }
    `,
    variables,
  };
};

export const prepFloorPlanForUpload = createAsyncThunk(
  'properties/prepFloorPlanForUpload',
  async (args: { files: any[] }) => {
    const { files } = args;
    const file = files[0].file;
    const response = await client.query(PREP_FLOOR_PLAN_FOR_UPLOAD(file.name, file.type));

    return response;
  },
);
