import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { getClientType } from 'utils/utils-authentication';

interface TranslateToContractQueryVariables {
  transactionId: string;
  finalised: boolean;
}

export const TRANSLATE_TO_CONTRACT = (transactionId: string, finalised: boolean) => {
  if (!transactionId) throw new Error('TranslateToContract: transactionId is required');

  const variables: TranslateToContractQueryVariables = {
    transactionId,
    finalised,
  };

  return {
    query: gql`
      mutation ($transactionId: ID!, $finalised: Boolean!) {
        translateToContract(transactionId: $transactionId, finalised: $finalised) {
          alerts {
            detail
            date
            message
            type
          }
          translationInfo {
            contract {
              id
              identifier
            }
            date
          }
        }
      }
    `,
    variables,
  };
};

export const translateToContract = createAsyncThunk(
  'transactions/translateToContract',
  async ({ finalised }: { finalised: boolean; toast?: boolean }, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
    } = getState() as RootState;

    const response = await getClientType().query(TRANSLATE_TO_CONTRACT(activeTransaction?.id as string, finalised));

    return response;
  },
);
