/* eslint-disable jsx-a11y/label-has-associated-control */
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateActiveTableParameter } from 'store/parameters/parameterDetailSlice';
import TableValidTypes from 'common/model/TableValidTypes';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION } from 'utils/UI';

interface TABLE_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: TableValidTypes;
}

const TABLE_TYPE_OPTIONS: TABLE_TYPE_OPTION[] = [
  { key: 1, text: 'Table', value: TableValidTypes.Table },
  { key: 2, text: 'Tabulation', value: TableValidTypes.Tab },
];

const TableParameterGeneralForm = ({ mode }: ModeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeTableParameter } = useAppSelector((state: RootState) => state.parameterDetail);
  const { name, type, description } = activeTableParameter;

  const onChange = (key: string, value: string) => dispatch(updateActiveTableParameter({ key, value }));

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Table name"
            value={name}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            dataTest="table-name"
            onChange={onChange}
            fieldKey="name"
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <SelectField
            label="Select table presentation in questionnaire"
            value={type}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select ..."
            dataTest="select-table-presentation"
            options={TABLE_TYPE_OPTIONS}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="type"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8}>
          <TextAreaField
            label="Description"
            value={description}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="description"
            dataTest="table-description"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default TableParameterGeneralForm;
