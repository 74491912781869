import TenantEntitiesDashboard from 'views/entities/tenantEntities/Dashboard';
import TenantEntitiesCreator from 'views/entities/tenantEntities/EntityCreator/EntityCreator';
import TenantEntitiesDetails from 'views/entities/tenantEntities/EntityDetails/EntityDetails';
import { isAuthenticated } from './redirects';

const TenantRoutes = () => [
  {
    path: '/tenant-entities',
    children: [
      {
        path: '/tenant-entities/dashboard',
        element: isAuthenticated(<TenantEntitiesDashboard />),
      },
      {
        path: '/tenant-entities/create',
        element: isAuthenticated(<TenantEntitiesCreator />),
      },
      {
        path: '/tenant-entities/:id/details',
        element: isAuthenticated(<TenantEntitiesDetails />),
      },
    ],
  },
];

export default TenantRoutes;
