import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { Forecasts } from 'common/_classes/forecastsV2';
import { RootState } from 'store';
import { IActiveForecast } from 'store/dashboard/forecasts2Slice';

// export const createForecast = createAsyncThunk(
//   'forecasts2/createForecast',
//   async (forecast: Forecasts, { rejectWithValue }) => {
//     try {
//       const response = await fetch('/api/createForecast', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(forecast),
//       });
//       const data = await response.json();
//       return new Forecasts(data);
//     } catch (error) {
//       return rejectWithValue('Failed to create forecast');
//     }
//   }
// );
// Mock function for createForecast

export const createForecast = createAsyncThunk(
  'forecasts2/createForecast',
  async (forecast: IActiveForecast | null, { rejectWithValue }) => {
    try {
      // Simulate API call delay
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Generate a mock response
      const mockResponse = {
        ...forecast,
        id: Math.random().toString(36).substr(2, 9),
        createdDate: new Date(),
        insertedAt: new Date(),
        updatedAt: new Date(),
        version: 1,
        archived: false,
      };

      // return new IForecasts(mockResponse);
      return forecast as IActiveForecast;
    } catch (error) {
      return rejectWithValue('Failed to create forecast');
    }
  },
);
