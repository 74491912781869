import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface ForgotPasswordQueryVariables {
  email: string;
}

export const FORGOT_PASSWORD = ({ email }: ForgotPasswordQueryVariables) => {
  const variables: ForgotPasswordQueryVariables = {
    email,
  };

  return {
    mutation: gql`
      mutation ($email: String!) {
        forgotPassword(email: $email) {
          email
        }
      }
    `,
    variables,
  };
};

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (_arg, { getState }) => {
  const {
    auth: { email },
  } = getState() as RootState;
  const response = await client.mutate(FORGOT_PASSWORD({ email }));
  return response;
});
