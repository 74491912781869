import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import PhoneNumberField from 'atoms/FormField/PhoneInput';
import SelectField from 'atoms/FormField/Select';
import { updateInput } from 'store/adminUsers/adminUsersListingSlice';
import { USER_TYPES_OPTIONS } from 'utils/UI';
import { invalidEmailText } from 'utils/tsHelper';
import { emailIsValid } from 'utils/utils-string';

const UserModalForm = () => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, email, phone, userType } = useAppSelector(
    (state: RootState) => state.adminUsers.activeUser,
  );

  const onChange = (key: string, value: string) => dispatch(updateInput({ key, value }));
  const checkIfValidEmail = emailIsValid(email);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="First name"
            fieldKey="firstName"
            placeholder="Enter first name.."
            value={firstName}
            onChange={onChange}
            required={true}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <InputField
            label="Last name"
            fieldKey="lastName"
            placeholder="Enter last name.."
            value={lastName}
            onChange={onChange}
            required={true}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <InputField
            label="Email"
            type={InputFieldType.EMAIL}
            fieldKey="email"
            placeholder="Enter email address.."
            value={email}
            onChange={onChange}
            required={true}
            isInValid={!checkIfValidEmail}
            errorMessage={(!checkIfValidEmail && invalidEmailText) || ''}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <PhoneNumberField
            label="Phone Number"
            fieldKey="phone"
            value={phone}
            onChange={onChange}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>
          <SelectField
            label="Role"
            fieldKey="userType"
            placeholder="Select role.."
            options={USER_TYPES_OPTIONS}
            value={userType}
            onChange={onChange}
            required={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default UserModalForm;
