import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import './PropertyDetails.scss';

const PropertyDetails = () => {
  const property = useAppSelector((state: RootState) => state.forecasts.activeProperty?.buildingName);

  return (
    <>
      <div className="dropdown-property-details">
        {property && (
          <div>
            Property: <span>{property}</span>
          </div>
        )}
      </div>
      <div className="dropdown-property-details">
        Forecast focus: <span>Annual</span>
      </div>
    </>
  );
};

export default PropertyDetails;
