import { PaginationAPIProps } from 'common/api/miscellaneous';

export enum EntityCreatorTypes {
  Landlord = 'Landlord',
  Tenant = 'Tenant',
}

export interface EntityTypeProps {
  entityType: EntityCreatorTypes;
}

export interface EntitiesListingActionArgs extends PaginationAPIProps {
  type: EntityCreatorTypes;
  searchBarSpinOnly?: boolean;
}
