import { useState } from 'react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import ModalTemplate from 'components/ModalTemplate';
import PrivacyAndTerms from 'views/PrivacyAndTerms';
import { ENVIRONMENT_TYPES } from 'common/api/miscellaneous';
import { Icons } from 'utils/utils-icons';
import './Footer.scss';

const Footer = (): JSX.Element => {
  const { version, buildDate, environment } = useAppSelector((state: RootState) => state.auth);

  const [open, updateModalStatus] = useState<boolean>(false);
  const environmentData: string = environment !== ENVIRONMENT_TYPES.PROD ? `- ${environment}` : '';
  const getCurrentYear: number = new Date().getFullYear();

  return (
    <div className="footer d-flex m-t-ml">
      <div className="footer-version color-blue-very-dark-grayish">
        Sericin Limited v{version} - {buildDate} {environmentData}
      </div>
      <div className="copyright m-l-s d-flex color-blue-very-dark-grayish">
        <Icon icon={Icons.CopyRight} />
        {getCurrentYear} All Rights Reserved
      </div>
      <div className="terms m-l-auto color-blue-very-dark-grayish">
        <span className="p-r-sm">
          <a
            className="color-blue-very-dark-grayish"
            href="mailto:info@sericin.net"
          >
            Contact Us
          </a>
        </span>

        <ModalTemplate
          isOpen={open}
          onToggleModalStatus={updateModalStatus}
          trigger={<span style={{ cursor: 'pointer' }}>Privacy & Terms</span>}
          title="Privacy & Terms"
        >
          <PrivacyAndTerms />
        </ModalTemplate>
      </div>
    </div>
  );
};

export default Footer;
