import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { convertDateToISO } from 'utils/utils-date';
import { addQuotes } from 'utils/utils-string';
import { uploadToAWS } from 'utils/utils-upload';
import { FloorPlanProps } from '../properties/types';

const CREATE_SPACES_AND_UNITS = (localSpacesAndUnits: string | any[]) => {
  let createSpacesAndUnits = '';
  const selectedEntity = localStorage.getItem('sericin_selected_entity');

  for (let j = 0; j < localSpacesAndUnits.length; j++) {
    const {
      name,
      registeredName,
      type,
      usedForOccupancyRate,
      grossArea,
      netArea,
      lettableArea,
      saleableArea,
      efficiencyRatio,
      comments,
      characteristics,
      activationDate,
      deactivationDate,
      floorId,
    } = localSpacesAndUnits[j];
    const characteristicsCopy = characteristics === null ? characteristics : `[${characteristics}]`;

    const params = `{
			name:${addQuotes(name)}, 
      registeredName:${addQuotes(registeredName)}, 
			type: ${type}, 
			usedForOccupancyRate:${usedForOccupancyRate}, 
			grossArea:${addQuotes(grossArea)}, 
			netArea:${addQuotes(netArea)}, 
			lettableArea:${addQuotes(lettableArea)}, 
			saleableArea:${addQuotes(saleableArea)}, 
			efficiencyRatio:${addQuotes(efficiencyRatio)}, 
			comments:${addQuotes(comments)}, 
			characteristics: ${characteristicsCopy}, 
			activationDate:${addQuotes(convertDateToISO(activationDate))}, 
			deactivationDate:${addQuotes(convertDateToISO(deactivationDate))}, 
		}`;

    createSpacesAndUnits += `spaceCreate${j}: createSpace(currentLandlordEntityId: "${selectedEntity}", floorId: "${floorId}", params: ${params}){
      name
      registeredName
      type
      usedForOccupancyRate
      grossArea
      netArea
      lettableArea
      saleableArea
      efficiencyRatio
      comments
      characteristics
      activationDate
      deactivationDate
	   }\n`;
  }

  return createSpacesAndUnits;
};

const UPDATE_SPACES_AND_UNITS = (spacesAndUnitsToUpdate: string | any[]) => {
  let updateSpacesAndUnits = '';
  const selectedEntity = localStorage.getItem('sericin_selected_entity');

  for (let i = 0; i < spacesAndUnitsToUpdate.length; i++) {
    const {
      id,
      name,
      registeredName,
      type,
      usedForOccupancyRate,
      grossArea,
      netArea,
      lettableArea,
      saleableArea,
      efficiencyRatio,
      comments,
      characteristics,
      activationDate,
      deactivationDate,
    } = spacesAndUnitsToUpdate[i];
    const characteristicsCopy = characteristics === null ? characteristics : `[${characteristics}]`;

    const params = `{ 
      name:${addQuotes(name)}, 
      registeredName:${addQuotes(registeredName)}, 
			type:${type}, 
			usedForOccupancyRate:${usedForOccupancyRate}, 
			grossArea:${addQuotes(grossArea)}, 
			netArea:${addQuotes(netArea)}, 
			lettableArea:${addQuotes(lettableArea)}, 
			saleableArea:${addQuotes(saleableArea)}, 
			efficiencyRatio:${addQuotes(efficiencyRatio)}, 
			comments:${addQuotes(comments)}, 
			characteristics:${characteristicsCopy}, 
			activationDate:${addQuotes(convertDateToISO(activationDate))}, 
			deactivationDate:${addQuotes(convertDateToISO(deactivationDate))}, 
		}`;

    updateSpacesAndUnits += `spacesAndUnitsUpdate${i}: updateSpace(currentLandlordEntityId: "${selectedEntity}", spaceId: "${id}", params: ${params}){
			id
			name
      registeredName
      type
      usedForOccupancyRate
      grossArea
      netArea
      lettableArea
      saleableArea
      efficiencyRatio
      comments
      characteristics
      activationDate
      deactivationDate
   	}\n`;
  }

  return updateSpacesAndUnits;
};

const DELETE_SPACES_AND_UNIT = (spacesAndUnitsToDelete: string | any[]) => {
  let deleteSpacesAndUnits = '';
  const selectedEntity = localStorage.getItem('sericin_selected_entity');

  for (let i = 0; i < spacesAndUnitsToDelete.length; i++) {
    deleteSpacesAndUnits += `spacesAndUnitsDelete${i}: deleteSpace(currentLandlordEntityId: "${selectedEntity}", spaceId: "${spacesAndUnitsToDelete[i]}"){
				id
				name
        registeredName
        type
        usedForOccupancyRate
        grossArea
        netArea
        lettableArea
        saleableArea  
        efficiencyRatio
        comments
        characteristics
        activationDate
        deactivationDate
   		}\n`;
  }

  return deleteSpacesAndUnits;
};

const SAVE_SPACES_AND_UNITS = (
  activeFloor: { spaces: string | any[]; id: any },
  spacesAndUnitsToDelete: string | any[],
) => {
  const newSpaceAndUnits = [];
  const spacesAndUnitsToUpdate = [];

  for (let i = 0; i < activeFloor.spaces.length; i++) {
    const spacewithFloorId = { ...activeFloor.spaces[i] };

    spacewithFloorId.floorId = activeFloor.id;

    if (activeFloor.spaces[i].local) {
      newSpaceAndUnits.push(spacewithFloorId);
    } else {
      spacesAndUnitsToUpdate.push(spacewithFloorId);
    }
  }

  let finalMutation = ``;

  if (newSpaceAndUnits.length > 0) {
    const createSpacesAndUnits = CREATE_SPACES_AND_UNITS(newSpaceAndUnits);
    finalMutation += createSpacesAndUnits + '\n';
  }
  if (spacesAndUnitsToUpdate.length > 0) {
    const updateSpacesAndUnits = UPDATE_SPACES_AND_UNITS(spacesAndUnitsToUpdate);
    finalMutation += updateSpacesAndUnits + '\n';
  }
  if (spacesAndUnitsToDelete.length > 0) {
    const deleteSpacesAndUnits = DELETE_SPACES_AND_UNIT(spacesAndUnitsToDelete);
    finalMutation += deleteSpacesAndUnits;
  }

  if (finalMutation === '') {
    return;
  }

  return finalMutation;
};

const CREATE_FLOOR_PLANS = (localFloorPlans: any) => {
  let createFloorPlans = '';

  for (let j = 0; j < localFloorPlans.length; j++) {
    const {
      reviewFlag,
      floorPlan,
      revisionDate,
      unitsNumber,
      whollyOwned,
      grossArea,
      netArea,
      lettableArea,
      saleableArea,
      efficiencyRatio,
      floorId,
    } = localFloorPlans[j];

    let floorPlanParams = floorPlan;

    if (floorPlan) {
      const { name, path, public: isPublic } = floorPlan;
      floorPlanParams = `{
        name: ${addQuotes(name)},
        path: ${addQuotes(path)},
        public: ${isPublic},
      }`;
    }

    const params = `{
        reviewFlag:${reviewFlag}, 
        floorPlan:${floorPlanParams}, 
        unitsNumber:${unitsNumber}, 
        whollyOwned:${whollyOwned}, 
        grossArea:${addQuotes(grossArea)}, 
        netArea:${addQuotes(netArea)}, 
        lettableArea:${addQuotes(lettableArea)}, 
        saleableArea:${addQuotes(saleableArea)}, 
        efficiencyRatio:${addQuotes(efficiencyRatio)}, 
        revisionDate:${addQuotes(convertDateToISO(revisionDate))}, 
	}`;

    createFloorPlans += `floorPlanCreate${j}: createFloorPlan(currentLandlordEntityId: "${getCurrentLandlordEntityId()}", floorId: "${floorId}", params: ${params}){
      reviewFlag
      floorPlan{
        id
        name
        path
        public
        url
      }
      unitsNumber
      whollyOwned
      grossArea
      netArea
      lettableArea
      saleableArea
      efficiencyRatio
      revisionDate
    }\n`;
  }

  return createFloorPlans;
};

const UPDATE_FLOOR_PLANS = (floorPlansToUpdate: any) => {
  let updateFloorPlans = '';

  for (let i = 0; i < floorPlansToUpdate.length; i++) {
    const {
      id,
      reviewFlag,
      floorPlan,
      revisionDate,
      unitsNumber,
      whollyOwned,
      grossArea,
      netArea,
      lettableArea,
      saleableArea,
      efficiencyRatio,
    } = floorPlansToUpdate[i];

    let floorPlanParams = floorPlan;

    if (floorPlan) {
      const { name, path, public: isPublic } = floorPlan;
      floorPlanParams = `{
        name: ${addQuotes(name)},
        path: ${addQuotes(path)},
        public: ${isPublic},
      }`;
    }

    const params = `{
        reviewFlag:${reviewFlag}, 
        floorPlan:${floorPlanParams}, 
        unitsNumber:${unitsNumber}, 
        whollyOwned:${whollyOwned}, 
        grossArea:${addQuotes(grossArea)}, 
        netArea:${addQuotes(netArea || null)}, 
        lettableArea:${addQuotes(lettableArea || null)}, 
        saleableArea:${addQuotes(saleableArea || null)}, 
        efficiencyRatio:${addQuotes(efficiencyRatio)}, 
        revisionDate:${addQuotes(convertDateToISO(revisionDate))}, 
	}`;

    updateFloorPlans += `floorPlansUpdate${i}: updateFloorPlan(currentLandlordEntityId: "${getCurrentLandlordEntityId()}", floorPlanId: "${id}", params: ${params}){
	    id
		  reviewFlag
      floorPlan{
        id
        name
        path
        public
        url
      }
      unitsNumber
      whollyOwned
      grossArea
      netArea
      lettableArea
      saleableArea
      efficiencyRatio
      revisionDate
   	}\n`;
  }

  return updateFloorPlans;
};

const DELETE_FLOOR_PLANS = (floorPlansToDelete: string[]) => {
  let deleteFloorPlans = '';

  for (let i = 0; i < floorPlansToDelete.length; i++) {
    deleteFloorPlans += `floorPlansDelete${i}: deleteFloorPlan(currentLandlordEntityId: "${getCurrentLandlordEntityId()}", floorPlanId: "${
      floorPlansToDelete[i]
    }"){
        id
        reviewFlag
        floorPlan{
          id
          name
          path
          public
          url
        }
        unitsNumber
        whollyOwned
        grossArea
        netArea
        lettableArea
        saleableArea
        efficiencyRatio
        revisionDate
   		}\n`;
  }

  return deleteFloorPlans;
};

const SAVE_FLOOR_PLANS = (
  { id, floorPlans }: { id: string; floorPlans: FloorPlanProps[] },
  floorPlansToDelete: string[],
) => {
  const newFloorPlans = [];
  const floorPlansToUpdate = [];

  for (let i = 0; i < floorPlans.length; i++) {
    const floorPlanWithFloorId = { ...floorPlans[i] };

    floorPlanWithFloorId.floorId = id;

    if (floorPlans[i].local) {
      newFloorPlans.push(floorPlanWithFloorId);
    } else {
      floorPlansToUpdate.push(floorPlanWithFloorId);
    }
  }

  let finalMutation = ``;

  if (newFloorPlans.length > 0) {
    const createFloorPlans = CREATE_FLOOR_PLANS(newFloorPlans);
    finalMutation += createFloorPlans + '\n';
  }
  if (floorPlansToUpdate.length > 0) {
    const updateFloorPlans = UPDATE_FLOOR_PLANS(floorPlansToUpdate);
    finalMutation += updateFloorPlans + '\n';
  }
  if (floorPlansToDelete.length > 0) {
    const deleteFloorPlans = DELETE_FLOOR_PLANS(floorPlansToDelete);
    finalMutation += deleteFloorPlans;
  }

  if (finalMutation === '') {
    return;
  }

  return finalMutation;
};

export const updateSpacesAndUnitsFloorPlans = createAsyncThunk(
  'properties/updateSpacesAndUnitsFloorPlans',
  async (_arg, { getState }) => {
    const {
      propertyDetail: { activeFloor, spacesAndUnitsToDelete, floorPlansToDelete },
    } = getState() as RootState;

    const updateMutation = SAVE_SPACES_AND_UNITS(activeFloor, spacesAndUnitsToDelete);

    const updateFloorPlansMutation = SAVE_FLOOR_PLANS(activeFloor, floorPlansToDelete);

    let finalMutation: any = '';
    if (updateMutation) {
      finalMutation += updateMutation;
    }
    if (updateFloorPlansMutation) {
      finalMutation += updateFloorPlansMutation;
    }

    if (finalMutation === '') {
      return;
    }
    // const floorPlans: FloorPlan[] = activeFloor.floorPlans;

    const floorPlansDoc = activeFloor.floorPlans.map((element: any) => element.floorPlan);

    if (floorPlansDoc.length > 0) {
      for (let i = 0; i < floorPlansDoc.length; i++) {
        if (floorPlansDoc[i]) {
          const { local, uploadUrl, file, name, path, public: docPublic } = floorPlansDoc[i];

          if (local) {
            const response = await uploadToAWS(uploadUrl, file);

            if (response instanceof Error) {
              toast.error(`Error occured while uploading ${name}`);
            }
          }

          floorPlansDoc.splice(i, 1, {
            name,
            path,
            public: local ? true : docPublic,
          });
        }
      }
    }

    finalMutation = {
      mutation: gql`
        mutation {
        ${finalMutation}	
      }`,
    };

    if (finalMutation) {
      const response = await client.mutate(finalMutation);

      return response;
    }
  },
);
