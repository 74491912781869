import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

export const DELETE_DEVELOPMENT = (id: string) => {
  const variables = {
    id,
  };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteDevelopment(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteDevelopment = createAsyncThunk('propertiesV2/deleteDevelopment', async (args: { id: string }) => {
  const response = await client.mutate(DELETE_DEVELOPMENT(args.id));
  return response;
});
