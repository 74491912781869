import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { DataObjectName } from 'atoms/ImportReport/utils-interfaces';
import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import {
  filterElementCountry,
  filterElementCountryTemplate,
  filterElementNumberRange,
} from 'atoms/TableReactPrime/columnTemplates';
import { clearImportedTenant } from 'store/entities/entitiesListingSlice';
import { createNewEntity } from 'store/entities/entityDetailSlice';
import EntityType from 'common/model/EntityType';
import { DocumentsType } from 'common/api/contracts';
import { EntityCreatorTypes, exportTenant, listEntities, uploadTenant } from 'common/api/entities';
import { MemberClone } from 'common/api/members';
import { listCountries } from 'common/api/miscellaneous';
import { CountryClone } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { Entity, Industry } from 'utils/types/entities';
import { addSpaceOrComma } from 'utils/utils-number';
import { capitalizeFirstLetter } from 'utils/utils-string';
import TenantEntitiesHeaderToolbar from './TenantEntitiesHeaderToolbar';
import { TenantEntitiesRowActionButtons } from './TenantEntitiesRowActionButtons';

interface TenantRow {
  id: string;
  identifier: string;
  name: string;
  nickname: string;
  countryFlag: string;
  countryName: string;
  type: string;
  industry: string;
  contractCount: string;
  transactionCount: number;
  rentArea: 0;
}

const TenantEntitiesDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, importedTenant, isExporting, exportSuccess, exportFail, entitiesList } = useAppSelector(
    (state: RootState) => state.entitiesListing,
  );
  const { members } = useAppSelector((state: RootState) => state.auth);
  const { countries } = useAppSelector((state: RootState) => state.miscellaneous);

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(listCountries());
    }
  }, [dispatch, countries.length]);

  const createNewTenant = () => {
    dispatch(createNewEntity(null));
    navigate(`/tenant-entities/create?step=1`);
  };

  const onViewDetails = (id: string): void => {
    const url = `/tenant-entities/${id}/details`;
    navigate(url);
  };

  const clearReport = () => {
    dispatch(clearImportedTenant());
  };

  const onImportTenant = (simulation: boolean, file: DocumentsType) => {
    dispatch(uploadTenant({ simulation, file })).then(({ meta: { requestStatus }, payload }: any) => {
      const { uploadTenant } = (payload && payload.data) || {};

      const { simulation } = uploadTenant || {};

      if (requestStatus === 'fulfilled' && simulation === false) {
        dispatch(listEntities({ type: EntityCreatorTypes.Tenant, first: 10 }));
      }
    });
  };

  const onExportTenant = () => {
    const member = members.find(({ entity: { id } }) => id === getCurrentLandlordEntityId()) as MemberClone | undefined;

    if (member) {
      const {
        entity: { name: landlordName },
      } = member;

      dispatch(exportTenant({ landlordName }));
    }
  };

  const findCountry = (legalDomicile: string | null, registrationCountry: string | null): CountryClone => {
    if (!legalDomicile && !registrationCountry) {
      const noCountry: CountryClone = {
        index: -1,
        name: '',
        code: '',
        flag: '',
      };
      return noCountry;
    }
    const countryValue = legalDomicile ? legalDomicile : registrationCountry;
    const countryMatch: CountryClone = countries.find(
      (country: CountryClone) => country.code === countryValue || country.name === countryValue,
    )!;
    return countryMatch;
  };

  const getIndustry = (industry: Industry | null) => {
    if (typeof industry === 'object' && industry !== null) {
      return industry.name;
    } else {
      return industry;
    }
  };

  /*
   * Convert/prepare list of data
   */
  const currentList = entitiesList.map((entity: Entity) => {
    const countryMatch = findCountry(entity.legalDomicile, entity.registrationCountry);
    const result = {
      id: entity.id,
      identifier: entity.identifier,
      name: entity.name,
      nickname: entity.nickname,
      countryFlag: countryMatch?.flag,
      countryName: countryMatch?.name,
      type: entity.type === EntityType.Coop ? 'Co-op' : capitalizeFirstLetter(entity.type),
      industry: getIndustry(entity.industry),
      contractCount: addSpaceOrComma(entity.contractCount),
      transactionCount: addSpaceOrComma(entity.transactionCount),
      rentArea: 0,
    };
    return result;
  });

  const columnConfig: CustomColumnProps[] = [
    {
      field: 'identifier',
      header: 'Tenant Identifier',
      filterPlaceholder: 'Search by Tenant Identifier',
      filter: true,
      filterField: 'identifier',
      sortable: true,
      mandatory: true,
      minWidth: '10rem',
    },
    {
      field: 'name',
      header: 'Tenant legal entity',
      filter: true,
      filterPlaceholder: 'Search by Tenant legal entity',
      filterField: 'name',
      sortable: true,
      dataType: 'text',
      isHide: false,
      mandatory: true,
    },
    {
      field: 'nickname',
      header: 'Trading Name',
      filter: true,
      filterPlaceholder: 'Search by Trading Name',
      filterField: 'nickname',
      sortable: true,
    },
    {
      field: 'countryName',
      header: 'Country',
      filter: true,
      filterPlaceholder: 'Search by Country Name',
      filterField: 'countryName',
      body: (row: TenantRow) => {
        return filterElementCountryTemplate(row.countryName, row.countryFlag);
      },
      filterElement: options =>
        filterElementCountry(
          options,
          countries.map((country: CountryClone) => country.name),
        ),
      showFilterMatchModes: false,
      sortable: true,
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      field: 'type',
      header: 'Type of entity',
      filter: true,
      filterPlaceholder: 'Search by Type of entity',
      filterField: 'type',
      sortable: true,
    },
    {
      field: 'industry',
      header: 'Industry',
      filter: true,
      filterPlaceholder: 'Search by Industry',
      filterField: 'industry',
      sortable: true,
    },
    {
      field: 'contractCount',
      header: 'Contracts',
      filter: true,
      filterPlaceholder: 'Search by Contracts count',
      filterField: 'contractCount',
      sortable: true,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
    },
    {
      field: 'transactionCount',
      header: 'Transactions',
      filter: true,
      filterPlaceholder: 'Search by Transactions',
      filterField: 'transactionCount',
      sortable: true,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
    },
    {
      field: 'rentArea',
      header: 'Rent Area',
      filter: true,
      filterPlaceholder: 'Search by Rent Area',
      filterField: 'rentArea',
      sortable: false,
      dataType: 'numeric',
      showFilterMatchModes: false,
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: TenantRow) => (
        <TenantEntitiesRowActionButtons
          rowId={row.id}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Tenant Entities' }];

    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE TENANT',
      url: '/tenant-entities/create?step=1',
      dataTest: 'create-tenant-button',
      onCreateNew: createNewTenant,
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: (
        <TenantEntitiesHeaderToolbar
          onImportTenant={onImportTenant}
          onExportTenant={onExportTenant}
          isExporting={isExporting}
          exportSuccess={exportSuccess}
          exportFail={exportFail}
          importedTenant={importedTenant}
          clearReport={clearReport}
          dataObjectName={DataObjectName.TENANT}
        />
      ),
      createNewButton,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }) => {
          dispatch(listEntities({ type: EntityCreatorTypes.Tenant, ...rest }));
        }}
        isFetching={isLoading}
        content={currentList}
        columnConfig={columnConfig}
        dataTest="tenants-table"
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default TenantEntitiesDashboard;
