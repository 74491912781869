import { Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { getAnswerWithChangedValue } from 'views/transactions/CreatorViewer/Tabs/ApprovalTab/ApprovalChanges/ChangesBox/ChangesTable';
import Parameter from 'common/model/Parameter';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { AnswerProps } from 'common/api/policies';
import { getAnswerValue } from 'utils/utils-answer';

const TemporaryDeviation = ({ answerIds }: { answerIds: string[] }): JSX.Element => {
  const { activeTransactionAnswers, activeMessageId } = useAppSelector((state: RootState) => state.transactionDetail);
  const { allParameters } = useAppSelector((state: RootState) => state.parametersTab);
  let temporaryAnswers: AnswerProps[] = activeTransactionAnswers.filter(
    (answer: AnswerProps) =>
      (answer?.transactionMessage?.id === activeMessageId || answer?.transactionMessageId === activeMessageId) &&
      answer?.approvalState === ValidApprovalStates.Pending,
  );

  // Don't render answers to be deleted
  temporaryAnswers = temporaryAnswers.filter((answer: AnswerProps) => !answerIds.includes(answer.id as string));

  const DeviationRow = ({ tempAnswer }: { tempAnswer: AnswerProps }): JSX.Element => {
    const parameter: Parameter | undefined = allParameters.find(
      (parameter: Parameter) => tempAnswer.paramRef.parameterId === parameter.id,
    );

    const history: AnswerProps[] = activeTransactionAnswers.filter(
      (obj: AnswerProps) =>
        obj.answerType === tempAnswer.answerType &&
        obj.paramRef.parameterId === tempAnswer.paramRef.parameterId &&
        obj.paramRef.tableId === tempAnswer.paramRef.tableId &&
        obj.paramRef.index === tempAnswer.paramRef.index &&
        ((obj?.transactionMessage === undefined && obj?.transactionMessageId === undefined) ||
          (obj?.transactionParameterApproval !== null && obj?.transactionParameterApproval !== undefined)),
    );

    let oldAnswer = null as AnswerProps | null;
    // Check if history is present
    if (history.length >= 1) {
      // Get the latest saved answer before temporary in history
      oldAnswer = history[history.length - 1];
    }

    const oldAnswerValue: any =
      oldAnswer === null ? '' : getAnswerValue(oldAnswer.answer, oldAnswer.answerType, parameter?.choices);

    let newValue = getAnswerValue(tempAnswer.answer, tempAnswer.answerType, parameter?.choices);
    newValue = getAnswerWithChangedValue(oldAnswerValue, newValue, tempAnswer.answerType);

    return (
      <TableRow>
        <TableCell>{parameter?.parameterGroup.name}</TableCell>
        <TableCell>{parameter?.name}</TableCell>
        <TableCell>{oldAnswerValue}</TableCell>
        <TableCell>{newValue}</TableCell>
      </TableRow>
    );
  };

  if (temporaryAnswers.length !== 0) {
    return (
      <>
        <h2 className="title">Parameter changes:</h2>
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Parameter Group</TableHeaderCell>
              <TableHeaderCell>Parameter</TableHeaderCell>
              <TableHeaderCell>Amended From</TableHeaderCell>
              <TableHeaderCell>Amended To</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {temporaryAnswers.map((tempAnswer, index) => {
              const key = `temporary-answer-${index}`;
              return (
                <DeviationRow
                  tempAnswer={tempAnswer}
                  key={key}
                />
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  } else {
    return <></>;
  }
};

export default TemporaryDeviation;
