import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DropdownProps, Form, Grid } from 'semantic-ui-react';
import { type RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateDevelopmentProperty } from 'store/propertiesV2/propertiesV2Slice';
import Development from 'common/model/Development';
import HK_District from 'common/model/HkDistrict';
import OwnershipType from 'common/model/OwnershipType';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { listLandlordEntityOptions } from 'common/api/properties';

const ownershipLabelMap = {
  [OwnershipType.WhollyOwnedSingleEntity]: 'Wholly owned single entity',
  [OwnershipType.WhollyOwnedMultipleEntity]: 'Wholly owned multiple entities',
  [OwnershipType.PartiallyOwnedSingleOrganisation]: 'Partially owned single organisation',
  [OwnershipType.PartiallyOwnedMultipleEntities]: 'Partially owned multiple entities',
};

const ownershipOptions: DropdownProps[] = Object.values(OwnershipType).map(value => ({
  text: ownershipLabelMap[value],
  value,
  key: value,
}));

const DevelopmentForm = () => {
  const { currentDevelopment, mode, ownersOptions } = useAppSelector((state: RootState) => state.propertiesV2);

  const dispatch = useAppDispatch();

  const onChange = (key: keyof Development, value: any) => {
    dispatch(updateDevelopmentProperty({ key, value }));
  };

  const shouldDisable = mode === MODE_OPTIONS.READ && !!currentDevelopment?.id;

  useEffect(() => {
    if (!ownersOptions || !ownersOptions.length) {
      dispatch(listLandlordEntityOptions());
    }
  }, [dispatch, listLandlordEntityOptions]);

  return (
    <Form className="p-b-l">
      <h2 className="color-blue-very-dark">Details</h2>
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={4}
            className="p-l-none"
          >
            <InputField
              label="Development name"
              value={currentDevelopment?.developmentName || ''}
              onChange={(key, value) => onChange('developmentName', value)}
              fieldKey="developmentName"
              disabled={shouldDisable}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputField
              label="Development ID"
              value={currentDevelopment?.developmentIdentifier || ''}
              onChange={(key, value) => onChange('developmentIdentifier', value)}
              fieldKey="developmentIdentifier"
              disabled={shouldDisable}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <InputField
              label="Development lot number"
              value={currentDevelopment?.developmentLotNumber || ''}
              onChange={(key, value) => onChange('developmentLotNumber', value)}
              fieldKey="developmentLotNumber"
              disabled={shouldDisable}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={4}
            className="p-l-none"
          >
            <InputField
              label="Development address"
              value={currentDevelopment?.address || ''}
              onChange={(key, value) => onChange('address', value)}
              fieldKey="address"
              disabled={shouldDisable}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SelectField
              label="District"
              value={currentDevelopment?.addressDistrict || ''}
              options={Object.values(HK_District).map(district => ({
                text: district,
                value: district,
              }))}
              onChange={(key, value) => onChange('addressDistrict', value)}
              fieldKey="addressDistrict"
              disabled={shouldDisable}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SelectField
              label="Development ownership"
              value={currentDevelopment?.ownershipType || ''}
              options={ownershipOptions}
              onChange={(key, value) => onChange('ownershipType', value)}
              fieldKey="ownershipType"
              disabled={shouldDisable}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={4}
            className="p-l-none"
          >
            {ownersOptions && ownersOptions.length && (
              <SelectField
                label="Development owner"
                value={currentDevelopment?.entityId || []}
                options={ownersOptions}
                onChange={(key, value) => {
                  onChange(
                    'entityId',
                    ownersOptions.filter(owner => value.includes(owner.value as string)).map(owner => owner.value),
                  );
                }}
                fieldKey="owner"
                multiple={true}
                disabled={shouldDisable}
              />
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <InputField
              label="Area"
              value={currentDevelopment?.area || ''}
              onChange={(key, value) => onChange('area', value)}
              fieldKey="area"
              disabled={shouldDisable}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

export default DevelopmentForm;
