enum ConditionType {
  ParamValue = 'PARAM_VALUE',
  ParamParam = 'PARAM_PARAM',
  TablePosition = 'TABLE_POSITION',
  TableIndex = 'TABLE_INDEX',
  TableSize = 'TABLE_SIZE',
  RowPosition = 'ROW_POSITION',
  CountParam = 'COUNT_PARAM'
}
export default ConditionType;
