import { ReactNode } from 'react';
import { Checkbox, Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import { DropdownMenuItem } from 'atoms/DropdownCardMenu';
import CardViewTemplate, { CardTemplateConfiguration } from 'components/CardViewTemplate';
import { setSelectedProvisionFilter } from 'store/miscellaneous/miscellaneousSlice';
import { updatePolicyViewDetailsTab } from 'store/policies/policyDetailSlice';
import { updateProvisionModalState } from 'store/provisions/provisionsListingSlice';
import {
  ProvisionClone,
  TProvisionContentPick,
  deletePolicyProvision,
  listPolicySequences,
  updatePolicyProvision,
} from 'common/api/policies';
import { PolicyProvisionDetailClone, PolicyProvisionInput } from 'common/api/policies';
import { ProvisionCategoryClone } from 'common/api/provisions';
import { isProvisionInPolicy } from 'utils/tsHelper';
import { Colors } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { POLICY_TABS_OFFSET } from '../PolicyTabs';

interface CardElement extends Omit<PolicyProvisionDetailClone, 'id'>, ProvisionClone {}

enum CHECKBOX_FIELD {
  MANDATORY = 'mandatory',
  DEFAULT = 'default',
}

const UsedProvisionsCards = () => {
  const { provisionCategoriesList } = useAppSelector((state: RootState) => state.provisionsListing);
  const { activePolicy, activePolicyProvisions } = useAppSelector((state: RootState) => state.policyDetail);
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);
  const orderedProvisions: PolicyProvisionDetailClone[] = orderBy(
    activePolicyProvisions,
    policyProvision => {
      return policyProvision.provision.index;
    },
    'asc',
  );

  const dispatch = useAppDispatch();

  const onUnselectProvision = (provisionId: string | null) => {
    const policyProvisionId: string | undefined = orderedProvisions.find(
      (element: PolicyProvisionDetailClone) => element.provision.id === provisionId,
    )?.id;
    dispatch(deletePolicyProvision({ id: policyProvisionId })).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(listPolicySequences({ policyId: activePolicy.id }));
      }
    });
  };

  const onSetMandatoryOrDefault = (provisionId: string | undefined, type?: CHECKBOX_FIELD) => {
    if (!provisionId) return;
    const policyProvision: PolicyProvisionDetailClone | undefined = orderedProvisions.find(
      (element: PolicyProvisionDetailClone) => element.provision.id === provisionId,
    );
    if (policyProvision) {
      const provisionUpdate: PolicyProvisionInput = {
        policyId: activePolicy.id,
        provisionId: policyProvision.provision.id,
        mandatory: type === CHECKBOX_FIELD.MANDATORY ? !policyProvision.mandatory : policyProvision.mandatory,
        selectedByDefault:
          type === CHECKBOX_FIELD.DEFAULT ? !policyProvision.selectedByDefault : policyProvision.selectedByDefault,
      };
      dispatch(updatePolicyProvision({ id: policyProvision.id, policyProvision: provisionUpdate }));
    }
  };

  const onEdit = (provision: CardElement) => {
    dispatch(updateProvisionModalState({ open: true, provision }));
  };

  const moveToParameterTab = (policyProvisionId: string | undefined) => {
    if (policyProvisionId) {
      dispatch(setSelectedProvisionFilter({ provisionId: policyProvisionId }));
      dispatch(updatePolicyViewDetailsTab({ tab: POLICY_TABS_OFFSET.PARAMETERS }));
    }
  };

  const cardMenuItems = (provision: CardElement): DropdownMenuItem[] => {
    return [
      {
        key: '1',
        label: (
          <Checkbox
            disabled
            label="Mandatory"
            checked={provision.mandatory}
            className="card-header-menu-options-checkbox"
            readOnly
          />
        ),
        onClick: () => onSetMandatoryOrDefault(provision.id, CHECKBOX_FIELD.MANDATORY),
      },
      {
        key: '2',
        label: (
          <Checkbox
            label="Selected by default"
            checked={provision.selectedByDefault}
            disabled
            className="card-header-menu-options-checkbox"
          />
        ),
        onClick: () => onSetMandatoryOrDefault(provision.id, CHECKBOX_FIELD.DEFAULT),
      },
      {
        key: '3',
        label: 'View Details',
        icon: Icons.EyeOpen,
        onClick: () => onEdit(provision),
      },
      {
        key: '4',
        label: 'Unselect',
        icon: Icons.Trash,
        // @ts-ignore
        onClick: () => onUnselectProvision(provision.id),
      },
    ];
  };

  const cardBodyTemplate = (provision: CardElement): ReactNode => {
    const documentMap = new Map();

    documentTypesList.map(element => {
      documentMap.set(element.id, element.name);
    });

    const getDocument = (key: string): string => {
      return documentMap.get(key);
    };

    return (
      <Grid className="component-card-body color-blue-desaturated-dark">
        <Grid.Row className="p-none">
          <Grid.Column
            className="p-none"
            width={5}
          >
            Appears in:
          </Grid.Column>
          <Grid.Column
            className="p-none color-blue-very-dark-grayish"
            width={11}
          >
            {provision.contents
              ?.map((_content: TProvisionContentPick, index: number) =>
                getDocument(provision.contents[index].documentTypeId),
              )
              .join(', ')}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const cardHeaderTemplate = (provision: CardElement): JSX.Element => {
    return (
      <div className="column-custom-template">
        <Popup
          className="popup-info"
          content={provision.name}
          trigger={<span className="name color-black">{provision.name}</span>}
        />
        {provision.selectedByDefault && (
          <Icon
            icon={Icons.TickCircleSolid}
            color={Colors.GREEN}
            data-test="select-by-default-icon"
            width="2rem"
            height="1.5rem"
          />
        )}
        {provision.mandatory && (
          <span
            className="card-header-icon-mandatory"
            data-test="mandatory-icon"
          >
            <span className="text-m">M</span>
          </span>
        )}
      </div>
    );
  };

  const cardConfigs: CardTemplateConfiguration<ProvisionCategoryClone, CardElement> = {
    columnConfiguration: {
      header: {
        titleField: 'name',
        showEdit: false,
      },
      width: '20rem',
    },
    cardConfiguration: {
      header: {
        headerTemplate: (provision: CardElement) => cardHeaderTemplate(provision),
        cardMenuItems: (provision: CardElement) => cardMenuItems(provision),
      },
      bodyTemplate: (provision: CardElement) => cardBodyTemplate(provision),
      onClick: (provision: CardElement) => moveToParameterTab(provision.id),
    },
  };

  // Only show the categories that have a provision
  const categoryList: ProvisionCategoryClone[] = provisionCategoriesList.filter(category => {
    const hasProvisions = orderedProvisions.find(
      (element: PolicyProvisionDetailClone) => element.provision?.provisionCategory?.id === category.id,
    );
    return hasProvisions;
  });

  // List, per category, the provisions that:
  //  - are part of this category
  //  - AND are in the policy
  const filterElementsPerCategory = (category: ProvisionCategoryClone): CardElement[] => {
    return orderedProvisions
      .filter(
        (element: PolicyProvisionDetailClone) =>
          element.provision.provisionCategory.id === category.id &&
          isProvisionInPolicy(activePolicyProvisions, element.provision.id),
      )
      .map((policyProvision: PolicyProvisionDetailClone) => {
        const result: CardElement = {
          ...policyProvision,
          ...policyProvision.provision,
        };
        return result;
      });
  };

  return (
    <div className="section-provisions p-t-xl p-l-xl border-sm-grayish-magenta-light bg-white">
      <h4 className="section-provisions-label color-blue-very-dark m-none">1. Selected Provisions</h4>
      <div className="policy-provisions-section">
        <CardViewTemplate<ProvisionCategoryClone, CardElement>
          categoryList={categoryList}
          configuration={cardConfigs}
          getCardElementsList={category => filterElementsPerCategory(category)}
        />
      </div>
    </div>
  );
};

export default UsedProvisionsCards;
