import { TabPane } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import Editor from 'components/Editor';
import TabContainer from 'components/TabContainer';
import { updateProvisionViewDetailsTab } from 'store/provisions/provisionDetailSlice';
import GeneralTab from './GeneralTab';

export enum PROVISION_TABS_OFFSET {
  GENERAL = 0,
  EDITOR = 1,
  PARAMETERS = 2,
  GUIDANCE = 3,
  APPROVALS = 4,
}

const getTabMenuConfig = (noApplicableDocuments: boolean): TabMenuProps[] => {
  return [
    {
      key: '0',
      href: '?tab=general',
      label: 'General',
      // @ts-ignore
      customTab: <GeneralTab />,
    },
    {
      key: '1',
      href: '?tab=editor',
      label: 'Editor',
      customTab: noApplicableDocuments ? (
        <TabPane attached={false}>
          <TabContainer tabTitle="Editor">
            <>No applicable documents was selected</>
          </TabContainer>
        </TabPane>
      ) : (
        <Editor />
      ),
    },
    {
      key: '2',
      href: '?tab=parameters',
      label: 'Parameters',
      customTab: (
        <TabPane attached={false}>
          <TabContainer tabTitle="Parameters">
            <>Parameters</>
          </TabContainer>
        </TabPane>
      ),
    },
    {
      key: '3',
      href: '?tab=guidance',
      label: 'Guidance',
      customTab: (
        <TabPane attached={false}>
          <TabContainer tabTitle="Guidance">
            <>Guidance</>
          </TabContainer>
        </TabPane>
      ),
    },
    {
      key: '4',
      href: '?tab=approvals',
      label: 'Approvals',
      customTab: (
        <TabPane attached={false}>
          <TabContainer tabTitle="Approvals">
            <>Approvals</>
          </TabContainer>
        </TabPane>
      ),
    },
  ];
};

const ProvisionTabs = () => {
  const { activeTabIndex, activeProvision } = useAppSelector((state: RootState) => state.provisionDetail);
  const contents = activeProvision?.contents || [];
  const noApplicableDocuments: boolean = contents.length === 0;

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateProvisionViewDetailsTab}
      tabMenu={getTabMenuConfig(noApplicableDocuments)}
    />
  );
};
export default ProvisionTabs;
