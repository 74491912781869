import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { updateActiveTableColumns } from 'store/parameters/parameterDetailSlice';
import { MODE_OPTIONS, PARAMETER_TABLE_IMPLEMENTATION } from 'common/api/miscellaneous';
import {
  createParameter,
  createParameterTable,
  getParameter,
  getParameterTable,
  updateParameter,
  updateParameterTable,
} from 'common/api/parameters';
import { checkIfValidNodeConditions } from 'utils/tsValidator';
import { parameterDisabled, tableDisabled } from '../ParameterValidator';

interface ParameterInformationTabToolbarProps {
  mode: MODE_OPTIONS;
  setMode: (value: MODE_OPTIONS) => void;
  isCreatePage: boolean;
  type: PARAMETER_TABLE_IMPLEMENTATION;
}

const ParameterInformationTabToolbar = ({
  mode,
  setMode,
  isCreatePage,
  type,
}: ParameterInformationTabToolbarProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activeParameter, activeTableParameter } = useAppSelector((state: RootState) => state.parameterDetail);
  const onCreate = (): void => {
    const onCreateMethod = type === PARAMETER_TABLE_IMPLEMENTATION.PARAMETER ? createParameter : createParameterTable;

    dispatch(onCreateMethod()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const data = response.payload.data;

        const { id } =
          type === PARAMETER_TABLE_IMPLEMENTATION.PARAMETER ? data['createParameter'] : data['createParameterTable'];

        const url =
          type === PARAMETER_TABLE_IMPLEMENTATION.PARAMETER
            ? `/parameters/${id}/details`
            : `/parameters/table/${id}/details`;

        navigate(url);
      }
    });
  };

  const onSave = (): void => {
    if (isCreatePage) {
      onCreate();
    } else {
      const update = type === PARAMETER_TABLE_IMPLEMENTATION.TABLE ? updateParameterTable : updateParameter;
      if (type === PARAMETER_TABLE_IMPLEMENTATION.TABLE) {
        let haveError = false;
        // need to validate all the conditions in columns
        const columns = activeTableParameter.columns.map(column => {
          if (!column.conditions) return column;
          if (!checkIfValidNodeConditions(column.conditions)) {
            haveError = true;
          }
          return column;
        });

        if (haveError) {
          setMode(MODE_OPTIONS.EDIT);
          toast.error('There are incomplete conditions. Please, check it');
          return;
        } else {
          dispatch(updateActiveTableColumns(columns));
        }
      }
      dispatch(update()).then(response => {
        if (response.meta.requestStatus === 'rejected') {
          onCancel();
        }
      });
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = (): void => {
    if (isCreatePage) {
      navigate('/parameters/dashboard');
    } else {
      const getDetails = type === PARAMETER_TABLE_IMPLEMENTATION.TABLE ? getParameterTable : getParameter;
      const id = type == PARAMETER_TABLE_IMPLEMENTATION.TABLE ? activeTableParameter.id : activeParameter.id;
      // @ts-ignore
      dispatch(getDetails({ id }));
    }
  };

  const checkDisabled = (): boolean => {
    return type === PARAMETER_TABLE_IMPLEMENTATION.TABLE
      ? tableDisabled(activeTableParameter)
      : parameterDisabled(activeParameter);
  };

  return (
    <DetailsControlButtons
      mode={mode}
      setMode={setMode}
      checkDisabled={!!checkDisabled()}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};

export default ParameterInformationTabToolbar;
