import React, { ReactElement, ReactNode } from 'react';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import './TabContainer.scss';

interface TabContainerProps {
  mode?: MODE_OPTIONS;
  tabToolbar?: ReactNode;
  tabTitle?: string | React.ReactElement;
  children: ReactElement<any>[] | ReactElement<any>;
  className?: string;
  nested?: boolean;
}

const TabContainer = ({ children, mode, tabTitle, tabToolbar, className, nested }: TabContainerProps) => {
  //tabToolbar,
  return (
    <div className={`tab-container ${className}`}>
      <div className="tab-container-header">
        <div className="tab-container-header-title">
          {/*typeof tabTitle === 'string' ? (
            nested ? (
              <h4 className="page-with-nested-tab-title color-blue-very-dark pm-none font-600">{tabTitle}</h4>
            ) : (
              <h3 className="page-with-tab-title color-blue-very-dark pm-none font-600">{tabTitle}</h3>
            )
          ) : (
            <>{tabTitle}</>
          )*/}
        </div>

        <div className="tab-container-header-toolbar">{tabToolbar}</div>
      </div>
      <div className="tab-container-body">
        {React.Children.map(children, child => React.cloneElement(child, { mode }))}
      </div>
    </div>
  );
};

export default TabContainer;
