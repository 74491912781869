import { Form } from 'semantic-ui-react';
import { EntityTypeProps } from 'common/api/entities';
import Contacts from './Contacts';
import EntityGeneralForm from './EntityGeneralForm';

const GeneralInfo = ({ entityType }: EntityTypeProps): JSX.Element => {
  return (
    <Form>
      <EntityGeneralForm entityType={entityType} />
      <Contacts />
    </Form>
  );
};

export default GeneralInfo;
