import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { DashboardPageType } from '../dashboard';

interface ListTranslateToContractQueryVariables {
  search: string | null;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
}

export const LIST_TRANSLATE_TO_CONTRACTS = (
  searchValue: string | null,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const variables: ListTranslateToContractQueryVariables = {
    first: first,
    last: last,
    after: after,
    before: before,
    search: searchValue,
  };

  return {
    query: gql`
      query ($first: Int, $last: Int, $after: String, $before: String, $search: String) {
        listTranslateToContracts(
          first: $first
          last: $last
          after: $after
          before: $before
          filter: { search: $search }
        ) {
          edges {
            cursor
            node {
              id
              name
              description
              active
              index
              reviewFlag
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables,
  };
};

export const listTranslateToContracts = createAsyncThunk(
  'translatorsToContract/listTranslateToContracts',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const {
      translatorsListing: { search },
    } = getState() as RootState;

    const response = await adminClient.query(LIST_TRANSLATE_TO_CONTRACTS(search, first, last, after, before));
    return response;
  },
);
