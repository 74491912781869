import Decimal from 'decimal.js';
import GovernmentRate from 'common/model/GovernmentRate';
import GovernmentRateInput from 'common/model/GovernmentRateInput';
import PaidBy from 'common/model/PaidBy';
import { convertDateToISO } from 'utils/utils-date';

export interface GovernmentRateInputClone
  extends Omit<GovernmentRateInput, 'paidBy' | 'rate' | 'startDate' | 'endDate'> {
  paidBy: PaidBy | null;
  rate: Decimal | null;
  startDate: Date | null;
  endDate: Date | null;
}

export class CGovernmentRate implements GovernmentRateInputClone {
  paidBy: PaidBy | null;
  rate: Decimal | null;
  startDate: Date | null;
  endDate: Date | null;

  constructor(input: GovernmentRate | null) {
    this.paidBy = input ? input.paidBy : null;
    this.rate = input && input.rate ? new Decimal(input.rate) : null;
    this.startDate = input && input.startDate ? new Date(input.startDate) : null;
    this.endDate = input && input.endDate ? new Date(input.endDate) : null;
  }

  static fragment() {
    return `
      paidBy
      rate
      startDate
      endDate
    `;
  }

  static formatForAPI(rate: CGovernmentRate) {
    return {
      paidBy: rate.paidBy,
      rate: rate.rate ? parseFloat(rate.rate.toString()) : null,
      startDate: convertDateToISO(rate.startDate),
      endDate: convertDateToISO(rate.endDate),
    };
  }
}
