export const handleScrollShadow = (scrollableWrapperId: string, shadowElementId: string) => {
  const scrollElement = document.getElementById(scrollableWrapperId) as HTMLElement;
  const shadowRight = document.getElementById(shadowElementId);

  const getScrollRatio = () => {
    const scrollLeft = scrollElement.scrollLeft;
    const maxScrollLeft = scrollElement.scrollWidth - scrollElement.clientWidth;
    return scrollLeft / maxScrollLeft;
  };

  const alterShadowOpacities = () => {
    const ratio = getScrollRatio();
    const scrollRatio = isNaN(ratio) ? 0 : ratio;
    const maxScrollLeft = scrollElement.scrollWidth - scrollElement.clientWidth;

    const shadowRightOffsetX = 10 * scrollRatio;

    const color = '#888';

    const blackShadowColor = '#00000026';

    if (shadowRight)
      shadowRight.style.boxShadow = `${shadowRightOffsetX >= 9.9 || maxScrollLeft === 0 ? 16 : shadowRightOffsetX}px 4px 12px 4px ${color}`;

    const headerSecondElement = scrollElement.querySelector('thead th:nth-of-type(2)') as HTMLElement;
    const bodySecondElements = scrollElement.querySelectorAll('tr td:nth-of-type(2)') as NodeListOf<HTMLElement>;

    const offSetX = 10 * scrollRatio;
    const offSetY = 6 * scrollRatio;

    const spread = 8 * scrollRatio;

    if (headerSecondElement)
      headerSecondElement.style.boxShadow = `${offSetX}px -${spread}px ${spread}px 0 ${blackShadowColor}`;

    bodySecondElements.forEach(element => {
      element.style.boxShadow = `${offSetX}px ${offSetY}px ${spread}px 0 ${blackShadowColor}`;
    });
  };

  scrollElement?.addEventListener('scroll', alterShadowOpacities);

  const resizeObserver = new ResizeObserver(() => {
    alterShadowOpacities();
  });

  resizeObserver.observe(scrollElement);

  return () => {
    if (scrollElement) scrollElement?.removeEventListener('scroll', alterShadowOpacities);
  };
};
