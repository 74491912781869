import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Contract2 from 'common/model/Contract2';
import Contract2Connection from 'common/model/Contract2Connection';
import PageInfo from 'common/model/PageInfo';
import { deleteContract2, listContracts2 } from 'common/api/contracts2';

interface ContractListingStateV2 {
  contractsList2: Contract2[];
  isLoading: boolean;
  isSearching: boolean;
  search: string | null;
  pageInfo: PageInfo | null;
}

const initialState: ContractListingStateV2 = {
  contractsList2: [],
  isLoading: false,
  isSearching: false,
  search: null,
  pageInfo: null,
};

const contractListingSliceV2 = createSlice({
  name: 'contractListingV2',
  initialState,
  reducers: {
    updateContractsListingSearchValue: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: builder => {
    // listContracts2
    builder.addCase(listContracts2.pending, (state, action) => {
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listContracts2.fulfilled, (state, action) => {
      state.isLoading = false;
      const { edges, pageInfo }: Contract2Connection = action.payload.data.listContracts2;
      console.log('edges', edges);
      state.contractsList2 = edges.map(element => element.node);
      state.pageInfo = pageInfo;
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listContracts2.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.warn(action.error);
      toast.error('listContracts2 API request rejected');
    });

    // deleteContract
    builder.addCase(deleteContract2.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteContract2.fulfilled, (state, action) => {
      const deletedContractId = action.meta.arg.id;
      state.contractsList2 = state.contractsList2.filter(({ id }) => id !== deletedContractId);
      state.isLoading = false;
      toast.success('Contract deleted successfully');
    });
    builder.addCase(deleteContract2.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('deleteContract2 API request rejected');
    });
  },
});

export const { updateContractsListingSearchValue } = contractListingSliceV2.actions;

export default contractListingSliceV2.reducer;
