import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import CurrentLease from './CurrentLease';
import EmptyAreaDetails from './EmptyAreaDetails';
import PropertyNextEvents from './PropertyNextEvents';

export enum sideSections {
  EMPTY_AREA_DETAILS = 1,
  PROPERTY_NEXT_EVENTS,
  CURRENT_LEASE,
}

const SideMenu = () => {
  const { activeSideBar } = useAppSelector((state: RootState) => state.stackingPlan);

  switch (activeSideBar) {
    case sideSections.EMPTY_AREA_DETAILS:
      return <EmptyAreaDetails />;
    case sideSections.CURRENT_LEASE:
      return <CurrentLease />;
    default:
      return <PropertyNextEvents />;
  }
};

export default SideMenu;
