import DashboardPage from 'views/dashboard';
import { isAuthenticated } from './redirects';

const DashboardRoutes = () => [
  {
    path: '/dashboard',
    element: isAuthenticated(<DashboardPage />),
  },
];

export default DashboardRoutes;
