import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import InviteUserModal from 'views/userAccountManagement/components/TeamModals/InviteUserModal';
import TeamMemberModal from 'views/userAccountManagement/components/TeamModals/TeamMemberModal';
import { onEditNewMemberDetails, onEditTeamMemberDetails } from 'store/members/membersSlice';
import MemberStatus from 'common/model/MemberStatus';
import { MemberClone, updateMemberResendInvite, updateMemberStatusAndRole } from 'common/api/members';
import { ColorNames } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import { MemberRow } from './MembersTableConfig';

interface MemberRowActionButtonsParams {
  row: MemberRow;
}

export const MemberRowActionButtons = ({ row }: MemberRowActionButtonsParams) => {
  const dispatch = useAppDispatch();

  const onInvite = (id: string) => {
    dispatch(onEditNewMemberDetails(id));
    dispatch(updateMemberResendInvite());
  };

  const onRevoke = (member: MemberClone) => {
    dispatch(
      updateMemberStatusAndRole({
        memberId: member.id,
        params: {
          userId: member.user.id,
          entityId: member.entity.id,
          roleId: member.role.id,
          status: MemberStatus.Revoked,
        },
      }),
    );
  };

  let buttons: TableActionButton[] = [];
  if (row.status === MemberStatus.Valid) {
    buttons.push({
      template: (
        <TeamMemberModal
          trigger={
            <button
              className="mini compact ui button"
              key={'table-action-row-' + row.id}
              data-tooltip="Edit Details"
              data-position="left center"
              onClick={() => dispatch(onEditTeamMemberDetails(row.id))}
              data-test="edit-details-button"
            >
              <Icon
                icon={Icons.Pencil}
                className={ColorNames.GREY}
              />
            </button>
          }
          createMode={false}
        />
      ),
    });
  } else {
    buttons.push({
      template: (
        <InviteUserModal
          trigger={
            <button
              className="mini compact ui button"
              key={'table-action-row-' + row.id}
              data-tooltip="Edit Details"
              data-position="left center"
              onClick={() => dispatch(onEditNewMemberDetails(row.id))}
              data-test="edit-details-button"
            >
              <Icon
                icon={Icons.Pencil}
                className={ColorNames.GREY}
              />
            </button>
          }
          createMode={false}
        />
      ),
    });
  }

  if (row.status === MemberStatus.Revoked || row.status === MemberStatus.Refused) {
    buttons.push({
      icon: Icons.ArrowDownRight,
      tooltip: 'Invite',
      onClick: () => onInvite(row.id),
      dataTest: 're-invite-user',
    });
  } else {
    buttons.push({
      icon: Icons.Refuse,
      tooltip: 'Revoke',
      onClick: () => onRevoke(row.member),
      isPopup: true,
      popupText: 'Do you really want to revoke this user?',
      popupConfirmationButton: 'REVOKE USER',
      popupCancelButton: 'REMAIN',
      dataTest: 'revoke-user',
    });
  }

  return <TableActionButtons buttons={buttons} />;
};
