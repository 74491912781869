import { PolicyListingClone } from 'common/_classes';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewButtonProps } from 'layouts/PageHeader/CreateNewButton';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import TableReactPrime from 'atoms/TableReactPrime';
import { ParameterModes, updateParameterMode } from 'store/miscellaneous/miscellaneousSlice';
import { createNewPolicy } from 'store/policies/policyDetailSlice';
import { listCountries, listDocumentTypes, listLanguages } from 'common/api/miscellaneous';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { listPolicies } from 'common/api/policies';
import { listProvisionsAndCategories } from 'common/api/provisions';
import { checkIfAdminURL } from 'utils/utils-admin';
import { STORAGE_KEYS } from 'utils/utils-storage';
import PoliciesHeaderToolbar from './PoliciesHeaderToolbar';
import { PolicyListRow, policyColumnConfig } from './PolicyTablesConfig';
import './PoliciesDashboard.scss';

const PoliciesDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isLoading,
    policiesList,
    // @ts-ignore
    pageInfo: { startCursor },
  } = useAppSelector((state: RootState) => state.policiesListing);
  const { countries, languages } = useAppSelector((state: RootState) => state.miscellaneous);

  const isAdmin: boolean = checkIfAdminURL();

  const onViewDetails = (id: string): void => {
    if (!isAdmin) {
      const url = `/policies/${id}/details`;
      navigate(url);
      dispatch(updateParameterMode(ParameterModes.Normal));
    }
  };

  useEffect(() => {
    dispatch(listProvisionsAndCategories({ first: 300 }));
    dispatch(listDocumentTypes({ first: 100 }));
  }, [dispatch]);

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(listCountries());
    }
    if (!languages.length) {
      dispatch(listLanguages());
    }
  }, [dispatch]);

  /*
   * When click on "create" button: open the policy create page
   */
  const onCreateNewPolicy = () => {
    dispatch(createNewPolicy());
    navigate('/policies/create');
  };

  /*
   * Page definition with
   *  - title,
   *  - filter,
   *  - create button for new policy,
   *  - listing of policies includgin action button column.
   */

  const breadCrumb: BreadCrumbItem[] = [{ title: 'Policies' }];

  const getPageHeaderProps = (): PageHeaderProps => {
    const createNewButton: CreateNewButtonProps = {
      btnText: 'CREATE POLICY',
      url: '/policies/create',
      dataTest: 'create-policy-button',
      onCreateNew: onCreateNewPolicy,
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <PoliciesHeaderToolbar />,
      createNewButton: !checkIfAdminURL() ? createNewButton : undefined,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  const currentList: PolicyListRow[] = policiesList.map(
    (policy: PolicyListingClone) => new PolicyListRow(policy, countries),
  );

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          dispatch(listPolicies({ ...rest }));
        }}
        isFetching={isLoading}
        // The typing on redux is 'never', it should be updated
        content={currentList}
        columnConfig={policyColumnConfig(languages, countries, isAdmin, startCursor, onViewDetails)}
        dataTest="policy-table"
        stateKey={STORAGE_KEYS.POLICY_TABLE}
        onRowClick={onViewDetails}
      />
    </PageLayout>
  );
};

export default PoliciesDashboard;
