import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import CheckboxField from 'atoms/FormField/Checkbox';
import { ActionCreator, MODE_OPTIONS, ReviewStatusClone } from 'common/api/miscellaneous';
import { getNewReviewTime, getNewReviewer } from 'utils/tsHelper';
import { getDateText } from 'utils/utils-date';
import './ReviewBox.scss';

export interface ReviewBoxValues {
  reviewFlag: boolean;
  reviewStatus: ReviewStatusClone | null;
}

interface ReviewBoxProps {
  mode?: MODE_OPTIONS;
  values: ReviewBoxValues;
  onChange: ActionCreator;
}

const ReviewBox = ({ mode, values, onChange }: ReviewBoxProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { firstName, lastName, id } = useAppSelector((state: RootState) => state.auth);

  const getFullName = (): string =>
    !values.reviewStatus?.user?.firstName && !values.reviewStatus?.user?.lastName
      ? ' --- '
      : `${values.reviewStatus.user?.firstName} ${values.reviewStatus.user?.lastName}`;

  const onReviewChange = (_fieldKey: string, value: boolean): void => {
    const reviewStatus = {
      date: getNewReviewTime(value),
      user: getNewReviewer(value, id, firstName, lastName),
    };

    dispatch(onChange({ reviewStatus, reviewFlag: value }));
  };

  return (
    <div className="review-box-template">
      <div className="review-checkbox m-b-m-b-xs">
        <span className="section-title">Reviewed</span>
        {/* @ts-ignore:next-line */}
        <CheckboxField
          disabled={mode === MODE_OPTIONS.READ}
          value={values.reviewFlag}
          onChange={onReviewChange}
        />
      </div>

      <div className="review-text color-blue-desaturated-dark">By: {getFullName()}</div>
      <div className="review-text color-blue-desaturated-dark m-b-xxs">
        Date: {values.reviewStatus !== null ? getDateText(values.reviewStatus.date) : ''}
      </div>
    </div>
  );
};

export default ReviewBox;
