import { ApexOptions } from 'apexcharts';
import { MouseEvent } from 'react';
import { Blocks, Floor, GraphInput, GraphInputData, GraphInputDataMeta } from 'utils/types/stackingPlan';
import { Colors } from 'utils/utils-colors';
import { getMonthsDifference } from 'utils/utils-date';
import { addSpaceOrComma } from 'utils/utils-number';
import { AVAILABILITY_STATUS, BAR_COLOR } from './stackingPlanDefinitions';

const GRAPH_COLOR = Colors.BLUE_DARK_GRAYISH;
const GRAPH_BACKGROUND = Colors.WHITE;

const getFloorIndexes = (totalFloor: number, buildingNumberOfUndergroundFloors: number) => {
  const categories: number[] = [];
  let i = 0;
  if (buildingNumberOfUndergroundFloors) {
    i -= buildingNumberOfUndergroundFloors;
  }
  for (; i < totalFloor; i++) {
    categories.push(i);
  }
  return categories;
};

const getXAxis = (totalFloor: number, buildingNumberOfUndergroundFloors: number): ApexXAxis => {
  return {
    type: 'category',
    overwriteCategories: getFloorIndexes(totalFloor, buildingNumberOfUndergroundFloors).reverse(),
    labels: {
      hideOverlappingLabels: true,
      showDuplicates: false,
      style: {
        colors: [GRAPH_COLOR],
        cssClass: 'x-axis-label',
      },
      trim: true,
    },
    title: {
      text: 'Total Area (sqft)​',
      style: {
        color: GRAPH_COLOR,
        cssClass: 'x-axis-label',
      },
    },
  };
};

const getYAxis = (formatter: (val: number, options?: any) => string | string[], max: number): ApexYAxis => {
  return {
    show: true,
    showAlways: true,
    forceNiceScale: true,
    reversed: false,
    floating: false,
    decimalsInFloat: 0,
    max: max || 5000,
    labels: {
      show: true,
      align: 'right',
      minWidth: 100,
      maxWidth: 100,
      style: {
        colors: GRAPH_COLOR,
        cssClass: 'y-axis-label',
      },
      formatter,
    },
    tooltip: {
      enabled: true,
      offsetX: 0,
    },
  };
};

const getDataLabels = (formatter: (val: number, options?: any) => string | number): ApexDataLabels => {
  return {
    enabled: true,
    enabledOnSeries: undefined,
    textAnchor: 'middle',
    distributed: false,
    offsetY: 0,
    style: {
      fontSize: '12px',
      fontFamily: 'Urbanist, Arial, sans-serif',
      fontWeight: 'bold',
    },
    background: {
      enabled: true,
      foreColor: Colors.BLUE_VERY_DARK,
      padding: 4,
      opacity: 0.7,
    },
    formatter,
  };
};

export const formatTenantName = (value: number, tenantName: string, maxLength: number) => {
  return (
    (tenantName.length > maxLength ? tenantName.slice(0, maxLength).trim() + '...' : tenantName) +
    ' ' +
    addSpaceOrComma(value, false)
  );
};

const getTooltip = (formatter: (options?: any) => string | number): ApexTooltip => {
  return {
    custom: formatter,
    enabled: true,
    shared: false,
    followCursor: true,
    intersect: true,
    inverseOrder: false,
    theme: 'light',
    style: {
      fontSize: '12px',
      fontFamily: undefined,
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    marker: {
      show: false,
    },
    items: {
      display: 'flex',
    },
    fixed: {
      enabled: false,
      position: 'topRight',
      offsetX: 0,
      offsetY: 0,
    },
  };
};

const plotOptions = {
  bar: {
    horizontal: true,
    columnWidth: '80%',
    barHeight: '100%',
    rangeBarOverlap: false,
    rangeBarGroupRows: true,
    dataLabels: {
      hideOverflowingLabels: true,
      position: 'center',
      total: {
        enabled: false,
        style: {
          fontSize: '13px',
          fontWeight: 900,
        },
      },
    },
  },
};

interface GraphConfigurationInput {
  barClickHandler: (e: MouseEvent, chart?: any, options?: any) => void;
  yAxisLabelsFormatter: (val: number, options?: any) => string | string[];
  barLabelsFormatter: (val: number, options?: any) => string | number;
  barTooltipFormatter: (options?: any) => string | number;
  totalFloor: number;
  buildingNumberOfUndergroundFloors: number;
  maxScale: number;
}

const getGraphConfiguration = ({
  barClickHandler,
  yAxisLabelsFormatter,
  barLabelsFormatter,
  barTooltipFormatter,
  totalFloor,
  buildingNumberOfUndergroundFloors,
  maxScale,
}: GraphConfigurationInput): ApexOptions => {
  return {
    chart: {
      type: 'bar',
      width: '100%',
      stacked: true,
      stackType: 'normal',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
      background: GRAPH_BACKGROUND,
      events: {
        click: barClickHandler,
      },
    },
    stroke: {
      show: true,
      colors: [GRAPH_BACKGROUND],
      curve: 'straight',
      lineCap: 'square',
      width: 5,
    },
    noData: {
      text: 'No Units Found, Please try with different filters',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: '14px',
        fontFamily: undefined,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: plotOptions,
    fill: {
      opacity: 1,
    },
    dataLabels: getDataLabels(barLabelsFormatter),
    xaxis: getXAxis(totalFloor, buildingNumberOfUndergroundFloors),
    yaxis: getYAxis(yAxisLabelsFormatter, maxScale),
    legend: {
      show: false,
      position: 'right',
      offsetY: 40,
    },
    tooltip: getTooltip(barTooltipFormatter),
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'lighten',
          value: 0.15,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
        },
      },
    },
  };
};

const mapToGraphData = (
  floorsList: Floor[],
  totalFloor: number,
  buildingNumberOfUndergroundFloors: number,
  blocks: Blocks[],
  monitoringDate: string,
): { data: GraphInput[]; maxArea: number } => {
  const floorData: GraphInput[] = [];
  let maxArea = 0;
  floorsList.forEach(floor => {
    if (floor) {
      const { id, name: floorName, area, index } = floor;
      let availableArea = Number(area);
      if (maxArea < availableArea) {
        maxArea = Number(area);
      }
      if (blocks.length) {
        const floorBlocks: Blocks[] = [];
        blocks
          .filter(({ floorId }) => floorId === id)
          .forEach(block => {
            const index = floorBlocks.findIndex(
              ({ floorId, contractId }) => block.floorId === floorId && block.contractId === contractId,
            );
            if (index > -1) {
              const area = Number(floorBlocks[index].area) + Number(block.area);
              floorBlocks[index].area = area;
            } else {
              floorBlocks.push({ ...block });
            }
          });
        floorBlocks.forEach(
          ({
            area: blockArea,
            contractId,
            tags: { availableDate },
            tenantName,
            futureContracts,
            effectiveRent,
            headlineRent,
          }) => {
            availableArea = availableArea - Number(blockArea);
            const { availabilityStatus: status, color } = getAvailabilityInfo(availableDate, monitoringDate);
            const meta: GraphInputDataMeta = {
              id: id + contractId,
              floorId: id,
              availabilityStatus: status,
              color,
              contractId: status !== AVAILABILITY_STATUS.AVAILABLE ? contractId : null,
              tenantName: status !== AVAILABILITY_STATUS.AVAILABLE ? tenantName : null,
              futureContractIds: futureContracts
                ? futureContracts.map(futureContract => futureContract.contractId)
                : null,
              floorName,
              availableDate,
              effectiveRent,
              headlineRent,
            };

            floorData.push({
              ...meta,
              data: getGraphData(index, blockArea, totalFloor, buildingNumberOfUndergroundFloors, meta),
            });
          },
        );
      }
      if (availableArea > 0) {
        const meta: GraphInputDataMeta = {
          id,
          floorId: id,
          color: BAR_COLOR.AVAILABLE,
          availabilityStatus: AVAILABILITY_STATUS.AVAILABLE,
          contractId: null,
          tenantName: null,
          futureContractIds: null,
          floorName,
        };
        floorData.push({
          ...meta,
          data: getGraphData(index, availableArea, totalFloor, buildingNumberOfUndergroundFloors, meta),
        });
      }
    }
  });
  return { data: floorData, maxArea };
};

const getGraphData = (
  index: string,
  area: string | number,
  totalFloor: number,
  buildingNumberOfUndergroundFloors: number,
  meta: GraphInputDataMeta,
): GraphInputData[] => {
  const temp: GraphInputData[] = [];
  const floorIndex = Number(index);
  let i = totalFloor - 1;
  while (i >= -buildingNumberOfUndergroundFloors) {
    if (i === floorIndex) {
      temp.push({ x: i, y: area, meta });
    } else {
      temp.push({ x: i, y: 0, meta });
    }
    i--;
  }
  return temp;
};

const getAvailabilityInfo = (
  availableDate: string,
  monitoringDate: string,
): { availabilityStatus: string; color: string } => {
  const blockAvailableDate = new Date(availableDate);
  const currentDate = new Date(monitoringDate);
  if (blockAvailableDate < currentDate) {
    return {
      color: BAR_COLOR.AVAILABLE,
      availabilityStatus: AVAILABILITY_STATUS.AVAILABLE,
    };
  }
  const differenceInMonths = getMonthsDifference(currentDate, blockAvailableDate);
  if (differenceInMonths < 6) {
    return {
      color: BAR_COLOR.LESS_THAN_SIX,
      availabilityStatus: AVAILABILITY_STATUS.LESS_THAN_SIX,
    };
  }
  if (differenceInMonths >= 6 && differenceInMonths <= 12) {
    return {
      color: BAR_COLOR.SIX_TO_TWELVE,
      availabilityStatus: AVAILABILITY_STATUS.SIX_TO_TWELVE,
    };
  }
  if (differenceInMonths > 12 && differenceInMonths <= 24) {
    return {
      color: BAR_COLOR.TWELVE_TO_TWENTY_FOUR,
      availabilityStatus: AVAILABILITY_STATUS.TWELVE_TO_TWENTY_FOUR,
    };
  }
  return {
    color: BAR_COLOR.MORE_THAN_TWENTY_FOUR,
    availabilityStatus: AVAILABILITY_STATUS.MORE_THAN_TWENTY_FOUR,
  };
};

export { GRAPH_BACKGROUND, mapToGraphData, getGraphConfiguration };
