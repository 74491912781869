import { useState } from 'react';
import { Button, Form, Image, Input } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import PasswordPopUp from 'atoms/PasswordPopUp';
import { updateInput } from 'store/authentication/authSlice';
import { resetPassword } from 'common/api/authentication';
import { checkNotEmpty } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';
import { PasswordValidator, passwordValidator, passwordValidatorChecks } from 'utils/utils-password';
import triangleCheck from 'assets/images/svg/triangle-check.svg';

const NewPassword = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { newPassword, newPasswordConfirmation } = useAppSelector((state: RootState) => state.auth);
  const [type, setType] = useState<InputFieldType>(InputFieldType.PASSWORD);
  const [typeConfirm, setTypeConfirm] = useState<InputFieldType>(InputFieldType.PASSWORD);
  const { minLength, specialCharacters, oneUpperCase, oneLowerCase, oneNumber } = passwordValidatorChecks;
  const checkNewPassword: PasswordValidator = passwordValidator({
    validationChecks: [minLength, specialCharacters, oneUpperCase, oneLowerCase, oneNumber],
    value: newPassword,
    isNewPasswordField: true,
  });

  // conditions for validator and checked renders
  const showNewPasswordError: boolean = checkNewPassword.isInValid && newPassword.length !== 0;
  const showNewPasswordConfirmationError: boolean =
    newPassword !== newPasswordConfirmation && newPasswordConfirmation.length !== 0;
  const showChecked: boolean = !checkNewPassword.isInValid && newPassword === newPasswordConfirmation;

  const checkDisabled = (): boolean => {
    if (newPassword !== newPasswordConfirmation) {
      return true;
    } else if (checkNewPassword.isInValid) {
      return true;
    } else {
      return false;
    }
  };

  const moveUp = (): boolean => {
    return checkNotEmpty(newPassword) && checkNotEmpty(newPasswordConfirmation);
  };

  return (
    <Form
      className="auth-form"
      step="3"
      onSubmit={() => dispatch(resetPassword())}
    >
      <div className="content">
        <h1 className="header">
          New Password <Icon icon={Icons.CircleDownFilled} />
        </h1>
        <Form.Field>
          <label>
            New Password <span className="required">*</span>
            <PasswordPopUp />
          </label>
          <Input
            value={newPassword}
            icon={
              <Icon
                icon={type === InputFieldType.PASSWORD ? Icons.Eye : Icons.EyeSlash}
                onClick={() =>
                  setType(type === InputFieldType.PASSWORD ? InputFieldType.TEXT : InputFieldType.PASSWORD)
                }
              />
            }
            placeholder="Enter your password..."
            type={type}
            onChange={e => dispatch(updateInput({ type: 'newPassword', value: e.target.value }))}
            required={true}
          />
          {showNewPasswordError && (
            <p className="validation-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              {checkNewPassword.errorMessage}
            </p>
          )}
          {!checkNewPassword.isInValid && (
            <p className="validation-message success">
              <Image
                className="triangle-check width-0_857 height-0_857"
                src={triangleCheck}
              />
              Password is secure
            </p>
          )}
        </Form.Field>
        <Form.Field>
          <label>
            Confirm new Password <span className="required">*</span>
          </label>
          <Input
            value={newPasswordConfirmation}
            icon={
              <Icon
                icon={typeConfirm === InputFieldType.PASSWORD ? Icons.Eye : Icons.EyeSlash}
                onClick={() =>
                  setTypeConfirm(
                    typeConfirm === InputFieldType.PASSWORD ? InputFieldType.TEXT : InputFieldType.PASSWORD,
                  )
                }
              />
            }
            placeholder="Enter your password..."
            type={typeConfirm}
            onChange={e =>
              dispatch(
                updateInput({
                  type: 'newPasswordConfirmation',
                  value: e.target.value,
                }),
              )
            }
            required={true}
          />
          {showNewPasswordConfirmationError && (
            <p className="validation-message">
              <Icon
                icon={Icons.Caution}
                className="height-0_857 width-0_857"
              />
              Passwords is not same.
            </p>
          )}
          {showChecked && (
            <p className="validation-message success">
              <Image
                className="triangle-check width-0_857 height-0_857"
                src={triangleCheck}
              />
              Checked
            </p>
          )}
        </Form.Field>
        <Button
          className="login-btn height-2_857 font-600 text-center"
          step="3"
          type="submit"
          moveup={`${moveUp()}`}
          disabled={checkDisabled()}
        >
          CREATE NEW PASSWORD
          <Icon
            icon={Icons.ArrowDownRight}
            rotate={4}
            className="width-1_143 height-1_143"
          />
        </Button>
      </div>
    </Form>
  );
};

export default NewPassword;
