import { ChartType, TooltipModel } from 'chart.js';
import {
  ContextAnalysisData,
  DASHBOARD_PAGE_SCOPE,
  PortfolioRevenueAnalysis,
  PropertyAnalysisData,
  PropertyRevenueAnalysis,
  UnitAnalysisData,
} from 'common/api/dashboard';
import {
  getAmount,
  getBarWidth,
  getColour,
  getMaxAbsoluteVariance,
  getVacancyText,
  sortByFloorIndexAndUnitName,
} from 'utils/tsHelper';
import { isNil } from 'utils/tsValidator';
import { ColorNames } from 'utils/utils-colors';
import { addSpaceOrComma } from 'utils/utils-number';

export enum OccupancyStatus {
  OCCUPIED = 'OCCUPIED',
  NOT_OCCUPIED = 'NOT OCCUPIED',
}

interface PropertyHTMLData {
  floorName: string;
  unitName: string;
  area: string;
  forecastArea: string;
  contractualArea: string;
  status: OccupancyStatus;
}

export const OccupancyVariancePropertyTooltip =
  (data: PropertyRevenueAnalysis) =>
  ({ title: [title], dataPoints }: TooltipModel<ChartType>) => {
    const index: number = dataPoints[0].dataIndex;
    const propertyStat: ContextAnalysisData = data.propertyData[index];

    const {
      statsArea: { contractualArea, forecastArea },
      statsRevenue: {
        revenueOverPerformance: { variance: overPerformanceVariance },
        revenueUnderPerformance: { variance: underPerformanceVariance },
      },
    } = propertyStat;

    const filteredUnitsData: UnitAnalysisData[] = data.unitsData.filter(
      (item: UnitAnalysisData) => item.month === propertyStat.month && !isNil(item.statsArea.variance),
    );

    const sortedfilteredUnitsData: PropertyHTMLData[] = sortByFloorIndexAndUnitName(filteredUnitsData).map(
      ({ floorName, unitName, statsArea: { area, forecastArea, contractualArea } }) => {
        const status: OccupancyStatus = getVacancyText(contractualArea, forecastArea);

        return {
          floorName,
          unitName,
          area,
          forecastArea,
          contractualArea,
          status,
        };
      },
    );

    return `
      <div class="inner-html occupancy-variance">
        <h4 class="title">Occupancy variance - ${title}</h4>

        <h3>
          Contractual area :
          <span class="m-l-xs">
            ${addSpaceOrComma(contractualArea, false)} sqft (gross)
          </span>
        </h3>
    
        <h3>
          Forecasted area :
          <span class="m-l-xs">
            ${addSpaceOrComma(forecastArea, false)} sqft (gross)
          </span>
        </h3>

        <h3 class="m-t-xs" >
          Overperformance variance :
          <span class="${ColorNames.GREEN}">
            ${addSpaceOrComma(overPerformanceVariance, false)} sqft
          </span>
          (gross)
        </h3>

        <h3>
          Underperformance variance :
          <span class="${ColorNames.RED}">
            ${addSpaceOrComma(underPerformanceVariance, false)} sqft
          </span>
          (gross)
        </h3>

        ${
          sortedfilteredUnitsData.length > 0
            ? `
          <div class="table-container">
            <table class="ui table dashboard-table occupancy-variance">
              <thead>
                <tr>
                  <th>Floor / units</th>
                  <th>Total area (sqft, gross)</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
                ${sortedfilteredUnitsData
                  .map(unitData => {
                    return `
                    <tr>
                      <td>
                        ${unitData.floorName} <span class="m-l-xs">(${unitData.unitName})</span>
                      </td>
                      <td class="align-right">
                        ${addSpaceOrComma(unitData.area, false)}
                      </td>
                      <td>
                        <div class="box ${unitData.status === OccupancyStatus.OCCUPIED ? 'green' : 'red'}">
                          ${unitData.status}
                        </div>
                      </td>
                    </tr>
                  `;
                  })
                  .join('')}
              </tbody>
            </table>
          </div>
          `
            : ''
        }
      </div>
      `;
  };

interface PortfolioHTMLData {
  overPerformanceVariance: string;
  underPerformanceVariance: string;
  variance: string | null | undefined;
  underPerformanceBarWidth: number;
  overPerformanceBarWidth: number;
  propertyId: string;
  propertyName: string;
  area: string;
}

const getPortfolioDisplayData = (propertyData: PropertyAnalysisData, maxVariance: number): PortfolioHTMLData => {
  const {
    occupancyOverPerformance: { variance: overPerformanceVariance },
    occupancyUnderPerformance: { variance: underPerformanceVariance },
  } = propertyData.statsArea || {
    occupancyOverPerformance: {},
    occupancyUnderPerformance: {},
  };

  let variance: string | number =
    (overPerformanceVariance ? parseFloat(overPerformanceVariance) : 0) +
    (underPerformanceVariance ? parseFloat(underPerformanceVariance) : 0);

  variance = variance.toFixed(2);

  const underPerformanceBarWidth = getBarWidth(maxVariance, underPerformanceVariance);

  const overPerformanceBarWidth = getBarWidth(maxVariance, overPerformanceVariance);

  return {
    propertyId: propertyData.propertyId,
    propertyName: propertyData.propertyName,
    area: propertyData.statsArea.area,
    overPerformanceVariance,
    underPerformanceVariance,
    variance,
    underPerformanceBarWidth,
    overPerformanceBarWidth,
  };
};

export const OccupancyVariancePortfolioTooltip =
  (data: PortfolioRevenueAnalysis, tab: string) =>
  ({ title: [title], dataPoints }: TooltipModel<ChartType>) => {
    const index: number = dataPoints[0].dataIndex;
    const month: string = data.portfolioData[index].month;

    const propertiesData: PropertyAnalysisData[] = data.propertiesData.filter(item => month === item.month);

    const maxVariance: number = getMaxAbsoluteVariance(propertiesData);

    const propertiesFormated: PortfolioHTMLData[] = propertiesData.map((propertyData: PropertyAnalysisData) =>
      getPortfolioDisplayData(propertyData, maxVariance),
    );

    return `
    <div class="inner-html occupancy-variance">
      <h4 class="title">Occupancy Forecast - ${title}</h4>

      <div class="table-container">
        <table class="ui table dashboard-table occupancy-variance">
          <thead>
            <tr>
              <th>Property</th>
              <th>Total area (sqft, gross)</th>
              <th>Variance (sqft)</th>
              <th>Variance Details (sqft)</th>
              <th>Under performance (sqft)</th>
              <th>Over performance (sqft)</th>
            </tr>
          </thead>

          <tbody>
            ${propertiesFormated
              .map(property => {
                return `
                <tr>
                  <td>
                    <a href="?context=${DASHBOARD_PAGE_SCOPE.PROPERTY}&propertyId=${property.propertyId}&propertyName=${
                      property.propertyName
                    }&tab=${tab}" target="_blank">
                      ${property.propertyName}
                    </a>
                  </td>
                  <td>
                    ${addSpaceOrComma(property.area, false)}
                  </td>
                  <td class="${getColour(property.variance)}">
                    ${getAmount(property.variance)}
                  </td>
                  <td class="bar-cell">
                    <div class="bar-container">
                      <div class="axis-line"></div>
                      <div class="bar">
                        <div class="red-bar" style="width: ${property.underPerformanceBarWidth}%">
                        </div>
                      </div>
                      <div class="bar">
                        <div class="green-bar" style="width: ${property.overPerformanceBarWidth}%">
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="${getColour(property.underPerformanceVariance)}">
                    ${getAmount(property.underPerformanceVariance)}
                  </td>
                  <td class="${getColour(property.overPerformanceVariance)}">
                    ${getAmount(property.overPerformanceVariance)}
                  </td>
              </tr>
              `;
              })
              .join('')}
          </tbody>
        </table>
      </div>
    </div>
    `;
  };
