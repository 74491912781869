import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';

interface GetNodeQueryVariables {
  id: string;
}

export const GET_NODE = (nodeId: string) => {
  if (!nodeId) throw new Error('Get Node: nodeId is required');

  const variables: GetNodeQueryVariables = {
    id: nodeId,
  };

  return {
    query: gql`
      query GetNode($id: ID!) {
        getNode(id: $id) {
          id
          name
          level
          iterable
          conditions {
            list {
              answer
              paramRef2 {
                tableId
                position
                offset
                parameterId
                index
              }
              comparator
              paramRef {
                tableId
                position
                offset
                parameterId
                index
              }
              position
              type
            }
            operator
          }
          formatter {
            id
            name
          }
          provision {
            id
            name
          }
          paramRefs {
            index
            offset
            parameterId
            position
            tableId
          }
          type
        }
      }
    `,
    variables,
  };
};

export const getNode = createAsyncThunk('nodes/getNode', async (id: string) => {
  const response = await getClientType().query(GET_NODE(id));
  return response.data;
});
