import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import ExportDropdown from 'atoms/ExportDropdown';
import ImportDropdown from 'atoms/ImportDropdown';
import ImportReport from 'atoms/ImportReport';
import { DataObjectName } from 'atoms/ImportReport/utils-interfaces';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/entities/entitiesListingSlice';
import { DocumentsType } from 'common/api/contracts';
import { EntityCreatorTypes, listEntities } from 'common/api/entities';

interface TenantEntitiesFilterProps {
  onImportTenant: (simulation: boolean, file: DocumentsType) => void;
  onExportTenant: () => void;
  isExporting: boolean;
  exportSuccess: boolean;
  exportFail: boolean;
  importedTenant: any;
  dataObjectName: DataObjectName;
  clearReport: () => void;
}

const TenantEntitiesFilter = ({
  onExportTenant,
  onImportTenant,
  isExporting,
  exportSuccess,
  exportFail,
  importedTenant,
  clearReport,
}: TenantEntitiesFilterProps): React.ReactElement => {
  const { search } = useAppSelector((state: RootState) => state.entitiesListing);

  const onGetData = (value?: string) => {
    return listEntities({
      type: EntityCreatorTypes.Tenant,
      searchBarSpinOnly: value !== undefined && value !== null,
      first: 1000,
    });
  };

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      <ImportDropdown
        onImport={onImportTenant}
        className="m-r-xs"
      />
      <ExportDropdown
        onExport={onExportTenant}
        isExporting={isExporting}
        exportSuccess={exportSuccess}
        exportFail={exportFail}
      />
      {importedTenant && (
        <ImportReport
          className="m-t-l"
          importReport={importedTenant}
          clearReport={clearReport}
          dataObjectName={DataObjectName.TENANT}
        />
      )}
    </>
  );
};

export default TenantEntitiesFilter;
