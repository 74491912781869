import { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import SelectField from 'atoms/FormField/Select';
import { DropdownProps, MODE_OPTIONS } from 'common/api/miscellaneous';
import { _FLOOR_PORTION_OPTIONS } from 'utils/UI';
import { conditionalClassName, deepClone } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import { Icons } from 'utils/utils-icons';
import { addSpaceOrComma } from 'utils/utils-number';
import { PremisesFormOnChangeFunction } from '..';
import {
  PremiseSelectedProps,
  onDeletePremiseFloor,
  onUpdatePremiseFloorId,
  onUpdatePremiseFloorPortion,
  onUpdatePremiseSelectedUnits,
} from '../utils-premiseForm';

interface FloorActionPayload {
  floorRowIndex: number;
  premiseIndex: number;
  value?: string;
}

export type FloorOnChangeFunction = (args: FloorActionPayload) => void;

interface FloorRowProps {
  mode: MODE_OPTIONS;
  firstRow: boolean;
  floorRowIndex: number;
  premises: PremiseSelectedProps[];
  measurement: string;
  haveDeleteButton: boolean;
  propertyId: string;
  premiseIndex: number;
  floorOptions: DropdownProps[];
  spaceArea: number;
  getSpaceOptions: () => DropdownProps[];
  onChange: PremisesFormOnChangeFunction;
}

const FloorRow = ({
  mode,
  floorRowIndex,
  measurement,
  haveDeleteButton,
  floorOptions,
  premiseIndex,
  spaceArea,
  premises,
  onChange,
  getSpaceOptions,
  firstRow,
}: FloorRowProps): JSX.Element => {
  const [spaceOptions, setSpaceOptions] = useState<DropdownProps[]>([]);

  const { id: floorId, floorPortion, spaceIds } = premises[premiseIndex].floorIds[floorRowIndex];

  const onUpdate =
    (updateHandler: Function, checkValueExists: boolean = true): FloorOnChangeFunction =>
    ({ floorRowIndex, premiseIndex, value }) => {
      if (checkValueExists && !value) return;

      const updatedPremises = deepClone(premises);
      const currentFloors = updatedPremises[premiseIndex].floorIds;

      updatedPremises[premiseIndex].floorIds = updateHandler(currentFloors, floorRowIndex, value);

      onChange(updatedPremises);
    };

  const onFloorPortionUpdate = (key: string, value: any): void =>
    onUpdate(onUpdatePremiseFloorPortion)({
      floorRowIndex,
      premiseIndex,
      value,
    });

  const onFloorUnitsUpdate = (key: string, value: any): void =>
    onUpdate(onUpdatePremiseSelectedUnits)({
      floorRowIndex,
      premiseIndex,
      value,
    });

  const onFloorIdUpdate = (key: string, value: any): void =>
    onUpdate(onUpdatePremiseFloorId)({
      floorRowIndex,
      premiseIndex,
      value,
    });

  const onDelete = (): void =>
    onUpdate(
      onDeletePremiseFloor,
      false,
    )({
      floorRowIndex,
      premiseIndex,
    });

  // TODO issue to solve here: do not use key
  const floorOptionsToShow = (options: DropdownProps[]): DropdownProps[] => {
    return options.filter((option: DropdownProps) => {
      return option.key === floorId || !option.selected;
    });
  };

  useEffect(() => {
    // UPDATE UNITS/SPACES OPTIONS AVAILABLE BASED ON THE FLOOR (i.e floorId) SELECTED
    if (floorId) {
      const spaceOptions: DropdownProps[] = getSpaceOptions();
      const options: DropdownProps[] = orderBy(spaceOptions, option => Number(option.text), 'asc');

      setSpaceOptions(options);
    }
  }, [floorId]);

  return (
    <Grid.Row className={`${firstRow && 'p-t-none'} m-b-xs`}>
      <Grid.Column
        width={12}
        className="btn-container p-none"
      >
        <Grid
          className="sub-section pm-none"
          data-test="floor-row"
        >
          <Grid.Row className="pm-none">
            <Grid.Column
              width={5}
              className="p-none"
            >
              <SelectField
                label={firstRow ? 'Floor' : ''}
                placeholder="Select floor"
                fieldKey="floorId"
                value={floorId}
                dataTest="select-floor"
                disabled={mode === MODE_OPTIONS.READ}
                options={floorOptionsToShow(floorOptions)}
                onChange={onFloorIdUpdate}
                search={true}
                runValidation={!checkNotEmpty(floorId)}
                required={mode === MODE_OPTIONS.EDIT}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <SelectField
                label={firstRow ? 'Whole or part' : ''}
                placeholder="Select whole or part floor"
                dataTest="select-whole-or-part-floor"
                fieldKey="floorPortion"
                value={floorPortion}
                options={_FLOOR_PORTION_OPTIONS}
                disabled={mode === MODE_OPTIONS.READ}
                onChange={onFloorPortionUpdate}
                runValidation={!checkNotEmpty(floorPortion)}
                required={mode === MODE_OPTIONS.EDIT}
              />
            </Grid.Column>
            <Grid.Column
              width={5}
              className="spaces-box"
            >
              <SelectField
                label={firstRow ? 'Units/spaces' : ''}
                placeholder="Select spaces / units"
                dataTest="select-units"
                fieldKey="spaceIds"
                value={spaceIds}
                options={spaceOptions}
                disabled={mode === MODE_OPTIONS.READ}
                onChange={onFloorUnitsUpdate}
                multiple={true}
                runValidation={spaceIds.length === 0}
                required={mode === MODE_OPTIONS.EDIT}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>

      <Grid.Column
        className={`area ${conditionalClassName(firstRow, 'first-row')}`}
        width={3}
      >
        <div className="bold">Area : </div>
        <div className="area-value">
          {` ${addSpaceOrComma(spaceArea, false)}`}
          {measurement}
        </div>
      </Grid.Column>

      {mode === MODE_OPTIONS.EDIT && haveDeleteButton && (
        <div
          className="delete-btn bg-gray-very-light-v4"
          data-test="delete-floor-button"
          onClick={onDelete}
          role="button"
          tabIndex={0}
        >
          <Icon
            className="color-red-soft"
            icon={Icons.Delete}
          />
        </div>
      )}
    </Grid.Row>
  );
};

export default FloorRow;
