import { Chart as ChartJS, ChartType, ScriptableTooltipContext, registerables } from 'chart.js';
import type { ChartData, ChartOptions } from 'chart.js';
import { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { Dropdown } from 'semantic-ui-react';
import { RiskAnalysisData } from 'common/api/dashboard';
import { DROPDOWN_OPTION } from 'utils/UI';
import { Colors } from 'utils/utils-colors';
import { externalTooltip } from './tooltip';

ChartJS.register(...registerables);

interface GraphProps {
  riskAnalysisData: RiskAnalysisData[];
}

interface ENTRY_GRAPH_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: number;
}

export const ENTRY_GRAPH_OPTIONS: ENTRY_GRAPH_OPTION[] = [
  { key: 1, text: '5 Entries', value: 5 },
  { key: 2, text: '10 Entries', value: 10 },
  { key: 3, text: '20 Entries', value: 20 },
];

const Graph = ({ riskAnalysisData }: GraphProps): JSX.Element => {
  const [entry, setEntry] = useState<number>(20);

  const size: number = Math.min(entry, riskAnalysisData.length);
  const labels: string[] = riskAnalysisData.slice(0, size).map((item: RiskAnalysisData) => item.reference);
  const revenues: string[] = riskAnalysisData.slice(0, size).map((item: RiskAnalysisData) => item.revenue);

  const cumulativeRevenuesPercentage: string[] = riskAnalysisData
    .slice(0, size)
    .map((item: RiskAnalysisData) => (parseFloat(item.accumulativeContribution) * 100).toFixed(1));

  const data: ChartData<any> = {
    labels,
    datasets: [
      {
        label: 'Cumalative Percentage',
        data: cumulativeRevenuesPercentage,
        borderColor: Colors.BLUE_VERY_DARK_GRAYISH,
        yAxisID: 'percentageAxis',
      },
      {
        label: 'Revenue in HK $',
        data: revenues,
        backgroundColor: Colors.MAGENTA_DESATURATED,
        borderColor: 'white',
        borderWidth: 1,
        type: 'bar',
      },
    ],
  };

  const options: ChartOptions<any> = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    categoryPercentage: 0.8,
    barPercentage: 0.2,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,

        external: (context: ScriptableTooltipContext<ChartType>) =>
          externalTooltip(context, 'pareto-graph', riskAnalysisData),
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: Colors.BLUE_DARK_GRAYISH,
          font: {
            family: 'Urbanist',
            size: '12',
            weight: '700',
            lineHeight: '17px',
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: Colors.BLUE_DARK_GRAYISH,
          font: {
            family: 'Urbanist',
            size: '12',
            weight: '700',
            lineHeight: '17px',
          },
          maxTicksLimit: 10,
          callback: (value: number) => value.toLocaleString(),
        },
      },
      percentageAxis: {
        type: 'linear',
        position: 'right',
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          callback: (value: number) => `${value}%`,
        },
      },
    },
  };

  return (
    <>
      <span className="pareto-entry-count">(top {entry})</span>

      <Dropdown
        value={entry}
        fluid={true}
        options={ENTRY_GRAPH_OPTIONS}
        selection={true}
        onChange={(e, { value }) => setEntry(value as number)}
      />
      <div className="pareto-graph-box">
        <p className="y-title hk">HK$</p>
        <Chart
          type="line"
          options={options}
          data={data}
        />
      </div>
      <div className="legends multi-graph">
        <div>
          <span className="circle-xs circle m-r-xs gray"></span>
          Cumalative contribution to total revenue (%)
        </div>
        <div>
          <span className="circle-xs circle m-r-xs magenta"></span>
          Individual contribution to revenue
        </div>
      </div>
    </>
  );
};

export default Graph;
