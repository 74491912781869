import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ImportPropertyQueryVariables {
  currentLandlordEntityId: string;
  simulation: boolean;
  uploadFile: any;
}

const IMPORT_PROPERTY = (currentLandlordEntityId: string, simulation: boolean, file: any) => {
  const variables: ImportPropertyQueryVariables = {
    currentLandlordEntityId,
    simulation,
    uploadFile: file,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $simulation: Boolean!, $uploadFile: Upload!) {
        importProperty(
          currentLandlordEntityId: $currentLandlordEntityId
          simulation: $simulation
          uploadFile: $uploadFile
        ) {
          dataType
          items {
            address
            area {
              gross
              grossDescription
              lettable
              lettableDescription
              net
              netDescription
              saleable
              saleableDescription
            }
            buildingGrade
            buildingIdentifier
            buildingName
            buildingNumberOfFloors
            buildingNumberOfUndergroundFloors
            description
            identification
            lotNumber
            measurementMethod
            measurementUnit
            photo
            type
          }
          itemsDuplicateBase {
            log
            type
          }
          itemsDuplicateExcel {
            log
            type
          }
          itemsModified {
            address
            area {
              gross
              grossDescription
              lettable
              lettableDescription
              net
              netDescription
              saleable
              saleableDescription
            }
            buildingGrade
            buildingIdentifier
            buildingName
            buildingNumberOfFloors
            buildingNumberOfUndergroundFloors
            description
            identification
            lotNumber
            measurementMethod
            measurementUnit
            photo
            type
          }
          logs {
            log
            type
          }
          simulation
        }
      }
    `,
    variables,
  };
};

interface ImportPropertyParams {
  currentLandlordEntityId: string;
  simulation: boolean;
  file: any;
}

export const importProperty = createAsyncThunk(
  'properties/importProperty',
  async ({ currentLandlordEntityId, simulation, file }: ImportPropertyParams) => {
    const response = await client.mutate(IMPORT_PROPERTY(currentLandlordEntityId, simulation, file));
    return response;
  },
);
