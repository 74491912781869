import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { PROPERTY_FLOORS_TAB_VIEW } from 'views/properties/CreatorViewer/Tabs/Floors';
import { PROPERTY_TABS_OFFSET } from 'views/properties/CreatorViewer/Tabs/PropertyTabs';
import {
  createFloor,
  deleteFloor,
  listPropertyFloors,
  prepFloorPlanForUpload,
  updateFloor,
  updateSpacesAndUnitsFloorPlans,
} from 'common/api/floor';
import { DocumentIntendedToBeUploaded } from 'common/api/miscellaneous';
import {
  FloorPlanProps,
  createProperty,
  getProperty,
  listLandlordEntityOptions,
  prepPropertyDocsForUpload,
  prepPropertyPhotoForUpload,
  updateProperty,
} from 'common/api/properties';
import { DROPDOWN_OPTION } from 'utils/UI';
import { getFrozenObjectCopy, getNewReviewer } from 'utils/tsHelper';
import { isValidNumber } from 'utils/utils-number';
import { generateUniqueId } from 'utils/utils-random';

let newProperty = () => ({
  id: null as string | null,
  entity: {
    id: localStorage.getItem('sericin_selected_entity'),
  },
  photo: null,
  buildingName: null,
  buildingIdentifier: null,
  buildingNumberOfFloors: null,
  buildingNumberOfUndergroundFloors: 0,
  area: {
    gross: null,
    grossDescription: null,
    net: null,
    netDescription: null,
    lettable: null,
    lettableDescription: null,
    saleable: null,
    saleableDescription: null,
  },
  type: [],
  lotNumber: null,
  buildingGrade: null,
  address: null,
  description: null,
  identification: null,
  measurementUnit: null,
  measurementMethod: null,
  floors: [],
  documents: [] as DocumentIntendedToBeUploaded[],
  reviewFlag: false,
  reviewStatus: {
    date: null,
    user: getNewReviewer(),
  },
  developmentName: null,
  developmentLotNumber: null,
  addressDistrict: null,
  buildingAddressDistrict: null,
  hasCentralAc: false,
  hasCurtainWall: false,
  hasMetroCoveredAccess: false,
});

let newFloor = () => ({
  id: '',
  name: null,
  index: null,
  comments: null,
  characteristics: null,
  ceilingHeight: null,
  loading: null,
  activationDate: null,
  deactivationDate: null,
  spaces: [] as any[],
  floorPlans: [] as any[],
});

const initialState = {
  activeProperty: newProperty(),
  activePropertyFrozenCopy: null as any,
  activeFloor: newFloor(),
  floorsList: [],
  landlordEntitiesOptions: [] as DROPDOWN_OPTION[],
  activeTabIndex: PROPERTY_TABS_OFFSET.INFO,
  spacesAndUnitsToDelete: [],
  floorPlansToDelete: [],
  modalStatus: false,
  pageInfo: [],
  cdnUrl: null,
  uploadUrl: null,
  file: null,
  deletePreviousPhoto: false,
  isLoading: false,
  isSearching: false,
  filterDate: null,
  heightExpand: false,
  floorsTabView: PROPERTY_FLOORS_TAB_VIEW.FLOORS_LISTING,
  floorPlans: [] as any[],
};

const propertyDetailSlice = createSlice({
  name: 'propertyDetail',
  initialState,
  reducers: {
    resetFloorlist: state => {
      state.floorsList = [];
    },
    updatePropertyReviewedStatus: (state, action) => {
      const { reviewStatus, reviewFlag } = action.payload;
      state.activeProperty.reviewStatus = reviewStatus as any;
      state.activeProperty.reviewFlag = reviewFlag;
    },
    deleteSupportingFiles: (state, action) => {
      const { id } = action.payload;
      state.activeProperty.documents = state.activeProperty.documents.filter(doc => doc.id !== id);
    },
    addSupportingFiles: (state, action) => {
      const { files } = action.payload;
      state.activeProperty.documents = [...files, ...state.activeProperty.documents];
    },

    deleteSelectedFloorPlan: (state, action) => {
      const { floorId } = action.payload;
      state.floorPlans = state.floorPlans.filter((plan: FloorPlanProps) => plan.floorId !== floorId);
    },
    deleteSelectedPhoto: state => {
      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
    },
    deletePhoto: state => {
      state.deletePreviousPhoto = true;
    },
    updateInputKey: (state, action) => {
      let { key, value } = action.payload;
      if (key === 'entity') {
        value = { id: value };
      }

      if (key === 'buildingNumberOfFloors' || key === 'buildingNumberOfUndergroundFloors') {
        if (value === '') {
          value = null;
        } else {
          value = parseInt(value);
        }
      }

      const propertyClone = { ...state.activeProperty };
      // @ts-ignore
      propertyClone[key] = value;
      state.activeProperty = propertyClone;
    },
    updateInputAreaKey: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeProperty.area[key] = value;
    },
    addSpace: (state, action) => {
      const { numberOfSpaces } = action.payload;
      const floor = state.activeFloor;

      const newSpaces = [...new Array(numberOfSpaces)].fill({
        id: generateUniqueId('local-floor'),
        name: null,
        registeredName: null,
        type: null,
        usedForOccupancyRate: null,
        grossArea: null,
        netArea: null,
        lettableArea: null,
        saleableArea: null,
        efficiencyRatio: 1,
        comments: null,
        characteristics: null,
        activationDate: null,
        deactivationDate: null,
        local: true,
      });

      const newSpaceAndUnits = [...floor.spaces, ...newSpaces];

      floor.spaces = newSpaceAndUnits;
      state.filterDate = null;
    },
    deleteSpace: (state, action) => {
      const { id } = action.payload;
      const floor = state.activeFloor;
      const spaceToBeDeleted = floor.spaces.find(space => space.id === id);
      const newSpaceAndUnits = floor.spaces.filter(space => space.id !== id);

      floor.spaces = newSpaceAndUnits;
      if (!spaceToBeDeleted.local) {
        // @ts-ignore
        state.spacesAndUnitsToDelete = [...state.spacesAndUnitsToDelete, spaceToBeDeleted.id];
      }
    },
    updateSpaceDetail: (state, action) => {
      const { key, value, spaceIndex } = action.payload;
      const floor = state.activeFloor;
      const space = floor.spaces[spaceIndex];
      const spaceClone = { ...space };
      if (key === 'efficiencyRatio') {
        if (value === '100.0') {
          return;
        }
        if (value >= 0 && value <= 100) {
          const firstDecimal = value.split('.')[1];
          if (firstDecimal === 0) {
            if (value === '0.0') {
              spaceClone[key] = '0.0';
            } else {
              spaceClone[key] = Number((value / 100).toFixed(4)) + '0';
            }
          } else {
            spaceClone[key] = Number((value / 100).toFixed(4));
          }
        }
      } else if (key === 'saleableArea') {
        spaceClone[key] = value === '' ? null : value;
      } else {
        spaceClone[key] = value;
        if (key === 'characteristics') {
          state.heightExpand = true;
        }
      }
      floor.spaces[spaceIndex] = spaceClone;
    },
    updateModalStatus: (state, action) => {
      const { status } = action.payload;
      state.modalStatus = status;
    },
    // Might be needed
    addFloor: (state, action) => {
      const { from, to } = action.payload;
      if (from === null || to === null) {
        toast.error('Invalid Range');
      } else {
        let flag = 0;
        for (let i = 0; i < state.floorsList.length; i++) {
          // @ts-ignore
          const name = state.floorsList[i].name;
          if (name >= from && name <= to) {
            flag = 1;
            break;
          }
        }
        if (from <= to && flag === 0) {
          for (let i = from; i <= to; i++) {
            const newFloor = {
              id: state.floorsList.length,
              name: i,
              index: null,
              comments: null,
              characteristics: [],
              ceilingHeight: null,
              loading: null,
              activationDate: new Date(),
              deactivationDate: null,
              spaces: [],
              floorPlans: [],
            };
            // @ts-ignore
            state.floorsList = [...state.floorsList, newFloor];
          }
          // @ts-ignore
          state.floorsList = state.floorsList.sort((a, b) => a.index - b.index);
        } else {
          toast.error('Invalid Range');
        }
      }
      state.modalStatus = false;
    },
    updateCurrentTab: (state, action) => {
      const { tab } = action.payload;
      state.activeTabIndex = tab;
    },
    createNewProperty: state => {
      state.activeProperty = newProperty();
      state.spacesAndUnitsToDelete = [];
      state.floorPlansToDelete = [];
      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
      state.deletePreviousPhoto = false;
    },

    updatePropertyFloorsTabView: (state, action) => {
      state.floorsTabView = action.payload.view;
    },
    setActiveFloor: (state, action) => {
      const { activeFloor, isNew } = action.payload;
      if (isNew) {
        state.activeFloor = newFloor();
      } else {
        const floor = activeFloor;
        state.activeFloor = { ...floor };
      }
    },
    updateActiveFloor: (state, action) => {
      let { key, value } = action.payload;
      // @ts-ignore
      state.activeFloor[key] = value;
    },
    addFloorPlan: state => {
      const floor = state.activeFloor;
      const newFloorPlans: any[] = [...floor.floorPlans];
      newFloorPlans.push({
        id: generateUniqueId(null),
        reviewFlag: null,
        floorPlan: null,
        revisionDate: null,
        unitsNumber: null,
        whollyOwned: false,
        grossArea: null,
        netArea: null,
        lettableArea: null,
        saleableArea: null,
        efficiencyRatio: 1,
        local: true,
      });
      floor.floorPlans = newFloorPlans;
      state.filterDate = null;
    },
    addFloorPlanFile: (state, action) => {
      const { floorPlanId, files } = action.payload;
      const index = state.activeFloor.floorPlans.findIndex(({ id }) => id === floorPlanId);
      state.activeFloor.floorPlans[index].floorPlan = files[0];
    },
    deleteFloorPlan: (state, action) => {
      const { id } = action.payload;
      const floor = state.activeFloor;
      const floorPlanToBeDeleted = floor.floorPlans.find(floorPlan => floorPlan.id === id);
      const newFloorPlans = floor.floorPlans.filter(floorPlan => floorPlan.id !== id);

      floor.floorPlans = newFloorPlans;
      if (!floorPlanToBeDeleted.local) {
        // @ts-ignore
        state.floorPlansToDelete = [...state.floorPlansToDelete, floorPlanToBeDeleted.id];
      }
    },
    deleteFloorPlanDocument: (state, action) => {
      const floorPlan = state.activeFloor.floorPlans.find(floorPlan => floorPlan.id === action.payload.id);
      floorPlan.floorPlan = null;
    },
    updateFloorPlan: (state, action) => {
      const { key, value, id } = action.payload;

      const index = state.activeFloor.floorPlans.findIndex(({ id: planId }) => planId === id);

      if (key === 'efficiencyRatio') {
        let parsedValue = parseFloat(value);
        if (value === '' || (isValidNumber(parsedValue) && parsedValue === 0)) {
          state.activeFloor.floorPlans[index][key] = 0;
        } else if (parsedValue > 100 || parsedValue <= 0 || isNaN(parsedValue)) {
          return;
        } else {
          parsedValue = parsedValue / 100;
          state.activeFloor.floorPlans[index][key] = parsedValue.toFixed(4);
        }
        return;
      }
      state.activeFloor.floorPlans[index][key] = value;
    },
    updateFilterDate: (state, action) => {
      const { value } = action.payload;
      state.filterDate = value;
    },
    updateHeightExpand: (state, action) => {
      const { status } = action.payload;
      state.heightExpand = status;
    },
  },
  extraReducers: builder => {
    //createProperty
    builder.addCase(createProperty.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createProperty.fulfilled, (state, action) => {
      const { createProperty } = action.payload.data;
      state.activeProperty = createProperty;
      state.isLoading = false;
      window.scrollTo(0, 0);
      toast.success('Property Created Successfully');
    });
    builder.addCase(createProperty.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('createProperty API request rejected');
    });

    //getProperty
    builder.addCase(getProperty.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getProperty.fulfilled, (state, action) => {
      const property = action.payload.data.getProperty;
      state.activeProperty = property;
      state.activePropertyFrozenCopy = getFrozenObjectCopy(property);
      state.isLoading = false;
      state.spacesAndUnitsToDelete = [];
      state.floorPlansToDelete = [];
      state.deletePreviousPhoto = false;
      state.floorPlans = [];
      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
      state.deletePreviousPhoto = false;
    });
    builder.addCase(getProperty.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getProperty API request rejected');
    });

    //updateProperty
    builder.addCase(updateProperty.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateProperty.fulfilled, (state, action) => {
      const updatedProperty = action.payload.data.updateProperty;
      state.activeProperty = updatedProperty;
      state.activePropertyFrozenCopy = getFrozenObjectCopy(updatedProperty);
      state.isLoading = false;
      state.activeTabIndex = PROPERTY_TABS_OFFSET.INFO;
      window.scrollTo(0, 0);

      state.cdnUrl = null;
      state.uploadUrl = null;
      state.file = null;
      state.deletePreviousPhoto = false;

      toast.success('Property Updated Successfully');
    });
    builder.addCase(updateProperty.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateProperty API request rejected');
    });

    //listLandlordEntityOptions
    builder.addCase(listLandlordEntityOptions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listLandlordEntityOptions.fulfilled, (state, action) => {
      const landlordEntities = action.payload.data.listLandlordEntities.edges.map((element: any) => element.node);
      state.landlordEntitiesOptions = landlordEntities.map((entity: any, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: entity.name,
          value: entity.id,
        };
        return result;
      });
      state.isLoading = false;
    });
    builder.addCase(listLandlordEntityOptions.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('listLandlordEntityOptions API request rejected');
    });

    //listPropertyFloors
    builder.addCase(listPropertyFloors.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listPropertyFloors.fulfilled, (state, action) => {
      const { edges, pageInfo } = action.payload.data.listPropertyFloors;
      state.floorsList = edges.map((element: any) => element.node);
      state.pageInfo = pageInfo;
      state.filterDate = null;
      state.isLoading = false;
    });
    builder.addCase(listPropertyFloors.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('listPropertyFloors API request rejected');
    });

    //createFloor
    builder.addCase(createFloor.pending, state => {
      state.spacesAndUnitsToDelete = [];
      state.floorPlansToDelete = [];
      state.isLoading = true;
    });
    builder.addCase(createFloor.fulfilled, (state, action) => {
      // @ts-ignore
      state.activeFloor.id = action.payload.data.createFloor.id;
      state.isLoading = false;
      toast.success('Floor Created Successfully');
    });
    builder.addCase(createFloor.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createFloor API request rejected');
    });

    //updateFloor
    builder.addCase(updateFloor.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateFloor.fulfilled, state => {
      state.isLoading = false;
      toast.success('Floor Updated Successfully');
    });
    builder.addCase(updateFloor.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateFloor API request rejected');
    });
    //deleteFloor
    builder.addCase(deleteFloor.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteFloor.fulfilled, (state, action) => {
      // @ts-ignore
      state.floorsList = state.floorsList.filter(obj => obj.id !== action.meta.arg.id);
      state.isLoading = false;
    });
    builder.addCase(deleteFloor.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('Floor cannot be deleted');
    });

    //updateSpacesAndUnitsFloorPlans
    builder.addCase(updateSpacesAndUnitsFloorPlans.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateSpacesAndUnitsFloorPlans.fulfilled, state => {
      state.spacesAndUnitsToDelete = [];
      state.isLoading = false;
    });
    builder.addCase(updateSpacesAndUnitsFloorPlans.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateSpacesAndUnitsFloorPlans API request rejected');
    });

    //prepPropertyPhotoForUpload
    builder.addCase(prepPropertyPhotoForUpload.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(prepPropertyPhotoForUpload.fulfilled, (state, action) => {
      // @ts-ignore
      const { file } = action.meta.arg;
      const { cdnUrl, uploadUrl } = action.payload.data.prepPropertyPhoto;

      state.cdnUrl = cdnUrl;
      state.uploadUrl = uploadUrl;
      state.file = file;

      state.isLoading = false;
    });
    builder.addCase(prepPropertyPhotoForUpload.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('prepPropertyPhotoForUpload API request rejected');
    });

    //prepFloorPlanForUpload
    builder.addCase(prepFloorPlanForUpload.fulfilled, (state, action) => {
      // @ts-ignore
      const { floorPlanId } = action.meta.arg;
      const { cdnUrl, path, uploadUrl } = action.payload.data.prepFloorPlan;

      const {
        activeFloor: { floorPlans },
      } = state;

      const index = floorPlans.findIndex(({ id }) => id === floorPlanId);

      const plan = floorPlans[index].floorPlan;
      floorPlans[index].floorPlan = {
        ...plan,
        cdnUrl,
        path,
        uploadUrl,
      };
    });
    builder.addCase(prepFloorPlanForUpload.rejected, (state, action) => {
      console.error(action.error);
      toast.error('prepFloorPlanForUpload API request rejected');
    });

    //prepPropertyDocsForUpload
    builder.addCase(prepPropertyDocsForUpload.fulfilled, (state, action) => {
      const documentsCopy = [...state.activeProperty.documents];
      Object.keys(action.payload.data).map(key => {
        const id = key.replace('prepPropertyDoc', '');
        const index = documentsCopy.findIndex(doc => doc.id === id);
        documentsCopy.splice(index, 1, {
          ...documentsCopy[index],
          ...action.payload.data[key],
        });
      });

      state.activeProperty.documents = documentsCopy;
    });

    builder.addCase(prepPropertyDocsForUpload.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('prepPropertyDocsForUpload API request rejected');
    });
  },
});

export const {
  addFloor,
  addFloorPlan,
  addFloorPlanFile,
  addSpace,
  addSupportingFiles,
  createNewProperty,
  deleteFloorPlan,
  deleteFloorPlanDocument,
  deletePhoto,
  deleteSelectedFloorPlan,
  deleteSelectedPhoto,
  deleteSpace,
  deleteSupportingFiles,
  resetFloorlist,
  setActiveFloor,
  updateActiveFloor,
  updateCurrentTab,
  updateFilterDate,
  updateFloorPlan,
  updateHeightExpand,
  updateInputAreaKey,
  updateInputKey,
  updateModalStatus,
  updatePropertyFloorsTabView,
  updatePropertyReviewedStatus,
  updateSpaceDetail,
} = propertyDetailSlice.actions;

export default propertyDetailSlice.reducer;
