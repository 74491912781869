import { toast } from 'react-toastify';
import { store } from 'store';
import { Editor } from 'tinymce';
import ContextIcons from 'components/ContextIcons';
import {
  ContextClauseToolbar,
  ContextMenuToolbar,
  ContextNodeToolbar,
  DiscussionToolbar,
} from 'components/ContextToolbar';
import { ContextClauseRefToolbar } from 'components/ContextToolbar/ContextNodeToolbar';
import ContextProvisionToolbar from 'components/ContextToolbar/ContextProvisionToolbar';
import { DataTypeSelect, checkIndexNumberingClause } from 'components/Editor/Clause/BackspaceClause';
import { cleanerFormattingHTML } from 'components/Editor/Clause/RemovingHTML';
import { nodeToString } from 'store/editor/editorSlice';
import { setActiveProvisionId } from 'store/miscellaneous/miscellaneousSlice';
import { updateShowContext } from 'store/nodes/nodesSlice';
import { ContentNodeType } from 'common/api/nodes';
import { KeyboardKeys } from 'utils/utils-clean-input';
import { parseContent } from 'utils/utils-string';

export enum EditorEvents {
  Keyboard = 'keyboard',
  Keyup = 'keyup',
  Keydown = 'keydown',
  Click = 'click',
  Init = 'init',
  Paste = 'paste',
  BeforeInput = 'beforeinput',
  BeforeExecCommand = 'BeforeExecCommand',
  ExecCommand = 'ExecCommand',
  SelectionChange = 'SelectionChange',
}

export enum EditorCommands {
  RemoveFormat = 'RemoveFormat',
  MceToggleFormat = 'mceToggleFormat',
  MceFocus = 'mceFocus',
  SelectAll = 'SelectAll',
}

/** Function to be run when `init` event is emitted from TinyMCE
 * https://www.tiny.cloud/docs/advanced/events/
 * @param {Editor} editor
 */
export const setup = (editor: Editor, callbackInit: () => void, readOnly: boolean) => {
  const pathname = window.location.pathname;
  const policiesPage = pathname.includes('policies');
  const transactionsPage = pathname.includes('transactions');
  if (policiesPage || transactionsPage) {
    ContextProvisionToolbar(editor);
    if (transactionsPage) {
      DiscussionToolbar(editor);
    }
  } else {
    ContextClauseToolbar(editor);
    ContextNodeToolbar(editor);
    ContextClauseRefToolbar(editor);
  }

  // Add context Menu
  ContextMenuToolbar(editor);
  // Add custom icons for context menu
  ContextIcons(editor);

  editor.on(EditorEvents.BeforeExecCommand, e => {
    if (e.command === EditorCommands.RemoveFormat) {
      // Make parameter & text nodes non-editable
      const parameterTags = editor.dom.select("[data-node-type='parameter']");
      // Make each and every parameter node non-editable
      Array.from(parameterTags).map(element => {
        element.setAttribute('contenteditable', 'false');
      });

      const textTags = editor.dom.select("[data-node-type='text']");
      // Make each and every text node non-editable
      Array.from(textTags).map(element => {
        element.setAttribute('contenteditable', 'false');
      });
    } else if (e.command === EditorCommands.MceToggleFormat) {
      /*** TinyMCE unformatter Bug Fix - part 1 ***
       * On selecting all the content and then clicking on the unformat button
       * Tinymce is unable to unformat the content in some cases: like when first
       * element in the editor is non editable element (i.e. Clause Index cannot
       * be modified by user)
       *
       * Solution:
       *   Grab first element in the editor, verify it is a clause number and then make
       *   it editable so that the unformat can be applied
       *
       * Note:
       *   ?? - Nullish coalescing operator
       *   logical operator that returns its right-hand side operand when its left-hand side operand is null or undefined.
       */
      const firstEditorElement: HTMLElement | null = (editor.dom.select('body')[0]?.firstChild as HTMLElement) ?? null;
      if (firstEditorElement) {
        const nodeType: string | null = firstEditorElement.getAttribute('data-node-type');
        // Check if first element is clause
        if (nodeType === ContentNodeType.CLAUSE) {
          // Get clause index
          const clauseIndex: HTMLElement = firstEditorElement.firstChild as HTMLElement;
          if (clauseIndex) {
            // Make the Clause Index editable
            clauseIndex.setAttribute('contenteditable', 'true');
          }
        }
      }
    }
  });

  /*
   * This listener is used for debugging editor commands
   *
   * There is an issue inside tinymce: While selecting the whole content and then
   * clicking 2 different formaters (bold, italic, font-size....) one after the other, a
   * new empty paragraph at the top of the content is added.
   *
   * Below is a patch for removing that extra paragraph
   */
  editor.on(EditorEvents.ExecCommand, e => {
    if (![EditorCommands.MceFocus, EditorCommands.SelectAll].includes(e.command as EditorCommands)) {
      const firstElement = editor.dom.select('.mce-content-body')[0].childNodes[0];
      const lastChild = firstElement?.lastChild as Element;
      if (lastChild && 'hasAttribute' in lastChild) {
        // Check if empty paragraph with cursor
        const checkIfBogus = lastChild.hasAttribute('data-mce-bogus');
        // Remove redundant paragraph added on font size update
        if (checkIfBogus) {
          firstElement.remove();
        }
      }
    }

    if (e.command === EditorCommands.RemoveFormat) {
      // Make parameter & text nodes editable again
      const parameterTags = editor.dom.select("[data-node-type='parameter']");
      // Make the parameter node editable again
      Array.from(parameterTags).map(element => {
        element.removeAttribute('contenteditable');
      });

      const textTags = editor.dom.select("[data-node-type='text']");
      // Make the text node editable again
      Array.from(textTags).map(element => {
        element.removeAttribute('contenteditable');
      });
    }
  });

  editor.on(EditorEvents.SelectionChange, () => {
    const selection = editor.selection.getContent();
    const node = editor.selection.getNode();
    const nodeStr = nodeToString(node);

    /*
     * This is an anti-pattern (calling dispatch from outside a React Component).
     * Unfortunately, this is the best we can do, given the circumstances.
     */

    store.dispatch({
      type: 'editor/receiveSelectedNode',
      payload: nodeStr,
    });

    /*
     * NOTE: when the editor starts, its entire contents are selected, so
     * the received node will be <body>
     */

    store.dispatch({
      type: 'editor/receiveSelectedContent',
      payload: selection,
    });
  });

  editor.on(EditorEvents.Init, () => {
    // Prevent Enter events inside of a parameter tag
    preventDuplicatingParameterTags(editor);

    // DEB-215 - Add a second event to TXButton. Its remove extra tags
    const txButton = document.querySelector('button[aria-label="Clear formatting"]');
    // Add an extra action for the Formatting Text Button
    txButton?.addEventListener('click', () => {
      // Remove undesired elements tags
      setTimeout(() => {
        cleanerFormattingHTML(editor);
      }, 500);
    });

    callbackInit();
    store.dispatch({
      type: 'editor/receiveContent',
      payload: '',
    });
    if (store.getState().hiddenMenu.activeTab !== 3) {
      store.dispatch({
        type: 'hiddenMenu/updateSidebarTab',
        payload: 0,
      });
    }
  });

  /* Event to be called on clicking in the editor
    Get all the elements inside the editor 
    then get the closest provision node 
    Used for updating the table of content in the editor */
  editor.on(EditorEvents.Click, e => {
    /*** TinyMCE unformatter Bug Fix - part 2 ***
     *
     * Solution:
     *   Grab first element in the editor, verify it is a clause number and then make
     *   it non-editable again, after the click
     */
    const firstEditorElement: HTMLElement | null = (editor.dom.select('body')[0]?.firstChild as HTMLElement) ?? null;
    if (firstEditorElement) {
      const nodeType: string | null = firstEditorElement.getAttribute('data-node-type');
      // Check if first element is clause
      if (nodeType === ContentNodeType.CLAUSE) {
        // Get the clause index
        const clauseIndex: HTMLElement = firstEditorElement.firstChild as HTMLElement;
        // When clauseIndex is editable
        if (clauseIndex && clauseIndex.getAttribute('contenteditable') === 'true') {
          // Remove all formats and reset the content
          // Check if condition node is present
          if (clauseIndex.innerHTML.includes('data-condition')) {
            clauseIndex.innerHTML = '1.<div data-condition="true">C</div>';
          } else {
            clauseIndex.innerHTML = '1.';
          }
          // Set clauseIndex back as non editable
          clauseIndex.setAttribute('contenteditable', 'false');
        }
      }
    }

    // Get all elements
    const allElements = editor.dom.select('.mce-content-body')[0].childNodes;
    let clickedNode = e.target;
    let provisionId = null;

    let nearestTextOrParam = clickedNode;
    let nearestType = nearestTextOrParam.getAttribute('data-node-type');

    // Get to parameter or text node from nested node
    while (
      nearestTextOrParam !== null &&
      ![ContentNodeType.PARAMETER, ContentNodeType.TEXT, ContentNodeType.CLAUSE_REFERENCE].includes(
        nearestType as ContentNodeType,
      )
    ) {
      nearestTextOrParam = nearestTextOrParam.parentElement as HTMLElement;
      if (nearestTextOrParam !== null) {
        nearestType = nearestTextOrParam.getAttribute('data-node-type');
      }
    }

    // Show the context menu for text and parameter
    if ([ContentNodeType.TEXT, ContentNodeType.PARAMETER, ContentNodeType.CLAUSE_REFERENCE].includes(nearestType)) {
      store.dispatch(updateShowContext(true));
    }

    // Get parent element
    while (clickedNode.parentNode !== null) {
      const parentNode = clickedNode.parentNode.nodeName;
      if (parentNode === 'BODY') {
        break;
      } else {
        clickedNode = clickedNode.parentNode;
      }
    }

    // Get closest provision node
    for (let i = 0; i < allElements.length; i++) {
      const currentElem = allElements[i] as any;
      if (currentElem.dataset.nodeType === ContentNodeType.PROVISION) {
        provisionId = currentElem.dataset.nodeId;
      } else if (currentElem === clickedNode) {
        break;
      }
    }
    store.dispatch(setActiveProvisionId({ provisionId }));
    document.getElementById(`table-of-content-${provisionId}`)?.scrollIntoView();
  });

  editor.on(EditorEvents.Keydown, ({ key, preventDefault }) => {
    // Do not delete the clause on Backspace & Delete
    if ([KeyboardKeys.Backspace, KeyboardKeys.Delete].includes(key as KeyboardKeys)) {
      const selected = editor.selection.getSel();
      const offset = selected?.anchorOffset;
      const node = editor.selection.getNode();

      if (selected !== null) {
        const prevSibling = node.previousSibling;
        const prevSiblingTag = prevSibling?.nodeName;

        let checkIndex = false;
        if (prevSibling !== null) {
          if ('getAttribute' in prevSibling) {
            if ((prevSibling as HTMLElement).getAttribute('data-node-type') === ContentNodeType.CLAUSE_INDEX) {
              checkIndex = true;
            }
          }
        }

        // Get to last child of previous sibling
        let lastChild = prevSibling?.lastChild;
        let lastChildTag = lastChild?.nodeName;
        while (lastChild && lastChild !== null && lastChildTag !== 'TABLE') {
          lastChild = lastChild?.lastChild;
          lastChildTag = lastChild?.nodeName;
        }

        // Remove paragraphs with only br content if previous element is Table
        const brTags = ['<br>', '<br data-mce-bogus="1">'];
        if (brTags.includes(node.innerHTML) && node.nodeName === 'P') {
          if (!checkIndex) {
            if (prevSiblingTag === 'TABLE' || lastChildTag === 'TABLE') {
              editor.dom.remove(node);
            }
          }
        }

        // Remove redundant <br> tags after table
        const tables = editor.dom.select('table');
        for (let i = 0; i < tables.length; i++) {
          const nextElem = tables[i].nextSibling as HTMLElement;
          if (nextElem !== null) {
            if (brTags.includes(nextElem.outerHTML)) {
              editor.dom.remove(nextElem);
            }
          }
        }
      }

      const prevNode = node.previousSibling as Element;
      if (prevNode !== null) {
        if ('getAttribute' in prevNode) {
          const nodeType = prevNode.getAttribute('data-node-type');
          if (nodeType === ContentNodeType.CLAUSE_INDEX && offset === 0) {
            preventDefault();
          }
        }
      }
    }
    // Disable enter press inside text and parameter node
    if (key === KeyboardKeys.Enter) {
      let node = editor.selection.getNode() as Element;
      let nodeType = node.getAttribute('data-node-type') as ContentNodeType;

      const nodeTypes = [ContentNodeType.TEXT, ContentNodeType.PARAMETER];

      if (nodeType === null) {
        // check if parent node is text or parameter
        // get nearest text or parameter node for nested content
        while (!nodeTypes.includes(node.getAttribute('data-node-type') as ContentNodeType)) {
          node = node.parentNode as Element;

          // check if first level node
          if (node.nodeName === '#document') {
            break;
          } else {
            nodeType = node.getAttribute('data-node-type') as ContentNodeType;
          }
        }
      }

      if (nodeTypes.includes(nodeType)) {
        preventDefault();
      }
    }
  });

  // Custom paste for text nodes upto level 3
  editor.on(EditorEvents.Paste, e => {
    const copiedClipboardData: string | undefined = e.clipboardData?.getData('text/html');
    if (copiedClipboardData && copiedClipboardData.includes('data-node-level="3"')) {
      editor.insertContent(copiedClipboardData);
      e.preventDefault();
    }

    if (copiedClipboardData) {
      // Get the parsed data to be queried
      const parsedCopiedData: Document = parseContent(copiedClipboardData);

      // Don't let the user paste a clause without numbering
      if (checkIndexNumberingClause(parsedCopiedData, DataTypeSelect.Document)) {
        toast.error('Cannot paste clause without numbering. Please copy properly again.');
        e.preventDefault();
      }
    }
  });

  // For preventing the user from typing when in read only mode
  editor.on(EditorEvents.BeforeInput, e => {
    if (readOnly) {
      e.preventDefault();
    }
  });
};

export const editorPlugins = [
  'advlist',
  'anchor',
  'autolink',
  'charmap',
  'code',
  'fullscreen',
  'help',
  'image',
  'insertdatetime',
  'link',
  'lists',
  'media',
  'nonbreaking',
  // 'noneditable',
  'preview',
  // 'removeformat',
  'searchreplace',
  'table',
  'visualblocks',
  'wordcount',
];

/**
 * DEB-147
 * Avoid creating parameter duplicate tags when pressing enter...
 *
 * This method does not check where is pressed the Enter Button, but it
 * checks if was created another tag with the same content.
 *
 * If was created another tag with the same id, it prevents and reverts
 */
const preventDuplicatingParameterTags = (editor: Editor) => {
  editor.on(EditorEvents.Keyup, event => {
    // Check the event key
    if (event.key === 'Enter') {
      // Get the current Selection object
      const getSelection = editor.selection.getSel() as any;
      const { anchorNode } = getSelection;

      // Get the ID from element
      const nodeId = getNodeId(anchorNode);

      // If node id was found
      if (nodeId) {
        // Get all elements with the ID
        const allIds = editor.dom.select(`[data-node-id="${nodeId}"]`);

        // For each ID just one parameter should exist
        if (allIds.length !== 1) {
          console.warn(`[EDITOR] Double id on editor. ID: ${nodeId}`);
          // Undo the edit
          editor.undoManager.hasUndo() && editor.undoManager.undo();
          event.preventDefault();
        }
      }
    }
  });
};

/**
 * Get the ID function
 */
const getNodeId = (element: Element) => {
  let nodeId: string | null = '';

  // Check if the element exists and if the method exists
  if (element?.getAttribute) {
    // Get the current Node ID
    nodeId = element?.getAttribute('data-node-id');
  }

  // If not found, going one level up and try to get the ID
  if (!nodeId) {
    nodeId = (element.parentNode as Element)?.getAttribute('data-node-id');
  }

  // Return the ID
  return nodeId;
};
