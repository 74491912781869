import FloorStatus from 'common/model/FloorStatus';
import { ContractTimelineEvent } from 'common/api/contracts';

export interface Space {
  id: string;
  name: string;
  grossArea: string;
  activationDate: string;
  deactivationDate: string | null;
}

export interface Floor {
  index: string;
  name: string;
  area: number;
  id: string;
  floorNumber: number;
  floorArea: number;
  spaces: Space[];
}

export interface Blocks {
  area: string | number;
  availableStatus: string;
  contractId: string;
  floorId: string;
  tenantName: string;
  tags: { availableDate: string };
  futureContracts: null | { contractId: string }[];
  headlineRent: string;
  effectiveRent: string;
  unitIds: string[];
}

export interface GraphInputDataMeta {
  color: string;
  id: string;
  floorId: string;
  contractId: string | null;
  availabilityStatus: string;
  tenantName: string | null;
  futureContractIds: null | string[];
  floorName: string | null;
  headlineRent?: string;
  effectiveRent?: string;
  availableDate?: string;
}

export interface GraphInputData {
  x: number;
  y: string | number;
  meta?: GraphInputDataMeta;
}

export interface GraphInput extends GraphInputDataMeta {
  data: GraphInputData[];
}

interface PersonDetail {
  id: string;
  name: string;
}

interface Property {
  area: {
    gross: string;
  };

  floors: Floor[];
}

export interface Premises {
  property: Property;
  floorsStatus: FloorStatus[];
  spaceIds: string[];
}

export interface RentDescription {
  endDate: string;
  monthlyBaseRent: string;
  monthlyEffectiveRent: string;
  rentReview: string | null;
  startDate: string;
}

export interface Contract {
  id: string;
  startDate: string;
  endDate: string;
  tenant: PersonDetail;
  premises: Premises[];
  rentDescriptions: RentDescription[];
}

export interface EventDropdownOption {
  label: string | number;
  value: string;
  className?: string;
}

export interface TimeLineEventsUI extends ContractTimelineEvent {
  color?: string;
  background?: string;
  top?: number;
}

interface TimelineEventFloor {
  floorName: string;
  floorIndex: string;
}
export interface PropertyTimelineEvent extends ContractTimelineEvent {
  tenantName: string;
  floors: TimelineEventFloor[];
}
export interface PropertyTimeline {
  propertyId: string;
  events: PropertyTimelineEvent[];
}

export interface PropertyFilter {
  range: Date[];
  floors: string[];
  events: string[];
}

export interface TransactionProps {
  startDate: string;
  status: string;
  transactionId: string;
  tenantName: string;
  unitIds: string[];
}

export enum STACKING_PLAN_VIEW_OPTIONS {
  Table = 'Table',
  StackingPlan = 'Stacking Plan',
}
