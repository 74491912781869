import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TabContainer from 'components/TabContainer';
import AnalysisContext from 'common/model/AnalysisContext';
import {
  generatePortfolioRevenueAnalysisBase,
  generatePropertyRevenueAnalysisBase,
} from 'common/api/dashboard/forecasting';
import { DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS } from 'common/api/miscellaneous';
import ForecastingGraphs from './Graphs';
import PeriodSelect from './PeriodSelect';
import ForecastingTable from './Table';
import UploadAndDownload from './UploadAndDownload';
import ViewSelect from './ViewSelect';
import './Forecasting.scss';

const ForecastingTab = () => {
  const dispatch = useAppDispatch();
  const { context, activePortfolio } = useAppSelector((state: RootState) => state.dashboard);
  const { viewType } = useAppSelector((state: RootState) => state.forecasts);
  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);

  useEffect(() => {
    if (context === AnalysisContext.Portfolio) {
      dispatch(generatePortfolioRevenueAnalysisBase());
    } else if (context === AnalysisContext.Property) {
      dispatch(generatePropertyRevenueAnalysisBase());
    }
  }, [dispatch, activeProperty, activePortfolio]);

  return (
    <TabContainer className="m-t-m">
      <div className="forecasting-tab-top-section">
        <PeriodSelect />
        <ViewSelect />
        {context === AnalysisContext.Property && <UploadAndDownload />}
      </div>

      <div className="forecasting-tab-view-section">
        {viewType === DASHBOARD_PAGE_FORECASTING_TAB_VIEW_OPTIONS.TABLE ? <ForecastingTable /> : <ForecastingGraphs />}
      </div>
    </TabContainer>
  );
};

export default ForecastingTab;
