import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface PrepUserPhotoForUploadQueryVariables {
  fileName: string | null;
  mimeType: string | null;
}

export const PREP_USER_PHOTO_FOR_UPLOAD = (fileName: string | null, mimeType: string | null) => {
  const variables: PrepUserPhotoForUploadQueryVariables = {
    fileName,
    mimeType,
  };

  return {
    query: gql`
      query ($fileName: String!, $mimeType: String!) {
        prepUserPhoto(fileName: $fileName, mimeType: $mimeType) {
          cdnUrl
          uploadUrl
        }
      }
    `,
    variables,
  };
};

export const prepUserPhotoForUpload = createAsyncThunk(
  'auth/prepUserPhotoForUpload',
  async ({ file }: { file: any }) => {
    const response = await client.query(PREP_USER_PHOTO_FOR_UPLOAD(file.name, file.type));
    return response;
  },
);
