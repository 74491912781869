import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownProps } from 'semantic-ui-react';
import { useAppDispatch, useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import Development from 'common/model/Development';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { listLandlordEntityOptions } from 'common/api/properties';
import { listDevelopments } from 'common/api/propertiesV2';
import { PropertiesRow, developmentColumnConfig } from './DevelopmentsTableConfig';

const transFormDevelopment = (development: Development, ownersOptions: DropdownProps[]) => {
  const owners = ownersOptions
    .filter(owner => development?.entityId?.includes(owner.value as string))
    .map(ownersOptions => ({
      id: ownersOptions.value as string,
      name: ownersOptions.text ?? '',
    }));
  return { ...development, owners };
};

const DevelopmentTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { developmentList, isDevelopmentsLoading } = useAppSelector(state => state.propertiesV2);

  const { ownersOptions } = useAppSelector(state => state.propertiesV2);

  const currentList: PropertiesRow[] = useMemo(() => {
    if (!ownersOptions) {
      return [];
    }
    return developmentList.map(development => new PropertiesRow(transFormDevelopment(development, ownersOptions)));
  }, [developmentList, ownersOptions]);

  const onViewDetails = (id: string): void => {
    navigate(`/properties-new/${id}/details`);
  };

  const onListProperties = (params: PaginationAPIProps) => {
    // @ts-ignore
    dispatch(listDevelopments({ ...params }));
  };

  useEffect(() => {
    if (!ownersOptions || !ownersOptions.length) {
      dispatch(listLandlordEntityOptions());
    }
  }, [ownersOptions]);

  return (
    <div
      className="property-listing"
      data-test="properties-data-table"
    >
      <TableReactPrime
        fetchContent={({ ...rest }: PaginationAPIProps) => {
          onListProperties(rest);
        }}
        isFetching={isDevelopmentsLoading}
        content={currentList}
        columnConfig={developmentColumnConfig(onViewDetails)}
        onRowClick={onViewDetails}
      />
    </div>
  );
};

export default DevelopmentTable;
