import { GuidelinesBase } from 'common/_classes';
import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import EditorLoading from 'atoms/EditorBox/EditorLoading';
import ModalTemplate from 'components/ModalTemplate';
import GuidelineEditor, {
  GuidelineType,
  OnUpdateGuidelineTypeAction,
  getTitleFromGuidelineType,
} from './GuidelineEditor';

export interface EditorModalProps {
  guidelines: GuidelinesBase;
  status: boolean;
  setModalStatus: (value: boolean) => void;
  guidelineType: GuidelineType;
  onUpdateGuidelineType: OnUpdateGuidelineTypeAction;
}

const GuidelineEditorModal = ({
  guidelines,
  status,
  setModalStatus,
  guidelineType,
  onUpdateGuidelineType,
}: EditorModalProps): JSX.Element => {
  const [editorLoaded, setEditorLoaded] = useState<boolean>(false);

  const onModalUpdate = (isOpen: boolean): void => {
    setModalStatus(isOpen);
    if (!isOpen) {
      setEditorLoaded(false);
    }
  };

  return (
    <ModalTemplate
      isOpen={status}
      onToggleModalStatus={onModalUpdate}
      className="guidelines-modal"
      title={getTitleFromGuidelineType(guidelineType)}
    >
      <>
        <GuidelineEditor
          guidelines={guidelines}
          guidelineType={guidelineType}
          setEditorLoaded={setEditorLoaded}
          onUpdateGuidelineType={onUpdateGuidelineType}
        />

        {!editorLoaded && <EditorLoading />}

        {editorLoaded && (
          <Button
            className="cancel-btn m-t-ml"
            onClick={() => onModalUpdate(false)}
            data-test="close-button"
            fluid
          >
            CLOSE
          </Button>
        )}
      </>
    </ModalTemplate>
  );
};

export default GuidelineEditorModal;
