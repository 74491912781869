import { useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updatedInvitedUser } from 'store/members/membersSlice';
import { clearRoleOptions } from 'store/roles/rolesSlice';
import { listRolesOptions } from 'common/api/roles';
import { invalidEmailText } from 'utils/tsHelper';
import { emailIsValid } from 'utils/utils-string';

const InviteMemberForm = () => {
  const dispatch = useAppDispatch();
  const { roleOptions, optionsLoading } = useAppSelector((state: RootState) => state.roles);
  const {
    activeInvitedUser: { email, roleId },
  } = useAppSelector((state: RootState) => state.members);

  const onChange = (key: string, value: string) => dispatch(updatedInvitedUser({ key, value }));

  const checkIfValidEmail = emailIsValid(email);

  useEffect(() => {
    dispatch(listRolesOptions());

    return () => {
      dispatch(clearRoleOptions());
    };
  }, [dispatch]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          width="16"
          className="pm-none"
        >
          <InputField
            label="Email"
            type={InputFieldType.EMAIL}
            fieldKey="email"
            placeholder="Enter user email..."
            value={email}
            onChange={onChange}
            required={true}
            isInValid={!checkIfValidEmail}
            errorMessage={(!checkIfValidEmail && invalidEmailText) || ''}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column
          width="16"
          className="pm-none m-t-s"
        >
          <SelectField
            label="Role"
            fieldKey="roleId"
            placeholder="Select user role..."
            options={roleOptions}
            value={roleId}
            onChange={onChange}
            info={true}
            required={true}
            loading={optionsLoading}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default InviteMemberForm;
