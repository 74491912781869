import { Popup } from 'semantic-ui-react';
import AssignedUsersListing from './AssignedUsersListing';
import './AssignedUsersToolTip.scss';

export interface AssignedUsersToolTipProps {
  trigger: any;
  id: string;
  disabled: boolean;
}

const AssignedUsersToolTip = ({ trigger, id, disabled }: AssignedUsersToolTipProps) => (
  <Popup
    content={<AssignedUsersListing id={id} />}
    position="bottom left"
    className="assigned-users-tooltip border-none bg-white"
    trigger={trigger}
    hoverable={true}
    basic={true}
    disabled={disabled}
  />
);

export default AssignedUsersToolTip;
