import { ChartType, TooltipModel } from 'chart.js';
import moment from 'moment';
import Event from 'common/model/Event';
import Scale from 'common/model/Scale';
import { ContractEvents, PropertiesEvents } from 'common/api/dashboard';
import { deepClone } from 'utils/tsHelper';
import { getContractEventTypeProperty } from 'utils/utils-contract';
import { addSpaceOrComma } from 'utils/utils-number';

export interface UpcomingEventTooltipProps {
  contractEvents: ContractEvents[];
  eventName: Event;
}

const getEventsByPeriod = (activeDate: string, events: ContractEvents[], period: string) => {
  switch (period) {
    case Scale.Month:
      const activeDateMonth = new Date(activeDate).getMonth();
      const activeDateYear = new Date(activeDate).getFullYear();
      return events.filter(
        ({ date }) => new Date(date).getMonth() === activeDateMonth && new Date(date).getFullYear() === activeDateYear,
      );
  }
};

const groupByEventName = (events?: ContractEvents[]) => {
  if (!events) return [];
  const output: UpcomingEventTooltipProps[] = [];
  events.forEach(event => {
    const index = output.findIndex(({ eventName }) => eventName === event.event);
    if (index === -1) {
      output.push({ eventName: event.event, contractEvents: [event] });
    } else {
      const { tenantId, contractId, area } = event;
      const contractEventIndex = output[index].contractEvents.findIndex(
        contractEvent => contractEvent.contractId === contractId && contractEvent.tenantId === tenantId,
      );
      if (contractEventIndex > -1) {
        output[index].contractEvents[contractEventIndex].area = (
          Number(output[index].contractEvents[contractEventIndex].area) + Number(area)
        ).toString();
      } else {
        output[index].contractEvents.push(event);
      }
    }
  });
  return output;
};

const getContractEventView = (contractEventDetail: UpcomingEventTooltipProps) => {
  if (contractEventDetail) {
    const { eventName, contractEvents } = contractEventDetail;
    return `
          <div class="section">
              <div class="title">
                  <div class="bullet" style="background: ${getContractEventTypeProperty(eventName, 'color')}"></div>
                  <h3 class="item-title">${getContractEventTypeProperty(
                    eventName,
                    'text',
                  )} (${contractEvents.length})</h3>

              </div>
              <ul class="list">
              ${contractEvents
                .map(
                  ({ date, area, contractId, contractIdentifier, tenantId, tenantName }, index) => `
                  <li key="${index}">
                        <span class="text">
                          <span class="item-title">Area: &nbsp </span>
                          <span>${addSpaceOrComma(area, false)} sqft</span>
                        </span>
                        <span class="text">
                          <span class="item-title">Contract ID:&nbsp</span>
                          <span><a href="/contracts/${contractId}/details" target="_blank">${contractIdentifier}</a> </span>
                        </span>
                        <span class="text">
                          <span class="item-title">Tenant: &nbsp </span>
                          <span><a href="/tenant-entities/${tenantId}/details" target="_blank">${tenantName}</a></span>
                        </span>
                        <span class="text">
                          <span class="item-title">Date: &nbsp </span>
                          <span>${date ? moment(date).format('DD/MM/YYYY') : ''}</span>
                        </span>
                      </li>  `,
                )
                .join('')}
              <ul>
          </div>
      `;
  }
};

export const UpcomingEventsPortfolioTooltip =
  (data: PropertiesEvents, period: string) =>
  ({ dataPoints }: TooltipModel<ChartType>) => {
    const { contractEvents, propertiesEvents } = data;
    const index = dataPoints[0].dataIndex;
    const date = propertiesEvents[index].date;
    const filteredEvents: UpcomingEventTooltipProps[] = groupByEventName(
      getEventsByPeriod(date, deepClone(contractEvents), period),
    );

    if (filteredEvents?.length) {
      return ` <div class="inner-html upcoming-events">
                  ${filteredEvents.map(event => getContractEventView(event)).join('')}
                </div>`;
    } else {
      return 'No Events';
    }
  };
