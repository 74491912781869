import DropdownSelect from 'atoms/DropdownSelect';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import { checkIfNotDefined } from 'utils/tsHelper';
import { getNestedValue } from 'utils/utils-nested-object-update';
import { RowFilterChildProps } from '.';
import {
  ComparablesTableFilterDropdownOptions,
  ComparablesTableOperatorsDropdownOptions,
  DataType,
  FilterLogicalOperators,
  FilterMode,
  FilterOnchangeKeys,
  RowAction,
  RowProps,
} from '../../RowProps';

export const FILTER_OPTIONS: ComparablesTableFilterDropdownOptions[] = [
  { key: 1, text: 'Equals', value: FilterMode.EQUALS },
  { key: 2, text: 'Greater than', value: FilterMode.GREATER_THAN },
  { key: 3, text: 'Less than', value: FilterMode.LESS_THAN },
  { key: 4, text: 'Greater than or equal', value: FilterMode.GREATER_THAN_OR_EQUALS },
  { key: 5, text: 'Less than or equal', value: FilterMode.LESS_THAN_OR_EQUALS },
];

export const LOGICAL_OPERATORS: ComparablesTableOperatorsDropdownOptions[] = [
  { key: 1, text: 'AND', value: FilterLogicalOperators.AND },
  { key: 2, text: 'OR', value: FilterLogicalOperators.OR },
];

const compare = (filterValue: number, value: number, filterMode?: FilterMode) => {
  switch (filterMode) {
    case FilterMode.EQUALS:
      return value === filterValue;
    case FilterMode.GREATER_THAN:
      return filterValue < value;
    case FilterMode.LESS_THAN:
      return filterValue > value;
    case FilterMode.GREATER_THAN_OR_EQUALS:
      return value >= filterValue;
    case FilterMode.LESS_THAN_OR_EQUALS:
      return value <= filterValue;
    default:
      return true;
  }
};

export function applyNumbersFilter(rowConfig: RowProps, content: any, rowAction: RowAction): boolean {
  if (!rowConfig.field) throw new Error('ApplyNumbersFilter: field key is missing');

  let value = getNestedValue(content, rowConfig.field);

  if (checkIfNotDefined(value)) {
    value = 0;
  }

  if (rowConfig.dataType === DataType.DECIMAL) {
    value = value.toNumber();
  }

  if (
    (checkIfNotDefined(rowAction.filterValue) || Number.isNaN(rowAction.filterValue)) &&
    (checkIfNotDefined(rowAction.filterValue2) || Number.isNaN(rowAction.filterValue2))
  )
    return true;

  const case1 = compare(rowAction.filterValue, value, rowAction.filterMode);
  const case2 = compare(rowAction.filterValue2, value, rowAction.filterMode2);

  switch (rowAction.filterLogicalOperator) {
    case FilterLogicalOperators.AND:
      return case1 && case2;
    case FilterLogicalOperators.OR:
      return case1 || case2;
    default:
      return case1;
  }
}

const NumberFilter = ({
  filterMode,
  filterMode2,
  filterValue,
  filterValue2,
  filterLogicalOperator,
  label,
  dataType,
  onChange,
}: RowFilterChildProps): JSX.Element => {
  return (
    <div>
      <div className="grid">
        <div className="col-6">
          <DropdownSelect
            placeholder="Select..."
            fieldKey={FilterOnchangeKeys.filterMode}
            value={filterMode}
            options={FILTER_OPTIONS.filter(({ value }) => value !== filterMode2)}
            onChange={onChange}
            clearable={true}
          />
        </div>
        <div className="col-6">
          <InputField
            type={InputFieldType.NUMBER}
            value={filterValue}
            placeholder={'Search By ' + label}
            onChange={onChange}
            fieldKey={FilterOnchangeKeys.filterValue}
            isDecimal={dataType === DataType.DECIMAL}
          />
        </div>
      </div>

      <div className="grid m-b-sm">
        <div className="col-3">
          <DropdownSelect
            placeholder="Select..."
            fieldKey={FilterOnchangeKeys.filterLogicalOperator}
            value={filterLogicalOperator}
            options={LOGICAL_OPERATORS}
            onChange={(key, value) => {
              if (value) {
                onChange(key, value);
              } else {
                onChange(
                  key,
                  {
                    filterLogicalOperator: value,
                    filterMode2: undefined,
                    filterValue2: undefined,
                  },
                  true,
                );
              }
            }}
            clearable={true}
          />
        </div>
      </div>

      {filterLogicalOperator && (
        <div className="grid">
          <div className="col-6">
            <DropdownSelect
              placeholder="Select..."
              fieldKey={FilterOnchangeKeys.filterMode2}
              value={filterMode2}
              options={FILTER_OPTIONS.filter(({ value }) => value !== filterMode)}
              onChange={onChange}
              clearable={true}
            />
          </div>
          <div className="col-6">
            <InputField
              type={InputFieldType.NUMBER}
              value={filterValue2}
              placeholder={'Search By ' + label}
              onChange={onChange}
              fieldKey={FilterOnchangeKeys.filterValue2}
              isDecimal={dataType === DataType.DECIMAL}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default NumberFilter;
