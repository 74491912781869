import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import TransactionProvisionApproval from 'common/model/TransactionProvisionApproval';
import TransactionProvisionApprovalInput from 'common/model/TransactionProvisionApprovalInput';

interface UpdateTransactionProvisionApprovalMutationVariables {
  transactionProvisionApprovalId: string;
  params: TransactionProvisionApprovalInput;
}

export const UPDATE_TRANSACTION_PROVISION_APPROVAL = (
  transactionProvisionApproval: TransactionProvisionApproval | undefined,
) => {
  if (!transactionProvisionApproval?.id)
    throw new Error('Update Transaction Provision Approval: Transaction Provision Approval ID is missing');

  const variables: UpdateTransactionProvisionApprovalMutationVariables = {
    transactionProvisionApprovalId: transactionProvisionApproval.id,
    params: {
      userId: transactionProvisionApproval.user.id,
      provisionId: transactionProvisionApproval.provision.id,
      status: transactionProvisionApproval.status,
      dateSubmitted: transactionProvisionApproval.dateSubmitted,
    },
  };

  return {
    mutation: gql`
      mutation ($transactionProvisionApprovalId: ID!, $params: TransactionProvisionApprovalInput!) {
        updateTransactionProvisionApproval(
          transactionProvisionApprovalId: $transactionProvisionApprovalId
          params: $params
        ) {
          id
          status
          dateSubmitted
          note
          user {
            id
            firstName
            lastName
            photoUrl
          }
          provision {
            selected
            provision {
              id
              name
              provisionCategory {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: variables,
  };
};

export const updateTransactionProvisionApproval = createAsyncThunk(
  'transactions/updateTransactionProvisionApproval',
  async ({ approvalId }: { approvalId: string }, { getState }) => {
    const {
      transactionDetail: { transactionProvisionApprovals },
    } = getState() as RootState;
    const activeTransactionProvisionApproval = transactionProvisionApprovals.find(
      (obj: TransactionProvisionApproval) => obj.id === approvalId,
    );

    const response = await client.mutate(UPDATE_TRANSACTION_PROVISION_APPROVAL(activeTransactionProvisionApproval));
    return response;
  },
);
