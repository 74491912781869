import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import { CreateNewDropdownProps, PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { DropdownItem } from 'atoms/DropdownMenu';
import { createNewProvision } from 'store/provisions/provisionDetailSlice';
import { MODAL_MODE_OPTIONS, listDocumentTypes } from 'common/api/miscellaneous';
import { listProvisionCategories, listProvisionsAndCategories } from 'common/api/provisions';
import CreateModal from './CreateModal';
import ProvisionsCardsView from './ProvisionsCardsView';
import ProvisionsHeaderToolbar from './ProvisionsHeaderToolbar';

/**
 * Provisions Card pages
 *
 * This page loads the categories and for each one, shows the correspondents provisions
 *
 * In the page you can:
 * Create a new Category
 * Edit a Category
 * Delete a Cetegory
 * Go to Provision Create Page
 * Go to Provision Detail Page
 */
const ProvisionsDashboard = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoading, categoriesLoading, provisionCategoriesList } = useAppSelector(
    (state: RootState) => state.provisionsListing,
  );
  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [modalType, setModalType] = useState<MODAL_MODE_OPTIONS>(MODAL_MODE_OPTIONS.CREATE);

  useEffect(() => {
    dispatch(listProvisionCategories());
    dispatch(listDocumentTypes({ first: 100 }));
    dispatch(listProvisionsAndCategories({ first: 300 }));
  }, [dispatch]);

  const setCreateModal = (type: MODAL_MODE_OPTIONS): void => {
    setModalType(type);
    setModalStatus(true);
  };

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [{ title: 'Provisions' }];

    const onCreateProvision = (): void => {
      dispatch(createNewProvision());
      navigate('/provisions/create');
    };

    const dropdownItems: DropdownItem[] = [
      {
        key: '1',
        label: 'Category',
        onClick: () => setCreateModal(MODAL_MODE_OPTIONS.CREATE),
      },
      {
        key: '2',
        label: 'Provision',
        disabled: provisionCategoriesList.length === 0,
        onClick: onCreateProvision,
      },
    ];

    const createNewDropdown: CreateNewDropdownProps = {
      dropdownItems,
      dropdownText: 'CREATE',
      dataTest: 'create-button',
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <ProvisionsHeaderToolbar />,
      createNewDropdown,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading || categoriesLoading}
      className="provisions-page"
      pageHeader={getPageHeaderProps()}
    >
      <ProvisionsCardsView setModalStatus={setCreateModal} />
      <CreateModal
        type={modalType}
        status={modalStatus}
        setModalStatus={setModalStatus}
      />
    </PageLayout>
  );
};

export default ProvisionsDashboard;
