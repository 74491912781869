import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';
import { removeActiveClassFromPreviousDate } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import { dateInputValidator } from 'utils/utils-clean-input';
import { Icons } from 'utils/utils-icons';
import CustomHeader from './CustomHeader';
import './DateCell.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface DateCellProps {
  date: string;
  type: string;
  onChange: ActionCreatorWithPayload<any>;
}

const DateCell = ({ date, type, onChange }: DateCellProps): JSX.Element => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const convertToDate = (date: string): Date | undefined => {
    if (checkNotEmpty(date)) {
      return new Date(date);
    }
  };

  const clickCalender = (id: string): void => {
    document.getElementById(id)?.click();
  };

  const onSelectDate = (newDate: Date | null, e: React.SyntheticEvent<HTMLInputElement> | undefined): void => {
    dispatch(onChange({ type, date: newDate, dateValue: e?.currentTarget?.value }));
    setIsOpen(false);
  };

  const selectToday = (date: Date): void => onSelectDate(date, undefined);

  useEffect(() => {
    removeActiveClassFromPreviousDate(isOpen);
  }, [isOpen]);

  return (
    <div className="date-cell">
      <DatePicker
        id={`date-picker-${type}`}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
        open={isOpen}
        dateFormat="dd/MM/yyyy"
        tabIndex={-1}
        placeholderText="DD/MM/YYYY"
        renderCustomHeader={data => CustomHeader(data, selectToday)}
        onKeyDown={dateInputValidator}
        selected={convertToDate(date)}
        onChange={onSelectDate}
        preventOpenOnFocus={true}
      />
      <Icon
        icon={Icons.Calender}
        onClick={() => clickCalender(`date-picker-${type}`)}
      />
    </div>
  );
};

export default DateCell;
