import { Grid } from 'semantic-ui-react';
import PageLayout from 'layouts/PageLayout';
import RightImage from './RightImage';
import './RightImagePage.scss';

const RightImagePage = ({ leftContent, landingPage }: { leftContent: JSX.Element; landingPage?: boolean }) => {
  return (
    <PageLayout>
      <Grid className={`pm-none welcome-page ${landingPage ? 'section-box' : ''}`}>
        <Grid.Row className="pm-none">
          <Grid.Column
            width={landingPage ? '8' : '6'}
            className={!landingPage ? 'pm-none left' : 'landing-page-left'}
          >
            {leftContent}
          </Grid.Column>
          {!landingPage && <Grid.Column width="1"></Grid.Column>}
          <Grid.Column
            width={landingPage ? '8' : '9'}
            className={`${landingPage && 'landing-page-right'} pm-none right`}
          >
            <RightImage />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PageLayout>
  );
};

export default RightImagePage;
