import SaleAndRedevelopmentRights from 'common/model/SaleAndRedevelopmentRights';
import SaleAndRedevelopmentRightsInput from 'common/model/SaleAndRedevelopmentRightsInput';
import { convertDateToISO } from 'utils/utils-date';

export interface SaleAndRedevelopmentRightsInputClone
  extends Omit<SaleAndRedevelopmentRightsInput, 'earliestDate' | 'noticePeriodMonths'> {
  earliestDate: Date | null;
  noticePeriodMonths: number | null;
}

export class CSaleAndRedevelopmentRights implements SaleAndRedevelopmentRightsInputClone {
  compensationFormula: string;
  earliestDate: Date | null;
  noticePeriodMonths: number | null;

  constructor(input: SaleAndRedevelopmentRights | null) {
    this.compensationFormula = input ? input.compensationFormula : '';
    this.earliestDate = input && input.earliestDate ? new Date(input.earliestDate) : null;
    this.noticePeriodMonths = input ? input.noticePeriodMonths : null;
  }

  static fragment() {
    return `
        compensationFormula
        earliestDate
        noticePeriodMonths
    `;
  }

  static formatForAPI(right: CSaleAndRedevelopmentRights) {
    return {
      compensationFormula: right.compensationFormula ? right.compensationFormula : '',
      earliestDate: convertDateToISO(right.earliestDate),
      noticePeriodMonths: right.noticePeriodMonths ? parseInt(right.noticePeriodMonths.toString()) : null,
    };
  }
}
