import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeletePolicyProvisionQueryVariables {
  id: string;
}

export const DELETE_POLICY_PROVISION = (id: string | undefined) => {
  if (!id) throw new Error('DELETE_POLICY_PROVISION: id is required');

  const variables: DeletePolicyProvisionQueryVariables = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deletePolicyProvision(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deletePolicyProvision = createAsyncThunk(
  'policies/deletePolicyProvision',
  async ({ id }: { id: string | undefined }) => {
    const response = await client.mutate(DELETE_POLICY_PROVISION(id));
    return response;
  },
);
