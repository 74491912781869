import { CManagementCharge, CRenewalRights } from 'common/_classes/contractsV2';
import moment from 'moment';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import CheckboxField from 'atoms/FormField/Checkbox';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveContractEventInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { conditionalClassName } from 'utils/tsHelper';
import TabLabel from '../../TabLabel';
import OptionToRenewForm from './OptionToRenewForm';

const OptionToRenew = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();

  const { activeContractEvent } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const renewalRightsFlag = activeContractEvent?.renewFlag || false;
  const renewalRights = activeContractEvent?.renewalRights || [];

  const [tabIndex, setTabIndex] = useState(0);

  const disabled = mode === MODE_OPTIONS.READ;

  const onAdd = () => {
    const value = [...renewalRights, new CRenewalRights(null)];
    dispatch(updateActiveContractEventInput({ key: 'renewalRights', value }));
    setTabIndex(value.length - 1);
  };

  const onDelete = (index: number) => {
    const value = renewalRights.filter((_item, idx) => index !== idx);
    dispatch(
      updateActiveContractEventInput({
        key: 'renewalRights',
        value,
      }),
    );

    if (value.length === 0) dispatch(updateActiveContractEventInput({ key: 'renewFlag', value: false }));
  };

  const getTabsMenu = (): TabMenuProps[] => {
    return renewalRights.map(({ startDate, endDate }, index: number) => {
      const start = moment(startDate).isValid() ? moment(startDate).format('DD/MM/YYYY') : '';
      const end = moment(endDate).isValid() ? moment(endDate).format('DD/MM/YYYY') : '';

      const dateText = `${start}${start && end ? ' - ' : ''}${end}`;

      return {
        key: `renewal-rights-tab-${index}`,
        label: (
          <TabLabel
            title="Option"
            disabled={mode === MODE_OPTIONS.READ}
            deletable={true}
            index={index}
            onDelete={onDelete}
            activeIndex={tabIndex}
            tabsLength={renewalRights.length}
            dateText={dateText}
            setTabIndex={setTabIndex}
          />
        ),
        customTab: (
          <OptionToRenewForm
            key={index}
            mode={mode}
            index={index}
          />
        ),
      };
    });
  };

  const onTabChange = (index: number) => setTabIndex(index);

  const toggleSwitch = (_key: string, value: boolean) => {
    dispatch(updateActiveContractEventInput({ key: 'renewalRights', value: value ? [new CRenewalRights(null)] : [] }));
    dispatch(updateActiveContractEventInput({ key: 'renewFlag', value }));

    if (!value) setTabIndex(0);
  };

  return (
    <div className={conditionalClassName(renewalRightsFlag, 'border-bottom-sm-grayish-magenta-light p-b-s')}>
      <div className="grid">
        <div className="col-2 d-flex justify-space-between">
          <label className="font-600 font-size-1_1429">Option to renew </label>

          <CheckboxField
            className="d-flex align-center"
            dataTest=""
            fieldKey=""
            value={renewalRightsFlag}
            toggle={true}
            disabled={disabled}
            onChange={toggleSwitch}
          />
        </div>

        {renewalRightsFlag && !disabled && (
          <AddPlus
            label="Add option to Renew"
            onClick={onAdd}
            className="m-l-auto"
          />
        )}
      </div>

      {renewalRightsFlag && (
        <TabReact
          dataTest=""
          activeTabIndex={tabIndex}
          tabMenu={getTabsMenu()}
          onTabChange={onTabChange}
        />
      )}
    </div>
  );
};

export default OptionToRenew;
