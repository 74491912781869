import moment from 'moment';
import { Form, Input } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import DateField from 'atoms/FormField/DateInput';
import { updateMonitoringDate, updateView } from 'store/dashboard/stackingPlanSlice';
import { STACKING_PLAN_VIEW_OPTIONS } from 'utils/types/stackingPlan';
import { Icons } from 'utils/utils-icons';
import ContractAvailableStatus from './ContractAvailableStatusList';
import './StackingPlan.scss';

const StackingPlanFilter = () => {
  const { activeView, monitoringDate } = useAppSelector((state: RootState) => state.stackingPlan);

  const dispatch = useAppDispatch();

  const onViewChange = (key: string, value: string) => {
    dispatch(updateView({ view: value }));
  };

  const additionalFilters = (
    <>
      <div className="p-l-sm">
        <Input
          className="search-field"
          icon={Icons.SearchInput}
          iconPosition="left"
          placeholder="Search..."
        />
      </div>
      <ContractAvailableStatus showCheckbox={false} />
    </>
  );

  const monitoringDateFilterChange = (key: string, value: any) => {
    dispatch(
      updateMonitoringDate({
        monitoringDate: moment(value).format('YYYY-MM-DD'),
      }),
    );
  };

  return (
    <Form className="p-l-none">
      <div className="stacking-plan-filters">
        <div className="d-inline-flex">
          <label className="m-r-sm m-t-s">Monitoring date</label>
          <DateField
            fieldKey="monitoring-date"
            value={new Date(monitoringDate).toISOString()}
            onChange={monitoringDateFilterChange}
          />
        </div>

        {activeView === STACKING_PLAN_VIEW_OPTIONS.Table ? additionalFilters : ''}
      </div>
      {activeView === STACKING_PLAN_VIEW_OPTIONS.Table ? (
        <div className="stacking-table-file-actions">
          <span>
            Import
            <Icon icon={Icons.Downloadv2} />
          </span>
          <span>
            Export
            <Icon icon={Icons.Uploadv2} />
          </span>
        </div>
      ) : (
        ''
      )}
    </Form>
  );
};

export default StackingPlanFilter;
