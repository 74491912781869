import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

export interface GeneratePropertyRevenueAnalysisBaseQueryVariables {
  currentLandlordEntityId?: string | null;
  propertyId?: string | null;
  startDate?: string;
  endDate?: string;
}

export const GENERATE_PROPERTY_REVENUE_ANALYSIS_BASE = (propertyId: string, startDate: string, endDate: string) => {
  if (!propertyId) {
    throw new Error('Generate Property Revenue Analysis Base: Property ID could not be determined. Query aborted.');
  }

  const variables: GeneratePropertyRevenueAnalysisBaseQueryVariables = {
    currentLandlordEntityId: getCurrentLandlordEntityId(),
    propertyId,
    startDate,
    endDate,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $propertyId: ID!, $startDate: Date!, $endDate: Date!) {
        generatePropertyRevenueAnalysisBase2(
          currentLandlordEntityId: $currentLandlordEntityId
          propertyId: $propertyId
          startDate: $startDate
          endDate: $endDate
        ) {
          propertyId
          propertyData {
            month
            statsRevenue {
              contractualRevenue
              forecastRevenue
              headlineRent
              revenueOverPerformance {
                contractualRevenue
                forecastRevenue
                performance
                variance
              }
              revenueUnderPerformance {
                contractualRevenue
                forecastRevenue
                performance
                variance
              }
            }
            statsArea {
              area
              contractualArea
              contractualOccupancyRate
              forecastArea
              forecastOccupancyRate
              occupancyOverPerformance {
                contractualArea
                forecastArea
                performance
                variance
              }
              occupancyUnderPerformance {
                contractualArea
                forecastArea
                performance
                variance
              }
            }
          }
          unitsData {
            month
            unitId
            statsRevenue {
              performance
              variance
              contractualRevenue
              forecastRevenue
              headlineRent
            }
            unitName
            statsArea {
              area
              contractualArea
              forecastArea
              performance
              variance
            }
            floorName
            floorId
            floorIndex
          }
        }
      }
    `,
    variables,
  };
};

export const generatePropertyRevenueAnalysisBase = createAsyncThunk(
  'forecasts/generatePropertyRevenueAnalysisBase',
  async (_args, { getState }) => {
    const {
      forecasts: {
        dateFilter: { startDate, endDate },
      },
      stackingPlan: { activeProperty },
    } = getState() as RootState;

    const { id: propertyId } = activeProperty || { id: '' };

    const response = await client.query(GENERATE_PROPERTY_REVENUE_ANALYSIS_BASE(propertyId, startDate, endDate));
    return response;
  },
);
