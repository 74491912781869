/* eslint-disable jsx-a11y/label-has-associated-control */
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { updateResetStep } from 'store/authentication/authSlice';
import { Icons } from 'utils/utils-icons';
import { PASSWORD_RESET_STEPS } from '../PasswordReset';

const SignUpVerify = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state: RootState) => state.auth);

  const onRedirect = () => {
    dispatch(updateResetStep({ step: PASSWORD_RESET_STEPS.ENTER_EMAIL }));
    navigate('/sign-in');
  };

  return (
    <Form
      className="auth-form"
      step="success"
    >
      <div className="content">
        <h1 className="header">
          Success!
          <Icon
            icon={Icons.CheckFilled}
            className="circle-xl success-check-icon"
          />
        </h1>
        <p className="reset-text email-text">
          Please check your mailbox to verify you email <b>{email}</b>. The verification link will expire in one hour.
        </p>
        <Button
          className="login-btn height-2_857 font-600 text-center"
          type="submit"
          onClick={onRedirect}
        >
          BACK TO LOG IN PAGE
          <Icon
            icon={Icons.ArrowDownRight}
            rotate={4}
            className="width-1_143 height-1_143"
          />
        </Button>
      </div>
    </Form>
  );
};

export default SignUpVerify;
