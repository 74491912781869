import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import TabContainer from 'components/TabContainer';
import { Icons } from 'utils/utils-icons';
import './UnderConstructionContent.scss';

interface UnderConstructionContentProps {
  showBackButton?: boolean;
}

const UnderConstructionContent = ({ showBackButton = true }: UnderConstructionContentProps) => {
  const navigate = useNavigate();

  return (
    <TabContainer tabTitle={'Under Construction'}>
      <div className="under-construction-template">
        <p>Woops! Looks like this page is under construction...</p>
        {showBackButton ? (
          <Button
            fluid={true}
            className="btn grey-bg back-btn"
            onClick={() => navigate(-1)}
          >
            <Icon icon={Icons.ArrowBack} />
            GO BACK
          </Button>
        ) : (
          ''
        )}
      </div>
    </TabContainer>
  );
};

export default UnderConstructionContent;
