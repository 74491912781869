import React from 'react';
import { Button, ButtonProps, Modal } from 'semantic-ui-react';
import { Icon } from '@iconify/react';
import { Icons } from 'utils/utils-icons';

interface FailureContentProps {
  onTryAgain: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void;
  close: (isClosed: boolean) => void;
}

const FailureContent = ({ onTryAgain, close }: FailureContentProps) => {
  return (
    <Modal.Content>
      <Modal.Description>
        <h4 className="title">Failed...</h4>
      </Modal.Description>

      <div className="text">Something went wrong, please try again.</div>
      <Button
        fluid={true}
        className="btn grey-bg"
        onClick={onTryAgain}
      >
        TRY AGAIN
        <Icon
          icon={Icons.ArrowCounterClockwise}
          style={{ transform: 'rotate(305deg)' }}
        />
      </Button>

      <Button
        fluid={true}
        className="btn grey-bg m-t-sm"
        onClick={() => close(false)}
      >
        CLOSE
      </Button>
    </Modal.Content>
  );
};

export default FailureContent;
