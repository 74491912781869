import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import TabContainer from 'components/TabContainer';
import { setActiveFloor } from 'store/properties/propertyDetailSlice';
import { FloorTabViewProps, PROPERTY_FLOORS_TAB_VIEW } from '..';
import { PROPERTY_TABS_OFFSET } from '../../PropertyTabs';
import PropertyFloorTable from './PropertyFloorTable';

const FloorListing = ({ switchView }: FloorTabViewProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const url = `/properties/${id}/details?tabIndex=${PROPERTY_TABS_OFFSET.FLOORS_AND_UNITS}&view=${PROPERTY_FLOORS_TAB_VIEW.FLOOR_CREATE}`;

  const onCreateNewFloor = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setActiveFloor({ isNew: true }));
    switchView(PROPERTY_FLOORS_TAB_VIEW.FLOOR_CREATE);
    navigate(url);
  };

  return (
    <TabContainer
      tabTitle="Floors & Units"
      tabToolbar={
        <Button
          className="btn grey-bg"
          data-test="create-new-floor-button"
          onClick={onCreateNewFloor}
          href={url}
        >
          Create New Floor
        </Button>
      }
    >
      <PropertyFloorTable />
    </TabContainer>
  );
};

export default FloorListing;
