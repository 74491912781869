import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { ViewProps } from 'common/api/miscellaneous';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import {
  createTranslateToContract,
  getTranslateToContract,
  updateTranslateToContract,
} from 'common/api/translatorsToContract';
import { translateToContractDisabled } from 'utils/tsValidator';
import TranslatorToContractContent from './TranslatorToContractContent';

const TranslatorCreatorViewer = ({ isViewPage }: ViewProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { id } = useParams();
  const checkCreate: boolean = location.pathname.includes('create');
  const pageTypeCheck: MODE_OPTIONS = checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ;
  const [mode, setMode] = useState<MODE_OPTIONS>(pageTypeCheck);
  const { activeTranslateToContract, isLoading } = useAppSelector((state: RootState) => state.translatorDetail);

  useEffect(() => {
    if (isViewPage) {
      // @ts-ignore
      dispatch(getTranslateToContract({ id: id || '' }));
    }
  }, [id]);

  const getPageHeaderProps = (): PageHeaderProps => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Translators To Contract',
        url: '/translate-to-contract/dashboard',
      },
      {
        title: activeTranslateToContract.name,
      },
    ];

    const CustomHeaderButtons = (): React.ReactElement => {
      const checkDisabled = (): boolean => {
        return translateToContractDisabled(activeTranslateToContract);
      };

      const onCreate = (): void => {
        dispatch(createTranslateToContract()).then((response: any) => {
          if (response.meta.requestStatus === 'fulfilled') {
            navigate('/translate-to-contract/dashboard');
          }
        });
      };

      const onSave = (): void => {
        if (checkCreate) {
          onCreate();
        } else {
          const result = dispatch(updateTranslateToContract()).then(
            response => response.meta.requestStatus === 'fulfilled',
          );
          if (!result) setMode(MODE_OPTIONS.EDIT);
        }
      };

      const onCancel = (): void => {
        if (checkCreate) {
          navigate('/translate-to-contract/dashboard');
        } else {
          const translateToContract = activeTranslateToContract as any;
          dispatch(getTranslateToContract({ id: translateToContract.id }));
        }
      };

      return (
        <DetailsControlButtons
          mode={mode}
          setMode={setMode}
          checkDisabled={checkDisabled()}
          onSave={onSave}
          onCancel={onCancel}
        />
      );
    };

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: <CustomHeaderButtons />,
    };

    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TranslatorToContractContent mode={mode} />
    </PageLayout>
  );
};

export default TranslatorCreatorViewer;
