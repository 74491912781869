import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { CreateMemberInput, CreatedMemberInput } from './type';

interface UpdateMemberResendInviteQueryVariables {
  currentLandlordEntityId: string;
  params: CreateMemberInput;
  memberId: string;
}

export const UPDATE_MEMBER_RESEND_INVITE = (member: CreatedMemberInput) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Update Member Resend Invite: currentLandlordEntityId is required');

  if (!member?.memberId) throw new Error('Update Member Resend Invite: memberId is required');

  const variables: UpdateMemberResendInviteQueryVariables = {
    currentLandlordEntityId,
    memberId: member.memberId,
    params: {
      email: member.email,
      roleId: member.roleId,
    },
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $memberId: ID!, $params: CreateMemberInput!) {
        updateMemberResendInvite(
          currentLandlordEntityId: $currentLandlordEntityId
          memberId: $memberId
          params: $params
        ) {
          id
          user {
            id
            photoUrl
            email
            firstName
            lastName
            phoneNumber
          }
          role {
            id
            name
            approvalLevel
          }
          entity {
            id
            name
          }
          status
        }
      }
    `,
    variables,
  };
};

export const updateMemberResendInvite = createAsyncThunk(
  'members/updateMemberResendInvite',
  async (_arg, { getState }) => {
    const state = getState() as RootState;
    const members: any = state.members;
    const activeInvitedUser = members.activeInvitedUser;

    const response = await client.mutate(UPDATE_MEMBER_RESEND_INVITE(activeInvitedUser as CreatedMemberInput));

    return response;
  },
);
