import { store } from 'store';
import { Editor } from 'tinymce';
import { ToolbarButton } from 'components/ContextToolbar';
import { deleteActiveNode } from 'components/Editor/Components';
import { EDITOR_INFORMATION_TABS_OFFSET } from 'components/Editor/EditorSideMenu';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import {
  setCrossRefDetails,
  setCrossRefModal,
  updateActiveDocumentContent,
} from 'store/provisions/provisionDetailSlice';
import NodeType from 'common/model/NodeType';
import { ContentNodeType, deleteNode } from 'common/api/nodes';
import { updateProvisionContent } from 'common/api/provisions';
import { contextToolbarForceClose } from '../ContextClauseToolbar';

/**
 * Update the active provision with the content of editor
 */
const updateActiveProvision = (editor: Editor) => {
  if (!editor) return;
  const documentTypeId = editor.getParam('id');
  if (!documentTypeId) {
    console.warn('Trying to save without an ID');
    return;
  }
  store.dispatch(updateActiveDocumentContent({ editor, documentTypeId }));
};

// Insert space node before or after the current node
const insertSpaceNode = (editor: Editor, pos: string) => {
  if (!editor) return;
  const documentTypeId = editor.getParam('id');

  let node = editor.selection.getNode();

  // Get to parameter or text node from nested node
  while (
    ![ContentNodeType.PARAMETER, ContentNodeType.TEXT, ContentNodeType.CLAUSE_REFERENCE].includes(
      node.getAttribute('data-node-type') as ContentNodeType,
    )
  ) {
    node = node.parentElement as HTMLElement;
  }

  // Insert the new element tag as a sibling
  const position = pos === 'after' ? 'afterend' : 'beforebegin';
  node.insertAdjacentHTML(position, '&nbsp;');
  const sibling = pos === 'after' ? node.nextSibling : node.previousSibling;
  // Set focus on inserted sibling
  editor.selection.setCursorLocation(sibling as Node, 1);
  editor.focus();

  // Update content to Active Provision
  updateActiveProvision(editor);

  // Silent - Saves the entire Provision
  store.dispatch(updateProvisionContent({ documentTypeId }));
};

// Remove the Node from the Editor
const deleteActiveNodeForm = (editor: Editor) => {
  const { node } = store.getState().nodes;
  const documentTypeId = editor.getParam('id');

  if (!editor || !node) return;

  deleteActiveNode(editor, node);

  // Don't remove text node from the backend
  if (node.type !== NodeType.Text) {
    if (node?.id) store.dispatch(deleteNode({ id: node.id }));
  }

  // Update content to Active Provision
  updateActiveProvision(editor);

  // Silent - Saves the entire Provision
  store.dispatch(updateProvisionContent({ documentTypeId }));

  // Move to None tab
  store.dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.NONE));
};

const toolbarButtons: ToolbarButton[] = [
  {
    id: 'deleteText',
    text: 'Delete',
    icon: 'trash',
    action: editor => {
      deleteActiveNodeForm(editor);
      contextToolbarForceClose();
    },
  },
  {
    id: 'insertSpaceBefore',
    text: 'Insert space before',
    icon: 'pullUp',
    action: editor => {
      insertSpaceNode(editor, 'before');
      contextToolbarForceClose();
    },
  },
  {
    id: 'insertSpaceAfter',
    text: 'Insert space after',
    icon: 'pullDown',
    action: editor => {
      insertSpaceNode(editor, 'after');
      contextToolbarForceClose();
    },
  },
];

const toolbarClauseRefButtons: ToolbarButton[] = [
  {
    id: 'updateClauseRef',
    text: 'Update Clause Reference',
    icon: 'pullUp',
    action: editor => {
      contextToolbarForceClose();
      const { node } = store.getState().nodes;
      const clauseRefAttributes = editor.dom.select(`[data-node-id='${node?.id}']`)[0].attributes;
      const clauseRefData = {
        clauseId: clauseRefAttributes.getNamedItem('data-clause-id')?.value,
        docId: clauseRefAttributes.getNamedItem('data-doc-id')?.value,
        provisionId: clauseRefAttributes.getNamedItem('data-provision-id')?.value,
      };
      store.dispatch(setCrossRefDetails(clauseRefData));
      store.dispatch(setCrossRefModal(true));
    },
  },
  ...toolbarButtons,
];

/** Function to build context-menu based on the defined array of ToolbarButtons
 * @param {Editor} editor
 */
const ContextNodeToolbar = (editor: Editor) => {
  // List of options for the Context Text
  const contextButtons: ToolbarButton[] = toolbarButtons;

  // Register every option. The option SHOULD be registered before used.
  for (let button of contextButtons) {
    // Add a button passing each object's attributes as parameters
    editor.ui.registry.addButton(button.id, {
      text: button.text,
      icon: button.icon,
      onAction: () => {
        button.action(editor);
      },
    });
  }

  // Map over each button and take it's id.
  const buttonsList: string = [''].concat(contextButtons.map(btn => btn.id)).join(' | ');

  // Context with Add Context
  editor.ui.registry.addContextToolbar('nodeIndexToolbar', {
    /* Predicate returns a boolean
    Toolbar is shown accordingly */
    predicate: node => {
      const nodeType = node.getAttribute('data-node-type') as ContentNodeType;
      const showContext = store.getState().nodes.showContext;
      if (!nodeType) return false;
      return [ContentNodeType.TEXT, ContentNodeType.PARAMETER].includes(nodeType) && showContext;
    },
    items: buttonsList,
    position: 'selection',
    scope: 'node',
  });
};

/** Function to build context-menu based on the defined array of ToolbarButtons
 * @param {Editor} editor
 */
const ContextClauseRefToolbar = (editor: Editor) => {
  // List of options for the Context Text
  const contextButtons: ToolbarButton[] = toolbarClauseRefButtons;

  // Register every option. The option SHOULD be registered before used.
  for (let button of contextButtons) {
    // Add a button passing each object's attributes as parameters
    editor.ui.registry.addButton(button.id, {
      text: button.text,
      icon: button.icon,
      onAction: () => {
        button.action(editor);
      },
    });
  }

  // Map over each button and take it's id.
  const buttonsList: string = [''].concat(contextButtons.map(btn => btn.id)).join(' | ');

  // Context with Add Context
  editor.ui.registry.addContextToolbar('clauseRefIndexToolbar', {
    /* Predicate returns a boolean
    Toolbar is shown accordingly */
    predicate: node => {
      const nodeType = node.getAttribute('data-node-type') as ContentNodeType;
      const showContext = store.getState().nodes.showContext;
      if (!nodeType) return false;
      return [ContentNodeType.CLAUSE_REFERENCE].includes(nodeType) && showContext;
    },
    items: buttonsList,
    position: 'selection',
    scope: 'node',
  });
};

export { ContextNodeToolbar, ContextClauseRefToolbar };
