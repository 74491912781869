import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ProvisionDetailClone } from 'common/_classes';
import { RootState } from 'store';
import { convertToProvisionInput } from '.';

interface CreateProvisionQueryVariables {
  params: unknown; //ProvisionInput
}

const CREATE_PROVISION = (activeProvision: ProvisionDetailClone) => {
  const variables: CreateProvisionQueryVariables = {
    params: convertToProvisionInput(activeProvision),
  };

  return {
    mutation: gql`
      mutation ($params: ProvisionInput!) {
        createProvision(params: $params) {
          ${ProvisionDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createProvision = createAsyncThunk('provisions/createProvision', async (_args, { getState }) => {
  const {
    provisionDetail: { activeProvision },
  } = getState() as RootState;
  const response = await adminClient.mutate(CREATE_PROVISION(activeProvision));
  return response;
});
