import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeleteNotificationQueryVariables {
  notificationId: string;
}

const DELETE_NOTIFICATION = ({ notificationId }: DeleteNotificationQueryVariables) => {
  if (!notificationId) throw new Error('Delete Notification: notificationId is required');

  const variables: DeleteNotificationQueryVariables = {
    notificationId,
  };

  return {
    mutation: gql`
      mutation ($notificationId: ID!) {
        deleteNotification(notificationId: $notificationId) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async ({ notificationId }: DeleteNotificationQueryVariables) => {
    const response = await client.mutate(DELETE_NOTIFICATION({ notificationId }));
    return response;
  },
);
