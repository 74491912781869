import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

export enum ValidNetworkType {
  owner = 'OWNER',
  tenant = 'TENANT',
  subcontractor = 'SUBCONTRACTOR',
  tenancyAdministrationService = 'TENANCY_ADMINISTRATION_SERVICE',
}

interface UpdateNetworkQueryVariables {
  networkId: string;
  params: any;
}

export const UPDATE_NETWORK = (id: string, childId: string, parentId: string, sharePercentage: string) => {
  let params: any = {
    type: ValidNetworkType.tenant,
    description: [{ sharePercentage: Number(sharePercentage) }],
    childId,
    parentId,
  };

  const variables: UpdateNetworkQueryVariables = {
    networkId: id,
    params: JSON.parse(JSON.stringify(params)),
  };

  return {
    mutation: gql`
      mutation ($networkId: ID!, $params: NetworkInput!) {
        updateNetwork(networkId: $networkId, params: $params) {
          childId
          parentId
          type
        }
      }
    `,
    variables,
  };
};

export const updateNetwork = createAsyncThunk(
  'entityDetail/updateNetwork',
  async (
    { id, childId, sharePercentage, parentId }: { id: string; childId: string; parentId: string; sharePercentage: any },
    { getState },
  ) => {
    const response = await client.mutate(UPDATE_NETWORK(id, childId, parentId, sharePercentage));
    return response;
  },
);
