import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import PropertyCheckbox from 'views/properties/CreatorViewer/Tabs/PropertyInformation/PropertyCheckbox';
import { updateBenchmarkInput } from 'store/benchmark/benchmarkDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';

const Premises = ({ mode }: ModeProps) => {
  const {
    activeBenchmark: { address, size, hasCentralAc, hasCurtainWall, hasMetroCoveredAccess },
  } = useAppSelector((state: RootState) => state.benchmarkDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string | boolean | undefined) => {
    dispatch(updateBenchmarkInput({ key, value }));
  };

  const getGrossArea = (size: number | null): string | null => {
    if (size === null) {
      return '';
    }
    return String(size);
  };

  return (
    <>
      <div className="grid">
        <div className="col-6">
          <InputField
            label="Building Address"
            dataTest="building-address-input"
            value={address}
            disabled={mode === MODE_OPTIONS.READ}
            onChange={onChange}
            fieldKey="address"
          />
        </div>
        <div className="col-6">
          <InputField
            label="Gross Area"
            value={getGrossArea(size)}
            type={InputFieldType.NUMBER}
            unit={'(sqft)'}
            disabled={mode === MODE_OPTIONS.READ}
            fieldKey="size"
            onChange={onChange}
            isDecimal={true}
            dataTest="gross-area-input"
          />
        </div>
        <div className="col-3">
          <PropertyCheckbox
            title="Central AC"
            dataTest="central-ac"
            disabled={mode === MODE_OPTIONS.READ}
            checked={hasCentralAc}
            fieldKey="hasCentralAc"
            onChange={onChange}
          />
        </div>
        <div className="col-3">
          <PropertyCheckbox
            title="Curtain Wall"
            dataTest="curtain-wall"
            disabled={mode === MODE_OPTIONS.READ}
            checked={hasCurtainWall}
            fieldKey="hasCurtainWall"
            onChange={onChange}
          />
        </div>
        <div className="col-3">
          <PropertyCheckbox
            title="Covered access to MTR"
            dataTest="covered-access-to-mtr"
            disabled={mode === MODE_OPTIONS.READ}
            checked={hasMetroCoveredAccess}
            fieldKey="hasMetroCoveredAccess"
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};
export default Premises;
