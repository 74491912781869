import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import DiscussionGroup, { DiscussionGroupInput } from 'common/model/DiscussionGroup';
import TransactionChannel from 'common/model/TransactionChannel';

interface UpdateDiscussionGroupsQueryVariables {
  params: DiscussionGroupInput[];
}

const setDiscussionGroupsInput = (groups: DiscussionGroup[]) => {
  const groupInputs = groups.map((group: DiscussionGroup) => {
    return {
      id: group.id,
      documentTypeId: group.documentType.id,
      index: group.index,
      provisionName: group.provisionName,
      transactionChannels: group.transactionChannels.map((transactionChannel: TransactionChannel) => {
        return {
          id: transactionChannel.id,
          amendmentId: transactionChannel.amendment?.id,
          clauseNumber: transactionChannel.clauseNumber,
          documentTypeId: transactionChannel.documentTypeId,
          index: transactionChannel.index,
          nodeId: transactionChannel.node?.id,
          status: transactionChannel.status,
          title: transactionChannel.title,
          type: transactionChannel.type,
        };
      }),
      transactionId: group.transaction.id,
      type: group.type,
    };
  });

  return groupInputs;
};

const UPDATE_DISCUSSION_GROUPS = (groups: DiscussionGroup[] | null) => {
  if (!groups) throw new Error('Update discussion groups: groups not available');

  const groupInputs = setDiscussionGroupsInput(groups);

  const variables: UpdateDiscussionGroupsQueryVariables = {
    // @ts-ignore
    params: groupInputs,
  };

  return {
    mutation: gql`
      mutation ($params: [DiscussionGroupInput!]!) {
        updateDiscussionGroups(params: $params) {
          id
          index
          provisionName
          type
          documentType {
            id
          }
          transaction {
            id
          }
          transactionChannels {
            id
            title
            clauseNumber
            index
            status
            documentTypeId
            messages {
              id
              message
              updatedAt
            }
            type
          }
        }
      }
    `,
    variables,
  };
};

export const updateDiscussionGroups = createAsyncThunk(
  'transactions/updateDiscussionGroups',
  async (_args, { getState }) => {
    const {
      transactionDetail: { discussionGroups },
    } = getState() as RootState;
    const response = await client.mutate(UPDATE_DISCUSSION_GROUPS(discussionGroups));
    return response;
  },
);
