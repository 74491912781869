import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

interface MemberInput {
  id?: string | null;
  entityId: string | null;
  roleId: string | null;
  status: string | null;
  userId: string | null;
}

interface UpdateMemberInvitationStatusQueryVariables {
  memberId: string | null | undefined;
  params: MemberInput;
}

export const UPDATE_MEMBER_INVITATION_STATUS = ({ id, entityId, roleId, status, userId }: MemberInput) => {
  if (!id) throw new Error('Update Member Invitation Status: Member ID is missing');

  const params: MemberInput = {
    roleId,
    entityId,
    status,
    userId,
  };

  const variables: UpdateMemberInvitationStatusQueryVariables = {
    memberId: id,
    params,
  };

  return {
    mutation: gql`
      mutation ($memberId: ID!, $params: MemberInput!) {
        updateMemberInvitationStatus(memberId: $memberId, params: $params) {
          role {
            id
            name
          }
          entity {
            id
            name
          }
          id
          status
        }
      }
    `,
    variables,
  };
};

export const updateMemberInvitationStatus = createAsyncThunk(
  'auth/updateMemberInvitationStatus',
  async (arg: MemberInput, { getState }) => {
    const { auth } = getState() as RootState;
    arg.userId = auth.id;
    const response = await client.mutate(UPDATE_MEMBER_INVITATION_STATUS(arg));
    return response;
  },
);
