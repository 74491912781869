import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/adminEntities/adminEntitiesListingSlice';
import { listEntities } from 'common/api/adminEntities';

const AdminEntitiesHeaderToolbar = () => {
  const { search } = useAppSelector((state: RootState) => state.adminEntities);

  const onGetData = (value?: string) => {
    return listEntities({ searchBarSpinOnly: value !== undefined && value !== null, first: 1000 });
  };

  //TODO: The Button below is never enabled, so why is it there?
  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
      />
      <Button
        className="btn grey-bg"
        disabled={true}
      >
        CREATE ENTITY
      </Button>
    </>
  );
};

export default AdminEntitiesHeaderToolbar;
