/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { Checkbox, Grid } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { initRowNumber } from 'store/parameters/parameterDetailSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { RowNumberType } from 'common/api/parameters';
import RowSelector from './RowSelector';

export type RowNumberProps = {
  tableId?: string;
  parameterId?: string;
  table?: { id: string; name: string } | null;
  parameter?: { id: string; name: string } | null;
};

export interface ConstrainProps {
  mode: MODE_OPTIONS;
  rowNumber: RowNumberProps;
}

const RowConstrain = ({ mode, rowNumber }: ConstrainProps): JSX.Element => {
  const dispatch = useAppDispatch();

  let activeRadio: RowNumberType;
  if (rowNumber !== null) {
    const parameterId = rowNumber.parameter ? rowNumber.parameter.id : null;
    const tableId = rowNumber.table ? rowNumber.table.id : null;
    if (parameterId !== null) {
      activeRadio = RowNumberType.PARAMETER;
    } else if (tableId !== null) {
      activeRadio = RowNumberType.TABLE;
    } else {
      activeRadio = RowNumberType.PARAMETER;
    }
  } else {
    activeRadio = RowNumberType.PARAMETER;
  }

  const [value, setValue] = useState<RowNumberType>(activeRadio);

  const onChange = (field: RowNumberType): void => {
    setValue(field);
    dispatch(initRowNumber());
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          width={8}
          className="d-grid"
          type="radio-btns"
        >
          <Checkbox
            radio
            label="Another table"
            data-test="check-another-table"
            name="checkboxRadioGroup"
            value="table"
            disabled={mode === MODE_OPTIONS.READ}
            checked={value === RowNumberType.TABLE}
            onChange={() => onChange(RowNumberType.TABLE)}
          />
          <Checkbox
            radio
            label="A parameter"
            data-test="check-parameter"
            name="checkboxRadioGroup"
            value="parameter"
            disabled={mode === MODE_OPTIONS.READ}
            checked={value === RowNumberType.PARAMETER}
            onChange={() => onChange(RowNumberType.PARAMETER)}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="m-t-xxl">
        <RowSelector
          mode={mode}
          type={value}
        />
      </Grid.Row>
    </Grid>
  );
};

export default RowConstrain;
