import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteFormatterQueryVariables {
  id: string;
}

export const DELETE_FORMATTER = (id: string) => {
  if (!id) throw new Error('Delete Formatter: id is required');

  const variables: DeleteFormatterQueryVariables = { id };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteFormatter(id: $id) {
          id
        }
      }
    `,
    variables,
  };
};

export const deleteFormatter = createAsyncThunk('formatters/deleteFormatter', async ({ id }: { id: string }) => {
  const response = await adminClient.mutate(DELETE_FORMATTER(id));
  return response;
});
