import { DataObjectName } from '../utils-interfaces';
import './Statistics.scss';

interface StatisticsProps {
  dataObjectName: DataObjectName;
  successCount: number;
  excelDuplicateCount: number;
  databaseDuplicateCount: number;
  errorsCount: number;
  warningsCount: number;
}

const Statistics = ({
  dataObjectName,
  successCount,
  excelDuplicateCount,
  databaseDuplicateCount,
  errorsCount,
  warningsCount,
}: StatisticsProps) => {
  return (
    <div className="item import-report-statistics">
      <h6 className="title">Statistics</h6>
      <ul>
        <li>
          <span className="bold-text">{successCount}</span> {dataObjectName} data imported
        </li>
        <li>
          <span className="bold-text">{excelDuplicateCount}</span> duplicated data in the Excel file
        </li>
        <li>
          <span className="bold-text">{databaseDuplicateCount}</span> duplicated data in Excel file with Database
        </li>
        <li className={errorsCount > 0 ? 'red-text' : ''}>
          <span className="bold-text">{errorsCount}</span> errors in the Excel file detected
        </li>
        <li>
          <span className="bold-text">{warningsCount}</span> warnings in the Excel file detected
        </li>
      </ul>
    </div>
  );
};

export default Statistics;
