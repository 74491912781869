import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import NotificationComponent from 'components/NotificationComponent';
import { updateActiveNotificationTabIndex } from 'store/notifications/notificationsSlice';
import { listNotifications } from 'common/api/notifications';

const getTabMenuConfig = (): TabMenuProps[] => {
  return [
    {
      key: 'notification-tab-new',
      href: '?tab=new',
      label: 'New',
      dataTest: 'nav-notification-new',
      customTab: <NotificationComponent />,
    },
    {
      key: 'notification-tab-archive',
      href: '?tab=archived',
      label: 'Archived',
      dataTest: 'nav-notification-archive',
      customTab: <NotificationComponent />,
    },
  ];
};

const NotificationTabs = () => {
  const { id: userId } = useAppSelector((state: RootState) => state.auth);
  const { activeTabIndex } = useAppSelector((state: RootState) => state.notifications);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(
        listNotifications({
          first: 1000,
          loader: true,
        }),
      );
    }
  }, [userId, activeTabIndex]);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateActiveNotificationTabIndex}
      tabMenu={getTabMenuConfig()}
    />
  );
};

export default NotificationTabs;
