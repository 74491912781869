import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface ListPolicySequencesQueryVariables {
  policyId: string | undefined;
}

export const LIST_POLICY_SEQUENCES = (policyId: string | undefined) => {
  if (!policyId) throw new Error('LIST_POLICY_SEQUENCES: policyId is required');

  const variables: ListPolicySequencesQueryVariables = { policyId };

  return {
    query: gql`
      query ($policyId: ID!) {
        listPolicySequences(policyId: $policyId) {
          id
          policy {
            id
          }
          documentType {
            id
            name
          }
          elements {
            provision {
              id
              name
              provisionCategory {
                color
              }
            }
            order
          }
        }
      }
    `,
    variables,
  };
};

export const listPolicySequences = createAsyncThunk(
  'policies/listPolicySequences',
  async ({ policyId }: { policyId: string | undefined }) => {
    const response = await client.query(LIST_POLICY_SEQUENCES(policyId));
    return response;
  },
);
