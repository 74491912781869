import { CValuationReport } from 'common/_classes/valuationReport2';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import { conditionalClassName } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { FilterDataType } from '.';
import DefaultFilter from './components/DefaultFilter';
import { RowAction, RowProps } from './components/RowTemplate/RowProps';

interface TableHeadProps {
  content: CValuationReport[];
  setContent: (content: CValuationReport[]) => void;
  onCancelColumn: (columnId: string) => void;
  filterData: FilterDataType;
  setFilterData: (filterData: FilterDataType) => void;
  onFilter: (filterData: FilterDataType) => void;
}

const TableHead = ({ content, setContent, onCancelColumn, filterData, setFilterData, onFilter }: TableHeadProps) => {
  /**
   * Column header for Contract or Benchmark name
   * @param item
   * @returns
   */
  const columnNameHeader = (item: CValuationReport): string | JSX.Element => {
    const label: string = item.identifier || item.id;
    if (item.isBenchmark) {
      // link to the benchmark
      return (
        <a
          className="benchmark-header-name"
          href={`/benchmarks/${item.id}/details`}
        >
          {label}
        </a>
      );
    } else {
      return label;
    }
  };

  const clearAllSortOrders = () => {
    const filterDataArray = Object.entries(filterData);

    const updatedWithSortOrder = filterDataArray.map(data => {
      const objKey = data[0];

      if (objKey === 'activeConfigId') return data;
      const value = data[1] as RowAction & RowProps;

      return [objKey, { ...value, sortOrder: undefined }];
    });

    setFilterData(Object.fromEntries(updatedWithSortOrder));
  };

  const onMoveLeft = (draggedItem: CValuationReport, index: number): void => {
    if (index === 1) return;

    clearAllSortOrders();

    const tempValuationReport: CValuationReport[] = [...content];
    // removed the dragged item
    tempValuationReport.splice(index, 1);

    // Added the item at drop location
    tempValuationReport.splice(index - 1, 0, draggedItem);
    setContent(tempValuationReport);
  };

  const onMoveRight = (draggedItem: CValuationReport, index: number): void => {
    if (content.length - 1 === index) return;

    clearAllSortOrders();

    const tempValuationReport: CValuationReport[] = [...content];
    // removed the dragged item
    tempValuationReport.splice(index, 1);

    // Added the item at drop location
    tempValuationReport.splice(index + 1, 0, draggedItem);
    setContent(tempValuationReport);
  };

  return (
    <thead>
      <tr>
        {/* 1st column, of the header, with the number of results */}
        <th>
          <div className="d-flex ">
            <span className="m-l-xs m-r-sm">({content.length - 1})</span>

            <DefaultFilter
              filterData={filterData}
              setFilterData={setFilterData}
              onFilter={onFilter}
            />
          </div>
        </th>

        {/* all other columns for the header with the names and badges*/}
        {content.map((item, index) => (
          <th
            key={`contract-name-${item.id}`}
            className="p-t-s p-b-s"
          >
            <div className="d-flex justify-center p-xs">
              <span className="m-r-xs">{columnNameHeader(item)}</span>

              {item.underNegotiation && (
                <Badge
                  badgeColor={BadgeColor.ORANGE}
                  className="m-r-xs"
                >
                  NEGOTIATION
                </Badge>
              )}

              {item.isBenchmark && (
                <Badge
                  badgeColor={BadgeColor.BLUE}
                  className="m-r-xs"
                >
                  Benchmark
                </Badge>
              )}
              {/* First contract column do not have the cross button*/}
              {index ? (
                <span
                  className="column-cancel"
                  onClick={() => onCancelColumn(item.id)}
                >
                  <Icon icon={Icons.Cross} />
                </span>
              ) : (
                <></>
              )}
            </div>
            {/* First contract column do not have arrow left or right*/}
            {index ? (
              <div className="move-icons">
                {index === 1 ? (
                  <></>
                ) : (
                  <span
                    className={`arrow p-xxs ${conditionalClassName(index === content.length - 1, 'm-r-auto m-l-auto ')}`}
                    onClick={() => onMoveLeft(item, index)}
                  >
                    <Icon icon={Icons.ArrowLeftDash} />
                  </span>
                )}

                {index === content.length - 1 ? (
                  <></>
                ) : (
                  <span
                    className={`arrow p-xxs m-l-auto ${conditionalClassName(index === 1, 'm-r-auto')}`}
                    onClick={() => onMoveRight(item, index)}
                  >
                    <Icon icon={Icons.ArrowRightDash} />
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
