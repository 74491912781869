import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import EntityInformationTab from 'views/entities/components/Tabs/EntityInformationTab';
import LandlordEntitiesDashboard from 'views/entities/landlordEntities/Dashboard';
import { updateOrganisationViewDetailsTab } from 'store/members/membersSlice';
import { EntityCreatorTypes, getEntity } from 'common/api/entities';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import TeamMembersTab from './Members';
import TeamRolesTab from './Roles';
import SubsidiariesTabs from './Subsidiaries';

export enum ORGANISATION_TABS_OFFSET {
  PROFILE = 0,
  SUBSIDIARIES = 1,
  TEAM = 2,
  ROLES = 3,
}

const getTabMenuConfig = (): TabMenuProps[] => {
  return [
    {
      key: '0',
      href: '?tab=profile',
      label: 'Organisation Profile',
      dataTest: 'nav-organisation-profile',
      customTab: (
        <EntityInformationTab
          organisation={true}
          type={EntityCreatorTypes.Landlord}
        />
      ),
    },
    {
      key: '1',
      href: '?tab=subsidiaries',
      label: 'Subsidiaries',
      dataTest: 'nav-subsidiaries',
      customTab: <SubsidiariesTabs />,
    },
    {
      key: '2',
      href: '?tab=team',
      label: 'Team',
      dataTest: 'nav-team',
      customTab: <TeamMembersTab />,
    },
    {
      key: '3',
      href: '?tab=roles',
      label: 'Roles & Access Rights',
      dataTest: 'nav-roles-access-rights',
      customTab: <TeamRolesTab />,
    },
  ];
};

const OrganisationTabs = () => {
  const { activeTabIndex } = useAppSelector((state: RootState) => state.members);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const id = getCurrentLandlordEntityId();
    if (id) {
      dispatch(getEntity({ type: EntityCreatorTypes.Landlord, id }));
    }
  }, [dispatch]);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateOrganisationViewDetailsTab}
      tabMenu={getTabMenuConfig()}
    />
  );
};

export default OrganisationTabs;
