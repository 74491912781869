import { Grid } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import { InputFieldType } from 'atoms/FormField/Input';
import InputField from 'atoms/FormField/Input';
import PhoneNumberField from 'atoms/FormField/PhoneInput';
import { deleteContact, updateContactInputKey } from 'store/entities/entityDetailSlice';
import { Contact } from 'utils/types/userManagement/adminEntities';
import { Icons } from 'utils/utils-icons';

interface ContactFormProps {
  contact: Contact;
  contacts: Contact[];
  index: number;
}

const ContactForm = ({ contacts, contact, index }: ContactFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { firstName, lastName, position, email, phone } = contact;

  const onPhoneNumberChange = (key: string, value: string, countryCode: string): void => {
    dispatch(
      updateContactInputKey({
        key: 'phone',
        value: '+' + value,
        index,
        dialCode: countryCode,
      }),
    );
  };

  const onChange =
    (index: number) =>
    (key: string, value: string): void => {
      dispatch(updateContactInputKey({ key, value, index }));
    };

  const onDelete = () => dispatch(deleteContact({ index }));

  return (
    <Grid className="m-t-m w-60">
      <Grid.Row className="p-none">
        <Grid.Column
          width="8"
          className="p-l-none"
        >
          <InputField
            label="First name"
            fieldKey="firstName"
            value={firstName || ''}
            placeholder="Enter first name..."
            dataTest="contact-first-name"
            onChange={onChange(index)}
            required={true}
          />

          <InputField
            label="Email"
            fieldKey="email"
            value={email || ''}
            placeholder="Enter email..."
            dataTest="contact-email"
            onChange={onChange(index)}
            required={true}
            type={InputFieldType.EMAIL}
          />

          <div data-test="contact-phone-number">
            <PhoneNumberField
              label="Phone number"
              fieldKey="phoneNumber"
              value={phone}
              onChange={onPhoneNumberChange}
              useCountryCode={true}
              dataTest="phone-number"
              placeholder="+852 8888 8888"
              countryCode="+852"
              className="m-t-m"
            />
          </div>
        </Grid.Column>

        <Grid.Column
          width="8"
          className="p-r-none"
        >
          <InputField
            label="Last name"
            fieldKey="lastName"
            value={lastName || ''}
            placeholder="Enter last name..."
            dataTest="contact-last-name"
            onChange={onChange(index)}
            required={true}
          />

          <InputField
            label="Job title/position"
            fieldKey="position"
            value={position || ''}
            placeholder="Enter job title..."
            dataTest="contact-job-title"
            onChange={onChange(index)}
            className="m-t-m"
          />

          {contacts.length !== 1 && (
            <div
              data-test="contact-delete-button"
              onClick={onDelete}
              role="button"
              tabIndex={0}
              className="d-inline-flex m-t-xl align-center p-xs cursor-pointer font-600 hover-bg-white-smoke"
              style={{ borderRadius: '0.5rem' }}
            >
              <Icon
                className="color-red-soft m-r-xs"
                icon={Icons.Delete}
              />
              <p className="color-red-soft">Delete this contact person</p>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ContactForm;
