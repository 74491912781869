import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getClientType } from 'utils/utils-authentication';
import { convertDateToISO } from 'utils/utils-date';

interface ExecuteListOfFormattersQueryVariables {
  context: string;
  contextId: string | undefined;
  landlordId: string | null;
  date: string | null;
  nodeIds: string[];
}

export const EXECUTE_LIST_OF_FORMATTERS = (
  context: string,
  contextId: string | undefined,
  date: string | null,
  nodeIds: string[],
) => {
  const variables: ExecuteListOfFormattersQueryVariables = {
    context,
    contextId,
    landlordId: getCurrentLandlordEntityId(),
    date,
    nodeIds,
  };

  return {
    query: gql`
      query ($context: ExecuteContext!, $contextId: ID!, $landlordId: ID, $date: Date!, $nodeIds: [ID]!) {
        executeListOfFormatters(
          context: $context
          contextId: $contextId
          landlordId: $landlordId
          date: $date
          nodeIds: $nodeIds
        ) {
          nodeId
          result
          error
        }
      }
    `,
    variables,
  };
};

export const executeListOfFormatters = createAsyncThunk(
  'formatters/executeListOfFormatters',
  async ({ context, contextId, nodeIds }: { context: string; contextId: string | undefined; nodeIds: string[] }) => {
    const date = convertDateToISO(new Date());
    const response = await getClientType().query(EXECUTE_LIST_OF_FORMATTERS(context, contextId, date, nodeIds));
    return response.data;
  },
);
