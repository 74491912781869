import { ChartType, ScriptableTooltipContext } from 'chart.js';

export const doughnutBoxTooltip = (context: ScriptableTooltipContext<ChartType>, id: string) => {
  // Tooltip Element
  let tooltipElement = document.getElementById(id);

  if (!tooltipElement) {
    let parentElement = document.getElementById('analytics-dashboard');
    tooltipElement = document.createElement('div');
    tooltipElement.id = id;
    tooltipElement.innerHTML = '<table></table>';
    if (parentElement) {
      parentElement.appendChild(tooltipElement);
    }
  }

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipElement.style.opacity = '0';
    return;
  }

  // Set caret Position
  tooltipElement.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipElement.classList.add(tooltipModel.yAlign);
  } else {
    tooltipElement.classList.add('no-transform');
  }

  let left, right;
  let innerHtml = '<div class="inner-html">';

  // Set Text
  if (tooltipModel.body) {
    const bodyLines = tooltipModel.body.map(({ lines }) => lines);

    bodyLines.forEach((body, i) => {
      const formatElement = body[0].split(':');
      const textBefore = formatElement[0];
      const textAfter = `${formatElement[1]}%`;
      const leased = textBefore === 'Leased Area';
      innerHtml += `<p class="first" index="${i + 1}"><span class="circle-xs circle ${
        leased ? 'bg-green' : 'bg-orange'
      }"></span> ${textBefore}: <span class="text-after">${textAfter}</span></p>`;

      if (textBefore === 'Available for Lease') {
        tooltipElement?.classList.add('availability');
        left = -35;
      } else {
        tooltipElement?.classList.remove('availability');
        left = -15;
      }
    });
    right = -65;
  }

  innerHtml += '</div>';

  let tableRoot = tooltipElement.querySelector('table');
  if (tableRoot) {
    tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();
  // Display, position, and set styles for font
  tooltipElement.style.opacity = '1';
  tooltipElement.style.position = 'absolute';

  let leftPosition = position.left + window.pageXOffset + tooltipModel.caretX + (left ? left : 0);

  const isRightEndOfScreen = leftPosition + tooltipElement.clientWidth + 55 >= window.innerWidth;

  if (isRightEndOfScreen) {
    leftPosition -= tooltipElement.clientWidth;
  }

  tooltipElement.style.left = leftPosition + 'px';

  tooltipElement.style.top = position.top + window.pageYOffset + tooltipModel.caretY + (right ? right : 0) + 'px';
  tooltipElement.style.pointerEvents = 'none';
};
