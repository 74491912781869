import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CPageInfo, PolicyListingClone } from 'common/_classes';
import { RootState } from 'store';
import { PaginationAPIProps } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { checkIfAdminURL } from 'utils/utils-admin';
import { getClientType } from 'utils/utils-authentication';

interface ListPoliciesQueryVariables {
  currentLandlordEntityId: string | null;
  first: number | undefined;
  last: number | undefined;
  after: string | undefined;
  before: string | undefined;
  search: string | null;
}

export const LIST_POLICIES = (
  searchValue: string | null,
  first: number | undefined,
  last: number | undefined,
  after: string | undefined,
  before: string | undefined,
) => {
  const isAdmin = checkIfAdminURL();

  const AddParamIfNotAdmin = (param: string) => (isAdmin ? '' : param);

  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!isAdmin && !currentLandlordEntityId) throw new Error('LIST_POLICIES: currentLandlordEntityId is required');

  const variables: ListPoliciesQueryVariables = {
    currentLandlordEntityId,
    first: first,
    last: last,
    after: after,
    before: before,
    search: searchValue,
  };

  return {
    query: gql`
      query (
        ${AddParamIfNotAdmin('$currentLandlordEntityId: ID!')}
        $first: Int
        $last: Int
        $after: String
        $before: String
        $search: String
      ) {
        listPolicies(
          ${AddParamIfNotAdmin('currentLandlordEntityId: $currentLandlordEntityId')}
          first: $first
          last: $last
          after: $after
          before: $before
          filter: { search: $search }
        ) {
          edges {
            cursor
            node {
              ${PolicyListingClone.fragments()}
            }
          }
          pageInfo {
            ${CPageInfo.fragments()}
          }
        }
      }
    `,
    variables,
  };
};

export const listPolicies = createAsyncThunk(
  'policies/listPolicies',
  async ({ first, last, after, before }: PaginationAPIProps, { getState }) => {
    const state = getState() as RootState;
    const {
      policiesListing: { search },
    } = state;

    const response = await getClientType().query(LIST_POLICIES(search, first, last, after, before));
    return response.data;
  },
);
