import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import PhotoInput from 'atoms/FormField/PhotoInput';
import { deletePhoto, deleteSelectedPhoto } from 'store/properties/propertyDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { prepPropertyPhotoForUpload } from 'common/api/properties';
import { Icons } from 'utils/utils-icons';

const PropertyPhoto = ({ mode }: ModeProps) => {
  const { activeProperty, deletePreviousPhoto, uploadUrl } = useAppSelector((state: RootState) => state.propertyDetail);

  return (
    <PhotoInput
      icon={Icons.Building}
      disabled={mode === MODE_OPTIONS.READ}
      src={activeProperty.photo}
      clearUploadedPhoto={uploadUrl ? false : true}
      prepUpload={prepPropertyPhotoForUpload}
      deleteSelectedPhoto={deleteSelectedPhoto}
      deletePhoto={deletePhoto}
      deletePreviousPhoto={deletePreviousPhoto}
    />
  );
};

export default PropertyPhoto;
