import { useState } from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import SectionComponent from 'layouts/SectionComponent';
import TabContainer from 'components/TabContainer';
import { getProfile, updateAdminProfile, updateProfile } from 'common/api/authentication';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { checkIfAdminURL } from 'utils/utils-admin';
import { userInfoValidation } from 'utils/utils-credential';
import DetailsForm from './DetailsForm';
import PhotoInputWrapper from './PhotoInputWrapper';

const Content = ({ mode }: ModeProps) => {
  return (
    <Form>
      <SectionComponent>
        <Grid>
          <Grid.Row className="pm-none">
            <Grid.Column
              width={2}
              className="pm-none"
            >
              <h4 className="section-title">Profile Image</h4>
              <PhotoInputWrapper isDisabled={mode === MODE_OPTIONS.READ} />
            </Grid.Column>
            <Grid.Column
              width={1}
              className="pm-none"
            ></Grid.Column>
            <Grid.Column width={13}>
              <h4 className="section-title">Details</h4>
              <DetailsForm mode={mode} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </SectionComponent>
    </Form>
  );
};

const InformationTab = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const isAdmin = checkIfAdminURL();
  const [mode, setMode] = useState<MODE_OPTIONS>(MODE_OPTIONS.READ);
  const { firstName, lastName, email } = useAppSelector((state: RootState) => state.auth);

  /**
   * On Save button Click
   */
  const onSave = (): void => {
    dispatch(isAdmin ? updateAdminProfile() : updateProfile());
  };

  /**
   * On Cancel button Click
   */
  const onCancel = (): void => {
    dispatch(getProfile());
  };

  return (
    <TabContainer
      tabTitle={'Information'}
      tabToolbar={
        <DetailsControlButtons
          mode={mode}
          setMode={setMode}
          checkDisabled={userInfoValidation(firstName, lastName, email)}
          onCancel={onCancel}
          onSave={onSave}
        />
      }
      mode={mode}
      data-test="profile-info-tab"
    >
      {/* Mode prop is present */
      /* @ts-ignore */}
      <Content />
    </TabContainer>
  );
};

export default InformationTab;
