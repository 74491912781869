import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterListingClone } from 'common/_classes';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';

const FIRST: number = 1000;

interface ListParametersQueryVariables {
  sortOrder: SortOrder;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  search: string;
  groupId: string;
}

export const LIST_PARAMETERS = (
  searchValue: any,
  selectedGroup: any,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
  choices?: boolean,
) => {
  const variables: ListParametersQueryVariables = {
    sortOrder: SortOrder.Asc,
    first: first || FIRST,
    last,
    after,
    before,
    search: searchValue,
    groupId: selectedGroup?.id,
  };

  return {
    query: gql`
      query (
        $sortOrder: SortOrder!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $search: String
        $groupId: ID
      ) {
        listParameters(
          sortOrder: $sortOrder
          first: $first
          last: $last
          after: $after
          before: $before
          parameterFilter: { search: $search, groupId: $groupId }
        ) {
          edges {
            node {
              ${ParameterListingClone.fragments(choices)}
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listParameters = createAsyncThunk(
  'parameters/listParameters',
  async ({ first, last, after, before }: any, { getState }) => {
    const {
      parametersListing: { search },
      parameterDetail: { paramViewSelectedGroup },
    }: any = getState() as RootState;
    const response = await adminClient.query(
      LIST_PARAMETERS(search, paramViewSelectedGroup, first, last, after, before),
    );
    return response;
  },
);
