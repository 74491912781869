import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetPropertyQueryVariables {
  currentLandlordEntityId: string;
  propertyId: string;
}

const GET_PROPERTY = (id: string) => {
  if (!id) {
    throw new Error('Get Property: id is required');
  }
  const currentLandlordEntityId = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) {
    throw new Error('Get Property: currentLandlordEntityId is required');
  }

  const variables: GetPropertyQueryVariables = {
    currentLandlordEntityId,
    propertyId: id,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $propertyId: ID!) {
        getProperty(currentLandlordEntityId: $currentLandlordEntityId, propertyId: $propertyId) {
          id
          entity {
            id
            name
          }
          photo
          buildingName
          buildingIdentifier
          buildingNumberOfFloors
          buildingNumberOfUndergroundFloors
          area {
            gross
            grossDescription
            net
            netDescription
            lettable
            lettableDescription
            saleable
            saleableDescription
          }
          type
          lotNumber
          buildingGrade
          address
          description
          identification
          measurementUnit
          measurementMethod
          documents {
            id
            name
            public
            url
            path
          }
          floors {
            id
            name
            index
            comments
            characteristics
            ceilingHeight
            floorLoading
            activationDate
            deactivationDate
            spaces {
              id
              name
              registeredName
              type
              usedForOccupancyRate
              grossArea
              netArea
              lettableArea
              saleableArea
              efficiencyRatio
              comments
              characteristics
              activationDate
              deactivationDate
            }
            floorPlans {
              id
              reviewFlag
              floorPlan {
                id
                name
                path
                public
                url
              }
              revisionDate
              unitsNumber
              whollyOwned
              grossArea
              netArea
              lettableArea
              saleableArea
              efficiencyRatio
              insertedAt
            }
          }
          reviewFlag
          reviewStatus {
            date
            user {
              id
              firstName
              lastName
            }
          }
          developmentName
          developmentLotNumber
          addressDistrict
          buildingAddressDistrict
          hasCentralAc
          hasCurtainWall
          hasMetroCoveredAccess
        }
      }
    `,
    variables,
  };
};

interface GetPropertyArgs {
  id: string;
}

export const getProperty = createAsyncThunk('properties/getProperty', async ({ id }: GetPropertyArgs) => {
  const response = await client.query(GET_PROPERTY(id));
  return response;
});
