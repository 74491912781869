import { useEffect, useState } from 'react';
import { last } from 'lodash';
import { ParameterDropdown } from 'components/ParameterMultiLevelSelect/ParameterDropdown';
import { GROUP_TYPE, OptionItem, TARGET_GROUPS } from '../../utils/types/categories';
import { ParameterInput } from './ParameterInput';
import {
  flatOptions,
  getNewOptionsMenuSet,
  handleResult,
  searchDropdown,
  substitute,
  withSelectedOptionCreatePath,
} from './tools';
import './ParameterMultiLevelSelect.scss';

/**
 * This is the Main file for dropdown multilevel.
 *
 * @param props.dropdown Array | Options list with suboptions to show on dropdown
 * @param props.onChange Function | Callback for on Change value
 * @param props.placeholder string | Input placeholder
 *
 */

interface ParameterMultiLevelSelectProps {
  onChange?: (result: OptionItem[]) => void;
  dropdown: OptionItem[];
  placeholder?: string;
  currentValue?: {
    id: string;
    label?: string;
    value?: string;
  };
}

const ParameterMultiLevelSelect = (props: ParameterMultiLevelSelectProps): JSX.Element => {
  const { dropdown, onChange, placeholder, currentValue, ...rest } = props;

  const [searching, setSearching] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [optionMenu, setOptionMenu] = useState<OptionItem[][]>([dropdown]);
  const [lastOptionMenu, setLastOptionMenu] = useState<OptionItem[]>([]);

  useEffect(() => {
    if (currentValue) {
      const { id, label, value } = currentValue;
      const flatDropdown = flatOptions(dropdown);
      const found = flatDropdown.find(({ id: flatId }) => flatId === id);
      if (!found) return;

      const newOptionMenu = withSelectedOptionCreatePath(dropdown, found);

      if (label && value) {
        const lastItem = last(newOptionMenu);
        if (lastItem) {
          const valueItem: OptionItem = {
            ...lastItem,
            group: GROUP_TYPE.VALUES,
            custom: { value: value || '', label: label || '' },
          };
          setLastOptionMenu([...newOptionMenu, valueItem]);
        }
      } else {
        setLastOptionMenu(newOptionMenu);
      }

      const newOptionSet = getNewOptionsMenuSet(newOptionMenu, dropdown);
      setOptionMenu(newOptionSet);
    } else {
      setOptionMenu([dropdown]);
      setLastOptionMenu([]);
    }
  }, [currentValue]);

  const handleClickOutsideMenu = (event: MouseEvent): void => {
    const allowedClasses = ['title', 'item', 'go-back'];
    const { className, tagName } = event.target as HTMLElement;
    if (tagName === 'svg') {
      const allowedSvgClasses = ['go-back-icon', 'go-right-icon'];
      if (
        // @ts-ignore
        className.baseVal.includes(allowedSvgClasses[0]) ||
        // @ts-ignore
        className.baseVal.includes(allowedSvgClasses[1])
      ) {
        return;
      } else {
        setShowDropdown(false);
      }
    } else if (tagName === 'INPUT') {
      const parentElem = (event.target as HTMLInputElement).parentElement as HTMLElement;
      const classesParent = parentElem.className;
      if (!classesParent.includes('index-input')) {
        setShowDropdown(false);
      }
    } else if (!allowedClasses.includes(className)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu, true);
    };
  }, []);

  const handleGoBack = () => {
    const lastOption = last(lastOptionMenu);

    if (!lastOption) return;

    setLastOptionMenu(lastOptionMenu.splice(0, lastOptionMenu.length - 1));

    if (lastOption.group !== GROUP_TYPE.VALUES) {
      setOptionMenu(optionMenu.splice(0, optionMenu.length - 1));
    }
  };

  const handleSelect = (selected: OptionItem): void => {
    if (searching) {
      const newOptionMenu = withSelectedOptionCreatePath(dropdown, selected);

      setSearching('');
      setLastOptionMenu(newOptionMenu);

      const newOptionSet = getNewOptionsMenuSet(newOptionMenu, dropdown);
      setOptionMenu(newOptionSet);

      if (TARGET_GROUPS.includes(selected.group)) {
        setShowDropdown(false);
        onChange && onChange(handleResult(newOptionMenu));
      }
    } else {
      setSearching('');

      const hasTable = lastOptionMenu.find(({ group }) => group === GROUP_TYPE.TABLES);

      const optionsSelected = lastOptionMenu.filter(({ group }) => group !== GROUP_TYPE.VALUES);

      if (hasTable && selected.group === GROUP_TYPE.VALUES) {
        setShowDropdown(false);
        setLastOptionMenu(substitute(selected, lastOptionMenu));
        onChange && onChange(substitute(selected, lastOptionMenu));
      } else if (!hasTable && selected.group === GROUP_TYPE.PARAMETERS) {
        setShowDropdown(false);
        setLastOptionMenu(substitute(selected, lastOptionMenu));
        onChange && onChange(substitute(selected, lastOptionMenu));
      } else {
        const { group, children } = selected;
        if (group !== GROUP_TYPE.VALUES && children && children.length) {
          const lastOptions = lastOptionMenu.filter(
            ({ group }) => group !== GROUP_TYPE.PARAMETERS && group !== GROUP_TYPE.VALUES,
          );
          setLastOptionMenu([...lastOptions, selected]);
          setOptionMenu([...optionMenu, children]);
        } else if (group === GROUP_TYPE.PARAMETERS) {
          setLastOptionMenu(substitute(selected, optionsSelected));
        }
      }
    }
  };

  const options: OptionItem[] = searchDropdown(searching, dropdown) || optionMenu[optionMenu.length - 1];

  return (
    <div
      className="parameter-multi-level-select"
      data-test="parameter-multi-level-select"
      {...rest}
    >
      <ParameterInput
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        searching={searching}
        onSearching={setSearching}
        lastOptionMenu={lastOptionMenu}
        placeholder={placeholder}
      />

      <ParameterDropdown
        allOptionMenu={optionMenu}
        optionMenu={options}
        showDropdown={showDropdown}
        dataTest="parameter-multi-level-select-dropdown"
        lastOptionMenu={lastOptionMenu}
        onSelect={handleSelect}
        onGoBack={handleGoBack}
      />
    </div>
  );
};

export default ParameterMultiLevelSelect;
