import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import MemberStatus from 'common/model/MemberStatus';
import User from 'common/model/User';
import { listUsers } from 'common/api/adminUsers';

const newUser = {
  id: '',
  photo: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: null,
  userType: '',
  created: '',
  status: MemberStatus.Valid,
};

const initialState = {
  usersList: [] as User[],
  activeUser: { ...newUser },
  isLoading: false,
  isSearching: false,
  search: null,
  pageInfo: [],
};

const adminUsers = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    updateSearch: (state, { payload }) => {
      state.search = payload;
    },
    updateInput: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.activeUser[key] = value;
    },
    discardUserCreation: state => {
      state.activeUser = { ...newUser };
    },
    createNewUser: state => {
      // @ts-ignore
      state.activeUser.id = Math.floor(Math.random() * (1000000 - 2 + 1)) + 2;
      state.activeUser.created = '27.05.2022';
      // @ts-ignore
      state.usersList = [...state.usersList, state.activeUser];
      state.activeUser = { ...newUser };
      toast.success('New user has been successfully added.');
    },
    onEditDetails: (state, action) => {
      const { id } = action.payload;
      // @ts-ignore
      state.activeUser = { ...state.usersList.find(user => user.id === id) };
    },
    inviteUser: (state, action) => {
      const { id } = action.payload;
      const index = state.usersList.findIndex(user => user.id === id);
      const user = { ...state.usersList[index], status: MemberStatus.Invited };
      state.usersList[index] = user;
      toast.success('User Invite sent successfully.');
    },
    blockUser: (state, action) => {
      const { id } = action.payload;
      const index = state.usersList.findIndex(user => user.id === id);
      const user = { ...state.usersList[index], status: '' };
      state.usersList[index] = user;
      toast.success('User blocked successfully.');
    },
    editUser: state => {
      const { id } = state.activeUser;
      const index = state.usersList.findIndex((user: User) => user.id === id);
      if (index > -1) {
        // @ts-ignore
        state.usersList[index] = state.activeUser;
      }
      state.activeUser = { ...newUser };
      toast.success('User edited successfully.');
    },
  },
  extraReducers: builder => {
    //listUsers
    builder.addCase(listUsers.pending, (state, action) => {
      const { searchValue } = action.meta.arg;
      if (searchValue || searchValue === '') {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listUsers.fulfilled, (state, action) => {
      const listUsers = action.payload.data.listUsers;
      state.usersList = listUsers.edges.map((element: any) => element.node);
      state.pageInfo = listUsers.pageInfo;
      state.search = null;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error);
      toast.error('listUsers API request rejected');
    });
  },
});

export const {
  updateInput,
  createNewUser,
  onEditDetails,
  inviteUser,
  blockUser,
  editUser,
  discardUserCreation,
  updateSearch,
} = adminUsers.actions;

export default adminUsers.reducer;
