import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { LIST_LANDLORD_AND_TENANT_ENTITIES } from '../entities';

export const listTenantsAndAutoSelectFirstTenant = createAsyncThunk(
  'transactions/listTenantsAndAutoSelectFirstTenant',
  async () => {
    const response = await client.query(LIST_LANDLORD_AND_TENANT_ENTITIES());
    return response;
  },
);
