import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface GetAutomatedTransactionIdentifierQueryVariables {
  propertyId: string | undefined;
  tenantId: string | undefined;
}

const GET_AUTOMATED_TRANSACTION_IDENTIFIER = (tenantId: string | undefined, propertyId: string | undefined) => {
  if (!tenantId) throw new Error('Get Automated Transaction Identifier: tenantId is required');
  if (!propertyId) throw new Error('Get Automated Transaction Identifier: propertyId is required');

  const variables: GetAutomatedTransactionIdentifierQueryVariables = {
    propertyId: propertyId,
    tenantId,
  };

  return {
    query: gql`
      query ($propertyId: ID!, $tenantId: ID!) {
        getAutomatedTransactionIdentifier(propertyId: $propertyId, tenantId: $tenantId) {
          automatedTransactionIdentifier
        }
      }
    `,
    variables,
  };
};

export const getAutomatedTransactionIdentifier = createAsyncThunk(
  'transactions/getAutomatedTransactionIdentifier',
  async ({ tenantId, propertyId }: { tenantId: string | undefined; propertyId: string | undefined }) => {
    const response = await client.query(GET_AUTOMATED_TRANSACTION_IDENTIFIER(tenantId, propertyId));
    return response;
  },
);
