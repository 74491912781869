import {
  addMonths,
  addQuarters,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInQuarters,
  differenceInYears,
  format,
  getDaysInMonth,
  getDaysInYear,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import _groupBy from 'lodash/groupBy';
import { OccupancyType } from '.';
import { DataType, data } from './data';

const fixedSectionWidth = 100;
const dayWidthInMonth = fixedSectionWidth / 30;
const dayWidthInQuarter = fixedSectionWidth / 90;
const dayWidthInYear = fixedSectionWidth / 360;

const sectionHeight = 40;

const floors = data.floors;

type Floor = (typeof floors)[number];

const getColorToFill = (type: string) => {
  if (type === 'tenancy') {
    return '#0FB239';
  } else if (type === 'assumed') {
    return '#F0F0F0';
  }
  return '#CFCFCF';
};

const config = {
  monthly: {
    dayWidth: dayWidthInMonth,
    addSeparation: (date: Date, separation: number) => addMonths(date, separation),
    diffInSeparations: (date1: Date, date2: Date) => differenceInMonths(date1, date2),
    startOfSeparation: (date: Date) => startOfMonth(date),
    getDaysInSeparation: (date: Date) => getDaysInMonth(date),
    headerFormat: 'MMM yyyy',
  },
  quarterly: {
    dayWidth: dayWidthInQuarter,
    addSeparation: (date: Date, separation: number) => addQuarters(date, separation),
    diffInSeparations: (date1: Date, date2: Date) => differenceInQuarters(date1, date2),
    startOfSeparation: (date: Date) => startOfQuarter(date),
    getDaysInSeparation: (date: Date) => getDaysInMonth(date) * 3,
    headerFormat: 'QQQ yyyy',
  },
  yearly: {
    dayWidth: dayWidthInYear,
    addSeparation: (date: Date, separation: number) => addYears(date, separation),
    diffInSeparations: (date1: Date, date2: Date) => differenceInYears(date1, date2),
    startOfSeparation: (date: Date) => startOfYear(date),
    getDaysInSeparation: (date: Date) => getDaysInYear(date),
    headerFormat: 'yyyy',
  },
};

const mapDataOccupanciesToInfo = (floors: Floor[]) => {
  const flattenedInfo = floors.flatMap(floor =>
    floor.units.flatMap(unit =>
      Object.entries(
        _groupBy(
          unit.occupancies.map(occupancy => ({
            type: occupancy.type,
            startDate: occupancy.startDate,
            endDate: occupancy.endDate,
            label: occupancy.label,
          })),
          'type',
        ),
      ).map(([type, occupancies]) => ({
        floor: floor.floorNumber,
        floorLabel: floor.label,
        unit: unit.unitNumber,
        unitLabel: unit.label,
        type,
        occupancies,
      })),
    ),
  );

  return flattenedInfo;
};

const renderFloor = (mappedInfo: ReturnType<typeof mapDataOccupanciesToInfo>) => {
  return (
    <div style={{}}>
      <div
        style={{
          display: 'flex',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: fixedSectionWidth,
            flex: '0 0 auto',
            height: sectionHeight,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Floor
        </div>
        <div
          style={{
            width: fixedSectionWidth,
            flex: '0 0 auto',
            height: sectionHeight,
            borderRight: '1px solid gray',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Unit
        </div>
      </div>
      {mappedInfo.map(info => {
        return (
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              key={info.floor + info.unit + info.type}
              style={{
                width: fixedSectionWidth,
                height: sectionHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {info.floorLabel}
            </div>
            <div
              style={{
                width: fixedSectionWidth,
                height: sectionHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRight: '1px solid gray',
              }}
            >
              {info.unitLabel}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const renderTimeline = (
  mappedInfo: ReturnType<typeof mapDataOccupanciesToInfo>,
  mode: 'monthly' | 'quarterly' | 'yearly',
) => {
  const { dayWidth, addSeparation, diffInSeparations, headerFormat, startOfSeparation, getDaysInSeparation } =
    config[mode];

  const timelineStart = startOfSeparation(new Date(data.timelineStart));
  const timelineEnd = startOfSeparation(new Date(data.timelineEnd));

  const separations = diffInSeparations(timelineEnd, timelineStart);

  const headerDates = new Array(separations + 1).fill(0).map((_, i) => addSeparation(timelineStart, i));

  return (
    <div
      style={{
        overflow: 'auto',
        overflowY: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          position: 'relative',
        }}
      >
        {headerDates.map(headerDate => (
          <div
            key={headerDate.toISOString()}
            style={{
              width: dayWidth * getDaysInSeparation(headerDate),
              height: sectionHeight,
              borderRight: '1px solid black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flex: '0 0 auto',
              borderBottom: '1px solid gray',
            }}
          >
            {format(headerDate, headerFormat)}
          </div>
        ))}
      </div>
      <div style={{ position: 'relative' }}>
        {mappedInfo.map((info, infoIndex) => {
          return info.occupancies.map((occupancy, occupancyIndex) => {
            return (
              <div
                style={{
                  position: 'absolute',
                  top: sectionHeight * infoIndex,
                  left: dayWidth * differenceInDays(new Date(occupancy.startDate), timelineStart),
                  width: dayWidth * (differenceInDays(new Date(occupancy.endDate), new Date(occupancy.startDate)) + 1),
                  height: sectionHeight,
                  backgroundColor: getColorToFill(occupancy.type),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {occupancy.label}
              </div>
            );
          });
        })}
        {/* Today line */}
        <div
          style={{
            height: sectionHeight * (mappedInfo.length + 1),
            position: 'absolute',
            top: 0,
            left: dayWidth * differenceInDays(new Date(), timelineStart),
            width: '1px',
            backgroundColor: 'black',
          }}
        ></div>
      </div>
    </div>
  );
};

const Graph = ({
  data,
  selectedOption,
  typesToShow,
}: {
  data: DataType;
  selectedOption: 'monthly' | 'quarterly' | 'yearly';
  typesToShow: OccupancyType[];
}) => {
  const mappedInfo = mapDataOccupanciesToInfo(floors).filter(info => typesToShow.includes(info.type as OccupancyType));

  return (
    <div
      style={{
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        position: 'relative',
      }}
    >
      {renderFloor(mappedInfo)}
      {renderTimeline(mappedInfo, selectedOption)}
    </div>
  );
};

export default Graph;
