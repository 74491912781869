import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { CFormatterDetail, IFormatterDetail } from 'common/_classes';
import { RootState } from 'store';
import { ReviewStatusMutationType } from 'common/api/miscellaneous';
import { getReviewFlagAndReviewStatus } from 'utils/tsHelper';
import { replaceDoubleSlash } from 'utils/utils-string';

interface ParamInterface {
  name: string | null;
  description: string | null;
  example: string | null;
  function: string | null;
  inputs: { label: string; type: string }[];
  reviewFlag: boolean;
  reviewStatus: ReviewStatusMutationType;
}

interface UpdateFormatterQueryVariables {
  id: string;
  params: ParamInterface;
}

const UPDATE_FORMATTER = (
  activeFormatter: IFormatterDetail,
  reviewFlag: boolean,
  reviewStatus: ReviewStatusMutationType,
) => {
  const { id, name, description, example, inputs } = activeFormatter;

  if (!id) {
    throw new Error('Update Formatter: Formatter ID could not be determined. Query aborted.');
  }

  if (reviewStatus.date && !reviewStatus.userId) {
    throw new Error('Update Formatter: Reviewer does not exist please reselect review box. Query aborted.');
  }

  const params = {
    name,
    description,
    example,
    function: replaceDoubleSlash(activeFormatter.function),
    inputs: JSON.parse(JSON.stringify(inputs)), // deep copy as activeFormatter.inputs is a reference as is readonly
    reviewFlag,
    reviewStatus,
  };

  const variables: UpdateFormatterQueryVariables = {
    id,
    params,
  };

  return {
    mutation: gql`
      mutation ($id: ID!, $params: FormatterInput!) {
        updateFormatter(id: $id, params: $params) {
          ${CFormatterDetail.fragment()}
        }
      }
    `,
    variables,
  };
};

export const updateFormatter = createAsyncThunk('formatters/updateFormatter', async (_args, { getState }) => {
  const {
    formatterDetail: { activeFormatter, activeFormatterFrozenCopy },
  } = getState() as RootState;
  const [reviewFlag, reviewStatus] = getReviewFlagAndReviewStatus(activeFormatter, activeFormatterFrozenCopy);
  const response = await adminClient.mutate(UPDATE_FORMATTER(activeFormatter, reviewFlag, reviewStatus));
  return response;
});
