import { Editor } from 'tinymce';
import { ContentNodeType } from 'common/api/nodes';

enum CASE_TYPE {
  LOWERCASE_ASCII_NUMBER = 96,
  UPPERCASE_ASCII_NUMBER = 64,
}
const NUMBER_OF_LETTERS = 26;

// Get the repeating number of index
// For level 3 the numbers are following the pattern:
//   a-> b → c → d → … → y → z
//   and then it should be
//   aa → bb → cc → …. → yy → zz
//   and then it should be
//   aaa → bbb → ccc → …. → yyy → zzz
// For level 5, the numbers are following the same pattern but in capital letters
// The pattern is done by dividing the index by 26 (number of letters in the alphabet),
// which gives us the number of repetition to apply.
const getRepeatingNumber = (index: number, caseType: CASE_TYPE): string => {
  // Check instance of number repetition
  // ex. index -> 27 then the second repetion has started as 1-26 is the first
  const numberOfRepeats: number = Math.ceil(index / NUMBER_OF_LETTERS);
  if (numberOfRepeats > 1) {
    // Get character number after removing repetition
    // ex. 27 - 26*(2-1) -> 1 -> which means 'a'
    index = index - NUMBER_OF_LETTERS * (numberOfRepeats - 1);
  }
  // Get ASCII values with case type
  let stringIndex: string = `${String.fromCharCode(index + caseType)}`;
  if (numberOfRepeats > 1) {
    // Repeat the number n times ex. AA, AAA
    stringIndex = stringIndex.repeat(numberOfRepeats);
  }

  return `(${stringIndex})`;
};

const formatClauseNumbering = (indexes: Array<number>): string => {
  if (indexes.length === 1) {
    return `${indexes[0]}.`;
  } else if (indexes.length === 2) {
    return indexes.join('.');
  } else if (indexes.length === 3) {
    return getRepeatingNumber(indexes[2], CASE_TYPE.LOWERCASE_ASCII_NUMBER);
  } else if (indexes.length === 4) {
    return `(${romanize(indexes[3]).toLowerCase()})`;
  } else if (indexes.length === 5) {
    return getRepeatingNumber(indexes[4], CASE_TYPE.UPPERCASE_ASCII_NUMBER);
  } else if (indexes.length === 6) {
    return `(${romanize(indexes[5])})`;
  }
  // default case, for now
  return indexes.join('.');
};

/**
 * Check the computeAllIndexes function
 *
 * This is a depth-first recursive algorithm to compute the index of
 * each Clause node whenever a Clause is created, deleted or
 * updated (position or hierarchy)
 */
const computeIndex = (node: Element, indexes: Array<number> = [], position: number = 0): void => {
  // VAR indexes SHOULD NOT BE UPDATED! ALWAYS DO A COPY
  const indexesCopy = [...indexes];

  // Add the new index
  indexesCopy.push(position + 1);

  // Check if the current node is a Clause
  if (node.getAttribute('data-node-type') === ContentNodeType.CLAUSE) {
    // Get index number
    const dataIndex = node.querySelector('div[data-index]');
    if (dataIndex) {
      // Add numbering separated by dot
      let formattedIndex = formatClauseNumbering(indexesCopy);
      // Check if there are tag inside of the Index.
      // If has then update the number but keep the tags
      // Check if condition tag is present
      if (dataIndex.innerHTML.indexOf('<') >= 0) {
        const regex = /([a-zA-Z\(\)]+\)|[\d.\d])+</;
        // Replace any line breaks
        const inner = dataIndex.innerHTML.replace(/(\r\n|\n|\r)/gm, '');
        // Update with index
        const newInnerHTML = inner.replace(regex, formattedIndex + '<');
        // Finally update with the formatted index
        dataIndex.innerHTML = newInnerHTML;
      } else {
        // If no tag just replace
        dataIndex.innerHTML = formattedIndex;
      }
    }
  }

  // Get the Node ID
  const nodeId = node.getAttribute('data-node-id');

  // Do a query to select all Clause's children from current Clause Node
  const nodeChildrenQuery = `div[data-node-id="${nodeId}"] > div[data-node-type="clause"]`;
  const children = Array.from(node.querySelectorAll(nodeChildrenQuery));

  // For each child, trigger a recursive function with previous values to update their indexes
  children.forEach((childrenNode, indexPosition) => {
    computeIndex(childrenNode, indexesCopy, indexPosition);
  });
};

/**
 * Convert the number int to a roman letter
 */
const romanize = (original: number): string => {
  if (original < 1 || original > 3999) {
    throw new Error('Error: Input integer limited to 1 through 3,999');
  }

  const numerals = [
    ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
    ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
    ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
    ['M', 'MM', 'MMM'], // 1000-3000
  ];

  const digits = Math.round(original).toString().split('');
  let position = digits.length - 1;

  return digits.reduce((roman, digit) => {
    if (digit !== '0') {
      roman += numerals[position][parseInt(digit) - 1];
    }
    position -= 1;

    return roman;
  }, '');
};

/**
 * Start of Clause Numbering
 *
 * This function triggers the compute index to update every clause numbering
 */
export const computeAllIndexes = (editor: Editor) => {
  // Select first level clauses ex. 1, 2, 3
  const baseClauses = Array.from(editor.dom.select('body > div[data-node-id]'));

  // The clauses are only first level relative to the 'body' element
  // Deeper clauses (level 2, 3, ...) are numbering by computeIndex in another moment
  for (let i = 0; i < baseClauses.length; i++) {
    const clause = baseClauses?.at(i);
    if (clause) {
      // Init of vars
      // clause: current clause
      // []: Init of sequece, it will determine the output 1.2.5 value
      // i: current position. It places the number 5 on the previous output
      computeIndex(clause, [], i);
    }
  }
};

/**
 * This function is used for Policy Page
 *
 * Very similar to computeAllIndexes function
 *
 * I tried to overload the "computeAllIndexes" function to accept a "Document"
 * type as well, but that didn't work, the TinyMCE editor stopped loading
 * and an HTML parsing error appeared in the console. Fixing this issue
 * needs some investigation, it can be listed as a technical dept. This
 * method receive a Document and return a String with all Clauses Updated
 *
 */
export const computeAllIndexesForDocument = (document: Document) => {
  // Get all elements other than clauses from level 1 of body content
  const pWrong = document.querySelectorAll('body > div:not([data-node-id])');
  const pWrongHTML = Array.from(pWrong);

  // Formating here. Updated the wrapper
  pWrongHTML.map(element => {
    element.outerHTML = `<p>${element.innerHTML}</p>`;
  });

  // Get all first level Clause
  const baseClauses: NodeListOf<HTMLElement> = document.querySelectorAll(
    'body > div[data-node-id][data-node-type="clause"], body > div[data-node-id][data-node-type="section"]',
  );
  let index: number = 0;
  for (let i = 0; i < baseClauses.length; i++) {
    const clause = baseClauses[i];

    if (clause) {
      // when encoutering a section, make
      // sure to restart the index numbering
      if (clause.dataset.nodeType === ContentNodeType.SECTION) {
        index = 0;
        continue;
      }
      // Init of vars
      // clause: current clause
      // []: Init of sequece, it will determine the output 1.2.5 value
      // i: current position. It places the number 5 on the previous output
      computeIndex(clause, [], index);
      index++;
    }
  }

  return document.body.innerHTML;
};

export default computeAllIndexes;
