import TableReactPrime, { CustomColumnProps } from 'atoms/TableReactPrime';
import { ApprovalRow } from '../..';
import ActionButtons from '../../ActionButtons';

const ApprovalItemResponseTable = ({ row }: { row: ApprovalRow }) => {
  const changesColumnConfig = (): CustomColumnProps[] => {
    return [
      {
        field: 'parameterGroupName',
        header: 'Parameter Group',
        filterPlaceholder: 'Search by Group Name',
        filter: true,
        filterField: 'parameterGroupName',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        field: 'parameterName',
        header: 'Parameter',
        filter: true,
        filterPlaceholder: 'Search by Parameter',
        filterField: 'parameterName',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        field: 'previousValue',
        header: 'Previous Value',
        filter: true,
        filterPlaceholder: 'Search by Previous Value',
        filterField: 'previousValue',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        field: 'newValue',
        header: 'New Value',
        filter: true,
        filterPlaceholder: 'Search by New Value',
        filterField: 'newValue',
        sortable: true,
        dataType: 'text',
        mandatory: true,
      },
      {
        mandatory: true,
        field: 'options',
        header: 'Actions',
        frozen: true,
        alignFrozen: 'right',
        body: () => (
          <ActionButtons
            row={row}
            reviewer={false}
          />
        ),
      },
    ];
  };

  return (
    <TableReactPrime
      content={[row]}
      columnConfig={changesColumnConfig()}
      showPagination={false}
    />
  );
};

export default ApprovalItemResponseTable;
