import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { ParamRefInput } from 'utils/types/nodes';

interface DeletePolicyParamRefAnswersQueryVariables {
  policyId: string | undefined;
  paramRef: ParamRefInput;
}

export const DELETE_POLICY_PARAM_REF_ANSWERS = (policyId: string | undefined, paramRef: ParamRefInput) => {
  if (!policyId) throw new Error('DELETE_POLICY_PARAM_REF_ANSWERS: policyId is required');

  const variables: DeletePolicyParamRefAnswersQueryVariables = {
    policyId,
    paramRef,
  };

  return {
    mutation: gql`
      mutation ($policyId: ID!, $paramRef: ParamRefInput!) {
        deletePolicyParamRefAnswers(policyId: $policyId, paramRef: $paramRef)
      }
    `,
    variables,
  };
};

export const deletePolicyParamRefAnswers = createAsyncThunk(
  'policies/deletePolicyParamRefAnswers',
  async (paramRef: ParamRefInput, { getState }) => {
    const state = getState() as RootState;
    const {
      policyDetail: {
        activePolicy: { id: policyId },
      },
    } = state;

    const response = await client.mutate(DELETE_POLICY_PARAM_REF_ANSWERS(policyId, paramRef));
    return response;
  },
);
