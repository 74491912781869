import ExpansionRightRentAssessmentType from 'common/model/ExpansionRightRentAssessmentType';
import ExpansionRightsValuationData from 'common/model/ExpansionRightsValuationData';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';
import { CValuationDataPremises2 } from './ValuationDataPremises';

export class CExpansionRightsValuationData {
  latestDate: Date | null;
  wholeOrPart: WholeOrPartOptions;
  rentAssessment: ExpansionRightRentAssessmentType;
  premises: CValuationDataPremises2;

  constructor(input: ExpansionRightsValuationData) {
    this.latestDate = input && input.latestDate ? new Date(input.latestDate) : null;
    this.wholeOrPart = input.wholeOrPart;
    this.rentAssessment = input.rentAssessment;
    this.premises = new CValuationDataPremises2(input.premises);
  }

  static fragment() {
    return `
        latestDate
        wholeOrPart
        rentAssessment
        premises {
          ${CValuationDataPremises2.fragment()}
        }
    `;
  }
}
