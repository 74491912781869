import { Loader } from 'semantic-ui-react';
import { Grid } from 'semantic-ui-react';
import { RiskAnalysisData } from 'common/api/dashboard';
import ParetoGraph from './Graph';
import './ParetoGraphWithOutlinedContainer.scss';

interface ParetoGraphWithOutlinedContainerProps {
  riskAnalysisData: RiskAnalysisData[];
  graphTitle: string;
  loading: boolean;
  className?: string;
}

const ParetoGraphWithOutlinedContainer = ({
  riskAnalysisData,
  graphTitle,
  loading,
  className = '',
}: ParetoGraphWithOutlinedContainerProps) => {
  return (
    <Grid className={`pareto-graph-without-line bg-white border-sm-grayish-magenta-light ${className}`}>
      <Grid.Row className="pm-none">
        {loading ? (
          <Loader
            active={true}
            size="big"
            className="graph-loader"
          />
        ) : (
          <Grid.Column>
            <ParetoGraph
              riskAnalysisData={riskAnalysisData}
              title={graphTitle}
            />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default ParetoGraphWithOutlinedContainer;
