import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { NotificationReadStatus } from 'components/NotificationComponent/utils.notification';
import NotificationType from 'common/model/NotificationType';
import { PaginationAPIProps } from 'common/api/miscellaneous';

export const queryNotificationResult = `
    id
    archive
    date
    infoApproval {
      transaction {
        id
        identifier
      }
    }
    infoSender {
      entity {
        id
        name
      }
      user {
        id
        firstName
        lastName
        photoUrl
      }
    }
    seen
    type
    user {
      email
      id
      photoUrl
      members {
        status
        entity {
          id
          name
        }
        role {
          id
          name
        }
      }
    }
    entity {
      id
      name
    }
`;

interface ListNotificationsQueryVariables {
  userId: string;
  archive: boolean;
  seen: boolean[];
  type: NotificationType[];
  search?: string | null;
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

const LIST_NOTIFICATIONS = ({
  userId,
  archive,
  seen,
  type,
  search,
  after,
  before,
  first,
  last,
}: ListNotificationsQueryVariables) => {
  if (!userId) throw new Error('List Notifications: userId is required');

  const variables: ListNotificationsQueryVariables = {
    archive,
    after,
    before,
    search,
    seen,
    first,
    last,
    userId,
    type,
  };

  return {
    query: gql`
    query (
      $after: String
      $before: String
      $search: String
      $archive: Boolean
      $seen: [Boolean]
      $first: Int
      $last: Int
      $userId: ID!
      $type: [NotificationType]
    ) {
      listNotifications(
        after: $after
        before: $before
        filter: { search: $search, seen: $seen, archive: $archive, type: $type }
        first: $first
        last: $last
        userId: $userId
      ) {
        edges {
          cursor
          node {
            ${queryNotificationResult}
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  `,
    variables,
  };
};

interface GetNotificationsAction extends PaginationAPIProps {
  loader: boolean;
}

export const listNotifications = createAsyncThunk(
  'notifications/listNotifications',
  async ({ first, last, after, before }: GetNotificationsAction, { getState }) => {
    const {
      notifications: { typeFilter, readFilter, archive, search },
      auth: { id },
    } = getState() as RootState;

    const seen: boolean[] = readFilter.map(item => item === NotificationReadStatus.Read);

    const response = await client.query(
      LIST_NOTIFICATIONS({ userId: id || '', archive, seen, type: typeFilter, search, after, before, first, last }),
    );

    return response;
  },
);
