import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import MemberStatus from 'common/model/MemberStatus';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface MemberInput {
  entityId: string;
  roleId: string;
  status: MemberStatus;
  userId: string;
}

interface UpdateMemberStatusAndRoleQueryVariables {
  currentLandlordEntityId: string;
  memberId: string;
  params: MemberInput;
}

export const UPDATE_MEMBER_STATUS_AND_ROLE = (memberId: string, params: MemberInput) => {
  const currentLandlordEntityId: string | null = getCurrentLandlordEntityId();

  if (!currentLandlordEntityId) throw new Error('Update Member Resend Invite: currentLandlordEntityId is required');

  if (!memberId) throw new Error('Update Member Status And Role: memberId is required');

  const variables: UpdateMemberStatusAndRoleQueryVariables = {
    currentLandlordEntityId,
    memberId,
    params,
  };

  return {
    mutation: gql`
      mutation ($currentLandlordEntityId: ID!, $memberId: ID!, $params: MemberInput!) {
        updateMemberStatusAndRole(
          currentLandlordEntityId: $currentLandlordEntityId
          memberId: $memberId
          params: $params
        ) {
          role {
            id
            name
            approvalLevel
          }
          entity {
            id
            name
          }
          id
          status
        }
      }
    `,
    variables,
  };
};

export const updateMemberStatusAndRole = createAsyncThunk(
  'members/updateMemberStatusAndRole',
  async (arg: { memberId: string; params: MemberInput }) => {
    const response = await client.mutate(UPDATE_MEMBER_STATUS_AND_ROLE(arg.memberId, arg.params));

    return response.data;
  },
);
