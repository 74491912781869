import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface UploadSendToTenantPDFMutationVariables {
  transactionId: string | null | undefined;
  documentTypeIds: string[];
  currentLandlordEntityId: string | null;
  final: boolean;
}

export const UPLOAD_SEND_TO_TENANT_PDF = (
  transactionId: string | null | undefined,
  documentTypeIds: string[],
  final: boolean,
) => {
  if (!transactionId) throw new Error('Upload Approved PDF: transactionId is required');

  const variables: UploadSendToTenantPDFMutationVariables = {
    transactionId,
    documentTypeIds,
    currentLandlordEntityId: getCurrentLandlordEntityId(),
    final,
  };

  return {
    mutation: gql`
      mutation ($transactionId: ID!, $documentTypeIds: [ID!], $currentLandlordEntityId: ID!) {
        uploadSendToTenantPDF(
          transactionId: $transactionId
          documentTypeIds: $documentTypeIds
          currentLandlordEntityId: $currentLandlordEntityId
        ) {
          result
        }
      }
    `,
    variables,
  };
};

export const uploadSendToTenantPDF = createAsyncThunk(
  'transactions/uploadSendToTenantPDF',
  async ({ documentTypeIds }: { documentTypeIds: string[] }, { getState }) => {
    const {
      transactionDetail: {
        activeTransaction: { id: transactionId },
      },
    } = getState() as RootState;

    const response = await client.mutate(UPLOAD_SEND_TO_TENANT_PDF(transactionId, documentTypeIds, true));
    return response;
  },
);
