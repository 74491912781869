import { useAppDispatch } from 'hooks';
import SearchBar from 'components/SearchBar';

interface SearchFilterProps {
  search: string | null;
  onUpdateSearch: (value?: string) => any;
  onGetData: (value?: string) => any;
  className?: string;
}

const SearchFilter = ({ search, onUpdateSearch, onGetData, className }: SearchFilterProps) => {
  const dispatch = useAppDispatch();

  const onSearchUpdate = (value?: string) => {
    dispatch(onUpdateSearch(value));
    dispatch(onGetData(value));
  };

  return (
    <SearchBar
      value={search || ''}
      search={(_event, { value }) => onSearchUpdate(value)}
      className={className}
    />
  );
};

export default SearchFilter;
