import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { PROFILE_TABS_OFFSET } from 'views/profile/Tabs/ProfileTabs';
import { updateActiveProfileTab } from 'store/authentication/authSlice';

interface CreateButtonProps {
  membersSize: number;
}
const CreateButton = ({ membersSize }: CreateButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onRedirect = () => {
    if (membersSize === 0) {
      navigate('/landlord-entities/create?step=1');
    } else {
      dispatch(updateActiveProfileTab({ tab: PROFILE_TABS_OFFSET.ENTITIES }));
      navigate('/profile');
    }
  };

  return (
    <Button
      fluid={true}
      className="btn grey-bg"
      onClick={onRedirect}
    >
      {membersSize === 0 ? 'CREATE LANDLORD' : 'GO TO ENTITIES'}
    </Button>
  );
};

export default CreateButton;
