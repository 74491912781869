import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

export const GET_SYSTEM_INFO = () => {
  return {
    query: gql`
      query {
        getSystemInfo {
          version
          buildDate
          environment
          gitVersion
        }
      }
    `,
  };
};

export const getSystemInfo = createAsyncThunk('auth/getSystemInfo', async () => {
  const response = await client.query(GET_SYSTEM_INFO());
  return response;
});
