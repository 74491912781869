import AmendmentCreator from 'views/transactions/AmendmentCreator';
import TransactionCreatorViewer from 'views/transactions/CreatorViewer';
import TransactionsDashboard from 'views/transactions/Dashboard';
import TransactionTenantViewer from 'views/transactions/TenantViewer';
import { checkIfAdminURL, checkIfTenantURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const TransactionsRoutes = () => {
  let children = [
    {
      path: '/transactions/dashboard',
      element: isAuthenticated(<TransactionsDashboard />),
    },
  ];

  if (checkIfTenantURL()) {
    children = [
      ...children,
      {
        path: '/transactions/:id/details',
        element: isAuthenticated(<TransactionTenantViewer />),
      },
    ];
  }

  if (!checkIfAdminURL() && !checkIfTenantURL()) {
    children = [
      ...children,
      {
        path: '/transactions/:id/details',
        element: isAuthenticated(<TransactionCreatorViewer isViewPage={true} />),
      },
      {
        path: '/transactions/create',
        element: isAuthenticated(<TransactionCreatorViewer />),
      },
      {
        path: '/transactions/:transactionId/amendment/:amendmentId/details',
        element: isAuthenticated(<AmendmentCreator isViewPage={true} />),
      },
      {
        path: '/transactions/:transactionId/amendment/create',
        element: isAuthenticated(<AmendmentCreator />),
      },
    ];
  }

  return [
    {
      path: '/transactions',
      children,
    },
  ];
};

export default TransactionsRoutes;
