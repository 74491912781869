import AnswerTypes from 'common/model/AnswerTypes';

export interface Choice {
  text: string;
  value: string;
}

/**
 * The following types are used to define the different types associated
 * to specific answerType
 */
export interface AnswerJsonMultiChoice {
  values: string[];
  inverses: string[];
  choices: Choice[];
}

export interface AnswerJsonNumberUnit {
  value: string;
}

export interface AnswerJsonTime {
  hours: string;
  minutes: string;
}

export interface AnswerJsonDuration {
  days: string;
  months: string;
  years: string;
}

export interface AnswerJsonString {
  value: string;
}

export interface AnswerJsonBoolean {
  value: boolean;
}

export interface AnswerJsonNumber {
  value: number;
}

export interface AnswerJsonNumberPercent {
  value: number;
}

export interface AnswerJsonDate {
  value: Date;
}

export interface PeriodJsonContent {
  startDate: string;
  endDate: string;
}

export type AnswerJsonTypes =
  | AnswerJsonMultiChoice
  | AnswerJsonNumberUnit
  | AnswerJsonTime
  | AnswerJsonDuration
  | PeriodJsonContent
  | AnswerJsonString
  | AnswerJsonBoolean
  | AnswerJsonNumber
  | AnswerJsonNumberPercent
  | AnswerJsonDate;

export interface FormatterAnswerInput {
  answer: AnswerJsonTypes;
  answerType: AnswerTypes;
  label: string;
}

export const initFormatterAnswer = (type: AnswerTypes): AnswerJsonTypes => {
  let answer: AnswerJsonTypes;

  switch (type) {
    case AnswerTypes.NumberUnit:
      answer = { value: '' };
      break;
    case AnswerTypes.Time:
      answer = { hours: '', minutes: '' };
      break;

    case AnswerTypes.Duration:
      answer = { days: '', months: '', years: '' };
      break;

    case AnswerTypes.SingleChoice:
    case AnswerTypes.MultiChoice:
      answer = {
        values: [],
        inverses: [],
        choices: [],
      };
      break;

    // case AnswerTypes.Number:
    // case AnswerTypes.NumberPercent:
    // case AnswerTypes.Boolean:
    // case AnswerTypes.Date:
    // case AnswerTypes.Text:
    default:
      answer = { value: '' };
      break;
  }

  return answer;
};

/**
 * Gets the empty or default value for answers
 * @returns
 */
export const initAnswerValue = (answerType: AnswerTypes, choiceIds?: string[]): any => {
  switch (answerType) {
    case AnswerTypes.SingleChoice:
    case AnswerTypes.MultiChoice:
      return {
        values: [],
        inverses: choiceIds,
      };
    case AnswerTypes.Text:
    case AnswerTypes.Date:
    case AnswerTypes.Number:
    case AnswerTypes.NumberPercent:
    case AnswerTypes.NumberUnit:
    case AnswerTypes.Boolean:
      return '';
    case AnswerTypes.Duration:
      return {
        years: '',
        months: '',
        days: '',
      };
    case AnswerTypes.Period:
      return {
        startDate: '',
        endDate: '',
      };
    case AnswerTypes.Time:
      return {
        hours: '',
        minutes: '',
      };
  }
};
