import { useEffect } from 'react';
import { Button } from 'semantic-ui-react';
import Alert from './Alert';
import DuplicatedData from './DuplicatedData';
import ErrorsAndWarnings from './ErrorsAndWarnings';
import ImportDetails from './ImportDetails';
import Statistics from './Statistics';
import { LogsType, UploadReportProps } from './utils-interfaces';
import './ImportReport.scss';

const ImportReport = ({ importReport, clearReport, dataObjectName, className = '' }: UploadReportProps) => {
  useEffect(() => {
    return () => {
      if (importReport) {
        clearReport();
      }
    };
  }, []);

  const getErrorOrWarningsCount = (source: LogsType[], type: string) => {
    return source.filter(s => s.type.toUpperCase() === type.toUpperCase()).length;
  };

  const { itemsDuplicateBase, itemsDuplicateExcel, itemsModified, logs, simulation } = importReport;

  return (
    <div className={`import-report border-sm-grayish-magenta-light ${className}`}>
      <div className="header">
        <h3 className="title">Import Report</h3>
        <div className="btns">
          <Button
            className="btn grey-outline"
            onClick={clearReport}
          >
            CLOSE REPORT
          </Button>
        </div>
      </div>

      <Alert isSimulation={simulation} />

      <div className="details">
        <div className="report-section">
          <Statistics
            dataObjectName={dataObjectName}
            successCount={itemsModified.length}
            excelDuplicateCount={itemsDuplicateExcel.length}
            databaseDuplicateCount={itemsDuplicateBase.length}
            warningsCount={getErrorOrWarningsCount(logs, 'info')}
            errorsCount={getErrorOrWarningsCount(logs, 'error')}
          />
        </div>
        <div className="report-section">
          <ImportDetails
            details={itemsModified}
            dataObjectName={dataObjectName}
          />
          <div className="item">
            <DuplicatedData
              databaseDuplication={itemsDuplicateBase}
              excelDuplication={itemsDuplicateExcel}
            />
          </div>
          <div className="item">
            <ErrorsAndWarnings logs={logs} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportReport;
