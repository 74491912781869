import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import InviteUserModal from 'views/userAccountManagement/components/TeamModals/InviteUserModal';
import { updateSearch } from 'store/members/membersSlice';
import 'common/api/members';
import { listMembers } from 'common/api/members';

const MembersHeaderToolbar = (): React.ReactElement => {
  const { search } = useAppSelector((state: RootState) => state.members);

  const onGetData = (value?: string) => {
    return listMembers({ searchValue: value, first: 1000 });
  };

  return (
    <>
      <SearchFilter
        search={search}
        onUpdateSearch={updateSearch}
        onGetData={onGetData}
        className="m-r-sm"
      />
      <InviteUserModal
        trigger={<Button className="btn grey-bg">INVITE USER</Button>}
        createMode={true}
      />
    </>
  );
};

export default MembersHeaderToolbar;
