import moment from 'moment';
import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateBenchmarkInput } from 'store/benchmark/benchmarkDetailSlice';
import { listIndustries } from 'common/api/entities';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { checkNotEmpty } from 'utils/tsValidator';

const PartiesOrTermForm = ({ mode }: ModeProps) => {
  const formatDate = (value?: string) => moment(value).format('YYYY-MM-DD');
  const {
    activeBenchmark: { name, industry, startDate, endDate },
  } = useAppSelector((state: RootState) => state.benchmarkDetail);

  const { industryListOptions } = useAppSelector((state: RootState) => state.entityDetail);

  const dispatch = useAppDispatch();
  const onChange = (key: string, value: string) => {
    if (key === 'startDate' || key === 'endDate') {
      dispatch(updateBenchmarkInput({ key, value: formatDate(value) }));
    } else {
      dispatch(updateBenchmarkInput({ key, value }));
    }
  };

  let industryValue;
  if (typeof industry === 'object' && industry !== null) {
    // @ts-ignore
    industryValue = industry.id;
  } else {
    industryValue = industry;
  }

  useEffect(() => {
    dispatch(listIndustries());
  }, [dispatch]);

  return (
    <div className="grid">
      <div className="col-6">
        <InputField
          label="Tenant"
          fieldKey="name"
          dataTest="tenant-name"
          value={name}
          disabled={mode === MODE_OPTIONS.READ}
          onChange={onChange}
        />
        <DateField
          label="Term start date"
          fieldKey="startDate"
          dataTest="term-start-date-input"
          value={startDate}
          disabled={mode === MODE_OPTIONS.READ}
          required={mode === MODE_OPTIONS.EDIT}
          onChange={onChange}
          runValidation={!checkNotEmpty(startDate)}
          // @ts-ignore
          validationDependant={endDate}
        />
      </div>
      <div className="col-6">
        <SelectField
          label="Tenant industry"
          value={industryValue}
          disabled={mode === MODE_OPTIONS.READ}
          placeholder="Select industry..."
          options={industryListOptions}
          search={true}
          onChange={onChange}
          fieldKey="industry"
          dataTest="entity-industry"
        />
        <DateField
          label="Term end date"
          fieldKey="endDate"
          dataTest="term-end-date-input"
          value={endDate}
          disabled={mode === MODE_OPTIONS.READ}
          required={mode === MODE_OPTIONS.EDIT}
          onChange={onChange}
          runValidation={!checkNotEmpty(endDate)}
          // @ts-ignore
          validationDependant={startDate}
        />
      </div>
    </div>
  );
};

export default PartiesOrTermForm;
