import { LogsType } from '../utils-interfaces';

interface DuplicatedDataProps {
  databaseDuplication: LogsType[];
  excelDuplication: LogsType[];
}

const DuplicatedData = ({ databaseDuplication, excelDuplication }: DuplicatedDataProps) => {
  return (
    <div className="item">
      <h6 className="title">Duplicated data</h6>
      <ul>
        {databaseDuplication.length === 0 && excelDuplication.length === 0 ? (
          <li>No duplicates found</li>
        ) : (
          <>
            {excelDuplication.map(({ log }, index) => (
              <li key={`excel-duplicate-${index}`}>
                <span className="amber">Excel duplication:</span> {`${log}`}
              </li>
            ))}
            {databaseDuplication.map(({ log }, index) => (
              <li key={`excel-duplicate-${index}`}>
                <span className="amber">Database duplication:</span> {`${log}`}
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default DuplicatedData;
