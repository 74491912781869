import { ProvisionDetailClone } from 'common/_classes';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import SectionComponent from 'layouts/SectionComponent';
import SelectField from 'atoms/FormField/Select';
import GuidelinesComponent from 'components/Guidelines';
import RevisionForm from 'components/RevisionForm';
import TabContainer from 'components/TabContainer';
import ProvisionUseTemplate from 'views/provisions/ProvisionUseTemplate';
import {
  togglerShowExistenceParameter,
  updateActiveProvision,
  updateActiveProvisionGuideLine,
} from 'store/provisions/provisionDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import Parameter from 'common/model/Parameter';
import SortOrder from 'common/model/SortOrder';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { listParameters } from 'common/api/multiLevelMenu';
import { MultiLevelParamPaginationProps } from 'common/api/multiLevelMenu';
import { createProvision, getProvision, updateProvision } from 'common/api/provisions';
import { DROPDOWN_OPTION } from 'utils/UI';
import { checkNotEmpty } from 'utils/tsValidator';
import ProvisionGeneralForm from './ProvisionGeneralForm';

const provisionDisabled = ({
  name,
  isStructure,
  restartNumbering,
  provisionCategory,
  showLinkedParameter,
  existenceParameter,
}: ProvisionDetailClone): boolean => {
  return (
    !checkNotEmpty(name) ||
    !checkNotEmpty(isStructure) ||
    !checkNotEmpty(restartNumbering) ||
    !checkNotEmpty(provisionCategory?.id) ||
    (showLinkedParameter ? !checkNotEmpty(existenceParameter?.id) : false)
  );
};

const PropertyGeneralContent = ({ mode }: ModeProps) => {
  const dispatch = useAppDispatch();
  const {
    activeProvision: { guideline, id: provision_id, showLinkedParameter = false, existenceParameter },
  } = useAppSelector((state: RootState) => state.provisionDetail);
  const { parameters } = useAppSelector((state: RootState) => state.multiLevelMenu);

  useEffect(() => {
    if (existenceParameter) dispatch(updateActiveProvision({ key: 'showLinkedParameter', value: true }));
  }, [existenceParameter]);

  useEffect(() => {
    //TODO currently, we have less than 1000 parameters. we will need to check how to fetch ALL, without a restriction on the number
    const FIRST_PAGINATION: number = 1000;
    const params: MultiLevelParamPaginationProps = {
      first: FIRST_PAGINATION,
      sortOrder: SortOrder.Asc,
    };

    // @ts-ignore
    dispatch(listParameters(params));
  }, []);

  // Only parameters of type AnswerTypes.Boolean and that have 'provisionExistence' flag ON should be used.
  // TODO : maybe update the 'params' of listParameters to have the filter embedded in the request so no
  //        filter would be needed here.
  const parameterOptions: DROPDOWN_OPTION[] = useMemo(() => {
    return parameters
      .filter((parameter: Parameter) => parameter.answerType === AnswerTypes.Boolean && !!parameter.provisionExistence)
      .map((parameter: Parameter, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: parameter.name,
          value: parameter.id,
        };
        return result;
      });
  }, [parameters]);

  return (
    <Form>
      <Grid>
        <Grid.Row className="pm-none">
          <Grid.Column width={10}>
            <SectionComponent title="1. General">
              <ProvisionGeneralForm mode={mode} />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column width={6}>
            <SectionComponent title="2. Versioning Information">
              <RevisionForm note="The provision has been updated in order to take into account the evolution of the legislation concerning the TODO option." />
            </SectionComponent>

            <SectionComponent
              className="m-t-xl"
              title={
                <>
                  3. Linked to existence parameter
                  <Checkbox
                    className="m-l-sm"
                    data-test="existence-parameter-checkbox"
                    checked={showLinkedParameter === true}
                    toggle={true}
                    onChange={() => dispatch(togglerShowExistenceParameter())}
                    disabled={mode === MODE_OPTIONS.READ}
                  />
                </>
              }
            >
              {showLinkedParameter && (
                <SelectField
                  label="Select existence parameter"
                  fieldKey="existenceParameter"
                  value={existenceParameter?.id}
                  disabled={mode === MODE_OPTIONS.READ}
                  placeholder="Select ..."
                  search={true}
                  options={parameterOptions}
                  required={mode === MODE_OPTIONS.EDIT}
                  onChange={(key, value) => dispatch(updateActiveProvision({ key, value }))}
                  dataTest="select-parameter"
                />
              )}
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <SectionComponent
              title="4. Guidelines"
              className="m-t-xl guidelines-box"
              data-test="guidelines"
            >
              <GuidelinesComponent
                mode={mode}
                guidelines={guideline}
                onUpdateGuidelineType={updateActiveProvisionGuideLine}
              />
            </SectionComponent>
          </Grid.Column>
        </Grid.Row>

        {provision_id && (
          <Grid.Row>
            <Grid.Column width={16}>
              <SectionComponent
                title="5. provision Use"
                className="m-t-xl"
              >
                <ProvisionUseTemplate
                  displayAsRow={true}
                  provision_id={provision_id}
                />
              </SectionComponent>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form>
  );
};

const GeneralTab = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const checkCreate: boolean = location.pathname.includes('create');
  const pageTypeCheck: MODE_OPTIONS = checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ;
  const { activeProvision } = useAppSelector((state: RootState) => state.provisionDetail);

  const [mode, setMode] = useState<MODE_OPTIONS>(pageTypeCheck);

  const onCreate = () => {
    dispatch(createProvision()).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        setMode(MODE_OPTIONS.READ);
        // @ts-ignore
        const provisionId = response.payload.data.createProvision.id;
        navigate(`/provisions/${provisionId}/details`);
      }
    });
  };

  /**
   * On Save button Click
   */
  const onSave = () => {
    if (checkCreate) {
      onCreate();
    } else {
      dispatch(updateProvision());
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = () => {
    if (checkCreate) {
      navigate('/provisions/dashboard');
    } else {
      const provision = activeProvision;
      dispatch(getProvision({ id: provision.id }));
    }
  };

  const tabToolbar = (
    <>
      {mode === MODE_OPTIONS.READ && (
        <div className="d-flex btns details-buttons">
          <Button
            className="btn grey-outline"
            data-test="check-consistency-button"
          >
            CHECK CONSISTENCY
          </Button>
        </div>
      )}
      <DetailsControlButtons
        mode={mode}
        setMode={setMode}
        checkDisabled={provisionDisabled(activeProvision)}
        onCancel={onCancel}
        onSave={onSave}
      />
    </>
  );

  return (
    <TabContainer
      tabTitle={'General'}
      mode={mode}
      tabToolbar={tabToolbar}
    >
      <PropertyGeneralContent mode={mode} />
    </TabContainer>
  );
};

export default GeneralTab;
