import { useState } from 'react';
import { useAppDispatch } from 'hooks';
import { Icon } from '@iconify/react';
import ConfirmPopup, { TEMPLATE_TYPES, TemplateData } from 'atoms/ConfirmPopup';
import { InputFieldType } from 'atoms/FormField/Input';
import { addSpace } from 'store/properties/propertyDetailSlice';
import { ColorNames } from 'utils/utils-colors';
import { Icons } from 'utils/utils-icons';
import './AddUnitModal.scss';

const AddUnitModal = () => {
  const dispatch = useAppDispatch();
  const [value, SetValue] = useState<number | null>(1);

  const updateSpaceCount = (key: string, count: string) => {
    const spaceCount = parseInt(count);
    if (Number.isNaN(spaceCount)) {
      SetValue(null);
    } else if (spaceCount > 0 && spaceCount <= 20) {
      SetValue(spaceCount);
    }
  };

  const modalTemplate: TemplateData[] = [
    {
      templateType: TEMPLATE_TYPES.INPUT,
      onChange: updateSpaceCount,
      value: value !== null ? value.toString() : '',
      fieldKey: '',
      label: 'Maximum value can be 20',
      required: true,
      type: InputFieldType.NUMBER,
      dataTest: 'number-of-units',
      min: 1,
      max: 20,
    },
  ];

  return (
    <ConfirmPopup
      color={ColorNames.GREEN}
      trigger={
        <span
          className="add-floors m-l-xxs"
          data-test="add-unit-button"
        >
          <Icon
            icon={Icons.AddFilled}
            className="height-1_286 width-1_286 color-orange m-r-xs"
          />
          <p className="color-blue-very-dark-grayish font-600">Add space/unit</p>
        </span>
      }
      content="Enter number of spaces/units"
      confirmButtonText="ADD SPACES/UNITS"
      onConfirm={() => dispatch(addSpace({ numberOfSpaces: value }))}
      cancelButtonText="CANCEL"
      templateData={modalTemplate}
    />
  );
};

export default AddUnitModal;
