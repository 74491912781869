import { ProvisionContentCloneExtend } from 'common/_classes';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Grid, Popup } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { uniqBy } from 'lodash';
import Badge, { BadgeColor } from 'atoms/Badge';
import { createNewNode } from 'components/Editor/PageEditor';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import { resetActiveNode, updateHandleEvent } from 'store/nodes/nodesSlice';
import { updateLoader, updateProvisionContents } from 'store/provisions/provisionDetailSlice';
import NodeType from 'common/model/NodeType';
import { updateProvision } from 'common/api/provisions';
import { ContentClone } from 'common/api/provisions';
import { getActiveDocument, sortContents } from 'utils/tsHelper';
import { EventHandlerNode } from 'utils/types/nodes';
import { parseContent } from 'utils/utils-string';
import { EDITOR_INFORMATION_TABS_OFFSET } from '..';
import './DuplicateNode.scss';

interface DuplicateNodeProps {
  nodeId: string | undefined;
  type: NodeType;
  provisionId: string;
}

const DuplicateNode = ({ nodeId, type, provisionId }: DuplicateNodeProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    activeProvision: { contents },
  } = useAppSelector((state: RootState) => state.provisionDetail);
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);
  const activeDocument: HTMLElement | undefined = getActiveDocument()?.documentElement;

  // Get list of all the duplicate nodes related to the selected one.
  //  1. Go through all the applicable documents of that provision
  //  2. Get the nodes in each applicable document
  //  3. Create list of elements to update
  interface DuplicateElement {
    node: Element;
    documentTypeId: string;
  }

  const getDuplicateNodes = (): DuplicateElement[] => {
    // Go through all the applicable documents of that provision
    return sortContents(contents, documentTypesList).flatMap((item: ProvisionContentCloneExtend) => {
      //parse content
      const parsedContent: Document = parseContent(item.content);
      // Get the nodes inside the doc
      const nodesInDoc: NodeListOf<Element> = parsedContent.querySelectorAll(`[data-node-id="${nodeId}"]`);

      return Array.from(nodesInDoc).map(nodeInDoc => {
        const element: DuplicateElement = {
          node: nodeInDoc,
          documentTypeId: item.documentTypeId,
        };
        return element;
      });
    });
  };

  const duplicateNodes: DuplicateElement[] = getDuplicateNodes();

  // Check if node exits in other docs as well
  const isMultiple: boolean = duplicateNodes.length > 1;

  const nodesToDuplicate: DuplicateElement[] = duplicateNodes.slice(1); // to Skips 1st node that does not need to be duplicated

  // Assign new id to the newly created node
  const fixDuplicateNodes = (): void => {
    dispatch(updateLoader(true));
    let conditions: HTMLElement[] = [];
    // Level is used to set the nested hierarchy of the node
    // Each level has a seperate color for the text nodes
    // It sets the data-node-level attribute
    // For text-nodes level can be 1,2,3
    let level = 0 as number | null;
    if (type === NodeType.Clause) {
      // For clauses which have the condition icon
      conditions = activeDocument ? (activeDocument.querySelectorAll('[data-condition="true"]') as any) : [];
      conditions = conditions ? conditions : [];
    } else if (type === NodeType.Parameter) {
      level = 1;
    }

    let promiseList = nodesToDuplicate.map((duplicateNode: DuplicateElement, index: number) => {
      if (type === NodeType.Text) {
        level = Number(duplicateNode.node.getAttribute('data-node-level')) as number | null;
      }
      return createNewNode(duplicateNode.node, type, level, index + 1, conditions, dispatch, null, provisionId, true);
    });

    // The function updateProvisionContents() needs to get as input all the contents that
    // have been modified. For all the nodes of one specific document (that have been
    // modified), the output of the createNewNode() function returns the exact same content
    // 'newNode.ownerDocument.body.innerHTML'. Therefore, we need to:
    //  1. identify which documents have been modified (the documentTypeId is not available
    //     in NewNodes, so we need to extract it from the corresponding nodesToDuplicate) !
    //  2. for each of the documents that are modified: update in DB

    // TODO to check if we can improve the implemententation in the future as we do not really
    // need to go through all the nodes. WE only need the content form each of the modified
    // document. Maybe we should modify createNewNode() function so that it outputs
    // 'ProvisionContent' directly ?

    Promise.all(promiseList as Promise<Element>[]).then((newNodes: Element[]) => {
      let contentsToUpdate: ContentClone[] = uniqBy(
        newNodes.map((newNode: Element, index: number) => {
          // Get latest content currenlty in the editor. Note that 'document' is the same for
          // all the nodes associated with same applicable document.
          const document: string = newNode.ownerDocument.body.innerHTML;

          // If document not yet listed, it needs to be updated in DB
          return {
            documentTypeId: nodesToDuplicate[index].documentTypeId,
            content: document,
          };
        }),
        'documentTypeId',
      );

      dispatch(resetActiveNode());
      dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.NONE));
      dispatch(updateHandleEvent(EventHandlerNode.NONE));

      // Update the docs with updated node id's
      dispatch(updateProvisionContents({ contentsToUpdate }));
      dispatch(updateProvision());
      toast('Fixed the duplicate nodes');
    });

    setIsOpen(false);
  };

  return (
    <>
      {isMultiple ? (
        <Grid.Row className="multiple-row">
          <Popup
            className="popup-info duplicate-node"
            on="click"
            open={isOpen}
            content={
              <div>
                <h2 className="title">Do you want to duplicate/split 'multi-used' nodes?</h2>
                <div className="flex">
                  <Button
                    className="btn grey-outline"
                    onClick={() => setIsOpen(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    className="btn grey-bg"
                    onClick={fixDuplicateNodes}
                  >
                    FIX
                  </Button>
                </div>
              </div>
            }
            trigger={
              <Badge
                onClick={() => setIsOpen(true)}
                badgeColor={BadgeColor.PURPLE}
              >
                MULTIPLE
              </Badge>
            }
          />
        </Grid.Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default DuplicateNode;
