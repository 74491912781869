import './ListOfProvisions.scss';

interface ListOfProvisionsProps {
  provisions: {
    provision: {
      name: string | null;
    };
  }[];
}

const ListOfProvisions = ({ provisions }: ListOfProvisionsProps) => (
  <ul className="provisions-list pm-none">
    {provisions
      ?.map(element => element.provision)
      .map((provision, index) => (
        <li
          className="item"
          key={index}
        >
          <div>
            <span>{`${index + 1} . ${provision.name}`}</span>
          </div>
        </li>
      ))}
  </ul>
);

export default ListOfProvisions;
