import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface DeleteOldTransactionApprovalAnswersMutationVariables {
  answerIds: string[];
}

export const DELETE_OLD_TRANSACTION_APPROVAL_ANSWERS = (answerIds: string[]) => {
  const variables: DeleteOldTransactionApprovalAnswersMutationVariables = {
    answerIds,
  };

  return {
    mutation: gql`
      mutation ($answerIds: [ID!]) {
        deleteOldTransactionApprovalAnswers(answerIds: $answerIds)
      }
    `,
    variables,
  };
};

export const deleteOldTransactionApprovalAnswers = createAsyncThunk(
  'transactions/deleteOldTransactionApprovalAnswers',
  async ({ answerIds }: { answerIds: string[] }) => {
    if (answerIds.length !== 0) {
      const response = await client.mutate(DELETE_OLD_TRANSACTION_APPROVAL_ANSWERS(answerIds));
      return { ...response, answerIds };
    }
  },
);
