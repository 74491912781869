import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import BreadCrumb, { BREAD_CRUMB_SIZE, BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import TabContainer from 'components/TabContainer';
import { setActiveFloor } from 'store/properties/propertyDetailSlice';
import Property from 'common/model/Property';
import { createFloor, updateFloor, updateSpacesAndUnitsFloorPlans } from 'common/api/floor';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { getProperty } from 'common/api/properties';
import { floorDisabled } from 'utils/tsValidator';
import { FloorTabViewProps, PROPERTY_FLOORS_TAB_VIEW } from '.';
import { PROPERTY_TABS_OFFSET } from '../PropertyTabs';
import FloorForm from './FloorForm';

const FloorCreator = ({ switchView }: FloorTabViewProps) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { activeFloor, floorsTabView } = useAppSelector((state: RootState) => state.propertyDetail);
  const isCreate: boolean = floorsTabView === PROPERTY_FLOORS_TAB_VIEW.FLOOR_CREATE;
  const [mode, setMode] = useState<MODE_OPTIONS>(isCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ);

  const getDetails = (): void => {
    // @ts-ignore:next-line
    dispatch(getProperty({ id })).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const activeProperty = response.payload.data.getProperty as Property;

        // @ts-ignore:next-line
        const floor = activeProperty.floors.find(({ id }) => activeFloor.id === id);

        if (floor) {
          dispatch(setActiveFloor({ activeFloor: floor }));
        }
      }
    });
  };

  const onUpdate = async () => {
    return dispatch(updateFloor()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        return dispatch(updateSpacesAndUnitsFloorPlans()).then(response => {
          getDetails();
          return response.meta.requestStatus === 'fulfilled';
        });
      }

      return false;
    });
  };

  const onCreate = (): void => {
    dispatch(createFloor()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(updateSpacesAndUnitsFloorPlans()).then((response: any) => {
          setMode(MODE_OPTIONS.READ);
          switchView(PROPERTY_FLOORS_TAB_VIEW.FLOOR_DETAIL);
        });
      }
    });
  };

  const checkDisabled = (): boolean => {
    return floorDisabled(activeFloor);
  };

  const goBack = (): void => switchView(PROPERTY_FLOORS_TAB_VIEW.FLOORS_LISTING);

  /**
   * On Save button Click
   */
  const onSave = async () => {
    if (isCreate) {
      onCreate();
    } else {
      const result = await onUpdate();
      if (!result) setMode(MODE_OPTIONS.EDIT);
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = (): void => {
    if (isCreate) {
      goBack();
    } else {
      getDetails();
    }
  };

  const TabToolbar = (): React.ReactElement => {
    return (
      <DetailsControlButtons
        mode={mode}
        checkDisabled={checkDisabled()}
        setMode={setMode}
        onSave={onSave}
        onCancel={onCancel}
      />
    );
  };

  const breadCrumb: BreadCrumbItem[] = [
    {
      title: 'Floors & Units',
      url: `?tabIndex=${PROPERTY_TABS_OFFSET.FLOORS_AND_UNITS}&view=${PROPERTY_FLOORS_TAB_VIEW.FLOORS_LISTING}`,
      onClick: goBack,
    },
    {
      title: activeFloor?.name || '',
    },
  ];

  return (
    <TabContainer
      tabTitle={
        <BreadCrumb
          size={BREAD_CRUMB_SIZE.SMALL}
          sections={breadCrumb}
        />
      }
      tabToolbar={<TabToolbar />}
      mode={mode}
    >
      <FloorForm mode={mode} />
    </TabContainer>
  );
};

export default FloorCreator;
