import { Button } from 'semantic-ui-react';
import { FilterDataType } from 'components/ComparablesTable';
import ModalTemplate from 'components/ModalTemplate';
import { deepClone } from 'utils/tsHelper';
import RowFilterContent from './RowFilterContent';
import { disableApplyButton } from './validation.utils';

interface RowFilterPopupProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  filterData: FilterDataType;
  setFilterData: (filterData: FilterDataType) => void;
  onFilter: (filterData: FilterDataType) => void;
}

const RowFilterPopup = ({ isOpen, setIsOpen, setFilterData, filterData, onFilter }: RowFilterPopupProps) => {
  const rowFilter = filterData[filterData.activeConfigId!];

  /**
   * onResetRowFilters
   */
  const onResetRowFilters = (): void => {
    setIsOpen(false);
    const updatedFilterData = deepClone(filterData);
    if (updatedFilterData[filterData.activeConfigId!].defaultFilter) {
      updatedFilterData[filterData.activeConfigId!] = {
        ...updatedFilterData[filterData.activeConfigId!],
        filterValue: undefined,
        filterMode: undefined,
        filterLogicalOperator: undefined,
        filterValue2: undefined,
        filterMode2: undefined,
        filterActive: false,
      };
    } else if (!updatedFilterData[filterData.activeConfigId!].sortOrder) {
      delete updatedFilterData[filterData.activeConfigId!];
    } else {
      const data = updatedFilterData[filterData.activeConfigId!];

      updatedFilterData[filterData.activeConfigId!] = {
        id: data.id,
        sortOrder: data.sortOrder,
        label: data.label,
      };
    }
    updatedFilterData.activeConfigId = null;

    setFilterData(updatedFilterData);
    onFilter(updatedFilterData);
  };

  /**
   * onApplyRowFilters
   */
  const onApplyRowFilters = (): void => {
    const updatedFilterData = deepClone(filterData);
    updatedFilterData[rowFilter.id].filterActive = true;
    setFilterData(updatedFilterData);

    setIsOpen(false);
    onFilter(updatedFilterData);
  };

  const onChange = (key: string, value: any, updateRoot?: boolean) => {
    let updatedRowFilter = { ...rowFilter };
    if (updateRoot) {
      updatedRowFilter = { ...updatedRowFilter, ...value };
    } else {
      updatedRowFilter = { ...rowFilter, [key]: value };
    }

    const updatedFilterData = deepClone(filterData);
    updatedFilterData[rowFilter.id] = updatedRowFilter;

    setFilterData(updatedFilterData);
  };

  return (
    <ModalTemplate
      isOpen={isOpen}
      onToggleModalStatus={setIsOpen}
      title={`${rowFilter.label} filter`}
      className="valuation-contract-view-row-filters-modal"
      showHeader={true}
    >
      <div className="filter-box">
        <div className="filter-content">
          {rowFilter ? (
            <RowFilterContent
              filter={rowFilter}
              onChange={onChange}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="filter-buttons grid justify-center m-t-xxs">
          <div className="col-6">
            <Button
              className="btn orange-outline w-100"
              onClick={onResetRowFilters}
            >
              CLEAR
            </Button>
          </div>
          <div className="col-6">
            <Button
              className="btn orange-bg w-100"
              onClick={onApplyRowFilters}
              disabled={disableApplyButton(rowFilter)}
            >
              APPLY
            </Button>
          </div>
        </div>
      </div>
    </ModalTemplate>
  );
};

export default RowFilterPopup;
