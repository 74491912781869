import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClientType } from 'utils/utils-authentication';

export const LIST_COUNTRIES = () => {
  return {
    mutation: gql`
      query {
        listCountries {
          name
          code
        }
      }
    `,
  };
};

export const listCountries = createAsyncThunk('miscellaneous/listCountries', async () => {
  const response = await getClientType().mutate(LIST_COUNTRIES());
  return response;
});
