import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterTableListingClone } from 'common/_classes';
import { RootState } from 'store';
import SortOrder from 'common/model/SortOrder';

interface ListParameterTablesPageQueryVariables {
  sortOrder: SortOrder;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  search: string | null;
  groupId: string | null;
}

export const LIST_PARAMETER_TABLES_PAGE = (
  searchValue: any,
  paramViewSelectedGroup: any,
  first?: number,
  last?: number,
  after?: string,
  before?: string,
) => {
  const variables: ListParameterTablesPageQueryVariables = {
    sortOrder: SortOrder.Asc,
    first: first || 1000,
    last,
    after,
    before,
    search: searchValue,
    groupId: paramViewSelectedGroup?.id,
  };

  return {
    query: gql`
      query (
        $sortOrder: SortOrder!
        $first: Int
        $last: Int
        $after: String
        $before: String
        $search: String
        $groupId: ID
      ) {
        listParameterTablesPage(
          sortOrder: $sortOrder
          first: $first
          last: $last
          after: $after
          before: $before
          parameterTableFilter: { search: $search, groupId: $groupId }
        ) {
          edges {
            node {
              ${ParameterTableListingClone.fragments()}
            }
          }
        }
      }
    `,
    variables,
  };
};

export const listParameterTablesPage = createAsyncThunk(
  'parameters/listParameterTablesPage',
  async ({ first, last, after, before }: any, { getState }) => {
    const {
      parametersListing: { search },
      parameterDetail: { paramViewSelectedGroup },
    }: any = getState() as RootState;
    const response = await adminClient.query(
      LIST_PARAMETER_TABLES_PAGE(search, paramViewSelectedGroup, first, last, after, before),
    );
    return response;
  },
);
