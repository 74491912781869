import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';

const DELETE_MULTIPLE_NOTIFICATIONS = (notificationIds: string[]) => {
  if (notificationIds.length === 0) throw new Error('Delete Multiple Notifications: notificationIds is required');

  let deleteMutations: string = '';

  for (let index = 0; index < notificationIds.length; index++) {
    const notificationId = notificationIds[index];

    deleteMutations += `
              deleteNotification${index}: deleteNotification(notificationId: "${notificationId}") {
                id
              }
          `;
  }

  return {
    mutation: gql`
        mutation {
          ${deleteMutations}
        }
      `,
  };
};

export const deleteMultipleNotifications = createAsyncThunk(
  'notifications/deleteMultipleNotifications',
  async (_arg, { getState }) => {
    const {
      notifications: { selectedNotificationIds },
    } = getState() as RootState;

    const response = await client.mutate(DELETE_MULTIPLE_NOTIFICATIONS(selectedNotificationIds));
    return response;
  },
);
