import { ParameterChoiceBase } from 'common/_classes';
import { Dropdown, Input } from 'semantic-ui-react';
import { InputFieldType } from 'atoms/FormField/Input';
import AnswerTypes from 'common/model/AnswerTypes';
import Conditions from 'common/model/Conditions';
import { deepClone } from 'utils/tsHelper';
import { numberInputValidator } from 'utils/utils-clean-input';
import { addSpaceOrComma, removeSpaceOrComma } from 'utils/utils-number';
import DurationInput from './DurationInput';

const BOOLEAN_OPTIONS = [{ text: 'TRUE' }, { text: 'FALSE' }];

const createOptionsDropdown = ({
  choices,
  isBoolean,
}: {
  choices: { text: string; id?: string }[];
  isBoolean: boolean;
}) => {
  if (!choices) return [];
  if (isBoolean) {
    return choices.map(({ text }, key) => ({ key, text, value: text }));
  } else {
    return choices.map(({ text, id }, key) => ({
      key,
      text,
      value: id,
    }));
  }
};

const InputType = ({
  answerValue,
  paramAnswerType,
  groupIndex,
  index,
  mcqOptions,
  numberUnit,
  value,
  onChange,
  countParam,
  paramKey,
}: {
  answerValue: any;
  paramAnswerType: AnswerTypes;
  groupIndex: number;
  index: number;
  mcqOptions: ParameterChoiceBase[];
  numberUnit: string | null | undefined;
  value: Conditions[];
  onChange: (value: Conditions[]) => void;
  countParam?: boolean;
  paramKey?: string;
}) => {
  const checkCase1Type = () => {
    switch (paramAnswerType) {
      case AnswerTypes.Date:
        return 'date';
      case AnswerTypes.Time:
        return 'time';
      default:
        return 'text';
    }
  };

  const getNumberLabel = () => {
    switch (paramAnswerType) {
      case AnswerTypes.NumberPercent:
        return '%';
      case AnswerTypes.NumberUnit:
        return numberUnit;
      default:
        return null;
    }
  };

  const getNumberClass = () => {
    switch (paramAnswerType) {
      case AnswerTypes.NumberUnit:
        return 'add-label-unit';
      case AnswerTypes.NumberPercent:
        return 'add-label-percent';
      case AnswerTypes.Number:
        return 'number-type';
    }
  };

  const multiChoiceOptions = () => {
    return paramAnswerType === AnswerTypes.Boolean
      ? createOptionsDropdown({
          choices: BOOLEAN_OPTIONS,
          isBoolean: true,
        })
      : createOptionsDropdown({
          choices: mcqOptions,
          isBoolean: false,
        });
  };

  const onChangeConditionAnswer = (groupIndex: number, index: number, answer: any) => {
    const temp = deepClone(value);
    temp[groupIndex].list[index].answer = answer;
    onChange(temp);
  };

  const onChangeConditionAnswerCountAnswer = (
    groupIndex: number,
    index: number,
    answer: any,
    paramKey: string | undefined,
  ) => {
    const temp = deepClone(value);
    // @ts-ignore
    if (!temp[groupIndex].list[index].answer.answer) {
      // @ts-ignore
      temp[groupIndex].list[index].answer.answer = {}; // Initialize if undefined
    }
    // @ts-ignore
    temp[groupIndex].list[index].answer.answer[paramKey] = answer;
    onChange(temp);
  };

  const onChangeDurationAnswer = (groupIndex: number, index: number, key: string, answerValue: string) => {
    const temp = deepClone(value);
    // @ts-ignore
    let duration = temp[groupIndex].list[index].answer.answer;
    if (duration === '') {
      duration = {
        years: '',
        months: '',
        days: '',
      };
      // @ts-ignore
      temp[groupIndex].list[index].answer.answer = duration;
    }

    let durationCopy = { ...duration } as any;
    durationCopy[key] = answerValue;
    // @ts-ignore
    temp[groupIndex].list[index].answer.answer = durationCopy;
    onChange(temp);
  };

  switch (paramAnswerType) {
    case AnswerTypes.Text:
    case AnswerTypes.Image:
    case AnswerTypes.Date:
    case AnswerTypes.Time:
      return (
        <Input
          id={`inner-input-${groupIndex}-${index}`}
          key={`inner-input-${groupIndex}-${index}`}
          data-test={`type-${paramAnswerType}`}
          className="inner-case-1-condition border-sm-grayish-magenta-light"
          type={checkCase1Type()}
          value={answerValue ? answerValue.answer : ''}
          onChange={({ target }: { target: EventTarget & HTMLInputElement }) => {
            const answer = target.value;
            onChangeConditionAnswer(groupIndex, index, { answer });
          }}
        />
      );

    case AnswerTypes.Number:
    case AnswerTypes.NumberUnit:
    case AnswerTypes.NumberPercent:
      return (
        <Input
          id={`inner-input-${groupIndex}-${index}`}
          key={`inner-input-${groupIndex}-${index}`}
          data-test={`type-${paramAnswerType}`}
          className={`inner-case-1-condition border-sm-grayish-magenta-light ${getNumberClass()}`}
          type={InputFieldType.TEXT}
          label={getNumberLabel()}
          labelPosition="right"
          value={answerValue ? addSpaceOrComma(answerValue.answer, false) : ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const answer = removeSpaceOrComma(e.target.value);
            onChangeConditionAnswer(groupIndex, index, { answer });
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
            numberInputValidator(e, true, true, e.currentTarget.value)
          }
        />
      );

    case AnswerTypes.SingleChoice:
    case AnswerTypes.MultiChoice:
    case AnswerTypes.Boolean:
      return (
        <Dropdown
          id={`inner-input-${groupIndex}-${index}`}
          key={`inner-input-${groupIndex}-${index}`}
          data-test={`type-${paramAnswerType}`}
          className="mcq-dropdown border-sm-grayish-magenta-light"
          value={answerValue ? (countParam ? answerValue : answerValue.answer) : null}
          fluid={true}
          options={multiChoiceOptions()}
          selection={true}
          selectOnBlur={false}
          onChange={(event, { value }) => {
            const answer = value;
            if (countParam) {
              onChangeConditionAnswerCountAnswer(groupIndex, index, answer, paramKey);
            } else {
              onChangeConditionAnswer(groupIndex, index, { answer });
            }
          }}
        />
      );

    case AnswerTypes.Duration:
      return (
        <div className="duration-box">
          <DurationInput
            groupIndex={groupIndex}
            index={index}
            value={answerValue.answer.years}
            type="years"
            dataTest="years"
            onChange={onChangeDurationAnswer}
          />

          <DurationInput
            groupIndex={groupIndex}
            index={index}
            value={answerValue.answer.months}
            type="months"
            dataTest="months"
            onChange={onChangeDurationAnswer}
          />

          <DurationInput
            groupIndex={groupIndex}
            index={index}
            value={answerValue.answer.days}
            type="days"
            dataTest="days"
            onChange={onChangeDurationAnswer}
          />
        </div>
      );

    default:
      return <Input className="default-input" />;
  }
};

export default InputType;
